// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Blocked from "../pages/Authentication/Blocked";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import MFASignIn from "../pages/Authentication/MFASignIn";
import Register from "../pages/Authentication/Register";
import ResetPassword from "../pages/Authentication/ResetPassword";
import PasswordChanged from "pages/Authentication/PasswordChanged";
import Restricted from "../pages/Authentication/Restricted";
import SignInFirst from "../pages/Authentication/SignInFirst";
import TwoFA from "../pages/Authentication/TwoFA";

// Dashboard
import Dashboard from "../pages/Dashboard2/index";
// import Dashboard2 from "../pages/Dashboard2/index";

//Users
import User from "pages/Users/user";
import Users from "pages/Users/users";
import Pages404 from "../pages/Extra Pages/pages-404";
import UsersChat from "../pages/Users/UsersChat/UsersChat";

// Sports
import Competitions from "pages/Sports/competition/competitions";
import CompetitionsGroups from "pages/Sports/competitionsGroup/competitionsGroups";
import Competitors from "pages/Sports/competitors/competitors";
import Countries from "pages/Sports/countries/countries";
import Matches from "pages/Sports/matches/matches";
import SingleMatch from "pages/Sports/matches/singleMatch";
import PeriodCompetitions from "pages/Sports/periodCompetition/periodCompetitions";
import Player from "pages/Sports/players/player";
import Regions from "pages/Sports/regions/regions";
import Sport from "pages/Sports/sportTypes/sport";
import SportTypes from "pages/Sports/sportTypes/sportsTypes";
import Sports from "pages/Sports/sports/sports";
import Venue from "pages/Sports/venue/venue";

// Betting
import Bets from "pages/Betting/Bets/bets";
import Markets from "pages/Betting/Markets/markets";
import MarketsGroup from "pages/Betting/Markets/marketsGroup";
import Trading from "pages/Betting/Markets/trading";
import customBets from "pages/Betting/Specials/customBets.container";
import BetsPerMatch from "pages/Betting/betsPerMatch";

// HeaderNotification
import BetTicker from "pages/BetTicker/betTicker";

// Settings
import APIs from "pages/Settings/APIs/Apis";
import ActivityLog from "pages/Settings/ActivityLog/activityLog";
import CMS from "pages/Settings/Cms/Cms";
import CMSUserActivity from "pages/Settings/CmsUserActivity/cmsUserActivity";
import Currencies from "pages/Settings/Currencies/Currencies";
import DefaultLeagues from "pages/Settings/DefaultLeagues/defaultLeagues";
import EmailTemplate from "pages/Settings/EmailTemplate/emailTemplate";
import ErrorLogs from "pages/Settings/ErrorLogs/errorLogs";
import Ladders from "pages/Settings/Ladders/Ladders";
import Languages from "pages/Settings/Languages/Languages";
import LandingPages from "pages/Settings/LandingPages/landingPages";
import MessageCodes from "pages/Settings/MessageCodes/messageCodes";
import pageContent from "pages/Settings/PageContent/pageContent";
import pagesContent from "pages/Settings/PageContent/pagesContent";
// import PaymentGateways from "pages/Settings/PaymentGateways/paymentGateways";
import Policies from "pages/Settings/Policies/policies";
import Policy from "pages/Settings/Policies/policy";
// import Providers from "pages/Settings/Providers/Providers";
import DefaultSports from "pages/Settings/SportsOrder/defaultSports";
import Term from "pages/Settings/Terms/term";
import Terms from "pages/Settings/Terms/terms";
import BogSettings from "pages/Settings/TraderOptions/BogSettings";
import UsersList from "pages/Settings/UserList/userList";
import Variables from "pages/Settings/Variables/variables";
import EmailTemplates from "pages/Settings/EmailTemplate/emailTemplates";
import Style from "../pages/Settings/Style/Style";
import Carousel from "./../pages/Settings/Carousel/carousel";
import BonusEngine from "./../pages/Settings/BonusEngine/bonusEngine";
import CasinoProviders from "pages/Settings/CasinoProviders/CasinoProviders";
import TransactionChecker from "pages/Settings/TransactionChecker/transactionChecker";

// Financial
// import DepositMoney from "pages/Finanical/DepositMoney/depositMoney";
// import SaferGambling from "pages/Finanical/Report/SaferGambling/saferGambling";
// import DepositMoneyDapp from "pages/Finanical/Report/depositMoneyDapp";
// import UserDevicesReport from "pages/Finanical/Report/userDevicesReport";

// Report
import userManualBet from "pages/Betting/userManualBet";
//Those Components are not being used anymore !
// import accountingSummary from "pages/Finanical/Report/AccountingSummary/accountingSummary";
// import accountingSummaryPerUser from "pages/Finanical/Report/AccountingSummaryUser/accountingSummaryPerUser";
// import balanceReport from "pages/Finanical/Report/BalanceReport/balanceReport";
// import clientBalanceMovement from "pages/Finanical/Report/ClientBalanceMovement/clientBalanceMovement";
// import DepositDifferentNames from "pages/Finanical/Report/DepositDifferentNames/depositDifferentNames";
// import DuplicateUsers from "pages/Finanical/Report/DuplicateUsers/duplicateUsers";
// import FinancialReport from "pages/Finanical/Report/FinancialReport/financialReport";
// import gamblingReminderActivityLog from "pages/Finanical/Report/GamblingReminderActivityLog/gamblingReminderActivityLog";
// import listRegisteredUsers from "pages/Finanical/Report/ListRegistredUsers/listRegisteredUsers";
// import turnoverBySports from "pages/Finanical/Report/TurnoverBySports/turnoverBySports";
// import UnclosedBets from "pages/Finanical/Report/UnclosedBets/unclosedBets";
// import HealthAPI from "pages/Finanical/Report/healthApi/healthAPI";
// import LiabilityReport from "pages/Finanical/Report/liabilityReport/liabilityReport";
// import netDeposits from "pages/Finanical/Report/netDeposits/netDeposits";
// import NetSession from "pages/Finanical/Report/netSession/netSession";
// import SuspendedAccounts from "pages/Finanical/Report/suspendedAccounts/suspendedAccounts";
import Reports from "pages/Reports/Reports";
import Casino from "pages/Settings/Casino/casino";
import LiveCasino from "pages/Settings/Casino/liveCasino";
import VirtualsCasino from "pages/Settings/Casino/virtualCasino";
import FooterLinks from "pages/Settings/FooterLinks/footerLinks";
import HeroBanner from "pages/Settings/HeroBanner/banner";
import MediaLibrary from "pages/Settings/MediaLibrary/mediaLibrary";
import MenuLinks from "pages/Settings/MenuLinks/menuLinks";
import pageLayout from "pages/Settings/PageLayout/pageLayout";
import pushNotification from "pages/Settings/PushNotification/pushNotification";
import RacingWidget from "pages/Settings/RacingWidget/racingWidget";
import Seo from "pages/Settings/Seo/Seo";
import Settings from "pages/Settings/Settings/Settings";
import SportsWidget from "pages/Settings/SportsWidget/sportsWidget";
import BetBuilder from "pages/Settings/TraderOptions/BetBuilder";
import BetOptions from "pages/Settings/TraderOptions/BetOptions";
import TraderChat from "pages/Settings/TraderOptions/TraderChat";
import words from "pages/Settings/Words/words";
import Liabilities from "pages/Trading/liabilities/liabilities";
import Liability from "pages/Trading/liabilities/liability";
import searchUsers from "pages/Users/searchUsers";
import Betslip from "./../pages/Settings/Betslip/betslip";
import CashOut from "pages/Settings/TraderOptions/CashOut";
import Telebet from "pages/Users/Telebet/telebet";
// import TelebetEvent from "pages/Users/Telebet/telebet-event.container";
import ChangePassword from "pages/Authentication/ChangePassword";
import OddsDisplay from "pages/Authentication/OddsDisplay";
import playerBet from "pages/Betting/Bets/playerBet";
import LandingPagesModal from "pages/Settings/LandingPages/landingPagesModal";
import PromoLinks from "pages/Settings/PromoLinks/promoLinks";
import NotificationBar from "pages/Settings/NotificationBar/notificationBar";
import AffiliateUsers from "pages/Settings/AffiliateUsers/affiliateUsers";
import About from "pages/Settings/About/about";
import UserV2 from "pages/Users/UserV2";
import CasinoSettings from "pages/Settings/TraderOptions/CasinoSettings";
import AffiliateUsersPage from "pages/Settings/AffiliateUsers/AffiliateUsersPage";
import AffiliateDashbord from "pages/Settings/AffiliateUsers/AffiliateDashbord";
import AffiliateMonthlyOverview from "pages/Settings/AffiliateUsers/AffiliateMonthlyOverview";
import AffiliateSettings from "pages/Settings/AffiliateUsers/AffiliateSettings";
import Offers from "pages/Settings/Offers/offers";
import { OpenReport } from "pages/Reports/OpenReport";

const userRoutes = [
	{ path: "/dashboard", component: Dashboard, roleToHide: ["affiliate"] },
	// { path: "/dashboard2", component: Dashboard2 },

	// users
	{ path: "/users", component: Users, roleToHide: ["affiliate"] },
	{ path: "/search_users", component: searchUsers, roleToHide: ["affiliate"] },
	{ path: "/user/:id", component: User, roleToHide: ["affiliate"] },
	{ path: "/userV2/:id", component: UserV2, roleToHide: ["affiliate"] },

	// bet ticker
	{ path: "/bet-ticker", component: BetTicker, roleToHide: ["finance, affiliate"] },

	// profile
	{ path: "/profile", component: UserProfile },
	{ path: "/profile_change_password", component: ChangePassword },
	{ path: "/profile_preferences", component: OddsDisplay, roleToHide: ["affiliate"] },

	// Sports
	{ path: "/sport/competition/list", component: Competitions, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/sport/competition/period/list", component: PeriodCompetitions, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/sport/competition/group/list", component: CompetitionsGroups, roleToHide: ["affiliate"] },
	{ path: "/competitors", component: Competitors, roleToHide: ["finance", "customer_service", "affiliateaffiliate"] },
	{ path: "/events", component: Matches, roleToHide: ["finance", "affiliate"] },
	{ path: "/events/:id", component: SingleMatch, roleToHide: ["finance", "affiliate"] },
	{ path: "/regions", component: Regions, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/venue", component: Venue, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/player", component: Player, roleToHide: ["affiliate"] },
	{ path: "/countries", component: Countries, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },

	//Settings
	{ path: "/settings", component: Settings, roleToHide: ["affiliate"] },
	{ path: "/trading", component: Trading, roleToHide: ["affiliate"] },
	{ path: "/markets", component: Markets, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/markets_group", component: MarketsGroup, roleToHide: ["affiliate"] },
	{ path: "/sport/league/default/list", component: DefaultLeagues, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/activity_log", component: ActivityLog, roleToHide: ["operations", "finance", "trader", "customer_service", "affiliate"] },
	{ path: "/casino-providers", component: CasinoProviders, roleToHide: ["operations", "finance", "trader", "customer_service", "affiliate"] },

	//Affiliates
	{ path: "/affiliates", component: AffiliateDashbord, roleToHide: ["finance", "trader", "customer_service", "admin", "auditor", "operations"] },
	{ path: "/affiliate_users", component: AffiliateUsers, roleToHide: ["operations", "finance", "trader", "customer_service"] },
	{ path: "/affiliate_users/:id", component: AffiliateUsersPage, roleToHide: ["operations", "finance", "trader", "customer_service"] },
	{ path: "/monthly-overview", component: AffiliateMonthlyOverview, roleToHide: ["operations", "finance", "trader", "customer_service"] },
	{ path: "/affiliates-settings", component: AffiliateSettings, roleToHide: ["operations", "finance", "trader", "customer_service"] },

	{ path: "/terms", component: Terms, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/term/:id", component: Term, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/bets", component: Bets, roleToHide: ["finance", "affiliate"] },
	{ path: "/bets/:id", component: BetsPerMatch, roleToHide: ["affiliate"] },
	{ path: "/policies", component: Policies, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/variables", component: Variables, roleToHide: ["affiliate"] },
	{ path: "/policy/:id", component: Policy, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/email_template", component: EmailTemplates, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/email_template/:id", component: EmailTemplate, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/page_content", component: pagesContent, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/page_content/:id", component: pageContent, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/user_list", component: UsersList, roleToHide: ["operations", "finance", "trader", "customer_service", "affiliate"] },
	{ path: "/manual_bets", component: customBets, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/cms", component: CMS, roleToHide: ["affiliate"] },
	{ path: "/cms_users_activity", component: CMSUserActivity, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/error_logs", component: ErrorLogs, roleToHide: ["affiliate"] },
	{ path: "/sport/order", component: DefaultSports, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/promo_landing_pages", component: LandingPages, roleToHide: ["affiliate"] },
	{ path: "/promo_landing_pages/:id", component: LandingPagesModal, roleToHide: ["affiliate"] },
	{ path: "/message_codes", component: MessageCodes, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	// { path: "/payment_gateways", component: PaymentGateways },
	{ path: "/user_manual_bet", component: userManualBet, roleToHide: ["affiliate"] },
	{ path: "/push_notification", component: pushNotification, roleToHide: ["finance", "affiliate"] },
	{ path: "/words", component: words, roleToHide: ["affiliate"] },
	{ path: "/betslip", component: Betslip, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/page_layout", component: pageLayout, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/carousel", component: Carousel, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/bonus_engine", component: BonusEngine, roleToHide: ["affiliate"] },
	// { path: "/hero_widget", component: HeroWidget },
	{ path: "/casino", component: Casino, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/live_casino", component: LiveCasino, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/virtual_casino", component: VirtualsCasino, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/pages", component: MenuLinks, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/footer", component: FooterLinks, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/banners", component: HeroBanner, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/media_library", component: MediaLibrary, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/racing_widget", component: RacingWidget, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/offers", component: Offers, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/sports_widget", component: SportsWidget, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/sports", component: Sports, roleToHide: ["affiliate"] },
	{ path: "/sport/type/list", component: SportTypes, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/apis", component: APIs, roleToHide: ["affiliate"] },
	{ path: "/currencies", component: Currencies, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	// { path: "/providers", component: Providers },
	{ path: "/ladders/:id?", component: Ladders, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/languages", component: Languages, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/bog_settings", component: BogSettings, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/bet_options", component: BetOptions, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/cash_out", component: CashOut, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/casino_settings", component: CasinoSettings, roleToHide: ["finance", "trader", "customer_service", "affiliate"] },
	{ path: "/bet_builder", component: BetBuilder, roleToHide: ["affiliate"] },
	{ path: "/promo_links", component: PromoLinks, roleToHide: ["affiliate"] },
	{ path: "/seo", component: Seo, roleToHide: ["operations", "finance", "trader", "customer_service", "affiliate"] },
	{ path: "/style", component: Style, roleToHide: ["operations", "finance", "trader", "customer_service", "affiliate"] },
	{ path: "/trader_chat", component: TraderChat, roleToHide: ["finance"] },
	{ path: "/users_chat", component: UsersChat, roleToHide: ["finance"] },
	{ path: "/telebet", component: Telebet, roleToHide: ["affiliate"] },
	// { path: "/telebet/event/:id", component: TelebetEvent, roleToHide: ["affiliate"] },
	{ path: "/about", component: About, roleToHide: ["affiliate"] },
	{ path: "/notification_bar", component: NotificationBar, roleToHide: ["affiliate"] },
	{ path: "/transaction_checker", component: TransactionChecker, roleToHide: ["affiliate"] },

	// Trading
	{ path: "/liabilities", component: Liabilities, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/liability/match_id=:id/sport_slug=:slug", component: Liability, roleToHide: ["finance", "customer_service", "affiliate"] },
	{ path: "/sport/:slug", component: Sport, roleToHide: ["affiliate"] },
	{ path: "/bet/:id", component: playerBet, roleToHide: ["affiliate"] },

	//Those routes are not being used anymore !
	//Financial
	// { path: "/deposit_money", component: DepositMoney },
	// { path: "/deposit_money_dapp", component: DepositMoneyDapp },
	// { path: "/safer_gambling", component: SaferGambling },
	// { path: "/deposit_net", component: netDeposits },
	// { path: "/financial_report", component: FinancialReport },
	// { path: "/user_devices_report", component: UserDevicesReport },
	// { path: "/balance_report", component: balanceReport },

	// Report
	// { path: "/duplicate_users", component: DuplicateUsers },
	// { path: "/unclosed_bets", component: UnclosedBets },
	// { path: "/session_net", component: NetSession },
	// { path: "/suspended_accounts", component: SuspendedAccounts },
	// { path: "/health_api", component: HealthAPI },
	// { path: "/liability_report", component: LiabilityReport },
	// { path: "/deposit_different_names", component: DepositDifferentNames },
	// { path: "/accounting_summary", component: accountingSummary },
	// { path: "/accounting_summary/user", component: accountingSummaryPerUser },
	// { path: "/client_balance_movement", component: clientBalanceMovement },
	// { path: "/gambling_reminder_activity_log", component: gamblingReminderActivityLog },
	// { path: "/turnover_by_sports", component: turnoverBySports },
	// { path: "/list_registered_users", component: listRegisteredUsers },
	{ path: "/reports", component: Reports, roleToHide: ["affiliate"] },
	{ path: "/open-report/:filename", component: OpenReport, roleToHide: ["affiliate"] },
	{ path: "/variables", component: Variables, roleToHide: ["operations", "finance", "trader", "customer_service", "affiliate"] },

	{ path: "/", exact: true },
];

const authRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	{ path: "/sign_in_first", component: SignInFirst },
	{ path: "/two_fa", component: TwoFA },
	{ path: "/restricted", component: Restricted },
	{ path: "/blocked", component: Blocked },
	{ path: "/reset_password", component: ResetPassword },
	{ path: "/password_changed", component: PasswordChanged },
	{ path: "/mfa_signin", component: MFASignIn },

	{ path: "/pages-404", component: Pages404 },
];

export { authRoutes, userRoutes };
