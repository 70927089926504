import React, { useEffect, useState } from "react";
import { SingleSearchFilter } from "../../../components/GeneralComponents/CustomInputs";
import { LoadingSaveButton, NoButton, YesButton } from "components/GeneralComponents/AddButton";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { getMenuLinksColumns } from "./menuLinksColumns";
import { MenuLinksModal } from "./menuLinksModal";
import SweetAlert from "react-bootstrap-sweetalert";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import DataTable from "components/GeneralComponents/Table";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const MenuLinks = () => {
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [menuLinks, setMenuLinks] = useState([]);
	const [deleteLoading, setDeleteLoading] = useState(null);
	const [editItem, setEditItem] = useState(null);
	const [deleteItem, setDeleteItem] = useState(null);
	const [unfilteredData, setUnfilteredData] = useState([]);

	const [showPromotion, setShowPromotion] = useState(false);
	// Page Header
	const pageHeaderData = {
		international: false,
		addNew: true,
		addNewClick: () => {
			setShowPromotion(true);
		},
		removeHeader: true,
	};

	// Get Menu data
	const getMenuLinks = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.menu_links, resolve, reject, true);
		})
			.then(response => {
				setMenuLinks(response?.data?.data);
				setUnfilteredData(response?.data?.data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const handleDeleteLink = id => {
		let payload = {
			id,
		};
		setDeleteLoading(id);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.delete_menu_links + `/${id}`, resolve, reject, payload, true);
		})
			.then(() => {
				let actualData = [];
				actualData = [...menuLinks];
				actualData = actualData.filter(row => row.id !== id);
				setMenuLinks(actualData);
				setDeleteItem(null);
			})
			.finally(() => {
				setDeleteLoading(null);
			});
	};

	const changeOrder = index => {
		const selectedRow = unfilteredData.splice(index, 1)[0];
		const newData = [selectedRow, ...unfilteredData];
		setNewData(newData);
	};
	const orderItems = newOrder => {
		let payload = {
			order: newOrder.map((row, index) => ({ id: row.id, order: index })),
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.order_menu_links, resolve, reject, payload, true);
		}).then(response => {
			setMenuLinks(response?.data);
		});
	};
	const setNewData = newData => {
		setMenuLinks(newData);
		setUnfilteredData(newData);
		orderItems(newData);
	};

	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};

	useEffect(() => {
		getMenuLinks();
	}, []);

	const menuLinksColumns = getMenuLinksColumns({ menuLinks, handleEditItem, setDeleteItem, changeOrder });
	return (
		<HelmetLayout titleProps={"Pages"}>
			<div>
				<div className="cms-page pt-0">
					<SingleSearchFilter setData={setMenuLinks} unfilteredData={unfilteredData} filterColumn={["link_name"]} />
					<DataTable
						data={menuLinks}
						columns={menuLinksColumns}
						setData={newData => {
							setNewData(newData);
						}}
						options={{
							showButtons: true,
							search: false,
							viewColumns: false,
							customToolbar: () => <AddTableButton className={"data-table-add-btn"} onClick={pageHeaderData["addNewClick"]} />,
						}}
						isLoading={isDataLoading}
						pagination={false}
						orderRows={true}
						sort={false}
					/>
				</div>
				{showPromotion && (
					<MenuLinksModal
						title={"Pages"}
						editItem={editItem}
						setNewData={setMenuLinks}
						getMenuLinks={getMenuLinks}
						handler={() => {
							setShowPromotion(false);
							setEditItem(null);
						}}
					/>
				)}
				{deleteItem && (
					<SweetAlert title={"Confirm Delete"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
						<div style={{ position: "relative" }}>
							<div className="">
								<span className="delete-span">Are you sure you want to delete &quot;{deleteItem?.link_name}&quot; ?</span>
							</div>
						</div>
						<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
							<NoButton onClick={() => setDeleteItem(null)} name="No" />
							{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={() => handleDeleteLink(deleteItem?.id)} />}
						</div>
					</SweetAlert>
				)}
			</div>
		</HelmetLayout>
	);
};

export default MenuLinks;
