import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { tableExtraButtons, tableResponsive, tableBarSettings } from "utils/tableSettings";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import ApiServices from "utils/ApiServices";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";
import MUIDataTable from "mui-datatables";
import { getEmailTemplateTableColumns } from "./emailTemplateTableColumns";
import EmailTemplate from "./emailTemplate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import xIcon from "../../../assets/images/x-icon.svg";
import "./style.scss";

const emailTemplates = props => {
	const [currentPage, setCurrentPage] = useState(0);
	const breadcrumbItems = [{ title: "Email templates", link: "#" }];
	const [rowPerPage, setRowPerPage] = useState(50);
	const [data, setData] = useState([]);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const editorRef = useRef(null);
	const user = useSelector(state => state.Login.user);

	useEffect(() => {
		props.setBreadcrumbItems("Email templates", breadcrumbItems);
	});
	let records = [];

	const filterEmailTemplates = () => {
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.email_template, resolve, reject, true);
		})
			.then(response => {
				let data = response.data;
				setData(data);
				setUnfilteredData(data);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		filterEmailTemplates();
	}, []);
	const [editRowModal, setEditRowModal] = useState({});
	const handleSendEmail = () => {
		if (user && user?.id) {
			let payload = {
				user_id: user.id,
				email_id: editRowModal?.key,
			};
			new Promise((resolve, reject) => {
				ApiServices.put(apiUrls.email_template_send, resolve, reject, payload, true);
			}).then(() => {});
		} else {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">User is not defined</p>
				</>,
				{ className: "custom-toast" }
			);
		}
	};

	if (data) {
		records = data;
	}

	let emailTemplateTableColumns = getEmailTemplateTableColumns({
		records,
		setShowEditModal,
		setEditRowModal,
	});

	return (
		<HelmetLayout titleProps={"Email Templates"}>
			{showEditModal && (
				<EmailTemplate
					setShowEditModal={setShowEditModal}
					editRowModal={editRowModal}
					setEditRowModal={setEditRowModal}
					handleSendEmail={handleSendEmail}
					showEditModal={showEditModal}
					editorRef={editorRef}
					getEmailTemplates={filterEmailTemplates}
				/>
			)}
			<div className="cms-page pt-0">
				<SingleSearchFilter setData={setData} unfilteredData={unfilteredData} filterColumn={["subject", "value", "language"]} />
				<MUIDataTable
					columns={emailTemplateTableColumns}
					data={data}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						textLabels: {
							body: {
								noMatch: loading ? <LoadingSpinner /> : "No data to display!",
							},
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: [10, 15, 50, 100],
						downloadOptions: { filename: "Email Templates.csv" },
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(emailTemplates);
