import React, { useEffect } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { useState } from "react";
import { InfoIconHelper, XCloseIcon } from "utils/icons";
import xIcon from "../../../assets/images/x-icon.svg";
import ApiServices from "utils/ApiServices";
import { toast } from "react-toastify";
import moment from "moment";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { apiUrls } from "utils/const.apiUrl";
import InformationPageHelper from "pages/Settings/InformationPageHelper";
import { setBackdrop } from "store/Backdrop/actions";
import { useDispatch } from "react-redux";
import { AffiliateLinkHelpInformation } from "../HelpPagesForSettings/HelpPages";
import { setAffiliateUsers } from "store/Users/actions";

import "./PromoLinks.scss";

const getSelectedPageType = defaultLandingPage => {
	const regexp = /sport\//;

	if (!defaultLandingPage) {
		return "";
	} else if (regexp.test(defaultLandingPage)) {
		return "sport-page";
	} else {
		return "pages";
	}
};

const PromoLinksModal = props => {
	const { editItem, title, handler, setNewData, data, handlerSave, extraFields = { type: "empty_row" } } = props;
	const [loading, setLoading] = useState(false);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();

	const [promoLink, setPromoLink] = useState({
		id: editItem?.id,
		name: editItem?.name,
		slug: editItem?.slug,
		notes: editItem?.notes,
		onboard_date: editItem?.onboard_date ? moment(editItem?.onboard_date).format("DD MMM YYYY") : "",
		active: editItem?.active || 1,
		default_landing_page: editItem?.default_landing_page === "index" ? "/index" : editItem?.default_landing_page,
		selected_page_type: getSelectedPageType(editItem?.default_landing_page),
	});

	const [pagesList, setPagesList] = useState([]);
	const [sportPages, setSportPages] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);

	const getPagesList = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.menu_links, resolve, reject, true);
		})
			.then(response => {
				setPagesList(response?.data?.data);
				const default_landing_page = editItem?.default_landing_page === "index" ? "/index" : editItem?.default_landing_page;
				if (editItem) {
					setPromoLink(state => {
						return {
							...state,
							default_landing_page,
						};
					});
				}
			})
			.catch(e => {
				console.log("Error", e?.message);
			});
	};

	const getSportPagesList = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_list, resolve, reject, true);
		})
			.then(response => {
				setSportPages(response?.data);
				if (editItem) {
					setPromoLink(state => {
						return {
							...state,
							selected_page_type: getSelectedPageType(editItem?.default_landing_page),
						};
					});
				}
			})
			.catch(e => {
				console.log("Error", e?.message);
			});
	};

	const getDropdownOptions = selectedPageType => {
		if (selectedPageType === "pages") {
			const filteredPagesList = pagesList.filter(item => item.published === 1);
			return filteredPagesList.map(item => {
				return {
					label: item.link_name,
					value: item.link,
					id: item.link,
				};
			});
		} else if (selectedPageType === "sport-page") {
			const filteredSportPagesList = sportPages.filter(item => item.active === 1);

			return filteredSportPagesList.map(item => {
				return {
					label: item.name,
					value: `/sport/${item.slug}`,
					id: `/sport/${item.slug}`,
				};
			});
		} else {
			return [];
		}
	};

	const selectionTypePage = [
		{ label: "Sport pages", value: "sport-page", id: "sport-page" },
		{ label: "Pages", value: "pages", id: "pages" },
	];

	const userFields = [
		extraFields,
		{ type: "input", name: "Name", fieldName: "name", required: true, textUpperCase: true },
		{
			type: "input",
			name: "Slug",
			fieldName: "slug",
			required: false,
			textUpperCase: true,
			copyIcon: true,
			pattern: /[^a-zA-Z0-9_-]/,
		},
		{ type: "input", name: "Notes", fieldName: "notes", inputClassName: "affiliateLargeInput", required: true },
		{ type: "date", name: "Start Date", fieldName: "onboard_date", required: true, defaultDate: true },
		{
			type: "dropdown",
			name: "Default Landing Page Type",
			fieldName: "selected_page_type",
			required: true,
			value: promoLink.selected_page_type || "pages",
			options: selectionTypePage,
		},
		{
			type: "dropdown",
			name: "Default Landing Page",
			fieldName: "default_landing_page",
			required: true,
			value: promoLink.default_landing_page,
			options: getDropdownOptions(promoLink.selected_page_type),
		},
	];

	useEffect(() => {
		if (editItem) {
			setPromoLink({
				id: editItem?.id,
				name: editItem?.name,
				slug: editItem?.slug,
				notes: editItem?.notes,
				onboard_date: editItem?.onboard_date ? moment(editItem?.onboard_date).format("DD MMM YYYY") : "",
				active: editItem?.active || 1,
				default_landing_page: editItem?.default_landing_page || "/index",
				selected_page_type: editItem?.selected_page_type,
				...editItem,
			});
		}
	}, [editItem]);

	useEffect(() => {
		if (editItem?.select_slug) setDataChanged(true);
	}, [editItem?.select_slug]);

	useEffect(() => {
		getPagesList();
		getSportPagesList();
	}, []);

	const addAffiliateUsers = () => {
		if (handlerSave) {
			handlerSave(promoLink);
		} else {
			if (/[^a-zA-Z0-9_-]/.test(promoLink.slug)) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Highlighted field are not alphanumeric</p>
					</>,
					{ className: "custom-toast" }
				);

				return;
			}

			let payload = {
				id: promoLink?.id || "",
				name: promoLink?.name,
				slug: promoLink?.slug,
				notes: promoLink?.notes,
				onboard_date: promoLink?.onboard_date ? moment(promoLink?.onboard_date).format("YYYY-MM-DD HH:mm:ss") : "",
				active: promoLink?.active,
				default_landing_page: promoLink?.default_landing_page,
			};
			setLoading(true);
			new Promise((resolve, reject) => {
				ApiServices.post(apiUrls.affiliate_users_add, resolve, reject, payload, true, userFields);
			})
				.then(response => {
					setDataChanged(false);
					let newData = response?.data?.data;
					if (editItem) {
						let updatedData = [...data];
						let index = updatedData.findIndex(item => item.id === editItem?.id);
						updatedData[index] = payload;
						setNewData(updatedData);
					} else {
						setNewData(newData);
					}
					dispatch(setAffiliateUsers([]));
					handler();
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};
	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	const dependecies = { select_slug: editItem?.select_slug };
	return (
		<>
			<div className="promotion-container">
				<div className="promotion-header">
					<span>{title}</span>
					<div>
						<XCloseIcon onClick={handler} className={"xclose-icon"} />
					</div>
				</div>
				<div className="promotion-body">
					<TopBarComponent saveHandler={addAffiliateUsers} loading={loading} dataChanged={dataChanged} onClose={handler} />
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs">
							<AllComponentsLoop fields={userFields} data={promoLink} setData={setPromoLink} setDataChanged={setDataChanged} dependecies={dependecies} />
						</div>
						<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left"></div>
					</div>
				</div>
				{!editItem && <InfoIconHelper onClick={infoIconHandler} />}
			</div>

			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<AffiliateLinkHelpInformation />
				</InformationPageHelper>
			)}
		</>
	);
};

export default PromoLinksModal;
