import React, { useEffect } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { useState } from "react";
import { LightningBoltIcon, XCloseIcon } from "utils/icons";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { useLocation } from "react-router-dom";
import { decimalToFractional, getLadderBoostedPriceV2 } from "@swiftyglobal/swifty-shared/dist/utils";

export const EditMarketMatchSelection = ({
	marketMatchColumnsData,
	setMarketMatchColumnsData,
	market_id,
	ladders,
	setEditMarketSelections,
	editMarketSelectionData,
	setEditMarketSelectionData,
	sportSlug,
	isRaceWinner,
}) => {
	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [fields, setfields] = useState([]);
	const location = useLocation();
	const user = JSON.parse(localStorage.getItem("user"));

	const changeFractionalValue = value => {
		const option = ladders?.find(item => item?.in_fraction == value);
		setEditMarketSelectionData({ ...editMarketSelectionData, decimal: option ? option.in_decimal : "", fractional: value });
		setDataChanged(true);
	};

	const changeDecimalValue = value => {
		const option = ladders?.find(item => item?.in_decimal == value);
		setEditMarketSelectionData({ ...editMarketSelectionData, fractional: option ? option.in_fraction : "", decimal: value });
		setDataChanged(true);
	};

	useEffect(() => {
		let newFields = [];
		if (isRaceWinner) {
			newFields = [
				{ type: "input", name: "Selection name", fieldName: "selection_name" },
				{ type: "input", name: "Feed Price", fieldName: "feed_price_display", disabled: true, readOnly: true },
				{
					type: "dropdown",
					name: "Price Boost",
					fieldName: "price_boost_id",
					optionsType: "price_boost",
					disabled: editMarketSelectionData.manual_price || !editMarketSelectionData?.feed_price_decimal,
					handleChangeDropdown: handlePriceBoostChange,
					customIcon: <LightningBoltIcon />,
					bindValue: editMarketSelectionData.manual_price ? "-" : editMarketSelectionData.price_boost?.label,
				},
			];

			!editMarketSelectionData.manual_price &&
				editMarketSelectionData.price_boost?.id &&
				newFields.push({
					type: "input",
					name: "  ",
					fieldName: "display_price",
					readOnly: true,
				});

			newFields.push({ type: "switch", name: "Manual Price", fieldName: "manual_price", disabled: false });
			if (editMarketSelectionData.manual_price) {
				newFields.push(
					{
						type: "dropdown",
						name: "Fractional",
						fieldName: "fractional",
						handleChangeDropdown: e => changeFractionalValue(e.id),
						options: ladders.map(({ in_fraction }) => ({
							label: in_fraction,
							id: in_fraction,
						})),
					},
					{
						type: "dropdown",
						name: "Decimal",
						fieldName: "decimal",
						handleChangeDropdown: e => changeDecimalValue(e.id),
						options: ladders.map(({ in_decimal }) => ({
							label: in_decimal,
							id: in_decimal,
						})),
					}
				);
			}
		}

		newFields.push(
			{ type: "switch", name: "Telebet", fieldName: "active", disabled: false },
			{ type: "switch", name: "Display", fieldName: "display", disabled: false }
		);

		setfields(newFields);
	}, [editMarketSelectionData, isRaceWinner]);

	useEffect(() => {
		if (editMarketSelectionData.manual_price) {
			setEditMarketSelectionData({
				...editMarketSelectionData,
				price_boost_id: "-1",
			});
		} else {
			setEditMarketSelectionData({
				...editMarketSelectionData,
				price_boost_id: editMarketSelectionData.initial_price_boost_id,
				price_boost: editMarketSelectionData.initial_price_boost,
			});
		}
	}, [editMarketSelectionData.manual_price]);

	const handlePriceBoostChange = e => {
		if (e.id == "-1") {
			setEditMarketSelectionData({
				...editMarketSelectionData,
				price_boost: "",
				price_boost_id: e.id,
			});
			setDataChanged(true);
		} else if (e) {
			const ladderIndex = e.id.slice(-1);
			const { feed_price, display_fractional } = editMarketSelectionData;
			const laddersDecimals = ladders
				.map(({ in_decimal, in_fraction }) => ({ in_decimal, in_fraction }))
				.filter(number => +number.in_decimal > feed_price)
				.sort((a, b) => Number(a.in_decimal) - Number(b.in_decimal));

			setDataChanged(true);

			const localLaddersDecimalPrice = getLadderBoostedPriceV2(e.id, feed_price);
			const fraction = decimalToFractional(localLaddersDecimalPrice);

			const localLadderPriceBoost = localLaddersDecimalPrice ? { in_decimal: localLaddersDecimalPrice.toFixed(2), in_fraction: fraction } : null;

			const ladderPrice = laddersDecimals[ladderIndex - 1] || laddersDecimals[laddersDecimals.length - 1] || null;

			//check if there are any ladders bigger than you feed price number then
			//get the item that is for ${ladderIndex} upper in the array or if that dosent exist get the last element of that array.
			const newPriceBoost = localLadderPriceBoost || ladderPrice || { in_decimal: feed_price, in_fraction: display_fractional };

			setEditMarketSelectionData({
				...editMarketSelectionData,
				display_price: user.odds_display == "fractional" ? newPriceBoost.in_fraction : newPriceBoost.in_decimal,
				price_boost: e,
				price_boost_id: e?.id,
			});
		} else {
			setEditMarketSelectionData({
				...editMarketSelectionData,
				display_price: editMarketSelectionData.display_price,
				price_boost: null,
				price_boost_id: "",
			});
		}
	};

	const onSave = () => {
		const { pathname } = location;
		const match_id = pathname.split("/")[2];
		const { selection_id, price_boost_id, active, display, decimal, manual_price, fractional, selection_name } = editMarketSelectionData;

		const payload = {
			selection_name,
			selection_id,
			market_id,
			match_id,
			active,
			display,
			sport_slug: sportSlug,
			price_boost: price_boost_id,
			is_manual: manual_price,
		};

		if (manual_price == 1) {
			payload.price = decimal;
			payload.fractional = fractional;
		}
		setLoading(true);
		// if (price_boost?.id && manual_price !== 1) payload.price_boost = price_boost?.id;

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.edit_market_price_boost, resolve, reject, payload, true);
		})
			.then(response => {
				setDataChanged(false);

				const data = response.data[0];
				setEditMarketSelections(false);
				setMarketMatchColumnsData(marketMatchColumnsData.map(selection => (selection.selection_id === selection_id ? data : selection)));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="promotion-container promotion-container-custom">
			<div className="promotion-header">
				<span>Selections</span>
				<XCloseIcon className={"xclose-icon"} onClick={() => setEditMarketSelections(false)} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={onSave} dataChanged={dataChanged} loading={loading} onClose={() => setEditMarketSelections(false)} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						<AllComponentsLoop
							fields={fields}
							data={editMarketSelectionData}
							// dependecies={dependecies}
							setData={setEditMarketSelectionData}
							setDataChanged={setDataChanged}
						/>
					</div>
					<div className="promotion-right col-12 col-lg-6"></div>
				</div>
			</div>
		</div>
	);
};
