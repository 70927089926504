import React from "react";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";
import { toast } from "react-toastify";
import xIcon from "../assets/images/x-icon.svg";

const saveImages = async (files, type, checked, accept = "image") => {
	const formData = new FormData();
	const firstFile = files && files[0];

	[...files].forEach(file => {
		formData.append("files", file);
	});
	const allWantedFormat = [...files].every(({ type }) => type?.startsWith(accept));
	if (!allWantedFormat) {
		if (accept == "image/png") {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please upload a png file</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		if (accept == "image/svg+xml") {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please upload a svg file</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		toast.error(
			<>
				<img src={xIcon} alt="Error" />
				<p className="toast-icon-manual-message">Please upload a image file</p>
			</>,
			{ className: "custom-toast" }
		);
		return;
	}

	formData.append("type", type);
	formData.append("optimize", checked);

	const config = {
		"Content-Type": "multipart/form-data",
	};

	if (!firstFile) {
		return;
	}

	return new Promise((resolve, reject) => {
		ApiServices.post(apiUrls.add_multiple_image, resolve, reject, formData, true, [], false, config);
	})
		.then(response => {
			if (response.success) {
				return response;
			}
		})
		.catch(() => {
			return null;
		});
};

export default saveImages;
