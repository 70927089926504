export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const CMS_LOGIN_USER = "CMS_LOGIN_USER";

export const CMS_SET_BET_HISTORY_EMAIL = "CMS_SET_BET_HISTORY_EMAIL";

export const GET_GS_DATA = "GET_GS_DATA";

export const CMS_SET_TRASACTION_HISTORY_EMAIL = "CMS_SET_TRASACTION_HISTORY_EMAIL";

export const CMS_SEARCH_FILTER_DATA = "CMS_SEARCH_FILTER_DATA";

export const SET_IS_LOGIN_PAGE = "SET_IS_LOGIN_PAGE";

export const CMS_SET_TRANSACTION_HISTORY_DATE_FROM = "CMS_SET_TRANSACTION_HISTORY_DATE_FROM";

export const CMS_SET_TRANSACTION_HISTORY_DATE_TO = "CMS_SET_TRANSACTION_HISTORY_DATE_TO";

export const ACTIVE_PAGE = "ACTIVE_PAGE";

export const SET_COUNTRY = "SET_COUNTRY";

export const SET_LANGUAGE = "SET_LANGUAGE";
