import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { setBetTickerData } from "store/BetTicker/actions";
import { setBetNotificationsList } from "store/NotificationsListBets/actions";
import { getGSData, getUser, setLanguage } from "./store/actions";
import { ToastContainer } from "react-toastify";
import { io } from "socket.io-client";
import { apiUrls } from "utils/const.apiUrl";
import { AppContext } from "./context/AppContext";
import { userRoutes, authRoutes } from "./routes/allRoutes";
import { signOutAndClearStorage } from "helpers/api_helper";
import { getLogo } from "./utils/global";
import Loader from "components/Loader/Loader";
import ApiServices from "utils/ApiServices";
import BetReferralModal from "./pages/BetTicker/betReferralModal/betReferralModal2";
import Backdrop from "./components/Backdrop/Backdrop";
import NonAuthLayout from "./components/NonAuthLayout";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import HorizontalLayout from "./components/HorizontalLayout";
import VerticalLayout from "./components/VerticalLayout";
import getRegionsData from "services/getRegionsData";
import getSportsData from "services/getSportsData";
import getCountriesData from "services/getCountriesData";
import InfoModal from "pages/Authentication/InfoModal";
import moment from "moment";
import Pages404 from "pages/Extra Pages/pages-404";
import PermissionDenied from "pages/Extra Pages/PermissionDenied";
import { v4 as uuidv4 } from "uuid";

import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_blue.css";
import "./assets/scss/theme.scss";
import "./pages/Users/Telebet/telebet-style.css";
import "./assets/css/style.css";
import "./pages/Betting/Bets/Bets.css";
import "./components/GeneralComponents/GeneralComponents.css";
import "./pages/Tables/datatables.scss";
import "./assets/css/mixins.scss";
import "./assets/css/main.scss";
import "./assets/css/MuiTable.scss";

const loginPaths = [
	"/login",
	"/forgot-password",
	"/reset_password",
	"/change_password",
	"/new_password",
	"/sign_in_first",
	"/mfa_signin",
	"/blocked",
	"/two_fa",
	"/password_changed",
	"/restricted",
];

// 24 hours in minutes
const EXPIRE_MINUTES = 24 * 60;

function App(props) {
	const [isLoginPage, setIsLoginPage] = useState(false);
	const user = useSelector(state => state.Login.user);
	const [socketCommunication, setSocketCommunication] = useState(null);
	// const [gamingSocket, setGamingSocket] = useState(null);

	const betTicketList = useSelector(state => state.BetTickerData.betTickerList);
	const betsList = useSelector(state => state.BetNotificationsList.listBetsNotifications);
	const betReferralModalOpen = useSelector(state => state.BetReferralModal.isOpen);

	const prevUser = JSON.parse(localStorage.getItem("prevUser"));
	const dateAndTime = useRef(prevUser?.betTickerDateAndTime || "");

	const openInfoModal = useSelector(state => state.Layout.openInfoModal);
	const [isTabFocused, setIsTabFocused] = useState(true);

	const access_token = localStorage.getItem("access_token");
	const device_id = localStorage.getItem("device_id");

	let stopCallingApi = ["customer_service", "auditor", "operations", "affiliate"];

	function getLayout() {
		let layoutCls = VerticalLayout;
		switch (props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	}

	const Layout = getLayout();
	const dispatch = useDispatch();
	const checkForInactivity = () => {
		// Get Expire Time from Local Storage
		const expireTimet = localStorage.getItem("expireSessionTime");
		const newUser = { ...user, betTickerDateAndTime: dateAndTime.current };

		// If expire time is earlier than now, log out,
		if (expireTimet < Date.now()) {
			signOutAndClearStorage({ wait: false, keepLocalStorage: ["prevUser"] });
			localStorage.setItem("prevUser", JSON.stringify(newUser));
		}
	};

	// Function to update expire time
	const updateExpiretime = () => {
		const ecx = Date.now() + EXPIRE_MINUTES * 60000;

		// Set  Expire time to local storage
		localStorage.setItem("expireSessionTime", ecx);
	};

	const getLanguages = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_languages, resolve, reject, true, stopCallingApi);
		}).then(response => {
			const { data } = response;
			const inUseLanguages = [];
			data.forEach(item => {
				if (item?.in_use) {
					const obj = {};
					if (item?.code2 !== "EN") {
						obj.name = `${item?.language_name} (${item?.code2})`;
						obj.for = item?.code2 ? item?.code2.toLowerCase() : "";
						inUseLanguages.push(obj);
					}
				}
			});
			dispatch(setLanguage(inUseLanguages));
		});
	};

	useEffect(() => {
		// TODO Clearn all session storage
		sessionStorage.removeItem("_currencies");
		sessionStorage.removeItem("_countries");

		if (loginPaths.includes(window.location.pathname)) {
			setIsLoginPage(true);
		} else {
			// Set initial logo
			getLogo(dispatch);
			dispatch(getUser());
			getLanguages();
			getGeneralData();
			setIsLoginPage(false);

			if (!user) signOutAndClearStorage({ wait: false, keepLocalStorage: ["prevUser"] });
		}
		// Check for inactivity every 5 seconds

		if (isLoginPage === false) {
			const interval = setInterval(() => {
				checkForInactivity();
			}, 5000);

			// Clear interval on unmount
			return () => {
				clearInterval(interval);
			};
		}
	}, [isLoginPage, user]);

	const getGeneralData = async () => {
		const gsData = {};

		const [sports, regions, countries] = await Promise.all([getSportsData(), getRegionsData(), getCountriesData()]);

		gsData.sports = sports;
		gsData.regions = regions;
		gsData.countries = countries;

		dispatch(getGSData(gsData));
		localStorage.setItem("gsData", JSON.stringify(gsData));
	};

	useEffect(() => {
		// Set initial expire time
		updateExpiretime();

		// Set event listener
		window.addEventListener("click", updateExpiretime);
		window.addEventListener("keypress", updateExpiretime);
		window.addEventListener("scroll", updateExpiretime);
		window.addEventListener("mousemove", updateExpiretime);

		// clean up
		return () => {
			window.removeEventListener("click", updateExpiretime);
			window.removeEventListener("keypress", updateExpiretime);
			window.removeEventListener("scroll", updateExpiretime);
			window.removeEventListener("mousemove", updateExpiretime);
		};
	}, []);

	useEffect(() => {
		let socketCommunication = null;
		// let gamingSocket = null;

		if (access_token && device_id) {
			socketCommunication = io(process.env.REACT_APP_COMUNICATION_SOCKET, {
				transports: ["websocket"],
				auth: {
					token: access_token,
					"device-id": device_id,
				},
				query: {
					cms: true,
				},
			});

			socketCommunication.on("connect", () => {
				setSocketCommunication(socketCommunication);
			});

			socketCommunication.on("disconnect", () => {
				setSocketCommunication(socketCommunication);
			});

			// 	gamingSocket = io(process.env.REACT_APP_GAMING_BACKEND_URL, {
			// 		transports: ["websocket"],
			// 		auth: {
			// 			token: access_token,
			// 			"device-id": device_id,
			// 		},
			// 		query: {
			// 			cms: true,
			// 		},
			// 	});

			// 	gamingSocket.on("connect", () => {
			// 		setGamingSocket(gamingSocket);
			// 	});

			// 	gamingSocket.on("disconnect", () => {
			// 		setGamingSocket(gamingSocket);
			// 	});
		}

		return () => {
			if (access_token && device_id) {
				socketCommunication.off("connect");
				socketCommunication.off("bet_referral_message");
				socketCommunication.off("new_chat_message");
				// gamingSocket.off("connect");
			}
		};
	}, [access_token, device_id]);

	useEffect(() => {
		if (isTabFocused && socketCommunication && !socketCommunication?.connected) {
			socketCommunication?.connect();

			getBetsData(true);
		}
	}, [socketCommunication?.connected, isTabFocused]);

	const handleVisibilityChange = () => {
		if (document.hidden) {
			setIsTabFocused(false);

			if (!loginPaths.includes(window.location.pathname)) {
				const date = moment.utc().format("YYYY-MM-DD HH:mm:ss");
				dateAndTime.current = date;
			}
		} else setIsTabFocused(true);
	};

	useEffect(() => {
		window.addEventListener("visibilitychange", handleVisibilityChange);

		if (!device_id) localStorage.setItem("device_id", uuidv4());

		return () => {
			window.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		if (user?.id && !dateAndTime.current) {
			getBetsData();
		}
	}, [user?.id]);

	useEffect(() => {
		if (prevUser?.id === user?.id) {
			getBetsData(true);
		}
	}, [prevUser]);

	const findOriginBet = id => {
		return betTicketList.find(item => item.id === id);
	};

	const getBetsData = removeLocalStorage => {
		if (removeLocalStorage) localStorage.removeItem("prevUser");

		let url = apiUrls.GET_BET_TICKER_LIST;

		if (dateAndTime.current) {
			const now = moment.utc();
			const prev = moment.utc(dateAndTime.current);
			const duration = moment.duration(now.diff(prev));
			const minutes = duration.asMinutes();

			// add 5 minutes to the check
			if (minutes <= EXPIRE_MINUTES + 5) url = `${apiUrls.GET_BET_TICKER_LIST}?dateFrom=${dateAndTime.current}`;
		}

		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true, stopCallingApi);
		}).then(response => {
			const { data } = response;
			dispatch(setBetTickerData(data));

			if (betsList.length) {
				const newBetsList = betsList.filter(item => findOriginBet(item?.notification?.bet_referral_id));
				dispatch(setBetNotificationsList(newBetsList));
			}
		});

		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_BET_TICKET_NOTIFICATIONS, resolve, reject, true, stopCallingApi);
		}).then(response => {
			const { data } = response;

			dispatch(setBetNotificationsList(data));
		});
	};

	return (
		<AppContext.Provider
			value={{
				user: user || null,
				setIsLoginPage,
				socketCommunication,
				// gamingSocket,
			}}
		>
			<Backdrop />
			{betReferralModalOpen && <BetReferralModal />}
			{openInfoModal && <InfoModal />}
			<>
				<Router>
					{!isLoginPage ? (
						user ? (
							<Switch>
								<Route path="/premission_denied" component={PermissionDenied} />

								{authRoutes.map((route, idx) => (
									<Authmiddleware path={route.path} layout={NonAuthLayout} component={route.component} key={idx} isAuthProtected={false} />
								))}

								{userRoutes.map((route, idx) => (
									<Authmiddleware
										path={route.path}
										layout={Layout}
										component={route.component}
										key={idx}
										isAuthProtected={false}
										user={user}
										roleToHide={route.roleToHide}
										exact
									/>
								))}

								<Route path="/pages-404" component={Pages404} />

								<Redirect to="/pages-404" />
							</Switch>
						) : (
							<Loader />
						)
					) : (
						<Switch>
							{authRoutes.map((route, idx) => (
								<Authmiddleware path={route.path} layout={NonAuthLayout} component={route.component} key={idx} isAuthProtected={false} />
							))}

							{userRoutes.map((route, idx) => (
								<Authmiddleware path={route.path} layout={Layout} user={user} component={route.component} key={idx} isAuthProtected={false} exact />
							))}
						</Switch>
					)}
				</Router>
			</>
			<>
				<ToastContainer
					position="bottom-right"
					autoClose={3000}
					newestOnTop={false}
					closeOnClick
					theme="colored"
					hideProgressBar
					icon={false}
					closeButton={false}
				/>
			</>
		</AppContext.Provider>
	);
}

App.propTypes = {
	layout: PropTypes.any,
};

const mapStateToProps = state => ({
	layout: state.Layout,
});

export default connect(mapStateToProps, null)(App);
