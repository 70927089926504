import React from "react";
import axios from "axios";
import { CheckToken } from "hooks/CheckToken";
import { toast } from "react-toastify";
import xIcon from "../assets/images/x-icon.svg";

//apply base url for axios
const API_URL = "";

const axiosApi = axios.create({
	baseURL: API_URL,
});

axiosApi.interceptors.response.use(
	response => response,
	error => Promise.reject(error)
);

export async function put(url, data, config = {}) {
	return axiosApi.put(url, { ...data }, { ...config }).then(response => response.data);
}

export async function getRequest(url, config, auth) {
	try {
		if (!url) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please provide url</p>
				</>,
				{ className: "custom-toast" }
			);
			return { status: false };
		}
		config.headers = getHeaders(auth);
		const response = await axiosApi.get(url, config);
		return { status: true, response };
	} catch (error) {
		if (error.response.status == 401) {
			let resCheckToken = await CheckToken();
			if (resCheckToken) {
				// Call the same function again
				return getRequest(url, config, auth);
			} else {
				window.location.href = "/login";
				return true;
			}
		} else if (error.response.status == 400) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">{error?.response?.data?.message}</p>
				</>,
				{ className: "custom-toast" }
			);
			return { status: false };
		}
		return { status: false, error: error.response.data };
	}
}

export function getHeaders(auth) {
	let headers = {};
	if (auth) {
		const access_token = localStorage.getItem("access_token");
		headers.Authorization = `Bearer ${access_token}`;
	}
	return headers;
}

export const signOutAndClearStorage = arg => {
	let keepLocalStorage = arg?.keepLocalStorage || [];
	keepLocalStorage = [...keepLocalStorage, "device_id"];

	sessionStorage.clear();

	const keysToRemove = Object.keys(localStorage).filter(key => !keepLocalStorage.includes(key));
	keysToRemove.forEach(key => localStorage.removeItem(key));

	if (arg?.wait) {
		setTimeout(function () {
			window.location.href = "/login";
		}, 1500);
	} else {
		window.location.href = "/login";
	}
};

export const checkForCheckSum = response => {
	if (response?.status === 210) {
		window.location.href = "/restricted";
		return true;
	}
	return false;
};
