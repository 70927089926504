import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { CancelButton, SaveButton } from "components/GeneralComponents/AddButton";
import { GeneralTextArea } from "components/GeneralComponents/CustomInputs";
import { toCapitalCase } from "utils/global";

export const DeleteDetailsModal = ({ object }) => {
	const modalData = object?.modalData;
	const setModalData = object?.setModalData;
	const setShowModal = object?.setShowModal;
	const clickModalEvent = object?.clickModalEvent;
	const description = object?.description || "";
	const title = object?.title || "";
	const className = object?.className || "";
	const hasTextArea = object?.hasTextArea || false;
	const disabled = hasTextArea ? (modalData?.reason ? false : true) : false;

	return (
		<SweetAlert title={title} onConfirm={() => {}} showConfirm={false} titleStyle={{ fontSize: "12px" }} customClass={className}>
			<div className="position-relative d-flex flex-column align-items-center">
				<div>
					<p className="delete_user_name bottom_description_modal m-0">{description}</p>

					{hasTextArea && (
						<GeneralTextArea
							object={{
								fieldName: "reason",
								value: modalData.reason,
								defaultPlaceholder: `Enter the reason for removing the ${toCapitalCase(modalData.type)}`,
								textareaClassName: "general_input largeInput",
							}}
							handleChange={e => setModalData({ ...modalData, reason: e })}
						/>
					)}
				</div>
				<div className="mt-3">
					<CancelButton onClick={() => setShowModal(false)} name="No" />
					<SaveButton onClick={clickModalEvent} name="Yes" disabled={disabled} classname={`general_button_white `} />
				</div>
			</div>
		</SweetAlert>
	);
};
