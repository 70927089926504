import React, { useEffect, useState, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TablePagination } from "@material-ui/core";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import { TableContainer } from "@mui/material";
// import { CSVLink } from "react-csv";
// import { DownloadIcone } from "utils/icons";

import "./Table.scss";

const initialRowPerPage = 100;
const initialrowsPerPageOptions = [10, 20, 50, 100];

// TODO: refactor this component and make it more customizable

const DataTable = ({
	data = [],
	columns,
	setData,
	isLoading,
	pagination = true,
	rowsPerPage = initialRowPerPage,
	rowsPerPageOptions = initialrowsPerPageOptions,
	orderRows = false,
	sort = true,
	options,
}) => {
	const tableBody = useRef(null);
	const [pg, setpg] = useState(0);
	const [rpg, setrpg] = useState(rowsPerPage);

	const [sortColumn, setSortColumn] = useState(null);
	const [sortOrder, setSortOrder] = useState("asc");

	useEffect(() => {
		if (!pagination) setrpg(data?.length);
	}, [data?.length]);

	const handleDragEnd = result => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const reorderedData = [...data];
		const [movedRow] = reorderedData.splice(result.source.index, 1);
		reorderedData.splice(result.destination.index, 0, movedRow);

		setData(reorderedData);
	};

	const renderRowBody = (item, column, other) => {
		if (column?.options?.customBodyRender) {
			return column?.options?.customBodyRender(item[column.name], item, other);
		}
		return item[column.name];
	};

	const handleSort = (columnId, column) => {
		const { name: key, customSort } = column;
		let dir;
		if (sortColumn === columnId) {
			dir = sortOrder === "asc" ? "desc" : "asc";
			// If the same column is clicked, toggle the sort order
			setSortOrder(dir);
		} else {
			// If a different column is clicked, set it as the new sorting column and default to ascending order
			dir = "acs";
			setSortColumn(columnId);
			setSortOrder(dir);
		}

		const sortedData = [...data].sort((a, b) => {
			if (dir === "asc") {
				if (customSort) return customSort(a, b);
				return a[key].localeCompare(b[key], undefined, { sensitivity: "base" });
			} else {
				if (customSort) return customSort(b, a);
				return b[key].localeCompare(a[key], undefined, { sensitivity: "base" });
			}
		});

		setData(sortedData);
	};

	const getStyles = (column, type) => {
		if (type == "head") return column?.options?.setCellHeaderProps ? column?.options?.setCellHeaderProps().style : {};
		if (type == "body") return column?.options?.setCellProps ? column?.options?.setCellProps().style : {};
	};

	const onRowClick = item => {
		const { onRowClick } = options;
		onRowClick(item);
	};

	return (
		<div className="dataTable-container">
			<TableContainer>
				{options?.customToolbar && <div className="table-actions">{options.customToolbar()}</div>}

				<div className="table-container">
					<Table>
						<TableHead>
							<TableRow>
								{columns?.map((column, index) => (
									<TableCell
										key={index}
										align="left"
										style={getStyles(column, "head")}
										className={column?.options?.setCellHeaderProps && column?.options?.setCellHeaderProps()?.className}
									>
										{sort ? (
											<TableSortLabel
												active={sortColumn === index}
												direction={sortColumn === index ? sortOrder : "asc"}
												onClick={() => handleSort(index, column)}
											>
												{column.label}
											</TableSortLabel>
										) : (
											<span>{column.label}</span>
										)}
									</TableCell>
								))}
							</TableRow>
						</TableHead>

						{isLoading && (
							<TableBody ref={tableBody}>
								<TableRow>
									<TableCell colSpan={columns.length}>
										<LoadingSpinner />
									</TableCell>
								</TableRow>
							</TableBody>
						)}

						{!isLoading && data.length == 0 && (
							<TableBody ref={tableBody}>
								<TableRow>
									<TableCell colSpan={columns.length}>
										<div className="no-data"> No data to display!</div>
									</TableCell>
								</TableRow>
							</TableBody>
						)}

						{!isLoading && orderRows && (
							<DragDropContext onDragEnd={handleDragEnd}>
								<Droppable droppableId="droppable" direction="vertical">
									{droppableProvided => (
										<TableBody
											ref={e => {
												droppableProvided?.innerRef(e);
												tableBody.current = e;
											}}
											{...droppableProvided?.droppableProps}
											className="tableRowCustomized"
										>
											{data?.slice(pg * rpg, pg * rpg + rpg).map((item, index) => (
												<Draggable key={index} draggableId={index.toString()} index={index} mode="virtual">
													{(draggableProvided, snapshot) => {
														return (
															<TableRow
																ref={draggableProvided.innerRef}
																className={`${snapshot.isDragging ? "dragging" : ""}`}
																{...draggableProvided.draggableProps}
																style={{
																	...draggableProvided.draggableProps.style,
																	width: `${snapshot.isDragging ? tableBody.current?.offsetWidth + "px" : "100%"}`,
																	cursor: options?.onRowClick ? "pointer" : "default",
																}}
																onClick={() => options?.onRowClick && onRowClick(item)}
															>
																{columns?.map((column, i) => (
																	<TableCell key={index + "-" + i} style={getStyles(column, "body")}>
																		<div>
																			{renderRowBody(item, column, {
																				dragHandleProps: draggableProvided.dragHandleProps,
																				index: index,
																				isDragging: snapshot.isDragging,
																			})}
																		</div>
																	</TableCell>
																))}
															</TableRow>
														);
													}}
												</Draggable>
											))}
										</TableBody>
									)}
								</Droppable>
							</DragDropContext>
						)}

						{!isLoading && !orderRows && (
							<TableBody ref={tableBody}>
								{data?.slice(pg * rpg, pg * rpg + rpg).map((item, index) => (
									<TableRow key={item?.id || index} className={options?.setRowProps(index, index, index)?.className}>
										{columns?.map((column, i) => (
											<TableCell key={index + "-" + i} style={getStyles(column, "body")}>
												<div>
													{renderRowBody(item, column, {
														index,
													})}
												</div>
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						)}
					</Table>
				</div>
				<div className="table-footer">
					{pagination && (
						<TablePagination
							rowsPerPageOptions={rowsPerPageOptions}
							component="div"
							count={data?.length}
							rowsPerPage={rpg}
							page={pg}
							onPageChange={(event, newpage) => setpg(newpage)}
							onRowsPerPageChange={event => {
								setrpg(parseInt(event.target.value, 10));
								setpg(0);
							}}
						/>
					)}
					{/* {options?.showButtons && (
						<div className={`d-flex gap-4 pe-3 justify-content-end ${pagination ? "" : "w-100"}`}>
							<CSVLink data={data} filename={"data.csv"} className="table-actions-icon">
								<DownloadIcone />
							</CSVLink>
						</div>
					)} */}
				</div>
			</TableContainer>
		</div>
	);
};

export default DataTable;
