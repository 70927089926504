import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { useDispatch } from "react-redux";
import { setBackdrop } from "store/Backdrop/actions";
import { InfoIconHelper } from "utils/icons";
import InformationPageHelper from "../InformationPageHelper";
import { BetOptionHelpInformation } from "../HelpPagesForSettings/HelpPages";

function BetOptions() {
	const [data, setData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [fields, setFields] = useState([]);
	const [unchagedData, setUnchangedData] = useState([]);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		let allFields = [
			{ type: "input", name: "Min Bet Amount", fieldName: "min_amount", numbersOnly: true },
			{ type: "input", name: "Max Bet Return", fieldName: "max_return", numbersOnly: true },
			{ type: "input", name: "Max Bet Payout", fieldName: "max_payout", numbersOnly: true },
			{ type: "input", name: "Min Odds Free Bet Price", fieldName: "free_bet_minimum_amount", numbersOnly: true },
			{ type: "input", name: "In-Play Delay (Sec.)", fieldName: "in_play_delay", numbersOnly: true },
			{ type: "date", name: "Early Price Start Time (UTC)", fieldName: "early_price_start_time", isTimeExist: true, timePicker: true },
			{ type: "input", name: "Max Folds", fieldName: "max_folds", numbersOnly: true },
			{ type: "switch", name: "Global Bet Referral", fieldName: "referral_enabled" },
			{ type: "switch", name: "Default Bet Referral (New Users)", fieldName: "default_bet_referral" },
			{ type: "input", name: "Bet Referral Auto Cancellation (Min.)", fieldName: "bet_referral_auto_cancellation", numbersOnly: true },
			{
				type: "input",
				name: "Bet Referral  User Auto Cancellation (Sec.)",
				fieldName: "bet_referral_offer_timeout",
				numbersOnly: true,
				placeholder: "150 seconds",
			},
			{ type: "switch", name: "Bet Referral Specific Times", fieldName: "bet_referral_time_enabled" },
		];
		if (data?.bet_referral_time_enabled == "1") {
			allFields.push(
				{ type: "date", name: "Bet Referral Start Time (UTC)", fieldName: "bet_referral_start_time", isTimeExist: true, timePicker: true, withUtc: true },
				{ type: "date", name: "Bet Referral End Time (UTC)", fieldName: "bet_referral_end_time", isTimeExist: true, timePicker: true, withUtc: true }
			);
		}
		setFields(allFields);
	}, [data?.bet_referral_time_enabled]);

	useEffect(() => {
		getBogSettings();
	}, []);

	//Get Bog options
	const getBogSettings = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.trader_options + "?type=bet-options", resolve, reject, true);
		})
			.then(response => {
				setDataChanged(false);
				let data = response?.data;
				setData(data);
				setUnchangedData(data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	//Edit Bet options
	const editBetOptions = () => {
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.trader_options_edit, resolve, reject, data, true);
		})
			.then(() => {})
			.catch(() => {
				setData(unchagedData);
			})
			.finally(() => {
				setDataChanged(false);
			});
	};

	const discardData = () => {
		setData(unchagedData);
		setDataChanged(false);
	};

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	return (
		<div>
			<TopBarComponent
				classname="special_top_component"
				saveHandler={editBetOptions}
				dataChanged={dataChanged}
				discardHandler={discardData}
				loading={isDataLoading}
			/>
			{isDataLoading ? (
				<LoadingSpinner className={"pt-5"} />
			) : (
				<div className="cms-page row">
					<div className="col-12 trader-form">
						<AllComponentsLoop fields={fields} data={data} setData={setData} setDataChanged={setDataChanged} />
					</div>
				</div>
			)}
			{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<BetOptionHelpInformation />
				</InformationPageHelper>
			)}
		</div>
	);
}

export default BetOptions;
