import React, { useState, useEffect } from "react";
import { AddIcon } from "utils/icons";
import AddNewRuleModal from "./addNewRuleModal";
import SingleRule from "./singleRule";
import { equalityOptions } from "./rules";

const rulesSections = [
	{
		label: "Conditions",
		id: "condition",
	},
	{
		label: "Bonus / Offer",
		id: "offer",
	},
	{
		label: "Bonus / Offer Criteria",
		id: "criteria",
	},
];

const ruleData = {
	rule_type: "",
	condition_type: "",
	condition: "",
	first_value_type: "",
	first_value: "",
	second_value_type: "",
	second_value: "",
	selected_type: "",
};

const formatRuleData = rule => {
	const multipleValues = typeof rule.selected_type == "object";

	const hasSecondConditionEquality = multipleValues ? rule.selected_type?.findIndex(type => type.selected_type == "equality") : -1;
	let second_type = rule.selected_type[1];

	if (multipleValues && rule.selected_type?.length > 2 && hasSecondConditionEquality == -1) {
		var third_type = rule.selected_type[2];
	}

	if (hasSecondConditionEquality !== -1) {
		second_type = rule.selected_type?.find(item => item?.index == "1");
		if (rule.selected_type?.length > 3) third_type = rule.selected_type?.find(item => item?.index == "2");
	}

	const valueTypes = multipleValues
		? {
				first_value_type: rule.selected_type[0].selected_type,
				...(!second_type?.optional ? { second_value_type: second_type?.selected_type } : {}),
				condition_second: hasSecondConditionEquality !== -1 ? rule.selected_type[hasSecondConditionEquality].equality : null,
				...(third_type && !third_type?.optional ? { third_value_type: third_type?.selected_type, third_value: "" } : {}),
		  }
		: {
				first_value_type: rule.selected_type,
		  };

	return {
		...ruleData,
		condition_type: rule.id,
		selected_type: rule.selected_type,
		rule_type: rule.type,
		condition: rule.equality || equalityOptions[0]?.id,
		...valueTypes,
	};
};

const BonusEngineRules = ({ rules, setRules, setDataChanged }) => {
	const [isAddModalOpen, setIsModalOpen] = useState({ open: false, id: "" });

	const addNewRule = rule => {
		if (rule) {
			setDataChanged(true);

			const data = formatRuleData(rule);
			setRules([...rules, data]);
		}
	};

	const changeConditionType = (condition_type, item) => {
		const formatedRule = formatRuleData(item);

		const updatedRules = rules.map(rule => (rule.condition_type == condition_type ? formatedRule : rule));
		setDataChanged(true);
		setRules(updatedRules);
	};

	const changeConditionValue = (condition_type, newValue) => {
		const updatedRules = rules.map(rule => (rule.condition_type == condition_type ? newValue : rule));
		setDataChanged(true);
		setRules(updatedRules);
	};

	const changeCondition = (condition_type, id, second_condition) => {
		const conditionId = second_condition ? "condition_second" : "condition";
		const updatedRules = rules.map(rule => (rule.condition_type == condition_type ? { ...rule, [conditionId]: id } : rule));
		setDataChanged(true);
		setRules(updatedRules);
	};

	const removeRule = condition_type => {
		const updatedRules = rules.filter(rule => rule.condition_type !== condition_type);
		setDataChanged(true);
		setRules(updatedRules);
	};

	useEffect(() => {
		console.log("rules", rules);
	}, [rules]);

	return (
		<div className="bonus-engine-rules">
			{isAddModalOpen.open && (
				<AddNewRuleModal
					addNewRule={addNewRule}
					closeModal={() => setIsModalOpen({ open: false, id: "" })}
					isAddModalOpen={isAddModalOpen}
					rulesSections={rulesSections}
					rules={rules}
				/>
			)}

			{rulesSections.map(({ label, id }, index) => (
				<div className="bonus-engine-rules-section" key={index}>
					<h3>{label}</h3>

					<div>
						{rules
							?.filter(({ rule_type }) => rule_type == id)
							.map((rule, i) => (
								<SingleRule
									key={index + "-" + i}
									rule={rule}
									changeConditionType={changeConditionType}
									changeCondition={changeCondition}
									changeConditionValue={changeConditionValue}
									removeRule={removeRule}
									rules={rules}
								/>
							))}
					</div>

					<div className="add-icon" onClick={() => setIsModalOpen({ open: true, id: id })}>
						<AddIcon />
					</div>
				</div>
			))}
		</div>
	);
};

export default BonusEngineRules;
