import React from "react";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { setBreadcrumbItems } from "../../../store/actions";
import { XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { toast } from "react-toastify";
import { GeneralDropdown, GeneralInput, SwitchInput } from "components/GeneralComponents/CustomInputs";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import xIcon from "../../../assets/images/x-icon.svg";
import { AvForm } from "availity-reactstrap-validation";

import "./PageContent.scss";

const editorApiUrl = process.env.REACT_APP_EDITOR_API_KEY;

const PageContent = props => {
	const [pageName, setPageName] = useState("");
	const [pageShowName, setPageShowName] = useState(1);
	const [pageContentLanguage, setPageContentLanguage] = useState("en");
	const [pageTitle, setPageTitle] = useState("");
	const [pageDescription, setPageDescription] = useState("");
	const [pageKey, setPageKey] = useState("");
	const [pageContent, setPageContent] = useState("");
	const [pageContentId, setPageContentId] = useState("");
	const [dataChanged, setDataChanged] = useState(false);
	const [languageOptions, setLanguageOptions] = useState([]);
	const [content, setContent] = useState();
	const [showSave, setShowSave] = useState(false);
	const [menuLinks, setMenuLinks] = useState([]);
	const [unChangedPageContent, setUnChangedPageContent] = useState();
	const [loading, setLoading] = useState(false);

	const breadcrumbItems = [{ title: "Term", link: "/activity_log" }];

	const filterTerms = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.page_content + "?id=" + props.match.params.id, resolve, reject, true);
		}).then(response => {
			if (response.success) {
				let data = response?.data;
				if (data.length) {
					const [page] = data;
					setPageContent(page);
					setPageContentId(page.id);
					setPageName(page.friendly_name);
					setPageShowName(page.show_title);
					setPageTitle(page.page_title);
					setUnChangedPageContent(page);
					if (page.description) {
						setPageDescription(page.description);
					} else {
						setPageDescription("");
					}
					setPageKey(page.name);

					setPageContentLanguage(page.language);
				}

				setShowSave(true);
			}
		});
	};

	useEffect(() => {
		props.setBreadcrumbItems("Page Content", breadcrumbItems);
		filterTerms();
	}, []);

	const editorRef = useRef(null);

	useEffect(() => {
		if (pageContent[0]?.translation || pageContent !== pageContent) {
			setContent(pageContent[0]?.translation);
		} else {
			setContent(pageContent?.content || "");
		}
	}, [pageContent]);

	const savePageContent = () => {
		if (!pageName) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please write page name</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}

		if (!pageContentId) {
			if (!pageKey) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please write page key name</p>
					</>,
					{ className: "custom-toast" }
				);
				return false;
			}
			if (pageContentLanguage === "") {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select language</p>
					</>,
					{ className: "custom-toast" }
				);
				return false;
			}
		}

		setShowSave(false);

		const editorContent = editorRef.current.getContent();

		let payload = {
			friendly_name: pageName,
			page_title: pageTitle,
			content: editorContent,
			description: pageDescription,
			name: pageKey,
			language: pageContentLanguage?.id.toLowerCase(),
			show_title: pageShowName,
		};

		if (pageContentId) {
			payload["id"] = pageContentId;
		}
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.page_content_edit, resolve, reject, payload, true);
		})
			.then(response => {
				setDataChanged(false);
				if (response.success) {
					filterTerms();
					props.history.push("/page_content");
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const handleChange = (value, id, elementType) => {
		let actualData = [];
		setShowSave(true);
		actualData = [...menuLinks];
		var editedElement = actualData.filter(row => row.id === id);
		var editedIndex = actualData.findIndex(row => row.id === id);
		if (editedElement && editedElement.length > 0) {
			editedElement[0][elementType] = value;
			editedElement[0].edited = true;
			actualData[editedIndex] = editedElement[0];
		}
		setMenuLinks(actualData);
	};

	const handleDataChanged = language => {
		if (language && props.match.params.id != "new") {
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls.page_content + `?language=${language?.id.toLowerCase()}&name=${pageKey}`, resolve, reject, true);
			}).then(response => {
				const { data } = response;
				const page = data[data.length - 1];
				console.log(page);
				setPageContent(page || "");
				setPageContentId(data.length ? page.id : 0);
			});
		}
		setDataChanged(true);
	};

	const handleTextChanged = () => {
		setUnChangedPageContent({ ...unChangedPageContent, content: editorRef.current?.getContent() });
	};

	const getLanguageDropdown = async () => {
		return new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_languages, resolve, reject, true);
		}).then(response => {
			let languages = response?.data;
			languages = languages
				.map(row => {
					if (row?.in_use === true) {
						return { id: row.code2, label: row.language_name };
					}
					return null;
				})
				.filter(language => language !== null);
			return languages;
		});
	};

	async function fetchLanguageOptions() {
		const languageOptions = await getLanguageDropdown();
		setLanguageOptions(languageOptions);
	}

	useEffect(() => {
		fetchLanguageOptions();
	}, []);

	useEffect(() => {
		const selectedLanguage = languageOptions.find(lang => lang.id?.toLowerCase() === pageContentLanguage);
		if (selectedLanguage) {
			setPageContentLanguage(selectedLanguage);
		}
	}, [pageContentLanguage, languageOptions]);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{"Page Content"}</span>
				<XCloseIcon className={"xclose-icon"} onClick={() => props.history.push("/page_content")} />
			</div>
			<div className="promotion-body page-content-body">
				{showSave ? (
					<div>
						<TopBarComponent
							saveHandler={savePageContent}
							loading={loading}
							dataChanged={dataChanged}
							onClose={() => props.history.push("/page_content")}
						/>
					</div>
				) : (
					""
				)}
				<div className="promotion-elements page-content-elements">
					<AvForm>
						<div className="inputs-list">
							<div className="input-item">
								<div className="form-group-horizontal">
									<GeneralInput
										object={{
											value: pageName,
											name: "Page Name*",
											inputContainer: "flex-column p-0",
											titleClass: "page_content_label w-100",
											required: true,
											onChangeEvent: newValue => {
												handleDataChanged();
												handleChange(newValue, pageContentId, "page_name");
												setPageName(newValue);
											},
										}}
									/>
									<SwitchInput
										handleChange={() => {
											handleDataChanged();
											setPageShowName(state => (state === 0 ? 1 : 0));
										}}
										object={{
											name: "Show Title",
											fieldName: "showTitle",
											value: pageShowName,
											wrapperClassName: "flex-column p-0",
											switchTitleClassName: "switch-title"
										}}
									/>
								</div>
							</div>

							<div className="input-item">
								<GeneralInput
									object={{
										value: pageKey,
										name: "Page Key Name*",
										inputContainer: "flex-column p-0",
										disabled: pageContentId ? true : false,
										titleClass: "page_content_label w-100",
										required: true,
										onChangeEvent: newValue => {
											handleDataChanged();
											handleChange(newValue, pageContentId, "page_key_name");
											setPageKey(newValue.replace(/[^a-z0-9-]+/gi, ""));
										},
									}}
								/>
							</div>

							<div className="input-item">
								{pageContentLanguage.id === "EN" || pageContentLanguage === "en" ? (
									""
								) : (
									<LanguageTitle
										data={unChangedPageContent}
										setData={setPageContent}
										title={"content"}
										translateTo={[pageContentLanguage?.id?.toLowerCase()]}
									/>
								)}
								<GeneralDropdown
									handleChange={e => {
										handleDataChanged(e);
										setPageContentLanguage(e?.id.toLowerCase());
										handleChange(e.id, "dropdown", pageContentId);
									}}
									object={{
										type: "dropdown",
										name: "Language",
										fieldName: "language",
										options: languageOptions,
										value: pageContentLanguage.id,
										label: pageContentLanguage.label,
										required: true,
										placeholder: "Choose Language",
										wrapperClassNameDropdown: "flex-column p-0",
										dropdownClassName: "page_content_label w-100",
									}}
								/>
							</div>
						</div>

						<div className="row mb-3 d-none">
							<div className="col-12 col-md-6">
								<GeneralInput
									object={{
										value: pageTitle,
										name: "Page Title",
										inputContainer: "flex-column gap-2",
										required: true,
										onChangeEvent: newValue => {
											handleDataChanged();
											setPageTitle(newValue);
										},
									}}
								/>
							</div>
							<div className="col-12 col-md-6">
								<GeneralInput
									object={{
										value: pageDescription,
										name: "Description",
										inputContainer: "flex-column gap-2",
										required: true,
										onChangeEvent: newValue => {
											handleDataChanged();
											setPageDescription(newValue);
										},
									}}
								/>
							</div>
						</div>

						<div className="title_content mb-2">
							<label className="page_content_label">Content</label>
							<Editor
								apiKey={editorApiUrl}
								onInit={(evt, editor) => (editorRef.current = editor)}
								initialValue={content}
								onChange={e => {
									handleChange(e.level.content, pageContentId, "editor_text");
									handleTextChanged(e.level.content);
								}}
								init={{
									height: 650,
									menubar: false,
									visual: false,
									plugins: [
										"advlist",
										"autolink",
										"lists",
										"link",
										"image",
										"charmap",
										"preview",
										"anchor",
										"searchreplace",
										"visualblocks",
										"code",
										"fullscreen",
										"insertdatetime",
										"media",
										"table",
										"code",
										"help",
										"wordcount",
									],
									toolbar:
										"undo redo | blocks | " +
										"bold italic forecolor link | alignleft aligncenter " +
										"alignright alignjustify | bullist numlist outdent indent | " +
										"removeformat | help | code",
									content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
								}}
								onEditorChange={() => {
									handleDataChanged();
								}}
							/>
						</div>
					</AvForm>
				</div>
			</div>
		</div>
	);
};

export default connect(null, { setBreadcrumbItems })(PageContent);
