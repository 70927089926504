import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner } from "react-bootstrap";
import { CancelButton, SaveButton } from "components/GeneralComponents/AddButton";

export const SettleBetModal = ({ setShowSettleModal, params, handleClick, spinningStatus }) => {
	const text = params == "cancel" ? "cancel" : "settle";
	const confirmTitle = `${text} Bet Confirm`;
	const title = `Are you sure you want to ${text} this bet ?`;
	return (
		<SweetAlert title={""} showConfirm={false} onConfirm={() => {}}>
			<div style={{ position: "relative" }}>
				<h2>{confirmTitle}</h2>
				<p>{title}</p>
				<div className="mt-3">
					<CancelButton onClick={() => setShowSettleModal({ show: false, params: "" })} name={"No"} />
					<SaveButton onClick={handleClick} name={"Yes"}>
						{spinningStatus ? <Spinner animation="border" size="sm" /> : "Yes"}
					</SaveButton>
				</div>
			</div>
		</SweetAlert>
	);
};
