export const betResultType = {
	winner: "Win",
	loser: "Lose",
	undo: "Cancelled",
	pushed: "Push",
	cancel: "Cancelled",
};

export const betTypes = {
	geniussport: "GSB",
	specialbet: "SB",
};

export const betsBreadcrumbItems = [{ title: "Bets", link: "/bets" }];
