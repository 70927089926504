import React, { useRef } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { XCloseIcon } from "utils/icons";
import {
	CompetitionInput,
	EndDateInput,
	EndTimeInput,
	EventIDInput,
	LinkTypeInput,
	// MarketInput,
	NeverExpireInput,
	PromoTypeInput,
	SwitchInput,
	SpecificDaysInput,
	SpecificTimesInput,
	SportInput,
	StartDateInput,
	StartTimeInput,
	ImageInput,
	SelectionInput,
	GeneralInput,
	GeneralDropdown,
} from "../../../components/GeneralComponents/CustomInputs";
import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import moment from "moment";
import { getAllMenuLink } from "services/getAllMenuLinks";
import xIcon from "../../../assets/images/x-icon.svg";
import { getDynamicValue, checkGenericUrl, convertFormatDateInUtc, getFormatDateByUserTimezone } from "utils/global";
import { getMatcheDropdown } from "services/getMatchesDropdown";
import getSportsData from "services/getSportsData";
import { getCompetitionsDropdown } from "services/getCompetitionsDropdown";
import { getCasinoGames } from "services/getCasinoGames";
import { getEventMarketsDropdown } from "services/getEventMreketsDropdown";
import { languageToString } from "utils/global";

export const BetSlipPromotion = ({ title, handler, editItem, getBetSlip, countires }) => {
	let details = null;
	if (editItem) {
		details = JSON.parse(editItem?.details);
	}

	const [betSlipData, setBetSlipData] = useState({
		checked: editItem ? editItem?.active : true,
		promoType: details ? details?.promo_type : "default",
		title: details ? details?.title : "",
		image: details ? details?.image : null,
		image_exist: editItem ? true : false,
		link: details ? details?.link : "",
		link_type: details ? (details?.link_type ? details?.link_type : "generic") : "generic",
		call_to_action: details ? details?.call_to_action : "",
		sport_id: details ? details?.sport_slug : "",
		event_id: details ? details?.event_id : "",
		event_name: details ? details?.event_name : "",
		competition_id: details ? details?.competition_raw_id : "",
		market_id: details ? details?.market_id : "",
		selection_id: details ? details?.selection_id : "",
		startDate: editItem
			? getFormatDateByUserTimezone(editItem?.publish_start_date_time)
			: moment().startOf("day").add(1, "minute").format("YYYY-MM-DD HH:mm"),
		end_date: editItem ? getFormatDateByUserTimezone(editItem?.publish_stop_date_time) : "",
		neverExpire: editItem ? editItem?.never_expire : true,
		specific_days: details ? details?.specific_days : false,
		selected_days: details ? (details?.selected_days ? details?.selected_days : []) : [],
		specific_time: details ? details?.specific_time : false,
		start_time: details ? getFormatDateByUserTimezone(details?.start_time) : "",
		end_time: details ? details?.end_time : "",
		game_id: details ? details?.game_id : "",
		date_to: details ? details.publish_stop_date_time : "",
		selected_selections: details ? (details?.selected_selections ? details?.selected_selections : []) : [],
		new_user: details ? details?.new_user : false,
	});

	const [sportsData, setSportsData] = useState([]);
	const [pageType, setPageType] = useState([]);
	const [markets, setMarkets] = useState([]);
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageContent, setPageContent] = useState([]);
	const [competitions, setCompetitions] = useState([]);
	const [games, setGames] = useState([]);
	const [selectionData, setSelectionData] = useState([]);
	const [selectedIds, setSelectedIds] = useState({
		sport_id: details ? details?.sport_slug : "",
		market_id: details ? details?.market_id : "",
		event_id: details ? details?.event_raw_id : "",
		event_name: details ? details?.event_name : "",
		selection_id: details ? details?.selection_id : "",
		competition_id: details ? details?.competition_raw_id : "",
		link_type: "generic",
	});
	const [error, setError] = useState({
		link_type: false,
		link: false,
		casino: false,
		sport: false,
		event: false,
	});
	const [dataChanged, setDataChanged] = useState(false);
	const [fetching, setFetching] = useState({
		market: false,
	});

	const getSelectableSelections = options => {
		return options.map((option, index) => {
			if (selectedIds?.market_id === details?.market_id) {
				const selectedSelection = details?.selected_selections?.find(selection => selection.selection_id == option.selection_id);

				if (selectedSelection?.active == 1) {
					return {
						active: selectedSelection?.active,
						selection_name: selectedSelection.selection_name,
						selection_id: "" + selectedSelection.selection_id,
					};
				}
			} else if (index < 3) {
				return {
					selection_name: option.selection_name,
					selection_id: "" + option.selection_id,
					active: 1,
				};
			}

			return {
				selection_name: option.selection_name,
				selection_id: "" + option.selection_id,
				active: 0,
			};
		});
	};

	const getMarketsData = async (event_id, sport_slug) => {
		setFetching({ ...fetching, market: true });
		const isRacing = selectedIds?.sport_id == "horseracing" || selectedIds?.sport_id == "greyhoundracing";
		const data = await getEventMarketsDropdown(event_id, sport_slug);

		if (isRacing) {
			const firstMarket = data.find(item => item.market_id == "c1");
			if (firstMarket) {
				setMarkets([firstMarket]);
				setSelectedIds({ ...selectedIds, market_id: firstMarket.market_id });
			}
		} else {
			setMarkets(data.map(({ market_id, market_name }) => ({ id: market_id, label: market_name })));
		}
		setFetching({ ...fetching, market: false });
	};

	// const getCompetitions = async sport_id => {
	const getCompetitions = async sport_id =>
		getCompetitionsDropdown(sport_id).then(response => {
			if (response.length > 0) {
				setCompetitions(response);
			}
		});

	const getMatches = async competition_id => {
		let events = await getMatcheDropdown(competition_id);
		setEvents(events);
	};

	const getSelectionData = sport_id => {
		if (sport_id && selectedIds.market_id && selectedIds.event_id) {
			new Promise((resolve, reject) => {
				ApiServices.get(
					`${apiUrls.SELECTIONS}?sport_slug=${sport_id}&market_id=${selectedIds.market_id}&match_id=${selectedIds.event_id}`,
					resolve,
					reject,
					true
				);
			}).then(response => {
				setSelectionData(getSelectableSelections(response.data));
			});
		}
	};

	const getPage = async () => {
		let page = await getAllMenuLink();
		setPageType(page);
	};

	const getSports = () => {
		getSportsData().then(response => {
			setSportsData(response);
		});
	};

	const getPageContent = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.page_content, resolve, reject, true);
		}).then(response => {
			let data = response?.data;
			setPageContent(data);
		});
	};

	const getGames = async value => {
		const games = await getCasinoGames(value);
		setGames(games);
	};

	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};
	const handleAcceptedFiles = () => {};

	const addBetSlip = () => {
		const dynamicFields = getDynamicValue(betSlipData, selectedIds, editItem);
		let payload = {
			published: betSlipData?.checked ? "1" : "0",
			promo_type: betSlipData?.promoType,
			title: betSlipData?.title,
			image: betSlipData?.image,
			image_exist: betSlipData?.image_exist,
			link: betSlipData?.link,
			start_date: convertFormatDateInUtc(betSlipData?.startDate),
			end_date: convertFormatDateInUtc(betSlipData?.end_date),
			type: "bet_slip",
			country_code: countires ? countires : "all",
			name: "",
			link_type: betSlipData?.link_type,
			sport_id: dynamicFields.sport_id,
			event_id: dynamicFields.event_id,
			event_name: betSlipData.event_name,
			market_id: dynamicFields.market_id,
			competition_id: dynamicFields.competition_id,
			selection_id: dynamicFields.selection_id,
			call_to_action: betSlipData?.call_to_action ? betSlipData?.call_to_action : "",
			never_expire: betSlipData?.neverExpire ? 1 : 0,
			specific_days: betSlipData?.specific_days ? 1 : 0,
			selected_days: betSlipData?.selected_days,
			specific_time: betSlipData?.specific_time ? 1 : 0,
			new_user: betSlipData?.new_user ? 1 : 0,
			start_time: betSlipData?.start_time,
			end_time: betSlipData?.end_time,
			game_id: betSlipData?.game_id,
			selected_selections: selectionData.filter(x => x.active === 1),
		};

		let language = languageToString(betSlipData, ["title_"]);
		// Object.entries(betSlipData).forEach(([key, value]) => {
		// 	if (key.includes("title_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });
		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };

		if (!betSlipData?.promoType) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select promo type</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!betSlipData?.title) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter title</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!betSlipData?.startDate) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select start date</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		if (betSlipData?.promoType == "default" && betSlipData?.link_type === "generic") {
			const shouldStop = checkGenericUrl(betSlipData?.link);
			if (shouldStop) return;
		}

		if (betSlipData["promoType"] === "dynamic") {
			if (!dynamicFields?.sport_id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select sport</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}

			if (!dynamicFields?.event_id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select event</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}
		}
		setLoading(true);
		const fields = [{ fieldName: "title", required: true }];
		if (betSlipData?.link_type == "sport" && betSlipData?.promoType == "default") {
			delete payload.competition_id;
			delete payload.event_id;
			delete payload.event_name;
		}

		if (betSlipData?.promoType === "dynamic") {
			payload.link_type = "event";
		}

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.add_bet_split, resolve, reject, payload, true, fields);
		})
			.then(() => {
				getBetSlip();
				handler();
				setSelectedIds({
					...selectedIds,
					sport_id: "",
					market_id: "",
					event_id: "",
					selection_id: "",
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const editBetSlip = () => {
		const dynamicFields = getDynamicValue(betSlipData, selectedIds, editItem);
		let payload = {
			published: betSlipData?.checked ? "1" : "0",
			promo_type: betSlipData?.promoType,
			title: betSlipData?.title,
			image: betSlipData?.image,
			image_exist: betSlipData?.image_exist,
			link: betSlipData?.link,
			start_date: convertFormatDateInUtc(betSlipData?.startDate),
			end_date: convertFormatDateInUtc(betSlipData?.end_date),
			type: "bet_slip",
			country_code: countires ? countires : "all",
			name: "",
			link_type: betSlipData?.link_type,
			sport_id: dynamicFields.sport_id,
			event_id: dynamicFields.event_id,
			event_name: dynamicFields.event_name,
			market_id: dynamicFields.market_id,
			competition_id: dynamicFields.competition_id,
			selection_id: dynamicFields.selection_id,
			call_to_action: betSlipData?.call_to_action ? betSlipData?.call_to_action : "",
			id: editItem?.id,
			never_expire: betSlipData?.neverExpire ? 1 : 0,
			specific_days: betSlipData?.specific_days ? 1 : 0,
			selected_days: betSlipData?.selected_days,
			specific_time: betSlipData?.specific_time ? 1 : 0,
			new_user: betSlipData?.new_user ? 1 : 0,
			start_time: betSlipData?.start_time,
			end_time: betSlipData?.end_time,
			game_id: betSlipData?.game_id,
			selected_selections: selectionData.filter(x => x.active === 1),
		};

		let language = languageToString(betSlipData, ["title_"]);
		// Object.entries(betSlipData).forEach(([key, value]) => {
		// 	if (key.includes("title_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });

		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };

		if (!betSlipData?.promoType) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select promo type</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!betSlipData?.title) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter title</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!betSlipData?.startDate) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select start date</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		if (betSlipData?.promoType == "default" && betSlipData?.link_type === "generic") {
			const shouldStop = checkGenericUrl(betSlipData?.link);
			if (shouldStop) return;
		}

		if (betSlipData["promoType"] !== "dynamic") {
			if (betSlipData.link_type === "sport" && !betSlipData.sport_id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select sport</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}
			if (betSlipData.link_type === "event" && (!betSlipData.sport_id || !betSlipData.event_id)) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select sport and event</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}
		}

		if (betSlipData["promoType"] === "dynamic") {
			if (!dynamicFields?.sport_id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select sport</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}

			if (!dynamicFields?.event_id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select event</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}
		}
		setLoading(true);

		if (betSlipData?.link_type == "sport" && betSlipData?.promoType == "default") {
			delete payload.competition_id;
			delete payload.event_id;
			delete payload.event_name;
		}
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.edit_bet_slip, resolve, reject, payload, true);
		})
			.then(() => {
				getBetSlip();
				handler();
				setSelectedIds({
					...selectedIds,
					sport_id: "",
					market_id: "",
					selection_id: "",
					event_id: "",
				});
			})
			.finally(() => {
				setLoading(false);
				setDataChanged(false);
			});
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	useEffect(() => {
		getPageContent();
		getGames();
		getSports();
		getPage();
		if (details) {
			let newData = { ...betSlipData };
			Object.entries(details).forEach(([key, value]) => {
				if (key.includes("title_")) {
					newData = { ...newData, [key]: value };
				}
			});
			setBetSlipData(newData);
		}
	}, []);

	useEffect(() => {
		if (betSlipData?.sport_id) {
			getCompetitions(betSlipData?.sport_id);
		}
	}, [betSlipData?.sport_id]);

	useEffect(() => {
		if (selectedIds?.event_id) {
			getMarketsData(selectedIds?.event_id, selectedIds?.sport_id);
		}
	}, [selectedIds?.event_id]);

	useEffect(() => {
		if (betSlipData?.competition_id) {
			getMatches(betSlipData?.competition_id);
		}
	}, [betSlipData?.competition_id]);

	useEffect(() => {
		if (selectedIds?.sport_id) {
			getCompetitions(selectedIds?.sport_id);
		}
	}, [selectedIds?.sport_id]);

	useEffect(() => {
		if (selectedIds?.competition_id) {
			getMatches(selectedIds?.competition_id);
		}
	}, [selectedIds?.competition_id]);

	useEffect(() => {
		if (betSlipData?.market_id) {
			getSelectionData(betSlipData?.sport_id);
		}
	}, [betSlipData?.market_id]);

	useEffect(() => {
		if (selectedIds?.market_id) {
			getSelectionData(selectedIds?.sport_id);
		}
	}, [selectedIds?.market_id]);

	const handleInputChange = (e, selectionId) => {
		const newValue = e.target.value;
		const updatedArray = selectionData.map(item => (item.selection_id === selectionId ? { ...item, selection_name: newValue } : item));
		setSelectionData(updatedArray);
	};

	const handleCheckBox = row => {
		setDataChanged(true);
		setSelectedIds({ ...selectedIds, selection_id: row.selection_id });

		let updatedArray;
		if (row?.active === 1) {
			updatedArray = selectionData.map(item => (item.selection_id === row.selection_id ? { ...item, active: 0 } : item));
		} else {
			updatedArray = selectionData.map(item => (item.selection_id === row.selection_id ? { ...item, active: 1 } : item));
		}
		setSelectionData(updatedArray);
	};

	useEffect(() => {
		if (selectedIds?.sport_id && details?.sport_slug != selectedIds?.sport_id) {
			setSelectedIds({ ...selectedIds, market_id: "", event_id: "", event_name: "", competition_id: "" });

			setMarkets([]);
			setEvents([]);
		}
	}, [selectedIds?.sport_id]);

	const limitNumberOfSelected = selectionData.filter(row => row.active == 1).length > 2;

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editItem ? editBetSlip : addBetSlip} loading={loading} dataChanged={dataChanged} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left selection-promotion-inputs">
						<AvForm id="betslipForm" onClick={() => !onFocus.current && handleShowOptions()}>
							<SwitchInput
								object={{
									name: "Published",
									value: betSlipData?.checked,
								}}
								handleChange={() => {
									handleDataChanged();
									setBetSlipData({ ...betSlipData, checked: !betSlipData?.checked });
								}}
							/>
							<PromoTypeInput data={betSlipData} setData={setBetSlipData} handleDataChanged={handleDataChanged} otherRowClass />
							<GeneralInput
								object={{
									name: "Title",
									fieldName: "title",
									value: betSlipData.title,
									onFocus: () => handleShowOptions("language", "title"),
									onBlur: () => (onFocus.current = false),
									required: true,
								}}
								onChange={e => {
									handleDataChanged();
									setBetSlipData({ ...betSlipData, title: e.target.value });
								}}
							/>
							<ImageInput
								object={{
									name: "Media",
									value: betSlipData.image,
									data: betSlipData,
									fieldName: "image",
									active: showOptions.image === "bet-slip",
									setData: setBetSlipData,
									setDataChanged: setDataChanged,
									imageLibaryType: "bet_slip",
									onFocus: () => handleShowOptions("image", "bet-slip"),
									onBlur: () => (onFocus.current = false),
									dimensions: "(580 x 580 px)",
								}}
							/>
							{betSlipData["promoType"] === "dynamic" ? (
								<>
									<SportInput
										data={selectedIds}
										setData={setSelectedIds}
										sportsData={sportsData}
										handleDataChanged={handleDataChanged}
										otherRowClass
									/>
									<CompetitionInput
										competitions={competitions}
										data={selectedIds}
										setData={setSelectedIds}
										handleDataChanged={handleDataChanged}
										otherRowClass
										notOptional
									/>
									<EventIDInput eventsData={events} data={selectedIds} setData={setSelectedIds} handleDataChanged={handleDataChanged} otherRowClass />
									<GeneralDropdown
										object={{
											name: "Market",
											options: markets,
											required: true,
											loading: fetching?.market,
											value: selectedIds?.market_id,
										}}
										handleChange={e => {
											handleDataChanged();
											setSelectedIds({ ...selectedIds, market_id: e?.id });
										}}
									/>
									{/* <MarketInput
										markets={markets}
										data={selectedIds}
										setData={setSelectedIds}
										handleDataChanged={handleDataChanged}
										otherRowClass
										notOptional
									/> */}
									{selectedIds?.market_id && selectionData?.length > 0 && (
										<SelectionInput
											object={{
												field: "selection_name",
												fieldId: "selection_id",
												data: selectionData,
												otherRowClass: true,
												name: "Selections",
												checkBoxOnChange: (row, index) => handleCheckBox(row, index),
												disabledOption: limitNumberOfSelected,
											}}
											onChange={(e, row) => {
												handleDataChanged();
												handleInputChange(e, row?.selection_id);
											}}
										/>
									)}
								</>
							) : (
								<LinkTypeInput
									data={betSlipData}
									setData={setBetSlipData}
									setCompetitions={setCompetitions}
									setEvents={setEvents}
									pageContent={pageContent}
									games={games}
									getGames={getGames}
									error={error}
									sportsData={sportsData}
									pageType={pageType}
									competitions={competitions}
									setError={setError}
									events={events}
									handleDataChanged={handleDataChanged}
									showCTA={false}
									otherRowClass
								/>
							)}
							<StartDateInput data={betSlipData} setData={setBetSlipData} handleDataChanged={handleDataChanged} otherRowClass required={true} />
							<NeverExpireInput
								checked={betSlipData?.neverExpire}
								handleChange={() => {
									handleDataChanged();
									setBetSlipData({
										...betSlipData,
										neverExpire: !betSlipData?.neverExpire,
									});
								}}
								otherRowClass
							/>
							{!betSlipData?.neverExpire && (
								<EndDateInput
									data={betSlipData}
									setData={data => {
										setBetSlipData({
											...data,
											end_date: data.end_date,
										});
									}}
									handleDataChanged={handleDataChanged}
									type="bet_slip_promotion"
									otherRowClass
									required={true}
								/>
							)}
							<SpecificDaysInput data={betSlipData} setData={setBetSlipData} handleDataChanged={handleDataChanged} otherRowClass />
							<SpecificTimesInput data={betSlipData} setData={setBetSlipData} handleDataChanged={handleDataChanged} otherRowClass />
							{betSlipData.specific_time ? (
								<>
									<StartTimeInput
										data={betSlipData}
										setData={setBetSlipData}
										handleDataChanged={handleDataChanged}
										otherRowClass
										custumText="Start Time (UTC)"
									/>
									<EndTimeInput
										data={betSlipData}
										setData={setBetSlipData}
										handleDataChanged={handleDataChanged}
										otherRowClass
										custumText="End Time (UTC)"
									/>
								</>
							) : (
								""
							)}
							<SwitchInput
								object={{
									name: "New User",
									value: betSlipData?.new_user,
								}}
								handleChange={() => {
									handleDataChanged();
									setBetSlipData({ ...betSlipData, new_user: !betSlipData?.new_user });
								}}
							/>
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
						{showOptions.language && <LanguageTitle data={betSlipData} setData={setBetSlipData} title={"title"} handleDataChanged={handleDataChanged} />}
						{showOptions.image && (
							<ImageLibrary
								data={betSlipData}
								setData={setBetSlipData}
								handleAcceptedFiles={handleAcceptedFiles}
								type="bet_slip"
								handleDataChanged={handleDataChanged}
								handleShowOptions={handleShowOptions}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
