import MetaTags from "react-meta-tags";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Row, Col, Card, Alert, Container } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { loginLogo, platformName } from "utils/constants";
import SignInFooter from "./SignInFooter";
import * as authService from "utils/AuthService";
import xIcon from "../../assets/images/x-icon.svg";
// actions
import { cmsLoginUser } from "../../store/actions";
import OTPInput from "react-otp-input";
import "./MFASignInStyle.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// 2305

const MFASignIn = props => {
	const history = useHistory();
	const inputRef = useRef(null);
	const dispatch = useDispatch();
	const [OTP, setOTP] = useState("");
	// const [rememberMe, setRememberMe] = useState(false);
	const [spinningLogin, setSpinningLogin] = useState(false);

	const verifyMFAToken = () => {
		if (spinningLogin) return;

		setSpinningLogin(true);
		if (OTP == "") {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please give the MFA code</p>
				</>,
				{ className: "custom-toast" }
			);
		}
		var session_key = sessionStorage.getItem("session_key");
		var email = sessionStorage.getItem("email");
		var body = {
			session_key: session_key,
			mfa_token: OTP,
			email: email,
		};
		new Promise((resolve, reject) => {
			authService.signWithMfa(resolve, reject, body);
		})
			.then(response => {
				if (response) {
					localStorage.setItem("email", email);
					localStorage.setItem("access_token", response.data.returnData.data.AccessToken);
					localStorage.setItem("refresh_token", response.data.returnData.data.RefreshToken);
					// if (rememberMe) {
					// localStorage.setItem("refresh_token", response.data.returnData.data.RefreshToken);
					// } else {
					// localStorage.removeItem("refresh_token");
					// }
					// remove the data from sessionStorage
					sessionStorage.removeItem("qr_code");
					sessionStorage.removeItem("manual_key");
					sessionStorage.removeItem("email");
					sessionStorage.removeItem("session_key");
					sessionStorage.removeItem("access_token");
					sessionStorage.removeItem("refresh_token");

					var user = [];
					var user_data = {};
					user_data = response.data.returnData.userData;
					user.push(user_data);
					if (user && user.length > 0) {
						localStorage.setItem("user", JSON.stringify(user_data));
					}
					dispatch(cmsLoginUser(user[0]));
					window.location.href = `/`;
				}
			})
			.catch(error => {
				if (error.code === 2305) {
					history.push("/login");
				}
			})
			.finally(() => {
				setSpinningLogin(false);
			});
	};

	useEffect(() => {
		document.body.classList.add("login-body");
		if (inputRef.current) {
			inputRef.current.focus();
		}
		return () => {
			document.body.classList.remove("login-body");
		};
	}, [inputRef]);

	useEffect(() => {
		const numericOTP = OTP.replace(/\D/g, "");
		setOTP(numericOTP);

		if (numericOTP.length === 6) {
			verifyMFAToken();
		}
	}, [OTP]);

	return (
		<React.Fragment>
			<MetaTags>
				<title> MFA Sign-in | CMS | {platformName} </title>
			</MetaTags>
			<div className="account-pages my-0 pt-sm-5 my-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5} className="login-flow-card">
							<Card className="overflow-hidden login-card">
								<h3 className="default-logo-login text-center mt-5">
									<div className="d-block auth-logo">
										<img src={loginLogo} alt="" height="60" width="200" className="auth-logo-dark" style={{ cursor: "auto" }} />
									</div>
								</h3>
								<h4 className="login-title mfa-main-title">Multi Factor Authentication</h4>
								<div className="mfa-text mfa-title">Enter verification code to complete sign-in</div>
								<AvForm
									className="form-horizontal"
									onValidSubmit={(e, v) => {
										verifyMFAToken(e, v);
									}}
								>
									{props.error && typeof props.error === "string" ? <Alert color="danger">{props.error}</Alert> : null}
									<div className="code-input-main">
										<div className="codeVerification">
											<OTPInput
												value={OTP}
												onChange={setOTP}
												shouldAutoFocus={true}
												numInputs={6}
												inputType="number"
												disabled={false}
												inputStyle={"codeInput"}
												renderInput={props => <input {...props} />}
												innerRef={inputRef}
											/>
										</div>
									</div>
									{/* <div className="remember-mfa">
										<div className="remember-box">
											<input
												type="checkbox"
												name="remember"
												className="remember-box-input"
												checked={rememberMe}
												onChange={() => setRememberMe(!rememberMe)}
											/>
											Remember me for 30 days
										</div>
									</div> */}
									<div className="two-fa-button">
										<div className="text-center">
											<button className="login-button form-control" type="submit">
												{spinningLogin ? <Spinner animation="border" size="sm" /> : "Verify"}
											</button>
										</div>
									</div>
								</AvForm>
							</Card>
							<SignInFooter />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(connect()(MFASignIn));
