export const getFields = ({ data, loggedUser, handleCheckBox, sendResetLink, resetPasswordLoading, handleChangeDropdown }) => [
	{
		group: "Customer",
		type: "input",
		name: "First Name",
		fieldName: "first_name",
		required: true,
		inputClassName: "general_input textCapitalized",
		normalWidth: true,
	},
	{
		group: "Customer",
		type: "input",
		name: "Last Name",
		fieldName: "last_name",
		required: true,
		inputClassName: "general_input textCapitalized",
		normalWidth: true,
	},
	{
		group: "Customer",
		type: "phone_prefix",
		name: "Phone",
		fieldName: "phone_prefix",
		optionsType: "phonePrefixes",
		numbersOnly: true,
		phoneField: "phone_number",
		checkBoxValue: data?.phone_number_verified,
		checkBoxOnChange: () => handleCheckBox("phone_number_verified", !data?.phone_number_verified),
		normalWidth: true,
	},
	{
		group: "Customer",
		type: "input",
		name: "E-Mail",
		fieldName: "email",
		required: true,
		inputClassName: "general_input ",
		checkBoxValue: data?.email_verified,
		checkBoxOnChange: () => handleCheckBox("email_verified", !data?.email_verified),
		disabled: true,
		normalWidth: true,
	},
	{
		group: "Customer",
		type: "input",
		name: "Address",
		fieldName: "address_line1",
		className: "textCapitalized",
		inputClassName: "general_input textCapitalized",
		normalWidth: true,
		required: true,
	},
	{
		group: "Customer",
		type: "input",
		name: "City",
		fieldName: "address_city",
		className: "textCapitalized",
		inputClassName: "general_input textCapitalized",
		normalWidth: true,
		required: true,
	},
	{
		group: "Customer",
		type: "input",
		name: "Post Code",
		fieldName: "address_zip",
		inputClassName: "general_input textCapitalized",
		normalWidth: true,
		required: true,
	},
	{
		group: "Customer",
		type: "dropdown",
		name: "Country",
		fieldName: "country",
		optionsType: "country",
		required: true,
		normalWidth: true,
		wrapperClassNameDropdown: "open_dropdown_up",
		handleChangeDropdown: handleChangeDropdown,
	},
	{
		group: "Customer",
		type: "date",
		name: "DOB",
		fieldName: "date_of_birth",
		required: true,
		convertedDate: true,
		inputClassName: "user_dob",
		isTimeExist: false,
		normalWidth: true,
	},
	{ group: "Customer", type: "input", name: "Age", fieldName: "age", disabled: true, inputClassName: "general_input ", normalWidth: true },
	{
		group: "Customer",
		type: "dropdown",
		name: "Language",
		fieldName: "language",
		optionsType: "languages",
		normalWidth: true,
		wrapperClassNameDropdown: "open_dropdown_up",
	},
	{ group: "Customer", type: "dropdown", name: "Currency", fieldName: "currency", optionsType: "currency", normalWidth: true },
	loggedUser?.role == "superadmin"
		? {
				group: "Customer",
				type: "dropdown",
				name: "Account Type",
				fieldName: "account_type",
				optionsType: "accountType",
				normalWidth: true,
				wrapperClassNameDropdown: "open_dropdown_up",
		  }
		: {},
	{
		group: "Customer",
		type: "dropdown",
		name: "Account Manager",
		fieldName: "account_manager_id",
		optionsType: "accountManager",
		placeholder: "Choose Tag",
		normalWidth: true,
		wrapperClassNameDropdown: "open_dropdown_up",
	},
	{
		group: "Customer",
		type: "dropdown",
		name: "Affiliate Tag",
		fieldName: "affiliate_tag",
		optionsType: "affiliateUsers",
		placeholder: "Choose Manager",
		normalWidth: true,
		wrapperClassNameDropdown: "open_dropdown_up",
	},
	{
		group: "Customer",
		type: "input",
		name: "Income Access Tag",
		fieldName: "income_access_tag",
		inputClassName: "general_input",
		normalWidth: true,
		disabled: true,
	},
	{
		group: "Account",
		type: "dropdown",
		name: "Account Status",
		fieldName: "account_status",
		optionsType: "accountStatus",
		placeholder: "Select...",
		normalWidth: true,
		disabled: data?.user_self_excluded,
		additionalInfo: data?.user_self_excluded ? "Self-excluded account" : "",
		additionalInfoType: "error",
	},
	{
		group: "Account",
		type: "dropdown",
		name: "KYC / AML",
		fieldName: "kyc_status",
		optionsType: "kycStatus",
		placeholder: "Select...",
		normalWidth: true,
	},
	data?.country == "ZA"
		? {
				group: "Account",
				type: "input",
				name: "ID Number",
				fieldName: "id_number",
				normalWidth: true,
				required: true,
		  }
		: {},
	{
		group: "Account",
		type: "dropdown",
		name: "Age Verified",
		fieldName: "ageVerified",
		optionsType: "ageVerification",
		placeholder: "Select...",
		normalWidth: true,
	},
	{
		group: "Account",
		type: "date",
		name: "SoF",
		fieldName: "sof_date",
		deleteEvent: true,
		convertedDate: true,
		isTimeExist: false,
		normalWidth: true,
	},
	{
		group: "Account",
		type: "date",
		name: "EDD",
		fieldName: "edd_date",
		deleteEvent: true,
		convertedDate: true,
		isTimeExist: false,
		normalWidth: true,
	},
	{ group: "Account", type: "switch", name: "Ring-fenced Funds", fieldName: "ring_fenced_funds_enabled", normalWidth: true },
	{
		group: "Security",
		type: "input",
		name: "Failed Login Attempts",
		fieldName: "failed_login_attempts",
		placeholder: 0,
		inputClassName: "general_input ",
		resetValue: "0",
		disabled: true,
		normalWidth: true,
	},
	{
		group: "Security",
		type: "button",
		title: "Reset Password",
		buttonName: "Send reset link",
		onClickEvent: sendResetLink,
		buttonClassname: "general_button_white button_reset_margin",
		normalWidth: true,
		isLoading: resetPasswordLoading,
	},
	{ group: "Notifications", type: "switch", name: "Sports updates and promotions", fieldName: "push_sports_update_promotions", normalWidth: true },
	{ group: "Notifications", type: "switch", name: "Bet updates", fieldName: "push_bet_updates", normalWidth: true },
	{ group: "E-mail Notifications", type: "switch", name: "Marketing and promotional", fieldName: "email_marketing_promotional", normalWidth: true },
	{ group: "E-mail Notifications", type: "switch", name: "News and updates", fieldName: "email_news_and_update", normalWidth: true },
	{ group: "E-mail Notifications", type: "switch", name: "Bet Updates", fieldName: "email_bet_updates", normalWidth: true },
	{ group: "Trader Settings", type: "switch", name: "Bet Referral", fieldName: "bet_referral_enabled", normalWidth: true },
	{ group: "Trader Settings", type: "switch", name: "BOG", fieldName: "bog_enabled", normalWidth: true },
	{ group: "Trader Settings", type: "switch", name: "Trader Chat", fieldName: "trader_chat_enabled", normalWidth: true },
	{ group: "Trader Settings", type: "switch", name: "Cash Out", fieldName: "cash_enabled", normalWidth: true },
	{ group: "Trader Settings", type: "switch", name: "Bonus Engine", fieldName: "bonus_engine", normalWidth: true },
	{ group: "Trader Settings", type: "switch", name: "Casino", fieldName: "casino_games_enabled", normalWidth: true },

	loggedUser?.role == "superadmin"
		? { group: "Trader Settings", type: "switch", name: "Override Country", fieldName: "override_country", normalWidth: true }
		: {},

	{
		group: "Trader Settings",
		type: "input",
		name: "BOG Max Payout",
		fieldName: "bog_max_payout",
		placeholder: "Set BOG Max Payout",
		numbersOnly: true,
		inputClassName: "general_input textCapitalized",
		normalWidth: true,
		resetValue: data?.default_bog_max_payout.toString(),
	},
	{
		group: "Trader Settings",
		type: "input",
		name: "Cash Out Reduction",
		fieldName: "cash_out_reduction",
		placeholder: "Set Cash Out Value Reduction",
		numbersOnly: true,
		inputClassName: "general_input textCapitalized",
		normalWidth: true,
		resetValue: data?.default_cash_out_reduction.toString(),
	},
];
