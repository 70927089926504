import HTMLReactParser from "html-react-parser";

export const getBetTypeBadges = betType => {
	let result = { label: "", class: "" };

	switch (betType) {
		case "Loser":
		case "Lose":
			result = { label: `Lose`, class: "lose" };
			break;
		case "Winner":
			result = { label: `Win`, class: `win` };
			break;
		case "Pushed":
			result = { label: `Push`, class: `push` };
			break;
		case "autoclose":
		case "Autoclose":
			result = { label: `Auto Closed`, class: "autoclose" };
			break;
		case "Cash Out":
			result = { label: `Cash Out`, class: "cashout" };
			break;
		case "Placed":
			result = { label: `Placed`, class: "placed" };
			break;
		case "Open":
			result = { label: `Open`, class: "open" };
			break;
		case "Partial Win":
		case "Partial":
			result = { label: `Partial`, class: "partial" };
			break;
		default:
			result = { label: betType, class: "" };
	}

	let formatedResult = `<span class="badge bet-badge ${result?.class}"> ${result?.label} </span>`;

	if (!result?.class) formatedResult = `${result?.label}`;

	return HTMLReactParser(formatedResult);
};
