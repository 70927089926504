import React from "react";
import "./scribes.css";

export const AffiliateLinkHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">How to Create a New Affiliate or Promo Link</h1>
				<p className="scribe-description">
					This guide provides step-by-step instructions on how to create a new affiliate or promo link. By following these steps, you can easily set
					up and manage affiliate links for promotions, enabling you to track and tag new players who sign up through these links. This guide is
					essential for anyone looking to optimize their affiliate marketing strategy and effectively promote their products or services.
				</p>
				<br />
				<br />

				<p className="scribe-step">
					<span className="number">1</span> Navigate to Settings -&gt; Affiliate Links
				</p>
				<p className="scribe-step">
					<span className="number">2</span> Click the plus button at the top of the promo links table
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/7195ceb7-7ee6-4512-bc49-1ebd075fadb5/ascreenshot.jpeg?tl_px=2940,0&br_px=4488,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=1029,86"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">3</span> Enter the name of the promotion or affiliate
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/033a5605-79c8-495c-972a-210dae01201e/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=324,138"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">4</span> Enter the slug. This will form part of the domain and the offer i.e. <br />
					<br />
					<a href="https://www.yourdomain.com/%7Bslug%7D">https://www.yourdomain.com/slug</a>
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/d81ac898-799d-4850-b9e2-7a58a2a2f959/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=301,195"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">5</span> Enter a description or any notes for the promotion or affiliate link.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/a07b5e01-0fcd-4a50-baa5-275818109bee/ascreenshot.jpeg?tl_px=0,13&br_px=1547,878&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=255,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">6</span> Enter the onboarded or start date of the promotion. <br />
					<br />
					Note: this does not prevent the promotional link from being live if you select a date in the future.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/d53c8738-9565-4add-9233-6f00ec8ae67b/ascreenshot.jpeg?tl_px=0,115&br_px=1547,980&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=340,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">7</span> Click Enabled to turn on the affiliate link. New players that sign up with this link will be tagged to
					this affiliate or promotion.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/4160648b-1141-4dd8-a00f-873212c05865/ascreenshot.jpeg?tl_px=0,196&br_px=1547,1061&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=244,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">8</span> Remember to save your settings.
				</p>
				<br />
			</body>
		</html>
	);
};

export const BannerHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">How to create a new Banner</h1>
				<p className="scribe-description">
					This guide explains how to create a new banner in CMS. It provides step-by-step instructions on adding a title, subtitle, background image,
					and selecting a link type. It also covers options for customizing the call to action button and setting an end time for the banner.
					Additionally, it explains how to specify the days on which the banner should be displayed. Following this guide will help users create an
					attractive and effective banner for their website or app.
				</p>

				<p className="scribe-step">
					<span className="number">1.</span> Navigate to CMS -&gt; Banners
				</p>

				<p className="scribe-step">
					<span className="number">2.</span> Click the &quot;plus&quot; to create a new banner or edit an existing banner
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/b7bd44c5-8e4b-4703-a169-c250c74aaeef/ascreenshot.jpeg?tl_px=1908,0&br_px=3456,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=1030,83"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">3.</span> Give your banner a Title. You can choose whether or not to display the title on the client app using the
					&quot;Show Title&quot; toggle.
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/c9ce47f1-88d7-44f3-ab66-1ffa2413d799/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=301,197"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">4.</span> You can choose to add a subtitle to the Banner.
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/6cd7adf7-3073-4920-84b8-3ff36a4a546c/ascreenshot.jpeg?tl_px=0,42&br_px=1547,907&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=355,277"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">5.</span> Add a background image to the banner by uploading a new image or selecting an image from the media
					library.
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/e9f50ef6-3b85-46d9-95a1-0e0f039903cb/ascreenshot.jpeg?tl_px=161,283&br_px=1708,1148&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">6.</span> Select a Link Type. <br />
					<br />
					Generic = Any external link i.e. <a href="https://www.google.com">https://www.google.com</a>
					<br />
					Modal = This will open any Content Page in a popup or modal window
					<br />
					Sport = Link to any sport i.e. Football
					<br />
					Event = Link to a specific event
					<br />
					Casino = Link to a casino game
					<br />
					Page = Link to any page created under Pages
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/8090124c-4b12-4d0d-ba99-a5e07dc88b5f/ascreenshot.jpeg?tl_px=0,789&br_px=1547,1654&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=342,277"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">7.</span> In this example, we have selected to link to a specific Page
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/7ecba0b9-bc2b-404b-aae1-21abb84096a1/ascreenshot.jpeg?tl_px=0,553&br_px=1547,1418&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=398,277"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">8.</span> You can create your own custom label for the call to action (CTA Button)
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/a5f26cda-a64a-4ee2-b8f4-6377d237e50d/ascreenshot.jpeg?tl_px=0,708&br_px=1547,1573&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=346,277"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">9.</span> You can choose to specify an end time for the banner or choose Never Expire
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/e3eab00e-b1f8-46e8-a6e9-0b2945f95d4f/ascreenshot.jpeg?tl_px=0,861&br_px=1547,1726&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=288,277"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">10.</span> You can choose to show your banner on specific days
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/9bb5f3cd-b83c-4c4b-980f-b96a9cd96d8b/ascreenshot.jpeg?tl_px=0,929&br_px=1547,1794&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=303,277"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">11.</span> Select the days that you want to display the banner
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/0b3289fa-34b3-4867-8639-c2d5e8ace6b4/ascreenshot.jpeg?tl_px=0,958&br_px=1547,1824&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=335,393"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">12.</span> Remember to save your banner
				</p>
			</body>
		</html>
	);
};

export const PromoLinksHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">How to Create a New Affiliate or Promo Link</h1>
				<p className="scribe-description">
					This guide provides step-by-step instructions on how to create a new affiliate or promo link. By following these steps, you can easily set
					up and manage affiliate links for promotions, enabling you to track and tag new players who sign up through these links. This guide is
					essential for anyone looking to optimize their affiliate marketing strategy and effectively promote their products or services.
				</p>

				<p className="scribe-step">
					<span className="number">1.</span> Navigate to CMS -&gt; Affiliate Links
				</p>

				<p className="scribe-step">
					<span className="number">2.</span> Click &quot;Promo Links&quot;
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/9696c248-88e7-46b4-b1ea-9f76114f93fa/ascreenshot.jpeg?tl_px=0,609&br_px=1719,1570&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=64,277"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">3.</span> Click the plus button at the top of the promo links table
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/2c5cadb1-3710-4060-a5a4-3b22ed4357f0/ascreenshot.jpeg?tl_px=1736,0&br_px=3456,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=978,86"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">4.</span> Enter the name of the promotion or affiliate
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/40c05c7c-7912-409f-8876-3e392f03f03f/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=306,146"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">5.</span> Enter the slug. This will form part of the domain and the offer i.e. <br />
					<br />
					<a href="https://www.yourdomain.com/%7Bslug%7D">https://www.yourdomain.com/slug</a>
				</p>

				<p className="scribe-step">
					<span className="number">6.</span> Enter a description or any notes for the promotion or affiliate link.
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/55a8cc7d-ec97-4695-9847-6606964e86a7/ascreenshot.jpeg?tl_px=0,21&br_px=1719,982&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=260,277"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">7.</span> Enter the onboarded or start date of the promotion. <br />
					<br />
					Note: this does not prevent the promotional link from being live if you select a date in the future.
				</p>

				<p className="scribe-step">
					<span className="number">8.</span> Remember to save your settings
				</p>
			</body>
		</html>
	);
};

export const LandingPageHelpInformation = () => {
	return (
		<body className="help_pages_body">
			<h1 className="scribe-title">Creating a Promo Landing Page</h1>
			<p className="scribe-description">
				This guide provides step-by-step instructions on how to create a promo landing page. It includes details on configuring affiliate/promo links,
				adding images and text, selecting promo tags, publishing the page, and optimizing it for SEO. By following this guide, you can effectively
				create a promotional landing page to attract and convert customers.
			</p>

			<p className="scribe-step">
				<span className="number">1.</span> Note: you need to have a configured affiliate / promo link before setting up a promo landing page
			</p>

			<p className="scribe-step">
				<span className="number">2.</span> Navigate to CMS - &gt; Promo Landing Pages
			</p>

			<p className="scribe-step">
				<span className="number">3.</span> Click &quot;Promo Landing Pages&quot;
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/301d7ac2-11fa-4132-bfe3-939f469ceb69/ascreenshot.jpeg?tl_px=0,725&br_px=1719,1686&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=124,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">4.</span> Click the plus button at the top of the promo links table
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/3eb54ba0-3ef0-42a3-8e71-9a4b2d5b8cb2/ascreenshot.jpeg?tl_px=1736,0&br_px=3456,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=994,91"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">5.</span> Enter the title of the offer
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/df0613f2-78d8-4c9d-bbe3-5cf67c5b617f/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=458,139"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">6.</span> Add a hero image for the desktop and mobile views
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/04aa7530-1076-427c-b0be-f9a7acd7042e/ascreenshot.jpeg?tl_px=102,331&br_px=1821,1292&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">7.</span> Enter the explainer text and the offer header text.
			</p>

			<p className="scribe-step">
				<span className="number">8.</span> Select the Promo Tag that has already been created. If this is not done, you can create this under CMS -&gt;
				Promo Links
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/0348f851-78dc-41b1-ae80-052cb82a4a2c/ascreenshot.jpeg?tl_px=0,505&br_px=1719,1466&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=419,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">9.</span> Publish the landing page
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/81dac0d4-6eb6-488d-8746-5da4659bd10f/ascreenshot.jpeg?tl_px=0,427&br_px=1719,1388&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=225,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">10.</span> Add the SEO information
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/d6edadfa-b75d-4edd-aea6-ae52497eaf96/ascreenshot.jpeg?tl_px=0,852&br_px=1719,1814&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=389,535"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">11.</span> Remember to save your settings
			</p>
		</body>
	);
};

export const CarouselHelpInformation = () => {
	return (
		<body className="help_pages_body">
			<h1 className="scribe-title">How to create a new Carousel</h1>
			<p className="scribe-description">
				This guide provides step-by-step instructions on how to create a new Carousel. It explains how to navigate to the CMS, add a new carousel, and
				customize its elements. The guide also provides examples of different options for linking the carousel elements, such as opening a specific game
				or page. Following this guide will help users create an engaging and interactive carousel on their website.
			</p>

			<p className="scribe-step">
				<span className="number">1.</span> Navigate to CMS -&gt; Carousel
			</p>

			<p className="scribe-step">
				<span className="number">2.</span> Click &quot;Carousel&quot;
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/5c35ea07-1a94-401f-a0f6-e2971c68c853/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=6,89"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">3.</span> To create a new carousel click on the dropdown button
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/14ac0dcf-ce15-4c21-b05c-313cd88ca4f8/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=516,85"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">4.</span> Click &quot;+ Add new carousel&quot;
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/fc02d785-9541-4a02-8d39-3eddd0946111/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=477,207"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">5.</span> Enter a name and click &quot;Create&quot;
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/15435522-4216-4000-a970-2a47d6105f0d/ascreenshot.jpeg?tl_px=640,449&br_px=2360,1410&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,276"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">6.</span> To add a new carousel element click the &quot;Add +&quot; button
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/691f699c-50ff-4600-8174-f3c8fa911c39/ascreenshot.jpeg?tl_px=1736,0&br_px=3456,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=995,152"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">7.</span> The Promo Type can either be a normal carousel element or a dynamically changing element. Dynamically
				changing should be used for sports offers in which you want to show the price changes.
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/3ce5c54a-afb4-4baf-8a0a-b0b36bc28f90/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=413,197"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">8.</span> Enter a title for the Carousel element
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/a4d09c00-55d8-4f1c-a436-49153a18f2b6/ascreenshot.jpeg?tl_px=0,0&br_px=1719,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=262,250"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">9.</span> Enter the subtitle or description
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/54f9da95-b32d-484a-a0f3-469a941b2a72/ascreenshot.jpeg?tl_px=115,217&br_px=1835,1178&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,276"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">10.</span> Add an image to the carousel
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/72843f43-4f94-4863-aaf4-bc8f04c3ca0f/ascreenshot.jpeg?tl_px=1180,309&br_px=2900,1270&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=523,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">11.</span> In this example when the user clicks the button they will be taken to a casino game. See the various
				different options below:
				<br />
				<br />
				Generic: This is any web address i.e. <a href="http://www.google.com">www.google.com</a>
				<br />
				Modal: This will open any of the content pages in a popup
				<br />
				Sport: This will open any of the sport pages
				<br />
				Event: This will open a specific event page
				<br />
				Casino: This will open a specific casino game
				<br />
				Page: This will open a specific page within the site
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/4c9df946-a756-4f1b-94b7-b112b9e9f633/user_cropped_screenshot.jpeg?tl_px=0,642&br_px=1719,1604&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=250,368"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">12.</span> In this example we will be opening the game Alien Fruits
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/48093a82-dc36-4983-8cc8-f9cbc7f9ac53/ascreenshot.jpeg?tl_px=0,645&br_px=1719,1606&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=307,276"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">13.</span> Enter the button label i.e. &quot;CLICK ME&quot;
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-16/44c153af-0052-4dad-9690-83a016a036c3/ascreenshot.jpeg?tl_px=0,751&br_px=1719,1712&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=271,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">14.</span> Remember to save your settings
			</p>
		</body>
	);
};

export const BetslipHelpInformation = () => {
	return (
		<body className="help_pages_body">
			<h1 className="scribe-title">How to add a new Betslip Promo or Advert</h1>
			<p className="scribe-description">
				This guide provides step-by-step instructions on how to add a new Betslip Promo or Advert. By following these instructions, users can create and
				customize promotions that link to specific URLs, pages, sporting events, or casino games. The guide also covers options for selecting media
				images, link types, start and end dates, and specific days and times for displaying the promotions. This guide is essential for anyone looking
				to effectively promote their offerings and engage customers.
			</p>

			<p className="scribe-step">
				<span className="number">1.</span> Navigate to Settings -&gt; Banner
			</p>

			<p className="scribe-step">
				<span className="number">2.</span> Click the &quot;plus&quot; to create a new banner or edit an existing betslip advert
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/d8113ded-f43b-49a5-a0ee-d99f72dd85eb/ascreenshot.jpeg?tl_px=1908,0&br_px=3456,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=1037,83"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">3.</span> Select the Promo Type:
				<br />
				<br />
				Default = This allows you to create a promotion that links to a specific URL, page, sporting event, or casino game
				<br />
				Dynamic Promo = A dynamic promo allows you to link to a specific event and market and display those ODDs on the card for a quick selection.
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/c42f32da-d91d-42ba-bbd5-5200300819bf/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1635,961&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=958,178"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">4.</span> Give your promotion a Title
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/4b076ed1-fcad-48a4-a466-dbe8c2600ac3/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=330,260"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">5.</span> Upload or select a media image for your Promotion
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/a9258c86-8d58-469c-9deb-d4dac56a5f2d/ascreenshot.jpeg?tl_px=163,132&br_px=1710,997&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">6.</span> Select a Link Type:
				<br />
				<br />
				Generic = Any external link i.e. <a href="https://www.google.com">https://www.google.com</a>
				<br />
				Modal = This will open any Content Page in a popup or modal window
				<br />
				Sport = Link to any sport i.e. Football
				<br />
				Event = Link to a specific event
				<br />
				Casino = Link to a casino game
				<br />
				Page = Link to any page created under Pages
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/23d66d58-25f6-45e9-ad2c-3a44beb88e3c/ascreenshot.jpeg?tl_px=0,452&br_px=1547,1317&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=332,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">7.</span> Select a Start Date for your promotion.
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/f1608c30-7811-4d33-b981-9bde1dc38a36/ascreenshot.jpeg?tl_px=0,562&br_px=1547,1427&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=423,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">8.</span> Select an End Date for your promotion or select Never Expire.
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/cda5b131-b9e8-4f19-9580-727882e9a482/ascreenshot.jpeg?tl_px=0,657&br_px=1547,1522&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=308,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">9.</span> You can choose to show the promotion on Specific Days.
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/cc851ce4-00b8-43c8-b3d2-5b6be503bf48/ascreenshot.jpeg?tl_px=0,718&br_px=1547,1583&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=288,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">10.</span> You can choose to show the promotion during specific times.
			</p>

			<p className="scribe-screenshot-container">
				<img
					className="scribe-screenshot"
					width="560"
					alt=""
					src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-19/3dbf65bf-38d0-433c-a329-9ed175772f11/ascreenshot.jpeg?tl_px=0,794&br_px=1547,1659&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=288,277"
				/>
			</p>

			<p className="scribe-step">
				<span className="number">11.</span> Remember to save your Betslip Promo.
			</p>
		</body>
	);
};

export const BogHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">Configure BOG Settings</h1>
				<p className="scribe-description">
					This guide explains how to enable and customize the Best Odds Guarantee (BOG) feature for a trading platform. It provides step-by-step
					instructions to navigate to the settings page and enable the global BOG. It also explains how to set the maximum payout per bet, the end
					time for BOG, and select the countries and bet types to offer BOG on. Following this guide will allow users to configure and save their BOG
					settings effectively.
				</p>
				<br />
				<br />
				<p className="scribe-step">
					<span className="number">1</span> Navigate to Settings -&gt; Trader Options -&gt; BOG Settings
				</p>
				<p className="scribe-step">
					<span className="number">2</span> Enable the global Best Odds Guarantee.
					<br />
					<br />
					This will enable the Best Odds Guarantee for all users.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-09/12183939-58c9-43fc-b0e9-ae2923dcb914/ascreenshot.jpeg?tl_px=39,0&br_px=1586,865&force_format=png&width=1120.0&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=524,168"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">3</span> The BOG Max Payout is the maximum amount that a player can get from BOG per bet. <br />
					<br />
					The default value for BOG is 5000 per bet. <br />
					Enter the total amount you want to pay a user per bet for BOG.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-09/7ab669a6-e307-4709-a502-d6679a0d8359/File.jpeg?tl_px=59,0&br_px=1606,865&force_format=png&width=1120.0&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=524,230"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">4</span> The BOG end time is in (24h format). <br />
					<br />
					The default value for BOG end time is 9 am. <br />
					Enter the preferred end time for BOG.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-09/d2bc500f-f70b-4ce6-bdbe-61b788619630/ascreenshot.jpeg?tl_px=19,13&br_px=1566,878&force_format=png&width=1120.0&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=523,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">5</span> Select the countries that the events occur that you would like to offer BOG.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-09/79cbe64b-4711-4736-b386-74793a4b1482/ascreenshot.jpeg?tl_px=320,142&br_px=1867,1007&force_format=png&width=1120.0&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">6</span> Select the Bet Types that you would like to offer BOG on. i.e. Singles, Doubles etc
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-09/ab6f0a5a-0b0b-420d-a83d-fe0459c481a7/ascreenshot.jpeg?tl_px=86,394&br_px=1633,1259&force_format=png&width=1120.0&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">7</span> Remember to Save your settings
				</p>
			</body>
		</html>
	);
};

export const CashOutHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">Configure Cash Out Options</h1>
				<p className="scribe-description">
					This guide explains how to configure cash out options for a trading platform. By following the steps, users can enable cash out for all
					users, add selection deductions, customize cash out value deductions, select specific bet types for cash out, and exclude certain sports
					from cash out.
				</p>
				<br />
				<br />
				<p className="scribe-step">
					<span className="number">1</span> Navigate to Settings -&gt; Trader Options -&gt; Cash Out
				</p>
				<p className="scribe-step">
					<span className="number">2</span> Enable the Global Cash Out.
					<br />
					<br />
					This will enable Cash Out for all users.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/be229592-b7be-422f-8c12-aaacf1d4fa4c/ascreenshot.jpeg?tl_px=21,0&br_px=1568,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=523,170"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">3</span> For each selection that is still in play, you can add a selection deduction for Cash Out.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/a32cd809-54bc-4963-a93f-b13ac487b541/ascreenshot.jpeg?tl_px=552,0&br_px=2099,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=523,231"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">4</span> The Cash Out Value Deduction will be on the total Bet Slip. This can be changed per user under the
					customer settings.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/add43747-654a-4268-bda7-608625f18285/ascreenshot.jpeg?tl_px=552,16&br_px=2099,881&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=523,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">5</span> Select the Bet Types that you want to offer Cash Out on.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/7013384a-4820-46f6-9782-bff94081cf4d/ascreenshot.jpeg?tl_px=561,108&br_px=2108,973&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">6</span> You can exclude certain sports from Cash Out.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/6794fa00-f4b0-4ddd-9c2c-3da6c5d6ea39/ascreenshot.jpeg?tl_px=565,472&br_px=2112,1337&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,276"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">7</span> Remember to Save your settings
				</p>
				<br />
			</body>
		</html>
	);
};

export const CountryHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">Configure Country Settings</h1>
				<p className="scribe-description">
					This guide provides step-by-step instructions on how to configure country settings on a platform. It covers various settings such as
					geoblocking, currency, odd format, KYC requirements, phone number verification, deposit limits, and KYC verification for deposits and
					withdrawals. By following this guide, users can customize the platform&apos;s functionality and restrictions based on specific countries,
					ensuring compliance and a tailored user experience.
				</p>
				<br />
				<br />
				<p className="scribe-step">
					<span className="number">1</span> Navigate to Settings -&gt; Countries
				</p>
				<p className="scribe-step">
					<span className="number">2</span> Find the country that you want to configure.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/3c2a17b6-361c-4254-b484-bfc4f6435099/ascreenshot.jpeg?tl_px=111,0&br_px=1658,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,86"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">3</span> Click the edit icon to modify the country settings
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/ab3c8226-7068-41b7-a2df-64474e7072e1/ascreenshot.jpeg?tl_px=1908,0&br_px=3456,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=976,233"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">4</span> The Master Geoblock will determine whether the platform will be available in this country. Turning this on
					will display the customer services message saying that this country is restricted.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/2112a658-bda9-4f16-b1e8-cc09b742105a/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=306,195"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">5</span> Enabling the Mobile Geoblock will restrict the mobile applications from being used within the specified
					country.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/e1ec6939-63d5-44f4-afd2-815cf4368e96/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=286,247"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">6</span> The Disable Reason is a note for yourself and is not used anywhere else in the platform.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/50ad2dae-391b-4a64-9b98-a00a8507a271/ascreenshot.jpeg?tl_px=0,24&br_px=1547,889&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=294,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">7</span> By default, we have a list of countries with the respective currencies. If you wanted to change the
					Default Currency for a specific country you can do that by selecting a different currency from the dropdown.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/765b4183-9a4c-4899-b725-70907456317a/ascreenshot.jpeg?tl_px=0,232&br_px=1547,1097&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=399,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">8</span> Change the preferred Odd Format for any new user that signs up on the platform. This will change for any
					user that signs up as a resident of the specified country.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/8a392aa4-6178-4568-a58b-fe8b3558b600/ascreenshot.jpeg?tl_px=0,263&br_px=1547,1128&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=334,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">9</span> Enabling KYC During Onboarding will determine whether or not the KYC onboarding journey will be enabled
					when a user first signs up from the specified country.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/2929ad3f-775d-485a-8b0a-94b6f30d5340/ascreenshot.jpeg?tl_px=0,349&br_px=1547,1214&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=294,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">10</span> Enabling Verify Phone Number will determine whether or not the mobile number will be verified when a user
					first signs up from the specified country.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/1de4db04-5c0f-4109-82d4-0bf889fd78c3/ascreenshot.jpeg?tl_px=0,436&br_px=1547,1301&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=291,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">11</span> Enabling Phone Prefix to Match Country will determine whether or not the phone number will need to be
					from the same country as the specified country of where the user signed up from.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/666cd906-191c-4b97-94b0-5cd7af93d030/ascreenshot.jpeg?tl_px=0,531&br_px=1547,1396&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=299,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">12</span> Enabling Set Deposit Limits on Initial Deposit will show the responsible gambling limits page prior to
					someone depositing for the first time for the specified country.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/01f76b1b-de11-4575-aa5e-ba0cec995a1d/ascreenshot.jpeg?tl_px=0,686&br_px=1547,1551&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=296,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">13</span> Enabling Require Number will check to see if a mobile number is registered under the player&apos;s
					account before any deposits or withdrawals can be made for the specified country.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/c06b6043-bd48-4cbf-bdb7-a597cf32b712/ascreenshot.jpeg?tl_px=0,765&br_px=1547,1630&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=294,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">14</span> Enabling Check KYC Verified on Deposits will check to see if the KYC has been verified before any
					deposits can be made for the specified country.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/33ffe73e-37a3-404a-97f9-c9af2712bc35/ascreenshot.jpeg?tl_px=0,862&br_px=1547,1727&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=294,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">15</span> Enabling Check KYC Verified on Withdrawals will check to see if the KYC has been verified before any
					withdrawals can be made for the specified country.
				</p>
				<p className="scribe-step">
					<span className="number">9</span> Remember to Save your settings
				</p>
				<br />
			</body>
		</html>
	);
};

export const TraderChatHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">Configure Trader Chat Options</h1>
				<p className="scribe-description">
					This guide provides step-by-step instructions on how to configure Trader Chat options. By following these steps, users can enable or disable
					Trader Chat for all users or specific individuals, set specific days for Trader Chat availability, and specify the start and end times for
					Trader Chat.
				</p>
				<br />
				<br />
				<p className="scribe-step">
					<span className="number">1</span> Navigate to Settings -&gt; Trader Options -&gt; Trader Chat
				</p>
				<p className="scribe-step">
					<span className="number">2</span> Enable the Global Trader Chat for all users. You can disable Trader Chat per user under customer settings
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/0bf6d9db-bf5b-44b9-a180-7dce0c99d0a9/ascreenshot.jpeg?tl_px=39,0&br_px=1586,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,174"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">3</span> You can enable Trader Chat for certain days by specifying the days here
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/aecc63ed-558c-49b2-b441-2d39430ebcdd/ascreenshot.jpeg?tl_px=37,0&br_px=1584,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,229"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">4</span> You can specify the start and end time that you want Trader Chat to be enabled.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-16/9010af24-3c37-41bb-ad22-3df0ebb0dd67/ascreenshot.jpeg?tl_px=390,301&br_px=1937,1166&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=523,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">5</span> Remember to Save your settings
				</p>
				<br />
			</body>
		</html>
	);
};

export const BetOptionHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">Configure Trading and Betting Options</h1>
				<p className="scribe-description">
					This guide provides step-by-step instructions on how to configure trading and betting options. By following these steps, users can customize
					various settings such as minimum bet amount, maximum bet return, in-play delay, max folds, global bet referral, and bet referral auto
					cancellation.
				</p>
				<br />
				<br />
				<p className="scribe-step">
					<span className="number">1</span> Navigate to Settings -&gt; Trader Options -&gt; Bet Options
				</p>
				<p className="scribe-step">
					<span className="number">2</span> The Minimum Bet Amount is the lowest stake amount allowed to be placed
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/9174dd99-5a87-4015-a943-c6cc781156e5/ascreenshot.jpeg?tl_px=109,0&br_px=1656,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,171"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">3</span> The Max Bet Return is the maximum bet return based on the users Odds and Stake.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/9f036a47-fb9d-4447-804c-9bb628408a69/ascreenshot.jpeg?tl_px=70,0&br_px=1617,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,230"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">4</span> In-Play Delay is the number of seconds that the platform checks to see if there have been any Odds Changes
					by the feed provider.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/af461a0a-1589-4976-945b-5b0cd0cb694e/ascreenshot.jpeg?tl_px=39,9&br_px=1586,874&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">5</span> Max Folds is the total number of accumulator / folds that you want to permit on the bet slip.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/91a17af5-e604-423e-a8cc-1d69943bdf77/ascreenshot.jpeg?tl_px=64,83&br_px=1611,948&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">6</span> Global Bet Referral when enabled sends bets that go over the liability and max bet returns to the Bet
					Ticker. This then allows a Trader to decide whether they would like to accept the bet.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/7973dc82-1db6-4638-a310-e82cd944f880/ascreenshot.jpeg?tl_px=28,182&br_px=1575,1047&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">7</span> The Bet Referral Auto Cancellation (Min.) is the number of minutes after a bet is sent for a referral and
					a trader did not make any action on that bet. After this time the bet is auto cancelled.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/349db58c-4226-4bed-98ef-5fdd990d1afb/ascreenshot.jpeg?tl_px=80,288&br_px=1627,1153&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">8</span> Bet Referral User Auto Cancellation (Sec.) is the number of seconds a user has to accept or reject an
					offer made from a Trader through Bet Referral.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-10-10/66348860-fc88-41f2-897a-9621ad9fb6c6/ascreenshot.jpeg?tl_px=88,400&br_px=1635,1265&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">9</span> Remember to Save your settings
				</p>
			</body>
		</html>
	);
};

export const LadderHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">How to create or update a ladder</h1>
				<p className="scribe-description">
					This guide provides step-by-step instructions on how to create or update ladders. It explains how to enable the custom ladder, download and
					import a template, copy from another ladder, and add or remove ladders from the list.
				</p>

				<p className="scribe-step">
					<span className="number">1.</span> Navigate to Settings -&gt; Ladders
				</p>

				<p className="scribe-step">
					<span className="number">2.</span> If Custom Ladder is disabled the Sport will fallback to use the Master Ladder. Enabling the Custom Ladder
					will allow the user to specify a new Ladder for that Sport.
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-11/04ed479a-58f6-4660-9256-3f763148d4cf/ascreenshot.jpeg?tl_px=23,0&br_px=1570,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,160"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">3.</span> Clicking &quot;Get Template&quot; will download a csv based template that you can add the decimal and
					fractional pairings to in Excel, Numbers or any text editor. This can later be imported to the platform.
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-11/0764c7a6-f862-469f-90b5-1e6beebeb6d3/ascreenshot.jpeg?tl_px=439,0&br_px=1986,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,81"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">4.</span> Click &quot;Import&quot; allows you to import the CSV file to automatically generate the Ladder for that
					specific sport.
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-11/ae398cd8-46bd-4e17-aff4-be4e531fe8dd/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=448,80"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">5.</span> You can &quot;Copy From&quot; another ladder by clicking the &quot;Copy From&quot; button. This will
					allow you to take the same ladder from another sport or Master Ladder.
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-11/8f5e6c2f-415f-4cea-8b57-76d8df8889f5/ascreenshot.jpeg?tl_px=170,0&br_px=1717,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,80"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">6.</span> Selecting the Master or Sport and Clicking Save will import that ladder from another sport.
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-11/a12becac-52a6-4726-8d4a-6b33fffe0f77/ascreenshot.jpeg?tl_px=867,459&br_px=2414,1324&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=523,277"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">7.</span> You can add and remove any ladder from the list. You must add the Decimal and Fractional pairing.
				</p>

				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-11/f2e1bd32-a314-4d91-98a2-4bf9181bb58f/ascreenshot.jpeg?tl_px=374,962&br_px=1921,1828&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,466"
					/>
				</p>

				<p className="scribe-step">
					<span className="number">8.</span> Remember to save your settings
				</p>
			</body>
		</html>
	);
};

export const UserHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">Add a new user to the CMS system</h1>
				<p className="scribe-description">
					This guide provides step-by-step instructions on how to add a new user to the CMS system. It covers important details such as entering the
					user&apos;s personal information, selecting their role, setting their email address and timezone, enabling 2FA for security, and saving the
					new user. Following this guide will ensure a smooth and secure process for adding new users to the CMS system.
				</p>
				<br />
				<br />
				<p className="scribe-step">
					<span className="number">1.</span> Navigate to Settings -&gt; CMS Users
				</p>
				<p className="scribe-step">
					<span className="number">2.</span> Click the plus icon to Add a new user
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/6104968b-4db5-468c-8f70-9afd20724ee8/ascreenshot.jpeg?tl_px=1908,0&br_px=3456,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=1029,96"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">3.</span> Enter the person&apos;s First Name
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/baa3058d-b1b7-4a35-9bd8-ffcefc7e4703/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=334,138"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">4.</span> Enter the person&apos;s Last Name
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/d000d77a-ccc6-47ea-9812-71397c5b3f35/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=319,196"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">5.</span> Select the Role that best fits the user&apos;s position. This will restrict access to the platform based
					on the role.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/0364e10b-9157-4595-b8c1-8ae7938ca788/ascreenshot.jpeg?tl_px=0,38&br_px=1547,903&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=371,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">6.</span> Enter the user&apos;s email address. An invite with their temporary password will go to this address.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/41e1c9ef-537c-4db1-b4bb-dd83a3d98116/ascreenshot.jpeg?tl_px=0,61&br_px=1547,926&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=382,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">7.</span> Enter the user&apos;s timezone. This will show logs, search history, events, etc., all in the user&apos;s
					local time.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/70ac29c6-39e6-45d5-a395-6b85c7f704bb/ascreenshot.jpeg?tl_px=0,144&br_px=1547,1009&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=523,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">8.</span> We advise keeping 2FA enabled for security purposes. The user will need to enter a six-digit code when
					they are using an untrusted browser.
				</p>
				<p className="scribe-step">
					<span className="number">9.</span> Remember to save the new user
				</p>
			</body>
		</html>
	);
};

export const SeoHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">Guide for setting up the master SEO settings</h1>
				<p className="scribe-description">
					This guide provides step-by-step instructions for setting up the master SEO settings on a website. It covers important aspects such as
					adding a favicon, setting a generic title and SEO description, adding SEO keywords, specifying a share image for social media, and adding
					Google header and body tags for tracking user visits. Following this guide will ensure that your website is optimized for search engines and
					properly tracked for analytics.
				</p>
				<p className="scribe-step">
					<span className="number">1.</span> Navigate to Settings -&gt; SEO
				</p>
				<p className="scribe-step">
					<span className="number">2.</span> Upload a Fav icon. This can be added in PNG or JPG
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/635cb11e-1272-4769-9d07-46c6a2c20177/ascreenshot.jpeg?tl_px=273,0&br_px=1820,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,246"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">3.</span> Add a title. This is a generic Title that will be used as a fallback in case the SEO information is
					missing from a specific page.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/6c466a82-bcc5-405c-af21-5b7b43cdf82e/ascreenshot.jpeg?tl_px=134,150&br_px=1681,1015&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">4.</span> Enter the SEO description.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/9bf37fe6-e7f6-435b-91ab-d6d5475c6e4d/ascreenshot.jpeg?tl_px=541,303&br_px=2088,1168&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">5.</span> Enter the SEO Keywords
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/eb49020e-ba9e-4794-9b1d-04a2a84c6bc0/ascreenshot.jpeg?tl_px=187,362&br_px=1734,1227&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=523,276"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">6.</span> Add the Share Image. This is the image that will be used on Twitter, Facebook, Whatsapp etc
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/e968508a-38ba-41de-8a33-52ee83af27a9/ascreenshot.jpeg?tl_px=286,542&br_px=1833,1407&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=524,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">7.</span> Enter the Google Header Tag. This is so you can track user visits to your site.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/c41da004-0185-42d2-a6ad-b30ddc734c3d/ascreenshot.jpeg?tl_px=212,778&br_px=1759,1643&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=523,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">8.</span> Enter the Google Body Tag. This is so you can track user visits to your site.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/0330dc65-1eaf-4865-977d-4c56a918459a/ascreenshot.jpeg?tl_px=104,911&br_px=1651,1776&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=523,277"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">9.</span> Remember to save your settings
				</p>
			</body>
		</html>
	);
};
export const CssHelpInformation = () => {
	return (
		<html>
			<body className="help_pages_body">
				<h1 className="scribe-title">How to Customize the Style and Logo</h1>
				<p className="scribe-description">
					This guide provides step-by-step instructions on how to customize the style and logo of a website. It covers uploading logos for desktop and
					mobile and overriding default styles and colors with custom CSS. By following this guide, users can easily personalize the appearance and
					branding of the website.
				</p>
				<br />
				<br />
				<p className="scribe-step">
					<span className="number">1.</span> Navigate to Settings -&gt; Style CSS
				</p>
				<p className="scribe-step">
					<span className="number">2.</span> Click &quot;Upload Logo&quot;
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/9bd6abbd-0470-4c6c-be37-90389090ad9e/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=466,99"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">3.</span> Upload the image used for both the desktop and mobile logo.
					<br />
					<br />
					Best aspect ratio for mobile logo is 1:1
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/f2357572-090d-46a6-b4bb-b13b5f213d8e/ascreenshot.jpeg?tl_px=0,0&br_px=1547,865&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=366,228"
					/>
				</p>
				<p className="scribe-step">
					<span className="number">4.</span> You can override any of the styles and colors within the platform by adding your own CSS into the Code
					Editor. In this example, we have changed all Header 1 text to red.
				</p>
				<p className="scribe-screenshot-container">
					<img
						className="scribe-screenshot"
						width="560"
						alt=""
						src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-11-05/f4b1222b-71c9-4504-8d00-45c479819e5a/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2948,1844&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/EAB308_standard.png&wat_pad=1030,28"
					/>
				</p>
			</body>
		</html>
	);
};
