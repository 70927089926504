import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import MetaTags from "react-meta-tags";

import { Alert, Card, Col, Container, Row } from "reactstrap";
// Redux
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// actions
import { apiError, cmsLoginUser, loginUser } from "../../store/actions";

// import images
import ApiServices from "utils/ApiServices";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import { loginLogo, platformName } from "utils/constants";
import SignInFooter from "./SignInFooter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import xIcon from "../../assets/images/x-icon.svg";
import "./Authentication.scss";

const Login = props => {
	// States
	const [spinningLogin, setSpinningLogin] = useState(false);
	const dispatch = useDispatch();
	// New url

	// Login data
	const [loginData, setLoginData] = useState({
		email: "",
		password: "",
	});
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const eyeIcon = showPassword ? faEyeSlash : faEye;
	const history = useHistory();

	useEffect(() => {
		const access_token = localStorage.getItem("access_token");
		if (access_token) {
			history.push("/");
		}
		document.body.classList.add("login-body");
		return () => {
			document.body.classList.remove("login-body");
		};
	}, []);

	// ### Handlers
	const handleSubmit = e => {
		e.preventDefault();
		// clear session
		sessionStorage.setItem("session_key", "");
		sessionStorage.setItem("access_token", "");
		sessionStorage.setItem("refresh_token", "");
		sessionStorage.setItem("email", "");

		setSpinningLogin(true);
		if (!loginData.email) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter email</p>
				</>,
				{ className: "custom-toast" }
			);
			setSpinningLogin(false);
			return false;
		}
		if (!loginData.password) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter password</p>
				</>,
				{ className: "custom-toast" }
			);
			setSpinningLogin(false);
			return false;
		}

		let data = loginData;
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.sign_in, resolve, reject, data, false);
		})
			.then(response => {
				var data = response.data;
				// If user needs to give new password
				if (data?.data?.challenge_name == "NEW_PASSWORD_REQUIRED") {
					sessionStorage.setItem("session_key", data?.data?.session);
					sessionStorage.setItem("email", loginData.email);
					props.history.push("/sign_in_first");
				} else if (data?.data?.challenge_name == "SWIFTY_MFA_SETUP") {
					sessionStorage.setItem("qr_code", data?.data?.qrcode);
					sessionStorage.setItem("manual_key", data?.data?.manual_key);
					sessionStorage.setItem("email", loginData.email);
					sessionStorage.setItem("password", loginData.password);
					props.history.push("/two_fa");
				} else if (data?.data?.challenge_name == "SOFTWARE_TOKEN_MFA") {
					props.history.push("/mfa_signin");
					sessionStorage.setItem("email", loginData.email);
					sessionStorage.setItem("session_key", data?.data?.session);
				} else if (data.userData?.blocked == "1") {
					props.history.push("/blocked");
				} else {
					localStorage.setItem("email", loginData.email);
					localStorage.setItem("access_token", data.access_token);
					localStorage.setItem("refresh_token", data.refresh_token);

					// remove the data from sessionStorage
					sessionStorage.removeItem("qr_code");
					sessionStorage.removeItem("manual_key");
					sessionStorage.removeItem("email");
					sessionStorage.removeItem("session_key");
					sessionStorage.removeItem("access_token");
					sessionStorage.removeItem("refresh_token");

					var user = [];
					var user_data = {};
					user_data = data.userData;
					user.push(user_data);
					if (user && user.length > 0) {
						localStorage.setItem("user", JSON.stringify(user_data));
					}
					dispatch(cmsLoginUser(user[0]));
					props.history.push("/");
				}
			})
			.catch(error => {
				console.log(error);
				if (error?.message.code == "PasswordResetRequiredException") {
					props.history.push("/reset_password");
				}
				if (error?.message.messageCode == 69) {
					props.history.push("/blocked");
				}
			})
			.finally(() => {
				setSpinningLogin(false);
			});
	};

	const handlerEmailInput = e => {
		setLoginData({
			...loginData,
			email: e.target.value,
		});
	};
	const handlerPasswordInput = e => {
		setLoginData({
			...loginData,
			password: e.target.value,
		});
	};

	return (
		<React.Fragment>
			<MetaTags>
				<title>Login | CMS | {platformName}</title>
			</MetaTags>
			<div className="account-pages my-0 pt-sm-5 my-sm-5">
				<Container>
					<Row className="justify-content-center login_container">
						<Col md={8} lg={6} xl={5} className="login-flow-card">
							<Card className="overflow-hidden login-card">
								<h3 className="default-logo-login text-center mt-5">
									<div className="d-block auth-logo">
										<img src={loginLogo} alt="" height="60" width="200" className="auth-logo-dark" style={{ cursor: "auto" }} />
									</div>
								</h3>
								<h4 className="login-title">Welcome Back!</h4>
								<AvForm
									className="form-horizontal login-form"
									onSubmit={e => {
										handleSubmit(e);
									}}
								>
									{props.error && typeof props.error === "string" ? <Alert color="danger">{props.error}</Alert> : null}

									<div className="mb-4 login-label">
										<AvField
											name="email"
											label="Email"
											placeholder="Email"
											className="form-control login-input"
											type="email"
											value={loginData.email}
											onChange={e => {
												handlerEmailInput(e);
											}}
											onKeyUp={e => {
												handlerEmailInput(e);
											}}
										/>
									</div>

									<div className="mb-2 login-label">
										<div className="password-input-wrapper">
											<AvField
												name="password"
												label="Password"
												placeholder="Password"
												type={showPassword ? "text" : "password"}
												className="login-input"
												value={loginData.password}
												onChange={handlerPasswordInput}
												onKeyUp={handlerPasswordInput}
												onInput={e => {
													e.preventDefault();
												}}
											/>
											<span className="password-toggle-icon" onClick={togglePasswordVisibility}>
												<FontAwesomeIcon icon={eyeIcon} />
											</span>
										</div>
									</div>

									<div className="row login-flow-row ms-0">
										<div className="form-group m-0 row p-0">
											<div className="col-12 p-0 login-forget-label">
												<Link to="/forgot-password" className="login-forget">
													Forgot password?
												</Link>
											</div>
										</div>
										<div className="p-0 login-label">
											<button className="login-button form-control" type="submit">
												{spinningLogin ? <Spinner animation="border" size="sm" /> : "Log in"}
											</button>
										</div>
									</div>
								</AvForm>
							</Card>
							<SignInFooter />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	const { error } = state.Login;
	return { error };
};

export default withRouter(connect(mapStateToProps, { loginUser, apiError })(Login));

Login.propTypes = {
	error: PropTypes.any,
	history: PropTypes.object,
	loginUser: PropTypes.func,
};
