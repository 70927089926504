import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let result = [];
export const getCasinoProviders = async (addAllProviders = true) => {
	if (result.length > 0 && !addAllProviders) return result;

	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.GET_CASINO_PROVIDERS, resolve, reject, true);
	})
		.then(response => {
			const { data } = response;
			const studioData = data.map(({ slug, name }) => {
				return { id: slug, label: name };
			});

			addAllProviders && studioData.unshift({ id: "all", label: "All Studios" });

			result = studioData;
			return studioData;
		})
		.catch(() => {
			return [];
		});
};
