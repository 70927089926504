import React from "react";

export const getLabelName = title => {
	switch (title) {
		case "address_line1":
			return "Adress";
		case "address_state":
			return "State";
		case "phone_number_verified":
			return "Phone Verification";
		case "currency":
			return "Currency";
		case "date_of_birth":
			return "Date of birth";
		case "kyc_status":
			return "Kyc Status";
		case "first_name":
			return "First Name";
		case "last_name":
			return "Last Name";
		case "phonePrefix":
			return "Prefix";
		case "phoneNumber":
		case "phone_number":
			return "Phone";
		case "phone_prefix":
			return "Phone Prefix";
		case "account_status":
			return "Account Status";
		case "email":
			return "Email";
		case "addressLine1":
			return "Address";
		case "sof_date":
			return "SoF";
		case "addressCity":
		case "address_city":
			return "City";
		case "addressZip":
			return "Post Code";
		case "failed_login_attempts":
			return "Failed login attempts";
		case "country":
			return "Country";
		case "language":
			return "Language";
		case "address_zip":
			return "Zip Code";
		case "affiliateTag":
		case "affiliate_tag":
			return "Affiliate Tag";
		case "accountStatus":
			return "Account Status";
		case "ageVerified":
			return "Age Verified";
		case "sof":
			return "SoF";
		case "bet_referral_enabled":
			return "Bet referral ";
		case "bog_enabled":
			return "Bog ";
		case "edd":
		case "edd_date":
			return "EDD";
		case "ringFencedFunds":
		case "ring_fenced_funds_enabled":
			return "Ring-fenced Funds";
		case "contactPreferenceEmail":
		case "contact_preference_email":
			return "Email Preference";
		case "contactPreferencePhone":
		case "contact_preference_phone":
			return "Phone Preference";
		case "contactPreferenceSms":
		case "contact_preference_sms":
			return "SMS Preference";
		case "account_suspended_until":
		case "cash_enabled":
			return "Cash Enabled";
		case "account_suspend":
		case "suspend_date_until":
			return "SMS Preference";
		case "trader_chat_enabled":
			return "Trader Chat";
		case "bog_max_payout":
			return "Bog Max Payout";
		case "bonus_engine":
			return "Bonus Engine";
		case "prematch_stake_factor":
			return "Prematch Stake Factor";
		case "self_exclude_until":
			return "Self Exclude";
		case "inplay_stake_factor":
			return "Inplay Stake Factor";
		case "cash_out_reduction":
			return "Cash Out Reduction";
		case "deposit_limit_daily":
			return "Deposit Limit Daily";
		case "deposit_limit_weekly":
			return "Deposit Limit Weekly";
		case "deposit_limit_monthly":
			return "Deposit Limit Monthly";
		case "account_manager_id":
			return "Accout Manager ID";
		case "prematch_prematch_stake_factor":
			return "SF Prematch";
		case "prematch_soccer":
			return "SF Prematch Football";
		case "prematch_basketball":
			return "SF Prematch Basketball";
		case "prematch_cricket":
			return "SF Prematch Cricket";
		case "prematch_festive":
			return "SF Prematch Festive";
		case "prematch_greyhoundracing":
			return "SF Prematch Dog Racing";
		case "prematch_handball":
			return "SF Prematch Handball";
		case "prematch_horseracing":
			return "SF Premach Horse Racing";
		case "prematch_icehockey":
			return "SF Premach Ice Hockey";
		case "prematch_motorsport":
			return "SF Premach Motorsport";
		case "prematch_rugbyunion":
			return "SF Premach RugbyUnion";
		case "prematch_tennis":
			return "SF Premach Tennis";
		case "inplay_prematch_stake_factor":
			return "SF Inplay Stake Factor";
		case "inplay_soccer":
			return "SF Inplay Football";
		case "inplay_baseball":
			return "SF Inplay Baseball";
		case "inplay_basketball":
			return "SF Inplay Basketball";
		case "inplay_cricket":
			return "SF Inplay Cricket";
		case "inplay_festive":
			return "SF Inplay Festive";
		case "inplay_greyhoundracing":
			return "SF Inplay Dog Racing";
		case "inplay_handball":
			return "SF Inplay Handball";
		case "inplay_horseracing":
			return "SF Inplay Horse Racing";
		case "inplay_icehockey":
			return "SF Inplay Ice Hockey";
		case "inplay_motorsport":
			return "SF Inplay Motorsport";
		case "inplay_rugbyunion":
			return "SF Inplay RugbyUnion";
		case "inplay_tennis":
			return "SF Inplay Tennis";

		default:
			return title;
	}
};

export const getAuditlogDetailsColumns = ({ auditlogDetails }) => {
	return [
		{
			name: "id",
			label: "FIELD",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = auditlogDetails[meta.currentTableData[meta.rowIndex].index];
					let title = row?.field;
					return <div>{getLabelName(title)}</div>;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "33%" } }),
			},
		},

		{
			name: "previous_value",
			label: "PREVIOUS VALUE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = auditlogDetails[meta.currentTableData[meta.rowIndex].index];
					return (
						<a href={"/user/" + record?.previous_value} target={"_blank"} rel="noreferrer" className="real_id_class">
							{record?.previous_value || "-"}
						</a>
					);
				},

				setCellProps: () => ({ style: { textAlign: "left", width: "33%" } }),
			},
		},
		{
			name: "new_value",
			label: "NEW VALUE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = auditlogDetails[meta.currentTableData[meta.rowIndex].index];
					let title = row?.new_value;
					let formattedTitle = title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
					return <>{formattedTitle}</>;
				},

				setCellProps: () => ({ style: { textAlign: "left", width: "33%" } }),
			},
		},
	];
};
