import React, { useEffect, useState } from "react";
import { DeleteIconButton, NoButton, YesButton } from "components/GeneralComponents/AddButton";
import { GeneralDropdown, LadderInputGeneral, SwitchInput } from "components/GeneralComponents/CustomInputs";
import { CustumInputLadder } from "components/GeneralComponents/TopBarComponent";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { AddIcon, InfoIconHelper } from "utils/icons";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { toast } from "react-toastify";
import xIcon from "../../../assets/images/x-icon.svg";
import { getAllSportsDropdown } from "services/getAllSportsDropdown";
import "./ladders.css";
import { useDispatch } from "react-redux";
import { setBackdrop } from "store/Backdrop/actions";
import InformationPageHelper from "../InformationPageHelper";
import { LadderHelpInformation } from "../HelpPagesForSettings/HelpPages";
import { AvForm } from "availity-reactstrap-validation";
import { disableInputs, userReadOnly } from "utils/global";

function Ladders(props) {
	let { id } = props.match.params;
	const [dataChanged, setDataChanged] = useState(false);
	const [unchangedData, setUnchangedData] = useState(false);
	const [data, setData] = useState();
	const [initialLadderArray, setinitialLadderArray] = useState([]);
	const [addLadderArray, setAddLadderArray] = useState([]);
	const [ladderArray, setLadderArray] = useState([]);
	const [copyFromModal, setCopyFromModal] = useState(false);
	const [sports, setSports] = useState([]);
	const [selectedSlug, setSelectedSlug] = useState("");
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const [ladderAddRow, setLadderAddRow] = useState({
		in_decimal: "",
		in_fraction: "",
		sport_slug: `${id ? id : "master"}`,
	});
	const [unchangedLadderAddRow, setUnchangedLadderRow] = useState({
		in_decimal: "",
		in_fraction: "",
		sport_slug: `${id ? id : "master"}`,
	});
	const dispatch = useDispatch();

	const [minHeight, setMinHeight] = useState(false);

	//Get All Ladders
	const getAllLaders = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_ladder + `${id ? id : "master"}/settings`, resolve, reject, true);
		}).then(response => {
			setData({
				...data,
				custom_ladder: response?.data.setting.is_custom,
			});
			setUnchangedData(response?.data.ladders);
			setinitialLadderArray(response?.data.ladders);
			setLadderArray(response?.data.ladders);
			setAddLadderArray(response?.data.ladders);
		});
	};

	//Function to add/update multi ladders
	const addLadderHandler = () => {
		let payload = addLadderArray;
		let updatedArrayLadder = [];
		let addArrayLadder = [];

		let isAlertThrown = false;
		let hasError = false;
		ladderArray.map(item => {
			if (item.in_decimal || item.in_fraction) {
				if (item.in_decimal && item.in_fraction) {
					addArrayLadder.push(item);
				} else {
					if (!isAlertThrown) {
						toast.error(
							<>
								<img src={xIcon} alt="Error" />
								<p className="toast-icon-manual-message">Please fill all the fields</p>
							</>,
							{ className: "custom-toast" }
						);
						isAlertThrown = true;
						hasError = true;
					}
				}
			}
		});
		if (hasError) {
			return; // Exit the function if there was an error
		}
		if (ladderArray.length > 0) {
			ladderArray.map((item, index) => {
				if (item?.id) {
					if (item?.in_decimal != initialLadderArray[index]?.in_decimal || item?.in_fraction != initialLadderArray[index]?.in_fraction) {
						payload[index] = item;
						updatedArrayLadder.push(item);
					}
				}
			});
		}

		if (updatedArrayLadder.length > 0) {
			let payload = { ladders: updatedArrayLadder };
			new Promise((resolve, reject) => {
				ApiServices.put(apiUrls.get_ladder + `${id ? id : "master"}`, resolve, reject, payload, true);
			})
				.then(() => {})
				.finally(() => {
					setDataChanged(false);
				});
		}
	};

	const discardData = () => {
		setLadderArray(unchangedData);
		setDataChanged(false);
		setLadderAddRow(unchangedLadderAddRow);
	};

	useEffect(() => {
		getAllLaders();
		setLadderAddRow(prevState => ({ ...prevState, sport_slug: id || "master" }));
		setUnchangedLadderRow(prevState => ({ ...prevState, sport_slug: id || "master" }));
	}, [id]);

	const handleFirst = () => {
		const fileInput = document.querySelector('input[type="file"]');
		fileInput.click();
	};
	const handleSecond = () => {
		setCopyFromModal(true);
	};

	const tolerance = 0.0;
	let sameDecimalValue = ladderArray.some(row => {
		if (row.in_decimal !== null && ladderAddRow.in_decimal !== null) {
			const decimal1 = parseFloat(row.in_decimal);
			const decimal2 = parseFloat(ladderAddRow.in_decimal);
			return Math.abs(decimal1 - decimal2) <= tolerance;
		}
		return false;
	});
	let sameFractionValue = ladderArray.some(row => {
		if (row.in_fraction !== null && ladderAddRow.in_fraction !== null) {
			return row.in_fraction === ladderAddRow.in_fraction;
		}
		return false;
	});
	const addRow = () => {
		console.log(ladderAddRow);
		if (!ladderAddRow || ladderAddRow.in_decimal === "" || ladderAddRow.in_fraction === "") {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">All fields must be filled</p>
				</>,
				{ className: "custom-toast" }
			);
		} else if (sameDecimalValue) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Decimal value exists</p>
				</>,
				{ className: "custom-toast" }
			);
		} else if (sameFractionValue) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Fraction value exists</p>
				</>,
				{ className: "custom-toast" }
			);
		} else {
			let payload = { ladders: [ladderAddRow] };
			new Promise((resolve, reject) => {
				ApiServices.post(apiUrls.add_ladder, resolve, reject, payload, true);
			})
				.then(response => {
					setLadderAddRow(unchangedLadderAddRow);
					setLadderArray(response?.data);
					setUnchangedData(response?.data);
				})
				.finally(() => {
					setDataChanged(false);
				});
		}
	};

	//Function to delete a ladder
	const deleteLadder = id => {
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.delete_ladder + id, resolve, reject, true);
		}).then(() => {
			let updatedArray = ladderArray.filter(item => item.id != id);
			setLadderArray(updatedArray);
		});
	};
	//Link of the download template
	const downloadHandler = () => {
		window.location.href = "https://swifty-global-app-assets-central.s3.eu-west-1.amazonaws.com/media/LaddersTemplate_v1.csv";
	};

	const getSports = async () => {
		let sportsData2 = await getAllSportsDropdown();
		sportsData2.unshift({ id: "master", label: "Master" });

		setSports(sportsData2);
	};

	const importHandler = () => {
		if (selectedSlug) {
			getSpecificLadder(selectedSlug);
			setCopyFromModal(false);
			setDataChanged(true);
		}
	};

	//Function to get specific ladders when copy from and add them
	const getSpecificLadder = slug => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_ladder + slug, resolve, reject, true);
		})
			.then(response => {
				let data = response?.data;
				data?.forEach(item => {
					delete item.id;
					item.sport_slug = id;
				});
				if (data?.length > 0) {
					let payload = { ladders: data };
					new Promise((resolve, reject) => {
						ApiServices.post(apiUrls.add_ladder, resolve, reject, payload, true);
					})
						.then(response => {
							setLadderArray(response?.data);
							setUnchangedData(response?.data);
							setinitialLadderArray(response?.data);
							setAddLadderArray(response?.data);
						})
						.finally(() => {
							setDataChanged(false);
						});
				}
			})
			.finally(() => {
				setCopyFromModal(false);
			});
	};

	const updateLadderSetting = isCustom => {
		let payload = { isCustom };
		if (id)
			new Promise((resolve, reject) => {
				ApiServices.put(apiUrls.get_ladder + `${id}/settings`, resolve, reject, payload, true);
			});
	};

	useEffect(() => {
		getSports();
	}, []);

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	useEffect(() => {
		if (isUserReadOnly) disableInputs();
	}, [isUserReadOnly]);

	return (
		<HelmetLayout titleProps={"Ladders"}>
			<div className={isUserReadOnly && "pe-none"}>
				{id ? (
					<CustumInputLadder
						discardHandler={discardData}
						title={"Import"}
						subtitle={"Copy From"}
						saveHandler={addLadderHandler}
						handleFirst={handleFirst}
						handleSecond={handleSecond}
						dataChanged={dataChanged}
						classname="special_top_component"
						ladderArray={ladderArray}
						setLadderArray={setLadderArray}
						downloadHandler={downloadHandler}
						setAddLadderArray={setAddLadderArray}
						id={id}
						setDataChanged={setDataChanged}
						disabled={isUserReadOnly}
					/>
				) : (
					<CustumInputLadder
						discardHandler={discardData}
						title={"Import"}
						subtitle={"Copy From"}
						saveHandler={addLadderHandler}
						handleFirst={handleFirst}
						handleSecond={handleSecond}
						removedCopyButton={true}
						dataChanged={dataChanged}
						classname="special_top_component"
						ladderArray={ladderArray}
						setLadderArray={setLadderArray}
						downloadHandler={downloadHandler}
						setAddLadderArray={setAddLadderArray}
						setDataChanged={setDataChanged}
						disabled={isUserReadOnly}
					/>
				)}
			</div>
			<div className="cms-page row overflow-auto">
				<div className="promotion-elements row m-0">
					<AvForm>
						<div className="d-flex flex-column p-3 ps-0">
							{id && (
								<SwitchInput
									handleChange={() => {
										setData({
											...data,
											custom_ladder: !data?.custom_ladder,
										});
										updateLadderSetting(!data?.custom_ladder);
									}}
									object={{
										name: "Custom Ladder",
										value: data?.custom_ladder,
									}}
								/>
							)}
							{((!id && ladderArray.length > 0) || (ladderArray.length > 0 && !!data?.custom_ladder)) &&
								ladderArray.map((row, index) => {
									return (
										<div key={index} className={index === 0 ? "d-flex ladder_container" : "d-flex ladder_container_extra "}>
											<div className="d-flex flex-column" style={{ width: "140px", marginRight: "20px" }}>
												{index === 0 && <div className="mb-2 special_ladders_input_field">Decimal</div>}
												<LadderInputGeneral
													value={ladderArray[index]?.in_decimal ?? ""}
													onchange={e => {
														const inputValue = e.target.value;
														const regex = /^(\d+(\.\d{0,5})?)?$/;
														if (regex.test(inputValue)) {
															let tempArray = [...ladderArray];
															tempArray[index] = { ...tempArray[index], in_decimal: inputValue };
															setLadderArray(tempArray);
														}
													}}
												/>
											</div>
											<div className="d-flex flex-column" style={{ width: "140px", marginRight: "20px" }}>
												{index === 0 && <div className="mb-2 special_ladders_input_field">Fraction</div>}
												<LadderInputGeneral
													value={ladderArray[index]?.in_fraction ?? ""}
													onchange={e => {
														const inputValue = e.target.value;
														const regex = /^[0-9/]*$/;

														if (regex.test(inputValue)) {
															let tempArray = [...ladderArray];
															tempArray[index] = { ...tempArray[index], in_fraction: inputValue };
															setLadderArray(tempArray);
														}
													}}
												/>
											</div>
											<div className="d-flex flex-column justify-content-center" style={{ width: "140px", marginRight: "20px" }}>
												{index === 0 && <div className="mb-2 special_ladders_input_field">&nbsp;</div>}

												<DeleteIconButton
													onClick={() => {
														deleteLadder(row?.id);
													}}
												/>
											</div>
										</div>
									);
								})}

							{(!!data?.custom_ladder || !id) && (
								<div className={ladderArray?.length === 0 ? "d-flex ladder_container" : "d-flex ladder_container_extra "}>
									<div className="d-flex flex-column" style={{ width: "140px", marginRight: "20px" }}>
										{ladderArray?.length === 0 && <div className="mb-2 special_ladders_input_field">Decimal</div>}
										<LadderInputGeneral
											value={ladderAddRow?.in_decimal ?? ""}
											onchange={e => {
												const inputValue = e.target.value;
												const regex = /^(\d+(\.\d{0,5})?)?$/;

												if (regex.test(inputValue)) {
													setLadderAddRow({ ...ladderAddRow, in_decimal: inputValue });
												}
											}}
										/>
									</div>
									<div className="d-flex flex-column" style={{ width: "140px", marginRight: "20px" }}>
										{ladderArray?.length === 0 && <div className="mb-2 special_ladders_input_field">Fraction</div>}
										<LadderInputGeneral
											value={ladderAddRow?.in_fraction ?? ""}
											onchange={e => {
												const inputValue = e.target.value;
												const regex = /^[0-9/]*$/;

												if (regex.test(inputValue)) {
													setLadderAddRow({ ...ladderAddRow, in_fraction: inputValue });
												}
											}}
										/>
									</div>

									<div className="d-flex flex-column justify-content-center" style={{ width: "140px", marginRight: "20px" }}>
										{ladderArray?.length === 0 && <div className="mb-2 special_ladders_input_field">&nbsp;</div>}
										<AddIcon
											classname={"special_style_top_component custom_ladder_button"}
											style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
											onClick={() => {
												addRow();
											}}
											fillColor={"#fff"}
										/>
									</div>
								</div>
							)}
							{copyFromModal && (
								<SweetAlert title={"Copy From"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
									<div
										style={
											minHeight
												? { height: "180px", overflow: "hidden", transition: "all 0.2s ease 0.1s" }
												: { height: "80px", overflow: "hidden", transition: "all 0.2s ease 0.1s" }
										}
									>
										<GeneralDropdown
											object={{
												value: selectedSlug,
												options: sports,
												placeholder: "Choose Sport",
												wrapperClassNameDropdown: "ladders-copy-form-dropdown",
												onOpen: () => setMinHeight(true),
												onClose: () => setMinHeight(false),
											}}
											handleChange={e => setSelectedSlug(e?.id)}
										/>
									</div>
									<div className="d-flex justify-content-center">
										<NoButton onClick={() => setCopyFromModal(false)} name="Cancel" />
										<YesButton name="Save" onClick={() => importHandler()} />
									</div>
								</SweetAlert>
							)}
						</div>
					</AvForm>
				</div>
			</div>
			{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<LadderHelpInformation />
				</InformationPageHelper>
			)}
		</HelmetLayout>
	);
}

export default Ladders;
