import React from "react";
import { CancelButton, LoadingSaveButton, SaveButton } from "components/GeneralComponents/AddButton";
import SweetAlert from "react-bootstrap-sweetalert";

export const DeleteMarketModal = ({ modalShow, setModalShow, submitDeleteMarketType, loadingButton }) => {
	return (
		<>
			{modalShow.deleteModal && (
				<SweetAlert title="Are you sure you want to delete this market ?" customClass={"delete-market"} showConfirm={false} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="mt-3">
							<CancelButton onClick={() => setModalShow({ ...modalShow, deleteModal: false })} name="No" />
							{loadingButton.modalButton ? <LoadingSaveButton /> : <SaveButton onClick={submitDeleteMarketType} name="Yes" />}
						</div>
					</div>
				</SweetAlert>
			)}
		</>
	);
};
