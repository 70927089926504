import { SET_SELECTED_BET_REFERRAL, SET_EMPTY_BET_REFERRAL, SET_BET_REFERRAL_PLAYER_SUB_ID, SET_UPDATED_ODDS, SET_FREE_BET_ID } from "./actionTypes";

const initialState = {
	selectedBetReferral: {},
	betReferralPlayerSubId: "",
	updatedOdds: {},
	freebetId: "",
};

const SelectedBetReferral = (state = initialState, action) => {
	switch (action.type) {
		case SET_SELECTED_BET_REFERRAL:
			if (action.payload) {
				const formatedSingles = action.payload.bet_slip.singles.map(single => {
					const betWithProvider = single.bet_provider + "-" + single.bet_id;
					let bet_id = single.bet_id.includes(single.bet_provider) ? single.bet_id : betWithProvider;

					const selectedBet = state.selectedBetReferral?.bet_slip?.singles?.find(bet => bet?.bet_id == bet_id) || {};
					if (action.payload.bet_slip.merge) delete single.stake;

					return {
						...selectedBet,
						...single,
						bet_id,
					};
				});

				const updatedStakes = action.payload.bet_slip.combinations.map(combination => {
					const obj = { stake: combination.stake, type: combination.type };
					if (combination.allow_each_way) obj.each_way = combination.each_way;
					return obj;
				});

				const newSelectedBet = {
					...action.payload,
					bet_slip: { ...action.payload.bet_slip, merge: false, singles: formatedSingles, stakes: updatedStakes },
				};

				return {
					...state,
					selectedBetReferral: newSelectedBet,
				};
			}
			break;
		case SET_EMPTY_BET_REFERRAL:
			return {
				...state,
				selectedBetReferral: {},
				betReferralPlayerSubId: "",
				updatedOdds: {},
				freebetId: "",
			};

		case SET_BET_REFERRAL_PLAYER_SUB_ID:
			return {
				...state,
				betReferralPlayerSubId: action.payload,
			};

		case SET_UPDATED_ODDS:
			return {
				...state,
				updatedOdds: {
					...state.updatedOdds,
					...action.payload,
				},
			};

		case SET_FREE_BET_ID:
			return {
				...state,
				freebetId: action.payload,
			};

		default:
			return state;
	}
};

export default SelectedBetReferral;
