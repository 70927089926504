// import { formatDecimal } from "utils/formatDecimal";
import React from "react";
import { RightDarkArrowIcon } from "utils/icons";

export const getliabilityTableColumns = ({ isSingle, data, onToggle, isRacing }) => {
	const array = [
		{
			name: "market_name",
			label: "MARKET / SELECTION",
			options: {
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];

					const { market_name, selection_name } = row;

					return <div>{market_name || selection_name}</div>;
				},
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "result",
			label: "Result",
			options: {
				customBodyRender: value => <div className="text-uppercase">{value}</div>,
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		isSingle
			? {
					name: "price",
					label: "Prices",
					options: {
						setCellProps: () => ({ style: { minWidth: "100px", textAlign: "right" } }),
						setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right align-right" }),
					},
			  }
			: null,
		{
			name: "bets",
			label: "Bets",
			options: {
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];
					const { total_bets, bets } = row;

					return <div>{total_bets || bets}</div>;
				},
				setCellProps: () => ({ style: { minWidth: "50px", textAlign: "right" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button-and-right align-right" }),
			},
		},
		{
			name: "total_stake",
			label: "TS",
			options: {
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];
					const { total_stakes, total_stake } = row;

					return <div>{total_stakes || total_stake}</div>;
				},
				setCellProps: () => ({ style: { minWidth: "100px", textAlign: "right" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right align-right" }),
			},
		},
		!isSingle && !isRacing
			? {
					name: "OS",
					label: "OS",
					options: {
						setCellProps: () => ({ style: { minWidth: "100px", textAlign: "right" } }),
						setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right align-right" }),
					},
			  }
			: null,
		{
			name: "PO",
			label: "P/O",
			options: {
				setCellProps: () => ({ style: { minWidth: "100px", textAlign: "right" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right align-right" }),
			},
		},

		isRacing
			? {
					name: "BOG_PO",
					label: "BOG P/O",
					options: {
						setCellProps: () => ({ style: { minWidth: "100px", textAlign: "right" } }),
						setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right align-right" }),
					},
			  }
			: null,
		isSingle
			? // {
			  // 	name: "bog_payout",
			  // 	label: "BOG P/O",
			  // 	options: {
			  // 		customBodyRender: formatDecimal,
			  // 		setCellProps: () => ({ style: { minWidth: "100px", textAlign: "right" } }),
			  // 		setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right align-right" }),
			  // 	},
			  // },
			  {
					name: "PL",
					label: "P/L",
					options: {
						customBodyRender: value => <div className={`${+value < 0 ? "negative-value" : ""}`}>{value}</div>,
						setCellProps: () => ({ style: { minWidth: "100px", textAlign: "right" } }),
						setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right align-right" }),
					},
			  }
			: null,
		isSingle
			? {
					name: "PLP",
					label: "P/L %",
					options: {
						customBodyRender: value => <div className={`${+value < 0 ? "negative-value" : ""}`}>{value ? `${value}%` : ""}</div>,
						setCellProps: () => ({ style: { minWidth: "100px", textAlign: "right" } }),
						setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right align-right" }),
					},
			  }
			: null,
		{
			name: "",
			label: "",
			className: "",
			options: {
				viewColumns: false,
				print: false,
				filter: false,
				searchable: false,
				customBodyRender: (value, meta) => {
					const record = data[meta.currentTableData[meta.rowIndex].index];

					const isMarket = record?.market_name;

					return (
						isMarket && (
							<div className="d-flex justify-content-end">
								<div
									className="row-market-collapse"
									onClick={() => {
										onToggle(record);
									}}
								>
									<RightDarkArrowIcon />
								</div>
							</div>
						)
					);
				},
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
	];

	return array.filter(a => !!a);
};

export const getLiabilityMultipleDetailsTableColumns = () => {
	return [
		{
			name: "selection_name",
			label: "Selection",
			options: {
				setCellProps: () => ({ style: { minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" } }),
			},
		},
		{
			name: "result",
			label: "RESULT",
			options: {
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "bets",
			label: "BETS",
			options: {
				setCellProps: () => ({ style: { minWidth: "50px", textAlign: "right" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button-and-right align-right" }),
			},
		},
		{
			name: "total_stakes",
			label: "TS",
			options: {
				setCellProps: () => ({ style: { minWidth: "50px", textAlign: "right" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button-and-right align-right" }),
			},
		},
		{
			name: "open_stakes",
			label: "OS",
			options: {
				setCellProps: () => ({ style: { minWidth: "50px", textAlign: "right" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button-and-right align-right" }),
			},
		},
		{
			name: "payout",
			label: "P/O",
			options: {
				setCellProps: () => ({ style: { minWidth: "50px", textAlign: "right" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button-and-right align-right" }),
			},
		},
		{
			name: "avg_payout",
			label: "ANP",
			options: {
				setCellProps: () => ({ style: { minWidth: "50px", textAlign: "right" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button-and-right align-right" }),
			},
		},
		{
			name: "profit",
			label: "PROFIT",
			options: {
				setCellProps: () => ({ style: { minWidth: "50px", textAlign: "right" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button-and-right align-right" }),
			},
		},
		{
			name: "profit_loss_percent",
			label: "P/L %",
			options: {
				setCellProps: () => ({ style: { minWidth: "50px", textAlign: "right" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button-and-right align-right" }),
			},
		},
	];
};
