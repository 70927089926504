import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

export const getContent = async (type, country = "all", page_type) => {
	const params = page_type ? `&page_type=${page_type}` : "";
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.content_get + `?type=${type}&country=${country}` + params, resolve, reject, true);
	})
		.then(response => {
			let content = response?.data;
			return content;
		})
		.catch(() => {
			return [];
		});
};
