import React, { useMemo, useState, useEffect } from "react";
import InfoIconBlue from "../../../assets/images/infoIconBlue.svg";
import { toFixedFloat } from "../BetTicketHelpers";
import { ThousandSeperated } from "hooks/General";
import { AddFreeCashLogo } from "utils/icons";
import { useSelector } from "react-redux";
import useGenerateBetslip from "hooks/betslip";

import "./BetReferralItem.scss";

const betReferralCombination = ({ item, disabled, useFreebet, setHasChanged }) => {
	const itemType = item.type;

	const returnsValue = toFixedFloat(item.payout);
	const SelectedBetReferral = useSelector(state => state.SelectedBetReferral.selectedBetReferral);
	const betSlip = SelectedBetReferral?.bet_slip;

	const generateBetslip = useGenerateBetslip();

	const allowEachWay = useMemo(() => item.allow_each_way, [item.allow_each_way]);
	const [isEW, setIsEW] = useState(false);
	const [input, setInput] = useState(item.stake > 0 ? item.stake : "");

	const handlerSetMultipleStake = (value, ew, type) => {
		const temp = { ...betSlip };

		let stakeExist = false;

		temp.stakes.forEach(stake => {
			if (stake.type === type) {
				stake.stake = value;
				stakeExist = true;

				if (allowEachWay) stake.each_way = ew;
			}
		});

		if (!stakeExist) {
			const newStake = { stake: value, type };

			if (allowEachWay) newStake.each_way = ew;

			temp.stakes.push(newStake);
		}

		setHasChanged(true);

		generateBetslip(temp, 300, true);
	};

	const onChange = e => {
		const value = e.target.value;
		setInput?.(value);
		handlerSetMultipleStake(value, isEW, itemType);
	};

	const onEwChange = () => {
		setIsEW(!isEW);
		handlerSetMultipleStake(input, !isEW, itemType);
	};

	useEffect(() => {
		setIsEW(item?.each_way);
	}, [item?.each_way]);

	useEffect(() => {
		if (item?.stake !== input) setInput(input);
	}, [item?.stake]);

	return (
		<div className="bet-referral-singles-wrapper">
			<div className="bet-referral-singles-wrapper_inner">
				<div className="bet-referral-singles">
					<img src={InfoIconBlue} alt="info" />
					{item.length ? itemType + ` (x${item.length})` : item.name}
				</div>
				<div className="bet-referral-singles-input">
					<div className="bet-referral-item-input-block">
						<input
							id={itemType}
							type="number"
							className="bet-referral-item-input bet-referral-item-input-round"
							name={itemType}
							disabled={disabled}
							min={0}
							onChange={onChange}
							value={input}
						/>

						{allowEachWay && (
							<div className="bet-referral-item-each_way each_way-combinations">
								<span>EW</span>
								<input type="checkbox" onChange={onEwChange} checked={isEW} />
							</div>
						)}
					</div>
					<div>
						<div className={`bet-referral-item-returns ${useFreebet ? "justify-content-between" : ""}`}>
							{useFreebet && <AddFreeCashLogo />} Returns:&nbsp;{ThousandSeperated(returnsValue)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default betReferralCombination;
