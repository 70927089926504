import React from "react";
import { LoadingSaveButton, NoButton, YesButton } from "components/GeneralComponents/AddButton";
import SweetAlert from "react-bootstrap-sweetalert";

function ConfirmModal(props) {
	const { title, paragraf, handlerClose, handlerSave, isLoading } = props;

	return (
		<SweetAlert title={title} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
			<div className="position-relative">
				<span className="delete-span">{paragraf}</span>
			</div>
			<div className="d-flex justify-content-center mt-5">
				<NoButton onClick={() => handlerClose()} name="No" />
				{isLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={() => handlerSave()} />}
			</div>
		</SweetAlert>
	);
}

export default ConfirmModal;
