import React from "react";
import { DeleteIconButton, EditButton } from "components/GeneralComponents/AddButton";

export const getMarketsSelectionColumns = ({ selectionTableData, handleClick }) => {
	return [
		{
			name: "outcome_name",
			label: "NAME",
			className: "name",
			sortable: true,
			sort: "asc",
		},
		{
			name: "suspend",
			label: "STATUS",
			className: "edit_status",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = selectionTableData[meta.currentTableData[meta.rowIndex].index];
					return <span>{record.active == 1 ? "Active" : "Inactive"}</span>;
				},
			},
		},
		{
			name: "",
			className: "edit-market-selection",
			sortable: true,
			sort: "asc",
			options: {
				viewColumns: false,
				filter: false,
				customBodyRender: (value, meta) => {
					const record = selectionTableData[meta.currentTableData[meta.rowIndex].index];

					return (
						<div className="d-flex justify-content-end">
							<div className="heroBanners-buttons">
								<EditButton onClick={() => handleClick(record, "edit")} />
								<DeleteIconButton onClick={() => handleClick(record, "delete")} />
							</div>
						</div>
					);
				},
			},
		},
	];
};
