import React, { useEffect, useState } from "react";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

import MUIDataTable from "mui-datatables";
import { getAffiliateDashbordColumns } from "./getAffiliateDashbordColumns";
import { tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import { _months } from "utils/constants";
import moment from "moment";
import { getAllMonthsAndYears } from "utils/global";

const formatedMonths = _months.map((month, index) => ({ label: month, value: index + 1 })).reverse();

const AffiliateDashbord = () => {
	const latestMonth = getAllMonthsAndYears(formatedMonths)[0]?.id;
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({ date: latestMonth, players: "all_players" });
	const [tabelData, setTabelData] = useState([]);

	const getAffiliateDashbord = obj => {
		setLoading(true);

		let url = apiUrls.GET_AFFILIATE_DASHBORD;

		if (obj) url += `?dateFrom=${obj?.dateFrom}&dateTo=${obj?.dateTo}`;
		if (data?.players) url += `&players=${data?.players}`;

		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				const { success, data } = response;
				if (success) setTabelData(data);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		const date = data?.date;

		if (date) {
			const startOfMonth = moment(date, "M-YYYY").startOf("month");

			const startDate = startOfMonth.format("YYYY-MM-DD 00:00:00");
			const endDate = startOfMonth.clone().endOf("month").format("YYYY-MM-DD 00:00:00");

			if (startDate && endDate) getAffiliateDashbord({ dateFrom: startDate, dateTo: endDate });
		}
	}, [data]);

	const fields = [
		{ type: "dropdown", fieldName: "players", optionsType: "affiliatePlayerType", wrapperClassNameDropdown: "affiliate-dashboard-fields" },
		{
			type: "dropdown",
			fieldName: "date",
			optionsType: "generateMonthAndYear",
			params: formatedMonths,
			wrapperClassNameDropdown: "affiliate-dashboard-fields",
		},
	];

	const columns = getAffiliateDashbordColumns({ tabelData });
	return (
		<HelmetLayout titleProps={"Affiliate"}>
			<div className="cms-page pt-0 gap-4">
				<div className="d-flex align-items-md-center justify-content-start gap-2 flex-column flex-md-row">
					<AllComponentsLoop fields={fields} data={data} setData={setData} />
				</div>

				<MUIDataTable
					columns={columns}
					data={tabelData}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: loading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						responsive: "",
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
					}}
				/>
			</div>
		</HelmetLayout>
	);
};
export default AffiliateDashbord;
