import { SET_NOTIFICATIONS_LIST, REMOVE_FROM_NOTIFICATIONS_LIST } from "./actionTypes";

const initialState = {
	listNotifications: [],
};

const NotificationsList = (state = initialState, action) => {
	let updatedList = [...state.listNotifications];
	let foundIndex = -1;
	switch (action.type) {
		case SET_NOTIFICATIONS_LIST:
			foundIndex = state.listNotifications.findIndex(room => room.room_id === action.payload.room_id);
			if (foundIndex !== -1) {
				updatedList[foundIndex] = action.payload;
				return { ...state, listNotifications: updatedList };
			} else {
				return {
					...state,
					listNotifications: [...state.listNotifications, action.payload],
				};
			}
		case REMOVE_FROM_NOTIFICATIONS_LIST:
			return { ...state, listNotifications: updatedList.filter(msg => msg.room_id !== action.payload.room_id) };
		default:
			return state;
	}
};

export default NotificationsList;
