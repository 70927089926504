import React, { useState } from "react";
import { useEffect } from "react";
import { InfoIcon } from "utils/icons";
import { useDispatch, useSelector } from "react-redux";
import { GeneralCheckbox } from "components/GeneralComponents/CustomInputs";
import { getCurrenciesDropdown } from "services/getCurrenciesDropdown";
import { setFreeBetCredit } from "store/actions";

import useGenerateBetslip from "hooks/betslip";

import "./telebetFreebet.scss";

export const TelebetFreebet = ({ freebetsData }) => {
	const dispatch = useDispatch();
	const telebetUser = useSelector(state => state.Telebet.telebetUser);
	const freebetCredit = useSelector(state => state.Telebet.freeBetCreditSelect);

	const [symbol, setSymbol] = useState("");

	const telebetBets = useSelector(state => state.Telebet.telebetBets);

	const generateBetslip = useGenerateBetslip();

	const onSelect = freeBet => {
		const tmp = { ...telebetBets };

		if (freeBet?.id) {
			tmp.use_free_bet = 1;
			tmp.free_bet_id = freeBet?.id;
		} else {
			tmp.use_free_bet = null;
		}

		generateBetslip(tmp);
	};

	const handleFreeBetSelect = row => {
		if (freebetCredit?.id === row?.id) {
			onSelect();
			dispatch(setFreeBetCredit({}));
		} else {
			onSelect(row);
			dispatch(setFreeBetCredit(row));
		}
	};

	const getcurrencies = async () => {
		const currencies = await getCurrenciesDropdown();
		const symbol = currencies.find(row => row?.id === telebetUser?.currency).symbol;
		setSymbol(symbol);
	};

	useEffect(() => {
		if (telebetUser?.currency) getcurrencies();
	}, [telebetUser?.currency]);

	return (
		freebetsData?.length > 0 && (
			<div className="freebet-info">
				{freebetsData.map((row, i) => {
					const { id, amount, sportNames, disabled } = row;

					const sports = sportNames?.map(sport => sport?.name);

					return (
						<div className={`freebet-info-container ${disabled ? "disabled" : ""} ${freebetCredit?.id === id ? "active" : ""}`} key={i}>
							<InfoIcon className="freebet-info-icon " fill={freebetCredit?.id === id || disabled ? "#fff" : "#2E3A49"} />

							<div className="freebet-info-item">{`${symbol}${amount} Free Credit ${sports?.length > 0 ? "(" + sports?.join(", ") + ")" : ""}`}</div>

							<GeneralCheckbox
								object={{
									type: "radio",
									checked: freebetCredit?.id === id,
									handleClick: () => handleFreeBetSelect(row),
									checkboxContainer: "p-0",
									checkboxExtraClass: "freebet-info-radio",
									disabled: disabled,
								}}
								handleChange={() => {}}
							/>
						</div>
					);
				})}
			</div>
		)
	);
};
