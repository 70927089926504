import {
	SET_TELEBET_SPORTS_GAMES,
	SET_TELEBET_USER,
	SET_TELEBET_SPORT,
	SET_TELEBET_MATCH,
	SET_SELECTED_BETS,
	EMPTY_TELEBET_BETS,
	SET_BET_AMOUT,
	SET_BET_STAKES,
	SET_EMPTY_LEGS,
	SET_SEARCH_COMPETITION_TELEBET,
	SET_SEARCH_COMPETITION_VALUE_TELEBET,
	SET_UPDATE_SELECTION,
	SET_TELEBET_BETS,
	SET_FREE_BET_CREDIT,
} from "./actionTypes";

export const setTelebetSportsGames = payload => {
	return {
		type: SET_TELEBET_SPORTS_GAMES,
		payload,
	};
};

export const setTelebetUser = payload => {
	return {
		type: SET_TELEBET_USER,
		payload,
	};
};

export const setTelebetSport = payload => {
	return {
		type: SET_TELEBET_SPORT,
		payload,
	};
};

export const setTelebetSearchCompetitions = payload => {
	return {
		type: SET_SEARCH_COMPETITION_TELEBET,
		payload,
	};
};

export const setTelebetSearch = payload => {
	return {
		type: SET_SEARCH_COMPETITION_VALUE_TELEBET,
		payload,
	};
};

export const setTelebetMatch = payload => {
	return {
		type: SET_TELEBET_MATCH,
		payload,
	};
};

export const selectBets = (payload, betType) => {
	return {
		type: SET_SELECTED_BETS,
		payload,
		betType,
	};
};

export const setBetAmount = payload => {
	return {
		type: SET_BET_AMOUT,
		payload,
	};
};

export const setBetStakes = payload => {
	return {
		type: SET_BET_STAKES,
		payload: payload,
	};
};

export const setEmptyLegs = payload => {
	return {
		type: SET_EMPTY_LEGS,
		payload,
	};
};

export const setUpdateSelection = payload => {
	return {
		type: SET_UPDATE_SELECTION,
		payload,
	};
};

export const setTelebetBets = payload => {
	return {
		type: SET_TELEBET_BETS,
		payload,
	};
};

export const emptyTelebetBets = payload => {
	return {
		type: EMPTY_TELEBET_BETS,
		payload,
	};
};

export const setFreeBetCredit = payload => {
	return {
		type: SET_FREE_BET_CREDIT,
		payload,
	};
};
