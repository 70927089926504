import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let result = [];

export const getCasinoFeatureDropdown = async () => {
	if (result.length) return result;

	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.GET_CASINO_FEATURES, resolve, reject, true);
	})
		.then(response => {
			let features = response?.data;
			features = features.map(row => {
				return { id: row.slug, label: row.title };
			});

			result = features;
			return features;
		})
		.catch(() => {
			result = [];
			return [];
		});
};
