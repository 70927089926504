import React, { useRef } from "react";
import { Card, CardBody } from "reactstrap";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

import useOutsideClick from "hooks/useOutsideClick";
import { CloseIconIframe } from "utils/icons";

export const ModalOfResponsibleGambling = ({ title, paragraph, allFields, data, setData, property, handleCancelClick, handleClick, showError }) => {
	const modalRef = useRef(null);
	let field = property ? allFields.filter(row => row?.group == property) : allFields;

	const acceptButtonName = type => {
		let name = "";
		if (type == "deposit_limits") name = "Set Limit";
		if (type == "reality_check") name = "Set Reality Check";
		if (type == "play_break") name = "Set Play Break";
		if (type == "self_excluded") name = "Set Self Exclusion";
		return name || "Accept";
	};

	const dependeciesObj = {
		type: data.type,
		onClickEvent: handleClick,
	};

	useOutsideClick(modalRef, () => handleCancelClick());

	const titleDescriptionMobile = type => {
		let titleDescription = "";
		if (type == "deposit_limits") titleDescription = "Deposit Limits";
		if (type == "reality_check") titleDescription = "Reality Check";
		if (type == "play_break") titleDescription = "Play Break";
		if (type == "self_excluded") titleDescription = "Self Exclusion";
		return titleDescription || "";
	};

	const buttonFields = [
		{
			type: "button",
			buttonName: "Cancel",
			wrapperClassName: "p-0",
			buttonClassname: "btn-primary cancel-btn reset_button_user cancel_button_responsible_gambling_modal",
			onClickEvent: handleCancelClick,
		},
		{
			type: "button",
			buttonName: acceptButtonName(property),
			wrapperClassName: "p-0",
			buttonClassname: `${showError ? "save_button_responsible_gambling_modal_disabled" : ""} save_button_responsible_gambling_modal `,
			onClickEvent: handleClick,
			disabled: showError ? true : false,
		},
	];

	return (
		<Card>
			<CardBody style={{ overflowX: "scroll" }} className="responsible_gambling_modal_container">
				<div className="bg-modal">
					<div className="bg-text" ref={modalRef}>
						<div className="row textPosition responsible_gambling_modal">
							<span style={{ textTransform: "capitalize" }}>
								<h5> {title} </h5>
								<div>
									<CloseIconIframe onClick={handleCancelClick} />
								</div>
							</span>
							<br />
							<p className="responsible_modal_paragraph">{paragraph}</p>
							<p className="reponsible_gambling_title_description">{titleDescriptionMobile(property)}</p>
							<div className="modal_responsible_buttons">
								<AllComponentsLoop fields={field} data={data} setData={setData} dependecies={dependeciesObj} />
								{showError && (
									<div className="responsible_gambling_error">
										<span></span>
										{showError}
									</div>
								)}
							</div>
							<div className="responsible_gambling_buttons">
								<AllComponentsLoop fields={buttonFields} dependecies={dependeciesObj} />
							</div>
						</div>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};
