import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { getVariablesTableColumne } from "./variablesTableColumns";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { NewVariable } from "./newVariable";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import MUIDataTable from "mui-datatables";

const Variables = props => {
	const [showAddModal, setShowAddModal] = useState(false);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [currentPage, setCurrentPage] = useState(0);
	const [editItem, setEditItem] = useState(null);
	const [records, setRecords] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const breadcrumbItems = [{ title: "Variables", link: "/variables" }];

	const getVariablesData = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_variables, resolve, reject, true);
		})
			.then(response => {
				let data = response?.data;
				setRecords(data);
				setUnfilteredData(data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		getVariablesData();
		props.setBreadcrumbItems("Variables", breadcrumbItems);
	}, []);

	let variablesTableColumne = getVariablesTableColumne({
		records,
		setEditItem,
		setShowAddModal,
	});

	let page_name = "Variables";
	return (
		<HelmetLayout titleProps={"Variables"}>
			<div className="cms-page pt-0">
				<SingleSearchFilter setData={setRecords} unfilteredData={unfilteredData} filterColumn={["description", "value"]} />
				<MUIDataTable
					columns={variablesTableColumne}
					data={records}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: `${page_name}.csv` },
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>

			{showAddModal && (
				<NewVariable setShowAddModal={setShowAddModal} editItem={editItem} setEditItem={setEditItem} records={records} setRecords={setRecords} />
			)}
		</HelmetLayout>
	);
};
export default connect(null, { setBreadcrumbItems })(Variables);
