import React, { useEffect, useRef, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { ImageInput, SwitchInput, GeneralInput, GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { XCloseIcon } from "utils/icons";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { languageToString } from "utils/global";
import { Editor } from "@tinymce/tinymce-react";

const promoTypes = [
	{ label: "Sportsbook", id: 1, value: "sportsbook" },
	{ label: "Casino", id: 2, value: "casino" },
	{ label: "Sportsbook and Casino", id: 3, value: "sportsbook_and_casino" },
];

const editorApiUrl = process.env.REACT_APP_EDITOR_API_KEY;

export const OffersPromotion = props => {
	const [loading, setLoading] = useState(false);
	const [selectedPromoTypeId, setSelectedPromoTypeId] = useState();
	const { editItem, title, handler, getPromoOffer } = props;

	let details = null;
	if (editItem) {
		details = JSON.parse(editItem?.details);
	}

	const [promoOfferData, setPromoOfferData] = useState({
		checked: editItem?.active,
		title: details ? details?.title : "",
		image: details ? details?.image : null,
		image_exist: editItem ? true : false,
		mobile_image: details ? details?.mobile_image : null,
		mobile_image_exist: editItem ? true : false,
		promo_type: details ? details?.promo_type : null,
		offer_header: details ? details?.offer_header : null,
		offer_description: details ? details?.offer_description : null,
		terms_header: details ? details?.terms_header : null,
		terms_description: details ? details?.terms_description : null,
		expanded: details?.expanded ? true : false,
	});

	const [dataChanged, setDataChanged] = useState(false);

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const handleChange = value => {
		handleDataChanged();
		setSelectedPromoTypeId(value.id);
		setPromoOfferData({ ...promoOfferData, promo_type: value.value });
	};

	const addPromoOffer = () => {
		let language = languageToString(promoOfferData, ["title_", "subtitle_", "offer_header_", "offer_description_", "terms_header_", "terms_description_"]);
		let payload = {
			title: promoOfferData?.title,
			published: promoOfferData?.checked ? true : false,
			image: promoOfferData?.image,
			image_exist: true,
			mobile_image: promoOfferData?.mobile_image,
			mobile_image_exist: true,
			country_code: "all",
			promo_type: promoOfferData?.promo_type,
			offer_header: promoOfferData?.offer_header,
			offer_description: promoOfferData?.offer_description,
			terms_header: promoOfferData?.terms_header,
			terms_description: promoOfferData?.terms_description,
			language,
		};

		setLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.put(`${apiUrls.add_promo_offer}`, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				getPromoOffer();
				handler();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const editPromo = () => {
		let language = languageToString(promoOfferData, ["title_", "subtitle_", "offer_header_", "offer_description_", "terms_header_", "terms_description_"]);

		let payload = {
			id: editItem.id,
			title: promoOfferData?.title,
			published: promoOfferData?.checked ? true : false,
			image: promoOfferData?.image,
			image_exist: promoOfferData?.image_exist,
			mobile_image: promoOfferData?.mobile_image,
			mobile_image_exist: promoOfferData?.mobile_image_exist,
			country_code: "all",
			promo_type: promoOfferData?.promo_type,
			offer_header: promoOfferData?.offer_header,
			offer_description: promoOfferData?.offer_description,
			terms_header: promoOfferData?.terms_header,
			terms_description: promoOfferData?.terms_description,
			expanded: promoOfferData?.expanded,
			language,
		};

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.edit_promo_offer, resolve, reject, payload, true);
		})
			.then(() => {
				getPromoOffer();
				handler();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};

	useEffect(() => {
		if (details) {
			let newData = { ...promoOfferData };
			Object.entries(details).forEach(([key, value]) => {
				if (key.includes("subtitle_") || key.includes("title_")) {
					newData = { ...newData, [key]: value };
				}
			});
			setPromoOfferData(newData);
		}
	}, []);

	const getPromoTypeId = () => {
		if (promoOfferData?.promo_type) {
			return promoTypes.find(item => item.value === promoOfferData?.promo_type).id;
		} else if (selectedPromoTypeId) {
			return selectedPromoTypeId;
		} else {
			return "";
		}
	};

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editItem ? editPromo : addPromoOffer} loading={loading} dataChanged={dataChanged} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AvForm onClick={() => !onFocus.current && handleShowOptions()}>
							<GeneralInput
								object={{
									name: "Title",
									value: promoOfferData.title,
									onFocus: () => handleShowOptions("language", "title"),
									onBlur: () => (onFocus.current = false),
									required: true,
								}}
								onChange={e => {
									handleDataChanged();
									setPromoOfferData({ ...promoOfferData, title: e.target.value });
								}}
							/>
							<GeneralDropdown
								object={{
									name: "Promo type",
									value: getPromoTypeId(),
									options: promoTypes,
									required: true,
								}}
								handleChange={value => handleChange(value)}
							/>

							<ImageInput
								object={{
									name: "Desktop Image",
									onFocus: () => handleShowOptions("image", true),
									onBlur: () => (onFocus.current = false),
									value: promoOfferData?.image,
									otherRowClass: true,
									fieldName: "image",
									active: showOptions?.image,
									data: promoOfferData,
									setData: setPromoOfferData,
									setDataChanged: setDataChanged,
									imageLibaryType: "promo_offer_image",
									dimensions: "(1440 x 600 px)",
								}}
							/>
							<ImageInput
								object={{
									name: "Mobile Image",
									onFocus: () => handleShowOptions("mobile_image", true),
									onBlur: () => (onFocus.current = false),
									value: promoOfferData?.mobile_image,
									active: showOptions?.mobile_image,
									otherRowClass: true,
									fieldName: "mobile_image",
									data: promoOfferData,
									setData: setPromoOfferData,
									setDataChanged: setDataChanged,
									imageLibaryType: "promo_offer_mobile_image",
									dimensions: "(380 x 320 px)",
								}}
							/>

							<GeneralInput
								object={{
									name: "Offer Header",
									value: promoOfferData.offer_header,
									onFocus: () => handleShowOptions("language", "offer_header"),
									onBlur: () => (onFocus.current = false),
									required: true,
								}}
								onChange={e => {
									handleDataChanged();
									setPromoOfferData({ ...promoOfferData, offer_header: e.target.value });
								}}
							/>

							<div>
								<div className="row wrapper_customized_modal">
									<div className="col-12 col-lg-4 d-flex align-items-center input-title">Offer Description</div>
									<div className="editor-texts">
										<Editor
											apiKey={editorApiUrl}
											value={promoOfferData.offer_description}
											onEditorChange={(e, editor) => {
												const newContent = editor.getContent();
												setPromoOfferData({
													...promoOfferData,
													offer_description: newContent,
												});
												handleDataChanged();
											}}
											init={{
												height: 200,
												menubar: false,
												visual: false,
												plugins: [
													"advlist",
													"autolink",
													"lists",
													"link",
													"image",
													"charmap",
													"preview",
													"anchor",
													"searchreplace",
													"visualblocks",
													"code",
													"fullscreen",
													"insertdatetime",
													"media",
													"table",
													"code",
													"help",
													"wordcount",
												],
												toolbar:
													"undo redo | blocks | " +
													"bold italic forecolor link | alignleft aligncenter " +
													"alignright alignjustify | bullist numlist outdent indent | " +
													"removeformat | help | code",
												content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
											}}
											onFocus={() => handleShowOptions("language", "offer_description")}
											// onBlur={() => (onFocus.current = false)}
										/>
									</div>
								</div>
							</div>
							<GeneralInput
								object={{
									name: "Terms Header",
									value: promoOfferData.terms_header,
									onFocus: () => handleShowOptions("language", "terms_header"),
									onBlur: () => (onFocus.current = false),
									required: true,
								}}
								onChange={e => {
									handleDataChanged();
									setPromoOfferData({ ...promoOfferData, terms_header: e.target.value });
								}}
							/>

							<div>
								<div className="row wrapper_customized_modal">
									<div className="col-12 col-lg-4 d-flex align-items-center input-title">Terms</div>
									<div className="editor-texts">
										<Editor
											apiKey={editorApiUrl}
											value={promoOfferData.terms_description}
											onEditorChange={(e, editor) => {
												const newContent = editor.getContent();
												setPromoOfferData({
													...promoOfferData,
													terms_description: newContent,
												});
												handleDataChanged();
											}}
											init={{
												height: 200,
												menubar: false,
												visual: false,
												plugins: [
													"advlist",
													"autolink",
													"lists",
													"link",
													"image",
													"charmap",
													"preview",
													"anchor",
													"searchreplace",
													"visualblocks",
													"code",
													"fullscreen",
													"insertdatetime",
													"media",
													"table",
													"code",
													"help",
													"wordcount",
												],
												toolbar:
													"undo redo | blocks | " +
													"bold italic forecolor link | alignleft aligncenter " +
													"alignright alignjustify | bullist numlist outdent indent | " +
													"removeformat | help | code",
												content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
											}}
											onFocus={() => handleShowOptions("language", "terms_description")}
											// onBlur={() => (onFocus.current = false)}
										/>
									</div>
								</div>
							</div>

							<SwitchInput
								handleChange={() => {
									handleDataChanged();
									setPromoOfferData({ ...promoOfferData, checked: !promoOfferData?.checked });
								}}
								object={{ fieldName: "published", value: promoOfferData?.checked }}
							/>
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
						{showOptions.language && (
							<LanguageTitle data={promoOfferData} setData={setPromoOfferData} title={showOptions.language} handleDataChanged={handleDataChanged} />
						)}
						{showOptions.image && (
							<ImageLibrary
								data={promoOfferData}
								setData={imageData => {
									setPromoOfferData({
										...promoOfferData,
										image: imageData.image,
									});
								}}
								type="promo_offer_image"
								handleDataChanged={handleDataChanged}
								handleShowOptions={handleShowOptions}
							/>
						)}
						{showOptions.mobile_image && (
							<ImageLibrary
								data={promoOfferData}
								setData={imageData => {
									setPromoOfferData({
										...promoOfferData,
										mobile_image: imageData.image,
									});
								}}
								type="promo_offer_mobile_image"
								handleDataChanged={handleDataChanged}
								handleShowOptions={handleShowOptions}
								property="mobile_image"
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
