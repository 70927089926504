import { ThousandSeperated } from "hooks/General";
import React from "react";
import { capitalizeText } from "services/capitalizeText";

export const getBonusLogsColumns = ({ bonusLogs }) => {
	return [
		{
			name: "id",
			label: "ID",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellHeaderProps: () => ({ style: { minWidth: "110px" } }),
				setCellProps: () => ({ style: { minWidth: "110px" } }),
			},
		},
		{
			name: "player_id",
			label: "USER ID",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellHeaderProps: () => ({ style: { minWidth: "110px" } }),
				setCellProps: () => ({ style: { minWidth: "110px" } }),
			},
		},
		{
			name: "executed_at",
			label: "EXECUTED AT",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),
				setCellProps: () => ({ style: { minWidth: "200px" } }),
			},
		},
		{
			name: "benefits",
			label: "BENEFITS",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = bonusLogs[meta.currentTableData[meta.rowIndex].index];
					const { benefits } = record;

					return benefits
						? benefits.map(row => {
								const { type, value, currency, betted_ids } = row;
								return (
									<div key={row.id} className="">
										<div className="d-flex align-items-center gap-3">
											<span className="d-flex gap-1">Description: {capitalizeText(type)}</span>
											<div className="d-flex gap-1">
												Amount:
												<span className="">{ThousandSeperated(value)}</span>
												<span className="">{currency}</span>
											</div>
										</div>
										{betted_ids?.length > 0 && (
											<div className="d-flex gap-1">
												Bet ids:
												{betted_ids.map((row, i) => (
													<span key={i}>
														{row}
														{i < betted_ids.length - 1 ? "," : ""}
													</span>
												))}
											</div>
										)}
										{/* {casino_bet_ids?.length > 0 && (
											<div className="">
												Casino ids:&nbsp;&nbsp;
												{casino_bet_ids.map((row, i) => (
													<span key={i}>
														{row}
														{i < casino_bet_ids.length - 1 ? "," : ""}&nbsp;&nbsp;
													</span>
												))}
											</div>
										)} */}
									</div>
								);
						  })
						: "-";
				},
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
	];
};
