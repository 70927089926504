import React, { useEffect, useState } from "react";
import { ThousandSeperated } from "hooks/General";
import { Spinner } from "reactstrap";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { getFormatDateByUserTimezone, getPhonePrefixAndNumber } from "utils/global";

import "./Overview.scss";
import { capitalizeText } from "services/capitalizeText";
import { useSelector } from "react-redux";
import { getAffiliateTagsDropdown } from "services/getAffiliateTagsDropdown";
import moment from "moment";
import UserNotes from "./UserNotes";

function Overview(props) {
	const { userData, extraItem } = props;

	const [userStatsData, setUserStatsData] = useState([]);
	const [stakeFactorData, setStakeFactorData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [affiliateUserTag, setAffiliateUserTag] = useState();

	const countries = useSelector(state => state.Login.gsData.countries);
	const parsedData = JSON.parse(sessionStorage.getItem("_languages"));

	const country = countries.find(row => row?.cca2 === userData.country);
	const full_name = capitalizeText(`${userData.first_name} ${userData.last_name}`);
	const address = capitalizeText(userData.address_line1);
	const userCity = capitalizeText(userData.address_city);
	const languageLabel = parsedData && parsedData.find(item => item.id === userData?.language)?.label;
	const sofFormattedDate = userData ? (userData.sof_date ? moment(userData.sof_date).format("DD MMM YYYY") : "-") : "-";

	const getAffiliateTags = async () => {
		const affiliateTagUser = await getAffiliateTagsDropdown();
		setAffiliateUserTag(affiliateTagUser.find(item => item.id == userData?.affiliate_tag)?.label);
	};

	const getStakeFactor = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_STAKE_FACTOR + extraItem.user_id, resolve, reject, true);
		}).then(response => {
			if (response.success) {
				const { data } = response;
				data.prematch.unshift({ name: "SF", value: data?.prematch_stake_factor, slug: "Stake Factor" });
				data.inplay.unshift({ name: "SF", value: data?.inplay_stake_factor, slug: "Stake Factor" });
				setStakeFactorData(data);
			}
		});
	};

	const getUserStats = () => {
		setIsLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_USER_STATS + extraItem.user_id, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					const { data } = response;
					setUserStatsData(data);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getUserStats();
		getAffiliateTags();
		getStakeFactor();
	}, []);

	const getClassName = (type, value) => {
		if (type === "user_stats") {
			const numericValue = Number(value);
			if (isNaN(numericValue)) return "user-box user-box-grey";
			else if (numericValue > 0) return "user-box user-box-green";
			else if (numericValue < 0) return "user-box user-box-red";
			else return "user-box user-box-grey";
		} else if (type === "kyc_status") {
			if (userData) {
				const kyc_status = value;
				let kyc_status_value = "kyc-status-value ";

				if (kyc_status === "verified") kyc_status_value += "green";
				else if (kyc_status === "init") kyc_status_value += "blue";
				else if (kyc_status === "pending") kyc_status_value += "yellow";
				else if (kyc_status === "rejected") kyc_status_value += "red";
				else kyc_status_value += "transparent";

				return kyc_status_value;
			}
		}
	};

	const userStatsFields = [
		{ name: "ACCOUNT MGR", value: userData?.accountManager ? userData?.accountManager : "-", currency: false },
		{ name: "ACCOUNT BAL", value: ThousandSeperated(userStatsData?.mainBalance) },
		{ name: "FREE BET CREDITS", value: ThousandSeperated(userStatsData?.freeBetCredits) },
		{ name: "WITHDRAWAL BAL", value: ThousandSeperated(userStatsData?.withdrawalBalance) },
		{ name: "AVG STAKE", value: ThousandSeperated(userStatsData?.avgStake) },
		{ name: "TOTAL BETS", value: ThousandSeperated(userStatsData?.totalBets, false), currency: false },
		{ name: "PROFIT / LOSS", value: ThousandSeperated(userStatsData?.profitLoss), className: getClassName("user_stats", userStatsData?.profitLoss) },
		{
			name: "LIFETIME POSITION",
			value: userStatsData?.lifetimePosition != 0 ? userStatsData?.lifetimePosition : "-",
			className: getClassName("user_stats", userStatsData?.lifetimePosition),
			currency: false,
			symbol: userStatsData?.lifetimePosition != 0 ? "%" : "",
		},
	];

	const overviewFields = [
		{ type: "leftSide", name: "Registered", value: getFormatDateByUserTimezone(userData?.registeredDate) },
		{ type: "leftSide", name: "Name", value: full_name },
		{ type: "leftSide", name: "Phone", value: getPhonePrefixAndNumber(userData?.phone_prefix, userData?.phone_number) },
		{ type: "leftSide", name: "Email", value: userData?.email },
		{ type: "leftSide", name: "Address", value: address },
		{ type: "leftSide", name: "City", value: userCity },
		{ type: "leftSide", name: "Post Code", value: userData.address_zip },
		{ type: "leftSide", name: "Country", value: country?.name },
		{ type: "leftSide", name: "DOB", value: getFormatDateByUserTimezone(userData?.date_of_birth, "DD MMM YYYY") },
		{ type: "leftSide", name: "Age", value: userData && userData?.date_of_birth && userData.age },
		{ type: "leftSide", name: "Language", value: languageLabel },
		{ type: "leftSide", name: "Currency", value: userData?.currency },
		{ type: "leftSide", name: "Affiliate Tag", value: affiliateUserTag },
		{ type: "leftSide", name: "Account Manager", value: userData?.accountManager },
		{ type: "leftSide", name: "Last Logged in", value: getFormatDateByUserTimezone(userData?.last_logged_in, "DD MMM YYYY HH:mm") },
		{ type: "leftSide", name: "Last Signin Location", value: userData?.last_logged_location },
		{ type: "middleSide", name: "Prematch SF", value: userData?.prematch_stake_factor, cube: "prematch" },
		{ type: "middleSide", name: "Inplay SF", value: userData?.inplay_stake_factor, cube: "inplay" },
		{
			type: "middleSide",
			name: "Last Bet Placed",
			value: getFormatDateByUserTimezone(userData?.last_bet_placed, "DD MMM YYYY HH:mm"),
			className: getFormatDateByUserTimezone(userData?.last_bet_placed, "DD MMM YYYY HH:mm") !== "-" ? "grey" : "transparent",
			divClass: "pb-4",
		},
		{ type: "middleSide", name: "KYC / AML", value: capitalizeText(userData?.kyc_status), className: getClassName("kyc_status", userData?.kyc_status) },
		{
			type: "middleSide",
			name: "Age Verified",
			value: userData?.ageVerified ? "Verified" : "Not Verified",
			className: userData?.ageVerified ? "green" : "red",
		},
		{ type: "middleSide", name: "SoF", value: sofFormattedDate, className: userData?.sof_date ? "green" : "transparent" },
		{
			type: "middleSide",
			name: "Daily Limit",
			value: userData?.deposit_limit_daily !== -1 ? userData?.deposit_limit_daily + " " + userData?.currency : "No Limit",
			className: userData?.deposit_limit_daily && userData?.deposit_limit_daily !== -1 ? "green" : "transparent",
		},
		{
			type: "middleSide",
			name: "Weekly Limit",
			value: userData?.deposit_limit_weekly !== -1 ? userData?.deposit_limit_weekly + " " + userData?.currency : "No Limit",
			className: userData?.deposit_limit_weekly && userData?.deposit_limit_weekly !== -1 ? "green" : "transparent",
		},
		{
			type: "middleSide",
			name: "Monthly Limit",
			value: userData?.deposit_limit_monthly !== -1 ? userData?.deposit_limit_monthly + " " + userData?.currency : "No Limit",
			className: userData?.deposit_limit_monthly && userData?.deposit_limit_monthly !== -1 ? "green" : "transparent",
		},
		{ type: "middleSide", name: "Reality Check", value: userData?.reality_check > 0 && `${userData?.reality_check} mins`, className: "green" },
		{
			type: "middleSide",
			name: "Self Excluded",
			value: getFormatDateByUserTimezone(userData?.excluded, "DD MMM YYYY HH:mm"),
			className: getFormatDateByUserTimezone(userData?.excluded, "DD MMM YYYY HH:mm") !== "-" ? "red" : "transparent",
		},
		{
			type: "middleSide",
			name: "Gamstop",
			value: userData && userData.gamestop ? moment(userData.gamestop).format("DD MMM YYYY") : "Not Checked",
			className: userData?.gamestop && userData?.gamestop.length !== 0 ? "red" : "transparent",
		},
	];

	const leftSide = overviewFields.filter(({ type }) => type === "leftSide");
	const middleSide = overviewFields.filter(({ type }) => type === "middleSide");

	return isLoading ? (
		<div className="d-flex justify-content-center w-100">
			<Spinner animation="border" size="lg"></Spinner>
		</div>
	) : (
		<div className="overview-container">
			<div className="row">
				<div className="d-flex overview-stats">
					{userStatsFields.map((row, i) => {
						const { name, value, currency = true, className = "", symbol = "" } = row;
						return (
							<div className={className ? className : "user-box"} key={i}>
								<span className="user-box-title">{name}</span>
								<span className="user-box-value">{`${currency ? userStatsData?.currency : ""} ${value} ${symbol}`}</span>
							</div>
						);
					})}
				</div>
			</div>
			<div className="overview-items">
				<div className="overview-item-container">
					{leftSide.map(({ name, value }, i) => {
						return (
							<div className="overview-item" key={i}>
								<span className="col-xl-6">{name}</span>
								<span className="col-xl-6 overview-second-item">{value || "-"}</span>
							</div>
						);
					})}
				</div>
				<div className="overview-item-container">
					{middleSide.map((row, i) => {
						const { name, value, cube = "", className = "" } = row;
						return (
							<div className="overview-item" key={i}>
								<span className="col-xl-6">{name}</span>
								{cube ? (
									<div className="d-flex gap-2 col-xl-6 overview-second-item">
										{stakeFactorData[cube] &&
											stakeFactorData[cube].map((item, i) => {
												const { name, value, slug } = item;
												return (
													<div key={i}>
														<span className="userMiniCube" data-tooltip={slug}>
															{name}
														</span>
														<span className="userGreenCube">{value || "-"}</span>
													</div>
												);
											})}
									</div>
								) : (
									<span className={`user-cube col-xl-6 overview-second-item ${className}`}>{value || "-"}</span>
								)}
							</div>
						);
					})}
				</div>

				<UserNotes userData={userData} extraItem={extraItem} />
			</div>
		</div>
	);
}

export default Overview;
