import React, { useEffect, useState } from "react";
import SearchFilter from "components/SearchFilter/searchFilter";
import { connect, useSelector } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getPlayersTableColumns } from "./playersTableColumns";
import { getPlayersFilterColumns } from "./playersFilterColumns";
import { EditPlayersModal } from "./editPlayersModal";
import ApiServices from "utils/ApiServices";
import { _genders } from "utils/constants";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import { AddPlayersModal } from "./addPlayersModal";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";
import MUIDataTable from "mui-datatables";
import { prepareFilterData } from "utils/global";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const Player = props => {
	const playerFilterData = useSelector(state => state.Login.searchFilterData.players);
	let loading;
	const [filterPlayer, setFilterPlayer] = useState([]);
	const [editClicked, setEditClicked] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);

	let playersFilterColumns = getPlayersFilterColumns({});

	// States
	const [playerData, setPlayerData] = useState({
		id: "",
		squad_number: "",
		position: "",
		gender: "",
		display_name: "",
		first_name: "",
		last_name: "",
	});
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [showModal, setShowModal] = useState({
		createReportModal: false,
		editPlayerModal: false,
	});
	const [isLoading, setIsLoading] = useState({
		editPlayerBtn: false,
	});
	const [currentPage, setCurrentPage] = useState(0);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [records, setRecords] = useState([]);
	const breadcrumbItems = [{ title: "Player", link: "/player" }];

	useEffect(() => {
		props.setBreadcrumbItems("Player", breadcrumbItems);
	}, []);

	let rc = [];
	let positionRecords = [];

	const filterPlayers = () => {
		setCurrentPage(0);
		setIsDataLoading(true);

		const formatedData = prepareFilterData(playerFilterData, playersFilterColumns);
		new Promise((resolve, reject) => {
			let display_name = formatedData.name;
			const apiUrl =
				apiUrls.players_list +
				"?first_name=" +
				formatedData.first_name +
				"&last_name=" +
				formatedData.last_name +
				"&position=" +
				formatedData.position +
				"&gender=" +
				formatedData.gender +
				"&type=" +
				formatedData.type +
				"&display_name=" +
				display_name;

			ApiServices.get(apiUrl, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let data = response?.data;
					data.map(ply => {
						let obj = {
							id: ply.id,
							genius_id: ply.genius_id,
							name: ply?.name,
							first_name: ply?.first_name,
							last_name: ply?.last_name,
							squad_number: ply?.squad_number,
							position: ply?.position,
							gender: ply?.gender,
							type: ply?.type,
							display_name: ply?.display_name,
						};
						if (obj.position != null && obj.position != "" && obj.position != undefined) {
							positionRecords.push({
								name: obj.position,
								value: obj.position,
							});
						}

						positionRecords = positionRecords.filter(
							(value, index, self) => index === self.findIndex(t => t.place === value.place && t.name === value.name)
						);
						rc.push(obj);
					});
					setRecords(rc);
					setFilterPlayer(data);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const savePlayerData = () => {
		setIsLoading({ ...isLoading, editPlayerBtn: true });
		let payload = {
			id: playerData.id,
			gender: playerData.gender,
			squad_number: playerData.squad_number,
			position: playerData.position,
			display_name: playerData.display_name,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.players_edit, resolve, reject, payload, true);
		}).then(response => {
			if (response.success) {
				filterPlayers();
				setShowModal({ ...showModal, editPlayerModal: false });
				setIsLoading({ ...isLoading, editPlayerBtn: false });
			}
		});
	};

	let playersTableColumns = getPlayersTableColumns({
		records,
		setShowModal,
		showModal,
		setPlayerData,
		filterPlayer,
		setEditClicked,
	});
	let page_name = "Player";

	return (
		<HelmetLayout titleProps={"Player"}>
			<div className="cms-page pt-0 cms-table-page">
				{loading ? (
					"... loading"
				) : (
					<>
						<div
							className="d-flex"
							style={{
								justifyContent: "space-between",
								position: "relative",
							}}
						>
							<div className="col-10">
								<SearchFilter
									columns={playersFilterColumns}
									page={"players"}
									preSelectedType={"select"}
									preSelectedValue={"gender"}
									preSelectedName={"Gender"}
									preSelectedData={_genders}
									preSelectedOptionKey={"value"}
									preSelectedOptionName={"name"}
									filterHandler={filterPlayers}
									filterPlayer={filterPlayer}
									filterLoading={isDataLoading}
									customizedFilter={true}
								/>
							</div>
						</div>
						{editClicked && (
							<EditPlayersModal
								showEditModal={editClicked}
								setShowEditModal={setEditClicked}
								playerData={playerData}
								setPlayerData={setPlayerData}
								savePlayerData={savePlayerData}
								isLoading={isLoading}
								setDataChanged={setDataChanged}
								dataChanged={dataChanged}
							/>
						)}
						{showAddModal && (
							<AddPlayersModal
								showAddModal={showAddModal}
								setShowAddModal={setShowAddModal}
								playerData={playerData}
								setPlayerData={setPlayerData}
								setDataChanged={setDataChanged}
								dataChanged={dataChanged}
							/>
						)}
						{
							<MUIDataTable
								columns={playersTableColumns}
								data={filterPlayer}
								options={{
									...tableBarSettings,
									selectableRows: "none",
									elevation: 0,
									onChangePage(page) {
										setCurrentPage(page);
									},
									onChangeRowsPerPage(number) {
										setRowPerPage(number);
									},
									textLabels: {
										body: {
											noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
										},
									},
									page: currentPage,
									responsive: tableResponsive,
									rowsPerPage: rowPerPage,
									rowsPerPageOptions: tableRowsPerPage,
									downloadOptions: { filename: page_name + ".csv" },
									customToolbar: () => <AddTableButton onClick={() => setShowAddModal(true)} />,
								}}
								extraButtons={tableExtraButtons}
							/>
						}
					</>
				)}
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(Player);
