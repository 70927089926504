import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { MetaTags } from "react-meta-tags";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { getAuditlogReportColumns } from "./auditlogTable";
import { ModalButton } from "components/GeneralComponents/CustomInputs";
import { AuditlogModal } from "./auditlogModal";
import { platformName } from "utils/constants";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const AuditLog = props => {
	const [auditlogData, setAuditlogData] = useState([]);
	const [auditLogModal, setAuditLogModal] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [logId, setLogId] = useState("");

	const id = props.id;
	const auditLog = () => {
		setPageLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_AUDIT_LOG + id, resolve, reject, true);
		}).then(response => {
			if (response?.success) {
				setAuditlogData(response?.data);
				setPageLoading(false);
			}
		});
	};

	useEffect(() => {
		auditLog();
	}, []);

	const auditlogReportColumns = getAuditlogReportColumns({ auditlogData, setAuditlogData, setAuditLogModal, setLogId });
	const history = useHistory();
	const redirectAuditLogPage = () => {
		history.push("/reports");
	};
	return (
		<React.Fragment>
			<MetaTags>
				<title>Audit Log - {platformName}</title>
			</MetaTags>
			{pageLoading ? (
				<div className="text-center mt-3">
					<Spinner animation="border" size="lg" />
				</div>
			) : (
				<div className="overflow-x-scroll mt-4">
					<div className="col-12 filterResponsive justify-content-between">
						<div className="col-10 d-flex"></div>
					</div>
					<div className="row">
						<div className="col-12">
							<span className="tableTitle">Audit Log</span>
							<div className="d-flex justify-content-end mb-2">
								<ModalButton
									object={{
										name: "",
										classname: "row",
										buttonName: "View All",
										onClickEvent: redirectAuditLogPage,
									}}
								/>
							</div>
							<MUIDataTable
								columns={auditlogReportColumns}
								data={auditlogData}
								options={{
									...tableBarSettings,
									customSearch: false,
									pagination: false,
									selectableRows: "none",
									elevation: 0,
									responsive: tableResponsive,
									rowsPerPage: 50,
									rowsPerPageOptions: tableRowsPerPage,
									downloadOptions: { filename: "Report - Transaction.csv" },
									textLabels: {
										body: {
											noMatch: " No data to display!",
										},
									},
								}}
								extraButtons={tableExtraButtons}
							/>
						</div>
					</div>
				</div>
			)}
			{auditLogModal && (
				<AuditlogModal handler={() => setAuditLogModal(false)} setAuditLogModal={setAuditLogModal} auditLogModal={auditLogModal} id={id} log_id={logId} />
			)}
		</React.Fragment>
	);
};
