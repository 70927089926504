import React from "react";
import "./users.scss";

import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import HelmetLayout from "../../components/HelmetLayout/HelmetLayout";

const Users = () => {
	return <HelmetLayout titleProps={"Users"} />;
};
export default connect(null, { setBreadcrumbItems })(Users);
