import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { setBreadcrumbItems, setTelebetSearchCompetitions, setTelebetSearch, setTelebetMatch } from "../../../store/actions";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import { BetSlipIcon, DarkBackArrowIcon, LeftDarkArrowIcon } from "utils/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import moment from "moment";
import { _telebetDaysTypesOptions } from "utils/constants";
import TelebetHorseracingFirstTable from "./telebet-horseracing.firstTable";
import TelebetHorseracingSecondTable from "./telebet-horseracing.secondTable";
import { getOdds, userReadOnly } from "utils/global";
import { Spinner } from "reactstrap";
import { PriceBoost } from "utils/icons";

import useGenerateBetslip from "hooks/betslip";
import useWindowSize from "hooks/useWindowSize";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			className="tabs-tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={"span"}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function TelebetEvent({ id, setShowBetslip }) {
	const history = useHistory();
	const [value, setValue] = useState(0);
	const [matchMarketsFilter, setMatchMarketsFilter] = useState([]);
	const telebetSport = useSelector(state => state.Telebet.telebetSport);
	const [marketGrup, setMarketGrup] = useState([]);
	const [matchMarkets, setMatchMarkets] = useState([]);
	const [competitionData, setCompetitionData] = useState([]);
	const telebetMatch = useSelector(state => state.Telebet.telebetMatch);
	const [days, setDays] = useState(null);
	const [competition, setCompetition] = useState([]);
	const [unChangeMatchMarkets, setUnChangeMatchMarkets] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const telebetBets = useSelector(state => state.Telebet.telebetBets);

	const generateBetslip = useGenerateBetslip();

	const dispatch = useDispatch();

	const { width } = useWindowSize();
	const isMobile = width < 768;

	const handleChange = (event, newValue, call) => {
		setValue(newValue);

		if (!showRunners || call) getTelebetMatch(newValue);
	};

	const user = useSelector(state => state.Login.user);

	const isUserReadOnly = userReadOnly(user);
	const showRunners = telebetSport?.slug === "horseracing" || telebetSport?.slug === "greyhoundracing";

	const getSportEvents = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_event_get + `?sport_slug=${telebetSport?.slug}`, resolve, reject, true);
		}).then(response => {
			let { data } = response;
			if (showRunners) {
				data.unshift({ id: 0, title: "Win / Ew" });
			}

			data = data.map(row => {
				return { id: row.id, label: row.title, ...row };
			});

			setMarketGrup(data);
		});
	};

	const getCompetitions = data => {
		let url = apiUrls.TELEBET_COMPETITIONS + `?sport_slug=${telebetSport?.slug}`;
		if (data) {
			url = apiUrls.TELEBET_COMPETITIONS + data;
		}
		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		}).then(response => {
			const { data } = response;
			setCompetitionData(data);
		});
	};

	const getTelebetMatch = data => {
		setIsLoading(true);
		let url = apiUrls.TELEBET_MATCH + `?id=${data}&match_id=${id}&sport_slug=${telebetSport?.slug}`;
		if (showRunners) {
			const competition_id = data ? data : telebetMatch?.competition_id;
			url = apiUrls.TELEBET_MATCH + `?sport_slug=${telebetSport?.slug}&competition_id=${competition_id}`;
		}
		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				// setMatchMarkets(response?.data);
				setUnChangeMatchMarkets(response?.data);
				if (data && showRunners) {
					const firstDate = response?.data[0];
					dispatch(setTelebetMatch(firstDate));
				}
			})
			.finally(() => setIsLoading(false));
	};

	const getTelebetMatchFilter = event_id => {
		let matchMarketsFilter = matchMarkets?.find(row => row.event_id === event_id);

		const runners = matchMarketsFilter?.selections
			?.filter(row => row.non_runner !== 1)
			.sort((a, b) => {
				if ((a.decimal === "SP" && b.decimal !== "SP") || (!a.decimal && !b.decimal)) {
					return 1;
				} else if ((a.decimal !== "SP" && b.decimal === "SP") || (!a.decimal && !!b.decimal)) {
					return -1;
				} else {
					const aPrice = getOdds({ ...a, sport_slug: telebetSport?.slug }, "decimal");
					const bPrice = getOdds({ ...b, sport_slug: telebetSport?.slug }, "decimal");

					return +aPrice - +bPrice;
				}
			});

		const nonRunners = matchMarketsFilter?.selections?.filter(row => row.non_runner === 1);

		if (matchMarketsFilter?.event_id) {
			const newMatchMarkets = [
				{
					runners: {
						...matchMarketsFilter,
						selections: [
							...runners,
							{
								couple_number: "?",
								forecast: false,
								non_runner: 0,
								selection_id: matchMarketsFilter?.event_id,
								selection_name: "Unnamed Favourite",
								tricast: false,
								trainer: " ",
								decimal: "SP",
								fractional: "SP",
								sport_slug: telebetSport?.slug,
							},
						],
					},

					non_runners: nonRunners,
				},
			];

			setMatchMarketsFilter(newMatchMarkets);
			if (event_id) history.push(`/telebet?event=${event_id}`);
		}
	};

	const getDays = e => {
		const currentDate = new Date();
		setDays(e);
		if (e?.id === "today" || e === "Today") {
			let today = moment().format("YYYY-MM-DD");
			let matchMarketsFilter = unChangeMatchMarkets?.filter(row => moment(row?.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") === today);
			setMatchMarkets(matchMarketsFilter);
		} else if (e?.id === "tomorrow" || e === "Tomorrow") {
			let tomorrow = currentDate.setDate(currentDate.getDate() + 1);
			let matchMarketsFilter = unChangeMatchMarkets?.filter(
				row => moment(row?.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") === moment(tomorrow).format("YYYY-MM-DD")
			);
			setMatchMarkets(matchMarketsFilter);
		} else if (e?.id === "two-weeks" || e) {
			setMatchMarkets(unChangeMatchMarkets);
		}
	};

	const getCompetition = id => {
		if (id && competitionData.length) {
			let competition = competitionData.find(row => row.id.includes(id));
			setCompetition(competition);
		}
	};

	const selectBet = ({ item, market }) => {
		let tmp = { ...telebetBets };

		const { selection_id: bet_id, decimal, fractional, boosted_decimal, boosted_fractional } = item;

		const [bet_provider] = bet_id.split("-");

		const odds_decimal = getOdds({ decimal, boosted_decimal, sport_slug: telebetSport?.slug }, "decimal");
		const odds_fractional = getOdds({ fractional, boosted_fractional, sport_slug: telebetSport?.slug }, "fractional");

		const odds = { odds_decimal, odds_fractional };

		const data = {
			description: market?.market_name,
			match_name: telebetMatch?.match_name,
			name: item?.outcome_name,
			price_boosted_enabled: boosted_decimal || boosted_fractional,
			sport_slug: telebetSport?.slug,
		};
		let new_bet = { ...item, ...data, bet_id, bet_provider, stake: 0, ...odds };

		const filteredSingles = tmp?.singles.filter(single => single.bet_id !== bet_id);

		if (filteredSingles.length >= tmp?.singles.length) {
			tmp?.singles?.push(new_bet);
		} else {
			tmp.singles = filteredSingles;
		}

		generateBetslip(tmp);
	};

	useEffect(() => {
		if (telebetSport) {
			getCompetitions();
		}
		getSportEvents();
	}, [telebetSport, id]);

	useEffect(() => {
		getTelebetMatchFilter(id);
	}, [matchMarkets.length, telebetMatch]);

	useEffect(() => {
		const newDays = telebetMatch?.day === "Today" || telebetMatch?.day === "Tomorrow" ? telebetMatch?.day : "Two Weeks";
		getDays(_telebetDaysTypesOptions.find(({ label }) => label == newDays));
		getCompetition(telebetMatch?.competition_id);
	}, [unChangeMatchMarkets.length]);

	useEffect(() => {
		if (marketGrup.length > 0) {
			const newValue = showRunners ? 0 : marketGrup.find((row, i) => i === 0).id;
			handleChange("", newValue, true);
		}
	}, [marketGrup]);

	const handleGoBack = () => {
		history.push("/telebet");
		dispatch(setTelebetSearchCompetitions([]));
		dispatch(setTelebetSearch(""));
	};

	return (
		<HelmetLayout titleProps={"In Game Telebet"}>
			<div className="in-game-telebet-competitions">
				<div className="header">
					<div className="header-title">
						<div className="d-flex justify-content-center align-items-center gap-2" onClick={() => isMobile && handleGoBack()}>
							<DarkBackArrowIcon className={"dark-back-arrow-icon"} onClick={() => handleGoBack()} />

							<LeftDarkArrowIcon className="left-dark-arrow-icon" />
							{!showRunners && <span className="telebet-match">{telebetMatch?.match_name}</span>}
						</div>

						<dev className="telebet-betslip-icon">
							<BetSlipIcon onClick={() => setShowBetslip(true)} className="betslip-icon" />
							{telebetBets?.singles?.length > 0 && <span>{telebetBets?.singles?.length}</span>}
						</dev>
					</div>

					{showRunners && (
						<div className="d-flex align-items-center w-100 gap-2">
							<GeneralDropdown
								object={{
									defaultPlaceholder: "Choose",
									wrapperClassNameDropdown: "p-0 m-0 w-100",
									options: competitionData,
									label: competition?.label,
									value: competition?.id,
								}}
								handleChange={e => {
									getTelebetMatch(e?.id);
									getCompetition(e?.id);
								}}
							/>
							<GeneralDropdown
								object={{
									defaultPlaceholder: "Choose",
									wrapperClassNameDropdown: "p-0 m-0 w-100",
									options: _telebetDaysTypesOptions,
									bindValue: days?.label || null,
								}}
								handleChange={e => getDays(e)}
							/>

							{showRunners && isMobile && (
								<GeneralDropdown
									object={{
										defaultPlaceholder: "Choose",
										wrapperClassNameDropdown: "p-0 m-0 w-50",
										options: matchMarkets.map(row => {
											return {
												id: row.event_id,
												label: moment(row?.date).add(user.offset, "minutes").format("HH:mm"),
											};
										}),
										bindValue: moment(matchMarketsFilter[0]?.runners?.date).add(user.offset, "minutes").format("HH:mm"),
									}}
									handleChange={e => getTelebetMatchFilter(e?.id)}
								/>
							)}
						</div>
					)}
				</div>
				{showRunners &&
					!isMobile &&
					matchMarkets?.map(row => {
						return (
							<button
								className={matchMarketsFilter[0]?.runners?.date === row?.date ? "telebet-button active-button mt-3" : "telebet-button mt-3"}
								key={row?.market_id}
								onClick={() => getTelebetMatchFilter(row?.event_id)}
							>
								{moment(row?.date).add(user.offset, "minutes").format("HH:mm")}
							</button>
						);
					})}
				<div className={`tabs-container ${showRunners ? "runners-tab" : ""}`}>
					<Box sx={{ width: "100%" }}>
						{isMobile ? (
							<GeneralDropdown
								object={{
									defaultPlaceholder: "Markets",
									wrapperClassNameDropdown: "p-0 align-items-center",
									normalWidth: true,
									options: marketGrup,
									bindValue: marketGrup.length > 0 && marketGrup.find(row => row?.id === value)?.label,
								}}
								handleChange={e => handleChange("", e.id, true)}
							/>
						) : (
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<Tabs value={value} onChange={handleChange} className="telebet-tabs" aria-label="basic tabs example" variant="fullWidth">
									{marketGrup.map(row => {
										return <Tab key={row?.id} value={row?.id} label={row.title} {...a11yProps(row?.id)} />;
									})}
								</Tabs>
							</Box>
						)}
						<div
							className={`${isLoading && "d-flex justify-content-center mt-4"} ${isUserReadOnly && "pe-none"} ${
								showRunners && "telebet-event-ctn-sis"
							} telebet-event-ctn`}
						>
							{isLoading ? (
								<Spinner animation="border" size="lg"></Spinner>
							) : (
								marketGrup.map(row => {
									return showRunners ? (
										<>
											<TabPanel value={value} index={row?.id} key={row?.id}>
												{value === 0 ? (
													<TelebetHorseracingFirstTable
														telebetBets={telebetBets}
														matchMarketsFilter={matchMarketsFilter}
														telebetMatch={telebetMatch}
													/>
												) : (
													<TelebetHorseracingSecondTable
														telebetBets={telebetBets}
														matchMarketsFilter={matchMarketsFilter}
														telebetMatch={telebetMatch}
													/>
												)}
											</TabPanel>
										</>
									) : (
										<TabPanel value={value} index={row?.id} key={row?.id}>
											{matchMarkets?.map(market => {
												return (
													<div className={`telebet-match-row `} key={market?.market_id}>
														<div className="telebet-match-row-title">{market?.market_name}</div>
														<div className="row m-0 w-100 justify-content-end">
															{market?.selections?.map((selection, index) => {
																const { decimal, fractional, boosted_decimal, boosted_fractional, selection_disabled, trading_status } =
																	selection;

																const isSuspended = trading_status == "suspended";
																const oddsObj = { decimal, fractional, boosted_decimal, boosted_fractional, isSuspended };

																let className = "telebet-odd w-25";

																const isSelected = telebetBets?.singles.some(row => row.bet_id === selection.selection_id);

																if (isSuspended) className += " suspended";
																if (isSelected) className += " selected";
																else if (selection_disabled) className += " disabled";

																if (boosted_decimal) className += " telebet-odd-boost";

																return (
																	<div className="telebet-match-odd col-12 col-md-4 mt-2" key={market?.market_id + index}>
																		<span className="telebet-odd w-75">{selection?.selection_name || selection?.outcome_name}</span>
																		<span
																			className={className}
																			style={{ borderLeft: "0" }}
																			onClick={() => !selection_disabled && selectBet({ item: selection, market })}
																		>
																			{getOdds(oddsObj, user?.odds_display, true)}
																			{boosted_decimal && !isSuspended && <PriceBoost className={isSelected ? "selected" : ""} />}
																		</span>
																	</div>
																);
															})}
														</div>
													</div>
												);
											})}
										</TabPanel>
									);
								})
							)}
						</div>
					</Box>
				</div>
			</div>
		</HelmetLayout>
	);
}

export default connect(null, { setBreadcrumbItems })(TelebetEvent);
