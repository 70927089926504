import React, { useEffect, useState } from "react";

import MUIDataTable from "mui-datatables";

import LoadingSpinner from "components/Loader/LoadingSpinner";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";
import { tableRowsPerPage, tableBarSettings } from "utils/tableSettings";

import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";

import { ModalOfResponsibleGambling } from "pages/Users/modalOfResponsibleGambling";
import { getSubAgentsTableColumn } from "./getSubAgentsTableColumn";
import DeleteAffiliates from "./DeleteAffiliates";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";
import { capitalizeText } from "services/capitalizeText";

const AffiliateSubAgent = props => {
	const { agents, affiliateUsers, loading, getSubAgents } = props;

	const { id: agent_id } = useParams();

	const [subAgents, setSubAgents] = useState(agents);
	const [deleteItem, setDeleteItem] = useState(null);

	const [editItem, setEditItem] = useState({ agent_id: "", revenue_share: "" });
	const [showSubAgent, setShowSubAgent] = useState("");

	const unfilteredData = agents;

	useEffect(() => {
		if (agents) setSubAgents(agents);
	}, [agents]);

	const handleEditSubAgentsItem = row => {
		setEditItem(row);
		setShowSubAgent("edit");
	};

	const handleCancelClick = () => {
		setEditItem({ agent_id: "", revenue_share: "" });
		setShowSubAgent("");
	};

	const handleSave = () => {
		const { agent_id, revenue_share: rev_share } = editItem;

		let payload = { agent_id, rev_share, parent_id: affiliateUsers.agent_id, general_fees: affiliateUsers.general_fees };
		if (showSubAgent !== "add") payload = { ...payload, currency: affiliateUsers.currency };

		new Promise((resolve, reject) => {
			if (showSubAgent === "add") ApiServices.post(apiUrls.ADD_SUB_AGENT, resolve, reject, payload, true);
			else ApiServices.put(apiUrls.EDIT_SUB_AGENT, resolve, reject, payload, true);
		})
			.then(response => {
				const { success } = response;
				if (success) getSubAgents();
			})
			.finally(() => setShowSubAgent(""));
	};

	const fields = [
		{ group: "sub_agents", type: "dropdown", fieldName: "agent_id", optionsType: "affiliateUserType", defaultPlaceholder: "Choose", params: agent_id },
		{ group: "sub_agents", type: "input", fieldName: "revenue_share", defaultPlaceholder: "Amount", numbersOnly: true },
	];

	const subAgentsColumn = getSubAgentsTableColumn({ subAgents, handleEditSubAgentsItem, setDeleteItem });

	return (
		<div style={{ position: "relative" }}>
			<SingleSearchFilter setData={setSubAgents} unfilteredData={unfilteredData} detailsColumn={["first_name"]} />
			{subAgents && (
				<MUIDataTable
					columns={subAgentsColumn}
					data={subAgents}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						sort: false,
						elevation: 0,
						textLabels: { body: { noMatch: loading ? <LoadingSpinner /> : " No data to display!" } },
						responsive: "",
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
						customToolbar: () => <AddTableButton onClick={() => setShowSubAgent("add")} />,
					}}
				/>
			)}

			{showSubAgent && (
				<ModalOfResponsibleGambling
					title={"Sub Agent"}
					paragraph={`${showSubAgent === "add" ? "Add" : "Edit"} the Sub Agent, and select the rev share %`}
					allFields={fields}
					data={editItem}
					setData={setEditItem}
					handleCancelClick={() => handleCancelClick()}
					handleClick={handleSave}
				/>
			)}

			{deleteItem && (
				<DeleteAffiliates
					paragraph={`Are you sure you want to delete this sub agent ${capitalizeText(deleteItem?.first_name)}`}
					deleteItem={deleteItem}
					setDeleteItem={setDeleteItem}
					getSubAgents={getSubAgents}
				/>
			)}
		</div>
	);
};

export default AffiliateSubAgent;
