import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import SearchFilter from "components/SearchFilter/searchFilter";
import { apiUrls } from "utils/const.apiUrl";
import { setBreadcrumbItems } from "../../../store/actions";
import { getCompetitionTableColumns } from "./competitionTableColumns";
import { getCompetitionFilterColumns } from "./competitionFilterColumns";
import { EditCompetitionModal } from "./editCompetitionModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { AddCompetitionModal } from "./addCompetitionModal";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { prepareFilterData, userReadOnly } from "utils/global";
import { getCompetitionsAllData } from "services/getCompetitionsAllData";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";
import { getCountriesDropdown } from "services/getCountriesDropdown";

const Competitions = props => {
	const competitionFilterData = useSelector(state => state.Login.searchFilterData.competitions);
	const [isLoading, setIsLoading] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [countries, setCountries] = useState([]);
	const [editClicked, setEditClicked] = useState(false);
	const sports = useSelector(state => state.Login.gsData.sports);
	const [competitions, setCompetitions] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const breadcrumbItems = [{ title: "Competitions", link: "/competitions" }];

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	let competitionFilterColumns = getCompetitionFilterColumns({
		sports,
	});

	const [viewColumns, setViewColumns] = useState({
		name: true,
		sport_name: true,
		status_txt: true,
		favorite: true,
		edit: true,
	});
	const [rowPerPage, setRowPerPage] = useState(50);

	// Report save

	useEffect(() => {
		props.setBreadcrumbItems("Competitions", breadcrumbItems);
	});
	//Filter competitions
	const filterCompetitions = () => {
		setIsLoading(true);
		let queryParams = [];
		const formatedData = prepareFilterData(competitionFilterData, competitionFilterColumns);
		//Query params for filter
		if (formatedData.sport_id) {
			queryParams.push("sport_slug=" + formatedData.sport_id);
		}
		if (formatedData.status) {
			queryParams.push("active=" + formatedData.status);
		}
		if (formatedData?.name) {
			queryParams.push("name=" + formatedData?.name);
		}

		let url = queryParams.length > 0 ? "?" + queryParams.join("&") : "";

		getCompetitionsAllData(url)
			.then(response => {
				setCompetitions(response);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	// Todo: check if we need the sport filter value from redux here
	const handleFavoriteCompetition = (id, favorite, sportSlug) => {
		setIsLoading(id);
		let payload = {
			id: id,
			favorite: favorite ? 1 : 0,
			sport_slug: sportSlug,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.competition_favorite_status_edit, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) {
					var newData = [...competitions];
					var objIndex = newData.find(obj => obj.id == id);
					objIndex.favorite = favorite ? 1 : 0;
					setCompetitions(newData);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	let competitionTableColumns = getCompetitionTableColumns({
		competitions,
		isLoading,
		handleFavoriteCompetition,
		setEditClicked,
		isUserReadOnly,
	});

	let page_name = "Competitions";

	useEffect(() => {
		getCountriesDropdown().then(response => {
			setCountries(response);
		});
	}, []);
	return (
		<HelmetLayout titleProps={"Competitions"}>
			<div className="cms-page pt-0 cms-table-page">
				<div className="col-10 d-flex justify-content-between">
					<SearchFilter
						columns={competitionFilterColumns}
						page={"competitions"}
						preSelectedType={"text"}
						preSelectedValue={"name"}
						preSelectedName={"Name"}
						preSelectedData={""}
						preSelectedOptionKey={""}
						preSelectedOptionName={""}
						filterHandler={filterCompetitions}
						filterLoading={isLoading}
						customizedFilter={true}
					/>
				</div>

				{editClicked && <EditCompetitionModal editClicked={editClicked} setEditClicked={setEditClicked} filterCompetitions={filterCompetitions} />}

				{showAddModal && (
					<AddCompetitionModal
						showAddModal={showAddModal}
						setShowAddModal={setShowAddModal}
						filterCompetitions={filterCompetitions}
						countries={countries}
					/>
				)}

				<MUIDataTable
					columns={competitionTableColumns}
					data={competitions}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						onViewColumnsChange(changedColumn) {
							Object.keys(viewColumns).forEach(key => {
								if (key == changedColumn) {
									var tempObj = viewColumns;
									var changedColumnValue = !viewColumns[key];
									tempObj[key] = changedColumnValue;
									setViewColumns(tempObj);
								}
							});
						},
						textLabels: {
							body: {
								noMatch: isLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: page_name + ".csv" },
						customToolbar: () => <AddTableButton onClick={() => setShowAddModal(true)} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(Competitions);
