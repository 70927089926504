import { apiUrls } from "utils/const.apiUrl";

const { default: ApiServices } = require("utils/ApiServices");
export const getNotes = async id => {
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.GET_USER_NOTES + id, resolve, reject, true);
	}).then(response => {
		if (response.success) {
			return response?.data || [];
		}
	});
};
