import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMatches } from "store/Matches/actions";

import { ShowBetsPerMatchModal } from "./showBetsPerMatchModal";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { formatEventPhase, formatEventTier } from "utils/global";

import "./matches.scss";

const SingleMatch = ({
	history,
	match: {
		params: { id },
	},
}) => {
	const dispatch = useDispatch();
	const [singleMatch, setSingleMatch] = useState({});
	const [dataChanged, setDataChanged] = useState(false);
	const [singleMatchMarkets, setSingleMatchMarkets] = useState([]);
	const [unChangedSingleMatch, setUnChangedSingleMatch] = useState([]);
	const [singleMatchResults, setSingleMatchResults] = useState([]);
	const matchesInStore = useSelector(state => state.Matches.matches);
	const [loading, setLoading] = useState(false);
	const [marketDataLoading, setMarketDataLoading] = useState(false);
	const [tabValue, setTabValue] = useState(0);

	const getEventView = (id, sport_slug) => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_trading_event + "?event_id=" + id + `&sport_slug=${sport_slug}`, resolve, reject, true);
		}).then(response => {
			const data = response?.data?.[0] || response?.data;

			if (data) {
				const { sport_slug } = data;

				const formatedData = {
					...data,
					current_phase: formatEventPhase(data.current_phase),
					grade_in_play: formatEventTier(data.grade_in_play),
					grade_pre_match: formatEventTier(data.grade_pre_match),
				};

				setSingleMatch(formatedData);
				setUnChangedSingleMatch(formatedData);
				getEventMarkets("all", id, sport_slug);
				getResultsMatch(id);
			}
		});
	};

	const getEventMarkets = (result, event_id, sport_slug) => {
		result = result || "all";
		setMarketDataLoading(true);
		setSingleMatchMarkets([]);

		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.event_markets + "?result=" + result + "&match_id=" + event_id + "&sport_slug=" + sport_slug, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;
				setSingleMatchMarkets(data);
			})
			.finally(() => {
				setMarketDataLoading(false);
			});
	};

	const getResultsMatch = match_id => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_event_results + `?event_id=${match_id}`, resolve, reject, true);
		}).then(response => {
			let data = response.data;
			setSingleMatchResults(data);
		});
	};

	useEffect(() => {
		const sport_slug = getQueryParam(history.location.search, "sport_slug");
		getEventView(id, sport_slug);
	}, [id]);

	const getQueryParam = (query, param) => {
		const params = new URLSearchParams(query);
		return params.get(param)?.toString();
	};

	const editSingleMatch = () => {
		setLoading(true);
		// editMarketMatch === "add" ? submitAddMarketType() :
		// editMarketMatch === "edit" ? submitEditMarketType() :
		// handleSave();

		const {
			id: matchId,
			active,
			display,
			name,
			start_time_utc,
			grade_pre_match,
			grade_in_play,
			current_phase,
			show_pre_match_bets,
			show_in_play_bets,
			overwrite_current_phase,
		} = singleMatch;
		let payload = {
			id: matchId,
			active,
			display,
			name,
			start_time_utc,
			grade_pre_match,
			grade_in_play,
			current_phase,
			show_pre_match_bets,
			show_in_play_bets,
			overwrite_current_phase,
		};

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.edit_trading_event + "?event_id=" + id + `&sport_slug=${singleMatch?.sport_slug}`, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				updateEventsInRedux();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateEventsInRedux = () => {
		if (matchesInStore?.length > 0) {
			const updatedEvents = matchesInStore.map(item => {
				if (item.event_id == id) {
					return {
						...item,
						display: singleMatch.display,
						active: singleMatch.active,
						name: singleMatch.name,
						start_time_utc: singleMatch.start_time_utc,
						current_phase: singleMatch.current_phase,
					};
				} else return item;
			});
			dispatch(setMatches(updatedEvents));
		}
	};

	//discard changes
	const handlerDiscard = () => {
		setSingleMatch(unChangedSingleMatch);
		setDataChanged(false);
	};

	return (
		<div>
			{tabValue === 0 && (
				<TopBarComponent
					classname="special_top_component"
					saveHandler={editSingleMatch}
					dataChanged={dataChanged}
					discardHandler={handlerDiscard}
					loading={loading}
					hasGoBackArrow={false}
				/>
			)}

			<div className="single-match-ctn">
				<ShowBetsPerMatchModal
					history={history}
					eventViewData={singleMatch}
					setEventViewData={setSingleMatch}
					setDataChanged={setDataChanged}
					marketMatchesViewData={singleMatchMarkets}
					marketMatchesView={getEventMarkets}
					resultData={singleMatchResults}
					getResultsMatch={getResultsMatch}
					marketDataLoading={marketDataLoading}
					handlerTabs={setTabValue}
					// setAddMarketEventData={setAddMarketEventData}
					// addMarketEventData={addMarketEventData}
					// setGeneralPage={setGeneralPage}
					// showBetsPerMatchValue={showBetsPerMatchValue}
				/>
			</div>
		</div>
	);
};

export default SingleMatch;
