import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Col, Row } from "reactstrap";

// Icon SVG
import xC from "../../assets/images/iks.svg";

const PopupModal = ({ show = false, handleClose, children, title, handler, button, formId }) => {
	return (
		<React.Fragment>
			<SweetAlert customClass={"customModal"} showConfirm={show} onHide={handleClose} title="" onConfirm={() => {}}>
				<Row>
					<Col xl={9} md={9} sm={9} xs={8}>
						<h2 className="align-items-right">{title}</h2>
					</Col>
					<Col xl={3} md={3} sm={3} xs={4} className="xUser">
						<img src={xC} onClick={handleClose} />
					</Col>
				</Row>
				<div>{children}</div>
				<div className="d-flex justify-content-end" style={{ marginTop: "15px" }}>
					<button
						className="btn btn-primary"
						style={{
							minWidth: 100,
							padding: "5px 10px",
							borderRadius: 7,
							border: "none",
							fontSize: 13.3,
							textTransform: "capitalize",
							lineHeight: 1,
							height: 30,
						}}
						type="submit"
						form={formId}
						onClick={handler}
					>
						{button}
					</button>
				</div>
			</SweetAlert>
		</React.Fragment>
	);
};

export default PopupModal;
