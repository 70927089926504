import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

function BetBuilder() {
	const [data, setData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);

	const fields = [
		{ type: "input", name: "Min Bet Amount", fieldName: "min_amount", inputClassName: "general_input", disabled: true },
		{ type: "input", name: "Max Bet Return", fieldName: "max_return", inputClassName: "general_input", disabled: true },
	];

	const getBogSettings = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.trader_options + "?type=bet-builder", resolve, reject, true);
		})
			.then(response => {
				setData(response?.data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		getBogSettings();
	}, []);

	return (
		<>
			{isDataLoading ? (
				<LoadingSpinner className={"pt-5"} />
			) : (
				<div className="cms-page cms-specific-page">
					<div className="col-12">
						<AllComponentsLoop fields={fields} data={data} setData={setData} />
					</div>
				</div>
			)}
		</>
	);
}

export default BetBuilder;
