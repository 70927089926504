import React from "react";
import { EditButton } from "components/GeneralComponents/AddButton";

export const getCompetitionsGroupTableColumns = ({
	records,
	competitionGroupsStatus,
	saveActiveInactiveCompetitionsGroups,
	editcompetitionData,
	setID,
	setFavoriteCompetitions,
	setCreatePeriodLeague,
}) => {
	return [
		{
			name: "sport_name",
			label: "Sport Name",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "30px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "30px" }, className: "max-button" }),
			},
		},
		{
			name: "name",
			label: "Name",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "30px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "30px" }, className: "max-button" }),
			},
		},
		{
			name: "created_at",
			label: "Created At",
			className: "sport",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "30px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "30px" }, className: "max-button" }),
			},
		},
		{
			name: "inactive",
			label: "Active",
			className: "sport",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<label className={`switch`}>
								<input
									type="checkbox"
									checked={competitionGroupsStatus[record.id] == 1 || competitionGroupsStatus[record.id] == null ? true : false}
									onChange={e => {
										e.persist();
										var status = e?.target?.checked;
										var id = record?.id;
										saveActiveInactiveCompetitionsGroups({ status, id });
									}}
								/>
								<span className="slider round"></span>
							</label>
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "700px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "700px" }, className: "max-button" }),
			},
		},
		{
			name: "edit",
			label: " ",
			className: "sport",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditButton
									onClick={() => {
										editcompetitionData(record?.id);
										setID(record?.id);
										let cData = JSON.parse(record?.competition_id);
										var newSport = [];
										cData.map(cd => {
											let objD = {
												name: cd?.name,
												genius_id: cd?.id,
												add_true: cd?.add_true,
											};
											newSport.push(objD);
										});
										setFavoriteCompetitions(newSport);
										setCreatePeriodLeague(true);
									}}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "50px" } }),
			},
		},
	];
};
