import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { connect, useSelector } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { getCompetitionsGroupTableColumns } from "./competitionGroupsTableColumns";
import { getMuiTheme } from "../../../components/TableMuiTheme";
import { CreateCompetitionsGroupModal } from "./createCompetitionGroupModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import { ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { platformName } from "utils/constants";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const CompetitionsGroups = props => {
	const breadcrumbItems = [{ title: "Competitions Groups", link: "/competition_groups" }];
	const sports = useSelector(state => state.Login.gsData.sports);

	// States
	const [viewColumns, setViewColumns] = useState({
		sport_name: true,
		competition_id: true,
		name: true,
		created_at: true,
		inactive: true,
		edit: true,
	});
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [createPeriodLeague, setCreatePeriodLeague] = useState(false);
	const [sportId, setSportId] = useState("");
	const [sportFilterSpinning, setSportFilterSpinning] = useState(false);
	const [favoriteCompetitions, setFavoriteCompetitions] = useState([]);
	const [saveEditFavoriteLoading, setSaveEditFavoriteLoading] = useState(false);
	const [records, setRecords] = useState([]);
	const [competitionGroupsStatus, setCompetitionGroupsStatus] = useState({});
	const [id, setID] = useState("");
	const [rowPerPage, setRowPerPage] = useState(50);
	const [competitionsGroupsData, setCompetitionsGroupsData] = useState({
		sport_id: "",
		sport_name: "",
		competitionId: "",
		name: "",
		created_at: "",
		inactive: "",
	});

	useEffect(() => {
		props.setBreadcrumbItems("Competitions Groups", breadcrumbItems);
		filterCompetitionsGroup();
	}, []);

	const saveActiveInactiveCompetitionsGroups = data => {
		var { id, status } = data;
		let active_status;
		if (status) {
			active_status = 1;
		} else {
			active_status = 0;
		}
		let payload = {
			id: id,
			status: active_status,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.competition_group_edit, resolve, reject, payload, true);
		}).then(response => {
			if (response.success) {
				setCompetitionGroupsStatus({ ...competitionGroupsStatus, [id]: active_status });
			}
		});
	};

	const editcompetitionData = id => {
		setSportFilterSpinning(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.competition_group_get + "?id=" + id, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let data = response.data;
					setSportFilterSpinning(false);
					let d = data[0];
					setCompetitionsGroupsData({
						...competitionsGroupsData,
						sport_id: d?.sport_id,
						sport_name: d?.sport_name,
						competitionId: "",
						name: d?.name,
						created_at: "",
						inactive: "",
					});
					setSportId(d?.sport_id);
					handleSportChange(d?.sport_id);
				}
			})
			.finally(() => {});
	};

	const filterCompetitionsGroup = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.competition_groups, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					var arr = [];
					var statusObj = {};
					let data = response.data;
					for (var i = 0; i < data.length; i++) {
						var row = data[i];
						var obj = {
							id: row?.id,
							sport_id: row?.sport_id,
							sport_name: row?.sport_name,
							competition_id: row?.competition_id,
							name: row?.name,
							created_at: moment(row?.created_at).format("YYYY-MM-DD"),
							inactive: row?.inactive,
						};
						arr.push(obj);
						statusObj[row.id] = row.inactive;
					}
					setRecords(arr);
					setCompetitionGroupsStatus(statusObj);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const handleSportChange = () => {
		setSportFilterSpinning(true);
	};

	const createCompetitionsGroups = () => {
		setSaveEditFavoriteLoading(true);
		let favDat = [];
		favoriteCompetitions.map(fc => {
			let obj = {
				id: fc.genius_id,
				name: fc.name,
				add_true: fc.added_new,
			};
			favDat.push(obj);
		});
		let payload = {};
		if (id) {
			payload = {
				id: id,
				sport_id: sportId,
				sport_name: competitionsGroupsData.sport_name,
				competition_id: JSON.stringify(favDat),
				name: competitionsGroupsData.name,
			};
			new Promise((resolve, reject) => {
				ApiServices.put(apiUrls.competition_group_add, resolve, reject, payload, true);
			})
				.then(response => {
					if (response.success) {
						filterCompetitionsGroup();
					}
				})
				.finally(() => {
					setSaveEditFavoriteLoading(false);
					setCreatePeriodLeague(false);
				});
		} else {
			payload = {
				sport_id: sportId,
				sport_name: competitionsGroupsData.sport_name,
				competition_id: JSON.stringify(favDat),
				name: competitionsGroupsData.name,
			};
			new Promise((resolve, reject) => {
				ApiServices.put(apiUrls.competition_group_add, resolve, reject, payload, true);
			})
				.then(response => {
					if (response.success) {
						filterCompetitionsGroup();
					}
				})
				.finally(() => {
					setSaveEditFavoriteLoading(false);
					setCreatePeriodLeague(false);
				});
		}
	};

	let competitionGroupsTableColumns = getCompetitionsGroupTableColumns({
		records,
		competitionGroupsStatus,
		saveActiveInactiveCompetitionsGroups,
		editcompetitionData,
		setID,
		setFavoriteCompetitions,
		setCreatePeriodLeague,
	});

	let page_name = "Competitions Groups";

	const handleOpenModal = () => {
		setCompetitionsGroupsData({
			...competitionsGroupsData,
			sport_id: "",
			sport_name: "",
			competitionId: "",
			name: "",
			created_at: "",
			inactive: "",
		});
		setSportId("");
		setID("");
		setFavoriteCompetitions([]);
		setCreatePeriodLeague(true);
	};

	return (
		<div>
			<MetaTags>
				<title>
					{page_name} - {platformName}
				</title>
			</MetaTags>

			<div className="cms-page pt-0 cms-table-page">
				<div
					className="col-12 d-flex"
					style={{
						justifyContent: "space-between",
					}}
				>
					<div className="col-10">
						<SingleSearchFilter />
					</div>
				</div>
				<CreateCompetitionsGroupModal
					createPeriodLeague={createPeriodLeague}
					sportFilterSpinning={sportFilterSpinning}
					setCompetitionsGroupsData={setCompetitionsGroupsData}
					setSportId={setSportId}
					handleSportChange={handleSportChange}
					sportId={sportId}
					sports={sports}
					setFavoriteCompetitions={setFavoriteCompetitions}
					setCreatePeriodLeague={setCreatePeriodLeague}
					saveEditFavoriteLoading={saveEditFavoriteLoading}
					createCompetitionsGroups={createCompetitionsGroups}
					competitionsGroupsData={competitionsGroupsData}
					favoriteCompetitions={favoriteCompetitions}
				/>
				<ThemeProvider theme={getMuiTheme()}>
					<MUIDataTable
						columns={competitionGroupsTableColumns}
						data={records}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							elevation: 0,
							onChangeRowsPerPage(number) {
								setRowPerPage(number);
							},
							onViewColumnsChange(changedColumn) {
								Object.keys(viewColumns).forEach(key => {
									if (key == changedColumn) {
										var tempObj = viewColumns;
										var changedColumnValue = !viewColumns[key];
										tempObj[key] = changedColumnValue;
										setViewColumns(tempObj);
									}
								});
							},
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							responsive: tableResponsive,
							rowsPerPageOptions: tableRowsPerPage,
							rowsPerPage: rowPerPage,
							customToolbar: () => <AddTableButton onClick={handleOpenModal} />,
						}}
						extraButtons={tableExtraButtons}
					/>
				</ThemeProvider>
			</div>
		</div>
	);
};

export default connect(null, { setBreadcrumbItems })(CompetitionsGroups);
