import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getCasinoProvidersTableColumn } from "./casinoProvidersTableColumns";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import { EditCasinoProviders } from "./showEditCasinoProviders";
import { formatToLocalDatetime } from "hooks/General";

function CasinoProviders() {
	const [casinoProvidersData, setCasinoProvidersData] = useState([]);
	const [fetchLoader, setFetchLoader] = useState(false);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [showCasinoProvidersModal, setShowCasinoProvidersModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState("");

	const getCasinoProviders = () => {
		setFetchLoader(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_CASINO_PRODUCERS, resolve, reject, true);
		})
			.then(response => {
				const data = response.data;
				const filteredData = data.map(item => ({
					...item,
					worker_period: item?.worker_period || "-",
					last_sync_formatted: item?.last_sync ? formatToLocalDatetime(item?.last_sync, "YYYY-MM-DD HH:mm") : "-",
				}));
				setUnfilteredData(filteredData);
				setCasinoProvidersData(filteredData);
			})
			.finally(() => {
				setFetchLoader(false);
			});
	};

	let casinoProvidersTableColumns = getCasinoProvidersTableColumn({ casinoProvidersData, setSelectedRow, setShowCasinoProvidersModal });

	useEffect(() => {
		getCasinoProviders();
	}, []);

	return (
		<HelmetLayout titleProps={"Casino Providers"}>
			<div className="cms-page pt-0 cms-table-page">
				<SingleSearchFilter setData={setCasinoProvidersData} unfilteredData={unfilteredData} detailsColumn={["provider_slug", "producer_name"]} />
				<MUIDataTable
					columns={casinoProvidersTableColumns}
					data={casinoProvidersData}
					options={{
						...tableBarSettings,
						pagination: true,
						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: fetchLoader ? <LoadingSpinner /> : " No data to display!",
							},
						},
						responsive: tableResponsive,
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
			{showCasinoProvidersModal && (
				<EditCasinoProviders
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
					setShowCasinoProvidersModal={setShowCasinoProvidersModal}
					casinoProvidersData={casinoProvidersData}
					setCasinoProvidersData={setCasinoProvidersData}
					getCasinoProviders={getCasinoProviders}
				/>
			)}
		</HelmetLayout>
	);
}

export default CasinoProviders;
