import { apiUrls } from "utils/const.apiUrl";
// import { getGSData } from "store/actions";

const { default: ApiServices } = require("utils/ApiServices");

const getRegionsData = async () => {
	const stopCallingApi = ["customer_service", "auditor", "operations", "affiliate"];
	// if (localStorage.getItem("_sports")) {
	// 	return JSON.parse(localStorage.getItem("_sports"));
	// }
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.GET_REGION_OPTIONS, resolve, reject, true, stopCallingApi);
	})
		.then(response => {
			let regions = response.data;

			// dispatch(getGSData(sportsData));
			return regions;
		})

		.catch(e => {
			console.log("ERROr", e);
			return [];
		});
};

export default getRegionsData;
