import React, { useEffect, useState } from "react";
import { AddButton } from "components/GeneralComponents/AddButton";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { XCloseIcon } from "utils/icons";
import MUIDataTable from "mui-datatables";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { getMarketsSelectionColumns } from "./marketsSelectionColumns";
import { AvForm } from "availity-reactstrap-validation";
import { DeleteSelectionMarketTypeModal } from "./deleteSelectionTypeMarketModal";
import { providerTypes } from "utils/constants";
import { tableBarSettings } from "utils/tableSettings";

import "./market.scss";

export const EditSelectionMarketModal = props => {
	const { modalShow, setModalShow, selectionModalMarket } = props;

	const [isLoading, setIsLoading] = useState(false);
	const [selectionsActive, setSelectionsActive] = useState("");
	const [dataChanged, setDataChanged] = useState(false);
	const [selectionTableData, setSelectionTableData] = useState([]);
	const [selectionData, setSelectionData] = useState();
	const [fields, setFields] = useState([]);

	const { market_id: marketId, market_name: marketTitle, provider_prefix } = selectionModalMarket || {};

	const selectionModalTitle = marketId + " - " + marketTitle;
	const isManualMarket = provider_prefix === providerTypes?.MANUAL;

	const getSelection = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.market_selection + "?market_id=" + marketId, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					const { data } = response;
					setSelectionTableData(data);
				}
			})
			.finally(() => {});
	};

	const handleClick = (record, type) => {
		let tempFileds = [];

		if (type === "edit") {
			setSelectionData({ ...record, market_name: marketTitle });

			tempFileds = [
				{ type: "input", name: "Outcome Name", fieldName: "outcome_name" },
				{ type: "switch", name: "Status", fieldName: "active" },
			];
		} else if (type === "delete") {
			setSelectionData(record);
			setModalShow({ ...modalShow, deleteSelectionTypeModal: true });
		} else if (type === "add") {
			const tempSelectionData = {
				outcome_name: "",
				active: 0,
			};
			tempFileds = [
				{ type: "input", name: "Outcome Name", fieldName: "outcome_name", required: true },
				{ type: "switch", name: "Status", fieldName: "active" },
			];

			setSelectionData(tempSelectionData);
		}

		setFields(tempFileds);
		setSelectionsActive(type);
	};

	const submitMarketType = () => {
		let payload = {};

		// const maxId = Math.max(...selectionTableData.map(item => item.outcome_id));
		// const newOutcomeId = maxId >= 0 ? maxId + 1 : 0;

		if (selectionsActive === "edit") payload = { id: selectionData?.id, active: selectionData?.active, outcome_name: selectionData?.outcome_name };
		else if (selectionsActive === "add") payload = { active: selectionData?.active, outcome_name: selectionData?.outcome_name, market_id: marketId };

		setIsLoading(true);

		const url = selectionsActive === "edit" ? apiUrls.market_selection_edit : apiUrls.market_selection_type_add;

		new Promise((resolve, reject) => {
			ApiServices.post(url, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) getSelection();
			})
			.finally(() => {
				setIsLoading(false);
				setSelectionsActive("");
			});
	};

	const submitDeleteMarketType = () => {
		setIsLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.select_market_type_delete + `/${selectionData.id}`, resolve, reject, true);
		})
			.then(() => {
				getSelection();
			})
			.finally(() => {
				setModalShow({ ...modalShow, deleteSelectionTypeModal: false });
				setIsLoading(false);
			});
	};

	const dependecies = { selectionsActive: selectionsActive };

	useEffect(() => {
		getSelection();
	}, []);

	useEffect(() => {
		if (selectionData?.outcome_name) {
			setSelectionData({ ...selectionData, publish_name: selectionData?.outcome_name });
		}
	}, [selectionData?.outcome_name]);

	const marketsSelectionColumns = getMarketsSelectionColumns({ selectionTableData, handleClick });

	const handler = () => {
		setModalShow({ ...modalShow, selectionModal: false, editSelectionModal: false });
	};

	return (
		<>
			<div className="promotion-container edit-market-selection">
				<div className="promotion-header">
					<span>Market Selection Type</span>
					<XCloseIcon onClick={handler} className={"xclose-icon"} />
				</div>
				<div className="promotion-body">
					<TopBarComponent saveHandler={() => submitMarketType()} dataChanged={dataChanged} loading={isLoading} onClose={handler} />
					<div className="promotion-elements market-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs market-left">
							<h4 className="mb-md-5 mb-3">{selectionModalTitle ?? ""}</h4>
							<MUIDataTable
								columns={marketsSelectionColumns}
								data={selectionTableData}
								options={{
									selectableRows: "none",
									elevation: 0,
									textLabels: {
										body: {
											noMatch: " No data to display!",
										},
									},
									responsive: "",
									rowsPerPage: 0,
									rowsPerPageOptions: [],
									downloadOptions: {},
									download: false,
									customSearch: false,
									filter: false,
									fixedSelectColumn: false,
									jumpToPage: false,
									print: false,
									resizableColumns: false,
									search: false,
									rowHover: false,
									searchOpen: false,
									selectableRowsOnClick: false,
									viewColumns: false,
									pagination: false,
									...tableBarSettings,
								}}
							/>
							{isManualMarket && (
								<div className="mt-3">
									<AddButton name={"Add New"} onClick={() => handleClick("", "add")} />
								</div>
							)}
						</div>

						{selectionsActive && (
							<div className="promotion-right col-12 col-lg-6 market-right">
								<AvForm>
									<AllComponentsLoop
										fields={fields}
										data={selectionData}
										setData={setSelectionData}
										setDataChanged={setDataChanged}
										dependecies={dependecies}
									/>
								</AvForm>
							</div>
						)}

						{modalShow.deleteSelectionTypeModal && (
							<DeleteSelectionMarketTypeModal
								modalShow={modalShow}
								setModalShow={setModalShow}
								deleteMaketSelection={submitDeleteMarketType}
								isLoading={isLoading}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
