import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

export const getCompetitionsAllData = async url => {
	const params = url ? url : "";
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.competition_list + params, resolve, reject, true);
	})
		.then(response => {
			let competitions = response?.data;
			competitions = competitions.map(row => {
				return row;
			});
			return competitions;
		})
		.catch(() => {
			return [];
		});
};
