import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import SignInFooter from "pages/Authentication/SignInFooter";
import sideArrow from "../../assets/images/side-arrow.svg";
import { useSelector } from "react-redux";

const PermissionDenied = () => {
	const user = useSelector(state => state.Login.user);
	const isAffiliate = user?.role === "affiliate";

	useEffect(() => {
		document.body.classList.add("error-body");
		return () => {
			document.body.classList.remove("error-body");
		};
	}, []);

	return (
		<React.Fragment>
			<MetaTags>
				<title>Permission Denied</title>
			</MetaTags>
			<div className="error-page">
				<Container>
					<Row className="justify-content-center">
						<Col md="8" lg="6" xl="8">
							<div className="ex-page-content text-center">
								{/* <div className="error-404">404</div> */}
								<div className="error-text">Permission Denied</div>
								<div className="error-redirect">
									<Link className="error-button form-button" to={isAffiliate ? "/affiliates" : "/dashboard"}>
										Back to {isAffiliate ? "Affiliate" : "Dashboard"} <img src={sideArrow} alt="side-arrow" />
									</Link>
								</div>
							</div>
							<SignInFooter />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default PermissionDenied;
