import moment from "moment";

export function ThousandSeperated(number, withDecimals = true, showZero = false) {
	// check if number is string

	if (typeof number === "string" && number.indexOf("/") > -1) {
		return number;
	}

	if ((!number && !showZero) || isNaN(number)) return "-";
	if (number == "-") return number;

	if (withDecimals) {
		return Number(number).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
	} else {
		return Number(number).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
	}
}

export const getBase64 = file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {};

		reader.onloadend = () => {
			return resolve(reader.result);
		};

		reader.onerror = error => reject(error);
	});
};

export const checkIfImageWhite = objBase64 => {
	return new Promise(resolve => {
		// Create new image element
		const img = new Image();

		// Set image source as base64 string
		img.src = objBase64;

		// Define function to execute after loading the image
		img.onload = () => {
			// Create canvas element
			const canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;

			// Draw image onto the canvas
			const ctx = canvas.getContext("2d");

			ctx.drawImage(img, 0, 0);

			// Read the pixel data
			const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			const pixels = imgData?.data;

			// Check if all pixels are white (255, 255, 255)
			for (let i = 0; i < pixels.length; i += 4) {
				if (pixels[i] !== 255 || pixels[i + 1] !== 255 || pixels[i + 2] !== 255) {
					return resolve(false);
				}
			}
			return resolve(true);
		};
	});
};

export const changeStateOnDeleteAndEdit = vars => {
	var arr = [];
	var { array, key, value, key_for_change, mode, conditionValue } = vars;
	for (var i = 0; i < array.length; i++) {
		var row = array[i];
		if (row[key] == conditionValue) {
			if (mode == "edit") {
				if (Array.isArray(key_for_change)) {
					for (var j = 0; j < key_for_change.length; j++) {
						row[key_for_change[j]] = value[j];
					}
				} else {
					row[key_for_change] = value;
				}
			} else if (mode == "delete") {
				continue;
			}
		}
		arr = [...arr, row];
	}
	return arr;
};

export const queryStringToJSON = () => {
	var pairs = location.search.slice(1).split("&");
	var result = {};
	pairs.forEach(function (pair) {
		pair = pair.split("=");
		result[pair[0]] = decodeURIComponent(pair[1] || "");
	});
	return JSON.parse(JSON.stringify(result));
};

export const formatToLocalDatetime = (datetime, format) => {
	format = format || "YYYY-MM-DD HH:mm:ss";
	if (datetime) {
		return moment.utc(datetime).local().format(format);
	}
	return "-";
};

export const isEmpty = obj => {
	return Object.keys(obj).length === 0;
};
