import React, { useRef } from "react";
import { GamblingButon } from "components/GeneralComponents/AddButton";
import useOutsideClick from "hooks/useOutsideClick";

import "./LogoutModal.scss";

export const LogoutModal = ({ title, paragraph, setShowLogoutModal, signOutUser }) => {
	const modalRef = useRef(null);

	useOutsideClick(modalRef, () => setShowLogoutModal(false));

	return (
		<React.Fragment>
			<div className="bg-modal" style={{ zIndex: "10002" }}>
				<div className="bg-text-logout" ref={modalRef}>
					<div className="row align-items-center justify-content-center">
						<span className="mt-4">
							<h4 className="logout-title"> {title} </h4>
						</span>
						<br />
						<p className="mt-2 logout-paragraph" style={{ padding: "0px 60px" }}>
							{paragraph}
						</p>
						<div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
							<GamblingButon text={"No"} isCancel onClick={() => setShowLogoutModal(false)} />
							<GamblingButon text={"Yes"} style={{ backgroundColor: "var(--global-color-primary) !important" }} onClick={() => signOutUser()} />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
