import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { XCloseIcon } from "utils/icons";
import React, { useEffect, useState } from "react";
import { GeneralInput, RegionsInput } from "components/GeneralComponents/CustomInputs";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";
import { useSelector } from "react-redux";

export const ShowEditVenue = props => {
	const { editItem, title, handler, setVenuesNewData, setShowPromotion, venuesNewData } = props;

	const [loading, setLoading] = useState(false);
	const [regionsData, setRegionsData] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);
	const [venuesData, setVenuesData] = useState({
		id: editItem?.id || "",
		name: editItem?.name || "",
		capacity: editItem?.capacity || "",
		latitude: editItem ? editItem?.latitude : "",
		longitude: editItem ? editItem?.longitude : "",
		region_id: editItem ? editItem?.region_id : "",
	});
	const region = useSelector(state => state.Login.gsData.regions);

	const getRegions = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.region_get, resolve, reject, true);
		}).then(response => {
			setRegionsData(response?.data);
		});
	};

	const addVenuesData = () => {
		let payload = {
			name: venuesData?.name,
			capacity: venuesData?.capacity,
			latitude: venuesData?.latitude,
			longitude: venuesData?.longitude,
			region_id: venuesData?.region_id,
		};

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.venue_add, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				let updatedData = [...venuesNewData];
				let copiedPayload = payload;
				copiedPayload.region_name = region.find(item => item.id === payload.region_id).name;
				let index = updatedData.findIndex(item => item.id === editItem?.id);
				updatedData[index] = payload;
				setVenuesNewData(updatedData);
				handler();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const editVenuesData = () => {
		let payload = {
			id: venuesData?.id,
			name: venuesData?.name,
			capacity: venuesData?.capacity,
			latitude: venuesData?.latitude,
			longitude: venuesData?.longitude,
			region_id: venuesData?.region_id,
		};

		setLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.venue_edit, resolve, reject, payload, true);
		})
			.then(() => {
				if (editItem) {
					let updatedData = [...venuesNewData];
					let copiedPayload = payload;
					copiedPayload.region_name = region.find(item => item.id === payload.region_id).name;
					let index = updatedData.findIndex(item => item.id === editItem?.id);
					updatedData[index] = payload;
					setVenuesNewData(updatedData);
				} else {
					setVenuesNewData([...venuesNewData, payload]);
				}
				handler();
			})
			.finally(() => {
				setShowPromotion(false);
				setLoading(false);
			});
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	useEffect(() => {
		getRegions();
	}, []);
	return (
		<>
			<div className="promotion-container">
				<div className="promotion-header">
					<span>{title}</span>
					<XCloseIcon className={"xclose-icon"} onClick={handler} />
				</div>
				<div className="promotion-body">
					<TopBarComponent saveHandler={editItem ? editVenuesData : addVenuesData} loading={loading} dataChanged={dataChanged} onClose={handler} />
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
							<GeneralInput
								object={{ name: "Name", placeholder: "Name", firstRowClass: true, value: venuesData?.name }}
								onChange={e => {
									setVenuesData({ ...venuesData, name: e.target.value });
									handleDataChanged();
								}}
							/>
							<GeneralInput
								object={{ name: "Capacity", placeholder: "Capacity", firstRowClass: true, value: venuesData?.capacity, otherRowClass: true }}
								onChange={e => {
									setVenuesData({ ...venuesData, capacity: e.target.value });
									handleDataChanged();
								}}
							/>
							<RegionsInput
								regionsData={regionsData}
								data={venuesData}
								setData={setVenuesData}
								handleDataChanged={handleDataChanged}
								type={"edit_venue"}
								otherRowClass
							/>
							<GeneralInput
								object={{ name: "Longitude", placeholder: "Longitude", firstRowClass: true, value: venuesData?.longitude, otherRowClass: true }}
								onChange={e => {
									setVenuesData({ ...venuesData, longitude: e.target.value });
									handleDataChanged();
								}}
							/>
							<GeneralInput
								object={{ name: "Latitude", placeholder: "Latitude", firstRowClass: true, value: venuesData?.latitude, otherRowClass: true }}
								onChange={e => {
									setVenuesData({ ...venuesData, latitude: e.target.value });
									handleDataChanged();
								}}
							/>
						</div>
						<div className="promotion-right col-12 col-lg-6"></div>
					</div>
				</div>
			</div>
		</>
	);
};
