import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import { withRouter } from "react-router-dom";
import { CmsSidebar, ProfileSidebar, ReportSidebar, SettingsSidebar, SportsSidebar, TradingSidebar, UsersSidebar, AffiliateSidebar } from "./SidebarComponents";
import { cmsLinks, platformName, settingsLinks, tradingLinks } from "utils/constants";

import { setActivePage } from "store/actions";
import { setShowSidebar } from "store/layout/actions";

const tradingPagesNested = ["/events/", "/bet/", "/liability/"];
const reportsLinks = ["/reports", "/open-report"];

const SidebarContent = props => {
	const ref = useRef();
	const lsUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

	const activePage = useSelector(state => state.Login.activePage);
	const showSidebar = useSelector(state => state.Layout.showSidebar);

	const dispatch = useDispatch();
	const [pathname, setPathname] = useState();

	const locationPathName = props.location.pathname;

	let dynamicArray = [];

	useEffect(() => {
		ref.current.recalculate();
	});

	const onLinkClick = () => {
		if (showSidebar) dispatch(setShowSidebar(false));
	};

	useEffect(() => {
		let cms;
		let trading;

		cmsLinks.map(row => {
			let links = row?.links;
			let cmsFilter = links.filter(item => locationPathName == item.route);
			if (cmsFilter?.length > 0) {
				cms = cmsFilter;
			}
		});

		tradingLinks.map(row => {
			let links = row?.links;
			let tradingFilter = links.filter(item => locationPathName == item.link);

			if (tradingFilter?.length > 0) {
				trading = tradingFilter;
			}
		});

		const settings = settingsLinks.some(row => locationPathName == row.link);

		if (cms?.length > 0) {
			setPathname("/cms");
			dynamicArray = cmsLinks;
		} else if (settings) {
			setPathname("/settings");
			dynamicArray = settingsLinks;
		} else if (locationPathName === "/users_chat") {
			setPathname("/users_chat");
		} else if (locationPathName.includes("profile")) {
			setPathname("/profile");
		} else if (locationPathName.includes("ladders")) {
			setPathname("/settings");
		} else if (locationPathName.includes("affiliate_users")) {
			setPathname("/settings");
		} else if (trading?.length > 0) {
			setPathname("/trading");
			dynamicArray = tradingLinks;
		} else if (locationPathName === "/search_users" || locationPathName.startsWith("/user/")) {
			setPathname("/search_users");
		} else if (tradingPagesNested.some(link => locationPathName.startsWith(link))) {
			setPathname("/trading");
		} else if (locationPathName.startsWith("/sport/") && locationPathName?.split("/")?.length == 3) {
			setPathname("/trading");
		} else if (locationPathName.includes("affiliates")) {
			setPathname("/affiliates");
		} else if (reportsLinks.some(link => locationPathName.startsWith(link))) {
			setPathname("/reports");
		}
	}, [locationPathName]);

	useEffect(() => {
		if (pathname) {
			dispatch(setActivePage(pathname));
		}
	}, [pathname]);

	useEffect(() => {
		if (locationPathName) {
			const selectedLink = dynamicArray?.flatMap(row => row.links).find(link => link?.route === locationPathName);
			if (selectedLink) {
				let selected = document.getElementsByClassName(`activeMenuLink`)[0];
				if (selected) {
					selected.scrollIntoView({ behavior: "auto" });
				}
			}
		}
	}, []);

	useEffect(() => {
		const handlePaginationClick = () => {
			// Scroll the table container to the top
			const tableContainer = document.querySelector("table");
			if (tableContainer) tableContainer.scrollIntoView();
		};
		// Add event listeners to pagination buttons
		const paginationButtons = document.querySelectorAll(".MuiTablePagination-actions button");

		paginationButtons.forEach(button => button.addEventListener("click", handlePaginationClick));

		// Clean up event listeners
		return () => {
			paginationButtons.forEach(button => button.removeEventListener("click", handlePaginationClick));
		};
	}, []);

	const ActiveMenu = useMemo(() => {
		switch (activePage) {
			case "/dashboard":
				return <div className="menu-title">DASHBOARD</div>;
			case "/profile":
				return <ProfileSidebar lsUser={lsUser} onClick={onLinkClick} props={props} />;
			case "/search_users":
				return <UsersSidebar lsUser={lsUser} onClick={onLinkClick} props={props} />;
			case "/users_chat":
				return <UsersSidebar lsUser={lsUser} onClick={onLinkClick} props={props} />;
			case "/matches":
				return <div className="menu-title">EVENTS</div>;
			case "/reports":
				return <ReportSidebar onClick={onLinkClick} />;
			case "/trading":
				return <TradingSidebar lsUser={lsUser} onClick={onLinkClick} props={props} />;
			case "/sports":
				return <SportsSidebar lsUser={lsUser} onClick={onLinkClick} props={props} />;
			case "/cms":
				return <CmsSidebar lsUser={lsUser} onClick={onLinkClick} props={props} />;
			case "/settings":
				return <SettingsSidebar lsUser={lsUser} onClick={onLinkClick} props={props} />;
			case "/affiliates":
				return <AffiliateSidebar lsUser={lsUser} onClick={onLinkClick} props={props} />;
			default:
				return <div className="menu-title"> DASHBOARD</div>;
		}
	}, [activePage, lsUser, props]);

	return (
		<React.Fragment>
			<SimpleBar style={{ maxHeight: "120%" }} ref={ref}>
				<div id="sidebar-menu">{ActiveMenu}</div>
				<div className="d-flex copyRight">
					Copyright © {platformName} {new Date().getFullYear()}
					<span className="d-none d-sm-inline-block"> </span>
				</div>
			</SimpleBar>
		</React.Fragment>
	);
};

SidebarContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
};

export default withRouter(SidebarContent);
