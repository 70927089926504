import React, { useEffect, useState } from "react";
import { PenEditButton, MinusButton } from "components/GeneralComponents/AddButton";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import ApiServices from "utils/ApiServices";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { apiUrls } from "utils/const.apiUrl";
import { capitalizeText } from "services/capitalizeText";
import { getImageThumbnail } from "utils/global";
import { EditCategory } from "./editCategory";
import { getAllCasinoRestrictionDropdown } from "services/getAllCasinoRestrictionDropdown";
import MissingLogoContent from "../../../assets/images/MissingLogoContent.svg";
import DragAndDropList from "components/GeneralComponents/DragAndDrop";
import { DragAndDropFillIcon, DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone } from "utils/icons";

const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;

export const CasinoItem = ({ setSelectedCategory, categoryGames, setCategoryGames, selectedCategory, editCasinoGrups, type }) => {
	const [editCategory, setEditCategory] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [categories, setCategories] = useState([]);

	const getGroupItems = id => {
		if (id) {
			setIsLoading(true);
			new Promise((resolve, reject) => {
				ApiServices.get(`${apiUrls.get_casino_group_items}?group_id=${id}`, resolve, reject, true);
			})
				.then(response => {
					setCategoryGames(response?.data);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setCategoryGames([]);
		}
	};

	useEffect(() => {
		if (editCasinoGrups) {
			getGroupItems(selectedCategory);
		}
	}, [editCasinoGrups]);

	const getCategories = async callAgain => {
		const categories = await getAllCasinoRestrictionDropdown(true, callAgain, type);
		setCategories(categories);
	};

	useEffect(() => {
		getCategories(true);
	}, [type]);

	const removeCasinoGame = game => {
		let payload = {
			group_id: selectedCategory,
			game_id: game?.id,
			type: "remove",
		};
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.add_casino_game, resolve, reject, payload, true);
		}).then(() => {
			let actualData = [...categoryGames];
			actualData = actualData.filter(row => row.id !== game.id);
			setCategoryGames(actualData);
		});
	};
	const handleChangeDropdown = e => {
		if (e) {
			getGroupItems(e.id);
			setSelectedCategory(e.id);
		} else {
			setCategoryGames([]);
			setSelectedCategory();
		}
	};

	const changeOrder = index => {
		const selectedRow = categoryGames.splice(index, 1)[0];
		const newData = [selectedRow, ...categoryGames];
		setDregAndDropData(newData);
	};

	const setDregAndDropData = newData => {
		setCategoryGames(newData);
		orderItems(newData);
	};

	const orderItems = data => {
		let payload = {
			group_id: selectedCategory,
			orders: data.map((row, index) => ({ game_id: row.id, order: index })),
		};
		setIsLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.order_casino_games, resolve, reject, payload, true);
		})
			.then(() => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			<div className="casino-item">
				<div className="casino-category">
					<div className="casino-category-search">
						<GeneralDropdown
							object={{
								value: selectedCategory,
								options: categories,
								wrapperClassNameDropdown: "w-100 pt-0",
								extraWidth: "w-100",
								defaultPlaceholder: "Category",
								handleChangeDropdown: e => handleChangeDropdown(e),
							}}
						/>
					</div>
					<PenEditButton className={"search-bar-pen"} onClick={() => setEditCategory(true)} />
				</div>
				{isLoading ? (
					<LoadingSpinner className={"pt-3"} />
				) : (
					<div className="casino-elements">
						<DragAndDropList data={categoryGames} setData={newData => setDregAndDropData(newData)}>
							{(row, { index, dragHandleProps, isDragging }) => {
								let details;

								try {
									if (row?.details) details = JSON.parse(row?.details);
								} catch (e) {
									details = {};
								}

								const published = row?.published ? "Published" : "Inactive";
								const classNamePublished = row?.published ? "badge badge-sports-published me-2" : "badge badge-sports-inactive me-2";
								const default_image = row?.default_image;
								const image = default_image ? row?.web_image_url : `${aws_s3_url}/media/${getImageThumbnail(details?.web_image)}`;

								const subTitle = `${capitalizeText(row?.provider)} | ${row?.slug}`;
								return (
									<div className="casino-element" key={index}>
										<div className="element-start">
											{/* <img src={image} className={"game-image"} alt={row?.title} /> */}
											{image ? (
												<img
													src={image}
													className={"game-image"}
													alt={row?.title}
													onError={({ currentTarget }) => {
														currentTarget.onerror = null;
														currentTarget.src = MissingLogoContent;
													}}
												/>
											) : (
												<img src={MissingLogoContent} />
											)}

											<div className="ms-2">
												<span className="casino-element-name">{row?.title}</span>
												<span className="casino-element-type">{subTitle}</span>
											</div>
										</div>
										<div className="element-end">
											<span className={classNamePublished}>{published}</span>

											<div className="d-flex justify-content-end align-items-center gap-2">
												{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}

												<div {...dragHandleProps} onMouseDown={(...args) => dragHandleProps?.onDragStart(...args)}>
													<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
												</div>
												<MinusButton onClick={() => removeCasinoGame(row)} />
											</div>
										</div>
									</div>
								);
							}}
						</DragAndDropList>
					</div>
				)}
			</div>
			{editCategory && (
				<EditCategory setEditCategory={setEditCategory} type={type} categories={categories} setCategories={setCategories} getCategories={getCategories} />
			)}
		</>
	);
};
