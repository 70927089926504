import React from "react";
import { Spinner } from "reactstrap";
import {
	CheckedCheckboxIcon,
	CloneIcon,
	DeleteIcon,
	EyeIcon,
	MinusIcon,
	MoveDownDisabledIcon,
	MoveDownIcon,
	MoveUpDisabledIcon,
	MoveupIcon,
	PenIcon,
	PlusIcon,
	UncheckedCheckboxIcon,
} from "utils/icons";
import { EditIcon } from "./../../utils/icons";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";

export const TableColumnsButton = ({ onClick, name }) => {
	return (
		<button className="btn-primary tableColumnsButton" onClick={onClick}>
			<span className="textButtonTypography">{name}</span>
		</button>
	);
};

export const AddButton = ({ onClick, name, style, disabled }) => {
	return (
		<button className="btn-primary saveViewButton me-2" onClick={onClick} style={style} disabled={disabled}>
			<span className="textButtonTypography"> {name} </span>
		</button>
	);
};
export const NowButton = ({ onClick, name, style }) => {
	return (
		<button className="btn-primary nowButton " onClick={onClick} style={style}>
			<span className="textButtonTypography"> {name} </span>
		</button>
	);
};

export const SaveButton = ({ onClick, name, classname, loading, disabled }) => {
	return (
		<button className="btn-primary saveButtonStyle saveButtonCustomized" disabled={disabled} onClick={onClick}>
			{loading ? <Spinner animation="border" size={"sm"} /> : <span className={`textButtonTypography ${classname}`}> {name} </span>}
		</button>
	);
};

export const CancellButton = ({ name }) => {
	return <button className="btn-secondary cancelButtonStyle ">{name}</button>;
};

export const SaveViewButton = ({ onClick, name }) => {
	return (
		<button className="btn-primary saveViewButton" onClick={onClick}>
			<span className="textButtonTypography">{name}</span>
		</button>
	);
};

export const LoadingSaveButton = ({ onClick }) => {
	return (
		<button className="btn-primary saveButtonStyle" onClick={onClick}>
			<Spinner animation="border" color="light" size="sm"></Spinner>
		</button>
	);
};

export const CancelButton = ({ onClick, name }) => {
	return (
		<button className="btn btn-light cancelButtonStyle" onClick={onClick}>
			<span className="textButtonTypographyy">{name}</span>
		</button>
	);
};

export const BlueCancelButton = ({ onClick, name }) => {
	return (
		<button className="btn btn-light cancelButtonStyle" onClick={onClick} style={{ border: "1px solid var(--global-color-primary)" }}>
			<span className="textButtonTypographyy" style={{ color: "var(--global-color-primary)" }}>
				{name}
			</span>
		</button>
	);
};

export const FilterButton = props => {
	const { onClick, name, style, className, loading } = props;

	return (
		<div className="filterBtnPosition">
			<button className={`btn btn-primary filterButton ${className || ""}`} onClick={onClick} style={style}>
				{loading ? <Spinner animation="border" size={"sm"} /> : <span className="textButtonTypography"> {name} </span>}
			</button>
		</div>
	);
};

export const LoadingFilterButton = ({ onClick, style, name }) => {
	return (
		<div className=" filterBtnPosition" style={style}>
			<button className="btn btn-primary filterButton" onClick={onClick}>
				<span className="textButtonTypography"> {name} </span>
			</button>
		</div>
	);
};
export const YesButton = ({ onClick, name, disabled = false }) => {
	return (
		<button className="btn-primary saveButtonStyle text-white" onClick={onClick} disabled={disabled}>
			<span className="textButtonTypography"> {name} </span>
		</button>
	);
};
export const NoButton = ({ onClick, name }) => {
	return (
		<button className="btn btn-light cancelButtonStyle" onClick={onClick}>
			<span className="textButtonTypographyy">{name}</span>
		</button>
	);
};

export const EyeButton = ({ onClick, className }) => {
	return (
		<button className={`btn btn-primary btn-sm btn-edit ${className && className}`} onClick={onClick}>
			<EyeIcon />
		</button>
	);
};

export const EditButton = ({ onClick, loading, disabled = false }) => {
	return (
		<button className={`btn btn-primary btn-sm btn-edit ${disabled ? "pe-none disable-btn" : ""}`} onClick={onClick} disabled={disabled}>
			{loading ? <Spinner animation={"border"} size={"sm"} /> : <PenIcon />}
		</button>
	);
};
export const CloneButton = ({ onClick }) => {
	return (
		<button className="btn btn-primary btn-sm btn-clone-sport" onClick={onClick}>
			<CloneIcon />
		</button>
	);
};

export const DiscardUnchangedButton = ({ onClick, name }) => {
	return (
		<button className="btn-primary unchanged-btn" onClick={onClick}>
			<span className="textButtonTypography">{name}</span>
		</button>
	);
};

export const DiscardButton = ({ onClick, name, style, dataChanged = false }) => {
	return (
		<button className={dataChanged ? "discardButton discardActiveButton" : "discardButton"} onClick={onClick} style={style} disabled={!dataChanged}>
			<span className="textButtonTypography">{name}</span>
		</button>
	);
};

export const SaveLightButtonCustum = ({ onClick, name, loading, style, formId, type, classname, setLadderArray, id, setAddLadderArray, setDataChanged }) => {
	if (!id) {
		id = "master";
	}
	const handleFileUpload = event => {
		let file = event.target.files[0];
		if (file) {
			const formData = new FormData();
			formData.append("csvFile", file);

			new Promise((resolve, reject) => {
				ApiServices.post(apiUrls.import_ladder + "?sportSlug=" + id, resolve, reject, formData, true);
			}).then(response => {
				const { data } = response;
				setLadderArray(data);
				setAddLadderArray(data);
				setDataChanged(false);
			});
		}
	};

	return (
		<button className={classname ? `saveLightButton ${classname}` : "saveLightButton"} onClick={onClick} style={style} form={formId} type={type}>
			<span className="textButtonTypography" style={{ color: "#fffff" }}>
				{loading ? <Spinner animation={"border"} size={"sm"} /> : name}
			</span>
			<input type="file" onChange={handleFileUpload} style={{ display: "none" }} />
		</button>
	);
};

export const SaveLightButton = ({ onClick, name, loading, style, formId, type, classname }) => {
	return (
		<button
			className={classname ? `saveLightButton ${classname}` : "saveLightButton"}
			onClick={() => !loading && onClick?.()}
			style={style}
			form={formId}
			type={type}
		>
			<span className="textButtonTypography" style={{ color: "#fffff" }}>
				{loading ? <Spinner animation={"border"} size={"sm"} /> : name}
			</span>
		</button>
	);
};

export const DeleteButton = ({ onClick, componentName }) => {
	return (
		<button className="btn btn-delete-carousel" style={{ marginRight: "5px", height: "30px" }} onClick={onClick}>
			Delete {componentName}
		</button>
	);
};

export const DisableCountryButton = ({ onClick }) => {
	return (
		<button className="btn btn-disable-country" onClick={onClick}>
			Disable Country
		</button>
	);
};

export const EnableCountryButton = ({ onClick }) => {
	return (
		<button className="btn btn-enable-country" onClick={onClick}>
			Enable Country
		</button>
	);
};

export const SaveUnchangedButton = ({ onClick, text, buttonStyle }) => {
	return (
		<button className="btn-primary unchanged-btn" onClick={onClick} style={buttonStyle ? buttonStyle : { cursor: "default" }}>
			<span className="textButtonTypography">{text ? text : "Save"}</span>
		</button>
	);
};
export const GamblingButon = ({ onClick, text, style, isCancel }) => {
	return (
		<button className={isCancel ? "btn-primary cancel-btn" : "save-btn"} onClick={onClick} style={style}>
			<span className="textButtonTypography">{text ? text : "Save"}</span>
		</button>
	);
};

export const AddNewButton = ({ onClick, name, loading, disabled }) => {
	return (
		<button className="btn-primary saveViewButton" onClick={onClick} disabled={disabled}>
			{loading ? <Spinner animation="border" size="sm"></Spinner> : <span className="textButtonTypography">{name}</span>}
		</button>
	);
};
export const UploadButton = ({ onClick, name, classname, loading }) => {
	return (
		<button className={classname ? `btn-primary saveViewButton ${classname}` : "btn-primary saveViewButton"} onClick={onClick}>
			{loading ? <Spinner animation="border" size="sm"></Spinner> : <span className="textButtonTypography">{name}</span>}
		</button>
	);
};

export const MoveDownButton = ({ onClick, className }) => {
	return <MoveDownIcon onClick={onClick} className={className} />;
};
export const MoveUpButton = ({ onClick, className }) => {
	return <MoveupIcon onClick={onClick} className={className} />;
};

export const MoveUpDisabledButton = ({ onClick, className }) => {
	return <MoveUpDisabledIcon onClick={onClick} className={className} />;
};

export const MoveDownDisabledButton = ({ onClick, className }) => {
	return <MoveDownDisabledIcon onClick={onClick} className={className} />;
};

export const PenEditButton = ({ onClick, className }) => {
	let elClassName = className ? "btn-primary btn-pen-edit search-bar-pen" : "btn-primary btn-pen-edit";
	return (
		<button className={elClassName} onClick={onClick}>
			<PenIcon />
		</button>
	);
};

export const MinusButton = ({ onClick, className }) => {
	let elClassName = className ? "btn-primary btn-pen-edit search-bar-pen" : "btn-primary btn-pen-edit";
	return (
		<button className={elClassName} onClick={onClick} style={{ padding: "0 5.5px" }}>
			<MinusIcon />
		</button>
	);
};

export const PublishedInputPublishedButton = ({ onClick, name = "Published" }) => {
	return (
		<span className="active-status-element" onClick={onClick}>
			{name}
		</span>
	);
};

export const InactiveButton = ({ onClick, name = "Inactive" }) => {
	return (
		<span onClick={onClick} className="inactive-status-element">
			{name}
		</span>
	);
};

export const CheckBoxButton = ({ publishData, published }) => {
	return published ? (
		<CheckedCheckboxIcon onClick={() => publishData(false)} className={"promotion-item-icon"} />
	) : (
		<UncheckedCheckboxIcon onClick={() => publishData(true)} className={"promotion-item-icon"} />
	);
};
export const HeroCheckBoxButton = ({ publishHeroWidget, published }) => {
	return published ? (
		<CheckedCheckboxIcon onClick={() => publishHeroWidget(false)} className={"promotion-item-icon"} />
	) : (
		<UncheckedCheckboxIcon onClick={() => publishHeroWidget(true)} className={"promotion-item-icon"} />
	);
};

export const EditIconButton = ({ onClick, className, onFocus }) => {
	return <EditIcon onClick={onClick} className={className} onFocus={onFocus} />;
};

export const DeleteIconButton = ({ onClick, className, isDisabled }) => {
	return <DeleteIcon onClick={onClick} className={className} isDisabled={isDisabled} />;
};

export const XButton = ({ onClick }) => {
	return (
		<button className="btn editIcon" onClick={onClick}>
			<img src={<DeleteIcon />} style={{ verticalAlign: "baseline", height: "24px", width: "24px" }} />
		</button>
	);
};

export const LanguageShortcuts = ({ language }) => {
	return <div className="languageShortcut"> {language} </div>;
};

export const PlusAddButton = ({ onClick, id, loading }) => {
	return (
		<button className="btn-primary btn-plus-add" onClick={onClick}>
			{loading === id ? <Spinner animation={"border"} color="light" style={{ width: "14px", height: "14px", borderWidth: "2px" }} /> : <PlusIcon />}
		</button>
	);
};

export const AddNewContent = ({ onClick, name }) => {
	return (
		<button className="btn-primary btn-add-new-content" onClick={onClick}>
			Add New {name}
		</button>
	);
};

export const MediaEditButton = ({ onClick }) => {
	return (
		<button className="btn-media-edit" onClick={onClick}>
			Edit
		</button>
	);
};

export const MediaSelectAllButton = ({ onClick, disabled }) => {
	return (
		<button className="btn-media-edit" onClick={onClick} disabled={disabled}>
			Select All
		</button>
	);
};

export const MediaUnSelectAllButton = ({ onClick, disabled }) => {
	return (
		<button className="btn-media-edit" onClick={onClick} disabled={disabled}>
			Unselect All
		</button>
	);
};

export const MediaCancelButton = ({ onClick }) => {
	return (
		<button className="btn-media-edit" onClick={onClick}>
			Cancel
		</button>
	);
};

export const ModalCancelButton = ({ onClick }) => {
	return (
		<button className="btn btn-modal-cancel" onClick={onClick}>
			Cancel
		</button>
	);
};

export const ModalCreateButton = ({ onClick }) => {
	return (
		<button className="btn btn-modal-create" onClick={onClick}>
			Create
		</button>
	);
};

export const DeleteCarouselButton = ({ onClick }) => {
	return (
		<button className="btn btn-delete-carousel" style={{ marginRight: "5px" }} onClick={onClick}>
			Delete Carousel
		</button>
	);
};

export const MediaDeleteButton = ({ onClick, text, loading, disabled }) => {
	return loading ? (
		<button className="btn-media-edit">
			<Spinner animation={"border"} size={"sm"} />
		</button>
	) : (
		<button className="btn-media-edit" onClick={onClick} disabled={disabled}>
			{text}
		</button>
	);
};

export const MediaEditImages = ({ onClick, text }) => {
	return (
		<button className="btn-media-edit" onClick={onClick}>
			{text}
		</button>
	);
};

export const MediaDeleteImageButton = ({ onClick }) => {
	return (
		<button className="btn-media-delete" onClick={onClick}>
			Delete
		</button>
	);
};
