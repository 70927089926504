import LoadingSpinner from "components/Loader/LoadingSpinner";

// import moment from "moment";
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { useSearchParams } from "hooks/useSearchParams";

import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";
import { formatString, getSecheledReportsTableColumns } from "./secheledReportsTableColumns";
import ScheduledReportsModal from "./ScheduledReportsModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { NoButton, YesButton } from "components/GeneralComponents/AddButton";

import "./ScheduledReports.scss";
import DataTable from "components/GeneralComponents/Table";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";

export const ScheduledReports = () => {
	const { search } = useLocation();
	const { slug } = useSearchParams(search);

	const [isDataLoading, setIsDataLoading] = useState(false);
	const [reportsList, setReportsList] = useState([]);
	const [unfilteredReportsList, setUnfilteredReportsList] = useState([]);
	const [deleteId, setDeleteId] = useState(null);
	const [showScheduledModal, setShowScheduledModal] = useState(null);

	const getReports = () => {
		setReportsList([]);
		setIsDataLoading(false);

		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.get_schdeulted_reports}`, resolve, reject, true);
		})
			.then(response => {
				const { success, data } = response;
				if (success) {
					const newData = data[0].map(row => ({ ...row, report_name: formatString(row?.report_type) }));
					setReportsList(newData);
					setUnfilteredReportsList(newData);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const deleteReport = () => {
		setIsDataLoading(false);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.schdeulted_report + "/" + deleteId, resolve, reject, true);
		})
			.then(response => {
				const { success } = response;

				if (success) {
					let newArrat = [...reportsList];
					newArrat = newArrat.filter(row => row.id !== deleteId);
					setReportsList(newArrat);
				}
			})
			.finally(() => {
				setDeleteId(null);
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		getReports();
	}, []);

	const tableColumns = getSecheledReportsTableColumns({ reportsList, setShowScheduledModal, setDeleteId });

	return slug ? (
		<HelmetLayout titleProps={"Reports"}>
			<div className="cms-page p-0 m">
				<SingleSearchFilter setData={setReportsList} unfilteredData={unfilteredReportsList} detailsColumn={["report_name", "report_type"]} />

				<DataTable
					data={reportsList}
					columns={tableColumns}
					options={{
						showButtons: true,
						search: false,
						viewColumns: false,
						customToolbar: () => <AddTableButton name="Create New Schedule" onClick={() => setShowScheduledModal("add")} />,
						onRowClick: row => setShowScheduledModal(row),
					}}
					isLoading={isDataLoading}
					pagination={false}
					orderRows={true}
					sort={false}
				/>
			</div>

			{showScheduledModal && <ScheduledReportsModal showScheduledModal={showScheduledModal} setShowScheduledModal={setShowScheduledModal} />}

			{deleteId && (
				<SweetAlert title={"Confirm delete"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="">
							<span className="delete-span">Are you sure you want to delete this Scheduled Reports?</span>
						</div>
					</div>
					<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
						<NoButton onClick={() => setDeleteId(null)} name="No" />
						{isDataLoading ? <LoadingSpinner /> : <YesButton name="Yes" onClick={() => deleteReport()} />}
					</div>
				</SweetAlert>
			)}
		</HelmetLayout>
	) : null;
};
