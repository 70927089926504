import { React, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setActivePage } from "./../../store/auth/login/actions";

const HeaderButton = ({ name, link, icon, active }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	useEffect(() => {
		if (window.location.pathname === link) {
			dispatch(setActivePage(link));
		}
	}, []);

	return (
		<div className={active ? "headerMenuStyle mx-3 px-0 " : "headerMenuStyle mx-2 px-0 "}>
			<Link
				onClick={() => {
					dispatch(setActivePage(link));
					history.push(link);
				}}
				type="button"
				className={`
						btn btn-sm px-4 font-size-24 header-page-link header-item waves-effect vertical-menu-btn
						${active ? "active" : ""}
							`}
				id="vertical-menu-btn"
				to={link}
			>
				{icon}
				<span> {name} </span>
			</Link>
		</div>
	);
};

export default HeaderButton;
