import React from "react";
// import { capitalizeText } from "services/capitalizeText";
import { _editResultMarketOptions } from "utils/constants";
import { getFormatDateByUserTimezone } from "utils/global";
import { PercentageIcon, XCloseIcon } from "utils/icons";

// const priceType = [
// 	{ label: "IP", id: "ip" },
// 	{ label: "PM", id: "pm" },
// ];

const rule4Options = [
	{ id: "80", label: "80%" },
	{ id: "75", label: "75%" },
	{ id: "70", label: "70%" },
	{ id: "65", label: "65%" },
	{ id: "60", label: "60%" },
	{ id: "55", label: "55%" },
	{ id: "50", label: "50%" },
	{ id: "45", label: "45%" },
	{ id: "40", label: "40%" },
	{ id: "35", label: "35%" },
	{ id: "30", label: "30%" },
	{ id: "25", label: "25%" },
	{ id: "20", label: "20%" },
	{ id: "15", label: "15%" },
	{ id: "10", label: "10%" },
	{ id: "5", label: "5%" },
	{ id: "0", label: "0%" },
];

if (process.env.REACT_APP_COUNTRY_CODE !== "ZA") {
	rule4Options.unshift({ id: "85", label: "85%" });
	rule4Options.unshift({ id: "90", label: "90%" });
}

export const getFields = (bets, handleCloseTable, bogEWData, allSports) => {
	let headFields = [];
	let bodyFields = [];
	let headFieldsSis = [];
	let bodyFieldsSis = [];

	const user = JSON.parse(localStorage.getItem("user"));

	const isEachWay = bogEWData?.is_each_way === "Yes";

	bets.forEach(element => {
		const odd = user.odds_display == "fractional" ? "odd_fractional" : "odd_decimal";
		const sp_odd = user.odds_display == "fractional" ? "sp_odd_fractional" : "sp_odd_decimal";
		const eachWay = isEachWay ? { fieldName: element.ew_terms, type: "input", field: "ew_terms", bet_id: element.bet_id } : "";

		if (element.sport_slug === "horseracing" || element.sport_slug === "greyhoundracing") {
			const resultType = element.result === "partial" ? "input" : "hidden";

			const newResultType = [..._editResultMarketOptions, { id: "placed", label: "Placed" }];

			const winReductionElement = ["winner", "partial"];
			const disabledWinReduction = winReductionElement.includes(element.result) ? false : true;
			const disabledPlaceReduction = element.result === "placed" ? false : true;
			const placeReduction = isEachWay
				? {
						fieldName: element.place_reduction,
						type: "input",
						field: "place_reduction",
						bet_id: element.bet_id,
						disabled: disabledPlaceReduction,
				  }
				: "";

			if (headFieldsSis.length < 1) {
				headFieldsSis.push({ thead: "" }, { thead: "Result" });
				bets.some(e => e.result === "partial") && headFieldsSis.push({ thead: "Partial Win" });
				headFieldsSis.push(
					// { thead: "Price Type" },
					{ thead: "Price" },
					{ thead: "SP Price" },
					{ thead: "Rule 4" },
					{ thead: "Win Reduction" }
				);
				isEachWay && headFieldsSis.push({ thead: "Place Reduction" });
				isEachWay && headFieldsSis.push({ thead: "E/W Reduction" });
			}
			bodyFieldsSis.push([
				{ fieldName: element.selection_name, type: "text", icon: true, bet_id: element.bet_id },
				{
					type: "icon",
					id: element.bet_id,
					information: [
						{ name: "", competition: <XCloseIcon fill={"#2e3a49"} onClick={() => handleCloseTable()} />, icon: true },
						{ name: "EVENT", competition: element.event_name, isLink: true, element: element },
						{ name: "EVENT START", competition: getFormatDateByUserTimezone(element.event_start_date) },
						{ name: "MARKET", competition: element.market_name },
						{ name: "SELECTION", competition: element.selection_name },
						{
							name: "SPORT",
							competition: allSports?.find(sport => sport?.id == element?.sport_slug)?.label,
						},
					],
				},
				// { fieldName: capitalizeText(element.bet_type), type: "text" },
				{
					fieldName: element.result,
					type: "dropdown",
					options: newResultType,
					field: "result",
					bet_id: element.bet_id,
					defaultValues: [{ field: "partial_win_percent", cases: { partial: "0" } }],
				},
				{
					fieldName: element.partial_win_percent,
					type: resultType,
					field: "partial_win_percent",
					bet_id: element.bet_id,
					validation: { min: 0, max: 100 },
					fieldType: "number",
					icon: <PercentageIcon />,
				},
				// { fieldName: element.price_type, type: "dropdown", options: priceType, field: "price_type", bet_id: element.bet_id },
				{
					fieldName: element[odd],
					type: "dropdown",
					options: element?.ladder,
					field: odd,
					bet_id: element.bet_id,
					disabled: element?.is_starting_price,
				},
				{ fieldName: element[sp_odd], type: "dropdown", options: element?.ladder, field: sp_odd, bet_id: element.bet_id },
				{ fieldName: element.rule_4, type: "dropdown", options: rule4Options, field: "rule_4", bet_id: element.bet_id },
				{
					fieldName: element.win_reduction,
					type: "input",
					field: "win_reduction",
					bet_id: element.bet_id,
					icon: <PercentageIcon />,
					disabled: disabledWinReduction,
				},
				placeReduction,
				eachWay,
			]);
		} else {
			const resultType = element.result === "partial" ? "input" : "hidden";
			const eachWay = isEachWay ? { fieldName: element.ew_terms, type: "input", field: "ew_terms", bet_id: element.bet_id, disabled: true } : "";

			if (headFields.length < 1) {
				headFields.push(
					{ thead: "Event" },
					{ thead: "Market" },
					{ thead: "Selection" },
					// { thead: "Bet Type" },
					{ thead: "Result" }
				);
				bets.some(e => e.result === "partial") && headFields.push({ thead: "Partial Win" });
				headFields.push({ thead: "Price" }, { thead: "Win Reduction" });
				isEachWay && headFields.push({ thead: "E/W Reduction" });
			}

			bodyFields.push([
				{ fieldName: element.event_name, type: "text", icon: true, bet_id: element.bet_id },
				{
					type: "icon",
					id: element.bet_id,
					information: [
						{ name: "", competition: <XCloseIcon fill={"#2e3a49"} onClick={() => handleCloseTable()} />, icon: true },
						{ name: "COMPETITION", competition: element.competition_name },
						{ name: "EVENT NAME", competition: element.event_name, isLink: true, element: element },
						{ name: "EVENT DATE", competition: getFormatDateByUserTimezone(element.event_start_date, "YYYY-MM-DD") },
						{ name: "EVENT START", competition: getFormatDateByUserTimezone(element.event_start_date, "HH:mm") },
						{ name: "MARKET", competition: element.market_name },
						{
							name: "SPORT",
							competition: allSports?.find(sport => sport?.id == element?.sport_slug)?.label,
						},
					],
				},
				{ fieldName: element.market_name, type: "text" },
				{ fieldName: element.selection_name, type: "text" },
				// { fieldName: capitalizeText(element.bet_type), type: "text", subTitle: element?.ew_terms },
				{
					fieldName: element.result,
					type: "dropdown",
					options: _editResultMarketOptions,
					field: "result",
					bet_id: element.bet_id,
					defaultValues: [{ field: "partial_win_percent", cases: { partial: "0" } }],
				},
				{
					fieldName: element.partial_win_percent,
					type: resultType,
					field: "partial_win_percent",
					bet_id: element.bet_id,
					validation: { min: 0, max: 100 },
					fieldType: "number",
					icon: <PercentageIcon />,
				},

				{ fieldName: element[odd], type: "dropdown", options: element?.ladder, field: odd, bet_id: element.bet_id },
				{ fieldName: element.win_reduction, type: "input", field: "win_reduction", bet_id: element.bet_id, icon: <PercentageIcon /> },
				eachWay,
			]);
		}
	});
	return { thead: headFields, tbody: bodyFields, theadSis: headFieldsSis, tbodySis: bodyFieldsSis };

	// 19832
};
