import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { setBreadcrumbItems } from "../../../store/actions";
import { Card, CardBody } from "reactstrap";
import { Spinner } from "react-bootstrap";
import { getErrorLogsTableColumns } from "./errorLogsTableColumns";
import { ShohEditErrorLogsModal } from "./showEditErrorLogsModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import MUIDataTable from "mui-datatables";
import Flatpickr from "react-flatpickr";
import { platformName } from "utils/constants";

const ErrorLogs = props => {
	const [filterSpinning, setFilterSpinning] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [acticityLogParams, setActivityLogParams] = useState({ date: "" });
	const [showEditModal, setShowEditModal] = useState(false);
	const [spinningStatusFixed, setSpinningStatusFixed] = useState(false);
	const [rowPerPage, setRowPerPage] = useState(50);
	const breadcrumbItems = [{ title: "Error Logs", link: "/error_log" }];

	useEffect(() => {
		props.setBreadcrumbItems("Error Logs", breadcrumbItems);
	}, []);

	// Array for data that come from query, this one hold all the data that will be render,
	let records = [];

	// Func that will fix error logs
	const handleErrorFix = () => {
		setSpinningStatusFixed(true);
	};

	// Filter errors by date func
	const filterErrorLogs = () => {
		setFilterSpinning(true);
	};

	let errorLogsTableColumns = getErrorLogsTableColumns({ setShowEditModal });
	let page_name = "CMS Users Activity";

	return (
		<React.Fragment>
			<MetaTags>
				<title>
					{page_name} - {platformName}
				</title>
			</MetaTags>
			<Card>
				<ShohEditErrorLogsModal
					showEditModal={showEditModal}
					setShowEditModal={setShowEditModal}
					handleErrorFix={handleErrorFix}
					spinningStatusFixed={spinningStatusFixed}
				/>
				<div className="row m-0 mb-5 mt-2">
					<div className="col-12 col-lg-4 col-xl-4">
						<div className="input-group">
							<div className="input-group-prepend" style={{ borderRadius: "5px 0 0 5px" }}>
								<span className="input-group-text" style={{ borderRadius: "5px 0 0 5px" }}>
									Date from - to
								</span>
							</div>
							<Flatpickr
								className="form-control d-block"
								placeholder="Date from - to"
								options={{
									mode: "range",
									dateFormat: "d-m-Y",
									defaultDate: ["today"],
									maxDate: "today",
									locale: {
										firstDayOfWeek: 1, // start week on Monday
									},
								}}
								onChange={(date, dateFilter) => {
									setActivityLogParams({ ...acticityLogParams, date: dateFilter });
								}}
							/>
						</div>
					</div>
					<div className="col-12 col-lg-8 col-xl-8 d-flex justify-content-end">
						{filterSpinning ? (
							<Spinner animation="border" size="sm"></Spinner>
						) : (
							<button className="btn btn-primary btn_filter_competitors" onClick={filterErrorLogs}>
								Filter
							</button>
						)}
					</div>
				</div>
				<CardBody style={{ overflowX: "scroll" }}>
					<MUIDataTable
						columns={errorLogsTableColumns}
						data={records}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							elevation: 0,
							onChangePage(page) {
								setCurrentPage(page);
							},
							onChangeRowsPerPage(number) {
								setRowPerPage(number);
							},
							textLabels: {
								body: {
									noMatch: " No data to display!",
								},
							},
							page: currentPage,
							responsive: tableResponsive,
							rowsPerPage: rowPerPage,
							rowsPerPageOptions: tableRowsPerPage,
							downloadOptions: { filename: `${page_name}.csv` },
						}}
						extraButtons={tableExtraButtons}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};
export default connect(null, { setBreadcrumbItems })(ErrorLogs);
