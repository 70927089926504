import { AvForm } from "availity-reactstrap-validation";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "reactstrap";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { languageToString } from "utils/global";
import { XCloseIcon } from "utils/icons";
import xIcon from "../../../assets/images/x-icon.svg";
import { toast } from "react-toastify";

const NotificationBarModal = ({ getNotificationData, editItem, title, handler, dataChanged, setDataChanged }) => {
	const [notificationFields, setNotificationBarFields] = useState([]);
	const [loading, setLoading] = useState(false);
	const [notificationBarData, setNotificationBarData] = useState({
		id: "",
		message: "",
		url: "",
		cta: "",
		start_date: "",
		end_date: "",
		active: "",
		never_expire: 0,
	});
	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};

	const getEditdata = () => {
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.notification_bar + `/${editItem?.id}`, resolve, reject, true);
		})
			.then(response => {
				const data = response?.data;
				let language;
				if (data?.language) {
					language = JSON.parse(data?.language);
				}
				setNotificationBarData({ ...data, ...language });
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (editItem?.id) {
			getEditdata();
		}
	}, []);

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const handleAddAndEdit = () => {
		if (!notificationBarData?.message) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter message</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		setLoading(true);
		let payload = {
			id: notificationBarData.id || "",
			active: notificationBarData.active,
			never_expire: notificationBarData.never_expire,
			cta: notificationBarData.cta,
			message: notificationBarData.message,
			url: notificationBarData.url,
			start_date: notificationBarData.start_date,
			end_date: notificationBarData.end_date,
		};

		let language = languageToString(notificationBarData, ["message_", "cta_"]);
		payload.language = `{${language}}`;
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.add_edit_notification_bar, resolve, reject, payload, true);
		})
			.then(() => {
				getNotificationData();
				handler();
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		let fields = [];
		fields.push(
			{
				type: "switch",
				name: "Published",
				fieldName: "active",
			},
			{
				type: "textarea",
				name: "Message",
				fieldName: "message",
				required: true,
				onFocus: () => handleShowOptions("language", "message"),
				onBlur: () => (onFocus.current = false),
			},
			{
				type: "input",
				name: "URL Address",
				fieldName: "url",
			},
			{
				type: "input",
				name: "CTA",
				fieldName: "cta",
				onFocus: () => handleShowOptions("language", "cta"),
				onBlur: () => (onFocus.current = false),
			},

			{
				type: "date",
				name: "Start Date",
				fieldName: "start_date",
			}
		);
		if (notificationBarData?.never_expire == "0") {
			fields.push({
				type: "date",
				name: "End Date",
				fieldName: "end_date",
			});
		}
		fields.push({
			type: "switch",
			name: "Never Expire",
			fieldName: "never_expire",
		});

		setNotificationBarFields(fields);
	}, [notificationBarData]);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={handleAddAndEdit} dataChanged={dataChanged} loading={loading} onClose={handler} />

				{loading ? (
					<div className="text-center">
						<Spinner size={"sm"} />
					</div>
				) : (
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
							<AvForm id="bannerForm" onClick={() => !onFocus.current && handleShowOptions()}>
								<AllComponentsLoop
									fields={notificationFields}
									data={notificationBarData}
									setData={setNotificationBarData}
									setDataChanged={setDataChanged}
								/>
							</AvForm>
						</div>
						<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
							{showOptions?.language && (
								<LanguageTitle
									data={notificationBarData}
									setData={setNotificationBarData}
									title={showOptions.language}
									handleDataChanged={handleDataChanged}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default NotificationBarModal;
