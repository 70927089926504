import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { BlueCancelButton, SaveButton } from "components/GeneralComponents/AddButton";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

import "./matches.scss";

const AddMarketsModal = ({ onClose, sport_slug, match_id, getNewMarketsData }) => {
	const [markets, setMarkets] = useState([]);
	const [selectedMarket, setSelectedMarket] = useState(null);
	const [isAdding, setIsAdding] = useState(false);

	useEffect(() => {
		getManualMarkets();
	}, []);

	const getManualMarkets = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_manual_markets + `?sport_slug=${sport_slug}`, resolve, reject, true);
		}).then(response => {
			console.log(response);
			const formatedData = response?.data.map(({ market_id, market_name }) => ({ id: market_id, label: market_name }));
			setMarkets(formatedData);
		});
	};

	const saveManualMarket = () => {
		setIsAdding(true);
		const payload = {
			event_id: match_id,
			market_id: selectedMarket,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.add_manual_market, resolve, reject, payload, true);
		})
			.then(() => {
				onClose();
				getNewMarketsData();
			})
			.finally(() => {
				setIsAdding(false);
			});
	};

	const handleChangeDropdown = value => {
		setSelectedMarket(value?.id);
	};

	return (
		<SweetAlert title="Add New Market" showConfirm={false} onConfirm={() => {}} customClass="add-market-modal">
			<div style={{ position: "relative" }}>
				<div className="d-flex justify-content-center mt-2">
					<GeneralDropdown
						object={{
							defaultPlaceholder: "Search Market",
							options: markets,
							required: true,
							normalWidth: true,
							value: selectedMarket,
							wrapperClassNameDropdown: "w-100",
							handleChangeDropdown: newValue => handleChangeDropdown(newValue),
						}}
					/>
				</div>
				<div className="mt-4 d-flex align-items-center justify-content-center">
					<BlueCancelButton onClick={onClose} name="Cancel" />
					<SaveButton onClick={saveManualMarket} name="Add" classname={"general_button_white"} loading={isAdding} />
				</div>
			</div>
		</SweetAlert>
	);
};

export default AddMarketsModal;
