import React, { useRef, useEffect, useState } from "react";
import { XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { providerTypes } from "utils/constants";
import { SelectionInput } from "components/GeneralComponents/CustomInputs";
import { languageToString } from "utils/global";
import { Spinner } from "reactstrap";

export const AddMarketsModal = ({ filterMarkets, marketItem, setMarketItem }) => {
	const isEdit = typeof marketItem === "object";
	const isCustomMarket = isEdit ? (marketItem.provider_prefix === providerTypes.MANUAL ? false : true) : false;
	let details = null;
	if (marketItem.details) {
		details = JSON.parse(marketItem?.details);
	}

	const [showTitleLanguage, setShowTitleLanguage] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const onFocus = useRef(false);
	const [loadingSaveButton, setLoadingSaveButton] = useState(false);
	const [loadingMarketHandicaps, setLoadingMarketHandicaps] = useState(false);
	const [marketTypeData, setMarketTypeData] = useState({
		sport_slug: marketItem.sport_slug || "",
		market_id: marketItem.market_id || "",
		market_name: marketItem.market_name || "",
		prematch: marketItem.prematch || 0,
		inplay: marketItem.inplay || 0,
		index: marketItem.index || 0,
		handicap: marketItem.handicap || 0,
		handicap_limit: marketItem.handicap_limit || "",
		active: marketItem.active || 0,
		market_sort: marketItem.market_sort || "",
		show_columns: marketItem.show_columns || "",
		ignore_time_interval: marketItem?.ignore_time_interval || 0,
		is_each_way: marketItem?.is_each_way || 0,
		...details,
	});

	const [fields, setFields] = useState([]);
	const [marketHandicaps, setMarketHandicaps] = useState([]);

	const handleShowOptions = type => {
		setShowTitleLanguage(false);
		onFocus.current = true;

		if (type === "market_name") {
			setShowTitleLanguage(true);
		}
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const getMarketHandicaps = () => {
		setLoadingMarketHandicaps(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_MARKET_HANDICAPS + marketTypeData?.market_id, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					const enabled_handicaps = marketItem.enabled_handicaps;
					const data = response.data.map(item => ({
						name: item,
						active: enabled_handicaps === null || enabled_handicaps?.split(",")?.includes(item) ? 1 : 0,
					}));

					setMarketHandicaps(data);
				}
			})
			.finally(() => setLoadingMarketHandicaps(false));
	};

	useEffect(() => {
		if (isEdit && marketTypeData?.handicap == 1) getMarketHandicaps();
	}, [marketTypeData?.market_id, marketTypeData?.handicap]);

	const handleCheckBox = (row, index) => {
		setDataChanged(true);

		let updatedArray;
		if (row?.active === 1) {
			updatedArray = marketHandicaps.map((item, i) => (i == index ? { ...item, active: 0 } : item));
		} else {
			updatedArray = marketHandicaps.map((item, i) => (i == index ? { ...item, active: 1 } : item));
		}
		setMarketHandicaps(updatedArray);
	};

	const handleSaveMarket = () => {
		setLoadingSaveButton(true);

		let language = languageToString(marketTypeData, ["market_name_"]);

		// Object.entries(marketTypeData).forEach(([key, value]) => {
		// 	if (key.includes("market_name_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });
		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		const show_columns = marketTypeData?.show_columns != "" ? parseFloat(marketTypeData?.show_columns) : "";

		let payload = isEdit
			? {
					...marketTypeData,
					show_columns,
					details: `{${language}}`,
					id: marketItem.id,
					enabled_handicaps: marketHandicaps.filter(item => item.active == 1).map(item => item.name),
			  }
			: { ...marketTypeData, show_columns, details: `{${language}}` };

		new Promise((resolve, reject) => {
			if (isEdit) ApiServices.put(apiUrls.SAVE_MARKET_EDIT, resolve, reject, payload, true);
			else ApiServices.post(apiUrls.SAVE_MARKET_ADD, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) {
					filterMarkets();
				}
			})
			.finally(() => {
				setMarketItem(false);
				setLoadingSaveButton(false);
			});
	};

	const MarketIDField = isEdit ? { type: "input", name: "Market Id", fieldName: "market_id", disabled: true } : { type: "empty_row" };

	useEffect(() => {
		const tempFileds = [
			{ type: "dropdown", name: "Sports", fieldName: "sport_slug", optionsType: "allSports", disabled: isCustomMarket, required: true },
			MarketIDField,
			{
				type: "input",
				name: "Market Name",
				fieldName: "market_name",
				required: true,
				onFocus: () => handleShowOptions("market_name"),
				onBlur: () => (onFocus.current = false),
			},
		];

		// if (!isEdit) {
		tempFileds.push(
			{ type: "switch", name: "Prematch", fieldName: "prematch" },
			{ type: "switch", name: "Inplay", fieldName: "inplay" },
			{ type: "switch", name: "Ignore Time Interval", fieldName: "ignore_time_interval" },
			{ type: "switch", name: "Each Way", fieldName: "is_each_way" },
			{ type: "switch", name: "Status", fieldName: "active" },
			{
				type: "dropdown",
				name: "Market Sort By",
				fieldName: "market_sort",
				optionsType: "market_sort_by",
			},
			{ type: "dropdown", name: "Show Columns", fieldName: "show_columns", numbersOnly: true, optionsType: "show_handicap_columns" },
			{ type: "input", name: "Handicap Limit", fieldName: "handicap_limit" }
		);

		setFields(tempFileds);
	}, [marketTypeData?.handicap]);

	return (
		<>
			<div className="promotion-container">
				<div className="promotion-header">
					<span>Market Type</span>
					<XCloseIcon onClick={() => setMarketItem(false)} className={"xclose-icon"} />
				</div>
				<div className="promotion-body">
					<TopBarComponent
						saveHandler={() => handleSaveMarket()}
						dataChanged={dataChanged}
						loading={loadingSaveButton}
						onClose={() => setMarketItem(false)}
					/>
					<div className="promotion-elements row m-0">
						<div
							className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left"
							onClick={() => !onFocus.current && handleShowOptions()}
						>
							<AllComponentsLoop fields={fields} data={marketTypeData} setData={setMarketTypeData} setDataChanged={setDataChanged} />
							{marketTypeData?.handicap === 0 ? (
								""
							) : loadingMarketHandicaps ? (
								<div className="d-flex mt-3">
									<span className="input-title">Handicaps</span> <Spinner animation="border" size="lg" />
								</div>
							) : (
								<SelectionInput
									object={{
										field: "name",
										fieldId: "name",
										data: marketHandicaps,
										otherRowClass: true,
										id: "name",
										name: "Handicaps",
										checkBoxOnChange: (row, index) => handleCheckBox(row, index),
										disabledOption: false,
										hideEdit: true,
										gridLayout: true,
									}}
								/>
							)}
						</div>
						<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
							{showTitleLanguage && (
								<LanguageTitle data={marketTypeData} setData={setMarketTypeData} title={"market_name"} handleDataChanged={handleDataChanged} />
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
