import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "../../../assets/images/delete.svg";
import { apiUrls } from "utils/const.apiUrl";

function insertAtIndex(str, substring, index) {
	return str.slice(0, index) + substring + str.slice(index);
}

export const getPromoLinkTableColumns = ({ promoLinksData, handleEditItem, setDeleteItem }) => {
	return [
		{
			name: "name",
			label: "NAME",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = promoLinksData[meta.currentTableData[meta.rowIndex].index];
					return <p className="hover_link">{row.name}</p>;
				},
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" } }),
			},
		},
		{
			name: "slug",
			label: "LINK",
			className: "slug",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = promoLinksData[meta.currentTableData[meta.rowIndex].index];
					let url = "";
					if (row?.slug) {
						if (row.slug?.startsWith("http")) {
							url = row.slug?.includes("/en/promo") ? row.slug : insertAtIndex(row.slug, "/en", row.slug.indexOf("/promo"));
						} else url = `${apiUrls.client_side_domain}/en/promo/${row.slug}`;
					}

					return url ? (
						<a href={url} className="hover_link" target="_blank" rel="noreferrer">
							{url}
						</a>
					) : (
						<></>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "450px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "450px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = promoLinksData[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => handleEditItem(row)} />
								<DeleteIconButton
									deleteIcon={deleteIcon}
									onClick={() => {
										setDeleteItem(row);
									}}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
