import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "../../../assets/images/delete.svg";

export const getSubAgentsTableColumn = ({ subAgents, handleEditSubAgentsItem, setDeleteItem }) => {
	return [
		{
			name: "id",
			label: "ID",
			className: "affiliate_users_row",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customHeadRender: columnMeta => <th className="custom-header-padding"> {columnMeta.label} </th>,
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "40px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "40px" } }),
			},
		},
		{
			name: "name",
			label: "NAME",
			className: "affiliate_users_row",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customHeadRender: columnMeta => <th className="custom-header-padding"> {columnMeta.label} </th>,
				customBodyRender: (value, meta) => {
					const row = subAgents ? subAgents[meta.currentTableData[meta.rowIndex].index] : [];
					return <span className="affiliate_users_row">{`${row?.first_name} ${row?.last_name}`}</span>;
				},
				setCellProps: () => ({ style: { minWidth: "70px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "70px" } }),
			},
		},
		{
			name: "revenue_share",
			label: "REV SHARE",
			className: "affiliate_users_row",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customHeadRender: columnMeta => <th className="custom-header-padding"> {columnMeta.label} </th>,
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = subAgents ? subAgents[meta.currentTableData[meta.rowIndex].index] : [];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => handleEditSubAgentsItem(row)} />
								<DeleteIconButton deleteIcon={deleteIcon} onClick={() => setDeleteItem(row)} />
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
