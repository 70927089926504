import React from "react";
import { CloneButton, DeleteIconButton, EditButton } from "components/GeneralComponents/AddButton";
import { Spinner } from "react-bootstrap";
import deleteIcon from "../../../assets/images/delete.svg";

export const getDefaultSportsTableColumns = ({
	orderData,
	editId,
	getEditSportData,
	setEditId,
	setShowCloneSport,
	setCloneId,
	setShowDeleteConfirm,
	setDeleteId,
	editSpinning,
	isUserReadOnly,
}) => {
	return [
		{
			name: "country_name",
			label: "COUNTRY",
			className: "country",
			align: "left",
			options: {
				setCellProps: () => ({ style: { minWidth: "120px" } }),
			},
		},
		{
			name: "sport_name",
			label: "SPORT",
			className: "default_league_sport_type",
			align: "left",
			options: {
				setCellProps: () => ({ style: { minWidth: "500px", width: "100%" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				viewColumns: false,
				filter: false,
				setCellProps: () => ({ style: { minWidth: "150px", textAlign: "center" } }),
				customBodyRender: (value, meta) => {
					const record = orderData[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								{!isUserReadOnly && (
									<CloneButton
										onClick={() => {
											setShowCloneSport(true);
											setCloneId(record.id);
										}}
									/>
								)}

								{editId == record.id && editSpinning ? (
									<EditButton>
										<Spinner animation="border" size="sm"></Spinner>
									</EditButton>
								) : (
									<EditButton
										onClick={() => {
											getEditSportData(record.id);
											setEditId(record.id);
										}}
									/>
								)}
								{isUserReadOnly ? (
									""
								) : record.country_code != "all" ? (
									<DeleteIconButton
										deleteIcon={deleteIcon}
										onClick={() => {
											setShowDeleteConfirm(true);
											setDeleteId(record.id);
										}}
									/>
								) : (
									<DeleteIconButton deleteIcon={deleteIcon} isDisabled={true} />
								)}
							</div>
						</div>
					);
				},
			},
		},
	];
};
