import { getAllSportsDropdown } from "services/getAllSportsDropdown";
import { _reportResultBetsOptions, accountStatusOptions, bankingTransactions, eventPhaseBetsAllowed, rightsHolder } from "./constants";

const casinoEnabled = process.env.REACT_APP_MODULE_ENABLE_CASINO === "1" ? 1 : 0;
const sportEnabled = process.env.REACT_APP_MODULE_ENABLE_SPORT === "1" ? 1 : 0;
const racingEnabled = process.env.REACT_APP_MODULE_ENABLE_RACING === "1" ? 1 : 0;

export const reports = [
	{
		id: "0",
		name: "Responsible Gambling",
		link: "/safer-gambling",
		period: false,
		limitRange: null,
		default: null,
		slug: "safer-gambling",
		description: "Provides a detailed report of all users and their current safer gambling settings, including limits, reality checks and self-exclusions.",
	},
	{
		id: "1",
		name: "Duplicate Users",
		link: "/duplicate-users",
		period: false,
		limitRange: null,
		default: null,
		slug: "duplicate-users",
		description: "Identifies users with multiple accounts, helping to prevent fraudulent activity or policy violations by analysing persons data.",
	},
	...(sportEnabled || racingEnabled
		? [
				{
					id: "2",
					name: "Betting Transactions",
					link: "/unclosed-bets",
					period: true,
					limitRange: 31,
					default: null,
					// periodOptional: true,
					slug: "unclosed-bets",
					description: "Provides a detailed report of all betting-related transactions, including placed bets and results.",
					dropdown: [
						{
							id: "bet_result",
							options: () => _reportResultBetsOptions,
							label: "Bet Result",
							placeholder: "Bet Result",
							multiple: true,
						},
					],
				},
		  ]
		: []),
	{
		id: "3",
		name: "Free Bets by User",
		link: "free-bets-report",
		period: true,
		limitRange: 31,
		default: null,
		slug: "free-bets-report",
		inputText: "Player",
		description: "Details the free bets provided and utilised by users, showing bet amounts, winnings, and free bet sources.",
	},
	{
		id: "4",
		name: "Self Excluded",
		link: "/suspended-accounts",
		period: false,
		limitRange: null,
		default: null,
		slug: "suspended-accounts",
		description: "Lists users who have self-excluded from the platform, showing details of their exclusion duration and history.",
	},
	{
		id: "5",
		name: "Self Excluded Log",
		link: "/suspended-accounts-log",
		period: true,
		limitRange: 31,
		default: null,
		slug: "suspended-accounts-log",
		description: "Provides a complete log of all users who have self-excluded, including the date, duration, and any interventions made by the platform.",
	},
	{
		id: "6",
		name: "Transactions",
		link: "/financial-report",
		period: true,
		// limitRange: 31,
		default: "previous day",
		slug: "financial-report",
		inputText: "Player",
		description:
			"Displays a history of all financial transactions made by users, including deposits, withdrawals, and refunds, with timestamps and transaction types.",
	},
	// {
	// 	id: "7",
	// 	name: "Transactions by User",
	// 	link: "/transaction-report-user",
	// 	period: true,
	// 	limitRange: 31,
	// 	default: "previous day",
	// 	slug: "transactions-report-by-user",
	// 	inputText: "Player",
	// },

	{
		id: "8",
		name: "Session Net",
		link: "/net-session",
		// period: true,
		limitRange: 31,
		default: "previous day",
		slug: "net-session",
		description: "Tracks the net result of all user sessions, showing total amounts wagered, won, and lost for the lifetime of that user. ",
	},
	{
		id: "9",
		name: "Banking Transactions",
		link: "/deposit-money",
		period: true,
		// limitRange: 31,
		default: "previous day",
		slug: "deposit-money",
		periodOptional: true,
		description: "Provides a detailed history of all financial transactions performed by users, including deposits, withdrawals, and balances.",
		dropdown: [
			{
				id: "transaction_types",
				options: () => bankingTransactions,
				label: "Transaction Types",
				multiple: true,
				placeholder: "Transaction Types",
			},
		],
	},
	...(casinoEnabled
		? [
				{
					id: "10",
					name: "Turnover By Game",
					link: "/turnover-by-game",
					period: true,
					limitRange: 31,
					default: "null",
					slug: "turnover-by-game",
					description: "Tracks the total turnover generated by individual games, showing which games are driving the most user activity and revenue.",
				},
		  ]
		: []),
	...(sportEnabled || racingEnabled
		? [
				{
					id: "11",
					name: "Turnover By Sports",
					link: "/turnover-by-sports",
					period: true,
					limitRange: 31,
					default: "previous day",
					slug: "turnover-by-sports",
					description:
						"Breaks down turnover by sport, offering insights into which sports attract the most betting volume and generate the highest revenue.",
				},
		  ]
		: []),
	{
		id: "12",
		name: "Deposit Limit Logs",
		link: "/deposit-limits-activity-log",
		period: true,
		limitRange: 31,
		default: "previous week",
		slug: "deposit-limits-activity-log",
		description: "Tracks changes in user-imposed deposit limits, recording adjustments and active limits.",
	},
	// {
	// 	id: "13",
	// 	name: "Self Excluded",
	// 	link: "/self-exclusion-report",
	// 	period: true,
	// 	limitRange: 31,
	// 	default: "previous week",
	// 	slug: "self-exclusion-report",
	// },
	{
		id: "14",
		name: "Self Excluded - Reopen",
		link: "/self-exclusion-reopening",
		period: true,
		limitRange: 31,
		default: "previous week",
		slug: "self-exclusion-reopening",
		roleToHide: ["finance"],
		description: "Tracks users who have reopened their accounts after a self-exclusion period, detailing the conditions under which they returned.",
	},
	{
		id: "15",
		name: "Registered Users",
		link: "/list-registered-users",
		period: true,
		limitRange: 31,
		default: null,
		slug: "list-registered-users",
		description: "Displays a list of users who have registered within the selected time period, including registration dates and account status.",
	},

	{
		id: "16",
		name: "Reality Check Log",
		link: "/reality-check-log",
		period: true,
		limitRange: 90,
		default: null,
		slug: "reality-check-log",
		description:
			"Monitors users who have triggered the platform's reality check feature, showing when and how often users are prompted to manage their gaming habits.",
	},
	{
		id: "17",
		name: "User Balances",
		link: "/user-balance",
		period: true,
		singleDate: true,
		periodOptional: true,
		limitRange: null,
		default: null,
		slug: "user-balance",
		description: "Displays current balances of all users, including available balance, bonus funds, and any pending withdrawals.",
	},
	...(sportEnabled || racingEnabled
		? [
				{
					id: "18",
					name: "Betting Summary by User",
					link: "/betting-summary-by-user",
					period: true,
					singleDate: true,
					periodOptional: true,
					limitRange: 30,
					default: null,
					slug: "betting-summary-by-user",
					description:
						"Summarizes each user’s betting activity, including total bets placed, won, and lost, providing an overview of their overall betting patterns.",
				},
		  ]
		: []),
	{
		id: "19",
		name: "Daily Summary",
		link: "/account-summary",
		period: true,
		limitRange: 31,
		default: "previous day",
		slug: "accounting-summary-by-day",
		description: "Summarizes daily platform activity, showing key metrics like total bets, revenue, active users, and top games or sports.",
	},
	{
		id: "20",
		name: "Users",
		link: "/total-number-active-users",
		// period: true,
		limitRange: 31,
		default: null,
		slug: "total-number-active-users",
		description: "Lists all users, offering detailed insights into their registration, kyc status, activity status, and engagement levels.",
		dropdown: [
			{
				id: "account_status",
				options: () => accountStatusOptions,
				label: "Account Status",
				multiple: true,
				placeholder: "Account Status",
			},
		],
	},
	{
		id: "21",
		name: "Affiliate Users",
		link: "/affiliate-user",
		period: true,
		limitRange: 31,
		default: null,
		slug: "affiliate-user",
		description:
			"Provides insights into players referred through affiliate links, including a breakdown of their betting activity (sports and casino) and the value of free bets utilized.",
	},
	...(sportEnabled || racingEnabled
		? [
				{
					id: "22",
					name: "Multiple Bets",
					link: "/multiple-bets-list",
					period: true,
					limitRange: 31,
					default: null,
					slug: "multiple-bets-list",
					description: "Lists bets that were manually placed or adjusted by administrators, including reasons for manual intervention and outcomes.",
				},
				{
					id: "23",
					name: "Event Placed Bets",
					link: "/event-placed-bets-report",
					period: true,
					limitRange: 31,
					default: null,
					slug: "event-placed-bets-report",
					inputText: "Event",
					description: "Lists all bets placed on a specific event, including details about the type of bets, user activity, and outcomes.",
				},
		  ]
		: []),
	{
		id: "24",
		name: "Free Bets",
		link: "/free-bets",
		period: true,
		limitRange: 31,
		default: null,
		slug: "free-bets",
		description:
			"Provides an overview of free bets issued by the platform, including the total value, usage rates, and the performance of free bet campaigns.",
		// inputText: "Event",
	},
	...(sportEnabled || racingEnabled
		? [
				{
					id: "25",
					name: "Over Average Bets",
					link: "/over-average-stake-bets",
					period: true,
					limitRange: 31,
					default: null,
					slug: "over-average-stake-bets",
					description: "Identifies bets that exceed the average betting amounts, helping to spot high-stakes bettors or potential risky behavior.",
				},
				{
					id: "26",
					name: "Account Stake Variance Report",
					link: "/account-stake-variance-report",
					period: true,
					limitRange: 31,
					default: null,
					slug: "account-stake-variance-report",
					description: "Identifies bets that exceed the average betting amounts, helping to spot high-stakes bettors or potential risky behavior. (SANS)",
				},
				{
					id: "27",
					name: "Cancelled Bets",
					link: "/cancelled-bets",
					period: true,
					limitRange: 31,
					default: null,
					slug: "cancelled-bets",
					description: "Displays a list of cancelled bets.",
				},
		  ]
		: []),
	{
		id: "28",
		name: "Customer Records",
		link: "/customer-records",
		period: true,
		limitRange: 31,
		default: null,
		slug: "customer-records",
		description: "Displays a complete record of customer interactions, including bets placed, results and the customer information. ",
	},
	{
		id: "29",
		name: "Manual Bets",
		link: "/manual-bets-by-user",
		period: true,
		limitRange: 31,
		default: null,
		slug: "manual-bets-by-user",
		inputText: "User",
		description: "Details multiple bet slips or accumulators placed by users, tracking outcomes and payouts of these multi-wagers.",
	},
	{
		id: "30",
		name: "Registration Report",
		link: "/registration-report",
		period: true,
		// limitRange: 31,
		default: null,
		slug: "registration-report",
		singleDate: true,
		description: "Provides the new user affiliate registrations for Income Access, highlighting user demographics and engagement rates post-registration.",
	},
	{
		id: "31",
		name: "Sales Report",
		link: "/sales-report",
		period: true,
		// limitRange: 31,
		default: null,
		slug: "sales-report",
		singleDate: true,
		description: "Provides affiliate sales information, tracking revenue from various streams such as deposits, placed bets and results.",
	},
	// {
	// 	id: "32",
	// 	name: "Scheduled Reports",
	// 	link: "/scheduled-reports",
	// 	default: null,
	// 	slug: "scheduled-reports",
	// },
	{
		id: "33",
		name: "Client Balance Movement",
		link: "/client-balance-movement",
		period: true,
		// singleDate: true,
		// limitRange: 31,
		default: null,
		slug: "client-balance-movement",
		description: "Tracks any movement in client balances, including deposits, withdrawals, bonuses, and balance transfers within a specific time frame.",
	},
	// {
	// 	id: "33",
	// 	name: "Last Reports Generated",
	// 	link: "/last-reports-generated",
	// 	default: null,
	// 	slug: "last-reports-generated",
	// },

	{
		id: "34",
		name: "Account Customer Strike Rate",
		link: "/account-customer-strike-rate",
		period: true,
		default: null,
		slug: "account-customer-strike-rate",
		description: "This report details the profit and loss of the account represented as a percentage",
	},
	{
		id: "35",
		name: "FICA Report",
		link: "/fica-report",
		period: true,
		// singleDate: true,
		// limitRange: 31,
		customInput: "amount_from",
		default: null,
		slug: "fica-report",
		description: "This report monitors and documents customer transactions that exceed a specific threshold.",
	},
	...(sportEnabled
		? [
				{
					id: "36",
					name: "Tax Returns Sports",
					link: "/tax-returns-sans-sports",
					period: true,
					// singleDate: true,
					// limitRange: 31,
					default: null,
					slug: "tax-returns-sans-sports",
					description: "This report provides an overview of tax returns specific to South African accounts.",
				},
		  ]
		: []),
	...(racingEnabled
		? [
				{
					id: "37",
					name: "Tax Returns Horse Racing",
					link: "/tax-returns-horse-racing",
					period: true,
					// singleDate: true,
					// limitRange: 31,
					default: null,
					slug: "tax-returns-sans-horse-racing",
					description: "This report provides an overview of tax returns specific to South African accounts.",
				},
				{
					id: "47",
					name: "Tax Returns Horse Racing V2",
					link: "/tax-returns-horse-racing-v2",
					period: true,
					// singleDate: true,
					// limitRange: 31,
					default: null,
					slug: "tax-returns-sans-horse-racing-v2",
					description: "This report provides an overview of tax returns specific to South African accounts.",
				},
		  ]
		: []),
	...(casinoEnabled
		? [
				{
					id: "38",
					name: "Tax Returns Casino",
					link: "/tax-returns-sans-casino",
					period: true,
					// singleDate: true,
					// limitRange: 31,
					default: null,
					slug: "tax-returns-sans-casino",
					description: "This report provides an overview of tax returns specific to South African accounts.",
				},
		  ]
		: []),

	{
		id: "39",
		name: "Bonus Engine",
		link: "/bonus-engine-report",
		period: true,
		limitRange: 31,
		default: null,
		inputText: "Player",
		customInput: "bonus_engine_id",
		slug: "bonus-engine-report",
		description: "This report details bonuses issued to players, covering types, frequency, and player engagement.",
	},
	...(sportEnabled || racingEnabled
		? [
				{
					id: "40",
					name: "Betting Transactions",
					link: "/unclosed-bets-sans",
					period: true,
					limitRange: 31,
					default: null,
					// periodOptional: true,
					slug: "unclosed-bets-sans",
					description: "Provides a detailed report of all betting-related transactions, including placed bets and results. (SA)",
					dropdown: [
						{
							id: "bet_result",
							options: () => _reportResultBetsOptions,
							label: "Bet Result",
							placeholder: "Bet Result",
							multiple: true,
						},
					],
				},
		  ]
		: []),
	...(sportEnabled || racingEnabled
		? [
				{
					id: "41",
					name: "Bets by Sport",
					link: "/sports-providers",
					period: true,
					limitRange: 31,
					default: null,
					// periodOptional: true,
					slug: "sports-providers",
					dropdown: [
						{
							id: "sport_slug",
							options: () => getAllSportsDropdown(),
							label: "Sport Slugs",
							placeholder: "Sport Slugs",
							multiple: true,
						},
						{
							id: "event_phase",
							options: () => eventPhaseBetsAllowed,
							label: "Event Phase",
							placeholder: "Event Phase",
							multiple: false,
						},
					],
					description: "This report outlines bets placed across various phases and sports.",
				},
		  ]
		: []),
	...(process.env.REACT_APP_COUNTRY_CODE === "ZA"
		? [
				{
					id: "42",
					name: "Significant Events",
					link: "/significant-events-report",
					period: true,
					limitRange: 31,
					default: null,
					inputText: "",
					customInput: "",
					slug: "significant-events-report",
					description: "This report provides an overview of significant events.",
				},
		  ]
		: []),
	{
		id: "43",
		name: "Customer Retention",
		link: "/customer-retention-report",
		period: true,
		limitRange: null,
		default: null,
		inputText: "",
		slug: "customer-retention-report",
		description: "This report provides an overview of customer retention.",
	},
	{
		id: "44",
		name: "Customer Betting",
		link: "/customer-betting-report",
		period: true,
		limitRange: 31,
		default: null,
		inputText: "Player",
		slug: "customer-betting-report",
		dropdown: [
			{
				id: "sport_slug",
				options: () => getAllSportsDropdown(),
				label: "Sport Slugs",
				placeholder: "Sport Slugs",
				multiple: true,
			},
		],
		description: "This report provides an overview of customer betting.",
	},
	{
		id: "45",
		name: "Event Summary",
		link: "/event-summary",
		period: true,
		limitRange: 31,
		default: null,
		// inputText: "Event",
		dropdown: [
			{
				id: "rights_holder",
				options: () => rightsHolder,
				label: "Rights Holder",
				placeholder: "Rights Holder",
				multiple: false,
			},
		],
		slug: "event-summary",
		description: "This report provides an overview of an events summary.",
	},
	...(casinoEnabled
		? [
				{
					id: "46",
					name: "Events Casino",
					link: "/events-casino",
					period: true,
					limitRange: 31,
					default: null,
					slug: "events-casino",
					description: "This report provides an overview of events casino.",
				},
		  ]
		: []),
];

export const ReportSlugs = {
	playerSlugs: [
		"total-number-active-users",
		"duplicate-users",
		"list-registered-users",
		"affiliate-user",
		"net-session",
		"free-bets-report",
		"account-customer-strike-rate",
		"bonus-engine-report",
		"customer-retention-report",
		"customer-betting-report",
	],
	tradingSlugs: ["liabilities", "unclosed-bets", "cancelled-bets", "unpaid-bets", "manual-bets-by-user", "sports-providers", "event-summary"],
	financialsSlugs: [
		"betting-summary-by-user",
		"accounting-summary-by-day",
		"deposit-money",
		"user-balance",
		"turnover-by-game",
		"turnover-by-sports",
		"financial-report",
		"transactions-report-by-user",
		"user-balances",
		"event-placed-bets-report",
		"multiple-bets-list",
		"over-average-stake-bets",
		"customer-records",
		"free-bets",
		"client-balance-movement",
	],
	incomeAccessSlugs: ["registration-report", "sales-report"],
	southAfrica: [
		"account-stake-variance-report",
		"unclosed-bets-sans",
		"fica-report",
		"tax-returns-sans-sports",
		"tax-returns-sans-horse-racing",
		"tax-returns-sans-horse-racing-v2",
		"tax-returns-sans-casino",
		"significant-events-report",
		"events-casino",
	],
};
