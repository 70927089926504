import { useSearchParams } from "hooks/useSearchParams";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { setRecentViewedUsers } from "store/Users/actions";
import ApiServices from "utils/ApiServices";
import { affiliateSidebarLinks, cmsLinks, settingsLinks, sportsLinks, tradingLinks, userProfile, usersChatLinks } from "utils/constants";
import { FavoriteActive, FavoriteDisabled, XCloseIcon } from "utils/icons";
import goBackIcon from "../../assets/images/goBackIcon.svg";
import logout from "../../assets/images/logOut.svg";
import { LogoutModal } from "./LogoutModal";
import { signOutAndClearStorage } from "helpers/api_helper";
import { apiUrls } from "utils/const.apiUrl";
import { getAllSportsDropdown } from "services/getAllSportsDropdown";
import { isEmptyObject } from "utils/global";
import { reports, ReportSlugs } from "utils/const.reports";

const SportsSidebar = ({ onClick, lsUser }) => {
	const user = useSelector(state => state.Login.user);
	const history = useHistory();
	let pathName = history?.location?.pathname;
	const sportLinks = sportsLinks?.roleToHide?.includes(user?.role);
	return (
		<ul className="metismenu list-unstyled" id="side-menu">
			{lsUser &&
				sportLinks.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<div className="settings_special_text">{row?.menuTitle}</div>
							{row?.links.map((link, i) => {
								return (
									<li className={pathName === link?.route ? "activeMenuLink" : "menuLink"} onClick={onClick} key={i}>
										<Link style={{ color: "white" }} to={link?.route}>
											{link?.sportsName}
										</Link>
									</li>
								);
							})}
						</React.Fragment>
					);
				})}
		</ul>
	);
};
const TradingSidebar = ({ onClick }) => {
	const history = useHistory();
	const user = useSelector(state => state.Login.user);
	let pathName = history?.location?.pathname;
	let newPathName = history?.location?.pathname?.split("/");
	let active = newPathName?.length === 3 && newPathName[2] !== "order" && newPathName[1] !== "bet" && newPathName[1] !== "events" ? true : false;

	const getActiveMenu = link => {
		if (pathName === link?.link) return true;
		else if (link?.tradingName === "Search Bets" && pathName.includes("bet/")) return true;
		else if (link.tradingName === "Liabilities" && pathName.includes("liability")) return true;
		else if (link?.tradingName === "Events" && pathName.includes("events")) return true;
		else if (link?.tradingName === "Sports" && active) return true;
		else return false;
	};
	return (
		<ul className="metismenu list-unstyled" id="side-menu">
			{tradingLinks.map((row, index) => (
				<React.Fragment key={index}>
					{row?.links.some(link => !link?.roleToHide?.includes(user?.role)) && row?.menuTitle && (
						<div className="settings_special_text">{row?.menuTitle}</div>
					)}
					{row?.links
						.filter(link => !link?.roleToHide?.includes(user?.role))
						.map((link, i) => {
							const active = getActiveMenu(link);

							return (
								<li className={active ? "activeMenuLink" : "menuLink"} onClick={onClick} key={i}>
									<Link style={{ color: "#ffffff", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }} to={link?.link}>
										{link?.tradingName}
									</Link>
								</li>
							);
						})}
				</React.Fragment>
			))}
		</ul>
	);
};

const CmsSidebar = ({ onClick }) => {
	const user = useSelector(state => state.Login.user);
	const history = useHistory();
	let pathName = history?.location?.pathname;

	return (
		<ul className="metismenu list-unstyled" id="side-menu">
			{cmsLinks.map((row, index) => (
				<React.Fragment key={index}>
					{row?.links.some(link => !link?.roleToHide?.includes(user?.role)) && row?.menuTitle && (
						<div className="settings_special_text">{row?.menuTitle}</div>
					)}
					{row?.links
						.filter(link => !link?.roleToHide?.includes(user?.role))
						.map((link, i) => (
							<li className={pathName === link?.route ? "activeMenuLink" : "menuLink"} onClick={onClick} key={i}>
								<Link
									style={{
										color: "#ffffff",
										fontSize: "14px",
										height: "56px",
										display: "flex",
										alignItems: "center",
									}}
									to={link?.route}
								>
									{link?.linkName}
								</Link>
							</li>
						))}
				</React.Fragment>
			))}
		</ul>
	);
};
const AffiliateSidebar = ({ onClick }) => {
	const user = useSelector(state => state.Login.user);
	const history = useHistory();
	let pathName = history?.location?.pathname;

	return (
		<ul className="metismenu list-unstyled" id="side-menu">
			{affiliateSidebarLinks.map((row, index) => (
				<React.Fragment key={index}>
					{row?.links.some(link => !link?.roleToHide?.includes(user?.role)) && row?.menuTitle && (
						<div className="settings_special_text">{row?.menuTitle}</div>
					)}

					{row?.links
						.filter(link => !link?.roleToHide?.includes(user?.role))
						.map((link, i) => (
							<li className={pathName === link?.route ? "activeMenuLink" : "menuLink"} onClick={onClick} key={i}>
								<Link
									style={{
										color: "#ffffff",
										fontSize: "14px",
										height: "56px",
										display: "flex",
										alignItems: "center",
									}}
									to={link?.route}
								>
									{link?.linkName}
								</Link>
							</li>
						))}
				</React.Fragment>
			))}
		</ul>
	);
};

const SettingsSidebar = ({ onClick, props, lsUser }) => {
	const [sports, setSports] = useState([]);
	const user = useSelector(state => state.Login.user);
	const getSports = async () => {
		let sport = await getAllSportsDropdown();
		setSports(sport);
	};

	useEffect(() => {
		getSports();
	}, []);

	settingsLinks.sort((a, b) => a.name.localeCompare(b.name));

	const match_path = props?.match?.path || "";

	const filteredSettingsLinks = lsUser
		? settingsLinks.filter(row => {
				if (
					match_path.includes("/ladders") ||
					match_path === "/bog_settings" ||
					match_path === "/bet_options" ||
					match_path === "/bet_builder" ||
					match_path === "/cash_out" ||
					match_path === "/trader_chat" ||
					match_path === "/casino_settings"
				) {
					return true;
				} else {
					return !row?.roleToHide?.includes(user?.role);
				}
		  })
		: [];

	let showSubmenu = false;
	if (
		match_path.includes("/ladders") ||
		match_path === "/bog_settings" ||
		match_path === "/bet_options" ||
		match_path === "/bet_builder" ||
		match_path === "/cash_out" ||
		match_path === "/trader_chat" ||
		match_path === "/casino_settings"
	) {
		showSubmenu = true;
	}

	return (
		<ul className="metismenu list-unstyled" id="side-menu">
			{(props?.match?.path.includes("/ladders") || showSubmenu) && (
				<li>
					<Link style={{ color: "#8A98AB" }} to="/settings">
						<img src={goBackIcon} className="go-back-icon" />
						{"Go Back"}
					</Link>
				</li>
			)}

			{!props?.match?.path.includes("/ladders") &&
				props?.match?.path !== "/bog_settings" &&
				props?.match?.path !== "/cash_out" &&
				props?.match?.path !== "/bet_options" &&
				props?.match?.path !== "/trader_chat" &&
				props?.match?.path !== "/bet_builder" &&
				props?.match?.path !== "/casino_settings" && <div className="settings_special_text">Settings</div>}

			{lsUser && (
				<>
					{props?.match?.path.includes("/ladders") ? (
						<>
							<li onClick={onClick} className={!props?.match?.params?.id ? "activeSettingsMenuLink" : ""}>
								<Link
									style={{
										color: "#ffffff",
										fontSize: "14px",
										height: "56px",
										display: "flex",
										alignItems: "center",
									}}
									to="/ladders"
								>
									{"Master Ladder"}
								</Link>
							</li>

							<li className="menu-title">{"Sports"}</li>
							{sports.map((item, index) => (
								<li key={index} onClick={onClick} className={props?.match?.params?.id === item.id ? "activeSettingsMenuLink" : ""}>
									<Link
										style={{
											color: "#ffffff",
											fontSize: "14px",
											height: "56px",
											display: "flex",
											alignItems: "center",
										}}
										to={`/ladders/${item.id}`}
									>
										{`${item.label}`}
									</Link>
								</li>
							))}
						</>
					) : showSubmenu ? (
						<>
							<li onClick={onClick} className={props?.match?.path === "/bog_settings" ? "activeSettingsMenuLink" : ""}>
								<Link
									style={{
										color: "#ffffff",
										fontSize: "14px",
										height: "56px",
										display: "flex",
										alignItems: "center",
									}}
									to="/bog_settings"
								>
									{"BOG Settings"}
								</Link>
							</li>
							<li onClick={onClick} className={props?.match?.path === "/bet_options" ? "activeSettingsMenuLink" : ""}>
								<Link
									style={{
										color: "#ffffff",
										fontSize: "14px",
										height: "56px",
										display: "flex",
										alignItems: "center",
									}}
									to="/bet_options"
								>
									{"Bet Options"}
								</Link>
							</li>
							{/* This is bet Builder and for now we will hide it -- James asked for it  */}
							{/* <li
								onClick={() => {
									isMobile ? tToggle() : "";
								}}
								className={props?.match?.path === "/bet_builder" ? "activeSettingsMenuLink" : ""}
								>
								<Link
									style={{
									color: "#ffffff",
									fontSize: "14px",
									height: "56px",
									display: "flex",
									alignItems: "center"
									}}
									to="/bet_builder"
								>
									{"Bet Builder"}
							</Link>
							</li> */}
							<li onClick={onClick} className={props?.match?.path === "/cash_out" ? "activeSettingsMenuLink" : ""}>
								<Link
									style={{
										color: "#ffffff",
										fontSize: "14px",
										height: "56px",
										display: "flex",
										alignItems: "center",
									}}
									to="/cash_out"
								>
									{"Cash Out"}
								</Link>
							</li>
							<li onClick={onClick} className={props?.match?.path === "/trader_chat" ? "activeSettingsMenuLink" : ""}>
								<Link
									style={{
										color: "#ffffff",
										fontSize: "14px",
										height: "56px",
										display: "flex",
										alignItems: "center",
									}}
									to="/trader_chat"
								>
									{"Trader Chat"}
								</Link>
							</li>
							<li onClick={onClick} className={props?.match?.path === "/casino_settings" ? "activeSettingsMenuLink" : ""}>
								<Link
									style={{
										color: "#ffffff",
										fontSize: "14px",
										height: "56px",
										display: "flex",
										alignItems: "center",
									}}
									to="/casino_settings"
								>
									{"Casino Settings"}
								</Link>
							</li>
						</>
					) : (
						filteredSettingsLinks.map(
							(row, index) =>
								row.name && (
									<li onClick={onClick} className={props?.match?.path === row?.link ? "activeSettingsMenuLink" : ""} key={index}>
										<Link
											style={{
												color: "#ffffff",
												fontSize: "14px",
												height: "56px",
												display: "flex",
												alignItems: "center",
											}}
											to={row?.link}
										>
											{row.name}
										</Link>
									</li>
								)
						)
					)}
				</>
			)}
		</ul>
	);
};

const ReportSidebar = ({ onClick }) => {
	const { search, pathname } = useLocation();
	const searchParams = useSearchParams(search);
	const user = useSelector(state => state.Login.user);

	const [favorites, setFavorites] = useState([]);
	const Player = { links: [], roleToHide: ["finance"] };
	const Trading = { links: [], roleToHide: ["finance"] };
	const Financials = { links: [], roleToHide: ["trader", "customer_service"] };
	const Responsible_gambling = { links: [], roleToHide: ["finance"] };
	const Income_Access = { links: [], roleToHide: ["trader"] };
	const scheduled_reports = { links: [], roleToHide: ["finance"] };
	const last_reports_generated = { links: [], roleToHide: ["finance"] };
	const south_africa = { links: [], roleToHide: ["finance"] };

	const handleGetFavoriteReport = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.favorite_reports}`, resolve, reject, true);
		}).then(response => {
			const data = response?.data;
			const updatedFavorites = data.reduce((acc, datum) => {
				const report = reports.find(report => report.slug === datum.report_slug);
				if (report) {
					acc.push({
						id: datum.id,
						name: report.name,
						slug: report.slug,
					});
				}
				return acc;
			}, []);

			setFavorites(updatedFavorites);
		});
	};

	const handlePushFavoriteReport = report => {
		const body = {
			report_slug: report?.slug,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(`${apiUrls.favorite_reports}`, resolve, reject, body, true);
		}).then(() => {
			handleGetFavoriteReport();
		});
	};

	const removeFromFavorite = report => {
		new Promise((resolve, reject) => {
			ApiServices.delete(`${apiUrls.favorite_reports}/${report?.id}`, resolve, reject, true);
		}).then(() => {
			const updatedFavorites = favorites.filter(item => item.slug !== report?.slug);
			setFavorites(updatedFavorites);
		});
	};
	useEffect(() => {
		handleGetFavoriteReport();
	}, []);

	const { playerSlugs, tradingSlugs, financialsSlugs, incomeAccessSlugs, southAfrica } = ReportSlugs;
	reports.forEach(report => {
		const isReportInFavorites = favorites.some(favorite => favorite.slug === report.slug);
		if (!isReportInFavorites) {
			if (playerSlugs.includes(report?.slug)) {
				if (!Player?.roleToHide?.includes(user?.role)) {
					Player.links.push(report);
				}
			} else if (tradingSlugs.includes(report?.slug)) {
				if (!Trading?.roleToHide?.includes(user?.role)) {
					Trading.links.push(report);
				}
			} else if (financialsSlugs.includes(report?.slug)) {
				if (!Financials?.roleToHide?.includes(user?.role)) {
					Financials.links.push(report);
				}
			} else if (incomeAccessSlugs.includes(report?.slug)) {
				if (!Income_Access?.roleToHide?.includes(user?.role)) {
					Income_Access.links.push(report);
				}
			} else if (report?.slug == "scheduled-reports") {
				if (!scheduled_reports?.roleToHide?.includes(user?.role)) {
					scheduled_reports.links.push(report);
				}
			} else if (report?.slug == "last-reports-generated") {
				if (!last_reports_generated?.roleToHide?.includes(user?.role)) {
					last_reports_generated.links.push(report);
				}
			} else if (southAfrica.includes(report?.slug)) {
				if (!south_africa?.roleToHide?.includes(user?.role)) {
					south_africa.links.push(report);
				}
			} else Responsible_gambling.links.push(report);
		}
	});

	useEffect(() => {
		if (searchParams) {
			let selected = document.querySelector(`.activeMenuLink`);
			if (selected) selected.scrollIntoView({ behavior: "auto" });
		}
	}, []);

	return (
		<>
			{last_reports_generated.links.length > 0 && (
				<ul className="metismenu list-unstyled report-sidemenu" id="side-menu">
					{last_reports_generated?.links?.map(report => {
						return (
							<li key={report.id} className={isEmptyObject(searchParams) ? "activeMenuLink" : "menuLink"} onClick={onClick}>
								<Link style={{ color: "#ffffff", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }} to={`/reports`}>
									{report.name}
								</Link>
							</li>
						);
					})}
				</ul>
			)}

			{scheduled_reports.links.length > 0 && (
				<ul className="metismenu list-unstyled report-sidemenu" id="side-menu">
					{scheduled_reports?.links?.map(report => {
						return (
							<li key={report.id} className={searchParams.slug === report.slug ? "activeMenuLink" : "menuLink"} onClick={onClick}>
								<Link
									style={{ color: "#ffffff", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }}
									to={`/reports?slug=${report.slug}`}
								>
									{report.name}
								</Link>
							</li>
						);
					})}
				</ul>
			)}

			{favorites.length > 0 && (
				<ul className="metismenu list-unstyled report-sidemenu" id="side-menu">
					<div className="settings_special_text">Favorites</div>
					{favorites
						.sort((a, b) => a.name.localeCompare(b.name))
						.map(report => {
							const active = pathname.includes(report.slug) || searchParams.slug === report.slug;
							return (
								<li key={report.id} className={active ? "activeMenuLink" : "menuLink"} onClick={onClick}>
									<Link
										style={{ color: "#ffffff", fontSize: "14px", height: "56px !important", display: "flex", alignItems: "center" }}
										to={`/reports?slug=${report.slug}`}
									>
										{report.name}
									</Link>
									<FavoriteActive onClick={() => removeFromFavorite(report)} />
								</li>
							);
						})}
				</ul>
			)}
			{Player.links.length > 0 && (
				<ul className="metismenu list-unstyled report-sidemenu" id="side-menu">
					<div className="settings_special_text">Player</div>
					{Player.links
						.sort((a, b) => a.name.localeCompare(b.name))
						.map(report => {
							const active = pathname.includes(report.slug) || searchParams.slug === report.slug;
							return (
								<li key={report.id} className={active ? "activeMenuLink" : "menuLink"} onClick={onClick}>
									<Link
										style={{ color: "#ffffff", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }}
										to={`/reports?slug=${report.slug}`}
									>
										{report.name}
									</Link>
									<FavoriteDisabled onClick={() => handlePushFavoriteReport(report)} />
								</li>
							);
						})}
				</ul>
			)}
			{Trading.links.length > 0 && (
				<ul className="metismenu list-unstyled report-sidemenu" id="side-menu">
					<div className="settings_special_text">Trading</div>
					{Trading.links
						.sort((a, b) => a.name.localeCompare(b.name))
						.map(report => {
							const active = pathname.includes(report.slug) || searchParams.slug === report.slug;
							return (
								<li key={report.id} className={active ? "activeMenuLink" : "menuLink"} onClick={onClick}>
									<Link
										style={{ color: "#ffffff", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }}
										to={`/reports?slug=${report.slug}`}
									>
										{report.name}
									</Link>
									<FavoriteDisabled onClick={() => handlePushFavoriteReport(report)} />
								</li>
							);
						})}
				</ul>
			)}
			{Financials.links.length > 0 && (
				<ul className="metismenu list-unstyled report-sidemenu" id="side-menu">
					{user && user?.role != "trader" && user?.role != "customer_service" && <div className="settings_special_text">Financials</div>}
					{Financials.links
						.sort((a, b) => a.name.localeCompare(b.name))
						.map(report => {
							const active = pathname.includes(report.slug) || searchParams.slug === report.slug;
							return (
								<li key={report.id} className={active ? "activeMenuLink" : "menuLink"} onClick={onClick}>
									<Link
										style={{ color: "#ffffff", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }}
										to={`/reports?slug=${report.slug}`}
									>
										{report.name}
									</Link>
									<FavoriteDisabled onClick={() => handlePushFavoriteReport(report)} />
								</li>
							);
						})}
				</ul>
			)}
			{Responsible_gambling.links.length > 0 && (
				<ul className="metismenu list-unstyled report-sidemenu" id="side-menu">
					{user && user.role !== "finance" && <div className="settings_special_text">Responsible Gambling</div>}
					{Responsible_gambling.links
						.sort((a, b) => a.name.localeCompare(b.name))
						.map(report => {
							const active = pathname.includes(report.slug) || searchParams.slug === report.slug;
							return (
								<li key={report.id} className={active ? "activeMenuLink" : "menuLink "} onClick={onClick}>
									<Link
										style={{ color: "#ffffff", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }}
										to={`/reports?slug=${report.slug}`}
									>
										{report.name}
									</Link>
									<FavoriteDisabled onClick={() => handlePushFavoriteReport(report)} />
								</li>
							);
						})}
				</ul>
			)}
			{Income_Access.links.length > 0 && (
				<ul className="metismenu list-unstyled report-sidemenu" id="side-menu">
					{user && user.role !== "finance" && <div className="settings_special_text">Income Access</div>}
					{Income_Access.links
						.sort((a, b) => a.name.localeCompare(b.name))
						.map(report => {
							const active = pathname.includes(report.slug) || searchParams.slug === report.slug;
							return (
								<li key={report.id} className={active ? "activeMenuLink" : "menuLink menuLink_income_access"} onClick={onClick}>
									<Link
										style={{ color: "#ffffff", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }}
										to={`/reports?slug=${report.slug}`}
									>
										{report.name}
									</Link>
									<FavoriteDisabled onClick={() => handlePushFavoriteReport(report)} />
								</li>
							);
						})}
				</ul>
			)}

			{south_africa.links.length > 0 && (
				<ul className="metismenu list-unstyled report-sidemenu" id="side-menu">
					{user && user.role !== "finance" && <div className="settings_special_text">South Africa</div>}
					{south_africa.links
						.sort((a, b) => a.name.localeCompare(b.name))
						.map(report => {
							const active = pathname.includes(report.slug) || searchParams.slug === report.slug;
							return (
								<li key={report.id} className={active ? "activeMenuLink" : "menuLink menuLink_income_access"} onClick={onClick}>
									<Link
										style={{ color: "#ffffff", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }}
										to={`/reports?slug=${report.slug}`}
									>
										{report.name}
									</Link>
									<FavoriteDisabled onClick={() => handlePushFavoriteReport(report)} />
								</li>
							);
						})}
				</ul>
			)}
		</>
	);
};

const UsersSidebar = ({ onClick }) => {
	const history = useHistory();
	let pathName = history?.location?.pathname;
	const dispatch = useDispatch();

	const rerecentViewedUsers = useSelector(state => state.Users.recentViewedUsers);

	useEffect(() => {
		const recentViewsFromStorage = JSON.parse(localStorage.getItem("recentViewUsers"));

		dispatch(setRecentViewedUsers(recentViewsFromStorage));
	}, []);

	const deleteRecentUser = id => {
		const newRecents = rerecentViewedUsers?.filter(item => item.id != id);
		localStorage.setItem("recentViewUsers", JSON.stringify(newRecents));
		dispatch(setRecentViewedUsers(newRecents));
	};

	const clearAll = () => {
		localStorage.setItem("recentViewUsers", JSON.stringify([]));
		dispatch(setRecentViewedUsers([]));
	};

	const user = useSelector(state => state.Login.user);

	return (
		<ul className="metismenu list-unstyled" id="side-menu">
			{usersChatLinks.map((row, index) => (
				<React.Fragment key={index}>
					{row?.links.some(link => !link?.roleToHide?.includes(user?.role)) && row?.menuTitle && (
						<div className="settings_special_text">{row?.menuTitle}</div>
					)}
					{row?.links
						.filter(link => !link?.roleToHide?.includes(user?.role))
						.map((link, i) => (
							<li className={pathName === link?.link ? "activeMenuLink" : "menuLink"} onClick={onClick} key={i}>
								<Link style={{ color: "#EAECEF", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }} to={link?.link}>
									{link?.tradingName}
								</Link>
							</li>
						))}
				</React.Fragment>
			))}

			<React.Fragment>
				{rerecentViewedUsers?.length > 0 && (
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<div className="settings_special_text">Users</div>
						<div className="settings_special_text" style={{ marginRight: "24px", cursor: "pointer" }} onClick={() => clearAll()}>
							Clear All
						</div>
					</div>
				)}
				{rerecentViewedUsers?.map(({ full_name, player_id, id }, i) => {
					return (
						<li className={pathName === `/user/${id}` ? "activeMenuLink" : "menuLink"} onClick={onClick} key={i}>
							<Link to={`/user/${id}`}>
								<div className="recent_views">
									<div className="recent_views-info">
										<h3>{full_name}</h3>
										<span>#{player_id}</span>
									</div>

									<button
										className="recent_views-delete"
										onClick={e => {
											e.stopPropagation();
											e.preventDefault();
											deleteRecentUser(id);
										}}
									>
										<XCloseIcon />
									</button>
								</div>
							</Link>
						</li>
					);
				})}
			</React.Fragment>
		</ul>
	);
};

const ProfileSidebar = ({ onClick }) => {
	const history = useHistory();
	const user = useSelector(state => state.Login.user);
	let pathName = history?.location?.pathname;
	const [showLogoutModal, setShowLogoutModal] = useState(false);

	const signOutUser = () => {
		setShowLogoutModal(false);
		var email = localStorage.getItem("email");
		let body = {
			email: email,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.new_main + "/api/v1/auth/sign-out", resolve, reject, body, true);
		}).then(response => {
			if (response.success == true) {
				signOutAndClearStorage({ wait: true });
				// localStorage.removeItem("access_token");
				// localStorage.removeItem("refresh_token");
				// setTimeout(function () {
				// 	props.history.push("/login");
				// }, 1500);
			}
		});
	};
	return (
		<>
			{showLogoutModal && (
				<LogoutModal title={"Logout"} paragraph="Are you sure you want to log out?" setShowLogoutModal={setShowLogoutModal} signOutUser={signOutUser} />
			)}
			<ul className="metismenu list-unstyled profile-menu" id="side-menu">
				{userProfile.map((row, index) => {
					return (
						<React.Fragment key={index}>
							<div className="settings_special_text">{row?.menuTitle}</div>
							{row?.links
								.filter(link => !link?.roleToHide?.includes(user?.role))
								.map((link, i) => {
									return (
										<li className={pathName === link?.link ? "activeMenuLink" : "menuLink"} onClick={onClick} key={i}>
											<Link style={{ color: "#ffffff", fontSize: "14px", height: "56px", display: "flex", alignItems: "center" }} to={link?.link}>
												{link?.tradingName}
											</Link>
										</li>
									);
								})}
						</React.Fragment>
					);
				})}
				<li className="profile-logout profile-li">
					<div className="profile-logout-content" onClick={() => setShowLogoutModal(true)}>
						<img src={logout} alt="logout" />
						<p className="profile-p"> Logout</p>
					</div>
				</li>
			</ul>
		</>
	);
};

export { CmsSidebar, ReportSidebar, SettingsSidebar, SportsSidebar, TradingSidebar, UsersSidebar, ProfileSidebar, AffiliateSidebar };
