import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "../../../assets/images/delete.svg";
import { DragAndDropFillIcon, DregAndDropIcone, EmptyGallery, MoveUpToTopDisabledIcone, MoveUpToTopIcone } from "utils/icons";

export const getMenuLinksColumns = ({ menuLinks, handleEditItem, setDeleteItem, changeOrder }) => {
	const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;
	return [
		{
			name: "icon",
			label: "ICON",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					return (
						<div className="sportsIcon">
							{row.image ? <img src={aws_s3_url + "/media/" + row?.image} alt="Menu Link image" width={"24"} height={"24"} /> : <EmptyGallery />}
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "80px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "80px" } }),
			},
		},
		{
			name: "link_name",
			label: "TITLE",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					return <>{row.link_name}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" } }),
			},
		},
		{
			name: "status",
			label: "STATUS",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					let published = row?.published;
					return (
						<>
							{published ? (
								<span className="badge badge-sports-published"> Published </span>
							) : (
								<span className="badge badge-sports-inactive"> Inactive </span>
							)}
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "120px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "120px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta, other) => {
					const row = meta;
					let { index, dragHandleProps, isDragging } = other;
					return (
						<div className="d-flex justify-content-end">
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => handleEditItem(row)} />
								{menuLinks.some(link => link.slug === row.slug && link.protected != 1) ? (
									<DeleteIconButton
										deleteIcon={deleteIcon}
										onClick={() => {
											setDeleteItem(row);
										}}
									/>
								) : null}
								{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}
								<div
									{...dragHandleProps}
									onMouseDown={(...args) => {
										dragHandleProps?.onDragStart(...args);
									}}
								>
									<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
								</div>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
