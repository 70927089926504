export const getCompetitorsFilterColumns = ({ sports }) => {
	const sportsData = sports ? JSON.stringify(sports) : [];

	const genders = JSON.stringify([
		{
			value: "",
			name: "All Genders",
		},
		{
			value: "Female",
			name: "Female",
		},
		{
			value: "Male",
			name: "Male",
		},
	]);
	const types = JSON.stringify([
		{
			value: "",
			name: "All types",
		},
		{
			value: "Person",
			name: "Person",
		},
		{
			value: "Team",
			name: "Team",
		},
	]);
	return [
		{
			name: "Gender",
			type: "select",
			value: "gender",
			data: genders,
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "Name",
			type: "text",
			value: "name",
		},
		{
			name: "Sport",
			type: "select",
			value: "sport_id",
			data: sportsData,
			optionKey: "id",
			optionName: "name",
		},
		{
			name: "Type",
			type: "select",
			value: "type",
			data: types,
			optionKey: "value",
			optionName: "name",
		},
	];
};
