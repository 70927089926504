export const getPushNotificationFilterColumns = () => {
	return [
		{
			name: "Title",
			type: "text",
			value: "title",
		},
		{
			name: "Platform",
			type: "select",
			value: "platform",
			data: JSON.stringify([
				{
					value: "",
					name: "All",
				},
				{
					value: "ios",
					name: "iOS",
				},
				{
					value: "android",
					name: "Android",
				},
				{
					value: "web",
					name: "Web",
				},
			]),
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "Processing",
			type: "select",
			value: "processing",
			data: JSON.stringify([
				{
					value: "",
					name: "All",
				},
				{
					value: "1",
					name: "Completed",
				},
				{
					value: "0",
					name: "Pending",
				},
			]),
			optionKey: "value",
			optionName: "name",
		},
	];
};
