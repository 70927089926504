import React, { useEffect, useState } from "react";

import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";

import { XCloseIcon } from "utils/icons";
import LoadingSpinner from "components/Loader/LoadingSpinner";

import MUIDataTable from "mui-datatables";

import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import { getBonusLogsColumns } from "./getBonusLogsColumns";
import { tableRowsPerPage, tableBarSettings } from "utils/tableSettings";

import "./bonusEngine.scss";

const BonusEngineLogs = props => {
	const { id: bonusId, setShowBonusEngineLogs } = props;

	const [isDataLoading, setIsDataLoading] = useState(false);
	const [bonusLogs, setBonusLogs] = useState([]);
	const [unfilteredLogs, setUnfilteredLogs] = useState([]);

	const getBonusEngineLogs = () => {
		setIsDataLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.bonus_engine + `/${bonusId}/logs`, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;

				setBonusLogs(data);
				setUnfilteredLogs(data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		getBonusEngineLogs();
	}, []);

	const bonusLogsColumn = getBonusLogsColumns({ bonusLogs });

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Bonus History</span>
				<XCloseIcon className={"xclose-icon"} onClick={() => setShowBonusEngineLogs(false)} />
			</div>
			<div className="bonus-tabs-container">
				<div className="cms-page bonus-logs-page">
					<SingleSearchFilter setData={setBonusLogs} unfilteredData={unfilteredLogs} detailsColumn={["player_id", "player_name"]} />

					<MUIDataTable
						columns={bonusLogsColumn}
						data={bonusLogs}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							sort: true,
							elevation: 0,
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							responsive: "",
							rowsPerPage: 50,
							rowsPerPageOptions: tableRowsPerPage,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default BonusEngineLogs;
