import React, { useEffect } from "react";
import { AddButton, DiscardButton, SaveLightButton, SaveLightButtonCustum, SaveUnchangedButton } from "./AddButton";
import { userReadOnly, disableInputs } from "utils/global";
import { SimpleGoBackArrow } from "utils/icons";
import useWindowSize from "hooks/useWindowSize";
import { breakpoints } from "utils/constants";

import "./topBarComponents.scss";

export const TopBarComponent = props => {
	const {
		saveHandler,
		loading,
		formId,
		type,
		discardHandler,
		dataChanged,
		classname,
		style,
		hasGoBackArrow = true,
		name = "Save",
		iconButton,
		dontDisable,
		onClose,
		children,
	} = props;

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	useEffect(() => {
		if (isUserReadOnly && !dontDisable) disableInputs();
	}, [isUserReadOnly]);

	return (
		<div className={classname ? `${classname} topbar-component` : "topbar-component"} style={style}>
			{hasGoBackArrow && <SimpleGoBackArrow onClick={() => onClose?.()} className="go_back_btn" />}
			{children}
			{discardHandler && <DiscardButton name={"Discard Changes"} onClick={discardHandler} dataChanged={dataChanged} />}
			{saveHandler &&
				(dataChanged ? <SaveLightButton name={name} onClick={saveHandler} loading={loading} formId={formId} type={type} /> : <SaveUnchangedButton />)}
			{iconButton && iconButton}
		</div>
	);
};

export const CustumInputLadder = ({
	loading,
	saveHandler,
	handleFirst,
	handleSecond,
	discardHandler,
	title,
	dataChanged,
	formId,
	type,
	classname,
	subtitle,
	removedCopyButton,
	ladderArray,
	setLadderArray,
	downloadHandler,
	id,
	setAddLadderArray,
	setDataChanged,
}) => {
	return (
		<>
			<div className={classname ? `${classname} topbar-component` : "topbar-component"} style={{ justifyContent: "space-between", paddingLeft: "20px" }}>
				<div className="d-flex gap-2">
					<SaveLightButtonCustum
						name={title}
						onClick={handleFirst}
						loading={loading}
						formId={formId}
						type={type}
						ladderArray={ladderArray}
						setLadderArray={setLadderArray}
						setAddLadderArray={setAddLadderArray}
						classname={"special_style_top_component"}
						id={id}
						setDataChanged={setDataChanged}
					/>
					{!removedCopyButton && (
						<SaveLightButtonCustum
							name={subtitle}
							onClick={handleSecond}
							loading={loading}
							formId={formId}
							type={type}
							classname={"special_style_top_component"}
						/>
					)}
					<DiscardButton name={"Get Template"} onClick={downloadHandler} style={{ border: "1px solid #5C626A", marginLeft: "0px" }} />
				</div>
				<div>
					{discardHandler && <DiscardButton name={"Discard Changes"} onClick={discardHandler} dataChanged={dataChanged} />}
					{dataChanged ? <SaveLightButton name={"Save"} onClick={saveHandler} loading={loading} formId={formId} type={type} /> : <SaveUnchangedButton />}
				</div>
			</div>
		</>
	);
};
export const StyleTopBarComponent = ({ loading, saveHandler, uploadImage, discardHandler, dataChanged, formId, type, classname }) => {
	const { width } = useWindowSize();
	const isMobile = width <= breakpoints.PHONE;

	return (
		<div
			className={classname ? `${classname} style-topbar-component topbar-component` : "topbar-component"}
			style={{ justifyContent: "space-between", paddingLeft: "24px" }}
		>
			<div>
				<SaveLightButton
					name={"Upload Logo"}
					onClick={uploadImage}
					loading={loading}
					formId={formId}
					type={type}
					classname={"special_style_top_component"}
					style={{ border: "none" }}
				/>
			</div>

			<div>
				{!isMobile && discardHandler && <DiscardButton name={"Discard Changes"} onClick={discardHandler} />}
				{dataChanged ? <SaveLightButton name={"Save"} onClick={saveHandler} loading={loading} formId={formId} type={type} /> : <SaveUnchangedButton />}
			</div>
		</div>
	);
};

export const CodesMSGTopBarComponent = ({ loading, importHandler, addnewHandler }) => {
	return (
		<div className="message-topbar">
			<div className="page-header-position">
				<AddButton name={"Import"} onClick={importHandler} />
				<AddButton name={"Export"} onClick={addnewHandler} loading={loading} />
			</div>
		</div>
	);
};
