export const getDefaultLeaguesFilterTable = ({ countries, competitions, sportsData }) => {
	const competitionsData = competitions ? JSON.stringify(competitions) : [];
	const sports = sportsData ? JSON.stringify(sportsData) : [];
	const countriesData = countries ? JSON.stringify(countries) : [];

	return [
		{
			name: "Country",
			type: "select",
			value: "country",
			data: countriesData,
			optionKey: "cca2",
			optionName: "name",
		},
		{
			name: "League",
			type: "select",
			value: "league_id",
			data: competitionsData,
			optionKey: "id",
			optionName: "name",
		},
		{
			name: "Sport",
			type: "select",
			value: "sport_id",
			data: sports,
			optionKey: "slug",
			optionName: "name",
		},
	];
};
