import React, { useEffect, useState } from "react";
import { getLastReportsGeneratedTableColmns } from "./LastReportsGeneratedTableColmns";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import LoadingSpinner from "components/Loader/LoadingSpinner";

import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";

import { useHistory } from "react-router-dom";
import { reports, ReportSlugs } from "utils/const.reports";

export const LastReportsGenerated = () => {
	const [lastReports, setLastReports] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const history = useHistory();

	const getReports = data => {
		const Player = [];
		const Trading = [];
		const Financials = [];
		const Income_Access = [];
		const Responsible_gambling = [];
		const south_africa = [];

		const { playerSlugs, tradingSlugs, financialsSlugs, incomeAccessSlugs, southAfrica } = ReportSlugs;

		data.forEach(report => {
			if (playerSlugs.includes(report?.slug)) {
				Player.push(report);
			} else if (tradingSlugs.includes(report?.slug)) {
				Trading.push(report);
			} else if (financialsSlugs.includes(report.slug)) {
				Financials.push(report);
			} else if (incomeAccessSlugs.includes(report?.slug)) {
				Income_Access.push(report);
			} else if (report.slug == "scheduled-reports" || report.slug == "last-reports-generated") {
				// eslint-disable-next-line no-empty
			} else if (southAfrica.includes(report?.slug)) {
				south_africa.push(report);
			} else Responsible_gambling.push(report);
		});

		if (Player.length > 0) Player.sort((a, b) => a.name.localeCompare(b.name)).unshift({ name: "Player", title: "Player", bgColors: true });
		if (Trading.length > 0) Trading.sort((a, b) => a.name.localeCompare(b.name)).unshift({ name: "Trading", bgColors: true });
		if (Financials.length > 0) Financials.sort((a, b) => a.name.localeCompare(b.name)).unshift({ name: "Financials", bgColors: true });
		if (Responsible_gambling.length > 0)
			Responsible_gambling.sort((a, b) => a.name.localeCompare(b.name)).unshift({ name: "Responsible Gambling", bgColors: true });
		if (Income_Access.length > 0) Income_Access.sort((a, b) => a.name.localeCompare(b.name)).unshift({ name: "Income Access", bgColors: true });
		if (southAfrica.length > 0) south_africa.sort((a, b) => a.name.localeCompare(b.name)).unshift({ name: "South Africa", bgColors: true });

		setLastReports([...Player, ...Trading, ...Financials, ...Responsible_gambling, ...Income_Access, ...south_africa]);
		setIsDataLoading(false);
	};

	useEffect(() => {
		setIsDataLoading(true);
		getReports(reports);
	}, []);

	const tableColumns = getLastReportsGeneratedTableColmns({ lastReports, history });
	return (
		<HelmetLayout titleProps={"Reports"}>
			<div className="last-reports-generated">
				<SingleSearchFilter setData={getReports} unfilteredData={reports} filterColumn={["name"]} />
				<div className="cms-page p-0">
					<MUIDataTable
						columns={tableColumns}
						data={lastReports}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							sort: true,
							elevation: 0,
							textLabels: { body: { noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!" } },
							setRowProps: row => {
								return { className: `${!row[2] ? "gray report-title" : "report-title-hover"}` };
							},
							onRowClick: (rowData, rowMeta) => {
								const slug = lastReports[rowMeta.dataIndex].slug;
								if (slug) history.push(`/reports?slug=${slug}`);
							},
							responsive: tableResponsive,
							rowsPerPage: 50,
							rowsPerPageOptions: tableRowsPerPage,
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
			</div>
		</HelmetLayout>
	);
};
