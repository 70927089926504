import React from "react";
import deleteIcon from "../../../assets/images/delete.svg";
import { EditButton, EyeButton } from "components/GeneralComponents/AddButton";
import { DeleteIconButton } from "./../../../components/GeneralComponents/AddButton";
import HTMLReactParser from "html-react-parser";
import { providerTypes } from "utils/constants";

const showEyeIcon = provider_prefix => {
	return provider_prefix === providerTypes.EVERY_MATRIX || provider_prefix === providerTypes.MANUAL;
};

export const getMarketsTableColumns = ({ records, getSelection, setModalShow, modalShow, setDeleteId, setMarketItem }) => {
	return [
		{
			name: "market_id",
			label: "ID",
			className: "name",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "30px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "30px" }, className: "max-button" }),
			},
		},
		{
			name: "market_name",
			label: "NAME",
			className: "name",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return <>{record.market_name}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" }, className: "max-button" }),
			},
		},
		{
			name: "sport_name",
			label: "SPORT",
			className: "name",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" }, className: "max-button" }),
			},
		},
		{
			name: "status",
			label: "STATUS",
			className: "name",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const active = record?.active ? 1 : 0;

					var status_txt = !active
						? `<span className="badge badge-sports-inactive"> Inactive </span>`
						: `<span className="badge badge-sports-published"> Published </span>`;
					return HTMLReactParser(status_txt);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button" }),
			},
		},
		{
			name: "Selection",
			label: "SELECTION",
			className: "",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const show = showEyeIcon(record?.provider_prefix);

					return (
						<div className="d-flex justify-content-start">
							<div>{show ? <EyeButton onClick={() => getSelection(record)} /> : "-"}</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { minWidth: "30px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "30px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "default_league_delete",

			sortable: true,
			sort: "asc",
			options: {
				viewColumns: false,
				filter: false,
				// Check if this is an saved report, if so check if that column is showing
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex justify-content-end">
							<div className="heroBanners-buttons">
								<EditButton onClick={() => setMarketItem(record)} />
								{record.provider_slug === providerTypes.MANUAL && (
									<DeleteIconButton
										onClick={() => {
											setModalShow({ ...modalShow, deleteModal: true });
											setDeleteId(record.id);
										}}
										record={record}
										deleteIcon={deleteIcon}
										setDeleteId={setDeleteId}
										setModalShow={setModalShow}
										modalShow={modalShow}
									/>
								)}
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { minWidth: "30px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "30px" } }),
			},
		},
	];
};
