import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { LoadingSaveButton, NoButton, YesButton } from "./AddButton";

export const ConfirmOrderItems = ({ setShow, loading, handler }) => {
	return (
		<SweetAlert title={"Confirm order of items"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
			<div style={{ position: "relative" }}>
				<div className="">
					<span className="delete-span">Are you sure you want to change order ?</span>
				</div>
			</div>
			<div className="d-flex justify-content-end" style={{ marginTop: "40px" }}>
				<NoButton onClick={() => setShow(false)} name="No" />
				{loading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={handler} />}
			</div>
		</SweetAlert>
	);
};
