import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "../../../assets/images/delete.svg";
import { getFormatDateByUserTimezone, getImageThumbnail, sortColumn } from "utils/global";
import { DragAndDropFillIcon, DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone } from "utils/icons";

export const getCarouselColumns = ({ handleEditItem, setDeleteItem, setItemTitle, changeOrder }) => {
	const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;
	return [
		{
			name: "media",
			label: "MEDIA",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					let details = row?.details ? JSON.parse(row.details) : {};
					let imageContent = getImageThumbnail(details?.image);
					return <>{imageContent && <img src={aws_s3_url + "/media/" + imageContent} alt="image" width={"45"} height={"30"} />}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "80px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "80px" }, className: "max-button" }),
			},
		},
		{
			name: "details",
			label: "TITLE",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customSort: (a, b) => sortColumn(a, b, "title"),
				customBodyRender: (value, meta) => {
					const row = meta;
					let details = row?.details ? JSON.parse(row.details) : {};
					let title = details?.title;
					return <>{title}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" }, className: "max-button" }),
			},
		},
		{
			name: "publish_start_date_time",
			label: "START DATE",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					const startDate = getFormatDateByUserTimezone(row?.publish_start_date_time);
					return <>{startDate}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "170px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "170px" }, className: "max-button" }),
			},
		},
		{
			name: "publish_stop_date_time",
			label: "END DATE",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					const endDate = getFormatDateByUserTimezone(row?.publish_stop_date_time);
					return <>{row?.never_expire !== 1 ? endDate : ""}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "170px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "170px" }, className: "max-button" }),
			},
		},
		{
			name: "status",
			label: "STATUS",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					let published = row?.active;

					return (
						<>
							{published ? (
								<span className="badge badge-sports-published"> Published </span>
							) : (
								<span className="badge badge-sports-inactive"> Inactive </span>
							)}
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "120px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "120px" }, className: "max-button" }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta, other) => {
					const row = meta;
					let { index, dragHandleProps, isDragging } = other;
					let details = row?.details ? JSON.parse(row.details) : {};
					let title = details?.title;
					return (
						<div className="d-flex justify-content-end">
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => handleEditItem(row)} />
								<DeleteIconButton
									deleteIcon={deleteIcon}
									onClick={() => {
										setItemTitle(title);
										setDeleteItem(row);
									}}
								/>

								{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}

								<div
									{...dragHandleProps}
									onMouseDown={(...args) => {
										dragHandleProps?.onDragStart(...args);
									}}
								>
									<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
								</div>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
