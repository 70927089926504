import { apiUrls } from "utils/const.apiUrl";

const { default: ApiServices } = require("utils/ApiServices");
export const getImages = async type => {
	return new Promise((resolve, reject) => {
		ApiServices.get(`${apiUrls.get_images}?type=${type}`, resolve, reject, true);
	}).then(response => {
		if (response.success) {
			return response?.data || [];
		}
	});
};
