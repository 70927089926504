import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "../../../assets/images/delete.svg";
import { getFormatDateByUserTimezone } from "utils/global";
export const getNotificationBarTableColumns = ({ data, handleEditItem, handleDeleteItem, setShowNotificationModal }) => {
	return [
		{
			name: "message",
			label: "MESSAGE",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "name",
			options: {
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];
					let message = row?.message;
					return <>{message}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "250px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "250px" }, className: "max-button" }),
			},
		},
		{
			name: "start_date",
			label: "START DATE",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];
					const startDate = getFormatDateByUserTimezone(row?.start_date);
					return <>{startDate}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" }, className: "max-button" }),
			},
		},
		{
			name: "end_date",
			label: "END DATE",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];
					const endDate = getFormatDateByUserTimezone(row?.end_date);
					return <>{row?.never_expire !== 1 ? endDate : ""}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" }, className: "max-button" }),
			},
		},
		{
			name: "status",
			label: "STATUS",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];
					let published = row?.active == "1";
					return (
						<>
							{published ? (
								<span className="badge badge-sports-published"> Published </span>
							) : (
								<span className="badge badge-sports-inactive"> Inactive </span>
							)}
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex justify-content-end">
							<div className="heroBanners-buttons">
								<EditIconButton
									onClick={() => {
										setShowNotificationModal(true);
										handleEditItem(row);
									}}
								/>
								<DeleteIconButton
									deleteIcon={deleteIcon}
									onClick={() => {
										handleDeleteItem(row);
									}}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" }, className: "max-button" }),
			},
		},
	];
};
