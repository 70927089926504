import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { CancelButton, LoadingSaveButton, SaveButton } from "components/GeneralComponents/AddButton";
export const ShowCreateWordsMessageModal = ({
	modalTitle,
	showCreateMessage,
	wordsData,
	editMode,
	setWordsData,
	languages,
	setShowCreateMessage,
	editWordsFunction,
	btnLoading,
}) => {
	return (
		<>
			{showCreateMessage && (
				<SweetAlert title={modalTitle} showConfirm={false} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="mt-3">
							<div className="row mb-2">
								<div className="col-3 d-flex">Language:</div>
								<div className="col-9">
									<select
										className="form-control"
										value={wordsData?.language_code}
										disabled={editMode}
										onChange={e => {
											setWordsData({
												...wordsData,
												language_code: e.target.value,
											});
										}}
									>
										{languages.map((row, i) => {
											let name, value;
											if (i != 0) {
												name = row.name;
												value = row.value;
											} else {
												name = "Select language";
												value = "0";
											}
											return (
												<option value={value} key={i}>
													{name}
												</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-3 d-flex">Name:</div>
								<div className="col-9">
									<input
										className="form-control"
										value={wordsData?.name}
										onChange={e => {
											setWordsData({
												...wordsData,
												name: e.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-3 d-flex">Value:</div>
								<div className="col-9">
									<input
										className="form-control"
										value={wordsData?.value}
										onChange={e => {
											setWordsData({
												...wordsData,
												value: e.target.value,
											});
										}}
									/>
								</div>
							</div>
						</div>
						<div className="mt-3">
							<CancelButton onClick={() => setShowCreateMessage(false)} name="Cancel" />

							{btnLoading?.editBtn ? (
								<LoadingSaveButton />
							) : (
								<SaveButton
									onClick={() => {
										editWordsFunction();
									}}
									name="Save"
								/>
							)}
						</div>
					</div>
				</SweetAlert>
			)}
		</>
	);
};
