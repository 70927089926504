import React, { useRef } from "react";
import { AvForm } from "availity-reactstrap-validation";
import {
	CompetitionInput,
	EventIDInput,
	HideEventsInput,
	ImageInput,
	// MarketInput,
	NeverExpireInput,
	SwitchInput,
	SpecificDaysInput,
	SportInput,
	GeneralDatePicker,
	WidgetTypeInput,
	GeneralInput,
	GeneralDropdown,
} from "components/GeneralComponents/CustomInputs";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";
import { toast } from "react-toastify";
import moment from "moment";
import xIcon from "../../../assets/images/x-icon.svg";
import { getMatcheDropdown } from "services/getMatchesDropdown";
import getSportsData from "services/getSportsData";
import { getCompetitionsDropdown } from "services/getCompetitionsDropdown";
import { getMarkets } from "services/getMarkets";
import { convertFormatDateInUtc, getFormatDateByUserTimezone, languageToString } from "utils/global";

export const SportsWidgetPromotion = props => {
	const [loading, setLoading] = useState(false);
	const { editItem, title, handler, getSportsWidget, countires } = props;
	let details = null;
	if (editItem) {
		details = JSON.parse(editItem?.details);
	}

	const [sportsWidgetData, setSportsWidgetData] = useState({
		checked: editItem ? editItem?.active : true,
		title: details ? details?.title : "",
		subtitle: details ? details?.subtitle : "",
		image: details ? details?.image : null,
		image_exist: editItem ? true : false,
		hide_events: details ? details?.hide_events : false,
		neverExpire: editItem ? editItem?.never_expire : true,
		specific_days: details ? details?.specific_days : false,
		selected_days: details ? (details?.selected_days ? details?.selected_days : []) : [],
		startDate: editItem
			? getFormatDateByUserTimezone(editItem?.publish_start_date_time)
			: moment().startOf("day").add(1, "minute").format("YYYY-MM-DD HH:mm"),
		endDate: editItem ? getFormatDateByUserTimezone(editItem?.publish_stop_date_time) : "",
		sport: details ? details?.sport_slug : "",
		event: details ? details?.event_raw_id : "",
		event_name: editItem ? editItem?.event_name : "",
		market: details ? details?.market_id : "",
		competition_id: details ? details?.competition_raw_id : "",
		call_to_action: details ? details?.call_to_action : "",
		widget_type: details ? details?.widget_type : "",
		handicap: details ? details.handicap : "",
	});

	const [sportsData, setSportsData] = useState([]);
	const [markets, setMarkets] = useState([]);
	const [events, setEvents] = useState([]);
	const [competitions, setCompetitions] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);
	const [selectedIds, setSelectedIds] = useState({
		sport_id: details ? details?.sport_slug : "",
		market_id: details ? details?.market_id : "",
		event_id: details ? details?.event_raw_id : "",
		event_name: details ? details?.event_name : "",
		handicapExist: details ? details?.handicap : "",
		competition_id: details ? details?.competition_raw_id : "",
		link_type: "generic",
		selected_sports: [],
	});
	const [fetching, setFetching] = useState({
		market: false,
	});
	const [marketHandicaps, setMarketHandicaps] = useState([]);
	const [loadingMarketHandicaps, setLoadingMarketHandicaps] = useState(false);

	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const checkRequiredFields = () => {
		if (!selectedIds?.sport_id) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select sport</p>
				</>,
				{ className: "custom-toast" }
			);
			return true;
		}

		if (!selectedIds?.competition_id) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select a competition</p>
				</>,
				{ className: "custom-toast" }
			);
			return true;
		}

		// if (!selectedIds?.event_id) {
		// 	toast.error(
		// 		<>
		// 			<img src={xIcon} alt="Error" />
		// 			<p className="toast-icon-manual-message">Please select an event</p>
		// 		</>,
		// 		{ className: "custom-toast" }
		// 	);
		// 	return true;
		// }

		if (!selectedIds?.market_id) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select a market</p>
				</>,
				{ className: "custom-toast" }
			);
			return true;
		}

		return false;
	};

	const addSports = () => {
		let sportId = selectedIds?.sport_id;
		if (["in_play", "starting_soon", "swifty_specials"].includes(sportsWidgetData?.widget_type)) {
			sportId = [];
			if (selectedIds.selected_sports.length === 0) {
				sportId = "";
			} else {
				selectedIds.selected_sports.map(item => {
					sportId.push(item.slug);
				});
				sportId = JSON.stringify(sportId);
			}
		}

		let payload = {
			published: sportsWidgetData?.checked ? "1" : "0",
			title: sportsWidgetData?.title,
			subtitle: sportsWidgetData?.subtitle,
			image: sportsWidgetData?.image,
			image_exist: sportsWidgetData?.image_exist,
			hide_events: sportsWidgetData?.hide_events,
			never_expire: sportsWidgetData?.neverExpire ? 1 : 0,
			specific_days: sportsWidgetData?.specific_days ? 1 : 0,
			selected_days: sportsWidgetData?.selected_days,
			start_date: convertFormatDateInUtc(sportsWidgetData?.startDate),
			end_date: convertFormatDateInUtc(sportsWidgetData?.endDate),
			sport_id: sportId,
			competition_id: selectedIds?.competition_id,
			market_id: selectedIds?.market_id,
			event_id: selectedIds?.event_id,
			event_name: selectedIds?.event_name,
			link_type: selectedIds?.link_type,
			call_to_action: sportsWidgetData?.call_to_action,
			type: "sport_widget",
			name: "",
			country_code: countires ? countires : "all",
			widget_type: sportsWidgetData?.widget_type,
			handicap: sportsWidgetData.handicap,
		};

		let language = languageToString(sportsWidgetData, ["title_", "subtitle_", "call_to_action_"]);
		// Object.entries(sportsWidgetData).forEach(([key, value]) => {
		// 	if (key.includes("title_") || key.includes("subtitle_") || key.includes("call_to_action_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });

		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };

		if (sportsWidgetData?.widget_type == "standard") {
			const isRequired = checkRequiredFields();
			if (isRequired) return;

			payload["link"] = selectedIds?.event_id ? `/${sportId}/event/${selectedIds?.event_id}` : `/sport/${sportId}`;
		}

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(`${apiUrls.ADD_SPORTS_WIDGET}`, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				getSportsWidget();
				handler();
				setSelectedIds({
					...selectedIds,
					sport_id: "",
					market_id: "",
					event_id: "",
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const editSports = () => {
		let sportId = selectedIds?.sport_id;
		if (["in_play", "starting_soon", "swifty_specials"].includes(sportsWidgetData?.widget_type)) {
			sportId = [];
			if (selectedIds.selected_sports.length === 0) {
				sportId = "";
			} else {
				selectedIds.selected_sports.map(item => {
					sportId.push(item.slug);
				});
				sportId = JSON.stringify(sportId);
			}
		}

		let payload = {
			published: sportsWidgetData?.checked ? "1" : "0",
			title: sportsWidgetData?.title,
			subtitle: sportsWidgetData?.subtitle,
			image: sportsWidgetData?.image,
			image_exist: sportsWidgetData?.image_exist,
			hide_events: sportsWidgetData?.hide_events,
			never_expire: sportsWidgetData?.neverExpire ? 1 : 0,
			specific_days: sportsWidgetData?.specific_days ? 1 : 0,
			selected_days: sportsWidgetData?.selected_days,
			start_date: convertFormatDateInUtc(sportsWidgetData?.startDate),
			end_date: convertFormatDateInUtc(sportsWidgetData?.endDate),
			sport_id: sportId,
			competition_id: selectedIds?.competition_id,
			market_id: selectedIds?.market_id,
			event_id: selectedIds?.event_id,
			link_type: selectedIds?.link_type,
			call_to_action: sportsWidgetData?.call_to_action,
			type: "sport_widget",
			name: "",
			country_code: countires ? countires : "all",
			id: editItem?.id,
			widget_type: sportsWidgetData?.widget_type,
			handicap: sportsWidgetData.handicap,
		};

		let language = languageToString(sportsWidgetData, ["title_", "subtitle_", "call_to_action_"]);
		// Object.entries(sportsWidgetData).forEach(([key, value]) => {
		// 	if (key.includes("title_") || key.includes("subtitle_") || key.includes("call_to_action_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });

		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };

		if (sportsWidgetData?.widget_type == "standard") {
			const isRequired = checkRequiredFields();
			if (isRequired) return;

			payload["link"] = selectedIds?.event_id ? `/${sportId}/event/${selectedIds?.event_id}` : `/sport/${sportId}`;
		}

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.EDIT_SPORTS_WIDGET, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				getSportsWidget();
				handler();
				setSelectedIds({
					...selectedIds,
					sport_id: "",
					market_id: "",
					event_id: "",
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};

	const getSports = () => {
		getSportsData().then(response => {
			setSportsData(response.filter(sport => !["horseracing", "greyhoundracing"].includes(sport?.id)));
		});
	};

	const getCompetitions = sport_id => {
		getCompetitionsDropdown(sport_id).then(response => {
			if (response.length > 0) {
				setCompetitions(response);
			}
		});
	};

	const getMarketsData = async sport_slug => {
		setFetching({ ...fetching, market: true });
		const data = await getMarkets(`?sport_slug=${sport_slug}`);

		setMarkets(data.map(({ market_id, market_name, handicap }) => ({ id: market_id, label: market_name, handicap: handicap })));
		setFetching({ ...fetching, market: false });
	};

	const getMatches = async competition_id => {
		let events = await getMatcheDropdown(competition_id);
		setEvents(events);
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	useEffect(() => {
		getSports();
		if (details) {
			let newData = { ...sportsWidgetData };
			Object.entries(details).forEach(([key, value]) => {
				if (key.includes("subtitle_") || key.includes("title_") || key.includes("call_to_action_")) {
					newData = { ...newData, [key]: value };
				}
			});
			setSportsWidgetData(newData);
		}
	}, []);

	useEffect(() => {
		if (sportsData && sportsData.length > 0) {
			if (editItem && details?.widget_type !== "standard") {
				if (details?.sport_slug) {
					let savedSportSlugs = JSON.parse(details?.sport_slug);

					let selectedSports = [];

					savedSportSlugs.forEach(sport => {
						const row = sportsData.find(item => item.slug == sport);
						if (row) selectedSports.push(row);
					});

					setSelectedIds({
						...selectedIds,
						selected_sports: selectedSports,
					});
				}
			}
		}
	}, [sportsData]);

	useEffect(() => {
		if (selectedIds?.sport_id) {
			getCompetitions(selectedIds?.sport_id);
			getMarketsData(selectedIds?.sport_id);
			setSelectedIds({ ...selectedIds, competition_id: "", event_id: "", market_id: "", handicapExist: "" });
		}
	}, [selectedIds?.sport_id]);

	useEffect(() => {
		if (selectedIds?.sport_id) {
			getMatches(selectedIds?.competition_id);
		}
	}, [selectedIds?.competition_id]);

	useEffect(() => {
		if (sportsWidgetData?.widget_type) {

			let link_type = 'generic';

			if (sportsWidgetData?.widget_type === "standard") {
				link_type = selectedIds?.event_id ? "event" : "competition";
			}

			setSelectedIds({ ...selectedIds, selected_sports: [], link_type });
		}
	}, [sportsWidgetData?.widget_type, selectedIds?.event_id]);

	const getMarketHandicaps = () => {
		setLoadingMarketHandicaps(true);

		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_event_handicaps + selectedIds?.market_id, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					const { data } = response;
					const mapData = data.map(row => {
						return { id: row, label: row };
					});

					setMarketHandicaps(mapData);
				}
			})
			.finally(() => setLoadingMarketHandicaps(false));
	};

	useEffect(() => {
		if (selectedIds?.market_id && !!selectedIds?.handicapExist) getMarketHandicaps();
	}, [selectedIds?.market_id, selectedIds?.handicapExist]);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editItem ? editSports : addSports} loading={loading} dataChanged={dataChanged} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AvForm onClick={() => !onFocus.current && handleShowOptions()}>
							<SwitchInput
								handleChange={() => {
									handleDataChanged();
									setSportsWidgetData({ ...sportsWidgetData, checked: !sportsWidgetData?.checked });
								}}
								object={{
									fieldName: "published",
									value: sportsWidgetData?.checked,
									name: "Published",
									firstRowClass: true,
								}}
							/>
							<GeneralInput
								object={{
									name: "Title",
									value: sportsWidgetData.title,
									onFocus: () => handleShowOptions("language", "title"),
									onBlur: () => (onFocus.current = false),
									required: true,
								}}
								onChange={e => {
									handleDataChanged();
									setSportsWidgetData({ ...sportsWidgetData, title: e.target.value });
								}}
							/>
							<GeneralInput
								object={{
									name: "Subtitle",
									value: sportsWidgetData.subtitle,
									onFocus: () => handleShowOptions("language", "subtitle"),
									onBlur: () => (onFocus.current = false),
								}}
								onChange={e => {
									handleDataChanged();
									setSportsWidgetData({ ...sportsWidgetData, subtitle: e.target.value });
								}}
							/>
							<ImageInput
								object={{
									name: "Background Image",
									onFocus: () => handleShowOptions("image", true),
									onBlur: () => (onFocus.current = false),
									value: sportsWidgetData?.image,
									otherRowClass: true,
									fieldName: "image",
									active: showOptions.image,
									data: sportsWidgetData,
									setData: setSportsWidgetData,
									setDataChanged: setDataChanged,
									imageLibaryType: "description",
									dimensions: "(3480 x 230 px)",
								}}
							/>
							<WidgetTypeInput
								data={sportsWidgetData}
								setData={setSportsWidgetData}
								handleDataChanged={handleDataChanged}
								selectedIds={selectedIds}
								setSelectedIds={setSelectedIds}
								otherRowClass
								sportWiget
							/>

							<SportInput
								sportsData={sportsData}
								data={selectedIds}
								setData={setSelectedIds}
								handleDataChanged={handleDataChanged}
								widgetType={sportsWidgetData?.widget_type}
								type="sports_widget"
								otherRowClass
								draggable
							/>
							{!["in_play", "starting_soon", "swifty_specials"].includes(sportsWidgetData?.widget_type) && (
								<>
									<CompetitionInput
										competitions={competitions}
										data={selectedIds}
										setData={setSelectedIds}
										handleDataChanged={handleDataChanged}
										otherRowClass
										notOptional
									/>

									<EventIDInput
										eventsData={events}
										data={selectedIds}
										setData={setSelectedIds}
										handleDataChanged={handleDataChanged}
										otherRowClass
										required={false}
									/>

									{/* <MarketInput
												markets={markets}
												data={selectedIds}
												setData={setSelectedIds}
												handleDataChanged={handleDataChanged}
												notOptional={true}
												otherRowClass
											/> */}

									<GeneralDropdown
										object={{
											name: "Market",
											options: markets,
											required: true,
											loading: fetching?.market,
											value: selectedIds?.market_id,
										}}
										handleChange={e => {
											handleDataChanged();
											setSelectedIds({ ...selectedIds, market_id: e?.id, handicapExist: e.handicap });
											setSportsWidgetData({ ...sportsWidgetData, handicap: "" });
											setMarketHandicaps([]);
										}}
									/>

									{!!selectedIds.handicapExist && (
										<GeneralDropdown
											object={{
												name: "Handicap",
												options: marketHandicaps,
												loading: loadingMarketHandicaps,
												value: sportsWidgetData?.handicap,
											}}
											handleChange={e => {
												handleDataChanged();
												setSportsWidgetData({ ...sportsWidgetData, handicap: e?.id });
											}}
										/>
									)}

									<GeneralInput
										object={{
											name: "CTA",
											placeholder: "Call to Action",
											value: sportsWidgetData.call_to_action,
											onFocus: () => handleShowOptions("language", "call_to_action"),
											onBlur: () => (onFocus.current = false),
										}}
										onChange={e => {
											handleDataChanged();
											setSportsWidgetData({ ...sportsWidgetData, call_to_action: e.target.value });
										}}
									/>
								</>
							)}
							<HideEventsInput data={sportsWidgetData} setData={setSportsWidgetData} handleDataChanged={handleDataChanged} otherRowClass />
							<GeneralDatePicker
								object={{
									name: "Start Date",
									id: "name",
									value: sportsWidgetData?.startDate,
									isTimeExist: true,
									formatDate: true,
									withUtc: true,
									noFormattedDate: true,
								}}
								handleChange={e => {
									handleDataChanged();
									setSportsWidgetData({ ...sportsWidgetData, startDate: e });
								}}
							/>

							<NeverExpireInput
								checked={sportsWidgetData?.neverExpire}
								handleChange={() => {
									handleDataChanged();
									setSportsWidgetData({
										...sportsWidgetData,
										neverExpire: !sportsWidgetData?.neverExpire,
									});
								}}
								otherRowClass
							/>
							{!sportsWidgetData?.neverExpire && (
								<GeneralDatePicker
									object={{
										name: "End Date",
										value: sportsWidgetData?.endDate,
										isTimeExist: true,
										formatDate: true,
										withUtc: true,
										noFormattedDate: true,
									}}
									handleChange={e => {
										handleDataChanged();
										setSportsWidgetData({ ...sportsWidgetData, endDate: e });
									}}
								/>
							)}
							<SpecificDaysInput
								data={sportsWidgetData}
								setData={setSportsWidgetData}
								handleDataChanged={handleDataChanged}
								handleShowOptions={handleShowOptions}
								otherRowClass
							/>
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
						{showOptions.language && (
							<LanguageTitle data={sportsWidgetData} setData={setSportsWidgetData} title={showOptions.language} handleDataChanged={handleDataChanged} />
						)}
						{showOptions.image && (
							<ImageLibrary
								data={sportsWidgetData}
								setData={setSportsWidgetData}
								type="sport_widget"
								handleDataChanged={handleDataChanged}
								handleShowOptions={handleShowOptions}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
