import { DeleteIconButton, MoveUpButton, MoveUpDisabledButton } from "components/GeneralComponents/AddButton";
import { GeneralDropdown, GeneralInput, SwitchInput } from "components/GeneralComponents/CustomInputs";
import React from "react";
import { Spinner } from "reactstrap";
import { _footerType } from "utils/constants";
import { getImageThumbnail, filterAndSortArray } from "utils/global";
import { EmptyGallery, LinkIcon, UploadIcon } from "utils/icons";

const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;
export const ImagesComponent = ({
	data,
	setInputId,
	setLinkData,
	setShowTitleLanguage,
	linkData,
	handleDataChanged,
	deleteIcon,
	deleteLoading,
	setDeleteItem,
	type,
	handleChange,
	changeOrder,
	handleOpenImageLibrary,
	setImageId,
	pageContentData,
	footerSelectedId,
}) => {
	pageContentData = [...pageContentData, { name: "/terms", friendly_name: "Terms and Conditions" }, { name: "/privacy", friendly_name: "Privacy Policy" }];
	const filteredCompetitions = filterAndSortArray(pageContentData, "friendly_name");
	let newFilteredCompetitions = filteredCompetitions.map(row => {
		return { id: row.name, label: row.friendly_name };
	});

	const handleChangeData = (newValue, id, newType) => {
		if (newType === "link") {
			handleChange(newValue.id, id, type, "link");
		} else {
			handleChange(newValue.id, id, "", "page_type");
		}
		handleDataChanged?.();
	};

	return (
		<div className="footer-links-container">
			<SwitchInput
				handleChange={() => {
					handleDataChanged();
					setLinkData({ ...linkData, enabled: !linkData?.enabled });
				}}
				object={{
					name: "Enabled",
					value: linkData.enabled,
					wrapperClassName: "pt-0",
				}}
			/>
			<div className="d-flex align-items-center mb-3">
				<GeneralInput
					onChange={e => handleChange(e.target.value, 0, type, "title")}
					onClick={() => {
						setInputId(null);
						setImageId(null);
						setShowTitleLanguage(0);
					}}
					object={{
						value: linkData?.title_name,
						defaultPlaceholder: "Title Name",
						inputContainer: "input-container footer-title-input ",
					}}
				/>
			</div>
			{data.map((element, index) => {
				const value = newFilteredCompetitions?.find(pageContent => pageContent?.id === element?.link.toLowerCase());
				const selectedRow = _footerType.find(row => row.id === element?.page_type);
				const filteredRow = footerSelectedId?.length > 0 && footerSelectedId?.find(row => row?.id === element?.id);
				const result = filteredRow ? filteredRow.value : "";

				return (
					<div className="d-flex align-items-center mb-3 gap-3" key={index}>
						<div className="d-flex align-items-center flex-column flex-md-row w-100 w-md-auto gap-2">
							<div className="link-name" onClick={() => handleOpenImageLibrary(element.id)}>
								<span className="footer-image">
									{element.value !== "" ? (
										<img src={aws_s3_url + "/media/" + getImageThumbnail(element?.value || result)} alt="Thumb" />
									) : (
										<EmptyGallery />
									)}
									<UploadIcon />
								</span>
							</div>
							<GeneralDropdown
								object={{
									options: _footerType,
									value: selectedRow?.id,
									label: selectedRow?.label,
									property: "page_type",
									fieldName: element.id,
									wrapperClassNameDropdown: "input-container p-0 m-0",
								}}
								handleChange={handleChangeData}
							/>
							{element.page_type === "modal" ? (
								<GeneralDropdown
									object={{
										inputClassName: "general_input",
										options: newFilteredCompetitions,
										value: value?.id,
										label: value?.label,
										fieldName: element.id,
										property: "link",
										defaultPlaceholder: "Page Content",
										wrapperClassNameDropdown: "input-container p-0 m-0",
									}}
									handleChange={handleChangeData}
								/>
							) : (
								<div className="w-100">
									{element.page_type !== "games_list" && (
										<GeneralInput
											onChange={e => handleChange(e.target.value.toLowerCase(), element.id, "image_links", "link")}
											onClick={() => {
												setInputId(null);
												setImageId(null);
												setShowTitleLanguage(null);
											}}
											object={{
												value: element.link,
												inputContainer: "input-container p-0 m-0",
												defaultPlaceholder: element.page_type === "default" || element.page_type === "new_tab" ? "Path" : "URL Address",
												inputClassName: "general_input",
												icon: <LinkIcon />,
											}}
										/>
									)}
								</div>
							)}
						</div>
						<div className="footer-links-actions">
							{deleteLoading === index ? (
								<Spinner animation="border" size="sm" />
							) : (
								<DeleteIconButton className={"m-0"} deleteIcon={deleteIcon} onClick={() => setDeleteItem(element?.id)} />
							)}
							{index === 0 ? (
								<MoveUpDisabledButton className={"m-0"} />
							) : (
								<MoveUpButton onClick={() => changeOrder(index, "UP", "images")} className={"m-0"} />
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};
