export const getLiabilitiesFilterColumns = props => {
	const { sports } = props;
	const sportsData = sports ? JSON.stringify(sports) : [];
	return [
		{
			name: "Sport",
			type: "select",
			value: "sport_id",
			data: sportsData,
			optionKey: "id",
			optionName: "name",
		},
		{
			name: "Date",
			type: "date",
			value: "date",
			// data: [],
			defaultValue: "24H",
			defaultDateDays: "1",
		},
	];
};
