import React, { useEffect, useState } from "react";
import { SearchHeader } from "./searchHeader";
import { DeleteIconButton, LoadingSaveButton, NoButton, PlusAddButton, YesButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "assets/images/delete.svg";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import SweetAlert from "react-bootstrap-sweetalert";
import { getMarkets } from "services/getMarkets";
import { Spinner } from "reactstrap";
import DragAndDropList from "components/GeneralComponents/DragAndDrop";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import { ActiveStarIcon, DragAndDropFillIcon, DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone, StarIcon } from "utils/icons";

export const SportMarketFilters = props => {
	const { sport, isUserReadOnly } = props;
	const [markets, setMarkets] = useState([]);
	const [unfilteredMarkets, setUnfilteredMarkets] = useState([]);
	const [eventMarkets, setEventMarkets] = useState([]);
	const [deleteId, setDeleteId] = useState(null);
	const [addLoading, setAddLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	// const [confirmOrder, setConfirmOrder] = useState(false);
	const [loader, setIsLoading] = useState(false);
	const [marketsHandicaps, setMarketsHandicaps] = useState({});
	const [handicapsLoading, setHandicapsLoading] = useState({});

	const getMarketsData = () => {
		setIsLoading(true);
		const param = `?sport_slug=${sport?.slug}`;
		getMarkets(param).then(data => {
			setIsLoading(false);
			setMarkets(data);
			setUnfilteredMarkets(data);
			getEventMarkets();
		});
	};

	const getEventMarkets = () => {
		setIsLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_event_connection_get + `?sport_event_id=0&sport_slug=${sport?.slug}`, resolve, reject, true);
		}).then(response => {
			const eventMarketsData = response?.data;
			setEventMarkets(eventMarketsData);
			setIsLoading(false);
		});
	};

	const addEventMarket = row => {
		let payload = {
			sport_slug: sport?.slug,
			market_id: row?.market_id,
			sport_event_id: 0,
		};

		setAddLoading(row?.market_id);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.sport_event_connection_add, resolve, reject, payload, true);
		})
			.then(response => {
				let newId = response?.data;
				let newMarket = {
					id: newId,
					market_id: row?.market_id,
					market_name: row?.market_name,
					order_nr: newId,
					sport_event_id: 0,
					sport_slug: sport?.slug,
					enabled_handicaps: row?.enabled_handicaps,
					handicap: row?.handicap,
					sport_widget_default: 0,
				};
				let actualEvents = [...eventMarkets];
				actualEvents.push(newMarket);

				setEventMarkets(actualEvents);

				setAddLoading(false);
			})
			.finally(() => {
				setAddLoading(false);
			});
	};

	const deleteEventMarket = () => {
		setDeleteLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.sport_event_connection_delete + `/${deleteId}`, resolve, reject, true);
		})
			.then(() => {
				let actualEventMarkets = [...eventMarkets];
				actualEventMarkets = actualEventMarkets.filter(row => {
					if (row.id === deleteId) {
						// Filter out the deleted market from the array
						return false;
					}
					return true;
				});

				setEventMarkets(actualEventMarkets);
				setDeleteId(null);
			})
			.finally(() => {
				setDeleteLoading(false);
			});
	};

	const changeOrder = index => {
		const selectedRow = eventMarkets.splice(index, 1)[0];
		const newData = [selectedRow, ...eventMarkets];
		setDregAndDropData(newData);
	};

	const setDregAndDropData = newData => {
		setEventMarkets(newData);
		orderItems(newData);
	};

	const orderItems = data => {
		let payload = {
			order: data.map((row, index) => ({ id: row.id, order: index })),
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.sport_event_connection_order, resolve, reject, payload, true);
		})
			.then(() => {
				setEventMarkets(data);
			})
			.finally(() => {});
	};

	const editDefaultMarket = id => {
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.sport_event_connection_edit + `/${id}`, resolve, reject, true, true);
		}).then(() => {
			let actualEventMarkets = [...eventMarkets];

			actualEventMarkets.forEach(row => {
				if (row.id === id) {
					if (row?.sport_widget_default === 0) {
						row.sport_widget_default = 1;
					} else {
						row.sport_widget_default = 0;
					}
				} else {
					row.sport_widget_default = 0;
				}
			});

			setEventMarkets(actualEventMarkets);
		});
	};

	useEffect(() => {
		if (sport) {
			getMarketsData();
		}
	}, []);

	const getMarketHandicaps = market_id => {
		setHandicapsLoading({ ...handicapsLoading, [market_id]: true });

		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_event_handicaps + market_id, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					const data = response.data;
					setMarketsHandicaps({ ...marketsHandicaps, [market_id]: data });
					return data;
				}
			})
			.finally(() => {
				setHandicapsLoading({ ...handicapsLoading, [market_id]: false });
			});
	};

	const saveMarketHadicap = (connection_id, handicap) => {
		const body = { handicap, connection_id };

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.save_event_hadicap, resolve, reject, body, true);
		})
			.then(response => {
				if (response.success) {
					const data = eventMarkets.map(item => (item.id == connection_id ? { ...item, handicap_value: handicap } : item));
					setEventMarkets(data);
				}
			})
			.finally(() => {});
	};
	return (
		<>
			{loader ? (
				<div className="text-center pt-2">
					<Spinner animation="border" size="lg" />
				</div>
			) : (
				<>
					<div className="row m-0">
						<div className="col-6">
							<div className="dark-table-header">Default Sport Filters</div>
							<div className="sport-markets custom-scrollbar">
								<DragAndDropList data={eventMarkets} setData={newData => setDregAndDropData(newData)}>
									{(row, { index, dragHandleProps, isDragging }) => {
										return (
											<div key={index} className={`default-sport-row ${isUserReadOnly && "pe-none"}`}>
												<span>{row?.market_name}</span>

												<div className="d-flex justify-content-end align-items-center gap-2">
													{row?.handicap == 1 && (
														<GeneralDropdown
															object={{
																bindValue: row?.handicap_value ? { id: row?.handicap_value, label: row?.handicap_value } : null,
																options: marketsHandicaps?.[row.market_id]?.map(item => ({ id: item, label: item })),
																handleChangeDropdown: e => saveMarketHadicap(row?.id, e?.id),
																defaultPlaceholder: "Handicaps",
																onOpen: () => {
																	if (!marketsHandicaps?.[row.market_id]) getMarketHandicaps(row?.market_id);
																},
																loading: handicapsLoading?.[row.market_id],
																wrapperClassNameDropdown: `${
																	eventMarkets.length == index + 1 && eventMarkets.length > 6 ? "open_dropdown_up" : ""
																}`,
															}}
														/>
													)}
													<div onClick={() => editDefaultMarket(row?.id)}>{row?.sport_widget_default ? <ActiveStarIcon /> : <StarIcon />}</div>

													<DeleteIconButton deleteIcon={deleteIcon} className={"m-0"} onClick={() => setDeleteId(row?.id)} />

													{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}

													<div {...dragHandleProps} onMouseDown={(...args) => dragHandleProps?.onDragStart(...args)}>
														<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
													</div>
												</div>
											</div>
										);
									}}
								</DragAndDropList>
							</div>
						</div>
						<div className="col-6">
							<div>
								<SearchHeader unfilteredData={unfilteredMarkets} data={markets} setData={setMarkets} />
								<div className="sport-markets custom-scrollbar">
									{markets
										?.filter(row => !eventMarkets.some(item => item.market_id == row?.market_id))
										.map((row, index) => {
											return (
												<div key={index} className={`default-sport-row ${isUserReadOnly && "pe-none"}`}>
													<span>{row?.market_name}</span>
													<div>
														<PlusAddButton onClick={() => addEventMarket(row)} id={row?.market_id} loading={addLoading} />
													</div>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>
					{deleteId && (
						<SweetAlert title={"Confirm delete"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
							<div style={{ position: "relative" }}>
								<div className="">
									<span className="delete-span">Are you sure you want to delete this market ?</span>
								</div>
							</div>
							<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
								<NoButton onClick={() => setDeleteId(null)} name="No" />
								{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={() => deleteEventMarket()} />}
							</div>
						</SweetAlert>
					)}

					{/* {confirmOrder && (
						<ConfirmOrderItems show={confirmOrder} setShow={setConfirmOrder} handler={confirmOrderHandler} loading={orderData?.loading} />
					)} */}
				</>
			)}
		</>
	);
};
