import { React, useEffect } from "react";
import { getPromoLinkTableColumns } from "./promoLinksColumns";
import MUIDataTable from "mui-datatables";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import { useState } from "react";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import ConfirmDeleteComponent from "components/GeneralComponents/ConfirmDeleteComponent";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";
import { filterAndSortArray } from "utils/global";
import PromoLinksModal from "./promoLinksModal";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";
import { useDispatch } from "react-redux";
import { setBackdrop } from "store/Backdrop/actions";
import { InfoIconHelper } from "utils/icons";
import { PromoLinksHelpInformation } from "../HelpPagesForSettings/HelpPages";
import InformationPageHelper from "../InformationPageHelper";

const PromoLinks = props => {
	const [showPromotion, setShowPromotion] = useState(false);
	const [promoLinksData, setPromoLinksData] = useState([]);
	const [deleteItem, setDeleteItem] = useState(null);
	const [editItem, setEditItem] = useState(null);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();

	const affiliateUsers = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.affiliate_users_get, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					var data = response.data?.data;
					data = filterAndSortArray(data, "name");
					setPromoLinksData(data);
					setUnfilteredData(data);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};

	useEffect(() => {
		affiliateUsers();
	}, []);

	const affiliateUsersColumns = getPromoLinkTableColumns({
		promoLinksData,
		props,
		handleEditItem,
		setDeleteItem,
		setShowPromotion,
	});

	const handleOpenModal = () => {
		setEditItem(null);
		setShowPromotion(true);
	};

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};
	return (
		<>
			<HelmetLayout titleProps={"Promo Link"}>
				<div className="cms-page pt-0">
					<SingleSearchFilter setData={setPromoLinksData} unfilteredData={unfilteredData} filterColumn={["name", "slug"]} />
					<MUIDataTable
						columns={affiliateUsersColumns}
						data={promoLinksData}
						options={{
							...tableBarSettings,
							pagination: true,
							selectableRows: "none",
							elevation: 0,
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							responsive: tableResponsive,
							rowsPerPage: 50,
							rowsPerPageOptions: tableRowsPerPage,
							customToolbar: () => <AddTableButton onClick={handleOpenModal} />,
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
				{deleteItem && (
					<ConfirmDeleteComponent
						showDeleteCom={deleteItem}
						deleteLoading={isDataLoading}
						setDeleteLoading={setIsDataLoading}
						deleteTitle={"Confirm Deletion of Promo Link"}
						titlename={deleteItem?.name}
						setShowDeleteCom={setDeleteItem}
						affiliateUsersData={promoLinksData}
						setAffiliateUsersData={setPromoLinksData}
						id={deleteItem?.id}
						type="affiliateUsers"
					/>
				)}
				{showPromotion && (
					<PromoLinksModal
						title={"PROMO LINK"}
						editItem={editItem}
						showPromotion={showPromotion}
						setShowPromotion={setShowPromotion}
						setNewData={setPromoLinksData}
						data={promoLinksData}
						handler={() => {
							setShowPromotion(false);
							setEditItem(null);
						}}
					/>
				)}
				{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			</HelmetLayout>
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<PromoLinksHelpInformation />
				</InformationPageHelper>
			)}
		</>
	);
};
export default PromoLinks;
