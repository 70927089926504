import React, { useState, useRef, useEffect, useMemo } from "react";
import { toFixedFloat } from "../BetTicketHelpers";
import { ThousandSeperated } from "hooks/General";
import useOutsideClick from "hooks/useOutsideClick";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { AddFreeCashLogo, PriceBoost } from "utils/icons";
import { useSelector } from "react-redux";
import useGenerateBetslip from "hooks/betslip";
import { getOdds } from "utils/global";

import "./BetReferralItem.scss";

const betReferralItem = ({ itm, disabled, useFreebet, initialBetReferral, setHasChanged, sportLadders = [] }) => {
	const dropdownRef = useRef(null);
	const { bet_id, name, description, match_name, payout, odds_decimal, odds_fractional, type, event_id, sport_slug } = itm;
	const user = JSON.parse(localStorage.getItem("user"));

	const isRacing = sport_slug === "horseracing" || sport_slug === "greyhoundracing";

	const SelectedBetReferral = useSelector(state => state.SelectedBetReferral.selectedBetReferral);
	const updatedOdds = useSelector(state => state.SelectedBetReferral.updatedOdds);

	const [inputValue, setInputValue] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [isEW, setIsEW] = useState(false);

	const [changeType, setChangeType] = useState("");
	const [prevOdd, setPrevOdd] = useState();
	const [currentSelectionInfo, setCurrentSelectionInfo] = useState();

	const updatedData = updatedOdds[bet_id];

	const betSlip = SelectedBetReferral?.bet_slip;
	const generateBetslip = useGenerateBetslip();

	const toggleDropdown = () => {
		setIsOpen(prev => !prev);
	};

	const handleOptionClick = (e, option) => {
		e.stopPropagation();
		setIsOpen(false);
		// onLadderChange(option, bet_id, optionToShow);
		const tmp = { ...betSlip };

		tmp.singles.forEach(element => {
			if (element.bet_id === bet_id) {
				element.odds_decimal = option?.in_decimal;
				element.odds_fractional = option?.in_fraction;
			}
		});

		setHasChanged(true);
		generateBetslip(tmp, 300, true);
	};

	const returnsValue = toFixedFloat(payout);

	useOutsideClick(dropdownRef, () => setIsOpen(false));

	const valueBet = getOdds({ ...itm, decimal: odds_decimal, fractional: odds_fractional }, user.odds_display, true);

	const onInputChange = e => {
		const {
			target: { value },
		} = e;

		setInputValue(value);

		const tmp = { ...betSlip };

		tmp.singles.forEach(element => {
			if (element.bet_id === bet_id) {
				element.stake = value;
			}
		});

		setHasChanged(true);
		generateBetslip(tmp, 300, true);
	};

	const onEwChange = () => {
		const tmp = { ...betSlip };

		tmp.singles.forEach(element => {
			if (element.bet_id === itm.bet_id && isRacing) {
				element.each_way = !isEW;
				element.starting_price = itm.starting_price;
			}
		});

		setHasChanged(true);

		setIsEW(!isEW);
		generateBetslip(tmp, null, true);
	};

	useEffect(() => {
		setInputValue(itm.stake);
	}, [itm?.stake]);

	useEffect(() => {
		setIsEW(itm?.each_way);
	}, [itm?.each_way]);

	const initiaItem = initialBetReferral?.singles?.find(item => item?.bet_id == bet_id);
	const initialOdd = getOdds({ ...initiaItem, decimal: initiaItem?.odds_decimal, fractional: initiaItem?.odds_fractional }, user.odds_display, true);

	const updatedOdd = useMemo(() => {
		const oddData = {
			...updatedData,
			decimal: updatedData?.odds_decimal,
			fractional: updatedData?.odds_fractional,
			...(updatedData?.price_boost
				? { boosted_decimal: updatedData?.price_boost_odds?.decimal, boosted_fractional: updatedData?.price_boost_odds.fractional }
				: {}),
		};

		if (updatedData) {
			const newValue = getOdds(oddData, "decimal");

			if (+prevOdd === +newValue || prevOdd === "SP") {
				return;
			}

			const type = +prevOdd < +newValue ? "drifting" : "shortening";

			setChangeType(type);
		}

		const displayNewOdd = getOdds(oddData, user.odds_display, true);

		const odd = updatedData ? displayNewOdd : currentSelectionInfo?.currentOdd;
		setPrevOdd(odd);

		return odd;
	}, [updatedData, currentSelectionInfo?.currentOdd]);

	const isPriceBoosted = updatedData ? updatedData?.price_boost : currentSelectionInfo?.price_boost;

	useEffect(() => {
		const param = `selectionId=${bet_id}&eventId=${event_id}&sportId=${sport_slug}&cms=true`;

		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_SELECTION_ODDS + `?${param}`, resolve, reject, true);
		}).then(response => {
			const currentOdd = getOdds(
				{
					sport_slug,
					decimal: response?.odds_decimal,
					fractional: response?.odds_fractional,
					...(response?.price_boost
						? { boosted_decimal: response?.price_boost_odds?.decimal, boosted_fractional: response?.price_boost_odds.fractional }
						: {}),
				},
				user.odds_display,
				true
			);
			setCurrentSelectionInfo({ ...response, currentOdd });
		});
	}, []);

	return (
		<div className="bet-referral-item">
			<div className="bet-referral-item-header">
				{/* TODO: Add logic to remove bets */}

				{/* {bets?.length > 1 && (
					<img className="bet-referral-block-close" width={20} height={20} src={CloseIconDark} alt="close" onClick={() => cancelBetHandler(bet_id)} />
				)} */}
				{name}
			</div>
			<div className="bet-referral-item-description">{description}</div>
			<div className="bet-referral-item-match">{match_name}</div>
			<div className="bet-referral-item-input-block">
				<input
					type="number"
					id={bet_id}
					className="bet-referral-item-input"
					name={bet_id + "_" + type}
					disabled={disabled}
					onChange={onInputChange}
					value={inputValue}
					placeholder="0.00"
				/>

				{updatedOdd && (
					<div className={`bet-referral-item-odd ${changeType}`}>
						{updatedOdd}
						{isPriceBoosted && <PriceBoost className={changeType ? "selected" : ""} />}
					</div>
				)}

				<div
					className={`bet-referral-item-dropdown ${itm?.allow_each_way ? "no-rounded" : ""}`}
					onClick={toggleDropdown}
					style={disabled ? { cursor: "default" } : {}}
				>
					{valueBet}

					<div className="bet-referral-item-dropdown-arrow">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#fff" />
						</svg>
					</div>

					{!disabled && (
						<div className={`bet-referral-dropdown-menu ${isOpen ? "open" : ""}`} ref={dropdownRef}>
							{sportLadders.map(option => {
								const optionFormat = user.odds_display == "fractional" ? option.in_fraction : option.in_decimal;
								return (
									<div key={option.id} className="bet-referral-dropdown-item" onClick={e => handleOptionClick(e, option, optionFormat)}>
										{optionFormat}
									</div>
								);
							})}
						</div>
					)}
				</div>

				{itm?.allow_each_way && (
					<div className="bet-referral-item-each_way">
						<span>EW</span>
						<input type="checkbox" onChange={onEwChange} checked={isEW} />
					</div>
				)}
			</div>

			<div className={`bet-referral-item-returns justify-content-between ${useFreebet ? "justify-content-between" : ""}`}>
				<div className="initial-info">
					Request: {initiaItem?.stake} @ {initialOdd}
					{initiaItem?.price_boosted_enabled && <PriceBoost />}
					{initiaItem?.each_way && " EW"}
				</div>
				<div>
					{useFreebet && <AddFreeCashLogo />} Returns:&nbsp;{ThousandSeperated(returnsValue)}
				</div>
			</div>
		</div>
	);
};

export default betReferralItem;
