import React from "react";
import "./BetReferralButtons.scss";
export const BetReferralBtn = ({ onClick, name, classProps, disabled }) => {
	const btnClassName = `${classProps} bet-referral-btn ${disabled && "bet-referral-btn-disabled"}`;
	const btnStyle = classProps === "referral-btn-no-filled" && disabled ? { color: "#8A98AB", background: "#fff" } : {};
	return (
		<button className={btnClassName} onClick={onClick} disabled={disabled} style={btnStyle}>
			{name}
		</button>
	);
};
