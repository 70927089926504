import React from "react";

// import { DownloadIcone } from "utils/icons";

export const getAffiliateMonthlyOverviewColumns = props => {
	const { data } = props;
	return [
		{
			name: "month",
			label: "MONTH",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "130px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "130px" }, className: "max-button" }),
			},
		},

		{
			name: "sports_ggr",
			label: "SPORTS GGY",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "130px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "casino_ggr",
			label: "CASINO GGR",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "130px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "sub_total",
			label: "SUB TOTAL",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{row.sub_total ? row.sub_total : "-"}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "120px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "120px" }, className: "max-button" }),
			},
		},
		{
			name: "less_deductions",
			label: "LESS DEDUCTIONS",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{row.less_deductions ? row.less_deductions : "-"}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "130px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "total_payable",
			label: "TOTAL",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = data[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{row.total_payable ? row.total_payable : "-"}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "140px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "140px" }, className: "max-button" }),
			},
		},
		// {
		// 	name: "download",
		// 	label: "DOWNLOAD",
		// 	className: "name",
		// 	align: "left",
		// 	sortable: true,
		// 	options: {
		// 		customBodyRender: (value, meta) => {
		// 			const row = data[meta.currentTableData[meta.rowIndex].index];
		// 			console.log(row, "row");
		// 			return (
		// 				<div className="d-flex justify-content-end">
		// 					<div className="heroBanners-buttons">
		// 						<DownloadIcone />
		// 					</div>
		// 				</div>
		// 			);
		// 		},
		// 		setCellProps: () => ({ style: { textAlign: "right", minWidth: "250px" } }),
		// 		setCellHeaderProps: () => ({ style: { textAlign: "right", minWidth: "250px" } }),
		// 	},
		// },
	];
};
