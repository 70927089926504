import { AvForm } from "availity-reactstrap-validation";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useEffect, useState, useRef } from "react";
import ApiServices from "utils/ApiServices";
import { XCloseIcon } from "utils/icons";
import { CategoryItems } from "./categoryItems";
import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import xIcon from "../../../assets/images/x-icon.svg";
import { _casinoCategoryOptions } from "utils/constants";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { capitalizeText } from "services/capitalizeText";
import { languageToString } from "utils/global";

export const EditCategory = ({ setEditCategory, type, categories, setCategories, getCategories }) => {
	const redirectRef = useRef(null);

	const handleRedirect = () => {
		setTimeout(() => {
			redirectRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest",
			});
		}, 350);
	};
	const [categoryItem, setCategoryItem] = useState({
		id: null,
		published: false,
		show_in_menu: false,
		view_style: "",
		title: "",
		mode: "",
		type: type,
	});
	const [loading, setLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [fields, setFields] = useState([]);

	const addNewCategory = () => {
		if (categoryItem?.mode) {
			let payload = categoryItem;
			payload["type"] = type;

			let language = languageToString(categoryItem, ["title_"]);

			// Object.entries(categoryItem).forEach(([key, value]) => {
			// 	if (key.indexOf("title_") > -1) {
			// 		language += `"${key}":"${value}",`;
			// 	}
			// });

			// language = language.replace(/,(\s+)?$/, ""); // remove last comma
			payload = { ...payload, language: `${language}` };

			setLoading(true);
			new Promise((resolve, reject) => {
				ApiServices.put(apiUrls.add_casino_group, resolve, reject, payload, true);
			})
				.then(() => {
					setDataChanged(false);
					getCategories(true);
					setCategoryItem({
						...categoryItem,
						mode: "",
					});
				})
				.finally(() => {
					setDataChanged(false);
					setLoading(false);
				});
		} else {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select any of element or add new one</p>
				</>,
				{ className: "custom-toast" }
			);
		}
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};
	const dependecies = {
		show_in_menu: categoryItem.show_in_menu,
	};

	useEffect(() => {
		let fields = [];
		fields.push(
			{ type: "switch", name: "Published", fieldName: "published", value: categoryItem?.published },
			{ type: "checkbox", name: "Show in main casino", fieldName: "show_in_menu", checked: categoryItem.show_in_menu },
			{
				type: "dropdown",
				name: "View Style",
				fieldName: "view_style",
				options: _casinoCategoryOptions,
				bindValue: capitalizeText(categoryItem.view_style),
			},
			{ type: "input", name: "Title", fieldName: "title", required: true }
		);
		setFields(fields);
	}, [categoryItem]);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Edit Categories</span>
				<XCloseIcon onClick={() => setEditCategory(false)} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={addNewCategory} loading={loading} dataChanged={dataChanged} onClose={() => setEditCategory(false)} />
				<div className="promotion-elements promotion-inputs  row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						<>
							<CategoryItems setCategory={setCategoryItem} categories={categories} setCategories={setCategories} handleRedirect={handleRedirect} />

							<button
								className="btn-primary btn-add-casino-category"
								onClick={() => {
									setCategoryItem({
										...categoryItem,
										id: "",
										published: true,
										show_in_menu: true,
										order: "",
										protected: "",
										view_style: "single_line",
										title: "",
										mode: "add",
										type: type,
									});
								}}
							>
								Add New
							</button>
						</>
					</div>

					{categoryItem?.mode && (
						<div className="promotion-right promotion-right-limited col-12 col-lg-6 customized_modal_promotion_left" ref={redirectRef}>
							<AvForm>
								<AllComponentsLoop
									fields={fields}
									data={categoryItem}
									setData={setCategoryItem}
									setDataChanged={setDataChanged}
									dependecies={dependecies}
								/>
								<div className="mt-3">
									<LanguageTitle data={categoryItem} setData={setCategoryItem} title={"title"} handleDataChanged={handleDataChanged} />
								</div>
							</AvForm>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
