import React from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { platformName } from "utils/constants";

const Sports = () => {
	return (
		<React.Fragment>
			<MetaTags>
				<title>Sports - {platformName}</title>
			</MetaTags>
		</React.Fragment>
	);
};

export default connect(null, { setBreadcrumbItems })(Sports);
