import React, { useEffect, useState } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { XCloseIcon } from "utils/icons";
import { useLocation } from "react-router-dom";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

const ManualSelection = ({
	onClose,
	getSelectionMarket,
	marketData,
	ladders,
	selections,
	manualSelectionType,
	editMarketSelectionData,
	setEditMarketSelectionData,
	sportSlug,
}) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const showDecimal = user.odds_display == "decimal";

	const { pathname } = useLocation();
	const match_id = pathname.split("/")[2];

	const [data, setData] = useState({
		name: "",
		type: "",
		odd: "",
	});
	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState([]);

	useEffect(() => {
		const fieldsData = [
			{ type: "input", name: "Name", fieldName: "name", required: true },
			{
				type: "dropdown",
				name: "Type",
				fieldName: "type",
				options: selections.map(({ id, outcome_name }) => ({
					label: outcome_name,
					id: id,
				})),
				required: true,
			},
			{
				type: "dropdown",
				name: "Odd",
				fieldName: "odd",
				options: ladders.map(({ in_decimal, in_fraction }) => ({
					label: showDecimal ? in_decimal : in_fraction,
					id: showDecimal ? in_decimal : in_fraction,
				})),
				required: true,
			},
		];

		if (manualSelectionType == "edit") {
			fieldsData.push(
				{ type: "switch", name: "Display", fieldName: "display", disabled: false },
				{ type: "switch", name: "Telebet", fieldName: "active", disabled: false }
			);
		}

		setFields(fieldsData);
	}, [manualSelectionType]);

	const onSave = () => {
		setLoading(true);

		const findOdd = ladders.find(item => (showDecimal ? item?.in_decimal == data?.odd : item?.in_fraction == data?.odd));

		let payload = {
			event_id: match_id,
			odd_decimal: findOdd?.in_decimal,
			odd_fractional: findOdd?.in_fraction,
			market_id: marketData?.market_id,
			selection_name: data?.name,
			outcome_type: data?.type,
		};

		// market_id, match_id

		const url = manualSelectionType == "edit" ? apiUrls.edit_market_price_boost : apiUrls.add_market_selections;

		if (manualSelectionType == "edit") {
			payload = {
				selection_name: data?.name,
				selection_id: editMarketSelectionData?.selection_id,
				market_id: marketData?.market_id,
				match_id: match_id,
				active: data?.active,
				display: data?.display,
				sport_slug: sportSlug,
				outcome_type: data?.type,
				price: findOdd?.in_decimal,
				fractional: findOdd?.in_fraction,
				type: "manual",
			};
		}

		new Promise((resolve, reject) => {
			ApiServices.post(url, resolve, reject, payload, true, fields);
		})
			.then(() => {
				onClose();
				getSelectionMarket(marketData?.market_id, match_id);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (manualSelectionType == "edit") {
			setData({
				name: editMarketSelectionData?.selection_name,
				type: editMarketSelectionData?.outcome_id,
				odd: showDecimal ? editMarketSelectionData?.feed_price_decimal : editMarketSelectionData?.feed_price_fractional,
				active: editMarketSelectionData?.active,
				display: editMarketSelectionData?.display,
			});
		}

		return () => setEditMarketSelectionData({});
	}, [manualSelectionType]);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Selections</span>
				<XCloseIcon className={"xclose-icon"} onClick={onClose} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={onSave} dataChanged={dataChanged} loading={loading} onClose={onClose} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						<AllComponentsLoop fields={fields} data={data} setData={setData} setDataChanged={setDataChanged} />
					</div>
					<div className="promotion-right col-12 col-lg-6"></div>
				</div>
			</div>
		</div>
	);
};

export default ManualSelection;
