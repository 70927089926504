import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { CardBody } from "reactstrap";
import { connect } from "react-redux";
import SearchFilter from "components/SearchFilter/searchFilter";
import { setBreadcrumbItems } from "../../../store/actions";
import { languageLabel, platformName } from "utils/constants";
import { getWordsTableColumns } from "./wordsTableColumns";
import { getWordsFilterColumns } from "./wordsFilterColumns";
import { ShowCreateWordsMessageModal } from "./showCreateWordsMessageModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { AddButton } from "components/GeneralComponents/AddButton";
import MUIDataTable from "mui-datatables";

const Words = props => {
	const [btnLoading, setBtnLoading] = useState({
		filterButton: false,
	});
	const [records, setRecords] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowPerPage, setRowPerPage] = useState(50);
	const breadcrumbItems = [{ title: "Translations", link: "/words" }];
	const [showCreateMessage, setShowCreateMessage] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [wordsData, setWordsData] = useState({
		id: "",
		language_code: "",
		name: "",
		value: "",
	});

	const languages = [
		{
			value: "",
			name: "All",
		},
		{
			value: "en",
			name: "English",
		},
		{
			value: "de",
			name: "German",
		},
		{
			value: "sq",
			name: "Albanian",
		},
		{
			value: "fr",
			name: "French",
		},
		{
			value: "pl",
			name: "Polish",
		},
		{
			value: "es",
			name: "Spanish",
		},
	];

	useEffect(() => {
		props.setBreadcrumbItems("Push Notification", breadcrumbItems);
	});

	const filterWords = () => {
		setBtnLoading({ ...btnLoading, filterButton: true });
		setRecords([]);
	};

	const editWordsFunction = () => {
		setBtnLoading({ ...btnLoading, editBtn: true });
	};

	let wordsTableColumns = getWordsTableColumns({
		records,
		languageLabel,
		setWordsData,
		setShowCreateMessage,
		setEditMode,
	});

	let wordsFilterColumns = getWordsFilterColumns({ languages });
	let page_name = "Translations";

	return (
		<React.Fragment>
			<MetaTags>
				<title>
					{page_name} - {platformName}
				</title>
			</MetaTags>

			<CardBody style={{ overflowX: "scroll" }}>
				<div className="row ">
					<div className="col-12 d-flex" style={{ justifyContent: "space-between" }}>
						<SearchFilter
							columns={wordsFilterColumns}
							page={"words"}
							preSelectedType={"select"}
							preSelectedValue={"language"}
							preSelectedName={"Language"}
							preSelectedData={languages || []}
							preSelectedOptionKey={"value"}
							preSelectedOptionName={"name"}
							filterHandler={filterWords}
							filterLoading={btnLoading?.filterButton}
						/>
						<div className="col-2" style={{ textAlign: "end" }}>
							<AddButton
								onClick={() => {
									setShowCreateMessage(true);
									setWordsData({
										...wordsData,
										id: "",
										language_code: "",
										name: "",
										value: "",
									});
									setEditMode(false);
								}}
								name="Create"
							/>
						</div>
					</div>
				</div>
				<ShowCreateWordsMessageModal
					showCreateMessage={showCreateMessage}
					wordsData={wordsData}
					editMode={editMode}
					setWordsData={setWordsData}
					languages={languages}
					setShowCreateMessage={setShowCreateMessage}
					editWordsFunction={editWordsFunction}
					btnLoading={btnLoading}
				/>
				<div className="cms-page">
					<MUIDataTable
						columns={wordsTableColumns}
						data={records}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							elevation: 0,
							onChangePage(page) {
								setCurrentPage(page);
							},
							onChangeRowsPerPage(number) {
								setRowPerPage(number);
							},
							textLabels: {
								body: {
									noMatch: " No data to display!",
								},
							},
							sortOrder: {
								name: "language_code",
								direction: "asc",
							},
							page: currentPage,
							responsive: tableResponsive,
							rowsPerPage: rowPerPage,
							rowsPerPageOptions: tableRowsPerPage,
							downloadOptions: { filename: `${page_name}.csv` },
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
			</CardBody>
		</React.Fragment>
	);
};
export default connect(null, { setBreadcrumbItems })(Words);
