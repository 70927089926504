import { FilterButton } from "components/GeneralComponents/AddButton";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import { getMuiTheme } from "components/TableMuiTheme";
import { useSearchParams } from "hooks/useSearchParams";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import ApiServices from "utils/ApiServices";
import { CalendarIcon, XCloseIcon } from "utils/icons";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import HelmetLayout from "../../components/HelmetLayout/HelmetLayout";
import { DownloadReport } from "./DownloadReport";
import { apiUrls } from "utils/const.apiUrl";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { GeneralDatePicker, GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import { getFormatDateByUserTimezone } from "utils/global";
import { ScheduledReports } from "./scheduledReports/ScheduledReports";
import { MultiSelect } from "primereact/multiselect";
import { LastReportsGenerated } from "./LastReportsGenerated/LastReportsGenerated";
import { capitalizeText } from "services/capitalizeText";
import { Link } from "react-router-dom";

import "./reports.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { reports } from "utils/const.reports";

const getDatePeriod = parameters => {
	const { dateFrom, dateTo } = parameters;
	return dateFrom && dateTo ? moment(dateFrom).format("DD-MM-YYYY") + " - " + moment(dateTo).format("DD-MM-YYYY") : "";
};

const Reports = () => {
	const { search } = useLocation();
	const { slug, player_id } = useSearchParams(search);
	const flatpickrRef = useRef(null);

	const report = reports.find(currentReport => {
		return currentReport.slug === slug;
	});

	const defaultDate = report?.default === "previous day" ? moment().subtract(1, "days") : moment().subtract(1, "weeks");

	const [dateRange, setDateRange] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [generateReportIsLoading, setGenerateReportIsLoading] = useState(false);
	const [reportsList, setReportsList] = useState([]);
	const [columns, setColumns] = useState([]);
	const [playerID, setPlayerID] = useState("");
	const [minMaxDate, setMinMaxDate] = useState({ minDate: "", maxDate: "" });
	const [dateToReports, setDateToReports] = useState("");

	const [dropdownOptions, setDropdownOptions] = useState([]);
	const [dropdownData, setDropdownData] = useState([]);
	const [customInput, setCustomInput] = useState("");

	const getReports = () => {
		if (slug === "scheduled-reports" || slug === "last-reports-generated") {
			setReportsList([]);
		} else {
			setReportsList([]);
			setIsDataLoading(true);

			new Promise((resolve, reject) => {
				ApiServices.get(`${apiUrls.get_report_list}?report_type=${slug}`, resolve, reject, true);
			})
				.then(response => {
					setReportsList(
						response.data.map(item => ({
							...item,
							date_period: getDatePeriod(item?.parameters),
						}))
					);
				})
				.finally(() => {
					setIsDataLoading(false);
				});
		}
	};

	const generateReportHandler = () => {
		setGenerateReportIsLoading(true);
		let player_id;
		let event_id;
		let user_id;
		let date_to;
		new Promise((resolve, reject) => {
			if (report?.inputText == "Player") {
				player_id = report?.inputText ? playerID : "";
			} else if (report?.inputText == "Event") {
				event_id = report?.inputText ? playerID : "";
			} else if (report?.inputText == "User") {
				user_id = report?.inputText ? playerID : "";
			} else if (report?.singleDate) {
				date_to = report?.singleDate ? dateToReports : "";
			}
			const filters = {};

			if (report.period && !report.singleDate && !(report?.periodOptional && dateRange?.length == 0)) {
				filters.dateFrom = dateRange?.[0] ? moment(dateRange?.[0]).format("YYYY-MM-DD HH:mm:ss") : defaultDate.format("YYYY-MM-DD HH:mm:ss");
				filters.dateTo = dateRange?.[1] ? moment(dateRange?.[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss");
			} else if (report.singleDate && !(report?.periodOptional && !date_to?.length)) {
				filters.dateTo = date_to;
				delete filters.dateFrom;
				delete filters.date_to;
			}
			if (player_id) {
				filters.player_id = player_id;
			}

			if (report?.customInput) {
				filters[report?.customInput] = customInput;
			}

			if (event_id) filters.event_id = event_id;
			if (user_id) filters.user_id = user_id;
			if (date_to && !report?.singleDate) filters.date_to = date_to;

			if (report?.dropdown?.length > 0) {
				for (const dropdown of report?.dropdown) {
					let data = dropdownData[dropdown?.id];
					if (dropdown?.multiple) data = data.map(row => row?.id);
					if (data?.length > 0) filters[dropdown?.id] = data;
				}
			}

			ApiServices.post(
				apiUrls.generate_report,
				resolve,
				reject,
				{
					filters,
					report_type: slug,
				},
				true
			);
		})
			.then(() => {
				getReports();
			})
			.finally(() => {
				setGenerateReportIsLoading(false);
			});
	};

	const updateValue = (selectedDates, dateStr) => {
		if (selectedDates.length === 2) {
			const rangeStr = dateStr.replace(" to ", " - ");
			document.querySelector(".flatpickr-input").value = rangeStr;
		}
	};

	const onDateChange = (selectedDates, dateStr) => {
		setDateRange(selectedDates);
		updateValue(selectedDates, dateStr);

		if (report?.limitRange) {
			setMinMaxDate({
				maxDate: selectedDates?.[0] && moment(selectedDates?.[0]).add(report?.limitRange, "days").toDate(),
				minDate: selectedDates?.[0] && moment(selectedDates?.[0]).subtract(report?.limitRange, "days").toDate(),
			});
		}
	};

	const resetDate = () => {
		setDateRange([]);
		flatpickrRef?.current?.clear();
	};

	const onInputClick = () => {
		if (!report?.limitRange && dateRange[0]) {
			const prevValue = document.querySelector(".flatpickr-input").value;

			flatpickrRef?.current?.clear(false);

			setTimeout(() => {
				updateValue(dateRange, prevValue);
			}, [1]);

			setMinMaxDate({
				maxDate: "",
				minDate: "",
			});
		}
	};

	useEffect(() => {
		if (report?.period && flatpickrRef?.current && slug && dateRange.length && !report?.singleDate) {
			resetDate();
		}

		if (slug) getReports();

		if (report?.singleDate && report?.periodOptional) {
			setDateToReports("");
		} else {
			setDateToReports(moment().format("YYYY-MM-DD"));
		}

		if (report?.dropdown?.length > 0) getDropdownOptions();

		setMinMaxDate({
			maxDate: "",
			minDate: "",
		});
		setCustomInput("");
	}, [slug]);

	const getDropdownOptions = async () => {
		let options = {};
		let data = {};

		for (const dropdown of report?.dropdown) {
			const dropdownOptions = await dropdown?.options();
			options = { ...options, [dropdown?.id]: dropdownOptions };
			data = { ...data, [dropdown?.id]: dropdown?.multiple ? dropdownOptions : "" };
		}

		setDropdownOptions(options);
		setDropdownData(data);
	};

	const preparedColumns = useMemo(() => {
		const columns = [
			{
				name: "created_at",
				label: "DATE GENERATED",
				sort: "asc",
				sortable: true,
				options: {
					customBodyRender: created_at => {
						return getFormatDateByUserTimezone(created_at, "YYYY-MM-DD HH:mm:ss");
					},
					setCellProps: () => ({ style: { minWidth: "150px" } }),
					setCellHeaderProps: () => ({ className: "max-button", minWidth: "150px" }),
				},
			},
			{
				name: "generated_by",
				label: "GENERATED BY",
				sort: "asc",
				sortable: true,
				display: false,
				options: {
					setCellProps: () => ({ style: { minWidth: "150px" } }),
					setCellHeaderProps: () => ({ className: "max-button", minWidth: "150px" }),
				},
			},
			{
				name: "report_name",
				label: " ",
				sort: "asc",
				sortable: true,
				align: "right",
				options: {
					setCellProps: () => ({ style: { minWidth: "200px" } }),
					setCellHeaderProps: () => ({ className: "max-button", minWidth: "200px" }),
					customBodyRender: (report_name, row) => {
						if (!report_name) {
							return (
								<div className="d-flex w-100 justify-content-start">
									<div className="badge-sports-yellow">Pending</div>
								</div>
							);
						}
						return (
							<>
								<div className="d-flex w-100 justify-content-end gap-3">
									<DownloadReport
										filename={report_name}
										name={`${report?.name?.replaceAll(" ", "-")}_${moment(row.rowData[0]).format("YYYYMMDD_HHmmss")}`}
									/>

									<div className="d-flex justify-content-start cursor-pointer">
										<div className="badge-sports-blue download_button_reporting view-button-reporting">
											<Link to={`/open-report/${report_name}`} className="text-white">
												View
											</Link>
										</div>
									</div>
								</div>
							</>
						);
					},
				},
			},
		];

		if (report?.period && !report?.singleDate) {
			columns.splice(1, 0, {
				name: "date_period",
				label: "DATE PERIOD",
				sort: "asc",
				sortable: true,
				options: {
					setCellProps: () => ({ style: { minWidth: "230px" } }),
					setCellHeaderProps: () => ({ className: "max-button", minWidth: "230px" }),
				},
			});
		}

		if (reportsList?.some(report => report?.parameters?.event_id))
			columns.splice(2, 0, {
				name: "event_id",
				label: "EVENT ID",
				sort: "asc",
				sortable: true,
				options: {
					customBodyRender: (value, meta) => {
						const recordIndex = meta.currentTableData[meta.rowIndex]?.index;
						const record = recordIndex !== undefined && reportsList[recordIndex];
						const event_id = record?.parameters?.event_id;
						return <div>{event_id}</div>;
					},
					setCellProps: () => ({ style: { minWidth: "120px" } }),
					setCellHeaderProps: () => ({ className: "max-button", minWidth: "120px" }),
				},
			});

		if (reportsList?.some(report => report?.parameters?.player_id)) {
			columns.splice(2, 0, {
				name: "player_id",
				label: "Player ID",
				sort: "asc",
				sortable: true,
				options: {
					customBodyRender: (value, meta) => {
						const recordIndex = meta.currentTableData[meta.rowIndex]?.index;
						const record = recordIndex !== undefined && reportsList[recordIndex];
						const player_id = record?.parameters?.player_id;
						return <div>{player_id}</div>;
					},
				},
			});
		}

		return columns.filter(column => !!column); // Filter out falsy values
	}, [report, reportsList]);

	useEffect(() => {
		setColumns(preparedColumns);
	}, [preparedColumns]);

	const changeEvent = e => {
		setPlayerID(e.target.value);
	};

	useEffect(() => {
		if (player_id) setPlayerID(player_id);
		else setPlayerID("");
	}, [player_id, slug]);

	return slug ? (
		report?.slug === "scheduled-reports" ? (
			<ScheduledReports />
		) : (
			<ThemeProvider theme={getMuiTheme()}>
				<HelmetLayout titleProps={"Reports"}>
					<div className="report-container">
						<div className={report?.inputText || report?.customInput ? "mb-3 report-menu" : "mb-3 report-menu report-menu-customized"}>
							{report?.dropdown?.length > 0 &&
								report?.dropdown?.map(dropdown => (
									<div className="report-dropdown" key={dropdown?.id}>
										{dropdown?.multiple ? (
											<div className="row wrapper_customized_modal">
												<MultiSelect
													value={dropdownData[dropdown?.id]}
													options={dropdownOptions[dropdown?.id]}
													optionLabel="label"
													selectAllLabel="Select All"
													maxSelectedLabels={1}
													placeholder={dropdown?.placeholder}
													onChange={e => setDropdownData({ ...dropdownData, [dropdown?.id]: e?.value })}
												/>
											</div>
										) : (
											<GeneralDropdown
												object={{
													value: dropdownData[dropdown?.id],
													options: dropdownOptions[dropdown?.id],
													defaultPlaceholder: dropdown?.placeholder,
												}}
												handleChange={e => {
													setDropdownData({ ...dropdownData, [dropdown?.id]: e?.id });
												}}
											/>
										)}
									</div>
								))}

							{report?.singleDate && (
								<GeneralDatePicker
									object={{
										id: "name",
										wrapperCustomized: "customized_date_reports_container",
										value: dateToReports,
										titleClass: "customized_date_title_reports",
										deleteEvent: report?.periodOptional,
										defaultPlaceholder: report?.periodOptional ? "Optional" : moment().format("DD-MM-YYYY"),
									}}
									handleChange={e => {
										setDateToReports(e);
									}}
								/>
							)}

							{report?.period && !report?.singleDate && (
								<div
									className={`position-relative promotion-inputs report-date
                                    ${report?.inputText || report?.customInput ? " report-date-customized" : ""}`}
								>
									<Flatpickr
										value={dateRange}
										options={{
											onOpen: onInputClick,
											mode: "range",
											dateFormat: "d M Y",
											maxDate: minMaxDate?.maxDate,
											minDate: minMaxDate?.minDate,
										}}
										// onValueUpdate={updateValue}
										onChange={onDateChange}
										placeholder={
											report?.periodOptional ? "Optional" : `${defaultDate.format("DD MMM YYYY")} - ${moment(new Date()).format("DD MMM YYYY")}`
										}
										onReady={(range, value, flatpickr) => {
											flatpickrRef.current = flatpickr;
										}}
									/>
									<CalendarIcon className="calendar" />
									{dateRange.length === 2 && <XCloseIcon className="close-icon-black" onClick={resetDate} />}
								</div>
							)}

							<div className={report?.inputText || report?.customInput ? "customized-filter-reports" : "d-flex"}>
								{report?.inputText && (
									<div className={report?.inputText && !report?.customInput ? "label_in_reports_customized" : "label_in_reports"}>
										{report?.inputText} ID
										<input value={playerID} onChange={changeEvent} className="ms-2 px-2" />
									</div>
								)}

								{report?.customInput && (
									<div className={"label_in_reports"}>
										{capitalizeText(report?.customInput)}
										<input value={customInput} onChange={e => setCustomInput(e.target.value)} className="ms-2 px-2" />
									</div>
								)}

								<div className={report?.inputText ? "filter-button-customized" : ""}>
									<FilterButton
										name={generateReportIsLoading ? <Spinner animation="border" size="sm" /> : "Run Report"}
										onClick={generateReportHandler}
										className={report?.inputText ? "customized-filter-button" : "filter-button-reports"}
									/>
								</div>
							</div>
						</div>
						<div className="cms-page p-0">
							<MUIDataTable
								columns={columns}
								data={reportsList}
								options={{
									...tableBarSettings,
									selectableRows: "none",
									elevation: 0,
									onChangePage(page) {
										setCurrentPage(page);
									},
									onChangeRowsPerPage(number) {
										setRowPerPage(number);
									},
									textLabels: {
										body: {
											noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
										},
									},
									page: currentPage,
									responsive: tableResponsive,
									rowsPerPage: rowPerPage,
									rowsPerPageOptions: tableRowsPerPage,
								}}
								extraButtons={tableExtraButtons}
							/>
						</div>
					</div>
				</HelmetLayout>
			</ThemeProvider>
		)
	) : (
		<LastReportsGenerated />
	);
};

export default Reports;
