export const capitalizeText = str => {
	if (str) {
		let words = [str];
		if (str?.includes("_")) words = str?.split("_");
		else if (str?.includes(" ")) words = str?.split(" ");

		const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
		const capitalizedStr = capitalizedWords.join(" ");
		return capitalizedStr;
	}
};
