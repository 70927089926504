import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useGenerateBetslip from "hooks/betslip";

import { XCloseIcon, PriceBoost, LightArrowDownIcon } from "utils/icons";
import { getOdds } from "utils/global";
import useOutsideClick from "hooks/useOutsideClick";

const TelebetSingle = ({ row, user, sportLadders = [] }) => {
	const dropdownRef = useRef(null);
	const telebetBets = useSelector(state => state.Telebet.telebetBets);

	const [value, setValue] = useState("");
	const [isEW, setIsEW] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const isRacing = row.sport_slug === "horseracing" || row.sport_slug === "greyhoundracing";

	const generateBetslip = useGenerateBetslip();

	const removeSingle = row => {
		let tmp = { ...telebetBets };

		tmp.singles = tmp.singles?.filter(single => single?.bet_id !== row?.bet_id);
		generateBetslip(tmp);
	};

	const onChange = value => {
		setValue(value);

		const tmp = { ...telebetBets };

		tmp.singles.forEach(element => {
			if (element.bet_id === row?.bet_id) {
				element.stake = value;
			}
		});

		generateBetslip(tmp, 300);
	};

	const onEwChange = () => {
		const tmp = { ...telebetBets };

		tmp.singles.forEach(element => {
			if (element.bet_id === row.bet_id && isRacing) {
				element.each_way = !isEW;
				element.starting_price = row.starting_price;
			}
		});

		setIsEW(!isEW);
		generateBetslip(tmp, null);
	};

	useEffect(() => {
		setValue(row.stake);
	}, [row?.stake]);

	useEffect(() => {
		setIsEW(row?.each_way);
	}, [row?.each_way]);

	const toggleDropdown = () => {
		setIsOpen(prev => !prev);
	};

	const handleOptionClick = (e, option) => {
		e.stopPropagation();
		setIsOpen(false);
		const tmp = { ...telebetBets };

		tmp.singles.forEach(element => {
			if (element.bet_id === row.bet_id) {
				element.odds_decimal = option?.in_decimal;
				element.odds_fractional = option?.in_fraction;
			}
		});

		generateBetslip(tmp, 300);
	};

	useOutsideClick(dropdownRef, () => setIsOpen(false));

	return (
		<div>
			<div className="telebet-betslip-data">
				<div className="selected-betslip-event">
					<div className="selected-betslip-event-header d-flex justify-content-between">
						<span className="selected-betslip-selection-name">{row?.name}</span>
						<XCloseIcon fill={"#2E3A49"} onClick={() => removeSingle(row)} />
					</div>
					<span className="selected-betslip-market-name">{row?.description}</span>
					<span className="selected-betslip-match-name">{row?.match_name}</span>
					<div className="selected-betslip-amounts">
						<div className="d-flex">
							<input
								type="number"
								placeholder="0.00"
								className="selected-betslip-input"
								value={value || ""}
								onChange={e => onChange(e?.target?.value)}
								min={0}
							/>
							<div className={`selected-betslip-odd cursor-pointer ${row?.allow_each_way ? "rounded-0" : ""}`} onClick={toggleDropdown}>
								<div className="d-flex align-items-center">
									{getOdds({ decimal: row?.odds_decimal, fractional: row?.odds_fractional, sport_slug: row?.sport_slug }, user?.odds_display, true)}
									{row?.price_boosted_enabled && !row?.starting_price && <PriceBoost className="selected" />}
								</div>

								<LightArrowDownIcon />

								<div className={`selected-betslip-odd-dropdown-menu ${isOpen ? "open" : ""}`} ref={dropdownRef}>
									{sportLadders.map(option => {
										const optionFormat = user.odds_display == "fractional" ? option.in_fraction : option.in_decimal;
										return (
											<div key={option.id} className="selected-betslip-odd-dropdown-item" onClick={e => handleOptionClick(e, option, optionFormat)}>
												{optionFormat}
											</div>
										);
									})}
								</div>
							</div>

							{row?.allow_each_way && (
								<div className="betslip-input-item-each_way">
									<span>EW</span>
									<input type="checkbox" onChange={onEwChange} checked={isEW} />
								</div>
							)}
						</div>
						<div className="selected-betslip-returns">Returns: {row?.starting_price ? "?" : row?.payout || "0.00"}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TelebetSingle;
