import PropTypes from "prop-types";
import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import logoDark from "../../assets/images/sp-logo-dark.png";
import { platformName } from "utils/constants";

const Register = props => {
	// handleValidSubmit
	const handleValidSubmit = (event, values) => {
		props.registerUser(values);
	};

	useEffect(() => {
		props.apiError("");
		localStorage.removeItem("user");
		localStorage.removeItem("gsData");
	}, []);

	return (
		<React.Fragment>
			<MetaTags>
				<title>Register | CMS | {platformName}</title>
			</MetaTags>

			<div className="account-pages my-0 pt-sm-5 my-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5}>
							<Card className="overflow-hidden">
								<CardBody className="pt-0">
									<h3 className="text-center mt-5 mb-4">
										<Link to="/" className="d-block auth-logo">
											<img src={logoDark} alt="" height="30" className="auth-logo-dark" />
										</Link>
									</h3>
									<div className="p-3">
										<h4 className="text-muted font-size-18 mb-1 text-center">Register with your email!</h4>
										<AvForm
											className="form-horizontal mt-4"
											onValidSubmit={(e, v) => {
												handleValidSubmit(e, v);
											}}
										>
											{props.user && props.user ? <Alert color="success">Register User Successfully</Alert> : null}

											{props.registrationError && props.registrationError ? (
												<Alert color="danger">{props.registrationError}</Alert>
											) : null}

											<div className="mb-3">
												<AvField
													id="email"
													name="email"
													label="Email"
													className="form-control"
													placeholder="Enter email"
													type="email"
													required
												/>
											</div>
											<div className="mb-3 row mt-4">
												<div className="col-12 text-end">
													<button className="btn btn-primary w-md waves-effect waves-light" type="submit">
														Register
													</button>
												</div>
											</div>
										</AvForm>
									</div>
								</CardBody>
							</Card>
							<div className="mt-5 text-center">
								<p>
									Already have an account ?
									<Link to="/login" className="text-primary">
										Login
									</Link>
								</p>
								<p>
									© {new Date().getFullYear()} {platformName} <span className="d-none d-sm-inline-block"></span>
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

Register.propTypes = {
	registerUser: PropTypes.func,
	registerUserFailed: PropTypes.func,
	registrationError: PropTypes.any,
	user: PropTypes.any,
};

const mapStatetoProps = state => {
	const { user, registrationError, loading } = state.Account;
	return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
	registerUser,
	apiError,
	registerUserFailed,
})(Register);
