import { ADD_REPORT_TO_FAVORITE, REMOVE_REPORT_FROM_FAVORITE } from "./actionTypes";

const INIT_STATE = {
	favorites: [],
};

const Report = (state = INIT_STATE, action) => {
	switch (action.type) {
		case ADD_REPORT_TO_FAVORITE:
			return {
				...state,
				favorites: [...state.favorites, action.payload],
			};

		case REMOVE_REPORT_FROM_FAVORITE:
			return {
				...state,
				favorites: state.favorites.filter(favorite => favorite !== action.payload),
			};
		default:
			return state;
	}
};

export default Report;
