import React, { useState, useEffect } from "react";
import InfoIconBlue from "../../../assets/images/infoIconBlue.svg";
import { toFixedFloat } from "../BetTicketHelpers";
import { ThousandSeperated } from "hooks/General";
import { AddFreeCashLogo } from "utils/icons";
import { useSelector } from "react-redux";
import useGenerateBetslip from "hooks/betslip";

import "./BetReferralItem.scss";

const AllSingles = ({ disabled, useFreebet, setHasChanged }) => {
	const itemType = "Singles";

	const SelectedBetReferral = useSelector(state => state.SelectedBetReferral.selectedBetReferral);
	const betSlip = SelectedBetReferral?.bet_slip;

	const allowEachWay = betSlip?.singles.some(bet => bet.allow_each_way == true);
	const enableIsEW = betSlip?.singles.filter(bet => bet.allow_each_way == true).every(bet => bet.each_way == true);

	const generateBetslip = useGenerateBetslip();

	const [isEW, setIsEW] = useState(false);

	const onChange = e => {
		const value = e.target.value;

		const tmp = { ...betSlip };

		tmp.singles.forEach(element => {
			element.stake = value > 0 ? value : "";
		});

		tmp.action = "check";

		setHasChanged(true);

		generateBetslip(tmp, null, true);
	};

	const onEwChange = () => {
		const tmp = { ...betSlip };

		tmp.singles.forEach(element => {
			if (element?.allow_each_way) {
				element.each_way = !isEW;
			}
		});

		setHasChanged(true);

		setIsEW(!isEW);
		generateBetslip(tmp, null, true);
	};

	useEffect(() => {
		setIsEW(enableIsEW);
	}, [enableIsEW]);

	const returnsValue = Object.values(betSlip?.singles).reduce((a, v) => a + toFixedFloat(v.payout), 0);

	return (
		<div className="bet-referral-singles-wrapper">
			<div className="bet-referral-singles-wrapper_inner">
				<div className="bet-referral-singles">
					<img src={InfoIconBlue} alt="info" />
					{`Singles (x${betSlip?.singles?.length})`}
				</div>
				<div className="bet-referral-singles-input">
					<div className="bet-referral-item-input-block">
						<input
							id={itemType}
							type="number"
							className="bet-referral-item-input bet-referral-item-input-round"
							name={itemType}
							// defaultValue={toFixedFloat(totalStakes)}
							disabled={disabled}
							min={0}
							onChange={onChange}
						/>

						{allowEachWay && (
							<div className="bet-referral-item-each_way each_way-combinations">
								<span>EW</span>
								<input type="checkbox" onChange={onEwChange} checked={isEW} />
							</div>
						)}
					</div>
					<div>
						<div className={`bet-referral-item-returns ${useFreebet ? "justify-content-between" : ""}`}>
							{useFreebet && <AddFreeCashLogo />} Returns:&nbsp;{ThousandSeperated(returnsValue)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AllSingles;
