const getKYCStatusHTML = status => {
	switch (status) {
		case "init":
			return `<span className="badge badge-sports-yellow"> Started </span>`;
		case "not_started":
			return `<span className="badge badge-sports-gray"> Not Started </span>`;
		case "verified":
			return `<span className="badge badge-sports-green"> Verified </span>`;
		case "pending":
			return `<span class="badge badge-sports-yellow"> Pending </span>`;
		case "rejected":
			return `<span class="badge badge-sports-red"> Rejected </span>`;
		case "suspended":
			return `<span class="badge badge-sports-red"> Suspended </span>`;
		default:
			return `<span class="badge badge-sports-default"> ${status} </span>`;
	}
};

const getAccountStatusHTML = status => {
	switch (status) {
		case "suspended":
			return `<span class="badge badge-sports-red"> Suspended </span>`;
		case "pending":
			return `<span class="badge badge-sports-yellow">Pending</span>`;
		case "closed":
			return `<span class="badge badge-sports-gray"> Closed </span>`;
		case "locked":
			return `<span className="badge badge-sports-yellow"> Locked </span>`;
		default:
			return `<span class="badge badge-sports-green"> Active </span>`;
	}
};

module.exports = {
	getKYCStatusHTML,
	getAccountStatusHTML,
};
