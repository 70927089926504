import React from "react";
import { XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { GeneralInput, SportInput, TypeCompetitorsInput } from "components/GeneralComponents/CustomInputs";
import { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import getSportsData from "services/getSportsData";

export const ShowEditCompetitors = ({
	setShowEditModal,
	dataChanged,
	setDataChanged,
	name,
	type,
	sport,
	editClicked,
	competitorsId,
	setCompetitorsName,
	setCompetitorsType,
	setSportType,
	filterCompetitors,
	setEditClicked,
}) => {
	const [sportsData, setSportsData] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const handleShowOptions = type => {
		if (type === "hidden") {
			console.log("hidden");
		}
	};

	const getSports = () => {
		getSportsData("all").then(response => {
			setSportsData(response);
		});
	};

	const editCompetitors = () => {
		setLoading(true);
		let payload = {
			id: competitorsId,
			name: name,
			sport_id: sport,
			type: type,
		};
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.competitor_edit, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				filterCompetitors();
			})
			.finally(() => {
				setLoading(false);
				setEditClicked(false);
			});
	};

	useEffect(() => {
		getSports();
	}, []);

	return (
		<>
			{editClicked && (
				<div className="promotion-container">
					<div className="promotion-header">
						<span>Edit Competitor</span>
						<XCloseIcon onClick={() => setShowEditModal(false)} className={"xclose-icon"} />
					</div>
					<div className="promotion-body">
						<TopBarComponent
							saveHandler={() => editCompetitors()}
							dataChanged={dataChanged}
							loading={loading}
							onClose={() => setShowEditModal(false)}
						/>
						<div className="promotion-elements row m-0">
							<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
								<GeneralInput
									onChange={e => {
										handleDataChanged();
										setCompetitorsName(e.target.value);
									}}
									object={{
										name: "Name",
										value: name,
									}}
								/>

								<SportInput
									sportsData={sportsData}
									type="competitor"
									data={sport}
									setData={setSportType}
									handleShowOptions={() => {
										handleShowOptions("hidden");
									}}
									handleDataChanged={handleDataChanged}
									otherRowClass
								/>
								<TypeCompetitorsInput
									data={type}
									setData={setCompetitorsType}
									handleShowOptions={() => {
										handleShowOptions("hidden");
									}}
									type="competitor"
									handleDataChanged={handleDataChanged}
									otherRowClass
								/>
							</div>
							<div className="promotion-right col-12 col-lg-6"></div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
