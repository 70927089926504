export const getPeriodCompetitionFilterColumns = ({ competitions, sports, handleFilterSportChange }) => {
	const competitionsData = competitions ? JSON.stringify(competitions) : [];
	const sportsData = sports ? JSON.stringify(sports) : [];
	let sport_id_ = "";
	return [
		{
			name: "Sport",
			type: "select",
			value: "sport_id",
			data: sportsData,
			optionKey: "id",
			optionName: "name",
			handler: () => handleFilterSportChange(sport_id_),
			hasHandler: true,
		},
		{
			name: "Competition",
			type: "select",
			value: "competition_id",
			data: competitionsData,
			optionKey: "id",
			optionName: "name",
			hasHandler: false,
		},
	];
};
