import { formatToLocalDatetime } from "hooks/General";
export const getResponsibleGamblingRealityCheckPage = () => {
	return [
		{
			name: "createdAt",
			label: "ACTION DATE",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: value => {
					return formatToLocalDatetime(value, "YYYY-MM-DD HH:mm");
				},
				setCellProps: () => ({ style: { width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
		{
			name: "old_value",
			label: "OLD AMOUNT",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
		{
			name: "new_value",
			label: "NEW AMOUNT",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
		{
			name: "by",
			label: "EDITED BY",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
	];
};
