import React from "react";
import { AddButton } from "components/GeneralComponents/AddButton";
import { Spinner } from "react-bootstrap";
import { formatToLocalDatetime } from "hooks/General";

export const getPushNotificationTableColumns = ({ records, stopLoading, sentStoppedNotification }) => {
	return [
		{
			name: "id",
			label: "ID",
			className: "msg-id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "50px" }, className: "max-button" }),
			},
		},
		{
			name: "message",
			label: "MESSAGE",
			className: "msg-message",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];

					return (
						<span>
							<b> {record.title} </b>
							<br />
							{record.message}
						</span>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "170px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "170px" }, className: "max-button" }),
			},
		},
		{
			name: "filter_by",
			label: "DISTRIBUTION",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return record.filter_by || "-";
				},
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "100px" }, className: "max-button" }),
			},
		},
		{
			name: "platform",
			label: "PLATFORM",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return record.platform || "-";
				},
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "100px" }, className: "max-button" }),
			},
		},
		{
			name: "device_reach",
			label: "REACH",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "100px" }, className: "max-button" }),
			},
		},
		{
			name: "sent_at",
			label: "SENT (UTC)",
			className: "msg-message",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return <span>{formatToLocalDatetime(record.sent_at)}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "100px" }, className: "max-button" }),
			},
		},
		{
			name: "processing",
			label: "PROCESSING",
			className: "msg-message",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					let btnText = record.sentStopped == 0 ? "Stop" : "Start";
					let btnTextStyle = false;
					if (btnText == "Stop") {
						btnTextStyle = {
							background: "#b71c1c",
							border: "none",
							minWidth: "auto",
							width: "90px",
							height: "24px",
						};
					} else {
						btnTextStyle = {
							background: "#008000",
							border: "none",
							minWidth: "auto",
							width: "90px",
							height: "24px",
						};
					}
					if (record.sentFinished == 0) {
						return (
							<div className="pushNotification-buttons">
								<AddButton style={btnTextStyle} onClick={e => sentStoppedNotification(record.id, record.sentStopped, e)} name={btnText}>
									{stopLoading[record.id] ? <Spinner animation="border" size="sm"></Spinner> : btnText}
								</AddButton>
							</div>
						);
					} else {
						return <span className="badge badge-sports-published">Completed</span>;
					}
				},
				setCellProps: () => ({ style: { textAlign: "left" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
	];
};
