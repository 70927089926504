import React, { useEffect, useState, useMemo } from "react";
import { MetaTags } from "react-meta-tags";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "store/actions";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { DarkArrowLeft } from "utils/icons";
import { tableResponsive, tableBarSettings } from "utils/tableSettings";
import { getliabilityTableColumns } from "./liabilityTableColumns";
import MUIDataTable from "mui-datatables";
import { platformName } from "utils/constants";
import { Spinner } from "reactstrap";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import moment from "moment";

import "./liabilities.scss";

const betTypeOptions = [
	{ id: "single", label: "Singles" },
	{ id: "multiple", label: "Multiples" },
];

const Liability = props => {
	const id = props.match.params?.id;
	// const sportSlug = props.match.params?.slug;
	const [data, setData] = useState([]);
	const [matchName, setMatchName] = useState("");
	const [loading, setLoading] = useState(false);
	const [betType, setBetType] = useState("single");
	const [tableData, setTableData] = useState([]);

	const showTableData = useMemo(() => {
		return tableData?.filter(item => item?.show);
	}, [tableData]);

	const getLiabilityData = bet_type => {
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_LIABILITY + `?event_id=${id}&bet_type=${bet_type}`, resolve, reject, true);
		})
			.then(response => {
				setMatchName(response?.data?.event_name || "");
				setData(response?.data);
				formatData(response?.data?.data);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const formatData = data => {
		const formatedData = data.flatMap((market, index) => {
			return [
				{ ...market, isOpen: index == 0, show: true },
				...market.selections.map(selection => ({ ...selection, market_id: market?.market_id, show: index == 0 })),
			];
		});

		setTableData(formatedData);
	};

	useEffect(() => {
		if (betType) getLiabilityData(betType);
	}, [betType]);

	const onToggle = record => {
		const isOpen = record?.isOpen;
		const filteredData = tableData
			.map(item => (item?.market_id == record?.market_id && !!item?.selection_name ? { ...item, show: !isOpen } : item))
			.map(item => (item?.market_name == record?.market_name ? { ...item, isOpen: !isOpen } : item));

		setTableData(filteredData);
	};

	const liabilityTableColumns = getliabilityTableColumns({
		isSingle: betType == "single",
		data: showTableData,
		onToggle,
		isRacing: data?.show_type == "racing",
	});

	return (
		<div>
			<MetaTags>
				<title>Liabilities - {platformName}</title>
			</MetaTags>
			<div className="cms-page pt-0 cms-table-page liability-page">
				{loading ? (
					<div className="text-center">
						<Spinner animation="border" size="lg" />
					</div>
				) : (
					<>
						<div className="col-12 d-flex align-items-center justify-content-between mb-4 mt-2">
							<div className="d-flex align-items-center">
								<DarkArrowLeft onClick={() => props.history.push("/liabilities")} />
								<span className="arrow-title">{matchName}</span>
							</div>

							<div className="d-flex align-items-center justify-content-end gap-3 flex-grow-1">
								<div className={`event-start ${data?.bets_resulted ? "is-resulted" : ""}`}>
									{data?.bets_resulted ? "RESULTED" : moment(data?.event_start_dt).format("DD MMM YY - HH:mm")}
								</div>
								<div className="bet_type-dropdown">
									<GeneralDropdown
										object={{
											options: betTypeOptions,
											defaultPlaceholder: "Bet Types",
											value: betType,
										}}
										handleChange={e => {
											setBetType(e?.id);
										}}
									/>
								</div>
							</div>
						</div>

						<MUIDataTable
							className="mb-5"
							columns={liabilityTableColumns}
							data={showTableData}
							options={{
								...tableBarSettings,
								selectableRows: "none",
								elevation: 0,
								textLabels: {
									body: {
										noMatch: " No data to display!",
									},
								},
								pagination: false,
								responsive: tableResponsive,
								setRowProps: (row, dataIndex, rowIndex) => {
									const isMarket = showTableData[rowIndex]?.market_name;
									const isToggle = showTableData[rowIndex]?.market_name && showTableData[rowIndex]?.isOpen;

									return {
										className: `${isMarket ? "gray row-market" : "row-selection"} ${isToggle ? "rotate-arrow" : ""}`,
									};
								},
							}}
							// extraButtons={tableExtraButtons}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default connect(null, { setBreadcrumbItems })(Liability);
