import React, { useEffect, useState } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

function CasinoSettings() {
	const [data, setData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [unchagedData, setUnchangedData] = useState([]);

	let fields = [
		{ type: "switch", name: "Global Casino", fieldName: "enabled" },
		{ type: "dropdown", name: "Blocked Countries", fieldName: "restricted_countries", optionsType: "country", multiple: true, bgWhite: true },
	];

	useEffect(() => {
		getCasinoSettings();
	}, []);

	//Get Casino settings
	const getCasinoSettings = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.trader_options + "?type=casino", resolve, reject, true);
		})
			.then(response => {
				let data = response?.data;

				const countries = JSON.parse(data.restricted_countries);
				setData({ ...data, restricted_countries: countries });
				setUnchangedData(data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	// //Edit Casino settings
	const editCasinoSettings = () => {
		let payload = data;
		delete payload?.bet_types_static;
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.trader_options_edit, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const discardData = () => {
		setData(unchagedData);
		setDataChanged(false);
	};

	return (
		<HelmetLayout titleProps={"Casino"}>
			<TopBarComponent
				classname="special_top_component"
				saveHandler={editCasinoSettings}
				dataChanged={dataChanged}
				discardHandler={discardData}
				loading={isDataLoading}
			/>
			{isDataLoading ? (
				<LoadingSpinner className={"pt-5"} />
			) : (
				<div className="cms-page row">
					<div className=" col-12 trader-form">
						<AllComponentsLoop fields={fields} data={data} setData={setData} setDataChanged={setDataChanged} />
					</div>
				</div>
			)}
		</HelmetLayout>
	);
}

export default CasinoSettings;
