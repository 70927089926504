export const landingPageInitEditor = {
	height: 650,
	menubar: false,
	visual: false,
	plugins: [
		"advlist autolink lists link image charmap print preview anchor",
		"searchreplace visualblocks code fullscreen",
		"insertdatetime media paste code help wordcount fullpage",
	],
	toolbar:
		"undo redo | blocks | " +
		"bold italic forecolor link | alignleft aligncenter " +
		"alignright alignjustify | bullist numlist outdent indent | " +
		"removeformat | help | code",
	content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
};
