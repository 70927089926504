import React from "react";
import { DeleteIconButton, EditButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "assets/images/delete.svg";

export const getDefaultLeaguesTableColumns = ({
	records,
	setDefaultLeaguesId,
	getDefaultLeaguesData,
	setShowDeleteModal,
	setDeleteId,
	orderSpinning,
	isUserReadOnly,
}) => {
	return [
		{
			name: "region_name",
			label: "REGION",
			className: "region_name",
			align: "left",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "20px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "20px" }, className: "max-button" }),
			},
		},
		{
			name: "sport_type",
			label: "SPORT",
			className: "default_league_sport_type",
			align: "left",
			options: {
				setCellProps: () => ({ style: { textTransform: "capitalize", textAlign: "left", maxWidth: "20px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "20px" }, className: "max-button" }),
			},
		},
		{
			name: "competition_name",
			label: "LEAGUES",
			className: "competition_name",
			align: "left",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "40px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "40px" }, className: "max-button" }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			options: {
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<EditButton
								onClick={() => {
									setDefaultLeaguesId(record);
									getDefaultLeaguesData(`?sport_slug=${record.sport_id}&region_id=${record.region_id}`, "order", record?.id);
								}}
								loading={record?.id === orderSpinning}
							></EditButton>
							{!isUserReadOnly && (
								<DeleteIconButton
									deleteIcon={deleteIcon}
									onClick={() => {
										setShowDeleteModal(true);
										setDeleteId(record.id);
									}}
									className={"delete-default-league-icon"}
								/>
							)}
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "700px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "700px" }, className: "max-button" }),
			},
		},
	];
};
