import React from "react";
import { EditIconButton, LanguageShortcuts } from "components/GeneralComponents/AddButton";

export const getMessageCodesTableColumns = ({ records, handlerEdit }) => {
	return [
		{
			name: "code",
			label: "CODE",
			className: "",
			align: "left",
			options: {
				setCellProps: () => ({ style: { minWidth: "100px", textAlign: "left" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "message_en",
			label: "MESSAGE",
			className: "",
			align: "left",
			options: {
				setCellProps: () => ({ style: { minWidth: "400px", textAlign: "left" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "400px" } }),
			},
		},
		{
			name: "language",
			label: "LANGUAGE",
			className: "language text-uppercase",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const languages = record.language.split(",");

					return (
						<div className="d-flex gap-1">
							{languages?.map(language => (
								<LanguageShortcuts key={language} language={language.toUpperCase()} />
							))}
						</div>
					);
				},
				setCellProps: () => ({ style: { minWidth: "100px", textAlign: "left" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "",
			align: "right",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex justify-content-end">
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => handlerEdit(record)} />
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { minWidth: "100px", textAlign: "right", marginRight: "20px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
	];
};
