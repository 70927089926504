import React, { useState } from "react";
import { InfoIconHelper, XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { useDispatch } from "react-redux";
import { setBackdrop } from "store/Backdrop/actions";
import InformationPageHelper from "../InformationPageHelper";
import { UserHelpInformation } from "../HelpPagesForSettings/HelpPages";

export const ShowAddUserListModal = ({ setShowAddModal, addUsers, data, setData, dataChanged, setDataChanged, loading }) => {
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();
	const userFields = [
		{
			type: "input",
			name: "First Name",
			fieldName: "firstName",
			required: true,
			textUpperCase: true,
		},
		{
			type: "input",
			name: "Last Name",
			fieldName: "lastName",
			required: true,
			textUpperCase: true,
		},
		{
			type: "dropdown",
			name: "Role",
			fieldName: "role",
			required: true,
			textUpperCase: true,
			optionsType: "role",
		},
		{ type: "input", name: "Email", fieldName: "email", required: true },
		{
			type: "dropdown",
			name: "Timezone",
			fieldName: "timezone_name",
			required: true,
			optionsType: "timezone",
		},
		{ type: "switch", name: "Enabled 2FA", fieldName: "status" },
	];

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	return (
		<>
			<div className="promotion-container">
				<div className="promotion-header">
					<span>CMS USER</span>
					<XCloseIcon onClick={() => setShowAddModal(false)} className={"xclose-icon"} />
				</div>
				<div className="promotion-body">
					<TopBarComponent saveHandler={() => addUsers(userFields)} dataChanged={dataChanged} loading={loading} onClose={() => setShowAddModal(false)} />
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
							<AllComponentsLoop fields={userFields} data={data} setData={setData} setDataChanged={setDataChanged} />
						</div>
						<div className="promotion-right col-12 col-lg-6"></div>
					</div>
				</div>
			</div>
			{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<UserHelpInformation />
				</InformationPageHelper>
			)}
		</>
	);
};
