import React from "react";
import { Pie, PieChart } from "recharts";

const ProgressBar = ({ used, amount }) => {
	const usedPercent = amount > 0 && used ? ((used * 100) / amount).toFixed(0) : 0;
	const data = [
		{
			name: "start",
			value: +used,
			cornerRadius: 100,
		},
		{
			name: "end",
			value: +amount - +used,
		},
	];

	return (
		<div className="pieChart">
			<PieChart width={90} height={90}>
				<Pie data={data} dataKey="value" nameKey="name" startAngle={90} endAngle={-270} innerRadius={37} outerRadius={46} />
			</PieChart>
			<div className="limitChartPercentWrapper">
				<div className="limitChartPercent">
					<span className="chartPercent">{usedPercent}%</span>
				</div>
			</div>
		</div>
	);
};

export default ProgressBar;
