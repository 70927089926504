import React, { useEffect, useRef, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import {
	EndDateInput,
	HideEventsInput,
	ImageInput,
	NeverExpireInput,
	SwitchInput,
	// RacingCompetition,
	RacingSportInput,
	SpecificDaysInput,
	StartDateInput,
	GeneralInput,
	GeneralDropdown,
} from "components/GeneralComponents/CustomInputs";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { XCloseIcon } from "utils/icons";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import moment from "moment";
import { convertFormatDateInUtc, filterAndSortArray, getFormatDateByUserTimezone } from "utils/global";
import { languageToString } from "utils/global";
import { getCompetitionsDropdown } from "services/getCompetitionsDropdown";

const formatCompetitions = arr => arr.map(item => item.competition_id);

const formatedCompetitions = (arr, allCompetitions) =>
	allCompetitions.filter(({ id }) => arr.includes(id)).map(({ id, name }) => ({ id, competition_id: id, name }));

export const RacingWidgetPromotion = props => {
	const [loading, setLoading] = useState(false);
	const { editItem, title, handler, getRacingWidget, countires } = props;
	let details = null;
	if (editItem) {
		details = JSON.parse(editItem?.details);
	}

	const [racingWidgetData, setRacingWidgetData] = useState({
		checked: editItem ? editItem?.active : true,
		title: details ? details?.title : "",
		subtitle: details ? details?.subtitle : "",
		image: details ? details?.image : null,
		image_exist: editItem ? true : false,
		sport: details ? details?.sport : "",
		competitions: details ? formatCompetitions(details?.competitions) : [],
		hide_events: details ? details?.hide_events : false,
		startDate: editItem
			? getFormatDateByUserTimezone(editItem?.publish_start_date_time)
			: moment().startOf("day").add(1, "minute").format("YYYY-MM-DD HH:mm"),
		endDate: editItem ? getFormatDateByUserTimezone(editItem?.publish_stop_date_time) : "",
		neverExpire: editItem ? editItem?.never_expire : true,
		selected_days: details ? (details?.selected_days ? details?.selected_days : []) : [],
		specific_days: details ? details?.specific_days : false,
	});

	const [dataChanged, setDataChanged] = useState(false);
	const [allCompetitions, setAllCompetitions] = useState([]);

	const handleChange = (value, type) => {
		const competitions = racingWidgetData?.competitions;
		let newArray = [...competitions];

		if (value?.id == "all" && type === "add") {
			const allIds = allCompetitions?.filter(({ id }) => id !== "all").map(({ id }) => id);
			newArray = [...allIds];
		} else if (type === "add") {
			newArray.push(value?.id);
		} else if (type === "remove") {
			newArray = competitions.filter(c => c !== value?.id);
		}

		handleDataChanged();
		setRacingWidgetData({ ...racingWidgetData, competitions: newArray });
	};

	const addRacing = () => {
		let payload = {
			published: racingWidgetData?.checked ? "1" : "0",
			title: racingWidgetData?.title,
			subtitle: racingWidgetData?.subtitle,
			image: racingWidgetData?.image,
			image_exist: racingWidgetData?.image_exist,
			hide_events: racingWidgetData?.hide_events,
			never_expire: racingWidgetData?.neverExpire ? 1 : 0,
			specific_days: racingWidgetData?.specific_days ? 1 : 0,
			selected_days: racingWidgetData?.selected_days,
			start_date: convertFormatDateInUtc(racingWidgetData?.startDate),
			end_date: convertFormatDateInUtc(racingWidgetData?.endDate),
			call_to_action: racingWidgetData?.call_to_action,
			type: "racing_widget",
			name: "",
			country_code: countires ? countires : "all",
			sport: racingWidgetData?.sport,
			competitions: formatedCompetitions(racingWidgetData?.competitions, allCompetitions),
		};

		let language = languageToString(racingWidgetData, ["title_", "subtitle_"]);
		// Object.entries(racingWidgetData).forEach(([key, value]) => {
		// 	if (key.includes("title_") || key.includes("subtitle_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });

		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };
		setLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.put(`${apiUrls.ADD_RACING_WIDGET}`, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				getRacingWidget();
				handler();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const editRacing = () => {
		let payload = {
			published: racingWidgetData?.checked ? "1" : "0",
			title: racingWidgetData?.title,
			subtitle: racingWidgetData?.subtitle,
			image: racingWidgetData?.image,
			image_exist: racingWidgetData?.image_exist,
			hide_events: racingWidgetData?.hide_events,
			never_expire: racingWidgetData?.neverExpire ? 1 : 0,
			specific_days: racingWidgetData?.specific_days ? 1 : 0,
			selected_days: racingWidgetData?.selected_days,
			start_date: convertFormatDateInUtc(racingWidgetData?.startDate),
			end_date: convertFormatDateInUtc(racingWidgetData?.endDate),
			call_to_action: racingWidgetData?.call_to_action,
			type: "racing_widget",
			name: "",
			country_code: countires ? countires : "all",
			id: editItem?.id,
			sport: racingWidgetData?.sport,
			competitions: formatedCompetitions(racingWidgetData?.competitions, allCompetitions),
		};

		let language = languageToString(racingWidgetData, ["title_", "subtitle_"]);
		// Object.entries(racingWidgetData).forEach(([key, value]) => {
		// 	if (key.includes("title_") || key.includes("subtitle_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });

		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.EDIT_RACING_WIDGET, resolve, reject, payload, true);
		})
			.then(() => {
				getRacingWidget();
				handler();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	useEffect(() => {
		if (details) {
			let newData = { ...racingWidgetData };
			Object.entries(details).forEach(([key, value]) => {
				if (key.includes("subtitle_") || key.includes("title_")) {
					newData = { ...newData, [key]: value };
				}
			});
			setRacingWidgetData(newData);
		}
	}, []);

	const getCompetition = async (sport_slug = "") => {
		const data = await getCompetitionsDropdown(sport_slug);
		const filteredCompetitions = filterAndSortArray(data, "name");

		const allOptions = { id: "all", label: "Select All Options", name: "Select All Options" };

		setAllCompetitions([allOptions, ...filteredCompetitions]);
	};

	useEffect(() => {
		if (racingWidgetData?.sport) {
			getCompetition(racingWidgetData?.sport);
		}
	}, [racingWidgetData?.sport]);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editItem ? editRacing : addRacing} loading={loading} dataChanged={dataChanged} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AvForm onClick={() => !onFocus.current && handleShowOptions()}>
							<SwitchInput
								handleChange={() => {
									handleDataChanged();
									setRacingWidgetData({ ...racingWidgetData, checked: !racingWidgetData?.checked });
								}}
								object={{ fieldName: "published", value: racingWidgetData?.checked }}
							/>
							<GeneralInput
								object={{
									name: "Title",
									value: racingWidgetData.title,
									onFocus: () => handleShowOptions("language", "title"),
									onBlur: () => (onFocus.current = false),
									required: true,
								}}
								onChange={e => {
									handleDataChanged();
									setRacingWidgetData({ ...racingWidgetData, title: e.target.value });
								}}
							/>
							<GeneralInput
								object={{
									name: "Subtitle",
									value: racingWidgetData.subtitle,
									onFocus: () => handleShowOptions("language", "subtitle"),
									onBlur: () => (onFocus.current = false),
								}}
								onChange={e => {
									handleDataChanged();
									setRacingWidgetData({ ...racingWidgetData, subtitle: e.target.value });
								}}
							/>
							<ImageInput
								object={{
									name: "Header Banner",
									onFocus: () => handleShowOptions("image", true),
									onBlur: () => (onFocus.current = false),
									value: racingWidgetData?.image,
									active: showOptions.image,
									otherRowClass: true,
									fieldName: "image",
									data: racingWidgetData,
									setData: setRacingWidgetData,
									setDataChanged: setDataChanged,
									imageLibaryType: "description",
									dimensions: "(3480 x 230 px)",
								}}
							/>
							<RacingSportInput
								data={racingWidgetData}
								handleShowOptions={handleShowOptions}
								setData={setRacingWidgetData}
								handleDataChanged={handleDataChanged}
								otherRowClass
							/>
							{/* <RacingCompetition
								allCompetitions={allCompetitions}
								setAllCompetitions={setAllCompetitions}
								data={racingWidgetData}
								setData={setRacingWidgetData}
								handleDataChanged={handleDataChanged}
								otherRowClass
							/> */}

							<GeneralDropdown
								object={{
									name: "Competition",
									placeholder: "Multiple Selections",
									options: allCompetitions,
									value: racingWidgetData?.competitions,
									multiple: true,
									required: true,
								}}
								handleRemove={value => handleChange(value, "remove")}
								handleChange={value => handleChange(value, "add")}
							/>

							<HideEventsInput data={racingWidgetData} setData={setRacingWidgetData} handleDataChanged={handleDataChanged} otherRowClass />
							<StartDateInput data={racingWidgetData} setData={setRacingWidgetData} handleDataChanged={handleDataChanged} otherRowClass />
							<NeverExpireInput
								checked={racingWidgetData?.neverExpire}
								handleChange={() => {
									handleDataChanged();
									setRacingWidgetData({
										...racingWidgetData,
										neverExpire: !racingWidgetData?.neverExpire,
									});
								}}
								otherRowClass
							/>
							{!racingWidgetData?.neverExpire && (
								<EndDateInput
									data={racingWidgetData}
									setData={setRacingWidgetData}
									handleDataChanged={handleDataChanged}
									otherRowClass
									type="sport_widget"
								/>
							)}
							<SpecificDaysInput data={racingWidgetData} setData={setRacingWidgetData} handleDataChanged={handleDataChanged} otherRowClass />
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
						{showOptions.language && (
							<LanguageTitle data={racingWidgetData} setData={setRacingWidgetData} title={showOptions.language} handleDataChanged={handleDataChanged} />
						)}
						{showOptions.image && (
							<ImageLibrary
								data={racingWidgetData}
								setData={setRacingWidgetData}
								type="racing_widget"
								handleDataChanged={handleDataChanged}
								handleShowOptions={handleShowOptions}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
