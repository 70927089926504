import React, { useState } from "react";
import { connect, useSelector } from "react-redux";

import SearchFilter from "components/SearchFilter/searchFilter";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions";
import { getActivityLogTableColumns } from "./activityLogTableColumns";
import { getActivityLogFilterColumns } from "./activityLogFilterColumns";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { defaultRowPerPage, httpCodes } from "utils/constants";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { getActivityLogData } from "services/activity_log/getAcitivtyLogData";
import MUIDataTable from "mui-datatables";

const ActivityLog = () => {
	// const activityLogFilterData = useSelector(state => state.Login.searchFilterData.activity_log);
	const [rowPerPage, setRowPerPage] = useState(defaultRowPerPage);
	const [currentPage, setCurrentPage] = useState(0);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [records, setRecords] = useState([]);

	const countries = useSelector(state => state.Login.gsData.countries);

	const filterCompetitions = async activityLogFilterData => {
		let query = "";
		if (activityLogFilterData.date) {
			let created_date_start, created_date_end;
			if (activityLogFilterData.date.indexOf("to") > -1) {
				var created_date = activityLogFilterData.date.split("to");
				created_date_start = created_date[0].trim();
				created_date_end = created_date[1].trim();
			} else {
				created_date_start = activityLogFilterData.date.trim().split("&")[0];
				created_date_end = activityLogFilterData.date.trim().split("&")[1];
			}
			query = JSON.stringify({
				start_time: created_date_start,
				end_time: created_date_end,
			});
			if (activityLogFilterData.date == activityLogFilterColumns.find(({ value }) => value == "date")?.defaultValue) {
				query = "";
			}
		}
		const { player_id, activity_name, user_agent, aws_request_id, ip, http_code } = activityLogFilterData;
		let params = `?createdAt=${query}&player_id=${player_id}&activity_name=${activity_name}&user_agent=${user_agent}
		&aws_request_id=${aws_request_id}&x_forwarded_for=${ip}&
		response_http_code=${http_code}
	`;
		setCurrentPage(0);
		await getActivityLogData({ params, setIsDataLoading, setRecords });
	};
	let activityLogTableColumns = getActivityLogTableColumns({ records });
	let activityLogFilterColumns = getActivityLogFilterColumns({ countries, httpCodes });
	let page_name = "Activity Log";

	return (
		<HelmetLayout titleProps={"Activity Log"}>
			<div className="cms-page pt-0 no-footer-table">
				<SearchFilter
					columns={activityLogFilterColumns}
					page={"activity_log"}
					preSelectedType={"text"}
					preSelectedValue={"activity_name"}
					preSelectedName={"Activity Name"}
					filterHandler={filterCompetitions}
					filterLoading={isDataLoading}
					customizedFilter={true}
				/>
				<MUIDataTable
					columns={activityLogTableColumns}
					data={records}
					options={{
						...tableBarSettings,
						pagination: false,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: `${page_name}.csv` },
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};
export default connect(null, { setBreadcrumbItems })(ActivityLog);
