import { AvForm } from "availity-reactstrap-validation";
import { GeneralInput, ImageInput } from "components/GeneralComponents/CustomInputs";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useRef, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";
import { languageToString } from "utils/global";

export const ImageEdit = props => {
	const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;
	const { imageData, handler } = props;
	const [loading, setLoading] = useState(false);
	let alt_details = null;
	if (imageData?.alt_text) {
		alt_details = JSON.parse(imageData?.alt_text);
	}

	// const [showAltLanguage, setShowAltLanguage] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const [editData, setEditData] = useState({
		...alt_details,
		id: imageData?.id,
		image: imageData?.image,
	});

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};
	const editImage = () => {
		setLoading(true);
		let payload = {
			id: editData?.id,
			alt_text: editData.alt_text,
		};

		let language = languageToString(editData, ["alt_text", "alt_text_"]);
		// Object.entries(editData).forEach(([key, value]) => {
		// 	if (key.includes("alt_text") || key.includes("alt_text_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });
		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, alt_text: `{${language}}` };

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.edit_image, resolve, reject, payload, true);
		})
			.then(() => {
				handler(payload);
				setDataChanged(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const fullUrl = aws_s3_url + "/media/" + editData.image;

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Media Library</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editImage} dataChanged={dataChanged} loading={loading} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AvForm onClick={() => !onFocus.current && handleShowOptions()} style={{ minHeight: "100%" }}>
							<ImageInput
								object={{
									name: "Media",
									value: editData?.image,
									dimensions: "(580 x 580 px)",
									hideRemoveButton: true,
								}}
							/>
							<GeneralInput
								onChange={e => {
									handleDataChanged();
									setEditData({
										...editData,
										alt_text: e.target.value,
									});
								}}
								onblur={() => handleShowOptions()}
								object={{
									name: "Alt Text",
									placeholder: "Alt Text",
									onFocus: () => handleShowOptions("language", "alt"),
									onBlur: () => (onFocus.current = false),
									value: editData.alt_text,
								}}
							/>
							<GeneralInput
								object={{
									name: "Image URL",
									placeholder: "Image URL",
									value: fullUrl,
									customLink: fullUrl,
									onFocus: () => (onFocus.current = false),
									onClickEvent: () => window.open(fullUrl, "_blank"),
									copyIcon: true,
									inputClassName: "general-input-link",
								}}
								onClick={() => {
									window.open(fullUrl, "_blank");
								}}
							/>
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6">
						{showOptions?.language && <LanguageTitle data={editData} setData={setEditData} title={"alt_text"} handleDataChanged={handleDataChanged} />}
					</div>
				</div>
			</div>
		</div>
	);
};
