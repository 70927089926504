import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import { checkForCheckSum } from "helpers/api_helper";
import xIcon from "../assets/images/x-icon.svg";

export async function setupMfa(resolve, reject, body) {
	let headers = {};

	headers["Authorization"] = `Bearer ${body.access_token}`;

	headers["Access-Control-Allow-Origin"] = "*";

	const deviceId = localStorage.getItem("device_id");

	if (deviceId) {
		headers["device-id"] = deviceId;
	}

	axios
		.post(apiUrls.new_main + "/api/v1/auth/setup-mfa", body, {
			headers,
		})
		.then(data => {
			const stop = checkForCheckSum(data);
			if (stop) return;

			resolve(data.data);
		})
		.catch(error => {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">{error?.response?.data?.message}</p>
				</>,
				{ className: "custom-toast" }
			);
			if (error?.response?.status === 403) {
				// Redirect to restriced
				// window.location.href = "/restricted";
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">{`You don't have access`}</p>{" "}
					</>,
					{ className: "custom-toast" }
				);
			} else {
				reject(error?.response?.data);
			}
		});
}

export async function verifyMfa(resolve, reject, body) {
	let headers = {};

	headers["Authorization"] = `Bearer ${body.access_token}`;

	headers["Access-Control-Allow-Origin"] = "*";

	const deviceId = localStorage.getItem("device_id");

	if (deviceId) {
		headers["device-id"] = deviceId;
	}

	axios
		.post(apiUrls.new_main + "/api/v1/auth/verify-mfa-token", body, {
			headers,
		})
		.then(data => {
			const stop = checkForCheckSum(data);
			if (stop) return;

			resolve(data.data);
		})
		.catch(error => {
			if (error?.response?.status === 403) {
				// Redirect to restriced
				// window.location.href = "/restricted";
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">{`You don't have access`}</p>{" "}
					</>,
					{ className: "custom-toast" }
				);
			} else {
				reject(error?.response?.data);
			}

			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">{error?.response?.data?.message}</p>
				</>,
				{ className: "custom-toast" }
			);
		});
}

export async function signWithMfa(resolve, reject, body) {
	let headers = {};

	headers["Access-Control-Allow-Origin"] = "*";

	const deviceId = localStorage.getItem("device_id");

	if (deviceId) {
		headers["device-id"] = deviceId;
	}

	axios
		.post(apiUrls.sign_verify_mfa_token, body, {
			headers,
		})
		.then(data => {
			const stop = checkForCheckSum(data);
			if (stop) return;

			resolve(data.data);
		})
		.catch(error => {
			if (error?.response?.status === 403) {
				// Redirect to restriced
				// window.location.href = "/restricted";
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">{`You don't have access`}</p>{" "}
					</>,
					{ className: "custom-toast" }
				);
			} else {
				reject(error?.response?.data);
			}

			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">{error?.response?.message || error?.response?.data?.message}</p>
				</>,
				{ className: "custom-toast" }
			);
		});
}

export async function signInFirst(resolve, reject, body) {
	let headers = {};
	headers["Access-Control-Allow-Origin"] = "*";

	const deviceId = localStorage.getItem("device_id");

	if (deviceId) {
		headers["device-id"] = deviceId;
	}

	axios
		.post(apiUrls.new_main + "/api/v1/auth/sign-in-first", body, {
			headers,
		})
		.then(data => {
			const stop = checkForCheckSum(data);
			if (stop) return;

			resolve(data.data);
		})
		.catch(error => {
			if (error?.response?.status === 403) {
				// Redirect to restriced
				// window.location.href = "/restricted";
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">{`You don't have access`}</p>{" "}
					</>,
					{ className: "custom-toast" }
				);
			} else {
				reject(error?.response?.data);
			}

			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">{error?.response?.message}</p>
				</>,
				{ className: "custom-toast" }
			);
		});
}
