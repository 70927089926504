// Import libraries
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { getMessageCodesTableColumns } from "./messageCodesTableColumns";
import { ShowEditMessageCodeModal } from "./showEditMessageCodeModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { CodesMSGTopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { platformName } from "utils/constants";
// import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const MessageCodes = props => {
	// States
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [editItem, setEditItem] = useState(null);
	const [existFilter, setExistFilter] = useState(false);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [records, setRecords] = useState([]);
	const breadcrumbItems = [{ title: "Message Codes", link: "/message_codes" }];

	const getMessageCodes = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_message_codes, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					const { data } = response;
					setRecords(data);
					setUnfilteredData(data);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		getMessageCodes();
		props.setBreadcrumbItems("Message  Codes", breadcrumbItems);
	}, []);

	// const addnewHandler = () => {
	// 	setShowAddModal(true);
	// };

	const importHandler = () => {};

	const handlerEdit = record => {
		setEditItem(record);
		setShowAddModal(true);
	};

	let messageCodesTableColumns = getMessageCodesTableColumns({ records, handlerEdit });

	let page_name = "Message Codes";
	// The page render
	return (
		<HelmetLayout titleProps={"Message Codes"}>
			<div>
				<CodesMSGTopBarComponent importHandler={importHandler} />
			</div>
			<div className="message-codes-search">
				<MetaTags>
					<title>
						{page_name} - {platformName}
					</title>
				</MetaTags>
				{showAddModal && (
					<ShowEditMessageCodeModal
						editItem={editItem}
						setEditItem={setEditItem}
						setShowAddModal={setShowAddModal}
						setExistFilter={setExistFilter}
						getMessageCodes={getMessageCodes}
					/>
				)}
				<div className="cms-page pt-0">
					<SingleSearchFilter
						setData={setRecords}
						unfilteredData={unfilteredData}
						filterColumn={["code", "language", "message_en"]}
						existFilter={existFilter}
						setExistFilter={setExistFilter}
					/>
					<MUIDataTable
						columns={messageCodesTableColumns}
						data={records}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							elevation: 0,
							onChangePage(page) {
								setCurrentPage(page);
							},
							onChangeRowsPerPage(number) {
								setRowPerPage(number);
							},
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							page: currentPage,
							responsive: tableResponsive,
							rowsPerPage: rowPerPage,
							rowsPerPageOptions: tableRowsPerPage,
							downloadOptions: { filename: `${page_name}.csv` },
							// customToolbar: () => <AddTableButton onClick={addnewHandler} />,
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(MessageCodes);
