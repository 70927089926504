import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import SearchFilter from "components/SearchFilter/searchFilter";
import { connect, useSelector } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { getDefaultLeaguesTableColumns } from "./defaultLeaguesTableColumns";
import { getDefaultLeaguesFilterTable } from "./defaultLeaguesFilterTable";
import { ShowDeleteDefaultLeaguesModal } from "./showDeleteDefaultLeaguesModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { ShowEditDefaultLeagues } from "./ShowEditDefaultLeagues";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";
import MUIDataTable from "mui-datatables";
import getSportsData from "services/getSportsData";
import { getCompetitionsAllData } from "services/getCompetitionsAllData";
import { prepareFilterData, userReadOnly } from "utils/global";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const defaultLeagues = props => {
	const defaultLeaguesFilterData = useSelector(state => state.Login.searchFilterData.default_league);
	const [records, setRecords] = useState([]);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [competitions, setCompetitions] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const [ordListData, setOrdListData] = useState([]);
	const [spinningStatus, setSpinningStatus] = useState(false);
	const [orderSpinning, setOrderSpinning] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const breadcrumbItems = [{ title: "Default Leagues", link: "/default_leagues" }];
	const countries = useSelector(state => state.Login.gsData.countries);
	const [editClicked, setEditClicked] = useState(false);
	const [defaultLeaguesId, setDefaultLeaguesId] = useState("");
	const [dataChanged, setDataChanged] = useState(false);
	const [sportsData, setSportsData] = useState([]);

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	let defaultLeaguesFilterTable = getDefaultLeaguesFilterTable({ countries, competitions, sportsData });

	useEffect(() => {
		props.setBreadcrumbItems("Default Leagues", breadcrumbItems);
	});

	useEffect(() => {
		filterDefaultLeagues();
		getSportTypes();
		getAllLeagues();
	}, []);

	const getAllLeagues = () => {
		getCompetitionsAllData().then(response => {
			if (response.length > 0) {
				setCompetitions(response);
			}
		});
	};

	const getSportTypes = () => {
		getSportsData("all").then(response => {
			setSportsData(response);
		});
	};

	const filterDefaultLeagues = () => {
		setCurrentPage(0);
		var league = "";
		var region = "";
		var sport = "";
		const formatedData = prepareFilterData(defaultLeaguesFilterData, defaultLeaguesFilterTable);
		if (!formatedData.league_id) {
			league = "";
		} else {
			league = formatedData.league_id;
		}
		if (!formatedData.country_id) {
			region = "";
		} else {
			region = formatedData.country_id;
		}
		if (!formatedData.sport_id) {
			sport = "";
		} else {
			sport = formatedData.sport_id;
		}

		if (formatedData?.country) {
			region = formatedData?.country;
		}

		let params = `?competition_id=${league}&region_id=${region}&sport_slug=${sport}`;
		getDefaultLeaguesData(params);
	};

	const getDefaultLeaguesData = (params, type, id) => {
		let url = params ? apiUrls.competition_default_leagues + params : apiUrls.competition_default_leagues;
		if (type === "order") {
			setOrderSpinning(id);
		}

		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				let data = response.data;
				let newData = [];
				data.map(row => {
					var regionName = row.region_id != 0 ? row.region_name : "All Countries";
					let obj = {
						id: row?.id,
						competition_name: row.competition_names,
						sport_type: row?.sport_type == "soccer" ? "Football" : row?.sport_type === "football" ? "American Football" : row?.sport_type,
						region_name: regionName,
						ord: row.ord,
						sport_id: row.sport_id,
						region_id: row.region_id,
						league_id: row.league_id,
					};
					newData.push(obj);
				});
				if (type === "order") {
					setOrdListData(newData);
					setEditClicked(true);
				} else {
					setRecords(newData);
				}
			})
			.finally(() => {
				if (type === "order") {
					setOrderSpinning(false);
					setIsDataLoading(false);
				} else {
					setIsDataLoading(false);
				}
			});
	};

	const deleteDefaultLeague = () => {
		setSpinningStatus(true);
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.default_league_delete + "?id=" + deleteId, resolve, reject, true);
		})
			.then(() => {
				let newData = records.filter(row => row.id !== deleteId);
				setRecords(newData);
			})
			.finally(() => {
				setSpinningStatus(false);
				setShowDeleteModal(false);
				setIsDataLoading(false);
			});
	};

	let defaultLeaguesTableColumns = getDefaultLeaguesTableColumns({
		records,
		setOrderSpinning,
		setOrdListData,
		setEditClicked,
		setDefaultLeaguesId,
		getDefaultLeaguesData,
		getAllLeagues,
		setShowDeleteModal,
		setDeleteId,
		orderSpinning,
		isUserReadOnly,
	});
	let page_name = "Default Leagues";

	const handleOpenModal = () => {
		setDefaultLeaguesId(null);
		setEditClicked(true);
	};

	return (
		<HelmetLayout titleProps={"Default Leagues"}>
			<Row>
				<Col>
					{editClicked && (
						<ShowEditDefaultLeagues
							editClicked={editClicked}
							setShowEditModal={setEditClicked}
							dataChanged={dataChanged}
							setDataChanged={setDataChanged}
							setDefaultLeaguesId={setDefaultLeaguesId}
							countries={countries}
							ordListData={ordListData}
							setOrdListData={setOrdListData}
							records={records}
							setRecords={setRecords}
							editId={defaultLeaguesId}
							sportsData={sportsData}
							isUserReadOnly={isUserReadOnly}
						/>
					)}
					<ShowDeleteDefaultLeaguesModal
						showDeleteModal={showDeleteModal}
						setShowDeleteModal={setShowDeleteModal}
						submitDeleteCompetition={deleteDefaultLeague}
						spinningStatus={spinningStatus}
					/>
					<div className="cms-page pt-0">
						<SearchFilter
							columns={defaultLeaguesFilterTable}
							page={"default_league"}
							preSelectedType={"select"}
							preSelectedValue={"country"}
							preSelectedName={"Country"}
							preSelectedData={countries ? countries : []}
							preSelectedOptionKey={"cca2"}
							preSelectedOptionName={"name"}
							filterHandler={filterDefaultLeagues}
							filterLoading={isDataLoading}
							competitionsHandler={() => {}}
							className="col-9"
							customizedFilter={true}
						/>
						<MUIDataTable
							columns={defaultLeaguesTableColumns}
							data={records}
							options={{
								...tableBarSettings,
								selectableRows: "none",
								elevation: 0,
								onChangePage(page) {
									setCurrentPage(page);
								},
								onChangeRowsPerPage(number) {
									setRowPerPage(number);
								},
								textLabels: {
									body: {
										noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
									},
								},
								page: currentPage,
								responsive: tableResponsive,
								rowsPerPage: rowPerPage,
								rowsPerPageOptions: tableRowsPerPage,
								downloadOptions: { filename: `${page_name}.csv` },
								customToolbar: () => <AddTableButton onClick={() => handleOpenModal()} />,
							}}
							extraButtons={tableExtraButtons}
						/>
					</div>
				</Col>
			</Row>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(defaultLeagues);
