import React from "react";
import { useEffect } from "react";
import { XCloseIcon } from "utils/icons";
import { useState } from "react";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";
import { Spinner } from "react-bootstrap";
import "./users.css";
import { getFormatDateByUserTimezone } from "utils/global";
import { getLabelName } from "./auditLogDetailsTable";

export const AuditlogModal = ({ handler, id, log_id }) => {
	const [auditlogDetails, setAuditlogDetails] = useState([]);
	const [pageLoading, setPageLoading] = useState(false);

	const auditLogDetails = () => {
		setPageLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_AUDIT_LOG_DETAILS + "?userId=" + id + "&log_id=" + log_id, resolve, reject, true);
		}).then(response => {
			if (response?.success) {
				setAuditlogDetails(response?.data);
			}
			setPageLoading(false);
		});
	};

	useEffect(() => {
		auditLogDetails();
	}, []);

	let title = "Audit Details";

	const theadField = [{ thead: "Description" }, { thead: "Old Value" }, { thead: "New Value" }, { thead: "Cms User" }];

	return (
		<div className="promotion-container audit-log-container">
			<div className="promotion-header">
				<span>{title}</span>
				<span className="d-flex justify-content-end w-100">
					<XCloseIcon onClick={handler} className={"fill-black"} />
				</span>
			</div>
			{pageLoading ? (
				<div className="text-center mt-3">
					<Spinner animation="border" size="lg" />
				</div>
			) : (
				<div className="promotion-body">
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs audit-log-customized">
							{/* <div> */}
							<table className="d-flex flex-column">
								<thead>
									<tr className="d-flex align-items-center mb-2 audit-log-items">
										{theadField?.map((row, i) => {
											const { thead } = row;
											return (
												<th key={i} className="audit-log-item">
													{thead}
												</th>
											);
										})}
									</tr>
								</thead>
								<tbody>
									{auditlogDetails?.map((row, i) => {
										const { field, previous_value, new_value, cms_users_first_name, cms_users_last_name } = row;
										return (
											<tr key={i} className="d-flex me-2 audit-log-items">
												<td className="audit-log-item">{getLabelName(field) || "-"}</td>
												<td className="audit-log-item">{previous_value || "-"}</td>
												<td className="audit-log-item">{new_value || "-"}</td>
												<td className="audit-log-item text-capitalize">
													{cms_users_first_name} {cms_users_last_name}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							{/* </div> */}
						</div>
						<div className="promotion-right col-12 col-lg-6 audit-log-customized">
							<div
								className="row"
								style={{ background: "#EAECEF", color: "#2E3A49", height: "44px", display: "flex", alignItems: "center", marginBottom: "1px" }}
							>
								<div className="col-6">Player ID</div>
								<div className="col-6">{auditlogDetails[0]?.user_id}</div>
							</div>
							<div
								className="row"
								style={{ background: "#EAECEF", color: "#2E3A49", height: "44px", display: "flex", alignItems: "center", marginBottom: "1px" }}
							>
								<div className="col-6">Owner</div>
								<div className="col-6" style={{ textTransform: "capitalize" }}>
									{auditlogDetails[0]?.first_name} {auditlogDetails[0]?.last_name}
								</div>
							</div>
							<div
								className="row"
								style={{ background: "#EAECEF", color: "#2E3A49", height: "44px", display: "flex", alignItems: "center", marginBottom: "1px" }}
							>
								<div className="col-6">Date of change</div>
								<div className="col-6">{getFormatDateByUserTimezone(auditlogDetails[0]?.created_at, "YYYY-MM-DD HH:mm:ss")}</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
