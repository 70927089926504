import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBonusEngineColumns } from "./bonusEngineColumns";
import { tableMuiTheme, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";
import { ThemeProvider } from "@mui/material/styles";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import SearchFilter from "components/SearchFilter/searchFilter";
import { getFilterColumns } from "./bonusEngineFilters";
import { bonusEnginePublished } from "utils/constants";
import { createParams, prepareFilterData } from "utils/global";
import { cmsSearchFilterData } from "store/actions";

import BonusEngineSingle from "./bonusEngineSingle";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

import { SaveFilterData } from "components/SearchFilter/saveFilterData";
import BonusEngineLogs from "./bonuseEngineLogs";

const BonusEngine = () => {
	const dispatch = useDispatch();

	const [currentPage, setCurrentPage] = useState(0);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [records, setRecords] = useState([]);
	const [showBonusEnginePromotion, setShowBonusEnginePromotion] = useState(false);
	const [showBonusEngineLogs, setShowBonusEngineLogs] = useState(false);
	const [selectedBonusEngine, setSelectedBonusEngine] = useState(null);
	const [loadingId, setLoadingId] = useState(null);
	const [defaultSearch, setDefaultSearch] = useState(false);

	const bonusEngineFilters = useSelector(state => state.Login.searchFilterData.bonus_engine);

	const filterColumns = getFilterColumns();

	const getBonusEngineData = params => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.bonus_engine + `${params ? params : ""}`, resolve, reject, true);
		})
			.then(response => {
				setRecords(response.data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const onBonusEngineSelect = (id, type) => {
		if (type === "logs") setShowBonusEngineLogs(true);
		else setShowBonusEnginePromotion(true);

		setSelectedBonusEngine(id);
	};

	const runBonusEngine = id => {
		setLoadingId(id);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.bonus_engine + `/${id}/run`, resolve, reject, true);
		})
			.then(() => {
				setLoadingId(null);
			})
			.catch(() => {
				setLoadingId(null);
			});
	};

	const filterBonusEngine = bonusEngineFilterData => {
		const bonusEngineParams = filterColumns.map(({ value }) => value);
		let params = createParams(bonusEngineFilterData, bonusEngineParams);

		getBonusEngineData(params);
	};

	const bonusEngineColumns = getBonusEngineColumns({ records, onBonusEngineSelect, runBonusEngine, loadingId });

	useEffect(() => {
		if (!bonusEngineFilters?.length) return;
		const formatedData = prepareFilterData(bonusEngineFilters, filterColumns);
		const isNotEmpty = Object.keys(formatedData).some(k => !!formatedData[k]);

		if (!isNotEmpty) {
			const newData = {
				published: { selected: "1", value: "Yes" },
			};

			const defaultSearchBets = bonusEngineFilters?.map(filter => {
				if (newData[filter?.key]?.selected) {
					return { ...filter, selected: newData[filter?.key]?.selected, value: newData[filter?.key]?.value };
				}

				return filter;
			});

			SaveFilterData("bonus_engine", defaultSearchBets, dispatch, cmsSearchFilterData);

			const newFilterData = prepareFilterData(defaultSearchBets, filterColumns);
			filterBonusEngine(newFilterData);

			setDefaultSearch(true);
		}
		// else {
		// 	filterBonusEngine(formatedData);
		// }
	}, [bonusEngineFilters?.length]);

	return (
		<HelmetLayout titleProps={"Bonus Engine"}>
			<div className="cms-page pt-0 cms-table-page">
				<div className="col-9 d-flex justify-content-between">
					<SearchFilter
						columns={filterColumns}
						page={"bonus_engine"}
						preSelectedType={"select"}
						preSelectedValue={"published"}
						preSelectedName={"Published"}
						preSelectedData={bonusEnginePublished ? bonusEnginePublished : []}
						preSelectedOptionKey={"value"}
						preSelectedOptionName={"name"}
						filterHandler={filterBonusEngine}
						filterLoading={isDataLoading}
						dep={{ defaultSearch }}
					/>
				</div>

				<ThemeProvider theme={tableMuiTheme()}>
					<MUIDataTable
						columns={bonusEngineColumns}
						data={records}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							elevation: 0,
							onChangePage(page) {
								setCurrentPage(page);
							},
							onChangeRowsPerPage(number) {
								setRowPerPage(number);
							},
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							page: currentPage,
							responsive: tableResponsive,
							rowsPerPage: rowPerPage,
							rowsPerPageOptions: tableRowsPerPage,
							downloadOptions: { filename: "Bonus Engine.csv" },
							customToolbar: () => <AddTableButton onClick={() => onBonusEngineSelect("")} />,
						}}
					/>
				</ThemeProvider>
			</div>
			{showBonusEnginePromotion && (
				<BonusEngineSingle id={selectedBonusEngine} setShowBonusEnginePromotion={setShowBonusEnginePromotion} records={records} setRecords={setRecords} />
			)}

			{showBonusEngineLogs && <BonusEngineLogs id={selectedBonusEngine} setShowBonusEngineLogs={setShowBonusEngineLogs} />}
		</HelmetLayout>
	);
};

export default BonusEngine;
