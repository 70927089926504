//Breadcrumb
export * from "./Breadcrumb/actions";
export * from "./auth/forgetpwd/actions";
export * from "./auth/login/actions";
export * from "./auth/profile/actions";
// Authentication module
export * from "./auth/register/actions";
//Calendar
export * from "./calendar/actions";
export * from "./layout/actions";
export * from "./report/actions";
export * from "./TelebetRrducers/actions";

export const resetStore = () => ({
	type: "RESET_STORE",
});
