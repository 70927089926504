import React, { useEffect, useState } from "react";
import { PenEditButton, PlusAddButton } from "components/GeneralComponents/AddButton";
import { SearchInput, GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import ApiServices from "utils/ApiServices";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import xIcon from "../../../assets/images/x-icon.svg";
import { capitalizeText } from "services/capitalizeText";
import { CasinoGame } from "./casinoGame";
import { getImageThumbnail } from "utils/global";
import TablePagination from "@mui/material/TablePagination";
import MissingLogoContent from "../../../assets/images/MissingLogoContent.svg";

const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;

export const CasinoGroups = ({ selectedCategory, categoryGames, setCategoryGames, editCasinoGrups, setEditCasinoGrups, type }) => {
	const [loading, setLoading] = useState(null);
	const [searchValue, setSearchValue] = useState("");
	const [studio, setStudio] = useState("all");
	const [studioData, setStudioData] = useState([]);
	const [isGameDataLoading, setIsGameDataLoading] = useState(false);
	const [games, setGames] = useState([]);
	const [editGame, setEditGame] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [count, setCount] = useState();

	useEffect(() => {
		if (searchValue) {
			const timeOut = setTimeout(() => getGames(searchValue), 500);
			return () => clearTimeout(timeOut);
		}
	}, [searchValue, studio, page, rowsPerPage]);

	const getGames = searchValue => {
		setIsGameDataLoading(true);
		let url = `${apiUrls.GET_CASINO_GAMES}?provider=${studio}&type=${type}&page=${page + 1}&limit=${rowsPerPage}`;

		if (searchValue) url += `&q=${searchValue}`;

		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;
				setCount(data?.total);
				setGames(data?.result);
			})
			.finally(() => {
				setIsGameDataLoading(false);
				setEditCasinoGrups(false);
			});
	};

	useEffect(() => {
		if (!searchValue) {
			getGames();
		}
	}, [studio, page, rowsPerPage]);

	useEffect(() => {
		if (editCasinoGrups) {
			getGames(searchValue);
		}
	}, [editCasinoGrups]);

	const addGameToCategory = game => {
		if (selectedCategory) {
			let payload = {
				group_id: selectedCategory,
				game_id: game?.id,
				studio: studio,
			};
			game["published"] = 1;
			setLoading(game?.id);
			new Promise((resolve, reject) => {
				ApiServices.put(apiUrls.add_casino_game, resolve, reject, payload, true);
			})
				.then(() => {
					let actualData = [...categoryGames];
					actualData.push(game);
					setCategoryGames(actualData);
				})
				.finally(() => {
					setLoading(null);
				});
		} else {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select any category before adding new game</p>
				</>,
				{ className: "custom-toast" }
			);
		}
	};

	const getCasinoProviders = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_CASINO_PROVIDERS, resolve, reject, true);
		}).then(response => {
			const { data } = response;
			const studioData = data.map(({ slug, name }) => {
				return { id: slug, label: name };
			});
			studioData.unshift({ id: "all", label: "All Studios" });
			setStudioData(studioData);
		});
	};

	useEffect(() => {
		getCasinoProviders();
	}, []);

	const handleChangeDropdwon = id => {
		setStudio(id);
		setPage(0);
	};

	const handleSearch = value => {
		if (value) {
			setSearchValue(value);
			setPage(0);
		} else {
			setSearchValue("");
			getGames();
		}
	};

	return (
		<>
			<div className="casino-item">
				<div className="casino-category">
					<div className="casino-category-search double-input casino-group-search" style={{ marginRight: "0px" }}>
						<SearchInput onChange={handleSearch} searchValue={searchValue} />
						<GeneralDropdown
							object={{
								value: studio,
								options: studioData,
								wrapperClassNameDropdown: "casino-category-studio p-0",
								defaultPlaceholder: "Studio",
								handleChangeDropdown: e => handleChangeDropdwon(e.id),
							}}
						/>
					</div>
				</div>
				{isGameDataLoading ? (
					<LoadingSpinner className={"pt-3"} />
				) : (
					<>
						<div className="casino-elements">
							{games.map((row, index) => {
								let details = {};
								try {
									if (row?.details) {
										details = JSON.parse(row?.details);
									}
								} catch (e) {
									details = {};
								}

								const published = row?.published ? "Published" : "Inactive";
								const classNamePublished = row?.published ? "badge badge-sports-published me-2" : "badge badge-sports-inactive me-2";
								const default_image = row?.default_image;
								const image = default_image
									? row?.web_image_url
									: getImageThumbnail(details?.web_image)
									? `${aws_s3_url}/media/${getImageThumbnail(details?.web_image)}`
									: "";

								const subTitle = `${capitalizeText(row?.provider)} | ${row?.slug}`;
								return (
									<div className="casino-element" key={index}>
										<div className="element-start casino-element-container">
											{image ? (
												<img
													src={image}
													className={"game-image"}
													alt={row?.title}
													onError={({ currentTarget }) => {
														currentTarget.onerror = null;
														currentTarget.src = MissingLogoContent;
													}}
												/>
											) : (
												<img src={MissingLogoContent} />
											)}

											<div className="ms-2">
												<span className="casino-element-name">{row?.title}</span>
												<span className="casino-element-type">{subTitle}</span>
											</div>
										</div>
										<div className="element-end">
											<span className={classNamePublished}>{published}</span>

											<PenEditButton onClick={() => setEditGame(row)} />
											<PlusAddButton onClick={() => addGameToCategory(row)} id={row?.id} loading={loading} />
										</div>
									</div>
								);
							})}
						</div>

						<TablePagination
							component="div"
							count={count}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={(event, page) => setPage(page)}
							rowsPerPageOptions={[50, 100, 150]}
							onRowsPerPageChange={event => setRowsPerPage(event.target.value)}
						/>
					</>
				)}
			</div>
			{editGame && <CasinoGame editGame={editGame} setEditGame={setEditGame} setEditCasinoGrups={setEditCasinoGrups} />}
		</>
	);
};
