import { bonusEnginePublished, bonusEngineFinished } from "utils/constants";

export const getFilterColumns = () => {
	return [
		{
			name: "Published",
			type: "select",
			value: "published",
			data: JSON.stringify(bonusEnginePublished),
			optionKey: "value",
			optionName: "name",
			defaultValue: {
				value: "1",
				name: "Yes",
			},
		},
		{
			name: "Finished",
			type: "select",
			value: "finished",
			data: JSON.stringify(bonusEngineFinished),
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "Valid From",
			type: "date",
			value: "valid_from",
			mode: "single",
		},
		{
			name: "Valid To",
			type: "date",
			value: "valid_to",
			mode: "single",
		},
	];
};
