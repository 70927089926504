import React from "react";
import { EditButton } from "components/GeneralComponents/AddButton";
// import { Spinner } from "react-bootstrap";
import { toCapitalCase } from "utils/global";

export const getCountriesTableColumns = ({ records, isLoading, editCountryFunc }) => {
	return [
		{
			name: "name",
			label: "COUNTRY",
			className: "name",
			sortable: true,
			sort: "asc",
			align: "left",
			options: { setCellHeaderProps: () => ({ style: { width: "200px", minWidth: "200px" } }) },
		},
		{
			name: "master_geoblock",
			label: "MASTER GEOBLOCK",
			sortable: true,
			sort: "asc",
			align: "left",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const master_geoblock = record?.master_geoblock == 0 ? "Inactive" : "Active";
					return <span>{master_geoblock}</span>;
				},
				setCellHeaderProps: () => ({ style: { width: "180px", minWidth: "180px" } }),
			},
		},
		{
			name: "mobile_geoblock",
			label: "MOBILE GEOBLOCK",
			sortable: true,
			sort: "asc",
			align: "left",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const mobile_geoblock = record?.mobile_geoblock == 0 ? "Inactive" : "Active";
					return <span>{mobile_geoblock}</span>;
				},
				setCellHeaderProps: () => ({ style: { width: "180px", minWidth: "180px" } }),
			},
		},
		{
			name: "disabled_reason",
			label: "DISABLED REASON",
			sortable: true,
			sort: "asc",
			align: "left",
			options: { setCellHeaderProps: () => ({ style: { width: "200px", minWidth: "200px" } }) },
		},

		{
			name: "default_currency",
			label: "DEFAULT CURRENCY",
			sortable: true,
			sort: "asc",
			align: "left",
			options: { setCellHeaderProps: () => ({ style: { width: "180px", minWidth: "180px" } }) },
		},

		{
			name: "default_odd_format",
			label: "DEFAULT ODD FORMAT",
			sortable: true,
			className: "default_odd_format",
			sort: "asc",
			align: "left",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const oddFormat = toCapitalCase(record?.default_odd_format);
					return <span>{oddFormat == "Fractional" ? "Fractional" : "Decimal"}</span>;
				},
				setCellHeaderProps: () => ({ style: { width: "150px", minWidth: "150px" } }),
			},
		},
		{
			name: "verify_phone_number",
			label: "MOBILE VERIFICATION REQUIRED",
			sortable: true,
			sort: "asc",
			align: "left",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const mobileVerifiedRequired = record?.verify_phone_number == false ? "Inactive" : "Active";
					return <span>{mobileVerifiedRequired}</span>;
				},
				setCellHeaderProps: () => ({ style: { width: "200px", minWidth: "200px" } }),
			},
		},
		{
			name: "phone_required",
			label: "MOBILE COUNTRY REQUIRED",
			sortable: true,
			sort: "asc",
			align: "left",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const mobileCountryRequired = record?.phone_required == false ? "Inactive" : "Active";
					return <span>{mobileCountryRequired}</span>;
				},
				setCellHeaderProps: () => ({ style: { width: "200px", minWidth: "200px" } }),
			},
		},
		{
			name: "",
			className: "edit_custom_bet",
			sortable: true,
			label: "",
			sort: "asc",
			align: "right",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return <EditButton loading={isLoading == record.cca2} onClick={() => editCountryFunc(record.cca2)} />;
				},
				setCellHeaderProps: () => ({ style: { width: "auto" } }),
			},
		},
	];
};
