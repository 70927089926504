import React from "react";
import { EyeButton } from "components/GeneralComponents/AddButton";
import HTMLReactParser from "html-react-parser";
import { getFormatDateByUserTimezone } from "utils/global";
import { getBetTypeBadges } from "./betsHelper";

import { GiftIcon } from "utils/icons";

export const getTableBetsColumns = ({ records, props }) => {
	return [
		{
			name: "",
			label: "",
			options: {
				customBodyRender: (value, meta) => {
					const row = records[meta.currentTableData[meta.rowIndex].index];
					const { status } = row;
					if (status.includes("Accepted") || status.includes("Approved")) {
						return (
							<div className="d-flex justify-content-end">
								<EyeButton onClick={() => props.history.push(`/bet/${row?.betId}`)} />
							</div>
						);
					}
				},
			},
		},
		{
			name: "time",
			label: "TIME",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: value => {
					return <div className="bet_player_time_container">{getFormatDateByUserTimezone(value)}</div>;
				},
			},
		},
		{
			name: "betId",
			label: "BET ID",
			sortable: true,
			sort: "asc",
		},
		{
			name: "player_id",
			label: "PLAYER ID",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: value => {
					const user = records.find(row => row.player_id == value);

					return (
						<div className="bet-user-hover-wrapper">
							<div>{value}</div>
							<div className="bet-user-hover">{user?.user?.toUpperCase()}</div>
						</div>
					);
				},
			},
		},
		{
			name: "stake_factor",
			label: "SF",
			sortable: true,
			options: {
				setCellHeaderProps: () => ({ title: "Stake Factor" }),
			},
		},
		{
			name: "type",
			label: "TYPE",
			sortable: true,
		},
		{
			name: "betType",
			label: "BET TYPE",
			className: "betted_id",
		},
		{
			name: "sport",
			label: "SPORT",
			sortable: true,
			sort: "asc",
		},
		{
			name: "competition",
			label: "COMPETITION",
			sortable: true,
			sort: "asc",
		},
		{
			name: "event",
			label: "EVENT",
			sortable: true,
			sort: "asc",
		},
		{
			name: "event_start",
			label: "EVENT START",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const eventStartTime = getFormatDateByUserTimezone(record.event_start);
					return <div>{eventStartTime}</div>;
				},
			},
		},
		{
			name: "market",
			label: "MARKET",
			sortable: true,
			sort: "asc",
		},
		{
			name: "selection",
			label: "SELECTION",
			sortable: true,
			sort: "asc",
		},

		{
			name: "eachWay",
			label: "EW",
			options: {
				setCellHeaderProps: () => ({ title: "EACH WAY" }),
			},
		},
		{
			name: "ewTerms",
			label: "EW TERMS",
			options: {
				setCellHeaderProps: () => ({ title: "EW TERMS" }),
			},
		},
		{
			name: "bog_enabled",
			label: "BOG ENABLED",
			sortable: true,
			sort: "asc",
		},
		{
			name: "result",
			label: "RESULT",
			className: "tag",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return getBetTypeBadges(record?.result);
				},
			},
		},

		{
			name: "price",
			label: "PRICE",
			options: {
				setCellHeaderProps: () => ({ className: "align-right" }),
				setCellProps: () => ({ style: { whiteSpace: "nowrap", textAlign: "right" } }),
			},
		},
		{
			name: "stakePerLine",
			label: "STAKE PER LINE",
			sortable: true,
			sort: "asc",
			options: {
				setCellHeaderProps: () => ({ className: "align-right", title: "STAKE PER LINE" }),
				setCellProps: () => ({ style: { textAlign: "right", whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "totalStake",
			label: "TOTAL STAKE",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];

					return (
						<div className="total-stake-container">
							{record?.isFreeBet && <GiftIcon />}
							<span className="value">{record?.totalStake}</span>
						</div>
					);
				},
			},
		},
		{
			name: "bog_payout",
			label: "BOG PAYOUT",
			sortable: true,
			sort: "asc",
			options: {
				setCellHeaderProps: () => ({ className: "max-button-and-right align-right header-width" }),
				setCellProps: () => ({ style: { textAlign: "right", whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "payout",
			label: "PAYOUT",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const { result, payout } = record;
					const newPayout = result == "Loser" || result == "Lose" ? "0.00" : payout;

					return <div>{newPayout}</div>;
				},
				setCellHeaderProps: () => ({ className: "align-right" }),
				setCellProps: () => ({ style: { textAlign: "right" } }),
			},
		},
		{
			name: "currency",
			label: "CURRENCY",
			sortable: true,
			sort: "asc",
		},
		{
			name: "maxBet",
			label: "MAX BET",
			sortable: true,
			sort: "asc",
			options: {
				setCellHeaderProps: () => ({ className: "align-right", title: "MAX BET" }),
				setCellProps: () => ({ style: { textAlign: "right", whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "maxLineStake",
			label: "MAX LINE STAKE",
			sortable: true,
			sort: "asc",
			options: {
				setCellHeaderProps: () => ({ className: "align-right", title: "MAX LINE STAKE" }),
				setCellProps: () => ({ style: { textAlign: "right", whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "maxAllowedBet",
			label: "SPL / MAB",
			sortable: true,
			sort: "asc",
			options: {
				setCellHeaderProps: () => ({
					className: "align-right",
					title: "STAKE PER LINE / MAX ALLOWED BET",
				}),
				setCellProps: () => ({ style: { textAlign: "right", whiteSpace: "nowrap" } }),
			},
		},

		{
			name: "channel",
			label: "CHANNEL",
			sortable: true,
			sort: "asc",
		},

		{
			name: "status",
			label: "STATUS",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];

					var result_txt =
						record.status == "Rejected"
							? `<span className="badge badge-sports-red"> Rejected </span>`
							: record.status == "Accepted" || record.status == "Approved"
							? `<span className="badge badge-sports-published modified_win_color"> ${record?.status} </span>`
							: record.status == "Auto Cancelled"
							? `<span className="badge badge-sports-gray"> Auto Cancelled </span>`
							: record.status == "Expired"
							? `<span className="badge badge-sports-gray">Expired</span>`
							: record.status;
					return HTMLReactParser(result_txt);
				},
			},
		},
		{
			name: "affiliate",
			label: "AFFILIATE",
			sortable: true,
			sort: "asc",
		},
		{
			name: "trader",
			label: "TRADER",
			sortable: true,
			sort: "asc",
		},
		{
			name: "ipAddress",
			label: "IP ADDRESS",
			sortable: true,
			sort: "asc",
		},
		{
			name: "platform_type",
			label: "Device Type",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { whiteSpace: "nowrap" }, className: "text-capitalize" }),
			},
		},
	];
};

export const betsTableShowColumns = {
	betted_id: true,
	bet_type: true,
	users_player_id: true,
	betted_at: true,
	resultedAt: true,
	sport: true,
	competition: true,
	match: true,
	market: true,
	selection: true,
	stake: true,
	odd: true,
	result_type: true,
	result: true,
	inplay: true,
	return_amount: true,
	currency: true,
	eventStart: true,
};
