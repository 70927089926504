import { SET_BET_NOTIFICATIONS_LIST, REMOVE_BET_FROM_NOTIFICATIONS_LIST, ADD_BET_NOTIFICATIONS_LIST } from "./actionTypes";

export const addBetNotificationsList = payload => {
	return {
		type: ADD_BET_NOTIFICATIONS_LIST,
		payload,
	};
};

export const setBetNotificationsList = payload => {
	return {
		type: SET_BET_NOTIFICATIONS_LIST,
		payload,
	};
};

export const removeBetFromNotificationsList = payload => {
	return {
		type: REMOVE_BET_FROM_NOTIFICATIONS_LIST,
		payload,
	};
};
