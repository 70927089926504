import React from "react";
import { EditIconButton } from "components/GeneralComponents/AddButton";
// import { StatusInput } from "components/GeneralComponents/StatusInput";

export const getCurrenciesUsersColumns = ({ currenciesData, handleEditItem }) => {
	return [
		{
			name: "code",
			label: "CURRENCY",
			className: "currency",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "30px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "60px" } }),
			},
		},
		{
			name: "name",
			label: "DESCRIPTION",
			className: "description",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" } }),
			},
		},
		// {
		// 	name: "enabled",
		// 	label: "Status",
		// 	className: "",
		// 	align: "left",
		// 	options: {
		// 		filter: false,
		// 		customBodyRender: (value, meta) => {
		// 			const record = currenciesData[meta.currentTableData[meta.rowIndex].index];
		// 			let updated = record?.enabled === 1 ? 0 : 1;
		// 			return (
		// 				<>
		// 					<StatusInput
		// 						checked={record.enabled == 1 ? true : false}
		// 						onChange={() => {
		// 							console.log(record);
		// 							let payload = {
		// 								id: record?.id,
		// 								code: record?.code,
		// 								symbol: record?.symbol,
		// 								name: record?.name,
		// 								enabled: updated,
		// 							};
		// 							console.log(payload, "payload");
		// 							editCurrency(payload);
		// 						}}
		// 					/>
		// 				</>
		// 			);
		// 		},
		// 		setCellProps: () => ({ style: { textAlign: "left", maxWidth: "450px" } }),
		// 		setCellHeaderProps: () => ({ style: { maxWidth: "450px" }, className: "max-button" }),
		// 	},
		// },
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = currenciesData[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => handleEditItem(row)} />
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "50px" } }),
			},
		},
	];
};
