import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { getFormatDateByUserTimezone } from "utils/global";

export const getActivityLogData = async ({ params, setIsDataLoading, setRecords }) => {
	let url = apiUrls.activity_log_get + params;
	setIsDataLoading(true);
	new Promise((resolve, reject) => {
		ApiServices.get(url, resolve, reject, true);
	})
		.then(response => {
			let data = response.data;
			let newRecords = [];
			data.map(activityLog => {
				const createdAt = getFormatDateByUserTimezone(activityLog?.createdAt, "YYYY-MM-DD HH:mm:ss");
				let obj = {
					player_id: activityLog.player_id !== "null" ? activityLog?.player_id : "",
					id: activityLog.id,
					activity_name: activityLog.activity_name,
					createdAt: createdAt,
					cloudfront_viewer_contry: activityLog.cloudfront_viewer_contry,
					x_forwarded_for: activityLog.x_forwarded_for,
					user_agent: activityLog.user_agent,
					aws_request_id: activityLog.aws_request_id,
					response_http_code: activityLog.response_http_code,
					swifty_id: activityLog.swifty_id,
				};
				newRecords.push(obj);
			});
			setRecords(newRecords);
		})
		.finally(() => {
			setIsDataLoading(false);
		});
};
