import React from "react";
import { Spinner } from "reactstrap";

function LoadingSpinner({ size, type = "border", className, color }) {
	return (
		<div className={`spinner-wrapper ${className}`}>
			<Spinner type={type} size={size} color={color} />
		</div>
	);
}

export default LoadingSpinner;
