import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner } from "react-bootstrap";
import { CancelButton, SaveButton } from "components/GeneralComponents/AddButton";

export const ShowDeleteDefaultLeaguesModal = ({ showDeleteModal, setShowDeleteModal, submitDeleteCompetition, spinningStatus }) => {
	return (
		<>
			{showDeleteModal && (
				<SweetAlert title="Are you sure you want to delete this competition ?" showConfirm={false} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="mt-3">
							<CancelButton onClick={() => setShowDeleteModal(false)} name={"No"} />
							<SaveButton onClick={submitDeleteCompetition} name={"Yes"}>
								{spinningStatus ? <Spinner animation="border" size="sm" /> : "Yes"}
							</SaveButton>
						</div>
					</div>
				</SweetAlert>
			)}
		</>
	);
};
