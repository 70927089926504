import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "../../../assets/images/delete.svg";
import { apiUrls } from "utils/const.apiUrl";

export const getPromoLinksTableColumn = ({ affiliateLinks, handleEditAffiliateItem, setDeleteItem }) => {
	return [
		{
			name: "link",
			label: "LINK",
			className: "link",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customHeadRender: columnMeta => <th className="custom-header-padding"> {columnMeta.label} </th>,
				customBodyRender: (value, meta) => {
					const row = affiliateLinks[meta.currentTableData[meta.rowIndex].index];
					const url = `${apiUrls.client_side_domain}/promo/${row.slug}`;
					return (
						<a href={url} className="hover_link" target="_blank" rel="noreferrer">
							{url}
						</a>
					);
				},
				// setCellHeaderProps: () => ({ style: { paddingLeft: "20px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = affiliateLinks[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => handleEditAffiliateItem(row)} />
								<DeleteIconButton deleteIcon={deleteIcon} onClick={() => setDeleteItem(row)} />
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
