import React, { useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./DregAndDrop.scss";

const DragAndDropList = props => {
	const { data = [], setData, children, className, disable = false } = props;

	const ref = useRef(null);

	const onDragEnd = result => {
		if (!result.destination) return;

		if (result.destination.index === result.source.index) return;

		const reorderedData = [...data];
		const [movedRow] = reorderedData.splice(result.source.index, 1);
		reorderedData.splice(result.destination.index, 0, movedRow);

		setData(reorderedData);
	};

	return (
		<div className={`dreg-and-drop ${className}`} ref={ref}>
			{!disable ? (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{provided => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{data.map((item, index) => (
									<Draggable key={index} draggableId={`item-${index}`} index={index}>
										{(draggableProvided, snapshot) => {
											return (
												<div
													ref={draggableProvided.innerRef}
													{...draggableProvided.draggableProps}
													className={`starting-to-crawl ${snapshot.isDragging ? "dragging" : ""}`}
													style={{
														...draggableProvided.draggableProps.style,
														width: snapshot.isDragging ? `${ref.current?.offsetWidth}px` : "100%",
													}}
												>
													{typeof children === "function" &&
														children(item, {
															dragHandleProps: draggableProvided.dragHandleProps,
															index: index,
															isDragging: snapshot.isDragging,
														})}
												</div>
											);
										}}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				<div>
					{data.map((item, index) => (
						<div className={`starting-to-crawl`} key={index}>
							{typeof children === "function" &&
								children(item, {
									index: index,
								})}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default DragAndDropList;
