import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";
import AllComponentsLoop from "../../components/GeneralComponents/AllComponentsLoop";
import { getCountryCallingCode } from "libphonenumber-js";

export const AddUserModal = ({ handler }) => {
	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [gamingUserData, setGamingUserData] = useState({
		kyc_enable: 0,
		language: "EN",
		currency: "",
		country: "",
		phone_prefix: "+44",
		affiliate_tag: "",
		address_city: "",
		address_line: "",
		address_zip: "",
	});

	// const toggleKycEnabled = () => {
	// 	setGamingUserData(prevState => ({
	// 		...prevState,
	// 		kyc_enable: prevState.kyc_enable === 1 ? 0 : 1,
	// 	}));
	// };

	const handleChangeDropdown = data => {
		if (data?.id) {
			const phoneNumber = getCountryCallingCode(data?.id);
			setGamingUserData(prevState => ({ ...prevState, country: data.id, currency: data?.default_currency, phone_prefix: `+${phoneNumber}` }));
		} else {
			setGamingUserData(prevState => ({ ...prevState, country: "" }));
		}
	};

	let userFields = [
		{
			type: "input",
			name: "First Name",
			fieldName: "first_name",
			required: true,
			placeholder: "First Name",
			className: "textCapitalized",
		},
		{
			type: "input",
			name: "Last Name",
			fieldName: "last_name",
			required: true,
			placeholder: "Last Name",
			className: "textCapitalized",
		},
		{
			type: "dropdown",
			name: "Country",
			fieldName: "country",
			optionsType: "country",
			placeholder: "Country",
			required: true,
			handleChangeDropdown: handleChangeDropdown,
		},
		{
			type: "phone_prefix",
			name: "Phone",
			fieldName: "phone_prefix",
			optionsType: "phonePrefixes",
			numbersOnly: true,
			phoneField: "phone_number",
			required: true,
			defaultPlaceholder: "Optional",
		},
		{ type: "input", name: "Email", fieldName: "email", placeholder: "Email", required: true },
		{
			type: "input",
			name: "Address",
			fieldName: "address_line",
			className: "textCapitalized",
			required: false,
			placeholder: "Address",
		},
		{
			type: "input",
			name: "City",
			fieldName: "address_city",
			className: "textCapitalized",
			required: false,
			placeholder: "City",
		},
		{
			type: "input",
			name: "Post Code",
			fieldName: "address_zip",
			required: false,
			placeholder: "Post Code",
		},
		{
			type: "dropdown",
			name: "Currency",
			fieldName: "currency",
			optionsType: "currency",
			required: true,
			placeholder: "Choose Currency",
		},
		{ type: "date", name: "DOB", fieldName: "date_of_birth", placeholder: "Date of Birth", isTimeExist: false, required: true },
		{
			type: "dropdown",
			name: "Language",
			fieldName: "language",
			optionsType: "languages",
			required: true,
			placeholder: "Choose Language",
			addUser: true,
		},
		{
			type: "dropdown",
			name: "Affiliate Tag",
			fieldName: "affiliate_tag",
			optionsType: "affiliateUsers",
			placeholder: "Affiliate",
			addUser: true,
		},
	];
	const handleAddUser = () => {
		setLoading(true);
		let payload = {
			email: gamingUserData?.email,
			first_name: gamingUserData?.first_name,
			last_name: gamingUserData?.last_name,
			phone_prefix: gamingUserData?.phone_prefix,
			phone_number: gamingUserData?.phone_number,
			state: "",
			date_of_birth: gamingUserData?.date_of_birth,
			country: gamingUserData?.country?.toUpperCase(),
			currency: gamingUserData?.currency,
			address_line: gamingUserData?.address_line,
			address_city: gamingUserData?.address_city,
			address_zip: gamingUserData?.address_zip,
			language: gamingUserData?.language.toLowerCase(),
			affiliate_tag: gamingUserData?.affiliate_tag,
			kyc_enable: gamingUserData?.kyc_enable,
		};

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.player_add, resolve, reject, payload, true, userFields);
		})
			.then(() => {
				handler();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	if (gamingUserData.kyc_enable === 1) {
		let addUserFields = userFields.filter(
			field => field.fieldName !== "address_line" && field.fieldName !== "address_city" && field.fieldName !== "address_zip"
		);
		userFields = addUserFields;
	}
	if (!gamingUserData.country) {
		let addUserFields = userFields.filter(field => field.fieldName !== "language" && field.fieldName !== "currency");
		userFields = addUserFields;
	}

	let title = "User";

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span className="header_title_mobile">{title}</span>
				<div>
					<XCloseIcon onClick={handler} className="xclose-icon" />
				</div>
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={handleAddUser} dataChanged={dataChanged} formId="avForm" type="submit" loading={loading} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="col-12 col-lg-6 promotion-inputs addUser-promotion">
						<AllComponentsLoop fields={userFields} data={gamingUserData} setData={setGamingUserData} setDataChanged={setDataChanged} />
					</div>
					<div className="addUser-promotion addUser-promotion-right col-12 col-lg-6"></div>
				</div>
			</div>
		</div>
	);
};
