import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import { setBreadcrumbItems } from "../../../store/actions";
import { getSportTableColumns } from "./sportTableColumn";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getMuiTheme } from "../../../components/TableMuiTheme";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import getSportsData from "services/getSportsData";

const SportTypes = props => {
	const [currentPage, setCurrentPage] = useState(0);
	const breadcrumbItems = [{ title: "Sports", link: "/sports" }];
	const [rowPerPage, setRowPerPage] = useState(50);
	const [viewColumns, setViewColumns] = useState({
		name: true,
		status_txt: true,
		edit: true,
	});
	const [records, setRecords] = useState([]);

	useEffect(() => {
		props.setBreadcrumbItems("Sports", breadcrumbItems);
	}, []);

	let rc = [];

	const filterSports = () => {
		getSportsData("all").then(response => {
			if (response?.length > 0) {
				let data = response;
				data.map(sport => {
					var status_txt = sport.active == 0 ? "Inactive" : "Active";
					let obj = {
						id: sport.id,
						name: sport?.name,
						active: sport.active,
						status_txt: status_txt,
						slug: sport?.slug,
						icon: sport?.icon,
						cover_active: sport?.cover_active,
						cover_inactive: sport?.cover_inactive,
					};
					rc.push(obj);
				});
				setRecords(rc);
			}
		});
	};

	useEffect(() => {
		filterSports();
	}, []);

	let sportTableColumn = getSportTableColumns({
		records,
	});

	return (
		<HelmetLayout titleProps={"Sport Types"}>
			<div className="cms-page pt-0 cms-table-page">
				<div className="no-search-bar">
					{records && records.length > 0 ? (
						<ThemeProvider theme={getMuiTheme()}>
							<MUIDataTable
								columns={sportTableColumn}
								data={records}
								options={{
									...tableBarSettings,
									selectableRows: "none",
									elevation: 0,
									onChangePage(page) {
										setCurrentPage(page);
									},
									onChangeRowsPerPage(number) {
										setRowPerPage(number);
									},
									onViewColumnsChange(changedColumn) {
										Object.keys(viewColumns).forEach(key => {
											if (key == changedColumn) {
												var tempObj = viewColumns;
												var changedColumnValue = !viewColumns[key];
												tempObj[key] = changedColumnValue;
												setViewColumns(tempObj);
											}
										});
									},
									textLabels: {
										body: {
											noMatch: " No data to display!",
										},
									},
									page: currentPage,
									responsive: tableResponsive,
									rowsPerPage: rowPerPage,
									rowsPerPageOptions: tableRowsPerPage,
									setTableProps: () => ({
										className: "tableRowCustomized",
									}),
								}}
								extraButtons={tableExtraButtons}
							/>
						</ThemeProvider>
					) : (
						<div className="loading-center-container">
							<Spinner animation="border" size="sm"></Spinner>
						</div>
					)}
				</div>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(SportTypes);
