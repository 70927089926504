import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";

export const MarketTierSettings = props => {
	const { handler, tier, data, setData, slug } = props;
	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);
	const [tierData, setTierData] = useState({
		name: tier?.name,
		max_bet: tier?.max_bet,
		max_win: tier?.max_win,
		// max_multiple_bet: tier?.multiple_max_bet,
		liability_limit: tier?.liability_limit,
		// max_bet_return: tier?.max_bet_return,
	});

	const editTier = () => {
		let payload = {
			tier_id: tier?.tier_id,
			max_bet: tierData?.max_bet,
			max_win: tierData?.max_win,
			// max_multiple_bet: tierData?.max_multiple_bet,
			liability_limit: tierData?.liability_limit,
			// max_bet_return: tierData?.max_bet_return,
			sport_slug: slug,
			market_id: tier?.market_id,
		};

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.sport_tier_edit, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				let actualTiers = [...data];
				let editedIndex = actualTiers.findIndex(row => row.market_id === tier?.market_id);
				actualTiers[editedIndex].max_bet = Number(tierData?.max_bet).toFixed(2);
				actualTiers[editedIndex].max_win = Number(tierData?.max_win).toFixed(2);
				// actualTiers[editedIndex].multiple_max_bet = Number(tierData?.max_multiple_bet).toFixed(2);
				actualTiers[editedIndex].liability_limit = Number(tierData?.liability_limit).toFixed(2);
				setData(actualTiers);
				handler();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		let allFields = [
			{ type: "input", name: "Name", fieldName: "name", disabled: true },
			{ type: "input", name: "Max Bet", fieldName: "max_bet" },
			{ type: "input", name: "Max Win", fieldName: "max_win" },
			// { type: "input", name: "Max Multiple Bet", fieldName: "max_multiple_bet" },
			{ type: "input", name: "Liability Limit", fieldName: "liability_limit" },
			// { type: "input", name: "Max Bet Return", fieldName: "max_bet_return" },
		];

		setFields(allFields);
	}, []);
	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{"Market Tier Settings"}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editTier} loading={loading} dataChanged={dataChanged} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						<AllComponentsLoop fields={fields} data={tierData} setData={setTierData} setDataChanged={setDataChanged} />
					</div>
				</div>
			</div>
		</div>
	);
};
