import React, { useState, useEffect } from "react";
import { BetSlipIcon, EyeIcon, LeftDarkArrowIcon, LightArrowDownIcon, PriceBoost } from "utils/icons";
import moment from "moment";
import { getOdds, userReadOnly, filterAndSortArray } from "utils/global";

import { Spinner } from "reactstrap";

import { setTelebetSportsGames, setTelebetMatch, setTelebetSport } from "store/actions";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import useGenerateBetslip from "hooks/betslip";
import getSportsData from "services/getSportsData";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useWindowSize from "hooks/useWindowSize";
import { capitalizeText } from "services/capitalizeText";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			className="tabs-tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={"span"}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const TelebetCompetitionContainer = ({ setShowBetslip }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [marketGrupId, setMarketGrupId] = useState(0);
	const [marketGrup, setMarketGrup] = useState([]);
	const [regionsData, setRegionsData] = useState([]);
	const [competitionData, setCompetitionData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [matches, setMatches] = useState([]);

	const sportsGames = useSelector(state => state.Telebet.sportsGames);
	const telebetSport = useSelector(state => state.Telebet.telebetSport);
	const telebetBets = useSelector(state => state.Telebet.telebetBets);
	const telebetUser = useSelector(state => state.Telebet.telebetUser);

	const [dropdownsData, setDropdownData] = useState({ competition_id: "", country_code: "", sports: telebetSport?.slug });

	const [field, setField] = useState([]);
	const [sports, setSports] = useState([]);

	const { width } = useWindowSize();

	const isRacing = telebetSport?.slug === "horseracing" || telebetSport?.slug === "greyhoundracing";

	const isMobile = width < 768;
	const firstAndLastName = capitalizeText(telebetUser?.first_name + " " + telebetUser?.last_name);

	const generateBetslip = useGenerateBetslip();

	const handleChangeDropdown = (newValue, fieldName) => {
		let newDropdownData = { ...dropdownsData, [fieldName]: newValue.id };

		if (fieldName === "country_code") {
			getCompetitions(newValue.id);

			if (newValue?.id == "") newDropdownData = { country_code: "", competition_id: "" };
		}

		setDropdownData(newDropdownData);
		getMatches(marketGrupId, newDropdownData);
	};

	const handleMarketChange = (event, newValue) => {
		setMarketGrupId(newValue);
		getMatches(newValue, dropdownsData);
	};

	const getMatches = (market_id, dropdownsData) => {
		setMatches([]);
		setIsLoading(true);

		let url = apiUrls.TELEBET_COMPETITIONS_MATCHES + `?sport_slug=${telebetSport?.slug}`;

		if (market_id) {
			url += `&market_group=${market_id}`;
		}
		if (dropdownsData?.country_code) {
			url += `&country_code=${dropdownsData?.country_code}`;
		}
		if (dropdownsData?.competition_id) {
			url += `&competition_id=${dropdownsData?.competition_id}`;
		}

		// else if (isRacing) {
		// 	url = apiUrls.TELEBET_COMPETITIONS_MATCHES + `?sport_slug=${telebetSport?.slug}`;
		// }

		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				const data = response?.data;
				setMatches(data);
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		if (marketGrup.length > 0 && !sportsGames?.market_id) {
			const newValue = isRacing ? "" : marketGrup.find((row, i) => i === 0).market_id;

			if (newValue) handleMarketChange("", newValue);
		}

		if (isRacing && !sportsGames?.matches) {
			setMarketGrupId(0);
			getMatches();
		}
	}, [marketGrup]);

	const handleOpen = (match, day) => {
		let newMatch = { ...match, day: day?.props?.children[0]?.props.children };
		dispatch(setTelebetMatch(newMatch));
		history.push(`/telebet?event=${match?.match_id}`);

		dispatch(setTelebetSportsGames({ matches: matches, dropdownsData: dropdownsData, market_id: marketGrupId }));
	};

	const getRegions = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.TELEBET_REGIONS + `?sport_slug=${telebetSport?.slug}`, resolve, reject, true);
		}).then(response => {
			const { data } = response;
			const formatedData = data.map(item => ({ id: item?.code, label: item.label }));
			setRegionsData(filterAndSortArray(formatedData, "label"));
		});
	};

	const getCompetitions = country_code => {
		let url = apiUrls.TELEBET_COMPETITIONS + `?sport_slug=${telebetSport?.slug}`;

		if (country_code) {
			url += `&country_code=${country_code}`;
		}

		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		}).then(response => {
			const { data } = response;
			setCompetitionData(filterAndSortArray(data, "label"));
		});
	};

	const getMarketGrup = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_event_connection_get + `?sport_event_id=0&sport_slug=${telebetSport?.slug}`, resolve, reject, true);
		}).then(response => {
			const { data } = response;
			setMarketGrup(data);
		});
	};

	const selectBet = (row, match) => {
		let tmp = { ...telebetBets };

		const selectedMarket = marketGrup?.find(({ market_id }) => market_id == marketGrupId)?.market_name;
		const { selection_id: bet_id, decimal, fractional, boosted_decimal, boosted_fractional } = row;

		const [bet_provider] = bet_id.split("-");

		const odds_decimal = getOdds({ decimal, boosted_decimal, sport_slug: telebetSport?.slug }, "decimal");
		const odds_fractional = getOdds({ fractional, boosted_fractional, sport_slug: telebetSport?.slug }, "fractional");

		const odds = { odds_decimal, odds_fractional };

		const data = { description: selectedMarket, match_name: match?.match_name, price_boosted_enabled: boosted_decimal || boosted_fractional };
		const new_bet = { ...row, ...data, bet_id, bet_provider, stake: 0, ...odds };

		const filteredSingles = tmp?.singles.filter(single => single.bet_id !== bet_id);

		if (filteredSingles.length >= tmp?.singles.length) {
			tmp?.singles?.push(new_bet);
		} else {
			tmp.singles = filteredSingles;
		}

		generateBetslip(tmp);
	};

	useEffect(() => {
		if (telebetSport) {
			if (!sportsGames?.matches) {
				setDropdownData({ ...dropdownsData, competition_id: "", country_code: "" });
				setMatches([]);
			}

			getRegions();
			getCompetitions(sportsGames?.dropdownsData?.country_code);

			if (telebetSport?.slug) getMarketGrup();
		}
	}, [telebetSport]);

	useEffect(() => {
		if (sportsGames?.dropdownsData) setDropdownData(sportsGames.dropdownsData);
		if (sportsGames?.market_id) setMarketGrupId(sportsGames?.market_id);

		getMatches(sportsGames?.market_id, sportsGames.dropdownsData);
	}, []);

	useEffect(() => {
		getSports();
	}, []);

	const getSports = async () => {
		let sports = await getSportsData();
		setSports(sports);
	};

	const handleChangeD = newValue => {
		dispatch(setTelebetSport(newValue));
		dispatch(setTelebetSportsGames({}));
		history.push(`/telebet`);
		if (newValue.id) setDropdownData({ ...dropdownsData, sports: newValue.id });
	};

	useEffect(() => {
		let fields = [
			{
				type: "dropdown",
				fieldName: "country_code",
				options: regionsData,
				handleChangeDropdown: handleChangeDropdown,
				wrapperClassNameDropdown: "p-0 align-items-center m-0",
				defaultPlaceholder: "All Countries",
			},
			{
				type: "dropdown",
				fieldName: "competition_id",
				options: competitionData,
				handleChangeDropdown: handleChangeDropdown,
				wrapperClassNameDropdown: "p-0 align-items-center m-0",
				defaultPlaceholder: "Competitions",
			},
		];

		if (isMobile) {
			fields.unshift({
				type: "dropdown",
				fieldName: "sports",
				defaultPlaceholder: "Sport",
				options: sports,
				bindValue: telebetSport?.name || "",
				handleChangeDropdown: handleChangeD,
				wrapperClassNameDropdown: "p-0 align-items-center m-0",
			});
		}

		setField(fields);
	}, [isMobile, sports, competitionData, regionsData, telebetSport]);

	const dependecies = { sports: sports, competitionData: competitionData, regionsData: regionsData };

	return (
		<div className="h-100">
			<div className="header">
				<div className="header-title">
					{isMobile ? (
						<div className="d-flex justify-content-center align-items-center gap-2" onClick={() => history.push(`/user/${telebetUser?.swifty_id}`)}>
							<LeftDarkArrowIcon className="betslip-icon" />
							<span className="telebet-sport">{telebetUser && firstAndLastName + " " + telebetUser?.player_id}</span>
						</div>
					) : (
						<span className="telebet-sport">{telebetSport?.name}</span>
					)}
					<dev className="telebet-betslip-icon">
						<BetSlipIcon onClick={() => setShowBetslip(true)} className="betslip-icon" />
						{telebetBets?.singles?.length > 0 && <span>{telebetBets?.singles?.length}</span>}
					</dev>
				</div>

				<div className="item">
					<AllComponentsLoop fields={field} data={dropdownsData} setData={setDropdownData} dependecies={dependecies} />
				</div>
			</div>

			<div className="telebet-competitions-content">
				{!isRacing && (
					<Box sx={{ borderBottom: 1, borderColor: "divider", bgcolor: "#fff", marginTop: "20px" }}>
						<Tabs value={marketGrupId} onChange={handleMarketChange} aria-label="basic tabs example" variant="fullWidth">
							{marketGrup.map((row, i) => {
								return <Tab key={i} value={row?.market_id} label={row.market_name} {...a11yProps(i)} />;
							})}
						</Tabs>
					</Box>
				)}

				<div className={`telebet-competitions ${isRacing && "telebet-competitions-sis"} ${isLoading && "d-flex justify-content-center"}`}>
					{isLoading ? (
						<Spinner animation="border" size="lg"></Spinner>
					) : (
						<TelebetCompetitions matches={matches} telebetBets={telebetBets} handleOpen={handleOpen} selectBet={selectBet} telebetSport={telebetSport} />
					)}
				</div>
			</div>
		</div>
	);
};

const TelebetCompetitions = ({ matches, telebetBets, handleOpen, selectBet, telebetSport }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	const { width } = useWindowSize();
	const isMobile = width < 1024;

	const displayTime = start_time => {
		const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const start_timeGMT = moment.utc(start_time);
		const userStartDate = new Date(start_timeGMT.toLocaleString("en-US", { timeZone: userTimezone }));
		const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: userTimezone }));
		if (
			userStartDate.getDate() === currentDate.getDate() &&
			userStartDate.getMonth() === currentDate.getMonth() &&
			userStartDate.getFullYear() === currentDate.getFullYear()
		) {
			return (
				<>
					<span>Today</span>
					<span>
						{userStartDate.toLocaleTimeString("en-US", {
							timeZone: userTimezone,
							hour: "2-digit",
							minute: "2-digit",
							hour12: false, // Display in 24-hour format
						})}
					</span>
				</>
			);
		} else {
			const tomorrow = new Date(currentDate);
			tomorrow.setDate(currentDate.getDate() + 1);
			if (
				userStartDate.getDate() === tomorrow.getDate() &&
				userStartDate.getMonth() === tomorrow.getMonth() &&
				userStartDate.getFullYear() === tomorrow.getFullYear()
			) {
				return (
					<>
						<span>Tomorrow</span>
						<span>
							{userStartDate.toLocaleTimeString("en-US", {
								timeZone: userTimezone,
								hour: "2-digit",
								minute: "2-digit",
								hour12: false, // Display in 24-hour format
							})}
						</span>
					</>
				);
			} else {
				return (
					<>
						<span>
							{userStartDate.toLocaleDateString("en-US", {
								timeZone: userTimezone,
								day: "numeric",
								month: "short",
								year: "numeric",
							})}
						</span>
						<span>
							{userStartDate.toLocaleTimeString("en-US", {
								timeZone: userTimezone,
								hour12: false, // Display in 24-hour format
								hour: "2-digit",
								minute: "2-digit",
							})}
						</span>
					</>
				);
			}
		}
	};

	const showRunners = telebetSport?.slug === "horseracing" || telebetSport?.slug === "greyhoundracing";

	const [match, setMatch] = useState([]);

	const openCompetition = row => {
		const { competition_id } = row;

		if (match.includes(competition_id)) {
			const updatedMatch = match.filter(item => item !== competition_id);
			setMatch(updatedMatch);
		} else {
			setMatch([...match, competition_id]);
		}
	};

	useEffect(() => {
		if (matches.length > 0) setMatch([matches[0]?.competition_id, matches[1]?.competition_id]);
	}, []);

	return (
		<>
			{matches?.map((row, index) => {
				const is_manual = row?.is_manual;
				const isOpen = isMobile ? match.some(item => item === row.competition_id) : true;

				return (
					<div className="telebet-competition" key={index}>
						<div className="competition-name" onClick={() => isMobile && openCompetition(row)}>
							<span>{is_manual ? "Manual Bet" : row?.competition_name}</span>
							<LightArrowDownIcon className={`icon ${isOpen && "arrow-active"}`} />
						</div>

						{isOpen && (
							<div className="telebet-header">
								<span className="telebet-time">Time</span>
								<span className="telebet-event">Event</span>
							</div>
						)}

						{isOpen &&
							row.matches.map(match => {
								let rowClick = false;
								if (showRunners) {
									if (match?.is_manual || is_manual) rowClick = false;
									else rowClick = true;
								}

								const match_name = match?.is_manual || is_manual ? match?.selections[0].selection_name : match?.match_name;

								const homeTeam = match_name ? match_name.split(" vs ")[0] : "";
								const awayTeam = match_name ? match_name.split(" vs ")[1] : "";
								return (
									<>
										{!showRunners && (
											<div className="match-header">
												{match?.is_manual || is_manual ? (
													!showRunners && <div className="odd-no-eye"></div>
												) : (
													<button className="btn-telebet-event m-0" onClick={() => handleOpen(match)}>
														<EyeIcon />
													</button>
												)}
												<div className="match-time">{match?.is_manual ? "" : displayTime(match?.start_time_utc)}</div>
											</div>
										)}
										<div
											className={`${match?.is_manual ? "telebet-row border-0" : "telebet-row"} ${showRunners && "telebet-row-sis"}`}
											key={match?.match_id}
											onClick={() => rowClick && handleOpen(match, displayTime(match?.start_time_utc))}
										>
											<div className={`d-flex align-items-center w-100 ${isUserReadOnly && "pe-none"}`}>
												<div className={`telebet-time ${showRunners && "telebet-time-sis"}`}>
													{match?.is_manual ? "" : displayTime(match?.start_time_utc)}
												</div>

												<div className="telebet-event">
													<div className="match-teams">
														{homeTeam && <span>{homeTeam}</span>}
														{!showRunners && <span className="vs">vs</span>}
														{awayTeam && <span>{awayTeam}</span>}
													</div>
													{!showRunners && (
														<div className="match-score">
															<span>vs</span>
														</div>
													)}
												</div>
												{!showRunners || match?.is_manual || is_manual ? (
													<div className="telebet-selections">
														{match?.selections.map(selection => {
															const { decimal, fractional, boosted_decimal, boosted_fractional, selection_disabled, trading_status } = selection;

															const isSuspended = trading_status == "suspended";
															const oddsObj = { decimal, fractional, boosted_decimal, boosted_fractional, isSuspended };
															let className = "telebet-odd";
															const isSelected = telebetBets?.singles.some(row => selection.selection_id === row?.bet_id);

															if (isSuspended) className += " suspended";

															if (isSelected) className += " selected";
															else if (selection_disabled) className += " disabled";

															if (boosted_decimal) className += " telebet-odd-boost";

															return (
																<div
																	onClick={() => !selection_disabled && !isSuspended && selectBet(selection)}
																	className={className}
																	key={selection?.selection_id}
																>
																	{getOdds(oddsObj, user?.odds_display, true)}
																	{(boosted_decimal || boosted_fractional) && !isSuspended && (
																		<PriceBoost className={isSelected ? "selected" : ""} />
																	)}
																</div>
															);
														})}
													</div>
												) : (
													""
												)}

												{match?.is_manual || is_manual ? (
													!showRunners && <div className="odd-no-eye"></div>
												) : (
													<div className={showRunners ? "ms-auto" : "btn-odd-eye"}>
														<button className="btn-telebet-event" onClick={() => handleOpen(match)}>
															<EyeIcon />
														</button>
													</div>
												)}
											</div>
										</div>
									</>
								);
							})}
					</div>
				);
			})}
		</>
	);
};

export default TelebetCompetitionContainer;
