import React from "react";
import { EditIconButton } from "components/GeneralComponents/AddButton";
import HTMLReactParser from "html-react-parser";

export const getRegionsTableColumns = ({ regionsData, handleEditItem }) => {
	return [
		{
			name: "id",
			label: "id",
			className: "name",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "20px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "20px" }, className: "max-button" }),
			},
		},
		{
			name: "name",
			label: "Name",
			className: "name",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "20px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "20px" }, className: "max-button" }),
			},
		},
		{
			name: "active",
			label: "Status",
			className: "current_status match_status",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = regionsData[meta.currentTableData[meta.rowIndex].index];
					var status_txt =
						record.status == 0 || null
							? `<span className="badge badge-sports-inactive"> Inactive </span>`
							: `<span className="badge badge-sports-published"> Published </span>`;
					return HTMLReactParser(status_txt);
				},
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "700px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "700px" }, className: "max-button" }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = regionsData[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons" style={{ marginRight: "10px" }}>
								<EditIconButton
									onClick={() => {
										handleEditItem(row);
									}}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "30px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "30px" } }),
			},
		},
	];
};
