import React, { useEffect, useState } from "react";
import { getBannerTableColumns } from "./bannerColumns";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ConfirmDeleteComponent from "components/GeneralComponents/ConfirmDeleteComponent";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { BannerPromotion } from "./bannerPromotion";
import { getContent } from "services/getContentAllData";
import { InfoIconHelper } from "utils/icons";
import { setBackdrop } from "store/Backdrop/actions";
import InformationPageHelper from "../InformationPageHelper";
import { BannerHelpInformation } from "../HelpPagesForSettings/HelpPages";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const Banner = props => {
	const [showPromotion, setShowPromotion] = useState(false);
	const [heroBannerData, setHeroBannerData] = useState([]);
	const [editItem, setEditItem] = useState(null);
	const pageLayoutType = "home_page";
	const [deleteItem, setDeleteItem] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [itemTitle, setItemTitle] = useState("");
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();
	const pageHeaderData = {
		international: false,
		addNew: true,
		addNewClick: () => {
			setShowPromotion(true);
		},
		removeHeader: true,
	};
	let country = useSelector(state => state.Login.country);

	const heroBanner = async () => {
		setIsDataLoading(true);
		if (!country || country === "undefined") {
			country = "all";
		}

		let response = await getContent("banner", country);
		if (response?.length > 0) {
			response.forEach(element => {
				if (element?.active) {
					element["status"] = "Published";
				} else {
					element["status"] = "Inactive";
				}
				if (element?.details) {
					let parsedDetails = JSON.parse(element["details"]);
					parsedDetails["publish_start_date_time"] = element["publish_start_date_time"];
					parsedDetails["publish_stop_date_time"] = element["publish_stop_date_time"];
					parsedDetails["status"] = element["status"];
					element["details"] = JSON.stringify(parsedDetails);
				}
			});
			setHeroBannerData(response);
			setUnfilteredData(response);
			setIsDataLoading(false);
		} else {
			setIsDataLoading(false);
			setHeroBannerData([]);
			setUnfilteredData([]);
		}
	};

	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};

	const heroBannerTableColumns = getBannerTableColumns({
		heroBannerData,
		props,
		handleEditItem,
		setDeleteItem,
		setItemTitle,
	});

	useEffect(() => {
		heroBanner();
	}, [country]);
	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	return (
		<>
			<HelmetLayout titleProps={"Banners"}>
				<div className="cms-page pt-0">
					<SingleSearchFilter
						setData={setHeroBannerData}
						unfilteredData={unfilteredData}
						detailsColumn={["title", "publish_start_date_time", "publish_stop_date_time", "status"]}
					/>
					<MUIDataTable
						columns={heroBannerTableColumns}
						data={heroBannerData}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							sort: true,
							elevation: 0,
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							responsive: tableResponsive,
							rowsPerPage: 50,
							rowsPerPageOptions: tableRowsPerPage,
							customToolbar: () => <AddTableButton onClick={pageHeaderData["addNewClick"]} />,
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
				{deleteItem && (
					<ConfirmDeleteComponent
						showDeleteCom={deleteItem}
						deleteLoading={deleteLoading}
						setDeleteLoading={setDeleteLoading}
						deleteTitle={"Confirm Deletion of Promotion"}
						titlename={itemTitle}
						setShowDeleteCom={setDeleteItem}
						betSlipData={heroBannerData}
						setBetSlipData={setHeroBannerData}
						id={deleteItem?.id}
					/>
				)}
				{showPromotion && (
					<BannerPromotion
						title={"Banner"}
						editItem={editItem}
						countires={country}
						pageLayoutType={pageLayoutType}
						getHeroBanner={heroBanner}
						handler={() => {
							setShowPromotion(false);
							setEditItem(null);
						}}
					/>
				)}
				{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			</HelmetLayout>
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<BannerHelpInformation />
				</InformationPageHelper>
			)}
		</>
	);
};

export default Banner;
