import React, { useEffect, useState } from "react";
import "./market.scss";
import { connect, useSelector } from "react-redux";
import SearchFilter from "components/SearchFilter/searchFilter";
import { setBreadcrumbItems } from "../../../store/actions";
import { getMarketsTableColumns } from "./marketsTableColumns";
import { getMarketsFilterColumns } from "./marketsFilterColumns";
import { AddMarketsModal } from "./addMarketsModal";
import { DeleteMarketModal } from "./deleteMarketModal";
import { EditSelectionMarketModal } from "./editSelectionMarketModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";
import MUIDataTable from "mui-datatables";
import { prepareFilterData } from "utils/global";
import { getMarkets } from "services/getMarkets";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const Markets = props => {
	const marketsFilterData = useSelector(state => state.Login.searchFilterData.markets);

	const [modalShow, setModalShow] = useState({
		addModal: false,
		addSelectionModal: false,
		deleteModal: false,
		selectionModal: false,
		editSelectionModal: false,
		deleteSelectionTypeModal: false,
		editMarketTypeModal: false,
	});
	const [rowPerPage, setRowPerPage] = useState(50);
	const [loadingButton, setLoadingButton] = useState({
		filterButton: false,
		modalButton: false,
		editSelectionButton: false,
	});

	const [records, setRecords] = useState([]);
	const [selectionModalMarket, setSelectionModalMarket] = useState(null);

	const [deleteId, setDeleteId] = useState("");
	const [currentPage, setCurrentPage] = useState(0);
	const sports = useSelector(state => state.Login.gsData.sports);

	const [isDataLoading, setIsDataLoading] = useState(false);
	const [marketItem, setMarketItem] = useState(false);
	const breadcrumbItems = [{ title: "Markets", link: "/markets" }];

	useEffect(() => {
		props.setBreadcrumbItems("Markets", breadcrumbItems);
	}, []);

	let marketsFilterColumns = getMarketsFilterColumns({ sports });

	const filterMarkets = () => {
		const formatedData = prepareFilterData(marketsFilterData, marketsFilterColumns);
		setIsDataLoading(true);
		setCurrentPage(0);
		setLoadingButton({ ...loadingButton, filterButton: true });
		setModalShow({ ...modalShow, selectionModal: false });
		const param =
			"?sport_slug=" +
			formatedData?.sport_id +
			"&market_name=" +
			formatedData?.name +
			"&market_id=" +
			formatedData?.market_id +
			"&active=" +
			formatedData?.status;
		getMarkets(param)
			.then(data => {
				setRecords(data);
			})
			.finally(() => {
				setLoadingButton({ ...loadingButton, filterButton: false });
				setIsDataLoading(false);
			});
	};

	const getSelection = record => {
		setSelectionModalMarket(record);

		setModalShow({ ...modalShow, selectionModal: true });
	};

	const submitDeleteMarketType = () => {
		setLoadingButton({ ...loadingButton, modalButton: true });
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.DELETE_MARKET + deleteId, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					var currentVariable = records.filter(x => x.id != deleteId);
					setRecords(currentVariable);
				}
			})
			.finally(() => {
				setModalShow({ ...modalShow, deleteModal: false });
				setLoadingButton({ ...loadingButton, modalButton: false });
				setIsDataLoading(false);
			});
	};

	let marketsTableColumns = getMarketsTableColumns({
		records,
		getSelection,
		setMarketItem,
		setModalShow,
		modalShow,
		setDeleteId,
	});

	let page_name = "Markets";

	return (
		<HelmetLayout titleProps={"Markets"}>
			{marketItem && <AddMarketsModal filterMarkets={filterMarkets} setMarketItem={setMarketItem} marketItem={marketItem} />}

			<div className={modalShow?.selectionModal ? "cms-page edit_cms_page pt-0 cms-table-page" : "cms-page pt-0 cms-table-page"}>
				<div className="col-10 d-flex justify-content-between">
					<SearchFilter
						columns={marketsFilterColumns}
						page={"markets"}
						preSelectedType={"text"}
						preSelectedValue={"name"}
						preSelectedName={"Name"}
						preSelectedData={[]}
						preSelectedOptionKey={""}
						preSelectedOptionName={""}
						filterHandler={filterMarkets}
						filterLoading={isDataLoading}
						customizedFilter={true}
					/>
				</div>

				{modalShow.deleteModal && (
					<DeleteMarketModal
						modalShow={modalShow}
						setModalShow={setModalShow}
						submitDeleteMarketType={submitDeleteMarketType}
						loadingButton={loadingButton}
					/>
				)}

				{modalShow.selectionModal && (
					<EditSelectionMarketModal modalShow={modalShow} setModalShow={setModalShow} selectionModalMarket={selectionModalMarket} />
				)}

				<MUIDataTable
					columns={marketsTableColumns}
					data={records}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						sortOrder: {
							name: "sport_name",
							direction: "asc",
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: page_name + ".csv" },
						pagination: true,
						customToolbar: () => <AddTableButton onClick={() => setMarketItem(true)} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};
export default connect(null, { setBreadcrumbItems })(Markets);
