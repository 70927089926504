import React, { useState, useEffect } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { PercentageIcon, XCloseIcon } from "utils/icons";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "hooks/useSearchParams";

export const EditRresultsMarketMatch = ({ loading, setResultEditMatch, setResultEditData, resultEditData, getResultsMatch }) => {
	const location = useLocation();
	const { sport_slug } = useSearchParams(location);

	const [dataChanged, setDataChanged] = useState(false);
	const [fields, setFields] = useState([]);

	const isGolf = sport_slug === "golf";

	const editResultMarket = () => {
		const { pathname } = location;
		const match_id = pathname.split("/")[2];

		let payload = {
			selection_id: resultEditData?.selection_id,
			id: resultEditData?.id,
			result: resultEditData?.result,
			partial_win: resultEditData?.result === "partial" ? resultEditData?.partial_win : 0,
			// partial_push: resultEditData?.partial_push,
		};

		if (isGolf) payload = { ...payload, position: resultEditData?.position, runners_position: resultEditData?.runners_position };

		new Promise((resolve, reject) => {
			ApiServices.post(`${apiUrls.EDIT_RESULTS_MARKET}`, resolve, reject, payload, true);
		})
			.then()
			.finally(() => {
				setResultEditMatch(false);
				getResultsMatch(match_id);
			})
			.catch(e => {
				console.log("err", e);
			});
	};

	useEffect(() => {
		let tempFields = [
			{ type: "input", name: "Market", fieldName: "selection_name", disabled: true },
			{ type: "dropdown", name: "Result", fieldName: "result", optionsType: "result_market", params: isGolf ? "golf" : "" },
		];

		if (resultEditData.result === "partial") {
			tempFields.push({ type: "input", name: "Partial Win", fieldName: "partial_win", iconInsideInput: true, icon: <PercentageIcon /> });
		}

		if (isGolf) {
			tempFields.push(
				{ type: "input", name: "Position", fieldName: "position" },
				{ type: "input", name: "Number of Selections", fieldName: "runners_position" }
			);
		}

		setFields(tempFields);
	}, [resultEditData]);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Results</span>
				<XCloseIcon onClick={() => setResultEditMatch(false)} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editResultMarket} loading={loading} dataChanged={dataChanged} onClose={() => setResultEditMatch(false)} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						<AllComponentsLoop fields={fields} data={resultEditData} setData={setResultEditData} setDataChanged={setDataChanged} />
					</div>
					<div className="promotion-right col-12 col-lg-6"></div>
				</div>
			</div>
		</div>
	);
};
