import React, { useEffect, useRef, useState } from "react";
import { CloseIconIframe } from "utils/icons";
import MUIDataTable from "mui-datatables";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import { getFormatDateByUserTimezone } from "utils/global";
import useOutsideClick from "hooks/useOutsideClick";
import { _resultBets } from "utils/constants";
import { tableBarSettings } from "utils/tableSettings";

export const BetHistoryModal = props => {
	const { bet_id, setShowBetHistoryModal } = props;

	const modalRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [logs, setLogs] = useState([]);

	const getBetLogs = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.GET_PLAYER_BETS}${bet_id}/logs`, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;
				setLogs(data?.logs);
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		if (bet_id) getBetLogs();
	}, [bet_id]);

	const logsColumn = getbetHistoryTableColumns({ logs });

	useOutsideClick(modalRef, () => setShowBetHistoryModal(false));

	return (
		<div className="bet-history-modal">
			<div className="bet-history-modal-overlay">
				<div className="bet-history-modal-container" ref={modalRef}>
					<div className="bet-history-modal-title">
						<span>Bet History</span>
						<CloseIconIframe onClick={() => setShowBetHistoryModal(false)} />
					</div>
					<div className="bet-history-modal-content">
						<MUIDataTable
							columns={logsColumn}
							data={logs}
							options={{
								pagination: false,
								...tableBarSettings,
								selectableRows: "none",
								sort: true,
								elevation: 0,
								textLabels: {
									body: {
										noMatch: isLoading ? <LoadingSpinner /> : " No data to display!",
									},
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const getbetHistoryTableColumns = ({ logs }) => {
	return [
		{
			name: "changed_at",
			label: "DATE",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = logs ? logs[meta.currentTableData[meta.rowIndex].index] : {};
					const { changed_at } = row;

					return <>{getFormatDateByUserTimezone(changed_at)}</>;
				},
				setCellProps: () => ({ style: { minWidth: "110px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "110px" } }),
			},
		},
		{
			name: "user_name",
			label: "RESULTED BY",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "name",
			options: {
				customBodyRender: (value, meta) => {
					const row = logs ? logs[meta.currentTableData[meta.rowIndex].index] : {};
					const { user_name } = row;

					return <>{user_name ? user_name : "-"}</>;
				},
				setCellProps: () => ({ style: { minWidth: "130px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "130px" } }),
			},
		},
		{
			name: "result",
			label: "RESULT TYPE",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = logs ? logs[meta.currentTableData[meta.rowIndex].index] : {};
					const { result } = row;

					const newResult = result ? _resultBets.find(row => row.id === result) : result;

					return <>{newResult ? newResult?.label : "-"}</>;
				},
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "action",
			label: "ACTION",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = logs ? logs[meta.currentTableData[meta.rowIndex].index] : {};
					const { action } = row;
					return <>{action ? action?.toUpperCase() : "-"}</>;
				},
				setCellProps: () => ({ style: { minWidth: "90px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "90px" } }),
			},
		},
		{
			name: "amount",
			label: "PAYOUT",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = logs ? logs[meta.currentTableData[meta.rowIndex].index] : {};
					const { amount } = row;

					return <>{amount ? amount : "-"}</>;
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "90px" } }),
				setCellHeaderProps: () => ({ style: { textAlign: "right", minWidth: "00px" } }),
			},
		},
	];
};
