import React from "react";
import { useSelector } from "react-redux";
import { getOdds, getTrainerAndJockeyForTelebetSis } from "utils/global";

import useGenerateBetslip from "hooks/betslip";

const fields = [
	{ name: "1st", fieldName: "first", place: 1 },
	{ name: "2nd", fieldName: "second", place: 2 },
	{ name: "3rd", fieldName: "third", place: 3 },
	{ name: "Any ", fieldName: "any", place: "any" },
];

export default function TelebetHorseracingSecondTable({ telebetBets, matchMarketsFilter, telebetMatch }) {
	const telebetSport = useSelector(state => state.Telebet.telebetSport);

	const generateBetslip = useGenerateBetslip();

	const onSelect = (item, finish, runners, isUnnamedFavorite) => {
		const place = fields?.find(({ fieldName }) => fieldName == finish)?.place;

		const { selection_id: bet_id, decimal, fractional, boosted_decimal, boosted_fractional } = item;

		const isSp = decimal == "SP" || !decimal;

		const [bet_provider] = bet_id.split("-");

		const odds_decimal = isSp ? "SP" : getOdds({ decimal, boosted_decimal, sport_slug: telebetSport?.slug }, "decimal");
		const odds_fractional = isSp ? "SP" : getOdds({ fractional, boosted_fractional, sport_slug: telebetSport?.slug }, "fractional");

		const odds = { odds_decimal, odds_fractional };

		let temp = { ...telebetBets };

		let exist = false;
		let racingBetsCounter = 0;

		temp.singles.forEach(bet => {
			if (bet.place) {
				racingBetsCounter++;
			}

			if (item.selection_id === bet.bet_id) {
				temp.singles = temp.singles.filter(bet => item.selection_id !== bet.bet_id);
				racingBetsCounter--;
				exist = true;
			}
		});

		let new_bet = {
			...item,
			stake: 0,
			place,
			bet_id: item?.selection_id,
			bet_provider,
			event_id: runners?.event_id,
			description: "Race Winner",
			...odds,
			name: item?.selection_name,
			match_name: runners?.market_name,
			sport_slug: telebetSport?.slug,
		};

		if (!isSp) new_bet.price = true;
		if (isSp) new_bet.starting_price = true;
		if (isUnnamedFavorite) new_bet.bet_type = "unnamed_favorite";

		if (racingBetsCounter === 1) {
			temp.forecast = true;
		}

		if (racingBetsCounter === 2) {
			temp.tricast = true;
		}

		if (!exist) {
			temp.singles.push(new_bet);
		}

		generateBetslip(temp);
	};

	return matchMarketsFilter?.map(({ runners, non_runners }, i) => {
		return (
			<div key={i} className="telebet-matchs">
				<div className="telebet-match-title">
					<span className="">{telebetMatch?.competition_name}</span>
					<span className="telebet-match-sutitle">{runners?.market_name}</span>
				</div>
				<div className="d-flex telebet-meach-header ">
					<span className="telebet-match-span-row telebet-match-odds">#</span>
					<div className={`telebet-match-span-row justify-content-start w-100`}>Runner</div>
					<span className="telebet-match-span-row telebet-match-odds">1st</span>
					<span className="telebet-match-span-row telebet-match-odds">2nd</span>
					{runners?.tricast && <span className="telebet-match-span-row telebet-match-odds">3rd</span>}
					<span className="telebet-match-span-row telebet-match-odds">Any Order</span>
				</div>
				{runners?.selections?.map((item, i) => {
					const isTricast = item.tricast;
					const isSuspended = item?.trading_status == "suspended";

					let filterFields = fields;

					let unnamedFavorite = "w-100";
					const isUnnamedFavorite = item?.selection_name === "Unnamed Favourite";

					if (isUnnamedFavorite) {
						unnamedFavorite = "w-100";
						filterFields = fields.filter(({ fieldName }) => fieldName == "any");
					} else if (isTricast) filterFields = fields;
					else {
						unnamedFavorite = "w-100";
						filterFields = fields.filter(({ fieldName }) => fieldName != "third");
					}

					return (
						<div className="d-flex telebet-meach-header" key={i}>
							<div className={`telebet-match-span-column d-flex flex-column justify-content-evenly telebet-match-odds`}>
								{item?.couple_number && <span>{item?.couple_number}</span>}
								{item?.drawn && <span>({item?.drawn})</span>}
							</div>
							<div className={`telebet-match-span-column d-flex flex-column align-items-start ${unnamedFavorite}`}>
								{item?.selection_name && <span>{item?.selection_name}</span>}
								{/* {item?.selection_name} */}
								<span className="telebet-match-column-sutitle">{getTrainerAndJockeyForTelebetSis(item?.trainer, item?.jockey)}</span>
							</div>
							{filterFields.map((row, i) => {
								let selectionType;
								let eventIdInBetslip;

								const selectedItem = telebetBets.singles.find(element => {
									if (!eventIdInBetslip) {
										eventIdInBetslip = element.event_id;
									}

									if (element.place === "any") {
										selectionType = "any";
										return element.bet_id === item.selection_id && element.place === row?.place;
									}

									if (element.place === 1 || element.place === 2 || element.place === 3) {
										selectionType = "place";
										return element.bet_id === item.selection_id || element.place === row?.place;
									}

									return element.bet_id === item.selection_id;
								});

								const currentPlaceIsSelected = selectedItem?.place === row?.place && selectedItem?.bet_id !== item.selection_id;

								const currentRunnerIsSelected = selectedItem?.place !== row?.place && selectedItem?.bet_id === item.selection_id;

								const selectionIsAny = selectionType === "place" && row?.place === "any";
								const selectionsIsNotAny = selectionType === "any" && row?.place !== "any";

								const isActive = selectedItem?.place === row?.place && selectedItem?.bet_id === item.selection_id;
								const isDisabled =
									(eventIdInBetslip ? eventIdInBetslip !== runners?.event_id : false) ||
									(selectionType === "place" ? currentPlaceIsSelected || currentRunnerIsSelected || selectionIsAny : selectionsIsNotAny);

								return (
									<span
										className={`telebet-match-span-column telebet-match-odds mouse-hover ${isDisabled ? "disable" : ""} ${
											isActive ? "selected" : ""
										}`}
										onClick={() => (!isDisabled ? onSelect(item, row?.fieldName, runners, isUnnamedFavorite) : "")}
										key={i}
									>
										{isSuspended ? "SUSP" : row.name}
									</span>
								);
							})}
						</div>
					);
				})}
				{non_runners?.length > 0 && (
					<>
						<div className="telebet-match-title justify-content-center">Non Runner</div>
						{non_runners?.map((item, i) => {
							const isTricast = item.tricast;

							let filterFields = fields;

							if (isTricast) filterFields = fields;
							else filterFields = fields.filter(({ fieldName }) => fieldName != "third");

							return (
								<div className="d-flex telebet-meach-header disabled" key={i}>
									<div className="telebet-match-span-column d-flex flex-column justify-content-evenly telebet-match-odds">
										{item?.couple_number && <span>{item?.couple_number}</span>}
										{item?.drawn && <span>({item?.drawn})</span>}
									</div>
									<div className="telebet-match-span-column w-100 d-flex flex-column align-items-start">
										{item?.selection_name}
										<span className="telebet-match-column-sutitle">{getTrainerAndJockeyForTelebetSis(item?.trainer, item?.jockey)}</span>
									</div>
									{filterFields.map((row, i) => {
										return (
											<span className="telebet-match-span-column telebet-match-odds" key={i}>
												NR
											</span>
										);
									})}
								</div>
							);
						})}
					</>
				)}
			</div>
		);
	});
}
