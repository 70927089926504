import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import React, { useEffect, useState } from "react";
// import AddIcon from "@mui/icons-material/Add";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import MUIDataTable from "mui-datatables";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import { getNotificationBarTableColumns } from "./getNotificationBarColumns";
import "./NotificationBar.scss";
import NotificationBarModal from "./notificationBarModal";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const NotificationBar = () => {
	const [data, setData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [showNotificationModal, setShowNotificationModal] = useState(false);
	const [editItem, setEditItem] = useState("");
	const [dataChanged, setDataChanged] = useState(false);

	const getNotificationData = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.notification_bar, resolve, reject, true);
		})
			.then(response => {
				const data = response?.data;
				setData(data);
				setUnfilteredData(data);
			})
			.finally(() => setIsDataLoading(false));
	};

	useEffect(() => {
		getNotificationData();
	}, []);

	const handleEditItem = row => {
		setEditItem(row);
		setShowNotificationModal(true);
	};

	const handleDeleteItem = row => {
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.delete_notification_bar + `/${row?.id}`, resolve, reject, true);
		}).then(() => {
			getNotificationData();
		});
	};

	const handler = () => {
		setShowNotificationModal(false);
	};
	const handleShowAddModal = () => {
		setShowNotificationModal(true);
		setEditItem("");
	};

	const notificationBarColumns = getNotificationBarTableColumns({ data, handleEditItem, handleDeleteItem, setShowNotificationModal });

	return (
		<HelmetLayout titleProps={"Notification Bar"}>
			<div className="cms-page pt-0">
				<SingleSearchFilter setData={setData} unfilteredData={unfilteredData} detailsColumn={["title", "start_date", "end_date", "status"]} />
				<MUIDataTable
					columns={notificationBarColumns}
					data={data}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						sort: true,
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						pagination: true,
						responsive: tableResponsive,
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
						customToolbar: () => <AddTableButton onClick={() => handleShowAddModal()} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
			{showNotificationModal && (
				<NotificationBarModal
					setIsLoading={setIsDataLoading}
					getNotificationData={getNotificationData}
					editItem={editItem}
					setEditItem={setEditItem}
					title="Notification Bar"
					handler={handler}
					dataChanged={dataChanged}
					setDataChanged={setDataChanged}
				/>
			)}
		</HelmetLayout>
	);
};

export default NotificationBar;
