export const getSpecialsFilterColumns = () => {
	return [
		{
			name: "Date",
			type: "date",
			value: "date",
			data: [],
		},
		{
			name: "Bet Status",
			type: "select",
			value: "bet_status",
			data: JSON.stringify([
				{
					value: "0",
					name: "Open",
				},
				{
					value: "1",
					name: "Closed",
				},
			]),
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "Result",
			type: "select",
			value: "result",
			data: JSON.stringify([
				{ value: "Winner", name: "Winner" },
				{ value: "Loser", name: "Loser" },
				{ value: "Pushed", name: "Pushed" },
				{ value: "Autoclose", name: "Autoclose" },
			]),
			optionKey: "value",
			optionName: "name",
		},
	];
};
