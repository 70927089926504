import { Autocomplete, TextField, Typography, capitalize, createFilterOptions } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import CodeMirror from "@uiw/react-codemirror";
// import { AvField } from "availity-reactstrap-validation";
import { ThousandSeperated, formatToLocalDatetime } from "hooks/General";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Spinner } from "reactstrap";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { _daysOptions, _inputTypeOptions, _pageLayoutOptions, getLanguages, returnDayIndex } from "utils/constants";
import { filterAndSortArray, isIsoDate } from "utils/global";
import "./GeneralComponents.css";
import {
	ArrowDownIcon,
	CalendarIcon,
	CopyIcon,
	DarkArrowDownIcon,
	DeleteIcon,
	DeleteXIcon,
	InfoIcon,
	PredictionInformationIcon,
	ResetIcon,
	SearchFilterIcon,
	SearchIcon,
	TickIcon,
	TimeIcon,
	TranslateIcone,
	TrashIcon,
	UploadIcon,
	XSmIcon,
	DragAndDropFillIcon,
	DregAndDropIcone,
	XCloseIcon,
} from "../../utils/icons";
import { AddButton, DeleteIconButton, EditIconButton, NowButton } from "./AddButton";
import saveImages from "services/addMultipleImages";
import { useDropzone } from "react-dropzone";
import { getCasinoGame } from "services/getCasinoGame";
import { capitalizeText } from "services/capitalizeText";
import DragAndDropList from "components/GeneralComponents/DragAndDrop";

// import { getAllSportsDropdown } from "services/getAllSportsDropdown";

const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;
const editorApiUrl = process.env.REACT_APP_EDITOR_API_KEY;

export const SwitchInput = ({ object, handleChange, onChange }) => {
	//This handler is temporary for the changeStatus of user
	let onChangeEventTrigger = object.onChangeEvent;
	const name = object?.name || "Published";
	let checked = object?.value == "0" || !object?.value ? 0 : object?.value;
	const switchTitleClassName = object?.switchTitleClassName || "";
	const wrapperClassName = name
		? `row wrapper_customized_modal ${object?.wrapperClassName || ""} dropdown_published`
		: `row wrapper_customized_modal dropdown_published justify-content-center`;
	const disabled = object?.disabled || false;
	const infoIcon = object?.infoIcon || false;
	return (
		<div className={wrapperClassName}>
			<div className={`col-12 col-lg-4 d-flex align-items-center input-switch  ${switchTitleClassName}`}>
				{name}
				{infoIcon && (
					<span className="infoIconContainer">
						<InfoIcon />
					</span>
				)}
			</div>

			<div className="col-12 col-lg-8 d-flex align-items-center switch-input">
				<label className="switch ">
					<input
						type="checkbox"
						className="published-input"
						checked={checked}
						onChange={
							onChangeEventTrigger ||
							onChange ||
							(() => {
								handleChange(!checked, object.fieldName, object.type, "", object?.emptyValues);
							})
						}
						disabled={disabled}
					/>

					<span className="slider round"></span>
				</label>
			</div>
		</div>
	);
};
export const SportsDataInput = ({ title, description, data, options, setData, property, sports }) => {
	let actualSelection = options;
	return (
		<>
			<div className="row mt-3">
				<div className={`col-12 col-lg-4 input-title`}>{title}</div>
				<div className="col-12 col-lg-8 d-flex align-items-center dropdown-wrapper">
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={sports ? sports : options}
						getOptionLabel={option => option}
						sx={{ width: 300 }}
						renderInput={params => <TextField placeholder={description} {...params} />}
						onChange={(event, newValue, reason) => {
							let actualSelection = [options];
							if (reason !== "clear") {
								let exist = actualSelection.some(row => row === newValue);
								if (!exist) {
									actualSelection[0].push(newValue);
								}

								setData({
									...data,
									[property]: actualSelection,
								});
							}
						}}
					/>
				</div>
			</div>
			<div className="row">
				<div className={`col-12 col-lg-4 input-title`}></div>
				<div className="col-12 col-lg-8 d-flex align-items-center flex-column">
					{actualSelection &&
						property != "bog_max_payment" &&
						actualSelection.length > 0 &&
						actualSelection.map((row, index) => {
							let selectedCountry = index === 0 ? "first-day" : "";
							return (
								<div className={selectedCountry + ` seleted-day`} key={index}>
									<span>{row}</span>
									<span
										className="delete-selected-day"
										onClick={() => {
											let actualSelection = [options];
											actualSelection = actualSelection[0].filter(el => el != row);
											setData({
												...data,
												[property]: actualSelection,
											});
										}}
									>
										<TrashIcon />
									</span>
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};
export const GeneralSportsDropdownData = ({
	title,
	description,
	data,
	options,
	setData,
	property,
	onChange,
	getOptionLabel,
	actualSelection,
	extraRows,
	freeBets,
	setFreeBets,
	firstRowClass,
	otherRowClass,
	readOnly,
}) => {
	actualSelection = options || actualSelection;
	return (
		<>
			<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
				<div className={`col-12 col-lg-4 input-title`} style={{ width: "115px" }}>
					{title}
				</div>
				<div
					className={`dropdown-wrapper 
						${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
				>
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={options}
						getOptionLabel={getOptionLabel}
						sx={{ width: 300 }}
						renderInput={params => <TextField placeholder={description} {...params} />}
						onChange={onChange}
						data={data}
						setData={setData}
						readOnly={readOnly}
					/>
				</div>
			</div>
			<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row "}>
				<div className={`col-12 col-lg-4 input-title`} style={{ width: "115px" }}></div>
				<div
					className={
						firstRowClass || otherRowClass
							? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal flex-column"
							: "col-12 col-lg-8 d-flex align-items-center  flex-column"
					}
				>
					{actualSelection &&
						property == "sportsbook_restrictions" &&
						actualSelection.length > 0 &&
						extraRows &&
						extraRows?.length > 0 &&
						extraRows.map((row, index) => {
							let selectedSport = index === 0 ? "first-day" : "";
							return (
								<div className={selectedSport + ` seleted-day`} key={index}>
									<span>{row}</span>
									<span
										className="delete-selected-day"
										onClick={() => {
											let actualSelection = [...(freeBets?.sportsbook_restrictions || [])];

											if (actualSelection.includes(row)) {
												actualSelection = actualSelection.filter(item => item !== row);
												// Update the state with the filtered array
												setFreeBets(prevState => ({
													...prevState,
													sportsbook_restrictions: actualSelection,
												}));
											}
										}}
									>
										<TrashIcon />
									</span>
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};
export const GeneralCasinoDropdownData = ({
	title,
	description,
	data,
	options,
	setData,
	property,
	onChange,
	getOptionLabel,
	actualSelection,
	extraRows,
	freeBets,
	setFreeBets,
	firstRowClass,
	otherRowClass,
	readOnly,
}) => {
	actualSelection = options;
	return (
		<>
			<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
				<div className={`col-12 col-lg-4 input-title`} style={{ width: "115px" }}>
					{title}
				</div>
				<div
					className={`dropdown-wrapper 
						${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
				>
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={options}
						getOptionLabel={getOptionLabel}
						sx={{ width: 300 }}
						renderInput={params => <TextField placeholder={description} {...params} />}
						onChange={onChange}
						data={data}
						setData={setData}
						readOnly={readOnly}
					/>
				</div>
			</div>
			<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row "}>
				<div className={`col-12 col-lg-4 input-title`} style={{ width: "115px" }}></div>
				<div
					className={
						firstRowClass || otherRowClass
							? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal flex-column"
							: "col-12 col-lg-8 d-flex align-items-center  flex-column"
					}
				>
					{actualSelection &&
						property == "casino_restrictions" &&
						actualSelection.length > 0 &&
						extraRows &&
						extraRows?.length > 0 &&
						extraRows.map((row, index) => {
							let selectedSport = index === 0 ? "first-day" : "";
							return (
								<div className={selectedSport + ` seleted-day`} key={index}>
									<span>{row}</span>
									<span
										className="delete-selected-day"
										onClick={() => {
											let actualSelection = [...(freeBets?.casino_restrictions || [])];

											if (actualSelection.includes(row)) {
												actualSelection = actualSelection.filter(item => item !== row);
												// Update the state with the filtered array
												setFreeBets(prevState => ({
													...prevState,
													casino_restrictions: actualSelection.title,
												}));
											}
										}}
									>
										<TrashIcon />
									</span>
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};
export const PromoTypeInput = ({ data, setData, handleInputChange, onClick, handleDataChanged, firstRowClass, otherRowClass }) => {
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				Promo Type
			</div>
			<div
				className={`dropdown-wrapper 
						${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					onInputChange={handleInputChange}
					onClick={onClick}
					disablePortal
					className="autocomplete-select input-arrow"
					options={_pageLayoutOptions}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					defaultValue={_pageLayoutOptions.find(option => option?.value == data?.promoType)}
					renderInput={params => <TextField placeholder={"Required"} {...params} onClick={onClick} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setData({
								...data,
								promoType: "default",
							});
						} else {
							setData({
								...data,
								promoType: newValue?.value,
							});
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};
export const PageLayoutDropdown = ({ setLayoutPageData, handler, layoutPageData }) => {
	return (
		<div className="mt-3 dropdown-wrapper">
			<div className="d-flex align-items-center dropdown-wrapper">
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					options={_pageLayoutOptions}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Select"} {...params} />}
					onChange={(event, newValue) => {
						const value = newValue?.value;
						setLayoutPageData({
							...layoutPageData,
							type: value,
						});
						handler(value);
					}}
				/>
			</div>
		</div>
	);
};
export const PageLayoutDropdownShow = ({ pageLayout, handler, layoutPageData, setLayoutPageData }) => {
	return (
		<div className="mt-3 dropdown-wrapper">
			<div className="d-flex align-items-center dropdown-wrapper">
				<Autocomplete
					disabled
					className="autocomplete-select input-arrow"
					options={pageLayout}
					getOptionLabel={option => option.type}
					sx={{ width: 300 }}
					renderInput={params => <TextField style={{ color: "#2E3A49" }} {...params} />}
					onChange={(event, newValue) => {
						let value = newValue?.value;
						setLayoutPageData({
							...layoutPageData,
							type: value,
						});
						handler(value);
					}}
				/>
			</div>
		</div>
	);
};
export const PageLayoutContentDropdownShow = ({ contentData, row, setLayoutPageData, layoutPageData }) => {
	return (
		<div className="mt-3 dropdown-wrapper">
			<div className="d-flex align-items-center dropdown-wrapper">
				<Autocomplete
					disabled
					className="autocomplete-select input-arrow"
					options={contentData}
					getOptionLabel={option => option.type}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={row.type_name} {...params} />}
					onChange={(event, newValue) => {
						let details = JSON.parse(newValue.details);
						let typeName = details.title;
						let value = newValue.id;
						setLayoutPageData({
							...layoutPageData,
							type_id: value,
							type_name: typeName,
						});
					}}
				/>
			</div>
		</div>
	);
};
export const PageLayoutContentDropdown = ({ contentData, setLayoutPageData, layoutPageData }) => {
	return (
		<div className="mt-3 dropdown-wrapper ">
			<div className="d-flex align-items-center dropdown-wrapper">
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					options={contentData}
					getOptionLabel={option =>
						layoutPageData?.type == "page_content"
							? option.friendly_name
							: layoutPageData?.type === "casino_category" || layoutPageData?.type === "live_casino_category"
							? option.title
							: layoutPageData?.type === "menu_links"
							? option?.link_name
							: JSON.parse(option.details).title
					}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Select"} {...params} />}
					onChange={(event, newValue) => {
						let typeName = "";
						let value = "";
						if (layoutPageData?.type === "page_content") {
							typeName = newValue.friendly_name;
							value = newValue.id;
						} else if (layoutPageData?.type === "casino_category" || layoutPageData?.type === "live_casino_category") {
							typeName = newValue.title;
							value = newValue.id;
						} else {
							let details = JSON.parse(newValue.details);
							typeName = details.title;
							value = newValue.id;
						}
						setLayoutPageData({
							...layoutPageData,
							type_id: value,
							type_name: typeName,
						});
					}}
				/>
			</div>
		</div>
	);
};
export const TitleInputCustum = ({ name, optional }) => {
	return (
		<div className="row mt-3">
			<div className="col-12 col-lg-4 align-items-center input-title optional-custum-input">
				{name} <br /> <span className="optional">{optional}</span>
			</div>
		</div>
	);
};

export const GeneralInputElipse = ({ firstText, secondText, classname }) => {
	return (
		<div className="row mt-3">
			<div className="col-12 col-lg-4 align-items-center input-title d-block trader-title"></div>
			<div className="col-12 col-lg-8 d-flex align-items-center">
				<div>
					{firstText && (
						<button className={classname ? `${classname}` : ""} style={{ marginRight: "10px", pointerEvents: "none" }}>
							{firstText}
						</button>
					)}
					{secondText && <button className={classname ? `${classname}` : ""}>{secondText}</button>}
				</div>
			</div>
		</div>
	);
};
export const GeneralInput = ({ handleChange, onChange, onClick, object }) => {
	const [clipboardMessage, setClipboardMessage] = useState("");
	const name = object?.name || "";

	const placeholder = object?.required ? "Required" : "Optional";
	const defaultPlaceholder = object?.defaultPlaceholder || null;

	let value = object?.value;
	const disabled = object?.disabled || false;
	const disabledCheckBox = object?.disabledCheckBox || false;
	const required = object?.required || false;
	const inputClassName = object?.inputClassName || "";
	const readOnly = object?.readOnly || false;
	const underText = object?.underText || "";
	const numbersOnly = object?.numbersOnly || false;
	value = object?.formatCurrency ? ThousandSeperated(value) : value;
	const addionalICon = object?.addionalICon || false;
	const customLink = object?.customLink || "";
	const icon = object?.icon || false;
	const iconInsideInput = object?.iconInsideInput || false;

	const copyToClipBoard = object?.copyIcon || false;

	const min = typeof object?.min !== "undefined" ? object?.min : null;
	const max = typeof object?.max !== "undefined" ? object?.max : null;

	const onFocus = object?.onFocus || null;
	const onBlur = object?.onBlur || null;

	const handlerClick = object?.onClickEvent;
	const handlerChange = object?.onChangeEvent || handleChange;

	const translateType = object?.translateType;
	const inputContainer = object?.inputContainer || "";
	const extraWidth = object?.extraWidth || "";
	const containerWrapper = object?.normalWidth
		? `col-12 col-lg-8 d-flex align-items-center input_container_dropdown input_container_dropdown `
		: `col-12 col-lg-8 d-flex align-items-center input-dropdown `;
	const titleClass = object.titleClass || "";
	const titleClassName = `col-12 col-lg-4 d-flex align-items-center input-title ${titleClass}`;
	const extraEndButtonName = object?.extraEndButtonName || "";
	const extraButtonHandler = object?.extraButtonHandler || "";

	const handleChangeEvent = e => {
		let value = e.target.value;
		if (numbersOnly) {
			const numericValue = value.replace(/[^0-9.]/g, "");

			if ((min || max) && numericValue >= min && numericValue <= max) handlerChange(numericValue, object?.fieldName, object?.property);
			else if (!min && !max) handlerChange(numericValue, object?.fieldName, object?.property);
		} else handlerChange(value, object?.fieldName, object?.property, object?.link_type);
	};
	const handleClickEvent = () => {
		if (translateType) {
			handlerClick(translateType);
		}
	};

	const patternCheck = () => {
		return object?.pattern && object.pattern.test(value);
	};

	return (
		<div className={name ? `row wrapper_customized_modal ${inputContainer} ` : `row wrapper_customized_modal justify-content-center ${inputContainer}`}>
			{name && (
				<div className={titleClassName}>
					<div className="general_input_title">
						{name}
						{underText && <div className="opacity-50">{underText}</div>}
					</div>
					<div className="prediction_icon_information">{addionalICon && <PredictionInformationIcon />}</div>
				</div>
			)}

			<div className={containerWrapper + extraWidth} style={{ position: "relative" }}>
				<input
					type={"text"}
					placeholder={defaultPlaceholder || placeholder}
					value={value}
					onClick={onClick || handleClickEvent}
					onChange={onChange || handleChangeEvent}
					className={`general_input ${patternCheck() ? "error_empty_input" : ""} ${inputClassName} ${
						typeof object?.checkBoxValue != "undefined" || (iconInsideInput && icon) ? "more-padding-right" : ""
					}`}
					disabled={disabled}
					readOnly={readOnly}
					required={required}
					onFocus={onFocus}
					onBlur={onBlur}
					min={min}
					max={max}
				/>
				{extraEndButtonName && (
					<button className="extraButtonInGeneralInput" onClick={() => extraButtonHandler()}>
						{extraEndButtonName}
					</button>
				)}
				{typeof object?.checkBoxValue != "undefined" && (
					<div className="promotion-inputs checkbox_inputs_container">
						<input
							type="checkbox"
							name="oddsOption"
							className="published-input cursor-pointer checkbox-customized"
							checked={object?.checkBoxValue == 1}
							onClick={() => object?.checkBoxOnChange()}
							disabled={disabledCheckBox}
						/>
					</div>
				)}
				{copyToClipBoard && (
					<div
						onClick={() => {
							if (!disabledCheckBox) {
								if (customLink) {
									navigator.clipboard.writeText(value);
								} else {
									navigator.clipboard.writeText(`${apiUrls.client_side_domain}/promo/` + value);
								}

								setClipboardMessage("Copied");
								setTimeout(() => {
									setClipboardMessage(false);
								}, 2000);
							}
						}}
						className="copy_icon_container"
					>
						<CopyIcon />
					</div>
				)}
				{object?.resetValue && object?.resetValue != value && (
					<div onClick={() => !disabledCheckBox && handleChangeEvent({ target: { value: object?.resetValue } })} className="reset_icon_container">
						<ResetIcon />
					</div>
				)}
				{icon && (
					<div className={`${iconInsideInput ? "inside-icon" : ""}`} onClick={() => object?.iconClick()}>
						{icon}
					</div>
				)}
				{clipboardMessage && <div className="clip_board_general_container">{clipboardMessage}</div>}
			</div>
		</div>
	);
};

export const GeneralTextArea = ({ handleChange, object }) => {
	const name = object?.name || "";
	const placeholder = object?.required ? "Required" : "Optional";
	const defaultPlaceholder = object?.defaultPlaceholder || null;
	const value = object?.value;
	const disabled = object?.disabled || false;
	const required = object?.required || false;
	const textareaClassName = object?.textareaClassName || "";
	const readOnly = object?.readOnly || false;
	const underText = object?.underText || "";

	const onFocus = object?.onFocus || null;
	const onBlur = object?.onBlur || null;

	const handlerClick = object?.onClickEvent;
	const translateType = object?.translateType;

	const wrapperClassName = name ? "row wrapper_customized_modal" : "row wrapper_customized_modal justify-content-center";
	const containerWrapper = "col-12 col-lg-8 d-flex align-items-center input-dropdown input-textarea";
	const titleClassName = "col-12 col-lg-4 d-flex align-items-center input-title";

	const handleOnChange = e => {
		let value = e.target.value;
		handleChange(value, object?.fieldName, object?.property);
	};

	return (
		<div className={wrapperClassName}>
			{name ? (
				<div className={titleClassName}>
					<div>
						{name}
						{underText && <div className="opacity-50">{underText}</div>}
					</div>
				</div>
			) : null}

			<div className={containerWrapper}>
				<textarea
					type={"text"}
					placeholder={defaultPlaceholder || placeholder}
					value={value}
					onClick={() => (handlerClick ? handlerClick(translateType) : "")}
					onChange={handleOnChange}
					className={textareaClassName}
					disabled={disabled}
					readOnly={readOnly}
					required={required}
					maxLength={object?.maxLength ? object?.maxLength : ""}
					onFocus={onFocus}
					onBlur={onBlur}
				/>
			</div>
		</div>
	);
};
export const SelectedCountSpecial = ({
	onClick,
	onBlur,
	generalData,
	name,
	id,
	optional,
	disabled,
	seo_special,
	data,
	handleDataChanged,
	setData,
	firstRowClass = false,
	otherRowClass = true,
	elementIndex = false,
}) => {
	return (
		<div
			className={
				seo_special ? "row seo_special_title" : firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"
			}
		>
			<div
				className={seo_special ? "col-12 col-lg-3 general_input_text" : "col-12 col-lg-4 align-items-center input-title d-block trader-title"}
				style={{ maxWidth: "115px" }}
			>
				{name} <br /> <div className="optional">{optional}</div>
			</div>
			<div
				className={elementIndex >= 0 ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}
				style={{ justifyContent: "space-between" }}
			>
				<div className="col-lg-5 d-flex">
					<input
						type={"text"}
						placeholder={"Numerator"}
						onClick={onClick}
						onBlur={onBlur}
						onChange={e => {
							handleDataChanged();
							setData({ ...data, numerator: e.target.value });
						}}
						id={id}
						className={"trader-input"}
						disabled={disabled}
						data={generalData}
					/>
				</div>
				<div className="col-lg-2 d-flex align-items-center justify-content-center">/</div>
				<div className="col-lg-5 d-flex ">
					<input
						type={"text"}
						placeholder={"Denominator"}
						onClick={onClick}
						onBlur={onBlur}
						onChange={e => {
							handleDataChanged();
							setData({ ...data, denominator: e.target.value });
						}}
						id={id}
						className={"trader-input"}
						disabled={disabled}
						data={generalData}
					/>
				</div>
			</div>
		</div>
	);
};
export const SelectedCount = ({ onClick, onBlur, generalData, value, name, onChange, id, optional, disabled, seo_special }) => {
	return (
		<div className={seo_special ? "row seo_special_title" : "row mt-3"}>
			<div
				className={seo_special ? "col-12 col-lg-4 general_input_text" : "col-12 col-lg-4 align-items-center input-title d-block trader-title"}
				style={{ maxWidth: "115px" }}
			>
				{name} <br /> <div className="optional">{optional}</div>
			</div>
			<div className="col-12 col-lg-3 d-flex">
				<input
					type={"text"}
					placeholder={"Numerator"}
					onClick={onClick}
					onBlur={onBlur}
					value={value}
					onChange={onChange}
					id={id}
					className={"trader-input"}
					disabled={disabled}
					data={generalData}
				/>
			</div>
			<div className="col-12 col-lg-2 d-flex align-items-center justify-content-center">/</div>
			<div className="col-12 col-lg-3 d-flex ">
				<input
					type={"text"}
					placeholder={"Denominator"}
					onClick={onClick}
					onBlur={onBlur}
					value={value}
					onChange={onChange}
					id={id}
					className={"trader-input"}
					disabled={disabled}
					data={generalData}
				/>
			</div>
		</div>
	);
};
export const GeneralInputDelete = ({
	onClick,
	onBlur,
	value,
	name,
	placeholder,
	onChange,
	id,
	optional,
	disabled,
	deleteButton,
	data,
	setData,
	inputs,
	setInputs,
	row,
}) => {
	const deleteStakeFactor = () => {
		if (!id) {
			const updatedInputs = inputs.filter(item => item.id !== row.id);
			setInputs(updatedInputs);
		} else if (id) {
			new Promise((resolve, reject) => {
				ApiServices.delete(apiUrls.EDIT_STAKE_FACTOR + "/" + id, resolve, reject, true);
			}).then(() => {
				const updatedData = {
					inplay: data.inplay.filter(item => item.id !== id),
					prematch: data.prematch.filter(item => item.id !== id),
				};

				setData(updatedData);
			});
		}
	};

	return (
		<div className="row mt-3">
			<div className="col-12 col-md-3 col-lg-4 col-xl-3">
				{name} <br /> <div className="optional">{optional}</div>
			</div>
			<div className="col-12 col-md-8 col-lg-8 col-xl-6 d-flex align-items-center gap-4">
				<input
					type="text"
					placeholder={placeholder}
					onClick={onClick}
					onBlur={onBlur}
					value={value}
					onChange={onChange}
					id={id}
					className="trader-input"
					disabled={disabled}
				/>
				{deleteButton && <DeleteIconButton onClick={() => deleteStakeFactor()} className="m-0" />}
			</div>
		</div>
	);
};
export const MixedInputs = props => {
	const { options, setDataChanged, setEditStakeFactor, editStakeFactor, stakeFactorData, type, getStakeFactor } = props;

	const [selectedSport, setSelectedSport] = useState("");
	const [inputValue, setInputValue] = useState("");
	const [inputs, setInputs] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const handleAddButton = () => {
		if (selectedSport && inputValue) {
			const stakeFactorSlugs = stakeFactorData[type].map(item => item.slug);
			const addStakeFactorSlugs = inputs.length > 0 ? inputs.map(item => item.selectedSport) : [];
			const isSlugMatch = stakeFactorSlugs.includes(selectedSport);
			const isAddSlugMatch = addStakeFactorSlugs.includes(selectedSport);

			const sportName = options.find(item => item?.id == selectedSport)?.label;

			if (isSlugMatch || isAddSlugMatch) {
				setErrorMessage("Selected sport already added!");
			} else {
				setSelectedSport("");
				setInputValue("");
				setErrorMessage("");
				// Add a new input component to the inputs array

				setInputs(prevInputs => [...prevInputs, { sportName, selectedSport, inputValue }]);

				let objectSelected = { selectedSport, inputValue };
				let updatedStakeFactor = [...editStakeFactor];
				updatedStakeFactor.push(objectSelected);
				setEditStakeFactor(updatedStakeFactor);
				getStakeFactor();
			}
		} else {
			setErrorMessage("Please select a sport and enter a value.");
		}
	};

	const handleDeleteButton = slug => {
		setInputs(prevInputs => prevInputs.filter(input => input.selectedSport !== slug));
		const newStakeFactors = editStakeFactor?.filter(item => item?.selectedSport !== slug);
		setEditStakeFactor(newStakeFactors);
	};

	const handleOnChange = (value, sport) => {
		const newInputs = inputs?.map(item => (item.selectedSport == sport ? { ...item, inputValue: value } : item));
		const newStakeFactors = editStakeFactor?.map(item => (item?.selectedSport == sport ? { ...item, inputValue: value } : item));

		setInputs(newInputs);
		setEditStakeFactor(newStakeFactors);
	};

	return (
		<div className="w-100">
			{inputs.map((input, index) => (
				<div key={index} className="col-12">
					<GeneralInput
						object={{
							name: capitalizeText(input?.sportName),
							value: input.inputValue,
							icon: <DeleteIconButton className="ms-2 m-0" />,
							iconClick: () => handleDeleteButton(input.selectedSport),
							numbersOnly: true,
							onChangeEvent: e => handleOnChange(e, input.selectedSport),
						}}
					/>
				</div>
			))}
			<span className="mobile-border"></span>
			<div className="row mt-3">
				<div className=" col-12 col-md-9">
					<div className="row">
						<div className="col-12 col-md-6">
							<GeneralDropdown
								object={{
									defaultPlaceholder: "Select Sport",
									options: options,
									value: selectedSport,
									wrapperClassNameDropdown: "w-100 p-0 m-0",
								}}
								handleChange={e => setSelectedSport(e?.id)}
							/>
						</div>
						<div className="col-12 col-md-6">
							<GeneralInput
								object={{
									value: inputValue,
									inputContainer: "p-0",
									defaultPlaceholder: "Enter Value",
									numbersOnly: true,
									onChangeEvent: newValue => {
										setInputValue(newValue);
										handleDataChanged();
									},
								}}
							/>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-3 factor-btn">
					<AddButton name="Add" onClick={handleAddButton} />
				</div>

				{errorMessage && (
					<div className="col-12 mt-3">
						<p style={{ color: "red" }}>{errorMessage}</p>
					</div>
				)}
			</div>
		</div>
	);
};
export const LadderInputGeneral = ({ value, onchange, disabled }) => {
	return <input type={"text"} value={value} className="ladder_input" disabled={disabled} onChange={onchange} />;
};
export const ProviderTagsInput = ({ data, setData, onClick, handleDataChanged, placeholder, firstRowClass, otherRowClass }) => {
	return (
		<>
			<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
				<div className="col-12 col-lg-4 align-items-center input-title d-block" style={{ maxWidth: "115px" }}>
					Tags
				</div>
				<AddButton name="Add" />
				<div className="col-12 col-lg-8 d-flex align-items-center input_custom_add_modal">
					<input
						placeholder={placeholder}
						type={"text"}
						value={data?.tag_value}
						onClick={onClick}
						onChange={e => {
							setData({
								...data,
								tag_value: e.target.value,
							});
						}}
					/>
				</div>
			</div>
			<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-2"}>
				<div className="col-12 col-lg-4 align-items-center input-title d-block" style={{ maxWidth: "115px" }}></div>
				<div className="col-12 col-lg-8 d-flex align-items-center flex-column input_custom_add_modal">
					{data.tags?.map((row, index) => {
						let dayClass = index === 0 ? "first-day" : "";
						return (
							<div className={dayClass + ` seleted-day`} key={index}>
								<span>{row}</span>
								<span
									className="delete-selected-day"
									onClick={() => {
										let actualTags = [...(data?.tags || [])];
										let index = actualTags.indexOf(row);
										if (index > -1) {
											actualTags.splice(index, 1);
										}
										setData({ ...data, tags: actualTags });
										handleDataChanged?.();
									}}
								>
									<TrashIcon />
								</span>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export const MultipleInput = ({ object, handleRemove }) => {
	const [inputValue, setInputValue] = useState("");

	const name = object?.name || "";
	const fieldName = object?.fieldName || "";
	const placeholder = object?.placeholder || object?.name;
	const disabled = object?.disabled || false;
	const disableMultipleScroll = object?.disableMultipleScroll || false;
	const bgWhite = object?.bgWhite || false;
	const multipleValue = object?.multipleValue || [];

	const handleChange = object?.handleChange || null;
	const handleOnRemove = object?.handleRemove || handleRemove;

	const wrapperClassName = name ? "row wrapper_customized_modal" : "row wrapper_customized_modal justify-content-center";

	const handleOnChange = e => {
		let value = e.target.value;
		setInputValue(value);

		if (e.keyCode === 13) {
			handleChange(value);
			setInputValue("");
		}
	};

	return (
		<>
			<div className={`row wrapper_customized_modal`}>
				{name && <div className="col-12 col-lg-4 d-flex align-items-center input-title">{name}</div>}
				<div className={`col-12 col-lg-8 d-flex align-items-center input-dropdown`}>
					<input placeholder={placeholder} type={"text"} value={inputValue} onChange={e => handleOnChange(e)} onKeyDown={handleOnChange} />
				</div>
			</div>
			{multipleValue && (
				<div className={wrapperClassName} style={{ marginTop: "5px" }}>
					{name && <div className={`col-12 col-lg-4 input-title`}></div>}
					<div className={`col-12 col-lg-8 d-flex align-items-center flex-column input-dropdown ${disableMultipleScroll ? "" : "multiselect-wrapper"}`}>
						{multipleValue.map((row, index) => {
							return (
								<div className={`seleted-day ${bgWhite ? "bg-white-multiple-dropdown" : ""}`} key={index}>
									<span>{row}</span>
									{/* fieldName */}
									<span className={`${disabled && "pe-none"} delete-selected-day`} onClick={() => handleOnRemove(row, fieldName, "array")}>
										<TrashIcon />
									</span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};

export const GeneralSubTitleInput = ({ onChange, onClick, onBlur, readOnly, placeholder, name, optional, firstRowClass, otherRowClass, value }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 align-items-center input-title optional-input" style={{ width: "115px" }}>
				{name} <br /> <span className="optional">{optional}</span>
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<textarea
					type={"text"}
					placeholder={placeholder}
					onClick={onClick}
					onBlur={onBlur}
					style={{ minHeight: "64px" }}
					value={value}
					onChange={onChange}
					readOnly={readOnly}
				/>
			</div>
		</div>
	);
};
export const GeneralLargeInput = ({ onClick, onBlur, value, onChange, name, placeholder, underText, classname, otherRowClass, firstRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div
				className={underText ? "col-12 col-lg-4 input-title  general_input_text " : "col-12 col-lg-4 d-flex align-items-center input-title"}
				style={{ maxWidth: "115px" }}
			>
				{name}
				{underText && <div style={{ opacity: "0.5" }}>{underText}</div>}
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input
					type={"text"}
					placeholder={placeholder}
					onClick={onClick}
					onBlur={onBlur}
					style={{ minHeight: "64px" }}
					value={value}
					onChange={onChange}
					className={classname}
				/>
			</div>
		</div>
	);
};
export const MiniUserInputs = ({ disabled, ID, onchange, onblur, onclick, onClick, onBlur, pholder, value, name, placeholder, onChange, id }) => {
	let percentValue;
	if (value > 0) {
		percentValue = (parseFloat(value) * 100).toFixed(2) + "%";
	} else percentValue = "0";

	return (
		<div className="row d-flex gap-2 w-100">
			<div className="col-12 col-md-3 col-lg-3 col-xl-4 smallFieldStakeFactor">{name}</div>
			<div className="col-12 col-md-8 col-lg-8 col-xl-6 d-flex align-items-center gap-3">
				<input
					className="col-6 p-2 pt-md-0 pb-md-0 smallFieldStakeFactor stake-factor-border"
					style={disabled ? { background: "#fff" } : {}}
					type={"text"}
					placeholder={placeholder}
					onClick={onClick}
					onBlur={onBlur}
					value={value}
					onChange={onChange}
					id={id}
					disabled={disabled}
				/>
				<input
					className="col-6 p-2 pt-md-0 pb-md-0 smallFieldStakeFactor stake-factor-border"
					type={"text"}
					placeholder={pholder}
					onClick={onclick}
					onBlur={onblur}
					value={percentValue} // Assign the converted value to Value
					onChange={onchange}
					id={ID}
					disabled
				/>
			</div>
		</div>
	);
};
export const PrefixSelector = ({ object, handleChange }) => {
	let options = object.options;
	const title = object.name;
	const value = object.value;

	if (!value) {
		options = [{ id: "-", label: "-" }, ...options];
	}

	const required = object.required;
	const placeholder = object?.required ? "Required" : "Optional";
	const defaultPlaceholder = object?.defaultPlaceholder || null;
	const fieldName = object.fieldName;
	const phoneNumber = object.phoneField;
	const phoneValue = object.phoneValue;
	const containerClass = object?.normalWidth ? "col-12 col-lg-8 d-flex input_container_dropdown" : "col-12 col-lg-8 d-flex input-dropdown";

	const disabled = object.disabled || false;

	return (
		<div className="row wrapper_customized_modal">
			<div className={`col-12 col-lg-4 d-flex align-items-center input-title`}>{title}</div>
			<div className={containerClass} style={{ position: "relative" }}>
				<select
					className="user-select custom-select"
					onChange={e => {
						handleChange(e.target.value, fieldName);
					}}
					value={value}
					required={required}
					disabled={disabled}
				>
					{options.map((prefix, index) => (
						<option key={index} value={prefix.label}>
							{prefix.label}
						</option>
					))}
				</select>
				<input
					placeholder={defaultPlaceholder || placeholder}
					required={required}
					type="text"
					className={`trader-input add-trader-input ${typeof object?.checkBoxValue != "undefined" ? "more-padding-right" : ""}`}
					aria-label="Text input with dropdown button"
					onChange={e => {
						handleChange(e.target.value, phoneNumber);
					}}
					value={phoneValue}
					disabled={disabled}
				/>

				{typeof object?.checkBoxValue != "undefined" && (
					<div className="promotion-inputs checkbox-input">
						<input
							type="checkbox"
							name="oddsOption"
							className="published-input cursor-pointer"
							checked={object?.checkBoxValue == 1}
							onClick={() => object?.checkBoxOnChange()}
							disabled={disabled}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export const GeneralGridItems = ({ object }) => {
	const data = object?.data;
	const title = object?.name;

	const containerClass = "col-12 col-lg-8";

	return (
		<div className="row wrapper_customized_modal">
			<div className={`col-12 col-lg-4 d-flex align-items-start input-title pt-2`}>{title}</div>
			<div className={`${containerClass} grid-items-container custom-scrollbar`} style={{ position: "relative" }}>
				{data?.map((item, i) => {
					return (
						<div key={`grid-` + i} className="grid-item">
							{item}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export const GeneralDatePicker = ({ object, onClick, handleChange }) => {
	const titleClass = object?.titleClass || "";
	const titleClassName = `col-12 col-lg-4 d-flex flex-wrap align-items-center input-title ${titleClass}`;
	const convertedFullDate = object?.convertedDate;
	const formatDate = object?.formatDate;
	const flatpickr_dateformat = convertedFullDate ? "d F Y" : formatDate ? "Y-m-d H:i" : "d-m-Y";
	const required = object?.required || false;
	const value = object?.value || "";
	const name = object?.name || "";
	const subtitle = object?.subtitle || "";
	const defaultPlaceholder = object?.defaultPlaceholder || "";
	const placeholder = object?.required ? "Required" : "Optional";
	const handleResetDate = object?.deleteEvent || false;
	const disabled = object?.disabled || false;
	const inputClassName = object?.inputClassName || "";
	const isTimeExist = object?.isTimeExist;
	const timePicker = object?.timePicker || false;
	const normalWidth = object?.normalWidth || false;
	const containerClass = normalWidth
		? "col-12 col-lg-8 d-flex align-items-center input_container_dropdown"
		: "col-12 col-lg-8 d-flex align-items-center input-dropdown";
	const noFormattedDate = object?.noFormattedDate || false;
	const withUtc = object?.withUtc || false;
	const containerClassName = object?.containerClassName || "";
	const wrapperCustomized = object?.wrapperCustomized || "";
	const defaultDate = object?.defaultDate || "";
	const mode = object?.mode || "single";
	const formatDateRange = object?.formatDateRange || false;

	const specificDates = object?.specificDates || "";
	const selectNumber = object?.selectNumber || "";
	const selectType = object?.selectType || "";

	const [formattedDate, setFormattedDate] = useState("");

	useEffect(() => {
		if (withUtc && isIsoDate(value.toString())) {
			const tempformattedDate = moment(value).utc().format("YYYY-MM-DD HH:mm:ss");
			setFormattedDate(tempformattedDate);
			return;
		}

		if (defaultDate) {
			if (value) {
				const tempformattedDate = moment(value).format("DD-MM-YYYY");
				setFormattedDate(tempformattedDate);
				return;
			} else {
				const nowDate = moment(Date.now()).format("DD-MM-YYYY");
				setFormattedDate(nowDate);
				return;
			}
		}

		if (!noFormattedDate) {
			if (timePicker) {
				const tempformattedDate = value || "10:00";
				setFormattedDate(tempformattedDate);
			} else if (convertedFullDate) {
				const tempformattedDate = moment(value, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY");
				setFormattedDate(tempformattedDate);
			} else if (isTimeExist) {
				const tempformattedDate = moment(value).format("YYYY-MM-DD HH:mm:ss");
				setFormattedDate(tempformattedDate);
			} else if (formatDateRange) {
				const [first, second] = value.split(" to ");
				setFormattedDate([first, second]);
			} else {
				const tempformattedDate = moment(value, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
				setFormattedDate(tempformattedDate);
			}
		}
	}, [value]);

	const resetDate = () => {
		handleChange("", object.fieldName);
	};

	let flatpickrOptions = {
		mode: mode,
		onOpen: onClick,
		locale: { firstDayOfWeek: 1 },
		disableMobile: true,
		dateFormat: flatpickr_dateformat,
	};
	if (specificDates) {
		const minDate = moment().format("YYYY-MM-DD");
		const maxDate = moment().add(selectNumber, selectType).format("YYYY-MM-DD");
		flatpickrOptions = {
			mode: mode,
			onOpen: onClick,
			minDate: minDate,
			maxDate: maxDate,
			dateFormat: flatpickr_dateformat,
			locale: { firstDayOfWeek: 1 },
			disableMobile: true,
		};
	}
	return (
		<div className={`row wrapper_customized_modal ${wrapperCustomized}`}>
			{name && (
				<div className={titleClassName}>
					<span>{name}</span>
					<span className="opacity-50">{subtitle}</span>
				</div>
			)}
			<div className={containerClass}>
				<div className={`flatpicrCustomized bg-white ${disabled && "isDisabled"} ${containerClassName}`} data-required={required}>
					<Flatpickr
						className={`form-control chooseOptionDate ${inputClassName} ${disabled && "isDisabled"} flatPickrBorderNone`}
						placeholder={defaultPlaceholder || placeholder || name}
						data-enable-time={isTimeExist}
						data-no-calendar={timePicker}
						options={!timePicker ? flatpickrOptions : {}}
						value={formattedDate || value}
						onChange={(date, selectedDate) => {
							if (mode == "range") handleChange(selectedDate, object.fieldName);
							else if (date.length > 0) {
								if (timePicker) {
									const timepicker = moment(date[0]).format("HH:mm");
									handleChange(timepicker, object.fieldName);
								} else {
									const format = isTimeExist ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD";
									const formatted = moment(date[0]).format(format);
									handleChange(formatted, object.fieldName);
								}
							}
						}}
						disabled={disabled}
					/>
					{handleResetDate && <DeleteXIcon onClick={resetDate} />}
					{timePicker ? (
						<div className="calender_icon_container">
							<TimeIcon />
						</div>
					) : (
						<div className="calender_icon_container">
							<CalendarIcon />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export const GeneralUserDate = ({ data, setData, name, gamblingInput, onChange, value, firstRowClass, otherRowClass, readOnly }) => {
	const handleDateChange = (date, selectedDate) => {
		if (!readOnly) {
			onChange(date, selectedDate);
		}
	};

	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ width: "115px" }}>
				{name}
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<Flatpickr
					className="form-control d-block chooseOptionDate"
					style={{
						width: !gamblingInput ? "calc(100% - 20px)" : "100%",
						border: "0.5px solid #8A98AB",
					}}
					placeholder="Choose date"
					value={value}
					onChange={handleDateChange}
					setData={setData}
					data={data}
					readOnly={readOnly} // Add the readOnly prop here
				/>

				<CalendarIcon className={firstRowClass || otherRowClass ? "calender-icon-customized" : "calendar-icon"} />
			</div>
		</div>
	);
};
export const TagsInput = ({ onClick, onBlur }) => {
	return (
		<div className="row mt-3">
			<div className="col-12 col-lg-4 d-flex align-items-center input-title">Tags</div>
			<div className="col-12 col-lg-8 d-flex align-items-center ">
				<input type={"text"} placeholder={"Add Tags"} onClick={onClick} onBlur={onBlur} />
			</div>
		</div>
	);
};
export const ImageInput = ({ object }) => {
	const { getRootProps, getInputProps } = useDropzone();
	const onClick = object?.onClick || null;
	const value = object?.value;
	const name = object?.name;
	const optional = object?.optional;
	const active = object?.active;
	const gameImage = object?.gameImage;
	const type = object?.type;
	const imageType = object?.imageType;
	const imageLibaryType = object?.imageLibaryType || imageType;
	const fieldName = object?.fieldName;
	const dimensions = object?.dimensions;

	const showFullImage = object?.showFullImage || false;

	const onFocus = object?.onFocus;
	const onBlur = object?.onBlur || null;

	const hideRemoveButton = object?.hideRemoveButton || false;
	const infoMessage = object?.infoMessage || "";

	// url is base on gameImage property

	const url = gameImage ? value : aws_s3_url + "/media/" + value;
	const containerWrapper = active ? "image-input-container active-images" : "image-input-container";
	const checked = true;

	let setData = object?.setData;
	let setDataChanged = object?.setDataChanged;
	let data = object?.data;

	const accept = object?.accept || "image";

	const handleDregAndDrop = async e => {
		e.preventDefault();
		let imageValue = e.dataTransfer.files;

		const response = await saveImages(imageValue, imageLibaryType, checked, accept);
		const addedImageData = response?.data?.files;

		if (addedImageData?.length > 0) {
			setData({ ...data, [fieldName]: addedImageData[0].filename, image_exist: true });
			setDataChanged(true);
		}
	};

	return (
		<div className="row wrapper_customized_modal">
			<div className="col-12 col-lg-4 input-title d-flex flex-column justify-content-center align-items-start">
				{name} <br /> {optional && <span className="optional">({optional})</span>}
			</div>

			<div className="col-12 col-lg-8 d-flex input_custom_add_modal flex-column">
				<div
					{...getRootProps()}
					tabIndex={0}
					className={containerWrapper}
					onClick={onClick}
					onFocus={onFocus}
					onBlur={onBlur}
					draggable={true}
					onDrop={e => handleDregAndDrop(e)}
				>
					<input {...getInputProps()} />
					{value ? (
						type === "all-media" ? (
							<video src={url} alt="Thumb" className="image-input-file" />
						) : (
							<img src={url} alt="Thumb" className={fieldName?.includes("mobile") && !showFullImage ? "image-input-file-mobile" : "image-input-file"} />
						)
					) : (
						<>
							<div className="mb-3">
								<UploadIcon />
							</div>
							<h6 className="text-muted pb-0">Click to select</h6>
						</>
					)}
				</div>

				<div className="image-input-buttons">
					{value && !hideRemoveButton && (
						<div
							className="btn btn-danger image-input-button"
							onClick={() => {
								setData({ ...data, [fieldName]: "" });
								setDataChanged(true);
							}}
						>
							<XCloseIcon />
						</div>
					)}

					{infoMessage?.length > 0 && (
						<div className="image-input-button info-message-ctn">
							<InfoIcon />
							<div className="info-message">
								<span>{infoMessage}</span>
							</div>
						</div>
					)}
				</div>

				{dimensions && <p className="suggested-dimensions m-0">Suggested media dimensions: {dimensions}</p>}
			</div>
		</div>
	);
};
export const CallToActionInput = ({ onClick, data, setData, handleDataChanged, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 align-items-center input-title optional-input">
				<span>CTA</span>
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input
					type={"text"}
					placeholder={"Call to Action"}
					value={data?.call_to_action}
					className="w-100"
					onClick={onClick}
					onChange={e => {
						setData({
							...data,
							call_to_action: e.target.value,
						});
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};
export const NameLink = ({ onClick, data, setData }) => {
	return (
		<div className="row mt-3">
			<div className="col-12 col-lg-4 align-items-center input-title optional-input">
				<label placeholder={"optional"}>
					Link Name <br /> <span className="optional">(optional)</span>
				</label>
			</div>
			<div className="col-12 col-lg-8 d-flex align-items-center">
				<input
					type={"text"}
					placeholder={"Link Name"}
					value={data?.name_link}
					onClick={onClick}
					onChange={e => {
						setData({
							...data,
							name_link: e.target.value,
						});
					}}
				/>
			</div>
		</div>
	);
};

export const GeneralDropdown = ({ handleChange, object, handleRemove }) => {
	let pageContentProperty = object?.property === "page_content";
	const onOpen = object?.onOpen || null;
	const onClose = object?.onClose || null;

	const customized_clasname = object?.customized_classname || "";
	const title = object?.name;
	const options = object?.options || [];
	let uniqueOptions = options.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));

	const value = object?.value || "";
	const hideMultipleValues = object?.hideMultipleValues || false;
	const multiple = object?.multiple && !hideMultipleValues;
	const fieldName = object?.fieldName;
	const placeholder = object?.required ? "Required" : "Optional";
	const defaultPlaceholder = object?.defaultPlaceholder || null;
	const required = object?.required || false;
	const wrapperClassNameDropdown = object?.wrapperClassNameDropdown || "";
	const extraWidth = object?.extraWidth || "";
	const searchFilterWrapperDropdown = wrapperClassNameDropdown === "search_filter_dropdown_wrapper" ? "search-filter-wrapper" : `${extraWidth}`;
	const wrapperClassName =
		pageContentProperty || customized_clasname
			? ""
			: title
			? `row wrapper_customized_modal ${wrapperClassNameDropdown}`
			: `row wrapper_customized_modal justify-content-center ${wrapperClassNameDropdown}`;
	const type = multiple ? "dropdown-multiple" : object?.property ? object?.property : "dropdown";
	const disabled = object?.disabled || false;
	const dropdownClassName = object?.dropdownClassName || "";
	const handleChangeDropdown = object?.handleChangeDropdown || handleChange;
	const bindValue = object?.bindValue || "";
	const multiBindValue = object?.multiBindValue || "";
	const disabledWritting = object?.disabledTyping || false;
	const containerClassName = object?.normalWidth || false;
	const property = object?.property;
	const loading = object?.loading || false;
	const bgWhite = object?.bgWhite || false;
	const specificDropdownMenu = object?.specificDropdownMenu ? "specific-dropdown-menu" : "";
	const disableMultipleScroll = object?.disableMultipleScroll || false;
	const customIcon = object?.customIcon || "";
	const additionalInfo = object?.additionalInfo || "";
	const additionalInfoType = object?.additionalInfoType || "";
	const sortOptions = object?.sort || false;

	const additionalIcon = object?.additionalIcon || null;

	let label = object?.label || "";
	let multipleOptions;
	let multipleValue;
	if (multiple) {
		if (multiBindValue) {
			multipleOptions = uniqueOptions?.filter(option => !multiBindValue?.includes(option.id.toString()));
			multipleValue = multiBindValue.map(i => ({ label: i, id: i }));
		} else {
			multipleOptions = uniqueOptions?.filter(option => !value?.includes(option.id.toString()));
			multipleValue = uniqueOptions?.filter(option => value?.includes(option.id.toString()));
		}
	}

	if (property == "reality_check") {
		label = object.value == -1 ? "Disabled" : object.value + " Mins";
	} else if (property == "self_excluded" || property == "play_break") {
		label = object.value == -1 ? "Disabled" : object.value + " Days";
	}

	if (property == "page_content") {
		const selectedLanguage = uniqueOptions.find(lang => lang?.id?.toLowerCase() === label);
		if (selectedLanguage) {
			label = selectedLanguage.label;
		}
	}

	const [isMobile, setIsMobile] = useState(window.document.documentElement.clientWidth <= 1502);

	useEffect(() => {
		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	});
	const resizeHandler = () => {
		setIsMobile(window.document.documentElement.clientWidth <= 1502);
	};

	const filterOptions = createFilterOptions({
		stringify: options => options.label,
	});

	useEffect(() => {
		if (sortOptions) {
			uniqueOptions = filterAndSortArray(uniqueOptions, "label");
		}
	}, [sortOptions]);

	return (
		<>
			<div className={`${wrapperClassName} dropdown-wrapper ${additionalInfo ? "pb-3" : ""}`}>
				{title && (
					<div className={pageContentProperty ? dropdownClassName : `col-12 col-lg-4 d-flex align-items-center input-title ${dropdownClassName}`}>
						{title}
						{customIcon}
					</div>
				)}
				<div
					className={`dropdown-wrapper position-relative ${disabled ? "isDisabled" : ""} ${
						customized_clasname
							? "col-12 col-lg-8 align-items-center responsive-dropdown customized_input_container"
							: pageContentProperty
							? ""
							: containerClassName
							? "col-12 col-lg-8 align-items-center responsive-dropdown input_container_dropdown"
							: isMobile
							? `col-12 col-lg-8 align-items-center input-dropdown responsive-dropdown ${searchFilterWrapperDropdown}`
							: `col-12 col-lg-8 d-flex align-items-center input-dropdown ${searchFilterWrapperDropdown}`
					}`}
				>
					{/* //height: calc(100% - 70px) !important; */}
					<Autocomplete
						// popupIcon={loading && <Spinner animation="border" size={"sm"} />}
						disablePortal
						className={`
							${specificDropdownMenu}
							${
								property === "page_content"
									? "page_content_dropdown autocomplete-select input-arrow "
									: object?.type === "bet-ticker-chip"
									? "bet-user-label search-bet-user-label "
									: `autocomplete-select input-arrow`
							}
								`}
						options={multiple ? multipleOptions : uniqueOptions}
						renderOption={(props, option) => (
							<li {...props} key={option.id} className={`${props.className} ${option.disabled ? "option-disabled" : ""}`}>
								{option.label}
							</li>
						)}
						getOptionLabel={option => (option && option.label ? option.label : option)}
						renderInput={params => (
							<TextField
								placeholder={defaultPlaceholder || placeholder}
								{...params}
								data-dropdownrequired={required}
								disabled={disabled}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{loading ? (
												<Spinner animation="border" size={"sm"} />
											) : additionalIcon ? (
												<div className="dropdown-icon-ctn">
													{additionalIcon}
													{params.InputProps.endAdornment}
												</div>
											) : (
												params.InputProps.endAdornment
											)}
										</>
									),
								}}
							/>
						)}
						filterOptions={filterOptions}
						onChange={(event, newValue, reason) => {
							if (reason == "clear") handleChangeDropdown({ id: "", label: "" }, fieldName, type, object?.link_type, object?.emptyValues);
							else handleChangeDropdown(newValue, fieldName, type, object?.link_type, object?.emptyValues);
						}}
						disabled={disabled}
						defaultValue={bindValue ? bindValue : multiple ? "" : label}
						value={value && options.length > 0 ? options.find(({ id }) => id == value) : bindValue ? bindValue : ""}
						onKeyPress={e => {
							if (disabledWritting) {
								e.preventDefault();
							}
						}}
						onOpen={onOpen}
						onClose={onClose}
					/>

					{additionalInfo && <p className={`additionalInfo ${additionalInfoType}`}>{additionalInfo}</p>}
				</div>
			</div>

			{multiple && (
				<div className={`${wrapperClassName} p-0`} style={{ marginTop: "5px" }}>
					{title && <div className={`col-12 col-lg-4 input-title ${dropdownClassName}`}></div>}
					<div className={`col-12 col-lg-8 d-flex align-items-center flex-column input-dropdown ${disableMultipleScroll ? "" : "multiselect-wrapper"}`}>
						{multipleValue.map((row, index) => {
							return (
								<div className={`seleted-day ${bgWhite ? "bg-white-multiple-dropdown" : ""}`} key={index}>
									<span>{row.label}</span>

									<span className={`${disabled && "pe-none"} delete-selected-day`} onClick={() => handleRemove(row, fieldName)}>
										<TrashIcon />
									</span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};

export const SportsDropdownAdd = ({ title, placeholder, data, setData, getOptionLabel, onChange, options, firstRowClass, otherRowClass, getOptionKey }) => {
	return (
		<>
			<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
				<div className={`col-12 col-lg-4 input-title`} style={{ width: "115px" }}>
					{title}
				</div>
				<div
					className={`dropdown-wrapper
						${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
				>
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={options}
						getOptionLabel={getOptionLabel}
						renderInput={params => <TextField placeholder={placeholder} {...params} />}
						onChange={onChange}
						getOptionKey={getOptionKey}
						data={data}
						setData={setData}
					/>
				</div>
			</div>

			<div className="row">
				<div className={`col-12 col-lg-4 input-title`}></div>
				<div className="col-12 col-lg-8 d-flex align-items-center flex-column"></div>
			</div>
		</>
	);
};
export const NotificationLinkInput = ({ onClick, data, name, placeholder, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				{name} <br /> <span className="optional"> </span>
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<div className="link-disable-input" onClick={onClick}>
					{data?.link ? data?.link : placeholder}
				</div>
			</div>
		</div>
	);
};
export const LinkUrlInput = ({ data, setData, handleDataChanged }) => {
	return (
		<div className="row mt-3">
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				Link
			</div>
			<div className="col-12 col-lg-8 d-flex align-items-center">
				<input
					type={"text"}
					className="link-disable-input"
					placeholder={"URL Address"}
					value={data?.link}
					onChange={e => {
						handleDataChanged?.();
						setData({
							...data,
							link: e.target.value,
						});
					}}
				/>
			</div>
		</div>
	);
};
export const StartDateInput = ({ data, setData, onClick, handleDataChanged, type, text, firstRowClass, otherRowClass, required }) => {
	let stUTC = moment(data?.startDate).format("YYYY-MM-DD HH:mm:ss");
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				{type == "competitions" ? "Date From (optional)" : text ? text : "Start Date"}
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<div className="flatpicrCustomized">
					<Flatpickr
						className="form-control chooseOptionDate flatPickrBorderNone"
						placeholder={required ? "Required" : "Optional"}
						data-enable-time
						options={{
							onOpen: onClick,
							locale: {
								firstDayOfWeek: 1, // start week on Monday
							},
						}}
						value={stUTC}
						onChange={(date, selectedDate) => {
							let dateValue = moment(selectedDate).format("YYYY-MM-DD HH:mm:ss");
							handleDataChanged?.();
							if (type == "display_start_date") {
								setData({
									...data,
									display_start_date: dateValue,
								});
							} else
								setData({
									...data,
									startDate: dateValue,
								});
						}}
					/>
					<div className="calender_icon_container">
						<CalendarIcon />
					</div>
				</div>
			</div>
		</div>
	);
};
export const GeneralDateInput = ({ setData, onClick, name, data, dateFormat, handleDataChanged, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				{name}
			</div>
			<div className="col-12 col-lg-8 d-flex align-items-center input_custom_add_modal">
				<Flatpickr
					className="form-control d-block chooseOption "
					placeholder="Choose date"
					options={{
						onOpen: onClick,
						dateFormat: dateFormat,
						locale: {
							firstDayOfWeek: 1, // start week on Monday
						},
					}}
					value={data?.onboard_date ? data?.onboard_date : ""}
					onChange={([date]) => {
						let dateValue = moment(date).format("YYYY-MM-DD HH:mm:ss");
						let startDateVal = moment(date).format("DD MMM YYYY");
						setData({
							...data,
							startDate: dateValue,
							onboard_date: startDateVal,
						});
						handleDataChanged?.();
					}}
				/>
				<CalendarIcon className={firstRowClass || otherRowClass ? "calender-icon-customized" : "calendar-icon"} />
			</div>
		</div>
	);
};
export const EndDateInput = ({ data, setData, onClick, handleDataChanged, type, text, firstRowClass, otherRowClass, required }) => {
	let minDate;
	let enUTC;
	if (type == "bet_slip_promotion") {
		minDate = moment(data?.startDate, "YYYY-MM-DD HH:mm:ss").add(1, "days");
		minDate = moment(minDate).format("YYYY-MM-DD HH:mm:ss");
		enUTC = moment.utc(data?.end_date).format("YYYY-MM-DD HH:mm:ss");
	} else if (data?.date_from) {
		minDate = moment(data?.date_from, "YYYY-MM-DD HH:mm:ss").add(1, "days");
		minDate = moment(minDate).format("YYYY-MM-DD HH:mm:ss");
		enUTC = moment.utc(data?.date_to).format("YYYY-MM-DD HH:mm:ss");
	} else if (data?.endDate) {
		enUTC = moment.utc(data?.endDate).format("YYYY-MM-DD HH:mm:ss");
	}

	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				{type == "competitions" ? "Date To (optional)" : text ? text : "End Date"}
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<div className="flatpicrCustomized">
					<Flatpickr
						className="form-control chooseOptionDate flatPickrBorderNone"
						placeholder={required ? "Required" : "Optional"}
						data-enable-time
						options={{
							onOpen: onClick,
							minDate: minDate,
							locale: {
								firstDayOfWeek: 1, // start week on Monday
							},
						}}
						value={enUTC}
						onChange={(date, selectedDate) => {
							let dateValue = moment(selectedDate).format("YYYY-MM-DD HH:mm:ss");
							handleDataChanged?.();
							if (type == "bet_slip_promotion") {
								setData({
									...data,
									end_date: dateValue,
								});
							} else if (type == "display_end_date") {
								setData({
									...data,
									display_end_date: dateValue,
								});
							} else if (type == "sport_widget") {
								setData({
									...data,
									endDate: dateValue,
								});
							} else {
								setData({
									...data,
									date_to: dateValue,
								});
							}
						}}
					/>
					<div className="calender_icon_container">
						<CalendarIcon />
					</div>
				</div>
			</div>
		</div>
	);
};
export const TypeInput = ({ data, setData, linkData, setLinkData, handleDataChanged }) => {
	return (
		<div className="row mt-3 dropdown-wrapper">
			<div className="col-12 col-lg-4 d-flex align-items-center input-title">Type</div>
			<div className="col-12 col-lg-8 d-flex align-items-center dropdown-wrapper">
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					options={_inputTypeOptions}
					getOptionLabel={option => option.name}
					defaultValue={{ name: data?.link_type === "event" ? "Event" : "Generic", value: data?.link_type }}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Event"} {...params} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setData({
								...data,
								link_type: "event",
							});
						} else {
							setData({
								...data,
								link_type: newValue?.value,
							});
						}
						if (setLinkData) {
							if (newValue?.value === "casino") {
								setLinkData({
									...linkData,
									link: "/casino",
								});
							} else {
								setLinkData({
									...linkData,
									link: "",
								});
							}
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};
export const SportInput = ({
	sportsData,
	data,
	setData,
	type,
	linkData,
	setLinkData,
	onClick,
	handleInputChange,
	handleDataChanged,
	classname,
	widgetType,
	firstRowClass,
	otherRowClass,
	draggable = false,
}) => {
	let selectedValue = sportsData.filter(row => row.slug === data?.sport_id);
	if (type == "competitor") {
		selectedValue = sportsData.filter(row => row.name == data);
	}
	if (type == "marketsModal") {
		selectedValue = sportsData.filter(row => row.genius_id == data?.sport_id);
	}
	if (type == "competitions_edit_modal") {
		selectedValue = sportsData.filter(row => row.name == data?.sport_name);
	}
	if (type == "competitions_add_modal") {
		selectedValue = sportsData.filter(row => row.name == data?.sport_name);
	}
	if (type == "custom_bet_add") {
		selectedValue = sportsData.filter(row => row.name == data?.sport_name);
	}
	if (type == "SelectionModal") {
		selectedValue = sportsData.filter(row => row.name == data?.name);
	}
	if (widgetType && ["in_play", "starting_soon", "swifty_specials"].includes(widgetType)) {
		selectedValue = null;
	}
	let filteredSportsOptions = [];

	if (type == "sports_widget") {
		if (data?.selected_sports?.length > 0) {
			filteredSportsOptions = sportsData.filter(sport => {
				return !data?.selected_sports.some(selectedSport => selectedSport.slug === sport.slug);
			});
		} else filteredSportsOptions = sportsData;
	}

	return (
		<>
			<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal " : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
				<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
					{type == "custom_bet_add" ? "Sport Type" : "Sports"}
				</div>
				<div
					className={`dropdown-wrapper
						${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
				>
					<Autocomplete
						onInputChange={handleInputChange}
						onClick={onClick}
						disablePortal
						className={classname ? `autocomplete-select input-arrow  ${classname}` : "autocomplete-select input-arrow"}
						options={type == "SelectionModal" ? "" : type == "sports_widget" ? filteredSportsOptions : sportsData}
						value={selectedValue && selectedValue.length > 0 ? selectedValue[0] : { name: "", id: "" }}
						getOptionLabel={option => option.name}
						sx={{ width: 300 }}
						renderInput={params => (
							<TextField
								// placeholder={type == "custom_bet_add" ? "Choose Sport" : type == "addMarket" ? "All Sports" : "Select Sport"}
								placeholder="Required"
								{...params}
								onClick={onClick}
							/>
						)}
						onChange={(event, newValue, reason) => {
							if (reason === "clear") {
								if (type === "change-link") {
									setLinkData({
										...linkData,
										link: "",
									});
								} else if (type === "competitor") {
									setData("");
								} else if (type == "competitions_edit_modal") {
									setData({
										...data,
										sport_id: "",
										sport_name: "",
									});
								} else
									setData({
										...data,
										sport_id: "",
										event_id: "",
										market_id: "",
										competition_id: "",
									});
							} else {
								if (type == "competitions_add_modal") {
									setData({
										...data,
										sport_id: newValue?.id,
										sport_name: newValue?.name,
										sport_slug: newValue?.slug,
									});
								} else if (type == "competitions_edit_modal") {
									setData({
										...data,
										sport_id: newValue?.id,
										sport_slug: newValue?.slug,
										sport_name: newValue?.name,
									});
								} else if (type == "custom_bet_add") {
									setData({
										...data,
										sport_id: newValue?.id,
										sport_name: newValue?.name,
										sport_slug: newValue?.slug,
									});
								} else if (type === "change-link") {
									setLinkData({
										...linkData,
										link: `/${newValue.name.toLowerCase().replace(/\s/g, "_")}`,
									});
								} else if (type === "competitor") {
									setData(newValue?.name);
								} else if (type === "marketsModal") {
									setData({ ...data, sport_id: newValue?.genius_id });
								} else {
									if (widgetType && ["in_play", "starting_soon", "swifty_specials"].includes(widgetType)) {
										let actualSports = [...(data?.selected_sports || [])];
										if (!actualSports.some(row => row.slug === newValue?.slug)) {
											actualSports.push(newValue);
											setData({
												...data,
												selected_sports: actualSports,
												sport_id: "",
												sport_slug: "",
											});
										}
									} else {
										setData({
											...data,
											sport_id: newValue?.slug,
											sport_slug: newValue?.genius_id,
										});
									}
								}
							}
							handleDataChanged?.();
						}}
					/>
				</div>
			</div>
			{widgetType && ["in_play", "starting_soon", "swifty_specials"].includes(widgetType) && (
				<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-1"}>
					<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}></div>
					<div
						className={
							firstRowClass || otherRowClass
								? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal flex-column"
								: "col-12 col-lg-8 d-flex align-items-center flex-column"
						}
					>
						<DragAndDropList
							data={data.selected_sports}
							setData={newData => {
								setData({
									...data,
									selected_sports: newData,
								});
								handleDataChanged?.();
							}}
							className="no-borders"
							disable={!draggable}
						>
							{(row, { index, dragHandleProps, isDragging }) => {
								let dayClass = index === 0 ? "first-day" : "";
								return (
									<div className={dayClass + ` seleted-day`} key={index}>
										<span>{row.name}</span>
										<div className="d-flex align-items-center justify-content-end gap-2">
											<span
												className="delete-selected-day"
												onClick={() => {
													let actualSports = [...(data?.selected_sports || [])];
													actualSports.splice(index, 1);
													setData({
														...data,
														selected_sports: actualSports,
													});
													handleDataChanged?.();
												}}
											>
												<TrashIcon />
											</span>
											{draggable && (
												<div
													{...dragHandleProps}
													onMouseDown={(...args) => {
														dragHandleProps?.onDragStart(...args);
													}}
												>
													<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
												</div>
											)}
										</div>
									</div>
								);
							}}
						</DragAndDropList>
					</div>
				</div>
			)}
		</>
	);
};
export const SpecialsCompetitionInput = ({
	data,
	onClick,
	handleInputChange,
	handleShowOptions,
	handleDataChanged,
	classname,
	setAddCustomBetData,
	otherRowClass,
}) => {
	const [selectedCompetition, setSelectedCompetition] = useState({});
	const newArray = data.map(item => ({
		name: item.name,
		id: item.id,
	}));

	newArray.forEach(item => {
		item.key = `${item.id}-${Math.random().toString(36).substring(7)}`;
	});

	return (
		<div
			className={`dropdown-wrapper ${!otherRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}
			key="competition-input"
		>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				Competition
			</div>
			<div
				className={`dropdown-wrapper ${
					otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}`}
			>
				<Autocomplete
					onInputChange={handleInputChange}
					onClick={onClick}
					disablePortal
					className={classname ? `autocomplete-select input-arrow  ${classname}` : "autocomplete-select input-arrow"}
					options={newArray}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					value={selectedCompetition?.name}
					renderInput={params => <TextField placeholder={"Choose Competition"} {...params} onClick={onClick} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setSelectedCompetition({ ...selectedCompetition, name: "", id: "" });
						} else {
							setAddCustomBetData(prevData => ({
								...prevData,
								competition_id: newValue?.id,
							}));
							setSelectedCompetition({
								...selectedCompetition,
								name: newValue?.name,
								id: newValue?.id,
								competition_genius_id: newValue?.genius_id,
							});
						}
						handleDataChanged?.();
					}}
					onFocus={() => {
						handleShowOptions("hidden");
					}}
				/>
			</div>
		</div>
	);
};
export const MatchesInput = ({
	data,
	onClick,
	handleInputChange,
	handleShowOptions,
	handleDataChanged,
	classname,
	defaultLeagueData,
	setDefaultLeagueData,
	firstRowClass,
	otherRowClass,
}) => {
	const [selectedMatch, setSelectedMatch] = useState({});

	const optionsWithKeys = data.map(option => ({
		...option,
		key: Math.random(),
	}));

	return (
		<div
			className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}
			key={Math.random()}
		>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				Match
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					onInputChange={handleInputChange}
					onClick={onClick}
					disablePortal
					className={classname ? `autocomplete-select input-arrow  ${classname}` : "autocomplete-select input-arrow"}
					options={optionsWithKeys}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					value={selectedMatch?.name}
					renderInput={params => <TextField placeholder={"Choose Match"} {...params} onClick={onClick} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setSelectedMatch({});
							setDefaultLeagueData({ ...defaultLeagueData, match_id: "" });
						} else {
							setSelectedMatch({ ...selectedMatch, name: newValue?.name, id: newValue?.id });
							setDefaultLeagueData({ ...defaultLeagueData, match_id: newValue?.id });
						}
						handleDataChanged?.();
					}}
					onFocus={() => {
						handleShowOptions("hidden");
					}}
				/>
			</div>
		</div>
	);
};
export const RegionsInput = ({
	regionsData,
	data,
	setData,
	type,
	linkData,
	setLinkData,
	onClick,
	handleInputChange,
	handleDataChanged,
	firstRowClass,
	otherRowClass,
}) => {
	let selectedValue = regionsData.filter(row => row.id === data?.region_id);

	if (type == "competition") {
		selectedValue = regionsData.filter(row => row.name == data?.region_name);
	}

	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				Region
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					onInputChange={handleInputChange}
					onClick={onClick}
					disablePortal
					className="autocomplete-select input-arrow"
					options={regionsData}
					value={selectedValue && selectedValue.length > 0 ? selectedValue[0] : null}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={type == "custom_bet_add" ? "Choose Region" : "Select Region"} {...params} onClick={onClick} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							if (type == "custom_bet_add") {
								setData({ ...data, region_id: "", genius_id: "" });
							}
							if (type == "competition") {
								setData({ ...data, region_name: "" });
							}
							setData({
								...data,
								region_id: "",
							});
							if (type === "change-link") {
								setLinkData({
									...linkData,
									link: "",
								});
							}
						} else {
							if (type == "competition") {
								setData({ ...data, region_name: newValue?.name, region_id: newValue?.id });
							} else if (type == "custom_bet_add") {
								setData({
									...data,
									region_id: newValue?.genius_id,
									region_name: newValue?.name,
								});
							} else if (type !== "edit_venue") {
								setData({
									...data,
									region_id: newValue?.id,
								});
							} else {
								setData({
									...data,
									region_id: newValue?.id,
									region_name: newValue?.name,
								});
							}
							if (type === "change-link") {
								setLinkData({
									...linkData,
									link: `/${newValue.name.toLowerCase().replace(/\s/g, "_")}`,
								});
							}
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};
export const TypeCompetitorsInput = ({ onClick, handleDataChanged, setData, data, type, allData, firstRowClass, otherRowClass }) => {
	const options = [
		{
			name: "Team",
			value: "team",
		},
		{
			name: "Person",
			value: "person",
		},
	];
	let selectedValue;
	if ((type == "competitor" || allData) && data) {
		selectedValue = options.find(row => row.name == data);
	}

	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ width: "115px" }}>
				Type
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					onClick={onClick}
					disablePortal
					className="autocomplete-select input-arrow"
					options={options}
					value={selectedValue ? selectedValue : null}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Select Type"} {...params} onClick={onClick} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							if (type == "competitor") {
								setData("");
							} else if (allData) {
								setData({
									...allData,
									type: "",
								});
							} else
								setData({
									...data,
									type: "",
								});
						} else {
							if (type == "competitor") {
								setData(newValue?.name);
							} else if (allData) {
								setData({
									...allData,
									type: newValue.name,
								});
							} else
								setData({
									...data,
									type: newValue.value,
								});
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};
export const EventIDInput = ({
	eventsData,
	data,
	setData,
	linkData,
	setLinkData,
	onClick,
	handleDataChanged,
	firstRowClass,
	otherRowClass,
	optional,
	required = true,
}) => {
	let selectedValue = eventsData.filter(row => row?.id == data?.event_id);
	if (data?.event_name && data?.event_id) selectedValue = [{ id: data?.event_id, label: data?.event_name }];
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				Event
				{optional && (
					<>
						<br /> <span className="optional">(optional)</span>
					</>
				)}
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					onClick={onClick}
					disablePortal
					className="autocomplete-select input-arrow"
					options={eventsData}
					defaultValue={data?.event_name && data?.event_id ? { id: data?.event_id, label: data?.event_name } : null}
					value={selectedValue && selectedValue.length > 0 ? selectedValue[0] : null}
					getOptionLabel={option => option.label}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={required ? "Required" : "Optional"} {...params} onClick={onClick} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setData({
								...data,
								event_id: "",
								event_name: "",
								market_id: "",
							});
							if (setLinkData) {
								setLinkData({
									...linkData,
									link: "",
								});
							}
						} else {
							setData({
								...data,
								event_id: newValue?.id,
								event_name: newValue?.label,
								market_id: "",
							});
							if (setLinkData) {
								setLinkData({
									...linkData,
									link: `/match/${newValue?.id}`,
								});
							}
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};
export const MarketInput = ({ markets, data, setData, onClick, handleDataChanged, notOptional, firstRowClass, otherRowClass }) => {
	let selectedValue = {};

	if (data?.market_id) {
		selectedValue = markets.filter(row => row.market_id.toString() === data?.market_id.toString());
	}
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4  align-items-center input-title optional-input" style={{ maxWidth: "115px" }}>
				Market
				{!notOptional && (
					<>
						<br /> <span className="optional">(optional)</span>
					</>
				)}
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					options={markets}
					getOptionLabel={option => option.market_name}
					value={selectedValue && selectedValue.length > 0 ? selectedValue[0] : null}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Required"} {...params} onClick={onClick} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setData({
								...data,
								market_id: "",
							});
						} else {
							setData({
								...data,
								market_id: newValue?.market_id,
							});
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const SelectionInput = ({ onChange, onClick, object }) => {
	const name = object?.name || "";
	const data = object?.data || [];
	const placeholder = object?.placeholder || name;
	const disabled = object?.disabled || false;
	const disabledOption = object?.disabledOption;
	const required = object?.required || false;
	const inputClassName = object?.inputClassName || "";
	const readOnly = object?.readOnly || false;
	const underText = object?.underText || "";
	const addionalICon = object?.addionalICon || false;
	const hideEdit = object?.hideEdit || false;
	const gridLayout = object?.gridLayout || false;
	const fieldName = object?.field || "";
	const fieldId = object?.fieldId || "";
	const customizedSelectionInput = object?.customizedSelectionInput || false;
	const isAnyEditOpen = data?.some(d => d.active);

	const inputContainer = object?.inputContainer || "";
	const wrapperClassName = name
		? `row wrapper_customized_modal align-items-start ${inputContainer}`
		: `row wrapper_customized_modal justify-content-center ${inputContainer}`;
	const containerWrapper = `col-12 col-lg-8 input-dropdown selection-input-container ${customizedSelectionInput} ${
		gridLayout ? "grid-layout" : isAnyEditOpen && !hideEdit ? "selections_container" : ""
	}`;
	const titleClassName = "col-12 col-lg-4 d-flex align-items-center input-title";

	const [inactiveSelection, setInactiveSelection] = useState({});

	useEffect(() => {
		setInactiveSelection(data?.reduce((a, v) => ({ ...a, [v[fieldId]]: true }), {}));
	}, [data[0]?.selection_id]);

	return (
		<div className={wrapperClassName}>
			{name && (
				<div className={titleClassName}>
					<div>
						{name}
						{underText && <div className="opacity-50">{underText}</div>}
					</div>
					<div className="prediction_icon_information">{addionalICon && <PredictionInformationIcon />}</div>
				</div>
			)}

			<div className={containerWrapper} style={{ position: "relative" }}>
				{data?.map((row, index) => (
					<div key={index} className="position-relative d-flex align-items-center">
						<input
							type={"text"}
							placeholder={placeholder}
							value={object?.formatCurrency ? ThousandSeperated(row[fieldName]) : row[fieldName]}
							onClick={onClick}
							onChange={e => onChange(e, row)}
							className={`${inactiveSelection[row[fieldId]] ? "" : "edit-selection"} ${
								row?.active === 0
									? `general_input ${inputClassName} non-selection-inputs`
									: `general_input ${inputClassName} ${typeof row?.active != "undefined" ? "more-padding-right selection-inputs" : ""}`
							}`}
							disabled={inactiveSelection[row[fieldId]] || disabled}
							readOnly={readOnly}
							required={required}
						/>
						{typeof row?.active != "undefined" && (
							<div style={{ position: "absolute", right: "6px", top: "6px", cursor: "pointer", zIndex: "1" }}>
								<input
									type="checkbox"
									name="oddsOption"
									className={
										disabledOption && row.active == 0
											? "selection-checked-input cursor-pointer round-checkbox max-selection"
											: "selection-checked-input cursor-pointer round-checkbox"
									}
									checked={row?.active == 1}
									onClick={() => object?.checkBoxOnChange(row, index)}
									disabled={disabledOption && row.active == 0}
								/>
							</div>
						)}
						{!(disabledOption && row?.active == 0) && row?.active == 1 && !hideEdit && (
							<div className="editSelection">
								<EditIconButton onClick={() => setInactiveSelection({ ...inactiveSelection, [row[fieldId]]: !inactiveSelection[row[fieldId]] })} />
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export const CompetitionInput = ({ competitions, data, setData, onClick, handleDataChanged, firstRowClass, otherRowClass, notOptional }) => {
	let selectedValue = competitions.filter(row => row.id === data?.competition_id);

	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4  align-items-center input-title optional-input" style={{ maxWidth: "115px" }}>
				Competition
				{!notOptional && (
					<>
						<br /> <span className="optional">(optional)</span>
					</>
				)}
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					options={competitions}
					getOptionLabel={option => option.name}
					value={selectedValue && selectedValue.length > 0 ? selectedValue[0] : null}
					sx={{ width: 300 }}
					renderOption={(props, option) => (
						<li {...props} key={option.id}>
							{option.name}
						</li>
					)}
					renderInput={params => <TextField placeholder={"Required"} {...params} onClick={onClick} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setData({
								...data,
								competition_id: "",
								event_id: "",
								market_id: "",
							});
						} else {
							setData({
								...data,
								competition_id: newValue?.id,
								event_id: "",
							});
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const GameIDInput = ({ data, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ width: "115px" }}>
				Game ID
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input type={"text"} placeholder={"2116"} value={data?.game_id} disabled className="input_customized_casino" />
			</div>
		</div>
	);
};

export const CodeInput = ({ data, setData, onClick, style, editItem, handleDataChanged, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ width: "115px" }}>
				Code
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				{!editItem ? (
					<input
						type={"text"}
						placeholder={"Required"}
						value={data?.code}
						onChange={e => {
							setData({
								...data,
								code: e.target.value,
							});
							handleDataChanged?.();
						}}
						onClick={onClick}
						style={style}
						className="general_input"
					/>
				) : (
					<input
						type={"text"}
						placeholder={"Required"}
						value={data?.code}
						onChange={e => {
							setData({
								...data,
								code: e.target.value,
							});
							handleDataChanged?.();
						}}
						onClick={onClick}
						style={style}
						disabled
						readOnly
						className="general_input"
					/>
				)}
			</div>
		</div>
	);
};
export const MessageInput = ({ data, setData, onClick, handleDataChanged, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ width: "115px" }}>
				Message
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input
					type={"text"}
					placeholder={"Required"}
					value={data?.message}
					onChange={e => {
						setData({
							...data,
							message: e.target.value,
						});
						handleDataChanged?.();
					}}
					onClick={onClick}
				/>
			</div>
		</div>
	);
};
export const VariableDescriptionInput = ({ data, setData, onClick, handleDataChanged }) => {
	return (
		<div className="other_row_customized_modal">
			<div className="col-12 col-lg-4 d-flex align-items-center input-title input_custom_label">Description</div>
			<div className="col-12 col-lg-8 d-flex align-items-center input_custom_add_modal">
				<input
					type={"text"}
					placeholder={"Description"}
					value={data?.description}
					onChange={e => {
						setData({
							...data,
							description: e.target.value,
						});
						handleDataChanged?.();
					}}
					onClick={onClick}
				/>
			</div>
		</div>
	);
};
export const ValueInput = ({ data, setData, onClick, handleDataChanged }) => {
	return (
		<div className="other_row_customized_modal">
			<div className="col-12 col-lg-4 d-flex align-items-center input-title input_custom_label">
				<label placeholder={"optional"}>Value</label>
			</div>
			<div className="col-12 col-lg-8 d-flex align-items-center input_custom_add_modal">
				<textarea
					placeholder="Value"
					rows={3}
					value={data?.value}
					onChange={e => {
						setData({
							...data,
							value: e.target.value,
						});
						handleDataChanged?.();
					}}
					onClick={onClick}
				></textarea>
			</div>
		</div>
	);
};

export const ReturnToPlayerInput = ({ data, setData, handleDataChanged, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title optional-input" style={{ width: "115px" }}>
				<label placeholder={"optional"}>
					Return to Player <br /> <span className="optional">(optional)</span>
				</label>
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input
					type={"number"}
					placeholder={"0.00"}
					className="input_customized_casino"
					value={data?.return_to_player}
					onChange={e => {
						setData({
							...data,
							return_to_player: e.target.value,
						});
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const MaxBetInput = ({ data, setData, handleDataChanged, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title optional-input" style={{ width: "115px" }}>
				<label placeholder={"optional"}>
					Max Bet <br /> <span className="optional">(optional)</span>
				</label>
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input
					type={"number"}
					placeholder={"0.00"}
					className="input_customized_casino"
					value={data?.max_bet}
					onChange={e => {
						setData({
							...data,
							max_bet: e.target.value,
						});
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const MinBetInput = ({ data, setData, handleDataChanged, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title optional-input" style={{ width: "115px" }}>
				<label placeholder={"optional"}>
					Min Bet <br /> <span className="optional">(optional)</span>
				</label>
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input
					type={"number"}
					className="input_customized_casino"
					placeholder={"0.00"}
					value={data?.min_bet}
					onChange={e => {
						setData({
							...data,
							min_bet: e.target.value,
						});
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const MaxWinInput = ({ data, setData, handleDataChanged, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title optional-input" style={{ width: "115px" }}>
				<label placeholder={"optional"}>
					Max Win <br /> <span className="optional">(optional)</span>
				</label>
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input
					type={"number"}
					placeholder={"0.00"}
					className="input_customized_casino"
					value={data?.max_win}
					onChange={e => {
						setData({
							...data,
							max_win: e.target.value,
						});
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const JackpotAmount = ({ data, setData, handleDataChanged, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title optional-input" style={{ width: "115px" }}>
				<label placeholder={"optional"}>
					Jackpot Amount <br /> <span className="optional">(optional)</span>
				</label>
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input
					type={"number"}
					placeholder={"Jackpot Amount"}
					className="input_customized_casino"
					value={data?.jackpot_amount}
					onChange={e => {
						setData({
							...data,
							jackpot_amount: e.target.value,
						});
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const ShowInMenuInput = ({ checked, handleChange, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ width: "115px" }}>
				Show in main casino
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input type="checkbox" className="published-input" checked={checked} onChange={handleChange} />
			</div>
		</div>
	);
};

export const ViewStyleInput = ({ data, setData, handleDataChanged, firstRowClass, otherRowClass }) => {
	let options = [
		{
			name: "Single Line",
			value: "single_line",
		},
		{
			name: "Hero Multi-Line",
			value: "hero_multi_line",
		},
		{
			name: "Multi-Line",
			value: "multi_line",
		},
		{
			name: "Trending",
			value: "trending",
		},
	];
	let defaultValue = { name: "Trending", value: "trending" };
	switch (data?.view_style) {
		case "single_line":
			defaultValue = { name: "Single Line", value: "single_line" };
			break;
		case "hero_multi_line":
			defaultValue = { name: "Hero Multi-Line", value: "hero_multi_line" };
			break;
		case "multi_line":
			defaultValue = { name: "Multi-Line", value: "multi_line" };
			break;
		case "trending":
			defaultValue = { name: "Trending", value: "trending" };
			break;
	}
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ width: "115px" }}>
				View Style
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					options={options}
					defaultValue={defaultValue}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Category"} {...params} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setData({
								...data,
								view_style: "",
							});
						} else {
							setData({
								...data,
								view_style: newValue?.value,
							});
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const SearchInput = ({ onChange, searchValue }) => {
	return (
		<div className="search-input-group">
			<div className="search-input-container">
				<input type={"text"} placeholder={"Search"} value={searchValue} onChange={e => onChange(e.target.value)} />
				<XSmIcon onClick={() => onChange("")} />
			</div>
			<div className="btn-primary btn-search-casino-group">
				<SearchIcon fill={"#FFFFFF"} />
			</div>
		</div>
	);
};

export const PageInput = ({ linkData, setLinkData, pageContent, handleDataChanged }) => {
	return (
		<div className="row mt-3 dropdown-wrapper">
			<div className="col-12 col-lg-4 d-flex align-items-center input-title">Page</div>
			<div className="col-12 col-lg-8 d-flex align-items-center dropdown-wrapper">
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					options={pageContent}
					getOptionLabel={option => option.friendly_name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Page"} {...params} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setLinkData({
								...linkData,
								link: "",
							});
						} else {
							setLinkData({
								...linkData,
								link: `/${newValue.name}`,
							});
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const GameInput = ({ gamesData, linkData, setLinkData, handleDataChanged }) => {
	return (
		<div className="row mt-3 dropdown-wrapper">
			<div className="col-12 col-lg-4 d-flex align-items-center input-title">Game</div>
			<div className="col-12 col-lg-8 d-flex align-items-center dropdown-wrapper">
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					options={gamesData}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Game"} {...params} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setLinkData({
								...linkData,
								link: "",
							});
						} else {
							setLinkData({
								...linkData,
								link: `/casino/${newValue.id}`,
							});
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const NeverExpireInput = ({ checked, handleChange, onClick, firstRowClass, otherRowClass }) => {
	return (
		<div
			className={
				firstRowClass ? "first_row_customized_modal dropdown_published" : otherRowClass ? "other_row_customized_modal dropdown_published" : "row mt-3"
			}
		>
			<div className="col-12 col-lg-4 d-flex align-items-center input-switch">Never Expire</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<label className={`switch`}>
					<input type="checkbox" className="published-input" checked={checked} onChange={handleChange} onClick={onClick} />
					<span className="slider round"></span>
				</label>
			</div>
		</div>
	);
};

export const MenuLinksShowInput = ({ checked, handleChange, onClick, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-switch">Show in Menu</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<label className={`switch`}>
					<input type="checkbox" className="published-input" checked={checked} onChange={handleChange} onClick={onClick} />
					<span className="slider round"></span>
				</label>
			</div>
		</div>
	);
};

export const DefaultImageInput = ({ checked, handleChange, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={firstRowClass || otherRowClass ? { width: "115px" } : {}}>
				Default Image
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input type="checkbox" className="published-input" checked={checked} onChange={handleChange} />
			</div>
		</div>
	);
};
export const GeneralCheckbox = ({ object, handleChange }) => {
	const type = object?.type || "checkbox";
	const checked = object?.checked;
	const name = object?.name || "";
	const onChange = object?.handleChange || handleChange;
	const onClick = object?.handleClick || null;
	const checkboxExtraClass = object?.checkboxExtraClass || "";
	const checkboxContainer = object?.checkboxContainer || "";
	const disabled = object?.disabled || false;

	return (
		<div className={`row wrapper_customized_modal ${checkboxContainer}`}>
			{name && <div className="col-12 col-lg-4 d-flex align-items-center input-title">{name}</div>}
			<div className={`col-12 col-lg-8 d-flex align-items-center ${checkboxExtraClass}`}>
				<input
					type={type}
					className="published-input"
					checked={checked}
					disabled={disabled}
					onChange={() => onChange(!checked, object.fieldName)}
					onClick={() => onClick(!checked, object.fieldName)}
				/>
			</div>
		</div>
	);
};

export const HideInput = ({ data, setData, handleDataChanged }) => {
	return (
		<div className="other_row_customized_modal">
			<div className="col-12 col-lg-4 d-flex align-items-center input-title input_custom_label">Hide</div>
			<div className="col-12 col-lg-8 d-flex align-items-center input_custom_add_modal">
				<input
					type="checkbox"
					className="published-input"
					checked={data.show_hide}
					onChange={() => {
						setData({
							...data,
							show_hide: !data?.show_hide,
						});
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const HeaderDropdown = React.forwardRef((props, ref) => {
	const { showOptions, setShowOptions, data, setPageType, pageLayoutType, addNew, addNewHandler, setAccept } = props;
	let selectedPage = data.filter(row => row.value === pageLayoutType);
	return (
		<div ref={ref} style={{ marginRight: "12px" }}>
			<div
				className="header-selected-page"
				onClick={() => {
					setShowOptions(!showOptions);
				}}
			>
				<span>{selectedPage.length > 0 ? selectedPage[0]?.name : "Select"}</span>
				<ArrowDownIcon />
			</div>
			{showOptions && (
				<div className="header-options">
					{data.map((row, index) => {
						return (
							<span
								className={`header-option ${selectedPage[0]?.name === row?.name ? "header-option-grey" : ""}`}
								key={index}
								onClick={() => {
									setPageType(row.value);
									setAccept(row.accept);
									setShowOptions(false);
								}}
							>
								{row?.name}
							</span>
						);
					})}
					{addNew && (
						<span
							className="header-option add-new-item"
							onClick={() => {
								setShowOptions(false);
								addNewHandler();
							}}
						>
							+ Add new carousel
						</span>
					)}
				</div>
			)}
		</div>
	);
});
HeaderDropdown.displayName = "HeaderDropdown";
export const FilenameInput = ({ disabled, firstRowClass, otherRowClass }) => {
	let inputClass = disabled ? "disabled-input" : "";
	return (
		<div className={firstRowClass ? "first_row_customized_modal " : otherRowClass ? "other_row_customized_modal " : "row mt-3"}>
			<div className={inputClass + ` col-12 col-lg-4 d-flex align-items-center input-title`} style={{ width: "115px" }}>
				Filename
			</div>
			<div
				className={
					firstRowClass || otherRowClass
						? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal "
						: "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input type="text" placeholder="Filename" disabled />
			</div>
		</div>
	);
};

export const AltTextInput = ({ data, setData, disabled, handleDataChanged, firstRowClass, otherRowClass }) => {
	let inputClass = disabled ? "disabled-input" : "";
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className={inputClass + ` col-12 col-lg-4 input-title optional-input`} style={{ width: "115px" }}>
				{"Alt Text"} <br /> {<span className="optional">(Optional)</span>}
			</div>
			<div
				className={
					firstRowClass || otherRowClass
						? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal "
						: "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<input
					type="text"
					placeholder="Alt Text"
					disabled={disabled}
					onChange={e => {
						handleDataChanged?.();
						setData({
							...data,
							alt: e.target.value,
						});
					}}
				/>
			</div>
		</div>
	);
};

export const MediaTagsInput = ({ ref, showOptions, setShowOptions, data, setData, type, handleDataChanged, firstRowClass, otherRowClass }) => {
	const options = [
		{
			name: "Betslip",
			value: "bet_slip",
		},
		{
			name: "Carousel",
			value: "carousel",
		},
		{
			name: "Hero Banner",
			value: "banner",
		},
		{
			name: "Hero Widget",
			value: "hero_widget",
		},
	];
	const matchingOption = options.find(option => option.value === type);
	return (
		<div className={firstRowClass ? "first_row_customized_modal " : otherRowClass ? "other_row_customized_modal " : "row mt-3"} ref={ref}>
			<div className="col-12 col-lg-4  align-items-center input-title" style={{ width: "115px" }}>
				Tags
			</div>
			<div
				className={
					firstRowClass || otherRowClass
						? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal "
						: "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<div className="w-100  position-relative">
					<div
						className="media-tags-select"
						onClick={() => {
							setShowOptions(!showOptions);
						}}
					>
						<span>{matchingOption?.name ? matchingOption?.name : ""}</span>
						<DarkArrowDownIcon />
					</div>
					{showOptions && (
						<div className="media-tags-options">
							{options.map((row, index) => {
								let selected = row.value === type || data.tags.includes(row.value) ? "selected-tag" : "";
								let disabled = row.value === type ? true : false;
								return (
									<p className={selected + ` media-tags-option`} key={index}>
										{!data.tags.includes(row.value) && !disabled ? (
											<span
												className="unselected-option"
												onClick={() => {
													let types = [...data.tags];
													types.push(row.value);
													handleDataChanged?.();
													setData({
														...data,
														tags: types,
													});
												}}
											></span>
										) : (
											<span
												className="selected-option"
												onClick={() => {
													if (!disabled) {
														let types = [...data.tags];
														if (types.includes(row.value)) {
															const index = types.indexOf(row.value);
															if (index > -1) {
																types.splice(index, 1);
															}
														}
														handleDataChanged?.();
														setData({
															...data,
															tags: types,
														});
													}
												}}
											>
												<TickIcon />
											</span>
										)}
										<span>{row.name}</span>
									</p>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export const SpecificDaysInput = ({ data, setData, handleDataChanged, onClick, firstRowClass, otherRowClass }) => {
	const [daysData, setDaysData] = useState(_daysOptions);
	useEffect(() => {
		let newData = [...daysData];
		newData.forEach(row => {
			const exist = data?.selected_days && Array.isArray(data.selected_days) ? data.selected_days.some(el => el.id === row.id) : false;
			if (exist) {
				const index = newData.findIndex(el => el.id === row.id);
				newData.splice(index, 1);
			}
		});
		setDaysData(newData);
	}, []);
	return (
		<>
			<div
				className={
					firstRowClass ? "first_row_customized_modal dropdown_published" : otherRowClass ? "other_row_customized_modal dropdown_published" : "row mt-3"
				}
			>
				<div className={`col-12 col-lg-4 input-switch`}>Specific Days</div>
				<div
					className={
						firstRowClass || otherRowClass
							? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal"
							: "col-12 col-lg-8 d-flex align-items-center"
					}
				>
					<label className={`switch`}>
						<input
							type="checkbox"
							checked={data?.specific_days}
							onChange={e => {
								handleDataChanged?.();
								e.persist();
								setData({
									...data,
									specific_days: !data.specific_days,
								});
							}}
							onClick={onClick}
						/>
						<span className="slider round"></span>
					</label>
				</div>
			</div>
			{data?.specific_days ? (
				<>
					<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
						<div className="col-12 col-lg-4 input-title">Days</div>
						<div
							className={`dropdown-wrapper
								${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input-dropdown" : "col-12 col-lg-8 d-flex align-items-center"}`}
						>
							<Autocomplete
								disablePortal
								className="autocomplete-select input-arrow"
								options={_daysOptions}
								getOptionLabel={option => option.label}
								sx={{ width: 360 }}
								renderInput={params => <TextField placeholder={"Select"} {...params} />}
								onChange={(event, newValue, reason) => {
									let actualDays = [...data.selected_days];
									if (reason !== "clear") {
										let exist = actualDays.some(row => row.id === newValue?.id);
										if (!exist) {
											actualDays.push(newValue);
										}
										let newData = [...daysData];
										newData = newData.filter(row => row.id !== newValue?.id);

										setDaysData(newData);
									}
									handleDataChanged?.();
									const formatedActualDays = actualDays.map(({ id, label, name, value }) => ({ name: label || name, value: id || value }));
									setData({
										...data,
										selected_days: formatedActualDays,
									});
								}}
							/>
						</div>
					</div>
					<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row"}>
						<div className="col-12 col-lg-4 input-title"></div>
						<div className="col-12 col-lg-8 d-flex align-items-center flex-column input-dropdown">
							{data?.selected_days.map((row, index) => {
								return (
									<div className={"seleted-day bg-white-multiple-dropdown"} key={index}>
										<span>{row.name}</span>

										<span
											className="delete-selected-day"
											onClick={() => {
												let newData = [...daysData];
												let actualDays = [...data.selected_days];
												actualDays = actualDays.filter(el => el.value !== row.value);
												setData({
													...data,
													selected_days: actualDays,
												});
												let returnIndex = returnDayIndex(row.value);
												newData.splice(returnIndex, 0, row);
												setDaysData(newData);
												handleDataChanged?.();
											}}
										>
											<TrashIcon />
										</span>
									</div>
								);
							})}
						</div>
					</div>
				</>
			) : (
				""
			)}
		</>
	);
};

export const SpecificTimesInput = ({ data, setData, handleDataChanged, onClick, firstRowClass, otherRowClass, specificTimes }) => {
	return (
		<div
			className={
				firstRowClass ? "first_row_customized_modal dropdown_published" : otherRowClass ? "other_row_customized_modal dropdown_published" : "row mt-3"
			}
		>
			<div className={`col-12 col-lg-4 input-switch`}>Specific Times</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
				style={specificTimes ? { width: "380px" } : {}}
			>
				<label className={`switch`}>
					<input
						type="checkbox"
						checked={data?.specific_time}
						onChange={e => {
							e.persist();
							let status = e?.target?.checked;
							setData({
								...data,
								specific_time: status,
							});
							handleDataChanged?.();
						}}
						onClick={onClick}
					/>
					<span className="slider round"></span>
				</label>
			</div>
		</div>
	);
};

export const StatusSwitch = ({ label, data, setData, handleDataChanged, onClick, type, checked_type, allValues, id, firstRowClass, otherRowClass }) => {
	return (
		<div
			className={
				firstRowClass ? "first_row_customized_modal dropdown_published" : otherRowClass ? "other_row_customized_modal dropdown_published" : "row mt-3"
			}
		>
			<div className={`col-12 col-lg-4 input-switch`}>{type !== "markets" ? (label ? label : "Status") : "Active"}</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<label className={`switch`}>
					<input
						type="checkbox"
						checked={checked_type == "status" ? data?.status : data?.active}
						onChange={e => {
							e.persist();
							let status = e?.target?.checked;
							status = status === true ? 1 : 0;
							if (type == "editSelectionMarket") {
								const updatedValues = allValues.map(value => {
									if (value.id === id) {
										return {
											...value,
											active: value?.active == 1 ? 0 : 1,
										};
									}
									return value;
								});
								setData(updatedValues);
							} else if (checked_type == "status") {
								setData({
									...data,
									status: status,
								});
							} else {
								setData({
									...data,
									active: status,
								});
							}
							handleDataChanged?.();
						}}
						onClick={onClick}
					/>
					<span className="slider round"></span>
				</label>
			</div>
		</div>
	);
};
export const FavoriteSwitch = ({ data, setData, handleDataChanged, onClick, firstRowClass, otherRowClass }) => {
	return (
		<div
			className={
				firstRowClass ? "first_row_customized_modal dropdown_published" : otherRowClass ? "other_row_customized_modal dropdown_published" : "row mt-3"
			}
		>
			<div className={`col-12 col-lg-4 input-switch`}>Expanded</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<label className={`switch`}>
					<input
						type="checkbox"
						checked={data?.favorite}
						onChange={e => {
							e.persist();
							let status = e?.target?.checked;
							status = status === true ? 1 : 0;
							setData({
								...data,
								favorite: status,
							});
							handleDataChanged?.();
						}}
						onClick={onClick}
					/>
					<span className="slider round"></span>
				</label>
			</div>
		</div>
	);
};

export const StartTimeInput = ({ data, setData, handleDataChanged, onClick, custumText, resetPaddingRight, firstRowClass, otherRowClass, specificTimes }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className={`col-12 col-lg-4 input-title`} style={resetPaddingRight ? { maxWidth: "115px", paddingRight: "0" } : { width: "115px" }}>
				{custumText ? custumText : "Start Time"}
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
				style={specificTimes ? { position: "relative" } : {}}
			>
				<Flatpickr
					className="form-control d-block chooseOption date_time_picker"
					placeholder="Select time"
					data-enable-time
					options={{
						noCalendar: true,
					}}
					value={data?.start_time}
					onChange={(date, selectedDate) => {
						setData({
							...data,
							start_time: selectedDate,
						});
						handleDataChanged?.();
					}}
					onClick={onClick}
				/>
				<TimeIcon
					className={specificTimes ? "calender-icon-fullscreen-customized" : firstRowClass || otherRowClass ? "calender-icon-customized" : "calendar-icon"}
				/>
			</div>
		</div>
	);
};

export const EndTimeInput = ({ data, setData, handleDataChanged, onClick, custumText, firstRowClass, otherRowClass, specificTimes }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}>
				{custumText ? custumText : "End Time"}
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
				style={specificTimes ? { position: "relative" } : {}}
			>
				<Flatpickr
					className="form-control d-block chooseOption date_time_picker"
					placeholder="Select time"
					data-enable-time
					options={{
						noCalendar: true,
					}}
					value={data?.end_time}
					onChange={(date, selectedDate) => {
						setData({
							...data,
							end_time: selectedDate,
						});
						handleDataChanged?.();
					}}
					onClick={onClick}
				/>
				<TimeIcon
					className={specificTimes ? "calender-icon-fullscreen-customized" : firstRowClass || otherRowClass ? "calender-icon-customized" : "calendar-icon"}
				/>
			</div>
		</div>
	);
};
export const SingleSearchFilterButtons = ({ extraHeaderButtons, data, setDatas, pageLayoutType, filterColumn, unfilteredData, detailsColumn, setData }) => {
	if (!data) return null;

	const pageLayout = data.find(item => item.slug === pageLayoutType);
	const [dataForFilterButton, setDataForFilterButton] = useState({
		slug: pageLayoutType,
		show_sidebar: pageLayout?.show_sidebar ? pageLayout?.show_sidebar : 0,
		show_betslip: pageLayout?.show_betslip ? pageLayout?.show_betslip : 0,
	});

	const [changed, setChanged] = useState(false);

	const showBetslip = pageLayout ? pageLayout.show_betslip : 0;
	const showSidebar = pageLayout ? pageLayout.show_sidebar : 0;

	useEffect(() => {
		if (changed) {
			editSwitch();
		}
	}, [changed]);

	const handleShowSidebarChange = () => {
		const updatedData = data.map(item => {
			if (item.slug === pageLayoutType) {
				return {
					...item,
					show_sidebar: showSidebar == 0 ? 1 : 0,
				};
			}
			return item;
		});

		setDataForFilterButton({
			...dataForFilterButton,
			slug: pageLayoutType,
			show_sidebar: showSidebar == 0 ? 1 : 0,
			show_betslip: showBetslip,
		});
		setDatas(updatedData);
		setChanged(true);
	};

	const handleShowBetslipChange = () => {
		const updatedData = data.map(item => {
			if (item.slug === pageLayoutType) {
				return {
					...item,
					show_betslip: showBetslip == 0 ? 1 : 0,
				};
			}
			return item;
		});

		setDataForFilterButton({
			...dataForFilterButton,
			slug: pageLayoutType,
			show_sidebar: showSidebar,
			show_betslip: showBetslip == 0 ? 1 : 0,
		});
		setDatas(updatedData);
		setChanged(true);
	};

	const editSwitch = () => {
		new Promise((resolve, reject) => {
			let body = dataForFilterButton;
			ApiServices.post(apiUrls.edit_menu_links, resolve, reject, body, true);
		})
			.then(() => {})
			.finally(() => {
				setChanged(false);
			});
	};

	return (
		<div className={extraHeaderButtons ? "col-lg-10 col-9 page_layout_filter mb-3" : "col-8 "}>
			<div className="input-group">
				<div>
					<SearchFilterIcon />
				</div>
				<div className="single-input-search-container ">
					<input
						placeholder="Search"
						onChange={e => {
							if (unfilteredData) {
								if (e.target.value === "") {
									setData(unfilteredData);
								} else {
									if (detailsColumn) {
										let newData = [...unfilteredData];
										let values = [];
										let filtered = [];
										values[0] = e.target.value;
										newData.forEach(item => {
											let itemDetails = item["details"];
											if (itemDetails) {
												itemDetails = JSON.parse(itemDetails);
											}
											detailsColumn.forEach(key => {
												values.forEach(value => {
													if (itemDetails[key] && itemDetails[key].toString().toLowerCase().includes(value.toLowerCase())) {
														filtered.push(item);
													}
												});
											});
										});
										const removeDuplicates = filtered.filter((value, index) => filtered.indexOf(value) === index);
										setData(removeDuplicates);
									} else {
										let newData = [...unfilteredData];
										let values = [];
										let filtered = [];
										values[0] = e.target.value;
										newData.forEach(item => {
											filterColumn.forEach(key => {
												values.forEach(value => {
													if (item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase())) {
														filtered.push(item);
													}
												});
											});
										});
										const removeDuplicates = filtered.filter((value, index) => filtered.indexOf(value) === index);
										setData(removeDuplicates);
									}
								}
							}
						}}
					/>
				</div>

				{extraHeaderButtons && (
					<div className="mt-md-3 mt-xl-0 extra-header-buttons">
						<SwitchInput
							handleChange={() => handleShowSidebarChange()}
							extraHeaderButtons
							object={{
								name: "Show Sidebar",
								value: showSidebar,
								switchTitleClassName: "switch-title",
								wrapperClassName: "pt-0",
							}}
						/>
						<SwitchInput
							handleChange={() => handleShowBetslipChange()}
							object={{
								name: "Show Betslip",
								value: showBetslip,
								switchTitleClassName: "switch-title",
								wrapperClassName: "pt-0",
							}}
							extraHeaderButtons
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export const SingleSearchFilter = props => {
	let { setData, filterColumn, unfilteredData, detailsColumn, type, existFilter, setExistFilter, noSpace } = props;
	const [newValue, setNewValue] = useState([""]);

	useEffect(() => {
		if (existFilter) {
			let newData = [...unfilteredData];
			let filtered = [];
			newData.forEach(item => {
				filterColumn.forEach(key => {
					newValue?.forEach(value => {
						if (item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase())) {
							filtered.push(item);
						}
					});
				});
			});
			const removeDuplicates = filtered.filter((value, index) => filtered.indexOf(value) === index);
			setData(removeDuplicates);
			setExistFilter(false);
		}
	}, [existFilter]);

	return (
		<div className={`single-input-container col-8 ${noSpace ? "" : " mb-4"}`}>
			<div className="input-group">
				<div>
					<SearchFilterIcon />
				</div>
				<div className="single-input-search-container">
					<input
						placeholder="Search"
						onChange={e => {
							if (unfilteredData) {
								if (e.target.value == "") {
									setData(unfilteredData);
									setNewValue([""]);
								} else {
									if (detailsColumn) {
										let newData = [...unfilteredData];
										let values = [];
										let filtered = [];
										if (type === "custom_bets") {
											const newData = [...unfilteredData];
											const searchValue = e.target.value.toLowerCase();
											const filteredData = [];

											newData.forEach(item => {
												const filteredProperties = Object.keys(item).filter(key => typeof item[key] === "string" || typeof item[key] === "number");

												const matchFound = filteredProperties.some(key => {
													const propertyValue = item[key].toString().toLowerCase();
													return propertyValue.includes(searchValue);
												});

												if (matchFound) {
													filteredData.push(item);
												}
											});

											setData(filteredData);
										} else {
											values[0] = e.target.value;
											newData.forEach(item => {
												let itemDetails = item["details"];
												if (itemDetails) {
													itemDetails = JSON.parse(itemDetails);
												} else itemDetails = item;

												detailsColumn.forEach(key => {
													values.forEach(value => {
														if (itemDetails[key] && itemDetails[key].toString().toLowerCase().includes(value.toLowerCase())) {
															filtered.push(item);
														}
													});
												});
											});
											const removeDuplicates = filtered.filter((value, index) => filtered.indexOf(value) === index);
											setData(removeDuplicates);
											setNewValue([e.target.value]);
										}
									} else {
										let newData = [...unfilteredData];
										let values = [];
										let filtered = [];
										values[0] = e.target.value;
										newData.forEach(item => {
											filterColumn.forEach(key => {
												values.forEach(value => {
													if (item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase())) {
														filtered.push(item);
													}
												});
											});
										});
										const removeDuplicates = filtered.filter((value, index) => filtered.indexOf(value) === index);
										setNewValue([e.target.value]);
										setData(removeDuplicates);
									}
								}
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export const UsersChatSearch = props => {
	let { setData, filterColumn, unfilteredData, detailsColumn } = props;
	return (
		<div className="single-search-chat">
			<div className="d-flex w-100">
				<div>
					<SearchFilterIcon />
				</div>
				<div className="single-input-search-chat-container">
					<input
						onChange={e => {
							if (unfilteredData) {
								if (e.target.value === "") {
									setData(unfilteredData);
								} else {
									if (detailsColumn) {
										let newData = [...unfilteredData];
										let values = [];
										let filtered = [];
										values[0] = e.target.value;
										newData.forEach(item => {
											let itemDetails = item["details"];
											if (itemDetails) {
												itemDetails = JSON.parse(itemDetails);
											}
											detailsColumn.forEach(key => {
												values.forEach(value => {
													if (itemDetails[key] && itemDetails[key].toString().toLowerCase().includes(value.toLowerCase())) {
														filtered.push(item);
													}
												});
											});
										});
										const removeDuplicates = filtered.filter((value, index) => filtered.indexOf(value) === index);
										setData(removeDuplicates);
									} else {
										let newData = [...unfilteredData];
										let values = [];
										let filtered = [];
										values[0] = e.target.value;
										newData.forEach(item => {
											filterColumn.forEach(key => {
												values.forEach(value => {
													if (item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase())) {
														filtered.push(item);
													}
												});
											});
										});
										const removeDuplicates = filtered.filter((value, index) => filtered.indexOf(value) === index);
										setData(removeDuplicates);
									}
								}
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export const RacingSportInput = ({ data, setData, handleDataChanged, handleShowOptions, firstRowClass, otherRowClass }) => {
	const options = [
		{
			value: "horseracing",
			name: "Horse Racing",
		},
		{
			value: "greyhoundracing",
			name: "Dog Racing",
		},
	];
	let selectedValue = options.filter(row => row.value === data?.sport);
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}>
				Sport
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					defaultValue={selectedValue && selectedValue.length > 0 ? selectedValue[0] : null}
					options={options}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Required"} {...params} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setData({
								...data,
								sport: "",
							});
						} else {
							setData({
								...data,
								sport: newValue?.value,
								competitions: [],
							});
						}
						handleShowOptions("hidden");
						handleDataChanged?.();
					}}
					onFocus={() => {
						handleShowOptions("hidden");
					}}
				/>
			</div>
		</div>
	);
};

export const RacingCompetition = ({ allCompetitions, setAllCompetitions, data, setData, handleDataChanged, firstRowClass, otherRowClass }) => {
	let actualCompetitions = [];
	if (data?.competitions) {
		actualCompetitions = [...data.competitions];
	}

	let filteredCompetitions = [];
	if (data?.competitions?.length > 0) {
		filteredCompetitions = allCompetitions.filter(row => {
			let exist = data.competitions.some(el => {
				el.name === row.name;
			});
			return !exist;
		});
	} else filteredCompetitions = allCompetitions;

	const uniqueOptions = filteredCompetitions.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));
	return (
		<>
			<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
				<div className={`col-12 col-lg-4 input-title`} style={{ width: "115px" }}>
					Competition
				</div>
				<div
					className={`dropdown-wrapper
						${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
				>
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={uniqueOptions}
						getOptionLabel={option => option.name}
						sx={{ width: 300 }}
						renderInput={params => <TextField placeholder={"Required"} {...params} />}
						onChange={(event, newValue, reason) => {
							if (reason !== "clear") {
								let exist = actualCompetitions.some(row => row.name === newValue?.name);
								if (!exist) {
									actualCompetitions.push(newValue);
									let filterdActualCompetition = filterAndSortArray(actualCompetitions, "name");
									setData({ ...data, competitions: filterdActualCompetition });
								}
								let newData = [...allCompetitions];
								newData = newData.filter(row => row.name !== newValue?.name);
								setAllCompetitions(newData);
							}
							handleDataChanged?.();
							setData({
								...data,
								competitions: actualCompetitions,
							});
						}}
					/>
				</div>
			</div>

			<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
				<div className={`col-12 col-lg-4 input-title`} style={{ width: "115px" }}></div>
				<div
					className={
						firstRowClass || otherRowClass
							? "col-12 col-lg-8 d-flex align-items-center flex-column input_custom_add_modal flex-column"
							: "col-12 col-lg-8 d-flex align-items-center flex-column"
					}
				>
					{data.competitions.map((row, index) => {
						let selectedCompetition = index === 0 ? "first-day" : "";
						selectedCompetition += ` seleted-day`;
						return (
							<div className={selectedCompetition} key={index}>
								<span>{row.name}</span>
								<span
									className="delete-selected-day"
									onClick={() => {
										handleDataChanged?.();
										let actualCompetitions = [...data.competitions];
										actualCompetitions = actualCompetitions.filter(el => el.name !== row.name);
										setData({
											...data,
											competitions: actualCompetitions,
										});
										let newData = [...allCompetitions];
										newData.push(row);
										newData = newData.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
										setAllCompetitions(newData);
									}}
								>
									<TrashIcon />
								</span>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export const DropwDown = ({ title, description, data, setData, property, countries, handleDataChanged }) => {
	let allCountries = [];
	if (data) {
		countries?.map(row => {
			allCountries.push(row.name);
		});
	}

	return (
		<>
			<div className="row autocomplete-container dropdown-wrapper">
				<div className={`col-12 col-lg-4 input-title`}>{title}</div>
				<div className="col-12 col-lg-8 d-flex align-items-center dropdown-wrapper" style={{ width: "396px" }}>
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={allCountries}
						getOptionLabel={option => option}
						sx={{ width: 300 }}
						renderInput={params => <TextField placeholder={description} {...params} />}
						onInputChange={(event, newValue) => {
							handleDataChanged();

							const selectedCountry = countries.find(country => country.name === newValue);
							if (selectedCountry) {
								const updatedBlockedCountries = [...data[property], selectedCountry.name];
								setData({ ...data, [property]: updatedBlockedCountries });
							}
						}}
					/>
				</div>
			</div>

			<div className="row">
				<div className={`col-12 col-lg-4 input-title`}></div>
				<div className="col-12 col-lg-8 d-flex align-items-center flex-column" style={{ width: "396px" }}>
					{data[property] &&
						data[property].length > 0 &&
						data[property].map((country, index) => (
							<div className={`seleted-day`} key={index}>
								<span>{country}</span>
								<span
									className="delete-selected-day"
									onClick={() => {
										const updatedBlockedCountries = data[property].filter(c => c !== country);
										setData({ ...data, [property]: updatedBlockedCountries });
									}}
									onChange={() => {
										handleDataChanged();
									}}
								>
									<TrashIcon />
								</span>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export const CountryDropDown = ({ title, description, data, setData, property, countries, handleDataChanged, firstRowClass, otherRowClass }) => {
	const [countriesData, setCountriesData] = useState([...countries]);
	useEffect(() => {
		let newData = [...countriesData];
		newData.forEach(country => {
			if (data[property] && data[property].length > 0) {
				if (data[property]?.includes(country?.cca2)) {
					newData = newData.filter(c => c.cca2 !== country.cca2);
				}
			}
		});
		setCountriesData(newData);
	}, []);

	return (
		<>
			<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
				<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}>
					{title}
				</div>
				<div className={"col-12 col-lg-8 d-flex align-items-center input_custom_add_modal dropdown-wrapper"}>
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={countriesData.length > 0 ? countriesData : countries}
						getOptionLabel={option => option?.name}
						sx={{ width: 300 }}
						renderInput={params => <TextField placeholder={description} {...params} />}
						onChange={(event, newValue) => {
							handleDataChanged();
							if (newValue) {
								let newCountry = [...data[property]];
								newCountry.push(newValue?.cca2);
								setData({ ...data, [property]: newCountry });
								let newCountries = [...countriesData];
								newCountries = newCountries.filter(c => c.cca2 !== newValue.cca2);
								setCountriesData(newCountries);
							}
						}}
					/>
				</div>
			</div>

			<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row "}>
				<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}></div>
				<div className="col-12 col-lg-8 d-flex align-items-center flex-column" style={{ width: "380px" }}>
					{countries.map((country, index) => {
						if (data[property] && data[property].length > 0 && data[property].includes(country?.cca2)) {
							return (
								<div className={`seleted-day`} key={index}>
									<span>{countries.filter(c => c.cca2 === country?.cca2).map(c => c.name)}</span>
									<span
										className="delete-selected-day"
										onClick={() => {
											handleDataChanged();
											const updatedBlockedCountries = data[property].filter(c => c !== country?.cca2);
											setData({ ...data, [property]: updatedBlockedCountries });
											let newData = [...countriesData];
											if (country?.cca2 === "GB") {
												newData.unshift(country);
											} else {
												newData.push(country);
												let gbRow = countries.find(c => c.cca2 === "GB");
												newData = newData.filter(c => c.cca2 !== "GB");
												newData = newData.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
												if (!data[property].includes("GB")) {
													newData.unshift(gbRow);
												}
											}
											setCountriesData(newData);
										}}
									>
										<TrashIcon />
									</span>
								</div>
							);
						}
					})}
				</div>
			</div>
		</>
	);
};

export const UserCountryDropDown = ({
	title,
	description,
	data,
	setData,
	property,
	countries,
	handleDataChanged,
	firstRowClass,
	addUser,
	otherRowClass,
	phone,
}) => {
	const [countriesData, setCountriesData] = useState([...countries]);

	return (
		<>
			<div
				className={`dropdown-wrapper ${
					firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : phone ? "row mt-3 prefix-width" : "row mt-3"
				}`}
			>
				<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}>
					{title}
				</div>
				<div
					className={`dropdown-wrapper
						${
							phone
								? "col-12 col-lg-8 d-flex align-items-center prefix-input-width"
								: addUser
								? "col-12 col-lg-8 d-flex align-items-center"
								: "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal"
						}`}
				>
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={countriesData.length > 0 ? countriesData : countries}
						getOptionLabel={option => option?.name || ""}
						sx={{ width: 300 }}
						renderInput={params => <TextField placeholder={description} {...params} />}
						onChange={(event, newValue) => {
							handleDataChanged();
							if (newValue) {
								setData({ ...data, [property]: newValue?.cca2 || "" });
								let newCountries = [...countriesData];
								newCountries = newCountries.filter(c => c.cca2 !== newValue.cca2);
								setCountriesData(newCountries);
							}
						}}
					/>
				</div>
			</div>
		</>
	);
};

export const CountriesDropdownAdd = ({ title, description, data, setData, property, handleDataChanged, countries, firstRowClass, otherRowClass }) => {
	const handleCountryRemoval = country => {
		const updatedBlockedCountries = data[property].filter(c => c !== country);
		setData({ ...data, [property]: updatedBlockedCountries });
		handleDataChanged();
	};
	const [countriesData, setCountriesData] = useState([...countries]);
	useEffect(() => {
		let newData = [...countriesData];
		newData.forEach(country => {
			if (data?.blocked_countries?.includes(country?.cca2)) {
				newData = newData.filter(c => c.cca2 !== country.cca2);
			}
		});
		setCountriesData(newData);
	}, []);

	return (
		<>
			<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
				<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}>
					{title}
				</div>
				<div className="col-12 col-lg-8 d-flex align-items-center input_custom_add_modal dropdown-wrapper">
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={countriesData}
						getOptionLabel={option => option?.name}
						sx={{ width: 300 }}
						renderInput={params => <TextField placeholder={description} {...params} />}
						onChange={(event, newValue) => {
							if (newValue) {
								let newCountry = [...(data?.blocked_countries || [])];
								newCountry.push(newValue?.cca2);
								setData({ ...data, [property]: newCountry });
								let newCountries = [...countriesData];
								newCountries = newCountries.filter(c => c.cca2 !== newValue.cca2);
								setCountriesData(newCountries);
							}
							handleDataChanged?.();
						}}
					/>
				</div>
			</div>
			<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row"}>
				<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}></div>
				<div className={"col-12 col-lg-8 d-flex align-items-center flex-column input_custom_add_modal"}>
					{countries.map((row, index) => {
						if (data?.blocked_countries?.includes(row.cca2)) {
							return (
								<div className={`seleted-day`} key={index}>
									<span>{row.name}</span>
									<span
										className="delete-selected-day"
										onClick={() => {
											handleCountryRemoval(row?.cca2);
											let newData = [...countriesData];
											if (row?.cca2 === "GB") {
												newData.unshift(row);
											} else {
												newData.push(row);
												let gbRow = countries.find(c => c.cca2 === "GB");
												newData = newData.filter(c => c.cca2 !== "GB");
												newData = newData.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
												if (!data?.blocked_countries?.includes("GB")) {
													newData.unshift(gbRow);
												}
											}

											setCountriesData(newData);
										}}
									>
										<TrashIcon />
									</span>
								</div>
							);
						}
					})}
				</div>
			</div>
		</>
	);
};
export const RedirectInput = ({ title, description, data, setData, handleDataChanged, onClick, firstRowClass, otherRowClass }) => {
	let OpenType = [
		{ name: "Same Tab", slug: "same_tab", id: "1" },
		{ name: "New Tab", slug: "new_tab", id: "2" },
	];
	const matchingRedirect = OpenType.find(item => item.slug === data.open_type);
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}>
				{title}
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					options={OpenType}
					value={data?.open_type ? matchingRedirect : ""}
					getOptionLabel={option => option?.name}
					sx={{ width: 300, fontSize: "12px !important" }}
					renderInput={params => <TextField placeholder={description} {...params} onClick={onClick} />}
					onChange={(event, newValue) => {
						setData({ ...data, open_type: newValue?.slug });
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};
export const BetTypeDropDown = ({ title, description, data, options, setData, property, handleDataChanged, customText, type, setMinHeight }) => {
	const [emptyInput, setEmptyInput] = useState(false);
	const [optionsData, setOptionsData] = useState(options);

	useEffect(() => {
		setEmptyInput(false);
	}, [data[property]]);

	useEffect(() => {
		let newData = [...optionsData];

		newData.forEach(row => {
			if (data[property] && data[property].length > 0) {
				if (data[property].includes(row)) {
					newData = newData.filter(c => c !== row);
				}
			}
		});
		setOptionsData(newData);
	}, []);

	return (
		<>
			<div className="row autocomplete-container dropdown-wrapper">
				<div className={`col-12 col-lg-4 input-title`} style={{ fontSize: "12px", maxWidth: "115px" }}>
					{title}
				</div>
				<div className="col-12 col-lg-8 d-flex align-items-center dropdown-wrapper" style={{ width: "396px" }}>
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={optionsData}
						getOptionLabel={option => option}
						value={customText ? customText : emptyInput ? "" : "Multiple Selection"}
						sx={{ width: 300, fontSize: "12px" }}
						renderInput={params => <TextField placeholder={description} {...params} />}
						onInputChange={(event, newValue, reason) => {
							if (handleDataChanged) {
								handleDataChanged();
							}
							if (reason === "reset") {
								if (type == "ladders") {
									setData();
								}
								setEmptyInput(true);
							}
							if (newValue) {
								if (type == "ladders") {
									setData(newValue);
								} else {
									const selectedBetType = options.find(elem => elem === newValue);
									if (selectedBetType && !data[property].includes(selectedBetType)) {
										const updatedBetType = [...data[property], selectedBetType];
										setData({ ...data, [property]: updatedBetType });
									}
									let newData = [...optionsData];
									newData = newData.filter(row => row !== newValue);
									setOptionsData(newData);
								}
							}
						}}
						classes={{
							paper: "custom-autocomplete-dropdown",
						}}
						onOpen={() => setMinHeight(true)}
						onClose={() => setMinHeight(false)}
					/>
				</div>
			</div>

			<div className="row">
				<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}></div>
				<div className="col-12 col-lg-8 d-flex align-items-center flex-column" style={{ width: "396px" }}>
					{data[property] &&
						data[property].length > 0 &&
						data[property].map((elem, index) => (
							<div className={`seleted-day`} key={index}>
								<span>{elem}</span>
								<span
									className="delete-selected-day"
									onClick={() => {
										const updatedBetTypes = data[property].filter(c => elem !== c);
										setData({ ...data, [property]: updatedBetTypes });
										let newData = [...optionsData];
										newData.push(elem);
										newData = newData.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
										setOptionsData(newData);
									}}
									onChange={() => {
										handleDataChanged();
									}}
								>
									<TrashIcon />
								</span>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export const TypeOfBetsDropdown = ({
	title,
	description,
	data,
	options,
	setData,
	property,
	handleDataChanged,
	customText,
	type,
	firstRowClass,
	otherRowClass,
	extraBottomPadding,
}) => {
	const [emptyInput, setEmptyInput] = useState(false);
	const [optionsData, setOptionsData] = useState(options);

	useEffect(() => {
		setEmptyInput(false);
	}, [data[property]]);

	useEffect(() => {
		let newData = [...optionsData];
		if (type == "bet_types") {
			newData = newData.map(item => item.name);
		} else if (type == "exclude_sports") {
			newData = newData.map(item => item.name);
		}
		setOptionsData(newData);
	}, []);

	let propertyValue;
	if (type === "bet_types") {
		propertyValue = JSON.parse(data?.[property] || "[]");
	} else if (type === "exclude_sports") {
		propertyValue = JSON.parse(data?.[property] || "[]");
	}

	// property values
	let propertyValues = "";
	if (type === "bet_types" || type === "exclude_sports") {
		propertyValue.map(
			(elem, index) =>
				(propertyValues += (
					<div className={`seleted-day`} key={index}>
						{type === "exclude_sports" ? (
							<span>{options.find(option => option.slug === elem)?.name}</span>
						) : (
							<span>{options.find(option => option.value === elem)?.name}</span>
						)}
						<span
							className="delete-selected-day"
							onClick={() => {
								let updatedArray = [...propertyValue];
								updatedArray = updatedArray.filter(item => item !== elem);
								setData({ ...data, [property]: JSON.stringify(updatedArray) });
							}}
							onChange={() => {
								handleDataChanged();
							}}
						>
							<TrashIcon />
						</span>
					</div>
				))
		);
	} else {
		data[property].map(
			(elem, index) =>
				(propertyValues += (
					<div className={`seleted-day`} key={index}>
						<span>{elem}</span>
						<span
							className="delete-selected-day"
							onClick={() => {
								const updatedBetTypes = data[property].filter(c => elem !== c);
								setData({ ...data, [property]: updatedBetTypes });
								let newData = [...optionsData];
								newData.push(elem);
								newData = newData.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
								setOptionsData(newData);
							}}
							onChange={() => {
								handleDataChanged();
							}}
						>
							<TrashIcon />
						</span>
					</div>
				))
		);
	}

	return (
		<>
			<div
				className={`dropdown-wrapper ${
					firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row autocomplete-container"
				}`}
				style={extraBottomPadding ? { marginBottom: "12px" } : {}}
			>
				<div className={`col-12 col-lg-4 input-title`} style={{ fontSize: "12px", maxWidth: "115px" }}>
					{title}
				</div>

				<div className="col-12 col-lg-8 d-flex align-items-center input_custom_add_modal dropdown-wrapper" style={{ width: "380px" }}>
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={optionsData}
						getOptionLabel={option => option}
						value={customText ? customText : emptyInput ? "" : "Multiple Selection"}
						sx={{ width: 300, fontSize: "12px" }}
						renderInput={params => <TextField placeholder={description} {...params} />}
						onInputChange={(event, newValue, reason) => {
							if (handleDataChanged) {
								handleDataChanged();
							}
							if (reason === "reset") {
								if (type === "ladders") {
									setData();
								}
								setEmptyInput(true);
							}
							if (reason === "clear") {
								if (type === "select_deduction") {
									setData({ ...data, selection_deduction: "" });
								} else if (type === "value_deduction") {
									setData({ ...data, value_deduction: newValue });
								} else if (type === "bet_types" || type === "exclude_sports") {
									setData({ ...data, [property]: "[]" });
								}
							} else if (newValue) {
								if (type === "ladders") {
									setData(newValue);
								} else if (type === "bet_types") {
									const newValueSelected = options
										.filter(row => row.name === newValue)
										.map(row => row.value)
										.find(value => value !== undefined);

									if (data?.[property] && newValueSelected) {
										const parsedArray = JSON.parse(data?.[property]);

										if (newValueSelected !== null && !parsedArray.includes(newValueSelected)) {
											let updatedArray = [...parsedArray];
											updatedArray.push(newValueSelected);
											setData({ ...data, [property]: JSON.stringify(updatedArray) });
										}
									}
								} else if (type === "exclude_sports") {
									const newValueSelected = options
										.filter(row => row.name === newValue)
										.map(row => row.slug)
										.find(slug => slug !== undefined);

									if (data?.[property] && newValueSelected) {
										const parsedArray = JSON.parse(data?.[property]);

										if (newValueSelected !== null && !parsedArray.includes(newValueSelected)) {
											let updatedArray = [...parsedArray];
											updatedArray.push(newValueSelected);
											setData({ ...data, [property]: JSON.stringify(updatedArray) });
										}
									}
								} else if (type === "select_deduction") {
									setData({ ...data, selection_deduction: newValue });
								} else if (type === "value_deduction") {
									setData({ ...data, value_reduction: newValue });
								} else {
									const selectedBetType = options.find(elem => elem === newValue);
									if (selectedBetType && !data[property].includes(selectedBetType)) {
										const updatedBetType = [...data[property], selectedBetType];
										setData({ ...data, [property]: updatedBetType });
									}
									let newData = [...optionsData];
									newData = newData.filter(row => row !== newValue);
									setOptionsData(newData);
								}
							}
						}}
					/>
				</div>
			</div>

			<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row "}>
				<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}></div>
				<div className="col-12 col-lg-8 d-flex align-items-center flex-column input_custom_add_modal" style={{ width: "380px" }}>
					{propertyValues}
				</div>
			</div>
		</>
	);
};

export const GeneralDropD = ({ title, description, data, options, setData, property, handleDataChanged }) => {
	const [emptyInput, setEmptyInput] = useState(false);
	const [optionsData, setOptionsData] = useState(options);

	useEffect(() => {
		setEmptyInput(false);
	}, [data[property]]);

	useEffect(() => {
		let newData = [...optionsData];

		newData.forEach(row => {
			if (data[property] && data[property].includes(row)) {
				newData = newData.filter(c => c !== row);
			}
		});
		setOptionsData(newData);
	}, []);

	return (
		<>
			<div className="row autocomplete-container dropdown-wrapper">
				<div className={`col-12 col-lg-4 input-title`} style={{ fontSize: "12px" }}>
					{title}
				</div>
				<div className="col-12 col-lg-8 d-flex align-items-center dropdown-wrapper" style={{ width: "396px" }}>
					<Autocomplete
						disablePortal
						className="autocomplete-select input-arrow"
						options={optionsData}
						getOptionLabel={option => option}
						value={emptyInput ? "" : "Multiple Selection"}
						sx={{ width: 300, fontSize: "12px" }}
						renderInput={params => <TextField placeholder={description} {...params} />}
						onInputChange={(event, newValue, reason) => {
							handleDataChanged();
							if (reason === "reset") {
								setEmptyInput(true);
							}
							if (newValue) {
								const selectedBetType = options.find(elem => elem === newValue);
								if (selectedBetType && !data[property].includes(selectedBetType)) {
									const updatedBetType = [...data[property], selectedBetType];
									setData({ ...data, [property]: updatedBetType });
								}
								let newData = [...optionsData];
								newData = newData.filter(row => row !== newValue);
								setOptionsData(newData);
							}
						}}
					/>
				</div>
			</div>

			<div className="row">
				<div className={`col-12 col-lg-4 input-title`}></div>
				<div className="col-12 col-lg-8 d-flex align-items-center flex-column" style={{ width: "396px" }}>
					{data[property] &&
						data[property].length > 0 &&
						data[property].map((elem, index) => (
							<div className={`seleted-day`} key={index}>
								<span>{elem}</span>
								<span
									className="delete-selected-day"
									onClick={() => {
										const updatedBetTypes = data[property].filter(c => elem !== c);
										setData({ ...data, [property]: updatedBetTypes });
										let newData = [...optionsData];
										newData.push(elem);
										newData = newData.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
										setOptionsData(newData);
									}}
									onChange={() => {
										handleDataChanged();
									}}
								>
									<TrashIcon />
								</span>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export const HideEventsInput = ({ data, setData, handleDataChanged, onClick, firstRowClass, otherRowClass }) => {
	return (
		<div
			className={
				firstRowClass ? "first_row_customized_modal dropdown_published" : otherRowClass ? "other_row_customized_modal dropdown_published" : "row mt-3"
			}
		>
			<div className={`col-12 col-lg-4 input-switch`}>Hide if no events</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<label className={`switch`}>
					<input
						type="checkbox"
						checked={data?.hide_events}
						onChange={e => {
							e.persist();
							let status = e?.target?.checked;
							setData({
								...data,
								hide_events: status,
							});
							handleDataChanged?.();
						}}
						onClick={onClick}
					/>
					<span className="slider round"></span>
				</label>
			</div>
		</div>
	);
};

export const WidgetTypeInput = ({ data, setData, handleDataChanged, selectedIds, setSelectedIds, firstRowClass, otherRowClass }) => {
	let options = [
		{
			value: "in_play",
			name: "In Play",
		},
		{
			value: "standard",
			name: "Standard",
		},
		{
			value: "starting_soon",
			name: "Starting Soon",
		},
	];

	// if (sportWiget)
	// 	options = [
	// 		...options,
	// 		{
	// 			value: "standard_competition",
	// 			name: "Standard Competition",
	// 		},
	// 	];

	let selectedValue = options?.filter(row => row?.value === data?.widget_type);

	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className={`col-12 col-lg-4 input-title`} style={{ maxWidth: "115px" }}>
				Widget Type
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					defaultValue={selectedValue && selectedValue.length > 0 ? selectedValue[0] : null}
					options={options}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Required"} {...params} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setData({
								...data,
								widget_type: "",
							});
							setSelectedIds({
								...selectedIds,
								competition_id: "",
								event_id: "",
								market_id: "",
							});
						} else {
							if (["in_play", "starting_soon", "swifty_specials"].includes(newValue?.value)) {
								setSelectedIds({
									...selectedIds,
									competition_id: "",
									event_id: "",
									market_id: "",
								});
							}
							setData({
								...data,
								widget_type: newValue.value,
							});
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

const InputError = ({ error, message }) => {
	return error ? (
		<Typography variant="caption" className="error" display="block" gutterBottom>
			{message}
		</Typography>
	) : null;
};

const UrlAddressInput = ({ data, setData, disabled, handleDataChanged, onClick, notRequired, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className={`col-12 col-lg-4  align-items-center input-title d-flex`}>URL Address</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				{/* {notRequired ? (
					<input
						type={"text"}
						placeholder={"Optional"}
						className="w-100"
						value={data.link}
						onChange={e => {
							setData({
								...data,
								link: e.target.value,
							});
							handleDataChanged?.();
						}}
						disabled={disabled}
						onClick={onClick}
					/>
				) : (
					<AvField
						validate={{
							required: { value: true },
						}}
						placeholder="Required"
						name="url"
						type="text"
						errorMessage="URL Address is required"
						value={data.link}
						onChange={e => {
							setData({
								...data,
								link: e.target.value,
							});
							handleDataChanged?.();
						}}
						disabled={disabled}
						onClick={onClick}
						className=""
					/>
				)} */}
				<input
					type={"text"}
					placeholder={notRequired ? "Optional" : "Required"}
					className="w-100"
					value={data.link}
					onChange={e => {
						setData({
							...data,
							link: e.target.value,
						});
						handleDataChanged?.();
					}}
					disabled={disabled}
					onClick={onClick}
				/>
			</div>
		</div>
	);
};

const LinkAutocomplete = ({
	value,
	inputValue,
	title,
	error,
	errorMessage,
	options,
	onChange,
	getOptionLabel,
	onClick,
	optional,
	firstRowClass,
	otherRowClass,
}) => {
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				<span>
					{title}
					{/* {optional && (
						<>
							<br /> <span className="optional">(optional)</span>
						</>
					)} */}
				</span>
			</div>
			<div className={`dropdown-wrapper ${firstRowClass || otherRowClass ? "col-12 col-lg-8 input_custom_add_modal" : "col-12 col-lg-8"}`}>
				<Autocomplete
					value={value}
					inputValue={inputValue}
					disablePortal
					className={`autocomplete-select input-arrow ${error ? "error" : ""}`}
					options={options}
					getOptionLabel={getOptionLabel}
					renderInput={params => <TextField placeholder={optional ? "Optional" : "Required"} {...params} onClick={onClick} />}
					onChange={onChange}
					renderOption={(props, option) => (
						<li {...props} key={option.id}>
							{getOptionLabel(option)}
						</li>
					)}
					sx={{ fontSize: "12px" }}
				/>
				<InputError error={error} message={errorMessage} />
			</div>
		</div>
	);
};

export const LinkTypeInput = ({
	onClick,
	data,
	setData,
	setEvents,
	setCompetitions,
	pageContent,
	games,
	getGames,
	events,
	error,
	setError,
	handleDataChanged,
	showCTA,
	sportsData,
	pageType,
	competitions,
	firstRowClass,
	otherRowClass,
}) => {
	const options = [
		{
			name: "Generic",
			value: "generic",
		},
		{
			name: "Modal",
			value: "modal",
		},
		{
			name: "Sport",
			value: "sport",
		},
		{
			name: "Event",
			value: "event",
		},
		{
			name: "Casino",
			value: "casino",
		},
		{
			name: "Page",
			value: "page",
		},
	];
	const pageOptions = data.link_type === "modal" ? pageContent : data.link_type === "page" ? pageType : "";
	let filteredPageOptions = [];
	if (pageOptions.length > 0 && data.link_type === "modal") {
		filteredPageOptions = filterAndSortArray(pageOptions, "name");
	} else if (pageOptions.length > 0 && data.link_type === "page") {
		filteredPageOptions = filterAndSortArray(pageOptions, "label");
	} else filteredPageOptions = pageOptions;

	return (
		<>
			<LinkAutocomplete
				title="Link Type"
				error={error?.link_type}
				errorMessage="Link type is required"
				options={options}
				optional={true}
				sx={{ fontSize: "12px" }}
				getOptionLabel={option => option.name}
				value={{ name: capitalize(data.link_type), value: data.link_type }}
				onClick={onClick}
				onChange={(_, newValue, reason) => {
					setError({
						link_type: false,
						link: false,
						casino: false,
						sport: false,
						event: false,
					});
					if (reason === "clear") {
						setData({
							...data,
							link_type: "",
							link: "",
							sport_id: "",
							event_id: "",
							call_to_action: "",
						});
					} else {
						setData({
							...data,
							link_type: newValue.value,
							link: "",
							sport_id: "",
							event_id: "",
							call_to_action: "",
						});
					}
					handleDataChanged?.();
				}}
				firstRowClass={firstRowClass}
				otherRowClass={otherRowClass}
			/>

			{data.link_type === "generic" ? (
				<GenericType setData={setData} data={data} handleDataChanged={handleDataChanged} onClick={onClick} showCTA={showCTA} firstRowClass otherRowClass />
			) : data.link_type === "modal" || data.link_type === "page" ? (
				<PageType
					pageOptions={filteredPageOptions}
					setData={setData}
					data={data}
					error={error}
					setError={setError}
					handleDataChanged={handleDataChanged}
					onClick={onClick}
					firstRowClass={firstRowClass}
					otherRowClass={otherRowClass}
				/>
			) : data.link_type === "sport" ? (
				<SportType
					setData={setData}
					data={data}
					error={error}
					setError={setError}
					handleDataChanged={handleDataChanged}
					onClick={onClick}
					sportsData={sportsData}
					firstRowClass={firstRowClass}
					otherRowClass={otherRowClass}
				/>
			) : data.link_type === "casino" ? (
				<CasinoType
					games={games}
					setData={setData}
					getGames={getGames}
					data={data}
					error={error}
					setError={setError}
					handleDataChanged={handleDataChanged}
					onClick={onClick}
					firstRowClass={firstRowClass}
					otherRowClass={otherRowClass}
				/>
			) : data.link_type === "event" ? (
				<EventType
					setData={setData}
					data={data}
					events={events}
					error={error}
					setError={setError}
					sportsData={sportsData}
					competitions={competitions}
					handleDataChanged={handleDataChanged}
					onClick={onClick}
					setEvents={setEvents}
					setCompetitions={setCompetitions}
					firstRowClass={firstRowClass}
					otherRowClass={otherRowClass}
				/>
			) : null}
		</>
	);
};

export const GenericType = ({ data, setData, handleDataChanged, onClick, showCTA, firstRowClass, otherRowClass }) => {
	return (
		<>
			<UrlAddressInput
				data={data}
				setData={setData}
				handleDataChanged={handleDataChanged}
				onClick={onClick}
				notRequired={false}
				firstRowClass={firstRowClass}
				otherRowClass={otherRowClass}
			/>
			{showCTA && data?.link_type !== "page" && (
				<CallToActionInput
					data={data}
					setData={setData}
					handleDataChanged={handleDataChanged}
					onClick={onClick}
					firstRowClass={firstRowClass}
					otherRowClass={otherRowClass}
				/>
			)}
		</>
	);
};

export const StaticDropDown = ({ dataToDisplay, data, setData, handleDataChanged, firstRowClass, otherRowClass, type, allArray, title }) => {
	let selectedOption = null;

	if (type === "currencyDropdown") {
		data.forEach(row => {
			if (row.code === dataToDisplay) {
				const newName = row?.code;
				row.name = newName;
				selectedOption = row;
			}
		});
	} else {
		data.forEach(row => {
			if (row.value === dataToDisplay) {
				selectedOption = row;
			}
		});
	}

	if (!selectedOption && dataToDisplay === "") {
		selectedOption = ""; // or any default value you want to assign
	}
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="static_dropdown_title">{title}</div>
			<div className={firstRowClass || otherRowClass ? "col-12 col-lg-8 input_custom_add_modal" : "col-12 col-lg-8"}>
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow custom-autocomplete"
					value={selectedOption ? selectedOption : ""}
					options={data}
					getOptionLabel={option => (option ? (type === "languageDropdown" ? option.language_name : option.name) : "")}
					renderInput={params => <TextField placeholder={"Type"} {...params} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							if (type == "fractionalDropdown") {
								setData({ ...allArray, default_odd_format: "" });
							} else if (type == "currencyDropdown") {
								setData({ ...allArray, default_currency: "" });
							} else if (type == "languageDropdown") {
								setData({ ...allArray, language: "" });
							} else {
								setData("");
							}
						} else if (type == "fractionalDropdown") {
							setData({ ...allArray, default_odd_format: newValue?.value });
						} else if (type == "currencyDropdown") {
							setData({ ...allArray, default_currency: newValue?.code });
						} else if (type == "languageDropdown") {
							setData({ ...allArray, language: newValue?.code2 });
						} else if (type == "edit_user_role") {
							setData(newValue?.value);
						} else {
							setData(newValue?.value);
						}

						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const PageType = ({ pageOptions, data, setData, error, setError, handleDataChanged, onClick, firstRowClass, otherRowClass }) => {
	let getOptionLabel;
	let property;
	let dataLink;

	if (data.link_type === "page") {
		property = "id";
		getOptionLabel = option => option.label;
		dataLink = data?.link;
	} else {
		property = "name";
		getOptionLabel = option => option.friendly_name;
		dataLink = data?.link.replace(/\//g, "");
	}

	let selectedLink = data?.link ? pageOptions.filter(row => row[property].toLowerCase().replace(/\s/g, "_") === dataLink) : null;

	return (
		<>
			<LinkAutocomplete
				title="Page"
				error={error.link}
				errorMessage="Page is required"
				options={pageOptions}
				value={selectedLink && selectedLink.length > 0 ? selectedLink[0] : null}
				getOptionLabel={getOptionLabel}
				onClick={onClick}
				onChange={(_, newValue, reason) => {
					let link = data.link_type === "page" ? newValue.id : `/${newValue.name.toLowerCase().replace(/\s/g, "_")}`;
					setError({
						...error,
						link: false,
					});
					if (reason === "clear") {
						setData({
							...data,
							link: "",
						});
					} else {
						setData({
							...data,
							link: link,
						});
					}
					handleDataChanged?.();
				}}
				firstRowClass={firstRowClass}
				otherRowClass={otherRowClass}
			/>
			<UrlAddressInput data={data} setData={setData} disabled onClick={onClick} firstRowClass={firstRowClass} otherRowClass={otherRowClass} />
		</>
	);
};

export const SportType = ({ data, setData, error, setError, handleDataChanged, onClick, sportsData, firstRowClass, otherRowClass }) => {
	let sports = sportsData;
	let selectedValue = sports.filter(row => row.slug === data?.sport_id);

	return (
		<>
			<LinkAutocomplete
				title="Sport"
				error={error.sport}
				errorMessage="Sport is required"
				options={sports}
				value={selectedValue && selectedValue.length > 0 ? selectedValue[0] : null}
				getOptionLabel={option => option.name}
				onClick={onClick}
				onChange={(_, newValue, reason) => {
					setError({
						...error,
						sport: false,
					});
					if (reason === "clear") {
						setData({
							...data,
							sport_id: "",
							link: "",
						});
					} else {
						setData({
							...data,
							sport_id: newValue.slug,
							link: `/sport/${newValue.slug.toLowerCase().replace(/\s/g, "_")}`,
						});
					}
					handleDataChanged?.();
				}}
				firstRowClass={firstRowClass}
				otherRowClass={otherRowClass}
			/>
			<UrlAddressInput data={data} setData={setData} disabled onClick={onClick} firstRowClass={firstRowClass} otherRowClass={otherRowClass} />
		</>
	);
};

export const CasinoType = ({ games, data, setData, onClick, firstRowClass, otherRowClass, getGames, handleDataChanged }) => {
	const handleChangeDropdown = newValue => {
		if (newValue) {
			setData({
				...data,
				link: `/casino/${newValue.id}`,
				game_id: newValue?.id,
				game_name: newValue?.label,
			});
		} else {
			setData({
				...data,
				link: "",
				game_id: "",
				game_name: "",
			});
		}
		handleDataChanged();
	};

	return (
		<>
			<GeneralFilterDropdown
				object={{
					name: "Game",
					options: games,
					bindValue: data?.game_name,
					value: data?.game_id,
					handlerFilterData: getGames,
					handleChangeDropdown: newValue => handleChangeDropdown(newValue),
					required: true,
				}}
			/>
			<UrlAddressInput data={data} setData={setData} disabled onClick={onClick} firstRowClass={firstRowClass} otherRowClass={otherRowClass} />
		</>
	);
};
export const GeneralFilterDropdown = ({ object, handleChange, handleFilter, handleRemove }) => {
	const [inputValue, setInputValue] = useState("");
	const [activMultipleOptions, setActivMultipleOptions] = useState(object?.multipleOptions);

	const options = object?.options || [];
	const uniqueOptions = options.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));

	const fieldName = object?.filterDropdownProperty || object.fieldName;
	const name = object?.name || "";
	const defaultPlaceholder = object?.defaultPlaceholder || null;
	const placeholder = object?.required ? "Required" : "Optional";
	const value = object?.value || "";
	const bindValue = object?.bindValue || "";
	const multiple = object?.multiple || "";
	const link_type = object?.link_type || "";
	const loading = object?.loading || false;

	const type = multiple ? "dropdown-multiple" : object?.property ? object?.property : "dropdown";

	const disabled = object?.disabled || false;
	const required = object?.required || false;

	const wrapperClassNameDropdown = object?.wrapperClassNameDropdown || "";
	const wrapperClassName = name
		? `row wrapper_customized_modal ${wrapperClassNameDropdown}`
		: `row wrapper_customized_modal justify-content-center ${wrapperClassNameDropdown}`;

	const dropdownClassName = object?.dropdownClassName || "";

	const handlerFilterData = object?.handlerFilterData || handleFilter;

	let newOptions = [...uniqueOptions];

	if (multiple) newOptions = uniqueOptions?.filter(option => !value?.includes(option?.id));

	const filterOptions = createFilterOptions({
		stringify: options => options.label,
	});

	const getTitleGame = async () => {
		if (value && !multiple) {
			const TitleGame = await getCasinoGame(value);
			setInputValue(TitleGame);
		}
	};

	const handleChangeDropdown = newValue => {
		if (newValue) {
			if (object?.handleChangeDropdown) object?.handleChangeDropdown(newValue, fieldName, type, link_type);
			else handleChange(newValue, fieldName, type, link_type);

			let updatedActivMultipleOptions = [...activMultipleOptions];
			updatedActivMultipleOptions.push({ ...newValue });

			setActivMultipleOptions(updatedActivMultipleOptions);
			setInputValue("");
		}
	};

	const handleRemoveDropdown = row => {
		if (object?.handleChangeDropdown) object?.handleRemoveDropdown(row, fieldName);
		else handleRemove(row, fieldName);

		const updatedActivMultipleOptions = activMultipleOptions.filter(c => c.id !== row?.id);
		setActivMultipleOptions(updatedActivMultipleOptions);
	};

	useEffect(() => {
		getTitleGame();
	}, []);

	useEffect(() => {
		const timeOut = setTimeout(() => handlerFilterData(inputValue), 500);
		return () => clearTimeout(timeOut);
	}, [inputValue]);

	useEffect(() => {
		if (!activMultipleOptions?.length && object?.multipleOptions?.length) {
			setActivMultipleOptions(object?.multipleOptions);
		}
	}, [object?.multipleOptions, activMultipleOptions]);

	return (
		<>
			<div className={`${wrapperClassName} dropdown-wrapper`}>
				{name && <div className={`col-12 col-lg-4 d-flex align-items-center input-title ${dropdownClassName}`}>{name}</div>}

				<div className={`dropdown-wrapper ${disabled ? "isDisabled" : ""} col-12 col-lg-8 d-flex align-items-center input-dropdown`}>
					<Autocomplete
						loading={loading}
						disablePortal
						className="autocomplete-select input-arrow"
						options={newOptions}
						renderOption={(props, option) => (
							<li {...props} key={option.id}>
								{option.label}
							</li>
						)}
						onInputChange={(event, inputValue) => setInputValue(inputValue)}
						getOptionLabel={option => (option && option.label ? option.label : option)}
						renderInput={params => (
							<TextField
								placeholder={defaultPlaceholder || placeholder}
								{...params}
								data-dropdownrequired={required}
								InputProps={{
									...params.InputProps,
									endAdornment: <>{loading ? <Spinner animation="border" size={"sm"} /> : params.InputProps.endAdornment}</>,
								}}
							/>
						)}
						filterOptions={filterOptions}
						onChange={(event, newValue) => handleChangeDropdown(newValue)}
						disabled={disabled}
						defaultValue={multiple ? "" : bindValue}
						value={multiple ? "" : inputValue ? inputValue : options.length > 0 ? options.find(({ id }) => id == value) : ""}
						noOptionsText={inputValue ? "No Options" : "Enter the first 3 characters of the games"}
					/>
				</div>
			</div>
			{multiple && (
				<div className={`${wrapperClassName} p-0`} style={{ marginTop: "5px" }}>
					{name && <div className="col-12 col-lg-4 input-title"></div>}
					<div className="col-12 col-lg-8 d-flex align-items-center flex-column input-dropdown multiselect-wrapper">
						{activMultipleOptions?.map((row, index) => {
							return (
								<div className={`seleted-day ${disabled && "isDisabled"}`} key={index}>
									<span>{row.label}</span>

									<span className="delete-selected-day" onClick={() => !disabled && handleRemoveDropdown(row)}>
										<TrashIcon />
									</span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};
export const EventType = ({
	setCompetitions,
	setEvents,
	data,
	setData,
	events,
	error,
	setError,
	handleDataChanged,
	onClick,
	sportsData,
	competitions,
	firstRowClass,
	otherRowClass,
}) => {
	let sports = sportsData;
	let sportSelectedValue = sports.filter(row => row.slug === data?.sport_id);
	let eventsSelectedValue = events.filter(row => row.id == data?.event_raw_id);
	let competitionSelectedValue = competitions.filter(row => row.id === data?.competition_id);
	return (
		<>
			<LinkAutocomplete
				title="Sport"
				error={error.sport}
				errorMessage="Sport is required"
				options={sports}
				value={sportSelectedValue && sportSelectedValue.length > 0 ? sportSelectedValue[0] : null}
				getOptionLabel={option => option.name}
				onClick={onClick}
				onChange={(_, newValue, reason) => {
					setError({
						...error,
						event: "",
						sport: false,
					});
					if (reason === "clear") {
						setData({
							...data,
							sport_id: "",
							competition_id: "",
							event_id: "",
							event_name: "",
							link: "",
						});
						setEvents([]);
						setCompetitions([]);
					} else {
						setEvents([]);
						setData({
							...data,
							sport_id: newValue.slug,
							competition_id: "",
							event_id: "",
							event_name: "",
							link: `/${newValue.name.toLowerCase().replace(/\s/g, "_")}`,
						});
					}
					handleDataChanged?.();
				}}
				firstRowClass={firstRowClass}
				otherRowClass={otherRowClass}
			/>
			<LinkAutocomplete
				title="Competition"
				options={competitions}
				getOptionLabel={option => option.name}
				value={competitionSelectedValue && competitionSelectedValue.length > 0 ? competitionSelectedValue[0] : null}
				onClick={onClick}
				onChange={(_, newValue, reason) => {
					if (reason === "clear") {
						setData({
							...data,
							competition_id: "",
						});
						setEvents([]);
					} else {
						setData({
							...data,
							competition_id: newValue?.id,
							competition_raw_id: newValue?.id,
							competition_name: newValue?.label,
							event_name: "",
						});
					}
					handleDataChanged?.();
				}}
				firstRowClass={firstRowClass}
				otherRowClass={otherRowClass}
			/>
			<LinkAutocomplete
				title="Event"
				options={events}
				value={eventsSelectedValue && eventsSelectedValue.length > 0 ? eventsSelectedValue[0] : null}
				inputValue={data?.event_name}
				getOptionLabel={option => option.label}
				onChange={(_, newValue, reason) => {
					setError({
						...error,
						event: false,
					});
					if (reason === "clear") {
						setData({
							...data,
							event_id: "",
							link: "",
						});
					} else {
						const competionNameSpecific = data?.competition_name?.toLowerCase();
						setData({
							...data,
							event_id: newValue?.id,
							event_name: newValue?.label,
							link:
								data?.sport_id == "horseracing" || data?.sport_id == "greyhoundracing"
									? `/racecard/${data?.sport_id}/${competionNameSpecific}?id=${newValue?.id}`
									: `${data?.sport_id}/event/${newValue?.id}`,
						});
					}
					handleDataChanged?.();
				}}
				firstRowClass={firstRowClass}
				otherRowClass={otherRowClass}
			/>
			<UrlAddressInput data={data} setData={setData} disabled onClick={onClick} firstRowClass={firstRowClass} otherRowClass={otherRowClass} />
		</>
	);
};

export const EnabledInput = ({ checked, handleChange, onClick }) => {
	return (
		<div className="enabled-input mt-3 mb-3">
			<span className="enabled-input-label">Enabled</span>
			<label className={`switch`}>
				<input type="checkbox" className="published-input" checked={checked} onChange={handleChange} onClick={onClick} />
				<span className="slider round"></span>
			</label>
		</div>
	);
};

export const FooterPageType = ({ data, onChange, handleDataChanged }) => {
	let options = [
		{
			name: "Default",
			value: "default",
		},
		{
			name: "Modal",
			value: "modal",
		},
		{
			name: "New Tab",
			value: "new_tab",
		},
	];

	let selectedRow = { name: "", value: "" };
	if (data?.page_type === "modal") {
		selectedRow = { name: "Modal", value: "modal" };
	} else if (data?.page_type === "new_tab") {
		selectedRow = { name: "New Tab", value: "new_tab" };
	} else if (data?.page_type === "default") {
		selectedRow = { name: "Default", value: "default" };
	}

	return (
		<Autocomplete
			disablePortal
			className="autocomplete-select input-arrow footer-page-type-autocomplete"
			value={selectedRow}
			options={options}
			getOptionLabel={option => option.name}
			sx={{ width: 300 }}
			renderInput={params => <TextField placeholder={"Type"} {...params} />}
			onChange={(event, newValue) => {
				onChange(newValue.value, data.id, "", "page_type");
				handleDataChanged?.();
			}}
		/>
	);
};

export const EditorTextInput = ({ data, footerEditorRefX, handleDataChanged, handleTranslate, translateLoading, isUserReadOnly }) => {
	let languages = getLanguages("footer_text");
	return (
		<>
			{languages.map((row, index) => {
				let editorRef = footerEditorRefX;
				return (
					<div className="footer-text-container" key={index}>
						<div className="lang-name d-flex justify-content-between">
							{row?.name}
							<div>
								{translateLoading === row?.language ? (
									<Spinner animation="border" size={"sm"} />
								) : (
									<TranslateIcone className="d-flex align-items-center" handleTranslate={() => handleTranslate(row?.language, row?.key)} />
								)}
							</div>
						</div>
						<div className={isUserReadOnly && "isDisabled"}>
							<Editor
								apiKey={editorApiUrl}
								onInit={(evt, editor) => (editorRef.current = editor)}
								initialValue={data[0]?.[row?.key]}
								init={{
									minHeight: 500,
									maxWidth: 700,
									menubar: false,
									statusbar: false,
									visual: false,
									plugins: [
										"advlist autolink lists link image charmap print preview anchor",
										"searchreplace visualblocks code fullscreen",
										"insertdatetime media paste code help wordcount fullpage",
									],
									toolbar: "undo redo  " + "bold italic  " + "link",
									content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
								}}
								onEditorChange={() => {
									handleDataChanged?.();
								}}
							/>
						</div>
					</div>
				);
			})}
		</>
	);
};

export const SealCodeEditorHeader = ({ data, setData, handleDataChanged, isUserReadOnly }) => {
	const handleChange = value => {
		setData({
			...data,
			seal_header: value,
		});
		handleDataChanged?.();
	};

	return (
		<div>
			<div className="code-editor-title">Code Editor - Seal Header</div>
			<CodeMirror
				basicSetup={{
					lineNumbers: false,
					foldGutter: false,
				}}
				height="33vh"
				value={data?.seal_header}
				onChange={handleChange}
				readOnly={isUserReadOnly}
				className={isUserReadOnly && "isDisabled"}
			/>
		</div>
	);
};
export const SealCodeEditorBody = ({ data, setData, handleDataChanged, isUserReadOnly }) => {
	const handleChange = value => {
		setData({
			...data,
			seal_body: value,
		});
		handleDataChanged?.();
	};
	return (
		<div>
			<div className="code-editor-title">Code Editor - Seal Body</div>
			<CodeMirror
				basicSetup={{
					lineNumbers: false,
					foldGutter: false,
				}}
				height="33vh"
				value={data?.seal_body}
				onChange={handleChange}
				readOnly={isUserReadOnly}
				className={isUserReadOnly && "isDisabled"}
			/>
		</div>
	);
};

export const LinkNameLanguage = ({ data, setData, inputId, handleDataChanged, type, handleTranslateRow, translateLoading }) => {
	let languages = getLanguages("link_name");
	let item = data.filter(row => row.id == inputId);
	let itemIndex = data.findIndex(row => row.id == inputId);

	return (
		<>
			{languages.map(row => {
				return (
					<div className="language-title" key={row?.key}>
						<div className="lang-name d-flex justify-content-between">
							{row?.name}
							<div className="d-flex justify-content-end my-3">
								{translateLoading == row.language ? (
									<Spinner animation="border" size="sm" />
								) : (
									<TranslateIcone
										value={row?.language}
										handleTranslate={() => {
											handleTranslateRow("name", row?.language);
										}}
									/>
								)}
							</div>
						</div>
						<div className="d-flex align-items-center">
							<span className="title-text">{"Link Name"}</span>
							<textarea
								style={{ minHeight: "64px !important" }}
								type="text"
								className={`language-input form-control`}
								placeholder={type === "title" ? "Title" : type === "name" ? "Name" : type === "description" ? "Description" : "Subtitle"}
								value={item && item?.length > 0 ? (item[0][row.key] ? item[0][row.key] : "") : ""}
								onChange={e => {
									let clonedItems = [...data];
									if (item && item.length > 0) {
										item[0][row.key] = e.target.value;
										item[0]["edited"] = true;
										clonedItems[itemIndex] = item[0];
										setData(clonedItems);
										handleDataChanged?.();
									}
								}}
							/>
						</div>
					</div>
				);
			})}
		</>
	);
};

export const ImageLanguage = ({ setImageLanguageInput, imageLanguageInput, linkData }) => {
	let languages = getLanguages("link_name");
	return (
		<>
			{languages.map(row => {
				return (
					<div className="language-title" key={row?.key}>
						<div className="lang-name">{row?.name}</div>
						<div className="d-flex align-items-center">
							<span className="title-text">{"Image"}</span>
							<div
								className="image-language-container"
								onClick={() => {
									setImageLanguageInput(row.key);
								}}
							>
								<div className="image-upload">{imageLanguageInput === row.key && linkData.image ? linkData.image : "Image Upload"}</div>
								{imageLanguageInput === row.key && linkData.image ? (
									<DeleteIcon
										onClick={() => {
											setImageLanguageInput("");
										}}
										className={"me-0"}
									/>
								) : (
									<UploadIcon />
								)}
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

export const ShowTitleInput = ({ checked, handleChange, onClick, firstRowClass, otherRowClass }) => {
	return (
		<div
			className={
				firstRowClass ? "first_row_customized_modal dropdown_published" : otherRowClass ? "other_row_customized_modal dropdown_published" : "row mt-3"
			}
		>
			<div className={`col-12 col-lg-4  align-items-center input-switch  d-flex`}>Show Title</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<label className={`switch`}>
					<input type="checkbox" className="published-input" checked={checked} onChange={handleChange} onClick={onClick} />
					<span className="slider round"></span>
				</label>
			</div>
		</div>
	);
};

export const TitleNameLanguage = ({ data, setData, handleDataChanged, type, translateLoading, handleTranslateRow }) => {
	let languages = getLanguages("title_name");
	return (
		<>
			{languages.map(row => {
				return (
					<div className="language-title" key={row?.key}>
						<div className="lang-name d-flex justify-content-between">
							{row?.name}
							<div className="d-flex justify-content-end my-3">
								{translateLoading == row.language ? (
									<Spinner animation="border" size="sm" />
								) : (
									<TranslateIcone
										value={row?.language}
										handleTranslate={() => {
											handleTranslateRow("title", row?.language);
										}}
									/>
								)}
							</div>
						</div>
						<div className="d-flex align-items-center">
							<span className="title-text">{"Title"}</span>
							<textarea
								style={{ minHeight: "64px !important" }}
								type="text"
								className={`language-input form-control`}
								placeholder={type === "title" ? "Title" : type === "name" ? "Name" : type === "description" ? "Description" : "Subtitle"}
								value={data[row.key]}
								onChange={e => {
									setData({
										...data,
										[row.key]: e.target.value,
									});
									handleDataChanged?.();
								}}
							/>
						</div>
					</div>
				);
			})}
		</>
	);
};

export const ModalButton = ({ object }) => {
	const name = object?.title;
	const buttonName = object?.buttonName || object?.name;
	const secondButtonName = object?.secondButtonName || false;
	const handlerClickEvent = object?.onClickEvent;
	const generalButtonClassName = object?.buttonClassname || "textButtonTypography";
	const extraButtonClassName = object?.extraButtonClassName || false;
	const extraClassname = object?.extraClassname || false;
	const position = object?.position || false;
	const isLoading = object?.isLoading || false;
	const disabled = object?.disabled || false;
	const titleClassName = "col-12 col-lg-4 d-flex align-items-center input-title";
	const wrapperClassName = name
		? `wrapper_customized_modal ${object?.wrapperClassName}`
		: `wrapper_customized_modal ${position} dropdown_published ${object?.wrapperClassName}`;
	const buttonClassName = extraButtonClassName
		? extraButtonClassName
		: `btn-primary saveButtonStyle saveViewButton ${generalButtonClassName} ${extraClassname}`;

	return (
		<div className={wrapperClassName}>
			{name && <div className={titleClassName}>{name}</div>}
			{isLoading ? (
				<div className={buttonClassName}>
					<Spinner animation="border" size={"sm"} />
				</div>
			) : (
				<>
					<button className={`${buttonClassName} ${disabled ? "disabled" : ""}`} onClick={() => handlerClickEvent(object?.type)} disabled={disabled}>
						{buttonName}
					</button>
					{secondButtonName && (
						<button className={`${buttonClassName} ${disabled ? "disabled" : ""}`} onClick={() => handlerClickEvent(object?.type)} disabled={disabled}>
							{secondButtonName}
						</button>
					)}
				</>
			)}
		</div>
	);
};

export const FooterComponentInput = ({ data, setData, setItemsData, setImageId, setInputId, setShowTitleLanguage, publishedFooters }) => {
	let options = [
		{
			value: "column",
			name: "Column",
		},
		{
			value: "seal",
			name: "Seal",
			once: true,
		},
		{
			value: "images",
			name: "Images",
		},
		{
			value: "text",
			name: "Text",
			once: true,
		},
	];
	let selectedValue = { value: "", name: "" };
	if (data?.type === "column") {
		selectedValue = { value: "column", name: "Column" };
	} else if (data?.type === "seal") {
		selectedValue = { value: "seal", name: "Seal" };
	} else if (data?.type === "images") {
		selectedValue = { value: "images", name: "Images" };
	} else if (data?.type === "text") {
		selectedValue = { value: "text", name: "Text" };
	}

	options = options.filter(({ value, once }) => !once || !publishedFooters.includes(value));

	return (
		<div className="row mt-3 dropdown-wrapper">
			<div className="d-flex align-items-center input-title" style={{ width: "70px", marginRight: "70px" }}>
				{"Component"}
			</div>
			<div className="d-flex align-items-center p-0 dropdown-wrapper" style={{ width: "160px" }}>
				<Autocomplete
					key={Math.random()}
					disablePortal
					className="autocomplete-select input-arrow"
					defaultValue={selectedValue}
					options={options}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Name"} {...params} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setData({
								...data,
								type: "",
							});
						} else {
							setData({
								...data,
								type: newValue.value,
							});
						}
						setImageId(null);
						setInputId(null);
						setItemsData([]);
						setShowTitleLanguage(null);
					}}
				/>
			</div>
		</div>
	);
};

export const NotificationTypeInput = ({ data, setData, handleDataChanged, onClick, firstRowClass, otherRowClass }) => {
	let options = [
		{
			name: "Notification",
			value: "only_notification",
		},
		{
			name: "Special Bet",
			value: "special_bet",
		},
	];
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				{"Type"}
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					onClick={onClick}
					disablePortal
					className={firstRowClass || otherRowClass ? "autocomplete-select input-arrow" : "autocomplete-select input-arrow notification-autocomplete"}
					options={options}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Required"} {...params} onClick={onClick} />}
					onChange={(event, newValue) => {
						setData({
							...data,
							type: newValue?.value,
						});
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const NotificationSpecialBetInput = ({ data, setData, handleDataChanged, specials, onClick, firstRowClass, otherRowClass }) => {
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				{"Special Bet"}
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center p-0 input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					onClick={onClick}
					disablePortal
					className="autocomplete-select input-arrow notification-autocomplete"
					options={specials}
					getOptionLabel={option => option.description}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"Required"} {...params} onClick={onClick} />}
					onChange={(event, newValue) => {
						setData({
							...data,
							special_bet_id: newValue?.id,
						});
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const NotificationUserInput = ({
	data,
	setData,
	handleDataChanged,
	userData,
	setUserData,
	handleShowOptions,
	onClick,
	countries,
	firstRowClass,
	otherRowClass,
}) => {
	let options = [
		{
			name: "All",
			value: "all",
		},
		{
			name: "By Player ID",
			value: "player_id",
		},
		{
			name: "By Country",
			value: "country",
		},
	];
	let selectedValue = { name: "", value: "" };
	if (userData?.type === "all") {
		selectedValue = { name: "All", value: "all" };
	} else if (userData?.type === "player_id") {
		selectedValue = { name: "By Player ID", value: "player_id" };
	} else if (userData?.type === "country") {
		selectedValue = { name: "By Country", value: "country" };
	}
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				User
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					disablePortal
					onClick={onClick}
					className={userData?.type === "all" ? "autocomplete-select input-arrow" : "autocomplete-select input-arrow user-autocomplete"}
					defaultValue={selectedValue}
					options={options}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={"All"} {...params} onClick={onClick} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setUserData({
								...userData,
								type: "all",
								data: [],
								show_select: false,
							});
						} else {
							setUserData({
								...userData,
								type: newValue?.value,
								data: [],
								show_select: newValue?.value == "all" ? false : true,
							});
							setData({
								...data,
								id: newValue?.value == "all" ? "all" : "",
							});
							handleDataChanged?.();
						}
					}}
				/>
				{userData.type === "all" && <></>}
				{userData?.type === "country" && (
					<>
						<Autocomplete
							disablePortal
							onClick={onClick}
							className={"autocomplete-select input-arrow"}
							options={countries}
							getOptionLabel={option => option.name}
							sx={{ width: 300 }}
							renderInput={params => <TextField placeholder={"Select"} {...params} onClick={onClick} />}
							onChange={(event, newValue) => {
								setData({
									...data,
									id: newValue?.cca2,
								});
								handleDataChanged();
								handleDataChanged?.();
							}}
						/>
					</>
				)}
				{userData?.type === "player_id" && (
					<input
						className="form-control"
						type="text"
						value={data.id}
						onChange={e => {
							handleDataChanged?.();
							setData({
								...data,
								id: e.target.value,
							});
						}}
						onClick={() => {
							handleShowOptions("hidden");
						}}
					/>
				)}
			</div>
		</div>
	);
};

export const NotificationMessageInput = ({ data, setData, handleDataChanged, onClick, firstRowClass, otherRowClass, required }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				{"Message"}
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<textarea
					placeholder={required ? "Required" : "Optional"}
					onClick={onClick}
					value={data?.message}
					onChange={e => {
						setData({
							...data,
							message: e.target.value,
						});
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};

export const NotificationDateInput = ({ handleDataChanged, actualDate, setActualDate, setSentDate, firstRowClass, otherRowClass }) => {
	return (
		<div className={firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				{"Sent At (UTC)"}
			</div>
			<div
				className={
					firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"
				}
			>
				<Flatpickr
					value={actualDate}
					data-enable-time
					onChange={([date]) => {
						handleDataChanged();
						let startDateVal = formatToLocalDatetime(date);
						setSentDate(startDateVal);
					}}
					className="form-control flatpickr-input"
					placeholder="Sent At (UTC)"
				/>

				<div className="iconPosition">
					<div>
						<CalendarIcon className="calendarIcon" />
					</div>
				</div>
				<NowButton
					style={{ marginRight: "0", color: "#fff" }}
					data-enable-time
					onClick={() => {
						handleDataChanged();
						let momentUtc = moment.utc().format("YYYY-MM-DD HH:mm:ss");
						setActualDate(momentUtc);
					}}
					value={actualDate}
					name={"Now"}
				/>
			</div>
		</div>
	);
};

export const NotificationPlatformInput = ({ data, setData, handleDataChanged, onClick, firstRowClass, otherRowClass, required }) => {
	let options = [
		{
			value: "all",
			name: "All",
		},
		{
			value: "ios",
			name: "iOS",
		},
		{
			value: "android",
			name: "Android",
		},
		{
			value: "web",
			name: "Web",
		},
	];
	return (
		<div className={`dropdown-wrapper ${firstRowClass ? "first_row_customized_modal" : otherRowClass ? "other_row_customized_modal" : "row mt-3"}`}>
			<div className="col-12 col-lg-4 d-flex align-items-center input-title" style={{ maxWidth: "115px" }}>
				{"Platform"}
			</div>
			<div
				className={`dropdown-wrapper
					${firstRowClass || otherRowClass ? "col-12 col-lg-8 d-flex align-items-center input_custom_add_modal" : "col-12 col-lg-8 d-flex align-items-center"}`}
			>
				<Autocomplete
					onClick={onClick}
					disablePortal
					className="autocomplete-select input-arrow"
					options={options}
					getOptionLabel={option => option.name}
					sx={{ width: 300 }}
					renderInput={params => <TextField placeholder={required ? "Required" : "Optional"} {...params} onClick={onClick} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setData({
								...data,
								platform: "",
							});
						} else {
							setData({
								...data,
								platform: newValue?.value,
							});
						}
						handleDataChanged?.();
					}}
				/>
			</div>
		</div>
	);
};
