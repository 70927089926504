export const __viewColumns = {
	sport_type: true,
	region: true,
	competition: true,
	match: true,
	type: true,
	description: true,
	title: true,
	odd: true,
	numerator_denominator: true,
	betted_count: true,
	result: true,
	bet_start_time: true,
	bet_end_time: true,
	start_date: true,
	end_date: true,
	created: true,
	status: true,
	plus: true,
	clone: true,
	edit: true,
};

export const __manualBet = {
	title: "",
	title_fr: "",
	title_es: "",
	description: "",
	description_fr: "",
	description_es: "",
	sport_id: "",
	region_id: "",
	match: "",
	market_id: "",
	outcome_id: "",
	outcome_name: "",
	odd_decimal: "",
	odd_fractional: "",
	bet_start: "",
	bet_end: "",
	bet_start_date: "",
	bet_end_date: "",
	display_start_date: "",
	display_end_date: "",
	status: "",
	numerator: "",
	denominator: "",
	competition_id: "",
	match_id: "",
	details: "",
};
