import React from "react";
import { Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
export const ShowConfirmDisableMFA = ({
	setPassword,
	confirmPasswordForDisableSpinner,
	confirmPasswordForDisableMFA,
	setConfirmDisableMFA,
	setSpinningStatusMfa,
}) => {
	return (
		<>
			<SweetAlert title="Disable MFA" showConfirm={false} onConfirm={() => {}}>
				<div style={{ position: "relative" }} className="mt-3">
					<>
						<div className="row justify-content-center">
							<div className="col-6">
								<div className="col-12" style={{ textAlign: "left", marginBottom: "5px", fontWeight: "600" }}>
									Enter your password to disable MFA:
								</div>
								<input
									type="password"
									className="form-control"
									onChange={e => {
										setPassword(e.target.value);
									}}
								/>
								<div className="col-12 mt-2 d-flex justify-content-end">
									<button
										style={{
											marginLeft: 10,
											minWidth: 88,
											padding: "5px 10px",
											borderRadius: 7,
											border: "none",
											fontSize: 14,
										}}
										className="btn-secondary"
										onClick={() => {
											setConfirmDisableMFA(false), setSpinningStatusMfa(false);
										}}
									>
										Cancel
									</button>
									<button type="button" className="btn btn-primary ms-2 " onClick={confirmPasswordForDisableMFA}>
										{confirmPasswordForDisableSpinner ? <Spinner animation="border" size="sm" /> : "Submit"}
									</button>
								</div>
							</div>
						</div>
					</>
				</div>
			</SweetAlert>
		</>
	);
};
