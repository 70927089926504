import { SET_BETS_STATS, SET_SEARCH_BETS } from "./actionTypes";

const initialState = {
	searchBets: [],
	betsStats: [],
};

const BetsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SEARCH_BETS:
			return {
				...state,
				searchBets: action.payload,
			};
		case SET_BETS_STATS:
			return {
				...state,
				betsStats: action.payload,
			};
		default:
			return state;
	}
};

export default BetsReducer;
