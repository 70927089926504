import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import SearchFilter from "components/SearchFilter/searchFilter";
import { setBreadcrumbItems } from "../../../store/actions";
import { getVenueTableColumns } from "./venueTableColumns";
import { getVenueFilterColumns } from "./venueFilterColumns";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { ShowEditVenue } from "./showEditVenue";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const Venue = props => {
	// const venueFilterData = useSelector(state => state.Login.searchFilterData.venues);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const breadcrumbItems = [{ title: "Venue", link: "/venue" }];
	const [venuesData, setVenuesData] = useState([]);
	const [editItem, setEditItem] = useState(null);
	const [showPromotion, setShowPromotion] = useState(false);
	// Redux user data
	const regions = useSelector(state => state.Login.gsData.regions);
	const pageHeaderData = {
		international: false,
		addNew: true,
		addNewClick: () => {
			setShowPromotion(true);
		},
		removeHeader: true,
	};

	const getVenuesData = params => {
		let url = params ? apiUrls.venue_get + params : apiUrls.venue_get;
		setCurrentPage(0);
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				const data = response?.data;
				let newData = [];
				data.map(venue_row => {
					var location = venue_row?.latitude != "null" && venue_row?.longitude ? venue_row?.latitude + ", " + venue_row?.longitude : "-";
					let obj = {
						id: venue_row?.id,
						genius_id: venue_row?.genius_id,
						region_id: venue_row?.region_id,
						name: venue_row?.name,
						capacity: venue_row?.capacity,
						latitude: venue_row?.latitude,
						longitude: venue_row?.longitude,
						region_name: venue_row?.region_name,
						location: location,
					};
					newData.push(obj);
				});

				setVenuesData(newData);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};
	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};
	useEffect(() => {
		props.setBreadcrumbItems("Venue", breadcrumbItems);
	});

	const filterVenue = venueFilterData => {
		var venues_name = venueFilterData.name != "" ? venueFilterData.name : "";
		let longitude = "";
		let latitude = "";
		if (venueFilterData.with_location === "Yes") {
			longitude = "<> 0";
			latitude = "<> 0";
		} else if (venueFilterData.with_location === "No") {
			longitude = "IS NULL";
			latitude = "IS NULL";
		} else {
			longitude = "";
			latitude = "";
		}
		let params = `?region_id=${venueFilterData.region_id}&name=${venues_name}&longitude=${longitude}&latitude=${latitude}`;
		getVenuesData(params);
	};

	let venueTableColumns = getVenueTableColumns({ venuesData, handleEditItem });
	let venueFilterColumns = getVenueFilterColumns({ regions });
	let page_name = "Venue";

	return (
		<HelmetLayout titleProps={"Venue"}>
			<div className="cms-page pt-0 cms-table-page">
				<div
					className="col-12 d-flex"
					style={{
						justifyContent: "space-between",
						position: "relative",
					}}
				>
					<SearchFilter
						columns={venueFilterColumns}
						page={"venues"}
						preSelectedType={"text"}
						preSelectedValue={"name"}
						preSelectedName={"Name"}
						preSelectedData={[]}
						preSelectedOptionKey={""}
						preSelectedOptionName={""}
						filterHandler={filterVenue}
						filterLoading={isDataLoading}
						customizedFilter={true}
					/>
				</div>
				{showPromotion && (
					<ShowEditVenue
						title={"Venues"}
						editItem={editItem}
						venuesNewData={venuesData}
						setVenuesNewData={setVenuesData}
						setShowPromotion={setShowPromotion}
						showPromotion={showPromotion}
						handler={() => {
							setShowPromotion(false);
							setEditItem(null);
						}}
					/>
				)}
				<MUIDataTable
					columns={venueTableColumns}
					data={venuesData}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: page_name + ".csv" },
						customToolbar: () => <AddTableButton onClick={pageHeaderData["addNewClick"]} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(Venue);
