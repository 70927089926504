import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

export const getPageContentDropdown = async () => {
	if (localStorage.getItem("page_content")) {
		return JSON.parse(localStorage.getItem("page_content"));
	} else {
		return new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.page_content, resolve, reject, true);
		})
			.then(response => {
				let page_content = response?.data;
				page_content = page_content.map(row => {
					return { id: row.name, label: row.friendly_name };
				});

				localStorage.setItem("page_content", JSON.stringify(page_content));
				return page_content;
			})
			.catch(() => {
				return [];
			});
	}
};
