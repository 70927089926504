import React, { useContext, useEffect, useState, useCallback } from "react";
import NoNotification from "../../assets/images/no_notification.svg";
import NotificationOn from "../../assets/images/notification_on.svg";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../store/auth/login/actions";
import { setBackdrop } from "../../store/Backdrop/actions";
import { setBetReferralModal } from "../../store/Modals/actions";
import { setSelectedBetReferral } from "../../store/SelectedBet/actions";
import { removeFromNotificationsList, setNotificationsList } from "../../store/NotificationsList/actions";
import { addBetNotificationsList, setBetNotificationsList, removeBetFromNotificationsList } from "../../store/NotificationsListBets/actions";
import ApiServices from "../../utils/ApiServices";
import { addBetTicker, setBetTickerData, setBetTickerNewAmounts, updateBetTickerStatus } from "../../store/BetTicker/actions";
import { apiUrls } from "utils/const.apiUrl";
import { getFormatDateByUserTimezone } from "utils/global";

import { setShowInfoModal, setInfoModalData } from "store/layout/actions";
import { ChatNotification, ChatActiveNotification } from "utils/icons";

import "./headerNotification.scss";

const HeaderNotification = () => {
	const dispatch = useDispatch();
	const betTicketList = useSelector(state => state.BetTickerData.betTickerList);
	const messageList = useSelector(state => state.NotificationsList.listNotifications);
	const betsList = useSelector(state => state.BetNotificationsList.listBetsNotifications);
	const notificationCount = useSelector(state => state.NotificationsList.listNotifications.length);
	const user = useSelector(state => state.Login.user);

	const [isBetListNotificationOpen, SetIsBetListNotificationOpen] = useState(false);
	const [isChatNotificationOpen, setIsChatNotificationOpen] = useState(false);
	const [betAssignData, setBetAssignData] = useState(null);

	const history = useHistory();
	const context = useContext(AppContext);
	const socketCommunication = context.socketCommunication;

	let audio = new Audio("https://swifty-global-app-assets-central.s3.eu-west-1.amazonaws.com/media/notification-sound.mp3");
	let messageAudio = new Audio("https://swifty-global-app-assets-central.s3.eu-west-1.amazonaws.com/media/Message+Notification.mp3");

	const triggerSound = message => {
		try {
			if (message) messageAudio.play();
			else audio.play();
		} catch (e) {
			console.log(e);
		}
	};

	const betNotificationIconHandler = () => {
		SetIsBetListNotificationOpen(prev => !prev);
		setIsChatNotificationOpen(false);

		if (isBetListNotificationOpen) {
			const newBetList = betsList.filter(bet => findOriginBet(bet.notification.bet_referral_id));
			dispatch(setBetNotificationsList(newBetList));
		}
	};

	const chatNotificationIconHandler = () => {
		setIsChatNotificationOpen(prev => !prev);
		SetIsBetListNotificationOpen(false);
	};

	useEffect(() => {
		socketCommunication &&
			socketCommunication.on("new_chat_message", newMessage => {
				if (!newMessage.from_cms) {
					triggerSound(true);
					newMessage && dispatch(setNotificationsList(newMessage));
				}
			});

		socketCommunication &&
			socketCommunication.on("bet_referral_message", newBet => {
				if (newBet && newBet.bet.status !== "accepted") {
					dispatch(addBetNotificationsList(newBet));
					triggerSound();
				}

				newBet && newBet.bet && dispatch(addBetTicker(newBet.bet));
			});

		socketCommunication &&
			socketCommunication.on("new_event", newBet => {
				if (newBet.type == "modal_message") {
					dispatch(setShowInfoModal(true));
					dispatch(setInfoModalData(newBet));
				}

				if (newBet.type === "bet_ticker_update" && newBet?.new_status && newBet?.bet_ticker_id) {
					dispatch(
						updateBetTickerStatus({
							id: newBet.bet_ticker_id,
							status: newBet.new_status,
						})
					);

					if (newBet?.new_status === "approved" || newBet?.new_status === "rejected") {
						dispatch(removeBetFromNotificationsList(newBet?.bet_ticker_id));
					}

					if ((newBet?.new_status === "approved" || newBet?.new_status === "accepted") && newBet.singles && newBet.combinations) {
						dispatch(
							setBetTickerNewAmounts({
								id: newBet.bet_ticker_id,
								singles: newBet.singles,
								combinations: newBet.combinations,
							})
						);
					}

					return;
				}

				if (newBet?.type === "BET_REASSIGNED") {
					setBetAssignData(newBet?.newReassignBet);
					return;
				}

				newBet &&
					newBet?.type !== "BET_REASSIGNED" &&
					new Promise((resolve, reject) => {
						ApiServices.get(`${apiUrls.GET_BET_TICKER_LIST}`, resolve, reject, true);
					}).then(response => {
						dispatch(setBetTickerData(response.data));
					});
			});

		return () => {
			socketCommunication && socketCommunication.off("new_chat_message");
			socketCommunication && socketCommunication.off("bet_referral_message");
			socketCommunication && socketCommunication.off("BET_REASSIGNED");
		};
	}, [socketCommunication]);

	useEffect(() => {
		if (betAssignData?.id) updateBetTicketData(betAssignData);
	}, [betAssignData]);

	const updateBetTicketData = useCallback(
		newBet => {
			const { trader_id, trader_name, id } = newBet;

			const newBetTickerData = betTicketList.map(bet => (bet.id == id ? { ...bet, trader_id: trader_id, trader_name: trader_name } : bet));
			const newBetList = betsList.map(bet => (bet.bet.id == id ? { ...bet, bet: { ...bet.bet, trader_id: trader_id } } : bet));

			dispatch(setBetTickerData(newBetTickerData));
			dispatch(setBetNotificationsList(newBetList));
			setBetAssignData(null);
		},
		[betTicketList]
	);

	const viewMessageHandler = messageObj => {
		dispatch(setActivePage("/users_chat"));
		dispatch(removeFromNotificationsList(messageObj));
		history.push(`/users_chat?room_id=${messageObj.room_id}&gamingUser=${messageObj.player_id}`);
	};

	const findOriginBet = id => {
		return betTicketList.find(item => item.id === id);
	};

	const formatTotalStake = itm => {
		return Number(itm?.bet.bet_slip?.total_stakes).toFixed(2);
	};

	const formatTotalPayout = itm => {
		const isSp = itm?.bet?.bet_slip?.singles.some(single => single.bet_type == "starting_price");
		return isSp ? "SP " : Number(itm.notification.total_payout).toFixed(2);
	};

	const viewBetHandler = betObj => {
		if (findOriginBet(betObj.notification.bet_referral_id)) {
			dispatch(setBackdrop(true));
			dispatch(setBetReferralModal(true));
			SetIsBetListNotificationOpen(false);

			const formatedData = JSON.parse(JSON.stringify(findOriginBet(betObj.notification.bet_referral_id)));
			dispatch(setSelectedBetReferral({ ...formatedData }));
		}
	};

	const renderNotificationMessageList = () => {
		return messageList.map(itm => {
			return (
				<div className="notification-item-wrapper" key={itm.message_id}>
					<div className="notification-item-title">Trader Chat</div>
					<div className="notification-item-name">
						New messages from&nbsp;<span className="notification-item-name-message">{itm.author_name}</span>&nbsp;({itm?.player_id})
					</div>
					<div className="notification-item-bottom">
						<div className="notification-item-created-at">Today at {getFormatDateByUserTimezone(itm.created_at, "HH:mm")}</div>
						<button className="btn-primary notification-item-button" onClick={() => viewMessageHandler(itm)}>
							View Message
						</button>
					</div>
				</div>
			);
		});
	};

	const renderNotificationBetList = () => {
		return betsList
			.filter(item => item.bet.trader_id == 0 || item.bet.trader_id == user?.id)
			.map(itm => {
				return (
					<div
						className={`notification-item-wrapper ${findOriginBet(itm.notification.bet_referral_id) ? "" : "no-active"}`}
						key={itm?.notification?.bet_referral_id}
					>
						<div className="notification-item-title">Bet Referral &#183; {itm?.notification?.bet_referral_id}</div>
						<div className="notification-item-name">{itm.notification.match_name}</div>
						<div className="notification-item-payout">
							Total Stake:
							{" " + formatTotalStake(itm)} {itm.notification.currency || "No currency found"}
						</div>
						<div className="notification-item-payout">
							Total Returns:
							{" " + formatTotalPayout(itm)} {itm.notification.currency || "No currency found"}
						</div>
						<div className="notification-item-bottom">
							<div className="notification-item-created-at">Today at {getFormatDateByUserTimezone(itm.bet.created_at, "HH:mm")}</div>
							<button className="btn-primary notification-item-button" onClick={() => viewBetHandler(itm)}>
								View Bet
							</button>
						</div>
					</div>
				);
			});
	};

	const betListCount = betsList.filter(item => item.bet.trader_id == 0 || item.bet.trader_id == user?.id)?.length;

	return (
		<div className="header-notification">
			<div className="header-notification-inner">
				<div className="cursor-pointer" onClick={chatNotificationIconHandler}>
					{notificationCount ? <ChatActiveNotification /> : <ChatNotification />}
				</div>
				{isChatNotificationOpen && (
					<div className="header-notification-inner_container">
						<div className="header-notification-box">
							<div className="ticker-notifications-title">Notifications</div>
							<div className="ticker-notifications-list">{renderNotificationMessageList()}</div>
						</div>
						<div className="notification_backdrop" onClick={() => setIsChatNotificationOpen(false)} />
					</div>
				)}
			</div>

			<div className="header-notification-inner">
				<img src={betListCount ? NotificationOn : NoNotification} alt="notification" onClick={betNotificationIconHandler} />
				{isBetListNotificationOpen && (
					<div className="header-notification-inner_container">
						<div className="header-notification-box">
							<div className="ticker-notifications-title">Notifications</div>
							<div className="ticker-notifications-list">{renderNotificationBetList()}</div>
						</div>
						<div className="notification_backdrop" onClick={() => SetIsBetListNotificationOpen(false)} />
					</div>
				)}
			</div>
		</div>
	);
};

export default HeaderNotification;
