import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { setBreadcrumbItems } from "../../../store/actions";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import Spinner from "react-bootstrap/Spinner";
import { XCloseIcon, TranslateIcone } from "utils/icons";
import { DisableCountryButton, EnableCountryButton, NoButton, YesButton } from "../../../components/GeneralComponents/AddButton";
import { _changeTypeOptions, platformName } from "utils/constants";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import xIcon from "../../../assets/images/x-icon.svg";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { getVersions } from "utils/global";
import classNames from "classnames";
import getTerms from "services/getTerms";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";

const editorApiUrl = process.env.REACT_APP_EDITOR_API_KEY;
const Term = props => {
	const paramId = props?.match?.params?.id;
	const countries = useSelector(state => state.Login.gsData.countries);
	const lsGSData = localStorage.getItem("gsData") ? JSON.parse(localStorage.getItem("gsData")) : [];

	const editorRef = useRef(null);

	const [term, setTerm] = useState(null);
	const [termCountry, setTermCountry] = useState({});
	const [termLanguage, setTermLanguage] = useState("EN");
	const [versionType, setVersionType] = useState("1.0");
	const [changeType, setChangeType] = useState("minor");
	const [termId, setTermId] = useState(0);
	const [allTerms, setAllTerms] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [languages, setLanguages] = useState(null);
	const [editorChanged, setEditorChanged] = useState(false);
	const [isLoadingTranslate, setIsLoadingTranslate] = useState(false);
	const [translateIsActive, setTranslateIsActive] = useState(true);

	const breadcrumbItems = [{ title: "Term", link: `/term/${paramId}` }];

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const handleTranslate = () => {
		var editorContent = editorRef.current.getContent();
		setIsLoadingTranslate(true);

		const payload = {
			text: editorContent,
			translateTo: [termLanguage?.code2],
			translateFrom: "en",
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.translate_languages, resolve, reject, payload, true);
		})
			.then(res => {
				const data = res.data[0];
				const translation = data?.translation;

				if (translation) {
					const formattedTransation = translation.replaceAll("\n", "");
					editorRef.current.setContent(formattedTransation.slice(1, formattedTransation.length - 1));
					setTranslateIsActive(false);
				}
			})
			.finally(() => {
				setIsLoadingTranslate(false);
			});
	};

	const handleDataChanged = (language, version) => {
		if (language) {
			setIsLoading(true);
			const params = `?language=${language}&country=${termCountry.country}&version=${version}`;
			getTerms(params)
				.then(response => {
					const { data } = response;

					const term = data[data.length - 1];

					setTerm(term || null);
					setEditorChanged(false);

					setTermId(data.length ? term?.id : 0);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const editTermFunction = () => {
		var editorContent = editorRef.current.getContent();
		let payload = "";
		if (termId != "0") {
			let type = changeType.toLocaleLowerCase();
			let countryCca2;
			if (termCountry.country && termCountry.country.length > 2) {
				countryCca2 = countries.find(item => item.name === termCountry.country)?.cca2;
			}
			payload = {
				id: termId,
				type: type,
				content: editorContent,
				country: countryCca2 ? countryCca2 : termCountry.country ? termCountry.country : "ALL",
				language: termLanguage.code2.toLowerCase(),
			};
		} else {
			const cca2 = countries.find(item => item.cca2 === termCountry.country)?.cca2;
			if (cca2 === "WX") {
				payload = {
					content: editorContent,
					language: termLanguage.code2.toLowerCase(),
					country: "ALL",
				};
			} else {
				payload = {
					content: editorContent,
					language: termLanguage.code2.toLowerCase(),
					country: cca2 ? cca2 : "ALL",
				};
			}
		}
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.term_edit, resolve, reject, payload, true);
		}).then(response => {
			if (response.success) {
				props.history.push(`/terms`);
			}
		});
	};

	const changeTermCountryStatus = async country_disabled => {
		return new Promise((res, rej) => {
			const payload = {
				country_disabled: country_disabled,
				country: termCountry.country,
			};

			ApiServices.post(`${apiUrls.term_enable_country}`, res, rej, payload, true);
		})
			.then(response => {
				if (response.success) {
					props.history.push(`/terms`);
				}
			})
			.catch(error => {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">{error?.message}</p>
					</>,
					{ className: "custom-toast" }
				);
			});
	};

	const fetchTerms = async () => {
		const languagesResponse = await new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_languages, resolve, reject, true);
		});

		setLanguages(languagesResponse);
		const param = `?id=${paramId}`;
		const [term] = await getTerms(param);

		setTerm(term);
		setTermId(term?.id);
		setTermCountry({ country: term?.country || "ALL", name: term?.name === "null" || !term?.name ? "ALL" : term?.name });

		setTermLanguage(
			term
				? languagesResponse.data.find(language => language.code2.toLowerCase() === term.language.toLowerCase())
				: { code2: "EN", language_name: "English" }
		);
		setVersionType(term?.version);

		await getTerms(param).then(response => {
			const data = response?.reduce((accum, value) => {
				if (value.country === term?.country) {
					if (accum[value.language]) {
						return {
							...accum,
							[value.language]: [...accum[value.language], value],
						};
					}

					return {
						...accum,
						[value.language]: [value],
					};
				}
				return accum;
			}, {});

			setAllTerms(data);
		});
	};

	useEffect(() => {
		props.setBreadcrumbItems("Term", breadcrumbItems);

		fetchTerms();
	}, []);

	const countryDropdown = countries && countries.length > 0 ? countries : lsGSData && lsGSData.countries ? lsGSData.countries : [];

	const versions = getVersions(allTerms, termLanguage.code2);

	const isLatestVersion = versions && !isLoading ? versions?.[0] === term?.version : true;

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Term - {platformName}</span>
				<XCloseIcon
					onClick={() => {
						props.history.push("/terms");
					}}
					className={"xclose-icon"}
				/>
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editTermFunction} dataChanged={editorChanged} onClose={() => props.history.push("/terms")}>
					{term && term?.country_disabled ? (
						<EnableCountryButton onClick={() => changeTermCountryStatus(false)} />
					) : (
						<DisableCountryButton onClick={openModal} />
					)}
				</TopBarComponent>
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-12 promotion-inputs customized_modal_promotion_left">
						<div className="row mb-3 align-items-center">
							<div className="col-12 col-md-3 terms_privacy_container">
								<GeneralDropdown
									object={{
										name: "Change Type",
										options: _changeTypeOptions,
										value: changeType,
										required: true,
										disabled: !isLatestVersion || paramId === "new",
										dropdownClassName: "mb-2",
										wrapperClassNameDropdown: "flex-column p-0",
									}}
									handleChange={e => setChangeType(e.id)}
								/>
							</div>
							<div className="col-12 col-md-3 terms_privacy_container">
								<GeneralDropdown
									object={{
										name: "Country",
										options: countryDropdown.map(({ cca2, name }) => {
											return { id: cca2, label: name };
										}),
										label: termCountry?.name || "ALL",
										value: termCountry?.country || "ALL",
										disabled: paramId !== "new",
										dropdownClassName: "mb-2",
										wrapperClassNameDropdown: "flex-column p-0",
									}}
									handleChange={e => setTermCountry({ country: e.id, name: e.label })}
								/>
							</div>
							<div className="col-12 col-md-3 terms_privacy_container">
								<GeneralDropdown
									object={{
										name: "Version",
										options: versions?.map((version, i) => {
											return { id: version, label: `${version} ${i === 0 && "(Latest)"}` };
										}),
										bindValue: versions?.length > 0 ? versionType : "1.0",
										value: versions?.length > 0 ? versionType : "1.0",
										disabled: paramId === "new",
										dropdownClassName: "mb-2",
										wrapperClassNameDropdown: "flex-column p-0",
									}}
									handleChange={e => {
										handleDataChanged(termLanguage.code2, e.id);
										setVersionType(e.id);
									}}
								/>
							</div>
							<div className="col-12 col-md-3 terms_privacy_container">
								<div className="page_content_icon">
									{translateIsActive && languages && termLanguage?.code2 && termLanguage?.code2 !== "EN" && (
										<span className="cursor-pointer">
											{isLoadingTranslate ? (
												<Spinner animation="border" size="sm" />
											) : (
												<TranslateIcone value={termLanguage.code2} handleTranslate={handleTranslate} />
											)}
										</span>
									)}
								</div>
								<GeneralDropdown
									object={{
										name: "Language",
										options: languages?.data
											?.filter(language => language.in_use)
											?.map(row => {
												return { id: row.code2, label: row.language_name };
											}),
										label: termLanguage?.language_name,
										value: termLanguage?.code2,
										dropdownClassName: "mb-2",
										wrapperClassNameDropdown: "flex-column p-0",
									}}
									handleChange={e => {
										const lastVersion = getVersions(allTerms, e.id)?.[0];

										setVersionType(lastVersion);
										handleDataChanged(e.id, lastVersion);
										setTermLanguage({ code2: e.id, language_name: e.label });
									}}
								/>
							</div>
						</div>
						{isLoading ? (
							<div className="text-center mt-5">
								<Spinner animation="border" size="lg" />
							</div>
						) : (
							<div className={classNames("editor", { active: isLatestVersion })}>
								<Editor
									apiKey={editorApiUrl}
									onInit={(evt, editor) => (editorRef.current = editor)}
									initialValue={term?.content || ""}
									init={{
										height: 700,
										menubar: false,
										plugins: [
											"advlist",
											"autolink",
											"lists",
											"link",
											"image",
											"charmap",
											"preview",
											"anchor",
											"searchreplace",
											"visualblocks",
											"code",
											"fullscreen",
											"insertdatetime",
											"media",
											"table",
											"code",
											"help",
											"wordcount",
										],
										toolbar:
											"undo redo | blocks | " +
											"bold italic forecolor link | alignleft aligncenter " +
											"alignright alignjustify | bullist numlist outdent indent | " +
											"removeformat | help | code",
										content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}",
									}}
									disabled={!isLatestVersion}
									onKeyDown={() => {
										setEditorChanged(true);
										setTranslateIsActive(true);
									}}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			{modalIsOpen && (
				<SweetAlert
					title={<span className="terms-disable-title">Confirm Disabling of Terms and Conditions</span>}
					showConfirm={false}
					style={{ borderRadius: "0", padding: "30px 24px" }}
					onConfirm={() => {}}
				>
					<p className="terms-disable-paragraph">
						Are you sure you want to disable the Terms and Conditions <br />
						for {term.name} ?
					</p>
					<p className="mb-3 pb-3 terms-disable-paragraph">
						If you want to disable a specific language create a <br /> new version which is blank.
					</p>
					<NoButton onClick={closeModal} name="No" />
					<YesButton
						name="Yes"
						onClick={() => {
							changeTermCountryStatus(true);
						}}
					/>
				</SweetAlert>
			)}
		</div>
	);
};
export default connect(null, { setBreadcrumbItems })(Term);
