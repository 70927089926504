import { SET_NOTIFICATIONS_LIST, REMOVE_FROM_NOTIFICATIONS_LIST } from "./actionTypes"

export const setNotificationsList = payload => {
	return {
		type: SET_NOTIFICATIONS_LIST,
			payload,
	}
};

export const removeFromNotificationsList = payload => {
	return {
		type: REMOVE_FROM_NOTIFICATIONS_LIST,
			payload,
	}
};
