export const transactionCheckerColumns = () => {
	return [
		{
			name: "player_id",
			label: "Player ID",
			className: "name",
			sortable: true,
			sort: "asc",
			align: "left",
		},
		{
			name: "user_id",
			label: "User ID",
			className: "name",
			sortable: true,
			sort: "asc",
			align: "left",
		},
		{
			name: "balance",
			label: "Balance",
			className: "name",
			sortable: true,
			sort: "asc",
			align: "left",
		},
		{
			name: "tx_balance",
			label: "Tx Balance",
			className: "name",
			sortable: true,
			sort: "asc",
			align: "left",
		},
		{
			name: "currency",
			label: "Currency",
			className: "name",
			sortable: true,
			sort: "asc",
			align: "left",
		},
	];
};
