import { SET_BACKDROP } from "./actionTypes";

const initialState = {
 isActive: false,
};

const Backdrop = ( state = initialState, action) => {
	switch (action.type) {
		case SET_BACKDROP:
			return {
				...state,
				isActive: action.payload,
			};

		default:
			return state;
	}
};

export default Backdrop;
