import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { GeneralDropdown, ModalButton } from "components/GeneralComponents/CustomInputs";

import { rulesData } from "./rules";

const AddNewRuleModal = ({ closeModal, addNewRule, isAddModalOpen, rulesSections, rules }) => {
	const [section, setSection] = useState(isAddModalOpen.id);
	const [selectedRule, setSelectedRule] = useState("");

	const checkIfDisabld = id => {
		const filteredElements = rulesData?.filter(item => item?.disable?.includes(id));
		return filteredElements?.some(item => rules.find(({ condition_type }) => condition_type == item.id));
	};

	return (
		<SweetAlert onConfirm={() => {}} showConfirm={false} onCancel={() => closeModal()} titleStyle={{ fontSize: "12px" }}>
			<div className="add-new-rule-modal">
				<h4>Add Rule</h4>
				<p>Select bonus engine rule. You will be able to modify the settings of the rule once you have added it.</p>

				<div className="inputs-ctn">
					<GeneralDropdown
						object={{
							value: section,
							options: rulesSections,
							placeholder: "Select",
						}}
						handleChange={e => setSection(e?.id)}
					/>

					<GeneralDropdown
						object={{
							value: selectedRule,
							options: rulesData
								.filter(({ type, id }) => type == section && !rules.some(({ condition_type }) => condition_type == id))
								.map(item => ({ ...item, disabled: checkIfDisabld(item.id) })),
							placeholder: "Select",
							sort: true,
						}}
						handleChange={e => {
							setSelectedRule(e?.id);
						}}
					/>

					<div className="button-ctn">
						<ModalButton
							object={{
								type: "button",
								buttonName: "Cancel",
								buttonClassname: "btn-primary cancel-btn reset_button_user",
								onClickEvent: closeModal,
							}}
						></ModalButton>

						<ModalButton
							object={{
								type: "button",
								buttonName: "Add",
								buttonClassname: "save-btn",
								onClickEvent: () => {
									const selectedItem = rulesData.find(({ id }) => id == selectedRule);
									addNewRule(selectedItem);
									closeModal();
								},
							}}
						></ModalButton>
					</div>
				</div>
			</div>
		</SweetAlert>
	);
};

export default AddNewRuleModal;
