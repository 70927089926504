import { React, useEffect, useRef, useState } from "react";
import { getLanguages } from "utils/constants";
import { AutoTranslate, TranslateIcone } from "utils/icons";
import ApiServices from "utils/ApiServices";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import { apiUrls } from "utils/const.apiUrl";
import { Editor } from "@tinymce/tinymce-react";
import { capitalizeText } from "services/capitalizeText";
import { landingPageInitEditor } from "utils/EditorSettings";
import { userReadOnly } from "utils/global";

const editorApiUrl = process.env.REACT_APP_EDITOR_API_KEY;
export const LanguageTitle = props => {
	const { data, setData, title, translateTo = [], editorRef = false, handleDataChanged } = props;
	const singleTranslate = translateTo.length === 1 ? true : false;

	const inUseLanguages = useSelector(state => state.Login.languages);
	const languageData = getLanguages(title);
	const textAreaRef = useRef(null);

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	const [isLoading, setIsLoading] = useState(false);
	const [language, setLanguage] = useState([{ translate: "", key: "", lang: "", translation: "" }]);
	const [textareaheight, setTextareaheight] = useState();

	if (inUseLanguages?.length > 0 && !singleTranslate) {
		inUseLanguages.forEach(lang => {
			translateTo.push(lang?.for);
		});
	}
	useEffect(() => {
		let languagesFormat = [];
		if (inUseLanguages?.length > 0) {
			inUseLanguages.forEach(lang => {
				let obj = {};
				obj[`translate_${lang?.for}`] = "";
				obj["key"] = `${title}_${lang?.for}`;
				obj["lang"] = lang?.for;
				obj["translation"] = "";
				languagesFormat.push(obj);
			});
			setLanguage(languagesFormat);
		}
	}, [title]);

	const handleTextAarea = (e, row) => {
		const value = e.target.value;

		if (value) e.target.style.height = `${e.target.scrollHeight}px`; // Set the height based on the scrollHeight
		else e.target.style.height = "30px"; // Reset the height to recalculate the actual scrollHeight

		const updatedLanguage = language?.map(item => {
			if (item?.lang === row?.language) {
				return { ...item, translation: value };
			}
			return item;
		});
		setLanguage(updatedLanguage);

		const updatedData = {
			...data,
			[row.key]: value,
			language: row.language,
		};

		setData(updatedData);
		handleDataChanged();
	};

	const handleTranslate = languageCode => {
		const single = languageCode?.length === 1 ? true : false;
		let payload = {
			text: data[title],
			translateTo: single ? languageCode : translateTo,
			translateFrom: "en",
		};

		if (single) setIsLoading(languageCode[0]);
		else setIsLoading("all");

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.translate_languages, resolve, reject, payload, true);
		})
			.then(res => {
				let translations = res?.data;
				if (translations?.length > 0) {
					const updatedLanguage = language.map(item => {
						const translation = translations.find(entry => entry.language === item.lang);
						if (translation) {
							return { ...item, ...translation };
						}
						return item;
					});
					setLanguage(updatedLanguage);

					if (singleTranslate) {
						setData(translations);
					} else {
						let updatedData = { ...data };

						updatedLanguage.forEach(language => {
							const key = language.key;
							const changeTranslate = language?.language;
							const newTranslation = changeTranslate ? language.translation : updatedData[key];

							if (title === "description") {
								const newKey = key.replace("title", "description");
								updatedData = { ...updatedData, [newKey]: newTranslation };
							} else {
								updatedData = { ...updatedData, [key]: newTranslation };
							}
						});
						setData(updatedData);
					}
					handleDataChanged();
					handleChange();
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleChange = () => {
		if (!editorRef) setTextareaheight(`${textAreaRef?.current?.scrollHeight}px` || "30px"); // Set the height based on the scrollHeight
	};

	useEffect(() => {
		if (!editorRef) handleChange();
	}, []);

	return (
		<div className={`language-titles ${isUserReadOnly && "pe-none"}`}>
			<div className={singleTranslate ? "page_content_icon justify-content-end" : "d-flex justify-content-end my-3"}>
				{isLoading === "all" ? (
					<Spinner animation="border" size="sm" style={{ marginBottom: "14px" }} />
				) : (
					<>
						{singleTranslate ? <TranslateIcone handleTranslate={() => handleTranslate([])} /> : <AutoTranslate onClick={() => handleTranslate()} />}
					</>
				)}
			</div>
			{languageData.map(row => {
				return (
					<div className={editorRef ? "landing-language" : "language-title"} key={row?.key}>
						<div className="lang-name d-flex justify-content-between">
							{row?.name}
							<div className="d-flex justify-content-end my-3">
								<TranslateIcone row={row?.language} isLoading={isLoading} handleTranslate={() => handleTranslate([row?.language])} />
							</div>
						</div>
						<div className="d-flex align-items-center" style={{ gap: "12px" }}>
							<span className="title-text">{capitalizeText(title)}</span>
							{editorRef ? (
								<div className={isUserReadOnly && "isDisabled"} style={{ width: "100%" }}>
									<Editor
										apiKey={editorApiUrl}
										onInit={(evt, editor) => (editorRef.current = editor)}
										value={data?.[row?.key] || ""}
										tagName="123"
										init={landingPageInitEditor}
										onEditorChange={(e, editor) => {
											const newContent = editor.getContent();
											setData({ ...data, [row?.key]: newContent });
										}}
									/>
								</div>
							) : (
								<textarea
									style={{ height: textareaheight }}
									type="text"
									className={`language-input form-control ${isUserReadOnly && "isDisabled"} ${singleTranslate ? "subtitleInputStyle" : ""}`}
									placeholder={"Optional"}
									value={data?.[row?.key] || ""}
									onChange={e => handleTextAarea(e, row)}
									ref={textAreaRef}
								/>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};
