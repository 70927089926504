export const getPeriodCompetitionTableColumns = () => {
	return [
		{
			name: "sport_name",
			label: "SPORT NAME",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "competition_name",
			label: "COMPETITION NAME",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "name",
			label: "NAME",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "date_from",
			label: "DATE FROM",
			className: "sport",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "date_to",
			label: "DATE TO",
			className: "sport",
			align: "left",
			sortable: true,
			sort: "asc",
		},
	];
};
