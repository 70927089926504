import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

const getTerms = async param => {
	const params = param ? param : "";
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.terms + params, resolve, reject, true);
	})
		.then(response => {
			let data = response?.data;
			return data;
		})
		.catch(() => {
			return [];
		});
};
export default getTerms;
