import React, { useEffect, createRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import SearchFilter from "components/SearchFilter/searchFilter";
import { setBreadcrumbItems } from "../../../store/actions";
import { getCompetitorsTableColumn } from "./competitiorsTableColumns";
import { getCompetitorsFilterColumns } from "./competitorsFilterColumns";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { _genders } from "utils/constants";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { ShowAddCompetitors } from "./showAddCompetitors";
import { ShowEditCompetitors } from "./showEditCompetitors";
import { setSportNameBySlug } from "utils/global";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";
import MUIDataTable from "mui-datatables";
import { prepareFilterData } from "utils/global";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const Competitors = props => {
	const competitorsFilterData = useSelector(state => state.Login.searchFilterData.competitors);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [rowPerPage, setRowPerPage] = useState(50);
	const ref = createRef();
	const breadcrumbItems = [{ title: "Competitors", link: "/competitors" }];
	const sports = useSelector(state => state.Login.gsData.sports);
	const [showAddModal, setShowAddModal] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [sportType, setSportType] = useState("");
	const [competitorsType, setCompetitorsType] = useState("");
	const [competitorsName, setCompetitorsName] = useState("");
	const [competitorsId, setCompetitorsId] = useState("");
	const [editClicked, setEditClicked] = useState(false);

	let competitorsFilterColumns = getCompetitorsFilterColumns({ sports });

	// Redux user data

	const [records, setRecords] = useState([]);

	useEffect(() => {
		props.setBreadcrumbItems("Competitors", breadcrumbItems);
	});

	var rc = [];

	const filterCompetitors = () => {
		setIsDataLoading(true);
		const formatedData = prepareFilterData(competitorsFilterData, competitorsFilterColumns);
		new Promise((resolve, reject) => {
			ApiServices.get(
				apiUrls.competitor_get +
					"?name=" +
					formatedData?.name +
					"&sport_slug=" +
					formatedData?.sport_id +
					"&gender=" +
					formatedData?.gender +
					"&type=" +
					formatedData?.type,
				resolve,
				reject,
				true
			);
		})
			.then(response => {
				if (response.success && response?.data) {
					let data = response.data;
					data.map(competitor => {
						var gender = competitor.gender != null ? competitor.gender : "-";
						let obj = {
							id: competitor.id,
							name: competitor?.name,
							type: competitor?.type,
							gender: gender,
							sport_name: competitor?.sport_name,
						};

						if (competitor?.genius_id == "0") {
							const { name } = setSportNameBySlug(competitor?.sport_slug);
							obj.sport_name = name;
						}
						rc.push(obj);
					});
					setRecords(rc);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	let competitiorsTableColumns = getCompetitorsTableColumn({
		records,
		setCompetitorsName,
		setCompetitorsType,
		setSportType,
		setEditClicked,
		setCompetitorsId,
	});
	let page_name = "Competitors";

	return (
		<HelmetLayout titleProps={"Competitors"}>
			<div className="cms-page pt-0 cms-table-page">
				<div className="col-9 d-flex justify-content-between">
					<SearchFilter
						columns={competitorsFilterColumns}
						page={"competitors"}
						preSelectedType={"select"}
						preSelectedValue={"gender"}
						preSelectedName={"Gender"}
						preSelectedData={_genders}
						preSelectedOptionKey={"value"}
						preSelectedOptionName={"name"}
						filterHandler={filterCompetitors}
						filterLoading={isDataLoading}
						customizedFilter={true}
					/>
				</div>
				<ShowAddCompetitors
					showAddModal={showAddModal}
					setShowAddModal={setShowAddModal}
					dataChanged={dataChanged}
					setDataChanged={setDataChanged}
					filterCompetitors={filterCompetitors}
				/>
				<ShowEditCompetitors
					editClicked={editClicked}
					setEditClicked={setEditClicked}
					setShowEditModal={setEditClicked}
					name={competitorsName}
					setCompetitorsName={setCompetitorsName}
					setCompetitorsType={setCompetitorsType}
					setSportType={setSportType}
					type={competitorsType}
					sport={sportType}
					dataChanged={dataChanged}
					setDataChanged={setDataChanged}
					competitorsId={competitorsId}
					records={records}
					setRecords={setRecords}
					filterCompetitors={filterCompetitors}
				/>
				<MUIDataTable
					columns={competitiorsTableColumns}
					data={records}
					ref={ref}
					options={{
						...tableBarSettings,
						selectableRows: false,
						elevation: 0,
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: page_name + ".csv" },
						customToolbar: () => <AddTableButton onClick={() => setShowAddModal(true)} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(Competitors);
