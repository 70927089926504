export const upFields = ({ handleShowOptions, showOptions, landingData, setLandingData, setDataChanged, onFocus }) => [
	{
		group: "upFields",
		type: "input",
		name: "Title",
		fieldName: "title",
		required: true,
		onFocus: () => handleShowOptions("language", "title"),
		onBlur: () => (onFocus.current = false),
	},
	{
		group: "upFields",
		type: "image",
		name: "Desktop Image",
		fieldName: "desktop_image",
		onFocus: () => handleShowOptions("image", "desktop_image"),
		onBlur: () => (onFocus.current = false),
		active: showOptions.image === "desktop_image",
		imageType: "desktop_image",
		data: landingData,
		setData: setLandingData,
		setDataChanged: setDataChanged,
		required: true,
		imageLibaryType: "seo",
		dimensions: "(1700 x 450 px)",
	},
	{
		group: "upFields",
		type: "image",
		name: "Mobile Image",
		fieldName: "mobile_image",
		onFocus: () => handleShowOptions("image", "mobile_image"),
		onBlur: () => (onFocus.current = false),
		active: showOptions.image === "mobile_image",
		value: landingData.mobile_image,
		imageType: "mobile_image",
		data: landingData,
		setData: setLandingData,
		setDataChanged: setDataChanged,
		imageLibaryType: "seo",
		required: true,
		dimensions: "(720 x 640 px)",
	},
	{
		group: "upFields",
		type: "textarea",
		name: "Explainer text",
		fieldName: "explainer_text",
		textareaClassName: "scaleTextarea",
		textUpperCase: true,
		// maxLength: 150,
		onFocus: () => handleShowOptions("language", "explainer_text"),
		onBlur: () => (onFocus.current = false),
	},
	{
		group: "upFields",
		type: "input",
		name: "Offer Header",
		fieldName: "offer_header",
		required: true,
		onFocus: () => handleShowOptions("language", "offer_header"),
		onBlur: () => (onFocus.current = false),
	},
];
export const downFields = ({ handleShowOptions, showOptions, landingData, setLandingData, setDataChanged, toggleKycEnabled, onFocus }) => [
	{
		group: "downFields",
		type: "dropdown",
		name: "Promo Tag",
		fieldName: "affiliate_id",
		optionsType: "affiliateUsers",
		placeholder: "Affiliate",
		addUser: true,
		required: true,
	},
	{
		group: "downFields",
		type: "switch",
		name: "Published",
		fieldName: "published",
		onClickEvent: toggleKycEnabled,
		value: landingData?.published,
	},
	{
		group: "downFields",
		type: "empty_row",
		name: "SEO",
		inputClassName: "optional-custum-input",
	},
	{
		group: "downFields",
		type: "input",
		name: "Title",
		fieldName: "seo_title",
		onFocus: () => handleShowOptions("language", "seo_title"),
		onBlur: () => (onFocus.current = false),
	},
	{
		group: "downFields",
		type: "textarea",
		name: "Description",
		fieldName: "seo_description",
		textareaClassName: "scaleTextarea",
		textUpperCase: true,
		// maxLength: 150,
		onFocus: () => handleShowOptions("language", "seo_description"),
		onBlur: () => (onFocus.current = false),
	},
	{
		group: "downFields",
		type: "image",
		name: "Image",
		fieldName: "seo_image",
		onFocus: () => handleShowOptions("image", "seo_image"),
		onBlur: () => (onFocus.current = false),
		active: showOptions.image === "seo_image",
		value: landingData.seo_image,
		imageType: "seo_image",
		data: landingData,
		setData: setLandingData,
		setDataChanged: setDataChanged,
		imageLibaryType: "seo",
		required: true,
		dimensions: "(3480 x 230 px)",
	},
	{
		group: "downFields",
		type: "input",
		name: "Keywords",
		fieldName: "seo_keywords",
		onFocus: () => handleShowOptions("language", "seo_keywords"),
		onBlur: () => (onFocus.current = false),
	},
];
