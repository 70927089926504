import React from "react";
import { EditButton } from "components/GeneralComponents/AddButton";

export const getPlayersTableColumns = ({ records, setShowModal, showModal, setPlayerData, setEditClicked }) => {
	return [
		{
			name: "id",
			label: "Id",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "100px", height: "42px", alignItems: "center", display: "flex" }}> Id</div>
						</>
					);
				},
			},
		},
		{
			name: "first_name",
			label: "First Name",
			className: "palyer_first_name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "150px", height: "42px", alignItems: "center", display: "flex" }}> First Name</div>
						</>
					);
				},
			},
		},
		{
			name: "last_name",
			label: "Last Name",
			className: "player_last_name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "150px", height: "42px", alignItems: "center", display: "flex" }}> Last Name</div>
						</>
					);
				},
			},
		},
		{
			name: "display_name",
			label: "Display Name",
			className: "display_name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "160px", height: "42px", alignItems: "center", display: "flex" }}>Display Name</div>
						</>
					);
				},
			},
		},
		{
			name: "squad_number",
			label: "Squad No.",
			className: "player_squad_number",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "160px", height: "42px", alignItems: "center", display: "flex" }}> Squad No.</div>
						</>
					);
				},
			},
		},
		{
			name: "position",
			label: "Position",
			className: "player_position",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "400px", height: "42px", alignItems: "center", display: "flex" }}> Position</div>
						</>
					);
				},
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div style={{ marginRight: "5px" }}>
								<EditButton
									onClick={() => {
										setShowModal({ ...showModal, editPlayerModal: true });
										setEditClicked(true);
										setPlayerData({
											id: record.id,
											squad_number: record.squad_number,
											position: record.position,
											gender: record.gender,
											display_name: record.display_name,
										});
									}}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
