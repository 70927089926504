import React from "react";
import Chart from "./chart.js";

const PieCharts = ({ chart }) => {
	const { data, title, type, column } = chart;
	const chartData = data.map(({ type, value }) => ({ type, value }));
	const colors = data.map(({ color }) => color);

	return (
		<div className={`card ${column}`}>
			<div className="pie-charts-card">
				<h4 className="card-title mb-4">{title}</h4>
				<Chart chartData={chartData} colors={colors} type={type} />
			</div>
		</div>
	);
};

export default PieCharts;
