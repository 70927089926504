import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { emptyTelebetBets, setEmptyLegs, setFreeBetCredit, setTelebetBets } from "store/actions";
import { DarkPlusIcon, XCloseIcon } from "utils/icons";
import { TelebetEmptyLeg } from "./telebetEmptyLeg.component";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { ThousandSeperated } from "hooks/General";
import getSportsData from "services/getSportsData";
import { TelebetFreebet } from "./TelebetFreebet/telebetFreebet";

import TelebetSingle from "./TelebetSingle";
import TelebetCombinations from "./TelebetCombinations";

import { getLadders } from "services/getLaddersDropdown";

export const TelebetBetslip = ({ telebetUser, getUserStats, setShowBetslip }) => {
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem("user"));

	const [cashBetLoading, setCashBetLoading] = useState(false);
	const [freeBetLoading, setFreeBetLoading] = useState(false);
	const [sports, setSports] = useState([]);
	const [sportsLadders, setSportsLadders] = useState({});

	const freebetCredit = useSelector(state => state.Telebet.freeBetCreditSelect);
	const telebetBets = useSelector(state => state.Telebet.telebetBets);
	const emptyLegs = useSelector(state => state.Telebet.emptyLegs) ?? [];

	const totalStakes = telebetBets?.total_stakes;
	const totalPayout = telebetBets?.total_payout;

	const message = telebetBets?.message?.length > 0 ? telebetBets?.message : telebetBets?.warnings;
	const isWarnings = telebetBets?.message?.length > 0 ? false : true;

	const cashBet = () => {
		const bets = [];
		const unnamed_favorite = [];

		telebetBets?.singles.forEach(selected_row => {
			// eslint-disable-next-line
			const { place, trading_status, odds_decimal, ...bet } = selected_row;

			if (selected_row?.trading_status?.toLowerCase() === "open") {
				bets.push({ ...bet, odds: odds_decimal });
			}

			if (selected_row?.bet_type?.toLowerCase() === "unnamed_favorite") {
				const { bet_id, ...unnamedFavoriteBet } = bet;

				unnamed_favorite.push({ event_id: bet_id, ...unnamedFavoriteBet });
			}
		});

		let payload = {
			player_id: telebetUser?.swifty_id,
			action: "place",
			bets: bets || [],
			unnamed_favorite: unnamed_favorite || [],
			empty_legs: emptyLegs,
			stakes: telebetBets.stakes ? telebetBets?.stakes : [],
			is_telebet: true,
			cms_user_id: user?.id,
		};

		if (freebetCredit?.id) {
			setFreeBetLoading(true);
			payload = { ...payload, free_bet_id: freebetCredit?.id, use_free_bet: 1 };
		} else {
			setCashBetLoading(true);
		}

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.ADD_BET_SLIP + "?cms=true", resolve, reject, payload, true, [], true);
		})
			.then(response => {
				const { data, message, warnings } = response;
				if (data.mode === "accepted") {
					getUserStats();
					dispatch(emptyTelebetBets());
					dispatch(setTelebetBets({ ...telebetBets, combinations: [], singles: [], message: ["Your bet has been placed successfully!"], warnings: [] }));

					setTimeout(() => {
						dispatch(emptyTelebetBets());
					}, 2000);
				} else {
					dispatch(setTelebetBets({ ...telebetBets, message, warnings }));
				}
			})
			.finally(() => {
				setCashBetLoading(false);
				setFreeBetLoading(false);
			});
	};

	const getSports = () => {
		getSportsData()
			.then(response => {
				setSports(response);
			})
			.finally(() => {
				setCashBetLoading(false);
			});
	};

	useEffect(() => {
		getSports();
	}, []);

	const removeEmptyLeg = index => {
		let newEmptyLegs = [...emptyLegs];
		newEmptyLegs.splice(index, 1);
		dispatch(setEmptyLegs(newEmptyLegs));
	};

	const showMessage = message => {
		if (isWarnings) {
			return message.map((row, i) => {
				return <div key={i}>{row?.message}</div>;
			});
		} else if (message?.length > 0 && typeof message == "object") {
			return message.map((row, i) => {
				return <div key={i}>{row}</div>;
			});
		} else if (typeof message == "string") {
			return <div className="telebet-message">{message}</div>;
		} else {
			return "";
		}
	};

	const handleEmptyTelebetBets = () => {
		dispatch(emptyTelebetBets());
	};

	useEffect(() => {
		if (telebetBets?.singles?.length === 0 && emptyLegs?.length === 0) {
			dispatch(setFreeBetCredit({}));
		}

		if (telebetBets?.free_bets?.length > 0) {
			const isSelectedFreeBetDisabled = telebetBets?.free_bets?.find(item => item?.id == freebetCredit?.id)?.disabled;

			if (isSelectedFreeBetDisabled) dispatch(setFreeBetCredit({}));
		}
	}, [telebetBets]);

	const getSportsLadders = async () => {
		const singles = telebetBets?.singles;
		const sportSlugs = singles.map(row => row.sport_slug).filter(slug => slug && !Object.keys(sportsLadders).includes(slug));

		const uniqueSlugs = [...new Set(sportSlugs)];

		let ladders = await getAllLadders(uniqueSlugs);
		setSportsLadders({ ...sportsLadders, ...ladders });
	};

	const getAllLadders = async sportSlugs => {
		const promise = await Promise.all(sportSlugs.map(f => getLadders(f)));
		const resultObject = Object.fromEntries(sportSlugs.map((slug, index) => [slug, promise[index]]));
		return resultObject;
	};

	useEffect(() => {
		getSportsLadders();
	}, [telebetBets?.singles]);

	return (
		<>
			<div className="telebet-betslip">
				<div className="telebet-betslip-header">
					<span>BETSLIP</span>
					<XCloseIcon onClick={() => setShowBetslip(false)} />
				</div>

				<div
					className="add-empty-leg"
					onClick={() => {
						let actualEmptyLegs = [...emptyLegs];
						actualEmptyLegs.push({
							index: emptyLegs.length + 1,
							sport: "",
							event: "",
							selection: "",
							stake: "",
							each_way: false,
							starting_price: false,
						});
						dispatch(setEmptyLegs(actualEmptyLegs));
					}}
				>
					<span style={{ color: "#2E3A49" }}>ADD MANUAL BET</span>
					<DarkPlusIcon className="add-empty-leg-icon" />
				</div>

				{(emptyLegs?.length > 0 || telebetBets?.singles?.length > 0) && (
					<div className="betslip-close" onClick={handleEmptyTelebetBets}>
						<span>Remove all bets</span>
						<XCloseIcon fill="#2E3A49" />
					</div>
				)}

				<div className="telebet-selected-bets custom-scrollbar">
					{emptyLegs?.map((row, index) => {
						return (
							<TelebetEmptyLeg
								key={index}
								row={row}
								sports={sports}
								emptyLegs={emptyLegs}
								setEmptyLegs={arg => dispatch(setEmptyLegs(arg))}
								handleRemove={() => removeEmptyLeg(index)}
							/>
						);
					})}
					{telebetBets?.singles?.map(row => {
						return <TelebetSingle row={row} key={row?.bet_id} user={user} sportLadders={sportsLadders[row?.sport_slug]} />;
					})}

					{telebetBets?.combinations?.map(row => {
						return <TelebetCombinations row={row} key={`combination-${row?.name}`} user={user} />;
					})}
				</div>
				<div className="telebet-betslip-bottom">
					{telebetBets?.free_bets?.length > 0 && <TelebetFreebet freebetsData={telebetBets?.free_bets} />}
					<div className="telebet-total">
						<span>User Balance:</span>
						<span>{ThousandSeperated(telebetUser?.userBalance)}</span>
					</div>
					<div className="telebet-total">
						<span>Total Stake:</span>
						<span>{totalStakes ? ThousandSeperated(totalStakes) : "0.00"}</span>
					</div>
					<div className="telebet-total no-border">
						<span>Total Returns:</span>
						<span>{totalPayout ? ThousandSeperated(totalPayout) : "0.00"}</span>
					</div>

					{message?.length > 0 ? <div className={`telebet-message`}>{showMessage(message)}</div> : ""}

					{totalStakes > +freebetCredit?.amount && (
						<div className={`telebet-message freebet-message`}>Lower your stake if you want to use free credit.</div>
					)}

					<div className="d-flex gap-2 align-items-center">
						<button
							className={`btn-telebet-cashbet`}
							disabled={freebetCredit?.id || (!telebetBets.singles.length && !emptyLegs.length) ? true : false}
							onClick={() => !freebetCredit?.id && cashBet()}
						>
							{cashBetLoading ? <Spinner animation="border" size="sm" /> : "CASH BET"}
						</button>
						{telebetBets?.free_bets?.length > 0 && (
							<button
								className={`btn-telebet-cashbet ${freebetCredit?.id ? "" : "disabled"}`}
								disabled={totalStakes > +freebetCredit?.amount || !freebetCredit?.id}
								onClick={() => freebetCredit?.id && cashBet()}
							>
								{freeBetLoading ? <Spinner animation="border" size="sm" /> : "USE FREE CREDITS"}
							</button>
						)}
					</div>
				</div>
			</div>
			<div className="telebet-backdrop" onClick={() => setShowBetslip(false)}></div>
		</>
	);
};
