// import MetaTags from "react-meta-tags";
import React, { useEffect } from "react";
// import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { useDispatch } from "react-redux";
// import { withRouter } from "react-router-dom";
// import logoDark from "../../assets/images/sp-logo-dark.png";
// import { platformName } from "utils/constants";
// import SignInFooter from "./SignInFooter";

import InfoModal from "./InfoModal";
import { resetStore } from "store/actions";

const Restricted = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		sessionStorage.clear();
		localStorage.clear();
		dispatch(resetStore());
	}, []);

	return (
		<div>
			<InfoModal
				title="Checksum Mismatch"
				message="Swifty Backoffice is not running because checksum check failed!"
				buttonText="Go to login page"
				redirect="/login"
				message_type="critical"
			/>
		</div>
	);
};

export default Restricted;
