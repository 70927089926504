import React, { useEffect, useState } from "react";
import { XCloseIcon } from "utils/icons";
import "./betReferralModal.css";
import "./BetReferral.scss";
import { GeneralDropdown, ModalButton } from "components/GeneralComponents/CustomInputs";
import { getAllSportsDropdown } from "services/getAllSportsDropdown";
import { getCountriesDropdown } from "services/getCountriesDropdown";

const BetReferralFilter = ({ closeModal, filterCriteria, setFilterCriteria, filterData, setFilteredDatas, betTicketList }) => {
	const [sportLabels, setSportLabels] = useState();
	const [countryLabels, setCountryLabels] = useState();

	const removeChip = (field, index) => {
		const updatedFilterCriteria = { ...filterCriteria };
		field.forEach(element => {
			const val = typeof index == "object" ? index.id + "," + index.label : index;
			updatedFilterCriteria[element] = updatedFilterCriteria[element].filter(item => !val.includes(item));
		});
		setFilterCriteria({
			...updatedFilterCriteria,
		});
	};

	const handleSportTypeInputChange = selectedSportSlug => {
		setFilterCriteria({
			...filterCriteria,
			sport_name: [...filterCriteria?.sport_name, selectedSportSlug?.id],
		});
	};

	const handleCountryInputChange = selectedCountry => {
		setFilterCriteria({
			...filterCriteria,
			country: [...filterCriteria?.country, selectedCountry?.label],
			country_id: [...filterCriteria?.country_id, selectedCountry?.id],
		});
	};

	const getDatas = async () => {
		let sport = await getAllSportsDropdown();
		let country = await getCountriesDropdown();
		setSportLabels(sport);
		setCountryLabels(country);
	};
	useEffect(() => {
		getDatas();
	}, []);
	const resetFilterFields = () => {
		setFilterCriteria({
			bets_over: "",
			sport_name: [],
			user_id: "",
			country: [],
			country_id: [],
		});
		setFilteredDatas(betTicketList);
	};
	return (
		<>
			<div className="bg-modal bet-ticker-modal">
				<div className="bet-user search-filter">
					<div className="bet-user-container search-bet-ticker-container">
						<div className="search-bet-ticker-header">
							<div className="search-bet-ticker-title">Filter by</div>
							<XCloseIcon onClick={closeModal} className={"xclose-icon search-x-icon"} fill={"#2e3a49"} />
						</div>
						<div className="search-bet-user-information">
							<div className="bet-user-inputs search-bet-user-inputs">
								<div className="search-inputs">
									<div className="search-bet-title">Bets Over</div>
									<input
										className="bet-user-label search-bet-user-label"
										placeholder="Bets Over"
										value={filterCriteria.bets_over}
										onChange={e =>
											setFilterCriteria({
												...filterCriteria,
												bets_over: e.target.value,
											})
										}
									/>
								</div>
								<div className="search-inputs">
									<div className="search-bet-title">Sport Type</div>
									<div className="chip-input-container">
										<GeneralDropdown
											object={{
												title: "Sport Type",
												placeholder: "Sport Type",
												options: sportLabels,
												value: filterCriteria?.sport_name,
												multiple: true,
												fieldName: "sport_name",
												type: "bet-ticker-chip",
											}}
											handleRemove={e => removeChip(["sport_name"], e)}
											handleChange={e => handleSportTypeInputChange(e)}
											className="bet-user-label search-bet-user-label"
										/>
									</div>
								</div>

								<div className="search-inputs">
									<div className="search-bet-title">Player</div>
									<input
										className="bet-user-label search-bet-user-label"
										placeholder="Player"
										value={filterCriteria.user_id}
										onChange={e =>
											setFilterCriteria({
												...filterCriteria,
												user_id: e.target.value,
											})
										}
									/>
								</div>

								<div className="search-inputs">
									<div className="search-bet-title">User Country</div>
									<GeneralDropdown
										object={{
											title: "User Country",
											placeholder: "User Country",
											options: countryLabels,
											value: filterCriteria?.country_id,
											multiple: true,
											fieldName: "country",
											type: "bet-ticker-chip",
										}}
										handleRemove={e => removeChip(["country_id", "country"], e)}
										handleChange={e => handleCountryInputChange(e)}
										className="bet-user-label search-bet-user-label"
									/>
								</div>
							</div>
							<div className="search-buttons">
								<ModalButton
									object={{
										name: "reset",
										extraButtonClassName: "referral-btn-no-filled search-bet-reset",
										buttonName: "Reset",
										onClickEvent: () => {
											resetFilterFields();
										},
									}}
								/>
								<ModalButton
									object={{
										name: "search",
										extraButtonClassName: "referral-btn-accept search-bet-approve",
										buttonName: "Search",
										onClickEvent: () => {
											setFilteredDatas(filterData());
										},
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BetReferralFilter;
