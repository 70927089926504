import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getCountriesTableColumns } from "./countriesTableColumns";
import { ShowEditCountryModal } from "./showEditCountryModal";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";

const Countries = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [records, setRecords] = useState([]);
	const [editCountryData, setEditCountryData] = useState();
	const [unfilteredData, setUnfilteredData] = useState([]);

	useEffect(() => {
		getCountries();
	}, []);

	const editCountryFunc = cca2 => {
		setIsLoading(cca2);
		getCountry(cca2);
	};

	const getCountries = () => {
		setIsLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.country_list, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;
				setRecords(data);
				setUnfilteredData(data);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	const getCountry = cca2 => {
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.country_list}?cca2=${cca2}`, resolve, reject, true);
		})
			.then(response => {
				const data = response.data[0];
				setEditCountryData(data);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const saveCountryData = () => {
		setIsLoading(true);
		let payload = { ...editCountryData };
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.country_edit, resolve, reject, payload, true);
		})
			.then(() => {
				getCountries();
			})
			.finally(() => {
				setIsLoading(false);
				setEditCountryData(false);
			});
	};

	let countriesTableColumns = getCountriesTableColumns({ records, isLoading, editCountryFunc });
	return (
		<HelmetLayout titleProps={"Countries"}>
			<div className="cms-page pt-0">
				<SingleSearchFilter
					setData={setRecords}
					unfilteredData={unfilteredData}
					filterColumn={[
						"name",
						"master_geoblock",
						"mobile_geoblock",
						"disabled_reason",
						"default_currency",
						"default_odd_format",
						"phone_same_country_required",
						"phone_required",
					]}
				/>
				<MUIDataTable
					columns={countriesTableColumns}
					data={records}
					options={{
						...tableBarSettings,
						pagination: true,
						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						responsive: tableResponsive,
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
			{editCountryData && (
				<ShowEditCountryModal
					editCountryData={editCountryData}
					setEditCountryData={setEditCountryData}
					saveCountryData={saveCountryData}
					loading={isLoading}
				/>
			)}
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(Countries);
