import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { CancelButton, LoadingSaveButton, SaveButton } from "components/GeneralComponents/AddButton";

export const DeleteSelectionMarketTypeModal = ({ modalShow, setModalShow, deleteMaketSelection, isLoading }) => {
	return (
		<>
			{modalShow.deleteSelectionTypeModal && (
				<SweetAlert
					title="Are you sure you want to delete this market selection type ?"
					showConfirm={false}
					style={{ zIndex: "10001 !important" }}
					customClass={"delete-market"}
					onConfirm={() => {}}
				>
					<div style={{ position: "relative" }}>
						<div className="mt-3 d-flex justify-content-center align-items-center">
							<CancelButton onClick={() => setModalShow({ ...modalShow, deleteSelectionTypeModal: false })} name="No" />
							{isLoading ? <LoadingSaveButton /> : <SaveButton onClick={deleteMaketSelection} name="Yes" />}
						</div>
					</div>
				</SweetAlert>
			)}
		</>
	);
};
