import React from "react";
import { sortColumn } from "utils/global";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import { ActiveExpandIcon, ExpandIcon, DragAndDropFillIcon, DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone } from "utils/icons";
import { Spinner } from "react-bootstrap";
import deleteIcon from "assets/images/delete.svg";

export const getOffersColumns = ({ handleEditItem, setDeleteItem, setItemTitle, changeOrder, handleExpanded }) => {
	return [
		{
			name: "details",
			label: "TITLE",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customSort: (a, b) => sortColumn(a, b, "title"),
				customBodyRender: (value, meta) => {
					const row = meta;
					let details = row?.details ? JSON.parse(row.details) : {};
					let title = details?.title;
					return <>{title}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" }, className: "max-button" }),
			},
		},
		{
			name: "promo",
			label: "PROMO",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					const details = JSON.parse(row.details);

					setItemTitle(details.title);

					return <>{details.promo_type}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "170px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "170px" }, className: "max-button" }),
			},
		},
		{
			name: "status",
			label: "STATUS",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					let published = row?.active;
					return (
						<>
							{published ? (
								<span className="badge badge-sports-published"> Published </span>
							) : (
								<span className="badge badge-sports-inactive"> Inactive </span>
							)}
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "120px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "120px" }, className: "max-button" }),
			},
		},
		{
			name: "favorite",
			label: "EXPANDED",
			className: "status_name",
			options: {
				filter: false,
				download: false,
				customBodyRender: (value, meta) => {
					const rowDetails = JSON.parse(meta.details);
					const expanded = rowDetails?.expanded;

					return (
						<div className="d-flex">
							{!rowDetails ? (
								<Spinner animation="border" size="sm"></Spinner>
							) : (
								<div
									onClick={() => {
										handleExpanded(meta, rowDetails, meta.id);
									}}
								>
									{expanded ? <ActiveExpandIcon /> : <ExpandIcon />}
								</div>
							)}
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "80px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "80px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta, other) => {
					const row = meta;

					let { index, dragHandleProps, isDragging } = other;
					return (
						<div className="d-flex justify-content-end">
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => handleEditItem(row)} />
								<DeleteIconButton
									deleteIcon={deleteIcon}
									onClick={() => {
										setDeleteItem(row);
									}}
								/>
								{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}
								<div
									{...dragHandleProps}
									onMouseDown={(...args) => {
										dragHandleProps?.onDragStart(...args);
									}}
								>
									<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
								</div>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
