import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

export const getLanguageDropdown = async () => {
	if (sessionStorage.getItem("_languages")) {
		return JSON.parse(sessionStorage.getItem("_languages"));
	}
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.get_languages, resolve, reject, true);
	})
		.then(response => {
			let languages = response?.data;
			languages = languages.map(row => {
				return { id: row.code2, label: row.language_name };
			});
			sessionStorage.setItem("_languages", JSON.stringify(languages));
			return languages;
		})
		.catch(() => {
			return [];
		});
};
