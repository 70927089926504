import { XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { GeneralInput, StatusSwitch, GeneralTextArea, GeneralDatePicker, GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import React, { useEffect, useRef, useState } from "react";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { _manualBetTypes } from "utils/constants";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { getAllSportsDropdown } from "services/getAllSportsDropdown";
import { AvForm } from "availity-reactstrap-validation";
import { getMarkets } from "services/getMarkets";

export const AddManualBet = ({
	showAddCustomBets,
	showEditCustomBets,
	setShowAddCustomBets,
	saveManualBet,
	editManualBet,
	bet,
	setBet,
	regionsData,
	getRegions,
	allMatches,
	getAllMatches,
	allCompetitions,
	getCompetition,
	setRegionsData,
	setAllCompetitions,
	setAllMatches,
	loading,
}) => {
	const [dataChanged, setDataChanged] = useState(false);
	const [displayStartDate, setDisplayStartDate] = useState(new Date());
	const [displayEndDate, setDisplayEndDate] = useState(new Date());
	const [betDates, setBetDates] = useState({ bet_start_date: "", bet_end_date: "" });
	const [manualBet, setManualBet] = useState(bet);

	const [disabledDates, setDisabledDates] = useState({ start_date: false, end_date: false });
	const [sports, setSports] = useState([]);
	const [sportMarkets, setSportMarkets] = useState([]);
	const [marketSelections, setMarketSelections] = useState([]);

	const [sportLadders, setSportLadders] = useState([]);
	const [showOptions, setShowOptions] = useState([]);

	const onFocus = useRef(false);

	const getSports = async () => {
		let sport = await getAllSportsDropdown();
		setSports(sport);
	};

	const getSportMarkets = async sport => {
		if (sport) {
			let data = await getMarkets(`?sport_slug=${sport}`);
			setSportMarkets(data.map(({ market_id, market_name }) => ({ id: market_id, label: market_name })));
		}
	};

	const getMarketSelection = async market_id => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.market_selection + "?market_id=" + market_id, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					const { data } = response;
					setMarketSelections(data?.map(({ id, outcome_name }) => ({ id, label: outcome_name })));
				}
			})
			.finally(() => {});
	};

	useEffect(() => {
		getSports();
	}, []);

	useEffect(() => {
		setManualBet(bet);
	}, [bet]);

	useEffect(() => {
		getSportMarkets(manualBet?.sport_slug);
	}, [manualBet?.sport_slug]);

	useEffect(() => {
		if (manualBet?.market_id) getMarketSelection(manualBet?.market_id);
		else setMarketSelections([]);
	}, [manualBet?.market_id]);

	useEffect(() => {
		if (manualBet.id) {
			setBetDates({ bet_start_date: manualBet?.bet_start_date, bet_end_date: manualBet?.bet_end_date });
			setDisplayStartDate(manualBet.display_start_date);
			setDisplayEndDate(manualBet.display_end_date);
		}
	}, [manualBet, sports]);

	useEffect(() => {
		if (manualBet?.display_start_date || manualBet?.display_end_date) {
			setDisabledDates({ start_date: true, end_date: true });
		} else {
			setDisabledDates({ start_date: false, end_date: false });
		}
	}, [manualBet?.display_start_date, manualBet?.display_end_date]);

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	useEffect(() => {
		if (manualBet?.sport_slug) {
			getRegions(manualBet?.sport_slug);
			getSportsLadders(manualBet?.sport_slug);
		}
	}, [manualBet?.sport_slug]);

	useEffect(() => {
		if (manualBet?.clone == "1") {
			setDataChanged(true);
		}
		if (manualBet?.sport_slug && manualBet?.region_id) {
			getCompetition(manualBet?.sport_slug, manualBet?.region_id);
		}
	}, [manualBet?.sport_slug, manualBet?.region_id]);

	useEffect(() => {
		if (manualBet?.sport_slug && manualBet?.competition_id && manualBet?.region_id) {
			getAllMatches(manualBet?.sport_slug, manualBet?.region_id, manualBet?.competition_id);
		}
	}, [manualBet?.sport_slug, manualBet?.competition_id, manualBet?.region_id]);

	const handleSave = () => {
		let newManualBet = { ...manualBet };
		newManualBet.sport_slug = manualBet?.sport_slug;
		newManualBet.sport_id = manualBet?.id;
		newManualBet.region_id = manualBet?.region_id;
		newManualBet.region_name = manualBet?.region_name;
		newManualBet.competition_id = manualBet?.competition_id;
		newManualBet.competition_name = manualBet?.competition_name;
		newManualBet.match_id = manualBet?.match_id;
		newManualBet.match_name = manualBet?.match_name;
		newManualBet.market_id = manualBet?.market_id;
		newManualBet.show_start_date = displayStartDate;
		newManualBet.show_end_date = displayEndDate;
		newManualBet.bet_start_date = betDates?.bet_start_date;
		newManualBet.bet_end_date = betDates?.bet_end_date;

		newManualBet.outcome_id = manualBet?.outcome_id;
		newManualBet.outcome_name = manualBet?.outcome_name;

		newManualBet.type = manualBet?.type;
		newManualBet.odd_decimal = manualBet?.odd_decimal;
		newManualBet.odd_fractional = manualBet?.odd_fractional;

		setBet(newManualBet);

		if (manualBet.clone == "1") {
			saveManualBet(newManualBet);
		} else if (manualBet.id) {
			editManualBet(newManualBet);
		} else {
			saveManualBet(newManualBet);
		}
		setDataChanged(false);
	};

	useEffect(() => {
		if (showEditCustomBets) {
			setManualBet({ title: "", description: "", odd_decimal: "", odd_fractional: "" });
			setDisplayStartDate("");
			setDisplayEndDate("");
			setBetDates({ bet_start_date: "", bet_end_date: "" });
		}
	}, [showEditCustomBets]);

	const disabledDropdDowns = manualBet?.clone == "1" || showEditCustomBets ? false : true;

	const changeFractionalValue = value => {
		const convertedToDecimal = sportLadders.find(({ in_fraction }) => in_fraction == value);
		if (value) {
			setManualBet({
				...manualBet,
				odd_decimal: convertedToDecimal.in_decimal,
				odd_fractional: value,
			});
		} else {
			setManualBet({
				...manualBet,
				odd_decimal: "",
				odd_fractional: "",
			});
		}
	};

	const changeDecimalValue = value => {
		const convertedToFractional = sportLadders.find(({ in_decimal }) => in_decimal == value);

		if (convertedToFractional) {
			setManualBet({
				...manualBet,
				odd_decimal: value,
				odd_fractional: convertedToFractional?.in_fraction,
			});
		} else {
			setManualBet({
				...manualBet,
				odd_decimal: "",
				odd_fractional: "",
			});
		}
	};

	const getSportsLadders = id => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_ladder + `${id}`, resolve, reject, true);
		}).then(response => {
			const data = response.data;
			setSportLadders(data);
		});
	};

	const getMatchDates = match_id => {
		if (match_id) {
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls.GET_MATCHES_DATES + `${match_id}/dates`, resolve, reject, true);
			}).then(response => {
				const { data } = response;

				setBetDates({ bet_start_date: data?.start_date, bet_end_date: data?.end_date });

				if (data?.start_date == "" && data?.end_date == "") {
					setDisabledDates({ start_date: false, end_date: false });
				} else {
					setDisabledDates({ start_date: true, end_date: true });
				}
			});
		} else {
			setBetDates({ bet_start_date: "", bet_end_date: "" });
			setDisabledDates({ start_date: false, end_date: false });
		}
	};

	return (
		<>
			{showAddCustomBets && (
				<div className="promotion-container">
					<div className="promotion-header">
						<span>{manualBet?.clone == "1" ? "Clone" : showEditCustomBets ? "Add" : "Edit"} Manual Bets</span>
						<XCloseIcon onClick={() => setShowAddCustomBets(false)} className={"xclose-icon"} />
					</div>
					<div className="promotion-body">
						<TopBarComponent saveHandler={handleSave} dataChanged={dataChanged} loading={loading} onClose={() => setShowAddCustomBets(false)} />
						<div className="promotion-elements row m-0">
							<div
								className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left add_manual_bet_container"
								onClick={() => !onFocus.current && handleShowOptions()}
							>
								<AvForm>
									<GeneralInput
										object={{
											name: "Title",
											placeholder: "Title",
											id: "name",
											value: manualBet.title,
											addionalICon: true,
											onFocus: () => handleShowOptions("language", "title"),
											onBlur: () => (onFocus.current = false),
										}}
										handleChange={e => {
											handleDataChanged();
											setManualBet({
												...manualBet,
												title: e,
											});
										}}
									/>
									<GeneralDropdown
										object={{
											name: "Bet Type",
											placeholder: "Type",
											id: "name",
											fieldName: "type",
											label: manualBet?.type,
											value: manualBet?.type,
											options: _manualBetTypes,
											required: true,
											disabled: disabledDropdDowns,
											addionalICon: true,
										}}
										handleChange={e => {
											setDataChanged(true);
											setManualBet({ ...manualBet, type: e?.id });
										}}
										handleRemove={() => {
											setManualBet({ ...manualBet, type: "" });
											setDataChanged(true);
										}}
									/>
									<GeneralTextArea
										object={{
											name: "Bet Description",
											placeholder: "",
											id: "name",
											value: manualBet.description,
											onFocus: () => handleShowOptions("language", "description"),
											onBlur: () => (onFocus.current = false),
											required: true,
										}}
										handleChange={e => {
											handleDataChanged();
											setManualBet({
												...manualBet,
												description: e,
											});
										}}
									/>
									<GeneralDropdown
										object={{
											name: "Sport Type",
											placeholder: "Choose Sport Type",
											id: "name",
											fieldName: "sport_slug",
											value: manualBet?.sport_slug,
											options: sports,
											disabled: disabledDropdDowns,
											required: true,
										}}
										handleChange={e => {
											handleDataChanged();
											setManualBet({
												...manualBet,
												sport_slug: e?.id,
												region_id: "",
												region_name: "",
												competition_id: "",
												competition_name: "",
												match_id: "",
												match_name: "",
												market_id: "",
												outcome_id: "",
												outcome_name: "",
											});
											setRegionsData([]);
											setAllCompetitions([]);
											setAllMatches([]);
										}}
										handleRemove={() => {
											handleDataChanged();
										}}
									/>

									<GeneralDropdown
										object={{
											name: "Region",
											placeholder: "Choose Region",
											id: "name",
											fieldName: "id",
											value: manualBet?.region_id,
											options: regionsData,
											disabled: disabledDropdDowns,
											required: true,
										}}
										handleChange={e => {
											handleDataChanged();
											setManualBet({
												...manualBet,
												region_name: e?.label,
												region_id: e?.id,
												competition_id: "",
												competition_name: "",
												match_id: "",
												match_name: "",
											});
											setAllCompetitions([]);
											setAllMatches([]);
										}}
										handleRemove={() => {
											handleDataChanged();
											setManualBet({ ...manualBet, region_id: "", region_name: "" });
										}}
									/>
									<GeneralDropdown
										object={{
											name: "Competition",
											placeholder: "Choose Competition",
											id: "name",
											fieldName: "id",
											value: manualBet?.competition_id,
											label: manualBet?.competition_id,
											options: allCompetitions,
											disabled: disabledDropdDowns,
											required: true,
										}}
										handleChange={e => {
											handleDataChanged();
											setManualBet({
												...manualBet,
												competition_id: e?.id,
												competition_name: e?.label,
												match_id: "",
												match_name: "",
											});
											setAllMatches([]);
										}}
										handleRemove={() => {
											handleDataChanged();
											setManualBet({ ...manualBet, competition_id: "", competition_name: "" });
										}}
									/>

									<GeneralDropdown
										object={{
											name: "Match",
											placeholder: "Choose Match",
											id: "name",
											fieldName: "id",
											value: manualBet?.match_id,
											options: allMatches,
											disabled: disabledDropdDowns,
										}}
										handleChange={e => {
											setManualBet({ ...manualBet, match_id: e?.id, match_name: e?.label });
											handleDataChanged();
											getMatchDates(e?.id);
										}}
										handleRemove={() => {
											handleDataChanged();
											setManualBet({ ...manualBet, match_id: "", match_name: "" });
										}}
									/>

									<GeneralDropdown
										object={{
											name: "Manual Market Type",
											id: "name",
											fieldName: "market_id",
											value: manualBet?.market_id,
											options: sportMarkets,
											disabled: disabledDropdDowns,
											required: true,
										}}
										handleChange={e => {
											setManualBet({ ...manualBet, market_id: e?.id, outcome_id: "", outcome_name: "" });
											handleDataChanged();
										}}
										handleRemove={() => {
											handleDataChanged();
											setManualBet({ ...manualBet, market_id: "", outcome_id: "", outcome_name: "" });
										}}
									/>

									<GeneralDropdown
										object={{
											name: "Outcome Type",
											id: "name",
											fieldName: "outcome_id",
											value: manualBet?.outcome_id,
											options: marketSelections,
											disabled: disabledDropdDowns,
											required: true,
										}}
										handleChange={e => {
											setManualBet({ ...manualBet, outcome_id: e?.id, outcome_name: e?.label });
											handleDataChanged();
										}}
										handleRemove={() => {
											handleDataChanged();
											setManualBet({ ...manualBet, outcome_id: "", outcome_name: "" });
										}}
									/>

									<GeneralDropdown
										object={{
											name: "Odd",
											placeholder: "Choose Decimal",
											id: "name",
											options: sportLadders.map(({ in_decimal }) => ({ id: in_decimal, label: in_decimal })),
											value: manualBet?.odd_decimal,
											required: true,
										}}
										handleChange={e => {
											setManualBet({ ...manualBet, odd_decimal: e?.id });
											handleDataChanged();
											changeDecimalValue(e?.label);
										}}
									/>

									<GeneralDropdown
										object={{
											name: "Fractional",
											placeholder: "Choose Fractional",
											id: "fractional",
											options: sportLadders.map(({ in_fraction }) => ({ id: in_fraction, label: in_fraction })),
											value: manualBet?.odd_fractional,
											required: true,
										}}
										handleChange={e => {
											setManualBet({ ...manualBet, odd_fractional: e?.id });
											handleDataChanged();
											changeFractionalValue(e?.label);
										}}
									/>

									<GeneralDatePicker
										object={{
											name: "Bet Start Date (UTC)",
											id: "name",
											value: betDates?.bet_start_date,
											disabled: disabledDates?.start_date,
											isTimeExist: true,
											formatDate: true,
											noFormattedDate: true,
											required: true,
											withUtc: true,
										}}
										handleChange={e => {
											handleDataChanged();
											setBetDates({ ...betDates, bet_start_date: e });
										}}
									/>

									<GeneralDatePicker
										object={{
											name: "Bet End Date (UTC)",
											id: "name",
											value: betDates?.bet_end_date,
											disabled: disabledDates?.end_date,
											isTimeExist: true,
											formatDate: true,
											noFormattedDate: true,
											required: true,
											withUtc: true,
										}}
										handleChange={e => {
											handleDataChanged();
											setBetDates({ ...betDates, bet_end_date: e });
										}}
									/>

									<GeneralDatePicker
										object={{
											name: "Display Start Date (UTC)",
											id: "name",
											value: displayStartDate,
											isTimeExist: true,
											formatDate: true,
											noFormattedDate: true,
											required: true,
											withUtc: true,
										}}
										handleChange={e => {
											handleDataChanged();
											setBetDates({ ...betDates });
											setDisplayStartDate(e);
										}}
									/>

									<GeneralDatePicker
										object={{
											name: "Display End Date (UTC)",
											id: "name",
											value: displayEndDate,
											isTimeExist: true,
											formatDate: true,
											noFormattedDate: true,
											required: true,
											withUtc: true,
										}}
										handleChange={e => {
											handleDataChanged();
											setDisplayEndDate(e);
										}}
									/>
									<StatusSwitch
										label={"Published"}
										data={manualBet}
										setData={setManualBet}
										handleDataChanged={handleDataChanged}
										checked_type="status"
										otherRowClass
									/>
								</AvForm>
							</div>
							<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
								{showOptions.language && (
									<LanguageTitle data={manualBet} setData={setManualBet} title={showOptions.language} handleDataChanged={handleDataChanged} />
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
