import React, { useEffect, useState } from "react";
import SearchFilter from "components/SearchFilter/searchFilter";
import { connect, useDispatch, useSelector } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { usersFilterColumns } from "./usersFilterColumns";
import { getUsersTableColumn } from "./usersTableColumns";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../components/HelmetLayout/HelmetLayout";
import { AddUserModal } from "./addUserModal";
import moment from "moment";
import MUIDataTable from "mui-datatables";

import { setUsers } from "store/Users/actions";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const SearchUsers = props => {
	const dispatch = useDispatch();

	// const userFilterData = useSelector(state => state.Login.searchFilterData.users);
	const usersInStore = useSelector(state => state.Users.users);
	const user = JSON.parse(localStorage.getItem("user"));

	const [rowPerPage, setRowPerPage] = useState(50);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const breadcrumbItems = [{ title: "Users", link: "/users" }];
	const countries = useSelector(state => state.Login.gsData.countries);
	const [records, setRecords] = useState([]);
	const [showAddModal, setShowAddModal] = useState(false);

	useEffect(() => {
		props.setBreadcrumbItems("Users", breadcrumbItems);
	});

	useEffect(() => {
		if (usersInStore) setRecords(usersInStore);
	}, []);

	let rc = [];

	const filterUsers = userFilterData => {
		const first_name = userFilterData.first_name || "";
		const last_name = userFilterData.last_name || "";
		const email = userFilterData?.email ? encodeURIComponent(userFilterData.email) : "";
		const player_id = userFilterData.player_id || "";
		const KYC = userFilterData.kyc || "";
		const account_status = userFilterData.account_status || "";
		setCurrentPage(0);
		var start_time = "";
		var end_time = "";
		setIsDataLoading(true);
		setRecords([]);
		if (userFilterData.date) {
			if (userFilterData.date.indexOf("to") > -1) {
				var user_created_date = userFilterData.date.split("to");
				start_time = moment(user_created_date[0].trim(), "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(user_created_date[1].trim(), "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss");
			} else {
				start_time = userFilterData.date.trim().split("&")[0];
				end_time = userFilterData.date.trim().split("&")[1];
			}
		}
		new Promise((resolve, reject) => {
			const url = `${apiUrls.users}?first_name=${first_name}&last_name=${last_name}&email=${email}&player_id=${player_id}&kyc_status=${KYC}&country=${userFilterData?.country}&created_at_start=${start_time}&created_at_end=${end_time}&account_status=${account_status}`;
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let data = response.data;
					data.map(user => {
						let obj = {
							...user,
							created_at: user.created_at !== null ? moment(new Date(user.created_at)).format("YYYY-MM-DD HH:mm:ss") : "null",
						};
						rc.push(obj);
					});
					setRecords(rc);
					dispatch(setUsers(rc));
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const page_name = "Users";
	let tableUsersColumns = getUsersTableColumn({ records, props, user });
	let filterColumns = usersFilterColumns({ countries });

	return (
		<HelmetLayout titleProps={"Users"}>
			{showAddModal && (
				<AddUserModal
					setShowAddModal={setShowAddModal}
					handler={() => {
						setShowAddModal(false);
					}}
				/>
			)}
			<div className="cms-page pt-0 cms-table-page">
				<div className="col-9 d-flex justify-content-between">
					<SearchFilter
						columns={filterColumns}
						page={"users"}
						preSelectedType={"text"}
						preSelectedValue={"player_id"}
						preSelectedName={"Player ID"}
						preSelectedData={""}
						preSelectedOptionKey={""}
						preSelectedOptionName={""}
						filterHandler={filterUsers}
						filterLoading={isDataLoading}
						customizedFilter={true}
					/>
				</div>

				<MUIDataTable
					onRowClick={(e, user) => {
						props.history.push(`/user/${user.id}`);
					}}
					columns={tableUsersColumns}
					data={records}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: "Report - " + page_name + ".csv" },
						customToolbar: () => <AddTableButton onClick={() => setShowAddModal(true)} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(SearchUsers);
