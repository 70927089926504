import React from "react";
import { ImageInput } from "components/GeneralComponents/CustomInputs";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { useState } from "react";
import { XCloseIcon } from "utils/icons";

const accept = ["image/png", "image/svg+xml"];

const ImageForStyleModal = ({ close, onChange, title, save, setData, data, loading }) => {
	const [imageLibrary, setImageLibrary] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [activImages, setactivImages] = useState({ desktop: false, mobile: false, solid: false });

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const handleImageUpload = (_, data) => {
		onChange(data?.image, imageLibrary);
	};

	const handleShowOptions = (type, imageType) => {
		if (type === "imageLibrary" && imageType === "desktop_logo") {
			setImageLibrary(imageType);
			setactivImages({ desktop: true, mobile: false, solid: false });
		} else if (type === "imageLibrary" && imageType === "mobile_logo") {
			setImageLibrary(imageType);
			setactivImages({ desktop: false, mobile: true, solid: false });
		} else if (type === "imageLibrary" && imageType === "solid_logo") {
			setImageLibrary(imageType);
			setactivImages({ desktop: false, mobile: false, solid: true });
		} else if (type === "imageLibrary" && !imageType) {
			setImageLibrary(false);
			setactivImages({ desktop: false, mobile: false });
		}
	};
	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={close} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={save} dataChanged={dataChanged} loading={loading} onClose={close} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						<ImageInput
							object={{
								name: "Desktop Logo",
								value: data.desktop_logo,
								fieldName: "desktop_logo",
								imageType: "desktop",
								active: activImages.desktop,
								onFocus: () => handleShowOptions("imageLibrary", "desktop_logo"),
								imageLibaryType: "logo",
								data: data,
								setData: setData,
								setDataChanged: setDataChanged,
								dimensions: "(240 x 50 px)",
								accept: accept,
							}}
						/>
						<ImageInput
							object={{
								name: "Mobile Logo",
								fieldName: "mobile_logo",
								value: data.mobile_logo,
								imageType: "mobile",
								active: activImages.mobile,
								onFocus: () => handleShowOptions("imageLibrary", "mobile_logo"),
								imageLibaryType: "logo",
								data: data,
								setData: setData,
								setDataChanged: setDataChanged,
								dimensions: "(80 x 80 px)",
								accept: accept,
								showFullImage: true,
							}}
						/>
						<ImageInput
							object={{
								name: "App Download Icon",
								fieldName: "solid_logo",
								value: data.solid_logo,
								imageType: "solid",
								active: activImages.solid,
								onFocus: () => handleShowOptions("imageLibrary", "solid_logo"),
								imageLibaryType: "logo",
								data: data,
								setData: setData,
								setDataChanged: setDataChanged,
								dimensions: "(80 x 80 px)",
								accept: accept,
							}}
						/>
					</div>
					<div className="promotion-right col-12 col-lg-6">
						{imageLibrary && (
							<ImageLibrary
								data={data}
								setData={handleImageUpload}
								handleDataChanged={handleDataChanged}
								type="logo"
								accept={accept}
								property={imageLibrary}
								handleShowOptions={handleShowOptions}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImageForStyleModal;
