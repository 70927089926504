import { React, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import { useState } from "react";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import CurrenciesModal from "./CurrenciesModal";
import { getCurrenciesUsersColumns } from "./CurrenciesTableColumn";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";

const Currencies = props => {
	const [showPromotion, setShowPromotion] = useState(false);
	const [currenciesData, setCurrencyData] = useState([]);
	const [editItem, setEditItem] = useState(null);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [unfilteredData, setUnfilteredData] = useState([]);

	const currencies = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_currencies, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					setCurrencyData(response?.data);
					setCurrencyData(response?.data);
					setUnfilteredData(response?.data);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};

	useEffect(() => {
		currencies();
	}, []);

	const editCurrency = payload => {
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.edit_currencies, resolve, reject, payload, true);
		}).then(() => {
			let updated = currenciesData.map(row => {
				if (row.id === payload.id) {
					return payload;
				} else {
					return row;
				}
			});
			setCurrencyData(updated);
		});
	};
	const currenciesUsersColumns = getCurrenciesUsersColumns({
		currenciesData,
		props,
		handleEditItem,
		setShowPromotion,
		editCurrency,
	});

	return (
		<HelmetLayout titleProps={"Currencies"}>
			<div className="cms-page pt-0">
				<SingleSearchFilter setData={setCurrencyData} unfilteredData={unfilteredData} filterColumn={["code", "name"]} />
				<MUIDataTable
					columns={currenciesUsersColumns}
					data={currenciesData}
					options={{
						...tableBarSettings,
						pagination: true,

						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						responsive: tableResponsive,
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
			{showPromotion && (
				<CurrenciesModal
					title={"Currencies"}
					editItem={editItem}
					currencies={currenciesData}
					setNewData={setCurrencyData}
					setShowPromotion={setShowPromotion}
					showPromotion={showPromotion}
					handler={() => {
						setShowPromotion(false);
						setEditItem(null);
					}}
				/>
			)}
		</HelmetLayout>
	);
};
export default Currencies;
