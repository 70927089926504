import {
	GeneralDatePicker,
	GeneralInput,
	ModalButton,
	SwitchInput,
	GeneralDropdown,
	PrefixSelector,
	ImageInput,
	GeneralTextArea,
	GeneralCheckbox,
	GeneralFilterDropdown,
	GeneralGridItems,
	MultipleInput,
} from "components/GeneralComponents/CustomInputs";
import React, { useEffect, useState } from "react";
import { getAffiliateTagsDropdown } from "services/getAffiliateTagsDropdown";
import { getCompetitionsDropdown } from "services/getCompetitionsDropdown";
import { getAllCasinoRestrictionDropdown } from "services/getAllCasinoRestrictionDropdown";
import { getCountriesDropdown } from "services/getCountriesDropdown";
import { getCurrenciesDropdown } from "services/getCurrenciesDropdown";
import { getLanguageDropdown } from "services/getLanguageDropdown";
import { getMatcheDropdown } from "services/getMatchesDropdown";
import { getPageContentDropdown } from "services/getPageContentDropdown";
import { getPhonePrefixDropdown } from "services/getPhonePrefixDropdown";
import { getLadders } from "services/getLaddersDropdown";
import {
	_LinkTypeOptions,
	_betTypesOptions,
	_daysOptions,
	_depositLimitTypes,
	_editResultMarketOptions,
	_gmtTimezones,
	_liabilitiesOptions,
	_eventPhaseType,
	_openTypeOptions,
	_paymentTypes,
	_playBreakTypes,
	_priceBoostOptions,
	_realityCheckTypes,
	_selfExcludedTypes,
	_suspendTypes,
	_transactionTypes,
	accountStatusOptions,
	ageVerifiedStatusOptions,
	defaultOddFormatData,
	kycStatusOptions,
	roleStaticData,
	_liabilitiesOptionsForSis,
	_turnRequirment,
	_casinoVolatilityOptions,
	_PageTypeOptions,
	_usageFreebetOptions,
	_nonTradingAdjustment,
	_tradingAdjusment,
	_usageMarketSortByOptions,
	_accountTypes,
	_showColumnsOptions,
	_affiliatePlayerTypes,
	_eventPhaseFreeBetType,
	_scheduleType,
} from "utils/constants";
import { Spinner } from "reactstrap";
import { getAllMenuLink } from "services/getAllMenuLinks";
import { getSportTiersDropdown } from "services/getSportTiersDropdown";
import { getMarketSportDropdown } from "services/getMarketSportDropdown";
import { getAllSportsDropdown } from "services/getAllSportsDropdown";
import { getAllCmsUsers } from "services/getAllCmsUsers";
import { getDeductionTypeDropdwon } from "services/getDeductionTypeDropdwon";
import { getCasinoThemesDropdown } from "services/getCasinoThemesDropdown";
import { getCasinoFeatureDropdown } from "services/getCasinoFeatureDropdown";
import { getCasinoGames } from "services/getCasinoGames";
import { getCasinoCatrgoryDropdown } from "services/getCasinoCategoryDropdown";
import { getAmountFreeBetDropdown } from "services/getAmountFreeBetDropdown";
import { getCasinoProviders } from "services/getCasinoProviders";
import { getAllMonthsAndYears, userReadOnly } from "utils/global";
import { getFreebetCasinoGames } from "services/getFreebetCasinoGames";
import { getAffiliateUserDropdown } from "services/getAffiliateUserDropdown";
import { getReportDropdown } from "services/getReportDropdow";

const AllComponentsLoop = ({ fields, data, setData, setDataChanged, dependecies, property }) => {
	const initialFieldsLength = fields.length;
	const [finished, setFinished] = useState(false);
	const [fieldsFilled, setFieldsFilled] = useState(fields);
	const [executeGetFunction, setExecuteGetFunction] = useState(false);

	async function getAllOptionsData() {
		const updatedFields = [...fields];

		for (let i = 0; i < updatedFields.length; i++) {
			const element = updatedFields[i];

			if (element?.optionsType) {
				element.options = await getOptions(element.optionsType, element.id, element.params);
				element.label = await getOptionName(element.optionsType, data[element.fieldName], element.params);
				updatedFields[i] = { ...element, options: element.options, label: element.label };
			}
			updatedFields[i] = element;
		}
		setFieldsFilled(updatedFields);
		setFinished(true);
		setExecuteGetFunction(false);
	}

	useEffect(() => {
		setExecuteGetFunction(true);
	}, [initialFieldsLength, dependecies]);

	useEffect(() => {
		if (executeGetFunction) {
			getAllOptionsData();
		}
	}, [executeGetFunction]);

	return finished ? (
		<AllComponentsItems fields={fieldsFilled} data={data} setData={setData} setDataChanged={setDataChanged} property={property} />
	) : (
		<div className="d-flex justify-content-center">
			<Spinner animation="border" size="lg" />
		</div>
	);
};
const AllComponentsItems = ({ fields, data, setData, setDataChanged, property }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	const handleChangeInput = (valueOfField, fieldName, type, link_type, emptyValues) => {
		const requiredElements = document.querySelectorAll("[required]");
		requiredElements.forEach(element => {
			if (element.value) {
				element.classList.remove("error_empty_input");
			}
		});

		const input = document.querySelectorAll('div[data-required="true"] .form-control');
		input.forEach(function (inputElement) {
			if (inputElement.value?.trim?.() !== "") {
				const parentDiv = inputElement.parentNode;
				parentDiv.classList.remove("error_empty_input");
			}
		});
		//Property is the name that is in an object description that updates the specific property within data
		if (property) {
			if (property == "deposit_limits") {
				const indexToUpdate = data[property].findIndex(limit => limit?.type === data?.type?.id);
				let newData = [...data[property]];
				if (indexToUpdate !== -1) {
					newData[indexToUpdate][fieldName] = valueOfField;
				}
				setData({ ...data, [property]: newData, [fieldName]: valueOfField?.id });
			} else if (
				(property === "reality_check" && fieldName === "frequency") ||
				((property === "play_break" || property === "self_excluded") && fieldName === "period")
			) {
				data[property][fieldName] = valueOfField?.id;
				setData(data);
			}
		} else if (type === "dropdown-multiple") {
			let newArray = data[fieldName] || [];

			newArray.push(valueOfField?.id);
			setData({ ...data, [fieldName]: newArray });
		} else if (type === "link_type") {
			let changeAdditionalValues = {};
			if (emptyValues?.length) {
				emptyValues.forEach(item => {
					changeAdditionalValues[item] = "";
				});
			}
			let linkUrl = `${link_type}${valueOfField?.id}`;
			setData({ ...data, [fieldName]: valueOfField?.id, link: linkUrl, ...changeAdditionalValues });
		} else if (type === "page_link") {
			// Convert the string to friendly url seo
			const id = valueOfField || "";
			let linkUrl = "/" + id.toLowerCase().replace(/ /g, "-");

			setData({ ...data, [fieldName]: valueOfField, link: linkUrl });
		} else if (type == "switch") {
			const newValue = valueOfField == false ? 0 : 1;

			let changeAdditionalValues = {};
			if (emptyValues?.length) {
				emptyValues.forEach(item => {
					changeAdditionalValues[item] = "";
				});
			}

			setData({ ...data, [fieldName]: newValue, ...changeAdditionalValues });
		} else if (type == "dropdown") {
			let changeAdditionalValues = {};
			if (emptyValues?.length) {
				emptyValues.forEach(item => {
					changeAdditionalValues[item] = "";
				});
			}
			setData({ ...data, [fieldName]: valueOfField?.id, ...changeAdditionalValues });
		} else setData({ ...data, [fieldName]: valueOfField });

		if (setDataChanged) setDataChanged(true);
	};

	const handleRemove = (row, fieldName, type) => {
		if (type === "array") {
			const updatedData = data[fieldName].filter(item => item !== row);
			setData({ ...data, [fieldName]: updatedData });
		} else {
			const updatedData = data[fieldName].filter(c => c !== row?.id);
			setData({ ...data, [fieldName]: updatedData });
		}

		setDataChanged(true);
	};

	const handleFilter = value => {
		setData({ ...data, filterDropdownValue: value });
	};

	const sanitizeSlug = (slug, pattern) => {
		return pattern.test(slug);
	};

	return (
		<>
			{fields.map((row, index) => {
				row.value = data?.[row?.fieldName];
				row.disabled = isUserReadOnly || row.disabled;

				if (row.type === "dropdown") {
					//This is to pass the values if its nested as a property into the data from response
					if (property && data[property]) {
						const fieldName = row?.fieldName;
						row.value = data[property]?.[fieldName] || "";
					}

					return (
						<div key={index}>
							<GeneralDropdown object={row} handleChange={handleChangeInput} handleRemove={handleRemove} />
						</div>
					);
				} else if (row.type === "filterDropdown") {
					return (
						<div key={index}>
							<GeneralFilterDropdown object={row} handleChange={handleChangeInput} handleFilter={handleFilter} handleRemove={handleRemove} />
						</div>
					);
				} else if (row.type === "date") {
					return (
						<div key={index}>
							<GeneralDatePicker object={row} handleChange={handleChangeInput} elementIndex={index} />
						</div>
					);
				} else if (row.type === "switch") {
					return (
						<div key={index}>
							<SwitchInput object={row} handleChange={handleChangeInput} />
						</div>
					);
				} else if (row.type === "button") {
					return (
						<div key={index}>
							<ModalButton object={row} />
						</div>
					);
				} else if (row.type === "input") {
					if (row?.pattern && sanitizeSlug(data?.[row?.fieldName], row.pattern)) {
						row.value = data?.[row?.fieldName];
						row.hasError = true;
					} else {
						row.value = data?.[row?.fieldName];
					}

					row.disabledCheckBox = isUserReadOnly || row.disabledCheckBox;
					return (
						<div key={index}>
							<GeneralInput object={row} handleChange={handleChangeInput} />
						</div>
					);
				} else if (row.type === "checkbox") {
					row.checked = data[row?.fieldName];
					return (
						<div key={index}>
							<GeneralCheckbox object={row} handleChange={handleChangeInput} />
						</div>
					);
				} else if (row.type === "image") {
					return (
						<div key={index}>
							<ImageInput object={row} />
						</div>
					);
				} else if (row.type === "empty_row") {
					return (
						row.name && (
							<div className={`${row.inputClassName} d-flex align-items-center justify-content-start gap-2`} key={index}>
								{row.name} <br /> <span className="optional">{row.optional}</span>
							</div>
						)
					);
				} else if (row.type === "textarea") {
					return (
						<div key={index}>
							<GeneralTextArea handleChange={handleChangeInput} object={row} />
						</div>
					);
				} else if (row.type === "phone_prefix") {
					row.phoneValue = data[row?.phoneField];
					return (
						<div key={index}>
							<PrefixSelector handleChange={handleChangeInput} object={row} />
						</div>
					);
				} else if (row.type === "grid") {
					return (
						<div key={index}>
							<GeneralGridItems object={row} />
						</div>
					);
				} else if (row.type === "multiple_input") {
					return (
						<div key={index}>
							<MultipleInput object={row} handleRemove={handleRemove} />
						</div>
					);
				} else if (row.type === "extra_componnet") {
					return <div key={index}>{row.children()}</div>;
				} else {
					return <p key={index}>No component for this type</p>;
				}
			})}
		</>
	);
};

const getOptionName = async (type, value, params) => {
	let return_data;
	let filtreted;
	if (type == "defaultOddFormat") {
		return_data = defaultOddFormatData;
	} else if (type == "currency") {
		return_data = await getCurrenciesDropdown();
	} else if (type == "country") {
		return_data = await getCountriesDropdown();
	} else if (type == "languages") {
		return_data = await getLanguageDropdown();
	} else if (type == "accountStatus") {
		return_data = accountStatusOptions;
	} else if (type === "kycStatus") {
		return_data = kycStatusOptions;
	} else if (type == "ageVerification") {
		return_data = ageVerifiedStatusOptions;
	} else if (type == "phonePrefixes") {
		return_data = await getPhonePrefixDropdown();
	} else if (type == "affiliateUsers") {
		return_data = await getAffiliateTagsDropdown();
	} else if (type == "role") {
		return_data = roleStaticData;
	} else if (type == "depositLimit") {
		return_data = _depositLimitTypes;
	} else if (type == "realityCheck") {
		return_data = _realityCheckTypes;
	} else if (type == "playBreak") {
		return_data = _playBreakTypes;
	} else if (type == "selfExclude") {
		return_data = _selfExcludedTypes;
	} else if (type == "suspendTypes") {
		return_data = _suspendTypes;
	} else if (type == "bet_types") {
		return_data = _betTypesOptions;
	} else if (type == "open_type") {
		return_data = _openTypeOptions;
	} else if (type == "page_type") {
		return_data = _PageTypeOptions;
	} else if (type == "link_type") {
		return_data = _LinkTypeOptions;
	} else if (type == "allSports") {
		return_data = await getAllSportsDropdown();
	} else if (type == "casinoRestriction") {
		//TODO
	} else if (type == "event") {
		return_data = await getMatcheDropdown(params);
	} else if (type == "event_phase_type") {
		return_data = _eventPhaseType;
	} else if (type == "event_phase_free_bet") {
		return_data = _eventPhaseFreeBetType;
	} else if (type == "transactionTypes") {
		return_data = _transactionTypes;
	} else if (type == "paymentTypes") {
		return_data = _paymentTypes;
	} else if (type == "nonTradingAdjustment") {
		return_data = _nonTradingAdjustment;
	} else if (type == "tradingAdjustment") {
		return_data = _tradingAdjusment;
	} else if (type == "days") {
		return_data = _daysOptions;
	} else if (type == "deduction_types") {
		return_data = await getDeductionTypeDropdwon();
	} else if (type == "timezone") {
		return_data = _gmtTimezones;
	} else if (type == "liabilities_type") {
		return_data = _liabilitiesOptions;
	} else if (type == "liabilities_type_sis") {
		return_data = _liabilitiesOptionsForSis;
	} else if (type == "tier_type") {
		return_data = await getSportTiersDropdown(params);
	} else if (type == "market_sport_type") {
		return_data = await getMarketSportDropdown(params);
	} else if (type == "competitions") {
		return_data = await getCompetitionsDropdown(params);
	} else if (type == "result_market") {
		if (params === "golf") {
			let a = [..._editResultMarketOptions];
			a.push({ id: "placed", label: "Placed" });
			return_data = a;
		} else {
			return_data = _editResultMarketOptions;
		}
	} else if (type == "turnRequirement") {
		return_data = _turnRequirment;
	} else if (type == "accountManager") {
		return_data = await getAllCmsUsers();
	} else if (type == "ladders") {
		return_data = await getLadders();
	} else if (type == "casino_volatility") {
		return_data = _casinoVolatilityOptions;
	} else if (type == "casino_themes") {
		return_data = await getCasinoThemesDropdown();
	} else if (type == "casino_feature") {
		return_data = await getCasinoFeatureDropdown();
	} else if (type == "casino_category") {
		return_data = await getCasinoCatrgoryDropdown();
	} else if (type == "usage_freebet_type") {
		return_data = _usageFreebetOptions;
	} else if (type == "market_sort_by") {
		return_data = _usageMarketSortByOptions;
	} else if (type == "accountType") {
		return_data = _accountTypes;
	} else if (type == "show_handicap_columns") {
		return_data = _showColumnsOptions;
	} else if (type == "affiliatePlayerType") {
		return_data = _affiliatePlayerTypes;
	} else if (type == "affiliateUserType") {
		return_data = await getAffiliateUserDropdown(params);
	} else if (type == "schedule_types") {
		return_data = _scheduleType;
	} else if (type == "report_type") {
		return_data = getReportDropdown();
	}

	if (return_data) {
		filtreted = return_data.filter(row => row.id == value);
	}

	return filtreted && filtreted.length > 0 ? filtreted[0]?.label : "";
};

const getOptions = async (type, value, params) => {
	let return_data;
	if (type === "defaultOddFormat") {
		return_data = defaultOddFormatData;
	} else if (type == "accountStatus") {
		return_data = accountStatusOptions;
	} else if (type === "currency") {
		return_data = await getCurrenciesDropdown();
	} else if (type === "country") {
		return_data = await getCountriesDropdown();
	} else if (type === "languages") {
		return_data = await getLanguageDropdown();
	} else if (type === "affiliateUsers") {
		return_data = await getAffiliateTagsDropdown();
	} else if (type === "kycStatus") {
		return_data = kycStatusOptions;
	} else if (type == "ageVerification") {
		return_data = ageVerifiedStatusOptions;
	} else if (type == "phonePrefixes") {
		return_data = await getPhonePrefixDropdown();
	} else if (type == "role") {
		return_data = roleStaticData;
	} else if (type == "depositLimit") {
		return_data = _depositLimitTypes;
	} else if (type == "realityCheck") {
		return_data = _realityCheckTypes;
	} else if (type == "playBreak") {
		return_data = _playBreakTypes;
	} else if (type == "selfExclude") {
		return_data = _selfExcludedTypes;
	} else if (type == "suspendTypes") {
		return_data = _suspendTypes;
	} else if (type == "bet_types") {
		return_data = _betTypesOptions;
	} else if (type == "open_type") {
		return_data = _openTypeOptions;
	} else if (type == "page_content") {
		return_data = await getPageContentDropdown();
	} else if (type == "page_type") {
		return_data = _PageTypeOptions;
	} else if (type == "link_type") {
		return_data = _LinkTypeOptions;
	} else if (type == "game") {
		return_data = await getCasinoGames(value, params);
	} else if (type == "allSports") {
		return_data = await getAllSportsDropdown(value);
	} else if (type == "competitions") {
		return_data = await getCompetitionsDropdown(value);
	} else if (type == "event") {
		return_data = await getMatcheDropdown(value);
	} else if (type == "casinoRestriction") {
		return_data = await getAllCasinoRestrictionDropdown();
	} else if (type == "transactionTypes") {
		return_data = _transactionTypes;
	} else if (type == "paymentTypes") {
		return_data = _paymentTypes;
	} else if (type == "nonTradingAdjustment") {
		return_data = _nonTradingAdjustment;
	} else if (type == "tradingAdjustment") {
		return_data = _tradingAdjusment;
	} else if (type == "days") {
		return_data = _daysOptions;
	} else if (type == "page_types") {
		return_data = await getAllMenuLink();
	} else if (type == "deduction_types") {
		return_data = await getDeductionTypeDropdwon();
	} else if (type == "timezone") {
		return_data = _gmtTimezones;
	} else if (type == "event_phase_type") {
		return_data = _eventPhaseType;
	} else if (type == "event_phase_free_bet") {
		return_data = _eventPhaseFreeBetType;
	} else if (type == "market_sport_type") {
		return_data = await getMarketSportDropdown(value);
	} else if (type == "liabilities_type") {
		return_data = _liabilitiesOptions;
	} else if (type == "liabilities_type_sis") {
		return_data = _liabilitiesOptionsForSis;
	} else if (type == "price_boost") {
		return_data = _priceBoostOptions;
	} else if (type == "tier_type") {
		return_data = await getSportTiersDropdown(value);
	} else if (type == "result_market") {
		if (params === "golf") {
			let a = [..._editResultMarketOptions];
			a.push({ id: "placed", label: "Placed" });
			return_data = a;
		} else {
			return_data = _editResultMarketOptions;
		}
	} else if (type == "accountManager") {
		return_data = await getAllCmsUsers();
	} else if (type == "turnRequirement") {
		return_data = _turnRequirment;
	} else if (type == "ladders") {
		return_data = await getLadders();
	} else if (type == "region") {
		let region = JSON.parse(localStorage.getItem("gsData"));
		region = region?.regions.map(row => {
			return { id: row.code, label: row.name };
		});
		return_data = region;
	} else if (type == "casino_volatility") {
		return_data = _casinoVolatilityOptions;
	} else if (type == "casino_themes") {
		return_data = await getCasinoThemesDropdown();
	} else if (type == "casino_feature") {
		return_data = await getCasinoFeatureDropdown();
	} else if (type == "casino_category") {
		return_data = await getCasinoCatrgoryDropdown(value);
	} else if (type == "usage_freebet_type") {
		return_data = _usageFreebetOptions;
	} else if (type == "amount_freebet_type") {
		return_data = await getAmountFreeBetDropdown(value, params);
	} else if (type == "casino_provider") {
		return_data = await getCasinoProviders(value);
	} else if (type == "market_sort_by") {
		return_data = _usageMarketSortByOptions;
	} else if (type == "show_handicap_columns") {
		return_data = _showColumnsOptions;
	} else if (type == "free_bet_casino_games") {
		return_data = await getFreebetCasinoGames(value, params);
	} else if (type == "accountType") {
		return_data = _accountTypes;
	} else if (type == "affiliatePlayerType") {
		return_data = _affiliatePlayerTypes;
	} else if (type == "affiliateUserType") {
		return_data = await getAffiliateUserDropdown(params);
	} else if (type == "generateMonthAndYear") {
		return_data = getAllMonthsAndYears(params);
	} else if (type == "schedule_types") {
		return_data = _scheduleType;
	} else if (type == "report_type") {
		return_data = getReportDropdown();
	}

	return return_data;
};

export default AllComponentsLoop;
