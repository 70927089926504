import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { formatToLocalDatetime } from "hooks/General";
import { getPoliciesTableColumns } from "./policiesTableColumns";
import { ShowDeletePoliciesModal } from "./showDeletePoliciesModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import getPolicy from "services/getPolicy";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const Policies = props => {
	const [isLoading, setIsLoading] = useState(false);
	const [spinningStatus, setSpinningStatus] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [records, setRecords] = useState([]);
	const [deleteId, setDeleteId] = useState("");
	const [currentPage, setCurrentPage] = useState(0);
	const breadcrumbItems = [{ title: "Policies", link: "/policies" }];
	const pageHeaderData = {
		international: false,
		addNew: true,
		addNewClick: () => {
			window.location.href = "/policy/new";
		},
	};

	useEffect(() => {
		props.setBreadcrumbItems("Competitions", breadcrumbItems);
	});

	const filterPolicy = () => {
		setIsLoading(true);
		setCurrentPage(0);
		getPolicy()
			.then(response => {
				if (response) {
					let data = response;
					const arr = [];

					data.map(policy => {
						if (policy.latest == 0) {
							return;
						}

						var insertedUtcFromDB = moment(policy?.insertedDt).utc().format("YYYY-MM-DD HH:mm:ss");
						var insertedLocalTime = formatToLocalDatetime(insertedUtcFromDB);
						var insertedDt = moment(insertedLocalTime).format("YYYY-MM-DD HH:mm:ss");
						var updatedUtcFromDB = moment(policy?.updatedDt).utc().format("YYYY-MM-DD HH:mm:ss");
						var updatedLocalTime = formatToLocalDatetime(updatedUtcFromDB);
						var updatedDt = moment(updatedLocalTime).format("YYYY-MM-DD HH:mm:ss");
						var country_name = policy.name != null ? policy.name : policy?.country?.toUpperCase();

						let highestVersion = data[0].version;

						data.forEach(term => {
							const currentVersion = term.version;
							if (currentVersion > highestVersion) {
								highestVersion = currentVersion;
							}
						});

						let version = policy.version;

						if (policy.version === highestVersion) {
							version += " (Latest)";
						}

						let objIndex = arr.findIndex(obj => obj.country === country_name);

						if (objIndex !== -1 && policy.language !== policy.country) {
							if (!arr[objIndex].languages.includes(policy.language)) {
								arr[objIndex].languages.push(policy.language);
							}
						} else {
							let obj = {
								id: policy.id,
								version: version,
								content: policy.content,
								insertedDt: insertedDt,
								updatedDt: updatedDt,
								languages: [policy.language],
								country: country_name,
								latest: policy.latest,
								country_disabled: policy.country_disabled,
							};
							arr.push(obj);
						}
					});
					arr.sort((a, b) => a.version.localeCompare(b.version));
					setRecords(arr);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const submitDeletePolicy = () => {
		setSpinningStatus(true);
		let payload = {
			id: deleteId,
			type: "policy",
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.general_delete, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) {
					setShowDeleteModal(false);
					var currentVariable = records.filter(x => x.id != deleteId);
					setRecords(currentVariable);
					filterPolicy();
				}
			})
			.finally(() => {
				setSpinningStatus(false);
			});
	};

	useEffect(() => {
		filterPolicy();
	}, []);

	let policiesTableColumns = getPoliciesTableColumns({ props, records, setShowDeleteModal, setDeleteId });
	let page_name = "Policies";

	return (
		<HelmetLayout titleProps={"Policies"}>
			<ShowDeletePoliciesModal
				showDeleteModal={showDeleteModal}
				setModalShow={setShowDeleteModal}
				submitDeletePolicy={submitDeletePolicy}
				spinningStatus={spinningStatus}
			/>
			<div className="cms-page no-search-filter">
				<MUIDataTable
					columns={policiesTableColumns}
					data={records}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						textLabels: {
							body: {
								noMatch: isLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: `${page_name}.csv` },
						customToolbar: () => <AddTableButton onClick={pageHeaderData["addNewClick"]} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};
export default connect(null, { setBreadcrumbItems })(Policies);
