import React from "react";
import { useEffect, useState } from "react";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { DarkArrowLeft, SimpleGoBackArrow } from "utils/icons";
import MUIDataTable from "mui-datatables";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { getSelectionMarketTableColumns } from "./marketTableColumns";
import { EditMarketMatchSelection } from "./EditMarketMatchSelection";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import { PlusAddButton } from "components/GeneralComponents/AddButton";
import ManualSelection from "./ManualSelection";
import { SwitchInput } from "components/GeneralComponents/CustomInputs";
import { tableBarSettings } from "utils/tableSettings";

const initialSelectionData = {
	status_all: 1,
	display_all: 1,
};

const formatEwTerms = item => {
	return `${item?.each_way_places}/${item?.each_way_terms}`;
};

export const EditMarketMatch = ({ marketMatchesView, sportId, sportSlug, getQueryParam }) => {
	const history = useHistory();
	const location = useLocation();
	// const title = addMarketMatch === "add" ? "Add New Market" : addMarketEventData.market_name;
	const [marketMatchColumnsData, setMarketMatchColumnsData] = useState([]);
	const [ladders, setLadders] = useState([]);
	const [editMarketSelections, setEditMarketSelections] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [addMarketEventData, setAddMarketEventData] = useState({
		match_id: "",
		allow_telebetting: 0,
		display: 0,
		name: "",
		suspended_time: "",
		market_id: "",
		bet_delay: "",
		inplay: false,
		bet_referral: false,
		singles_only: false,
		liabilities: "pre_match",
		pm_max_bet: 0,
		pm_max_win: 0,
		// pm_max_multiple_bet: 0,
		pm_liability_limit: 0,
		ip_max_bet: 0,
		ip_max_win: 0,
		ip_max_multiple_bet: 0,
		ip_liability_limit: 0,
	});

	const [editAllSelections, setEditAllSelections] = useState(initialSelectionData);
	const [editMarketSelectionData, setEditMarketSelectionData] = useState({});
	const [editMarketOrSelection, setEditMarketOrSelection] = useState({ market: false, selections: { status_all: false, display_all: false } });
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingSelection, setIsLoadingSelection] = useState(false);
	const [manualSelectionType, setManualSelectionType] = useState("");
	const [manualMarketSelections, setManualMarketSelections] = useState([]);

	const title = addMarketEventData?.market_name;
	const hasPositions = sportSlug === "horseracing" || sportSlug === "greyhoundracing";

	let isRaceWinner = hasPositions ? addMarketEventData?.market_id === "c1" : true;
	isRaceWinner = addMarketEventData?.market_id === "manual" ? false : isRaceWinner;

	const getSelectionMarket = (market_id, match_id) => {
		setIsLoadingSelection(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.event_market_selections + `?market_id=${market_id}&match_id=${match_id}&sport_slug=${sportSlug}`, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;
				setMarketMatchColumnsData(data);
			})
			.finally(() => {
				setIsLoadingSelection(false);
			});
	};

	const getManualMarketSelections = market_id => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.market_selection + "?market_id=" + market_id, resolve, reject, true);
		}).then(response => {
			if (response.success) {
				const { data } = response;
				setManualMarketSelections(data);
			}
		});
	};

	const getMarketData = (market_id, match_id) => {
		setIsLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.event_market + `?market_id=${market_id}&match_id=${match_id}&sport_slug=${sportSlug}`, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;
				let formatedData = { ...data };

				if (data?.ew_options) {
					formatedData.ew_options = data?.ew_options?.map(ow => ({ id: formatEwTerms(ow), label: formatEwTerms(ow) }));
					formatedData.ew_terms = data?.each_way_places ? formatEwTerms(data) : formatEwTerms(data?.ew_options[0]);
				}

				setAddMarketEventData({ ...formatedData });
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		const market_id = getQueryParam(history.location.search, "market_id");

		if (market_id && sportSlug) {
			const { pathname } = location;
			const match_id = pathname.split("/")[2];
			getSelectionMarket(market_id, match_id);
			getMarketData(market_id, match_id);

			getManualMarketSelections(market_id);
		}
	}, [sportSlug]);

	useEffect(() => {
		if (sportSlug) {
			getAllLaders(sportSlug);
		}
	}, [sportSlug]);

	useEffect(() => {
		if (marketMatchColumnsData.length) {
			const notActiveAll = marketMatchColumnsData.every(({ active }) => active == 0);
			const notDisplayAll = marketMatchColumnsData.every(({ display }) => display == 0);

			setEditAllSelections({
				status_all: notActiveAll ? 0 : 1,
				display_all: notDisplayAll ? 0 : 1,
			});
		}
	}, [marketMatchColumnsData]);

	let marketTableColumns = getSelectionMarketTableColumns({
		marketMatchColumnsData,
		setEditMarketSelections,
		setEditMarketSelectionData,
		isRaceWinner,
		setManualSelectionType,
	});

	const handleChangeDropdown = newValue => {
		setAddMarketEventData({
			...addMarketEventData,
			market_name: newValue?.label,
			market_id: newValue?.id,
		});
	};
	const [fields, setfields] = useState([]);

	useEffect(() => {
		let newFields = [];
		if (addMarketEventData?.market_id) {
			const { default_pre_match_liabilities, default_in_play_liabilities } = addMarketEventData;

			newFields.push(
				{
					group: "others",
					type: "dropdown",
					name: "Name",
					fieldName: "market_id",
					id: sportSlug,
					params: sportSlug,
					optionsType: "market_sport_type",
					handleChangeDropdown: handleChangeDropdown,
					bindValue: addMarketEventData?.market_name,
					disabled: true,
				},
				{ group: "others", type: "date", name: "Suspended Time", fieldName: "suspended_time", isTimeExist: true },
				{ group: "others", type: "input", name: "Market Id", fieldName: "market_id", readOnly: true, copyIcon: true, customLink: true }
			);
			!hasPositions && newFields.push({ group: "others", type: "switch", name: "In Play", fieldName: "inplay" });
			!hasPositions && newFields.push({ group: "others", type: "switch", name: "Bet referral", fieldName: "bet_referral" });
			!hasPositions && newFields.push({ group: "others", type: "switch", name: "Singles only", fieldName: "singles_only" });

			newFields.push(
				{
					group: "others",
					type: "empty_row",
					name: "Pre Match Limits",
					inputClassName: "medium-custum-input",
					optional: `(${default_pre_match_liabilities?.tier_name})`,
				},
				{ group: "others", type: "input", name: "Max bet", fieldName: "pm_max_bet", resetValue: default_pre_match_liabilities?.max_bet },
				{ group: "others", type: "input", name: "Max win", fieldName: "pm_max_win", resetValue: default_pre_match_liabilities?.max_win },
				// {
				// 	group: "others",
				// 	type: "input",
				// 	name: "Max multiple bet",
				// 	fieldName: "pm_max_multiple_bet",
				// 	resetValue: default_pre_match_liabilities?.multiple_max_bet,
				// },
				{
					group: "others",
					type: "input",
					name: "Liability Limit",
					fieldName: "pm_liability_limit",
					resetValue: default_pre_match_liabilities?.liability_limit,
				}
			);

			!hasPositions &&
				newFields.push(
					{
						group: "others",
						type: "empty_row",
						name: "In Play Limits",
						inputClassName: "medium-custum-input",
						optional: `(${default_in_play_liabilities?.tier_name})`,
					},
					{ group: "others", type: "input", name: "Max bet", fieldName: "ip_max_bet", resetValue: default_in_play_liabilities?.max_bet },
					{ group: "others", type: "input", name: "Max win", fieldName: "ip_max_win", resetValue: default_in_play_liabilities?.max_win },
					// {
					// 	group: "others",
					// 	type: "input",
					// 	name: "Max multiple bet",
					// 	fieldName: "ip_max_multiple_bet",
					// 	resetValue: default_in_play_liabilities?.multiple_max_bet,
					// },
					{
						group: "others",
						type: "input",
						name: "Liability Limit",
						fieldName: "ip_liability_limit",
						resetValue: default_in_play_liabilities?.liability_limit,
					}
				);

			hasPositions &&
				newFields.push(
					{ group: "others", type: "empty_row", name: "Each Way", inputClassName: "fs-5 pt-4" },
					{ group: "others", type: "switch", name: "Manual Places", fieldName: "is_manual_each_way" },
					{
						group: "others",
						type: "input",
						numbersOnly: true,
						name: "Places",
						fieldName: "each_way_places",
						readOnly: !addMarketEventData?.is_manual_each_way,
					},
					{
						group: "others",
						type: "input",
						numbersOnly: true,
						name: "Place reduction",
						fieldName: "each_way_terms",
						readOnly: !addMarketEventData?.is_manual_each_way,
					},
					{
						group: "others",
						type: "switch",
						name: "Update EW terms on open bets",
						fieldName: "each_way_update_open_bets",
						disabled: addMarketEventData?.is_manual_each_way ? false : true,
					}
				);

			addMarketEventData?.ew_options?.length > 0 &&
				newFields.push(
					{ group: "others", type: "empty_row", name: "Each Way", inputClassName: "fs-5 pt-4" },
					{
						group: "others",
						type: "dropdown",
						name: "Ew Terms",
						fieldName: "ew_terms",
						options: addMarketEventData?.ew_options,
					}
				);

			setfields(newFields);
		}
	}, [addMarketEventData?.market_id, sportId, addMarketEventData?.is_manual_each_way]);

	const dependecies = {
		max_bet: addMarketEventData?.max_bet,
		max_win: addMarketEventData?.max_win,
		// max_multiple_bet: addMarketEventData?.max_multiple_bet,
		liability_limit: addMarketEventData?.liability_limit,
	};

	useEffect(() => {
		if (addMarketEventData?.market_id) {
			const { default_pre_match_liabilities, default_in_play_liabilities } = addMarketEventData;

			setAddMarketEventData({
				...addMarketEventData,
				pm_max_bet: addMarketEventData?.pm_max_bet || default_pre_match_liabilities?.max_bet,
				pm_max_win: addMarketEventData?.pm_max_win || default_pre_match_liabilities?.max_win,
				// pm_max_multiple_bet: addMarketEventData?.pm_max_multiple_bet || default_pre_match_liabilities?.multiple_max_bet,
				pm_liability_limit: addMarketEventData?.pm_liability_limit || default_pre_match_liabilities?.liability_limit,

				...(hasPositions
					? {}
					: {
							ip_max_bet: addMarketEventData?.ip_max_bet || default_in_play_liabilities?.max_bet,
							ip_max_win: addMarketEventData?.ip_max_win || default_in_play_liabilities?.max_win,
							// ip_max_multiple_bet: addMarketEventData?.ip_max_multiple_bet || default_in_play_liabilities?.multiple_max_bet,
							ip_liability_limit: addMarketEventData?.ip_liability_limit || default_in_play_liabilities?.liability_limit,
					  }),
			});
		}
	}, [addMarketEventData?.market_id, hasPositions]);

	let othersFields = fields.filter(row => row?.group === "others");

	const handleBack = () => {
		const { pathname } = history;

		history.push({
			pathname,
			search: "?tab=1",
		});
	};

	const getAllLaders = id => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_ladder + `${id}`, resolve, reject, true);
		}).then(response => {
			const data = response.data;
			setLadders(data);
		});
	};

	const submitEditMarketType = () => {
		const { pathname } = location;
		const match_id = pathname.split("/")[2];

		const { default_pre_match_liabilities, default_in_play_liabilities } = addMarketEventData;

		let payload = {
			allow_telebetting: addMarketEventData.active,
			status: addMarketEventData.active,
			display: addMarketEventData.display,
			name: addMarketEventData.market_name,
			market_id: addMarketEventData?.market_id,
			bet_delay: addMarketEventData.bet_delay,
			inplay: addMarketEventData.inplay,
			suspended_time: addMarketEventData.suspended_time,
			bet_referral: addMarketEventData.bet_referral,
			singles_only: addMarketEventData.singles_only,
			match_id,
			sport_slug: sportSlug,
			is_manual_each_way: addMarketEventData.is_manual_each_way,
			each_way_places: addMarketEventData.each_way_places,
			each_way_terms: addMarketEventData.each_way_terms,
			each_way_update_open_bets: addMarketEventData.each_way_update_open_bets,
		};

		if (addMarketEventData?.ew_terms) {
			payload.each_way_places = addMarketEventData?.ew_terms.split("/")[0];
			payload.each_way_terms = addMarketEventData?.ew_terms.split("/")[1];
		}

		payload = {
			...payload,
			pm_max_bet: +addMarketEventData.pm_max_bet == +default_pre_match_liabilities?.max_bet ? null : +addMarketEventData.pm_max_bet,
			pm_max_win: +addMarketEventData.pm_max_win == +default_pre_match_liabilities?.max_win ? null : +addMarketEventData.pm_max_win,
			// pm_max_multiple_bet: addMarketEventData.pm_max_multiple_bet,
			pm_liability_limit:
				+addMarketEventData.pm_liability_limit == +default_pre_match_liabilities?.liability_limit ? null : +addMarketEventData.pm_liability_limit,
			ip_max_bet: +addMarketEventData.ip_max_bet == +default_in_play_liabilities?.max_bet ? null : +addMarketEventData.ip_max_bet,
			ip_max_win: +addMarketEventData.ip_max_win == +default_in_play_liabilities?.max_win ? null : +addMarketEventData.ip_max_win,
			// ip_max_multiple_bet: addMarketEventData.ip_max_multiple_bet,
			ip_liability_limit:
				+addMarketEventData.ip_liability_limit == +default_in_play_liabilities?.liability_limit ? null : +addMarketEventData.ip_liability_limit,
		};

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.save_event_market, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);

				// update the market table with new data
				marketMatchesView("all", match_id, sportSlug);
				// setAddMarketMatch(false);
			})
			.finally(() => {
				// setEditMarketMatch(false);
			});
	};

	const submitEditAllSelections = () => {
		const { pathname } = location;
		const match_id = pathname.split("/")[2];
		const { market_id } = addMarketEventData;
		const {
			selections: { status_all, display_all },
		} = editMarketOrSelection;

		let payloadActive = {
			market_id,
			match_id,
			active: editAllSelections.status_all,
			sport_slug: sportSlug,
		};

		let payloadDisplay = {
			market_id,
			match_id,
			display: editAllSelections.display_all,
			sport_slug: sportSlug,
		};

		if (status_all)
			new Promise((resolve, reject) => {
				ApiServices.put(apiUrls.market_active, resolve, reject, payloadActive, true);
			}).then(() => {
				setDataChanged(false);
				getSelectionMarket(market_id, match_id);
				// setAddMarketMatch(false);
			});

		if (display_all)
			new Promise((resolve, reject) => {
				ApiServices.put(apiUrls.market_display, resolve, reject, payloadDisplay, true);
			}).then(() => {
				setDataChanged(false);
				getSelectionMarket(market_id, match_id);
				// setAddMarketMatch(false);
			});
		setLoading(false);
	};

	const handleSave = () => {
		const { market, selections } = editMarketOrSelection;
		if (market) submitEditMarketType();
		if (selections.status_all || selections.display_all) submitEditAllSelections();
		setEditMarketOrSelection({ market: false, selections: { status_all: false, display_all: false } });
	};

	const onMarketSettingsEdit = (val, id) => {
		setAddMarketEventData({ ...addMarketEventData, [id]: val });
		setEditMarketOrSelection({ ...editMarketOrSelection, market: true });
		setDataChanged(true);
	};

	const onSelectionAllEdit = (val, id) => {
		const newSelectionData = {};

		setAddMarketEventData({ ...addMarketEventData, [id]: val });
		if (val != editAllSelections[id]) newSelectionData[id] = true;
		setEditAllSelections({ ...editAllSelections, [id]: val });

		setEditMarketOrSelection({
			...editMarketOrSelection,
			selections: { ...editMarketOrSelection.selections, ...newSelectionData },
		});

		setMarketMatchColumnsData(
			marketMatchColumnsData.map(columnData => {
				return {
					...columnData,
					[id === "status_all" ? "active" : "display"]: val ? 1 : 0,
				};
			})
		);

		setDataChanged(true);
	};

	return (
		<div className="white-container">
			<TopBarComponent classname="special_top_component" saveHandler={handleSave} dataChanged={dataChanged} hasGoBackArrow={false} loading={loading} />
			{isLoading ? (
				<div className="text-center edit-market-match-loader">
					<Spinner animation="border" size={"lg"} />
				</div>
			) : (
				<>
					<div className="matches-title">
						<SimpleGoBackArrow onClick={() => handleBack()} className="go-back-mobile" />
						<DarkArrowLeft onClick={() => handleBack()} className="go-back-desktop" />
						<span className="edit-market-title">{title}</span>
					</div>

					<div className="tabs-tabpanel row matches-container">
						<div className="col-12 col-lg-5 matches-left">
							<div className="d-md-flex flex-wrap justify-content-between gap-3">
								<div className="align-items-center fs-5 w-auto">Settings</div>
								<div className="d-md-flex flex-wrap gap-2">
									<SwitchInput
										object={{
											name: "Telebet",
											value: addMarketEventData.active,
											switchTitleClassName: "w-auto pe-2",
											wrapperClassName: "pt-md-0 mt-md-0",
										}}
										handleChange={val => onMarketSettingsEdit(val, "active")}
									/>
									<SwitchInput
										object={{
											name: "Display",
											value: addMarketEventData.display,
											switchTitleClassName: "w-auto pe-2",
											wrapperClassName: "pt-md-0 mt-md-0",
										}}
										handleChange={val => onMarketSettingsEdit(val, "display")}
									/>
								</div>
							</div>
							<AllComponentsLoop
								fields={othersFields}
								data={addMarketEventData}
								setData={setAddMarketEventData}
								setDataChanged={value => {
									setDataChanged(value);
									setEditMarketOrSelection({ ...editMarketOrSelection, market: true });
								}}
								dependecies={dependecies}
							/>
						</div>
						<div className="col-12 col-lg-7 matches-right">
							<div className="d-md-flex flex-wrap justify-content-between gap-3 mb-3">
								<div className="align-items-center fs-5 w-auto">Selections</div>
								<div className="d-md-flex flex-wrap gap-2">
									<SwitchInput
										object={{
											name: "Telebet All",
											value: editAllSelections.status_all,
											switchTitleClassName: "w-auto pe-2",
											wrapperClassName: "pt-md-0 mt-md-0",
										}}
										handleChange={val => onSelectionAllEdit(val, "status_all")}
									/>
									<SwitchInput
										object={{
											name: "Display All",
											value: editAllSelections.display_all,
											switchTitleClassName: "w-auto pe-2",
											wrapperClassName: "pt-md-0 mt-md-0",
										}}
										handleChange={val => onSelectionAllEdit(val, "display_all")}
									/>
								</div>
							</div>
							<MUIDataTable
								columns={marketTableColumns}
								data={marketMatchColumnsData}
								options={{
									selectableRows: "none",
									elevation: 0,
									textLabels: {
										body: {
											noMatch: isLoadingSelection ? <LoadingSpinner /> : " No data to display!",
										},
									},
									responsive: "",
									rowsPerPage: 0,
									rowsPerPageOptions: [],
									downloadOptions: {},
									download: false,
									customSearch: false,
									filter: false,
									fixedSelectColumn: false,
									jumpToPage: false,
									print: false,
									resizableColumns: false,
									search: false,
									rowHover: false,
									searchOpen: false,
									selectableRowsOnClick: false,
									viewColumns: false,
									pagination: false,
									...tableBarSettings,
								}}
							/>

							<div className="mt-3 d-flex justify-content-end pe-3 me-1">
								<PlusAddButton onClick={() => setManualSelectionType("add")} loading={false} />
							</div>
						</div>
					</div>
				</>
			)}
			{editMarketSelections && (
				<EditMarketMatchSelection
					setEditMarketSelections={setEditMarketSelections}
					editMarketSelectionData={editMarketSelectionData}
					setEditMarketSelectionData={setEditMarketSelectionData}
					ladders={ladders}
					market_id={addMarketEventData?.market_id}
					marketMatchColumnsData={marketMatchColumnsData}
					setMarketMatchColumnsData={setMarketMatchColumnsData}
					sportSlug={sportSlug}
					isRaceWinner={isRaceWinner}
				/>
			)}
			{manualSelectionType !== "" && (
				<ManualSelection
					onClose={() => setManualSelectionType("")}
					ladders={ladders}
					getSelectionMarket={getSelectionMarket}
					marketData={addMarketEventData}
					sportSlug={sportSlug}
					selections={manualMarketSelections}
					manualSelectionType={manualSelectionType}
					editMarketSelectionData={editMarketSelectionData}
					setEditMarketSelectionData={setEditMarketSelectionData}
				/>
			)}
		</div>
	);
};
