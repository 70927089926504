import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { XCloseIcon } from "utils/icons";
import React, { useEffect, useState } from "react";
import { CodeInput, MessageInput } from "components/GeneralComponents/CustomInputs";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";
import { AvForm } from "availity-reactstrap-validation";
import { useSelector } from "react-redux";

export const ShowEditMessageCodeModal = props => {
	const { setShowAddModal, editItem, setEditItem, setExistFilter, getMessageCodes } = props;

	const [showTitleLanguage, setShowTitleLanguage] = useState(false);
	const [messageCodes, setMessageCodes] = useState({
		id: editItem ? editItem?.id : "",
		code: editItem ? editItem?.code : "",
		message: editItem ? editItem?.message_en : "",
	});
	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState(false);

	const inUseLanguages = useSelector(state => state.Login.languages);

	const handleShowOptions = type => {
		setShowTitleLanguage(false);

		if (type === "title") {
			setShowTitleLanguage(true);
		}
	};

	const handleSaveMessageCodes = () => {
		setLoading(true);

		let payload = { id: editItem?.id, code: messageCodes?.code, language: "en", message: messageCodes?.message };

		let language = "";
		Object.entries(messageCodes).forEach(([key, value]) => {
			if (key.includes("message_")) {
				let a = key.split("_")[1];

				if (value !== "") language += `"${a}":"${value}",`;
			}
		});

		language = language.replace(/,(\s+)?$/, "");
		payload = { ...payload, message_translated: `{${language}}` };

		new Promise((resolve, reject) => {
			if (editItem) ApiServices.post(apiUrls.edit_message_codes, resolve, reject, payload, true);
			else ApiServices.put(apiUrls.add_message_codes, resolve, reject, payload, true);
		})
			.then(() => {
				getMessageCodes();
				setExistFilter(true);
				setDataChanged(false);
				setShowAddModal(false);
			})
			.finally(() => setLoading(false));
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	useEffect(() => {
		if (editItem?.message_translated) {
			let newData = { ...messageCodes };
			inUseLanguages.forEach(row => {
				JSON.parse(editItem?.message_translated, (key, value) => {
					if (key === row.for) {
						newData = { ...newData, [`message_${key}`]: value };
					}
				});
			});
			setMessageCodes(newData);
		}
	}, []);

	const handler = () => {
		setShowAddModal(false);
		setEditItem(null);
	};
	return (
		<>
			<div className="promotion-container">
				<div className="promotion-header">
					<span>Message</span>
					<XCloseIcon className={"xclose-icon"} onClick={handler} />
				</div>
				<div className="promotion-body">
					<TopBarComponent saveHandler={handleSaveMessageCodes} loading={loading} dataChanged={dataChanged} onClose={handler} />
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
							<AvForm>
								<CodeInput
									data={messageCodes}
									setData={setMessageCodes}
									editItem={editItem}
									onClick={() => handleShowOptions()}
									// style={editItem ? { background: "#ffffff", border: "none", cursor: "not-allowed" } : {}}
									handleDataChanged={handleDataChanged}
									firstRowClass
								/>

								<MessageInput
									data={messageCodes}
									setData={setMessageCodes}
									onClick={() => handleShowOptions("title")}
									handleDataChanged={handleDataChanged}
									otherRowClass
								/>
							</AvForm>
						</div>
						<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
							{showTitleLanguage && (
								<LanguageTitle title="message" data={messageCodes} setData={setMessageCodes} handleDataChanged={handleDataChanged} />
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
