import React from "react";
export const getMarketsGroupColumns = ({ recordsData }) => {
	return [
		{
			name: "id",
			label: "Id",

			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
				customBodyRender: (value, meta) => {
					const record = recordsData[meta.currentTableData[meta.rowIndex].index];
					return <span>{record?.id}</span>;
				},
			},
		},
		{
			name: "sport_id",
			label: "Sport Id",

			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
				customBodyRender: (value, meta) => {
					const record = recordsData[meta.currentTableData[meta.rowIndex].index];
					return <span>{record?.sport_id}</span>;
				},
			},
		},
		{
			name: "name",
			label: "Name",

			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
				customBodyRender: (value, meta) => {
					const record = recordsData[meta.currentTableData[meta.rowIndex].index];
					return <span>{record?.name}</span>;
				},
			},
		},
	];
};
