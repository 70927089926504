import React from "react";
import "../../components/Loader/Loader.css";

function Loader() {
	return (
		<div className="loader">
			<div className="dot-falling"></div>
		</div>
	);
}

export default Loader;
