import { DeleteIconButton, LoadingSaveButton, NoButton, YesButton } from "components/GeneralComponents/AddButton";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useState } from "react";
import { XCloseIcon } from "utils/icons";
import deleteIcon from "../../../assets/images/delete.svg";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import SweetAlert from "react-bootstrap-sweetalert";

export const SportTiers = props => {
	const { handler, tiersData, slug, setTiersData, checkForSelectedTier } = props;
	const [loading, setLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [tiers, setTiers] = useState(tiersData);
	const [tierName, setTierName] = useState("");
	const [addedTiers, setAddedTiers] = useState([]);
	const [deleteId, setDeleteId] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const handleAddNewTier = () => {
		let actualTiers = [...tiers];
		let newAddedTiers = [...addedTiers];
		actualTiers.push({
			id: `new_${Math.random()}`,
			name: tierName,
			sport_slug: slug,
			protected: 0,
		});
		newAddedTiers.push({
			id: `new_${Math.random()}`,
			name: tierName,
			sport_slug: slug,
			protected: 0,
		});
		setAddedTiers(newAddedTiers);
		setTiers(actualTiers);
		setTierName("");
		setDataChanged(true);
	};

	const addSportTier = () => {
		let payload = { items: addedTiers, sport_slug: slug };
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.sport_tier_add, resolve, reject, payload, true);
		})
			.then(response => {
				setDataChanged(false);
				let data = response.data;
				data.unshift({ id: "0", name: "Default Tier" });
				setTiersData(data);
				handler();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const confirmDeleteTier = () => {
		if (deleteId.toString().indexOf("new") > -1) {
			let actualTiers = [...tiers];
			let newAddedTiers = [...addedTiers];
			actualTiers = actualTiers.filter(row => row.id !== deleteId);
			newAddedTiers = newAddedTiers.filter(row => row.id !== deleteId);
			setAddedTiers(newAddedTiers);
			setTiers(actualTiers);
			setDeleteId(null);
		} else {
			setDeleteLoading(true);
			new Promise((resolve, reject) => {
				ApiServices.delete(apiUrls.sport_tier_delete + `/${deleteId}`, resolve, reject, true);
			})
				.then(() => {
					let actualTiers = [...tiers];
					let newAddedTiers = [...addedTiers];
					actualTiers = actualTiers.filter(row => row.id !== deleteId);
					newAddedTiers = newAddedTiers.filter(row => row.id !== deleteId);
					setAddedTiers(newAddedTiers);
					setTiers(actualTiers);
					setTiersData(actualTiers);
					setDeleteId(null);
					checkForSelectedTier(actualTiers);
				})
				.finally(() => {
					setDeleteLoading(false);
				});
		}
	};

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{"Tiers"}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={addSportTier} loading={loading} dataChanged={dataChanged} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						{tiers.map((row, index) => {
							return (
								<div className="d-flex mb-3 align-items-center" key={index}>
									<div className="sport-general-input">
										<span>{row.name}</span>
									</div>
									{row.id !== "0" && (
										<DeleteIconButton
											deleteIcon={deleteIcon}
											onClick={() => {
												setDeleteId(row.id);
											}}
											className="delete-tier"
										/>
									)}
								</div>
							);
						})}
						<div className="d-flex mb-3">
							<input
								type="text"
								placeholder="Enter new grade name here"
								className="sport-general-input"
								value={tierName}
								onChange={e => {
									setTierName(e.target?.value);
								}}
							/>
							<YesButton
								name={"Add"}
								onClick={() => {
									handleAddNewTier();
								}}
								disabled={!tierName}
							/>
						</div>
					</div>
				</div>
			</div>
			{deleteId && (
				<SweetAlert title={"Confirm delete of Tier"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="">
							<span className="delete-span">Are you sure you want to delete this tier ?</span>
						</div>
					</div>
					<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
						<NoButton onClick={() => setDeleteId(null)} name="No" />
						{deleteLoading ? (
							<LoadingSaveButton />
						) : (
							<YesButton
								name="Yes"
								onClick={() => {
									confirmDeleteTier();
								}}
							/>
						)}
					</div>
				</SweetAlert>
			)}
		</div>
	);
};
