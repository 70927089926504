import { useSelector } from "react-redux";
import React from "react";
import MetaTags from "react-meta-tags";
import { platformName } from "utils/constants";

const HelmetLayout = ({ children, titleProps }) => {
	const notificationCount = useSelector(state => state.NotificationsList.listNotifications.length);
	const betsListCount = useSelector(state => state.BetNotificationsList.listBetsNotifications.length);

	return (
		<>
			<MetaTags>
				<title>{`${!!notificationCount || !!betsListCount ? `(${notificationCount + betsListCount})` : ""} ${titleProps} - ${platformName}`}</title>
			</MetaTags>
			<div>{children}</div>
		</>
	);
};

export default HelmetLayout;
