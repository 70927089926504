import React from "react";
import { useEffect, useState } from "react";

import { MiniUserInputs } from "components/GeneralComponents/CustomInputs";
import getSportsData from "services/getSportsData";

import { RightDarkArrowIcon } from "utils/icons";
import { isEmptyObject } from "utils/global";
import { getFields } from "./getCustomerFields";
import { StakeFactorData } from "../stakeFactorModal";
import { CustomerModal } from "./CustomerModal.component";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeText } from "services/capitalizeText";

import { setRecentViewedUsers, setUsers } from "store/Users/actions";

import { toast } from "react-toastify";
import successIcon from "../../../assets/images/success-icon.svg";
import { getCountryCallingCode } from "libphonenumber-js";

import "./customerSettings.scss";

export const CustomerSettings = props => {
	const { data, setData, setunchangedData } = props;
	const [unChangedData, setUnChangedData] = useState(data);
	const [allSports, setAllSports] = useState([]);
	const [stakeFactorData, setStakeFactorData] = useState({});
	const [showPromotion, setShowPromotion] = useState(false);
	const [type, setType] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const loggedUser = JSON.parse(localStorage.getItem("user"));
	const [dataChanged, setDataChanged] = useState(false);
	const [openModal, setOpenModal] = useState({ className: "", id: 0 });
	const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
	const [category, setCategory] = useState([
		{ id: 1, name: "Customer" },
		{ id: 2, name: "Account" },
		{ id: 3, name: "Security" },
		{ id: 4, name: "Notifications" },
		{ id: 5, name: "E-mail Notifications" },
		{ id: 6, name: "Trader Settings" },
		{ id: 7, name: "Pre-match Stake Factor", stakeField: [], buttonType: "prematch", isStakeFactor: true, factorType: "prematch_stake_factor" },
		{ id: 8, name: "In-Play Stake Factor", stakeField: [], buttonType: "inplay", isStakeFactor: true, factorType: "inplay_stake_factor" },
	]);

	const usersInStore = useSelector(state => state.Users.users);
	const dispatch = useDispatch();
	const { id } = useParams();

	// let prop = props;

	// const [showModal, setShowModal] = useState(false);
	// let modalFields = [
	// 	{
	// 		type: "dropdown",
	// 		placeholder: "Select Reason",
	// 		fieldName: "suspendReason",
	// 		optionsType: "suspendTypes",
	// 		wrapperClassNameDropdown: "d-flex align-items-center",
	// 	},
	// ];

	useEffect(() => {
		getSportTypes();
		getStakeFactor();
	}, []);

	const getSportTypes = () => {
		getSportsData("all").then(response => {
			setAllSports(response);
		});
	};

	// useEffect(() => {
	// 	if (data.accountStatus === "suspended" && unchangedData?.accountStatus !== "suspended") {
	// 		setShowModal(true);
	// 	}
	// 	setData({ ...data, country: data?.country?.toUpperCase() });
	// }, [data.accountStatus]);

	// if (data?.suspendReason == "other") {
	// 	modalFields.push({
	// 		type: "input",
	// 		fieldName: "suspendReasonDescription",
	// 		placeholder: "Reason",
	// 		inputClassName: "general_input ",
	// 	});
	// }

	// const editUserStatus = () => {
	// 	const payload = {
	// 		reason_type: data?.suspendReason,
	// 		reason_description: data?.suspendReasonDescription || "",
	// 	};
	// 	const sub_id = props.match.params.id;

	// 	if (sub_id && payload) {
	// 		new Promise((resolve, reject) => {
	// 			ApiServices.put(apiUrls.SUSPEND_USER + sub_id, resolve, reject, payload, true);
	// 		}).then(response => {
	// 			if (response?.success) {
	// 				setShowModal(false);
	// 				setDataChanged(false);
	// 				setunchangedData({ ...unchangedData, accountStatus: "suspended" });
	// 			}
	// 		});
	// 	}
	// };

	// const buttonModalFields = [
	// 	{
	// 		type: "button",
	// 		buttonName: "Cancel",
	// 		buttonClassname: "btn-primary cancel-btn",
	// 		onClickEvent: () => {
	// 			setShowModal(false);
	// 			const oldStatus = unchangedData?.accountStatus;
	// 			setData({ ...data, accountStatus: oldStatus });
	// 		},
	// 	},
	// 	{ type: "button", buttonName: "Suspend Account", buttonClassname: "save-btn suspended_button", onClickEvent: editUserStatus },
	// ];
	// const dependecies = { data: data?.accountStatus };

	// Get stake-factor with new endpoint
	const getStakeFactor = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_STAKE_FACTOR + id, resolve, reject, true);
		}).then(response => {
			const { data, success } = response;
			if (success) setStakeFactorData(data);
		});
	};

	const editUserDatas = () => {
		setIsLoading(true);
		let payload = data;
		delete payload.stakeFactorPayload;
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.EDIT_USER_DATA + "?sub_id=" + id, resolve, reject, payload, true);
		})
			.then(() => {
				updateUsersInRedux();
				saveRecentUsers();
				onClose();
				setunchangedData(payload);
			})
			// .catch(() => {
			// 	const oldUserData = props?.unchangedData;
			// 	setData(oldUserData);
			// })
			.finally(() => {
				setDataChanged(false);
				setIsLoading(false);
			});
	};

	const updateUsersInRedux = () => {
		if (usersInStore?.length > 0) {
			let user = usersInStore.find(item => item.id == props.data.swifty_id.id);
			const fullName = capitalizeText(`${data.first_name} ${data.last_name}`);

			const allCountryies = JSON.parse(sessionStorage.getItem("_countries"));
			const country = allCountryies.find(country => country.id === data?.country);
			if (user) {
				let copiedUsers = usersInStore.map(item =>
					item.id === data.swifty_id
						? { ...item, name: fullName, accountStatus: data?.account_status, kyc_status: data?.kyc_status, country: country?.label }
						: item
				);
				dispatch(setUsers(copiedUsers));
			}
		}
	};

	const saveRecentUsers = () => {
		try {
			const recentViewUsers = JSON.parse(localStorage.getItem("recentViewUsers"));
			let newRecents = [];
			const { first_name, last_name, player_id, swifty_id } = data;
			const full_name = capitalizeText(`${first_name} ${last_name}`);

			const obj = {
				full_name,
				player_id,
				id: swifty_id,
			};
			if (!recentViewUsers || !recentViewUsers?.length) {
				newRecents = [obj];
			} else if (recentViewUsers?.length) {
				if (recentViewUsers.some(user => user.player_id == player_id)) {
					newRecents = [obj, ...recentViewUsers.filter(item => item.player_id != player_id)];
				} else {
					newRecents = [obj, ...recentViewUsers];
				}
			}

			if (newRecents?.length > 10) newRecents = newRecents.filter((item, i) => i < 10);

			localStorage.setItem("recentViewUsers", JSON.stringify(newRecents));
			dispatch(setRecentViewedUsers(newRecents));
		} catch (e) {
			console.log(e?.message);
		}
	};

	const handlerClick = row => {
		const { id } = row;

		if (openModal.id === 0) setOpenModal({ className: "modal-fields", id: id });
	};

	const onClose = () => setOpenModal({ className: "", id: 0 });

	const handlerOpenStakeFactorData = buttonType => {
		setShowPromotion(true);
		setType(buttonType);
	};

	const handleCheckBox = (key, value) => {
		setDataChanged(true);
		setData({ ...data, [key]: value === true ? 1 : 0 });
	};

	const handleDiscard = () => {
		setData(unChangedData);
		setUnChangedData(unChangedData);
		setDataChanged(false);
	};

	const sendResetLink = () => {
		setResetPasswordLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.RESET_PASSWORD + `?device_type=web&email=${data?.email}`, resolve, reject, true);
		})
			.then(res => {
				if (res?.reset_password_link) {
					toast.success(
						<>
							<img src={successIcon} alt="Success" />
							<p className="toast-icon-manual-message">Send reset link successfully</p>
						</>,
						{ className: "custom-toast" }
					);
					return;
				}
			})
			.finally(() => {
				setResetPasswordLoading(false);
			});
	};

	const handleChangeDropdown = newData => {
		const { id, default_currency } = newData;

		if (id) {
			const phonePrefix = getCountryCallingCode(id);
			setData({ ...data, country: id, currency: default_currency, phone_prefix: `+${phonePrefix}` });
		} else setData({ ...data, country: "" });

		setDataChanged(true);
	};

	useEffect(() => {
		let c = category.map(item => {
			const { isStakeFactor, factorType, buttonType, name } = item;

			const newFields = getFields({ data, loggedUser, handleCheckBox, sendResetLink, resetPasswordLoading, handleChangeDropdown });
			const fields = newFields.filter(row => row.group === name);

			if (!isEmptyObject(stakeFactorData)) {
				if (isStakeFactor) {
					return {
						...item,
						fields,
						stakeField: [{ id: 0, name: "Stake Factor", value: stakeFactorData[factorType] }, ...stakeFactorData[buttonType]],
						dataChanged,
						setDataChanged,
						normalTitle: true,
					};
				} else return { ...item, fields, dataChanged, setDataChanged };
			} else return { ...item, fields, dataChanged, setDataChanged };
		});

		setCategory(c);
	}, [openModal.id, dataChanged, stakeFactorData, data]);

	const customer = category?.filter(({ name }) => name === "Customer");

	const accountElement = ["Account", "Security", "Notifications", "E-mail Notifications"];
	const account = category?.filter(({ name }) => accountElement.includes(name));

	const traderSettingsElement = ["Trader Settings", "Pre-match Stake Factor", "In-Play Stake Factor"];
	const traderSettings = category?.filter(({ name }) => traderSettingsElement.includes(name));

	return (
		<>
			<div className="row customer-container">
				<div className="col-12  col-md-4 borderRight customer-item">
					{customer.map((row, i) => {
						const { name } = row;
						return (
							<div className="customer-item-container" key={i}>
								<div className="title" onClick={() => openModal.id === 0 && handlerClick(row)}>
									<p>{name} </p>
									<RightDarkArrowIcon className="go-back-btn" />
								</div>
								<CustomerModal
									row={row}
									openModal={openModal}
									data={data}
									setData={setData}
									onSave={editUserDatas}
									onClose={onClose}
									dataChanged={dataChanged}
									setDataChanged={setDataChanged}
									isLoading={isLoading}
									handleDiscard={handleDiscard}
								/>
							</div>
						);
					})}
				</div>
				<div className="col-12  col-md-4 borderRight customer-item">
					{account.map((row, i) => {
						const { name } = row;
						return (
							<div className="customer-item-container" key={i}>
								<div className="title" onClick={() => handlerClick(row)}>
									<p>{name} </p>
									<RightDarkArrowIcon className="go-back-btn" />
								</div>
								<CustomerModal
									row={row}
									openModal={openModal}
									data={data}
									setData={setData}
									onSave={editUserDatas}
									onClose={onClose}
									isLoading={isLoading}
									handleDiscard={handleDiscard}
								/>
							</div>
						);
					})}
				</div>

				<div className="col-12 col-md-4 customer-item">
					{traderSettings.map((row, i) => {
						const { name, stakeField = [], buttonType, normalTitle } = row;

						return (
							<div className="customer-item-container" key={i}>
								<div className={`title ${normalTitle ? "normalTitle" : ""}`} onClick={() => handlerClick(row)}>
									<p>{name} </p>
									<RightDarkArrowIcon className="go-back-btn" />
									{buttonType && (
										<div className="editClick" onClick={() => handlerOpenStakeFactorData(buttonType)}>
											edit
										</div>
									)}
								</div>

								<CustomerModal
									row={row}
									openModal={openModal}
									data={data}
									setData={setData}
									onSave={editUserDatas}
									onClose={onClose}
									handlerOpenStakeFactorData={handlerOpenStakeFactorData}
									isLoading={isLoading}
									handleDiscard={handleDiscard}
								>
									{stakeField.map((row, index) => {
										const nameOfSport = row?.slug ? allSports?.find(({ slug }) => slug == row?.slug)?.name : row.name;
										return (
											<div className="customer_settings_page" key={index}>
												<MiniUserInputs name={nameOfSport} pholder={"0%"} placeholder={"0"} value={row?.value} disabled={true} />
											</div>
										);
									})}
								</CustomerModal>
							</div>
						);
					})}
					{showPromotion && (
						<StakeFactorData
							title={"Edit"}
							handler={() => setShowPromotion(false)}
							data={data}
							setData={setData}
							stakeFactorData={stakeFactorData}
							setStakeFactorData={setStakeFactorData}
							getStakeFactor={getStakeFactor}
							props={props}
							type={type}
							setShowPromotion={setShowPromotion}
							allSports={allSports}
						/>
					)}
				</div>
			</div>
		</>
	);

	// 	{showModal && (
	// 		<ModalOfCustomerSettings
	// 			title={"Suspend Account"}
	// 			paragraph={"Are you sure you want to suspend this user? Please specify the reason for the suspension."}
	// 			buttonModalFields={buttonModalFields}
	// 			data={data}
	// 			setData={setData}
	// 			allFields={modalFields}
	// 		/>
	// 	)}
};
