import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

import { filterAndSortArray } from "utils/global";

export const getAffiliateUserDropdown = async id => {
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.GET_AFFILIATE_USERS, resolve, reject, true);
	})
		.then(response => {
			const { success, data } = response;
			if (success) {
				if (data) {
					let newData = data.filter(row => row.agent_id !== Number(id));
					newData = filterAndSortArray(newData, "first_name");

					let options = newData.map(row => {
						return { id: row.agent_id, label: `${row.first_name} ${row.last_name}` };
					});

					console.log(options, "options");
					return options;
				}
			}
		})
		.catch(() => {
			return [];
		});
};
