import React, { useEffect, useState } from "react";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { AvForm } from "availity-reactstrap-validation";
import { userReadOnly } from "utils/global";
import { GeneralCheckbox, GeneralDropdown, GeneralInput } from "components/GeneralComponents/CustomInputs";
import { capitalizeText } from "services/capitalizeText";
import { _gmtTimezones } from "utils/constants";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";

import "./OddsDisplay.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function OddsDisplay() {
	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);
	const history = useHistory();

	const [preferencesData, setPreferencesData] = useState({ odds_display: user.odds_display, timezone_name: user.timezone_name });
	const [dataChanged, setDataChanged] = useState("");
	const [isLoading, setIsLoading] = useState("");

	const odds = [{ name: "fractional" }, { name: "decimal" }];

	const getUserListData = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.new_main + "/api/v1/auth/get-user", resolve, reject, true);
		}).then(response => {
			const { data } = response;

			setPreferencesData({ ...preferencesData, odds_display: data?.odds_display });
			if (user) {
				user.odds_display = data?.odds_display;
				user.offset = data?.offset;
				user.timezone_name = preferencesData?.timezone_name;

				localStorage.setItem("user", JSON.stringify(user));
			}
		});
	};

	useEffect(() => {
		getUserListData();
	}, []);

	const handleValidSubmit = () => {
		let payload = { ...preferencesData };
		setIsLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.cms_user_odds_display, resolve, reject, payload, true);
		})
			.then(response => {
				if (response) getUserListData();
			})
			.finally(() => {
				setDataChanged(false);
				setIsLoading(false);
			});
	};

	const handleChange = (newValue, fieldName) => {
		setDataChanged(true);
		setPreferencesData({ ...preferencesData, [fieldName]: newValue });
	};

	return (
		<HelmetLayout titleProps={"Odds Display"}>
			<div className="cms-page profile-container">
				<TopBarComponent
					classname="special_top_component"
					saveHandler={() => handleValidSubmit()}
					dataChanged={dataChanged}
					loading={isLoading}
					dontDisable={true}
					onClose={() => history.goBack()}
				/>
				<div className="promotion-elements row m-0">
					<AvForm className={`${isUserReadOnly && "pe-none"} p-0`}>
						<div className="col-12 col-lg-5 promotion-inputs special_promotion_pages profile-page">
							<h4 className="profile-title">Preferences</h4>
							<div className="d-flex mb-3 profile-items">
								<span className="input-title profile-subtitle">Odds Display</span>

								{odds.map(({ name }, i) => {
									return (
										// <div className="row" key={i}>
										<div key={i} className="d-flex align-items-center me-md-4">
											<>
												<div className="show-mobile-side" onClick={() => handleChange(name, "odds_display")}>
													<GeneralInput
														object={{
															value: capitalizeText(name),
															inputContainer: "w-100",
															checkBoxValue: preferencesData.odds_display === name,
														}}
													/>
												</div>
												<div className="show-desktop-side" onClick={() => handleChange(name, "odds_display")}>
													<GeneralCheckbox
														object={{
															checked: preferencesData.odds_display === name,
															checkboxExtraClass: "p-0 me-3",
															checkboxContainer: "p-0",
														}}
													/>
													<span className="checkbox-odds">{capitalizeText(name)}</span>
												</div>
											</>
										</div>
									);
								})}
							</div>

							<GeneralDropdown
								object={{
									name: "Timezone",
									options: _gmtTimezones,
									value: preferencesData.timezone_name,
									wrapperClassNameDropdown: "profile-items",
									dropdownClassName: "profile-subtitle",
									handleChangeDropdown: newValue => handleChange(newValue.id, "timezone_name"),
								}}
							/>
						</div>
					</AvForm>
				</div>
			</div>
		</HelmetLayout>
	);
}
export default OddsDisplay;
