import React from "react";
// import { ModalButton } from "components/GeneralComponents/CustomInputs";
import { RightDarkArrowIcon } from "utils/icons";

export const getLastReportsGeneratedTableColmns = ({ lastReports, history }) => {
	return [
		{
			name: "name",
			label: "REPORT NAME",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "name",
			options: {
				setCellProps: () => ({ style: { minWidth: "170px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "170px" } }),
			},
		},
		{
			name: "description",
			label: "DESCRIPTION",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "300px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "300px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = lastReports[meta.currentTableData[meta.rowIndex].index];
					return (
						!row?.bgColors && (
							<div className="d-flex justify-content-end">
								<div className="heroBanners-buttons cursor-pointer" onClick={() => history.push(`/reports?slug=${row.slug}`)}>
									<RightDarkArrowIcon />
								</div>
							</div>
						)
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "120px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "120px" }, className: "max-button" }),
			},
		},
	];
};
