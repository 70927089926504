import React from "react";
import { Spinner } from "react-bootstrap";
import Parser from "html-react-parser";
export const ShowSetupMFAUserModal = ({
	showSetupMFAModal,
	continueNextStep,
	QRCodeData,
	setContinueNextStep,
	setShowSetupMFAModal,
	setMFAToken,
	verifyMFAToken,
	verifyTokenBtn,
	profileMenu,
}) => {
	let style = profileMenu ? { width: "150px", height: "200px", display: "flex", justifyContent: "center" } : { width: "200px", height: "200px" };
	return (
		<>
			{showSetupMFAModal && (
				<div
					style={{
						position: "absolute",
						zIndex: "10002",
						width: "500px",
						height: "512px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						background: "white",
						overflow: "hidden",
						left: "-400px",
					}}
				>
					<div style={{ position: "relative" }} className="mt-3">
						{!continueNextStep ? (
							<>
								<div className="row">
									<div className="col-5">
										<div style={style}>{Parser(QRCodeData.qrcode)}</div>
									</div>
									<div className="col-7 ">
										<div
											style={
												profileMenu
													? { marginTop: "20px", width: "100%", textAlign: "left", fontSize: "13px" }
													: { marginTop: "20px", textAlign: "left" }
											}
										>
											If you want to setup MFA please scan this QR code with one of the authenticator APP like Google Authenticator or
											just copy this code <span style={{ color: "blue" }}> {QRCodeData.secretCode} </span>
											and paste in the app. If you have scan code, press continue.
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 mt-3">
										<button
											style={{
												marginLeft: 10,
												minWidth: 100,
												padding: "5px 10px",
												borderRadius: 7,
												border: "none",
												fontSize: 16,
											}}
											className="btn-primary"
											onClick={() => setContinueNextStep(true)}
										>
											Continue
										</button>
										<button
											style={{
												marginLeft: 10,
												minWidth: 100,
												padding: "5px 10px",
												borderRadius: 7,
												border: "none",
												fontSize: 16,
											}}
											className="btn-secondary"
											onClick={() => setShowSetupMFAModal(false)}
										>
											Cancel
										</button>
									</div>
								</div>
							</>
						) : (
							<div className="row justify-content-center">
								<div className="col-6">
									<div className="col-12" style={{ textAlign: "left", fontSize: "12px", marginBottom: "10px" }}>
										A code to complete setup
									</div>
									<div className="col-12" style={{ textAlign: "left", marginBottom: "5px", fontWeight: "600" }}>
										MFA Code:
									</div>
									<input
										type="text"
										className="form-control"
										onChange={e => {
											e.persist();
											setMFAToken(e.target.value);
										}}
									/>
									<button type="button" className="btn btn-primary w-100 mt-2" onClick={verifyMFAToken}>
										{verifyTokenBtn ? <Spinner animation="border" size="sm" /> : "Submit"}
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};
