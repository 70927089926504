import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import React, { useEffect, useState } from "react";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getSportsWidgetColumns } from "./sportsWidgetColumns";
import { SportsWidgetPromotion } from "./sportsWidgetPromotion";
import ConfirmDeleteComponent from "components/GeneralComponents/ConfirmDeleteComponent";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { getContent } from "services/getContentAllData";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const SportsWidget = props => {
	const [showPromotion, setShowPromotion] = useState(false);
	const [sportsData, setSportsData] = useState([]);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);
	const [editItem, setEditItem] = useState(null);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [itemTitle, setItemTitle] = useState("");
	const [isDataLoading, setIsDataLoading] = useState(false);
	const pageHeaderData = {
		international: true,
		addNew: true,
		addNewClick: () => {
			setShowPromotion(true);
		},
	};
	const country = useSelector(state => state.Login.country);

	const sportsWidget = async () => {
		setIsDataLoading(true);
		let response = await getContent("sport_widget", country);
		if (response?.length > 0) {
			response.forEach(element => {
				if (element?.active) {
					element["status"] = "Published";
				} else {
					element["status"] = "Inactive";
				}
				if (element?.details) {
					try {
						let parsedDetails = JSON.parse(element["details"]);
						parsedDetails["publish_start_date_time"] = element["publish_start_date_time"];
						parsedDetails["publish_stop_date_time"] = element["publish_stop_date_time"];
						parsedDetails["status"] = element["status"];
						parsedDetails["price_boost"] = "Enabled";
						element["details"] = JSON.stringify(parsedDetails);
					} catch {
						element["details"] = "";
					}
				}
			});
			setSportsData(response);
			setUnfilteredData(response);
			setIsDataLoading(false);
		} else {
			setIsDataLoading(false);
			setSportsData([]);
			setUnfilteredData([]);
		}
	};

	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};

	useEffect(() => {
		sportsWidget();
	}, [country]);

	const sportsWidgetColumns = getSportsWidgetColumns({
		sportsData,
		props,
		handleEditItem,
		setDeleteItem,
		setItemTitle,
	});
	return (
		<HelmetLayout titleProps={"Sport Widget"}>
			<div className="cms-page pt-0">
				<SingleSearchFilter
					setData={setSportsData}
					unfilteredData={unfilteredData}
					detailsColumn={["title", "publish_start_date_time", "publish_stop_date_time", "status"]}
				/>

				<MUIDataTable
					columns={sportsWidgetColumns}
					data={sportsData}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						responsive: tableResponsive,
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
						customToolbar: () => <AddTableButton onClick={pageHeaderData["addNewClick"]} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
			{deleteItem && (
				<ConfirmDeleteComponent
					showDeleteCom={deleteItem}
					deleteLoading={deleteLoading}
					setDeleteLoading={setDeleteLoading}
					deleteTitle={"Confirm Deletion of Promotion"}
					titlename={itemTitle}
					setShowDeleteCom={setDeleteItem}
					betSlipData={sportsData}
					setBetSlipData={setSportsData}
					id={deleteItem?.id}
				/>
			)}

			{showPromotion && (
				<SportsWidgetPromotion
					title={"Sport Widget"}
					editItem={editItem}
					countires={country}
					getSportsWidget={sportsWidget}
					handler={() => {
						setShowPromotion(false);
						setEditItem(null);
					}}
				/>
			)}
		</HelmetLayout>
	);
};

export default SportsWidget;
