import { getCountriesDropdown } from "services/getCountriesDropdown";
import { getAffiliateTagsDropdown } from "services/getAffiliateTagsDropdown";
import { getAllSportsDropdown } from "services/getAllSportsDropdown";
import { getLadders } from "services/getLaddersDropdown";
import { _betTypesOptions, _turnRequirment, _resultBets, eventPhaseBetsAllowed } from "utils/constants";
import { getCasinoGamesInfo } from "services/getCasinoGamesInfo";
import { getCasinoProviders } from "services/getCasinoProviders";
import { getFreebetCasinoGames } from "services/getFreebetCasinoGames";
import { getCurrenciesDropdown } from "services/getCurrenciesDropdown";
import { getAmountFreeBetDropdown } from "services/getAmountFreeBetDropdown";

const getEligbleCasinoGames = (input, value, rules) => {
	const provider = value?.first_value;
	const currency = rules?.find(({ condition_type }) => condition_type == currencyRule?.id)?.first_value;

	let firstParams = provider && `provider=${provider}&q=${input}&limit=20&freebet=1`;
	if (currency) firstParams += `&currency=${currency}`;
	const game_slug = value?.second_value !== "" && `&game_slug=${value?.second_value?.split(",")[0]}`;

	if (input) return getFreebetCasinoGames(firstParams, game_slug);
	else return [];
};

const period = [
	{ id: "hours", label: "Hours" },
	{ id: "days", label: "Days" },
];

const recurring = () => [
	{ id: "monday", label: "Weekly(Monday)" },
	{ id: "tuesday", label: "Weekly(Tuesday)" },
	{ id: "wednesday", label: "Weekly(Wednesday)" },
	{ id: "thursday", label: "Weekly(Thursday)" },
	{ id: "friday", label: "Weekly(Friday)" },
	{ id: "saturday", label: "Weekly(Saturday)" },
	{ id: "sunday", label: "Weekly(Sunday)" },
];

const currencyRule = {
	label: "Currency",
	id: "currency",
	type: "condition",
	selected_type: "select",
	equality: "equal",
	options: () => getCurrenciesDropdown(),
	important: true,
};

const equalityOptions = [
	{
		label: ">=",
		id: "more_than",
	},
	{
		label: "=",
		id: "equal",
	},
	{
		label: "<=",
		id: "less_than",
	},
	{
		label: "!=",
		id: "not_equal",
	},
];

const rulesData = [
	// conditions
	currencyRule,
	{ label: "Affiliate Tag", id: "affiliate", type: "condition", selected_type: "array", equality: "equal", options: () => getAffiliateTagsDropdown() },
	{
		label: "Bet Settlement",
		id: "bet_settlement",
		type: "condition",
		equality: "more_than",
		selected_type: [
			{ selected_type: "number", placeholder: "No. Bets" },
			{ selected_type: "select", placeholder: "Select (Price)", options: () => getLadders("master", true) },
		],
	},
	{
		label: "Deposit - First Transaction",
		id: "deposit",
		type: "condition",
		equality: "more_than",
		selected_type: [
			// add 'index' if there is another equality input on the object, for easier maping and calculations
			{ selected_type: "number", placeholder: "No.", index: "0" },
			{ selected_type: "equality", equality: "less_than" },
			{ selected_type: "number", placeholder: "Optional", optional: true, index: "1" },
		],
		// equality: "more_than",
		// disable: ["event_phase", "bet_types", "eligible_casino_games", "eligible_sports", "first_deposit"],
	},
	{
		label: "Eligible Countries",
		id: "eligible_countries",
		type: "condition",
		selected_type: "array",
		equality: "equal",
		options: () => getCountriesDropdown(),
	},
	{
		label: "First Deposit",
		id: "first_deposit",
		type: "condition",
		selected_type: "number",
		equality: "more_than",
		// disable: "deposit"
	},
	{ label: "First Casino Stake", id: "first_casino_stake", type: "condition", selected_type: "number", equality: "more_than" },
	{ label: "Casino Stake", id: "casino_stake", type: "condition", selected_type: "number", equality: "more_than" },
	{ label: "Casino Stake Total", id: "casino_stake_total", type: "condition", selected_type: "number", equality: "more_than" },
	{ label: "Sportsbook Stake Total", id: "sportsbook_stake_total", type: "condition", selected_type: "number", equality: "more_than" },
	{ label: "First KYC Status (Active)", id: "first_kyc_status", type: "condition", selected_type: "string", hideValue: true },
	{ label: "First Sportsbook Stake", id: "first_sportsbook_stake", type: "condition", selected_type: "number" },
	{ label: "First Mobile Sportsbook Stake", id: "first_mobile_sportsbook_stake", type: "condition", selected_type: "number" },
	// { label: "Payment Method", id: "payment_method", type: "condition", selected_type: "array", equality: "equal" },
	{ label: "Sportsbook Stake", id: "sportsbook_stake", type: "condition", selected_type: "number" },
	{ label: "Registration Date", id: "registration_date", type: "condition", selected_type: "date" },
	{
		label: "Qualifying Sports",
		id: "qualifying_sports",
		type: "condition",
		selected_type: "array",
		options: () => getAllSportsDropdown(),
		equality: "equal",
	},
	{
		label: "Qualifying Casino Games",
		id: "qualifying_casino_games",
		type: "condition",
		selected_type: "array",
		equality: "equal",
		filterDropdown: true,
		options: input => {
			let param = `provider=all&q=${input}&limit=20&freebet=1`;
			if (input) return getFreebetCasinoGames(param);
			return [];
		},
		onStart: ids => getCasinoGamesInfo(ids),
	},
	{ label: "Recurring", id: "recurring", type: "condition", selected_type: "select", equality: "equal", options: () => recurring },
	{
		label: "Bet Types",
		id: "bet_types",
		type: "condition",
		selected_type: "array",
		equality: "equal",
		options: () => [..._betTypesOptions, { id: "forecast", label: "Forecast" }, { id: "tricast", label: "Tricast" }],
	},
	{
		label: "Bet Result",
		id: "bet_result",
		type: "condition",
		selected_type: "array",
		// equality: "not_equal",
		equalityOptions: equalityOptions.filter(({ id }) => id == "equal" || id == "not_equal"),
		options: () => _resultBets,
	},
	{
		label: "Bet Result Period",
		id: "bet_result_period",
		type: "condition",
		// equality: "equal",
		selected_type: [
			{ selected_type: "number", placeholder: "No." },
			{ selected_type: "select", options: () => period.filter(({ id }) => id == "days") },
		],
	},
	{
		label: "Selection Odds",
		id: "selection_odds",
		type: "condition",
		// equality: "equal",
		equalityOptions: equalityOptions.filter(({ id }) => id !== "not_equal"),
		selected_type: "select",
		placeholder: "Select (Price)",
		options: () => getLadders("master", true),
	},

	//Bonus / Offer

	{
		label: "Casino Spins",
		id: "casino_spins",
		type: "offer",
		equality: "equal",
		selected_type: [
			{ selected_type: "select", options: () => _turnRequirment, placeholder: "Select (Turns)" },
			{
				selected_type: "select",
				placeholder: "Amount",
				info: "Please select a eligible casino game in vonus offer cirteria",
				// disabled: rules => {
				// 	return !rules?.find(({ condition_type }) => condition_type == "eligible_casino_games")?.second_value;
				// },
				options: rules => {
					const casinoGames = rules?.find(({ condition_type }) => condition_type == "eligible_casino_games")?.second_value || "";
					const currency = rules?.find(({ condition_type }) => condition_type == currencyRule?.id)?.first_value;

					return getAmountFreeBetDropdown(casinoGames.split(","), null, currency);
				},
				dependecies: rules => ({
					games: rules?.find(({ condition_type }) => condition_type == "eligible_casino_games")?.second_value,
					currency: rules?.find(({ condition_type }) => condition_type == currencyRule?.id)?.first_value,
				}),
			},
		],
	},
	{ label: "Cash Reward", id: "cash_reward", type: "offer", selected_type: "number", equality: "equal" },
	{
		label: "Sportsbook Free Bet - Deposit Match %",
		id: "sportsbook_free_bet_deposit_match",
		type: "offer",
		selected_type: "number",
		equality: "equal",
		// disable: ["sportsbook_free-bet-value"],
	},
	{
		label: "Sportsbooks Free Bet - Stake Match %",
		id: "sportsbook_free_bet_stake_match",
		type: "offer",
		selected_type: "number",
		equality: "equal",
		// disable: ["sportsbook_free-bet-value"],
	},
	{
		label: "Sportsbook Free Bet - Value",
		id: "sportsbook_free-bet-value",
		type: "offer",
		selected_type: "number",
		equality: "equal",
		// disable: ["sportsbook_free_bet_deposit_match"],
	},

	// Bonus / Offer Criteria

	{ label: "Event Phase", id: "event_phase", type: "criteria", selected_type: "array", equality: "equal", options: () => eventPhaseBetsAllowed },
	{
		label: "Offer Expires After",
		id: "offer_expires_after",
		type: "criteria",
		equality: "equal",
		selected_type: [
			{ selected_type: "number", placeholder: "No." },
			{ selected_type: "select", options: () => period },
		],
	},
	{
		label: "Freespin Expires After",
		id: "freespin_expires_after",
		type: "criteria",
		equality: "equal",
		selected_type: [
			{ selected_type: "number", placeholder: "No." },
			{ selected_type: "select", options: () => period.filter(({ id }) => id == "days") },
		],
	},
	{
		label: "Eligible Casino Games",
		id: "eligible_casino_games",
		type: "criteria",
		equality: "equal",
		selected_type: [
			{
				placeholder: "Casino Studio",
				selected_type: "select",
				// filterDropdown: true,
				options: () => getCasinoProviders(false),
			},
			{
				selected_type: "array",
				filterDropdown: true,
				options: (input, value, rules) => getEligbleCasinoGames(input, value, rules),
				onStart: ids => getCasinoGamesInfo(ids),
			},
		],
	},
	{ label: "Eligible Sports", id: "eligible_sports", type: "criteria", selected_type: "array", equality: "equal", options: () => getAllSportsDropdown() },
	{
		label: "Maximum Award Value",
		id: "maximum_award_value",
		type: "criteria",
		selected_type: "number",
		equality: "equal",
	},
];

export { currencyRule, rulesData, equalityOptions };
