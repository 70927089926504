import React from "react";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { StyleTopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import ImageForStyleModal from "./ImageForStyleModal";
import { getLogo } from "../../../utils/global";
import { useDispatch } from "react-redux";
import { setBackdrop } from "store/Backdrop/actions";
import { CssHelpInformation } from "../HelpPagesForSettings/HelpPages";
import InformationPageHelper from "../InformationPageHelper";
import { InfoIconHelper } from "utils/icons";
import Editor from "@monaco-editor/react";
import "./style.scss";

const Style = () => {
	const [data, setData] = useState("");
	const [unChangeData, setUnChangeData] = useState("");
	const [isDataChanged, setIsDataChanged] = useState(false);
	const [showImage, setShowImage] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();

	const handleChange = value => {
		setData({ ...data, css_content: value });
		setIsDataChanged(value !== data.css_content);
	};
	const getCss = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_css, resolve, reject, true);
		})
			.then(response => {
				setData(response.data);
				setUnChangeData(response.data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const save = async () => {
		let payload = { style: data.css_content, desktop_logo: data.desktop_logo, mobile_logo: data.mobile_logo, solid_logo: data.solid_logo };

		await new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.add_css, resolve, reject, payload, true);
		})
			.then(() => {
				getLogo(dispatch);
			})
			.finally(() => {
				setShowImage(false);
				setIsDataChanged(false);
			});

		getCss();
	};

	const close = () => {
		setShowImage(false);
		setData(unChangeData);
	};

	const handleImageUpload = (image, type) => {
		if (type === "desktop_logo") {
			setData({ ...data, desktop_logo: image });
		} else if (type === "mobile_logo") {
			setData({ ...data, mobile_logo: image });
		} else if (type === "solid_logo") {
			setData({ ...data, solid_logo: image });
		}
	};

	const uploadImage = () => {
		setShowImage(true);
	};

	const discardChanges = () => {
		getCss();
		setIsDataChanged(false);
	};

	useEffect(() => {
		getCss();
	}, []);

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};
	const handlerOnKeyDown = e => {
		e.preventDefault();

		if (isDataChanged) save();
		return false;
	};

	return (
		<HelmetLayout titleProps={"Style (CSS)"}>
			<StyleTopBarComponent
				discardHandler={discardChanges}
				uploadImage={uploadImage}
				saveHandler={save}
				dataChanged={isDataChanged}
				classname="special_top_component style_top_component"
			/>
			<Card className="style-card-header" onKeyDown={e => e.key === "s" && e.ctrlKey && handlerOnKeyDown(e)}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<span className="mx-3">Code Editor - Style (CSS)</span>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
				{isDataLoading ? (
					<LoadingSpinner className={"pt-3"} />
				) : (
					<CardBody>
						<Editor
							height="calc(100vh - 20rem)"
							defaultLanguage="css"
							value={data?.css_content}
							defaultValue={data?.css_content}
							onChange={handleChange}
						/>
					</CardBody>
				)}
			</Card>
			{showImage && (
				<ImageForStyleModal
					title={"Style CSS"}
					save={save}
					close={close}
					onChange={handleImageUpload}
					data={data}
					setData={setData}
					loading={isDataLoading}
				/>
			)}
			{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} className="info_icon_style_customized" />}
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<CssHelpInformation />
				</InformationPageHelper>
			)}
		</HelmetLayout>
	);
};

export default Style;
