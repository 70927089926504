import React, { useState } from "react";
import { SearchIcon, XSmIcon } from "utils/icons";

export const SearchHeader = ({ unfilteredData, setData }) => {
	const [searchValue, setSearchValue] = useState("");

	const handleSearch = searchValue => {
		setSearchValue(searchValue);
		if (searchValue) {
			let filtered = [...unfilteredData];
			const newData = filtered.filter(value => value.market_name.toLowerCase().includes(searchValue.toLowerCase()));
			setData(newData);
		} else {
			setData(unfilteredData);
		}
	};
	return (
		<div className="search-header">
			<div className="search-input-group" style={{ height: "100%" }}>
				<div className="search-input-container">
					<input
						type={"text"}
						placeholder={"Search All Market Types"}
						value={searchValue}
						onChange={e => {
							handleSearch(e.target.value);
						}}
					/>
					<XSmIcon
						onClick={() => {
							setSearchValue("");
							setData(unfilteredData);
						}}
					/>
				</div>
				<div className="btn-primary btn-search-casino-group">
					<SearchIcon fill={"#FFFFFF"} />
				</div>
			</div>
		</div>
	);
};
