import React, { useRef } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { XCloseIcon } from "utils/icons";
import {
	CompetitionInput,
	EndDateInput,
	EventIDInput,
	ImageInput,
	LinkTypeInput,
	// MarketInput,
	NeverExpireInput,
	PromoTypeInput,
	SwitchInput,
	SpecificDaysInput,
	SportInput,
	StartDateInput,
	SelectionInput,
	GeneralInput,
	GeneralDropdown,
} from "../../../components/GeneralComponents/CustomInputs";
import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import moment from "moment";
import { getAllMenuLink } from "services/getAllMenuLinks";
import xIcon from "../../../assets/images/x-icon.svg";
import { getDynamicValue, checkGenericUrl, getFormatDateByUserTimezone, convertFormatDateInUtc } from "utils/global";
import { getMatcheDropdown } from "services/getMatchesDropdown";
import getSportsData from "services/getSportsData";
import { getCompetitionsDropdown } from "services/getCompetitionsDropdown";
import { getCasinoGames } from "services/getCasinoGames";
import { getEventMarketsDropdown } from "services/getEventMreketsDropdown";
import { languageToString } from "utils/global";

export const CarouselPromotion = ({ title, handler, editItem, getCarousel, countires, pageLayoutType }) => {
	let details = null;
	if (editItem) {
		details = JSON.parse(editItem?.details);
		console.log(details);
	}

	const [carouselData, setCarouselData] = useState({
		checked: editItem ? editItem?.active : true,
		promoType: details ? details?.promo_type : "default",
		title: details ? details?.title : "",
		show_title: !(details && details.show_title === 0),
		subtitle: details ? details?.subtitle : "",
		image: details ? details?.image : null,
		image_exist: editItem ? true : false,
		link: details ? details?.link : "",
		link_type: details ? (details?.link_type ? details?.link_type : "generic") : "generic",
		sport_id: details ? details?.sport_slug : "",
		event_id: details ? details?.event_raw_id : "",
		event_name: details ? details?.event_name : "",
		competition_id: details ? details?.competition_raw_id : "",
		market_id: details ? details?.market_id : "",
		startDate: editItem
			? getFormatDateByUserTimezone(editItem?.publish_start_date_time)
			: moment().startOf("day").add(1, "minute").format("YYYY-MM-DD HH:mm"),
		endDate: editItem ? getFormatDateByUserTimezone(editItem?.publish_stop_date_time) : "",
		neverExpire: editItem ? editItem?.never_expire : true,
		call_to_action: details ? details?.call_to_action : "",
		selected_days: details ? (details?.selected_days ? details?.selected_days : []) : [],
		specific_days: details ? details?.specific_days : false,
		game_id: details ? details?.game_id : "",
		selection_id: details ? details?.selection_id : "",
		selected_selections: details ? (details?.selected_selections ? details?.selected_selections : []) : [],
	});

	const [pageType, setPageType] = useState([]);
	const [sportsData, setSportsData] = useState([]);
	const [markets, setMarkets] = useState([]);
	const [events, setEvents] = useState([]);
	const [pageContent, setPageContent] = useState([]);
	const [games, setGames] = useState([]);
	const [loading, setLoading] = useState(false);
	const [competitions, setCompetitions] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);
	const [selectionData, setSelectionData] = useState([]);
	const [selectedIds, setSelectedIds] = useState({
		sport_id: details ? details?.sport_slug : "",
		market_id: details ? details?.market_id : "",
		event_id: details ? details?.event_raw_id : "",
		event_name: details ? details?.event_name : "",
		competition_id: details ? details?.competition_raw_id : "",
		selection_id: details ? details?.selection_id : "",
		link_type: "generic",
	});
	const [error, setError] = useState({
		link_type: false,
		link: false,
		casino: false,
		sport: false,
		event: false,
	});

	const [fetching, setFetching] = useState({
		market: false,
	});

	const getSelectableSelections = options => {
		return options.map((option, index) => {
			if (selectedIds?.market_id === details?.market_id) {
				const selectedSelection = details?.selected_selections?.find(selection => selection.selection_id == option.selection_id);

				if (selectedSelection?.active == 1) {
					return {
						active: selectedSelection?.active,
						selection_name: selectedSelection.selection_name,
						selection_id: "" + selectedSelection.selection_id,
					};
				}
			} else if (index < 3) {
				return {
					selection_name: option.selection_name,
					selection_id: "" + option.selection_id,
					active: 1,
				};
			}

			return {
				selection_name: option.selection_name,
				selection_id: "" + option.selection_id,
				active: 0,
			};
		});
	};

	const getSports = () => {
		getSportsData().then(response => {
			setSportsData(response);
		});
	};

	const getPageContent = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.page_content, resolve, reject, true);
		}).then(response => {
			let data = response?.data;
			setPageContent(data);
		});
	};

	const getGames = async value => {
		const games = await getCasinoGames(value);
		setGames(games);
	};

	const getMarketsData = async (event_id, sport_slug) => {
		setFetching({ ...fetching, market: true });
		const isRacing = selectedIds?.sport_id == "horseracing" || selectedIds?.sport_id == "greyhoundracing";
		const data = await getEventMarketsDropdown(event_id, sport_slug);

		if (isRacing) {
			const firstMarket = data.find(item => item.market_id == "c1");
			if (firstMarket) {
				setMarkets([firstMarket]);
				setSelectedIds({ ...selectedIds, market_id: firstMarket.market_id });
			}
		} else setMarkets(data.map(({ market_id, market_name }) => ({ id: market_id, label: market_name })));
		setFetching({ ...fetching, market: false });
	};

	const getCompetitions = sport_id => {
		getCompetitionsDropdown(sport_id).then(response => {
			if (response.length > 0) {
				setCompetitions(response);
			}
		});
	};

	const getMatches = async competition_id => {
		let events = await getMatcheDropdown(competition_id);
		setEvents(events);
	};

	const getSelectionData = sport_id => {
		new Promise((resolve, reject) => {
			ApiServices.get(
				`${apiUrls.SELECTIONS}?sport_slug=${sport_id}&market_id=${selectedIds.market_id}&match_id=${selectedIds.event_id}`,
				resolve,
				reject,
				true
			);
		}).then(response => {
			setSelectionData(getSelectableSelections(response.data));
		});
	};

	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};

	const handleAcceptedFiles = () => {};

	const editCarousel = () => {
		const dynamicFields = getDynamicValue(carouselData, selectedIds, editItem);
		let payload = {
			published: carouselData?.checked ? "1" : "0",
			promo_type: carouselData?.promoType,
			title: carouselData?.title,
			show_title: carouselData?.show_title ? 1 : 0,
			subtitle: carouselData?.subtitle,
			image: carouselData?.image,
			image_exist: carouselData?.image_exist,
			link: carouselData?.link,
			start_date: convertFormatDateInUtc(carouselData?.startDate),
			end_date: convertFormatDateInUtc(carouselData?.endDate),
			type: "carousel",
			country_code: countires ? countires : "all",
			name: "",
			link_type: carouselData?.link_type,
			sport_id: dynamicFields.sport_id,
			event_id: dynamicFields.event_id,
			event_name: dynamicFields?.event_name,
			market_id: dynamicFields.market_id,
			competition_id: dynamicFields.competition_id,
			never_expire: carouselData?.neverExpire ? 1 : 0,
			page_type: pageLayoutType,
			call_to_action: carouselData?.call_to_action ? carouselData?.call_to_action : "",
			specific_days: carouselData?.specific_days ? 1 : 0,
			selected_days: carouselData?.selected_days,
			game_id: carouselData?.game_id,
			selection_id: dynamicFields.selection_id,
			selected_selections: selectionData.filter(x => x.active === 1),
		};

		if (carouselData?.promoType == "default" && carouselData?.link_type === "generic") {
			const shouldStop = checkGenericUrl(carouselData?.link);
			if (shouldStop) return;
		}

		let language = languageToString(carouselData, ["title_", "subtitle_", "call_to_action_"]);
		// Object.entries(carouselData).forEach(([key, value]) => {
		// 	if (key.includes("title_") || key.includes("subtitle_") || key.includes("call_to_action_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });
		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };

		if (editItem) {
			payload.id = editItem?.id;
		}

		if (!carouselData?.promoType) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select promo type</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!carouselData?.title) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter title</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!carouselData?.startDate) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select start date</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		if (!carouselData?.endDate && !carouselData.neverExpire) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select end date</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		if (carouselData["promoType"] !== "dynamic") {
			if (carouselData.link_type === "sport" && !carouselData.sport_id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select sport</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}

			if (carouselData.link_type === "event" && (!carouselData.sport_id || !carouselData.event_id)) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select sport and event</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}
		}

		if (carouselData["promoType"] === "dynamic") {
			if (!selectedIds.sport_id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select sport</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}
			if (!selectedIds.event_id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select event</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}
		}

		setLoading(true);
		if (carouselData?.link_type == "sport" && carouselData?.promoType == "default") {
			delete payload.competition_id;
			delete payload.event_id;
			delete payload.event_name;
		}

		if (carouselData?.promoType === "dynamic") {
			payload.link_type = "event";
		}

		new Promise((resolve, reject) => {
			ApiServices.post(editItem ? apiUrls.carousel_edit : apiUrls.carousel_add, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				getCarousel();
				handler();
				setSelectedIds({
					...selectedIds,
					sport_id: "",
					market_id: "",
					event_id: "",
					selection_id: "",
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const getPage = async () => {
		let page = await getAllMenuLink();
		setPageType(page);
	};

	useEffect(() => {
		getPageContent();
		getGames();
		getSports();
		getPage();
		if (details) {
			let newData = { ...carouselData };
			Object.entries(details).forEach(([key, value]) => {
				if (key.includes("subtitle_") || key.includes("title_") || key.includes("call_to_action_")) {
					newData = { ...newData, [key]: value };
				}
			});
			setCarouselData(newData);
		}
	}, []);

	useEffect(() => {
		if (carouselData?.sport_id) {
			getCompetitions(carouselData?.sport_id);
		}
	}, [carouselData?.sport_id]);

	useEffect(() => {
		if (selectedIds?.event_id) {
			getMarketsData(selectedIds?.event_id, selectedIds?.sport_id);
		}
	}, [selectedIds?.event_id]);

	useEffect(() => {
		if (carouselData?.competition_id) {
			getMatches(carouselData?.competition_id);
		}
	}, [carouselData?.competition_id]);

	useEffect(() => {
		if (selectedIds?.sport_id) {
			getCompetitions(selectedIds?.sport_id);
		}
	}, [selectedIds?.sport_id]);

	useEffect(() => {
		if (selectedIds?.competition_id) {
			getMatches(selectedIds?.competition_id);
		}
	}, [selectedIds?.competition_id]);

	useEffect(() => {
		if (carouselData?.market_id) {
			getSelectionData(carouselData?.sport_id);
		}
	}, [carouselData?.market_id]);

	useEffect(() => {
		if (selectedIds?.market_id) {
			getSelectionData(selectedIds?.sport_id);
		}
	}, [selectedIds?.market_id]);

	const handleInputChange = (e, selectionId) => {
		const newValue = e.target.value;
		const updatedArray = selectionData.map(item => (item.selection_id === selectionId ? { ...item, selection_name: newValue } : item));
		setSelectionData(updatedArray);
		setDataChanged(true);
	};

	const handleCheckBox = row => {
		setDataChanged(true);
		setSelectedIds({ ...selectedIds, selection_id: row.selection_id });

		let updatedArray;
		if (row?.active === 1) {
			updatedArray = selectionData.map(item => (item.selection_id === row.selection_id ? { ...item, active: 0 } : item));
		} else {
			updatedArray = selectionData.map(item => (item.selection_id === row.selection_id ? { ...item, active: 1 } : item));
		}
		setSelectionData(updatedArray);
	};

	useEffect(() => {
		if (selectedIds?.sport_id && details?.sport_slug != selectedIds?.sport_id) {
			setSelectedIds({ ...selectedIds, market_id: "", event_id: "", event_name: "", competition_id: "" });
			setMarkets([]);
			setEvents([]);
		}
	}, [selectedIds?.sport_id]);

	const limitNumberOfSelected = selectionData.filter(row => row.active == 1).length > 2;

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent
					dataChanged={dataChanged}
					data={carouselData}
					loading={loading}
					formId="avForm"
					saveHandler={editCarousel}
					type="submit"
					onClose={handler}
				/>

				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left selection-promotion-inputs">
						<AvForm id="avForm" onClick={() => !onFocus.current && handleShowOptions()}>
							<SwitchInput
								handleChange={() => {
									handleDataChanged();
									setCarouselData({ ...carouselData, checked: !carouselData?.checked });
								}}
								object={{ fieldName: "published", value: carouselData?.checked, name: "Published", firstRowClass: true }}
							/>
							<PromoTypeInput data={carouselData} setData={setCarouselData} handleDataChanged={handleDataChanged} otherRowClass />
							<GeneralInput
								object={{
									name: "Title",
									value: carouselData.title,
									onFocus: () => handleShowOptions("language", "title"),
									onBlur: () => (onFocus.current = false),
									required: true,
								}}
								onChange={e => {
									handleDataChanged();
									setCarouselData({ ...carouselData, title: e.target.value });
								}}
							/>
							<SwitchInput
								handleChange={() => {
									handleDataChanged();
									setCarouselData({ ...carouselData, show_title: !carouselData?.show_title });
								}}
								object={{ fieldName: "show_title", value: carouselData?.show_title, name: "Show Title" }}
							/>
							<GeneralInput
								object={{
									name: "Subtitle",
									value: carouselData.subtitle,
									onFocus: () => handleShowOptions("language", "subtitle"),
									onBlur: () => (onFocus.current = false),
								}}
								onChange={e => {
									handleDataChanged();
									setCarouselData({ ...carouselData, subtitle: e.target.value });
								}}
							/>
							<ImageInput
								object={{
									name: "Media",
									onFocus: () => handleShowOptions("image", true),
									onBlur: () => (onFocus.current = false),
									value: carouselData?.image,
									otherRowClass: true,
									fieldName: "image",
									active: showOptions.image,
									data: carouselData,
									setData: setCarouselData,
									setDataChanged: setDataChanged,
									imageLibaryType: "carousel",
									dimensions: "(640 x 320 px)",
								}}
							/>
							{carouselData["promoType"] === "dynamic" ? (
								<>
									<SportInput sportsData={sportsData} data={selectedIds} setData={setSelectedIds} eDataChanged={handleDataChanged} otherRowClass />
									<CompetitionInput
										competitions={competitions}
										data={selectedIds}
										setData={setSelectedIds}
										handleDataChanged={handleDataChanged}
										otherRowClass
										notOptional
									/>
									<EventIDInput eventsData={events} data={selectedIds} setData={setSelectedIds} handleDataChanged={handleDataChanged} otherRowClass />
									{/* <MarketInput
										markets={markets}
										data={selectedIds}
										setData={setSelectedIds}
										handleDataChanged={handleDataChanged}
										otherRowClass
										notOptional
									/> */}
									<GeneralDropdown
										object={{
											name: "Market",
											options: markets,
											required: true,
											loading: fetching?.market,
											value: selectedIds?.market_id,
										}}
										handleChange={e => {
											handleDataChanged();
											setSelectedIds({ ...selectedIds, market_id: e?.id });
										}}
									/>

									{selectedIds?.market_id && selectionData?.length > 0 && (
										<SelectionInput
											object={{
												field: "selection_name",
												fieldId: "selection_id",
												data: selectionData,
												otherRowClass: true,
												name: "Selections",
												checkBoxOnChange: (row, index) => handleCheckBox(row, index),
												disabledOption: limitNumberOfSelected,
											}}
											onChange={(e, row) => {
												handleInputChange(e, row?.selection_id);
											}}
										/>
									)}
								</>
							) : (
								<>
									<LinkTypeInput
										data={carouselData}
										setData={setCarouselData}
										setCompetitions={setCompetitions}
										setEvents={setEvents}
										pageContent={pageContent}
										games={games}
										getGames={getGames}
										error={error}
										pageType={pageType}
										setError={setError}
										events={events}
										competitions={competitions}
										sportsData={sportsData}
										handleDataChanged={handleDataChanged}
										otherRowClass
									/>
									<GeneralInput
										object={{
											name: "CTA",
											placeholder: "Call to Action",
											value: carouselData.call_to_action,
											onFocus: () => handleShowOptions("language", "call_to_action"),
											onBlur: () => (onFocus.current = false),
										}}
										onChange={e => {
											handleDataChanged();
											setCarouselData({ ...carouselData, call_to_action: e.target.value });
										}}
									/>
								</>
							)}
							<StartDateInput data={carouselData} setData={setCarouselData} handleDataChanged={handleDataChanged} otherRowClass required={true} />
							<NeverExpireInput
								checked={carouselData?.neverExpire}
								handleChange={() => {
									setCarouselData({
										...carouselData,
										neverExpire: !carouselData?.neverExpire,
									});
									handleDataChanged();
								}}
								otherRowClass
							/>
							{!carouselData?.neverExpire && (
								<EndDateInput
									data={carouselData}
									setData={setCarouselData}
									handleDataChanged={handleDataChanged}
									otherRowClass
									type="sport_widget"
									required={true}
								/>
							)}
							<SpecificDaysInput data={carouselData} setData={setCarouselData} handleDataChanged={handleDataChanged} otherRowClass />
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
						{showOptions.language && (
							<LanguageTitle data={carouselData} setData={setCarouselData} title={showOptions.language} handleDataChanged={handleDataChanged} />
						)}

						{showOptions.image && (
							<ImageLibrary
								data={carouselData}
								setData={setCarouselData}
								handleAcceptedFiles={handleAcceptedFiles}
								type="carousel"
								handleDataChanged={handleDataChanged}
								handleShowOptions={handleShowOptions}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
