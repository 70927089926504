export const getCmsUserActivityFilterColumns = ({ userList }) => {
	return [
		{
			name: "Date from-to",
			type: "date",
			value: "date",
			defaultValue: "24H",
		},
		{
			name: "CMS User",
			type: "select",
			value: "user_id",
			optionKey: "value",
			optionName: "name",
			data: JSON.stringify(userList),
		},
	];
};
