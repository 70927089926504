// const { default: ApiServices } = require("utils/ApiServices");
// const { apiUrls } = require("utils/constants");

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

export const getCountriesDropdown = async () => {
	if (sessionStorage.getItem("_countries")) {
		return JSON.parse(sessionStorage.getItem("_countries"));
	}
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.country_list, resolve, reject, true);
	})
		.then(response => {
			let countries = response?.data;
			countries = countries.map(row => {
				return { id: row.cca2, label: row.name, ...row };
			});
			sessionStorage.setItem("_countries", JSON.stringify(countries));
			return countries;
		})
		.catch(() => {
			return [];
		});
};
