import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import { isJSON } from "utils/global";

export const getLandingPagesTableColumns = ({ setDeleteItem, deleteIcon, landingPagesData, props, setCloneItem }) => {
	return [
		{
			name: "title",
			label: "Title",
			className: "",
			options: {
				setCellProps: () => ({ style: { minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" } }),
				customBodyRender: (value, meta) => {
					const row = landingPagesData[meta.currentTableData[meta.rowIndex].index];
					let title = row?.title;
					const parsedTitle = isJSON(title);
					return (
						<>
							<div className="capitalizeTitle">{parsedTitle?.title ? parsedTitle?.title : parsedTitle}</div>
						</>
					);
				},
			},
		},
		{
			name: "status",
			label: "STATUS",
			className: "name",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = landingPagesData[meta.currentTableData[meta.rowIndex].index];
					let published = row?.published;
					return (
						<>
							{published == 1 ? (
								<span className="badge badge-sports-published"> Published </span>
							) : (
								<span className="badge badge-sports-inactive"> Inactive </span>
							)}
						</>
					);
				},
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "",
			label: "ASSIGNED",
			className: "",
			options: {
				setCellProps: () => ({ style: { minWidth: "120px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "120px" } }),
				customBodyRender: (value, meta) => {
					const row = landingPagesData[meta.currentTableData[meta.rowIndex].index];
					let affiliateAssigned = row?.affiliate_id ? "Yes" : "No";
					return (
						<>
							<div className="capitalizeTitle">{affiliateAssigned}</div>
						</>
					);
				},
			},
		},
		{
			name: "",
			align: "right",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = landingPagesData[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<div className="d-flex landing-buttons">
								<div className="heroBanners-buttons">
									<div
										className="badge  badge-sports-published manual_bet_buttons"
										onClick={() => {
											setCloneItem(record);
										}}
									>
										Clone
									</div>
									<EditIconButton
										onClick={() => {
											props.history.push(`/promo_landing_pages/${record.id}`);
										}}
									/>
									<DeleteIconButton
										deleteIcon={deleteIcon}
										onClick={() => {
											setDeleteItem(record);
										}}
									/>
								</div>
							</div>
						</>
					);
				},
				setCellProps: () => ({ style: { minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),
			},
		},
	];
};
