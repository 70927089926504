import React from "react";
import { PlusAddButton } from "components/GeneralComponents/AddButton";
import { DeleteIcon, DragAndDropFillIcon, DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone, XCloseIcon } from "utils/icons";
import { useEffect, useState } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
// import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import DragAndDropList from "components/GeneralComponents/DragAndDrop";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";

export const ShowEditDefaultSportModal = ({
	showEditSport,
	sportOrderData,
	sportItems,
	setShowEditSport,
	handleAddSportOrder,
	addSpinning,
	editId,
	setSportOrderData,
	isUserReadOnly,
	sportSlugs,
	setSportSlugs,
	countriesArr,
}) => {
	const [dataChanged, setDataChanged] = useState(false);
	let countries = useSelector(state => state.Login.gsData.countries);
	let newCountries = countries.filter(row => !countriesArr.includes(row?.cca2));

	const [deletedSport, setDeletedSport] = useState([]);
	const [activeSport, setActiveSport] = useState([]);

	const handleAddSport = row => {
		let sport_slugs = [...sportSlugs];
		sport_slugs.push(row?.slug);

		setDataChanged(true);
		setSportSlugs(sport_slugs);
		setActiveSport([...activeSport, row]);
		setDeletedSport(deletedSport.filter(row2 => row2.slug != row.slug));
	};

	const deleteSport = deleteId => {
		let actualDeleted = [...deletedSport];
		actualDeleted.push(deleteId);

		setDeletedSport(actualDeleted);
		setActiveSport(activeSport.filter(row => row.slug != deleteId?.slug));
		setSportSlugs(sportSlugs.filter(sport => sport !== deleteId?.slug));

		setDataChanged(true);
	};

	useEffect(() => {
		if (!newCountries.some(row => row.name === "All Countries")) {
			newCountries.unshift({ name: "All Countries", cca2: 0 });
		}
	}, []);

	const handleGetSports = () => {
		let activeSportArr = [];
		let deletedSportArr = [];

		sportItems.forEach((row, i) => {
			var sportId = sportItems[i].slug;
			var checkExists = sportSlugs.some(element => element == sportId);
			if (!checkExists) {
				const obj = { id: sportItems[i].id, name: sportItems[i].name, slug: sportItems[i].slug };
				deletedSportArr.push(obj);
			} else {
				const obj = { id: sportItems[i].id, name: sportItems[i].name, slug: sportItems[i].slug };
				activeSportArr.push(obj);
			}
		});

		activeSportArr = sportSlugs.map(slug => activeSportArr.find(item => item?.slug === slug));

		setDeletedSport(deletedSportArr);
		setActiveSport(activeSportArr);
	};

	useEffect(() => {
		handleGetSports();
	}, []);

	const setDregAndDropData = newData => {
		let newSportSlugs = [];

		setActiveSport(newData);
		newData.forEach(row => newSportSlugs.push(row.slug));

		setSportSlugs(newSportSlugs);
		setDataChanged(true);
	};

	const changeOrder = index => {
		const selectedRow = activeSport.splice(index, 1)[0];
		const newData = [selectedRow, ...activeSport];

		setDregAndDropData(newData);
	};

	return (
		<>
			{showEditSport && (
				<div className="promotion-container">
					<div className="promotion-header">
						<span>Sport Order</span>
						<XCloseIcon onClick={() => setShowEditSport(false)} className={"xclose-icon"} />
					</div>
					<div className="promotion-body">
						<TopBarComponent
							saveHandler={() => {
								handleAddSportOrder();
								setDataChanged(false);
							}}
							loading={addSpinning}
							dataChanged={dataChanged}
							onClose={() => setShowEditSport(false)}
						/>
						<div className="promotion-elements row m-0">
							<div className="promotion-left col-12 col-lg-6 promotion-inputs pt-5 pt-md-3 pb-3 pb-md-0">
								<div className="row mb-3">
									<div className="col-4 d-flex align-items-center">Country</div>
									<div className="col-8 dropdown-wrapper">
										<AvForm>
											{editId ? (
												<div className="sport-selected-country">{sportOrderData.country_name}</div>
											) : (
												<GeneralDropdown
													object={{
														options: newCountries.map(({ cca2, name }) => ({ id: cca2, label: name })),
														value: sportOrderData.country_code,
														bindValue: sportOrderData.country_name,
														wrapperClassNameDropdown: "p-0",
														handleChangeDropdown: newValue => setSportOrderData({ ...sportOrderData, country_code: newValue.id }),
													}}
												/>
											)}
										</AvForm>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-4 d-flex mb-3 mb-md-0">Order List</div>
									<div className="col-12 col-md-8 sport-order-list custom-scrollbar">
										<DragAndDropList data={activeSport} setData={newData => setDregAndDropData(newData)} className="sport-list-dreg-drop">
											{(row, { index, dragHandleProps, isDragging }) => {
												return (
													<div className={`laegues-item mb-0 `}>
														<span>{row?.name}</span>

														<div className="d-flex justify-content-end">
															<div className="heroBanners-buttons">
																<DeleteIcon className="casino-icon no-margin cursor-pointer " role="button" onClick={() => deleteSport(row)} />
																{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}
																<div
																	{...dragHandleProps}
																	onMouseDown={(...args) => {
																		dragHandleProps?.onDragStart(...args);
																	}}
																>
																	<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
																</div>
															</div>
														</div>
													</div>
												);
											}}
										</DragAndDropList>
									</div>
								</div>
							</div>
							<div className="promotion-right col-12 col-lg-6">
								<div className={`row mb-3 ${isUserReadOnly && "pe-none"}`}>
									<div className="col-12 col-md-4 d-flex mb-3 mb-md-0">All Sports</div>
									<div className="col-12 col-md-8 sports-list custom-scrollbar">
										{deletedSport
											.sort((a, b) => {
												return a.name.trim().toLowerCase() < b.name.trim().toLowerCase() ? -1 : 1;
											})
											.map(row => {
												return (
													<div className="laegues-item" key={row.id}>
														<span>{row?.name}</span>
														<div className="element-end no-margin">
															<PlusAddButton onClick={() => handleAddSport(row)} id={""} loading={false} />
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* {deleteId?.slug && (
						<SweetAlert title={"Confirm delete"} showConfirm={false} customClass={"delete-conf-sweet"}>
							<div style={{ position: "relative" }}>
								<div className="">
									<span className="delete-span">Are you sure you want to delete &quout;{deleteId?.name}&quout; ?</span>
								</div>
							</div>
							<div className="d-flex justify-content-end" style={{ marginTop: "70px" }}>
								<NoButton onClick={() => setDeleteId(null)} name="No" />
								{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={() => deleteSport()} />}
							</div>
						</SweetAlert>
					)} */}
				</div>
			)}
		</>
	);
};
