import React from "react";
import { StatusInput } from "components/GeneralComponents/StatusInput";
import { userReadOnly } from "utils/global";

export const getLanguagesTableColumn = ({ languages, editLanguageStatus }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);
	return [
		{
			name: "language_name",
			label: "LANGUAGE",
			className: "language_name",
			align: "left",
			options: {
				setCellProps: () => ({ style: { textAlign: "left" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "200px", width: "200px" } }),
			},
		},
		{
			name: "code2",
			label: "CODE",
			className: "code2",
			align: "left",
			options: {
				setCellProps: () => ({ style: { textAlign: "left" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "200px", width: "200px" } }),
			},
		},
		{
			name: "in_use",
			label: "STATUS",
			className: "",
			align: "left",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = languages[meta.currentTableData[meta.rowIndex].index];

					return (
						<StatusInput
							checked={record.in_use == true ? true : false}
							onChange={() => {
								let language_name = record.language_name;
								let in_use = record.in_use;
								let code = record?.code2;
								editLanguageStatus({ language_name, in_use, code });
							}}
							disabled={isUserReadOnly}
						/>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "max-content", width: "max-content" }, className: "max-button" }),
			},
		},
	];
};
