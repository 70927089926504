import React from "react";
import HTMLReactParser from "html-react-parser";
import { getFormatDateByUserTimezone } from "utils/global";
import { ThousandSeperated } from "hooks/General";

export const getPendingWithdrawals = ({ bankingPendingWithdrawal, setItemTitle, setDeleteItem }) => {
	const data = [
		{
			name: "transaction_dt",
			label: "DATE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankingPendingWithdrawal[meta.currentTableData[meta.rowIndex].index];
					const date = getFormatDateByUserTimezone(row.transaction_dt, "YYYY-MM-DD");
					return <>{date}</>;
				},
				setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "160px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px", maxWidth: "160px" } }),
			},
		},
		{
			name: "amount",
			label: "AMOUNT",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = bankingPendingWithdrawal[meta.currentTableData[meta.rowIndex].index];
					return <span>{ThousandSeperated(record?.amount)}</span>;
				},
				setCellProps: () => ({ className: "ms-3" }),
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "id",
			label: "TXN ID",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = bankingPendingWithdrawal[meta.currentTableData[meta.rowIndex].index];
					return <span>{record?.id}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "provider",
			label: "PROVIDER",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = bankingPendingWithdrawal[meta.currentTableData[meta.rowIndex].index];
					let title = record?.provider;
					let formattedTitle = title
						?.replace(/_/g, " ")
						?.toLowerCase()
						?.replace(/(?:^|\s)\S/g, function (char) {
							return char.toUpperCase();
						});
					return <span>{formattedTitle}</span>;
				},
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},

		{
			name: "status",
			label: "STATUS",
			align: "right",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = bankingPendingWithdrawal[meta.currentTableData[meta.rowIndex].index];
					var status_txt =
						record.status === `<span class="badge badge-warning"> Started </span>`
							? `<span className="badge badge-sports-green"> Active </span>`
							: record.status === `<span class="badge badge-secondary"> Not Started </span>`
							? `<span className="badge badge-sports-secondary"> Not Started </span>`
							: `<span className="badge badge-sports-yellow"> Pending </span>`;
					return HTMLReactParser(status_txt);
				},
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},

		process.env.REACT_APP_SHOW_CANCEL_WITHDRAWAL == "1"
			? {
					name: "",
					label: "",
					align: "left",
					sortable: true,
					sort: "asc",
					options: {
						viewColumns: false,
						customBodyRender: (value, meta) => {
							const row = bankingPendingWithdrawal[meta.currentTableData[meta.rowIndex].index];
							return (
								<span
									onClick={() => {
										setItemTitle("Withdrawal");
										setDeleteItem(row);
									}}
									style={{ cursor: "pointer" }}
									className="badge badge-sports-red"
								>
									Cancel
								</span>
							);
						},
						setCellProps: () => ({ style: { textAlign: "right", fontSize: "10px !important" } }),
					},
			  }
			: null,
	];

	return data?.filter(item => !!item);
};
