export const getDefaultSportsFilterTable = ({ countries }) => {
	const countriesData = countries ? JSON.stringify(countries) : [];

	return [
		{
			name: "Country",
			type: "select",
			value: "country",
			data: countriesData,
			optionKey: "cca2",
			optionName: "name",
		},
	];
};
