import React from "react";
import { InfoIconHelper, XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { useState, useEffect } from "react";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { useDispatch } from "react-redux";
import InformationPageHelper from "pages/Settings/InformationPageHelper";
import { setBackdrop } from "store/Backdrop/actions";
import { CountryHelpInformation } from "pages/Settings/HelpPagesForSettings/HelpPages";

export const ShowEditCountryModal = props => {
	const { editCountryData, setEditCountryData, saveCountryData, loading } = props;

	const [dataChanged, setDataChanged] = useState(false);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();

	const [fields, setFields] = useState([]);

	let title = "Countries";

	useEffect(() => {
		const userFields = [
			{ type: "input", name: "Country", fieldName: "name", disabled: true },
			{ type: "switch", name: "Master Geoblock", fieldName: "master_geoblock" },
			{ type: "switch", name: "Mobile Geoblock", fieldName: "mobile_geoblock" },
			{ type: "input", name: "Disabled Reasons", fieldName: "disabled_reason" },
			{ type: "dropdown", name: "Default Currency", fieldName: "default_currency", optionsType: "currency" },
			{
				type: "dropdown",
				name: "Default Odd Format",
				fieldName: "default_odd_format",
				required: true,
				optionsType: "defaultOddFormat",
			},
			{ type: "empty_row", name: "Onboarding", inputClassName: "empty_row_container" },

			{ type: "switch", name: "KYC during onboarding", fieldName: "kyc_during_onboarding" },
			{ type: "switch", name: "Verify Mobile Number", fieldName: "phone_required" },
			{ type: "switch", name: "Phone Prefix to Match Country", fieldName: "phone_same_country_required" },
			{ type: "empty_row", name: "Deposits and Withdrawals", inputClassName: "empty_row_container" },
			{
				type: "switch",
				name: "Allowed amount without KYC",
				fieldName: "allow_deposit_without_kyc",
				inputClassName: "",
				emptyValues: ["deposit_amount_without_kyc"],
			},
		];

		if (editCountryData?.allow_deposit_without_kyc) {
			userFields.push({
				type: "input",
				name: "Amount",
				fieldName: "deposit_amount_without_kyc",
				icon: editCountryData?.default_currency || "EUR",
				iconInsideInput: true,
			});
		}

		userFields.push(
			{ type: "switch", name: "Set deposit limits on initial deposit", fieldName: "set_limit_initial_deposit" },
			{ type: "switch", name: "Require Mobile No", fieldName: "require_mobile_no" },
			{ type: "switch", name: "Check KYC verified on deposit", fieldName: "check_kyc_on_deposit" },
			{ type: "switch", name: "Check KYC verified on withdrawal", fieldName: "check_kyc_on_withdraw" }
		);

		setFields(userFields);
	}, [editCountryData?.allow_deposit_without_kyc]);

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	const dep = { allow_deposit_without_kyc: editCountryData?.allow_deposit_without_kyc };

	return (
		<>
			<div className="promotion-container">
				<div className="promotion-header">
					<span>{title}</span>
					<XCloseIcon onClick={() => setEditCountryData(false)} className={"xclose-icon"} />
				</div>
				<div className="promotion-body">
					<TopBarComponent saveHandler={() => saveCountryData()} dataChanged={dataChanged} loading={loading} onClose={() => setEditCountryData(false)} />
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
							<AllComponentsLoop fields={fields} data={editCountryData} setData={setEditCountryData} setDataChanged={setDataChanged} dependecies={dep} />
						</div>
						<div className="promotion-right col-12 col-lg-6"></div>
					</div>
				</div>
			</div>
			{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<CountryHelpInformation />
				</InformationPageHelper>
			)}
		</>
	);
};
