import { React } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { useState } from "react";
import { XCloseIcon } from "utils/icons";
import ApiServices from "utils/ApiServices";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { apiUrls } from "utils/const.apiUrl";

const CurrenciesModal = props => {
	const { editItem, title, handler, setNewData, currencies, setShowPromotion } = props;

	const [currenciesData, setCurrencyData] = useState({
		id: editItem?.id,
		code: editItem?.code,
		symbol: editItem?.symbol,
		name: editItem?.name,
		enabled: editItem?.enabled,
	});

	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState(false);

	const editCurrencies = () => {
		setLoading(true);
		let payload = {
			code: currenciesData?.code,
			symbol: currenciesData?.symbol,
			name: currenciesData?.name,
			enabled: currenciesData?.enabled,
		};
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.edit_currencies, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				if (editItem) {
					let updatedData = [...currencies];
					let index = updatedData.findIndex(item => item.id === editItem?.id);
					updatedData[index] = payload;
					setNewData(updatedData);
				} else {
					setNewData([...currencies, payload]);
				}
				handler();
			})
			.finally(() => {
				setLoading(false);
				setShowPromotion(false);
			});
	};

	const fields = [
		{
			type: "input",
			name: "Currency",
			fieldName: "code",
			required: true,
			textUpperCase: true,
			disabled: true,
		},
		{
			type: "input",
			name: "Symbol",
			fieldName: "symbol",
			required: true,
		},
		{ type: "input", name: "Description", fieldName: "name", required: true },
		{
			type: "switch",
			name: "Enabled",
			fieldName: "enabled",
			required: true,
			disabled: true,
		},
	];

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editCurrencies} dataChanged={dataChanged} loading={loading} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AllComponentsLoop fields={fields} data={currenciesData} setData={setCurrencyData} setDataChanged={setDataChanged} />
					</div>
					<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left"></div>
				</div>
			</div>
		</div>
	);
};

export default CurrenciesModal;
