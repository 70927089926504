import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { setBreadcrumbItems, setTelebetUser } from "../../../store/actions";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

import { TelebetBetslip } from "./telebetBetslip.component";
import TelebetCompetitions from "./telebetCompetitions.component";
import TelebetEvent from "./telebet-event.container";

import { TelebetSidebar } from "./TelebetSidebar";
import { useLocation } from "react-router-dom";

import useWindowSize from "hooks/useWindowSize";
import "./telebet.scss";

function Telebet() {
	const { search } = useLocation();

	const user = useSelector(state => state.Login.user);
	const telebetUser = useSelector(state => state.Telebet.telebetUser);

	const { width } = useWindowSize();
	const isShowBetslip = width < 1350;

	const [showBetslip, setShowBetslip] = useState(!isShowBetslip);

	const queryEvents = useMemo(() => {
		const params = new URLSearchParams(search);
		return params.get("event")?.toString();
	}, [search]);

	const dispatch = useDispatch();

	const getUserStats = () => {
		const player_status = telebetUser?.swifty_id;
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_USER_STATS + player_status, resolve, reject, true);
		}).then(response => {
			if (response.success) {
				const newUser = { ...telebetUser, userBalance: response?.data?.mainBalance };
				dispatch(setTelebetUser(newUser));
			}
		});
	};

	useEffect(() => {
		getUserStats();
	}, []);

	useEffect(() => {
		setShowBetslip(!isShowBetslip);
	}, [isShowBetslip]);

	return (
		<HelmetLayout titleProps={"Telebet"}>
			<div className="row m-0 telebet-height telebet-page">
				<TelebetSidebar />

				<div className="telebet-center">
					{queryEvents ? <TelebetEvent id={queryEvents} setShowBetslip={setShowBetslip} /> : <TelebetCompetitions setShowBetslip={setShowBetslip} />}
				</div>

				{showBetslip && <TelebetBetslip user={user} telebetUser={telebetUser} getUserStats={getUserStats} setShowBetslip={setShowBetslip} />}
			</div>
		</HelmetLayout>
	);
}

export default connect(null, { setBreadcrumbItems })(Telebet);
