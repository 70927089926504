import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { setBreadcrumbItems } from "store/actions";
import { getLiabilitiesTableColumns } from "./liabilitiesTableColumns";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import { tableExtraButtons, tableResponsive, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { getLiabilitiesFilterColumns } from "./liabilitiesFilterColumns";
import SearchFilter from "components/SearchFilter/searchFilter";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
// import moment from "moment";
import MUIDataTable from "mui-datatables";
import { setLiabilitiesFilteredData } from "store/Liabilities/actions";
import { createParams, filterAndSortArray } from "utils/global";
import moment from "moment";

const Liabilities = () => {
	const [liabilitiesData, setLiabilitiesData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	// const liabilitiesFilterData = useSelector(state => state.Login.searchFilterData.liabilities);
	const sports = useSelector(state => state.Login.gsData.sports);
	const dispatch = useDispatch();
	const liabilitiesFilteredData = useSelector(state => state?.LiabilitiesData?.filteredData);

	const getLiabilitiesData = data => {
		let params = data;
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_LIABILITIES + params, resolve, reject, true);
		})
			.then(response => {
				let data = response?.data;
				let records = [];
				data.forEach(row => {
					records.push(row);
				});
				setLiabilitiesData(filterAndSortArray(records, "event_start_dt"));
				dispatch(setLiabilitiesFilteredData(records));
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const filterData = liabilitiesFilterData => {
		let dateTimeFrom = "";
		let dateTimeTo = "";
		let sport_slug = liabilitiesFilterData?.sport_id;

		if (liabilitiesFilterData?.date !== "24H") {
			const [startDate, endDate] = liabilitiesFilterData.date.split(" & ");
			dateTimeFrom = startDate;
			dateTimeTo = endDate;
		} else {
			dateTimeFrom = moment.utc().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss");
			dateTimeTo = moment.utc().format("YYYY-MM-DD 23:59:59");
		}
		const liabilitiesfilterData = { date_time_from: dateTimeFrom, date_time_to: dateTimeTo, sport_slug };
		const newBetsfilterPayloadData = ["sport_slug", "date_time_from", "date_time_to"];
		let params = createParams(liabilitiesfilterData, newBetsfilterPayloadData);

		if (params) {
			getLiabilitiesData(params);
		} else getLiabilitiesData("");
	};

	useEffect(() => {
		if (liabilitiesFilteredData) setLiabilitiesData(liabilitiesFilteredData);
	}, []);

	const liabilitiesFilterColumns = getLiabilitiesFilterColumns({ sports });
	const liabilitiesColumns = getLiabilitiesTableColumns({ liabilitiesData });

	return (
		<HelmetLayout titleProps={"Liabilities"}>
			<div className="cms-page pt-0 cms-table-page no-footer-table">
				<div className="col-12 d-flex">
					<SearchFilter
						columns={liabilitiesFilterColumns}
						page={"liabilities"}
						preSelectedType={"select"}
						preSelectedValue={"sport_id"}
						preSelectedName={"Sport"}
						preSelectedData={sports ? sports : []}
						preSelectedOptionKey={"id"}
						preSelectedOptionName={"name"}
						filterHandler={filterData}
						filterLoading={isDataLoading}
						customizedFilter={true}
					/>
				</div>
				<MUIDataTable
					columns={liabilitiesColumns}
					data={liabilitiesData}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						pagination: false,
						responsive: tableResponsive,
						downloadOptions: { filename: "Liabilities.csv" },
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(Liabilities);
