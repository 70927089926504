import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let prevSportSlug = "";
let result = [];

export const getMarketSportDropdown = async sport_slug => {
	if (!sport_slug) {
		result = [];
		prevSportSlug = "";
	}

	if (sport_slug && prevSportSlug !== sport_slug) {
		prevSportSlug = sport_slug;

		return new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.TRADING_MARKET_TYPE + "?sport_slug=" + sport_slug, resolve, reject, true);
		})
			.then(response => {
				let data = response?.data;
				data = data.map(row => {
					return { id: row.market_id, label: row.market_name };
				});

				result = data;
				return data;
			})
			.catch(() => {
				result = [];
				return [];
			});
	} else return result;
};
