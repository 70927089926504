import React, { useEffect, useState } from "react";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import MUIDataTable from "mui-datatables";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";
import { getApisTableColumns } from "./getApisTableColumns";
import { ApisAddAndEditModal } from "./ApisAddAndEditModal";
import { tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

function Apis() {
	const [data, setData] = useState([]);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [openModal, setOpenModal] = useState(false);
	const [modalItem, setModalItem] = useState(false);

	const getApisData = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_apis, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;
				setData(data);
				setUnfilteredData(data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		getApisData();
	}, []);

	const handleOpenModals = (type, row) => {
		if (type == "edit") setModalItem({ id: row.id, modalType: type });
		else setModalItem({ modalType: type });

		setOpenModal(true);
	};

	const apisTableColumns = getApisTableColumns({ data, handleOpenModals });

	return (
		<HelmetLayout titleProps={"APIs"}>
			<div className="cms-page pt-0">
				<SingleSearchFilter setData={setData} unfilteredData={unfilteredData} filterColumn={["name"]} />
				<MUIDataTable
					columns={apisTableColumns}
					data={data}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						sort: true,
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						responsive: tableResponsive,
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
						customToolbar: () => <AddTableButton onClick={() => handleOpenModals("add")} />,
					}}
				/>
			</div>

			{openModal && <ApisAddAndEditModal setOpenModal={setOpenModal} modalItem={modalItem} getApisData={getApisData} />}
		</HelmetLayout>
	);
}

export default Apis;
