import React, { useRef } from "react";
import { CloseIconIframe } from "utils/icons";
import useOutsideClick from "hooks/useOutsideClick";

const InformationPageHelper = ({ children, closeHandler }) => {
	const modalRef = useRef(null);

	useOutsideClick(modalRef, () => closeHandler());
	return (
		<div className="information_page_helper" ref={modalRef}>
			<CloseIconIframe className="close_icon_iframe" onClick={closeHandler} />
			{children}
		</div>
	);
};
export default InformationPageHelper;
