import React, { useEffect, useState, useRef } from "react";

import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";
// import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { XCloseIcon } from "utils/icons";
import { toast } from "react-toastify";
import xIcon from "../../../assets/images/x-icon.svg";

import BonusEngineDetails from "./bonusEngineDetails";
import BonusEngineRules from "./bonusEngineRules";
import { checkIfAllFieldsAreFilled } from "utils/global";
import { currencyRule } from "./rules";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import moment from "moment";

import "./bonusEngine.scss";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			className="tabs-tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={"span"}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const initialBonusDetails = {
	published: 1,
	name: "",
	description: "",
	valid_from: "",
	never_expire: 0,
	valid_to: "",
	terms_and_conditions: "",
};

const initialRule = {
	rule_type: currencyRule?.type,
	condition_type: currencyRule?.id,
	condition: currencyRule?.equality,
	first_value_type: currencyRule?.selected_type,
	first_value: "",
	second_value: "",
	second_value_type: "",
};

const BonusEngineSingle = ({ id: bonusId, setShowBonusEnginePromotion, records, setRecords }) => {
	const bonusDetailsRef = useRef();

	const [isDataLoading, setIsDataLoading] = useState(false);
	const [bonusRules, setBonusRules] = useState([initialRule]);
	const [bonusDetails, setBonusDetails] = useState(initialBonusDetails);
	const [tabValue, setTabValue] = useState(0);
	const [dataChanged, setDataChanged] = useState(false);
	const [id, setBonusId] = useState("");
	const [isSaving, setIsSaving] = useState(false);

	const handleTabChange = (event, newValue) => {
		if (id) {
			setTabValue(newValue);
		} else {
			checkIfAllFieldsAreFilled(bonusDetails, bonusDetailsRef.current?.fields);
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Required fields must be completed before generating bonus rules</p>
				</>,
				{ className: "custom-toast" }
			);
		}
	};

	const getBonusEngineRules = id => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.bonus_engine + `/${id}/rule`, resolve, reject, true);
		})
			.then(response => {
				const hasInitialValue = response.data.find(d => d.condition_type == initialRule?.condition_type);

				const sortedRules = [hasInitialValue, ...response.data.filter(d => d.condition_type !== initialRule?.condition_type)];

				const data = hasInitialValue ? sortedRules : [initialRule, ...response?.data];
				setBonusRules(data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const getBonusEngineDetails = id => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.bonus_engine + `/${id}/details`, resolve, reject, true);
		})
			.then(response => {
				setBonusDetails(response.data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		if (bonusId) {
			getBonusEngineRules(bonusId);
			getBonusEngineDetails(bonusId);

			setBonusId(bonusId);
		}
	}, [bonusId]);

	const onEdit = () => {
		if (tabValue == 0) editAddBonusDetails();
		if (tabValue == 1) editBonusRules();
	};

	const editAddBonusDetails = () => {
		let data = { ...bonusDetails };
		if (data?.valid_from) {
			data.valid_from = moment(bonusDetails?.valid_from).startOf("day").format("YYYY-MM-DD HH:mm:ss");
		}

		if (data?.valid_to) {
			data.valid_to = moment(bonusDetails?.valid_to).endOf("day").format("YYYY-MM-DD HH:mm:ss");
		}

		const fields = bonusDetailsRef.current?.fields;

		setIsSaving(true);

		console.log(data);

		if (id) {
			new Promise((resolve, reject) => {
				ApiServices.put(apiUrls.bonus_engine, resolve, reject, data, true, fields);
			})
				.then(response => {
					const data = response.data[0];

					const updatedRecords = records.map(record => (record.id == data?.id ? { ...record, ...data } : record));

					setRecords(updatedRecords);
					setShowBonusEnginePromotion(false);
					setDataChanged(false);
				})
				.finally(() => {
					setIsSaving(false);
				});
		} else {
			new Promise((resolve, reject) => {
				ApiServices.post(apiUrls.bonus_engine, resolve, reject, data, true, fields);
			})
				.then(response => {
					const data = response.data;
					setBonusId(data?.id);
					setBonusDetails(data);

					const updatedRecords = [data, ...records];
					setRecords(updatedRecords);
					setDataChanged(false);
				})
				.finally(() => {
					setIsSaving(false);
				});
		}
	};

	const editBonusRules = () => {
		setIsSaving(true);

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.bonus_engine + `/${id}/rule`, resolve, reject, { rules: bonusRules }, true);
		})
			.then(response => {
				console.log(response);
				setShowBonusEnginePromotion(false);
				setDataChanged(false);
			})
			.finally(() => {
				setIsSaving(false);
			});
	};

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Bonus Engine</span>
				<XCloseIcon
					className={"xclose-icon"}
					onClick={() => {
						setShowBonusEnginePromotion(false);
					}}
				/>
			</div>
			<div className="promotion-body">
				<TopBarComponent
					classname="topbar_component"
					saveHandler={onEdit}
					dataChanged={dataChanged}
					loading={isSaving}
					onClose={() => setShowBonusEnginePromotion(false)}
				/>
				<div className="tabs-container bonus-tabs-container">
					<div className="bonus-tabs-container_inner">
						<Box sx={{ width: "100%" }}>
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" variant="fullWidth">
									<Tab label="Details" {...a11yProps(0)} />
									<Tab label="Rules" {...a11yProps(1)} />
								</Tabs>
							</Box>
							<TabPanel value={tabValue} index={0}>
								<BonusEngineDetails
									details={bonusDetails}
									setDetails={setBonusDetails}
									setDataChanged={setDataChanged}
									isDataLoading={isDataLoading}
									ref={bonusDetailsRef}
								/>
							</TabPanel>
							<TabPanel value={tabValue} index={1}>
								<BonusEngineRules rules={bonusRules} setRules={setBonusRules} setDataChanged={setDataChanged} />
							</TabPanel>
						</Box>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BonusEngineSingle;
