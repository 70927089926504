import React from "react";
import { capitalizeText } from "services/capitalizeText";
import { toFixed } from "pages/BetTicker/BetTicketHelpers";
import { getFormatDateByUserTimezone } from "utils/global";

export const getAffiliateDashbordColumns = props => {
	const { tabelData } = props;
	return [
		{
			name: "player_id",
			label: "PLAYER ID",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "110px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "110px" }, className: "max-button" }),
			},
		},
		{
			name: "name",
			label: "PLAYER NAME",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = tabelData[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{capitalizeText(`${row?.first_name} ${row?.last_name}`)}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "130px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "sub_agent",
			label: "SUB AGENT",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = tabelData[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{row.sub_agent ? row.sub_agent : "-"}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "120px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "120px" }, className: "max-button" }),
			},
		},
		{
			name: "onboarded_date",
			label: "ONBOARDED DATE",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = tabelData[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{row.onboarded_date ? getFormatDateByUserTimezone(row.onboarded_date) : "-"}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "170px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "170px" }, className: "max-button" }),
			},
		},
		{
			name: "status",
			label: "STATUS",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = tabelData[meta.currentTableData[meta.rowIndex].index];
					let published = row?.status;

					return published ? <span className="badge badge-sports-published">Active</span> : <span className="badge badge-sports-inactive">Inactive</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button" }),
			},
		},
		{
			name: "last_deposit",
			label: "LAST DEPOSIT",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = tabelData[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{row.last_deposit ? getFormatDateByUserTimezone(row.last_deposit) : "-"}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "130px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "casino_ggy",
			label: "CASINO GGY",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = tabelData[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{toFixed(row.casino_ggy)}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "130px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "sports_ggr",
			label: "SPORTS GGR",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = tabelData[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{toFixed(row.sports_ggr)}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "130px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "rev_share",
			label: "REV SHARE %",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = tabelData[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{row.rev_share ? row.rev_share : "-"}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "130px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "total_payable",
			label: "TOTAL PAYABLE",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = tabelData[meta.currentTableData[meta.rowIndex].index];
					return <span className="affiliate_users_row">{toFixed(row.total_payable)}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "140px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "140px" }, className: "max-button" }),
			},
		},
		{
			name: "currency",
			label: "CURRENCY",
			className: "name",
			align: "left",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
