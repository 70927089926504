import React from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";

const UserProfile = () => {
	return <HelmetLayout titleProps={"Profile"} />;
};

export default connect(null, { setBreadcrumbItems })(UserProfile);
