import React, { useEffect, createRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import { connect } from "react-redux";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { platformName } from "utils/constants";
import { getMarkets } from "services/getMarkets";
import { tableExtraButtons } from "utils/tableSettings";

const BetsPerMatch = props => {
	const ref = createRef();
	const breadcrumbItems = [
		{ title: "Bets", link: "/bets" },
		{ title: "Bets Per Match", link: "/bets/:id" },
	];
	const [records, setRecords] = useState([]);
	let rc = [];

	useEffect(() => {
		props.setBreadcrumbItems("Bets Per Match", breadcrumbItems);
		const params = `?active=1`;
		getMarkets(params).then(data => {
			data.map(betsType => {
				var markets_count = betsType.selection_count + " Markets";
				let obj = {
					market_name: betsType.market_name,
					football_markets_tranding_status: betsType.football_markets_tranding_status,
					selection_count: markets_count,
					active: betsType.active,
				};
				rc.push(obj);
			});
			setRecords(rc);
		});
	}, []);

	const columns = [
		{
			key: "market_name",
			text: "Market Name",
			className: "tag",

			sortable: true,
			sort: "asc",
		},
		{
			key: "football_markets_tranding_status",
			text: "Status",
			className: "tag",

			sortable: true,
			sort: "asc",
		},
		{
			key: "selection_count",
			text: "Selections",
			className: "tag",

			sortable: true,
			sort: "asc",
		},
		{
			key: "",
			text: "Active",
			className: "tag",

			sortable: true,
			sort: "asc",
		},
	];

	return (
		<React.Fragment>
			<MetaTags>
				<title>Bets Per Match - {platformName}</title>
			</MetaTags>
			<Row>
				<Col>
					<Card>
						<CardBody style={{ overflowX: "scroll" }}>
							<ReactDatatable
								className="table table-striped table-bordered"
								id="bets_per_match-table"
								ref={ref}
								config={{
									page_size: 20,
									length_menu: [10, 20, 50],
									sort: true,
									button: {
										extra: true,
									},
								}}
								records={records}
								columns={columns}
								extraButtons={tableExtraButtons}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default connect(null, { setBreadcrumbItems })(BetsPerMatch);
