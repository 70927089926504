import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

export const getCurrenciesDropdown = () => {
	if (sessionStorage.getItem("_currencies")) {
		return JSON.parse(sessionStorage.getItem("_currencies"));
	}
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.get_currencies, resolve, reject, true);
	})
		.then(response => {
			if (response.success) {
				let return_data = response.data;
				return_data = return_data.map(row => {
					return { id: row.code, label: row.name, symbol: row?.symbol };
				});
				sessionStorage.setItem("_currencies", JSON.stringify(return_data));
				return return_data;
			} else {
				return [];
			}
		})
		.catch(() => {
			return [];
		});
};
