import React from "react";
import { SmallAddIcon } from "utils/icons";

export const AddTableButton = props => {
	const { onClick, className } = props;
	return (
		<button className={`addNew_btn ${className}`} onClick={onClick}>
			<span>Add</span> <SmallAddIcon fillColor={"#FFF"} />
		</button>
	);
};
