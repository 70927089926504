import { React, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import { useState } from "react";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableRowsPerPageValue, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import ConfirmDeleteComponent from "components/GeneralComponents/ConfirmDeleteComponent";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";
import { filterAndSortArray } from "utils/global";
import { getLandingPagesTableColumns } from "./landingPagesTableColumns";
import deleteIcon from "../../../assets/images/delete.svg";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";
import { useDispatch } from "react-redux";
import { setBackdrop } from "store/Backdrop/actions";
import { InfoIconHelper } from "utils/icons";
import InformationPageHelper from "../InformationPageHelper";
import { LandingPageHelpInformation } from "../HelpPagesForSettings/HelpPages";

const LandingPages = props => {
	const [landingPagesData, setLandingPagesData] = useState([]);
	const [deleteItem, setDeleteItem] = useState(null);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const [cloneItem, setCloneItem] = useState("");
	const dispatch = useDispatch();
	const pageHeaderData = {
		international: false,
		addNew: true,
		addNewClick: () => {
			props.history.push("/promo_landing_pages/new");
		},
	};
	const getLandingPages = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_landing_pages, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					var data = response.data?.data;
					data = filterAndSortArray(data, "title");
					data = data.sort((a, b) => b.affiliate_id - a.affiliate_id);
					setLandingPagesData(data);
					setUnfilteredData(data);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const handleCloneItem = item => {
		const body = { id: item?.id };
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.clone_landing_pages, resolve, reject, body, true);
		})
			.then(() => {
				getLandingPages();
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		getLandingPages();
	}, []);

	const landingPagesColumns = getLandingPagesTableColumns({
		landingPagesData,
		props,
		setDeleteItem,
		deleteIcon,
		setCloneItem,
	});

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};
	let title = "";
	if (deleteItem) {
		title = JSON.parse(deleteItem?.title)?.title;
	}

	useEffect(() => {
		if (cloneItem) {
			handleCloneItem(cloneItem);
		}
	}, [cloneItem]);
	return (
		<>
			<HelmetLayout titleProps={"Promo Landing Pages"}>
				<div className="cms-page pt-0">
					<SingleSearchFilter setData={setLandingPagesData} unfilteredData={unfilteredData} filterColumn={["title", "published"]} />
					<MUIDataTable
						columns={landingPagesColumns}
						data={landingPagesData}
						options={{
							...tableBarSettings,
							pagination: true,
							selectableRows: "none",
							elevation: 0,
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							responsive: tableResponsive,
							rowsPerPage: tableRowsPerPageValue,
							rowsPerPageOptions: tableRowsPerPage,
							customToolbar: () => <AddTableButton onClick={pageHeaderData["addNewClick"]} />,
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
				{deleteItem && (
					<ConfirmDeleteComponent
						showDeleteCom={deleteItem}
						deleteLoading={isDataLoading}
						titlename={title}
						setDeleteLoading={setIsDataLoading}
						deleteTitle={"Confirm Deletion of Promo Landing Page"}
						setShowDeleteCom={setDeleteItem}
						landingPagesData={landingPagesData}
						setLandingPagesData={setLandingPagesData}
						id={deleteItem?.id}
						type="landingPages"
					/>
				)}
				{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			</HelmetLayout>
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<LandingPageHelpInformation />
				</InformationPageHelper>
			)}
		</>
	);
};
export default LandingPages;
