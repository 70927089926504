import React from "react";
import { useSelector } from "react-redux";
import { getOdds, getTrainerAndJockeyForTelebetSis } from "utils/global";
import { PriceBoost } from "utils/icons";

import useGenerateBetslip from "hooks/betslip";

export default function TelebetHorseracingFirstTable({ telebetBets, matchMarketsFilter, telebetMatch }) {
	const user = JSON.parse(localStorage.getItem("user"));
	const telebetSport = useSelector(state => state.Telebet.telebetSport);

	const generateBetslip = useGenerateBetslip();

	const selectHrBet = ({ item, price, sp, runners, isUnnamedFavorite }) => {
		selectBet({ item, price, sp, event_id: runners?.event_id, market: { market_name: runners?.market_name }, isUnnamedFavorite });
	};

	const selectBet = ({ item, price, sp, event_id, isUnnamedFavorite, market }) => {
		let tmp = { ...telebetBets };

		const { selection_id: bet_id, decimal, fractional, boosted_decimal, boosted_fractional } = item;

		const [bet_provider] = bet_id.split("-");

		const odds_decimal = sp ? "SP" : getOdds({ decimal, boosted_decimal, sport_slug: telebetSport?.slug }, "decimal");
		const odds_fractional = sp ? "SP" : getOdds({ fractional, boosted_fractional, sport_slug: telebetSport?.slug }, "fractional");

		const odds = { odds_decimal, odds_fractional };

		const data = {
			description: sp ? "Starting Price" : "Race Winner",
			match_name: market?.market_name,
			name: item?.selection_name,
			price_boosted_enabled: boosted_decimal || boosted_fractional,
			sport_slug: telebetSport?.slug,
		};
		let new_bet = { ...item, ...data, bet_id, bet_provider, stake: 0, ...odds };

		if (event_id) new_bet.event_id = event_id;
		if (price) new_bet.price = price;
		if (sp) new_bet.starting_price = sp;
		if (isUnnamedFavorite) new_bet.bet_type = "unnamed_favorite";

		const filteredSingles = tmp?.singles.filter(single => single.bet_id !== bet_id);

		if (filteredSingles.length >= tmp?.singles.length) {
			tmp?.singles?.push(new_bet);
		} else {
			tmp.singles = filteredSingles;
		}

		// if (showRunners) {
		// 	if (isUnnamedFavorite) dispatch(selectBets({ ...item, bet_id, event_id, stake: 0 }, "unnamed_favorite"));
		// 	else dispatch(selectBets({ bet_id, stake: 0, price: price, starting_price: sp, event_id, odds, odds_fractional }, "bets"));
		// } else {
		generateBetslip(tmp);
		// }
	};

	return matchMarketsFilter.map(({ runners, non_runners }, i) => {
		return (
			<div key={i} className="telebet-matchs">
				<div className="telebet-match-title">
					{telebetMatch?.competition_name && <span>{telebetMatch?.competition_name}</span>}
					{runners?.market_name && <span className="telebet-match-sutitle">{runners?.market_name}</span>}
				</div>
				<div className="d-flex telebet-meach-header">
					<span className="telebet-match-span-row telebet-match-odds">#</span>
					<div className="telebet-match-span-row justify-content-start w-100">Runner</div>
					<span className="telebet-match-span-row telebet-match-odds">Price</span>
					<span className="telebet-match-span-row telebet-match-odds">SP</span>
				</div>
				{runners?.selections?.map((item, i) => {
					const {
						selection_id,
						selection_name,
						decimal,
						fractional,
						boosted_decimal,
						boosted_fractional,
						trainer,
						jockey,
						couple_number,
						drawn,
						trading_status,
					} = item;
					const isSuspended = trading_status == "suspended";
					const isSp = item.decimal == "SP" || !item.decimal;

					const oddsObj = { decimal, fractional, boosted_decimal, boosted_fractional, sport_slug: telebetSport.slug, isSuspended };

					const odds = isSp && !isSuspended ? "SP" : getOdds(oddsObj, user?.odds_display, true);

					let priceClassName = "telebet-match-span-column telebet-match-odds ";
					let unnamedFavorite = "w-100";
					let isUnnamedFavorite = false;

					if (boosted_decimal && boosted_decimal != "SP") priceClassName += " telebet-odd-boost ";

					if (selection_name == "Unnamed Favourite") {
						unnamedFavorite = "w-100";
						isUnnamedFavorite = true;
					}

					if (!isSp && telebetBets?.singles?.some(row => row.bet_id === selection_id && !row?.starting_price)) priceClassName += " selected";
					else if (isSp && telebetBets?.singles?.some(row => row.bet_id === selection_id && row?.starting_price)) priceClassName += " selected";
					else priceClassName += " mouse-hover";

					let spClass = "telebet-match-span-column telebet-match-odds";

					const isSelected = telebetBets?.singles?.some(row => row?.bet_id === selection_id && row?.starting_price);

					if (isSelected) spClass += " selected";
					if (isSuspended) {
						spClass += " suspended";
						priceClassName += " suspended";
					} else if (telebetBets?.unnamed_favorite?.some(row => row.bet_id === selection_id)) spClass += " selected";
					else spClass += " mouse-hover";

					return (
						<div className="d-flex telebet-meach-header" key={i}>
							<div className={`telebet-match-span-column flex-column telebet-match-odds`}>
								{couple_number && <span>{couple_number}</span>}
								{drawn && <span>({drawn})</span>}
							</div>
							<div className={`telebet-match-span-column d-flex flex-column align-items-start ${unnamedFavorite}`}>
								{selection_name}
								{(trainer || jockey) && <span className="telebet-match-column-sutitle">{getTrainerAndJockeyForTelebetSis(trainer, jockey)}</span>}
							</div>
							{!isUnnamedFavorite && (
								<span
									className={priceClassName}
									onClick={() => {
										if (!isSuspended) {
											selectHrBet({ item, price: !isSp, sp: isSp, runners });
										}
									}}
								>
									{odds}
									{boosted_decimal && boosted_decimal != "SP" && !isSuspended && <PriceBoost className={isSelected ? "selected" : ""} />}
								</span>
							)}

							<span className={spClass} onClick={() => !isSuspended && selectHrBet({ item, sp: true, runners, isUnnamedFavorite })}>
								{isSuspended ? "SUSP" : "SP"}
							</span>
						</div>
					);
				})}
				{non_runners?.length > 0 && (
					<>
						<div className="telebet-match-title justify-content-center">Non Runner</div>
						{non_runners.map((item, i) => {
							return (
								<div className="d-flex telebet-meach-header disabled" key={i}>
									<div className="telebet-match-span-column d-flex flex-column justify-content-evenly col-2 col-md-1">
										{item?.couple_number && <span>{item?.couple_number}</span>}
										{item?.drawn && <span>({item?.drawn})</span>}
									</div>
									<div className="telebet-match-span-column d-flex flex-column align-items-start col-6 col-md-9">
										{item?.selection_name}
										<span className="telebet-match-column-sutitle">{getTrainerAndJockeyForTelebetSis(item?.trainer, item?.jockey)}</span>
									</div>
									<span className="telebet-match-span-column col-2 col-md-1">NR</span>
									<span className="telebet-match-span-column col-2 col-md-1">NR</span>
								</div>
							);
						})}
					</>
				)}
			</div>
		);
	});
}
