import React from "react";
import axios from "axios";
import { checkIfAllFieldsAreFilled } from "./global";
import { toast } from "react-toastify";
import { apiUrls } from "./const.apiUrl";
import { signOutAndClearStorage, checkForCheckSum } from "helpers/api_helper";
import successIcon from "../assets/images/success-icon.svg";
import xIcon from "../assets/images/x-icon.svg";

const ignoreRoutes = ["/restricted"];

export default class ApiServices {
	static get(url, resolve, reject, auth, stopCallingApi = [], numberOfCalls = 1) {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user && stopCallingApi.length > 0 && stopCallingApi?.includes(user?.role)) return false;

		let headers = this.getHeaders();
		const initObject = {
			headers: headers,
		};
		let token = localStorage.getItem("access_token");
		if (auth) {
			if (token) {
				axios
					.get(url, initObject)
					.then(response => {
						const ret_data = response.data;
						const { success, message } = ret_data;

						const stop = checkForCheckSum(response);
						if (stop) return;

						if (success && message) {
							toast.success(
								<>
									<img src={successIcon} alt="Success" />
									<p className="toast-icon-manual-message">{message}</p>
								</>,
								{ className: "custom-toast" }
							);
						} else if (!success && message) {
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{message}</p>
								</>,
								{ className: "custom-toast" }
							);
						}
						resolve(ret_data);
					})
					.catch(error => {
						// When token is expired
						if (error?.response?.status === 483) {
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{`Country Restricted`}</p>{" "}
								</>,
								{ className: "custom-toast" }
							);
							reject(error?.response?.data);
						} else if (error?.response?.status === 401) {
							if (url === "v1/user/sign_out" || numberOfCalls > 2) {
								signOutAndClearStorage();
							} else {
								this.updateAccessToken(() => this.get(url, resolve, reject, auth, [], numberOfCalls + 1));
							}
							// When token is invalid
						} else if (error?.response?.status === 403) {
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{`You don't have access`}</p>
								</>,
								{ className: "custom-toast" }
							);
							reject(error?.response?.data);
						} else {
							if (error?.response?.data?.message) {
								toast.error(
									<>
										<img src={xIcon} alt="Error" />
										<p className="toast-icon-manual-message">{error?.response?.data?.message}</p>
									</>,
									{ className: "custom-toast" }
								);
							}
							reject(error?.response?.data);
						}
					});
			} else {
				this.updateAccessToken(() => this.get(url, resolve, reject, auth));
			}
		} else {
			axios
				.get(url, initObject)
				.then(data => {
					resolve(data.data);
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						toast.error(
							<>
								<img src={xIcon} alt="Error" />
								<p className="toast-icon-manual-message">{error?.response?.data?.message}</p>
							</>,
							{ className: "custom-toast" }
						);
					}
					reject(error?.response?.data);
				});
		}
	}

	static post(url, resolve, reject, body, auth, userFields = [], hideMessage = false, customHeaders = {}, stopCallingApi = []) {
		const user = JSON.parse(localStorage.getItem("user"));

		if (user && stopCallingApi.length > 0 && stopCallingApi?.includes(user?.role)) return false;

		const result = checkIfAllFieldsAreFilled(body, userFields);

		if (!result) {
			const message = "Highlighted field(s) are mandatory";
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">{message}</p>
				</>,
				{ className: "custom-toast" }
			);
			reject({ message });
			return false;
		}

		let headers = this.getHeaders();
		const initObject = {
			headers: { ...headers, ...customHeaders },
		};
		let token = localStorage.getItem("access_token");
		if (auth) {
			if (token) {
				axios
					.post(url, body, initObject)
					.then(response => {
						const ret_data = response.data;
						const { success } = ret_data;

						const stop = checkForCheckSum(response);
						if (stop) return;

						if (hideMessage === false) {
							if (success && ret_data?.message) {
								toast.success(
									<>
										<img src={successIcon} alt="Success" />
										<p className="toast-icon-manual-message">{ret_data?.message}</p>
									</>,
									{ className: "custom-toast" }
								);
							} else if (ret_data.status == "success" && ret_data?.message?.length > 0) {
								if (ret_data?.message?.length > 0) {
									ret_data?.message?.map(x => {
										toast.success(
											<>
												<img src={successIcon} alt="Success" />
												<p className="toast-icon-manual-message">{x}</p>
											</>,
											{ className: "custom-toast" }
										);
									});
								} else {
									toast.success(
										<>
											<img src={successIcon} alt="Success" />
											<p className="toast-icon-manual-message">{ret_data?.message}</p>
										</>,
										{ className: "custom-toast" }
									);
								}
							} else if (!success && ret_data?.message && ret_data?.message?.length > 0) {
								toast.error(
									<>
										<img src={xIcon} alt="Error" />
										<p className="toast-icon-manual-message">{ret_data?.message}</p>
									</>,
									{ className: "custom-toast" }
								);
							}
						}
						resolve(ret_data);
					})
					.catch(error => {
						// console.log("ERR", error);
						if (error?.response?.status === 483) {
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{`Country Restricted`}</p>{" "}
								</>,
								{ className: "custom-toast" }
							);
							reject(error?.response?.data);
						} else if (error?.response?.status === 401) {
							if (url === "v1/user/sign_out") {
								signOutAndClearStorage();
							} else {
								this.updateAccessToken(() => this.post(url, resolve, reject, body, auth));
							}
						} else if (error?.response?.status === 403) {
							// Redirect to restriced
							// window.location.href = "/restricted";
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{`You don't have access`}</p>{" "}
								</>,
								{ className: "custom-toast" }
							);
							reject(error?.response?.data);
						} else {
							if (error?.response?.data) {
								toast.error(
									<>
										<img src={xIcon} alt="Error" />
										<p className="toast-icon-manual-message">{error?.response?.data?.message?.message || error?.response?.data?.message}</p>
									</>,
									{ className: "custom-toast" }
								);
							}
							reject(error?.response?.data);
						}
					});
			} else {
				this.updateAccessToken(() => this.post(url, resolve, reject, body, auth));
			}
		} else {
			axios
				.post(url, body, initObject)
				.then(data => {
					const stop = checkForCheckSum(data);
					if (stop) return;

					resolve(data.data);
				})
				.catch(error => {
					if (error?.response?.status === 483) {
						toast.error(
							<>
								<img src={xIcon} alt="Error" />
								<p className="toast-icon-manual-message">{`Country Restricted`}</p>{" "}
							</>,
							{ className: "custom-toast" }
						);
						reject(error?.response?.data);
					} else if (error?.response?.status === 401) {
						if (url === "v1/user/sign_out") {
							signOutAndClearStorage();
						}
					} else if (error?.response?.status === 403) {
						// Redirect to restriced
						// window.location.href = "/restricted";
						toast.error(
							<>
								<img src={xIcon} alt="Error" />
								<p className="toast-icon-manual-message">{`You don't have access`}</p>
							</>,
							{ className: "custom-toast" }
						);
						reject(error?.response?.data);
					} else {
						reject(error?.response?.data);
					}
					if (hideMessage === false) {
						toast.error(
							<>
								<img src={xIcon} alt="Error" />
								<p className="toast-icon-manual-message">{error?.response?.data?.message?.message || error?.response?.data?.message}</p>
							</>,
							{ className: "custom-toast" }
						);
					}
				});
		}
	}

	static put(url, resolve, reject, body, auth, userFields = [], customHeaders = {}, stopCallingApi = []) {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user && stopCallingApi.length > 0 && stopCallingApi?.includes(user?.role)) return false;

		let result = false;

		for (const field of userFields) {
			if (field.fieldName === "accountStatus" && (field.value === "closed" || field.value === "suspended")) {
				result = true;
				break;
			}
		}

		if (!result) {
			const result = checkIfAllFieldsAreFilled(body, userFields);

			if (!result) {
				const message = "Highlighted field(s) are mandatory";
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">{message}</p>
					</>,
					{ className: "custom-toast" }
				);
				reject({ message });
				return false;
			}
		}

		let headers = this.getHeaders();
		const initObject = {
			headers: { ...headers, ...customHeaders },
		};

		let token = localStorage.getItem("access_token");
		if (auth) {
			if (token) {
				axios
					.put(url, body, initObject)
					.then(data => {
						const ret_data = data.data;
						const { success, message } = ret_data;

						const stop = checkForCheckSum(data);
						if (stop) return;

						if (success && message) {
							toast.success(
								<>
									<img src={successIcon} alt="Success" />
									<p className="toast-icon-manual-message">{message}</p>
								</>,
								{ className: "custom-toast" }
							);
						} else if (!success && message) {
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{message}</p>
								</>,
								{ className: "custom-toast" }
							);
						}
						resolve(ret_data);
					})
					.catch(error => {
						if (error?.response?.status === 483) {
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{`Country Restricted`}</p>{" "}
								</>,
								{ className: "custom-toast" }
							);
							reject(error?.response?.data);
						} else if (error?.response?.status === 401) {
							this.updateAccessToken(() => this.put(url, resolve, reject, body, auth));
						} else if (error?.response?.status === 403) {
							// Redirect to restriced
							// window.location.href = "/restricted";
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{`You don't have access`}</p>
								</>,
								{ className: "custom-toast" }
							);
							reject(error?.response?.data);
						} else if (error?.response?.data?.message) {
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{error?.response?.data?.message?.message || error?.response?.data?.message}</p>
								</>,
								{ className: "custom-toast" }
							);
							reject(error?.response?.data);
						} else {
							reject(error?.response?.data);
						}
					});
			} else {
				this.updateAccessToken(() => this.put(url, resolve, reject, body, auth));
			}
		} else {
			axios
				.put(url, body, initObject)
				.then(data => {
					resolve(data.data);
				})
				.catch(error => {
					reject(error?.response?.data);
				});
		}
	}

	static updateAccessToken(callback) {
		const user = JSON.parse(localStorage.getItem("user"));

		const headers = {
			"content-type": "application/json",
		};

		if (!localStorage.getItem("refresh_token")) {
			console.log("No refresh token found");

			// If this page is not login page and one of ignored routes then redirect to login page
			if (window.location.pathname !== "/login" && !ignoreRoutes.includes(window.location.pathname)) {
				signOutAndClearStorage();
			}
			return false;
		}

		const refresh_token = localStorage.getItem("refresh_token");
		const body = {
			refresh_token: refresh_token,
			swifty_id: user?.sub_id,
		};

		axios
			.post(apiUrls.refresh_token, body, {
				headers,
			})
			.then(data => {
				localStorage.setItem("access_token", data?.data?.data?.access_token);

				callback && callback();
				// location.reload();
				return false;
			})
			.catch(() => {
				setTimeout(() => {
					signOutAndClearStorage();
				}, 500);
			});
	}

	static getHeaders() {
		let token = localStorage.getItem("access_token");
		const deviceId = localStorage.getItem("device_id");
		let headers = {};
		if (token) {
			headers["Authorization"] = `Bearer ${token}`;
		}

		if (deviceId) {
			headers["device-id"] = deviceId;
		}

		headers["Access-Control-Allow-Origin"] = "*";
		// headers["Access-Control-Allow-Credentials"] = true;
		return headers;
	}

	static delete(url, resolve, reject, auth, stopCallingApi = []) {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user && stopCallingApi.length > 0 && stopCallingApi?.includes(user?.role)) return false;

		let headers = this.getHeaders();
		const initObject = {
			headers: headers,
		};
		let token = localStorage.getItem("access_token");
		if (auth) {
			if (token) {
				axios
					.delete(url, initObject)
					.then(response => {
						const ret_data = response.data;
						const { success, message } = ret_data;
						if (success && message) {
							toast.success(
								<>
									<img src={successIcon} alt="Success" />
									<p className="toast-icon-manual-message">{message}</p>
								</>,
								{ className: "custom-toast" }
							);
						} else if (!success && message) {
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{message}</p>
								</>,
								{ className: "custom-toast" }
							);
						}
						resolve(ret_data);
					})
					.catch(error => {
						if (error?.response?.status === 483) {
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{`Country Restricted`}</p>{" "}
								</>,
								{ className: "custom-toast" }
							);
							reject(error?.response?.data);
						} else if (error?.response?.status === 401) {
							this.updateAccessToken(() => this.delete(url, resolve, reject, auth));
						} else if (error?.response?.status === 210) {
							window.location.href = "/restricted";
						} else if (error?.response?.status === 403) {
							// Redirect to restriced
							// window.location.href = "/restricted";
							toast.error(
								<>
									<img src={xIcon} alt="Error" />
									<p className="toast-icon-manual-message">{`You don't have access`}</p>
								</>,
								{ className: "custom-toast" }
							);
							reject(error?.response?.data);
						} else {
							if (error?.response?.data?.message?.message) {
								toast.error(
									<>
										<img src={xIcon} alt="Error" />
										<p className="toast-icon-manual-message">{error?.response?.data?.message?.message}</p>
									</>,
									{ className: "custom-toast" }
								);
							}
							if (!error?.response?.data?.success) {
								toast.error(
									<>
										<img src={xIcon} alt="Error" />
										<p className="toast-icon-manual-message">{error?.response?.data?.message}</p>
									</>,
									{ className: "custom-toast" }
								);
							}
							reject(error?.response?.data);
						}
					});
			} else {
				this.updateAccessToken(() => this.delete(url, resolve, reject, auth));
			}
		} else {
			axios
				.delete(url, initObject)
				.then(data => {
					resolve(data.data);
				})
				.catch(error => {
					reject(error?.response?.data);
				});
		}
	}
}
