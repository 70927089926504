import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "assets/images/delete.svg";
import { DragAndDropFillIcon, DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone } from "utils/icons";
export const getFooterLinksColumns = ({ setDeleteItem, changeOrder, handleEditItem }) => {
	return [
		{
			name: "component",
			label: "COMPONENT",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "120px", width: "120px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "120px", width: "120px" } }),
			},
		},
		{
			name: "title",
			label: "TITLE",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100%" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100%" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta, other) => {
					const row = meta;
					let { index, dragHandleProps, isDragging } = other;
					return (
						<div className="d-flex justify-content-end">
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => handleEditItem(row)} />
								<DeleteIconButton
									deleteIcon={deleteIcon}
									onClick={() => {
										setDeleteItem(row);
									}}
								/>
								{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}
								<div
									{...dragHandleProps}
									onMouseDown={(...args) => {
										dragHandleProps?.onDragStart(...args);
									}}
								>
									<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
								</div>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
