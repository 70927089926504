import React, { useEffect, useState } from "react";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

export const MatchGeneral = props => {
	const { eventViewData, setEventViewData, setDataChanged, showAddEventModal, handleChange } = props;

	const [fields, setFields] = useState([]);

	const dependeciesObj = {
		sport_slug: eventViewData?.sport_slug,
		grade_pre_match: eventViewData?.grade_pre_match,
		grade_in_play: eventViewData?.grade_in_play,
	};

	useEffect(() => {
		const disabled = showAddEventModal ? false : true;
		const isSis = eventViewData?.sport_slug === "horseracing" || eventViewData?.sport_slug === "greyhoundracing";
		const event_id = eventViewData?.genius_id ? "genius_id" : "event_unique_id";

		let formFields = [
			{ type: "switch", name: "Telebet", fieldName: "active" },
			{ type: "switch", name: "Display", fieldName: "display" },

			{
				type: "dropdown",
				name: "Sport",
				fieldName: "sport_slug",
				optionsType: "allSports",
				label: "sport_slug",
				inputClassName: "general_input textCapitalized",
				disabled: disabled,
				required: true,
			},
			{
				type: "dropdown",
				name: "Competition",
				fieldName: "competition_id",
				optionsType: "competitions",
				params: eventViewData?.sport_slug,
				id: eventViewData?.sport_slug,
				inputClassName: "general_input textCapitalized",
				disabled: disabled,
				handleChangeDropdown: handleChange,
				required: true,
			},
		];

		if (showAddEventModal) {
			formFields.push({ type: "dropdown", name: "Region", fieldName: "region_id", optionsType: "region" });
		} else {
			formFields.push({ type: "input", name: "Event ID", fieldName: event_id, inputClassName: "general_input", disabled: true });
		}

		formFields.push({ type: "input", name: "Event Name", fieldName: "name", inputClassName: "general_input", required: true });

		if (isSis && !showAddEventModal) formFields.push({ type: "input", name: "Event Title", fieldName: "title", inputClassName: "general_input" });

		formFields.push(
			{
				type: "date",
				name: "Start Time (UTC)",
				fieldName: "start_time_utc",
				formatDate: true,
				isTimeExist: true,
				value: eventViewData?.start_time_utc,
				noFormattedDate: true,
				required: true,
				withUtc: true,
			},
			{
				type: "dropdown",
				name: "Pre Match Tier",
				fieldName: "grade_pre_match",
				optionsType: "tier_type",
				id: eventViewData?.sport_slug,
				params: eventViewData?.sport_slug,
			}
		);

		if (!isSis)
			formFields.push({
				type: "dropdown",
				name: "In-Play Tier",
				fieldName: "grade_in_play",
				optionsType: "tier_type",
				id: eventViewData?.sport_slug,
				params: eventViewData?.sport_slug,
			});

		formFields.push({
			type: "dropdown",
			name: "Event Phase",
			fieldName: "current_phase",
			optionsType: "event_phase_type",
			id: eventViewData?.sport_slug,
			params: eventViewData?.sport_slug,
		});

		if (!showAddEventModal) {
			formFields.push(
				{
					type: "input",
					name: "Provider Event Phase",
					fieldName: "provider_current_phase",
					disabled: true,
				},
				{ type: "switch", name: "Override Provider Event Phase", fieldName: "overwrite_current_phase" }
			);
		}

		formFields.push({ type: "switch", name: "Pre-Match Bets", fieldName: "show_pre_match_bets" });

		if (!isSis) formFields.push({ type: "switch", name: "In-Play Bets", fieldName: "show_in_play_bets" });

		if (eventViewData?.sport_slug || showAddEventModal) setFields(formFields);
	}, [showAddEventModal, eventViewData?.sport_slug, eventViewData?.genius_id]);

	return (
		<div className="sport-general-container">
			<div className="row mb-3">
				<AllComponentsLoop fields={fields} data={eventViewData} setData={setEventViewData} setDataChanged={setDataChanged} dependecies={dependeciesObj} />
			</div>
		</div>
	);
};
