import React, { useState } from "react";
import { GeneralDropdown, GeneralInput } from "components/GeneralComponents/CustomInputs";
import { capitalizeText } from "services/capitalizeText";
import { InfoIcon } from "utils/icons";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { Link } from "react-router-dom";

let isDragging = false;

const getMinWidth = (index, type) => {
	if (index === 0) {
		return "min-w-350";
	} else if ((index === 4 || index === 6 || index === 7) && type === "tbody") {
		return "min-w-150";
	} else if ((index === 3 || index === 4 || index === 5) && type === "thead") {
		return "min-w-150";
	}
};

export const getThead = array => {
	return (
		<tr className="d-flex align-items-center mb-2">
			{array?.map((row, i) => {
				return (
					<th key={i} className={`main_header_table_bet_players main_header_table_bet_player ${getMinWidth(i, "thead")}`}>
						{row.thead}
					</th>
				);
			})}
		</tr>
	);
};
export const getTbody = (array, betsData, setBetsData, playerBetsData, setPlayerBetsData, isUserReadOnly, setDataChanged) => {
	const [position, setPosition] = useState({ x: 24, y: 370 });
	const IsPartialWin = betsData.find(row => row.result == "partial");
	const isNotOpen = playerBetsData.status !== "Open";
	const inputElement = ["win_reduction", "place_reduction", "ew_terms", "partial_win_percent"];

	return array?.map((tbody, index) => {
		return (
			<tr className={`d-flex align-items-center me-2 ${isUserReadOnly && "isDisabled"}`} key={index}>
				{tbody?.map((row, i) => {
					if (row.type === "text") {
						const { fieldName, subTitle, icon } = row;
						return (
							<td
								key={i}
								className={`main_header_table_bet_players text ${
									icon && `d-flex align-items-center justify-content-between main_label_table_bet_player`
								} ${getMinWidth(i)}`}
							>
								{fieldName && <p className="p-0 m-0">{fieldName}</p>}
								{subTitle && <p className="p-0 m-0">{"EW : " + subTitle}</p>}

								{icon && (
									<InfoIcon className="ms-2 info-Icon-Container" onClick={() => setPlayerBetsData({ ...playerBetsData, informatinId: row.bet_id })} />
								)}
							</td>
						);
					} else if (row.type === "dropdown") {
						let value = row.fieldName || "";

						if (row?.field === "result" || row.field === "price_type") value = capitalizeText(row.fieldName || "");
						return (
							<td key={i} className={`main_header_table_bet_players ${getMinWidth(i, "tbody")}`}>
								<GeneralDropdown
									object={{
										bindValue: value,
										options: row.options,
										customized_classname: true,
										specificDropdownMenu: true,
										handleChangeDropdown: e => {
											setDataChanged(true);
											handleChange(e, row, betsData, setBetsData, playerBetsData, setPlayerBetsData, row.defaultValues);
										},
										disabled: isUserReadOnly || row.disabled || isNotOpen,
									}}
								/>
							</td>
						);
					} else if (row.type === "input") {
						return (
							<td key={i} className={`main_header_table_bet_players ${getMinWidth(i, "tbody")}`}>
								<GeneralInput
									object={{
										value: row.fieldName || "",
										inputContainer: "p-0 m-0",
										extraWidth: "w-100",
										disabled: isUserReadOnly || row.disabled || isNotOpen,
										...row?.validation,
										numbersOnly: row?.fieldType == "number",
										icon: row?.icon,
										iconInsideInput: true,
										onBlur: () => inputElement.includes(row.field) && getAutoSettlement(betsData, playerBetsData, setPlayerBetsData),
									}}
									handleChange={val => {
										setDataChanged(true);
										handleChange(val, row, betsData, setBetsData, playerBetsData, setPlayerBetsData, row.defaultValues);
									}}

									// TODO: add validation and add delay when you are writing on the box
								/>
							</td>
						);
					} else if (row.type === "icon" && row.id === playerBetsData.informatinId) {
						return (
							<td key={i} className="info-Icon-Container-hidden" style={{ left: `${position.x}px`, top: `${position.y}px` }}>
								<span className="d-flex flex-column " style={{ minWidth: "300px" }}>
									{row?.information.map((item, index) => {
										const { name, competition, icon, isLink, element } = item;
										const classPlayer = icon ? "close_container_bet_search" : "player_bet_details_container";
										const mouseDown = icon ? handleMouseDown : null;
										return (
											<span className={classPlayer} key={index} onMouseDown={e => mouseDown(e, position, setPosition)}>
												<span>{name}</span>
												{isLink ? (
													<Link
														to={`/events/${element.event_id}?sport_slug=${element.sport_slug}&tab=0`}
														target="_blank"
														className="p-0 m-0 bet-link"
													>
														{competition}
													</Link>
												) : (
													<p className="p-0 m-0">{competition}</p>
												)}
											</span>
										);
									})}
								</span>
							</td>
						);
					} else if (row.type === "hidden") {
						return <td key={i} className={IsPartialWin && "main_header_table_bet_players"}></td>;
					}
				})}
			</tr>
		);
	});
};

const handleMouseDown = (e, position, setPosition) => {
	const offsetX = e.clientX - position.x;
	const offsetY = e.clientY - position.y;

	isDragging = true;
	const handleMouseMove = e => {
		if (!isDragging) return;
		const newX = e.clientX - offsetX;
		const newY = e.clientY - offsetY;
		setPosition({ x: newX, y: newY });
	};
	const handleMouseUp = () => {
		isDragging = false;
		document.removeEventListener("mousemove", handleMouseMove);
		document.removeEventListener("mouseup", handleMouseUp);
	};
	document.addEventListener("mousemove", handleMouseMove);
	document.addEventListener("mouseup", handleMouseUp);
};

const handleChange = (value, row, betsData, setBetsData, playerBetsData, setPlayerBetsData, defaultValues) => {
	const { bet_id, field, type } = row;

	const newValue = type === "input" ? value : value?.id;

	let newValues = {};
	if (defaultValues) {
		newValues = defaultValues.reduce((a, v) => {
			const field = v.field;
			const selectedValue = Object.entries(v?.cases || {})?.find(([key]) => key == newValue);

			if (selectedValue) return { ...a, [field]: selectedValue[1] };
			return { ...a, [field]: "" };
		}, {});
	}

	let newBetsData = [];

	const oddFormat = ["odd_decimal", "odd_fractional", "sp_odd_decimal", "sp_odd_fractional"];

	if (oddFormat.includes(field)) {
		let name = "";
		if (field === "odd_decimal" || field === "odd_fractional") name = "odd";
		else if (field === "sp_odd_decimal" || field === "sp_odd_fractional") name = "sp_odd";

		newBetsData = betsData.map(row =>
			row.bet_id === bet_id
				? { ...row, [`${name}_decimal`]: value.in_decimal, [`${name}_fractional`]: value.in_fraction, [field]: newValue, ...newValues }
				: row
		);
	} else {
		newBetsData = betsData.map(row => (row.bet_id === bet_id ? { ...row, [field]: newValue, ...newValues } : row));
	}

	const existField = ["result", "odd_decimal", "odd_fractional", "sp_odd_decimal", "sp_odd_fractional", "rule_4"];

	if (existField.includes(field) && playerBetsData.status === "Open") {
		getAutoSettlement(newBetsData, playerBetsData, setPlayerBetsData);
	}

	setBetsData(newBetsData);
};

const getAutoSettlement = (betsData, playerBetsData, setPlayerBetsData) => {
	const bets = betsData.map(row => {
		const {
			result,
			partial_win_percent,
			rule_4,
			win_reduction,
			place_reduction,
			is_each_way,
			ew_terms,
			odd_decimal,
			odd_fractional,
			sp_odd_decimal,
			sp_odd_fractional,
			is_bog_applicable,
			is_starting_price,
		} = row;

		let data = {
			odd_decimal,
			odd_fractional,
			result,
			sp_odd_decimal,
			sp_odd_fractional,
			rule_4,
			win_reduction,
			place_reduction,
			is_each_way,
			ew_terms,
			is_bog_applicable,
			is_starting_price,
		};

		if (result === "partial") data = { ...data, partial_win_percent };
		return data;
	});

	let payload = {
		selections: bets,
		stake: playerBetsData?.stake,
		free_bet_amount: playerBetsData.free_bet_amount,
		bog_applicable: playerBetsData.is_bog_applicable,
	};
	new Promise((resolve, reject) => {
		ApiServices.put(
			apiUrls.GET_CALC_PAYOUT_BETS + `?bet_type=${playerBetsData?.bet_type_value}` + `&player_id=${playerBetsData?.player_id}`,
			resolve,
			reject,
			payload,
			true
		);
	}).then(response => {
		const { data } = response;
		setPlayerBetsData({ ...playerBetsData, payout: data?.payout });
	});
};
