import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useEffect, useRef, useState } from "react";
import ApiServices from "utils/ApiServices";
import { XCloseIcon } from "utils/icons";

import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import xIcon from "../../../assets/images/x-icon.svg";
import { AvForm } from "availity-reactstrap-validation";
// import { getImageThumbnail } from "utils/global";
import { languageToString, sanitizeJSONString } from "utils/global";

const formatDetails = details => {
	const themeTags = typeof details?.theme_tags == "object" ? details?.theme_tags : details?.theme_tags?.split(",");
	const featureTags = typeof details?.feature_tags == "object" ? details?.feature_tags : details?.feature_tags?.split(",");

	return { ...details, theme_tags: themeTags, feature_tags: featureTags };
};

export const CasinoGame = ({ editGame, setEditGame, setEditCasinoGrups }) => {
	const [editData, setEditData] = useState();
	const [loading, setLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [fields, setFields] = useState([]);

	const [showOptions, setShowOptions] = useState("");
	const onFocus = useRef(false);

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};

	const getGame = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.GET_EDIT_CASINO_GAME}/${editGame.id}`, resolve, reject, true);
		}).then(response => {
			const { data } = response;
			let newData = data;
			if (data?.details) {
				const details = data?.details_2;

				if (details) {
					newData = Object.assign(data, formatDetails(details));
				}
				const language = typeof newData?.language == "object" ? newData?.language : JSON.parse(newData?.language);

				if (language) {
					newData = Object.assign(data, language);
				}
			}
			setEditData(newData);
		});
	};

	useEffect(() => {
		getGame();
	}, []);

	const handleAcceptedFiles = () => {};

	const editCasinoGame = () => {
		let details = {};
		let language = languageToString(editData, ["title_", "description_"]);
		// Object.entries(editData).forEach(([key, value]) => {
		// 	if (key.includes("title_") || key.includes("description_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });
		// language = language.replace(/,(\s+)?$/, ""); // remove last comma

		details["language"] = language ? `{${language}}` : null;
		details["web_image"] = editData?.web_image;
		details["mobile_image"] = editData?.mobile_image;
		details["description"] = editData?.description;
		details["return_to_player"] = editData?.return_to_player;
		details["max_bet"] = editData?.max_bet + "";
		details["min_bet"] = editData?.min_bet + "";
		details["max_win"] = editData?.max_win + "";
		details["jackpot_amount"] = editData?.jackpot_amount;
		details["theme_tags"] = editData?.theme_tags?.join(",");
		details["feature_tags"] = editData?.feature_tags?.join(",");
		details["reels"] = editData?.reels + "";
		details["lines"] = editData?.lines + "";
		details["volatility"] = editData?.volatility;

		let detailsJson = JSON.stringify(details);
		let languageJson = "";
		try {
			languageJson = JSON.parse(`{${sanitizeJSONString(language)}}`);
		} catch {
			languageJson = "";
		}

		let payload = {
			published: editData?.published,
			default_image: editData?.default_image,
			default_image_mobile: editData?.default_image_mobile,
			active: editData?.active,
			title: editData?.title,
			web_image_url: editData?.web_image_url,
			mobile_image_url: editData?.mobile_image_url,
			details: detailsJson,
			details_2: {
				...details,
				language: languageJson,
			},
		};

		if (editData?.title == "") {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Name cannot be empty</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		if (Number(editData?.min_bet) > Number(editData?.max_bet)) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Min Bet cannot be greater than Max Bet</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(`${apiUrls.EDIT_GAME}/${editGame.id}`, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
			})
			.finally(() => {
				setLoading(false);
				setEditGame(false);
				setEditCasinoGrups(true);
			});
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const dependecies = {
		image: editData?.image,
		default_image: editData?.default_image,
	};

	useEffect(() => {
		let fields = [];
		const web_image = editData?.default_image ? "web_image_url" : "web_image";
		const mobile_image = editData?.default_image_mobile ? "mobile_image_url" : "mobile_image";
		const default_image = editData?.default_image;
		const default_image_mobile = editData?.default_image_mobile;

		if (editData) {
			fields.push(
				{ type: "switch", name: "Published", fieldName: "published" },
				{ type: "switch", name: "Enabled", fieldName: "active" },
				{ type: "input", name: "Game ID", fieldName: "slug", disabled: true },
				{
					type: "input",
					name: "Name",
					fieldName: "title",
					onFocus: () => handleShowOptions("language", "title"),
					onBlur: () => (onFocus.current = false),
					required: true,
				},
				{ type: "switch", name: "Web Default Image", fieldName: "default_image" },
				{
					type: "image",
					name: "Web Image",
					fieldName: web_image,
					gameImage: default_image,
					data: editData,
					setData: setEditData,
					setDataChanged: setDataChanged,
					onFocus: () => !default_image && handleShowOptions("image", "web_image"),
					onBlur: () => (onFocus.current = false),
					active: !default_image && showOptions.image === "web_image",
					dimensions: "(580 x 260 px)",
					hideRemoveButton: default_image,
					infoMessage: default_image && "The default image is currently active. To upload or edit a new photo, please deactivate the default image first.",
				},
				{ type: "switch", name: "Mobile Default Image", fieldName: "default_image_mobile" },
				{
					type: "image",
					name: "Mobile Image",
					fieldName: mobile_image,
					gameImage: default_image_mobile,
					data: editData,
					setData: setEditData,
					setDataChanged: setDataChanged,
					onFocus: () => !default_image_mobile && handleShowOptions("image", "mobile_image"),
					onBlur: () => (onFocus.current = false),
					active: !default_image_mobile && showOptions.image === "mobile_image",
					dimensions: "(400 x 400 px)",
					hideRemoveButton: default_image_mobile,
					infoMessage:
						default_image_mobile &&
						"The mobile default image is currently active. To upload or edit a new photo, please deactivate the mobile default image first.",
				},
				{
					type: "textarea",
					name: "Description",
					fieldName: "description",
					onFocus: () => handleShowOptions("language", "description"),
					onBlur: () => (onFocus.current = false),
					placeholder: "Optional",
				},
				{ type: "input", name: "Return to Player", fieldName: "return_to_player", placeholder: "Optional", numbersOnly: true },
				{ type: "input", name: "Max Bet", fieldName: "max_bet", placeholder: "Optional", numbersOnly: true },
				{ type: "input", name: "Min Bet", fieldName: "min_bet", placeholder: "Optional", numbersOnly: true },
				{ type: "input", name: "Max Win", fieldName: "max_win", placeholder: "Optional", numbersOnly: true },
				{ type: "input", name: "Jackpot Amount", fieldName: "jackpot_amount", placeholder: "Optional", numbersOnly: true },
				{ type: "dropdown", name: "Theme Tags", fieldName: "theme_tags", optionsType: "casino_themes", multiple: true },
				{ type: "dropdown", name: "Feature Tags", fieldName: "feature_tags", optionsType: "casino_feature", multiple: true },
				{ type: "input", name: "Reels ", fieldName: "reels", numbersOnly: true },
				{ type: "input", name: "Lines ", fieldName: "lines" },
				{ type: "dropdown", name: "Volatility", fieldName: "volatility", optionsType: "casino_volatility" },
				{ type: "grid", name: "Blacklist", fieldName: "black_list", data: editData?.black_list },
				{ type: "grid", name: "Whitelist", fieldName: "white_list", data: editData?.white_list }
			);
		}
		setFields(fields);
	}, [editData?.default_image, editData?.default_image_mobile, showOptions.image]);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Edit Game</span>
				<XCloseIcon onClick={() => setEditGame(null)} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editCasinoGame} loading={loading} dataChanged={dataChanged} onClose={() => setEditGame(null)} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AvForm onClick={() => !onFocus.current && handleShowOptions()}>
							<AllComponentsLoop fields={fields} data={editData} setData={setEditData} setDataChanged={setDataChanged} dependecies={dependecies} />
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
						{showOptions.language && (
							<LanguageTitle title={showOptions.language} data={editData} setData={setEditData} handleDataChanged={handleDataChanged} />
						)}
						{showOptions.image && (
							<ImageLibrary
								data={editData}
								setData={(_, data) => setEditData({ ...editData, [showOptions.image]: data.image })}
								handleAcceptedFiles={handleAcceptedFiles}
								type="casino"
								handleDataChanged={handleDataChanged}
								property={showOptions.image}
								handleShowOptions={() => handleShowOptions()}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
