import React, { useEffect, useRef, useState } from "react";
import { AddButton, LoadingSaveButton, NoButton, YesButton } from "components/GeneralComponents/AddButton";
// import { ConfirmOrderItems } from "components/GeneralComponents/ConfirmOrderItems";
import { EditorTextInput, GeneralDropdown, LinkNameLanguage, TitleNameLanguage } from "components/GeneralComponents/CustomInputs";
import { FooterImageLibrary } from "components/GeneralComponents/FooterImageLibrary";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { AutoTranslate, XCloseIcon } from "utils/icons";
import deleteIcon from "../../../assets/images/delete.svg";
import { ColumnComponent } from "./ColumnComponent";
import { ImagesComponent } from "./ImagesComponent";
import { SealComponent } from "./SealComponent";
import { TextComponent } from "./TextComponent";
import { toast } from "react-toastify";
import { getLanguages } from "utils/constants";
import xIcon from "../../../assets/images/x-icon.svg";
import { checkGenericUrl, userReadOnly } from "utils/global";
import { AvForm } from "availity-reactstrap-validation";

import "./footerLinks.scss";

export const FooterLinksModal = ({ handler, type, setFooterData, item, setUnfilteredData, pageContentData }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(null);
	// const [confirmOrder, setConfirmOrder] = useState(false);
	// const [orderData, setOrderData] = useState({
	// 	index: "",
	// 	direction: "",
	// 	loading: false,
	// 	type: "",
	// });
	const [linkData, setLinkData] = useState({
		type: type,
		enabled: true,
		items: data,
		image: null,
		title_name: "",
		seal_header: "",
		seal_body: "",
	});
	const footerEditorRef = useRef(null);
	const footerEditorRefX = useRef(null);
	const [inputId, setInputId] = useState(null);
	const [imageId, setImageId] = useState(null);
	const [deleteItem, setDeleteItem] = useState(null);
	const [dataChanged, setDataChanged] = useState(false);
	const [showTitleLanguage, setShowTitleLanguage] = useState(null);
	const [translateLoading, setTranslateLoading] = useState(false);
	const [footerSelectedImg, setFooterSelectedImg] = useState("");
	const [footerSelectedId, setFooterSelectedId] = useState("");
	const inUseLanguages = useSelector(state => state.Login.languages);
	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);
	let languages = getLanguages("footer_text");
	let translateTo = [];
	if (inUseLanguages?.length > 0) {
		inUseLanguages.forEach(lang => {
			translateTo.push(lang?.for);
		});
	}
	const translate = {
		text: "",
		translateTo: translateTo,
		translateFrom: "en",
	};
	const footerText = {
		footer_text: "",
	};
	const country = useSelector(state => state.Login.country);
	// Order items

	const reorderArray = (event, originalArray, type) => {
		let orders = [];
		const movedItem = originalArray.find((item, index) => index === event.oldIndex);
		const remainingItems = originalArray.filter((item, index) => index !== event.oldIndex);

		const reorderedItems = [...remainingItems.slice(0, event.newIndex), movedItem, ...remainingItems.slice(event.newIndex)];

		reorderedItems.forEach((row, index) => {
			row.order = index;
			orders.push(row);
		});
		setLinkData(prevData => ({
			...prevData,
			items: reorderedItems,
		}));
		setDataChanged(true);
		orderItems(orders, type);
		return reorderedItems;
	};

	function changeOrder(index, direction, type) {
		// setConfirmOrder(true);

		const orderData = { index, direction, type };

		setData(reorderArray({ oldIndex: orderData?.index, newIndex: orderData?.index + (orderData?.direction === "UP" ? -1 : 1) }, data, orderData?.type));
	}

	const handleChange = (value, id, type, elementType) => {
		let actualData = [];
		actualData = [...data];
		if (elementType === "title") {
			actualData.forEach(row => {
				row["title"] = value;
				row["edited"] = true;
			});
			setLinkData({
				...linkData,
				title_name: value,
			});
		} else {
			var editedElement = actualData.filter(row => row.id === id);
			var editedIndex = actualData.findIndex(row => row.id === id);
			if (editedElement && editedElement.length > 0) {
				editedElement[0][elementType] = value || footerSelectedImg;
				editedElement[0].edited = true;
				actualData[editedIndex] = editedElement[0];
			}
		}
		setData(actualData);
		handleDataChanged();
	};

	const handleAddLink = () => {
		let newElement = {
			id: Math.max(...data.map(row => row.id), 0) + 1,
			value: "",
			link: "",
			order: "",
			new: true,
			page_type: "default",
		};

		var actualData = [...data];
		actualData.push(newElement);
		setData(actualData);
	};
	const handleLanguage = id => {
		setShowTitleLanguage(null);
		setInputId(id);
		let item = data.filter(row => row.id == id);
		let details = null;
		if (item && item.length > 0) {
			details = item[0].details;
			if (details) {
				details = JSON.parse(details);
			}
		}

		setLinkData({
			...linkData,
		});
	};

	const handleOpenImageLibrary = elementId => {
		setImageId(elementId);
		setInputId(null);
		setShowTitleLanguage(null);
	};

	const selectedImg = (value, id) => {
		setFooterSelectedId(id);
		setFooterSelectedImg(value);
		handleDataChanged(true);
	};

	const handleAcceptedFiles = () => {};

	const handleSaveLinks = () => {
		let payload = {};
		let hasError = false;
		if (linkData?.type === "text") {
			payload = {
				id: item?.id ? item?.id : null,
				type: linkData.type,
				text: footerEditorRef?.current?.getContent(),
				country_code: country,
			};
			let details = {};
			if (data?.length > 0) {
				Object.entries(data[0]).forEach((row, key) => {
					if (row[0].indexOf("footer_text_") > -1) {
						if (key + 1 === Object.entries(data[0]).length) {
							details[row[0]] = `${row[1].replace(/"/g, '\\"')}`;
						} else {
							details[row[0]] = `${row[1].replace(/"/g, '\\"')}`;
						}
					}
				});
			}
			payload = { ...payload, details: JSON.stringify(details) };

			const expectedContent = `<!DOCTYPE html>\n<html>\n<head>\n</head>\n<body>\n\n</body>\n</html>`;
			const isMatch = footerEditorRef?.current?.getContent() === expectedContent;
			if (isMatch) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please enter text</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}
		} else if (linkData?.type === "seal") {
			payload = {
				id: item?.id ? item?.id : null,
				type: linkData.type,
				seal_body: linkData.seal_body,
				seal_header: linkData.seal_header,
			};
			if (!linkData.seal_header) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please enter seal header</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			} else if (!linkData.seal_body) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please enter seal body</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}
		} else {
			let dataPayload = [];

			data.forEach((row, index) => {
				let link;
				link = row.page_type === "games_list" ? "/games-list" : row?.link;

				if ((row.page_type === "default" || row.page_type === "new_tab") && !/^\/[a-zA-Z0-9-_]*(\/[a-zA-Z0-9-_]*)*(\?[a-zA-Z0-9&=_-]*)?$/.test(link)) {
					hasError = true;

					toast.error(
						<>
							<img src={xIcon} alt="Error" />
							<p className="toast-icon-manual-message">Please enter valid path</p>
						</>,
						{ className: "custom-toast" }
					);
					return;
				}

				if ((row.page_type === "default_external" || row.page_type === "new_tab_external") && checkGenericUrl(link)) {
					hasError = true;
					return;
				}

				let newObj = {
					id: index + 1,
					value: row.value,
					link: link || "",
					page_type: row.page_type,
					order: index + 1,
				};
				newObj = Object.assign(row, newObj);
				dataPayload.push(newObj);
			});

			let titleDetails = {};
			Object.entries(linkData).forEach(row => {
				if (row[0].indexOf("title_name_") > -1) {
					titleDetails[row[0]] = row[1];
				}
			});

			payload = {
				id: item?.id ? item?.id : null,
				type: linkData?.type,
				enabled: linkData.enabled,
				items: JSON.stringify(dataPayload),
				title: linkData.title_name,
				details: JSON.stringify(titleDetails),
				country_code: country,
			};
		}

		if (!dataChanged || hasError) {
			return;
		}

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.save_footer_links, resolve, reject, payload, true);
		})
			.then(response => {
				setDataChanged(false);
				handler();
				let data = response?.data;
				let records = [];
				data.forEach(row => {
					let obj = {};
					if (row.type === "images") {
						obj = {
							component: "Images",
							title: row.title,
							type: "images",
							id: row.id,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					} else if (row.type === "text") {
						obj = {
							component: "Text",
							title: "None",
							type: "text",
							id: row.id,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					} else if (row.type === "seal") {
						obj = {
							component: "Seal",
							title: "Disclaimer",
							type: "seal",
							id: row.id,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					} else {
						obj = {
							component: "Column",
							title: row.title,
							type: row.type,
							id: row.id,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					}
					records.push(obj);
				});
				setUnfilteredData(records);
				setFooterData(records);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const orderItems = orders => {
		let payload = {
			items: JSON.stringify(orders),
		};

		if (item?.id) payload.id = item?.id;

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.order_footer_links, resolve, reject, payload, true);
		});
	};

	const handleDeleteLink = id => {
		let actualData = [...data];
		actualData = actualData.filter(row => row.id !== id);
		let payload = {
			items: JSON.stringify(actualData),
			type,
			id: item?.id ? item?.id : "",
		};
		setDeleteLoading(id);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.delete_footer_link, resolve, reject, payload, true);
		})
			.then(response => {
				setData(actualData);
				setDeleteItem(null);
				let data = response?.data;
				let records = [];
				data.forEach(row => {
					let obj = {};
					if (row.type === "images") {
						obj = {
							component: "Images",
							title: row.title,
							type: "images",
							id: row.id,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					} else if (row.type === "text") {
						obj = {
							component: "Text",
							title: "None",
							type: "text",
							id: row.id,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					} else if (row.type === "seal") {
						obj = {
							component: "Seal",
							title: "Disclaimer",
							type: "seal",
							id: row.id,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					} else {
						obj = {
							component: "Column",
							title: row.title,
							type: row.type,
							id: row.id,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					}
					records.push(obj);
				});
				setFooterData(records);
			})
			.finally(() => {
				setDeleteLoading(null);
			});
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const handleTranslate = language => {
		let footerText = "";
		if (footerEditorRef?.current?.startContent) {
			footerText = footerEditorRef?.current?.getContent();
		}
		let payload = {
			text: footerText,
			translateTo: language ? [language] : translate.translateTo,
			translateFrom: translate.translateFrom,
		};
		if (language) {
			setTranslateLoading(language);
		} else {
			setTranslateLoading(true);
		}
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.translate_languages, resolve, reject, payload, true);
		})
			.then(res => {
				let translations = res?.data;
				let actualData = [...data];
				if (translations?.length > 0) {
					translations.forEach(row => {
						if (row?.translation) {
							if (actualData.length > 0) {
								actualData[0]["footer_text_" + row?.language] = row?.translation.replace(/^\n\n"|"$/g, "");
							} else {
								if (linkData?.type === "text") {
									languages.map(languageRow => {
										actualData.push({
											value: footerEditorRef?.current?.getContent(),
											[languageRow?.key]: row?.translation,
										});
									});
								}
							}
						}
					});
					setData(actualData);
				}
			})
			.finally(() => {
				setTranslateLoading(false);
			});
	};

	const handleTranslateRow = (type, language) => {
		let payload = {};
		if (type === "title") {
			payload = {
				text: linkData?.title_name,
				translateTo: language ? [language] : translate.translateTo,
				translateFrom: translate.translateFrom,
			};
		} else {
			let item = data.filter(row => row.id == inputId);
			payload = {
				text: item[0]?.value,
				translateTo: language ? [language] : translate.translateTo,
				translateFrom: translate.translateFrom,
			};
		}
		if (language) {
			setTranslateLoading(language);
		} else {
			setTranslateLoading(true);
		}
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.translate_languages, resolve, reject, payload, true);
		})
			.then(res => {
				let translations = res?.data;
				if (translations?.length > 0) {
					if (type === "title") {
						let newLinkData = { ...linkData };
						translations.forEach(row => {
							if (row?.translation) {
								newLinkData[`title_name_${row.language}`] = row?.translation;
							}
						});
						setLinkData(newLinkData);
					} else {
						let item = data.filter(row => row.id == inputId);
						let actualData = [...data];
						translations.forEach(row => {
							if (row?.translation) {
								item[0][`link_name_${row.language}`] = row?.translation;
							}
						});
						let editedIndex = actualData.findIndex(row => row.id === inputId);
						actualData[editedIndex] = item[0];
						setData(actualData);
					}
				}
				handleDataChanged?.();
			})
			.finally(() => {
				setTranslateLoading(false);
			});
	};

	useEffect(() => {
		if (item?.id && (linkData?.type === "images" || linkData?.type === "column")) {
			let value = item?.value;
			let details = item?.details;
			if (value) {
				value = JSON.parse(value);
				setData(value);
			}
			setLinkData({
				...linkData,
				title_name: item?.title,
				enabled: item?.enabled,
			});
			if (details) {
				details = JSON.parse(details);
				let newObj = { ...linkData };
				newObj["title_name"] = item?.title;
				newObj["enabled"] = item?.enabled;
				newObj = Object.assign(newObj, details);
				setLinkData(newObj);
			}
		} else if (item?.id && linkData?.type === "text") {
			if (item?.value) {
				let data = [];
				data.push({
					value: item?.value,
				});
				setData(data);
			}
			if (item?.details) {
				let details = JSON.parse(item?.details);
				let data = [];
				let mergedItem = { value: item.value };
				languages.forEach(row => {
					if (details[row.key] !== undefined) {
						mergedItem[row.key] = details[row.key];
					}
				});
				data.push(mergedItem);
				setData(data);
			}
		} else if (item?.id && linkData?.type === "seal") {
			if (item?.value) {
				let value = JSON.parse(item?.value);
				setLinkData({
					...linkData,
					seal_body: value?.body,
					seal_header: value?.header,
				});
			}
		}
	}, []);

	useEffect(() => {
		if (linkData?.image || footerSelectedImg) {
			let newData = [...data];
			let editedElement = newData.filter(row => row.id === imageId);
			let editedIndex = newData.findIndex(row => row.id === imageId);
			if (editedElement && editedElement.length > 0) {
				editedElement[0].value = linkData?.image || footerSelectedImg;
				editedElement[0].edited = true;
				newData[editedIndex] = editedElement[0];
			}
			setData(newData);
		}
	}, [linkData.image, footerSelectedImg]);

	let options = [
		{ id: "column", label: "Column" },
		{ id: "seal", label: "Seal", once: true },
		{ id: "images", label: "Images" },
		{ id: "text", label: "Text", once: true },
	];
	options = options.filter(({ once }) => !once);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Footer</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={handleSaveLinks} loading={loading} dataChanged={dataChanged} onClose={handler} />
				<div
					className={linkData?.type === "seal" ? "promotion-elements row m-0 seal-elements footer-elements" : "promotion-elements row m-0 footer-elements"}
				>
					<div
						className={
							linkData?.type === "seal"
								? "promotion-left col-12 promotion-inputs footer-left"
								: "promotion-left col-12 col-lg-6 promotion-inputs footer-left"
						}
					>
						<AvForm>
							{!item && (
								<GeneralDropdown
									object={{
										options: options,
										name: "Component",
										value: linkData?.type,
										defaultPlaceholder: "Name",
										wrapperClassNameDropdown: "input-container p-0 m-0",
										extraWidth: "footer-title-input",
									}}
									handleChange={newValue => {
										setLinkData({ ...linkData, type: newValue.id });
										setData([]);
										setImageId(null);
										setInputId(null);
										setShowTitleLanguage(null);
										handleDataChanged();
									}}
								/>
							)}
							{linkData?.type === "column" && (
								<ColumnComponent
									data={data}
									linkData={linkData}
									setLinkData={setLinkData}
									setShowTitleLanguage={setShowTitleLanguage}
									handleDataChanged={handleDataChanged}
									deleteIcon={deleteIcon}
									deleteLoading={deleteLoading}
									setDeleteItem={setDeleteItem}
									type={type}
									handleChange={handleChange}
									changeOrder={changeOrder}
									handleLanguage={handleLanguage}
									setInputId={setInputId}
									setImageId={setImageId}
									pageContentData={pageContentData}
								/>
							)}
							{linkData?.type === "images" && (
								<ImagesComponent
									data={data}
									linkData={linkData}
									setLinkData={setLinkData}
									setShowTitleLanguage={setShowTitleLanguage}
									handleDataChanged={handleDataChanged}
									deleteIcon={deleteIcon}
									deleteLoading={deleteLoading}
									setDeleteItem={setDeleteItem}
									type={type}
									handleChange={handleChange}
									changeOrder={changeOrder}
									handleLanguage={handleLanguage}
									setInputId={setInputId}
									handleOpenImageLibrary={handleOpenImageLibrary}
									setImageId={setImageId}
									pageContentData={pageContentData}
									footerSelectedImg={footerSelectedImg}
									footerSelectedId={footerSelectedId}
									selectedImg={selectedImg}
								/>
							)}
							{linkData?.type === "seal" && (
								<SealComponent linkData={linkData} setLinkData={setLinkData} handleDataChanged={handleDataChanged} isUserReadOnly={isUserReadOnly} />
							)}
							{linkData?.type === "text" && <TextComponent footerEditorRef={footerEditorRef} data={data} handleDataChanged={handleDataChanged} />}
							{linkData?.type && linkData?.type !== "text" && linkData?.type !== "seal" && (
								<div className="mt-3">
									<AddButton name={"Add"} onClick={() => handleAddLink(linkData?.type)} />
								</div>
							)}
						</AvForm>
					</div>
					<div className={linkData?.type === "seal" ? "d-none" : "promotion-right col-12 col-lg-6 footer-right"}>
						{inputId != null && (
							<div className={isUserReadOnly && "pe-none"}>
								<div className="footer-translate">
									{translateLoading === true ? (
										<Spinner animation="border" size="sm" />
									) : (
										<AutoTranslate type={"title"} onClick={() => handleTranslateRow("name")} />
									)}
								</div>
								<LinkNameLanguage
									data={data}
									setData={setData}
									inputId={inputId}
									handleDataChanged={handleDataChanged}
									type={"name"}
									handleTranslateRow={handleTranslateRow}
									translateLoading={translateLoading}
								/>
							</div>
						)}
						{showTitleLanguage != null && (
							<div className={isUserReadOnly && "pe-none"}>
								<div className="footer-translate">
									{translateLoading === true ? (
										<Spinner animation="border" size="sm" />
									) : (
										<AutoTranslate type={"title"} onClick={() => handleTranslateRow("title")} />
									)}
								</div>
								<TitleNameLanguage
									data={linkData}
									setData={setLinkData}
									inputId={showTitleLanguage}
									handleDataChanged={handleDataChanged}
									type={"title"}
									handleTranslateRow={handleTranslateRow}
									translateLoading={translateLoading}
								/>
							</div>
						)}
						{imageId !== null && (
							<>
								<FooterImageLibrary
									data={linkData}
									setData={setLinkData}
									itemsData={data}
									setItemsData={setData}
									handleAcceptedFiles={handleAcceptedFiles}
									type="footer_images"
									handleDataChanged={handleDataChanged}
									id={deleteItem?.id}
									imageId={imageId}
									selectedImg={selectedImg}
									footerSelectedImg={footerSelectedImg}
									editData={data}
									handleChange={handleChange}
								/>
							</>
						)}
						{linkData?.type === "text" && (
							<div className={isUserReadOnly && "pe-none"}>
								<div className="d-flex justify-content-end my-3">
									{translateLoading === true ? (
										<Spinner animation="border" size="sm" style={{ marginBottom: "14px" }} />
									) : (
										<AutoTranslate type={"text"} onClick={() => handleTranslate()} />
									)}
								</div>
								<EditorTextInput
									data={data}
									footerEditorRef={footerEditorRef}
									footerEditorRefX={footerEditorRefX}
									footerText={footerText}
									handleDataChanged={handleDataChanged}
									handleTranslate={handleTranslate}
									translateLoading={translateLoading}
									isUserReadOnly={isUserReadOnly}
								/>
							</div>
						)}
					</div>
				</div>
				{deleteItem && (
					<SweetAlert title={"Confirm Delete"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
						<div style={{ position: "relative" }}>
							<div className="">
								<span className="delete-span">Are you sure you want to delete this link ?</span>
							</div>
						</div>
						<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
							<NoButton onClick={() => setDeleteItem(null)} name="No" />
							{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={() => handleDeleteLink(deleteItem)} />}
						</div>
					</SweetAlert>
				)}
				{/* {confirmOrder && <ConfirmOrderItems show={confirmOrder} setShow={setConfirmOrder} handler={confirmOrderHandler} loading={orderData?.loading} />} */}
			</div>
		</div>
	);
};
