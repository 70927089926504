import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { useDispatch } from "react-redux";
import { setBackdrop } from "store/Backdrop/actions";
import { InfoIconHelper } from "utils/icons";
import InformationPageHelper from "../InformationPageHelper";
import { CashOutHelpInformation } from "../HelpPagesForSettings/HelpPages";
import { AvForm } from "availity-reactstrap-validation";

function CashOut() {
	const [data, setData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [unchagedData, setUnchangedData] = useState([]);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();

	const fields = [
		{ type: "switch", name: "Cash Out", fieldName: "enabled" },
		{ type: "dropdown", name: "Cash Out Value Reduction", fieldName: "value_reduction", optionsType: "deduction_types" },
		{ type: "dropdown", name: "Bet Types", fieldName: "bet_types", optionsType: "bet_types", multiple: true, bgWhite: true, disableMultipleScroll: true },
		{
			type: "dropdown",
			name: "Exclude Sports",
			fieldName: "exclude_sports",
			optionsType: "allSports",
			multiple: true,
			id: true,
			params: true,
			bgWhite: true,
		},
	];
	const CashOut = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.trader_options + "?type=cash-out", resolve, reject, true);
		})
			.then(response => {
				setDataChanged(false);
				let data = response?.data;
				const exclude_sports = JSON.parse(data.exclude_sports);
				const bet_types = JSON.parse(data.bet_types);
				setData({ ...data, exclude_sports: exclude_sports, bet_types: bet_types });
				setUnchangedData(data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		CashOut();
	}, []);

	const discardData = () => {
		setData(unchagedData);
		setDataChanged(false);
	};

	const editCashOut = () => {
		let payload = data;

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.trader_options_edit, resolve, reject, payload, true);
		})
			.then(() => {})
			.finally(() => {
				setDataChanged(false);
			});
	};
	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	return (
		<div>
			<TopBarComponent
				classname="special_top_component"
				saveHandler={editCashOut}
				dataChanged={dataChanged}
				discardHandler={discardData}
				loading={isDataLoading}
			/>
			{isDataLoading ? (
				<LoadingSpinner className={"pt-5"} />
			) : (
				<div className="cms-page row overflow-y-auto">
					<div className="col-12 trader-form">
						<AvForm>
							<AllComponentsLoop fields={fields} data={data} setData={setData} setDataChanged={setDataChanged} />
						</AvForm>
					</div>
				</div>
			)}
			{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<CashOutHelpInformation />
				</InformationPageHelper>
			)}
		</div>
	);
}

export default CashOut;
