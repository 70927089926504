import React from "react";
import { EditIconLogo } from "utils/icons";
export const getErrorLogsTableColumns = ({ errorLogsData, setShowEditModal, setHandleErrorFix }) => {
	return [
		{
			name: "id",
			label: "ID",
			className: "name",
			direction: "desc",
		},
		{
			name: "message",
			label: "Message",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "createdAt",
			label: "Created At",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "fnName",
			label: "Fn Name",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "",
			label: "Fix",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = errorLogsData[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<button
								className="btn btn-primary btn-sm"
								onClick={() => {
									setShowEditModal(true);
									setHandleErrorFix(record.id);
								}}
								style={{ marginRight: "5px" }}
							>
								<EditIconLogo />
							</button>
						</>
					);
				},
				setCellProps: () => ({ style: { maxWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "100px" } }),
			},
		},
	];
};
