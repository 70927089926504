import React, { useEffect, useState } from "react";
import { AddButton, DeleteIconButton, EditIconButton, LoadingSaveButton, NoButton, YesButton } from "components/GeneralComponents/AddButton";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { DragAndDropFillIcon, DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone, XCloseIcon } from "utils/icons";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import SweetAlert from "react-bootstrap-sweetalert";
import { setSportNameBySlug } from "utils/global";
import { AvForm } from "availity-reactstrap-validation";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import DragAndDropList from "components/GeneralComponents/DragAndDrop";
import deleteIcon from "../../../assets/images/delete.svg";
import { languageToString } from "utils/global";

export const EditEventMarketFilters = props => {
	const { handler, slug, setNewData, isUserReadOnly } = props;
	const [dataChanged, setDataChanged] = useState(false);
	const [events, setEvents] = useState([]);
	const [item, setItem] = useState({
		published: false,
		show_in_menu: false,
		title: "",
		id: null,
	});
	const [deleteId, setDeleteId] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const getSportEvents = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_event_get + `?sport_slug=${slug}`, resolve, reject, true);
		}).then(response => {
			setEvents(response?.data);
		});
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const addSportEvent = () => {
		let payload = {
			slug,
			published: item?.published,
			show_in_menu: item?.show_in_menu,
			title: item?.title,
		};

		let details = languageToString(item, ["title_"]);
		payload = { ...payload, details: `{${details}}` };

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.sport_event_add, resolve, reject, payload, true);
		})
			.then(response => {
				setEvents(response?.data);
				setNewData(response?.data);
				setItem({
					...item,
					id: "",
				});
			})
			.finally(() => {
				setDataChanged(false);
			});
	};

	const editSportEvent = () => {
		let payload = {
			id: item?.id,
			published: item?.published,
			show_in_menu: item?.show_in_menu,
			title: item?.title,
		};

		let details = languageToString(item, ["title_"]);
		payload = { ...payload, details: `{${details}}` };

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.sport_event_edit, resolve, reject, payload, true);
		})
			.then(() => {
				let editedIndex = events.findIndex(row => row.id === item?.id);
				let actualEvents = [...events];
				actualEvents[editedIndex].published = item?.published;
				actualEvents[editedIndex].show_in_menu = item?.show_in_menu;
				actualEvents[editedIndex].title = item?.title;
				actualEvents[editedIndex].details = payload.details;
				setEvents(actualEvents);
				setNewData(actualEvents);
			})
			.finally(() => {
				setDataChanged(false);
			});
	};

	const deleteEvent = () => {
		setDeleteLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.sport_event_delete + `/${deleteId}`, resolve, reject, true);
		})
			.then(() => {
				let actualEvents = [...events];
				actualEvents = actualEvents.filter(row => row?.id !== deleteId);
				setEvents(actualEvents);
				setNewData(actualEvents);
				setDeleteId(null);
			})
			.finally(() => {
				setDeleteLoading(false);
			});
	};

	const orderItems = newOrder => {
		let payload = {
			order: newOrder.map((row, index) => ({ id: row.id, order: index })),
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.sport_event_order, resolve, reject, payload, true);
		}).then(() => {});
	};

	const changeOrder = index => {
		const selectedRow = events.splice(index, 1)[0];
		const newData = [selectedRow, ...events];
		setDregAndDropData(newData);
	};

	useEffect(() => {
		getSportEvents();
	}, []);

	const setDregAndDropData = newData => {
		setEvents(newData);
		orderItems(newData);
	};

	const handleEditItem = row => {
		let newDataUpdated = row;

		if (row?.details) {
			const details = JSON?.parse(row?.details);

			if (row?.details) {
				Object.entries(details).forEach(([key, value]) => {
					newDataUpdated = { ...newDataUpdated, [key]: value };
				});
			}
		}

		setItem(newDataUpdated);
	};
	const { name } = setSportNameBySlug(slug) || "";

	const fields = [
		{ type: "switch", name: "Published", fieldName: "published" },
		{ type: "switch", name: "Show in Menu", fieldName: "show_in_menu" },
		{ type: "input", name: "Title", fieldName: "title", required: true },
	];

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{name} Filters</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={item?.id === "new" ? addSportEvent : editSportEvent} dataChanged={dataChanged} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						<DragAndDropList data={events} setData={newData => setDregAndDropData(newData)}>
							{(row, { index, dragHandleProps, isDragging }) => {
								return (
									<div className={`default-sport-row fs-6 ${isUserReadOnly && "pe-none"}`}>
										<span>{row?.title}</span>

										<div className="d-flex justify-content-end">
											<div className="heroBanners-buttons">
												<EditIconButton onClick={() => handleEditItem(row)} />
												<DeleteIconButton deleteIcon={deleteIcon} onClick={() => setDeleteId(row.id)} />
												{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}
												<div
													{...dragHandleProps}
													onMouseDown={(...args) => {
														dragHandleProps?.onDragStart(...args);
													}}
												>
													<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
												</div>
											</div>
										</div>
									</div>
								);
							}}
						</DragAndDropList>
						<AddButton
							name={"Add New"}
							style={{ marginTop: "12px" }}
							disabled={isUserReadOnly}
							onClick={() => {
								setItem({
									...item,
									published: 1,
									show_in_menu: 1,
									title: "",
									title_fr: "",
									title_es: "",
									id: "new",
								});
							}}
						/>
					</div>
					<div className="promotion-right col-12 col-lg-6">
						{item?.id && (
							<AvForm>
								<AllComponentsLoop fields={fields} data={item} setData={setItem} setDataChanged={setDataChanged} />

								<LanguageTitle data={item} setData={setItem} title={"title"} handleDataChanged={handleDataChanged} />
							</AvForm>
						)}
					</div>
				</div>
			</div>
			{deleteId && (
				<SweetAlert title={"Confirm delete"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="">
							<span className="delete-span">Are you sure you want to delete this event ?</span>
						</div>
					</div>
					<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
						<NoButton onClick={() => setDeleteId(null)} name="No" />
						{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={() => deleteEvent()} />}
					</div>
				</SweetAlert>
			)}
		</div>
	);
};
