import React from "react";
// import { DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone, DragAndDropFillIcon } from "utils/icons";
import { EditIconButton } from "components/GeneralComponents/AddButton";

export const getSisSportCompetitionsTableColumns = props => {
	const { setEditCompetition } = props;

	return [
		{
			name: "country_name",
			label: "COUNTRY",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { width: "50%" } }),
				setCellHeaderProps: () => ({ style: { width: "50%" } }),
			},
		},
		{
			name: "competition_name",
			label: "NAME",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { width: "50%" } }),
				setCellHeaderProps: () => ({ style: { width: "50%" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					// let { index, dragHandleProps, isDragging } = other;
					return (
						<>
							<div className={`d-flex`} style={{ justifyContent: "flex-end", gap: "10px" }}>
								<div className="sportTypes-buttons" style={{ borderRadius: "0" }}>
									<EditIconButton onClick={() => setEditCompetition(row)} />
								</div>
								{/* {index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}

								<div
									{...dragHandleProps}
									onMouseDown={(...args) => {
										dragHandleProps?.onDragStart(...args);
									}}
								>
									<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
								</div> */}
							</div>
						</>
					);
				},
			},
		},
	];
};
