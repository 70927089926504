import React from "react";
import { EditButton } from "components/GeneralComponents/AddButton";
import HTMLReactParser from "html-react-parser";
import { LightningBoltIcon } from "utils/icons";
// import { tableColumnNumber } from "utils/tableSettings";

export const getSelectionMarketTableColumns = ({
	marketMatchColumnsData,
	setEditMarketSelections,
	setEditMarketSelectionData,
	isRaceWinner,
	setManualSelectionType,
}) => {
	const user = JSON.parse(localStorage.getItem("user"));

	return [
		{
			name: "selection_name",
			label: "SELECTION",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "name",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "110px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "110px" }, className: "max-button" }),
			},
		},
		{
			name: "price",
			label: "PRICE",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "name",
			options: {
				customBodyRender: (value, meta) => {
					const record = marketMatchColumnsData[meta.currentTableData[meta.rowIndex].index];
					const { display_fractional, display_price, price_boost } = record;
					const { odds_display } = user;

					// console.log(record);

					return (
						<div className="d-flex align-items-center justify-content-end">
							{!+display_price || !isRaceWinner ? "-" : odds_display == "fractional" ? display_fractional : Number(display_price).toFixed(2)}

							<span style={{ marginLeft: "5px" }}>
								{/* {(feed_price_decimal || !!+display_price) && parseFloat(display_price) != parseFloat(feed_price_decimal) && (
									<LightningBoltIcon />
								)} */}

								{price_boost !== "-1" && price_boost !== "" && <LightningBoltIcon />}
							</span>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "25px" }, className: "max-button align-right" }),
			},
		},
		{
			name: "active",
			label: "TELEBET",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "name",
			options: {
				customBodyRender: (value, meta) => {
					const record = marketMatchColumnsData[meta.currentTableData[meta.rowIndex].index];
					var status_txt =
						record.active == 0 || record.active == "" || record.active == null
							? `<span className="badge badge-sports-inactive"> Inactive </span>`
							: `<span className="badge badge-sports-published"> Published </span>`;
					return HTMLReactParser(status_txt);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "25px" }, className: "max-button" }),
			},
		},
		{
			name: "display",
			label: "DISPLAY",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "name",
			options: {
				customBodyRender: (value, meta) => {
					const record = marketMatchColumnsData[meta.currentTableData[meta.rowIndex].index];
					var status_txt =
						record.display == 0 || record.display == "" || record.display == null
							? `<span className="badge badge-sports-inactive"> Inactive </span>`
							: `<span className="badge badge-sports-published"> Published </span>`;
					return HTMLReactParser(status_txt);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "25px" }, className: "max-button" }),
			},
		},
		{
			name: "",
			className: "edit-market-selection",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = marketMatchColumnsData[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditButton
									onClick={() => {
										if (record?.type == "manual") setManualSelectionType("edit");
										else setEditMarketSelections(true);

										const { odds_display } = user;
										setEditMarketSelectionData({
											...record,
											decimal: record?.fractional ? record.price : "",
											fractional: record.fractional,
											display_fractional: record.initialFractional,

											price_boost: record.is_manual == 1 ? "" : { id: record.price_boost == "-1" ? "" : record.price_boost },
											price_boost_id: record.is_manual == 1 ? "" : record.price_boost,

											initial_price_boost_id: record.is_manual == 1 ? "-1" : record.price_boost,
											initial_price_boost: record.is_manual == 1 ? "" : { id: record.price_boost == "-1" ? "" : record.price_boost },

											acitve: record.active || 0,
											display: record.display || 0,

											display_price: odds_display == "fractional" ? record.display_fractional : record.display_price || 0,
											feed_price: record.feed_price_decimal || 0,
											feed_price_display: odds_display == "fractional" ? record.feed_price_fractional : record.feed_price_decimal || 0,

											manual_price: record.is_manual,
										});
									}}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "25px" }, className: "max-button" }),
			},
		},
	];
};
export const getMarketTableColumns = ({ marketMatchesViewData, handlerClick }) => {
	return [
		{
			name: "market_name",
			label: "Market ",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "210px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "210px" }, className: "max-button" }),
			},
		},
		{
			name: "tranding_status",
			label: "RESULTED",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button" }),
			},
		},
		{
			name: "selection_count",
			label: "Selections",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button" }),
			},
		},
		{
			name: "active",
			label: "Telebet",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				customBodyRender: (value, meta) => {
					const record = marketMatchesViewData[meta.currentTableData[meta.rowIndex].index];
					var status_txt =
						record?.active == 0 || record?.active == "" || record?.active == null
							? `<span className="badge badge-sports-inactive"> Inactive </span>`
							: `<span className="badge badge-sports-published"> Published </span>`;
					return HTMLReactParser(status_txt);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button" }),
			},
		},
		{
			name: "display",
			label: "Display",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				customBodyRender: (value, meta) => {
					const record = marketMatchesViewData[meta.currentTableData[meta.rowIndex].index];
					var status_txt =
						record?.display == 0 || record?.display == "" || record?.display == null
							? `<span className="badge badge-sports-inactive"> Inactive </span>`
							: `<span className="badge badge-sports-published"> Published </span>`;
					return HTMLReactParser(status_txt);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "max-button" }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = marketMatchesViewData[meta.currentTableData[meta.rowIndex].index];

					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<EditButton
								onClick={() => {
									handlerClick("edit", record);
								}}
								// disabled={record?.market_active == 0 ? true : false}
							/>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
	];
};
export const getResultTabelColums = ({ resultData, handleEdit }) => {
	return [
		{
			name: "market_name",
			label: "SELECTION",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				customBodyRender: (value, meta) => {
					const record = resultData[meta.currentTableData[meta.rowIndex].index];
					const { selection_name, market_name } = record;
					return (
						<span>
							{market_name} - {selection_name}
						</span>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),
			},
		},
		{
			name: "bet_result",
			label: "BET RESULT",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "result",
			label: "SELECTION RESULT",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "total_stake",
			label: "TOTAL STAKE",
			options: {
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right align-right" }),
			},
		},
		{
			name: "total_liability",
			label: "TOTAL LIABILITY",
			options: {
				customBodyRender: (value, meta) => {
					const record = resultData[meta.currentTableData[meta.rowIndex].index];

					return record.total_liability.toFixed(2);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right align-right" }),
			},
		},
		{
			name: "",
			label: "",
			sortable: true,
			sort: "asc",
			className: "",
			options: {
				customBodyRender: (value, meta) => {
					const record = resultData[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex justify-content-end">
							<div>
								<EditButton
									onClick={() => {
										handleEdit(record);
									}}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
	];
};
