import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");
import { getFormatDateByUserTimezone } from "utils/global";

let prevCompetitionID = "";
let result = [];

export const getMatcheDropdown = async (competition_id, sport_slug, region_id) => {
	if (!competition_id) {
		result = [];
		prevCompetitionID = "";
	}
	if (competition_id && prevCompetitionID !== competition_id) {
		prevCompetitionID = competition_id;

		let params = `?competition_id=${competition_id}`;

		if (sport_slug) params += `&sport_slug=${sport_slug}`;
		if (region_id) params += `&region_id=${region_id}`;

		return new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.GET_MATCHES_OPTIONS}${params}&next=31`, resolve, reject, true);
		})
			.then(response => {
				let matches = response?.data;

				matches = matches.map(row => {
					const date = row?.date;
					let name = row.name;

					if (date) {
						const formatedDate = getFormatDateByUserTimezone(date);
						name = formatedDate + " " + row.name;
					}

					return { id: row.event_id, name: name, label: name };
				});

				result = matches;
				return matches;
			})
			.catch(() => {
				result = [];
				return [];
			});
	} else return result;
};
