import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import SignInFooter from "pages/Authentication/SignInFooter";
import sideArrow from "../../assets/images/side-arrow.svg";

const Pages404 = () => {
	useEffect(() => {
		document.body.classList.add("error-body");
		return () => {
			document.body.classList.remove("error-body");
		};
	}, []);

	return (
		<React.Fragment>
			<MetaTags>
				<title>Error 404 | CMS | Swifty</title>
			</MetaTags>
			<div className="error-page">
				<Container>
					<Row className="justify-content-center">
						<Col md="8" lg="6" xl="8">
							<div className="ex-page-content text-center">
								<div className="error-404">404</div>
								<div className="error-text">Sorry, we were unable to find that page</div>
								<div className="error-redirect">
									<Link className="error-button form-button" to="/">
										Back to Dashboard <img src={sideArrow} alt="side-arrow" />
									</Link>
								</div>
							</div>
							<SignInFooter />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Pages404;
