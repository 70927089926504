import React from "react";
import { getFormatDateByUserTimezone } from "utils/global";
import { DeleteIcon } from "utils/icons";
import { DeleteIconButton } from "components/GeneralComponents/AddButton";

export const getRealityCheckTable = ({ realityCheckGambling, setShowDeleteConfirm, deleteItem, setDeleteItem }) => {
	return [
		{
			name: "frequency",
			label: "FREQUENCY",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = realityCheckGambling[meta.currentTableData[meta.rowIndex].index];
					let title = row?.frequency;

					if (title == -1) {
						title = "Not Set";
					} else {
						title = `${title} Mins`;
					}

					return <>{title}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "166px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "60px" }, className: "max-button" }),
			},
		},

		{
			name: "start_period",
			label: "PERIOD START DATE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = realityCheckGambling[meta.currentTableData[meta.rowIndex].index];
					const date = getFormatDateByUserTimezone(row.start_period, "DD MMM YYYY HH:mm");
					return <>{row?.frequency == "-1" ? "-" : date}</>;
				},

				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
		//We might recover this
		// {
		// 	name: "end_period",
		// 	label: "PERIOD END DATE",
		// 	align: "left",
		// 	sortable: true,
		// 	sort: "asc",
		// 	options: {
		// 		customBodyRender: (value, meta) => {
		// 			const row = realityCheckGambling[meta.currentTableData[meta.rowIndex].index];
		// 			const date = getFormatDateByUserTimezone(row.end_period,"DD MMM YYYY HH:mm");

		// 			return <>{date}</>;
		// 		},
		// 		setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
		// 		setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
		// 	},
		// },
		{
			name: "",
			label: "",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				viewColumns: false,
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
				customBodyRender: (value, meta) => {
					const record = realityCheckGambling[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<DeleteIconButton
									deleteIcon={DeleteIcon}
									onClick={() => {
										setShowDeleteConfirm(true);
										setDeleteItem({ ...deleteItem, type: "reality check", value: record?.frequency });
									}}
								/>
							</div>
						</div>
					);
				},
			},
		},
	];
};

export const getRealityCheckData = realityCheckData => {
	let data = {};
	const { frequency, start_period } = realityCheckData;

	data = { ...data, frequency: frequency == -1 ? "Not Set" : `${frequency} Mins` };
	data = { ...data, start_period: frequency == "-1" ? "-" : getFormatDateByUserTimezone(start_period, "DD MMM YYYY HH:mm") };

	return [
		{ name: "Frequency", value: data.frequency },
		{ name: "Start", value: data.start_period },
		{ name: "", value: "" },
	];
};
