import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import { Spinner } from "react-bootstrap";
export const getUserListTableColumns = ({
	handleGetUserData,
	records,
	spinningStatusMfa,
	setShowDeleteModal,
	setDeleteUserEmail,
	setBlocked,
	setBlockModal,
	setEmailBlock,
	setUserFullName,
	setShowEditModal,
}) => {
	return [
		{
			name: "first_name",
			label: "FIRST NAME",
			className: "user_email_list",
			align: "left",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "100px" } }),
				setCellHeaderProps: () => ({ style: { width: "150px", minWidth: "150px" } }),
			},
		},
		{
			name: "last_name",
			label: "LAST NAME",
			className: "user_email_list",
			align: "left",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "100px" } }),
				setCellHeaderProps: () => ({ style: { width: "150px", minWidth: "150px" } }),
			},
		},
		{
			name: "role",
			label: "ROLE",
			className: "user_email_list",
			align: "left",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "160px" } }),
				setCellHeaderProps: () => ({ style: { width: "150px", minWidth: "150px" } }),
			},
		},
		{
			name: "email",
			label: "EMAIL",
			className: "user_email_list",
			align: "left",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "400px", overflowWrap: "anywhere" } }),
				setCellHeaderProps: () => ({ style: { width: "400px", minWidth: "400px" } }),
			},
		},
		{
			name: "mfa_status",
			label: "MFA STATUS",
			className: "user_email_list",
			align: "left",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return spinningStatusMfa ? <Spinner animation="border" size="sm" /> : record?.setup_mfa || record?.mfa_status == 1 ? "Active" : "Inactive";
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { width: "150px", minWidth: "150px" } }),
			},
		},
		{
			name: "account_status",
			label: "ACCOUNT STATUS",
			className: "user_email_list",
			align: "left",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const accountStats = record?.blocked === 0 ? "Active" : "Blocked";
					return accountStats;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { width: "170px", minWidth: "170px" } }),
			},
		},
		{
			name: "",
			className: "variables_edit",
			align: "left",
			sortable: true,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<button
									className="btn btn-primary btn-sm d-flex justify-content-center align-items-center"
									style={{ height: "24px", width: "90px", borderRadius: "0" }}
									onClick={() => {
										setBlockModal(true);
										setEmailBlock(record?.email);
										setBlocked(!record?.blocked);
										setUserFullName({ first_name: record?.first_name, last_name: record?.last_name });
									}}
								>
									{record?.blocked != 0 ? "Unblock" : "Block"}
								</button>

								<EditIconButton
									onClick={() => {
										setShowEditModal(true);
										handleGetUserData(record);
									}}
								/>

								<DeleteIconButton
									onClick={() => {
										setShowDeleteModal(true);
										setDeleteUserEmail(record.email);
										setUserFullName({ first_name: record?.first_name, last_name: record?.last_name });
									}}
									style={{ marginRight: "5px" }}
								/>
							</div>
						</div>
					);
				},
			},
		},
	];
};
