import { useSelector } from "react-redux";

export const platformName = process.env.REACT_APP_PLATFORM_NAME;
export const loginLogo = process.env.REACT_APP_S3_ASSET_URL + "/media/cms/logo.svg";

export const _resultBets = [
	{ id: "winner", label: "Won" },
	{ id: "loser", label: "Lose" },
	{ id: "open", label: "Open" },
	{ id: "pushed", label: "Pushed" },
	{ id: "placed", label: "Placed" },
	{ id: "partial", label: "Partial" },
	{ id: "cash_out", label: "Cashout" },
];

export const _resultOptions = [
	{ id: "Winner", label: "Winner" },
	{ id: "Loser", label: "Loser" },
	{ id: "Pushed", label: "Pushed" },
	{ id: "Autoclose", label: "Autoclose" },
];
export const _casinoVolatilityOptions = [
	{ id: "low", label: "Low" },
	{ id: "medium", label: "Medium" },
	{ id: "high", label: "High" },
	{ id: "very_high", label: "Very High" },
];
export const _componentPageLayoutOptions = [
	{ id: "banner", label: "Banners" },
	{ id: "carousel", label: "Carousel" },
	{ id: "racing_widget", label: "Racing Widget" },
	{ id: "sport_widget", label: "Sports Widget" },
	{ id: "promo_offer", label: "Offers" },
	{ id: "casino", label: "Casino" },
	{ id: "casino_category", label: "Casino Category" },
	{ id: "live_casino", label: "Live Casino" },
	{ id: "live_casino_category", label: "Live Casino Category" },
	{ id: "virtual_casino", label: "Virtual Casino" },
	{ id: "virtual_casino_category", label: "Virtual Casino Category" },
	//Swifty special is hiden for now !
	// {id: "swifty_special", label: "Swifty Special",  },
	{ id: "page_content", label: "Page Content" },
];
export const _marketTypes = [
	{
		label: "Prematch",
		id: "pre_match",
	},
	{
		label: "Inplay",
		id: "in_play",
	},
];

export const _paymentTypes = [
	{
		id: "non_trading_adjustment",
		label: "Non Trading Adjustment",
	},
	{
		id: "trading_adjustment",
		label: "Trading Adjustment",
	},
];

export const _tradingAdjusment = [
	{
		id: "bog",
		label: "BOG",
	},
	{
		id: "cashback",
		label: "Cashback",
	},
	{
		id: "other",
		label: "Other",
	},
];

export const _nonTradingAdjustment = [
	{
		id: "bank_transfer",
		label: "Bank Transfer",
	},
	{
		id: "card",
		label: "Card",
	},
	{
		id: "wallet",
		label: "Wallet",
	},
];

export const bankingTransactions = [
	{
		id: "deposits",
		label: "Deposits",
	},
	{
		id: "withdrawals",
		label: "Withdrawals",
	},
	{
		id: "manual_adjustments",
		label: "Manual Adjustments",
	},
	{
		id: "non_manual_adjustments",
		label: "Non Manual Adjustments",
	},
];

export const transactionFilterTypes = [
	{ value: "sports", name: "Sports" },
	{ value: "casino", name: "Casino" },
	{ value: "banking", name: "Banking" },
];

export const _transactionTypes = [
	{ id: "deposit", label: "Deposit" },
	{ id: "withdrawal", label: "Withdrawal" },
];

export const _suspendTypes = [
	{
		id: "violation",
		label: "Violation of Terms and Conditions:",
	},
	{
		id: "cheating",
		label: "Cheating",
	},
	{
		id: "payment issues",
		label: "Payment Issues",
	},
	{
		id: "collusion",
		label: "Collusion",
	},
	{
		id: "other",
		label: "Other",
	},
];

export const _selfExcludedTypes = [
	{
		id: "6",
		label: "6 Months",
	},
	{
		id: "12",
		label: "1 Year",
	},
	{
		id: "24",
		label: "2 Years",
	},
	{
		id: "60",
		label: "5 Years",
	},
	{
		id: "-1",
		label: "Disabled",
	},
];

export const _playBreakTypes = [
	{
		id: "1",
		label: "1 Day",
	},
	{
		id: "3",
		label: "3 Days",
	},
	{
		id: "7",
		label: "7 Days",
	},
	{
		id: "14",
		label: "14 Days",
	},
	{
		id: "30",
		label: "30 Days",
	},
	{
		id: "45",
		label: "45 Days",
	},
	{
		id: "60",
		label: "60 Days",
	},
	{
		id: "-1",
		label: "Disabled",
	},
];

export const _realityCheckTypes = [
	{
		id: "15",
		label: "15 Mins",
	},
	{
		id: "30",
		label: "30 Mins",
	},
	{
		id: "45",
		label: "45 Mins",
	},
	{
		id: "60",
		label: "60 Mins",
	},
	{
		id: "-1",
		label: "Disabled",
	},
];

export const _depositLimitTypes = [
	{
		id: "daily",
		label: "Daily Limit",
	},
	{
		id: "weekly",
		label: "Weekly Limit",
	},
	{
		id: "monthly",
		label: "Monthly Limit",
	},
];

export const ageVerifiedStatusOptions = [
	{ id: true, label: "Verified" },
	{ id: false, label: "Not Verified" },
];
export const _footerType = [
	{ id: "default", label: "Default" },
	{ id: "default_external", label: "Default External" },
	{ id: "new_tab", label: "New Tab" },
	{ id: "new_tab_external", label: "New Tab External" },
	{ id: "modal", label: "Modal" },
	{ id: "games_list", label: "Games List" },
];

export const _scheduleType = [
	{ id: "daily", label: "Daily" },
	{ id: "weekly", label: "Weekly" },
	{ id: "monthly", label: "Monthly" },
];

export const _changeTypeOptions = [
	{ id: "minor", label: "Minor" },
	{ id: "major", label: "Major" },
];

export const accountStatusOptions = [
	{ id: "active", label: "Active" },
	{ id: "suspended", label: "Suspended" },
	{ id: "pending", label: "Pending" },
	{ id: "closed", label: "Closed" },
	{ id: "locked", label: "Locked" },
];
export const kycStatusData = JSON.stringify([
	{ value: "init", name: "Started" },
	{ value: "pending", name: "Pending" },
	{ value: "rejected", name: "Rejected" },
	{ value: "verified", name: "Verified" },
	{ value: "not_started", name: "Not started" },
]);
export const accountstatusData = JSON.stringify([
	{ value: "1", name: "Active" },
	{ value: "0", name: "Suspended" },
]);
export const accountData = JSON.stringify([
	{ value: "test_user", name: "Test User" },
	{ value: "real_user", name: "Real User" },
]);

export const responsibleGamblingMenu = JSON.stringify([
	{ value: "daily_limit", name: "Daily Limit" },
	{ value: "weekly_limit", name: "Weekly Limit" },
	{ value: "monthly_limit", name: "Monthly Limit" },
]);

export const kycStatusOptions = [
	{ id: "not_started", label: "Not Started" },
	{ id: "rejected", label: "Rejected" },
	{ id: "verified", label: "Verified" },
	{ id: "pending", label: "Pending" },
	{ id: "init", label: "Started" },
];

export const betTypeData = [
	// { value: "bet_slip_place_single", name: "Single" },
	{ value: "bet_slip_single", name: "Single" },
	{ value: "bet_slip_place_unnamed_favorite", name: "Unnamed Favorite" },
	{ value: "bet_slip_place_each_way", name: "Each Way" },
	{ value: "bet_slip_place_double", name: "Double" },
	{ value: "bet_slip_place_treble", name: "Treble" },
	{ value: "bet_slip_place_trixie", name: "Trixie" },
	{ value: "bet_slip_place_forecast", name: "Forecast" },
	{ value: "bet_slip_place_reverse_forecast", name: "Reverse Forecast" },
	{ value: "bet_slip_place_yankee", name: "Yankee" },
	{ value: "bet_slip_place_lucky15", name: "Lucky 15" },
	{ value: "bet_slip_place_patent", name: "Patent" },
	{ value: "bet_slip_place_fold4", name: "Fold 4" },
	{ value: "bet_slip_place_fold5", name: "Fold 5" },
	{ value: "bet_slip_place_fold6", name: "Fold 6" },
	{ value: "bet_slip_place_fold7", name: "Fold 7" },
	{ value: "bet_slip_place_fold8", name: "Fold 8" },
	{ value: "bet_slip_place_fold9", name: "Fold 9" },
	{ value: "bet_slip_place_tricast", name: "Tricast" },
	{ value: "bet_slip_cash_out", name: "Cash Out" },
	{ value: "bet_slip_place_lucky31", name: "Lucky 31" },
	{ value: "bet_slip_place_superheinz", name: "Superheinz" },
	{ value: "bet_slip_place_reverse_tricast", name: "Reverse Tricast" },
	{ value: "bet_slip_place_heinz", name: "Heinz" },
	{ value: "bet_slip_place_lucky63", name: "Lucky 63" },
	{ value: "bet_slip_place_canadian", name: "Canadian" },
	{ value: "bet_slip_place_goliath", name: "Goliath" },
	{ value: "bet_slip_place_supergoliath", name: "Super Goliath" },
	{ value: "bet_slip_place_forecast_single", name: "Forecast Single" },
	{ value: "bet_slip_place_forecast_reverse", name: "Forecast Reverse" },
	{ value: "bet_slip_place_forecast_combination", name: "Forecast Combination" },
	{ value: "bet_slip_place_tricast_single", name: "Tricast Single" },
	{ value: "bet_slip_place_tricast_combination", name: "Tricast Combination" },
	// { value: "bet_slip_place_forecast_reverse", name: "Forecast Reverse" },
	// { value: "bet_slip_place_forecast_revers", name: "Forecast Revers" },
];

export const _betTypesOptions = [
	{ id: "single", label: "Singles" },
	{ id: "double", label: "Doubles" },
	{ id: "treble", label: "Trebles" },
	{ id: "trixie", label: "Trixie" },
	{ id: "fold", label: "Folds" },
	{ id: "patent", label: "Patent" },
	{ id: "yankee", label: "Yankee" },
	{ id: "lucky15", label: "Lucky 15" },
	{ id: "canadian", label: "Canadian" },
	{ id: "lucky31", label: "Lucky 31" },
	{ id: "heinz", label: "Heinz" },
	{ id: "lucky63", label: "Lucky 63" },
	{ id: "superheinz", label: "Super Heinz" },
	{ id: "goliath", label: "Goliath" },
];
export const _betTypesOptionsSIS = [
	{ id: "forecast", label: "Forecast" },
	{ id: "reverse_forecast", label: "Reverse Forecast" },
	{ id: "tricast", label: "Tricast" },
	{ id: "unnamed_favorite", label: "Unnamed Favorite" },
];

export const _openTypeOptions = [
	{ id: "same_tab", label: "Same Tab" },
	{ id: "new_tab", label: "New Tab" },
];
export const _LinkTypeOptions = [
	{ id: "generic", label: "Generic" },
	{ id: "modal", label: "Modal" },
	{ id: "sport", label: "Sport" },
	{ id: "event", label: "Event" },
	{ id: "casino", label: "Casino" },
	{ id: "page", label: "Page" },
];

export const _PageTypeOptions = [
	{ id: "generic", label: "Generic" },
	{ id: "page", label: "Page" },
	{ id: "modal", label: "Modal" },
	{ id: "sport", label: "Sport" },
];
export const marketSelectionTypes = ["away_team_name", "player_name", "player_number", "home_team_name", "selection_name", "sequence", "handicap"];
export const languageLabel = {
	en: "English",
	de: "German",
	pl: "Polish",
	fr: "French",
	sq: "Albanian",
	es: "Spanish",
};

export const defaultOddFormatData = [
	// { id: "straight", label: "Straight" },
	{ id: "decimal", label: "Decimal" },
	// { id: "american", label: "American" },
	{ id: "fractional", label: "Fractional" },
];

export const roleStaticData = [
	{ id: "admin", label: "Administrator" },
	{ id: "auditor", label: "Auditor" },
	{ id: "customer_service", label: "Customer Service" },
	{ id: "finance", label: "Finance" },
	{ id: "operations", label: "Operations" },
	{ id: "trader", label: "Trader" },
];

export const defaultRowPerPage = 50;

export const httpCodes = [
	{ code: 200 },
	{ code: 210 },
	{ code: 211 },
	{ code: 212 },
	{ code: 400 },
	{ code: 401 },
	{ code: 403 },
	{ code: 485 },
	{ code: 486 },
	{ code: 488 },
];

export const transactionStatusBadge = {
	inited: '<span className="badge badge-warning"> Initialed </span>',
	pending: '<span className="badge badge-info"> Pending </span>',
	processed: '<span className="badge badge-success"> Completed </span>',
	successful: '<span className="badge badge-success"> Completed </span>',
	expired: '<span className="badge badge-secondary"> Expired </span>',
	failed: '<span className="badge badge-danger"> Failed </span>',
	null: '<span className="badge badge-info"> Undefined </span>',
};

export const _selectionDeductionTypesOptions = [
	{ id: "5", label: "5%" },
	{ id: "10", label: "10%" },
	{ id: "20", label: "20%" },
	{ id: "30", label: "30%" },
	{ id: "50", label: "50%" },
	{ id: "75", label: "75%" },
	{ id: "100", label: "100%" },
];
export const _telebetDaysTypesOptions = [
	{ id: "today", label: "Today" },
	{ id: "tomorrow", label: "Tomorrow" },
	{ id: "two-weeks", label: "Two Weeks" },
];

export const _usageFreebetOptions = [
	{ id: "cash", label: "Cash" },
	{ id: "sports", label: "Sports Free Bet" },
	{ id: "casino", label: "Casino Free Bet" },
];
export const _usageMarketSortByOptions = [
	{ id: "odds", label: "Price" },
	{ id: "outcome-type", label: "Outcome" },
	{ id: "handicaps", label: "Handicap" },
	{ id: "yes-no", label: "Yes / No" },
	{ id: "over-under", label: "Over / Under" },
];

export const _showColumnsOptions = [
	{ id: "1", label: "1" },
	{ id: "2", label: "2" },
	{ id: "3", label: "3" },
];

export const sportsLinks = [
	{
		menuTitle: "Sportsbook",
		links: [
			{
				sportsName: "Sports Types",
				route: "/sport/type/list",
			},
			{
				sportsName: "Sports Order",
				route: "/sport/order",
			},
			{
				sportsName: "Competitions",
				route: "/sport/competition/list",
			},
			{
				sportsName: "Period Competitions",
				route: "/sport/competition/period/list",
			},
			{
				sportsName: "Competitions Groups",
				route: "/sport/competition/group/list",
			},
			{
				sportsName: "Deafult Competitions",
				route: "/sport/league/default/list",
			},
		],
		roleToHide: ["finance"],
	},
];

export const _pageLayoutOptions = [
	{
		name: "Default",
		value: "default",
	},
	{
		name: "Dynamic Promo",
		value: "dynamic",
	},
];

export const cmsLinks = [
	{
		links: [
			{
				linkName: "Bonus Engine",
				route: "/bonus_engine",
			},
		],
	},
	{
		menuTitle: "Promotions",
		links: [
			{
				linkName: "Banners",
				route: "/banners",
				roleToHide: ["finance", "customer_service"],
			},
			{
				linkName: "Betslip",
				route: "/betslip",
				roleToHide: ["finance", "customer_service"],
			},
			{
				linkName: "Carousel",
				route: "/carousel",
				roleToHide: ["finance", "customer_service"],
			},
			{
				linkName: "Push Notifications",
				route: "/push_notification",
				roleToHide: ["finance"],
			},
			...(process.env.REACT_APP_MODULE_ENABLE_RACING === "0"
				? []
				: [
						{
							linkName: "Racing Widget",
							route: "/racing_widget",
							roleToHide: ["finance", "customer_service"],
						},
				  ]),
			{
				linkName: "Offers",
				route: "/offers",
				roleToHide: ["finance", "customer_service"],
			},
			...(process.env.REACT_APP_MODULE_ENABLE_SPORT === "0"
				? []
				: [
						{
							linkName: "Sport Widget",
							route: "/sports_widget",
							roleToHide: ["finance", "customer_service"],
						},
				  ]),
			{
				linkName: "Promo Links",
				route: "/promo_links",
			},
			{
				linkName: "Promo Landing Pages",
				route: "/promo_landing_pages",
			},
		],
	},
	...(process.env.REACT_APP_MODULE_ENABLE_CASINO === "0"
		? []
		: [
				{
					menuTitle: "Games",
					links: [
						{
							linkName: "Casino",
							route: "/casino",
							roleToHide: ["finance", "customer_service"],
						},
						{
							linkName: "Live Casino",
							route: "/live_casino",
							roleToHide: ["finance", "customer_service"],
						},
						{
							linkName: "Virtual Casino",
							route: "/virtual_casino",
							roleToHide: ["finance", "customer_service"],
						},
					],
				},
		  ]),
	{
		menuTitle: "General",
		links: [
			{
				linkName: "Email Templates",
				route: "/email_template",
				roleToHide: ["finance", "trader", "customer_service"],
			},
			{
				linkName: "Footer",
				route: "/footer",
				roleToHide: ["finance", "trader", "customer_service"],
			},
			{
				linkName: "Media Library",
				route: "/media_library",
				roleToHide: ["finance", "customer_service"],
			},

			{
				linkName: "Message Codes",
				route: "/message_codes",
				roleToHide: ["finance", "trader", "customer_service"],
			},
			{
				linkName: "Notification Bar",
				route: "/notification_bar",
				roleToHide: ["finance"],
			},
			{
				linkName: "Pages",
				route: "/pages",
				roleToHide: ["finance", "customer_service"],
			},
			{
				linkName: "Page Content",
				route: "/page_content",
				roleToHide: ["finance", "customer_service"],
			},
			{
				linkName: "Page Layout",
				route: "/page_layout",
				roleToHide: ["finance", "customer_service"],
			},
		],
	},
	{
		menuTitle: "Legal",
		links: [
			{
				linkName: "Privacy Policy",
				route: "/policies",
				roleToHide: ["finance", "trader", "customer_service"],
			},
			{
				linkName: "Terms and Conditions",
				route: "/terms",
				roleToHide: ["finance", "trader", "customer_service"],
			},
		],
	},
];
export const affiliateSidebarLinks = [
	{
		menuTitle: "Affiliates Back Office",
		links: [
			{
				linkName: "Player Performance",
				route: "/affiliates",
				roleToHide: ["finance", "trader", "customer_service", "auditor", "operations"],
			},
			{
				linkName: "Monthly Overview",
				route: "/monthly-overview",
				roleToHide: ["finance", "trader", "customer_service", "auditor", "operations"],
			},
			{
				linkName: "Affiliate Settings",
				route: "/affiliates-settings",
				roleToHide: ["finance", "trader", "customer_service", "auditor", "operations"],
			},
		],
	},
];

export const tradingLinks = [
	{
		menuTitle: "Search",
		links: [
			{
				tradingName: "Search Bets",
				link: "/bets",
				roleToHide: ["finance"],
			},
		],
	},
	{
		menuTitle: "Trading",
		links: [
			{
				tradingName: "Events",
				link: "/events",
				roleToHide: ["finance"],
			},
			{
				tradingName: "Liabilities",
				link: "/liabilities",
				roleToHide: ["finance", "customer_service"],
			},

			{
				tradingName: "Manual Bets",
				link: "/manual_bets",
				roleToHide: ["finance", "customer_service"],
			},
		],
	},
	{
		menuTitle: "Sportsbook",
		links: [
			{
				tradingName: "Sports",
				link: "/sport/type/list",
				roleToHide: ["finance", "customer_service"],
			},
			{
				tradingName: "Competitions",
				link: "/sport/competition/list",
				roleToHide: ["finance", "customer_service"],
			},
			{
				tradingName: "Markets",
				link: "/markets",
				roleToHide: ["finance", "customer_service"],
			},
		],
	},
	{
		// menuTitle: "Predictions",
		links: [
			{
				tradingName: "Sports Display Order",
				link: "/sport/order",
				roleToHide: ["finance", "customer_service"],
			},
			// {
			// 	tradingName: "Default Competitions",
			// 	link: "/sport/league/default/list",
			// 	roleToHide: ["finance", "customer_service"],
			// },
			// {
			// 	tradingName: "Period Competitions",
			// 	link: "/sport/competition/period/list",
			// },
		],
	},
];

export const settingsLinks = [
	{
		name: "About",
		link: "/about",
	},
	{
		name: "Activity Log",
		link: "/activity_log",
		roleToHide: ["finance", "trader", "operations", "customer_service"],
	},
	{
		name: "Affiliates Users",
		link: "/affiliate_users",
		roleToHide: ["finance", "trader", "operations", "customer_service"],
	},
	//Hide for now we will bring this later
	// {
	// 	name: "APIs",
	// 	link: "/apis",
	// },
	{
		name: "Casino Providers",
		link: "/casino-providers",
		roleToHide: ["finance", "trader", "operations", "customer_service"],
	},
	{
		name: "CMS Users",
		link: "/user_list",
		roleToHide: ["finance", "trader", "operations", "customer_service"],
	},
	{
		name: "CMS Activity Log",
		link: "/cms_users_activity",
		roleToHide: ["finance", "trader", "customer_service"],
	},
	{
		name: "Countries",
		link: "/countries",
		roleToHide: ["finance", "trader", "customer_service"],
	},
	{
		name: "Currencies",
		link: "/currencies",
		roleToHide: ["finance", "trader", "customer_service"],
	},
	//Hide for now providers
	// {
	// 	name: "Providers",
	// 	link: "/providers",
	// },
	{
		name: "Ladders",
		link: "/ladders",
		roleToHide: ["finance", "trader", "customer_service"],
	},
	{
		name: "Languages",
		link: "/languages",
		roleToHide: ["finance", "trader", "customer_service"],
	},
	// {
	// 	name: "Payment Gateways",
	// 	link: "/payment_gateways",
	// },
	{
		name: "Trader Options",
		link: "/bog_settings",
		roleToHide: ["finance", "trader", "customer_service"],
	},
	// {
	// 	name: "Transaction Checker",
	// 	link: "/transaction_checker",
	// 	roleToHide: ["finance", "trader", "customer_service"],
	// },
	{
		name: "SEO",
		link: "/seo",
		roleToHide: ["finance", "trader", "operations", "customer_service"],
	},
	{
		name: "Style (CSS)",
		link: "/style",
		roleToHide: ["finance", "trader", "operations", "customer_service"],
	},
	{
		name: "Variables",
		link: "/variables",
		roleToHide: ["finance", "trader", "operations", "customer_service"],
	},
	{
		name: "",
		link: "/bet_options",
		roleToHide: ["finance", "trader", "customer_service"],
	},
	{
		name: "",
		link: "/cash_out",
		roleToHide: ["finance", "trader", "customer_service"],
	},
	{
		name: "",
		link: "/trader_chat",
		roleToHide: ["finance", "trader", "customer_service"],
	},
	{
		name: "",
		link: "/casino_settings",
		roleToHide: ["finance", "trader", "customer_service"],
	},
];

export const languages = [
	{
		name: "French (FR)",
		for: "fr",
	},
	{
		name: "Spanish (ES)",
		for: "es",
	},
];

export const _daysOptions = [
	{ id: "monday", label: "Monday" },
	{ id: "tuesday", label: "Tuesday" },
	{ id: "wednesday", label: "Wednesday" },
	{ id: "thursday", label: "Thursday" },
	{ id: "friday", label: "Friday" },
	{ id: "saturday", label: "Saturday" },
	{ id: "sunday", label: "Sunday" },
];

export const _marketMatchesFilterOptions = [
	{ id: "all", label: "All" },
	{ id: "open", label: "Open" },
	{ id: "closed", label: "Closed" },
	{ id: "suspended", label: "Suspended" },
];

export const _priceBoostOptions = [
	{ id: "ladder+1", label: "Ladder +1" },
	{ id: "ladder+2", label: "Ladder +2" },
	{ id: "ladder+3", label: "Ladder +3" },
	{ id: "ladder+4", label: "Ladder +4" },
	{ id: "-1", label: "Not Set" },
];

export const _liabilitiesOptions = [
	{ id: "pre_match", label: "Pre Match Limits" },
	{ id: "in_play", label: "In Play" },
];
export const _liabilitiesOptionsForSis = [{ id: "pre_match", label: "Pre Match Limits" }];
export const _resultMatchesOptions = [
	{ id: "resulted", label: "Resulted" },
	{ id: "not_resulted", label: "Not Resulted" },
];
export const _editResultMarketOptions = [
	{ id: "winner", label: "Winner" },
	{ id: "partial", label: "Partial Win" },
	{ id: "loser", label: "Lose" },
	{ id: "void", label: "Void" },
	{ id: "open", label: "Open" },
];

export const _resultBetsOptions = [
	{ id: "winner", label: "Winner" },
	{ id: "loser", label: "Loser" },
	{ id: "pushed", label: "Pushed" },
	{ id: "partial", label: "Partial" },
	{ id: "placed", label: "Placed" },
	{ id: "cash_out", label: "Cashout" },
];

export const _reportResultBetsOptions = [
	{ id: "winner", label: "Winner" },
	{ id: "loser", label: "Loser" },
	{ id: "partial", label: "Partial" },
	{ id: "placed", label: "Placed" },
	{ id: "cash_out", label: "Cashout" },
	{ id: "void", label: "Void" },
];

export const _eventPhaseType = [
	{ id: "Pre-Match", label: "Pre-Match" },
	{ id: "In-Play", label: "In-Play" },
	{ id: "Finished", label: "Finished" },
	{ id: "Suspended", label: "Suspended" },
	{ id: "Interrupted", label: "Interrupted" },
];

export const _eventPhaseFreeBetType = [
	{ id: "in-play", label: "In-Play" },
	{ id: "pre-match", label: "Pre-Match" },
	{ id: "all", label: "All" },
];

export const eventPhaseBetsAllowed = [
	{ id: "pre-match", label: "Pre-Match" },
	{ id: "in-play", label: "In-Play" },
];

export const _casinoCategoryOptions = [
	{ id: "single_line", label: "Single Line" },
	{ id: "hero_multi_line", label: "Hero Multi-Line" },
	{ id: "multi_line", label: "Multi-Line" },
	{ id: "trending", label: "Trending" },
];

// export const _gmtTimezones = [
// 	{ id: 1, offset: -720, label: "(GMT-12:00) International Date Line West" },
// 	{ id: 2, offset: -660, label: "(GMT-11:00) Midway Island, Samoa" },
// 	{ id: 3, offset: -600, label: "(GMT-10:00) Hawaii" },
// 	{ id: 4, offset: -540, label: "(GMT-09:00) Alaska" },
// 	{ id: 5, offset: -480, label: "(GMT-08:00) Pacific Time (US and Canada)" },
// 	{ id: 6, offset: -420, label: "(GMT-07:00) Mountain Time (US and Canada)" },
// 	{ id: 7, offset: -420, label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
// 	{ id: 8, offset: -420, label: "(GMT-07:00) Arizona" },
// 	{ id: 9, offset: -360, label: "(GMT-06:00) Central Time (US and Canada)" },
// 	{ id: 10, offset: -360, label: "(GMT-06:00) Saskatchewan" },
// 	{ id: 11, offset: -360, label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey" },
// 	{ id: 12, offset: -360, label: "(GMT-06:00) Central America" },
// 	{ id: 13, offset: -300, label: "(GMT-05:00) Eastern Time (US and Canada)" },
// 	{ id: 14, offset: -300, label: "(GMT-05:00) Indiana (East)" },
// 	{ id: 15, offset: -300, label: "(GMT-05:00) Bogota, Lima, Quito" },
// 	{ id: 16, offset: -240, label: "(GMT-04:00) Atlantic Time (Canada)" },
// 	{ id: 17, offset: -240, label: "(GMT-04:00) Caracas, La Paz" },
// 	{ id: 18, offset: -240, label: "(GMT-04:00) Santiago" },
// 	{ id: 19, offset: -210, label: "(GMT-03:30) Newfoundland and Labrador" },
// 	{ id: 20, offset: -180, label: "(GMT-03:00) Brasilia" },
// 	{ id: 21, offset: -180, label: "(GMT-03:00) Buenos Aires, Georgetown" },
// 	{ id: 22, offset: -180, label: "(GMT-03:00) Greenland" },
// 	{ id: 23, offset: -120, label: "(GMT-02:00) Mid-Atlantic" },
// 	{ id: 24, offset: -60, label: "(GMT-01:00) Azores" },
// 	{ id: 25, offset: -60, label: "(GMT-01:00) Cape Verde Islands" },
// 	{ id: 26, offset: 0, label: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London" },
// 	{ id: 27, offset: 0, label: "(GMT) Casablanca, Monrovia" },
// 	{ id: 28, offset: 60, label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
// 	{ id: 29, offset: 60, label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
// 	{ id: 30, offset: 60, label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
// 	{ id: 31, offset: 60, label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
// 	{ id: 32, offset: 60, label: "(GMT+01:00) West Central Africa" },
// 	{ id: 33, offset: 120, label: "(GMT+02:00) Bucharest" },
// 	{ id: 34, offset: 120, label: "(GMT+02:00) Cairo" },
// 	{ id: 35, offset: 120, label: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius" },
// 	{ id: 36, offset: 120, label: "(GMT+02:00) Athens, Istanbul, Minsk" },
// 	{ id: 37, offset: 120, label: "(GMT+02:00) Jerusalem" },
// 	{ id: 38, offset: 120, label: "(GMT+02:00) Harare, Pretoria" },
// 	{ id: 39, offset: 180, label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
// 	{ id: 40, offset: 180, label: "(GMT+03:00) Kuwait, Riyadh" },
// 	{ id: 41, offset: 180, label: "(GMT+03:00) Nairobi" },
// 	{ id: 42, offset: 180, label: "(GMT+03:00) Baghdad" },
// 	{ id: 43, offset: 210, label: "(GMT+03:30) Tehran" },
// 	{ id: 44, offset: 240, label: "(GMT+04:00) Abu Dhabi, Muscat" },
// 	{ id: 45, offset: 240, label: "(GMT+04:00) Baku, Tbilisi, Yerevan" },
// 	{ id: 46, offset: 270, label: "(GMT+04:30) Kabul" },
// 	{ id: 47, offset: 300, label: "(GMT+05:00) Ekaterinburg" },
// 	{ id: 48, offset: 300, label: "(GMT+05:00) Islamabad, Karachi, Tashkent" },
// 	{ id: 49, offset: 330, label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
// 	{ id: 50, offset: 345, label: "(GMT+05:45) Kathmandu" },
// 	{ id: 51, offset: 360, label: "(GMT+06:00) Astana, Dhaka" },
// 	{ id: 52, offset: 360, label: "(GMT+06:00) Sri Jayawardenepura" },
// 	{ id: 53, offset: 360, label: "(GMT+06:00) Almaty, Novosibirsk" },
// 	{ id: 54, offset: 390, label: "(GMT+06:30) Yangon Rangoon" },
// 	{ id: 55, offset: 420, label: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
// 	{ id: 56, offset: 420, label: "(GMT+07:00) Krasnoyarsk" },
// 	{ id: 57, offset: 480, label: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi" },
// 	{ id: 58, offset: 480, label: "(GMT+08:00) Kuala Lumpur, Singapore" },
// 	{ id: 59, offset: 480, label: "(GMT+08:00) Taipei" },
// 	{ id: 60, offset: 480, label: "(GMT+08:00) Perth" },
// 	{ id: 61, offset: 480, label: "(GMT+08:00) Irkutsk, Ulaanbaatar" },
// 	{ id: 62, offset: 540, label: "(GMT+09:00) Seoul" },
// 	{ id: 63, offset: 540, label: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
// 	{ id: 64, offset: 540, label: "(GMT+09:00) Yakutsk" },
// 	{ id: 65, offset: 570, label: "(GMT+09:30) Darwin" },
// 	{ id: 66, offset: 570, label: "(GMT+09:30) Adelaide" },
// 	{ id: 67, offset: 600, label: "(GMT+10:00) Canberra, Melbourne, Sydney" },
// 	{ id: 68, offset: 600, label: "(GMT+10:00) Brisbane" },
// 	{ id: 69, offset: 600, label: "(GMT+10:00) Hobart" },
// 	{ id: 70, offset: 600, label: "(GMT+10:00) Vladivostok" },
// 	{ id: 71, offset: 600, label: "(GMT+10:00) Guam, Port Moresby" },
// 	{ id: 72, offset: 660, label: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia" },
// 	{ id: 73, offset: 720, label: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands" },
// 	{ id: 74, offset: 720, label: "(GMT+12:00) Auckland, Wellington" },
// 	{ id: 75, offset: 780, label: "(GMT+13:00) Nuku'alofa" },
// ];

export const _gmtTimezones = [
	{
		name: "(GMT-11:00) Midway Island",
		timezone: "Pacific/Midway",
	},
	{
		name: "(GMT-11:00) Samoa",
		timezone: "US/Samoa",
	},
	{
		name: "(GMT-10:00) Hawaii",
		timezone: "US/Hawaii",
	},
	{
		name: "(GMT-09:00) Alaska",
		timezone: "US/Alaska",
	},
	{
		name: "(GMT-08:00) Pacific Time (US & Canada)",
		timezone: "US/Pacific",
	},
	{
		name: "(GMT-08:00) Tijuana",
		timezone: "America/Tijuana",
	},
	{
		name: "(GMT-07:00) Arizona",
		timezone: "US/Arizona",
	},
	{
		name: "(GMT-07:00) Mountain Time (US & Canada)",
		timezone: "US/Mountain",
	},
	{
		name: "(GMT-07:00) Chihuahua",
		timezone: "America/Chihuahua",
	},
	{
		name: "(GMT-07:00) Mazatlan",
		timezone: "America/Mazatlan",
	},
	{
		name: "(GMT-06:00) Mexico City",
		timezone: "America/Mexico_City",
	},
	{
		name: "(GMT-06:00) Monterrey",
		timezone: "America/Monterrey",
	},
	{
		name: "(GMT-06:00) Saskatchewan",
		timezone: "Canada/Saskatchewan",
	},
	{
		name: "(GMT-06:00) Central Time (US & Canada)",
		timezone: "US/Central",
	},
	{
		name: "(GMT-05:00) Eastern Time (US & Canada)",
		timezone: "US/Eastern",
	},
	{
		name: "(GMT-05:00) Indiana (East)",
		timezone: "US/East-Indiana",
	},
	{
		name: "(GMT-05:00) Bogota",
		timezone: "America/Bogota",
	},
	{
		name: "(GMT-05:00) Lima",
		timezone: "America/Lima",
	},
	{
		name: "(GMT-04:30) Caracas",
		timezone: "America/Caracas",
	},
	{
		name: "(GMT-04:00) Atlantic Time (Canada)",
		timezone: "Canada/Atlantic",
	},
	{
		name: "(GMT-04:00) La Paz",
		timezone: "America/La_Paz",
	},
	{
		name: "(GMT-04:00) Santiago",
		timezone: "America/Santiago",
	},
	{
		name: "(GMT-03:30) Newfoundland",
		timezone: "Canada/Newfoundland",
	},
	{
		name: "(GMT-03:00) Buenos Aires",
		timezone: "America/Buenos_Aires",
	},
	{
		name: "(GMT-03:00) Greenland",
		timezone: "Greenland",
	},
	{
		name: "(GMT-02:00) Stanley",
		timezone: "Atlantic/Stanley",
	},
	{
		name: "(GMT-01:00) Azores",
		timezone: "Atlantic/Azores",
	},
	{
		name: "(GMT-01:00) Cape Verde Is.",
		timezone: "Atlantic/Cape_Verde",
	},
	{
		name: "(GMT) Casablanca",
		timezone: "Africa/Casablanca",
	},
	{
		name: "(GMT) Dublin",
		timezone: "Europe/Dublin",
	},
	{
		name: "(GMT) Lisbon",
		timezone: "Europe/Lisbon",
	},
	{
		name: "(GMT) London",
		timezone: "Europe/London",
	},
	{
		name: "(GMT) Monrovia",
		timezone: "Africa/Monrovia",
	},
	{
		name: "(GMT+01:00) Amsterdam",
		timezone: "Europe/Amsterdam",
	},
	{
		name: "(GMT+01:00) Belgrade",
		timezone: "Europe/Belgrade",
	},
	{
		name: "(GMT+01:00) Berlin",
		timezone: "Europe/Berlin",
	},
	{
		name: "(GMT+01:00) Bratislava",
		timezone: "Europe/Bratislava",
	},
	{
		name: "(GMT+01:00) Brussels",
		timezone: "Europe/Brussels",
	},
	{
		name: "(GMT+01:00) Budapest",
		timezone: "Europe/Budapest",
	},
	{
		name: "(GMT+01:00) Copenhagen",
		timezone: "Europe/Copenhagen",
	},
	{
		name: "(GMT+01:00) Ljubljana",
		timezone: "Europe/Ljubljana",
	},
	{
		name: "(GMT+01:00) Madrid",
		timezone: "Europe/Madrid",
	},
	{
		name: "(GMT+01:00) Paris",
		timezone: "Europe/Paris",
	},
	{
		name: "(GMT+01:00) Prague",
		timezone: "Europe/Prague",
	},
	{
		name: "(GMT+01:00) Rome",
		timezone: "Europe/Rome",
	},
	{
		name: "(GMT+01:00) Sarajevo",
		timezone: "Europe/Sarajevo",
	},
	{
		name: "(GMT+01:00) Skopje",
		timezone: "Europe/Skopje",
	},
	{
		name: "(GMT+01:00) Stockholm",
		timezone: "Europe/Stockholm",
	},
	{
		name: "(GMT+01:00) Vienna",
		timezone: "Europe/Vienna",
	},
	{
		name: "(GMT+01:00) Warsaw",
		timezone: "Europe/Warsaw",
	},
	{
		name: "(GMT+01:00) Zagreb",
		timezone: "Europe/Zagreb",
	},
	{
		name: "(GMT+02:00) Athens",
		timezone: "Europe/Athens",
	},
	{
		name: "(GMT+02:00) Bucharest",
		timezone: "Europe/Bucharest",
	},
	{
		name: "(GMT+02:00) Cairo",
		timezone: "Africa/Cairo",
	},
	{
		name: "(GMT+02:00) Harare",
		timezone: "Africa/Harare",
	},
	{
		name: "(GMT+02:00) Helsinki",
		timezone: "Europe/Helsinki",
	},
	{
		name: "(GMT+02:00) Istanbul",
		timezone: "Europe/Istanbul",
	},
	{
		name: "(GMT+02:00) Jerusalem",
		timezone: "Asia/Jerusalem",
	},
	{
		name: "(GMT+02:00) Kyiv",
		timezone: "Europe/Kiev",
	},
	{
		name: "(GMT+02:00) Minsk",
		timezone: "Europe/Minsk",
	},
	{
		name: "(GMT+02:00) Riga",
		timezone: "Europe/Riga",
	},
	{
		name: "(GMT+02:00) Sofia",
		timezone: "Europe/Sofia",
	},
	{
		name: "(GMT+02:00) Tallinn",
		timezone: "Europe/Tallinn",
	},
	{
		name: "(GMT+02:00) Vilnius",
		timezone: "Europe/Vilnius",
	},
	{
		name: "(GMT+03:00) Baghdad",
		timezone: "Asia/Baghdad",
	},
	{
		name: "(GMT+03:00) Kuwait",
		timezone: "Asia/Kuwait",
	},
	{
		name: "(GMT+03:00) Nairobi",
		timezone: "Africa/Nairobi",
	},
	{
		name: "(GMT+03:00) Riyadh",
		timezone: "Asia/Riyadh",
	},
	{
		name: "(GMT+03:00) Moscow",
		timezone: "Europe/Moscow",
	},
	{
		name: "(GMT+03:30) Tehran",
		timezone: "Asia/Tehran",
	},
	{
		name: "(GMT+04:00) Baku",
		timezone: "Asia/Baku",
	},
	{
		name: "(GMT+04:00) Volgograd",
		timezone: "Europe/Volgograd",
	},
	{
		name: "(GMT+04:00) Muscat",
		timezone: "Asia/Muscat",
	},
	{
		name: "(GMT+04:00) Tbilisi",
		timezone: "Asia/Tbilisi",
	},
	{
		name: "(GMT+04:00) Yerevan",
		timezone: "Asia/Yerevan",
	},
	{
		name: "(GMT+04:30) Kabul",
		timezone: "Asia/Kabul",
	},
	{
		name: "(GMT+05:00) Karachi",
		timezone: "Asia/Karachi",
	},
	{
		name: "(GMT+05:00) Tashkent",
		timezone: "Asia/Tashkent",
	},
	{
		name: "(GMT+05:30) Kolkata",
		timezone: "Asia/Kolkata",
	},
	{
		name: "(GMT+05:45) Kathmandu",
		timezone: "Asia/Kathmandu",
	},
	{
		name: "(GMT+06:00) Ekaterinburg",
		timezone: "Asia/Yekaterinburg",
	},
	{
		name: "(GMT+06:00) Almaty",
		timezone: "Asia/Almaty",
	},
	{
		name: "(GMT+06:00) Dhaka",
		timezone: "Asia/Dhaka",
	},
	{
		name: "(GMT+07:00) Novosibirsk",
		timezone: "Asia/Novosibirsk",
	},
	{
		name: "(GMT+07:00) Bangkok",
		timezone: "Asia/Bangkok",
	},
	{
		name: "(GMT+07:00) Jakarta",
		timezone: "Asia/Jakarta",
	},
	{
		name: "(GMT+08:00) Krasnoyarsk",
		timezone: "Asia/Krasnoyarsk",
	},
	{
		name: "(GMT+08:00) Chongqing",
		timezone: "Asia/Chongqing",
	},
	{
		name: "(GMT+08:00) Hong Kong",
		timezone: "Asia/Hong_Kong",
	},
	{
		name: "(GMT+08:00) Kuala Lumpur",
		timezone: "Asia/Kuala_Lumpur",
	},
	{
		name: "(GMT+08:00) Perth",
		timezone: "Australia/Perth",
	},
	{
		name: "(GMT+08:00) Singapore",
		timezone: "Asia/Singapore",
	},
	{
		name: "(GMT+08:00) Taipei",
		timezone: "Asia/Taipei",
	},
	{
		name: "(GMT+08:00) Ulaan Bataar",
		timezone: "Asia/Ulaanbaatar",
	},
	{
		name: "(GMT+08:00) Urumqi",
		timezone: "Asia/Urumqi",
	},
	{
		name: "(GMT+09:00) Irkutsk",
		timezone: "Asia/Irkutsk",
	},
	{
		name: "(GMT+09:00) Seoul",
		timezone: "Asia/Seoul",
	},
	{
		name: "(GMT+09:00) Tokyo",
		timezone: "Asia/Tokyo",
	},
	{
		name: "(GMT+09:30) Adelaide",
		timezone: "Australia/Adelaide",
	},
	{
		name: "(GMT+09:30) Darwin",
		timezone: "Australia/Darwin",
	},
	{
		name: "(GMT+10:00) Yakutsk",
		timezone: "Asia/Yakutsk",
	},
	{
		name: "(GMT+10:00) Brisbane",
		timezone: "Australia/Brisbane",
	},
	{
		name: "(GMT+10:00) Canberra",
		timezone: "Australia/Canberra",
	},
	{
		name: "(GMT+10:00) Guam",
		timezone: "Pacific/Guam",
	},
	{
		name: "(GMT+10:00) Hobart",
		timezone: "Australia/Hobart",
	},
	{
		name: "(GMT+10:00) Melbourne",
		timezone: "Australia/Melbourne",
	},
	{
		name: "(GMT+10:00) Port Moresby",
		timezone: "Pacific/Port_Moresby",
	},
	{
		name: "(GMT+10:00) Sydney",
		timezone: "Australia/Sydney",
	},
	{
		name: "(GMT+11:00) Vladivostok",
		timezone: "Asia/Vladivostok",
	},
	{
		name: "(GMT+12:00) Magadan",
		timezone: "Asia/Magadan",
	},
	{
		name: "(GMT+12:00) Auckland",
		timezone: "Pacific/Auckland",
	},
	{
		name: "(GMT+12:00) Fiji",
		timezone: "Pacific/Fiji",
	},
].map(({ name, timezone }) => ({ label: name, id: timezone }));

export const usersChatLinks = [
	{
		menuTitle: "Accounts",
		links: [
			{
				tradingName: "Search Users",
				link: "/search_users",
			},
			{
				tradingName: "Trader Chat",
				link: "/users_chat",
				roleToHide: ["finance"],
			},
		],
	},
];

export const userProfile = [
	{
		menuTitle: "Profile",
		links: [
			{
				tradingName: "Change Password",
				link: "/profile_change_password",
			},
			{
				tradingName: "Preferences",
				link: "/profile_preferences",
				roleToHide: ["affiliate"],
			},
		],
	},
];

export const returnDayIndex = day => {
	switch (day) {
		case "monday":
			return 0;
		case "tuesday":
			return 1;
		case "wednesday":
			return 2;
		case "thursday":
			return 3;
		case "friday":
			return 4;
		case "saturday":
			return 5;
		case "sunday":
			return 6;
		default:
			return 0;
	}
};

export const _inputTypeOptions = [
	{
		name: "None",
		value: "none",
	},
	{
		name: "Generic",
		value: "generic",
	},
	{
		name: "Page",
		value: "page",
	},
	{
		name: "Sport",
		value: "sport",
	},
	{
		name: "Sport Event",
		value: "event",
	},
	{
		name: "Casino",
		value: "casino",
	},
	{
		name: "Casino Game",
		value: "casino_game",
	},
];

export const _promoTypeOptions = [
	{
		name: "Default",
		value: "default",
	},
	{
		name: "Dynamic Promo",
		value: "dynamic",
	},
];

export const eventStatuses = [
	{ name: "Live", value: "live" },
	{ name: "Upcoming", value: "upcoming" },
	{ name: "Finished", value: "finished" },
	{ name: "Abandoned", value: "abandoned" },
	{ name: "Postponed", value: "postponed" },
	{ name: "Unclosed", value: "unclosed" },
	{ name: "Interrupted", value: "interrupted" },
];

export const manualEventStatuses = [
	{ name: "Yes", value: "1" },
	{ name: "No", value: "0" },
];

export const eventFinishedStatuses = [
	"PostMatch",
	"PostGame",
	"WinnerCompetitorOneByRetirementOrDefault",
	"WinnerCompetitorTwoByRetirementOrDefault",
	"WinnerCompetitorTwo",
	"WinnerCompetitorOne",
	"Off",
];

export const rightsHolder = [
	{ id: "All", label: "All" },
	{ id: "SIS", label: "SIS" },
	{ id: "ATR", label: "ATR" },
	{ id: "RMG", label: "RMG" },
	{ id: "Unknown", label: "Unknown" },
];

export const getLanguages = type => {
	let languages = useSelector(state => state.Login.languages);
	let returnData = [];
	if (type !== "content" && type !== "template") {
		languages.forEach(row => {
			returnData.push({
				name: row?.name,
				key: `${type}_${row?.for}`,
				language: `${row?.for}`,
			});
		});
	}

	return returnData;
};

export const reportType = {
	SAFER_GAMBLING: "safer-gambling",
	DEPOSIT: "deposit-money",
	DUPLICATE_USERS: "duplicate-users",
	LIABILITIES: "liabilities",
	SUSPENDED_ACCOUNTS: "suspended-accounts",
	TURNOVER: "turnover-by-sports",
	UNCLOSED_BETS: "unclosed-bets",
	NET_SESSION: "net-session",
	DEPOSIT_DIFFERENT_NAMES: "deposit-different-names",
	HEALTH_API: "health-api",
	FINANCIAL_REPORT: "financial-report",
	LIST_REGISTERED_USERS: "list-registered-users",
};

export const _breadcrumbItems = { cms_activity_log: { title: "CMS Users Activity", link: "/activity_log" } };

export const _genders = [
	{
		value: "",
		name: "All Genders",
	},
	{
		value: "Female",
		name: "Female",
	},
	{
		value: "Male",
		name: "Male",
	},
];

export const _manualBetTypes = [
	{
		id: "manual",
		label: "Manual",
	},
	{
		id: "price_boost",
		label: "Price Boost",
	},
	{
		id: "special",
		label: "Special",
	},
];

export const searchBetStatusData = [
	{
		value: "approved",
		name: "Approved",
	},
	{
		value: "rejected",
		name: "Rejected",
	},
	{
		value: "pending",
		name: "Pending",
	},
	{
		value: "auto_cancelled",
		name: "Auto Cancelled",
	},
	{
		value: "accepted",
		name: "Accepted",
	},
	{
		value: "placed",
		name: "Placed",
	},
	{
		value: "expired",
		name: "Expired",
	},
];

export const searchSecheledReports = [
	{ value: "1", name: "Published" },
	{ value: "0", name: "Inactive" },
];

export const _turnRequirment = [
	{ id: "5", label: "5x" },
	{ id: "10", label: "10x" },
	{ id: "20", label: "20x" },
	{ id: "30", label: "30x" },
	{ id: "40", label: "40x" },
	{ id: "50", label: "50x" },
	{ id: "75", label: "75x" },
	{ id: "100", label: "100x" },
];

export const _months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const providerTypes = {
	BETGENIUS: "a",
	SIS: "c",
	MANUAL: "m",
	PAMEDIA: "d",
	EVERY_MATRIX: "e",
};

export const casinoTypes = {
	CASINO: "casino",
	LIVE_CASINO: "live_casino",
	VIRTUAL_CASINO: "virtual_casino",
};

export const emailTemplateVariables = [
	"bet_won_amount",
	"player_generated_password",
	"generated_report_name",
	"generated_report_link",
	"player_currency",
	"deposit_amount",
	"player_last_name",
	"player_first_name",
	"player_id",
	"signin_device_info",
	"signin_utc_current_date_time",
	"signin_country",
	"bet_won_match_name",
	"bet_data_raw",
	"code",
	"gaming_sign_in_link",
	"company_name",
	"company_trading_name",
	"company_address",
	"company_number",
	"company_license_number",
	"company_legal_jurisdiction",
	"year",
	"company_facebook_url",
	"company_twitter_url",
	"company_instagram_url",
	"company_url",
	"company_google_play_url",
	"company_apple_store_url",
];

export const _accountTypes = [
	{
		id: "individual",
		label: "Individual",
	},
	{
		id: "test",
		label: "Test",
	},
];
export const _affiliatePlayerTypes = [
	{ id: "all_players", label: "All Players" },
	{ id: "active_players", label: "Active Players" },
	{ id: "inactive_players", label: "Inactive Players" },
];

export const bonusEnginePublished = [
	{
		value: "1",
		name: "Yes",
	},
	{
		value: "0",
		name: "No",
	},
];

export const bonusEngineFinished = [
	{
		value: "1",
		name: "Yes",
	},
	{
		value: "0",
		name: "No",
	},
];

// breakpoint variables

export const breakpoints = { SMALL_PHONE: 576, PHONE: 768, TABLET: 1024, SMALL_LLAPTOPS: 1350, BIG_LLAPTOPS: 1600 };
