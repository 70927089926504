import { eventStatuses, manualEventStatuses } from "utils/constants";

export const getMatchesFilterColumns = ({ sports, handleFilterSportChange, competitions }) => {
	const competitionsData = competitions ? JSON.stringify(competitions) : [];
	const sportsData = sports ? JSON.stringify(sports) : [];

	const statuses = eventStatuses ? JSON.stringify(eventStatuses) : [];
	const manualEventStatus = manualEventStatuses ? JSON.stringify(manualEventStatuses) : [];

	// eslint-disable-next-line
	// const defaultStatus = { name: "Upcoming", value: "upcoming" };
	let sport_id = "";

	return [
		{
			name: "Name",
			type: "text",
			value: "name",
			removeOthersFilter: true,
		},
		{
			name: "Event Status",
			type: "select",
			value: "status",
			optionKey: "value",
			optionName: "name",
			data: statuses,
			// defaultValue: defaultStatus,
		},
		{
			name: "Event Date",
			type: "date",
			value: "date",
			data: [],
			defaultValue: "24H",
			defaultDateDays: "1",
		},
		{
			name: "Manual Event",
			type: "select",
			value: "manual_event",
			data: manualEventStatus,
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "Sport",
			type: "select",
			value: "sport_id",
			data: sportsData,
			optionKey: "id",
			optionName: "name",
			handler: () => handleFilterSportChange(sport_id),
			hasHandler: true,
		},
		{
			name: "Competition",
			type: "select",
			value: "competition_id",
			data: competitionsData,
			optionKey: "id",
			optionName: "name",
			hasHandler: false,
		},
	];
};
