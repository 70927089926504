import {
	ADD_BET_TICKER,
	REMOVE_BET_TICKER,
	SET_BET_TICKER_DATA,
	SET_BET_TICKER_FLAG,
	UPDATE_BET_TICKER,
	UPDATE_BET_TICKER_STATUS
} from './actionTypes';

export const setBetTickerData = payload => {
	return {
		type: SET_BET_TICKER_DATA,
		payload,
	}
};

export const setBetTickerFlag = payload => {
	return {
		type: SET_BET_TICKER_FLAG,
		payload,
	}
};

export const addBetTicker = payload => {
	return {
		type: ADD_BET_TICKER,
		payload,
	}
};

export const removeBetTicker = payload => {
	return {
		type: REMOVE_BET_TICKER,
		payload,
	}
}

export const updateBetTickerStatus = payload => {
	return {
		type: UPDATE_BET_TICKER_STATUS,
		payload,
	}
}

export const setBetTickerNewAmounts = payload =>  {
	return  {
		type: UPDATE_BET_TICKER,
		payload,
	}
}
