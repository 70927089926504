import { AddNewButton, DeleteCarouselButton } from "components/GeneralComponents/AddButton";
import { HeaderDropdown } from "components/GeneralComponents/CustomInputs";
import useOutsideClick from "hooks/useOutsideClick";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { getCountriesDropdown } from "services/getCountriesDropdown";
import { getContent } from "services/getContentAllData";
import { userReadOnly } from "utils/global";

export const PageHeader = ({
	pageHeaderData,
	setCountiresData,
	setContentData,
	setPageType,
	type,
	pageLayoutType,
	handleShowLibrary,
	addNew,
	addNewHandler,
	deleteCarousel,
	deleteCarouselHandler,
	isLoading,
	setAccept,
}) => {
	const [countries, setCountries] = useState([]);
	const [selectedOption, setSelectedOption] = useState("");
	const dropdownRef = React.useRef();
	const [showOptions, setShowOptions] = useState(false);

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	const changeCountries = async (type, country, pageLayoutType) => {
		if (!country || country === "undefined") {
			country = "all";
		}
		let response = await getContent(type, country, pageLayoutType);

		if (response?.length > 0) {
			let data = response?.returnData?.data;
			setContentData(data);
		}
	};

	useEffect(() => {
		const cData = getCountriesDropdown();
		setCountries(cData);
	}, []);

	useOutsideClick(dropdownRef, () => setShowOptions(false));

	return (
		<div id={type !== "media_library" ? "page-header" : "page-header-library"} style={{ width: "100% !important" }}>
			<div className="page-header-position">
				<link rel="stylesheet" href="/carousel" />
				{pageHeaderData?.dropdownData && (
					<HeaderDropdown
						ref={dropdownRef}
						showOptions={showOptions}
						setShowOptions={setShowOptions}
						data={pageHeaderData?.dropdownData}
						setPageType={setPageType}
						setAccept={data => setAccept?.(data)}
						pageLayoutType={pageLayoutType}
						addNew={addNew}
						addNewHandler={addNewHandler}
					/>
				)}

				<div className="page-header-end">
					{type !== "media_library" && pageHeaderData["international"] && (
						<select
							className="btn btn-primary p-0"
							style={{
								height: "30px",
								width: "140px",
								borderRadius: "0px",
								marginRight: "12px",
							}}
							value={selectedOption}
							onChange={e => {
								changeCountries(type, e.target.value, pageLayoutType);
								setCountiresData(e.target.value);
								setSelectedOption(e.target.value);
							}}
						>
							<option value={""}> International</option>
							{countries.map((c, index) => {
								return (
									<option value={c.id} key={index}>
										{c.label}
									</option>
								);
							})}
						</select>
					)}
					{deleteCarousel && <DeleteCarouselButton onClick={deleteCarouselHandler} />}
					{pageHeaderData["upload"] && (
						<AddNewButton name={"Upload"} onClick={() => handleShowLibrary()} loading={isLoading} disabled={isUserReadOnly} />
					)}
					{pageHeaderData["addPlus"] && <AddIcon onClick={pageHeaderData["addNewPlus"]} className="addNew_btn" />}
				</div>
			</div>
		</div>
	);
};
