export const getMarketsFilterColumns = ({ sports }) => {
	const sportsData = sports ? JSON.stringify(sports) : [];

	return [
		{
			name: "Name",
			type: "text",
			value: "name",
		},
		{
			name: "Market ID",
			type: "text",
			value: "market_id",
		},
		{
			name: "Sport",
			type: "select",
			value: "sport_id",
			data: sportsData,
			optionKey: "id",
			optionName: "name",
		},
		{
			name: "Active",
			type: "select",
			value: "status",
			data: JSON.stringify([
				{
					value: "all",
					name: "All statues",
				},
				{
					value: "1",
					name: "Active",
				},
				{
					value: "0",
					name: "Inactive",
				},
			]),
			optionKey: "value",
			optionName: "name",
		},
	];
};
