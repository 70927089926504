import { AvForm } from "availity-reactstrap-validation";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";
export const EditCasinoProviders = ({
	selectedRow,
	setSelectedRow,
	setShowCasinoProvidersModal,
	casinoProvidersData,
	setCasinoProvidersData,
	getCasinoProviders,
}) => {
	const [dataChanged, setDataChanged] = useState(false);
	const [dataSaving, setDataSaving] = useState(false);

	const casinoProvidersFields = [
		{
			type: "switch",
			name: "Status",
			fieldName: "active",
		},
		{ type: "input", name: "Provider", fieldName: "provider_name", readOnly: true },
		{ type: "input", name: "Producer", fieldName: "producer_name", readOnly: true },
		{
			type: "input",
			name: "Last Sync",
			fieldName: "last_sync_formatted",
			readOnly: true,
			extraEndButtonName: "Manual Sync",
			extraButtonHandler: () => editSyncCasinoProvider(selectedRow?.provider_slug, selectedRow?.producer_slug),
		},
		{ type: "input", name: "Sync Period", fieldName: "worker_period", readOnly: true },
		{ type: "dropdown", name: "Blocked Countries", optionsType: "country", multiple: true, bgWhite: true },
	];

	const editStatus = producer_slug => {
		setDataSaving(true);

		const url = apiUrls.EDIT_CASINO_PRODUCER + `?slug=${producer_slug}`;
		new Promise((resolve, reject) => {
			ApiServices.put(url, resolve, reject, true);
		})
			.then(() => {
				const updatedArray = casinoProvidersData.map(item => {
					if (item.producer_slug === producer_slug) {
						return { ...item, active: item.active === 1 ? 0 : 1 };
					} else {
						return item;
					}
				});

				setCasinoProvidersData(updatedArray);
				setShowCasinoProvidersModal(false);
				setSelectedRow("");
			})
			.finally(() => {
				setDataSaving(false);
			});
	};

	const editSyncCasinoProvider = (provider_slug, producer_slug) => {
		const payload = { provider_slug, producer_slug };
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.EDIT_CASINO_PRODUCER_SYNC, resolve, reject, payload, true);
		}).then(() => {
			getCasinoProviders();
		});
	};

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Casino Providers</span>
				<XCloseIcon
					onClick={() => {
						setShowCasinoProvidersModal(false);
						setSelectedRow("");
					}}
					className={"xclose-icon"}
				/>
			</div>
			<div className="promotion-body">
				<TopBarComponent
					saveHandler={() => {
						editStatus(selectedRow?.producer_slug);
					}}
					dataChanged={dataChanged}
					onClose={() => setSelectedRow(false)}
					loading={dataSaving}
				/>
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AvForm>
							<AllComponentsLoop fields={casinoProvidersFields} data={selectedRow} setData={setSelectedRow} setDataChanged={setDataChanged} />
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6"></div>
				</div>
			</div>
		</div>
	);
};
