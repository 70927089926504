import React from "react";
import { EditIconButton, LanguageShortcuts } from "components/GeneralComponents/AddButton";

export const getEmailTemplateTableColumns = ({ records, setShowEditModal, setEditRowModal }) => {
	return [
		{
			name: "subject",
			label: "EMAIL ",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "250px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "250px" }, className: "max-button" }),
			},
		},
		{
			name: "value",
			label: "KEY",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "200px" }, className: "max-button" }),
			},
		},
		{
			name: "language",
			label: "LANGUAGE",
			className: "language text-uppercase",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				viewColumns: false,
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const languageString = Array.isArray(record.language) ? record.language[0] : record.language;
					const languages = languageString.split(",");

					if (languages.length > 1 || (languages.length === 1 && languages[0].trim() !== "")) {
						return (
							<div style={{ display: "flex", gap: "5px" }}>
								{languages.map((language, index) => (
									<LanguageShortcuts key={index} language={!language ? "" : language.trim().slice(0, 2).toUpperCase()} />
								))}
							</div>
						);
					} else {
						const language = languages[0];
						return <LanguageShortcuts language={!language ? "" : language.trim().slice(0, 2).toUpperCase()} />;
					}
				},
				setCellProps: () => ({ style: { textAlign: "left", maxWidth: "450px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "450px" }, className: "max-button" }),
			},
		},
		{
			name: "",
			className: "name",
			left: true,
			sort: "asc",
			align: "right",
			sortable: true,
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditIconButton
									onClick={() => {
										setEditRowModal(record);
										setShowEditModal(true);
									}}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", marginRight: "20px" } }),
			},
		},
	];
};
