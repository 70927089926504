import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { SaveLightButton, SaveUnchangedButton } from "components/GeneralComponents/AddButton";
import { getSportCountriesTableColumns } from "./SportCountriesTableColumns";
import DataTable from "components/GeneralComponents/Table";

export const SportCountries = props => {
	const { sport } = props;
	const [data, setData] = useState();
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);

	const getSportCountries = () => {
		setIsLoading("data");
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_SPORT_COUNTRIES + `?slug=${sport?.slug}`, resolve, reject, true);
		})
			.then(response => {
				setData(response?.data);
				setUnfilteredData(response?.data);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handlerSaveOrder = () => {
		console.log(data, "data");
		let payload = {
			order: data.map((row, index) => ({ code: row?.code, order: index })),
			sport_slug: sport?.slug,
		};

		setIsLoading("order");
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.ORDER_SPORT_COUNTRIES, resolve, reject, payload, true);
		})
			.then(() => {})
			.finally(() => {
				setDataChanged(false);
				setIsLoading(false);
			});
	};

	const changeOrder = index => {
		const selectedRow = unfilteredData.splice(index, 1)[0];
		const newData = [selectedRow, ...unfilteredData];
		setData(newData);
		setUnfilteredData(newData);
		setDataChanged(true);
	};

	const setNewData = newData => {
		setData(newData);
		setUnfilteredData(newData);
		setDataChanged(true);
	};

	useEffect(() => {
		if (sport) {
			getSportCountries();
		}
	}, [sport]);

	let columns = getSportCountriesTableColumns({ changeOrder });

	return (
		<div>
			<div className="sport-tiers-filter">
				<div className="tiers-autocomplete"></div>
				<div>
					{dataChanged ? (
						<SaveLightButton name={"Save Order"} onClick={handlerSaveOrder} loading={isLoading === "order"} />
					) : (
						<SaveUnchangedButton text={"Save Order"} />
					)}
				</div>
			</div>
			<DataTable
				data={data}
				columns={columns}
				setData={newData => {
					setNewData(newData);
				}}
				options={{
					showButtons: false,
					search: false,
					viewColumns: false,
				}}
				isLoading={isLoading === "data"}
				pagination={false}
				orderRows={true}
				sort={false}
			/>
		</div>
	);
};
