const backend_endpoint_url = process.env.REACT_APP_API_URL_NEW;
// const gaming_endopoint_url = process.env.REACT_APP_API_CLIENT_SIDE_DOMAIN;
const gaming_backend_url = process.env.REACT_APP_GAMING_BACKEND_URL;
const dev_gaming_endpoint_url = process.env.REACT_APP_GAMING_API_URL;
const dev_communication_url = process.env.REACT_APP_COMUNICATION_SOCKET;
// const gaming_endpoint_frontend = process.env.REACT_APP_GAMING_FRONTEND_URL;

export const apiUrls = {
	main: process.env.REACT_APP_API_URL,
	new_main: backend_endpoint_url,
	client_side_domain: process.env.REACT_APP_API_CLIENT_SIDE_DOMAIN,

	// AUTH (CMS USER)

	refresh_token: `${backend_endpoint_url}/api/v1/auth/refresh-token`,
	sign_in: `${backend_endpoint_url}/api/v1/auth/sign-in`,
	sign_verify_mfa_token: `${backend_endpoint_url}/api/v1/auth/sign-verify-mfa-token`,
	sign_out_user: `${backend_endpoint_url}/api/v1/auth/sign-out`,
	GET_CMS_USERS: `${backend_endpoint_url}/api/v1/auth/user-list`,
	cms_user_delete: `${backend_endpoint_url}/api/v1/auth/user`,
	cms_user_add: `${backend_endpoint_url}/api/v1/auth/user`,
	cms_user_edit: `${backend_endpoint_url}/api/v1/auth/user`,
	cms_user_block: `${backend_endpoint_url}/api/v1/auth/user/block`,
	cms_user_mfa: `${backend_endpoint_url}/api/v1/auth/user/mfa`,
	cms_user_mfa_reset: `${backend_endpoint_url}/api/v1/auth/user/mfa/reset`,
	cms_user_password_change: `${backend_endpoint_url}/api/v1/auth/user/password/change`,
	cms_user_password_reset: `${backend_endpoint_url}/api/v1/auth/user/password/reset`,
	cms_user_odds_display: `${backend_endpoint_url}/api/v1/auth/user/odds-display`,

	// Player (USER)

	player_add: `${backend_endpoint_url}/api/v1/player`,

	// Sport

	sport_list: `${backend_endpoint_url}/api/v1/sports/sport/list`,
	sport_options: `${backend_endpoint_url}/api/v1/sports/sport/options`,
	sport_edit: `${backend_endpoint_url}/api/v1/sports/sport`,
	sport_order: `${backend_endpoint_url}/api/v1/sports/sport/order`,
	sport_order_add: `${backend_endpoint_url}/api/v1/sports/sport/order`,
	sport_order_edit: `${backend_endpoint_url}/api/v1/sports/sport/order`, // not being used
	sport_order_clone: `${backend_endpoint_url}/api/v1/sports/sport/order/clone`,
	sport_order_delete: `${backend_endpoint_url}/api/v1/sports/sport`,
	sport_tiers: `${backend_endpoint_url}/api/v1/sports/sport/tiers`,
	sport_tier_edit: `${backend_endpoint_url}/api/v1/sports/sport/tier`,
	sport_tier_add: `${backend_endpoint_url}/api/v1/sports/sport/tier`,
	sport_tier_delete: `${backend_endpoint_url}/api/v1/sports/sport/tier`,
	sport_liabilities_get: `${backend_endpoint_url}/api/v1/sports/sport/liabilities`,

	// Regions

	region_get: `${backend_endpoint_url}/api/v1/sports/region`,
	region_edit: `${backend_endpoint_url}/api/v1/sports/region`,

	// Competitions

	competition_groups: `${backend_endpoint_url}/api/v1/sports/competition/group/list`,
	competition_period: `${backend_endpoint_url}/api/v1/sports/competition/period`,
	competition_list: `${backend_endpoint_url}/api/v1/sports/competition/list`,
	competition_group_get: `${backend_endpoint_url}/api/v1/sports/sports/competition/group`,
	competition_favorite_status_edit: `${backend_endpoint_url}/api/v1/sports/competition/favorite-status`,
	competition_group_edit: `${backend_endpoint_url}/api/v1/sports/competition/group`,
	competition_edit: `${backend_endpoint_url}/api/v1/sports/competition`,
	competition_group_add: `${backend_endpoint_url}/api/v1/sports/competition/group`,
	competition_add: `${backend_endpoint_url}/api/v1/sports/competition`,
	GET_COMPETITION_OPTIONS: `${backend_endpoint_url}/api/v1/sports/competition/options`,

	// Competitor

	competitor_get: `${backend_endpoint_url}/api/v1/sports/competitor`,
	competitor_add: `${backend_endpoint_url}/api/v1/sports/competitor`,
	competitor_edit: `${backend_endpoint_url}/api/v1/sports/competitor`,

	// Player

	players_list: `${backend_endpoint_url}/api/v1/sports/player/list`,
	players_edit: `${backend_endpoint_url}/api/v1/sports/player`,
	players_add: `${backend_endpoint_url}/api/v1/sports/player`,

	// Venue
	venue_get: `${backend_endpoint_url}/api/v1/sports/venue/list`,
	venue_add: `${backend_endpoint_url}/api/v1/trading/venue`,
	venue_edit: `${backend_endpoint_url}/api/v1/trading/venue`,

	// Default Leagues

	competition_default_leagues: `${backend_endpoint_url}/api/v1/sports/default-league/list`, // change key name later. Miran
	default_league_add: `${backend_endpoint_url}/api/v1/sports/default-league`,
	default_league_edit: `${backend_endpoint_url}/api/v1/sports/default-league`,
	default_league_order: `${backend_endpoint_url}/api/v1/sports/default-league/order`, // not being used
	default_league_delete: `${backend_endpoint_url}/api/v1/sports/default-league`,

	// Period Leagues

	period_league: `${backend_endpoint_url}/api/v1/sports/period-league`,

	// Sport Event

	sport_event_get: `${backend_endpoint_url}/api/v1/sports/event/list`,
	sport_event_edit: `${backend_endpoint_url}/api/v1/sports/event`,
	sport_event_add: `${backend_endpoint_url}/api/v1/sports/event`,
	sport_event_delete: `${backend_endpoint_url}/api/v1/sports/event`,
	sport_event_order: `${backend_endpoint_url}/api/v1/sports/event/order`,
	sport_event_connection_get: `${backend_endpoint_url}/api/v1/sports/event/connection`,
	sport_event_connection_add: `${backend_endpoint_url}/api/v1/sports/event/connection`,
	sport_event_connection_delete: `${backend_endpoint_url}/api/v1/sports/event/connection/default`,
	sport_event_connection_order: `${backend_endpoint_url}/api/v1/sports/event/connection/order`,
	sport_event_connection_edit: `${backend_endpoint_url}/api/v1/sports/event/connection/default`,
	sport_event_handicaps: `${backend_endpoint_url}/api/v1/sports/event/handicap-options/`,

	// Settings

	GET_SETTINGS_ABOUT: `${backend_endpoint_url}/api/v1/settings/about`,

	country_list: `${backend_endpoint_url}/api/v1/settings/countries/list`,
	country_edit: `${backend_endpoint_url}/api/v1/settings/countries`,

	payment_gateway_get: `${backend_endpoint_url}/api/v1/settings/payment-gateway/list`,

	activity_log_get: `${backend_endpoint_url}/api/v1/settings/activity-log/list`,
	activity_log_get_details: `${backend_endpoint_url}/api/v1/settings/cms-activity-log/details`,
	affiliate_users_get: `${backend_endpoint_url}/api/v1/settings/affiliate-user/list`,
	affiliate_users_delete: `${backend_endpoint_url}/api/v1/settings/affiliate-user`,
	affiliate_users_add: `${backend_endpoint_url}/api/v1/settings/affiliate-user`,

	GET_AFFILIATE_DASHBORD: `${backend_endpoint_url}/api/v1/settings/affiliate-user/dashboard`,
	GET_AFFILIATE_MONNTHLY_OVERVIEW: `${backend_endpoint_url}/api/v1/settings/affiliate-user/monthly-overview`,
	GET_AFFILIATE_SETTINGS: `${backend_endpoint_url}/api/v1/settings/affiliate-user/`,

	GET_AFFILIATE_USERS: `${backend_endpoint_url}/api/v1/settings/affiliate-user/user/list`,
	GET_AFFILIATE_USER: `${backend_endpoint_url}/api/v1/settings/affiliate-user`,
	ADD_AFFILIATE_USERS: `${backend_endpoint_url}/api/v1/settings/affiliate-user/create`,
	EDIT_AFFILIATE_USERS: `${backend_endpoint_url}/api/v1/settings/affiliate-user/edit`,
	GET_SUB_AGENT: `${backend_endpoint_url}/api/v1/settings/affiliate-user/sub-agents/list`,
	ADD_SUB_AGENT: `${backend_endpoint_url}/api/v1/settings/affiliate-user/add/sub-agent`,
	EDIT_SUB_AGENT: `${backend_endpoint_url}/api/v1/settings/affiliate-user/edit/sub-agent`,
	ADD_AFFILIATE_LINKS: `${backend_endpoint_url}/api/v1/settings/affiliate-user/add/promo-link`,
	EDIT_AFFILIATE_LINKS: `${backend_endpoint_url}/api/v1/settings/affiliate-user/edit/promo-link`,
	DELETE_SUB_AGENT: `${backend_endpoint_url}/api/v1/settings/affiliate-user/user/`,

	get_ladder: `${backend_endpoint_url}/api/v1/settings/ladder/`,
	add_ladder: `${backend_endpoint_url}/api/v1/settings/ladder`,
	delete_ladder: `${backend_endpoint_url}/api/v1/settings/ladder/`,
	import_ladder: `${backend_endpoint_url}/api/v1/settings/ladder/import-csv/`,

	get_languages: `${backend_endpoint_url}/api/v1/settings/languages/list`,
	edit_languages: `${backend_endpoint_url}/api/v1/settings/languages/status`,
	translate_languages: `${backend_endpoint_url}/api/v1/settings/languages/translate-text`,

	get_currencies: `${backend_endpoint_url}/api/v1/settings/currencies/list`,
	edit_currencies: `${backend_endpoint_url}/api/v1/settings/currencies`,

	cms_activity: `${backend_endpoint_url}/api/v1/settings/cms-activity-log/list`,

	get_providers: `${backend_endpoint_url}/api/v1/settings/provider/list`,
	add_providers: `${backend_endpoint_url}/api/v1/settings/provider`,
	delete_providers: `${backend_endpoint_url}/api/v1/settings/provider/edit`,

	trader_options: `${backend_endpoint_url}/api/v1/settings/trader/option`,
	trader_options_edit: `${backend_endpoint_url}/api/v1/settings/trader/option`,
	trader_chat: `${backend_endpoint_url}/api/v1/settings/trader/chat`,
	trader_chat_edit: `${backend_endpoint_url}/api/v1/settings/trader/chat`,

	seo: `${backend_endpoint_url}/api/v1/settings/seo`,
	seo_add: `${backend_endpoint_url}/api/v1/settings/seo`,
	seo_update: `${backend_endpoint_url}/api/v1/settings/seo/update`, // not being used
	get_seo_list: `${backend_endpoint_url}/api/v1/settings/seo/list`, // not being used

	get_css: `${backend_endpoint_url}/api/v1/settings/css/list`,
	add_css: `${backend_endpoint_url}/api/v1/settings/css`,
	get_css_content: `${backend_endpoint_url}/api/v1/settings/css/content`,

	email_template: `${backend_endpoint_url}/api/v1/settings/email-template/list`,
	email_template_edit: `${backend_endpoint_url}/api/v1/settings/email-template`,
	email_template_send: `${backend_endpoint_url}/api/v1/settings/email-template`,
	transaction_checker: `${backend_endpoint_url}/api/v1/users/user-balance-mismatch`,

	get_apis: `${backend_endpoint_url}/api/v1/cms/access-keys`,
	apis_save: `${backend_endpoint_url}/api/v1/cms/access-keys/save`,

	// CMS

	// Page Content

	page_content: `${backend_endpoint_url}/api/v1/cms/content/page`,
	page_content_edit: `${backend_endpoint_url}/api/v1/cms/content/page`,
	content_get: `${backend_endpoint_url}/api/v1/cms/content/list`,
	published_content_get: `${backend_endpoint_url}/api/v1/cms/content/published`,
	content_delete: `${backend_endpoint_url}/api/v1/cms/content`,
	page_content_delete: `${backend_endpoint_url}/api/v1/cms/content/page`,
	general_delete: `${backend_endpoint_url}/api/v1/cms/general-delete`,
	notification_bar: `${backend_endpoint_url}/api/v1/cms/notification-bar`,
	add_edit_notification_bar: `${backend_endpoint_url}/api/v1/cms/notification-bar/save`,
	delete_notification_bar: `${backend_endpoint_url}/api/v1/cms/notification-bar`,

	// Variable

	get_variables: `${backend_endpoint_url}/api/v1/cms/variable/list`, // MOVE TO SETTINGS MIRAN
	add_variable: `${backend_endpoint_url}/api/v1/cms/variable`, // MOVE TO SETTINGS MIRAN // this is not on backend
	edit_variable: `${backend_endpoint_url}/api/v1/cms/variable`, // MOVE TO SETTINGS MIRAN

	// Image

	get_images: `${backend_endpoint_url}/api/v1/cms/image/list`,
	delete_image: `${backend_endpoint_url}/api/v1/cms/image/delete`,
	add_image: `${backend_endpoint_url}/api/v1/cms/image`,
	add_multiple_image: `${backend_endpoint_url}/api/v1/cms/image/multiple-uploader`,
	edit_image: `${backend_endpoint_url}/api/v1/cms/image`,

	// Bet slip

	edit_bet_slip: `${backend_endpoint_url}/api/v1/cms/bet-slip`,
	add_bet_split: `${backend_endpoint_url}/api/v1/cms/bet-slip`,
	publish_bet_slip: `${backend_endpoint_url}/api/v1/cms/bet-slip/publish`,
	order_bet_slip_item: `${backend_endpoint_url}/api/v1/cms/bet-slip/order`,

	// Casino

	GET_CASINO_OPTIONS: `${backend_endpoint_url}/api/v1/cms/casino/options`,
	GET_CASINO_CATEGORY_OPTIONS: `${backend_endpoint_url}/api/v1/cms/casino/category/options`,
	GET_CASINO_GAMES: `${backend_endpoint_url}/api/v1/cms/casino/games`,
	GET_LIVE_CASINO_GAMES: `${backend_endpoint_url}/api/v1/cms/casino/live-games`,
	GET_CASINO_GAMES_INFO: `${backend_endpoint_url}/api/v1/cms/casino/games/filter`,
	get_casino_groups: `${backend_endpoint_url}/api/v1/cms/casino/group`,
	get_casino_group_items: `${backend_endpoint_url}/api/v1/cms/casino/group-items`,
	add_casino_group: `${backend_endpoint_url}/api/v1/cms/casino/group`,
	add_casino_game: `${backend_endpoint_url}/api/v1/cms/casino/game`,
	delete_casino_group: `${backend_endpoint_url}/api/v1/cms/casino/group`,
	order_casino_group: `${backend_endpoint_url}/api/v1/cms/casino/group/order`,
	GET_EDIT_CASINO_GAME: `${backend_endpoint_url}/api/v1/cms/casino/games`,
	EDIT_GAME: `${backend_endpoint_url}/api/v1/cms/casino/game`,
	GET_CASINO_FEATURES: `${backend_endpoint_url}/api/v1/cms/casino/features/options`,
	GET_CASINO_THEMES: `${backend_endpoint_url}/api/v1/cms/casino/themes/options`,
	GET_CASINO_PROVIDERS: `${backend_endpoint_url}/api/v1/cms/casino/providers`,
	GET_CASINO_PRODUCERS: `${backend_endpoint_url}/api/v1/cms/casino/producers`,
	EDIT_CASINO_PRODUCER: `${backend_endpoint_url}/api/v1/cms/casino/producers/status`,
	EDIT_CASINO_PRODUCER_SYNC: `${backend_endpoint_url}/api/v1/cms/casino/producers/sync`,
	order_casino_games: `${backend_endpoint_url}/api/v1/cms/casino/order`,

	// Page Layout

	page_layout: `${backend_endpoint_url}/api/v1/cms/layout/list`,
	order_page_layout: `${backend_endpoint_url}/api/v1/cms/layout/order`,
	save_page_layout: `${backend_endpoint_url}/api/v1/cms/layout`,
	delete_page_layout: `${backend_endpoint_url}/api/v1/cms/layout`,

	// Carousel

	CAROUSEL_GROUPS: `${backend_endpoint_url}/api/v1/cms/carousel`,
	ADD_CAROUSEL_GROUP: `${backend_endpoint_url}/api/v1/cms/carousel/group`,
	carousel_add: `${backend_endpoint_url}/api/v1/cms/carousel/add`,
	carousel_edit: `${backend_endpoint_url}/api/v1/cms/carousel/edit`,
	publish_carousel: `${backend_endpoint_url}/api/v1/cms/carousel/publish`,
	order_carousel_item: `${backend_endpoint_url}/api/v1/cms/carousel/order`,
	DELETE_CAROUSEL_GROUP: `${backend_endpoint_url}/api/v1/cms/carousel/group`,

	// Promo Offer

	add_promo_offer: `${backend_endpoint_url}/api/v1/cms/promo-offer`,
	edit_promo_offer: `${backend_endpoint_url}/api/v1/cms/promo-offer`,
	order_promo_offer_item: `${backend_endpoint_url}/api/v1/cms/promo-offer/order`,

	// Hero widget

	add_hero_widget: `${backend_endpoint_url}/api/v1/cms/hero-widget`,
	edit_hero_widget: `${backend_endpoint_url}/api/v1/cms/hero-widget`,
	publish_hero_widget: `${backend_endpoint_url}/api/v1/cms/hero-widget/publish`,
	order_hero_widget_item: `${backend_endpoint_url}/api/v1/cms/hero-widget/order`,

	// Hero banner

	edit_hero_banner: `${backend_endpoint_url}/api/v1/cms/hero-banner`,
	publish_hero_banner: `${backend_endpoint_url}/api/v1/cms/hero-banner/publish`,
	add_hero_banner: `${backend_endpoint_url}/api/v1/cms/hero-banner`,

	// Race widget

	ADD_RACING_WIDGET: `${backend_endpoint_url}/api/v1/cms/racing-widget`,
	EDIT_RACING_WIDGET: `${backend_endpoint_url}/api/v1/cms/racing-widget`,

	// Menu links

	menu_links: `${backend_endpoint_url}/api/v1/cms/menu-link/list`,
	save_menu_links: `${backend_endpoint_url}/api/v1/cms/menu-link`,
	order_menu_links: `${backend_endpoint_url}/api/v1/cms/menu-link/order`,
	edit_menu_links: `${backend_endpoint_url}/api/v1/cms/menu-link`,
	delete_menu_links: `${backend_endpoint_url}/api/v1/cms/menu-link`,

	// Sport Widget

	ADD_SPORTS_WIDGET: `${backend_endpoint_url}/api/v1/cms/sport-widget`,
	EDIT_SPORTS_WIDGET: `${backend_endpoint_url}/api/v1/cms/sport-widget`,

	// Message Codes

	get_message_codes: `${backend_endpoint_url}/api/v1/cms/message-codes/list`,
	edit_message_codes: `${backend_endpoint_url}/api/v1/cms/message-codes`,
	add_message_codes: `${backend_endpoint_url}/api/v1/cms/message-codes`,

	// Push Notifications

	get_push_notification: `${backend_endpoint_url}/api/v1/cms/push-notifications/list`,
	edit_sent_stop_push_notifications: `${backend_endpoint_url}/api/v1/cms/push-notifications`,
	add_push_notification: `${backend_endpoint_url}/api/v1/cms/push-notifications`,

	// Footer Link

	footer_links: `${backend_endpoint_url}/api/v1/cms/footer-link/list`,
	grouped_footer_links: `${backend_endpoint_url}/api/v1/cms/footer-link/grouped`,
	FOOTER_ORDER: `${backend_endpoint_url}/api/v1/cms/footer-link/order-footer`,
	order_footer_links: `${backend_endpoint_url}/api/v1/cms/footer-link/order`,
	save_footer_links: `${backend_endpoint_url}/api/v1/cms/footer-link`,
	delete_footer_link: `${backend_endpoint_url}/api/v1/cms/footer-link/delete`,
	delete_footer_links: `${backend_endpoint_url}/api/v1/cms/footer-link`,

	// Bet Ticker

	GET_BET_TICKET_NOTIFICATIONS: `${backend_endpoint_url}/api/v1/trading/bets/bet-tickers`,
	GET_BET_TICKER_LIST: `${backend_endpoint_url}/api/v1/cms/bet-ticker/list`,
	EDIT_FLAG_BET_TICKER: `${backend_endpoint_url}/api/v1/cms/bet-ticker/flag`,
	EDIT_BET_REFERRAL_STATUS: `${backend_endpoint_url}/api/v1/cms/bet-ticker/status`,
	ASSIGN_TRADER: `${backend_endpoint_url}/api/v1/cms/bet-ticker/assign-trader`,

	// Policy

	policies: `${backend_endpoint_url}/api/v1/cms/policies/list`,
	policies_edit: `${backend_endpoint_url}/api/v1/cms/policies/`,
	policy_enable_country: `${backend_endpoint_url}/api/v1/cms/policies/country`,

	// Terms

	terms: `${backend_endpoint_url}/api/v1/cms/terms/list`,
	term_edit: `${backend_endpoint_url}/api/v1/cms/terms`,
	term_enable_country: `${backend_endpoint_url}/api/v1/cms/terms/enable-country`,

	// Landing page

	get_landing_pages: `${backend_endpoint_url}/api/v1/cms/landing-page`,
	add_landing_pages: `${backend_endpoint_url}/api/v1/cms/landing-page`,
	delete_landing_pages: `${backend_endpoint_url}/api/v1/cms/landing-page/`,
	clone_landing_pages: `${backend_endpoint_url}/api/v1/cms/landing-page/clone`,

	// Bonus Engine

	bonus_engine: `${backend_endpoint_url}/api/v1/cms/bonus_engine`,

	// Matches

	GET_MATCHES_OPTIONS: `${backend_endpoint_url}/api/v1/events/options`,
	GET_MATCHES_DATES: `${backend_endpoint_url}/api/v1/events/`,

	// Trading Market

	get_markets: `${backend_endpoint_url}/api/v1/trading/market/`,
	markets_group: `${backend_endpoint_url}/api/v1/trading/market/group`,
	add_market_group: `${backend_endpoint_url}/api/v1/trading/market/group`,

	TRADING_MARKET_TYPE: `${backend_endpoint_url}/api/v1/trading/market/type`,

	ADD_TRADING_MARKET: `${backend_endpoint_url}/api/v1/trading/market`,
	EDIT_RESULTS_MARKET: `${backend_endpoint_url}/api/v1/trading/market/result`,
	get_selections: `${backend_endpoint_url}/api/v1/trading/market/market/selection`,
	market_selection_edit: `${backend_endpoint_url}/api/v1/trading/market/selection`,
	market_selection_add: `${backend_endpoint_url}/api/v1/trading/market/selection`,
	market_selection: `${backend_endpoint_url}/api/v1/trading/market/market/selection`,
	select_market_type_delete: `${backend_endpoint_url}/api/v1/trading/market/selection`,
	SAVE_MARKET_EDIT: `${backend_endpoint_url}/api/v1/trading/market/edit`,
	SAVE_MARKET_ADD: `${backend_endpoint_url}/api/v1/trading/market/add`,
	DELETE_MARKET: `${backend_endpoint_url}/api/v1/trading/market/`,
	GET_MARKET_HANDICAPS: `${backend_endpoint_url}/api/v1/sports/event/handicap-options/`,
	save_event_hadicap: `${backend_endpoint_url}/api/v1/sports/event/handicap`,

	// TRADING EVENTS
	add_custom_event: `${backend_endpoint_url}/api/v1/trading/event`,
	save_event_market: `${backend_endpoint_url}/api/v1/trading/event/market`,
	get_trading_event: `${backend_endpoint_url}/api/v1/trading/event`,
	edit_trading_event: `${backend_endpoint_url}/api/v1/trading/event`,
	event_markets: `${backend_endpoint_url}/api/v1/trading/event/markets`, // get markets of match
	event_market: `${backend_endpoint_url}/api/v1/trading/event/market`, // get event market by match id, market id
	event_market_selections: `${backend_endpoint_url}/api/v1/trading/event/market/selections`, // get selections of match - market
	add_market_selections: `${backend_endpoint_url}/api/v1/trading/event/market/selection/manual`,
	edit_market_price_boost: `${backend_endpoint_url}/api/v1/trading/event/market/selections`,
	market_active: `${backend_endpoint_url}/api/v1/trading/event/market/selections/activate`,
	market_display: `${backend_endpoint_url}/api/v1/trading/event/market/selections/display`,
	get_event_positions: `${backend_endpoint_url}/api/v1/trading/event/positions`, //for horseracing
	save_event_positions: `${backend_endpoint_url}/api/v1/trading/event/positions/save`,
	get_event_results: `${backend_endpoint_url}/api/v1/trading/event/result`,
	get_event_positions_result: `${backend_endpoint_url}/api/v1/trading/event/positions/result`,
	get_manual_markets: `${backend_endpoint_url}/api/v1/trading/event/markets/options`,
	add_manual_market: `${backend_endpoint_url}/api/v1/trading/event/market/manual`,

	// Trading Bets

	get_bets: `${backend_endpoint_url}/api/v1/trading/bets`, // bets -> bets2 to test data
	get_bet_odds: `${backend_endpoint_url}/api/v1/trading/bets/odds`,
	change_specials_result: `${backend_endpoint_url}/api/v1/trading/bets/specials/result`,
	get_specials: `${backend_endpoint_url}/api/v1/trading/bets/specials`,
	add_custom_bet: `${backend_endpoint_url}/api/v1/trading/bets/custom`,
	edit_custom_bet: `${backend_endpoint_url}/api/v1/trading/bets/custom`,
	bets_sport_comp: `${backend_endpoint_url}/api/v1/trading/bets/games/placed`,

	// Trading Liabilities

	// GET_LIABILITIES: `${backend_endpoint_url}/api/v1/trading/liability/list`,
	// GET_LIABILITY: `${backend_endpoint_url}/api/v1/trading/liability/selections`,

	// Liabilities v2
	GET_LIABILITIES: `${backend_endpoint_url}/api/v1/trading/liability/list-v2`,
	GET_LIABILITY: `${backend_endpoint_url}/api/v1/trading/liability/event`,
	// GET_LIABILITY: `${backend_endpoint_url}/api/v1/trading/liability/selections`,

	// Trading Competition

	GET_SPORT_COMPETITION: `${backend_endpoint_url}/api/v1/trading/competition/list`,
	EDIT_SPORT_COMPETITION: `${backend_endpoint_url}/api/v1/trading/competition`,
	ORDER_SPORT_COMPETITION: `${backend_endpoint_url}/api/v1/trading/competition/order`,
	FAVORITE_SPORT_COMPETITION: `${backend_endpoint_url}/api/v1/trading/competition/expand`,

	// Trading Sports

	GET_SPORT: `${backend_endpoint_url}/api/v1/trading/sports/list`,

	// Trading Placed Bet

	GET_PLAYER_BETS: `${backend_endpoint_url}/api/v1/trading/placed/bet/`,
	GET_CALC_PAYOUT_BETS: `${backend_endpoint_url}/api/v1/trading/placed/bet/calc-payout`,

	// Users V2

	GET_USER_DATA: `${backend_endpoint_url}/api/v1/users-v2/data/`,
	GET_USER_NOTES: `${backend_endpoint_url}/api/v1/users-v2/notes/`,
	GET_USER_STATS: `${backend_endpoint_url}/api/v1/users-v2/stats/`,
	GET_STAKE_FACTOR: `${backend_endpoint_url}/api/v1/users-v2/stake-factor/`,
	EDIT_STAKE_FACTOR: `${backend_endpoint_url}/api/v1/users-v2/user/stake-factor`,
	EDIT_USER_DATA: `${backend_endpoint_url}/api/v1/users-v2/settings/edit`,
	CONTACT_PREFERENCES: `${backend_endpoint_url}/api/v1/users-v2/contact-preference/edit`,
	TRADER_SETTINGS: `${backend_endpoint_url}/api/v1/users-v2/trader-settings/edit`,
	edit_note: `${backend_endpoint_url}/api/v1/users-v2/notes/pin`,
	EDIT_USER_NOTES: `${backend_endpoint_url}/api/v1/notes`,
	SUSPEND_USER: `${backend_endpoint_url}/api/v1/users-v2/user/suspend/`,
	RESPONSIBLE_GAMBLING_DEPOSIT_LIMITS_DETAILS: `${backend_endpoint_url}/api/v1/users-v2/deposit-limit-change/`,
	RESPONSIBLE_GAMBLING_REALITY_CHECK_DETAILS: `${backend_endpoint_url}/api/v1/users-v2/reality-check-logs/`,
	RESPONSIBLE_GAMBLING_PLAY_BREAK_DETAILS: `${backend_endpoint_url}/api/v1/users-v2/play-break-logs/`,
	RESPONSIBLE_GAMBLING_SELF_EXCLUDED_DETAILS: `${backend_endpoint_url}/api/v1/users-v2/self-exluded-logs/`,
	RESPONSIBLE_GAMBLING_TIMERS: `${backend_endpoint_url}/api/v1/users-v2/responsible-gambling-timers/`,

	// Telebet

	TELEBET_COMPETITIONS_MATCHES: `${backend_endpoint_url}/api/v1/telebet/competitions/matches`,
	TELEBET_COMPETITIONS: `${backend_endpoint_url}/api/v1/telebet/competitions`,
	TELEBET_MATCH: `${backend_endpoint_url}/api/v1/telebet/match`,
	TELEBET_REGIONS: `${backend_endpoint_url}/api/v1/telebet/regions`,

	// Notes

	notes: `${backend_endpoint_url}/api/v1/notes`,

	// Regions

	GET_REGION_OPTIONS: `${backend_endpoint_url}/api/v1/regions/options`,

	// Selections

	SELECTIONS: `${backend_endpoint_url}/api/v1/selections/options`,

	// Dashboard

	miniwdget: `${backend_endpoint_url}/api/v1/dashboard/miniwdget`,
	statistics: `${backend_endpoint_url}/api/v1/dashboard/statistics`,
	dashboard_data: `${backend_endpoint_url}/api/v1/dashboard/data`,

	// Reports

	favorite_reports: `${backend_endpoint_url}/api/v1/reports/favorite-report`,

	//Those endpoints are not being used anymore!
	generate_report: `${backend_endpoint_url}/api/v1/reports/generate-report`,
	get_report_list: `${backend_endpoint_url}/api/v1/reports/list`,
	download_report: `${backend_endpoint_url}/api/v1/reports/download-report`,
	get_schdeulted_reports: `${backend_endpoint_url}/api/v1/reports/scheduled-report/list`,
	get_schdeulted_report: `${backend_endpoint_url}/api/v1/reports/scheduled-report/`,
	schdeulted_report: `${backend_endpoint_url}/api/v1/reports/scheduled-report`,

	// Events

	get_matches: `${backend_endpoint_url}/api/v1/events/events`,
	events: `${backend_endpoint_url}/api/v1/events/events`,
	change_bet_result: `${backend_endpoint_url}/api/v1/events/change-bet-result`,
	status_general_edit: `${backend_endpoint_url}/api/v1/events/status-general`, // #3 same as #4
	bets_per_event: `${backend_endpoint_url}/api/v1/events/bets`, // Egzon check, not being used
	change_match_event_status: `${backend_endpoint_url}/api/v1/events/match/status`,
	edit_payment_gateway_status: `${backend_endpoint_url}/api/v1/events/status-general`, // #4 same as #3
	// active_matches: `${backend_endpoint_url}/api/v1/events/active`,
	market_selection_type_add: `${backend_endpoint_url}/api/v1/events/market-selection-type/add`,

	// Users

	userv1: `${backend_endpoint_url}/api/v1/users`, // not being used Egzon Check
	users: `${backend_endpoint_url}/api/v1/users/list`,
	user_payment_card: `${backend_endpoint_url}/api/v1/users/payment-card`,
	phone_prefix: `${backend_endpoint_url}/api/v1/users/phone-prefix`,
	suspend: `${backend_endpoint_url}/api/v1/users/suspend`,
	user_data_edit: `${backend_endpoint_url}/api/v1/users/data`, // check if it is being used
	exclude_user_status_edit: `${backend_endpoint_url}/api/v1/users/exclude-status`, // check if it is being used
	user_balance_edit: `${backend_endpoint_url}/api/v1/users/balance`, // check if it is being used
	user_payment_card_delete: `${backend_endpoint_url}/api/v1/users/payment-card`, // check if it is being used
	ADD_FREE_BET: `${backend_endpoint_url}/api/v1/users/free-bet`,
	GET_TRANSICTION_HISTORY: `${backend_endpoint_url}/api/v1/users/transactions`,
	GET_RESPONSIBLE_GAMBLING: `${backend_endpoint_url}/api/v1/users/responsible-gambling/`,
	EDIT_RESPONSIBLE_GAMBLING: `${backend_endpoint_url}/api/v1/users/responsible-gambling/`,
	GET_BANKING_DATA: `${backend_endpoint_url}/api/v1/users/banking/`,
	EDIT_FREE_BET: `${backend_endpoint_url}/api/v1/users/free-bet`,
	ADD_ADJUSTMENT: `${backend_endpoint_url}/api/v1/users/manual-adjustment/`,
	GET_AUDIT_LOG: `${backend_endpoint_url}/api/v1/users/audit-log/`,
	GET_AUDIT_LOG_DETAILS: `${backend_endpoint_url}/api/v1/users/audit-log-details`,
	DELETE_WITHDRAWAL: `${backend_endpoint_url}/api/v1/users/pending-withdrawal/`,
	SEARCH_USER_COMPETITIONS: `${backend_endpoint_url}/api/v1/telebet/search-events`,

	// Free Bet
	GET_AMOUNTS_FREEBET: `${backend_endpoint_url}/api/v1/cms/casino/free-bet/levels/options`,
	GET_FREE_BETS: `${backend_endpoint_url}/api/v1/users/free-bets/`,
	GET_EDIT_FREE_BET: `${backend_endpoint_url}/api/v1/users/free-bet/`,
	CANCEL_FREE_BET: `${backend_endpoint_url}/api/v1/cms/casino/free-bet/`,

	// User Default Leagues

	default_leagues_by_country: `${backend_endpoint_url}/api/v1/users/default-leagues/list`, //check if it is being used
	import_default_leagues: `${backend_endpoint_url}/api/v1/users/default-leagues/import`, //check if it is being used

	// User

	favorite_competition_edit: `${backend_endpoint_url}/api/v1/users/favorite-competition`, // not being used
	kyc_status_edit: `${backend_endpoint_url}/api/v1/users/kyc-status/edit`, // not being used
	timeline: `${backend_endpoint_url}/api/v1/users/timeline`, // not being used Egzon Check

	user_note_add: `${backend_endpoint_url}/api/v1/users/user-note/add`,

	//

	report_turnover_by_sport: "/v1/report/turnover_by_sport",
	gambling_reminder_activity_log: "/v1/report/gambling_reminder_logs",
	self_exclusion_activity_log: "/v1/report/self_exclusion",
	list_registered_users: "/v1/report/list_registered_users",
	deposit_limits_activity_logs: "/v1/report/deposit_limits_activity_logs",
	setup_mfa: "/v1/setup_mfa",
	mfa_status_user: "/v1/mfa-status-for-user",
	create_user: "/v1/create_user",
	sign_out: "/v1/sign_out",
	delete_user: "/v1/delete_user",
	update_user: "/v1/update_user",
	verify_mfa_token: "/v1/verify_mfa_token",
	user: "/v1/user",
	gamstop: "/v1/user/recheck/gamstop",
	sign_in_first: "/v1/sign_in_first",
	confirm_password: "/v1/confirm_password",
	reset_password: "/v1/reset_password",
	check_token: "/v1/check_token",
	block: "/v1/block",
	user_devices: "/v1/user_devices",
	bet_decs: "/v1/bet_decs",
	balance_report: "/v1/balance_report",

	EDIT_SPORT: `${backend_endpoint_url}/api/v1/sports/edit-sports`,
	onboarding: `${process.env.REACT_APP_GAMING_API_URL}/onboarding`,
	GET_SPORT_COUNTRIES: `${backend_endpoint_url}/api/v1/trading/countries/list`,
	ORDER_SPORT_COUNTRIES: `${backend_endpoint_url}/api/v1/trading/countries/order`,
	GET_ALL_COMPETITIONS: `${backend_endpoint_url}/api/v1/sports/competitions`,

	// PLACE_BET: `${gaming_endopoint_url}/api/v1/betting/generate-bet-slips`,
	ADD_BET_SLIP: `${gaming_backend_url}/api/v1/betting/generate-bet-slips`,
	RESET_PASSWORD: `${dev_gaming_endpoint_url}/user/request_reset_password`,
	GET_SELECTION_ODDS: `${gaming_backend_url}/api/v1/betting/selection`,

	// GENERATE_SLUG_LINL: `${gaming_endpoint_frontend}/`,
	send_message_on_communication: `${dev_communication_url}/api/v1/ws-connector/send-message`,
};
