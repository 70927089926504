import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

export const getAllSportsDropdown = async (params = false) => {
	const sports = JSON.parse(localStorage.getItem("_sports"));

	if (sports?.allSports && params) {
		return sports.allSports;
	} else if (sports?.allActiveSports && !params) {
		return sports.allActiveSports;
	}

	let url;
	if (params) {
		url = apiUrls.sport_list + "?type=all";
	} else {
		url = apiUrls.sport_list;
	}
	return new Promise((resolve, reject) => {
		ApiServices.get(url, resolve, reject, true);
	})
		.then(response => {
			let allSports = response.data;
			allSports = allSports.map(row => {
				return { id: row.slug, label: row.name };
			});

			let sport = {};
			if (params) {
				sport = { ...sports, allSports: allSports };
			} else {
				sport = { ...sports, allActiveSports: allSports };
			}

			localStorage.setItem("_sports", JSON.stringify(sport));
			return allSports;
		})

		.catch(() => {
			return [];
		});
};
