import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

export const getEventMarketsDropdown = async (event_id, sport_slug) => {
	if (!event_id || !sport_slug) return [];
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.event_markets + "?result=all&match_id=" + event_id + "&sport_slug=" + sport_slug, resolve, reject, true);
	})
		.then(response => {
			if (response.success) {
				let data = response?.data;

				return data;
			}
		})
		.catch(() => {
			return [];
		});
};
