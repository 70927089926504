import { SET_USERS, SET_RECENT_VIEW_USERS, SET_AFFILIATE_USERS } from "./actionTypes";

const initialState = {
	users: [],
	recentViewedUsers: [],
	affliateUsers: [],
};

const UsersReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USERS:
			return {
				...state,
				users: action.payload,
			};
		case SET_RECENT_VIEW_USERS:
			return {
				...state,
				recentViewedUsers: action.payload,
			};

		case SET_AFFILIATE_USERS:
			return {
				...state,
				affliateUsers: action.payload,
			};
		default:
			return state;
	}
};

export default UsersReducer;
