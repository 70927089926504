import React from "react";
import { XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { GeneralInput, SportInput, TypeCompetitorsInput } from "components/GeneralComponents/CustomInputs";
import { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import getSportsData from "services/getSportsData";

export const ShowAddCompetitors = ({ showAddModal, setShowAddModal, dataChanged, setDataChanged, filterCompetitors }) => {
	const [sportsData, setSportsData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [competitor, setCompetitorData] = useState({
		name: "",
		sport_id: "",
		type: "",
	});
	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const handleShowOptions = type => {
		if (type === "hidden") {
			console.log("hidden");
		}
	};

	const getSports = () => {
		getSportsData().then(response => {
			setSportsData(response);
		});
	};

	const addCompetitors = () => {
		setLoading(true);
		let payload = {
			name: competitor.name,
			sport_slug: competitor?.sport_id,
			type: competitor.type,
		};
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.competitor_add, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				filterCompetitors();
			})
			.finally(() => {
				setCompetitorData("");
				setLoading(false);
				setShowAddModal(false);
			});
	};

	useEffect(() => {
		getSports();
	}, []);

	return (
		<>
			{showAddModal && (
				<div className="promotion-container">
					<div className="promotion-header">
						<span>Competitors</span>
						<XCloseIcon onClick={() => setShowAddModal(false)} className={"xclose-icon"} />
					</div>
					<div className="promotion-body">
						<TopBarComponent
							saveHandler={() => addCompetitors()}
							dataChanged={dataChanged}
							loading={loading}
							onClose={() => setShowAddModal(false)}
						/>
						<div className="promotion-elements row m-0">
							<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
								<GeneralInput
									onChange={e => {
										handleDataChanged();
										setCompetitorData({
											...competitor,
											name: e.target.value,
										});
									}}
									object={{
										name: "Name",
										value: competitor.name,
									}}
								/>

								<TypeCompetitorsInput
									data={competitor?.type}
									setData={setCompetitorData}
									handleShowOptions={handleShowOptions}
									handleDataChanged={handleDataChanged}
									allData={competitor}
									otherRowClass
								/>
								<SportInput
									sportsData={sportsData}
									data={competitor}
									setData={setCompetitorData}
									handleShowOptions={handleShowOptions}
									handleDataChanged={handleDataChanged}
									otherRowClass
								/>
							</div>
							<div className="promotion-right col-12 col-lg-6"></div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
