import React from "react";
import { useEffect, useState } from "react";

import { GeneralInput, MixedInputs } from "components/GeneralComponents/CustomInputs";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { XCloseIcon } from "utils/icons";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { AvForm } from "availity-reactstrap-validation";
import { DeleteIconButton } from "components/GeneralComponents/AddButton";
import { getAllSportsDropdown } from "services/getAllSportsDropdown";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const StakeFactorData = ({
	handler,
	stakeFactorData: prevStakeData,
	setStakeFactorData: setPrevStakeData,
	type,
	setShowPromotion,
	getStakeFactor,
	data: prevData,
	setData: setPrevData,
	allSports,
}) => {
	const [editStakeFactor, setEditStakeFactor] = useState([]);
	const [generalData, setGeneralData] = useState([]);
	const [data, setData] = useState({ ...prevData });

	const [stakeFactorData, setStakeFactorData] = useState({ ...prevStakeData });

	const [stakeFactor, setStakeFactor] = useState(type == "prematch" ? data?.prematch_stake_factor : data?.inplay_stake_factor);
	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [sports, setSports] = useState([]);

	const { id } = useParams();

	const getSport = async () => {
		const sports = await getAllSportsDropdown();
		setSports(sports);
	};

	useEffect(() => {
		getSport();
	}, []);

	let title = "Edit";

	const editStakeFactors = () => {
		setLoading(true);
		let payload;
		if (editStakeFactor.length > 0 || stakeFactorData) {
			let payloadValueSlug = [];

			if (type === "prematch") {
				payloadValueSlug.push({ value: stakeFactor, slug: "prematch_stake_factor" });
			} else {
				payloadValueSlug.push({ value: stakeFactor, slug: "inplay_stake_factor" });
			}
			payloadValueSlug = payloadValueSlug.concat(
				editStakeFactor.map(row => ({ value: row?.inputValue, slug: row?.selectedSport })),
				stakeFactorData[type].map(row => ({ value: row?.value, slug: row?.slug }))
			);
			payload = { type: type, value_slug: payloadValueSlug };
		}

		let updatedArray = [];
		if (payload) updatedArray.push(payload);

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.EDIT_STAKE_FACTOR + "?sub_id=" + id, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				getStakeFactor();
				setShowPromotion(false);
				setPrevData({ ...data });
				setPrevStakeData(stakeFactorData);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSave = () => {
		editStakeFactors();
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const deleteStakeFactor = row => {
		if (row.id) {
			new Promise((resolve, reject) => {
				ApiServices.delete(apiUrls.EDIT_STAKE_FACTOR + "/" + row.id, resolve, reject, true);
			}).then(() => {
				const updatedData = {
					inplay: stakeFactorData.inplay.filter(item => item.id !== row.id),
					prematch: stakeFactorData.prematch.filter(item => item.id !== row.id),
				};

				setStakeFactorData(updatedData);
				setPrevStakeData(updatedData);
			});
		}
	};

	const handleSingelStakeFactor = (newValue, fieldName) => {
		const inputValue = newValue;
		const value = inputValue.replace(/[^0-9.]/g, "");
		setData({ ...data, [fieldName]: value });
		setStakeFactor(newValue);
		handleDataChanged();
	};

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={handleSave} dataChanged={dataChanged} formId="avForm" type="submit" loading={loading} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						<AvForm className="stake-factor-mixed">
							<GeneralInput
								object={{
									name: "Stake Factor",
									fieldName: type === "prematch" ? "prematch_stake_factor" : "inplay_stake_factor",
									value: type === "prematch" ? data?.prematch_stake_factor : data?.inplay_stake_factor,
									onChangeEvent: (newValue, fieldName) => handleSingelStakeFactor(newValue, fieldName),
									icon: <DeleteIconButton className="ms-2 m-0 delete-button-invisible" />,
									numbersOnly: true,
								}}
							/>

							{stakeFactorData[type]?.map((row, index) => {
								const nameOfSport = allSports?.find(({ slug }) => slug == row?.slug)?.name;
								return (
									<div key={index} className="">
										<GeneralInput
											object={{
												name: nameOfSport,
												value: row?.value,
												onChangeEvent: newValue => {
													let updatedArray = [...stakeFactorData[type]];
													const newStakeData = updatedArray.map(item => {
														if (item.slug == row?.slug) return { ...row, value: newValue };
														else return item;
													});
													handleDataChanged();
													setStakeFactorData({ ...stakeFactorData, [type]: newStakeData });
												},
												numbersOnly: true,
												icon: <DeleteIconButton className="ms-2 m-0" />,
												iconClick: () => deleteStakeFactor(row),
											}}
										/>
									</div>
								);
							})}

							<MixedInputs
								options={sports}
								pHolder="Enter a value"
								handleDataChanged={handleDataChanged}
								setDataChanged={setDataChanged}
								handleSave={handleSave}
								setEditStakeFactor={setEditStakeFactor}
								editStakeFactor={editStakeFactor}
								stakeFactorData={stakeFactorData}
								setStakeFactorData={setStakeFactorData}
								getStakeFactor={getStakeFactor}
								type={type}
							/>

							<div>
								{generalData.map((data, index) => {
									return (
										<div key={index}>
											<GeneralInput
												object={{
													name: data.sport.name,
													value: data.value,
													icon: <DeleteIconButton className="ms-2 m-0" />,
													iconClick: () => {
														const updatedData = [...generalData];
														updatedData.splice(index, 1);
														setGeneralData(updatedData);
													},
												}}
											/>
										</div>
									);
								})}
							</div>
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6"></div>
				</div>
			</div>
		</div>
	);
};
