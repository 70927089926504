import React, { useState, useEffect } from "react";
import { DarkArrowLeft, SimpleGoBackArrow } from "utils/icons";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { MatchGeneral } from "./MatchGeneral";
import { MarketsMatches } from "./marketsMatches";
import { ResultsMatches } from "./ResultsMatches";
import { EditMarketMatch } from "./EditMarketMatch";
import PositionsEvent from "./PositionsEvent";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			className="tabs-tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={"span"}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export const ShowBetsPerMatchModal = ({
	setAddMarketMatch,
	eventViewData,
	resultData,
	setEventViewData,
	setDataChanged,
	marketMatchesView,
	getResultsMatch,
	history,
	marketMatchesViewData,
	marketDataLoading,
	handlerTabs,
}) => {
	const [value, setValue] = useState(0);
	const [singleMarketOpen, setSingleMarketOpen] = useState(false);
	const hasPositions = eventViewData?.sport_slug === "horseracing" || eventViewData?.sport_slug === "greyhoundracing";

	const handleChange = (event, newValue) => {
		setValue(newValue);
		handlerTabs(newValue);
	};

	const changeQueryParam = (query, paramToChange, newValue) => {
		const params = new URLSearchParams(query);
		params.set(paramToChange, newValue);
		return params.toString();
	};

	const getQueryParam = (query, param) => {
		const params = new URLSearchParams(query);
		return params.get(param)?.toString();
	};

	useEffect(() => {
		const defaultValue = getQueryParam(history.location.search, "tab");
		if (defaultValue) {
			setValue(+defaultValue);
			handlerTabs(+defaultValue);
		}
	}, []);

	useEffect(() => {
		const {
			location: { pathname, search },
		} = history;

		history.push({
			pathname,
			search: changeQueryParam(search, "tab", value),
		});
	}, [value]);

	const onClickBack = () => {
		history.push("/events");
	};

	useEffect(() => {
		const market_id = getQueryParam(history.location.search, "market_id");
		if (market_id) setSingleMarketOpen(true);
		else setSingleMarketOpen(false);
	}, [history.location.search]);

	if (singleMarketOpen)
		return (
			<EditMarketMatch
				marketMatchesView={marketMatchesView}
				sportId={eventViewData.sport_id}
				getQueryParam={getQueryParam}
				sportSlug={eventViewData.sport_slug}
				eventData={eventViewData}
			/>
		);

	return (
		<>
			<div className="white-container">
				<div className="matches-title">
					<SimpleGoBackArrow onClick={() => onClickBack()} className="go-back-mobile" />
					<DarkArrowLeft onClick={() => onClickBack()} className="go-back-desktop" />
					<span>{eventViewData?.name}</span>
				</div>

				<div className="tabs-container">
					<Box sx={{ width: "100%" }}>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
								<Tab label="General" {...a11yProps(0)} value={0} />
								<Tab label={"Markets"} {...a11yProps(1)} value={1} />
								<Tab label="Results" {...a11yProps(2)} value={2} />
							</Tabs>
						</Box>
						<TabPanel value={value} index={0}>
							<MatchGeneral eventViewData={eventViewData} setEventViewData={setEventViewData} setDataChanged={setDataChanged} />
						</TabPanel>
						<TabPanel value={value} index={1}>
							{/* TODO fix sinlge market */}
							<MarketsMatches
								marketMatchesViewData={marketMatchesViewData}
								setAddMarketMatch={setAddMarketMatch}
								eventViewData={eventViewData}
								marketMatchesView={marketMatchesView}
								marketDataLoading={marketDataLoading}
							/>
						</TabPanel>
						<TabPanel value={value} index={2}>
							{hasPositions ? (
								<PositionsEvent eventViewData={eventViewData} />
							) : (
								<ResultsMatches resultData={resultData} getResultsMatch={getResultsMatch} />
							)}
						</TabPanel>
					</Box>
				</div>
			</div>
		</>
	);
};
