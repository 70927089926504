import React from "react";
import { XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { FavoriteSwitch, GeneralDropdown, GeneralInput, SportInput, SwitchInput } from "components/GeneralComponents/CustomInputs";
import { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { AvForm } from "availity-reactstrap-validation";
export const AddCompetitionModal = ({ showAddModal, setShowAddModal, filterCompetitions, countries }) => {
	const [sportsData, setSportsData] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);

	const [loading, setLoading] = useState(false);
	const [competitionData, setCompetitionData] = useState({
		competiton_name: "",
		country_code: "",
		sport_id: "",
		status: "",
		favorite: "",
		sport_slug: "",
	});

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const getSports = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_options, resolve, reject, true);
		}).then(response => {
			setSportsData(response?.data);
		});
	};

	const addCompetition = () => {
		setLoading(true);
		let payload = {
			country_code: competitionData?.country_code,
			competiton_name: competitionData.competiton_name,
			sport_slug: competitionData.sport_slug,
			status: competitionData.status,
			favorite: competitionData.favorite,
		};
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.competition_add, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				setShowAddModal(false);
				filterCompetitions();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getSports();
	}, []);

	return (
		<>
			{showAddModal && (
				<div className="promotion-container">
					<div className="promotion-header">
						<span>Competition</span>
						<XCloseIcon
							onClick={() => {
								setShowAddModal(false);
							}}
							className={"xclose-icon"}
						/>
					</div>
					<div className="promotion-body">
						<TopBarComponent saveHandler={() => addCompetition()} dataChanged={dataChanged} loading={loading} onClose={() => setShowAddModal(false)} />
						<div className="promotion-elements row m-0">
							<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
								<AvForm>
									<GeneralInput
										onChange={e => {
											handleDataChanged();
											setCompetitionData({
												...competitionData,
												competiton_name: e.target.value,
											});
										}}
										object={{
											name: "Competiton Name",
											value: competitionData.competiton_name,
											required: true,
										}}
									/>
									<GeneralDropdown
										object={{
											value: competitionData?.country_code,
											type: "dropdown",
											name: "Country",
											fieldName: "country_code",
											options: countries,
											required: true,
										}}
										handleChange={e => {
											setCompetitionData({
												...competitionData,
												country_code: e.id,
											});
										}}
									/>
									<SportInput
										sportsData={sportsData}
										data={competitionData}
										type="competitions_add_modal"
										setData={setCompetitionData}
										handleDataChanged={handleDataChanged}
										otherRowClass
									/>
									<SwitchInput
										object={{
											name: "Status",
											value: competitionData.status,
										}}
										handleChange={checked => {
											handleDataChanged();
											setCompetitionData({ ...competitionData, status: checked ? 1 : 0 });
										}}
									/>
									<FavoriteSwitch data={competitionData} setData={setCompetitionData} handleDataChanged={handleDataChanged} otherRowClass />
								</AvForm>
							</div>
							<div className="promotion-right col-12 col-lg-6"></div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
