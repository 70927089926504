import React from "react";
import HTMLReactParser from "html-react-parser";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { cmsSearchFilterData } from "store/actions";
import { EditIconButton } from "components/GeneralComponents/AddButton";
import { getFormatDateByUserTimezone } from "utils/global";

export const getSpecialsTableColumns = ({
	recordss,
	getEditDataSpinning,
	editCustomBet,
	setShowAddCustomBets,
	setGetEditDataSpinning,
	addResult,
	setShowCustumBets,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	return [
		{
			name: "id",
			label: "ID",
			className: "tag",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "80px" } }),
				setCellHeaderProps: () => ({ style: { textAlign: "left", minWidth: "80px" }, className: "max-button" }),
			},
		},
		{
			name: "sport_type",
			label: "SPORT",
			className: "tag",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px", whiteSpace: "nowrap" } }),
				setCellHeaderProps: () => ({ style: { textAlign: "left", minWidth: "100px" }, className: "max-button" }),
			},
		},
		{
			name: "competition",
			label: "COMPETITION & MATCH",
			className: "tag",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "150px", whiteSpace: "nowrap" } }),
				setCellHeaderProps: () => ({ style: { textAlign: "left", minWidth: "150px" }, className: "max-button" }),
				customBodyRender: (value, meta) => {
					const record = recordss[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<span>
								<b>{record.competition}</b>
							</span>
							<br />
							<span>{record.match}</span>
						</>
					);
				},
			},
		},
		{
			name: "description",
			label: "TITLE & DESCRIPTION",
			className: "bet_description",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "300px", whiteSpace: "nowrap", textWrap: "balance" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "300px" } }),

				customBodyRender: (value, meta) => {
					const record = recordss[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<span>
								<b>{record.title}</b>
							</span>
							<br />
							<span>{record.description}</span>
						</>
					);
				},
			},
		},
		{
			name: "type",
			label: "BET TYPE",
			className: "tag",
			align: "right",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "80px", whiteSpace: "nowrap" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "80px" } }),

				customBodyRender: (value, meta) => {
					const record = recordss[meta.currentTableData[meta.rowIndex].index];
					if (record?.type === "empty_leg") {
						return "Empty Leg";
					} else if (record?.type === "price_boost") {
						return "Price Boost";
					} else if (record?.type === "manual") {
						return "Manual";
					} else if (record?.type === "special") {
						return "Special";
					}
					return (
						<>
							<span>{record.type}</span>
						</>
					);
				},
			},
		},
		{
			name: "odd_decimal",
			label: "DEC. ODDS",
			className: "tag",
			align: "right",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "80px", whiteSpace: "nowrap" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "80px" } }),
			},
		},
		{
			name: "odd_fractional",
			label: "FRA. ODDS",
			className: "tag",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "80px", whiteSpace: "nowrap" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "80px" } }),
			},
		},
		{
			name: "betted_count",
			label: "BET COUNT",
			className: "bet_description",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "80px", whiteSpace: "nowrap" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "80px" } }),
				sortCompare: order => {
					return ({ data: a }, { data: b }) => {
						return (parseInt(a) - parseInt(b)) * (order === "asc" ? 1 : -1);
					};
				},
				customBodyRender: (value, meta) => {
					const record = recordss[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							{record.betted_count != 0 ? (
								<span
									className="cursor-pointer"
									onClick={() => {
										var gs_bet_id = record?.id;
										var storeObj = {
											bet_id: "",
											bet_counted: gs_bet_id,
											match_name: "",
											player_id: "",
											competitor: "",
											stake_amount: "",
											stake_amount_type: "",
											return_amount_type: "",
											return_amount: "",
											handicap_type: "",
											handicap: "",
											date: "",
											competition_id: "",
											region_id: "",
											sport_id: "",
											market_id: "",
											selection_id: "",
											in_play: "",
											sport_name: "",
											market_name: "",
											selection_name: "",
										};
										dispatch(
											cmsSearchFilterData({
												bets: storeObj,
											})
										);
										history.push("/bets");
									}}
								>
									{record.betted_count}
								</span>
							) : (
								<span className="cursor-pointer">{record.betted_count}</span>
							)}
						</>
					);
				},
			},
		},
		{
			name: "result",
			label: "RESULT",
			className: "tag",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" } }),

				customBodyRender: (value, meta) => {
					const record = recordss[meta.currentTableData[meta.rowIndex].index];

					var result_txt =
						record.result == "Pushed"
							? `<span className="badge badge-sports-yellow"> Push </span>`
							: record.result == "Loser"
							? `<span className="badge badge-sports-red"> Lose </span>`
							: record.result == "Winner"
							? `<span className="badge badge-sports-published modified_win_color"> Win </span>`
							: record.result.toLowerCase() == "autoclose"
							? `<span className="badge badge-sports-gray"> Auto Closed </span>`
							: record.result == "open"
							? `<span className="badge badge-sports-blue"> Open </span>`
							: "";
					return HTMLReactParser(result_txt);
				},
			},
		},
		//Do not remove this since we might recover
		// {
		// 	name: "bet_start_time",
		// 	label: "Bet Start Date",
		// 	className: "bet_date",
		// 	sortable: true,
		// 	sort: "asc",
		// 	options: {
		// 		setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
		// 		setCellHeaderProps: () => ({ className: "max-button-and-right" }),
		// 	},
		// },
		{
			name: "start_date",
			label: "DISPLAY START DATE",
			className: "bet_date",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { whiteSpace: "nowrap", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),

				customBodyRender: (value, meta) => {
					const record = recordss[meta.currentTableData[meta.rowIndex].index];
					const data = getFormatDateByUserTimezone(record.start_date);
					return <div className="text-center">{data}</div>;
				},
			},
		},
		{
			name: "end_date",
			label: "DISPLAY END DATE",
			className: "bet_date",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { whiteSpace: "nowrap", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),

				customBodyRender: (value, meta) => {
					const record = recordss[meta.currentTableData[meta.rowIndex].index];
					const data = getFormatDateByUserTimezone(record.end_date);
					return <div className="text-center">{data}</div>;
				},
			},
		},
		{
			name: "created_at",
			label: "CREATED",
			className: "bet_date",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { whiteSpace: "nowrap", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),

				customBodyRender: (value, meta) => {
					const record = recordss[meta.currentTableData[meta.rowIndex].index];
					const data = getFormatDateByUserTimezone(record.created);
					return <div className="text-center">{data}</div>;
				},
			},
		},
		{
			name: "status",
			label: "STATUS",
			className: "bet_date",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { whiteSpace: "nowrap", minWidth: "350px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "350px" } }),

				customBodyRender: (value, meta) => {
					const record = recordss[meta.currentTableData[meta.rowIndex].index];
					var result_txt =
						record.status == 1
							? `<span className="badge  badge-sports-green "> Active </span>`
							: `<span class="badge badge-sports-inactive ">Inactive</span>`;

					return (
						<div className="d-flex gap-2">
							{HTMLReactParser(result_txt)}
							<div className="d-flex gap-2">
								<div className="badge  badge-sports-published manual_bet_buttons" onClick={() => addResult(record.id, record.group_id)}>
									Resulting
								</div>
								<div
									className="badge  badge-sports-published manual_bet_buttons"
									onClick={() => {
										editCustomBet({ id: record.id, clone: 1 });
										setShowAddCustomBets(true);
									}}
								>
									Clone
								</div>

								<EditIconButton
									onClick={() => {
										editCustomBet({ id: record.id, clone: 0 });
										setShowAddCustomBets(true);
										setGetEditDataSpinning({
											...getEditDataSpinning,
											[record.id]: true,
										});
										setShowCustumBets(false);
									}}
								/>
							</div>
						</div>
					);
				},
			},
		},
	];
};
