import { EyeButton } from "components/GeneralComponents/AddButton";
import React from "react";
import { getFormatDateByUserTimezone } from "utils/global";
export const getCmsUserActivityTableColumns = ({ records, setCmsUserActivityModal }) => {
	return [
		{
			name: "datetime",
			label: "DATE",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = records[meta.currentTableData[meta.rowIndex].index];
					return <div>{getFormatDateByUserTimezone(row?.datetime, "YYYY-MM-DD HH:mm:ss")}</div>;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "180px" } }),
				setCellHeaderProps: () => ({ style: { width: "180px" } }),
			},
		},
		{
			name: "username",
			label: "USER",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = records[meta.currentTableData[meta.rowIndex].index];
					let username = row?.username;
					return <span>{username}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "140px" } }),
				setCellHeaderProps: () => ({ style: { width: "140px" } }),
			},
		},
		{
			name: "method",
			label: "METHOD",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = records[meta.currentTableData[meta.rowIndex].index];
					const methodValue = row?.method || "";
					return <span>{methodValue}</span>;
				},
			},
		},
		{
			name: "description",
			label: "DESCRIPTION",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "",
			label: "",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex justify-content-end align-items-center">
							<EyeButton
								onClick={() => {
									setCmsUserActivityModal({ show: true, id: row?.id });
								}}
							/>
						</div>
					);
				},
			},
		},
	];
};
