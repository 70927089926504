export const getCompetitionFilterColumns = ({ sports }) => {
	const sportsData = sports ? JSON.stringify(sports) : [];
	const statusFilter = JSON.stringify([
		{
			value: "",
			name: "All statuses",
		},
		{
			value: "0",
			name: "Inactive",
		},
		{
			value: "1",
			name: "Active",
		},
	]);

	return [
		{
			name: " Name",
			type: "text",
			value: "name",
		},
		{
			name: "Sport",
			type: "select",
			value: "sport_id",
			data: sportsData,
			optionKey: "id",
			optionName: "name",
		},
		{
			name: "Status",
			type: "select",
			value: "status",
			data: statusFilter,
			optionKey: "value",
			optionName: "name",
		},
	];
};
