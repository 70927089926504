import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useState } from "react";
import { XCloseIcon } from "utils/icons";
import { MatchGeneral } from "./MatchGeneral";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

function AddEventModal({ setShowAddEventModal }) {
	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [eventViewData, setEventViewData] = useState({
		active: 0,
		display: 0,
		name: "",
		start_time_utc: "",
		show_pre_match_bets: 0,
		show_in_play_bets: 0,
		current_phase: "",
		grade_pre_match: "0",
		grade_in_play: "0",
		competition_id: "",
		competition_name: "",
		region_id: "",
		sport_slug: "",
	});

	const handlerAddEvent = () => {
		setLoading(true);
		let payload = {
			active: eventViewData.active,
			display: eventViewData.display,
			name: eventViewData.name,
			start_time_utc: eventViewData.start_time_utc,
			show_pre_match_bets: eventViewData.show_pre_match_bets,
			show_in_play_bets: eventViewData.show_in_play_bets,
			current_phase: eventViewData.current_phase,
			grade_pre_match: eventViewData.grade_pre_match,
			grade_in_play: eventViewData.grade_in_play,
			competition_id: eventViewData.competition_id,
			competition_name: eventViewData.competition_name,
			region_id: eventViewData.region_id,
			sport_slug: eventViewData.sport_slug,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.add_custom_event, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				setShowAddEventModal(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = competition => {
		setEventViewData({
			...eventViewData,
			competition_id: competition?.id,
			competition_name: competition?.label,
		});
	};
	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Event</span>
				<XCloseIcon onClick={() => setShowAddEventModal(false)} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent
					saveHandler={() => handlerAddEvent()}
					dataChanged={dataChanged}
					loading={loading}
					onClose={() => setShowAddEventModal(false)}
				/>
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<MatchGeneral
							showAddEventModal={true}
							eventViewData={eventViewData}
							setEventViewData={setEventViewData}
							setDataChanged={setDataChanged}
							handleChange={handleChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddEventModal;
