import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { SaveButton, BlueCancelButton } from "components/GeneralComponents/AddButton";
import { LoadingSaveButton } from "./../../../components/GeneralComponents/AddButton";

export const ShowDeleteConfirmDefaultSportModal = ({ showDeleteConfirm, setShowDeleteConfirm, deleteSpinning, handleDeleteSportOrder }) => {
	return (
		<>
			{showDeleteConfirm && (
				<SweetAlert title="Delete Sports Order" customClass={"delete-conf-sweet"} showConfirm={false} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="">
							<span className="delete-span">Are you sure you want to delete this sport order ?</span>
						</div>
						<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
							<BlueCancelButton onClick={() => setShowDeleteConfirm(false)} name={"No"} />
							{deleteSpinning ? <LoadingSaveButton /> : <SaveButton onClick={() => handleDeleteSportOrder()} name={"Yes"} />}
						</div>
					</div>
				</SweetAlert>
			)}
		</>
	);
};
