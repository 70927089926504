import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import SearchFilter from "components/SearchFilter/searchFilter";
import { setBreadcrumbItems } from "../../../store/actions";
import { getPeriodCompetitionTableColumns } from "./periodCompetitionTableColumns";
import { getPeriodCompetitionFilterColumns } from "./periodCompetitionsFilterColumns";
import { CreatePeriodCompetitionModal } from "./createPeriodCompetitionModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import moment from "moment";
import MUIDataTable from "mui-datatables";

const PeriodCompetitions = props => {
	const breadcrumbItems = [{ title: "Period Competitions", link: "/period_competitions" }];
	const sports = useSelector(state => state.Login.gsData.sports);

	// States
	const [createPeriodLeague, setCreatePeriodLeague] = useState(false);
	const [periodLeagueName, setPeriodLeagueName] = useState("");
	const [sportId, setSportId] = useState("");
	const [competitionId, setCompetitionId] = useState("");
	const [competitions, setCompetitions] = useState([]);
	const [sportFilterSpinning, setSportFilterSpinning] = useState(false);
	const [dateFrom, setDateFrom] = useState("");
	const [dateTo, setDateTo] = useState("");
	// const periodFilterData = useSelector(state => state.Login.searchFilterData.period_competitions);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [records, setRecords] = useState([]);
	const [sportName, setSportName] = useState("");
	const [competitionName, setCompetitionName] = useState("");

	useEffect(() => {
		props.setBreadcrumbItems("Period Competitions", breadcrumbItems);
	}, []);

	const handleFilterSportChange = sport => {
		let payload = {
			sport_id: sport,
			region_id: "",
			gender: "",
			status: "",
			name: "",
		};
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.competition_list, resolve, reject, payload, true);
		}).then(response => {
			if (response.success) {
				setCompetitions(response?.data);
			}
		});
	};

	const handleSportChange = sport => {
		setSportFilterSpinning(true);
		let payload = {
			sport_id: sport,
			region_id: "",
			gender: "",
			status: "",
			name: "",
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.change_match_event_status, resolve, reject, payload, true);
		}).then(() => {});
	};

	const createPeriodCompetition = () => {
		let payload = {
			sport_id: sportId,
			sport_name: sportName,
			competition_id: competitionId,
			competition_name: competitionName,
			name: periodLeagueName,
			date_from: dateFrom,
			date_to: dateTo,
		};

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.period_league, resolve, reject, payload, true);
		})
			.then(() => {})
			.finally(() => {
				setCreatePeriodLeague(false);
			});
	};

	const filterPeriodLeagues = periodFilterData => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(
				apiUrls.competition_period + "?sport_slug=" + periodFilterData?.sport_id + "&competition_id=" + periodFilterData?.competition_id,
				resolve,
				reject,
				true
			);
		})
			.then(response => {
				if (response.success) {
					let data = response.data;
					if (data) {
						let dataPeriodLeagues = data;
						let arr = [];
						dataPeriodLeagues.map(dpl => {
							let obj = {
								id: dpl?.id,
								sport_id: dpl?.sport_id,
								sport_name: dpl?.sport_name,
								competition_id: dpl?.competition_id,
								competition_name: dpl?.competition_name,
								name: dpl?.name,
								date_from: moment(dpl?.date_from).format("YYYY-MM-DD"),
								date_to: moment(dpl?.date_to).format("YYYY-MM-DD"),
							};
							arr.push(obj);
						});
						setRecords(arr);
					}
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	let periodCompetitionsTableColumns = getPeriodCompetitionTableColumns();
	let periodCompetitionFilterColumns = getPeriodCompetitionFilterColumns({
		competitions,
		sports,
		handleFilterSportChange,
	});
	let page_name = "Period Competitions";
	return (
		<HelmetLayout titleProps={"Period Competitions"}>
			<div className="cms-page pt-0 cms-table-page">
				<div
					className="col-12 d-flex"
					style={{
						justifyContent: "space-between",
					}}
				>
					<div className="col-10">
						<SearchFilter
							columns={periodCompetitionFilterColumns}
							page={"period_competitions"}
							preSelectedType={"select"}
							preSelectedValue={"sport_id"}
							preSelectedName={"Sport"}
							preSelectedData={sports ? sports : []}
							preSelectedOptionKey={"id"}
							preSelectedOptionName={"name"}
							filterHandler={filterPeriodLeagues}
							filterLoading={isDataLoading}
							competitionsHandler={handleFilterSportChange}
							customizedFilter={true}
						/>
					</div>
				</div>

				<CreatePeriodCompetitionModal
					createPeriodLeague={createPeriodLeague}
					setSportId={setSportId}
					handleSportChange={handleSportChange}
					setSportName={setSportName}
					sportId={sportId}
					sports={sports}
					sportFilterSpinning={sportFilterSpinning}
					setCompetitionId={setCompetitionId}
					setCompetitionName={setCompetitionName}
					competitionId={competitionId}
					periodLeagueName={periodLeagueName}
					setPeriodLeagueName={setPeriodLeagueName}
					dateFrom={dateFrom}
					setDateFrom={setDateFrom}
					dateTo={dateTo}
					setDateTo={setDateTo}
					setCreatePeriodLeague={setCreatePeriodLeague}
					createPeriodCompetition={createPeriodCompetition}
					competitions={competitions}
				/>
				<MUIDataTable
					columns={periodCompetitionsTableColumns}
					data={records}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						responsive: tableResponsive,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: page_name + ".csv" },
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(PeriodCompetitions);
