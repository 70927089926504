import React from "react";
import { EyeButton } from "components/GeneralComponents/AddButton";
import { getFormatDateByUserTimezone } from "utils/global";

export const getAuditlogReportColumns = ({ auditlogData, setAuditLogModal, setLogId }) => {
	return [
		{
			name: "created_at",
			label: "CHANGE TIME",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = auditlogData[meta.currentTableData[meta.rowIndex].index];
					const formattedDate = getFormatDateByUserTimezone(record?.created_at, "YYYY-MM-DD HH:mm:ss");
					return (
						<>
							<>{formattedDate}</>
						</>
					);
				},

				setCellProps: () => ({ style: { textAlign: "left", width: "190px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "60px" }, className: "max-button" }),
			},
		},

		{
			name: "user",
			label: "USER",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = auditlogData[meta.currentTableData[meta.rowIndex].index];
					let name = row?.first_name;
					let lastName = row?.last_name;

					// Capitalize first letter of name
					name = name ? name.charAt(0).toUpperCase() + name.slice(1) : "";
					// Capitalize first letter of lastName
					lastName = lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1) : "";

					return (
						<>
							{name} {""}
							{lastName}
						</>
					);
				},

				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
			},
		},
		{
			name: "description",
			label: "DESCRIPTION",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = auditlogData[meta.currentTableData[meta.rowIndex].index];
					let title = row?.description;
					let formattedTitle = title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
					return <>{formattedTitle}</>;
				},

				// setCellProps: () => ({ style: { textAlign: "left", width: "200px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "text-right",
			align: "right",
			sortable: true,
			sort: "asc",
			options: {
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = auditlogData[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex justify-content-end">
							<EyeButton
								onClick={() => {
									setLogId(row?.id);
									setAuditLogModal(true);
								}}
							/>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right" } }),
			},
		},
	];
};
