import React from "react";
import { LightArrowRight } from "utils/icons";
import { useHistory } from "react-router-dom";
import { getFormatDateByUserTimezone, sortColumnString } from "utils/global";

export const getLiabilitiesTableColumns = ({ liabilitiesData }) => {
	const history = useHistory();

	return [
		{
			name: "event_start_dt",
			label: "START TIME",
			options: {
				filter: true,
				sort: true,
				customBodyRender: value => {
					const startTime = getFormatDateByUserTimezone(value);
					return <>{startTime}</>;
				},
				setCellProps: () => ({ style: { minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" } }),
			},
		},
		{
			name: "event_name",
			label: "EVENT NAME",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({ style: { minWidth: "250px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "250px" } }),
			},
		},
		{
			name: "competition_name",
			label: "COMPETITION",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({ style: { minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" } }),
			},
		},
		{
			name: "sport_name",
			label: "SPORT",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "total_bets",
			label: "TOTAL BETS",
			options: {
				setCellHeaderProps: () => ({ style: { minWidth: "80px" }, className: "max-button-and-right  align-right" }),
				setCellProps: () => ({ style: { minWidth: "80px", textAlign: "right" } }),
			},
		},
		{
			name: "total_stakes",
			label: "TOTAL STAKES",
			sortable: true,
			options: {
				sort: true,
				sortCompare: order => sortColumnString(order),
				setCellHeaderProps: () => ({ style: { minWidth: "80px" }, className: "max-button-and-right  align-right" }),
				setCellProps: () => ({ style: { minWidth: "80px", textAlign: "right" } }),
			},
		},
		// {
		// 	name: "single_liability",
		// 	label: "LIABILITY (SINGLES)",
		// 	options: {
		// 		customBodyRender: value => {
		// 			return Number(value).toFixed(2);
		// 		},
		// 		setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button-and-right  align-right" }),
		// 		setCellProps: () => ({ style: { minWidth: "100px", textAlign: "right" } }),
		// 	},
		// },
		// {
		// 	name: "multiple_liability",
		// 	label: "LIABILITY (MULTI)",
		// 	options: {
		// 		customBodyRender: value => {
		// 			return Number(value).toFixed(2);
		// 		},
		// 		setCellHeaderProps: () => ({ style: { minWidth: "80px" }, className: "max-button-and-right  align-right" }),
		// 		setCellProps: () => ({ style: { minWidth: "80px", textAlign: "right" } }),
		// 	},
		// },
		{
			name: "",
			label: "",
			className: "",
			options: {
				viewColumns: false,
				print: false,
				filter: false,
				searchable: false,
				customBodyRender: (value, meta) => {
					const record = liabilitiesData[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<button
								className="btn btn-primary btn-no-border"
								onClick={() => {
									history.push(`/liability/match_id=${record.event_id}/sport_slug=${record.sport_slug}`);
								}}
							>
								<LightArrowRight />
							</button>
						</div>
					);
				},
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
	];
};
