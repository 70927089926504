import { SET_BET_REFERRAL_MODAL } from "./actionTypes";

const initialState = {
	isOpen: false,
};

const BetReferralModal = (state = initialState, action) => {
	switch (action.type) {
		case SET_BET_REFERRAL_MODAL:
			return {
				...state,
				isOpen: action.payload,
			};

		default:
			return state;
	}
};

export default BetReferralModal;
