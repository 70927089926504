export const ProviderPrefixes = {
	a: 'BET_GENIUS',
	e: 'EVERY_MATRIX',
	c: 'SIS',
	d: 'PA_MEDIA',
	m: 'MANUAL',
};

/**
 * @param id {string} - id of the selection/event/competition, etc.
 * @returns {boolean} - true if the id has a provider prefix, otherwise false.
 * @description - This function should check if the id has a provider or not.
 * Available providers are:
 * - Everymatrix (prefix: `e-`)
 * - Betgenius (prefix: `g-`)
 * - Pamedia (prefix: `d-`)
 * - SIS (prefix: `c-`)
 * - Manual (prefix: `m-`)
 */
export const hasProviderInName = (id) => {
	if (!id) {
		return false
	}

	const providerPrefixMaybe = id[0];

	return !!ProviderPrefixes[providerPrefixMaybe];
};

/**
 * @description generates raw selection id without prefix
 * @example
 * const selectionIdWithPrefix = 'a-320117421-0';
 * const rawSelectionId = getIdWithoutProvider(idWithPrefixProvider) -> '320117421-0'
 */
export const getIdWithoutProvider = (id) => {
	if (!hasProviderInName(id)) return id;

	return id.slice(2);
};