import { SET_BETS_STATS, SET_SEARCH_BETS } from "./actionTypes";

export const setBets = payload => {
	return {
		type: SET_SEARCH_BETS,
		payload,
	};
};

export const setBetsStat = payload => {
	return {
		type: SET_BETS_STATS,
		payload,
	};
};
