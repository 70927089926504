import React from "react";
import { createTheme } from "@mui/material/styles";
import { ThousandSeperated } from "hooks/General";
import { CsvPrintIconLogo, ExcelPrintIconLogo, PrintIconLogo } from "./icons";
var ref;

export const tableNumberFormat = value => {
	return Number(value).toFixed(2);
};

export const setCellTextAlignRight = () => ({ style: { textAlign: "right" } });
export const setCellHeaderTextAlignRight = () => {
	return { className: "align-right" };
};
export const tableColumnNumber = {
	setCellProps: setCellTextAlignRight,
	setCellHeaderProps: setCellHeaderTextAlignRight,
	customBodyRender: value => {
		return typeof value == "number" ? ThousandSeperated(value) : value;
	},
};

export const tableRowsPerPage = [10, 15, 50, 100];

export const tableRowsPerPageValue = 100;

export const tableResponsive = "";

export const tableExtraButtons = [
	{
		className: "btn btn-primary buttons-pdf",
		title: "Export Test",
		children: [
			<span key={0}>
				<PrintIconLogo />
			</span>,
		],
		onClick: () => {
			ref.current.exportToPDF(this);
		},
	},
	{
		className: "btn btn-primary buttons-csv",
		title: "Export CSV",
		children: [
			<span key={1}>
				<CsvPrintIconLogo />
			</span>,
		],
		onClick: () => {
			ref.current.exportToCSV(this);
		},
	},
	{
		className: "btn btn-primary buttons-excel",
		title: "Export XLX",
		children: [
			<span key={3}>
				<ExcelPrintIconLogo />
			</span>,
		],
		onClick: () => {
			ref.current.exportToExcel(this);
		},
	},
];

export const tableMuiTheme = () =>
	createTheme({
		components: {
			MuiTableCell: {
				styleOverrides: {
					root: {
						border: "0px",
						fontFamily: "Poppins",
					},
				},
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						padding: "0px",
						color: "#5C626A",
						fontFamily: "Poppins",
					},
				},
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						"&:nth-of-type(odd)": {
							backgroundColor: "#F8F9FA",
						},
					},
				},
			},
		},
	});

export const tableBarSettings = {
	search: false,
	filter: false,
	viewColumns: false,
	download: false,
	print: false,
};
