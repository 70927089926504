import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
	Dropdown,
	DropdownToggle,
	// DropdownMenu,
	// DropdownItem,
	// DON'T DELETE COMMENTED CODE !!!
} from "reactstrap";
import { ShowSetupMFAUserModal } from "../../../pages/Settings/UserList/showSetupMFAUserModal";
import { ShowConfirmDisableMFA } from "../../../pages/Settings/UserList/showConfirmDisableMFAModal";
import ApiServices from "utils/ApiServices";
import { toast } from "react-toastify";
import {
	connect,
	useDispatch,
	// useSelector,
	// DON'T DELETE COMMENTED CODE !!!
} from "react-redux";
import { signOutAndClearStorage } from "helpers/api_helper";
import { withRouter, Link, useHistory } from "react-router-dom";
{
	/* DON'T DELETE COMMENTED CODE */
}
// import { InternationalIcon } from "utils/icons";

import {
	cmsLoginUser,
	setActivePage,
	// setCountry,
	// DON'T DELETE COMMENTED CODE !!!
} from "store/actions";
// import { capitalizeText } from "services/capitalizeText";
import { apiUrls } from "utils/const.apiUrl";
import successIcon from "../../../assets/images/success-icon.svg";
import { LogoutModal } from "components/VerticalLayout/LogoutModal";
import { userProfile } from "utils/constants";
import logout from "../../../assets/images/logOut.svg";
import { XCloseIcon } from "utils/icons";

import "./ProfileMenu.scss";

const ProfileMenu = () => {
	{
		/* DON'T DELETE COMMENTED CODE */
	}
	// const country = useSelector(state => state.Login.country);
	// const [langugagesMenu, setLanguagesMenu] = useState(false);
	// const [countries, setCountries] = useState([]);
	const [menu, setMenu] = useState(false);
	const [password, setPassword] = useState("");
	const [userList, setUserList] = useState([]);
	const [continueNextStep, setContinueNextStep] = useState(false);
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const [showSetupMFAModal, setShowSetupMFAModal] = useState(false);
	const [confirmDisableMFA, setConfirmDisableMFA] = useState(false);
	const [confirmPasswordForDisableSpinner, setConfirmPasswordForDisableSpinner] = useState(false);
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem("user"));
	const history = useHistory();

	useEffect(() => {
		setUserList(user);
		dispatch(cmsLoginUser(user));
	}, []);

	// Sign out users from cms
	const signOutUser = () => {
		var email = localStorage.getItem("email");
		let body = {
			email: email,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.new_main + "/api/v1/auth/sign-out", resolve, reject, body, true);
		}).then(response => {
			if (response.success == true) {
				signOutAndClearStorage({ wait: true });
				// localStorage.removeItem("access_token");
				// localStorage.removeItem("refresh_token");
				// setTimeout(function () {
				// 	props.history.push("/login");
				// }, 1500);
			}
		});
	};

	/* WHEN WE RECOVER THE CODE FOR LANGUAGES, HERE AT useEffect WE NEED TO ADD getAllCountries(); */

	const confirmPasswordForDisableMFA = () => {
		if (!password) {
			toast.success(
				<>
					<img src={successIcon} alt="Success" />
					<p className="toast-icon-manual-message">Please enter password</p>
				</>,
				{ className: "custom-toast" }
			);
			setConfirmPasswordForDisableSpinner(true);
			return false;
		}
		let data = {
			email: localStorage.getItem("email"),
			password: password,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.new_main + "/api/v1/auth/sign-in", resolve, reject, data, true);
		})
			.then(response => {
				if (response) {
					let body = {
						access_token: localStorage.getItem("access_token"),
					};
					new Promise((resolve, reject) => {
						ApiServices.post(apiUrls.new_main + "/api/v1/auth/change-mfa-statu", resolve, reject, body, true);
					})
						.then(response => {
							if (response) {
								setConfirmDisableMFA(false);
							}
						})
						.finally(() => {});
				}
			})
			.finally(() => {
				setConfirmPasswordForDisableSpinner(false);
			});
	};

	{
		/* DON'T DELETE COMMENTED CODE */
	}
	// const getAllCountries = () => {
	// 	new Promise((resolve, reject) => {
	// 		ApiServices.get(apiUrls.country_list, resolve, reject, true);
	// 	}).then(response => {
	// 		let country = response?.data;
	// 		setCountries(country);
	// 	});
	// };

	// const handleSelectedCountry = value => {
	// 	dispatch(setCountry(value));
	// 	localStorage.setItem("country", value);
	// };

	const openLogoutModal = () => {
		setShowLogoutModal(true);
		setMenu(false);
	};
	return (
		<React.Fragment>
			{/* DON'T DELETE COMMENTED CODE */}

			{/* <Dropdown isOpen={langugagesMenu} toggle={() => setLanguagesMenu(!langugagesMenu)} className="d-inline-block">
				<DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
					{country ? country !== "all" ? country : <InternationalIcon /> : <InternationalIcon />}
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end languages-dropdown">
					<DropdownItem
						onClick={() => {
							handleSelectedCountry("all");
						}}
					>
						{"International"}
					</DropdownItem>
					{countries &&
						countries.map((country, index) => {
							return (
								<DropdownItem
									key={index}
									onClick={() => {
										handleSelectedCountry(country?.cca2);
									}}
								>
									{country?.name}
								</DropdownItem>
							);
						})}
				</DropdownMenu>
			</Dropdown> */}

			<Dropdown isOpen={menu} className="d-inline-block">
				<DropdownToggle className="btn header-item waves-effect user-icons" id="page-header-user-dropdown" tag="button">
					<Link
						onClick={() => dispatch(setActivePage("/profile"))}
						type="button"
						className="rounded-circle header-profile-user profile-button"
						to={"/profile"}
					>
						{userList?.first_name?.charAt(0).toUpperCase()} {userList?.last_name?.charAt(0).toUpperCase()}
					</Link>

					<div type="button" className="rounded-circle header-profile-user header-profile-user-mobile profile-button" onClick={() => setMenu(!menu)}>
						{userList?.first_name?.charAt(0).toUpperCase()} {userList?.last_name?.charAt(0).toUpperCase()}
					</div>
				</DropdownToggle>

				{menu && (
					<div className="profile-menu-container">
						<div className="profile-menu-container-data">
							{userProfile.map(({ menuTitle, links }, i) => {
								return (
									<>
										<div className="profile-menu-title d-flex align-items-center justify-content-between " key={i}>
											{menuTitle}
											<XCloseIcon className="cc" fill={"#000"} onClick={() => setMenu(false)} />
										</div>
										{links
											.filter(link => !link?.roleToHide?.includes(user?.role))
											.map((row, i) => {
												const { tradingName, link } = row;
												return (
													<div className="profile-menu-item" key={i} onClick={() => history.push(link)}>
														{tradingName}
													</div>
												);
											})}
										<div className="profile-menu-item d-flex align-items-center" key={i} onClick={() => openLogoutModal()}>
											<p className="profile-p m-0">Log out</p>
											<img src={logout} alt="logout" />
										</div>
									</>
								);
							})}
						</div>
						<div className="profile_backdrop" onClick={() => setMenu(false)} />
					</div>
				)}
			</Dropdown>

			<ShowSetupMFAUserModal
				showSetupMFAModal={showSetupMFAModal}
				continueNextStep={continueNextStep}
				setContinueNextStep={setContinueNextStep}
				setShowSetupMFAModal={setShowSetupMFAModal}
			/>
			{confirmDisableMFA ? (
				<ShowConfirmDisableMFA
					setPassword={setPassword}
					confirmPasswordForDisableSpinner={confirmPasswordForDisableSpinner}
					confirmPasswordForDisableMFA={confirmPasswordForDisableMFA}
					setConfirmDisableMFA={setConfirmDisableMFA}
				/>
			) : (
				""
			)}
			{showLogoutModal && (
				<LogoutModal title={"Logout"} paragraph="Are you sure you want to log out?" setShowLogoutModal={setShowLogoutModal} signOutUser={signOutUser} />
			)}
		</React.Fragment>
	);
};

ProfileMenu.propTypes = {
	success: PropTypes.any,
	t: PropTypes.any,
};

const mapStatetoProps = state => {
	const { error, success } = state.Profile;
	return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(ProfileMenu));
