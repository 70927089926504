export const getActivityLogFilterColumns = ({ httpCodes }) => {
	const httpCodesData = httpCodes ? JSON.stringify(httpCodes) : [];
	return [
		{
			name: "Activity Name",
			type: "text",
			value: "activity_name",
		},
		{
			name: "AWS Request ID",
			type: "text",
			value: "aws_request_id",
		},

		{
			name: "Date",
			type: "date",
			value: "date",
			defaultValue: "24H",
			defaultDateDays: "1",
		},
		{
			name: "HTTP",
			type: "select",
			value: "http_code",
			data: httpCodesData,
			optionKey: "code",
			optionName: "code",
		},
		{
			name: "IP",
			type: "text",
			value: "ip",
		},
		{
			name: "Player ID",
			type: "text",
			value: "player_id",
		},
		{
			name: "User Agent",
			type: "text",
			value: "user_agent",
		},
	];
};
