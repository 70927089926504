import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { transactionCheckerColumns } from "./transactionCheckerColumns";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { ModalButton } from "components/GeneralComponents/CustomInputs";

function transactionChecker() {
	const [data, setData] = useState([]);
	const [fetchLoader, setFetchLoader] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const getTransactionChecker = () => {
		setFetchLoader(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.transaction_checker, resolve, reject, true);
		})
			.then(response => {
				setData(response?.data);
			})
			.finally(() => {
				setFetchLoader(false);
			});
	};

	const editSyncTransactionChecker = () => {
		setIsLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.transaction_checker, resolve, reject, true);
		})
			.then(() => {
				getTransactionChecker();
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const getTransactionCheckerColumns = transactionCheckerColumns({ data, isLoading, editSyncTransactionChecker });

	useEffect(() => {
		getTransactionChecker();
	}, []);

	return (
		<HelmetLayout titleProps={"Transaction Checker"}>
			<div className="cms-page pt-0 cms-table-page">
				<div className="no-search-bar">
					<div className="sync_button_transaction_checker">
						<ModalButton
							object={{
								name: "Sync",
								disabled: isLoading,
								wrapperClassName: "sync_button_casino_providers",
								isLoading: isLoading,
								onClickEvent: () => {
									editSyncTransactionChecker();
								},
							}}
						/>
					</div>
					<MUIDataTable
						columns={getTransactionCheckerColumns}
						data={data}
						options={{
							...tableBarSettings,
							pagination: true,
							selectableRows: "none",
							elevation: 0,
							textLabels: {
								body: {
									noMatch: fetchLoader ? <LoadingSpinner /> : " No data to display!",
								},
							},
							responsive: tableResponsive,
							rowsPerPage: 50,
							rowsPerPageOptions: tableRowsPerPage,
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
			</div>
		</HelmetLayout>
	);
}

export default transactionChecker;
