import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({ component: Component, user, roleToHide, layout: Layout, isAuthProtected, path, ...rest }) => (
	//
	<Route
		{...rest}
		render={props => {
			if (path === "/") {
				if (user?.role === "affiliate") return <Redirect to="/affiliates" />;
				else return <Redirect to="/dashboard" />;
			} else {
				if (user?.role && roleToHide?.includes(user.role)) {
					return <Redirect to="/premission_denied" />;
				} else if (isAuthProtected && !localStorage.getItem("authUser")) {
					return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
				}
			}

			return (
				<Layout>
					<Component {...props} />
				</Layout>
			);
		}}
	/>
);

Authmiddleware.propTypes = {
	isAuthProtected: PropTypes.bool,
	component: PropTypes.any,
	location: PropTypes.object,
	layout: PropTypes.any,
};

export default Authmiddleware;
