import { toast } from 'react-toastify';
import xIcon from '../assets/images/x-icon.svg';
import React from 'react';

export const toastError = (message) => {
  return toast.error(
    <>
      <img src={xIcon} alt="Error" />
      <p className="toast-icon-manual-message">{message}</p>
    </>,
    { className: "custom-toast" }
  );
}
