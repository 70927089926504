import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Row, Col, Card, Alert, Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Parser from "html-react-parser";
import { loginLogo, platformName } from "utils/constants";
import SignInFooter from "./SignInFooter";
import * as authService from "utils/AuthService";
import { CopyIcon, XCloseIcon } from "utils/icons";
import OTPInput from "react-otp-input";

const TwoFA = props => {
	const [spinningLogin, setSpinningLogin] = useState(false);
	const [MFAToken, setMFAToken] = useState("");
	const [showAuthCode, setShowAuthCode] = useState(false);
	const [QRCodeData, setQRCodeData] = useState({
		qrcode: "",
		secretCode: "",
	});
	const [clipboardMessage, setClipboardMessage] = useState("");

	const verifyMFAToken = async () => {
		setSpinningLogin(true);
		var mfa_token = MFAToken;
		var email = sessionStorage.getItem("email");
		var password = sessionStorage.getItem("password");

		var body = {
			mfa_token: mfa_token,
			email: email,
			password: password,
		};

		new Promise((resolve, reject) => {
			authService.verifyMfa(resolve, reject, body);
		})
			.then(response => {
				sessionStorage.removeItem("password");
				localStorage.setItem("email", email);
				localStorage.setItem("access_token", response.data.data.AccessToken);
				localStorage.setItem("refresh_token", response.data.data.RefreshToken);
				var user = [];
				var user_data = {};
				user_data = response.data.userData;
				user.push(user_data);
				if (user && user.length > 0) {
					localStorage.setItem("user", JSON.stringify(user_data));
				}
				window.location.href = `/`;
			})
			.finally(() => {
				setSpinningLogin(false);
			});
	};

	useEffect(() => {
		document.body.classList.add("login-body");
		return () => {
			document.body.classList.remove("login-body");
		};
	}, []);

	useEffect(() => {
		if (MFAToken.length === 6) {
			verifyMFAToken();
		}
	}, [MFAToken]);

	useEffect(() => {
		var qr_code = sessionStorage.getItem("qr_code");
		var manual_key = sessionStorage.getItem("manual_key");

		setQRCodeData({
			qrcode: qr_code,
			secretCode: manual_key,
		});
	}, []);

	return (
		<React.Fragment>
			<MetaTags>
				<title> 2FA Sign-in | CMS | {platformName}</title>
			</MetaTags>
			<div className="account-pages">
				<Container>
					<Row className="justify-content-center login_container">
						<Col md={8} lg={6} xl={5} className="login-flow-card">
							{showAuthCode ? (
								<Card className="login-card mfa-auth">
									<div className="close-icon" onClick={() => setShowAuthCode(false)}>
										<XCloseIcon fill="#262A3C" />
									</div>
									<h4 className="login-title">Authentication App</h4>
									<div className="mfa-text">Enter the 6 digit code generated by your authentication app.</div>

									<div className="code-input-main">
										<div className="codeVerification">
											<OTPInput
												value={MFAToken}
												onChange={setMFAToken}
												shouldAutoFocus={true}
												numInputs={6}
												inputType="number"
												disabled={false}
												inputStyle={"codeInput"}
												renderInput={props => <input {...props} />}
												// innerRef={inputRef}
											/>
										</div>
									</div>
								</Card>
							) : (
								<Card className="login-card">
									<h3 className="default-logo-login text-center mt-5">
										<div className="d-block auth-logo">
											<img src={loginLogo} alt="" height="60" width="200" className="auth-logo-dark" style={{ cursor: "auto" }} />
										</div>
									</h3>
									<div className="pl-5">
										<h4 className="login-title two-fa-title">Set up Two-Factor Authentication</h4>
										<div className="mfa-text mt-3">
											Two-Factor Authentication has been enabled. Please follow the instructions below to finalise the setup process.{" "}
										</div>
										<div className="mfa-text mfa-1">1. Download the Google Authenticator app (Android, IOS)</div>
										<div className="mfa-text mfa-2">2. Scan the QR code</div>
										<div className="img-two-fa">
											<div className="qr-code">{Parser(QRCodeData.qrcode)}</div>
										</div>
										<div className="mfa-text mfa-2">Or enter the following code manually</div>
										<div className="mfa-manually-code mfa-2">
											<input type="text" className="form-control login-input" value={QRCodeData.secretCode} disabled={true} />
											<div
												className="copy-icon"
												onClick={() => {
													navigator.clipboard.writeText(QRCodeData.secretCode);
													setClipboardMessage("Copied");
													setTimeout(() => {
														setClipboardMessage(false);
													}, 2000);
												}}
											>
												<CopyIcon active={true} />
											</div>
											{clipboardMessage && <div className="clip_board_general_container">{clipboardMessage}</div>}
										</div>
									</div>

									{props.error && typeof props.error === "string" ? <Alert color="danger">{props.error}</Alert> : null}

									<div className="two-fa-button">
										<div className="text-center">
											<button className="login-button form-control" type="submit" onClick={() => setShowAuthCode(true)}>
												{spinningLogin ? <Spinner animation="border" size="sm" /> : "Next"}
											</button>
										</div>
									</div>
								</Card>
							)}
							<SignInFooter />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(connect()(TwoFA));
