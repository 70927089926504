import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { Spinner } from "reactstrap";

const About = () => {
	const fields = [
		{ type: "input", name: "Release Date", disabled: "true", fieldName: "release_date" },
		{ type: "input", name: "Build Number", disabled: "true", fieldName: "build_number" },
		{ type: "input", name: "Hash", disabled: "true", fieldName: "checksum" },
	];

	const [data, setData] = useState({ release_date: "", build_number: "", checksum: "" });
	const [loading, setLoading] = useState(false);
	const setDataChanged = () => {};

	const getAbout = () => {
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.GET_SETTINGS_ABOUT}`, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;
				setData(data);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		getAbout();
	}, []);

	return (
		<div>
			{loading ? (
				<div className="d-flex align-items-center justify-content-center">
					<Spinner animation="border" size="md" />
				</div>
			) : (
				<AllComponentsLoop fields={fields} data={data} setData={setData} setDataChanged={setDataChanged} />
			)}
		</div>
	);
};

export default About;
