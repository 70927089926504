import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { getSpecialsTableColumns } from "./specialsTableColumns";
import { AddManualBet } from "./addManualBet.modal";
import { AddResultModal } from "./addResultModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { __manualBet } from "./index";
import xIcon from "../../../assets/images/x-icon.svg";
import SearchFilter from "components/SearchFilter/searchFilter";
import { getSpecialsFilterColumns } from "./specialsFilterColumns";
import { prepareFilterData } from "utils/global";
import { getMatcheDropdown } from "services/getMatchesDropdown";
import { getCompetitionsDropdown } from "services/getCompetitionsDropdown";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const CustomBets = props => {
	const columnsForBetsFilter = getSpecialsFilterColumns();
	const customFilterData = useSelector(state => state.Login.searchFilterData.specials);

	const [rowPerPage, setRowPerPage] = useState(50);
	const breadcrumbItems = [{ title: "Manual Bets", link: "/manual_bets" }];
	const [showAddCustomBets, setShowAddCustomBets] = useState(false);
	const [showEditCustomBets, setShowCustumBets] = useState(false);
	const user = useSelector(state => state.Login.user);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [showAddResult, setShowAddResult] = useState(false);
	const [betResult, setBetResult] = useState("");
	const [betId, setBetId] = useState({
		id: "",
		group_id: "",
	});
	const [currentPage, setCurrentPage] = useState(0);
	const [recordss, setRecords] = useState([]);
	const [getEditDataSpinning, setGetEditDataSpinning] = useState({});
	// const [viewColumns, setViewColumns] = useState(__viewColumns);
	const [manualBet, setManualBet] = useState(__manualBet);
	const [regionsData, setRegionsData] = useState([]);
	const [allMatches, setAllMatches] = useState([]);
	const [allCompetitions, setAllCompetitions] = useState([]);

	var records = [];

	useEffect(() => {
		props.setBreadcrumbItems("Specials", breadcrumbItems);
	}, []);

	const editCustomBet = ({ id, clone }) => {
		let payload = {
			id,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(`${apiUrls.get_specials}`, resolve, reject, payload, true);
		})
			.then(response => {
				let data = response.data;
				if (data && data.length > 0) {
					if (clone != 1) {
						setManualBet({
							...manualBet,
							id: id,
							description: data[0].description,
							title: data[0].title,
							odd_decimal: data[0].odd_decimal,
							odd_fractional: data[0].odd_fractional,
							sport_id: data[0].sport_id,
							sport_slug: data[0].sport_slug,
							region_id: data[0].region_id,
							region_name: data[0].region_name,
							competition_id: data[0].competition_id,
							competition_name: data[0].competition_name,
							match_id: data[0]?.match_id,
							match_name: data[0]?.match_name,
							market_id: data[0]?.market_id,
							outcome_id: data[0]?.outcome_id,
							outcome_name: data[0]?.outcome_name,
							status: data[0]?.status,
							type: data[0]?.type,
							display_start_date: data[0]?.show_start_date,
							display_end_date: data[0]?.show_end_date,
							bet_start_date: data[0]?.bet_start_date,
							bet_end_date: data[0]?.bet_end_date,
							clone,
						});
					} else {
						setManualBet({
							...manualBet,
							id: id,
							description: data[0].description,
							title: data[0].title,
							odd_decimal: data[0].odd_decimal,
							odd_fractional: data[0].odd_fractional,
							sport_id: data[0].sport_id,
							sport_slug: data[0].sport_slug,
							region_id: data[0].region_id,
							region_name: data[0].region_name,
							competition_id: data[0].competition_id,
							competition_name: data[0].competition_name,
							match_id: data[0]?.match_id,
							match_name: data[0]?.match_name,
							market_id: data[0]?.market_id,
							outcome_id: data[0]?.outcome_id,
							outcome_name: data[0]?.outcome_name,
							status: data[0]?.status,
							group_id: data[0]?.group_id,
							type: data[0]?.type,
							display_start_date: data[0]?.show_start_date,
							display_end_date: data[0]?.show_end_date,
							bet_start_date: data[0]?.bet_start_date,
							bet_end_date: data[0]?.bet_end_date,
							clone,
						});
					}
				}
			})
			.finally(() => {
				setGetEditDataSpinning({
					...getEditDataSpinning,
					[id]: false,
				});
			});
	};

	const addResult = (id, group_id) => {
		setShowAddResult(true);
		setBetId({
			...betId,
			id: id,
			group_id: group_id,
		});
	};

	const handleSaveBetResult = () => {
		if (!betResult) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please choose bet result</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}
		let payload = {
			id: betId.id,
			group_id: betId.group_id,
			result: betResult,
			resultedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
			user_id: user ? user?.id : "",
		};
		setShowAddResult(false);
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.change_specials_result, resolve, reject, payload, true);
		})
			.then(() => {
				setBetResult("");
				setBetId("");
				// filterCustomMarket();
				const data = prepareFilterData(customFilterData, columnsForBetsFilter);
				const payload = getPayload(data);
				getSpecials(payload);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		const data = prepareFilterData(customFilterData, columnsForBetsFilter);
		const payload = getPayload(data);
		getSpecials(payload);
	}, []);

	const getPayload = data => {
		let bet_start_date = "";
		let bet_end_date = "";

		if (data.date) {
			const dates = data?.date?.split("&");

			bet_start_date = moment(dates[0]).format("YYYY-MM-DD");
			bet_end_date = moment(dates[1]).format("YYYY-MM-DD");
		}

		let payload = {
			result: data.result,
			bet_start_date: bet_start_date,
			bet_end_date: bet_end_date,
		};
		if (data.bet_status) {
			payload.bet_status = data.bet_status;
		}

		return payload;
	};

	const handleBetResultType = type => {
		if (type === betResult) {
			setBetResult("");
		} else {
			setBetResult(type);
		}
	};

	let specialsTableColumns = getSpecialsTableColumns({
		recordss,
		getEditDataSpinning,
		editCustomBet,
		setShowAddCustomBets,
		setShowCustumBets,
		setGetEditDataSpinning,
		addResult,
	});

	const getSpecials = payload => {
		setCurrentPage(0);
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.get_specials, resolve, reject, payload, true);
		})
			.then(response => {
				let data = response.data;
				data.map(bet => {
					let bet_created = "";
					if (bet?.created_at) {
						bet_created = bet?.created_at;
					}
					let odds = parseFloat(bet?.odd_decimal).toFixed(2);
					let obj = {
						id: bet.id,
						sport_id: bet?.sport_id,
						sport_slug: bet?.sport_slug,
						competition_id: bet?.competition_id,
						description: bet?.description,
						type: bet?.type,
						region_name: bet?.region_name,
						competition_name: bet?.competition_name,
						match_name: bet?.match_name,
						market_id: bet?.market_id,
						outcome_id: bet?.outcome_id,
						outcome_name: bet?.outcome_name,
						title: bet?.title,
						odd_decimal: odds,
						odd_fractional: bet?.odd_fractional,
						betted_count: bet?.betted_count,
						start_date: bet?.show_start_date || "",
						end_date: bet?.show_end_date || "",
						competition: bet?.competition_name,
						match: bet?.match_name,
						sport_type: bet?.sport_name,
						region: bet?.region_name,
						bet_start_time: bet?.bet_start_date || "",
						bet_end_time: bet?.bet_end_date || "",
						bet_start_date: bet?.bet_start_date || "",
						bet_end_date: bet?.bet_end_date || "",
						status: bet?.status,
						status_value: bet?.status,
						created: bet_created,

						result: bet?.result,
						resulted: bet?.result,
					};
					records.push(obj);
				});
				setRecords(records);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const saveManualBet = manualBet => {
		setIsDataLoading(true);

		let detailsJson = {
			title_es: manualBet?.title_es,
			title_fr: manualBet?.title_fr,
			description_es: manualBet?.description_es,
			description_fr: manualBet?.description_fr,
		};
		detailsJson = JSON.stringify(detailsJson);
		let payload = {
			sport_slug: manualBet.sport_slug,
			sport_id: manualBet.sport_id,
			competition_id: manualBet.competition_id,
			competition_name: manualBet.competition_name,
			region_id: manualBet.region_id,
			region_name: manualBet.region_name,
			match_id: manualBet.match_id,
			match_name: manualBet.match_name,
			market_id: manualBet.market_id,
			outcome_id: manualBet?.outcome_id,
			outcome_name: manualBet?.outcome_name,
			description: manualBet.description,
			title: manualBet.title,
			type: manualBet.type,
			odd_decimal: manualBet.odd_decimal,
			odd_fractional: manualBet.odd_fractional,

			show_start_date: manualBet.show_start_date,
			show_end_date: manualBet.show_end_date,
			bet_start_date: manualBet.bet_start_date,
			bet_end_date: manualBet.bet_end_date,

			status: manualBet.status,
			user_id: user ? user?.id : "",
			details: detailsJson,
		};

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.add_custom_bet, resolve, reject, payload, true);
		})
			.then(() => {
				getSpecials();
				setShowAddCustomBets(false);
			})
			.finally(() => {
				setTimeout(() => {
					setIsDataLoading(false);
				}, 1000);
			});
	};

	const editManualBet = manualBet => {
		setIsDataLoading(true);
		let detailsJson = {
			title_es: manualBet?.title_es,
			title_fr: manualBet?.title_fr,
			description_es: manualBet?.description_es,
			description_fr: manualBet?.description_fr,
		};
		detailsJson = JSON.stringify(detailsJson);
		let payload = {
			id: manualBet.id,
			group_id: manualBet.group_id,
			sport_slug: manualBet.sport_slug,
			sport_id: manualBet.sport_id,
			competition_id: manualBet?.competition_id,
			competition_name: manualBet.competition_name,
			region_id: manualBet?.region_id,
			region_name: manualBet.region_name,
			match_id: manualBet.match_id,
			match_name: manualBet.match_name,
			market_id: manualBet.market_id,
			outcome_id: manualBet?.outcome_id,
			outcome_name: manualBet?.outcome_name,
			description: manualBet.description,
			title: manualBet.title,
			type: manualBet.type,
			odd_decimal: manualBet.odd_decimal,
			odd_fractional: manualBet.odd_fractional,

			show_start_date: manualBet.show_start_date,
			show_end_date: manualBet.show_end_date,
			bet_start_date: manualBet?.bet_start_date,
			bet_end_date: manualBet?.bet_end_date,

			status: manualBet.status,
			user_id: user ? user?.id : "",
			details: detailsJson,
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.edit_custom_bet, resolve, reject, payload, true);
		})
			.then(() => {
				getSpecials();
				setShowAddCustomBets(false);
			})
			.finally(() => {
				setTimeout(() => {
					setIsDataLoading(false);
				}, 1000);
			});
	};

	let page_name = "Specials";

	const getRegions = sport_slug => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_REGION_OPTIONS + "?sport_slug=" + sport_slug, resolve, reject, true);
		}).then(response => {
			let regionsData = response?.data;
			regionsData = regionsData?.map(x => ({ id: x.code, label: x.name }));
			setRegionsData(regionsData);
		});
	};

	const getAllMatches = async (sport_slug, region_id, competition_id) => {
		const data = await getMatcheDropdown(competition_id, sport_slug, region_id);
		setAllMatches(data);
	};

	const getCompetition = async (sport_slug = "", region_id = "") => {
		const data = await getCompetitionsDropdown(sport_slug, region_id);
		setAllCompetitions(data);
	};

	const filterBets = customFilterData => {
		const payload = getPayload(customFilterData);
		getSpecials(payload);
	};

	const handleShowAddModal = () => {
		setShowAddCustomBets(true);
		setShowCustumBets(true);
	};
	return (
		<HelmetLayout titleProps={"Specials"}>
			{showAddCustomBets && (
				<AddManualBet
					showAddCustomBets={showAddCustomBets}
					setShowAddCustomBets={setShowAddCustomBets}
					saveManualBet={saveManualBet}
					editManualBet={editManualBet}
					bet={manualBet}
					setBet={setManualBet}
					showEditCustomBets={showEditCustomBets}
					regionsData={regionsData}
					setRegionsData={setRegionsData}
					setAllCompetitions={setAllCompetitions}
					setAllMatches={setAllMatches}
					getRegions={getRegions}
					allMatches={allMatches}
					getAllMatches={getAllMatches}
					allCompetitions={allCompetitions}
					getCompetition={getCompetition}
					loading={isDataLoading}
				/>
			)}

			<div className="cms-page pt-0 cms-table-page">
				<div className="col-9 d-flex justify-content-between">
					<SearchFilter
						columns={columnsForBetsFilter}
						page={"specials"}
						preSelectedType={"date"}
						preSelectedValue={"date"}
						preSelectedName={"Data"}
						preSelectedOptionKey={"value"}
						preSelectedOptionName={"name"}
						filterHandler={filterBets}
						filterLoading={isDataLoading}
						customizedFilter={true}
					/>
				</div>

				{showAddResult && (
					<AddResultModal
						betResult={betResult}
						handleBetResultType={handleBetResultType}
						setShowAddResult={setShowAddResult}
						handleSaveBetResult={handleSaveBetResult}
					/>
				)}
				<MUIDataTable
					columns={specialsTableColumns}
					data={recordss}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						// On table columns get which columns are showing
						// onViewColumnsChange(changedColumn) {
						// 	Object.keys(viewColumns).forEach(key => {
						// 		if (key == changedColumn) {
						// 			var tempObj = viewColumns;
						// 			var changedColumnValue = !viewColumns[key];
						// 			tempObj[key] = changedColumnValue;
						// 			setViewColumns(tempObj);
						// 		}
						// 	});
						// },
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: {
							filename: page_name + ".csv",
						},
						customToolbar: () => <AddTableButton onClick={() => handleShowAddModal()} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(CustomBets);
