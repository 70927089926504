import React, { useEffect, useState } from "react";
import { getPositionsTableColumns } from "./PositionsTableColumns";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "components/GeneralComponents/Table";
import { SaveLightButton, SaveUnchangedButton } from "components/GeneralComponents/AddButton";
import { GeneralInput } from "components/GeneralComponents/CustomInputs";

function PositionsEvent() {
	// const { eventViewData } = props;
	const location = useLocation();

	const { pathname } = location;
	const match_id = pathname.split("/")[2];

	const user = JSON.parse(localStorage.getItem("user"));

	const [positionsData, setPositionsData] = useState();
	const [positionContainer, setPositionContainer] = useState({
		tricast: "",
		forecast: "",
	});
	const [dataChanged, setDataChanged] = useState(false);

	const getPositions = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_event_positions + `?event_id=${match_id}`, resolve, reject, true);
		}).then(response => {
			const { data } = response;
			const { positions, tricast, forecast } = data;
			const newPositios = positions.map(row => ({
				...row,
				sp:
					user?.odds_display === "decimal" ? (row?.sp_decimal ? Number(row?.sp_decimal).toFixed(2) : row?.sp_decimal || null) : row?.sp_fractional || null,
				status: row?.status ? row?.status : "Runner",
			}));

			const sortedByPosition = newPositios.sort((a, b) => {
				const positionA = a.position ?? Infinity;
				const positionB = b.position ?? Infinity;
				return positionA - positionB;
			});

			setPositionsData(sortedByPosition);
			setPositionContainer({ tricast: tricast, forecast: forecast });
		});
	};

	useEffect(() => {
		getPositions();
	}, []);

	const handlerSaveOrder = () => {
		const orderPayload = positionsData.map(row => ({ selection_id: row.selection_id, status: row?.status, sp_odd: row?.sp, position: row?.position }));
		const payload = { selections: orderPayload };

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.save_event_positions + `?event_id=${match_id}`, resolve, reject, payload, true);
		}).then(() => {
			setDataChanged(false);
		});
	};

	const handlerPositionsResult = () => {
		let payload = {
			event_id: match_id,
			tricast: positionContainer.tricast,
			forecast: positionContainer.forecast,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.get_event_positions_result, resolve, reject, payload, true);
		}).then(() => {
			setDataChanged(false);
		});
	};

	const handlerSelectionStatus = (newValue, record) => {
		const newPositionsData = positionsData.map(row => (row.selection_id === record.selection_id ? { ...record, status: newValue } : row));
		setPositionsData(newPositionsData);
		setDataChanged(true);
	};

	const getTricastandFourcastData = (value, fieldName, record, type) => {
		if (type === "table") {
			const regex = /^[0-9./]*$/;

			if (regex.test(value)) {
				let newPositionsData = positionsData.map(row => (row.selection_id === record.selection_id ? { ...record, [fieldName]: value } : row));
				console.log(newPositionsData, "newPositionsData");
				setPositionsData(newPositionsData);
				setDataChanged(true);
			}
		} else {
			setPositionContainer({ ...positionContainer, [fieldName]: value });
		}
	};

	let positionsTableColumns = getPositionsTableColumns({ positionsData, handlerSelectionStatus, getTricastandFourcastData });

	return (
		<>
			<div className="result-input positions-input">
				<div className="d-flex justify-content-start gap-2 w-100">
					<GeneralInput
						object={{
							name: "Forecast",
							value: positionContainer?.forecast,
							inputContainer: "flex-column",
							numbersOnly: true,
							fieldName: "forecast",
						}}
						handleChange={getTricastandFourcastData}
					/>
					<GeneralInput
						object={{
							name: "Tricast",
							value: positionContainer?.tricast,
							inputContainer: "flex-column ms-4",
							numbersOnly: true,
							fieldName: "tricast",
						}}
						handleChange={getTricastandFourcastData}
					/>
				</div>
				<div className="d-flex justify-content-end align-items-center gap-2 w-100 position-buttons">
					<SaveLightButton name={"Set Result"} onClick={handlerPositionsResult} loading={false} />
					{dataChanged ? <SaveLightButton name={"Save Order"} onClick={handlerSaveOrder} loading={false} /> : <SaveUnchangedButton text={"Save Order"} />}
				</div>
			</div>

			<DataTable
				data={positionsData}
				columns={positionsTableColumns}
				setData={newData => {
					setPositionsData(newData);
					setDataChanged(true);
				}}
				// isLoading={isDataLoading}
				pagination={false}
				orderRows={true}
				sort={false}
			/>
		</>
	);
}

export default PositionsEvent;
