import React from "react";
import { EyeButton } from "components/GeneralComponents/AddButton";
import HTMLReactParser from "html-react-parser";
import { eventFinishedStatuses } from "utils/constants";
import { getFormatDateByUserTimezone } from "utils/global";

export const getMatchesTableColumns = ({ records, props }) => {
	return [
		{
			name: "event_id",
			label: "EVENT ID",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "match_gid",
			options: {
				setCellProps: () => ({ style: { minWidth: "100px", whiteSpace: "nowrap" } }),
				setCellHeaderProps: () => ({ className: "max-button", style: { minWidth: "100px", whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "start_time_utc",
			label: "START",
			sortable: true,
			sort: "asc",
			className: "match_started_time",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const eventTime = getFormatDateByUserTimezone(record?.start_time_utc);
					return <>{eventTime}</>;
				},
				setCellProps: () => ({ style: { minWidth: "100px", whiteSpace: "nowrap" } }),
				setCellHeaderProps: () => ({ className: "max-button", style: { minWidth: "100px", whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "sport_name",
			label: "SPORT",
			sortable: true,
			sort: "asc",
			className: "match_sport_name",
			options: {
				setCellProps: () => ({ style: { minWidth: "80px", whiteSpace: "nowrap" } }),
				setCellHeaderProps: () => ({ className: "max-button", style: { minWidth: "80px", whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "name",
			label: "EVENT",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "250px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "250px" }, className: "max-button" }),
			},
		},
		{
			name: "competition_name",
			label: "COMPETITION",
			className: "competition_name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "250px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "250px" }, className: "max-button" }),
			},
		},
		{
			name: "current_phase",
			label: "PHASE",
			className: "current_phase match_current_phase",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				// Check if this is an saved report, if so check if that column is showing
				setCellProps: () => ({ style: { whiteSpace: "nowrap", minWidth: "80px" } }),
				setCellHeaderProps: () => ({ className: "max-button", minWidth: "80px" }),
			},
		},
		{
			name: "display",
			label: "DISPLAY",
			className: "display",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					var current_phase = record.current_phase;
					var status_txt =
						record.display == 0 || record.display == null
							? `<span className="badge badge-sports-inactive"> Hidden </span>`
							: `<span className="badge badge-sports-published"> Display </span>`;
					if (!eventFinishedStatuses.includes(current_phase)) {
						return HTMLReactParser(status_txt);
					}
				},
				setCellProps: () => ({ style: { minWidth: "80px" } }),
				setCellHeaderProps: () => ({ className: "max-button", minWidth: "80px" }),
			},
		},
		{
			name: "active",
			label: "STATUS",
			className: "current_status match_status",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					var current_phase = record.current_phase;
					var status_txt =
						record.active == 0 || record.active == null || record.active == ""
							? `<span className="badge badge-sports-inactive"> Inactive </span>`
							: `<span className="badge badge-sports-published"> Published </span>`;
					if (!eventFinishedStatuses.includes(current_phase)) {
						return HTMLReactParser(status_txt);
					}
				},
				setCellHeaderProps: () => ({ style: { minWidth: "80px" } }),
				setCellProps: () => ({ style: { minWidth: "80px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div>
								<EyeButton
									onClick={() => {
										props.history.push(`/events/${record.event_id}?sport_slug=${record.sport_slug}`);
									}}
								/>
							</div>
						</div>
					);
				},
			},
		},
	];
};
