import React from "react";
import { EditButton } from "components/GeneralComponents/AddButton";
import { Spinner } from "react-bootstrap";

export const getCompetitorsTableColumn = ({ records, setCompetitorsName, setCompetitorsType, setSportType, setEditClicked, setCompetitorsId }) => {
	return [
		{
			name: "id",
			label: "id",
			className: "id",
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "90px", height: "42px", alignItems: "center", display: "flex" }}> id </div>
						</>
					);
				},
			},
		},
		{
			name: "name",
			label: "Name",
			className: "name",
			options: {
				filter: true,
				sort: true,
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "130px", height: "42px", alignItems: "center", display: "flex" }}> Name </div>
						</>
					);
				},
			},
		},
		{
			name: "type",
			label: "Type",
			className: "type",
			options: {
				filter: true,
				sort: true,
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "130px", height: "42px", alignItems: "center", display: "flex" }}> Type </div>
						</>
					);
				},
			},
		},
		{
			name: "sport_name",
			label: "Sport",
			className: "type",
			options: {
				filter: true,
				sort: true,
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "550px", height: "42px", alignItems: "center", display: "flex" }}> Sport </div>
						</>
					);
				},
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				setCellProps: () => ({ style: { width: "200px", textAlign: "center" } }),
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div style={{ height: "24px" }}>
								<EditButton
									onClick={() => {
										setEditClicked(true);
										setCompetitorsName(record?.name);
										setCompetitorsType(record?.type);
										setSportType(record?.sport_name);
										setCompetitorsId(record?.id);
									}}
								>
									<Spinner animation="border" size="sm"></Spinner>
								</EditButton>
							</div>
						</div>
					);
				},
			},
		},
	];
};
