import React from "react";
import { Row } from "reactstrap";
import { connect } from "react-redux";

const Breadcrumb = () => {
	return (
		<span>
			<Row></Row>
		</span>
	);
};

const mapStatetoProps = state => {
	const Layout = state.Layout;
	const BreadcrumbData = state.Breadcrumb;
	return {
		layoutType: Layout.layoutType,
		title: BreadcrumbData.title,
		breadcrumbItems: BreadcrumbData.breadcrumbItems,
	};
};

export default connect(mapStatetoProps, {})(Breadcrumb);
