import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { apiUrls } from "utils/const.apiUrl";
import { getPagesContentTableColumns } from "./pagesContentTableColumns";
import { ShowDeletePagesContentModal } from "./showDeletePagesContentModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const pagesContent = props => {
	const [rowPerPage, setRowPerPage] = useState(50);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const [currentPage, setCurrentPage] = useState(0);
	const breadcrumbItems = [{ title: "Pages Content", link: "/page_content" }];
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [records, setRecords] = useState([]);
	const [deleteTitle, setDeleteTitle] = useState(null);

	let rc = [];

	useEffect(() => {
		props.setBreadcrumbItems("Pages Content", breadcrumbItems);
	});

	const filterPagesContent = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.page_content, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let data = response.data;
					data.map(row => {
						let obj = {
							id: row.id,
							name: row?.friendly_name,
							title: row?.page_title,
							description: row?.description,
							page_meta_key: row?.name,
							language: [row?.language],
							protected: row?.protected,
						};
						rc.push(obj);
					});
					setRecords(rc);
					setUnfilteredData(rc);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const pageHeaderData = {
		international: false,
		addNew: true,
		addNewClick: () => {
			props.history.push("/page_content/new");
		},
	};
	const deletePage = () => {
		var page_meta_key = deleteId;
		setDeleteLoading(true);
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.page_content_delete + `/${page_meta_key}`, resolve, reject, true);
		})
			.then(() => {
				let newData = [...records];
				newData = newData.filter(row => row.page_meta_key !== page_meta_key);
				setRecords(newData);
			})
			.finally(() => {
				setDeleteLoading(false);
				setShowDeleteModal(false);
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		filterPagesContent();
	}, []);

	let pagesContentTableColumns = getPagesContentTableColumns({ props, records, setDeleteId, setShowDeleteModal, setDeleteTitle });
	let page_name = "Pages Content";
	return (
		<>
			<HelmetLayout titleProps={"Page Content"}>
				<ShowDeletePagesContentModal
					showDeleteModal={showDeleteModal}
					setShowDeleteModal={setShowDeleteModal}
					deletePage={deletePage}
					deleteTitle={deleteTitle}
					deleteLoading={deleteLoading}
				/>
				<div className="cms-page pt-0" style={{ position: "relative" }}>
					<SingleSearchFilter setData={setRecords} unfilteredData={unfilteredData} filterColumn={["name", "page_meta_key", "language"]} />
					<MUIDataTable
						columns={pagesContentTableColumns}
						data={records}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							elevation: 0,
							onChangePage(page) {
								setCurrentPage(page);
							},
							onChangeRowsPerPage(number) {
								setRowPerPage(number);
							},
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							sortOrder: {
								name: "name",
								direction: "asc",
							},
							page: currentPage,
							responsive: tableResponsive,
							rowsPerPage: rowPerPage,
							rowsPerPageOptions: tableRowsPerPage,
							downloadOptions: { filename: `${page_name}.csv` },
							customToolbar: () => <AddTableButton onClick={pageHeaderData["addNewClick"]} />,
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
			</HelmetLayout>
		</>
	);
};

export default connect(null, { setBreadcrumbItems })(pagesContent);
