import { SET_BET_NOTIFICATIONS_LIST, REMOVE_BET_FROM_NOTIFICATIONS_LIST, ADD_BET_NOTIFICATIONS_LIST } from "./actionTypes";

const initialState = {
	listBetsNotifications: [],
};

const BetNotificationsList = (state = initialState, action) => {
	const updatedList = [...state.listBetsNotifications];
	switch (action.type) {
		case ADD_BET_NOTIFICATIONS_LIST:
			return {
				...state,
				listBetsNotifications: [action.payload, ...state.listBetsNotifications],
			};
		case SET_BET_NOTIFICATIONS_LIST:
			return {
				...state,
				listBetsNotifications: action.payload,
			};
		case REMOVE_BET_FROM_NOTIFICATIONS_LIST:
			return {
				...state,
				listBetsNotifications: updatedList.filter(bet => bet.notification.bet_referral_id !== action.payload),
			};
		default:
			return state;
	}
};

export default BetNotificationsList;
