import React from "react";
import { EditButton } from "components/GeneralComponents/AddButton";
import HTMLReactParser from "html-react-parser";
import { Spinner } from "react-bootstrap";
import { ActiveExpandIcon, ExpandIcon } from "utils/icons";

export const getCompetitionTableColumns = ({ competitions, isLoading, handleFavoriteCompetition, setEditClicked, isUserReadOnly }) => {
	return [
		{
			name: "id",
			label: "ID",
			className: "id",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),
			},
		},
		{
			name: "country_name",
			label: "COUNTRY NAME",
			className: "name",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),
			},
		},
		{
			name: "name",
			label: "COMPETITION NAME",
			className: "name",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "250px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "250px" } }),
			},
		},
		{
			name: "sport_name",
			label: "SPORT",
			className: "sport",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "active",
			label: "STATUS",
			className: "status_name",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = competitions[meta.currentTableData[meta.rowIndex].index];
					var status =
						record.active != 1
							? `<span class="badge badge-sports-inactive"> Inactive </span>`
							: `<span class="badge badge-sports-published"> Published </span>`;
					return HTMLReactParser(status);
				},
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "favorite",
			label: "EXPANDED",
			className: "status_name",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				download: false,
				customBodyRender: (value, meta) => {
					const record = competitions[meta.currentTableData[meta.rowIndex].index];
					const { id, favorite, sport_slug } = record;
					return (
						<div className="d-flex">
							{isLoading === record.id ? (
								<Spinner animation="border" size="sm"></Spinner>
							) : (
								<div className={`${isUserReadOnly && "pe-none"}`} onClick={() => handleFavoriteCompetition(id, !favorite, sport_slug)}>
									{favorite ? <ActiveExpandIcon /> : <ExpandIcon />}
								</div>
							)}
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "80px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "80px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			sortable: true,
			sort: "asc",
			options: {
				viewColumns: false,
				filter: false,
				download: false,
				customBodyRender: (value, meta) => {
					const record = competitions[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end", textAlign: "right", alignItems: "flex-end" }}>
							<div className="competition-buttons">
								<EditButton onClick={() => setEditClicked({ ...record })} />
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
	];
};
