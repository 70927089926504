import React from "react";
import { EditIconButton } from "components/GeneralComponents/AddButton";
import { ThousandSeperated } from "hooks/General";

export const getSportLiabilitiesTableColumns = ({ sportLiabilities, setMarketTierSettings, selectedTier }) => {
	return [
		{
			name: "market_name",
			label: "NAME",
			className: "name",
			sort: "asc",
			sortable: true,
		},
		{
			name: "max_bet",
			label: "MAX BET",
			className: "name",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const record = sportLiabilities[meta.currentTableData[meta.rowIndex].index];
					return <div style={{ textAlign: "right" }}>{ThousandSeperated(record?.max_bet)}</div>;
				},
				setCellProps: () => ({ style: { textAlign: "right" } }),
				setCellHeaderProps: () => ({ className: "max-button align-right" }),
			},
		},
		{
			name: "max_win",
			label: "MAX WIN",
			className: "name",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const record = sportLiabilities[meta.currentTableData[meta.rowIndex].index];
					const maxBetValue = record?.max_win ? ThousandSeperated(record?.max_win) : "-";
					return <div style={{ textAlign: "right" }}>{maxBetValue}</div>;
				},
				setCellProps: () => ({ style: { textAlign: "right" } }),
				setCellHeaderProps: () => ({ className: "max-button align-right" }),
			},
		},
		{
			name: "liability_limit",
			label: "LIABILITY LIMIT",
			className: "name",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const record = sportLiabilities[meta.currentTableData[meta.rowIndex].index];
					const liabilityLimit = record?.liability_limit ? ThousandSeperated(record?.liability_limit) : "-";
					return <div style={{ textAlign: "right" }}>{liabilityLimit}</div>;
				},
				setCellProps: () => ({ style: { textAlign: "right" } }),
				setCellHeaderProps: () => ({ className: "max-button align-right" }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = sportLiabilities[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<div className="d-flex" style={{ justifyContent: "flex-end" }}>
								<div className="sportTypes-buttons" style={{ borderRadius: "0" }}>
									<EditIconButton
										onClick={() => {
											row["name"] = selectedTier?.name;
											row["tier_id"] = selectedTier?.id;
											setMarketTierSettings(row);
										}}
									/>
								</div>
							</div>
						</>
					);
				},
			},
		},
	];
};
