import { EyeButton } from "components/GeneralComponents/AddButton";
import HTMLReactParser from "html-react-parser";
import React from "react";
import { getAccountStatusHTML, getKYCStatusHTML } from "utils/user";

export const getUsersTableColumn = ({ records, props, user }) => {
	return [
		{
			name: "player_id",
			label: "PLAYER ID",
			className: "name",
			options: {
				sortable: true,
				setCellProps: () => ({ style: { textAlign: "left", width: "130px", fontSize: "12px", fontFamily: "Poppins", color: "#2E3A49" } }),
				setCellHeaderProps: () => ({ className: "max-button", width: "130px" }),
			},
		},
		{
			name: "name",
			label: "NAME",
			className: "name",
			options: {
				sortable: true,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex gap-2 align-items-center">
							<a href={"/user/" + record.id} target={"_blank"} rel="noreferrer" className="search-user-name">
								{record.name}
							</a>
							{user.role === "superadmin" && record?.accountType === "test" && <div className="account-type">Test</div>}
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "400px" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "country",
			label: "COUNTRY",
			className: "country",
			options: {
				sortable: true,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return <span>{record?.country}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "330px", fontSize: "12px", fontFamily: "Poppins", color: "#2E3A49" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},

		{
			name: "kyc_status",
			label: "KYC",
			className: "status",
			options: {
				sortable: true,
				filter: false,
				setCellProps: () => ({ style: { textAlign: "left", width: "230px" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					var status_txt = getKYCStatusHTML(record?.kyc_status);
					return HTMLReactParser(status_txt);
				},
				download: false,
				display: true,
			},
		},
		{
			name: "accountStatus",
			label: "STATUS",
			className: "status",
			options: {
				sortable: true,
				filter: false,
				setCellProps: () => ({ style: { textAlign: "left", width: "1200px" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return HTMLReactParser(getAccountStatusHTML(record?.accountStatus));
				},
				download: false,
				display: true,
			},
		},
		{
			name: "",
			label: "",
			className: "",
			options: {
				sortable: false,
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<EyeButton onClick={() => props.history.push(`/user/${record.id}`)} />
						</div>
					);
				},
				setCellProps: () => ({ style: { width: "0px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
