import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip);

const Chart = ({ type, colors, chartData }) => {
	const columns = chartData.map(data => data.value.replace(/[^0-9.]/g, ""));

	const data = {
		labels: chartData.map(item => item.type),
		datasets: [
			{
				// label: "My First Dataset",
				data: columns,
				backgroundColor: colors,
				hoverOffset: 4,
			},
		],
	};

	// const dougnutLabel = {
	// 	id: "doughnutLabel",
	// 	beforeDatasetsDraw(chart) {
	// 		const { ctx, data } = chart;
	// 		console.log(chart, ctx, data);
	// 		ctx.save();
	// 		ctx.font = "bolder 20px sans-serif";
	// 		ctx.fillStyle = "red";
	// 		ctx.textAlign = "center";
	// 		ctx.textBaseLine = "middle";

	// 		console.log(chart.getDatasetMeta(0).data[0]);

	// 		ctx.fillText("VLABLA VL", chart.getDatasetMeta(0).data[0]._view.x, chart.getDatasetMeta(0).data[0]._view.y);
	// 	},
	// };

	const options = {
		type: type,
		responsive: true,
		maintainAspectRatio: false,
		legend: {
			display: false,
		},
		plugins: {
			legend: {
				display: false,
			},
		},
	};

	return (
		<div className="chart">
			<div className="w-100 h-100">
				<Doughnut data={data} options={options} />
			</div>

			{/* {type == "doughnut" && (
				<div className="donut-inner">
					<h5>47 / 60 st</h5>
					<span>(30 / 25 st)</span>
				</div>
			)} */}
			<div className={`chart-labels ${type}`}>
				{chartData.map((data, index) => (
					<div className="label" key={index}>
						<div className="dot" style={{ backgroundColor: colors[index] }} />
						<h3>{data.type}</h3>
						{type == "doughnut" && <h4>{data.value}</h4>}
					</div>
				))}
			</div>
		</div>
	);
};

export default Chart;
