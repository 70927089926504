import { useSelector } from "react-redux";
import "./Backdrop.css";
import React, { useEffect } from "react";

const Backdrop = () => {
	const isActive = useSelector(state => state.Backdrop.isActive);
	useEffect(() => {
		if (isActive) {
			document.body.classList.add("modal-open");
		} else {
			document.body.classList.remove("modal-open");
		}
	}, [isActive]);
	return <>{isActive && <div className="backdrop" />}</>;
};

export default Backdrop;
