import React, { useState } from "react";

import SweetAlert from "react-bootstrap-sweetalert";
import { LoadingSaveButton, NoButton, YesButton } from "components/GeneralComponents/AddButton";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

const DeleteAffiliates = props => {
	const { type, paragraph, deleteItem, setDeleteItem, getSubAgents } = props;

	const [deleteLoading, setDeleteLoading] = useState(false);

	const handlerDelete = () => {
		setDeleteLoading(true);

		if (type === "affiliates_links") {
			new Promise((resolve, reject) => {
				ApiServices.delete(apiUrls.affiliate_users_delete + `/${deleteItem.id}`, resolve, reject, true);
			})
				.then(response => {
					const { success } = response;
					if (success) {
						getSubAgents();
						setDeleteItem(null);
					}
				})
				.finally(() => setDeleteLoading(false));
		} else {
			new Promise((resolve, reject) => {
				ApiServices.delete(apiUrls.DELETE_SUB_AGENT + deleteItem?.agent_id, resolve, reject, true);
			})
				.then(response => {
					const { success } = response;
					if (success) {
						getSubAgents();
						setDeleteItem(null);
					}
				})
				.finally(() => setDeleteLoading(false));
		}
	};

	return (
		<SweetAlert title={"Confirm delete"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
			<div style={{ position: "relative" }}>
				<span className="delete-span">{paragraph}</span>
			</div>
			<div className="d-flex justify-content-center" style={{ marginTop: "30px" }}>
				<NoButton onClick={() => setDeleteItem(null)} name="No" />
				{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={handlerDelete} />}
			</div>
		</SweetAlert>
	);
};

export default DeleteAffiliates;
