import { formatToLocalDatetime } from "hooks/General";
import { tableColumnNumber } from "utils/tableSettings";

export const getResponsibleGamblingDepositLimitsPage = () => {
	return [
		{
			name: "deposit_limit_type",
			label: "LIMIT",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				setCellProps: () => ({ style: { width: "166px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "60px" }, className: "max-button" }),
			},
		},
		{
			name: "createdAt",
			label: "ACTION DATE",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: value => {
					return formatToLocalDatetime(value, "YYYY-MM-DD HH:mm");
				},
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "action_type",
			label: "ACTION TYPE",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: value => {
					return getActionType(value);
				},
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "old_value",
			label: "OLD AMOUNT",
			sortable: true,
			sort: "asc",
			options: tableColumnNumber,
		},
		{
			name: "new_value",
			label: "NEW AMOUNT",
			sortable: true,
			sort: "asc",
			options: tableColumnNumber,
		},
		{
			name: "by",
			label: "EDITED BY",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
	];
};

export const getActionType = value => {
	if (value === "Accept") return "New Limit Approved";
	else if (value === "Initial") return "New Limit Pending Confirmation";
	else if (value === "Reject" || value === "Decline") return "New Limit Rejected";
	else if (value === "Immediately") return "New Limit Applied";
	else return "-";
};
