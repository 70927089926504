import PropTypes from "prop-types";
import React, { Component } from "react";

import { connect } from "react-redux";
import { Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import { changeLayout, changeSidebarTheme, changeSidebarType, changeTopbarTheme, changeLayoutWidth } from "../../store/actions";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Breadcrumb from "../../components/Common/Breadcrumb";
// import { TelebetSidebar } from "./TelebetSidebar";

import "./verticalLayout.scss";

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
		};
		this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
	}

	componentDidMount() {
		if (this.props.isPreloader === true) {
			document.getElementById("preloader").style.display = "block";
			document.getElementById("status").style.display = "block";

			setTimeout(function () {
				document.getElementById("preloader").style.display = "none";
				document.getElementById("status").style.display = "none";
			}, 2500);
		} else {
			document.getElementById("preloader").style.display = "none";
			document.getElementById("status").style.display = "none";
		}

		// Scroll Top to 0
		window.scrollTo(0, 0);

		if (this.props.leftSideBarTheme) {
			this.props.changeSidebarTheme(this.props.leftSideBarTheme);
		}

		if (this.props.layoutWidth) {
			this.props.changeLayoutWidth(this.props.layoutWidth);
		}

		if (this.props.leftSideBarType) {
			this.props.changeSidebarType(this.props.leftSideBarType);
		}
		if (this.props.topbarTheme) {
			this.props.changeTopbarTheme(this.props.topbarTheme);
		}
	}

	toggleMenuCallback = () => {
		if (this.props.leftSideBarType === "default") {
			this.props.changeSidebarType("condensed", this.state.isMobile);
		} else if (this.props.leftSideBarType === "condensed") {
			this.props.changeSidebarType("default", this.state.isMobile);
		}
	};

	render() {
		const { activePage } = this.props;

		return (
			<React.Fragment>
				<div id="preloader">
					<div id="status">
						<div className="spinner-chase">
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
						</div>
					</div>
				</div>
				<div id="layout-wrapper">
					<Header toggleMenuCallback={this.toggleMenuCallback} />
					{this.props?.location?.pathname !== "/bet-ticker" && !this.props?.location?.pathname.includes("/telebet") && (
						<Sidebar theme={this.props.leftSideBarTheme} type={this.props.leftSideBarType} isMobile={this.state.isMobile} />
					)}
					{/* {this.props?.location?.pathname.indexOf("/telebet") > -1 && <TelebetSidebar />} */}
					<div
						className={`main-content
							${activePage === "/bet-ticker" ? "main-content-no-sidebar main_content_bet_ticker" : ""} 
							${this.props?.location?.pathname.includes("/telebet") ? "main-content-no-sidebar" : ""}`}
					>
						<div
							className={`page-content ${
								activePage === "/bet-ticker"
									? "page_content_bet_ticker"
									: this.props?.location?.pathname.includes("/telebet")
									? "telebet-page-content"
									: this.props?.location?.pathname.indexOf("/bet/player") > -1
									? "player_bet_content"
									: this.props?.location?.pathname == "/casino" ||
									  this.props?.location?.pathname == "/live_casino" ||
									  this.props?.location?.pathname == "/virtual_casino"
									? "casino_page_content"
									: "tables-page-content"
							} `}
						>
							<Container fluid className="tables-body">
								<Breadcrumb />
								{this.props.children}
							</Container>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

Layout.propTypes = {
	changeLayoutWidth: PropTypes.func,
	changeSidebarTheme: PropTypes.func,
	changeSidebarType: PropTypes.func,
	changeTopbarTheme: PropTypes.func,
	children: PropTypes.object,
	isPreloader: PropTypes.any,
	layoutWidth: PropTypes.any,
	leftSideBarTheme: PropTypes.any,
	leftSideBarType: PropTypes.any,
	location: PropTypes.object,
	showRightSidebar: PropTypes.any,
	topbarTheme: PropTypes.any,
};

const mapStatetoProps = state => {
	return {
		...state.Layout,
		activePage: state.Login.activePage,
	};
};
export default connect(mapStatetoProps, {
	changeLayout,
	changeSidebarTheme,
	changeSidebarType,
	changeTopbarTheme,
	changeLayoutWidth,
})(withRouter(Layout));
