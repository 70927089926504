import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { getLanguagesTableColumn } from "./LanguagesTableColumn";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "store/actions";
import MUIDataTable from "mui-datatables";

function Languages() {
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [languages, setLanguages] = useState([]);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [currentPage, setCurrentPage] = useState(0);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const inUseLanguages = useSelector(state => state.Login.languages);
	const dispatch = useDispatch();
	const getLanguage = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_languages, resolve, reject, true);
		})
			.then(response => {
				setLanguages(response?.data);
				setUnfilteredData(response?.data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		getLanguage();
	}, []);

	const editLanguageStatus = data => {
		var { language_name, in_use, code } = data;
		let status = in_use;
		let active_status;
		if (status) {
			active_status = 0;
		} else {
			active_status = 1;
		}
		let payload = {
			language_name: language_name,
			status: active_status,
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.edit_languages, resolve, reject, payload, true);
		}).then(() => {
			let newData = [...languages];
			let index = newData.findIndex(row => row.code2 === code);
			let item = newData.filter(row => row.code2 === code);

			if (item && item.length > 0) {
				item[0].in_use = active_status ? true : false;
				newData[index] = item[0];
				setLanguages(newData);
			}
			let actualLanguages = [...inUseLanguages];
			if (actualLanguages.some(row => row.for === code.toLowerCase())) {
				let index = actualLanguages.findIndex(row => row.for === code.toLowerCase());
				actualLanguages.splice(index, 1);
			} else {
				actualLanguages.push({ name: language_name + ` (${code})`, for: code.toLowerCase() });
			}
			dispatch(setLanguage(actualLanguages));
		});
	};

	let languagesTable = getLanguagesTableColumn({ languages, editLanguageStatus, setLanguages });

	return (
		<HelmetLayout titleProps={"Languages"}>
			<div className="cms-page pt-0">
				<SingleSearchFilter setData={setLanguages} unfilteredData={unfilteredData} filterColumn={["language_name", "code2"]} />
				<MUIDataTable
					className="mui-data-table-background"
					columns={languagesTable}
					data={languages}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						sortOrder: {
							name: "first_name",
							direction: "asc",
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: "CMS Users.csv" },
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
}

export default Languages;
