import React from "react";
import MUIDataTable from "mui-datatables";
import { ModalButton } from "components/GeneralComponents/CustomInputs";
import { tableBarSettings } from "utils/tableSettings";

const DynamicTable = ({ object }) => {
	const columns = object.columns;
	const data = object.data;
	const options = { ...object.options, ...tableBarSettings };
	const modalButtons = object.modalButtons;
	const title = object.title;
	const className = object.className || "";
	return (
		<div>
			<div className="col-12 filterResponsive justify-content-between">
				<div className="col-10 d-flex"></div>
			</div>
			<div className="row">
				<div className="col-12 p-0">
					{title && (
						<div className={`container_of_dynamic_tabel ${className}`}>
							<span className="tableTitle">{title}</span>
							<div className="d-flex justify-content-end">
								{modalButtons &&
									modalButtons.map((row, index) => {
										row.buttonClassname = "general_button_white";
										return <ModalButton key={index} object={row} />;
									})}
							</div>
						</div>
					)}
					<MUIDataTable columns={columns} data={data} options={options} />
				</div>
			</div>
		</div>
	);
};

export default DynamicTable;
