import { apiUrls } from "utils/const.apiUrl";
import { filterAndSortArray } from "utils/global";
const { default: ApiServices } = require("utils/ApiServices");
import store from "store/index";
import { setAffiliateUsers } from "store/Users/actions";

export const getAffiliateTagsDropdown = async () => {
	const affiliateUsers = store.getState().Users.affliateUsers;

	if (affiliateUsers?.length) {
		return affiliateUsers;
	}

	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.affiliate_users_get, resolve, reject, true);
	})
		.then(response => {
			let affiliateUsers = response.data?.data;
			affiliateUsers = affiliateUsers.map(row => {
				return { id: row.id, label: row.name };
			});
			const filteredAffiliateUsers = filterAndSortArray(affiliateUsers, "label");
			store.dispatch(setAffiliateUsers(filteredAffiliateUsers));
			return affiliateUsers;
		})
		.catch(() => {
			return [];
		});
};
