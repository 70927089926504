import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

export const getAllMenuLink = async () => {
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.menu_links, resolve, reject, true);
	})
		.then(response => {
			let data = response?.data?.data;

			data = data.map(row => {
				return { id: row.link, label: row.link_name };
			});
			return data;
		})
		.catch(() => {
			return [];
		});
};
