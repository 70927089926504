import React from "react";
import HTMLReactParser from "html-react-parser";
import { EditIconButton, EyeButton } from "components/GeneralComponents/AddButton";
// import { formatToLocalDatetime } from "hooks/General";
import moment from "moment";

export const getBonusEngineColumns = ({ records, onBonusEngineSelect }) => {
	const format = "YYYY-MM-DD";
	return [
		{
			name: "id",
			label: "ID",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "created",
			label: "CREATED",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = records[meta.currentTableData[meta.rowIndex].index];
					const { first_name, last_name } = row;
					return (
						<>
							{first_name} {last_name}
						</>
					);
				},
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "name",
			label: "NAME",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "valid_from",
			label: "VALID FROM (UTC)",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					// return formatToLocalDatetime(record?.valid_from, format);
					return <span>{record?.valid_from ? moment.utc(record?.valid_from).format(format) : "-"}</span>;
				},
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "valid_to",
			label: "VALID TO (UTC)",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					// return formatToLocalDatetime(record?.never_expire ? "" : record?.valid_to, format);
					return <span>{record?.valid_to ? moment.utc(record?.valid_to).format(format) : "-"}</span>;
				},
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "published",
			label: "ACTIVE",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: value => {
					var status_txt =
						value == 0
							? `<span className="badge badge-sports-inactive"> Inactive </span>`
							: `<span className="badge badge-sports-published"> Published </span>`;
					return HTMLReactParser(status_txt);
				},
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "finished",
			label: "Finished",
			className: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: value => {
					var status_txt =
						value == 0
							? `<span className="badge badge-sports-inactive"> Not Finished </span>`
							: `<span className="badge badge-sports-published"> Finished </span>`;
					return HTMLReactParser(status_txt);
				},
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex bonus-engine-buttons justify-content-end gap-3">
							{/* <PlusAddButton onClick={() => runBonusEngine(row.id)} id={row.id} loading={loadingId} /> */}
							<EyeButton className="bonus-engine-eye-btn" onClick={() => onBonusEngineSelect(row.id, "logs")} />
							<EditIconButton onClick={() => onBonusEngineSelect(row.id, "")} />
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
