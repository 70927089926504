import React, { useEffect, useState } from "react";

import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { XCloseIcon } from "utils/icons";
import { AvForm } from "availity-reactstrap-validation";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";

export const ApisAddAndEditModal = props => {
	const { setOpenModal, modalItem, getApisData } = props;

	const isEdit = modalItem.modalType === "edit";

	const [dataChanged, setDataChanged] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	// const [showOptions, setShowOptions] = useState([]);
	// const onFocus = useRef(false);

	// const handleShowOptions = (fieldName, value) => {
	// 	onFocus.current = true;
	// 	setShowOptions({ [fieldName]: value });
	// };

	const getApisEditData = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_apis + `/${modalItem?.id}`, resolve, reject, true);
		}).then(response => {
			const { data } = response;
			setData(data);
		});
	};

	useEffect(() => {
		if (modalItem?.id) getApisEditData();
	}, [modalItem?.id]);

	const headerSave = () => {
		let payload;

		if (isEdit) payload = { id: modalItem?.id, name: data.name, active: data.active };
		else payload = { name: data.name, active: data.active };

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.apis_save, resolve, reject, payload, true, fields);
		})
			.then(() => {
				getApisData();
			})
			.finally(() => {
				setLoading(false);
				setOpenModal(false);
			});
	};

	const fields = [
		{ type: "switch", name: "Status", fieldName: "active" },
		{ type: "input", name: "Name", fieldName: "name" },
		{ type: "input", name: "API Key", fieldName: "apiKey", required: true, textUpperCase: true, copyIcon: true, disabled: true },
		{ type: "input", name: "Secret", fieldName: "secret", disabled: true },
	];

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{"APIs"}</span>
				<XCloseIcon onClick={() => setOpenModal(false)} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={headerSave} dataChanged={dataChanged} loading={loading} onClose={() => setOpenModal(false)} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						{/* onClick={() => !onFocus.current && handleShowOptions()} */}
						<AvForm id="apis">
							<AllComponentsLoop fields={fields} data={data} setData={setData} setDataChanged={setDataChanged} />
						</AvForm>
					</div>
				</div>
			</div>
		</div>
	);
};
