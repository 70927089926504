import { Editor } from "@tinymce/tinymce-react";
import React from "react";

const editorApiUrl = process.env.REACT_APP_EDITOR_API_KEY;
export const TextComponent = props => {
	let { data, footerEditorRef, handleDataChanged } = props;

	return (
		<div className="mt-3 footer-text-container ">
			<span className="editor-title">Footer Text</span>
			<Editor
				apiKey={editorApiUrl}
				onInit={(evt, editor) => (footerEditorRef.current = editor)}
				initialValue={data.length > 0 ? data[0].value : ""}
				init={{
					minHeight: 500,
					maxWidth: 700,
					menubar: false,
					statusbar: false,
					visual: false,
					plugins: [
						"advlist autolink lists link image charmap print preview anchor",
						"searchreplace visualblocks code fullscreen",
						"insertdatetime media paste code help wordcount fullpage",
					],
					toolbar: "undo redo  " + "bold italic  " + "link",
					content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
				}}
				onEditorChange={() => {
					handleDataChanged?.();
				}}
			/>
		</div>
	);
};
