import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useState, useEffect } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";
import moment from "moment";
import { convertFormatDateInUtc, getFormatDateByUserTimezone } from "utils/global";
import { GeneralInput, GeneralDropdown, GeneralDatePicker, GeneralTextArea, GeneralFilterDropdown } from "components/GeneralComponents/CustomInputs";
import { _usageFreebetOptions, _eventPhaseFreeBetType, _turnRequirment } from "utils/constants";
import { getAllSportsDropdown } from "services/getAllSportsDropdown";
import { getCasinoProviders } from "services/getCasinoProviders";
import { getFreebetCasinoGames } from "services/getFreebetCasinoGames";
import { getAmountFreeBetDropdown } from "services/getAmountFreeBetDropdown";

export const FreeBetModal = ({ handler, getFreeBetsData, freeBetModal, userCurrency }) => {
	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const dateFromNowTo7Days = moment(Date.now()).add(7, "days").format("YYYY-MM-DD");
	const timeFromNowTo7Days = moment(Date.now()).add(7, "days").format("HH:mm");

	const [sports, setSports] = useState([]);
	const [casinoProviders, setCasinoProviders] = useState([]);
	const [casinoGames, setCasinoGames] = useState([]);
	const [loadingOptions, setLoadingOptions] = useState(false);
	const [casinoAmounts, setCasinoAmounts] = useState([]);

	const [freeBets, setFreeBets] = useState({
		amount: "",
		reason: "",
		sub_id: freeBetModal?.subId,
		end_date: dateFromNowTo7Days,
		end_hour: timeFromNowTo7Days,
		usage: "",
		event_phase: "all",
		turn_requirements: "",
		casino_restrictions: [],
		provider: "",
		sport_restrictions: [],
		prepared_casino_restrictions: [],
	});

	const isEdit = freeBetModal?.isEdit;

	const handlerGetFreeBet = () => {
		if (isEdit) {
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls.GET_EDIT_FREE_BET + freeBetModal.id, resolve, reject, true);
			}).then(response => {
				const { data } = response;
				const endHour = getFormatDateByUserTimezone(data?.expire_date, "HH:mm");
				const body = { ...data, end_date: data?.expire_date, end_hour: endHour, turn_requirements: data.free_spins };
				setFreeBets(body);
			});
		}
	};

	const handlerSaveFreeBets = () => {
		setLoading(true);
		let endDate = `${freeBets?.end_date} ${freeBets?.end_hour}`;
		endDate = convertFormatDateInUtc(endDate);
		const payload = { ...freeBets, end_date: endDate, sub_id: freeBetModal?.subId };

		delete payload.prepared_casino_restrictions;
		delete payload.end_hour;
		payload.usage === "cash" && delete payload.end_date;

		if (payload.usage !== "sports") {
			delete payload.event_phase;
		}

		new Promise((resolve, reject) => {
			if (isEdit) ApiServices.put(apiUrls.EDIT_FREE_BET, resolve, reject, payload, true);
			else ApiServices.post(apiUrls.ADD_FREE_BET, resolve, reject, payload, true);
		})
			.then(() => {
				getFreeBetsData();
				setDataChanged(false);
				handler();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (isEdit) {
			handlerGetFreeBet();
		}
	}, []);

	useEffect(() => {
		if (freeBets?.usage == "sports") getSports();
		if (freeBets?.usage == "casino") getCasinoProvidersData();
	}, [freeBets?.usage]);

	useEffect(() => {
		getCasinoAmounts(freeBets?.casino_restrictions, freeBetModal?.subId);
	}, [freeBets?.casino_restrictions, freeBetModal?.subId]);

	useEffect(() => {
		if (!isEdit) setFreeBets({ ...freeBets, casino_restrictions: [], prepared_casino_restrictions: [], amount: "" });
	}, [freeBets.provider]);

	const getSports = async () => {
		const data = await getAllSportsDropdown();
		setSports(data);
	};

	const getCasinoProvidersData = async () => {
		const data = await getCasinoProviders();
		setCasinoProviders(data);
	};

	const getFilterOptionsData = async value => {
		const firstParams = freeBets.provider && value ? `provider=${freeBets.provider}&q=${value}&limit=20&freebet=1&sub_id=${freeBetModal?.subId}` : "";

		const game_slug = freeBets.casino_restrictions.length > 0 && `&game_slug=${freeBets.casino_restrictions[0]}`;

		setLoadingOptions(true);
		const data = await getFreebetCasinoGames(firstParams, game_slug);

		setLoadingOptions(false);
		setCasinoGames(data);
	};

	const getCasinoAmounts = async (param, sub_id) => {
		const data = await getAmountFreeBetDropdown(param, sub_id);
		setCasinoAmounts(data);
	};

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Free Bet</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={handlerSaveFreeBets} formId="avForm" type="submit" dataChanged={dataChanged} loading={loading} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<GeneralTextArea
							object={{
								name: "Reason",
								fieldName: "reason",
								value: freeBets.reason,
								// onBlur: () => (onFocus.current = false),
								required: true,
								disabled: isEdit,
								textareaClassName: "general_input largeInput",
							}}
							handleChange={e => {
								setDataChanged(true);
								setFreeBets({ ...freeBets, reason: e });
							}}
						/>

						{freeBets.usage !== "cash" && (
							<>
								<GeneralDatePicker
									object={{
										name: "Expire Date",
										id: "end_date",
										value: freeBets?.end_date,
										disabled: isEdit,
										convertedFullDate: true,
										selectNumber: 7,
										selectType: "days",
										specificDates: true,
										required: true,
										defaultDate: true,
									}}
									handleChange={e => {
										setDataChanged(true);
										setFreeBets({ ...freeBets, end_date: e });
									}}
								/>
								<GeneralDatePicker
									object={{
										id: "end_hour",
										name: "Expire Time",
										disabled: isEdit,
										convertedFullDate: true,
										isTimeExist: true,
										timePicker: true,
										required: true,
										value: freeBets?.end_hour,
									}}
									handleChange={e => {
										setDataChanged(true);
										setFreeBets({ ...freeBets, end_hour: e });
									}}
								/>
							</>
						)}

						<GeneralDropdown
							object={{
								name: "Usage",
								id: "usage",
								fieldName: "usage",
								options: _usageFreebetOptions,
								required: true,
								disabled: isEdit,
								value: freeBets.usage,
							}}
							handleChange={e => {
								setDataChanged(true);
								setFreeBets({ ...freeBets, usage: e?.id });
							}}
						/>

						{freeBets?.usage == "cash" && (
							<>
								<GeneralInput
									object={{
										name: "Amount",
										id: "amount",
										value: freeBets.amount,
										required: true,
										disabled: isEdit,
										numbersOnly: true,
										icon: userCurrency,
										iconInsideInput: true,
									}}
									handleChange={e => {
										setDataChanged(true);
										setFreeBets({ ...freeBets, amount: e });
									}}
								/>
							</>
						)}

						{freeBets?.usage == "sports" && (
							<>
								<GeneralDropdown
									object={{
										name: "Event Phase",
										id: "event_phase",
										fieldName: "event_phase",
										options: _eventPhaseFreeBetType,
										required: true,
										disabled: isEdit,
										value: freeBets.event_phase,
									}}
									handleChange={e => {
										setDataChanged(true);
										setFreeBets({ ...freeBets, event_phase: e?.id });
									}}
								/>

								<GeneralDropdown
									object={{
										name: "Sport Restrictions",
										id: "sport_restrictions",
										fieldName: "sport_restrictions",
										options: sports,
										multiple: true,
										disabled: isEdit,
										value: freeBets.sport_restrictions,
									}}
									handleChange={e => {
										setDataChanged(true);
										const newArray = freeBets?.sport_restrictions;
										newArray.push(e?.id);
										setFreeBets({ ...freeBets, sport_restrictions: newArray });
									}}
									handleRemove={e => {
										setDataChanged(true);
										const newArray = freeBets?.sport_restrictions?.filter(sport => sport !== e?.id);
										setFreeBets({ ...freeBets, sport_restrictions: newArray });
									}}
								/>

								<GeneralInput
									object={{
										name: "Amount",
										id: "amount",
										value: freeBets.amount,
										required: true,
										disabled: isEdit,
										numbersOnly: true,
										icon: userCurrency,
										iconInsideInput: true,
									}}
									handleChange={e => {
										setDataChanged(true);
										setFreeBets({ ...freeBets, amount: e });
									}}
								/>
							</>
						)}

						{freeBets?.usage == "casino" && (
							<>
								<GeneralDropdown
									object={{
										name: "Casino Studio",
										id: "provider",
										fieldName: "provider",
										options: casinoProviders,
										required: true,
										disabled: isEdit,
										value: freeBets?.provider,
									}}
									handleChange={e => {
										setDataChanged(true);
										setFreeBets({ ...freeBets, provider: e?.id });
									}}
								/>

								<GeneralFilterDropdown
									object={{
										value: freeBets?.casino_restrictions,
										options: casinoGames,
										loading: loadingOptions,
										disabled: isEdit,
										filedName: "casino_restrictions",
										name: "Casino Restrictions",
										multipleOptions: freeBets.prepared_casino_restrictions,
										multiple: true,
										required: true,
									}}
									handleRemove={e => {
										const newValue = freeBets?.casino_restrictions?.filter(casino => casino !== e?.id);

										setDataChanged(true);
										setFreeBets({ ...freeBets, casino_restrictions: newValue });
									}}
									handleChange={e => {
										const newValue = [...freeBets?.casino_restrictions, e?.id];
										setDataChanged(true);
										setFreeBets({ ...freeBets, casino_restrictions: newValue });
									}}
									handleFilter={e => {
										getFilterOptionsData(e);
									}}
								/>

								<GeneralDropdown
									object={{
										name: "Amount",
										id: "amount",
										fieldName: "amount",
										options: casinoAmounts,
										required: true,
										disabled: isEdit,
										value: freeBets?.amount,
										additionalIcon: userCurrency,
									}}
									handleChange={e => {
										setDataChanged(true);
										setFreeBets({ ...freeBets, amount: e?.id });
									}}
								/>

								<GeneralDropdown
									object={{
										name: "Turn Requirement",
										id: "turn_requirements",
										fieldName: "turn_requirements",
										options: _turnRequirment,
										required: true,
										disabled: isEdit,
										value: freeBets?.turn_requirements,
									}}
									handleChange={e => {
										setDataChanged(true);
										setFreeBets({ ...freeBets, turn_requirements: e?.id });
									}}
								/>
							</>
						)}
					</div>
					<div className="promotion-right col-12 col-lg-6"></div>
				</div>
			</div>
		</div>
	);
};
