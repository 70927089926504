import React, { useEffect, useState } from "react";
import { SearchHeader } from "./searchHeader";
import { DeleteIconButton, LoadingSaveButton, NoButton, PlusAddButton, YesButton } from "components/GeneralComponents/AddButton";
import { Autocomplete, TextField } from "@mui/material";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import SweetAlert from "react-bootstrap-sweetalert";
import { EditEventMarketFilters } from "./editEventMarketFilters";
import { toast } from "react-toastify";
import xIcon from "../../../assets/images/x-icon.svg";
import { getMarkets } from "services/getMarkets";
import { Spinner } from "reactstrap";
import DragAndDropList from "components/GeneralComponents/DragAndDrop";
import { ActiveExpandIcon, DragAndDropFillIcon, DregAndDropIcone, ExpandIcon, MoveUpToTopDisabledIcone, MoveUpToTopIcone } from "utils/icons";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import deleteIcon from "../../../assets/images/delete.svg";

export const EventMarketFilters = props => {
	const { sport, isUserReadOnly } = props;
	const [markets, setMarkets] = useState([]);
	const [events, setEvents] = useState([]);
	const [eventMarkets, setEventMarkets] = useState([]);
	const [unfilteredMarkets, setUnfilteredMarkets] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [addLoading, setAddLoading] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [editFilters, setEditFilters] = useState(false);
	const [loader, setIsLoader] = useState(false);
	const [marketsHandicaps, setMarketsHandicaps] = useState({});
	const [handicapsLoading, setHandicapsLoading] = useState({});

	const getMarketsData = () => {
		setIsLoader(true);
		const param = `?sport_slug=${sport?.slug}`;
		getMarkets(param).then(data => {
			setIsLoader(false);
			setMarkets(data);
			setUnfilteredMarkets(data);
		});
	};

	const getEventMarkets = sport_event_id => {
		setIsLoader(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_event_connection_get + `?sport_event_id=${sport_event_id}&sport_slug=${sport?.slug}`, resolve, reject, true);
		}).then(async response => {
			setIsLoader(false);
			const data = response.data;
			setEventMarkets(data);

			setMarkets(unfilteredMarkets);
		});
	};

	const getSportEvents = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_event_get + `?sport_slug=${sport?.slug}`, resolve, reject, true);
		}).then(response => {
			setEvents(response.data);
		});
	};

	const addEventMarket = row => {
		if (!selectedEvent?.id) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select Event</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		let payload = {
			sport_slug: sport?.slug,
			market_id: row?.market_id,
			sport_event_id: selectedEvent?.id,
		};
		setAddLoading(row?.market_id);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.sport_event_connection_add, resolve, reject, payload, true);
		})
			.then(response => {
				let newId = response?.data;
				let newMarket = {
					id: newId,
					market_id: row?.market_id,
					market_name: row?.market_name,
					order_nr: newId,
					sport_event_id: 0,
					sport_slug: sport?.slug,
					enabled_handicaps: row?.enabled_handicaps,
					handicap: row?.handicap,
				};
				let actualEvents = [...eventMarkets];
				actualEvents.push(newMarket);
				setEventMarkets(actualEvents);
			})
			.finally(() => {
				setAddLoading(false);
			});
	};

	const deleteEventMarket = () => {
		setDeleteLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.sport_event_connection_delete + `/${deleteId}`, resolve, reject, true);
		})
			.then(() => {
				let actualEventMarkets = [...eventMarkets];
				// let deletedMarket;
				actualEventMarkets = actualEventMarkets.filter(row => {
					if (row.id === deleteId) {
						// deletedMarket = row;
						return false;
					}
					return true;
				});

				setEventMarkets(actualEventMarkets);
				setDeleteId(null);
			})
			.finally(() => {
				setDeleteLoading(false);
			});
	};

	const changeOrder = index => {
		const selectedRow = eventMarkets.splice(index, 1)[0];
		const newData = [selectedRow, ...eventMarkets];
		setDregAndDropData(newData);
	};

	const setDregAndDropData = newData => {
		setEventMarkets(newData);
		orderItems(newData);
	};

	const orderItems = data => {
		let payload = { order: data.map((row, index) => ({ id: row.id, order: index })) };

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.sport_event_connection_order, resolve, reject, payload, true);
		})
			.then(() => {
				setEventMarkets(data);
			})
			.finally(() => {});
	};

	const editDefaultMarket = id => {
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.sport_event_connection_edit + `/${id}`, resolve, reject, true, true);
		}).then(() => {
			let actualEventMarkets = [...eventMarkets];
			actualEventMarkets.forEach(row => {
				if (row.id === id) {
					if (row?.sport_widget_default === 0) {
						row.sport_widget_default = 1;
					} else {
						row.sport_widget_default = 0;
					}
				}
			});
			setEventMarkets(actualEventMarkets);
		});
	};

	useEffect(() => {
		if (sport) {
			getMarketsData();
			getSportEvents();
		}
	}, []);

	const getMarketHandicaps = market_id => {
		setHandicapsLoading({ ...handicapsLoading, [market_id]: true });

		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_event_handicaps + market_id, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					const data = response.data;
					setMarketsHandicaps({ ...marketsHandicaps, [market_id]: data });
					return data;
				}
			})
			.finally(() => {
				setHandicapsLoading({ ...handicapsLoading, [market_id]: false });
			});
	};

	const saveMarketHadicap = (market_id, handicap, connection_id) => {
		const sport_event_id = selectedEvent?.id;
		const sport_slug = sport?.slug;

		const body = { handicap, sport_slug, market_id, sport_event_id, connection_id };

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.save_event_hadicap, resolve, reject, body, true);
		})
			.then(response => {
				if (response.success) {
					const data = eventMarkets.map(item => (item.id == connection_id ? { ...item, handicap_value: handicap } : item));
					setEventMarkets(data);
				}
			})
			.finally(() => {});
	};

	const handleHandicapChange = (value, type, row) => {
		const { market_id, id, handicap_value } = row;
		const oldHandicap = handicap_value ? handicap_value?.split(",") : [];
		let newHandicap = [...oldHandicap];

		if (value?.id) {
			if (type === "add") {
				newHandicap.push(value?.id);
			} else if (type === "remove") {
				newHandicap = oldHandicap.filter(c => c != value?.id);
			}

			saveMarketHadicap(market_id, newHandicap.join(","), id);
		}
	};

	return (
		<>
			{loader ? (
				<div className="text-center pt-2">
					<Spinner animation="border" size="lg" />
				</div>
			) : (
				<>
					<div className="row m-0">
						<div className="col-6">
							<div className="search-header">
								<div className="search-input-group" style={{ height: "100%" }}>
									<div className="search-input-container dropdown-wrapper">
										<Autocomplete
											disablePortal
											className="autocomplete-select input-arrow"
											options={events}
											value={selectedEvent ? events.find(event => event?.id == selectedEvent?.id) : null}
											key={Math.random()}
											getOptionLabel={option => option.title}
											sx={{ width: 300 }}
											renderInput={params => <TextField placeholder={"Select"} {...params} />}
											onChange={(event, newValue) => {
												setSelectedEvent(newValue);
												getEventMarkets(newValue?.id);
											}}
										/>
									</div>
									<YesButton name="Edit Filters" onClick={() => setEditFilters(true)} />
								</div>
							</div>
							<div className="sport-markets custom-scrollbar">
								<DragAndDropList data={eventMarkets} setData={newData => setDregAndDropData(newData)}>
									{(row, { index, dragHandleProps, isDragging }) => {
										return (
											<div key={index} className={`default-sport-row ${isUserReadOnly && "pe-none"}`}>
												<span>{row?.market_name}</span>

												<div className="d-flex justify-content-end align-items-start gap-2">
													{row?.handicap == 1 && (
														<div>
															<GeneralDropdown
																object={{
																	multiBindValue: row?.handicap_value ? row?.handicap_value?.split(",") : [],
																	options: marketsHandicaps?.[row.market_id]?.map(item => ({ id: item, label: item })),
																	defaultPlaceholder: "Handicaps",
																	multiple: true,
																	onOpen: () => {
																		if (!marketsHandicaps?.[row.market_id]) getMarketHandicaps(row?.market_id);
																	},
																	loading: handicapsLoading?.[row.market_id],
																	wrapperClassNameDropdown: `${
																		eventMarkets.length == index + 1 && eventMarkets.length > 6 ? "open_dropdown_up" : ""
																	}`,
																}}
																handleRemove={value => handleHandicapChange(value, "remove", row)}
																handleChange={value => handleHandicapChange(value, "add", row)}
															/>
														</div>
													)}
													<span onClick={() => editDefaultMarket(row?.id)}>
														{row?.sport_widget_default ? <ActiveExpandIcon /> : <ExpandIcon />}
													</span>

													<span>
														<DeleteIconButton deleteIcon={deleteIcon} className={"m-0"} onClick={() => setDeleteId(row?.id)} />
													</span>
													<span>{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}</span>
													<span>
														<div {...dragHandleProps} onMouseDown={(...args) => dragHandleProps?.onDragStart(...args)}>
															<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
														</div>
													</span>
												</div>
											</div>
										);
									}}
								</DragAndDropList>
							</div>
						</div>
						<div className="col-6">
							<div>
								<SearchHeader unfilteredData={unfilteredMarkets} data={markets} setData={setMarkets} />
								<div className="sport-markets custom-scrollbar">
									{markets
										?.filter(row => !eventMarkets.some(item => item.market_id == row?.market_id))
										.map((row, index) => {
											return (
												<div key={index} className={`default-sport-row ${isUserReadOnly && "pe-none"}`}>
													<span>{row?.market_name}</span>
													<div>
														<PlusAddButton onClick={() => addEventMarket(row)} id={row?.market_id} loading={addLoading} />
													</div>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>
					{deleteId && (
						<SweetAlert title={"Confirm delete"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
							<div style={{ position: "relative" }}>
								<div className="">
									<span className="delete-span">Are you sure you want to delete this market ?</span>
								</div>
							</div>
							<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
								<NoButton onClick={() => setDeleteId(null)} name="No" />
								{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={() => deleteEventMarket()} />}
							</div>
						</SweetAlert>
					)}
					{editFilters && (
						<EditEventMarketFilters handler={() => setEditFilters(false)} slug={sport?.slug} setNewData={setEvents} isUserReadOnly={isUserReadOnly} />
					)}

					{/* {confirmOrder && (
						<ConfirmOrderItems show={confirmOrder} setShow={setConfirmOrder} handler={confirmOrderHandler} loading={orderData?.loading} />
					)} */}
				</>
			)}
		</>
	);
};
