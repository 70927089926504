import React, { useState, useEffect } from "react";
import MiniWidget from "./miniwidget";
import PieCharts from "./pieCharts";
import BarLine from "./BarLine";
import DashboardTable from "./dashboardTable";
import ApiServices from "utils/ApiServices";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";

import { apiUrls } from "utils/const.apiUrl";
import { _months } from "utils/constants";
import { getAllMonthsAndYears } from "utils/global";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";

import "./style.scss";

const formatedMonths = _months.map((month, index) => ({ label: month, value: index + 1 })).reverse();

const Dashboard2 = () => {
	const latestMonth = getAllMonthsAndYears(formatedMonths)[0]?.id;
	const [filter, setFilter] = useState(latestMonth);
	const [dashboardData, setDashboardData] = useState({});

	useEffect(() => {
		getDashboardData();
	}, [filter]);

	const getDashboardData = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.dashboard_data + `?period=${filter}`, resolve, reject, true);
		}).then(response => {
			setDashboardData(response.data);
		});
	};

	return (
		<HelmetLayout titleProps={"Dashboard"}>
			<div className="dashboard">
				<div className="dashboard-filter">
					<GeneralDropdown
						object={{
							value: filter,
							handleChangeDropdown: value => setFilter(value?.id),
							options: getAllMonthsAndYears(formatedMonths),
						}}
					/>
				</div>
				<MiniWidget data={dashboardData?.mini_widgets} />

				<div className="dashboard-charts">
					{dashboardData?.charts?.map((chart, index) => {
						return chart.type == "bar" ? <BarLine chart={chart} key={index} /> : <PieCharts chart={chart} key={index} />;
					})}
				</div>

				<DashboardTable data={dashboardData?.tables} />
			</div>
		</HelmetLayout>
	);
};

export default Dashboard2;
