import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useState } from "react";

import { XCloseIcon } from "utils/icons";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

function AddAffiliateUser(props) {
	const { handleClose } = props;

	const [loading, setLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [affiliateUsers, setAffiliateUsers] = useState({
		email: "",
		first_name: "",
		last_name: "",
		mfa_status: 0,
		timezone_name: "Europe/London",
		active_status: 0,
		revenue_share: "0.00",
		deductions: "0.00",
		taxes: "0.00",
		duties: "0.00",
		platform_fees: "0.00",
		sports_provider_fees: "0.00",
		casino_provider_fees: "0.00",
		payment_process_fees: "0.00",
		admin_fees: "0.00",
		general_fees: "0.00",
		currency: "",
		// parent_id,
	});

	const handlerAdd = () => {
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.ADD_AFFILIATE_USERS, resolve, reject, affiliateUsers, true);
		})
			.then(response => {
				const { success } = response;
				if (success) handleClose("call");
			})
			.finally(() => setLoading(false));
	};

	// const handleChange = () => {};

	const fields = [
		{ type: "switch", name: "Active", fieldName: "active_status" },
		{ type: "input", name: "First Name", fieldName: "first_name", required: true },
		{ type: "input", name: "Last Name", fieldName: "last_name", required: true },
		{ type: "input", name: "Email", fieldName: "email", required: true },
		{ type: "dropdown", name: "Timezone", fieldName: "timezone_name", required: true, optionsType: "timezone" },
		{ type: "switch", name: "Enabled 2FA", fieldName: "mfa_status" },
		{ type: "dropdown", name: "Currency", fieldName: "currency", optionsType: "currency" },
		{ type: "input", name: "Rev Share", fieldName: "revenue_share" },
		{ type: "empty_row", name: "Deductions", inputClassName: "medium-custum-input" },
		{ type: "input", name: "General", fieldName: "general_fees" },
		{ type: "input", name: "Taxes", fieldName: "taxes" },
		{ type: "input", name: "Duties", fieldName: "duties" },
		{ type: "input", name: "Platform Fees", fieldName: "platform_fees" },
		{ type: "input", name: "Sports Provider Fees", fieldName: "sports_provider_fees" },
		{ type: "input", name: " Casino Provider Fees", fieldName: "casino_provider_fees" },
		{ type: "input", name: " Payment processing Fees", fieldName: "payment_process_fees" },
		{ type: "input", name: "Admin Fees", fieldName: "admin_fees" },
	];

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>AFFILIATE USER</span>
				<XCloseIcon className={"xclose-icon"} onClick={handleClose} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={() => handlerAdd()} dataChanged={dataChanged} onClose={handleClose} loading={loading} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AllComponentsLoop fields={fields} data={affiliateUsers} setData={setAffiliateUsers} setDataChanged={setDataChanged} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddAffiliateUser;
