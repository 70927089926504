import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import { UsersChatSearch } from "../../../components/GeneralComponents/CustomInputs";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { AppContext } from "../../../context/AppContext";
import { removeFromNotificationsList } from "../../../store/NotificationsList/actions";
import "./UsersChat.css";
import { capitalizeText } from "services/capitalizeText";
import { getFormatDateByUserTimezone, isToday, userReadOnly } from "utils/global";
import "./UsersChat.scss";
import { SimpleGoBackArrow } from "utils/icons";

const lastMessageTime = date => {
	const currentDate = moment();
	const dateToCheck = moment(date);

	if (currentDate.isSame(dateToCheck, "day")) {
		return getFormatDateByUserTimezone(date, "HH:mm A");
	} else {
		return getFormatDateByUserTimezone(date, "DD-MM-YYYY");
	}
};

const UsersChat = () => {
	const chatBlockRef = useRef(null);
	const dispatch = useDispatch();
	const roomsListRef = useRef(null);

	const [lastMessage, setLastMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [activeRoom, setActiveRoom] = useState("");
	const [activeUser, setActiveUser] = useState("");
	const [activeUserName, setActiveUserName] = useState("");
	const [writtenMessage, setWrittenMessage] = useState("");
	const [rooms, setRooms] = useState({});
	const [filteredRooms, setFilteredRooms] = useState({});
	const [isFetching, setIsFetching] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [userChatResponsive, setUserChatResponsive] = useState(false);

	const context = useContext(AppContext);
	const socketCommunication = context.socketCommunication;
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const history = useHistory();
	const roomIdSearch = searchParams.get("room_id");
	const gamingUserSearch = searchParams.get("gamingUser");

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	const sortMessages = data => {
		return Object.values(data).sort((a, b) => {
			return moment(b.messages[b.messages.length - 1].created_at) - moment(a.messages[a.messages.length - 1].created_at);
		});
	};

	const updateRooms = callback => {
		setRooms(callback);
		setFilteredRooms(callback);
	};

	const reassemblyRooms = roomsObject => {
		const resultObject = {};

		roomsObject.forEach(data => {
			const { gaming_user, messages, room_id, player_id } = data;

			if (gaming_user) {
				resultObject[room_id] = {
					gaming_user,
					messages,
					room_id,
					player_id,
				};
			}
		});

		return resultObject;
	};

	const scrollMessage = () => {
		const chatBlock = chatBlockRef.current;
		chatBlock.scrollIntoView();
	};

	const sendMessageHandler = event => {
		event.preventDefault();

		if (!activeRoom) {
			return;
		}

		if (writtenMessage.length > 0) {
			socketCommunication.emit(
				"cms_chat_message",
				{
					message: writtenMessage,
					to: activeRoom,
				},
				response => {
					if (response?.data) {
						updateRooms(prev => {
							return {
								...prev,
								[activeRoom]: {
									...prev[activeRoom],
									messages: [...prev[activeRoom].messages, response.data],
								},
							};
						});

						setWrittenMessage("");
					}
				}
			);
		}
	};

	const activeRoomHandler = (roomId, gaminUser, userName) => {
		setUserChatResponsive(true);
		setActiveRoom(roomId);
		setActiveUser(gaminUser);
		dispatch(removeFromNotificationsList({ room_id: roomId }));
		setActiveUserName(userName);
	};

	const fetchNextPage = () => {
		setIsFetching(true);

		socketCommunication.emit("messages_by_rooms", { page: currentPage + 1 }, response => {
			if (response?.data) {
				setCurrentPage(response.data.current_page);
				setTotalPages(response.data.total_pages);
				setIsFetching(false);
				updateRooms(prev => {
					return {
						...prev,
						...reassemblyRooms(
							response.data.rooms.map(user => {
								return {
									...user,
									messages: user.messages.reverse(),
								};
							})
						),
					};
				});
			}
		});
	};

	const hasNextPage = currentPage < totalPages;

	const scrollHandler = e => {
		if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 0 && !isFetching && hasNextPage) {
			fetchNextPage();
		}
	};

	useEffect(() => {
		if (socketCommunication?.connected) {
			socketCommunication.emit("messages_by_rooms", { page: currentPage }, response => {
				if (response?.data) {
					setCurrentPage(response.data.current_page);
					setTotalPages(response.data.total_pages);

					const reasembledRooms = reassemblyRooms(
						response.data.rooms.map(user => {
							return {
								...user,
								messages: user.messages.reverse(),
							};
						})
					);

					const firstRoom = sortMessages(reasembledRooms)[0];

					setActiveRoom(firstRoom.room_id);
					setActiveUserName(firstRoom.gaming_user);
					setActiveUser(firstRoom.player_id);

					updateRooms(() => reasembledRooms);
					if (roomIdSearch) {
						setActiveRoom(roomIdSearch);
						setActiveUser(gamingUserSearch);
						history.replace("/users_chat");
					}
				}

				setIsLoading(false);
			});

			socketCommunication.on("new_chat_message", newMessage => {
				updateRooms(prev => {
					newMessage.is_read = true;

					return {
						...prev,
						[newMessage.room_id]: {
							room_id: newMessage.room_id,
							gaming_user: newMessage.from_cms ? prev[newMessage.room_id].gaming_user : newMessage.author_name,
							messages: prev[newMessage.room_id] ? [...prev[newMessage.room_id].messages, newMessage] : [newMessage],
							player_id: newMessage.from_cms ? prev[newMessage.room_id].player_id : newMessage.player_id,
						},
					};
				});

				setLastMessage(newMessage);
			});
		}
	}, [socketCommunication]);

	useEffect(() => {
		if (activeRoom) {
			scrollMessage();
		}
	}, [activeRoom, rooms]);

	useEffect(() => {
		if (roomsListRef?.current?.scrollHeight <= roomsListRef?.current?.clientHeight && !isLoading && hasNextPage) {
			fetchNextPage();
		}
	}, [rooms, isLoading]);

	useEffect(() => {
		if (activeRoom) {
			const readMessagesIds = rooms[activeRoom]?.messages?.filter(message => !message.is_read && !message.from_cms)?.map(message => message.message_id);
			if (readMessagesIds?.length > 0) {
				socketCommunication.emit("read_messages", {
					messageIds: readMessagesIds,
					roomId: activeRoom,
				});

				updateRooms(prev => ({
					...prev,
					[activeRoom]: {
						...prev[activeRoom],
						messages: prev[activeRoom].messages.map(message => {
							if (!message.from_cms) {
								return {
									...message,
									is_read: true,
								};
							}

							return message;
						}),
					},
				}));
			}
		}
	}, [activeRoom]);

	useEffect(() => {
		if (lastMessage) {
			if (lastMessage?.room_id === activeRoom) {
				socketCommunication.emit("read_messages", {
					messageIds: [lastMessage.message_id],
					roomId: activeRoom,
				});
			}

			updateRooms(prev => {
				prev[lastMessage.room_id].messages.splice(prev[lastMessage.room_id].messages.length - 1, 1, {
					...lastMessage,
					is_read: lastMessage?.room_id === activeRoom,
				});

				return {
					...prev,
					[lastMessage.room_id]: {
						...prev[lastMessage.room_id],
						messages: prev[lastMessage.room_id].messages,
					},
				};
			});
		}
	}, [lastMessage]);

	useEffect(() => {
		if (activeRoom) setWrittenMessage("");
	}, [activeRoom]);

	const sortedMessagesByLastMessage = sortMessages(filteredRooms);

	return (
		<HelmetLayout titleProps={"Trader Chat"}>
			<div className="users-chat-wrapper">
				<div className="users-chat-left-side">
					{isLoading ? (
						<Spinner animation="border" size="md m-3" />
					) : (
						<>
							<div className="chat-search-wrapper">
								<UsersChatSearch
									unfilteredData={Object.values(rooms)}
									filterColumn={["gaming_user"]}
									setData={filteredData => {
										setFilteredRooms(reassemblyRooms(filteredData));
									}}
								/>
							</div>
							<div className="users-chat-rooms-list" ref={roomsListRef} onScroll={scrollHandler}>
								{sortedMessagesByLastMessage?.length > 0 ? (
									sortedMessagesByLastMessage.map(messagesData => {
										const unreadMessages = messagesData?.messages?.reduce((accumulator, currentValue) => {
											if (!currentValue?.is_read && !currentValue.from_cms) {
												return accumulator + 1;
											}

											return accumulator;
										}, 0);

										const lastUserMessage = messagesData?.messages.filter(message => !message.from_cms).pop();
										return (
											<div
												className={`row m-0 user-chat-card ${messagesData.room_id === activeRoom && "active-room"}`}
												onClick={() => activeRoomHandler(messagesData.room_id, messagesData.player_id, messagesData.gaming_user)}
												key={messagesData.room_id}
											>
												<div className="col-9 p-0 user-chat-info">
													<span className="user-name">{capitalizeText(messagesData.gaming_user)}</span>
													<span className="user-id">{messagesData.player_id}</span>
												</div>
												<div className="col-3 p-0 user-chat-right">
													<span className="message-time">{lastMessageTime(lastUserMessage.created_at)}</span>
													{unreadMessages > 0 && <span className="count-unread-message">{unreadMessages}</span>}
												</div>
											</div>
										);
									})
								) : (
									<span className="chat-search-wrapper">No users found</span>
								)}
							</div>
						</>
					)}
				</div>
				{activeRoom && (
					<div className={`users-chat-box ${userChatResponsive ? "active" : ""}`}>
						<div>
							<div className="users-chat-header">
								<div className="go-back">
									<SimpleGoBackArrow onClick={() => setUserChatResponsive(false)} />
								</div>
								<div>
									<div className="user-name">{capitalizeText(activeUserName)}</div>
									<div className="user-id">{activeUser}</div>
								</div>
							</div>
							<div className={`users-chat-messages`}>
								{rooms[activeRoom]?.messages?.map(message => {
									let userMessageTime, adminMessageTime;
									let formatType = "YYYY-MM-DD HH:mm";

									if (isToday(message?.created_at)) formatType = "HH:mm A";

									if (!message?.from_cms) {
										userMessageTime = getFormatDateByUserTimezone(message?.created_at, formatType);
									} else {
										adminMessageTime = getFormatDateByUserTimezone(message?.created_at, formatType);
									}
									return (
										<div key={message.message_id}>
											<div className={`${message.from_cms ? "user-cms-message" : "user-chat-message"}`}>
												<div className={`user-message ${message.from_cms && "user-cms-message-bg"}`}>{message.message}</div>
											</div>
											<div className="user-message-info">
												{!message?.from_cms && (
													<div className={`${message.from_cms ? "user-cms-message" : "user-chat-message"}`}>{userMessageTime}</div>
												)}
											</div>
											<div className="admin-message-info">
												{message?.from_cms && (
													<div className={`${message.from_cms ? "user-cms-message" : "user-chat-message"}`}>{adminMessageTime}</div>
												)}
												{message?.from_cms && (
													<div className={`${message.from_cms ? "user-cms-message" : "user-chat-message"}`}>- {message.author_name}</div>
												)}
											</div>
										</div>
									);
								})}
								<div ref={chatBlockRef}></div>
							</div>
						</div>
						<form className={`typing-input-form ${isUserReadOnly && "pe-none"}`} onSubmit={sendMessageHandler}>
							<input
								className="users-chat-input"
								type="text"
								maxLength={2500}
								value={writtenMessage}
								onChange={event => setWrittenMessage(event.target.value)}
								disabled={isUserReadOnly}
							/>
							<input className="users-chat-send-btn" type="submit" value="Send" disabled={isUserReadOnly} />
						</form>
					</div>
				)}
			</div>
		</HelmetLayout>
	);
};

export default UsersChat;
