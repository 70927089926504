import PropTypes from "prop-types";
import React, { useState, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { changeSidebarType, showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import HeaderButton from "components/HeaderButton/HeaderButton.js";
import { BetTickerIcon, CmsIcon, DashboardIcon, ReportingIcon, TradingIcon, UsersIcon, ThreeDotsIcon, HamburgerIcon, AffiliatesIcon } from "utils/icons";
import { SettingsIcon } from "./../../utils/icons";
import HeaderNotification from "../HeaderNotification/headerNotification";
import { setShowSidebar } from "store/layout/actions";

import "./header.scss";

const Header = () => {
	const activePage = useSelector(state => state.Login.activePage);
	const user = useSelector(state => state.Login.user);
	const logo = useSelector(state => state.Layout.logo?.desktop_logo);
	const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;
	const url = aws_s3_url + "/media/" + logo;

	const dispatch = useDispatch();

	const [showMore, setShowMore] = useState(false);

	const openSideBar = () => {
		dispatch(setShowSidebar(true));
	};

	function tToggle() {
		var body = document.body;
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
	}

	const headerLinks = [
		{
			name: "Dashboard",
			link: "/dashboard",
			icon: <DashboardIcon active={activePage === "/dashboard"} />,
			active: activePage === "/dashboard" ? true : false,
			roleToHide: ["affiliate"],
		},
		{
			name: "Users",
			link: "/search_users",
			icon: <UsersIcon active={activePage === "/search_users" || activePage === "/users_chat"} />,
			active: activePage === "/search_users" || activePage === "/users_chat" ? true : false,
			roleToHide: ["affiliate"],
		},
		{
			name: "Trading",
			link: "/bets",
			icon: <TradingIcon active={activePage === "/trading" || activePage === "/bets"} />,
			active: activePage === "/trading" || activePage == "/bets" ? true : false,
			roleToHide: ["finance", "affiliate"],
			checkEnabledModules: true,
		},
		{
			name: "Bet Ticker",
			link: "/bet-ticker",
			icon: <BetTickerIcon active={activePage === "/bet-ticker"} />,
			active: activePage === "/bet-ticker" ? true : false,
			roleToHide: ["finance", "affiliate"],
			checkEnabledModules: true,
		},
		{
			name: "Reporting",
			link: "/reports",
			icon: <ReportingIcon active={activePage === "/reports"} />,
			active: activePage === "/reports" ? true : false,
			roleToHide: ["affiliate"],
		},
		{
			name: "CMS",
			link: "/cms",
			icon: <CmsIcon active={activePage === "/cms"} />,
			active: activePage === "/cms" ? true : false,
			roleToHide: ["finance", "affiliate"],
		},
		{
			name: "Settings",
			link: "/settings",
			icon: <SettingsIcon active={activePage === "/settings"} />,
			active: activePage === "/settings" ? true : false,
			roleToHide: ["finance", "trader", "customer_service", "affiliate"],
		},
		{
			name: "Affiliates",
			link: "/affiliates",
			icon: <AffiliatesIcon active={activePage === "/affiliates"} />,
			active: activePage === "/affiliates" ? true : false,
			roleToHide: ["finance", "trader", "customer_service", "admin", "auditor", "operations", "superadmin"],
		},
	];

	const filteredMobileLinks = useMemo(() => {
		return headerLinks?.filter(row => !row.roleToHide || (user && !row?.roleToHide?.includes(user?.role)));
	}, [activePage, headerLinks, user]);

	return (
		<React.Fragment>
			<header id="page-topbar">
				<div>
					<div className="navbar-header">
						<div className="d-flex align-items-center ">
							<div className="navbar-brand-box navbar-logo">
								{/* <Link to="/" className="logo logo-dark ">
									<span className="logo-sm">
										<img src={url} alt="" height="12" />
									</span>
									<span className="logo-lg">
										<img src={url} alt="" height="29" />
									</span>
								</Link> */}
								<Link to="/" className="logo">
									{/* <span className="logo-sm">
										<img src={url} alt="" height="12" />
									</span> */}
									<img src={url} alt="" height="29" />
								</Link>
							</div>
						</div>

						<div className="header-right">
							<div className="d-flex align-items-center justify-content-start headerScroll">
								{headerLinks.map((row, index) => {
									if (
										row?.checkEnabledModules &&
										(process.env.REACT_APP_MODULE_ENABLE_SPORT === "0" || process.env.REACT_APP_MODULE_ENABLE_RACING === "0") &&
										(row.link === "/bets" || row.link === "/bet-ticker")
									) {
										return null;
									}
									if (!row.roleToHide || (user && !row?.roleToHide?.includes(user?.role))) {
										return <HeaderButton name={row.name} link={row.link} icon={row.icon} tToggle={tToggle} key={index} active={row.active} />;
									} else return null;
								})}
							</div>
							{showMore && (
								<div className="headerScroll headerScroll-mobile headerScrollCustomized">
									{filteredMobileLinks
										.filter((_, i) => i >= 4)
										.map((row, index) => {
											return <HeaderButton name={row.name} link={row.link} icon={row.icon} tToggle={tToggle} key={index} active={row.active} />;
										})}
								</div>
							)}
							<div className={showMore ? "headerScroll headerScroll-mobile headerScrollCustomizedShadow" : "headerScroll headerScroll-mobile"}>
								{filteredMobileLinks
									.filter((_, i) => i < 4)
									.map((row, index) => {
										return <HeaderButton name={row.name} link={row.link} icon={row.icon} tToggle={tToggle} key={index} active={row.active} />;
									})}

								{filteredMobileLinks?.length > 4 && (
									<div className="show-more headerMenuStyle mx-2 px-0">
										<div className="header-page-link" onClick={() => setShowMore(!showMore)}>
											<ThreeDotsIcon /> <span>{showMore ? "Less" : "More"}</span>
										</div>
									</div>
								)}
							</div>

							<div className={`burger-icon`} onClick={openSideBar}>
								<HamburgerIcon />
							</div>

							<div className="d-flex header-profile">
								<HeaderNotification />
								<ProfileMenu />
							</div>
						</div>
					</div>
				</div>
			</header>
		</React.Fragment>
	);
};

Header.propTypes = {
	changeSidebarType: PropTypes.func,
	leftMenu: PropTypes.any,
	leftSideBarType: PropTypes.any,
	showRightSidebar: PropTypes.any,
	showRightSidebarAction: PropTypes.func,
	t: PropTypes.any,
	toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
	const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
	return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
	showRightSidebarAction,
	toggleLeftmenu,
	changeSidebarType,
})(Header);
