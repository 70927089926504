import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { apiUrls } from "utils/const.apiUrl";
import { getUserListTableColumns } from "./userListTableColumns";
import { ShowAddUserListModal } from "./showAddUserListModal";
import { ShowDeleteUserListModal } from "./showDeleteUserListModal";
import { EditUserListModal } from "./editUserListModal";
import { ShowConfirmDisableMFA } from "./showConfirmDisableMFAModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { ShowConfirmBlockUser } from "./showConfirmBlockUser";
import ApiServices from "utils/ApiServices";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { capitalizeText } from "services/capitalizeText";
import xIcon from "../../../assets/images/x-icon.svg";
import { signOutAndClearStorage } from "helpers/api_helper";

import { cmsLoginUser } from "store/actions";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const UsersList = props => {
	const dispatch = useDispatch();

	const [userList, setUserList] = useState([]);
	const [blocked, setBlocked] = useState(false);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [emailBlock, setEmailBlock] = useState("");
	const [resetEmail, setResetEmail] = useState("");
	const [currentPage, setCurrentPage] = useState(0);
	const [blockModal, setBlockModal] = useState(false);
	const [userFullName, setUserFullName] = useState({});
	const [editClicked, setEditClicked] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [deleteUserEmail, setDeleteUserEmail] = useState("");
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [spinningStatusMfa, setSpinningStatusMfa] = useState(false);
	const [confirmDisableMFA, setConfirmDisableMFA] = useState(false);
	const breadcrumbItems = [{ title: "User List", link: "/user_list" }];
	const [resetPasswordSpinner, setResetPasswordSpinner] = useState(false);

	const user = JSON.parse(localStorage.getItem("user"));

	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		role: "",
		status: "",
		timezone: "",
		timezone_name: "",
	});

	let records = [];

	const getUserListData = async () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.new_main + "/api/v1/auth/user-list", resolve, reject, true);
		})
			.then(response => {
				if (response) {
					let data = response.data[0];
					var userArray = [];
					data.map(user => {
						let userObj = {
							id: user.id,
							email: user?.email,
							first_name: capitalizeText(user?.first_name),
							last_name: capitalizeText(user?.last_name),
							role: user?.role == "customer_service" ? "Customer Service" : capitalizeText(user?.role),
							original_role: user?.role,
							mfa_status: user?.mfa_status,
							timezone: user?.timezone,
							timezone_name: user?.timezone_name,
							blocked: user?.blocked,
							setup_mfa: user?.setup_mfa,
						};
						records.push(userObj);
						userArray.push(userObj);
					});
					setUserList(userArray);
					setUnfilteredData(userArray);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const handleSwitchStatus = () => {
		let body = {
			statuse: data?.status,
			id: data.id,
			username: data.email,
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.cms_user_mfa, resolve, reject, body, true);
		}).then(() => {
			if (body.statuse == 1 && user.id == body.id) signOutAndClearStorage({ wait: true });
		});

		getUserListData();
	};

	const changeMFAStatus = async body => {
		if (!body.statuse) {
			// setConfirmDisableMFA(true);
			return;
		} else {
			if (!body.statuse) {
				setupMFA();
			}

			return;
		}
	};

	const setupMFA = () => {
		setSpinningStatusMfa(true);
		var token = localStorage.getItem("access_token");
		var body = {
			access_token: token,
		};
		// new global axios helper
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.new_main + "/api/v1/auth/setup-mfa", resolve, reject, body, true);
		})
			.then(() => {})
			.finally(() => {
				setSpinningStatusMfa(false);
			});
	};

	// Add users from cms
	const addUsers = userFields => {
		if (!data.email) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please write first name !</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}
		if (!data.firstName) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please write last name !</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}
		if (!data.lastName) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please write email !</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}

		let body = {
			email: data.email,
			first_name: data.firstName,
			last_name: data.lastName,
			role: data.role,
			mfa_status: data?.status,
			offset: data?.offset || data.timezone,
			timezone_name: data.timezone_name?.id || data.timezone_name,
		};
		setIsDataLoading(true);
		// new global axios helper
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.cms_user_add, resolve, reject, body, true, userFields);
		})
			.then(response => {
				setDataChanged(false);
				if (response?.success) {
					let data = response.data.returnData.allData;
					setUserList(data);
					getUserListData();
					setDataChanged(false);
					setShowAddModal(false);
					setIsDataLoading(false);
					localStorage.removeItem("cmsUsers");
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	//Sign out user from cms
	const signOutUser = () => {
		const payload = { email: data?.email };

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.sign_out_user, resolve, reject, payload, true);
		}).then(() => {});
	};

	// Delete users from cms
	const deleteUser = () => {
		setIsDataLoading(true);
		setShowDeleteModal(false);

		// new global axios helper
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.cms_user_delete + "?email=" + encodeURIComponent(deleteUserEmail), resolve, reject, true);
		})
			.then(() => {
				let emailCheck = localStorage.getItem("email");
				localStorage.removeItem("cmsUsers");

				if (deleteUserEmail == emailCheck) {
					setTimeout(function () {
						props.history.push("/login");
						localStorage.removeItem("access_token");
						localStorage.removeItem("refresh_token");
					}, 300);
				} else {
					setTimeout(function () {
						location.reload();
					}, 2000);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	// Edit users data from cms
	const updateUser = fields => {
		setIsDataLoading(true);

		let body = {
			...data,
			first_name: data.firstName,
			last_name: data.lastName,
			timezone: data.timezone_name?.offset || data.timezone,
			timezone_name: data.timezone_name?.id || data.timezone_name,
		};

		// new global axios helper
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.cms_user_edit, resolve, reject, body, true, fields);
		})
			.then(() => {
				getUserListData();
				setEditClicked(false);
				setShowEditModal(false);
				localStorage.removeItem("cmsUsers");

				if (user?.id == data?.id) {
					const updatedUser = {
						...user,
						role: data?.role,
						first_name: data.firstName,
						last_name: data.lastName,
						timezone: data.timezone_name?.offset || data.timezone,
						timezone_name: data.timezone_name?.id || data.timezone_name,
					};

					getUserList(updatedUser.timezone_name, updatedUser);

					if (user?.role !== data?.role) {
						setTimeout(() => {
							location.reload();
						}, 200);
					}
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});

		const userFound = userList.find(user => user.id === data?.id);
		if (userFound?.mfa_status != data?.status) {
			handleSwitchStatus();
		}
	};

	const getUserList = (timezone_name, user) => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.new_main + "/api/v1/auth/get-user", resolve, reject, true);
		}).then(response => {
			const { data } = response;
			if (user) {
				user.offset = data?.offset;
				user.timezone_name = timezone_name;

				dispatch(cmsLoginUser(user));
				localStorage.setItem("user", JSON.stringify(user));
			}
		});
	};

	//Reset 2FA
	const handleReset2FA = () => {
		if (data?.email) {
			let body = { id: data?.id, username: data?.email };
			new Promise((resolve, reject) => {
				ApiServices.post(apiUrls.cms_user_mfa_reset, resolve, reject, body, true);
			})
				.then(() => {
					setEditClicked(false);
					getUserListData();
					if (user.id == body.id) signOutAndClearStorage({ wait: true });
				})
				.finally(() => {
					setIsDataLoading(false);
				});
		}
	};

	if (userList) {
		records = userList;
	}

	useEffect(() => {
		getUserListData();
		props.setBreadcrumbItems("User List", breadcrumbItems);
	}, []);

	//Reset password for user from cms
	const handleResetPassword = () => {
		setResetPasswordSpinner(true);
		if (data.email) {
			setResetEmail(data?.email);
			new Promise((resolve, reject) => {
				ApiServices.post(apiUrls.cms_user_password_reset, resolve, reject, data, true);
			})
				.then(() => {
					localStorage.removeItem("access_token");
					localStorage.removeItem("refresh_token");
					setTimeout(function () {
						props.history.push("/reset_password");
					}, 1500);
				})
				.finally(() => {
					setResetPasswordSpinner(false);
				});
		}
	};

	// Block user from cms
	const handleBlockUser = () => {
		setBlockModal(false);
		setIsDataLoading(true);
		const payload = {
			email: emailBlock,
			blocked: blocked,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.cms_user_block, resolve, reject, payload, true);
		})
			.then(() => {
				const updatedUserList = userList.map(user => {
					if (user.email === emailBlock) {
						return {
							...user,
							blocked: user.blocked === 0 ? 1 : 0,
						};
					}
					return user;
				});
				setUserList(updatedUserList);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const handleGetUserData = record => {
		setEditClicked(true);
		setData({
			id: record.id,
			firstName: record.first_name,
			lastName: record.last_name,
			email: record.email,
			status: record.setup_mfa ? record?.setup_mfa : record.mfa_status,
			role: record.original_role,
			timezone: record.timezone,
			timezone_name: record.timezone_name,
		});
	};

	let userListTableColumns = getUserListTableColumns({
		records,
		handleGetUserData,
		setSpinningStatusMfa,
		spinningStatusMfa,
		changeMFAStatus,
		setShowEditModal,
		setShowDeleteModal,
		setDeleteUserEmail,
		handleResetPassword,
		resetPasswordSpinner,
		resetEmail,
		setBlocked,
		setBlockModal,
		setEmailBlock,
		blocked,
		setShowAddModal,
		setUserFullName,
	});

	const handleShowAddModal = () => {
		setShowAddModal(true);
		setData({
			firstName: "",
			lastName: "",
			email: "",
			role: "",
			timezone: "",
			timezone_name: "Europe/London",
			status: 1,
		});
	};
	return (
		<HelmetLayout titleProps={"User List"}>
			{showAddModal && (
				<ShowAddUserListModal
					setShowAddModal={setShowAddModal}
					addUsers={addUsers}
					data={data}
					setData={setData}
					dataChanged={dataChanged}
					setDataChanged={setDataChanged}
					loading={isDataLoading}
				/>
			)}

			{showDeleteModal && <ShowDeleteUserListModal setShowDeleteModal={setShowDeleteModal} deleteUser={deleteUser} userFullName={userFullName} />}

			<EditUserListModal
				updateUser={updateUser}
				data={data}
				setData={setData}
				editClicked={editClicked}
				dataChanged={dataChanged}
				setDataChanged={setDataChanged}
				handleReset2FA={handleReset2FA}
				handleResetPassword={handleResetPassword}
				signOutUser={signOutUser}
				showEditModal={showEditModal}
				setShowEditModal={setShowEditModal}
				loading={isDataLoading}
			/>

			{confirmDisableMFA ? <ShowConfirmDisableMFA setConfirmDisableMFA={setConfirmDisableMFA} setSpinningStatusMfa={setSpinningStatusMfa} /> : ""}
			{blockModal ? <ShowConfirmBlockUser blockUser={handleBlockUser} blocked={blocked} setBlockModal={setBlockModal} userFullName={userFullName} /> : ""}
			<div className="cms-page pt-0">
				<SingleSearchFilter
					setData={setUserList}
					unfilteredData={unfilteredData}
					filterColumn={["first_name", "last_name", "role", "email", "mfa_status"]}
				/>
				<MUIDataTable
					className="mui-data-table-background"
					columns={userListTableColumns}
					data={userList}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						sortOrder: {
							name: "first_name",
							direction: "asc",
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: "CMS Users.csv" },
						customToolbar: () => <AddTableButton onClick={() => handleShowAddModal()} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};
export default connect(null, { setBreadcrumbItems })(UsersList);
