import React from "react";
import { EditIconButton, LanguageShortcuts } from "components/GeneralComponents/AddButton";
import { getFormatDateByUserTimezone } from "utils/global";

export const getTermsTableColumns = ({ props, records }) => {
	return [
		{
			name: "version",
			label: "VERSION",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "country",
			label: "COUNTRY",
			className: "status_name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "updatedDt",
			label: "DATE UPDATED",
			className: "region",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = records[meta.currentTableData[meta.rowIndex].index];
					const startDate = getFormatDateByUserTimezone(row?.updatedDt, "YYYY-MM-DD HH:mm:ss");
					return <>{startDate}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),
			},
		},
		{
			name: "language",
			label: "LANGUAGES",
			className: "gender",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const languages = Array.isArray(record.languages) ? record.languages : [];

					if (languages.length > 1) {
						return (
							<div style={{ display: "flex", gap: "5px" }}>
								{languages.map((language, index) => (
									<LanguageShortcuts key={index} language={!language ? "en" : language.slice(0, 2).toUpperCase()} />
								))}
							</div>
						);
					} else {
						const language = Array.isArray(record.languages) ? record.languages[0] : record.languages;
						return (
							<>
								<LanguageShortcuts language={!language ? "en" : language.slice(0, 2).toUpperCase()} />
							</>
						);
					}
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),
			},
		},
		{
			name: "status",
			label: "STATUS",
			className: null,
			align: "left",
			sortable: false,
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const countryDisabled = !!record.country_disabled;

					return countryDisabled ? (
						<span className="badge badge-sports-inactive"> Disabled </span>
					) : (
						<span className="badge badge-sports-published"> Active </span>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px", width: "100%" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" }, className: "max-button" }),
			},
		},
		{
			name: "",
			className: "edit",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				viewColumns: false,
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditIconButton
									onClick={() => {
										props.history.push(`/term/${record.id}`);
									}}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" } }),
			},
		},
	];
};
