import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let result = [];
let prevSportSlug = "";

export const getSportTiersDropdown = async sport_slug => {
	if (prevSportSlug === sport_slug) return result;

	if (sport_slug) {
		return new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_tiers + `?sport_slug=${sport_slug}`, resolve, reject, true);
		})
			.then(response => {
				let data = response?.data;
				data = data.map(row => {
					return { id: row.id, label: row.name };
				});
				data.unshift({ id: "0", label: "Default Tier" });

				result = data;
				prevSportSlug = sport_slug;

				return data;
			})
			.catch(() => {
				result = [];
				return [];
			});
	}
};
