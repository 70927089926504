import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { tableResponsive, tableRowsPerPage } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { getPendingWithdrawals } from "./pendingWithdrawalsTable";
import { getFreeBets } from "./freeBetsTable";
import { getAdjustmentTable } from "./adjustmentsTable";
import { FreeBetModal } from "./freeBetModal";
import { AdjustmentModal } from "./adjustmentModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmDeleteComponent from "components/GeneralComponents/ConfirmDeleteComponent";
import DynamicTable from "./DynamicTable";
import { platformName } from "utils/constants";
import ConfirmModal from "./ConfirmModal";
import { userReadOnly } from "utils/global";

export const BankingUser = props => {
	const { userStatsData, setUserStatsData, playerId } = props;
	const [bankingUser, setBankingUser] = useState({
		pending_withdrawal: [],
		manual_adjustment: [],
	});
	const [freeBetsData, setFreeBetsData] = useState([]);
	const [modalShowing, setModalShowing] = useState({ value: false, type: "" });
	const [freeBetModal, setFreeBetModal] = useState(false);
	const [usedModal, setUsedModal] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [itemTitle, setItemTitle] = useState("");
	const [deleteItem, setDeleteItem] = useState(null);

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	let pendingWithdrawalData = bankingUser.pending_withdrawal;
	let manualAdjustmentData = bankingUser.manual_adjustment;

	const handleEdit = (row, type) => {
		if (type === "edit") setFreeBetModal({ isEdit: true, id: row.id, subId: props.subId });
		else setUsedModal({ id: row.id, paragraf: "Are you sure you want to cancel this free bet?" });
	};

	const pendingWithdrawals = getPendingWithdrawals({ bankingPendingWithdrawal: pendingWithdrawalData, setDeleteItem, setItemTitle });
	const freeBet = getFreeBets({ bankigFreeBetsActiveData: freeBetsData, handleEdit, isUserReadOnly });
	const adjustmentsTable = getAdjustmentTable({ bankingManualAdjustmentData: manualAdjustmentData });

	const bankingUsers = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_BANKING_DATA + props.subId, resolve, reject, true);
		}).then(response => {
			if (response?.success) {
				const { pending_withdrawal, manual_adjustment } = response?.data;
				setBankingUser({
					pending_withdrawal: pending_withdrawal,
					manual_adjustment: manual_adjustment,
				});
			}
		});
	};

	const getFreeBetsData = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_FREE_BETS + props.subId, resolve, reject, true);
		}).then(response => {
			if (response?.success) {
				const { data } = response;
				setFreeBetsData(data);
			}
		});
	};

	const cancelFreeBets = () => {
		setDeleteLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.CANCEL_FREE_BET}${usedModal.id}/cancel`, resolve, reject, true);
		})
			.then(response => {
				if (response?.success) {
					const { data } = response;
					const newData = freeBetsData.map(row => (row.id === data.id ? data : row));
					setFreeBetsData(newData);
				}
			})
			.finally(() => {
				setDeleteLoading(false);
				setUsedModal(false);
			});
	};

	const history = useHistory();
	const redirectToReports = () => {
		history.push(`/reports?slug=financial-report&player_id=${playerId}`);
	};

	const redirectToActiveFreeBetReports = () => {
		history.push(`/reports?slug=free-bets-report&player_id=${playerId}`);
	};

	useEffect(() => {
		bankingUsers();
		getFreeBetsData();
	}, []);

	const MUIOptions = {
		search: false,
		print: false,
		viewColumns: false,
		download: false,
		filter: false,
		pagination: false,
		selectableRows: "none",
		elevation: 0,
		responsive: tableResponsive,
		rowsPerPage: 50,
		rowsPerPageOptions: tableRowsPerPage,
	};

	const tableProperties = [
		{
			title: "Pending Withdrawals",
			data: pendingWithdrawalData,
			columns: pendingWithdrawals,
			options: {
				...MUIOptions,
				downloadOptions: { filename: "Deposit Limits - Transaction.csv" },
				textLabels: {
					body: {
						noMatch: " No data to display!",
					},
				},
			},
		},
		{
			title: "Active Free Bets",
			columns: freeBet,
			data: freeBetsData,
			options: {
				...MUIOptions,
				downloadOptions: { filename: "Report - Transaction.csv" },
				textLabels: {
					body: {
						noMatch: " No data to display!", // Custom message for no matching records
					},
				},
			},
			//For now leave this as commented
			modalButtons: [
				{
					buttonName: "Add Free Bet",
					onClickEvent: () => setFreeBetModal({ isEdit: false, subId: props.subId }),
				},
				{
					buttonName: "View All",
					onClickEvent: redirectToActiveFreeBetReports,
				},
			],
		},
		{
			title: "Banking Adjustments (30 Days)",
			columns: adjustmentsTable,
			data: manualAdjustmentData,
			options: {
				...MUIOptions,
				downloadOptions: { filename: "Report - Transaction.csv" },
				textLabels: {
					body: {
						noMatch: " No data to display!", // Custom message for no matching records
					},
				},
			},
			modalButtons: [
				{
					buttonName: "Adjustment",
					onClickEvent: () => setModalShowing({ value: true, type: "adjustments" }),
				},
				{
					buttonName: "View All",
					onClickEvent: redirectToReports,
				},
			],
			className: "adjusment_header_description",
		},
	];

	return (
		<React.Fragment>
			<MetaTags>
				<title>Banking - {platformName}</title>
			</MetaTags>
			<div className="col-12 filterResponsive" style={{ justifyContent: "space-between" }}>
				<div className="col-10 d-flex"></div>
			</div>
			<div className="row">
				<div className="col-12 p-0 px-3">
					{tableProperties.map((row, index) => (
						<DynamicTable object={row} key={index} />
					))}

					{freeBetModal && (
						<FreeBetModal
							handler={() => setFreeBetModal(false)}
							getFreeBetsData={getFreeBetsData}
							freeBetModal={freeBetModal}
							userCurrency={userStatsData?.currency}
						/>
					)}
					{usedModal && (
						<ConfirmModal
							title={"Active Free Bets"}
							paragraf={usedModal.paragraf}
							isLoading={deleteLoading}
							handlerClose={() => setUsedModal(false)}
							handlerSave={cancelFreeBets}
						/>
					)}

					{modalShowing?.type == "adjustments" && (
						<AdjustmentModal
							handler={() => setModalShowing(false)}
							setModalShowing={setModalShowing}
							data={userStatsData}
							setData={setUserStatsData}
							props={props}
							bankingUsers={bankingUsers}
						/>
					)}
				</div>
				{deleteItem && (
					<ConfirmDeleteComponent
						showDeleteCom={deleteItem}
						deleteLoading={deleteLoading}
						setDeleteLoading={setDeleteLoading}
						deleteTitle={"Cancel Withdrawal"}
						titlename={itemTitle}
						setShowDeleteCom={setDeleteItem}
						bankingUser={bankingUser}
						setBankingUser={setBankingUser}
						id={deleteItem?.id}
						type="pendingWithdrawal"
					/>
				)}
			</div>
		</React.Fragment>
	);
};
