import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import xIcon from "../../assets/images/x-icon.svg";
import { signOutAndClearStorage } from "helpers/api_helper";
import { passwordRequirementsCheck } from "utils/global";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { BackIcon } from "utils/icons";

import "./OddsDisplay.scss";

function ChangePassword() {
	const [showPassword, setShowPassword] = useState(false);
	const [newShowPassword, setNewShowPassword] = useState(false);
	const [showConfirmPassword, setConfirmShowPassword] = useState(false);
	const [data, setData] = useState({
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	});
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const [requirementStatus, setRequirementStatus] = useState({
		length: false,
		specialCharacter: false,
		number: false,
	});

	const [dataChanged, setDataChanged] = useState(false);

	const handleDataChanged = () => {
		if (data.oldPassword.trim().length > 0 && data.newPassword.trim().length > 0 && data.confirmPassword.trim().length > 0) {
			setDataChanged(true);
		} else {
			setDataChanged(false);
		}
	};

	useEffect(() => {
		handleDataChanged();
	}, [data.oldPassword, data.newPassword, data.confirmPassword]);

	useEffect(() => {
		const passwordRequirments = passwordRequirementsCheck(data?.newPassword);
		setRequirementStatus(passwordRequirments);
	}, [data]);

	const handleValidSubmit = () => {
		if (data.oldPassword === data.newPassword) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Password can&apos;t be the same as the old password!</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		} else if (data.newPassword !== data.confirmPassword) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Passwords do not match</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		const token = localStorage.getItem("access_token");
		let payload = {
			accessToken: token,
			oldPassword: data.oldPassword,
			newPassword: data.newPassword,
		};
		setLoading(true);

		// new global axios helper
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.cms_user_password_change, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) {
					setData({
						oldPassword: "",
						newPassword: "",
						confirmPassword: "",
					});
					setRequirementStatus({
						length: false,
						specialCharacter: false,
						number: false,
					});

					signOutAndClearStorage({ wait: true });
				}
			})
			.finally(() => {
				setDataChanged(false);
				setLoading(false);
			});
	};

	const eyeIcon = showPassword ? faEyeSlash : faEye;
	const eyeNewIcon = newShowPassword ? faEyeSlash : faEye;
	const eyeConfirmIcon = showConfirmPassword ? faEyeSlash : faEye;

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const toggleNewPasswordVisibility = () => {
		setNewShowPassword(!newShowPassword);
	};

	const toggleConfirmPasswordVisibility = () => {
		setConfirmShowPassword(!showConfirmPassword);
	};

	return (
		<HelmetLayout titleProps={"Change Password"}>
			<div className="cms-page profile-container cms_page_settings ">
				<TopBarComponent
					classname="special_top_component"
					saveHandler={() => handleValidSubmit()}
					dataChanged={dataChanged}
					loading={loading}
					dontDisable={true}
					onClose={() => history.goBack()}
				/>
				<div className="promotion-elements row m-0">
					<div className="col-12 col-lg-5 promotion-inputs special_promotion_pages profile-page">
						{/* <div className="show-mobile-side">
							<BackIcon onClick={() => history.goBack()} />
						</div> */}
						<h4 className="profile-title">Change Password</h4>
						<AvForm className="form-horizontal change-password-form">
							<div className="login-label second-current-label">
								<div className="password-input-wrapper">
									<label className="change-label current-label">Current Password</label>
									<div className="change-input">
										<AvField
											name="oldPassword"
											type={showPassword ? "text" : "password"}
											placeholder="Current Password"
											value={data?.oldPassword}
											onInput={e => {
												e.preventDefault();
											}}
											onChange={e => {
												setData({
													...data,
													oldPassword: e.target.value,
												});
											}}
										/>
										<span className="change-password-toggle-icon" onClick={togglePasswordVisibility}>
											<FontAwesomeIcon icon={eyeIcon} />
										</span>
									</div>
								</div>
							</div>
							<div className="login-label">
								<div className="password-input-wrapper">
									<label className="change-label new-label">New Password</label>
									<div className="change-input">
										<AvField
											name="newPassword"
											placeholder="New Password"
											type={newShowPassword ? "text" : "password"}
											value={data?.newPassword}
											onInput={e => {
												e.preventDefault();
											}}
											onChange={e => {
												setData({
													...data,
													newPassword: e.target.value,
												});
											}}
										/>
										<span className="change-password-toggle-icon" onClick={toggleNewPasswordVisibility}>
											<FontAwesomeIcon icon={eyeNewIcon} />
										</span>
									</div>
								</div>
							</div>
							<div className="change-password-requirements">
								<ul className="change-ul">
									<li className={`change-li ${requirementStatus.length ? "blue-dot" : ""}`}>Must be at least 8 characters</li>
									<li className={`change-li ${requirementStatus.specialCharacter ? "blue-dot" : ""}`}>Must include a special character</li>
									<li className={`change-li ${requirementStatus.number ? "blue-dot" : ""}`}>Must include a number</li>
								</ul>
							</div>
							<div className="password-input-wrapper">
								<label className="change-label new-label">Confirm Password</label>
								<div className="change-input">
									<AvField
										name="confirmPassword"
										placeholder="Confirm Password"
										type={showConfirmPassword ? "text" : "password"}
										value={data?.confirmPassword}
										onInput={e => {
											e.preventDefault();
										}}
										onChange={e => {
											setData({
												...data,
												confirmPassword: e.target.value,
											});
										}}
									/>
									<span className="change-password-toggle-icon" onClick={toggleConfirmPasswordVisibility}>
										<FontAwesomeIcon icon={eyeConfirmIcon} />
									</span>
								</div>
							</div>
							<div className="change-password-requirements">
								<ul className="change-ul">
									<li className={`change-li ${data?.newPassword === data?.confirmPassword && data?.confirmPassword !== "" ? "blue-dot" : ""}`}>
										Both passwords must match
									</li>
								</ul>
							</div>
						</AvForm>
					</div>
				</div>
			</div>
		</HelmetLayout>
	);
}
export default ChangePassword;
