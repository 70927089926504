import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import TextareaAutosize from "react-textarea-autosize";
import { setBreadcrumbItems } from "../../../store/actions";
import { CancelButton } from "components/GeneralComponents/AddButton";
import { XCloseIcon, CopyIcon } from "./../../../utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { AvForm } from "availity-reactstrap-validation";
import { emailTemplateVariables } from "utils/constants";

import "./style.scss";

const editorApiUrl = process.env.REACT_APP_EDITOR_API_KEY;
const emailTemplate = props => {
	const { handleSendEmail, getEmailTemplates, setShowEditModal, setEditRowModal, editRowModal } = props;
	const [emailTemplate, setEmailTemplate] = useState({});
	const editorRef = useRef(null);
	const [emailData, setEmailData] = useState([]);
	const [languageOptions, setLanguageOptions] = useState([]);
	const [unchangedData, setUnchangedData] = useState({});
	const [template, setTemplate] = useState();
	const [templateId, setEmailTemplateId] = useState();
	const [clipboardMessage, setClipboardMessage] = useState({});
	const [languageName, setLanguageName] = useState("");
	const [loading, setLoading] = useState(false);

	const sortedEmailTemplateVariables = emailTemplateVariables.sort((a, b) => a.localeCompare(b));

	const filterEmailTemplates = () => {
		const id = props?.editRowModal?.id;
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.email_template + "?id=" + id, resolve, reject, true);
		}).then(response => {
			if (response.success) {
				let data = response?.data[0];
				if (data) {
					setEditRowModal({ ...editRowModal, key: data?.value });
					setEmailTemplate(data);
					setUnchangedData(data);
					setEmailTemplateId(data?.id);
					setLanguageName(data.language);
				}
			}
		});
	};

	const [dataChanged, setDataChanged] = useState(false);
	const breadcrumbItems = [{ title: "Email template", link: "/activity_log" }];

	const discardChanges = () => {
		setEmailTemplate(unchangedData);
	};

	useEffect(() => {
		if (emailTemplate[0]?.translation || emailTemplate !== emailTemplate) {
			setTemplate(emailTemplate[0]?.translation);
		} else {
			setTemplate(emailTemplate?.template || "");
		}
	}, [emailTemplate]);

	const handleDataChanged = language => {
		if (language) {
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls.email_template + `?language=${language?.id.toLowerCase()}&value=${unchangedData?.value}`, resolve, reject, true);
			}).then(response => {
				const { data } = response;
				const page = data[data.length - 1];
				setEmailTemplate(page || "");
				setEmailTemplateId(data.length ? page?.id : "");
			});
		}
		setDataChanged(true);
	};

	useEffect(() => {
		props.setBreadcrumbItems("Email template", breadcrumbItems);
		filterEmailTemplates();
	}, []);

	const handleChange = (value, id, elementType) => {
		let actualData = [];
		actualData = [...emailData];
		var editedElement = actualData.filter(row => row.id === id);
		var editedIndex = actualData.findIndex(row => row.id === id);
		if (editedElement && editedElement.length > 0) {
			editedElement[0][elementType] = value;
			editedElement[0].edited = true;
			actualData[editedIndex] = editedElement[0];
		}
		setEmailData(actualData);
	};

	const getLanguageDropdown = async () => {
		return new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_languages, resolve, reject, true);
		}).then(response => {
			let languages = response?.data;
			languages = languages
				.map(row => {
					if (row?.in_use === true) {
						return { id: row.code2, label: row.language_name };
					}
					return null;
				})
				.filter(language => language !== null);
			return languages;
		});
	};

	const editEmailTemplateFunc = () => {
		setLoading(true);
		const editorContent = editorRef.current.getContent();

		let payload = {
			...emailTemplate,
			template: editorContent,
			language: languageName?.id?.toLowerCase(),
		};

		if (!emailTemplate) {
			delete payload["id"];
		}
		if (emailTemplate?.id !== templateId) {
			payload["id"] = templateId;
		} else {
			payload["id"] = emailTemplate?.id;
		}
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.email_template_edit, resolve, reject, payload, true);
		})
			.then(response => {
				setDataChanged(false);
				if (response.success) {
					getEmailTemplates();
					setShowEditModal(false);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};
	let title = "Email template";

	const handleTextChanged = () => {
		setUnchangedData({ ...unchangedData, template: editorRef.current?.getContent() });
	};

	async function fetchLanguageOptions() {
		const languageOptions = await getLanguageDropdown();
		setLanguageOptions(languageOptions);
	}

	useEffect(() => {
		fetchLanguageOptions();
	}, []);

	useEffect(() => {
		const selectedLanguage = languageOptions.find(lang => lang.id?.toLowerCase() === languageName);
		if (selectedLanguage) {
			setLanguageName(selectedLanguage);
		}
	}, [languageName, languageOptions]);

	return (
		<div className="promotion-container" style={{ overflowY: "auto" }}>
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon
					className={"xclose-icon"}
					onClick={() => {
						setShowEditModal(false);
					}}
				/>
			</div>
			<div className="promotion-body promotion-body-email-template">
				<TopBarComponent
					discardHandler={discardChanges}
					saveHandler={editEmailTemplateFunc}
					dataChanged={dataChanged}
					loading={loading}
					onClose={() => setShowEditModal(false)}
				/>

				<div className="promotion-elements row email-template-container">
					<AvForm id="emailTemplateForm">
						<div className="row mb-3 email-template-av-form">
							<div className="title_content mb-2 col-4 title_content_email_template" style={{ paddingLeft: "0" }}>
								<label className="page_content_label page_content_label_email_template">Subject*</label>
								<input
									type="text"
									className="form-control col-9 "
									placeholder=""
									onChange={e => {
										handleDataChanged();

										setEmailTemplate({ ...emailTemplate, subject: e.target.value });
									}}
									value={emailTemplate?.subject}
								/>
							</div>

							<div className="title_content mb-2 col-4 title_content_email_template">
								<label className="page_content_label page_content_label_email_template">
									Text* <span className="optional-email">(optional)</span>
								</label>
								<TextareaAutosize
									className={"form-control col-8"}
									placeholder=""
									minRows={2}
									onChange={e => {
										handleDataChanged();
										setEmailTemplate({ ...emailTemplate, template_text: e.target.value });
									}}
									value={emailTemplate?.template_text}
								/>
							</div>
							<div className="title_content mb-2 col-2 px-0 title_content_email_template">
								{languageName?.id === "EN" || languageName === "en" ? (
									""
								) : (
									<LanguageTitle data={unchangedData} setData={setEmailTemplate} title={"template"} translateTo={[languageName?.id?.toLowerCase()]} />
								)}
								<GeneralDropdown
									handleChange={e => {
										handleDataChanged(e);
										setLanguageName(e?.id.toLowerCase());
										handleChange(e.id, "dropdown", templateId);
									}}
									object={{
										type: "dropdown",
										name: "Language",
										fieldName: "language",
										options: languageOptions,
										value: languageName?.id,
										label: languageName.label,
										required: true,
										placeholder: "Choose Language",
										addUser: true,
										property: "page_content",
										dropdownClassName: "page_content_label page_content_label_email_template",
									}}
								/>
							</div>
						</div>

						<div className="email-template-sub-content">
							<div className="email-template-variables">
								<div className="title">List of Variables</div>
								<div className="variables">
									{sortedEmailTemplateVariables.map((variable, i) => (
										<div key={i} className="variable-name">
											<h3 dangerouslySetInnerHTML={{ __html: `{${variable.replace(/_/g, "_<wbr/>")}}` }} />

											<div
												onClick={() => {
													navigator.clipboard.writeText(`{${variable}}`);
													setClipboardMessage({ message: "Copied", index: i });
													setTimeout(() => {
														setClipboardMessage(false);
													}, 2500);
												}}
												className="cursor-pointer"
											>
												<CopyIcon active />
											</div>

											{clipboardMessage?.message && clipboardMessage?.index === i && (
												<div className="clip_board_email_template">{clipboardMessage?.message}</div>
											)}
										</div>
									))}
								</div>
							</div>

							<div className="email-template-editor">
								<Editor
									apiKey={editorApiUrl}
									onInit={(evt, editor) => (editorRef.current = editor)}
									initialValue={template}
									onChange={e => {
										handleChange(e.level.template, templateId, "editor_text");
										handleTextChanged(e.level.template);
									}}
									init={{
										height: 650,
										menubar: false,
										visual: false,
										plugins: [
											"advlist",
											"autolink",
											"lists",
											"link",
											"image",
											"charmap",
											"preview",
											"anchor",
											"searchreplace",
											"visualblocks",
											"code",
											"fullscreen",
											"insertdatetime",
											"media",
											"table",
											"code",
											"help",
											"wordcount",
										],
										toolbar:
											"undo redo | blocks | " +
											"bold italic forecolor link | alignleft aligncenter " +
											"alignright alignjustify | bullist numlist outdent indent | " +
											"removeformat | help | code",
										content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
									}}
									onEditorChange={() => {
										handleDataChanged();
									}}
								/>
							</div>
						</div>

						<div className="cancel_container_email_template">
							<CancelButton onClick={handleSendEmail} name="Send Test Email" />
						</div>
					</AvForm>
				</div>
			</div>
		</div>
	);
};

export default connect(null, { setBreadcrumbItems })(emailTemplate);
