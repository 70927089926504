import React, { useRef, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { useEffect } from "react";
import { checkGenericUrl } from "utils/global";
import { languageToString } from "utils/global";

export const MenuLinksModal = props => {
	const { handler, editItem, title, setNewData, getMenuLinks } = props;
	let details = null;
	if (editItem) {
		if (editItem?.details) {
			details = JSON.parse(editItem?.details);
		}
		if (details && Object.keys(details).length === 0 && details.constructor === Object) {
			details["link_type"] = "layout";
		}
	}
	const [loading, setLoading] = useState(false);
	const [menuLink, setMenuLink] = useState({
		...details,
		id: editItem ? editItem?.id : "",
		image: editItem ? editItem?.image : "",
		image_exist: editItem ? true : false,
		title: editItem ? editItem.link_name : "",
		pageName: editItem ? editItem?.page_name : "",
		open_type: editItem ? editItem?.open_type : "same_tab",
		sport_id: editItem ? details?.sport_slug : "",
		competition_id: editItem ? details?.competition_raw_id : "",
		event_id: editItem ? details?.event_id : "",
		fav_icon: "",
		link_type: editItem ? details?.link_type : "",
		link: editItem ? editItem?.link : "",
		link_name: editItem ? editItem?.link_name : "",
		call_to_action: editItem ? details?.call_to_action : "",
		show_menu: editItem ? editItem?.show_menu : "",
		published: editItem ? editItem?.published : "",
		seo_image: editItem ? details?.seo_image : "",
		seo_image_exist: editItem ? details?.seo_image_exist : false,
		seo_title: editItem ? details?.seo_title : "",
		seo_description: editItem ? details?.seo_description : "",
		game_id: editItem ? details?.game_id : "",
	});

	const [fields, setfields] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);

	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const changeLinkData = () => {
		let payload = {
			image: menuLink.image,
			title: menuLink.title,
			link_type: menuLink.link_type,
			sport_slug: menuLink?.sport_id,
			competition_id: menuLink.competition_id,
			open_type: menuLink.open_type,
			event_id: menuLink.event_id,
			link: menuLink.link,
			show_menu: menuLink.show_menu,
			image_exist: menuLink.image_exist,
			seo_image: menuLink.seo_image,
			seo_keywords: menuLink.seo_keywords,
			seo_title: menuLink.seo_title,
			seo_description: menuLink.seo_description,
			game_id: menuLink?.game_id,
			published: menuLink?.published,
		};
		if (editItem) {
			payload.id = editItem.id;
		}

		if (menuLink?.link_type === "generic") {
			const shouldStop = checkGenericUrl(menuLink?.link);
			if (shouldStop) return;
		}
		let language = languageToString(menuLink, ["title_", "seo_title_", "seo_description_", "seo_keywords_"]);
		// Object.entries(menuLink).forEach((row, key) => {
		// 	if (row[0].indexOf("title_") > -1) {
		// 		if (key + 1 === Object.entries(menuLink).length) {
		// 			language += `"${row[0]}":"${row[1]}"`;
		// 		} else {
		// 			language += `"${row[0]}":"${row[1]}",`;
		// 		}
		// 	}
		// });
		if (menuLink?.link_type === "sport") {
			delete payload.competition_id;
			delete payload.event_id;
		}
		language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.save_menu_links, resolve, reject, payload, true, fields);
		})
			.then(response => {
				setDataChanged(false);
				let data = response?.data?.returnData?.data;
				setNewData(data);
				getMenuLinks();
				handler();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};

	const handleAcceptedFiles = () => {};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const dependeciesObj = {
		sport_id: menuLink.sport_id,
		comp_id: menuLink.competition_id,
		link_type: menuLink.link_type,
	};
	useEffect(() => {
		let newFields = [];
		newFields.push(
			{
				type: "image",
				name: "Icon",
				fieldName: "image",
				onFocus: () => handleShowOptions("image", "menu_links"),
				onBlur: () => (onFocus.current = false),
				optional: "optional",
				imageType: "menu_links",
				active: showOptions.image === "menu_links",
				data: menuLink,
				setData: setMenuLink,
				setDataChanged: setDataChanged,
				accept: "image/svg+xml",
				dimensions: "(40 x 40 px). SVG files only. ",
			},
			{ type: "switch", name: "Show in Menu", fieldName: "show_menu" },
			{ type: "switch", name: "Published", fieldName: "published" },
			{ type: "dropdown", name: "Open In", fieldName: "open_type", optionsType: "open_type", required: true },
			{
				type: "input",
				name: "Title",
				fieldName: "title",
				onFocus: () => handleShowOptions("language", "title"),
				onBlur: () => (onFocus.current = false),
				required: true,
				// property: menuLink.link_type === "page" && "page_link",
			},
			{
				type: "dropdown",
				name: "Link Type",
				fieldName: "link_type",
				optionsType: "page_type",
				emptyValues: ["sport_id", "competition_id", "event_id", "pageName", "link"],
			}
		);

		if (menuLink.link_type === "generic") {
			newFields.push({ type: "input", name: "URL Address", fieldName: "link", required: true });
		} else if (menuLink.link_type === "event") {
			newFields.push(
				{
					type: "dropdown",
					name: "Sport",
					fieldName: "sport_id",
					optionsType: "allSports",
					emptyValues: ["competition_id", "event_id", "pageName"],
				},
				{
					type: "dropdown",
					name: "Competition",
					fieldName: "competition_id",
					optionsType: "competitions",
					id: menuLink?.sport_id,
					params: menuLink?.sport_id,
				},
				{
					type: "dropdown",
					name: "Event",
					fieldName: "event_id",
					optionsType: "event",
					id: menuLink?.competition_id,
					params: menuLink?.competition_id,
					property: "link_type",
					link_type: `/${menuLink?.sport_id}/event/`,
					required: true,
				},
				{ type: "input", name: "URL Address", fieldName: "link", disabled: true, required: true }
			);
		} else if (menuLink.link_type !== "" && menuLink.link_type !== undefined) {
			let name;
			let optionsType;
			let link = "/";
			let inputType = "dropdown";
			let property = "link_type";
			let addDropdown = true;

			if (menuLink.link_type === "modal") {
				name = "Page";
				optionsType = "page_content";
			} else if (menuLink.link_type === "sport") {
				name = "Sport";
				optionsType = "allSports";
				link = "/sport/";
			} else if (menuLink.link_type === "casino") {
				name = "Game";
				optionsType = "game";
				link = "/casino/";
			} else if (menuLink.link_type === "page") {
				name = "Page";
				inputType = "input";
				addDropdown = false;
			}
			if (addDropdown) {
				newFields.push({
					type: inputType,
					name: name,
					fieldName: "sport_id",
					optionsType: optionsType,
					link_type: link,
					property: property,
				});
			}

			newFields.push({ type: "input", name: "URL Address", fieldName: "link", disabled: true, required: true });
		}
		newFields.push(
			{
				type: "empty_row",
				name: "SEO",
				inputClassName: "optional-custum-input",
			},
			{
				type: "input",
				name: "Title",
				fieldName: "seo_title",
				onFocus: () => handleShowOptions("language", "seo_title"),
				onBlur: () => (onFocus.current = false),
			},
			{
				type: "textarea",
				name: "Description",
				fieldName: "seo_description",
				textareaClassName: "scaleTextarea",
				textUpperCase: true,
				maxLength: 160,
				onFocus: () => handleShowOptions("language", "seo_description"),
				onBlur: () => (onFocus.current = false),
			},
			{
				type: "image",
				name: "Image",
				fieldName: "seo_image",
				onFocus: () => handleShowOptions("image", "seo_image"),
				onBlur: () => (onFocus.current = false),
				imageType: "seo_image",
				active: showOptions.image === "seo_image",
				data: menuLink,
				setData: setMenuLink,
				setDataChanged: setDataChanged,
				dimensions: "(580 x 580 px)",
			},
			{
				type: "input",
				name: "Keywords",
				fieldName: "seo_keywords",
				inputClassName: "general_input",
				onFocus: () => handleShowOptions("language", "seo_keywords"),
				onBlur: () => (onFocus.current = false),
			}
		);
		setfields(newFields);
	}, [menuLink, showOptions.image]);

	useEffect(() => {
		if (menuLink?.title && editItem?.protected !== 1 && menuLink.link_type === "page") {
			let linkUrl = "/" + menuLink?.title.toLowerCase().replace(/ /g, "-");
			setMenuLink(prevMenuLink => ({
				...prevMenuLink,
				link: linkUrl,
			}));
		}
	}, [menuLink?.title, menuLink.link_type]);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent loading={loading} saveHandler={changeLinkData} formId="avForm" type="submit" dataChanged={dataChanged} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AvForm id="avForm" onClick={() => !onFocus.current && handleShowOptions()}>
							<AllComponentsLoop fields={fields} data={menuLink} setData={setMenuLink} setDataChanged={setDataChanged} dependecies={dependeciesObj} />
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
						{showOptions.language && (
							<LanguageTitle data={menuLink} setData={setMenuLink} title={showOptions.language} handleDataChanged={handleDataChanged} />
						)}
						{showOptions.image && (
							<ImageLibrary
								data={menuLink}
								setData={setMenuLink}
								handleAcceptedFiles={handleAcceptedFiles}
								type={showOptions.image}
								handleDataChanged={handleDataChanged}
								accept={showOptions.image === "menu_links" ? "image/svg+xml" : "image/*"}
								property={showOptions.image === "menu_links" ? "image" : showOptions.image}
								handleShowOptions={handleShowOptions}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
