import React, { useEffect, useState } from "react";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";
// import { capitalizeText } from "services/capitalizeText";
import { _componentPageLayoutOptions, casinoTypes } from "utils/constants";
import { getCasinoCatrgoryDropdown } from "services/getCasinoCategoryDropdown";
import { AvForm } from "availity-reactstrap-validation";
// import { getCasinoProviders } from "services/getCasinoProviders";
export const PageLayoutModal = props => {
	const { setShowPromotion, editItem, setEditItem, pageLayoutType, setPageLayout, country } = props;

	const [loading, setLoading] = useState(false);
	const [contentData, setContentData] = useState([]);
	// const [casionoProvider, setCasionoProvider] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);

	const [layoutData, setLayoutData] = useState({
		name: editItem ? editItem?.type_name : "",
		type: editItem ? editItem?.type : "",
		name_id: editItem ? editItem?.type_id : "",
		studio: "",
	});

	const addLayout = () => {
		let body = {
			parent: pageLayoutType,
			type: layoutData?.type,
			type_id: layoutData?.name_id,
			type_name: layoutData?.name,
			provider: layoutData?.studio,
			country: country,
			id: editItem ? editItem?.id : null,
		};
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.save_page_layout, resolve, reject, body, true);
		})
			.then(response => {
				setDataChanged(false);
				let data = response?.data;
				setPageLayout(data);
				setContentData([]);
				setLayoutData({
					...layoutData,
					name: "",
					type: "",
					name_id: "",
				});
				setShowPromotion(false);
				setEditItem(null);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getContentDropdown = (url, id, labelName, type) => {
		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		}).then(response => {
			let data;
			if (type === "duble_data") data = response?.data?.data;
			else data = response?.data;

			let contentData = [];
			data.map(row => {
				if (row?.details) {
					let details = JSON.parse(row?.details) || null;
					contentData.push({ id: row[id], label: details[labelName] });
				} else {
					contentData.push({ id: row[id], label: row[labelName] });
				}
			});
			setContentData(contentData);
		});
	};

	const getContent = async (id, label) => {
		if (editItem) {
			setLayoutData({ ...layoutData, type: id, title: label, name: "", name_id: "" });
		} else {
			setLayoutData({ type: id, title: label, studio: "", name: "", name_id: "" });
		}
		let url;
		let labelName = "title";
		let type = "";

		if (Object.values(casinoTypes).includes(id) || id === "promo_offer") {
			// setContentData([]);
			// const providers = await getCasinoProviders();
			// setCasionoProvider(providers);
			setDataChanged(true);
		} else if (id === "casino_category" || id === "live_casino_category" || id == "virtual_casino_category") {
			const casinoCategory = await getCasinoCatrgoryDropdown(id);
			setContentData(casinoCategory);
		} else if (id === "page_content") {
			url = apiUrls.page_content;
			labelName = "friendly_name";
		} else if (id === "carousel") {
			url = apiUrls.CAROUSEL_GROUPS;
			labelName = "name";
			type = "duble_data";
		} else if (id === "swifty_special") {
			url = `${apiUrls.published_content_get}?type=sport_widget&country_code=${country}`;
		} else {
			url = `${apiUrls.published_content_get}?type=${id}&country_code=${country}`;
		}

		if (url) getContentDropdown(url, "id", labelName, type);
	};

	useEffect(() => {
		getContent(layoutData?.type, layoutData?.title);
		if (!Object.values(casinoTypes).includes(layoutData?.type)) {
			handleDropDown(layoutData?.studio, layoutData?.name, layoutData?.type);
		}
	}, []);

	const handleDropDown = (id, label, type) => {
		if (!label) setLayoutData({ ...layoutData, name_id: "", name: "", studio: "" });

		if (type === "name") {
			setLayoutData({ ...layoutData, name_id: id, name: label });
		} else {
			setLayoutData({ ...layoutData, studio: id });
		}

		setDataChanged(true);
	};
	const handlerFilterData = value => {
		if (value) {
			const type = layoutData?.type;
			getContentDropdown(`${apiUrls.GET_CASINO_OPTIONS}?provider=${layoutData?.studio}&&type=${type}&q=${value}`, "id", "title");
		} else {
			setContentData([]);
		}

		setDataChanged(true);
	};

	useEffect(() => {
		if (layoutData?.studio) {
			handlerFilterData();
		}
	}, [layoutData?.studio]);

	const handler = () => {
		setContentData([]);
		setLayoutData({
			...layoutData,
			title: "",
			name: "",
			type: "",
			name_id: "",
		});
		setShowPromotion(false);
		setEditItem(null);
	};
	return (
		<>
			<div className="promotion-container">
				<div className="promotion-header">
					<span>{"Page Layout"}</span>
					<XCloseIcon onClick={handler} className={"xclose-icon"} />
				</div>
				<div className="promotion-body">
					<TopBarComponent saveHandler={addLayout} loading={loading} dataChanged={dataChanged} onClose={handler} />
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						<AvForm>
							<GeneralDropdown
								object={{
									value: layoutData.type,
									name: "Component",
									options: _componentPageLayoutOptions,
									required: true,
									handleChangeDropdown: newValue => getContent(newValue.id, newValue.label),
								}}
							/>

							{layoutData?.type &&
								(Object.values(casinoTypes).includes(layoutData?.type) || layoutData?.type === "promo_offer" ? (
									<>
										{/* <GeneralDropdown
										object={{
											value: layoutData.studio,
											name: capitalizeText(layoutData?.type) + " Studio",
											options: casionoProvider,
											placeholder: "Studio",
											handleChangeDropdown: e => handleDropDown(e.id, e.label, layoutData?.type),
										}}
									/>
									<GeneralFilterDropdown
										object={{
											name: "Name",
											options: contentData,
											bindValue: layoutData.name,
											handlerFilterData: handlerFilterData,
											handleChangeDropdown: e => handleDropDown(e?.id, e?.label, "name"),
										}}
									/> */}
									</>
								) : (
									<GeneralDropdown
										object={{
											value: layoutData.name_id,
											name: "Name",
											options: contentData,
											placeholder: "Name",
											handleChangeDropdown: e => handleDropDown(e.id, e.label, "name"),
										}}
									/>
								))}
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6"></div>
				</div>
			</div>
		</>
	);
};
