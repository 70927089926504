import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner } from "react-bootstrap";
import { CancelButton, SaveButton } from "components/GeneralComponents/AddButton";
import Flatpickr from "react-flatpickr";
import moment from "moment";

export const CreatePeriodCompetitionModal = ({
	createPeriodLeague,
	setSportId,
	handleSportChange,
	setSportName,
	sportId,
	sports,
	sportFilterSpinning,
	setCompetitionId,
	setCompetitionName,
	competitionId,
	periodLeagueName,
	setPeriodLeagueName,
	setDateFrom,
	setDateTo,
	setCreatePeriodLeague,
	createPeriodCompetition,
	competitions,
	regionId,
}) => {
	return (
		<>
			{createPeriodLeague && (
				<SweetAlert title="Create Period Leagues" showConfirm={false} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div style={{ display: "block" }}>
							<div className="row mb-2">
								<div className="col-4" style={{ textAlign: "start" }}>
									Sport Type
								</div>
								<div className="col-8">
									<select
										type={"text"}
										className="form-control"
										onChange={e => {
											var index = e.target.selectedIndex;
											var optionElement = e.target.childNodes[index];
											var name = optionElement.getAttribute("data-name");
											setSportId(e.target.value);
											handleSportChange(e.target.value, regionId);
											setSportName(name);
										}}
										value={sportId}
									>
										<option value={""}>Choose sport</option>
										{sports &&
											sports.map((row, index) => {
												return (
													<option data-id={row.genius_id} key={index} value={row.genius_id} data-name={row.name}>
														{row.name}
													</option>
												);
											})}
									</select>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-4" style={{ textAlign: "start" }}>
									Competition
								</div>
								<div className="col-8">
									{sportFilterSpinning ? (
										<Spinner animation="border" size="sm" />
									) : (
										<select
											type={"text"}
											className="form-control"
											onChange={e => {
												var index = e.target.selectedIndex;
												var optionElement = e.target.childNodes[index];
												var name = optionElement.getAttribute("data-name");
												setCompetitionId(e.target.value);
												setCompetitionName(name);
											}}
											value={competitionId}
										>
											<option value={""}>Choose competition</option>
											{competitions.map((row, index) => {
												return (
													<option data-id={row.genius_id} key={index} value={row.genius_id} data-name={row.name}>
														{row.name}
													</option>
												);
											})}
										</select>
									)}
								</div>
							</div>
							<div className="row align-items-center mb-2 mt-2">
								<div className="col-4 d-flex justify-content-start">Name</div>
								<div className="col-8">
									<input type="text" className="form-control" value={periodLeagueName} onChange={e => setPeriodLeagueName(e.target.value)} />
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-4" style={{ textAlign: "start" }}>
									Date From
								</div>
								<div className="col-8">
									<Flatpickr
										className="form-control d-block"
										placeholder="Select Date"
										options={{
											mode: "single",
											dateFormat: "d-m-Y",
											minDate: "today",
											locale: {
												firstDayOfWeek: 1, // start week on Monday
											},
										}}
										onChange={([date]) => {
											let startDateVal = moment(date).format("YYYY-MM-DD HH:mm:ss");
											setDateFrom(startDateVal);
										}}
									/>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-4" style={{ textAlign: "start" }}>
									Date to
								</div>
								<div className="col-8">
									<Flatpickr
										className="form-control d-block"
										placeholder="Select Date"
										options={{
											mode: "single",
											dateFormat: "d-m-Y",
											minDate: "today",
											locale: {
												firstDayOfWeek: 1, // start week on Monday
											},
										}}
										onChange={([date]) => {
											let startDateVal = moment(date).format("YYYY-MM-DD HH:mm:ss");
											setDateTo(startDateVal);
										}}
									/>
								</div>
							</div>

							<CancelButton onClick={() => setCreatePeriodLeague(false)} name={"Cancel"} />

							<SaveButton onClick={createPeriodCompetition} name={"Save"} />
						</div>
					</div>
				</SweetAlert>
			)}
		</>
	);
};
