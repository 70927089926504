import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import React, { useEffect, useState } from "react";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getRacingWidgetColumns } from "./racingWidgetColumns";
import { RacingWidgetPromotion } from "./racingWidgetPromotion";
import { useSelector } from "react-redux";
import ConfirmDeleteComponent from "components/GeneralComponents/ConfirmDeleteComponent";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { getContent } from "services/getContentAllData";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const RacingWidget = props => {
	const [showPromotion, setShowPromotion] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [racingData, setRacingData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);
	const [editItem, setEditItem] = useState(null);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [itemTitle, setItemTitle] = useState("");
	const pageHeaderData = {
		international: true,
		addNew: true,
		addNewClick: () => {
			setShowPromotion(true);
		},
	};
	const country = useSelector(state => state.Login.country);

	const racingWidget = async () => {
		setIsDataLoading(true);
		let response = await getContent("racing_widget", country);
		if (response?.length > 0) {
			response.forEach(element => {
				if (element?.active) {
					element["status"] = "Published";
				} else {
					element["status"] = "Inactive";
				}
				if (element?.details) {
					let parsedDetails = JSON.parse(element["details"]);
					parsedDetails["publish_start_date_time"] = element["publish_start_date_time"];
					parsedDetails["publish_stop_date_time"] = element["publish_stop_date_time"];
					parsedDetails["status"] = element["status"];
					parsedDetails["price_boost"] = "Enabled";
					element["details"] = JSON.stringify(parsedDetails);
				}
			});
			setRacingData(response);
			setUnfilteredData(response);
			setIsDataLoading(false);
		} else {
			setIsDataLoading(false);
			setRacingData([]);
			setUnfilteredData([]);
		}
	};

	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};

	useEffect(() => {
		racingWidget();
	}, [country]);

	const racingColumns = getRacingWidgetColumns({ racingData, props, handleEditItem, setDeleteItem, setItemTitle });
	return (
		<HelmetLayout titleProps={"Racing Widget"}>
			<div className="cms-page pt-0">
				<SingleSearchFilter
					setData={setRacingData}
					unfilteredData={unfilteredData}
					detailsColumn={["title", "publish_start_date_time", "publish_stop_date_time", "status", "price_boost"]}
				/>
				<MUIDataTable
					columns={racingColumns}
					data={racingData}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						responsive: tableResponsive,
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
						customToolbar: () => <AddTableButton onClick={pageHeaderData["addNewClick"]} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
			{deleteItem && (
				<ConfirmDeleteComponent
					showDeleteCom={deleteItem}
					deleteLoading={deleteLoading}
					setDeleteLoading={setDeleteLoading}
					deleteTitle={"Confirm Deletion of Promotion"}
					titlename={itemTitle}
					setShowDeleteCom={setDeleteItem}
					betSlipData={racingData}
					setBetSlipData={setRacingData}
					id={deleteItem?.id}
				/>
			)}

			{showPromotion && (
				<RacingWidgetPromotion
					title={"Racing Widget"}
					editItem={editItem}
					countires={country}
					getRacingWidget={racingWidget}
					handler={() => {
						setShowPromotion(false);
						setEditItem(null);
					}}
				/>
			)}
		</HelmetLayout>
	);
};

export default RacingWidget;
