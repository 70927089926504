import { accountStatusOptions, kycStatusData } from "utils/constants";

export const usersFilterColumns = ({ countries }) => {
	const countriesData = countries ? JSON.stringify(countries) : [];
	const accountStatusData = accountStatusOptions ? JSON.stringify(accountStatusOptions) : [];
	return [
		{
			value: "player_id",
			name: "Player ID",
			type: "text",
		},
		{
			value: "first_name",
			name: "First Name",
			type: "text",
		},
		{
			value: "last_name",
			name: "Last Name",
			type: "text",
		},
		{
			value: "country",
			name: "Country",
			type: "select",
			data: countriesData,
			optionKey: "cca2",
			optionName: "name",
		},
		{
			value: "email",
			name: "Email",
			type: "text",
		},

		{
			value: "kyc",
			name: "KYC",
			type: "select",
			data: kycStatusData,
			optionKey: "value",
			optionName: "name",
		},
		{
			value: "date",
			name: "Registration Date",
			type: "date",
		},
		{
			value: "account_status",
			name: "Account Status ",
			type: "select",
			data: accountStatusData,
			optionKey: "id",
			optionName: "label",
		},
	];
};
