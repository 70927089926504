import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import Papa from "papaparse";
import MUIDataTable from "mui-datatables";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import { tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { DarkBackArrowIcon } from "utils/icons";
// import { tableResponsive } from "utils/tableSettings";

export const OpenReport = () => {
	const location = useLocation();
	const { pathname } = location;
	const filename = pathname.split("/")[2];
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState([]);
	const [headers, setHeaders] = useState([]);

	const convertToNumbers = (row, data) => {
		const value = data[0][row];
		let number;

		if (typeof value === "string") {
			if (value.includes("%")) number = parseFloat(value.replace("%", "")) / 100;
			else if (/[\d,]+\.\d+/.test(value)) number = parseFloat(value.replace(/,/g, ""));
			else if (/^\d+$/.test(value)) number = Number(value);
			else number = value;
		} else {
			number = value;
		}

		return number;
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				new Promise((resolve, reject) => {
					ApiServices.post(apiUrls.download_report, resolve, reject, { filename }, true);
				})
					.then(response => {
						const csvContent = response;
						// console.log("csvContent", csvContent);

						// Parse the CSV file
						Papa.parse(csvContent, {
							header: true,
							skipEmptyLines: true,
							complete: result => {
								const headers = result.meta.fields;
								const data = result.data;

								const newHeaders = headers.map(row => {
									const number = convertToNumbers(row, data);
									return {
										name: row,
										label: row,
										options: {
											setCellProps: () => ({ style: { textAlign: !isNaN(number) && !row.includes("ID") ? "right" : "left" } }),
											setCellHeaderProps: () => ({ className: !isNaN(number) && !row.includes("ID") ? "max-button-and-right align-right" : "" }),
										},
									};
								});
								setHeaders(newHeaders);
								setData(data);
							},
						});
					})
					.finally(() => {
						setIsLoading(false);
					});
			} catch (error) {
				console.error("Error fetching or parsing the file:", error);
			}
		};

		fetchData();
	}, [filename]);

	const goBack = () => {
		history.goBack();
	};

	return (
		<div>
			<div className="open-report-page">
				<div className="back-icon" onClick={goBack}>
					<DarkBackArrowIcon />
					<span>Back</span>
				</div>
				<MUIDataTable
					columns={headers}
					data={data}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						sort: true,
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						responsive: "",
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
					}}
				/>
			</div>
		</div>
	);
};
