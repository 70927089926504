import React, { useState } from "react";
import { CasinoItem } from "./casinoItem";
import { CasinoGroups } from "./casinoGroups";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";

import "./casinoGames.scss";

const CasinoPageLayout = ({ type, title }) => {
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [categoryGames, setCategoryGames] = useState([]);
	const [editCasinoGrups, setEditCasinoGrups] = useState(false);

	return (
		<HelmetLayout titleProps={title}>
			<div className="d-flex flex-column h-100">
				<div className="casino_info">
					<p className="casino_info_header">AI Image upscaling is currently active for default images. </p>
					<p>After adding a new game to a category it can take up to 10 minutes before the upscaled images will show on the client facing applications.</p>
				</div>
				<div className="cms-page cms-casino row m-0 pt-0 pb-0">
					<div className="col-lg-6 col-12 ps-0">
						<CasinoItem
							setSelectedCategory={setSelectedCategory}
							categoryGames={categoryGames}
							setCategoryGames={setCategoryGames}
							selectedCategory={selectedCategory}
							editCasinoGrups={editCasinoGrups}
							type={type}
						/>
					</div>
					<div className="col-lg-6 col-12 pe-0">
						<CasinoGroups
							selectedCategory={selectedCategory}
							categoryGames={categoryGames}
							setCategoryGames={setCategoryGames}
							setEditCasinoGrups={setEditCasinoGrups}
							editCasinoGrups={editCasinoGrups}
							type={type}
						/>
					</div>
				</div>
			</div>
		</HelmetLayout>
	);
};

export default CasinoPageLayout;
