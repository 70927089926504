import React from "react";
import { EyeButton } from "components/GeneralComponents/AddButton";
import { getFormatDateByUserTimezone, getStatusSpan } from "utils/global";
import { ModalButton } from "components/GeneralComponents/CustomInputs";
import { capitalizeText } from "services/capitalizeText";
// import HTMLReactParser from "html-react-parser";

export const getFreeBets = ({ bankigFreeBetsActiveData, handleEdit, isUserReadOnly }) => {
	return [
		{
			name: "created_at",
			label: "DATE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankigFreeBetsActiveData[meta.currentTableData[meta.rowIndex].index];
					const date = getFormatDateByUserTimezone(row.created_at, "YYYY-MM-DD");
					return <>{date}</>;
				},
				// setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				// setCellHeaderProps: () => ({ style: { width: "150px" }, className: "max-button" }),
				setCellProps: () => ({ style: { minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" } }),
			},
		},
		{
			name: "amount",
			label: "AMOUNT",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "130px" } }),
				setCellHeaderProps: () => ({ style: { width: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "amount_used",
			label: "AMOUNT USED",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				setCellProps: () => ({ style: { textAlign: "left", width: "154px" } }),
				setCellHeaderProps: () => ({ style: { width: "154px" }, className: "max-button" }),
			},
		},

		{
			name: "free_spins",
			label: "Turn Requirement",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "130px" } }),
				setCellHeaderProps: () => ({ style: { width: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "usage",
			label: "USAGE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankigFreeBetsActiveData[meta.currentTableData[meta.rowIndex].index];
					return <>{capitalizeText(row?.usage)}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "130px" } }),
				setCellHeaderProps: () => ({ style: { width: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "restrictions",
			label: "RESTRICTIONS",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				setCellProps: () => ({ style: { textAlign: "left", width: "130px" } }),
				setCellHeaderProps: () => ({ style: { width: "130px" }, className: "max-button" }),
			},
		},
		{
			name: "expire_date",
			label: "EXPIRE DATE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankigFreeBetsActiveData[meta.currentTableData[meta.rowIndex].index];
					const date = getFormatDateByUserTimezone(row?.expire_date);
					const displayDate = date != null ? date : "Never Expire";
					return <>{displayDate}</>;
				},

				// setCellProps: () => ({ style: { textAlign: "left", width: "180px" } }),
				// setCellHeaderProps: () => ({ style: { width: "180px" }, className: "max-button" }),
				setCellProps: () => ({ style: { minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" } }),
			},
		},
		{
			name: "status",
			label: "STATUS",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankigFreeBetsActiveData[meta.currentTableData[meta.rowIndex].index];

					return getStatusSpan(row.status);
				},
				setCellHeaderProps: () => ({ style: { width: "100px" }, className: "max-button" }),
			},
		},
		{
			name: "",
			label: "",
			align: "right",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankigFreeBetsActiveData[meta.currentTableData[meta.rowIndex].index];

					const { status, usage } = row;

					let active = false;

					if (status === "available") active = true;
					else if (usage === "sports" && status === "partly_used") active = true;

					return (
						<div className="heroBanners-buttons justify-content-end">
							{isUserReadOnly
								? ""
								: active && (
										<ModalButton
											object={{
												buttonName: "Cancel",
												position: "p-0",
												extraClassname: "cancel_adjustment_free_bets",
												onClickEvent: () => handleEdit(row),
											}}
										/>
								  )}

							<EyeButton onClick={() => handleEdit(row, "edit")} />
						</div>
					);
				},
				filter: false,
				viewColumns: false,
				setCellProps: () => ({ style: { textAlign: "right", width: "100px" } }),
				setCellHeaderProps: () => ({ style: { width: "100px" }, className: "max-button" }),
			},
		},
	];
};
