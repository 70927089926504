import React, { useEffect, useState } from "react";
import { GeneralDropdown, GeneralInput, ModalButton } from "components/GeneralComponents/CustomInputs";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import { Link } from "react-router-dom";
import ApiServices from "utils/ApiServices";
import { BetHistoryeIcon, DarkArrowLeft, GiftIcon } from "utils/icons";
import { getFields } from "./PlayeBetsFields";
import { apiUrls } from "utils/const.apiUrl";
import { capitalizeText } from "services/capitalizeText";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getTbody, getThead } from "../getTableData";
import { getLadders } from "services/getLaddersDropdown";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { AvForm } from "availity-reactstrap-validation";
import { disableInputs, userReadOnly } from "utils/global";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { getAllSportsDropdown } from "services/getAllSportsDropdown";

import { SettleBetModal } from "./settleBetModal";
import { _resultBetsOptions, betTypeData } from "utils/constants";

import "./playerBet.scss";
import { BetHistoryModal } from "./betHistoryModal";

function playerBet() {
	const location = useLocation();

	const { pathname } = location;
	const bet_id = pathname.split("/")[2];

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	const [isLoading, setIsLoading] = useState(false);
	const [playerBetsData, setPlayerBetsData] = useState([]);
	const [unChangedplayerBetsData, setUnChangedPlayerBetsData] = useState([]);
	const [dataChanged, setDataChanged] = useState(false);
	const [showBetHistoryModal, setShowBetHistoryModal] = useState(false);
	const [bogEWData, setBogEW] = useState({});
	const [betsData, setBetsData] = useState([]);
	const [unChangedBetsData, setUnChangedBetsData] = useState([]);
	const [settleModal, setShowSettleModal] = useState({ show: false, params: "" });
	const [manualResultField, setManualResultField] = useState("");

	const [tableData, setTableData] = useState([]);
	const [allSports, setSports] = useState([]);

	const fields = [
		{
			type: "input",
			name: "Bet ID",
			fieldName: "betted_id",
			disabled: true,
			normalWidth: true,
			inputContainer: "flex-row",
			titleClass: "main_header_table_bet_players header-width",
		},
		{
			type: "input",
			name: "Status",
			fieldName: "status",
			disabled: true,
			normalWidth: true,
			inputContainer: "flex-row",
			titleClass: "main_header_table_bet_players header-width",
		},
		{
			type: "input",
			name: "Player ID",
			fieldName: "player_id",
			disabled: true,
			normalWidth: true,
			inputContainer: "flex-row",
			titleClass: "main_header_table_bet_players header-width",
		},
		{
			type: "input",
			name: "Stake",
			fieldName: "stake",
			disabled: true,
			inputContainer: "flex-row",
			normalWidth: true,
			titleClass: "main_header_table_bet_players header-width",
			icon: (
				<div className="d-flex align-items-center justify-content-end gap-2">
					<span>{playerBetsData?.currency}</span>
					{playerBetsData?.free_bet_amount > 0 && <GiftIcon />}
				</div>
			),
			iconInsideInput: true,
		},
		{
			type: "date",
			name: "Date",
			fieldName: "created_at",
			disabled: true,
			formatDate: true,
			isTimeExist: true,
			noFormattedDate: true,
			normalWidth: true,
			wrapperCustomized: "flex-row",
			titleClass: "main_header_table_bet_players header-width",
		},
	];

	const bogandEwField = [
		{
			type: "input",
			name: "BOG",
			fieldName: "is_bog_applicable",
			disabled: true,
			normalWidth: true,
			inputContainer: "flex-row",
			titleClass: "main_header_table_bet_players header-width",
		},
		{
			type: "input",
			name: "BOG Amount",
			fieldName: "bog_amount",
			disabled: true,
			normalWidth: true,
			inputContainer: "flex-row",
			titleClass: "main_header_table_bet_players header-width",
		},
		{
			type: "input",
			name: "Each Way",
			fieldName: "is_each_way",
			disabled: true,
			normalWidth: true,
			inputContainer: "flex-row",
			titleClass: "main_header_table_bet_players header-width",
		},
	];

	const thirdColumnFields = [
		{
			type: "date",
			name: "Resulted At",
			fieldName: "resulted_at",
			disabled: true,
			formatDate: true,
			isTimeExist: true,
			noFormattedDate: true,
			normalWidth: true,
			wrapperCustomized: "flex-row",
			titleClass: "main_header_table_bet_players header-width",
		},
	];

	const betTypesFields = [
		{
			type: "input",
			name: "Bet Type",
			fieldName: "bet_type",
			optionsType: "bet_types",
			disabled: true,
			normalWidth: true,
			inputContainer: "flex-row",
			titleClass: "main_header_table_bet_players header-width",
		},
	];

	const betSettledFields = [
		{
			type: "input",
			name: "Resulted By",
			fieldName: "settled_by",
			disabled: true,
			normalWidth: true,
			inputContainer: "flex-row",
			titleClass: "main_header_table_bet_players header-width",
		},
	];

	const dependecies = { free_bet_amount: playerBetsData?.free_bet_amount };

	const isOpen = playerBetsData.status === "Open";
	const handleClick = () => {
		setIsLoading(true);
		let params = settleModal?.params;
		let payload = {};
		const bets = betsData.map(row => {
			const {
				bet_id,
				result,
				partial_win_percent,
				rule_4,
				win_reduction,
				place_reduction,
				ew_reduction,
				is_each_way,
				ew_terms,
				odd_decimal,
				odd_fractional,
				sp_odd_decimal,
				sp_odd_fractional,
				is_bog_applicable,
				is_starting_price,
			} = row;

			let data = {
				result,
				bet_id,
				rule_4,
				win_reduction,
				place_reduction,
				ew_reduction,
				is_each_way,
				ew_terms,
				odd_decimal,
				odd_fractional,
				sp_odd_decimal,
				sp_odd_fractional,
				is_bog_applicable,
				is_starting_price,
			};

			if (result === "partial") data = { ...data, partial_win_percent };
			return data;
		});

		if (params !== "cancel") {
			const payout = params === "settle" ? playerBetsData.payout : playerBetsData.manual_payout;
			payload = {
				user_id: playerBetsData.user_id,
				bets: bets,
				payout: payout,
				free_bet_amount: playerBetsData.free_bet_amount,
				bog_applicable: playerBetsData.is_bog_applicable,
			};
		}
		if (params.includes("settle?manual=1")) {
			params = "settle?manual=1&manual_bet_result=" + manualResultField;
		}
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.GET_PLAYER_BETS + `${bet_id}/${params}`, resolve, reject, payload, true);
		})
			.then(response => {
				const { data, success } = response;
				if (success && data) {
					setPlayerBetsData({ ...data, status: capitalizeText(data?.status || "") });
					setBetsData(data.bets);
					setShowSettleModal({ show: false, params: "" });
					getPlayerBets();
				}
			})
			.finally(() => {
				setIsLoading(false);
				setShowSettleModal({ show: false, params: "" });
				setDataChanged(false);
			});
	};

	const handleCloseTable = () => {
		setPlayerBetsData({ ...playerBetsData, informatinId: 0 });
	};

	const getAllLadders = async sportSlugs => {
		const promise = await Promise.all(sportSlugs.map(f => getLadders(f)));
		const resultObject = Object.fromEntries(sportSlugs.map((slug, index) => [slug, promise[index]]));
		return resultObject;
	};

	const getPlayerBets = type => {
		let url = apiUrls.GET_PLAYER_BETS + `${bet_id}`;

		const isUnsettle = type === "unsettle";

		if (isUnsettle) {
			url += "/un-settle";
			setIsLoading(true);
		}

		new Promise((resolve, reject) => {
			if (isUnsettle) ApiServices.put(url, resolve, reject, true);
			else ApiServices.get(url, resolve, reject, true);
		})
			.then(async response => {
				const { data } = response;
				const free_bet_amount = parseInt(data?.free_bet_amount);
				const betType = betTypeData.find(row => row.value === data?.bet_type)?.name;

				setPlayerBetsData({
					...data,
					status: capitalizeText(data?.status || ""),
					free_bet_amount: free_bet_amount,
					bet_type: betType,
					bet_type_value: data?.bet_type,
					manual_payout: data?.is_manual_payout === 1 ? data?.partial_payout : 0,
				});
				if (data?.is_manual_payout) setManualResultField(data?.status);

				if (!isUnsettle)
					setUnChangedPlayerBetsData({
						...data,
						status: capitalizeText(data?.status || ""),
						free_bet_amount: free_bet_amount,
						bet_type: betType,
						bet_type_value: data?.bet_type,
					});
				else
					setUnChangedPlayerBetsData({
						...data,
						status: "Open",
						free_bet_amount: free_bet_amount,
						bet_type: betType,
						bet_type_value: data?.bet_type,
					});
				const sportSlugs = data.bets.map(row => row.sport_slug);
				const uniqueSlugs = [...new Set(sportSlugs)];

				let ladders = await getAllLadders(uniqueSlugs);

				const newBets = data.bets.map(row => {
					const formattedOdd = row?.odd === null || row.odd_decimal === null ? "0.00" : parseFloat(row.odd_decimal || row?.odd).toFixed(2);
					const formattedSpOdd = row?.sp_odd === null || row.sp_odd_decimal === null ? "0.00" : parseFloat(row.sp_odd_decimal || row?.sp_odd).toFixed(2);

					return {
						...row,
						ladder: ladders[row.sport_slug],
						odd_decimal: formattedOdd,
						sp_odd_decimal: formattedSpOdd,
					};
				});

				setBetsData(newBets);

				const is_bog_applicable = data?.is_bog_applicable ? "Yes" : "No";
				const is_each_way = data?.is_each_way ? "Yes" : "No";

				setBogEW({ is_bog_applicable, is_each_way, bog_amount: data?.bog_amount || "0.00" });

				setUnChangedBetsData(newBets);
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		getPlayerBets();
		getSports();
	}, []);

	useEffect(() => {
		if (betsData) {
			const tableData = getFields(betsData, handleCloseTable, bogEWData, allSports);
			setTableData(tableData);
		}
	}, [betsData, bogEWData, playerBetsData, allSports]);

	useEffect(() => {
		if (isUserReadOnly) disableInputs();
	}, [isUserReadOnly]);

	const handleDiscard = () => {
		setPlayerBetsData(unChangedplayerBetsData);
		setBetsData(unChangedBetsData);
		setDataChanged(false);
		setManualResultField("");
	};

	const getSports = async () => {
		let sport = await getAllSportsDropdown();
		setSports(sport);
	};

	return (
		<HelmetLayout titleProps={"Bet"}>
			{settleModal.show && (
				<SettleBetModal setShowSettleModal={setShowSettleModal} params={settleModal.params} handleClick={handleClick} spinningStatus={isLoading} />
			)}

			{showBetHistoryModal && <BetHistoryModal bet_id={bet_id} setShowBetHistoryModal={setShowBetHistoryModal} />}
			<TopBarComponent discardHandler={isOpen && handleDiscard} classname="bets-topbar-component" dataChanged={dataChanged}>
				{!isOpen && (
					<ModalButton
						object={{
							buttonName: "Unsettle",
							wrapperClassName: "p-0",
							isLoading: isLoading === "unsettle",
							onClickEvent: () => getPlayerBets("unsettle"),
						}}
					/>
				)}
			</TopBarComponent>
			<div className="player_bet_container">
				<div className="d-flex align-items-center justify-content-between w-100 pb-1 p-4">
					<Link className="link-color" to="/bets">
						<DarkArrowLeft />
					</Link>

					<ModalButton
						object={{
							buttonName: (
								<>
									<BetHistoryeIcon />
									<span>Bet History</span>
								</>
							),
							extraButtonClassName: `bet_history`,
							isLoading: isLoading === "cancel",
							onClickEvent: () => setShowBetHistoryModal(true),
						}}
					/>
				</div>
				<AvForm>
					<div className="m-4 player-bets-information">
						<div className="information-items">
							<AllComponentsLoop fields={fields} data={playerBetsData} setData={setPlayerBetsData} dependecies={dependecies} />
						</div>
						<div className="information-items">
							<AllComponentsLoop fields={bogandEwField} data={bogEWData} setData={setPlayerBetsData} dependecies={dependecies} />
							<AllComponentsLoop fields={betTypesFields} data={playerBetsData} setData={setPlayerBetsData} dependecies={dependecies} />
							{playerBetsData?.settled_by && (
								<AllComponentsLoop fields={betSettledFields} data={playerBetsData} setData={setPlayerBetsData} dependecies={dependecies} />
							)}
						</div>
						<div className="information-items">
							<AllComponentsLoop fields={thirdColumnFields} data={playerBetsData} setData={setPlayerBetsData} dependecies={dependecies} />
						</div>
					</div>
					<div className="mb-5 m-4 bets-table">
						<table className="d-flex flex-column">
							<thead>{getThead(tableData?.thead)}</thead>
							<tbody>{getTbody(tableData?.tbody, betsData, setBetsData, playerBetsData, setPlayerBetsData, isUserReadOnly, setDataChanged)}</tbody>
						</table>
						<table className={`d-flex flex-column ${tableData?.thead?.length > 0 && `mt-4`}`}>
							<thead>{getThead(tableData?.theadSis)}</thead>
							<tbody>{getTbody(tableData?.tbodySis, betsData, setBetsData, playerBetsData, setPlayerBetsData, isUserReadOnly, setDataChanged)}</tbody>
						</table>
					</div>
					<div className="m-4">
						<div className="d-flex gap-4 align-items-center">
							<div className="settlement_buttons_container">Auto settlement</div>
							{playerBetsData?.warning_message && <div className="settlement_error">{`(${playerBetsData?.warning_message})`}</div>}
						</div>
						<div className="d-flex mt-2 pt-1">
							<div className="d-flex flex-column settlement-input">
								<GeneralInput
									object={{
										value: isOpen || playerBetsData?.is_manual_payout === 1 ? playerBetsData?.payout : playerBetsData?.partial_payout,
										name: "Payout",
										inputClassName: "text-end",
										inputContainer: "flex-row p-0",
										titleClass: "main_header_table_bet_players header-width",
										disabled: true,
									}}
								/>
								<div className="d-flex justify-content-end buttons_container_settlement">
									<ModalButton
										object={{
											buttonName: "Cancel Bet",
											extraButtonClassName: `button_player_bet ms-3 ${isLoading === "cancel" ? "bg-danger" : "cancle_button_player_bet"} 
										${!isOpen && "cursor-default"}`,
											isLoading: isLoading === "cancel",
											onClickEvent: () => isOpen && setShowSettleModal({ show: true, params: "cancel" }),
											disabled: !isOpen || playerBetsData?.disable_auto_settlement,
										}}
									/>
									<ModalButton
										object={{
											buttonName: "Settle Bet",
											extraButtonClassName: `button_player_bet ms-3 ${isLoading === "settle" && "bg-success"} ${!isOpen && "cursor-default"}`,
											isLoading: isLoading === "settle",
											onClickEvent: () => isOpen && setShowSettleModal({ show: true, params: "settle" }),
											disabled: !isOpen || playerBetsData?.disable_auto_settlement,
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="m-4 mt-5">
						<div className="settlement_buttons_container">Manual settlement</div>
						<div className="d-flex mt-2 pt-1">
							<div className="d-flex flex-column settlement-input">
								<GeneralInput
									object={{
										value: playerBetsData?.manual_payout,
										name: "Payout",
										inputClassName: "text-end",
										inputContainer: "flex-row p-0",
										titleClass: "main_header_table_bet_players header-width",
										disabled: isUserReadOnly ? isUserReadOnly : isOpen ? false : true,
									}}
									onChange={e => {
										setDataChanged(true);
										setPlayerBetsData({ ...playerBetsData, manual_payout: e.target.value });
									}}
								/>
								<div className="d-flex justify-content-end buttons_container_settlement">
									<ModalButton
										object={{
											buttonName: "Cancel Manually",
											extraButtonClassName: `button_player_bet ms-3 ${isLoading === "cancel" ? "bg-danger" : "cancle_button_player_bet"} 
										${!isOpen && "cursor-default"}`,
											isLoading: isLoading === "cancel",
											onClickEvent: () => isOpen && setShowSettleModal({ show: true, params: "cancel" }),
											disabled: !isOpen || playerBetsData?.disable_auto_settlement,
										}}
									/>
									<ModalButton
										object={{
											buttonName: "Settle Manually",
											extraButtonClassName: `button_player_bet ms-3 ${isLoading === "settle?manual=1" && "bg-success"} 
										${!isOpen && "cursor-default"}`,
											isLoading: isLoading === "settle?manual=1",
											// onClickEvent: () => handleClick("settle?manual=1"),
											onClickEvent: () => isOpen && setShowSettleModal({ show: true, params: "settle?manual=1" }),
											disabled: !isOpen || playerBetsData?.disable_auto_settlement,
										}}
									/>
								</div>
							</div>
							<GeneralDropdown
								object={{
									type: "dropdown",
									options: _resultBetsOptions,
									field: "result",
									value: manualResultField,
									defaultPlaceholder: "Main Bet Result",
									disabled: !isOpen,
									wrapperClassNameDropdown: "flex-row open_dropdown_up settle_bet_manual_dropdown p-0",
									// defaultValues: [{ field: "partial_win_percent", cases: { partial: "0" } }],
								}}
								handleChange={e => {
									setManualResultField(e?.id);
									setDataChanged(true);
								}}
							/>
						</div>
					</div>
				</AvForm>
			</div>
		</HelmetLayout>
	);
}

export default playerBet;
