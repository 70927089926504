import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner } from "react-bootstrap";

export const ShohEditErrorLogsModal = ({ showEditModal, setShowEditModal, handleErrorFix, spinningStatusFixed }) => {
	return (
		<>
			{showEditModal && (
				<SweetAlert title="Are you sure you have fixed this bug?" showConfirm={false} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="mt-3">
							<button
								style={{
									marginLeft: 10,
									minWidth: 100,
									padding: "5px 10px",
									borderRadius: 7,
									border: "none",
									fontSize: 16,
								}}
								className="btn-secondary"
								onClick={() => setShowEditModal(false)}
							>
								No
							</button>
							<button
								style={{
									marginLeft: 10,
									minWidth: 100,
									padding: "5px 10px",
									borderRadius: 7,
									border: "none",
									fontSize: 16,
								}}
								className="btn-primary"
								onClick={handleErrorFix}
							>
								{spinningStatusFixed ? <Spinner animation="border" size="sm" /> : "Yes"}
							</button>
						</div>
					</div>
				</SweetAlert>
			)}
		</>
	);
};
