import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AffiliateUsersGeneral from "./AffiliateUsersGeneral";
import { DarkArrowLeft } from "utils/icons";
import AffiliatesLinks from "./AffiliatesLinks";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import AffiliateSubAgent from "./AffiliateSubAgent";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

import { capitalizeText } from "services/capitalizeText";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			className="tabs-tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={"span"}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const AffiliateUsersPage = () => {
	const history = useHistory();
	const { id: agent_id } = useParams();

	const [tabValue, setTabValue] = useState(0);

	const [affiliateUsers, setAffiliateUsers] = useState();

	const [subAgents, setSubAgents] = useState([]);
	const [subAgentsLoading, setSubAgentsLoading] = useState([]);

	const [affiliateLinks, setAffiliateLinks] = useState([]);
	const [affiliateLinksOptions, setAffiliateLinksOptions] = useState([]);
	const [affiliateLinksLoading, setAffiliateLinksLoading] = useState([]);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const getAffiliateUser = () => {
		setSubAgentsLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.GET_AFFILIATE_USER}/${agent_id}/info`, resolve, reject, true);
		})
			.then(response => {
				const { success, data } = response;
				if (success) setAffiliateUsers(data);
			})
			.finally(() => setSubAgentsLoading(false));
	};

	const getSubAgents = () => {
		setSubAgentsLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_SUB_AGENT + "?id=" + agent_id, resolve, reject, true);
		})
			.then(response => {
				const { success, data } = response;
				if (success) {
					data.sort((a, b) => a.id - b.id);
					setSubAgents(data);
				}
			})
			.finally(() => setSubAgentsLoading(false));
	};

	const getAffiliateLinks = () => {
		setAffiliateLinksLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.affiliate_users_get, resolve, reject, true);
		})
			.then(response => {
				const { success, data } = response;
				if (success) {
					const promoLinkList = affiliateUsers?.promo_link_list;
					const parsePromoLinkList = JSON.parse(promoLinkList);
					const newData = data?.data.filter(row => parsePromoLinkList.includes(row.id));
					setAffiliateLinks(newData);

					let options = data?.data.map(row => {
						return { id: row.id, label: row.slug, ...row };
					});
					setAffiliateLinksOptions(options);
				}
			})
			.finally(() => setAffiliateLinksLoading(false));
	};

	useEffect(() => {
		getSubAgents();
	}, []);

	useEffect(() => {
		if (affiliateUsers?.promo_link_list) getAffiliateLinks();
	}, [affiliateUsers?.promo_link_list]);

	useEffect(() => {
		if (agent_id) getAffiliateUser();
	}, [agent_id]);

	return (
		<HelmetLayout titleProps={"Affiliates Users"}>
			<div className="cms-page pt-0">
				<div className={`affiliate_users_container tabs-container ${tabValue !== 0 && "mt-3"}`}>
					<div className="affiliate_users_header">
						<DarkArrowLeft onClick={() => history.push("/affiliate_users")} />
						<span>{capitalizeText(`${affiliateUsers?.first_name} ${affiliateUsers?.last_name}`)}</span>
					</div>
					<Box sx={{ width: "100%" }}>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" variant="fullWidth">
								<Tab label="General" {...a11yProps(0)} />
								<Tab label="Affiliates Links" {...a11yProps(1)} />
								<Tab label="Sub Agents" {...a11yProps(2)} />
							</Tabs>
						</Box>
						<TabPanel value={tabValue} index={0}>
							<AffiliateUsersGeneral affiliateUsers={affiliateUsers} getAffiliateUser={getAffiliateUser} />
						</TabPanel>
						<TabPanel value={tabValue} index={1}>
							<AffiliatesLinks
								affiliateLinks={affiliateLinks}
								loading={affiliateLinksLoading}
								affiliateLinksOptions={affiliateLinksOptions}
								getAffiliateUser={getAffiliateUser}
								getAffiliateLinks={getAffiliateLinks}
							/>
						</TabPanel>
						<TabPanel value={tabValue} index={2}>
							<AffiliateSubAgent agents={subAgents} affiliateUsers={affiliateUsers} loading={subAgentsLoading} getSubAgents={getSubAgents} />
						</TabPanel>
					</Box>
				</div>
			</div>
		</HelmetLayout>
	);
};

export default AffiliateUsersPage;
