import React, { useEffect, useState } from "react";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

import { XCloseIcon } from "utils/icons";
import { AvForm } from "availity-reactstrap-validation";
import { capitalizeText } from "services/capitalizeText";

// import { useDispatch } from "react-redux";

const ScheduledReportsModal = props => {
	const { showScheduledModal, setShowScheduledModal, filterReports } = props;
	// const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

	const [fields, setFields] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [data, setData] = useState({
		recipients: null,
		frequency: "",
		frequency_days: null,
		status: 0,
		report_type: "",
		user_id: "",
		player_id: "",
		event_id: "",
	});
	// const dispatch = useDispatch();

	const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

	const toggleDaySelection = day => {
		const prevFrequencyDays = data.frequency_days ? [...data.frequency_days] : [];
		const newArray = prevFrequencyDays.includes(day) ? prevFrequencyDays.filter(selectedDay => selectedDay !== day) : [...prevFrequencyDays, day];

		setData({ ...data, frequency_days: newArray });
		setDataChanged(true);
	};
	const handleChangeMultipleInput = value => {
		if (value) {
			let a = data?.recipients !== null ? data.recipients : [];
			a.push(value);

			setData({ ...data, recipients: a });
			setDataChanged(true);
		}
	};

	const getReport = () => {
		setIsDataLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_schdeulted_report + "?id=" + showScheduledModal?.id, resolve, reject, true);
		})
			.then(response => {
				const { success, data } = response;
				if (success) {
					const newRecipients = data[0].recipients.split(",");
					setData({ ...data[0], recipients: newRecipients });
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		if (showScheduledModal !== "add") getReport();
	}, []);

	const requiredFieldsForReports = {
		"free-bets-report": ["player_id"],
		"free-bets": ["player_id"],
		"manual-bets-by-user": ["user_id"],
		"customer-records": ["player_id"],
		"event-placed-bets-report": ["event_id"],
	};

	useEffect(() => {
		let fields = [
			{ type: "switch", name: "Enabled", fieldName: "status" },
			{ type: "dropdown", name: "Schedule", fieldName: "frequency", optionsType: "schedule_types", required: true },
		];
		if (data?.frequency === "weekly")
			fields.push({
				type: "extra_componnet",
				children: () => {
					const frequencyDays = data.frequency_days ? [...data.frequency_days] : [];
					return (
						<div className={`row wrapper_customized_modal`}>
							<div className="col-12 col-lg-4 d-flex align-items-center input-title">Days</div>
							<div className={`col-12 col-lg-8 d-flex align-items-center`}>
								<div className="weekdays">
									{daysOfWeek.map(day => (
										<div key={day} className={`day ${frequencyDays.includes(day) ? "selected" : ""}`} onClick={() => toggleDaySelection(day)}>
											{day}
										</div>
									))}
								</div>
							</div>
						</div>
					);
				},
			});
		fields.push({ type: "dropdown", name: "Report Type", fieldName: "report_type", optionsType: "report_type", required: true });

		if (requiredFieldsForReports[data.report_type]) {
			requiredFieldsForReports[data.report_type].map(row => {
				console.log(row, "row");
				fields.push({
					type: "input",
					name: capitalizeText(row),
					fieldName: row,
				});
			});
		}

		fields.push({
			type: "multiple_input",
			name: "Email Recipients",
			fieldName: "recipients",
			handleChange: handleChangeMultipleInput,
			multipleValue: typeof data?.recipients === "string" ? [data?.recipients] : data?.recipients,
		});

		setFields(fields);
	}, [data]);

	const handlerScheduledReports = () => {
		const newRecipients = data.recipients?.length > 0 ? data.recipients : [""];
		let payload = { ...data, recipients: newRecipients };

		if (showScheduledModal === "add") delete payload?.id;

		new Promise((resolve, reject) => {
			if (showScheduledModal === "add") ApiServices.post(apiUrls.schdeulted_report, resolve, reject, payload, true);
			else ApiServices.put(apiUrls.schdeulted_report, resolve, reject, data, true);
		})
			.then(response => {
				const { success } = response;
				if (success) {
					setIsDataLoading(false);
					filterReports({ status: "", frequency: "" });
				}
			})
			.finally(() => {
				setDataChanged(false);
				setShowScheduledModal(null);
			});
	};

	const handler = () => {
		setShowScheduledModal(null);
	};

	const dependeciesObj = { frequency: data?.frequency, frequency_days: data?.frequency_days, recipients: data?.recipients };

	return (
		<HelmetLayout titleProps={"Scheduled Reports"}>
			<div className="promotion-container">
				<div className="promotion-header">
					<span>Create New Schedule</span>
					<XCloseIcon onClick={handler} className={"xclose-icon"} />
				</div>
				<div className="promotion-body">
					<TopBarComponent saveHandler={handlerScheduledReports} loading={isDataLoading} dataChanged={dataChanged} onClose={handler} />
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
							<AvForm id="scheduledForm">
								<AllComponentsLoop fields={fields} data={data} setData={setData} setDataChanged={setDataChanged} dependecies={dependeciesObj} />
							</AvForm>
						</div>
						<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
							{/* {showOptions?.language && (
								<LanguageTitle data={heroBannerData} setData={setHeroBannerData} title={showOptions?.language} handleDataChanged={handleDataChanged} />
							)}
							{showOptions?.imageLibrary && (
								<ImageLibrary
									data={heroBannerData}
									setData={setHeroBannerData}
									handleAcceptedFiles={handleAcceptedFiles}
									type="banner"
									handleDataChanged={handleDataChanged}
									handleShowOptions={handleShowOptions}
								/>
							)} */}
						</div>
					</div>
				</div>
			</div>
		</HelmetLayout>
	);
};

export default ScheduledReportsModal;
