import {
	LOGIN_USER,
	LOGIN_SUCCESS,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
	API_ERROR,
	GET_USER,
	GET_USER_SUCCESS,
	GET_USER_ERROR,
	CMS_LOGIN_USER,
	CMS_SET_BET_HISTORY_EMAIL,
	GET_GS_DATA,
	CMS_SET_TRASACTION_HISTORY_EMAIL,
	CMS_SEARCH_FILTER_DATA,
	SET_IS_LOGIN_PAGE,
	CMS_SET_TRANSACTION_HISTORY_DATE_FROM,
	CMS_SET_TRANSACTION_HISTORY_DATE_TO,
	ACTIVE_PAGE,
	SET_COUNTRY,
	SET_LANGUAGE,
} from "./actionTypes";

export const loginUser = (user, history) => {
	return {
		type: LOGIN_USER,
		payload: { user, history },
	};
};

export const loginSuccess = user => {
	return {
		type: LOGIN_SUCCESS,
		payload: user,
	};
};

export const logoutUser = history => {
	return {
		type: LOGOUT_USER,
		payload: { history },
	};
};

export const logoutUserSuccess = () => {
	return {
		type: LOGOUT_USER_SUCCESS,
		payload: {},
	};
};

export const apiError = error => {
	return {
		type: API_ERROR,
		payload: error,
	};
};

export const getUser = () => {
	return {
		type: GET_USER,
	};
};

export const getUserSuccess = payload => {
	return {
		type: GET_USER_SUCCESS,
		payload,
	};
};

export const getUserError = payload => {
	return {
		type: GET_USER_ERROR,
		payload,
	};
};

export const cmsLoginUser = payload => {
	return {
		type: CMS_LOGIN_USER,
		payload,
	};
};

export const cmsSetBetHistoryEmail = payload => {
	return {
		type: CMS_SET_BET_HISTORY_EMAIL,
		payload,
	};
};

export const cmsSetTransactionHistoryDateFrom = payload => {
	return {
		type: CMS_SET_TRANSACTION_HISTORY_DATE_FROM,
		payload,
	};
};

export const cmsSetTransactionHistoryDateTo = payload => {
	return {
		type: CMS_SET_TRANSACTION_HISTORY_DATE_TO,
		payload,
	};
};

export const getGSData = payload => {
	return {
		type: GET_GS_DATA,
		payload,
	};
};

export const cmsSetTransactionHistoryEmail = payload => {
	return {
		type: CMS_SET_TRASACTION_HISTORY_EMAIL,
		payload,
	};
};

export const cmsSearchFilterData = payload => {
	return {
		type: CMS_SEARCH_FILTER_DATA,
		payload,
	};
};

export const appSetIsLoginPage = payload => {
	return {
		type: SET_IS_LOGIN_PAGE,
		payload,
	};
};

export const setActivePage = payload => {
	return {
		type: ACTIVE_PAGE,
		payload,
	};
};

export const setCountry = payload => {
	return {
		type: SET_COUNTRY,
		payload,
	};
};

export const setLanguage = payload => {
	return {
		type: SET_LANGUAGE,
		payload,
	};
};
