import React from "react";
import { DangerIcon, WarningIcon } from "utils/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShowInfoModal } from "store/layout/actions";
import { isEmpty } from "hooks/General";

import "./InfoModal.scss";

const InfoModal = props => {
	const dispatch = useDispatch();
	const infoModalData = useSelector(state => state.Layout.infoModalData);

	const object = isEmpty(props) ? infoModalData : props;

	const { title, message, buttonText, redirect, message_type } = object;

	const closeModal = () => {
		dispatch(setShowInfoModal(false));
	};

	// useEffect(() => {
	// 	return () => {
	// 		dispatch(setInfoModalData({}));
	// 	};
	// });

	return (
		<div className="info-modal-ctn">
			<div className="info-modal">
				<div className="icon">
					{message_type == "critical" && <DangerIcon />}
					{message_type == "warning" && <WarningIcon />}
				</div>
				<h2>{title}</h2>
				<p dangerouslySetInnerHTML={{ __html: message }}></p>

				{redirect ? (
					<Link to={redirect} className="button" onClick={closeModal}>
						{buttonText}
					</Link>
				) : (
					<button className="button" onClick={closeModal}>
						Ok
					</button>
				)}
			</div>
		</div>
	);
};

export default InfoModal;
