// import { checkIfImageWhite, getBase64 } from "hooks/General";
import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner } from "reactstrap";
import { NoButton, UploadButton, YesButton } from "../../components/GeneralComponents/AddButton";
import { getImages } from "services/getImages";
import saveImages from "services/addMultipleImages";
import { deleteImages } from "services/deleteImages";
import { getImageThumbnail } from "utils/global";
import { casinoTypes } from "utils/constants";
import { XCloseIcon } from "utils/icons";
import useOutsideClick from "hooks/useOutsideClick";
import useWindowSize from "hooks/useWindowSize";
import { breakpoints } from "utils/constants";

import "./ImageLibrary.scss";

const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;

export const ImageLibrary = ({
	data,
	setData,
	// handleAcceptedFiles,
	accept = "image/*",
	type,
	imageFor,
	setShowSave,
	className,
	handleDataChanged,
	mediaLibraryUpload,
	getImagesMediaLibrary,
	pageLayoutType,
	setIsLoading,
	property = "image",
	handleShowOptions,
	handleShowLibrary,
}) => {
	const inputRef = useRef(null);
	const imageLibraryInner = useRef(null);

	const [library, setLibrary] = useState([]);
	const [loading, setLoading] = useState(false);
	const [uploading, setUploading] = useState(false);

	const [addedImageData, setAddedImageData] = useState(null);
	const [checkDeleteLoading, setCheckDeleteLoading] = useState(null);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [selectedActualImage, setSelectedActualImage] = useState(null);
	const [imageUsed, setImageUsed] = useState("");
	const imagePath = aws_s3_url + "/media/";

	const { width } = useWindowSize();
	const isMobile = width <= breakpoints.PHONE;

	const handleClick = () => {
		inputRef.current.click();
	};

	const handleFileChange = async event => {
		setIsLoading?.(true);
		setUploading(true);
		const checked = true;

		let imageValue = event.target.files;

		const response = await saveImages(imageValue, type, checked);

		setIsLoading?.(false);
		setUploading(false);

		if (response) {
			let addedImageData = response?.data?.files[0];
			setAddedImageData(addedImageData);
			// handleAcceptedFiles?.(fileObj);
			if (type == "seo_image") {
				setData({
					...data,
					fav_icon: addedImageData?.filename,
					seo_image_exist: true,
					seo_image: addedImageData?.filename,
				});
			} else {
				setData(
					{
						...data,
						image: addedImageData?.filename,
						image_exist: true,
						header_banner: addedImageData?.filename,
						header_banner_exist: true,
					},
					{
						image: addedImageData?.filename,
					}
				);
			}
			getAllImages();
			handleDataChanged();
			if (mediaLibraryUpload) {
				getImagesMediaLibrary(pageLayoutType);
			}
		}
	};

	const getAllImages = () => {
		setLoading(true);
		getImages(type)
			.then(response => {
				setLibrary(response);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const imageType = () => {
		if (type === "seo_image") {
			return "seo_menu_links";
		} else if (type === "menu_links") {
			return "menu_links";
		} else if (type === "footer_images") {
			return "footer_links";
		} else if (type === "bet_slip" || type === "carousel" || type === "banner" || type === "sport_widget" || type === "racing_widget") {
			return "cms_items";
		} else if (Object.values(casinoTypes).includes(type)) {
			return "casino";
		}
	};

	const deleteImage = () => {
		let payload = {
			id: [showConfirmDelete],
			action: "delete",
			type: imageType(),
		};
		deleteImages(payload)
			.then(response => {
				if (response.success) {
					getAllImages();
				}
				let newImages = [...library];
				newImages = newImages.filter(row => row.id !== showConfirmDelete);
				setLibrary(newImages);
				if (data?.image === addedImageData?.name || data?.image === selectedActualImage?.name) {
					setData({
						...data,
						image: null,
						image_exist: false,
					});
				} else if (data?.seo_image === addedImageData?.name || data?.seo_image === selectedActualImage?.name) {
					setData({
						...data,
						seo_image: null,
						seo_image_exist: false,
					});
				} else if (data?.header_banner === addedImageData?.name || data?.header_banner === selectedActualImage?.name) {
					setData({
						...data,
						header_banner: null,
						header_banner_exist: false,
					});
				}
				if (addedImageData?.id === showConfirmDelete) {
					setAddedImageData(null);
				}
				handleDataChanged?.();
			})
			.finally(() => {
				setShowConfirmDelete(null);
			});
	};

	const handleCheckDelete = id => {
		setCheckDeleteLoading(id);
		let payload = {
			id: [id],
			action: "check",
			type: imageType(),
		};
		deleteImages(payload)
			.then(response => {
				let data = response.data[0];
				if (data[0]) {
					setImageUsed(data[0]?.type);
				}
				setShowConfirmDelete(id);
			})
			.finally(() => {
				setCheckDeleteLoading(null);
			});
	};

	useEffect(() => {
		if (type !== "menu_links" && type !== "seo_image") {
			getAllImages();
		}
		if (mediaLibraryUpload) {
			handleClick();
		}
	}, []);
	useEffect(() => {
		if (data?.dragAndDropDesktop) {
			handleFileChange(data);
		}
	}, [data?.dragAndDropDesktop]);

	useEffect(() => {
		getAllImages();
	}, [type]);

	const message = "This image is being used in :";
	const title =
		type == "bet_slip"
			? "Bet Slip"
			: type == "racing_widget"
			? "Racing Widget"
			: type == "sport_widget"
			? "Sport Widget"
			: type == "menu_links"
			? "Menu Links"
			: type;

	useEffect(() => {
		const inputElement = inputRef.current;

		if (inputElement) {
			inputElement.addEventListener("cancel", handleShowLibrary);

			return () => {
				inputElement.removeEventListener("cancel", handleShowLibrary);
			};
		}
	}, []);

	useOutsideClick(imageLibraryInner, () => {
		if (isMobile) handleShowOptions("imageLibrary", false);
	});

	return (
		<>
			{mediaLibraryUpload ? (
				<div className={className ? `${className} image-library-container` : `image-library-container`}>
					<div className="d-flex align-items-center justify-content-between ">
						<input style={{ display: "none" }} ref={inputRef} type="file" accept={accept} multiple onChange={handleFileChange} />
					</div>
				</div>
			) : (
				<div className="image-library">
					<div className={className ? `${className} image-library-container` : `image-library-container`} ref={imageLibraryInner}>
						<div className="upload_button_container">
							<span className="image-library-title">Image Library</span>
							<input style={{ display: "none" }} ref={inputRef} type="file" accept={accept} multiple onChange={handleFileChange} />
							<UploadButton name={"Upload"} onClick={handleClick} classname="upload_button_in_media_library" loading={uploading} />
						</div>
						<div className="uploaded-library">
							{loading ? (
								<div className="images-library-spinner">
									<Spinner animation={"border"} size={"sm"} />
								</div>
							) : (
								<>
									<div className="row library-images-row">
										<div className="title_library_image">
											<p>{title}</p>
											<XCloseIcon fill={"rgba(46, 58, 73, 1)"} onClick={() => handleShowOptions("imageLibrary", false)} />
										</div>
										{library.map((row, index) => {
											return (
												<div
													className={
														(row?.is_white === 1 || row?.is_white === 0) && row?.image === data[property]
															? "white-image library-image-container active-images"
															: row?.is_white === 1
															? "white-image library-image-container"
															: "library-image-container"
													}
													key={index}
												>
													<button
														className="btn btn-danger btn-sm delete-library-image delete-image-btn"
														onClick={() => {
															handleCheckDelete(row.id);
															setSelectedActualImage({ id: row?.id, name: row?.image });
														}}
													>
														{checkDeleteLoading === row.id ? <Spinner animation={"border"} size={"sm"} /> : "x"}
													</button>
													{row?.file_type.indexOf("video") > -1 ? (
														<div className="library-image-container library-img-div">
															<video
																className="VideoInput_video"
																width="80%"
																height={"100"}
																controls
																src={imagePath + row?.image}
																alt={row?.image}
																key={index}
																onClick={() => {
																	if (imageFor === "header_banner") {
																		setData({
																			...data,
																			header_banner: row?.image,
																			header_banner_file: row?.file_type,
																			header_banner_exist: true,
																		});
																	} else {
																		setData(
																			{
																				...data,
																				image: row?.image,
																				image_file_type: row?.file_type,
																				image_exist: true,
																			},
																			row
																		);
																	}
																	handleDataChanged();
																	setSelectedActualImage({ id: row?.id, name: row?.image });
																	if (setShowSave) {
																		setShowSave(true);
																	}
																}}
															/>
														</div>
													) : (
														<>
															<img
																src={imagePath + getImageThumbnail(row?.image) || imagePath + getImageThumbnail(row?.header_banner)}
																alt={row?.image}
																key={index}
																onClick={() => {
																	if (imageFor === "header_banner") {
																		setData({
																			...data,
																			header_banner: row?.image,
																			header_banner_exist: true,
																			image_exist: true,
																		});
																	}
																	if (type == "seo_image") {
																		setData({
																			...data,
																			seo_image: row?.image,
																			image_exist: true,
																		});
																	} else {
																		setData(
																			{
																				...data,
																				image: row?.image,
																				image_exist: true,
																			},
																			row
																		);
																	}
																	handleDataChanged();
																	setSelectedActualImage({ id: row?.id, name: row?.image });
																	if (setShowSave) {
																		setShowSave(true);
																	}
																}}
															/>
														</>
													)}
												</div>
											);
										})}
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			)}
			{showConfirmDelete && (
				<SweetAlert title={"Delete images"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div>
							<span className="delete-span">Are you sure you want to delete image ?</span>
							<span className="delete-span">
								{imageUsed
									? imageUsed.includes("bet_slip")
										? `${message} Bet Slip`
										: imageUsed.includes("sport_widget")
										? `${message} Sport Widget`
										: imageUsed.includes("banner")
										? `${message} Banner`
										: imageUsed.includes("carousel")
										? `${message} Carousel`
										: imageUsed.includes("racing_widget")
										? `${message} Racing Widget`
										: ""
									: ""}
							</span>
						</div>
					</div>
					<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
						<NoButton onClick={() => setShowConfirmDelete(false)} name="No" />
						<YesButton name="Yes" onClick={deleteImage} />
					</div>
				</SweetAlert>
			)}
		</>
	);
};
