import React, { useState, useEffect } from "react";
import { GeneralDropdown, GeneralDatePicker, GeneralInput, GeneralFilterDropdown } from "components/GeneralComponents/CustomInputs";
import { equalityOptions } from "./rules";
// import { InfoIcon } from "utils/icons";

const RuleValue = ({ rule, selectedRule, changeConditionValue, multipleValues, changeCondition, hasThirdValue, rules }) => {
	const { first_value, second_value, condition_type, condition_second, third_value } = rule;
	const { selected_type, placeholder, equality, optional, filterDropdown, onStart, disabled, dependecies } = selectedRule;

	const [options, setOptions] = useState([]);
	const [initialValue, setInitialValue] = useState(null);
	const [loadingOptions, setLoadingOptions] = useState(false);

	let optionDep = dependecies?.(rules);

	useEffect(() => {
		if (selected_type === "select" || selected_type === "array") {
			if (filterDropdown) getFilterOptionsData();
			else getOptionsData();
		}
	}, [selected_type, condition_type, filterDropdown, JSON.stringify(optionDep)]);

	const getFilterOptionsData = async (input = "") => {
		setLoadingOptions(true);
		const optionsData = await selectedRule?.options(input, rule, rules);
		setLoadingOptions(false);

		setOptions(
			optionsData.map(({ id, label }) => ({
				id: id + "",
				label,
			}))
		);
	};

	const onStartGetValues = async () => {
		const initial = await onStart?.(value?.split(","));
		setInitialValue(
			initial.map(({ id, label }) => ({
				id: id + "",
				label,
			}))
		);
	};

	const getOptionsData = async () => {
		const optionsData = await selectedRule.options(rules);
		setOptions(optionsData);
	};

	const value = multipleValues ? (hasThirdValue ? third_value : second_value) : first_value;
	const key = multipleValues ? (hasThirdValue ? "third_value" : "second_value") : "first_value";

	const changeValue = (condition_type, newValue) => {
		const optionalTypes = { [`${key}_type`]: newValue?.length || !optional ? selected_type : "" };
		const value = { ...rule, [key]: newValue, ...optionalTypes };

		changeConditionValue(condition_type, value);
	};

	useEffect(() => {
		if (value) {
			if (onStart) onStartGetValues();
		}
	}, []);

	let ruleDisabled = disabled?.(rules) || false;

	if (selected_type == "equality") {
		return (
			<div className="equality-dropdown">
				<GeneralDropdown
					object={{
						value: condition_second || "",
						options: equalityOptions,
						disabled: equality,
						defaultPlaceholder: " ",
					}}
					handleChange={e => changeCondition(condition_type, e?.id, true)}
				/>
			</div>
		);
	}

	if (selected_type == "select" || selected_type == "array") {
		const isMultiple = selected_type == "array";

		if (filterDropdown)
			return (
				<div className="rule-container">
					<GeneralFilterDropdown
						object={{
							value: isMultiple ? (value ? value.split(",") : []) : value,
							...(isMultiple ? { multipleOptions: initialValue || [] } : {}),
							options: options,
							multiple: isMultiple,
							defaultPlaceholder: placeholder || "Select",
							loading: loadingOptions,
							disabled: ruleDisabled,
						}}
						handleRemove={e => {
							const newValue = [...value.split(",").filter(id => id !== e?.id.toString() || !id)].join(",");

							changeValue(condition_type, newValue);
							setInitialValue(null);
						}}
						handleChange={e => {
							const newValue = isMultiple ? [...value.split(","), e?.id].filter(id => id).join(",") : e?.id;
							changeValue(condition_type, newValue);
							setInitialValue(null);
						}}
						handleFilter={e => {
							getFilterOptionsData(e);
						}}
					/>
					{/* <InfoIconButton info={info} disabled={ruleDisabled} /> */}
				</div>
			);

		return (
			<div className="rule-container">
				<GeneralDropdown
					object={{
						value: isMultiple ? value?.split(",") : value,
						bindValue: value,
						options: options,
						multiple: isMultiple,
						defaultPlaceholder: placeholder || "Select",
						disabled: ruleDisabled,
						loading: loadingOptions,
					}}
					handleRemove={e => {
						const newValue = [...value.split(",").filter(id => id !== e?.id.toString() || !id || !options.find(item => item.id == id))].join(",");
						changeValue(condition_type, newValue);
					}}
					handleChange={e => {
						const newValue = isMultiple ? [...value.split(","), e?.id].filter(id => id && options.find(item => item.id == id)).join(",") : e?.id;
						changeValue(condition_type, newValue);
					}}
				/>
				{/* <InfoIconButton info={info} disabled={ruleDisabled} /> */}
			</div>
		);
	}

	if (selected_type == "string" || selected_type == "number") {
		return (
			<div className="rule-container">
				<GeneralInput
					handleChange={value => {
						const newValue = value;
						changeValue(condition_type, newValue);
					}}
					object={{
						value: value,
						defaultPlaceholder: placeholder || "Amount",
						numbersOnly: selected_type == "number",
						disabled: ruleDisabled,
					}}
				/>
				{/* <InfoIconButton info={info} disabled={ruleDisabled} /> */}
			</div>
		);
	}

	if (selected_type == "date") {
		return (
			<div className="rule-container">
				<GeneralDatePicker
					handleChange={e => {
						const newValue = e;
						changeValue(condition_type, newValue);
					}}
					object={{
						value: value,
						otherRowClass: true,
						defaultPlaceholder: "Date",
						disabled: ruleDisabled,
					}}
				/>

				{/* <InfoIconButton info={info} disabled={ruleDisabled} /> */}
			</div>
		);
	}

	if (typeof selected_type == "object") {
		return (
			<div className="two-values">
				{selected_type.map((type, index) => {
					const newRule = { ...rule, selected_type: type.selected_type };

					const hasThirdValue = type?.index ? +type?.index >= 2 : index >= 2;

					return (
						<>
							<RuleValue
								rule={newRule}
								selectedRule={type}
								key={index}
								changeConditionValue={changeConditionValue}
								multipleValues={index > 0}
								changeCondition={changeCondition}
								hasThirdValue={hasThirdValue}
								rules={rules}
							/>
						</>
					);
				})}
			</div>
		);
	}

	return (
		<GeneralInput
			object={{
				value: "",
				readOnly: true,
			}}
		/>
	);
};

// const InfoIconButton = ({ info, disabled }) => {
// 	const onIconClick = () => {};
// 	return info && disabled ? <InfoIcon className="rule-info" onClick={onIconClick} fill="#8A98AB" /> : <></>;
// };

export default RuleValue;
