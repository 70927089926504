import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let result = [];
let prevSlug = "";

export const getLadders = async (sport_slug = "master", fractionalDefault) => {
	if (prevSlug == sport_slug) return result;

	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.get_ladder + `${sport_slug}`, resolve, reject, true);
	})
		.then(response => {
			const user = JSON.parse(localStorage.getItem("user"));

			let ladders = response.data.map(({ in_decimal, in_fraction }) => {
				const format = user.odds_display == "fractional" ? in_fraction : in_decimal;

				return {
					id: fractionalDefault ? in_fraction : format,
					label: format,
					in_fraction,
					in_decimal,
				};
			});

			result = ladders;
			prevSlug = sport_slug;
			return ladders;
		})
		.catch(() => {
			result = [];
			prevSlug = "";
			return [];
		});
};
