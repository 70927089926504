import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

const BonusEngineDetails = forwardRef(({ details, setDetails, setDataChanged }, ref) => {
	const [fields, setFields] = useState([]);

	useImperativeHandle(ref, () => ({
		fields,
	}));

	useEffect(() => {
		const tempFields = [];

		if (details?.id) {
			tempFields.push({
				type: "input",
				name: "Id",
				fieldName: "id",
				value: details?.id,
				disabled: true,
			});
		}

		tempFields.push(
			{ type: "switch", name: "Published", fieldName: "published" },
			{ type: "input", name: "Name", fieldName: "name", placeholder: "Required", required: true },
			{ type: "textarea", name: "Description", fieldName: "description", textareaClassName: "general_input", placeholder: "Optional" },
			{
				type: "date",
				name: "Valid From (UTC)",
				fieldName: "valid_from",
				value: details.valid_from,
				required: true,
			},
			{ type: "switch", name: "Never Expire", fieldName: "never_expire" }
		);

		if (!details?.never_expire) {
			tempFields.push({
				type: "date",
				name: "Valid To (UTC)",
				fieldName: "valid_to",
				value: details.valid_to,
				required: true,
			});
		}

		tempFields.push({
			type: "textarea",
			name: "Terms and Conditions",
			fieldName: "terms_and_conditions",
			textareaClassName: "general_input",
			placeholder: "Optional",
		});

		setFields(tempFields);
	}, [details?.never_expire, details?.id]);

	const dependecies = { id: details?.id, never_expire: details?.never_expire };
	return (
		<div>
			<AllComponentsLoop fields={fields} data={details} setData={setDetails} setDataChanged={setDataChanged} dependecies={dependecies} />
		</div>
	);
});

BonusEngineDetails.displayName = "BonusEngineDetails";

export default BonusEngineDetails;
