import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { getResultTabelColums } from "./marketTableColumns";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import { _resultMatchesOptions } from "utils/constants";
import { EditRresultsMarketMatch } from "./EditRresultsMarketMatch";
import { tableBarSettings } from "utils/tableSettings";

export const ResultsMatches = ({ resultData, getResultsMatch }) => {
	const [resultEditMatch, setResultEditMatch] = useState(false);
	const [resultEditData, setResultEditData] = useState({
		id: "",
		selection_name: "",
		result: "",
		partial_win: "",
	});
	const [filterData, setFilterData] = useState({
		result: "",
	});

	const handleEdit = record => {
		setResultEditData({
			...resultEditData,
			id: record.id,
			selection_id: record.selection_id,
			selection_name: record.market_name,
			result: record.result,
			partial_win: "",
			partial_push: "",
		});
		setResultEditMatch(true);
	};

	let resultsColums = getResultTabelColums({ resultData, handleEdit });

	const handleChangeDropdown = result => {
		setFilterData({ result });
	};

	const filteredResultData =
		filterData.result === ""
			? resultData
			: resultData.filter(resultRecord => {
					if (filterData.result === "resulted") return resultRecord.result !== "OPEN";
					if (filterData.result === "not_resulted") return resultRecord.result === "OPEN";
			  });

	return (
		<>
			<div className="result-input">
				<GeneralDropdown
					object={{
						defaultPlaceholder: "Choose result",
						label: "All",
						options: _resultMatchesOptions,
						wrapperClassNameDropdown: "w-100 justify-content-start",
					}}
					handleChange={e => {
						handleChangeDropdown(e?.id);
					}}
				/>
			</div>
			{resultEditMatch && (
				<EditRresultsMarketMatch
					setResultEditMatch={setResultEditMatch}
					setResultEditData={setResultEditData}
					resultEditData={resultEditData}
					getResultsMatch={getResultsMatch}
				/>
			)}
			<MUIDataTable
				columns={resultsColums}
				data={filteredResultData}
				options={{
					search: false,
					selectableRows: "none",
					elevation: 0,
					downloadOptions: { filename: "otr" + ".csv" },
					responsive: "",
					rowsPerPage: 0,
					rowsPerPageOptions: [],
					download: false,
					customSearch: false,
					filter: false,
					fixedSelectColumn: false,
					jumpToPage: false,
					textLabels: {
						body: {
							noMatch: "No data to display!",
						},
					},
					print: false,
					resizableColumns: false,
					rowHover: false,
					searchOpen: false,
					selectableRowsOnClick: false,
					viewColumns: false,
					pagination: false,
					...tableBarSettings,
				}}
			/>
		</>
	);
};
