import React from "react";
import { getFormatDateByUserTimezone } from "utils/global";
export const getActivityLogTableColumns = ({ records }) => {
	return [
		{
			name: "player_id",
			label: "PLAYER ID",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<a href={"/user/" + record.swifty_id} target={"_blank"} rel="noreferrer" className="real_id_class">
								{record.player_id}
							</a>
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "50px", minWidth: "50px" } }),
				setCellHeaderProps: () => ({ style: { width: "50px", minWidth: "50px" } }),
			},
		},
		{
			name: "activity_name",
			label: "ACTIVITY NAME",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "160px", minWidth: "160px", overflowWrap: "anywhere" } }),
				setCellHeaderProps: () => ({ style: { width: "160px", minWidth: "160px" } }),
			},
		},
		{
			name: "createdAt",
			label: "DATE",
			className: "sport",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: value => {
					return <div className="text-right">{getFormatDateByUserTimezone(value, "YYYY-MM-DD HH:mm:ss")}</div>;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "140px", minWidth: "140px", overflowWrap: "anywhere" } }),
				setCellHeaderProps: () => ({ style: { width: "140px", minWidth: "140px" } }),
			},
		},
		{
			name: "cloudfront_viewer_contry",
			label: "VIEW FROM",
			className: "region",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "50px", minWidth: "50px", overflowWrap: "anywhere" } }),
				setCellHeaderProps: () => ({ style: { width: "50px", minWidth: "50px" } }),
			},
		},
		{
			name: "response_http_code",
			label: "HTTP",
			className: "response_http_code",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "50px", minWidth: "50px", overflowWrap: "anywhere" } }),
				setCellHeaderProps: () => ({ style: { width: "50px", minWidth: "50px" } }),
			},
		},
		{
			name: "x_forwarded_for",
			label: "IP",
			className: "gender",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "100px", minWidth: "100px", overflowWrap: "anywhere" } }),
				setCellHeaderProps: () => ({ style: { width: "100px" } }),
			},
		},
		{
			name: "aws_request_id",
			label: "AWS REQUEST ID",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "150px", minWidth: "150px", overflowWrap: "anywhere" } }),
				setCellHeaderProps: () => ({ style: { width: "150px", minWidth: "150px" } }),
			},
		},
		{
			name: "user_agent",
			label: "USER AGENT",
			className: "status_name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "100px", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { width: "100px", minWidth: "100px" } }),
			},
		},
	];
};
