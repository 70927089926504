import { SET_MATCHES } from "./actionTypes";

const initialState = {
	matches: [],
};

const MatchesReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_MATCHES:
			return {
				...state,
				matches: action.payload,
			};
		default:
			return state;
	}
};

export default MatchesReducer;
