import MetaTags from "react-meta-tags";
import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import images
import { loginLogo, platformName } from "utils/constants";
import SignInFooter from "./SignInFooter";

const Blocked = () => {
	useEffect(() => {
		document.body.classList.add("login-body");
		return () => {
			document.body.classList.remove("login-body");
		};
	}, []);
	return (
		<React.Fragment>
			<MetaTags>
				<title>Blocked | CMS | {platformName}</title>
			</MetaTags>
			<div className="account-pages my-0 pt-sm-5 my-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5} className="login-flow-card">
							<Card className="overflow-hidden login-card">
								<h3 className="default-logo-login text-center mt-5">
									<div className="d-block auth-logo">
										<img src={loginLogo} alt="" height="60" width="200" className="auth-logo-dark" style={{ cursor: "auto" }} />
									</div>
								</h3>
								<div className="blocked-content">
									<h4 className="text-center blocked-title">
										Your account is blocked, <br /> please contact your administrator
									</h4>
								</div>
								<a href="login" className="blocked-reset">
									Return to login page
								</a>
							</Card>
							<SignInFooter />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(connect()(Blocked));
