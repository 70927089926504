import React from "react";
import HTMLReactParser from "html-react-parser";
import { EditIconButton } from "../../../components/GeneralComponents/AddButton";
import { useHistory } from "react-router-dom";
const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;

export const getSportTableColumns = ({ records }) => {
	const history = useHistory();
	return [
		{
			name: "icon",
			label: "ICON",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "126px", height: "42px", alignItems: "center", display: "flex" }}> Icon </div>
						</>
					);
				},
				customBodyRender: value => {
					const record = value;
					return (
						<div className="sportsIcon">
							<img style={{ width: "20px", height: "20px" }} src={aws_s3_url + "/sport/icon/" + record + `?${new Date()}`} alt="Icon" />
						</div>
					);
				},
			},
		},
		{
			name: "name",
			label: "SPORT",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "200px", height: "42px", alignItems: "center", display: "flex" }}> Sport </div>
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
			},
		},
		{
			name: "status_txt",
			label: "STATUS",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "600px", height: "42px", alignItems: "center", display: "flex" }}> Status </div>
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left" } }),
				setCellHeaderProps: () => ({ className: "max-button" }),
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					var status_txt =
						record.active == 0
							? `<span className="badge badge-sports-inactive"> Inactive </span>`
							: `<span className="badge badge-sports-published"> Published </span>`;
					return HTMLReactParser(status_txt);
				},
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<div className="d-flex" style={{ justifyContent: "flex-end" }}>
								<div className="sportTypes-buttons" style={{ borderRadius: "0" }}>
									<EditIconButton
										onClick={() => {
											history.push(`/sport/${row?.slug}`);
										}}
									/>
								</div>
							</div>
						</>
					);
				},
			},
		},
	];
};
