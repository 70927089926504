import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, Alert, Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ApiServices from "utils/ApiServices";
import xIcon from "../../assets/images/x-icon.svg";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import { loginLogo, platformName } from "utils/constants";
import SignInFooter from "./SignInFooter";
import { passwordRequirementsCheck } from "utils/global";

const ResetPassword = props => {
	const [spinningLogin, setSpinningLogin] = useState(false);

	const email = localStorage.getItem("email");

	const handleValidSubmit = (event, values) => {
		setSpinningLogin(true);

		if (values.code === "") {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter the code</p>
				</>,
				{ className: "custom-toast" }
			);
			setSpinningLogin(false);
			return;
		}
		const passwordCheck = passwordRequirementsCheck(values.password);

		if (!passwordCheck?.length || !passwordCheck?.specialCharacter || !passwordCheck?.number) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">
						Password must contain at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a symbol.
					</p>
				</>,
				{ className: "custom-toast" }
			);
			setSpinningLogin(false);
			return;
		} else if (values.password !== values.confirmPassword) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Passwords do not match</p>
				</>,
				{ className: "custom-toast" }
			);
			setSpinningLogin(false);
			return;
		}

		var body = {
			email: email,
			code: values.code,
			password: values.password,
		};
		// new global axios helper
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.new_main + "/api/v1/auth/confirm-password", resolve, reject, body, false);
		})
			.then(response => {
				if (response) {
					props.history.push("/password_changed");
				}
			})
			.finally(() => {
				setSpinningLogin(false);
			});
	};

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setConfirmShowPassword] = useState(false);
	const eyeIcon = showPassword ? faEyeSlash : faEye;
	const eyeConfirmIcon = showConfirmPassword ? faEyeSlash : faEye;

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const toggleConfirmPasswordVisibility = () => {
		setConfirmShowPassword(!showConfirmPassword);
	};

	useEffect(() => {
		document.body.classList.add("login-body");
		return () => {
			document.body.classList.remove("login-body");
		};
	}, []);
	return (
		<React.Fragment>
			<MetaTags>
				<title>Enter your new password | CMS | {platformName}</title>
			</MetaTags>
			<div className="account-pages my-0 pt-sm-5 my-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5} className="login-flow-card">
							<Card className="overflow-hidden login-card reset-card">
								<h3 className="default-logo-login text-center mt-5">
									<div className="d-block auth-logo">
										<img src={loginLogo} alt="" height="60" width="200" className="auth-logo-dark" style={{ cursor: "auto" }} />
									</div>
								</h3>
								<h4 className="login-title">Check your email</h4>
								<div className="mfa-text mt-2 mfa-title">
									We sent a password reset link to <span style={{ color: "#2E3A49" }}>{email}</span>
								</div>
								<AvForm
									className="form-horizontal reset-form"
									onValidSubmit={(e, v) => {
										handleValidSubmit(e, v);
									}}
								>
									{props.error && typeof props.error === "string" ? <Alert color="danger">{props.error}</Alert> : null}
									<div className="mb-4 login-label">
										<div className="reset-label">
											<AvField name="code" label="Code" className="form-control login-input" placeholder="Code" type="code" />
										</div>
										<div className="password-input-wrapper">
											<AvField
												name="password"
												label="Password"
												placeholder="Password"
												type={showPassword ? "text" : "password"}
												className="login-input"
												onInput={e => {
													e.preventDefault();
												}}
											/>
											<span className="password-toggle-icon" onClick={togglePasswordVisibility}>
												<FontAwesomeIcon icon={eyeIcon} />
											</span>
										</div>
									</div>
									<div className="login-label">
										<div className="password-input-wrapper">
											<AvField
												name="confirmPassword"
												label="Confirm Password"
												placeholder="Confirm Password"
												type={showConfirmPassword ? "text" : "password"}
												className="login-input"
												onInput={e => {
													e.preventDefault();
												}}
											/>
											<span className="password-toggle-icon" onClick={toggleConfirmPasswordVisibility}>
												<FontAwesomeIcon icon={eyeConfirmIcon} />
											</span>
										</div>
									</div>
									<div className="row reset-button">
										<div className="login-label">
											<button className="login-button form-control" type="submit">
												{spinningLogin ? <Spinner animation="border" size="sm" /> : "Reset Password"}
											</button>
										</div>
										<div className="check-reset-footer">
											Remember Password?
											<Link to="/login" className="reset-resend">
												Sign in here
											</Link>
										</div>
									</div>
								</AvForm>
							</Card>
							<SignInFooter />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(connect()(ResetPassword));
