import React from "react";
import FlagIcon from "../../assets/images/flag-selected.svg";
import EmptyFlagIcon from "../../assets/images/empty_flag.svg";
import { useDispatch, useSelector } from "react-redux";
import { setBackdrop } from "../../store/Backdrop/actions";
import { setBetReferralModal } from "../../store/Modals/actions";
import { setSelectedBetReferral } from "../../store/SelectedBet/actions";
import { setBetTickerFlag } from "../../store/BetTicker/actions";
import ApiServices from "../../utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { ThousandSeperated } from "hooks/General";
import { capitalize } from "lodash";
import presentIcon from "../../assets/images/present-icon.svg";
// import { decimalToFractional } from "utils/decimalToFractional";
import { getFormatDateByUserTimezone } from "utils/global";

export const getColumnStructure = dataForTable => {
	const dispatch = useDispatch();
	const betTicketList = useSelector(state => state.BetTickerData.betTickerList);
	const user = JSON.parse(localStorage.getItem("user"));

	const getStatusClass = value => {
		switch (value) {
			case "select":
				return "bet-status-orange";
			case "rejected":
				return "bet-status-red";
			case "Auto Cancelled":
				return "bet-status-red";
			case "approved":
				return "bet-status-green";
			case "new offer":
				return "bet-status-blue";
			default:
				return "bet-status-grey";
		}
	};

	const findOriginBet = id => {
		const data = betTicketList.find(item => item.id === id);
		const formatedData = JSON.parse(JSON.stringify(data));
		return formatedData;
	};

	const selectBtnHandler = rowData => {
		dispatch(setBackdrop(true));
		dispatch(setBetReferralModal(true));
		dispatch(setSelectedBetReferral({ ...findOriginBet(rowData.id) }));
	};

	const flagSelectedHandler = row => {
		new Promise((resolve, reject) => {
			ApiServices.post(`${apiUrls.EDIT_FLAG_BET_TICKER}/${row.id}`, resolve, reject, { flag_enabled: !row.flag }, true);
		}).then(response => {
			if (response) {
				dispatch(
					setBetTickerFlag({
						id: row.id,
						flag: !row.flag,
					})
				);
			}
		});
	};

	const getOnclickAction = (value, rowData) => {
		switch (value) {
			case "select":
				return selectBtnHandler(rowData);
			case "rejected":
				return false;
			case "approved":
				return selectBtnHandler(rowData);
			default:
				return false;
		}
	};

	return [
		{
			name: "status",
			label: "STATUS",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					if (value) {
						const row = dataForTable[meta.currentTableData[meta.rowIndex].index];

						const valueBtnName = value => {
							value?.toLowerCase();
							switch (value) {
								case "pending":
									return "select";
								case "new_offer":
									return "new offer";
								case "auto_cancelled":
									return "Auto Cancelled";
								default:
									return value;
							}
						};

						return (
							<div className="bet-status-wrapper">
								<button
									className={`bet-ticker-status ${getStatusClass(valueBtnName(value))}`}
									onClick={() => getOnclickAction(valueBtnName(value), row)}
								>
									{valueBtnName(value)}
								</button>
								{value !== "accepted" ? (
									<img
										className="bet-flag-selected"
										width={16}
										height={16}
										src={row?.flag ? FlagIcon : EmptyFlagIcon}
										alt="flag"
										onClick={() => flagSelectedHandler(row)}
									/>
								) : (
									<div style={{ width: "16px", height: "16px" }} />
								)}
							</div>
						);
					} else {
						return "";
					}
				},
			},
		},
		{
			name: "created_at",
			label: "TIME",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: item => {
					const createdAt = getFormatDateByUserTimezone(item);
					return <>{createdAt}</>;
				},
				setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "user_id",
			label: "PLAYER",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = dataForTable[meta.currentTableData[meta.rowIndex].index];

					return (
						<div className="bet-user-hover-wrapper">
							<div>{value}</div>
							<div className="bet-user-hover">{row?.player_name?.toUpperCase()}</div>
						</div>
					);
				},
			},
		},
		{
			name: "stake_factor",
			label: "SF",
			sort: "asc",
			sortable: true,
			options: {
				setCellHeaderProps: () => ({ className: "align-right" }),
				setCellProps: () => ({ style: { textAlign: "right" } }),
			},
		},
		{
			name: "bet_type",
			label: "TYPE",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = dataForTable[meta.currentTableData[meta.rowIndex].index];

					const { bet_type, in_play } = row;

					return (
						<div className="bet-status-wrapper">
							{in_play && "In Play"}
							{!in_play &&
								bet_type
									?.replace(/[-_]/g, " ")
									.split(" ")
									.map(word => capitalize(word))
									.join(" ")}
						</div>
					);
				},
				setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "sport_name",
			label: "SPORT",
			sort: "asc",
			sortable: true,
		},
		{
			name: "competition_name",
			label: "COMPETITION",
			sort: "asc",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "match_name",
			label: "EVENT",
			sort: "asc",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "market_name",
			label: "MARKET",
			sort: "asc",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "selection_name",
			label: "SELECTION",
			sort: "asc",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
			},
		},
		{
			name: "return",
			label: "PRICE",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: (value, meta) => {
					const row = dataForTable[meta.currentTableData[meta.rowIndex].index];

					if (row?.type !== "single") return row.odds_decimal;

					return user.odds_display == "fractional"
						? row.odds_fractional
						: row.odds_decimal !== "?" && row.odds_decimal !== "SP"
						? Number(row.odds_decimal).toFixed(2)
						: row.odds_decimal;
				},
				setCellHeaderProps: () => ({ className: "align-right" }),
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "150px" } }),
			},
		},
		{
			name: "each_way",
			label: "EW",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: value => {
					return value ? "Yes" : "No";
				},
			},
		},
		{
			name: "bog_applicable",
			label: "BOG",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: value => {
					return value ? "Yes" : "No";
				},
			},
		},
		{
			name: "type",
			label: "BET TYPE",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: value => {
					return (
						<div>
							{value
								?.replace(/[-_]/g, " ")
								.split(" ")
								.map(word => capitalize(word))
								.join(" ")}
						</div>
					);
				},
			},
		},
		{
			name: "stake",
			label: "STAKE PER LINE",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: (value, tableMeta) => {
					return (
						<div className="d-flex justify-content-end">
							{dataForTable[tableMeta.rowIndex].is_free_bet && <img src={presentIcon} alt="free bet" className="me-1" />}
							{ThousandSeperated(value)}
						</div>
					);
				},
				setCellHeaderProps: () => ({ className: "align-right" }),
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "150px" } }),
			},
		},
		{
			name: "total_stake",
			label: "TOTAL STAKE",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: value => {
					if (value) return ThousandSeperated(value);
				},
				setCellHeaderProps: () => ({ className: "align-right" }),
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "150px" } }),
			},
		},
		{
			name: "max_bet",
			label: "MAX BET",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: value => {
					return ThousandSeperated(value);
				},
				setCellHeaderProps: () => ({ className: "align-right" }),
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "150px" } }),
			},
		},
		{
			name: "max_line_stake",
			label: "MAX LINE STAKE",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: value => {
					return ThousandSeperated(value);
				},
				setCellHeaderProps: () => ({ className: "align-right" }),
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "150px" } }),
			},
		},
		{
			name: "max_allowed_bet",
			label: "SPL / MAB",
			sort: "asc",
			sortable: true,
			options: {
				customBodyRender: value => {
					return ThousandSeperated(value) + "%";
				},
				setCellHeaderProps: () => ({ className: "align-right" }),
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "150px" } }),
			},
		},
	];
};

export const getBetInput = (formRef, bet_id) => {
	if (formRef.current && formRef.current.elements) {
		return formRef.current.elements.namedItem(bet_id);
	}
	return null;
};

export const toFixed = item => Number(item).toFixed(2);

export const toFixedFloat = (num, fractionDigits = 2) => {
	let result = typeof num === "string" || typeof num === "number" ? num : 0;

	if (typeof result === "string") {
		result = result.replace(/,/g, "");
		result = Number(result);
	}

	return +result.toFixed(fractionDigits).replace(/,/g, "");
};
