import React, { useEffect, useRef, useState } from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import { ModalButton } from "components/GeneralComponents/CustomInputs";
import { getNotes } from "services/getNotes";
import { isEmptyObject, userReadOnly } from "utils/global";
import { PinnedIcon, UnPinnedIcon, XCloseIcon } from "utils/icons";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import deleteIcon from "../../assets/images/delete.svg";
import { toast } from "react-toastify";
import xIcon from "../../assets/images/x-icon.svg";
import moment from "moment";
import ConfirmModal from "./ConfirmModal";

import "./UserNotes.scss";

function UserNotes(props) {
	const { userData, extraItem, getUserTab } = props;

	// const activeTab = localStorage.getItem("activeTab");
	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	const notesRef = useRef({});

	const [notes, setNotes] = useState([]);
	const [newNote, setNewNote] = useState("");
	const [unchangedNotes, setUnchangedNotes] = useState([]);
	const [textAreaHeight, setTextAreaHeight] = useState("40px");
	const [suspendSpining, setSuspendSpinning] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [deleteItem, setDeleteItem] = useState({});

	const handleAddUserNotes = () => {
		if (!notes) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter notes</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}
		setSuspendSpinning(true);
		let payload = {
			user_id: userData.id,
			swifty_id: extraItem?.user_id,
			notes: newNote,
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls?.notes, resolve, reject, payload, true);
		})
			.then(async response => {
				const { success } = response;
				if (success) {
					if (extraItem?.user_id) {
						const allNote = await getNotes(extraItem?.user_id);

						setNotes(allNote);
						setUnchangedNotes(allNote);
					}
				}
			})
			.finally(() => {
				setNewNote("");
				setSuspendSpinning(false);
				setTextAreaHeight("35px");
			});
	};
	const handleClickPinnedNotes = row => {
		const id = row?.id;
		const noteValue = row?.pinned == 1 ? 0 : 1;
		const payload = { note_id: id, value: noteValue };
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls?.edit_note, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) {
					const updatedNotes = notes.map(item => {
						if (item.id === id) {
							return { ...item, pinned: noteValue };
						}
						return item;
					});

					setNotes(updatedNotes);
					// setUnchangedNotes(updatedNotes);
				}
			})
			.finally(() => {});
	};
	const handleDeleteNotes = id => {
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls?.EDIT_USER_NOTES + `/${id}`, resolve, reject, true);
		}).then(() => {
			const updatedNotes = notes.filter(item => item?.id != id);
			setNotes(updatedNotes);
			setDeleteItem({});
		});
	};

	const handleNoteChange = (e, row) => {
		const { value } = e.target;
		const updatedNotes = notes.map(item => {
			if (item?.id === row?.id) {
				return { ...item, notes: value };
			} else return item;
		});
		setNotes(updatedNotes);

		// Auto-resize the textarea based on the content
		const textarea = notesRef.current[row.id];
		if (textarea) {
			textarea.style.height = "auto";
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
	};

	const handleEditNote = row => {
		let payload = {
			user_id: userData?.id,
			swifty_id: userData?.swifty_id,
			notes: row?.notes,
			id: row?.id,
		};
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls?.EDIT_USER_NOTES, resolve, reject, payload, true);
		}).then(() => {
			setUnchangedNotes(notes);
		});
	};
	const handleEditClick = id => {
		setDisabled(false);

		const currentNoteRef = notesRef.current[id];
		if (currentNoteRef) {
			currentNoteRef.focus();
			const length = currentNoteRef.value.length;
			currentNoteRef.setSelectionRange(length, length);
		}
	};

	const getNote = async () => {
		const allNote = await getNotes(extraItem?.user_id);
		setNotes(allNote);
		setUnchangedNotes(allNote);
	};
	useEffect(() => {
		if (extraItem?.user_id) {
			getNote();
		}
	}, [extraItem?.user_id]);

	const sortedNotes = [...notes].sort((a, b) => b.pinned - a.pinned);

	useEffect(() => {
		notes.forEach(note => {
			const textarea = notesRef.current[note.id];
			if (textarea) {
				textarea.style.height = "auto";
				textarea.style.height = `${textarea.scrollHeight}px`;
			}
		});
	}, [notes]);

	return (
		<div className="overview-item-container">
			<div className="notes-container">
				<div className="notes-header">
					<span className="d-flex justify-content-end w-100">
						<XCloseIcon fill={"#000"} onClick={() => getUserTab("overview", 0)} />
					</span>
					<h2>{"Notes"}</h2>
				</div>
				<div className="notes-body">
					<div className={`w-100 h-100  ${isUserReadOnly ? "pe-none" : ""}`}>
						<textarea
							className="col-12"
							style={{
								height: textAreaHeight,
								border: "2px solid #EAECEF",
								padding: "8px 12px",
							}}
							value={newNote}
							placeholder="Add new note"
							onChange={e => setNewNote(e.target.value)}
							onClick={() => setTextAreaHeight("100px")}
						></textarea>
						{textAreaHeight == "100px" && (
							<div className="col-12 d-flex justify-content-end">
								<ModalButton
									object={{
										name: "",
										buttonName: "Save",
										position: "p-0",
										isLoading: suspendSpining,
										onClickEvent: () => handleAddUserNotes(),
									}}
								/>
							</div>
						)}

						{sortedNotes.map((row, index) => {
							const pinned = row?.pinned == 1 ? true : false;
							const createdAtFormat = row?.created_at ? moment(row.created_at).format("DD MMM YYYY HH:mm") : "-";

							const unchangedNote = unchangedNotes.find(note => note.id === row.id);
							return (
								<div className={`notes_container`} key={index}>
									<div className="d-flex justify-content-between mb-2 notes_title_and_description">
										<span>Last Edited: {row?.last_edit_name}</span>
										<span>{createdAtFormat}</span>
									</div>
									<textarea
										value={row?.notes}
										disabled={disabled}
										className="w-100"
										style={{ background: "transparent", border: "none", padding: "10px 5px", overflow: "hidden", resize: "none" }}
										onChange={e => handleNoteChange(e, row)}
										ref={el => (notesRef.current[row.id] = el)}
										onFocus={() => {
											const currentNoteRef = notesRef.current[row.id];
											if (currentNoteRef) {
												const { value } = currentNoteRef;
												currentNoteRef.value = "";
												currentNoteRef.value = value;
											}
										}}
									/>
									<div className="d-flex justify-content-between mb-2 notes_tag_description">
										<span>{row?.tag}</span>
										<div className="d-flex justify-content-between align-items-center w-100 mt-3">
											<div>
												{pinned ? (
													<PinnedIcon pinned={pinned} onClick={() => handleClickPinnedNotes(row)} />
												) : (
													<UnPinnedIcon pinned={pinned} onClick={() => handleClickPinnedNotes(row)} />
												)}
											</div>
											<div className="d-flex align-items-center gap-1">
												{unchangedNote ? (
													unchangedNote.notes === row.notes ? (
														<EditIconButton
															key={`edit_${unchangedNote.id}`}
															className={"edit_icon_notes"}
															onClick={() => handleEditClick(row.id)}
														/>
													) : (
														<ModalButton
															key={`modal_${unchangedNote.id}`}
															object={{
																name: "",
																buttonName: "Save",
																position: "note_save_button",
																onClickEvent: () => handleEditNote(row),
															}}
														/>
													)
												) : null}

												<DeleteIconButton className={"delete_icon_notes"} deleteIcon={deleteIcon} onClick={() => setDeleteItem(row)} />
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
					{!isEmptyObject(deleteItem) && (
						<ConfirmModal
							title="Confirm Deletion Notes"
							paragraf={`Are you sure you want to delete the note?`}
							handlerSave={() => handleDeleteNotes(deleteItem?.id)}
							handlerClose={() => setDeleteItem({})}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default UserNotes;
