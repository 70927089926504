import { SET_USERS, SET_RECENT_VIEW_USERS, SET_AFFILIATE_USERS } from "./actionTypes";

export const setUsers = payload => {
	return {
		type: SET_USERS,
		payload,
	};
};

export const setRecentViewedUsers = payload => {
	return {
		type: SET_RECENT_VIEW_USERS,
		payload,
	};
};

export const setAffiliateUsers = payload => {
	return {
		type: SET_AFFILIATE_USERS,
		payload,
	};
};
