import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "../../utils/tableSettings";
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
// import ApiServices from "../../utils/ApiServices";
import "./betTicker.css";
import { getColumnStructure } from "./BetTicketHelpers";
import { useSelector } from "react-redux";
// import { setBetTickerData } from "../../store/BetTicker/actions";
// import { apiUrls } from "utils/const.apiUrl";
import { assignRowClasses } from "utils/global";
import BetReferralFilter from "./betReferralModal/betReferralFilter";
import { Search } from "@mui/icons-material";

const BetTicker = () => {
	const [isDataLoading] = useState(false);
	// const dispatch = useDispatch();
	const betTicketList = useSelector(state => state.BetTickerData.betTickerList);
	const [currentPage, setCurrentPage] = useState(0);
	const [filteredDatas, setFilteredDatas] = useState();
	const [rowPerPage, setRowPerPage] = useState(100);
	const [bgColors, setBgColors] = useState([]);
	const [modalShow, setModalShow] = useState(false);
	const [filterCriteria, setFilterCriteria] = useState({
		bets_over: [],
		sport_name: [],
		user_id: [],
		country: [],
		country_id: [],
	});

	const prepareData = betTickerArray => {
		return betTickerArray.flatMap(betTicker => {
			let result = [];
			const combinations = [];

			if (betTicker?.bet_slip?.combinations.length > 0) {
				betTicker?.bet_slip?.combinations
					.filter(item => item?.stake > 0)
					.map(item => {
						item.stake = item.new_stake ?? item.stake;
						item.total_stake = item.new_stake ?? item.total_stake;
						item.max_line_stake = item.new_stake ?? item.max_line_stake;
						combinations.push(item);
					});
			}

			const singles = betTicker?.bet_slip?.singles?.map(innerObj => {
				return {
					...innerObj,
					// user_id: betTicker.player_id,
					player_name: betTicker.player_name,
					stake: innerObj.new_stake ?? innerObj.stake,
					total_stake: innerObj.new_stake ?? innerObj.total_stake,
					max_line_stake: innerObj.new_stake ?? innerObj.max_line_stake,
					bet_type: innerObj.bet_type || "pre_match",
				};
			});

			result = [...combinations, ...singles];

			result = result.map((item, index) =>
				index == 0
					? {
							...item,
							total_payout: betTicker?.bet_slip.total_payout,
							created_at: betTicker.created_at,
							flag: betTicker.flag,
							id: betTicker.id,
							status: betTicker.status,
							user_id: betTicker.player_id,
							player_name: betTicker.player_name,
					  }
					: item
			);

			return result;
		});
	};

	// const getBetsData = () => {
	// 	setIsDataLoading(true);

	// 	new Promise((resolve, reject) => {
	// 		ApiServices.get(apiUrls.GET_BET_TICKER_LIST, resolve, reject, true);
	// 	})
	// 		.then(response => {
	// 			dispatch(setBetTickerData(response.data));
	// 		})
	// 		.finally(() => {
	// 			setIsDataLoading(false);
	// 		});
	// };

	// useEffect(() => {
	// 	getBetsData();
	// }, []);

	const openModal = () => {
		if (!modalShow) {
			setModalShow(true);
		}
	};

	const closeModal = () => {
		if (modalShow) {
			setModalShow(false);
		}
	};

	const filterData = () => {
		const filteredData = betTicketList.filter(row => {
			// Bets Over
			const result = row?.bet_slip?.singles?.map(innerObj => {
				return innerObj;
			});
			const betsOverFilter = parseFloat(filterCriteria.bets_over);
			const stakeMatch =
				filterCriteria.bets_over === "" ||
				(row?.bet_slip?.total_stakes && row?.bet_slip?.total_stakes >= filterCriteria.bets_over) ||
				(result && result.some(obj => parseFloat(obj.total_stake) === betsOverFilter));

			// Player ID
			const playerMatch = filterCriteria.user_id === "" || (row.player_id && row.player_id.toString().includes(filterCriteria.user_id));

			// Sport Type
			const sportSlugs = row.sport_slugs ? row.sport_slugs.split(",") : [];
			const sportMatch =
				filterCriteria.sport_name.length === 0 ||
				filterCriteria.sport_name.some(filterSport => sportSlugs.some(slug => slug.toLowerCase().startsWith(filterSport.toLowerCase())));

			// User Country
			const countryMatch = filterCriteria.country.length === 0 || (row.player_country && filterCriteria.country.includes(row.player_country));

			return playerMatch && sportMatch && stakeMatch && countryMatch;
		});
		return filteredData;
	};

	const dataForTables = prepareData(filteredDatas || betTicketList);

	useEffect(() => {
		if (dataForTables?.length) assignRowClasses(dataForTables, setBgColors, "created_at");
	}, [betTicketList]);

	return (
		<div className="bet-ticker-page">
			{modalShow && (
				<BetReferralFilter
					closeModal={closeModal}
					filteredData={filteredDatas}
					filterData={filterData}
					filterCriteria={filterCriteria}
					setFilterCriteria={setFilterCriteria}
					setFilteredDatas={setFilteredDatas}
					betTicketList={betTicketList}
				/>
			)}
			<MUIDataTable
				columns={getColumnStructure(dataForTables)}
				data={dataForTables}
				options={{
					selectableRows: "none",
					elevation: 0,
					onChangePage(page) {
						setCurrentPage(page);
					},
					onChangeRowsPerPage(number) {
						setRowPerPage(number);
					},
					textLabels: {
						body: {
							noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
						},
					},
					setRowProps: (row, dataIndex, rowIndex) => {
						return {
							className: `${bgColors[rowIndex]}`,
						};
					},
					customToolbar: () => {
						return (
							<button className="MuiIconButton-sizeMedium" onClick={openModal}>
								<Search />
							</button>
						);
					},
					page: currentPage,
					responsive: tableResponsive,
					rowsPerPage: rowPerPage,
					rowsPerPageOptions: tableRowsPerPage,
					...tableBarSettings,
					viewColumns: true,
				}}
				extraButtons={tableExtraButtons}
			/>
		</div>
	);
};

export default BetTicker;
