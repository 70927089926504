import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

export const getCasinoGamesInfo = async gameSlugs => {
	if (gameSlugs?.length) {
		const payload = { gameSlugs };
		return new Promise((resolve, reject) => {
			ApiServices.post(`${apiUrls.GET_CASINO_GAMES_INFO}`, resolve, reject, payload, true);
		})
			.then(response => {
				let games = response?.data;

				games = games.map(row => {
					return { id: row.slug, label: `${row.title} | ${row.slug}` };
				});
				return games;
			})
			.catch(() => {
				return [];
			});
	} else {
		return [];
	}
};
