import React, { useEffect, useRef, useState } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { AvForm } from "availity-reactstrap-validation";
import { XCloseIcon } from "utils/icons";
import { Editor } from "@tinymce/tinymce-react";
import "./landingPages.css";
import { downFields, upFields } from "./landingFields";

const translateData = ["title", "explainer_text", "offer_header", "offer_text", "terms", "seo_title", "seo_description", "seo_keywords"];

const editorApiUrl = process.env.REACT_APP_EDITOR_API_KEY;
const LandingPagesModal = props => {
	const [dataChanged, setDataChanged] = useState(false);
	const [imageType, setImageType] = useState("");
	const [loading, setLoading] = useState(false);
	const [landingData, setLandingData] = useState({
		id: "",
		title: "",
		desktop_image: "",
		mobile_image: "",
		explainer_text: "",
		offer_header: "",
		offer_text: "",
		terms: "",
		affiliate_id: "",
		published: "",
		seo_title: "",
		seo_description: "",
		seo_image: "",
		seo_keywords: "",
	});
	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const filterTerms = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_landing_pages + "?id=" + props.match.params.id, resolve, reject, true);
		}).then(response => {
			let data = response?.data?.data;
			const [landing] = data;
			if (landing) {
				const translatedData = transformLandingData(landing, translateData);
				setLandingData({ ...landing, ...translatedData });
			} else {
				const translatedData = transformLandingData(landingData, translateData);
				setLandingData({ ...landing, ...translatedData });
			}
		});
	};

	const transformLandingData = (landing, landingData) => {
		let updatedLandingData = {};
		landingData.forEach(d => {
			const value = landing[d];
			if (value) {
				const parsedValue = JSON.parse(value);
				updatedLandingData = {
					...updatedLandingData,
					...parsedValue,
				};
			}
		});
		return updatedLandingData;
	};

	const createTranslatedJSON = (data, keysToTranslate) => {
		const result = {};

		keysToTranslate.forEach(item => {
			const translations = {};
			Object.entries(data).forEach(([key, value]) => {
				if (key.startsWith(item)) {
					translations[key] = value;
				}
			});
			result[item] = Object.keys(translations).length ? JSON.stringify(translations) : "";
		});
		return result;
	};

	const handleImageUpload = (_, data) => {
		if (showOptions.image === "seo_image") {
			setLandingData({ ...landingData, seo_image: data.image });
		}
		if (showOptions.image === "desktop_image") {
			setLandingData({ ...landingData, desktop_image: data.image });
		}
		if (showOptions.image === "mobile_image") {
			setLandingData({ ...landingData, mobile_image: data.image });
		}
	};

	const toggleKycEnabled = () => {
		setLandingData(prevState => ({
			...prevState,
			published: prevState.published == 1 ? 0 : 1,
		}));
	};

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
		if (value === "seo_image" || value === "desktop_image" || value === "mobile_image") {
			setImageType("seo");
		}
	};

	const addLandingPages = () => {
		setLoading(true);
		let body = {
			...landingData,
		};

		if (!landingData?.id) {
			delete body["id"];
		}
		if (!landingData?.published) {
			body["published"] = 0;
		}

		const translations = createTranslatedJSON(body, translateData);
		body = { ...body, ...translations };

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.add_landing_pages, resolve, reject, body, true);
		})
			.then(response => {
				if (response?.success) {
					props.history.push("/promo_landing_pages");
					setDataChanged(false);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const dependecies = {
		seo_image: landingData?.seo_image,
		desktop_image: landingData?.desktop_image,
		mobile_image: landingData?.mobile_image,
		affiliateUserTag: landingData?.affiliate_id,
	};

	useEffect(() => {
		filterTerms();
	}, []);

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	let disabledAffiliate = !landingData?.id;

	const editorRef = useRef(null);
	const fieldsArgs = { handleShowOptions, showOptions, landingData, setLandingData, setDataChanged, disabledAffiliate, onFocus };
	const upFieldsArray = upFields({
		...fieldsArgs,
	});

	const downFieldsArray = downFields({
		...fieldsArgs,
		toggleKycEnabled,
	});
	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Promo Landing Pages</span>
				<XCloseIcon
					onClick={() => {
						props.history.push("/promo_landing_pages");
					}}
					className={"xclose-icon"}
				/>
			</div>
			<div className="promotion-body">
				<TopBarComponent
					saveHandler={addLandingPages}
					loading={loading}
					dataChanged={dataChanged}
					onClose={() => props.history.push("/promo_landing_pages")}
				/>

				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AvForm
							id="landingForm"
							onClick={() => {
								!onFocus.current && handleShowOptions();
							}}
						>
							<AllComponentsLoop
								fields={upFieldsArray}
								data={landingData}
								setData={setLandingData}
								setDataChanged={setDataChanged}
								dependecies={dependecies}
							/>
							<div>
								<div className="row wrapper_customized_modal">
									<div className="col-12 col-lg-4 d-flex align-items-center input-title">Offer Text</div>
									<div className="editor-texts">
										<Editor
											apiKey={editorApiUrl}
											onInit={(evt, editor) => (editorRef.current = editor)}
											value={landingData?.offer_text}
											onEditorChange={(e, editor) => {
												const newContent = editor.getContent();
												setLandingData({
													...landingData,
													offer_text: newContent,
												});
												handleDataChanged();
											}}
											init={{
												height: 200,
												menubar: false,
												visual: false,
												plugins: [
													"advlist",
													"autolink",
													"lists",
													"link",
													"image",
													"charmap",
													"preview",
													"anchor",
													"searchreplace",
													"visualblocks",
													"code",
													"fullscreen",
													"insertdatetime",
													"media",
													"table",
													"code",
													"help",
													"wordcount",
												],
												toolbar:
													"undo redo | blocks | " +
													"bold italic forecolor link | alignleft aligncenter " +
													"alignright alignjustify | bullist numlist outdent indent | " +
													"removeformat | help | code",
												content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
											}}
											onFocus={() => handleShowOptions("language", "offer_text")}
											// onBlur={() => (onFocus.current = false)}
										/>
									</div>
								</div>
							</div>
							<div>
								<div className="row wrapper_customized_modal">
									<div className="col-12 col-lg-4 d-flex align-items-center input-title">Terms</div>
									<div className="editor-texts">
										<Editor
											apiKey={editorApiUrl}
											onInit={(evt, editor) => (editorRef.current = editor)}
											value={landingData?.terms}
											onEditorChange={(e, editor) => {
												const newContent = editor.getContent();
												setLandingData({
													...landingData,
													terms: newContent,
												});
												handleDataChanged();
											}}
											init={{
												height: 200,
												menubar: false,
												visual: false,
												plugins: [
													"advlist",
													"autolink",
													"lists",
													"link",
													"image",
													"charmap",
													"preview",
													"anchor",
													"searchreplace",
													"visualblocks",
													"code",
													"fullscreen",
													"insertdatetime",
													"media",
													"table",
													"code",
													"help",
													"wordcount",
												],
												toolbar:
													"undo redo | blocks | " +
													"bold italic forecolor link | alignleft aligncenter " +
													"alignright alignjustify | bullist numlist outdent indent | " +
													"removeformat | help | code",
												content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
											}}
											onFocus={() => handleShowOptions("language", "terms")}
											// onBlur={() => (onFocus.current = false)}
										/>
									</div>
								</div>
							</div>
							<AllComponentsLoop
								fields={downFieldsArray}
								data={landingData}
								setData={setLandingData}
								setDataChanged={setDataChanged}
								dependecies={dependecies}
							/>
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6 special_promotion_pages landing-language h-100">
						{showOptions.language && (
							<LanguageTitle
								data={landingData}
								setData={setLandingData}
								title={showOptions.language}
								handleDataChanged={handleDataChanged}
								editorRef={showOptions.language === "offer_text" || showOptions.language === "terms" ? editorRef : false}
							/>
						)}

						{showOptions.image && (
							<ImageLibrary
								data={landingData}
								setData={handleImageUpload}
								onChange={handleImageUpload}
								handleDataChanged={handleDataChanged}
								type={imageType}
								property={showOptions.image}
								handleShowOptions={handleShowOptions}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default LandingPagesModal;
