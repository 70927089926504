import React from "react";
import { EditIconButton } from "components/GeneralComponents/AddButton";
import { MapMarketIconLogo } from "utils/icons";

export const getVenueTableColumns = ({ venuesData, handleEditItem }) => {
	return [
		{
			name: "id",
			label: "id",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				// Check if this is an saved report, if so check if that column is showing
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "150px", height: "42px", alignItems: "center", display: "flex" }}> id</div>
						</>
					);
				},
			},
		},
		{
			name: "name",
			label: "Name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "200px", height: "42px", alignItems: "center", display: "flex" }}> Name</div>
						</>
					);
				},
			},
		},
		{
			name: "capacity",
			label: "Capacity",
			align: "right",
			sortable: true,
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "200px", height: "42px", alignItems: "center", display: "flex" }}> Capacity</div>
						</>
					);
				},
			},
		},
		{
			name: "region_name",
			label: "Region Name",
			align: "right",
			sortable: true,
			options: {
				customHeadLabelRender: () => {
					return (
						<>
							<div style={{ width: "200px", height: "42px", alignItems: "center", display: "flex" }}> Region Name</div>
						</>
					);
				},
			},
		},
		{
			name: "location",
			label: "Location",
			align: "right",
			sortable: true,
			options: {
				customHeadLabelRender: () => {
					return <div style={{ width: "550px", height: "42px", alignItems: "center", display: "flex" }}> Location </div>;
				},
				customBodyRender: (value, meta) => {
					const record = venuesData[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							{record.location != "-" ? (
								<a className="location-mark" href={`http://maps.google.com/?ll=${record.location}`} target="_blank" rel="noreferrer">
									<MapMarketIconLogo />
								</a>
							) : (
								record.location
							)}
						</>
					);
				},
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = venuesData[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons" style={{ marginRight: "10px" }}>
								<EditIconButton
									onClick={() => {
										handleEditItem(row);
									}}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "30px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "30px" } }),
			},
		},
	];
};
