import React, { useState, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { ActiveExpandIcon, ExpandIcon } from "utils/icons";
import { Chart as ChartJS, CategoryScale, LinearScale, LineController, BarElement, Title, LineElement, Tooltip, PointElement, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, LineController, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const numberOfTicks = 6;

const chartDataFnc = (data, labels) => {
	const datasets = data?.map(dataset => ({
		backgroundColor: dataset.color,
		fill: false,
		bezierCurve: false,
		order: dataset.type == "bar" ? 1 : 0,
		pointRadius: 0,
		barThickness: 10,
		// barPercentage: 0.5,
		categoryPercentage: 1,
		borderColor: [dataset.color],
		yAxisID: dataset?.position == "left" ? "A" : "B",
		...dataset,
	}));

	return {
		labels: labels,
		datasets: datasets,
	};
};

const processNumber = (limit, n) => {
	if (n < 0) {
		const newNumber = Math.floor(n / limit) * limit;
		return newNumber;
	}
	if (n < limit) {
		return limit;
	} else {
		const newNumber = Math.ceil(n / limit) * limit;
		return newNumber;
	}
};

const findMax = array => {
	return Math.max.apply(
		Math,
		array.map(a => Math.max.apply(null, a))
	);
};

const findMin = array => {
	return Math.min.apply(
		Math,
		array.map(a => Math.min.apply(null, a))
	);
};

const calcMinMax = (leftSideStep, righSideStep, leftMinMax, rightMinMax) => {
	let left = Math.abs(leftMinMax) / leftSideStep;
	let right = Math.abs(rightMinMax) / righSideStep;

	if (leftSideStep == 0 && leftMinMax == 0) left = 0;
	if (righSideStep == 0 && rightMinMax == 0) right = 0;

	const step = Math.max(left, right);
	const sign = Math.sign(step == left ? leftMinMax : rightMinMax);

	return step < 1 ? sign * 1 : sign * step;
};

const formatNumber = (number, withIncrement = false) => {
	const [n] = String(number).split(".");
	if (String(n).length > 2) {
		var d = Math.pow(10, String(n).length - 2);
		const increment = 5 * d;
		const range = Math.ceil(n / d) * d;

		if (withIncrement) {
			if (range % increment === 0) {
				return range;
			} else {
				const nearest = range + d / 2;
				const a = Math.ceil(nearest / increment) * increment;

				return a;
			}
		}

		return range;
	} else {
		if (Number(n) < 10) {
			return n;
		} else {
			return Math.ceil(n / 10) * 10;
		}
	}
};

const getBarInfo = data => {
	//get left side and right side data
	const leftData = data.filter(({ position }) => position == "left").map(({ data }) => data);
	const rightData = data.filter(({ position }) => position == "right").map(({ data }) => data);

	// find the max and min for each side
	const leftYMax = findMax(leftData);
	const rightYMax = findMax(rightData);
	const leftMin = findMin(leftData);
	const rightMin = findMin(rightData);

	// Left Side Calc
	let newLeftYMax = formatNumber(leftYMax);
	const minMaxLeft = Math.max(Math.abs(newLeftYMax), Math.abs(leftMin));
	let leftYStep = +formatNumber(formatNumber(minMaxLeft) / numberOfTicks, true);

	if (leftYStep == 0) leftYStep = 10;
	// Right Side Calc
	let newRightYMax = formatNumber(rightYMax);
	const minMaxRight = Math.max(Math.abs(newRightYMax), Math.abs(rightMin));
	const rightYStep = +formatNumber(formatNumber(minMaxRight) / numberOfTicks, true);

	// Calc the min value for both sides
	const minValue = calcMinMax(leftYStep, rightYStep, leftMin, rightMin);
	let newLeftMin = processNumber(leftYStep, minValue * leftYStep);
	let newRightMin = processNumber(rightYStep, minValue * rightYStep);

	newLeftMin = newLeftMin > 0 ? 0 : newLeftMin;
	newRightMin = newRightMin > 0 ? 0 : newRightMin;

	// Calc the max value from both sites
	const maxValue = calcMinMax(leftYStep, rightYStep, leftYMax, rightYMax);
	newLeftYMax = processNumber(leftYStep, maxValue * leftYStep);
	newRightYMax = processNumber(rightYStep, maxValue * rightYStep);

	newLeftYMax = newLeftYMax < 0 ? 0 : newLeftYMax;
	newRightYMax = newRightYMax < 0 ? 0 : newRightYMax;

	return [
		{ newLeftYMax, leftYStep, newLeftMin },
		{ newRightYMax, rightYStep, newRightMin },
	];
};

const getOptions = data => {
	const [{ newLeftYMax, leftYStep, newLeftMin }, { newRightYMax, rightYStep, newRightMin }] = getBarInfo(data);

	return {
		maintainAspectRatio: false,
		scales: {
			x: {
				grid: {
					display: false,
				},
				ticks: {
					autoSkip: false,
					beginAtZero: true,
				},
			},
			A: {
				position: "left",
				offset: true,
				type: "linear",
				display: true,
				grid: {
					display: true,
				},
				ticks: {
					max: newLeftYMax,
					stepSize: leftYStep.toFixed(2),
					min: newLeftMin,
					beginAtZero: true,
					callback: function (label) {
						return Number(label)?.toFixed(leftYStep < 1 ? 1 : 0);
					},
				},
			},
			B: data.some(d => d.position === "right")
				? {
						position: "right",
						offset: true,
						type: "linear",
						display: true,
						grid: {
							display: leftYStep === 0,
						},
						ticks: {
							max: newRightYMax,
							stepSize: rightYStep.toFixed(2),
							min: newRightMin,
							beginAtZero: true,
							callback: function (label) {
								return Number(label)?.toFixed(rightYStep < 1 ? 1 : 0);
							},
						},
				  }
				: null,
		},
		indexAxis: "x",
		elements: {
			bar: {
				borderWidth: 1,
				innerWidth: 10,
				padding: 0,
			},
			line: {
				tension: 0,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				position: "top",
				align: "center",
			},
		},
	};
};

const BarLine = ({ chart }) => {
	const barLine = useRef(null);
	const { title, data, labels, column } = chart;

	const chartData = chartDataFnc(data, labels);
	const options = getOptions(data);

	const [expand, setExpand] = useState(false);

	const toggleExpand = () => {
		setExpand(!expand);

		const elem = barLine.current;

		if (!expand) openFullscreen(elem);
		else closeFullscreen(elem);
	};

	const openFullscreen = elem => {
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Safari */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			/* IE11 */
			elem.msRequestFullscreen();
		}
	};

	const closeFullscreen = () => {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.webkitExitFullscreen) {
			/* Safari */
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) {
			/* IE11 */
			document.msExitFullscreen();
		}
	};

	return (
		<div className={`card ${column} ${expand ? "expand" : ""}`} ref={barLine}>
			<div className="bar-line">
				<div className="d-flex items-center justify-content-between">
					<h4>{title}</h4>

					<div onClick={toggleExpand}>{!expand ? <ActiveExpandIcon /> : <ExpandIcon />}</div>
				</div>
				<Bar data={chartData} options={options} />
			</div>
		</div>
	);
};

export default BarLine;
