import React, { useEffect, useState } from "react";
import { BetSlipPromotion } from "./betSlipPromotion";
import ApiServices from "utils/ApiServices";
import { getBetSlipTableColumns } from "./betSlipColumns";
import ConfirmDeleteComponent from "components/GeneralComponents/ConfirmDeleteComponent";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import { useDispatch, useSelector } from "react-redux";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";
// import MUIDataTable from "mui-datatables";
import DataTable from "components/GeneralComponents/Table";
import { getContent } from "services/getContentAllData";
import { InfoIconHelper } from "utils/icons";
import InformationPageHelper from "../InformationPageHelper";
import { BetslipHelpInformation } from "../HelpPagesForSettings/HelpPages";
import { setBackdrop } from "store/Backdrop/actions";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const Betslip = props => {
	const [showPromotion, setShowPromotion] = useState(false);
	const [betSlipData, setBetSlipData] = useState([]);
	const [editItem, setEditItem] = useState(null);
	const [deleteItem, setDeleteItem] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [itemTitle, setItemTitle] = useState("");
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);

	const dispatch = useDispatch();

	let country = useSelector(state => state.Login.country);
	const pageHeaderData = {
		international: false,
		addNew: true,
		addNewClick: () => {
			setShowPromotion(true);
		},
		removeHeader: true,
	};

	const betSlip = async () => {
		setIsDataLoading(true);
		if (!country || country === "undefined") {
			country = "all";
		}
		let response = await getContent("bet_slip", country);

		if (response?.length > 0) {
			response.forEach(element => {
				if (element?.active) {
					element["status"] = "Published";
				} else {
					element["status"] = "Inactive";
				}
				if (element?.details) {
					let parsedDetails = JSON.parse(element["details"]);
					parsedDetails["publish_start_date_time"] = element["publish_start_date_time"];
					parsedDetails["publish_stop_date_time"] = element["publish_stop_date_time"];
					parsedDetails["status"] = element["status"];
					element["details"] = JSON.stringify(parsedDetails);
				}
			});
			setBetSlipData(response);
			setUnfilteredData(response);
			setIsDataLoading(false);
		} else {
			setIsDataLoading(false);
			setBetSlipData([]);
			setUnfilteredData([]);
		}
	};

	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};

	const orderItems = newOrder => {
		let payload = {
			order: newOrder.map((row, index) => ({ id: row.id, order: index })),
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.order_bet_slip_item, resolve, reject, payload, true);
		}).then(() => {});
	};

	const changeOrder = index => {
		const selectedRow = unfilteredData.splice(index, 1)[0];
		const newData = [selectedRow, ...unfilteredData];
		setNewData(newData);
	};

	const setNewData = newData => {
		setBetSlipData(newData);
		setUnfilteredData(newData);
		orderItems(newData);
	};

	const betSlipTableColumns = getBetSlipTableColumns({
		betSlipData,
		props,
		handleEditItem,
		setDeleteItem,
		setItemTitle,
		changeOrder,
	});

	useEffect(() => {
		betSlip();
	}, [country]);

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};
	return (
		<>
			<HelmetLayout titleProps={"Betslip"}>
				<div className="cms-page pt-0">
					<SingleSearchFilter
						setData={setBetSlipData}
						unfilteredData={unfilteredData}
						detailsColumn={["title", "publish_start_date_time", "publish_stop_date_time", "status"]}
					/>

					<DataTable
						data={betSlipData}
						columns={betSlipTableColumns}
						setData={newData => {
							setNewData(newData);
						}}
						options={{
							showButtons: true,
							search: false,
							viewColumns: false,
							customToolbar: () => <AddTableButton className={"data-table-add-btn"} onClick={pageHeaderData["addNewClick"]} />,
						}}
						isLoading={isDataLoading}
						pagination={false}
						orderRows={true}
						sort={false}
					/>
				</div>
				{deleteItem && (
					<ConfirmDeleteComponent
						showDeleteCom={deleteItem}
						deleteLoading={deleteLoading}
						setDeleteLoading={setDeleteLoading}
						deleteTitle={"Confirm Deletion of Promotion"}
						titlename={itemTitle}
						setShowDeleteCom={setDeleteItem}
						betSlipData={betSlipData}
						setBetSlipData={setBetSlipData}
						id={deleteItem?.id}
					/>
				)}
				{showPromotion && (
					<BetSlipPromotion
						title={"Bet Slip Promotion"}
						editItem={editItem}
						countires={country}
						getBetSlip={betSlip}
						handler={() => {
							setShowPromotion(false);
							setEditItem(null);
						}}
					/>
				)}
				{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			</HelmetLayout>
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<BetslipHelpInformation />
				</InformationPageHelper>
			)}
		</>
	);
};

export default Betslip;
