import { ADD_BET_TICKER, REMOVE_BET_TICKER, SET_BET_TICKER_DATA, SET_BET_TICKER_FLAG, UPDATE_BET_TICKER, UPDATE_BET_TICKER_STATUS } from "./actionTypes";

const initialState = {
	betTickerList: [],
};

const BetTickerData = (state = initialState, action) => {
	switch (action.type) {
		case SET_BET_TICKER_DATA:
			return {
				...state,
				betTickerList: action.payload,
			};
		case SET_BET_TICKER_FLAG:
			return {
				...state,
				betTickerList: state.betTickerList.map(item => {
					if (item.id === action.payload?.id) {
						return {
							...item,
							flag: !item.flag,
						};
					}
					return item;
				}),
			};
		case ADD_BET_TICKER:
			return {
				...state,
				betTickerList: [action.payload, ...state.betTickerList],
			};
		case REMOVE_BET_TICKER:
			return {
				...state,
				betTickerList: state.betTickerList.filter(item => item.id !== action.payload?.id),
			};
		case UPDATE_BET_TICKER_STATUS:
			return {
				...state,
				betTickerList: state.betTickerList.map(item => {
					if (item.id === action.payload?.id) {
						return {
							...item,
							status: action.payload?.status,
						};
					}

					return item;
				}),
			};
		case UPDATE_BET_TICKER:
			return {
				...state,
				betTickerList: state.betTickerList.map(item => {
					if (item.id === action.payload?.id) {
						return {
							...item,
							bet_slip: {
								...item.bet_slip,
								singles: action.payload?.singles,
								combinations: action.payload?.combinations,
							},
						};
					}

					return item;
				}),
			};

		default:
			return state;
	}
};

export default BetTickerData;
