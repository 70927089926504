const tableColumns = columns => {
	let TableColumns = columns.map(col => ({ name: col == "#" ? "order" : col, label: col }));

	TableColumns = TableColumns.map((data, index) =>
		index > 1
			? {
					...data,
					options: {
						setCellProps: () => ({ style: { textAlign: "right" } }),
						setCellHeaderProps: () => ({ className: "align-right" }),
					},
			  }
			: data
	);

	return TableColumns;
};

export default tableColumns;
