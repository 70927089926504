import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { formatToLocalDatetime } from "hooks/General";
import { getTermsTableColumns } from "./termsTableColumns";
import { ShowDeleteTermsModal } from "./showDeleteTermsModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import getTerms from "services/getTerms";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const Terms = props => {
	const [rowPerPage, setRowPerPage] = useState(50);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [spinningStatus, setSpinningStatus] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [records, setRecords] = useState([]);
	const breadcrumbItems = [{ title: "Terms", link: "/terms" }];
	const [deleteId, setDeleteId] = useState("");

	useEffect(() => {
		props.setBreadcrumbItems("Competitions", breadcrumbItems);
	});

	const pageHeaderData = {
		international: false,
		addNew: true,
		addNewClick: () => {
			window.location.href = "/term/new";
		},
	};

	const filterTerms = async () => {
		setIsDataLoading(true);
		setCurrentPage(0);
		getTerms()
			.then(response => {
				let data = response;
				var arr = [];
				data?.map(terms => {
					if (terms.latest == 0) {
						return;
					}
					var insertedDtUtc = moment(terms?.insertedDt).utc().format("YYYY-MM-DD HH:mm:ss");
					var insertedDt = formatToLocalDatetime(insertedDtUtc);
					insertedDt = moment(insertedDt).format("YYYY-MM-DD HH:mm:ss");

					var updatedDtUtc = moment(terms?.updatedDt).utc().format("YYYY-MM-DD HH:mm:ss");
					var updatedDt = formatToLocalDatetime(updatedDtUtc);
					updatedDt = moment(updatedDt).format("YYYY-MM-DD HH:mm:ss");
					var country_name = terms.name === "null" ? "ALL" : terms.name != null ? terms.name : terms?.country?.toUpperCase();
					let highestVersion = data[0].version;

					data.forEach(term => {
						const currentVersion = term.version;
						if (currentVersion > highestVersion) {
							highestVersion = currentVersion;
						}
					});

					let version = terms.version;
					//Add latest to the last element
					if (terms.version === highestVersion) {
						version += " (Latest)";
					}

					let objIndex = arr.findIndex(obj => obj.country === country_name);

					if (objIndex !== -1 && terms.language !== terms.country) {
						if (!arr[objIndex].languages.includes(terms.language)) {
							arr[objIndex].languages.push(terms.language);
						}
					} else {
						let obj = {
							id: terms.id,
							version: version,
							content: terms.content,
							insertedDt: insertedDt,
							updatedDt: updatedDt,
							languages: [terms.language],
							country: country_name,
							latest: terms.latest,
							country_disabled: terms.country_disabled,
						};
						arr.push(obj);
					}
				});
				arr.sort((a, b) => a.version.localeCompare(b.version));
				setRecords(arr);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const submitDeleteTerm = () => {
		setSpinningStatus(true);
		setIsDataLoading(true);
		let payload = {
			id: deleteId,
			type: "term",
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.general_delete, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) {
					var currentVariable = records.filter(x => x.id != deleteId);
					setRecords(currentVariable);
					filterTerms();
				}
			})
			.finally(() => {
				setShowDeleteModal(false);
				setSpinningStatus(false);
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		filterTerms();
	}, []);

	let termsTableColumns = getTermsTableColumns({ props, records, setShowDeleteModal, setDeleteId });
	let page_name = "Terms";

	return (
		<HelmetLayout titleProps={"Terms"}>
			<ShowDeleteTermsModal
				showDeleteModal={showDeleteModal}
				setShowDeleteModal={setShowDeleteModal}
				submitDeleteTerm={submitDeleteTerm}
				spinningStatus={spinningStatus}
			/>
			<div className="cms-page no-search-filter">
				<MUIDataTable
					columns={termsTableColumns}
					data={records}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: `${page_name}.csv` },
						customToolbar: () => <AddTableButton onClick={pageHeaderData["addNewClick"]} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};
export default connect(null, { setBreadcrumbItems })(Terms);
