import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import getSportsData from "services/getSportsData";
import { setTelebetSearchCompetitions, setTelebetSearch, setTelebetSport, emptyTelebetBets, setTelebetMatch, setTelebetSportsGames } from "store/actions";
import { toCapitalCase } from "utils/global";
import { DarkSearchIcon, LeftDarkArrowIcon, RightDarkArrowIcon, XCloseIcon } from "utils/icons";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";
import { toast } from "react-toastify";
import xIcon from "assets/images/x-icon.svg";
import moment from "moment";
import { useDebounce } from "hooks/useDebounce";

export const TelebetSidebar = () => {
	const [unfilteredSports, setUnfilteredSports] = useState([]);
	const [sports, setSports] = useState([]);

	const telebetUser = useSelector(state => state.Telebet.telebetUser);
	const telebetSport = useSelector(state => state.Telebet.telebetSport);
	const telebetSearchCompetition = useSelector(state => state.Telebet.telebetSearchCompetition);
	const telebetSearchValue = useSelector(state => state.Telebet.telebetSearchValue);

	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();

	const getSports = () => {
		getSportsData().then(response => {
			setSports(response);
			setUnfilteredSports(response);
		});
	};

	const handleFilterSports = value => {
		if (!value) {
			setSports(unfilteredSports);
			dispatch(setTelebetSearchCompetitions([]));
		} else {
			const searchParam = `?name=${value}`;
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls.SEARCH_USER_COMPETITIONS + searchParam, resolve, reject, true);
			}).then(response => {
				const data = response?.data;
				dispatch(setTelebetSearchCompetitions(data));
			});
		}
	};

	const handleClick = row => {
		dispatch(setTelebetSport(row));
		dispatch(setTelebetSportsGames({}));
		history.push(`/telebet`);
	};

	useEffect(() => {
		getSports();

		const myTimeout = sessionStorage.getItem("telebetSearchTimeout");
		clearTimeout(myTimeout);

		return () => {
			const telebetSearchTimeout = setTimeout(() => {
				dispatch(setTelebetSearchCompetitions([]));
				dispatch(setTelebetSearch(""));
				dispatch(emptyTelebetBets());
			}, 2000);

			sessionStorage.setItem("telebetSearchTimeout", telebetSearchTimeout);
		};
	}, []);

	const firstAndLastName = toCapitalCase(telebetUser?.first_name + " " + telebetUser?.last_name);

	const handleClickRedirect = data => {
		const event_id = data?.event_id.startsWith("e-") ? data?.event_id.substring(2) : data?.event_id;

		const newData = {
			match_id: event_id,
			match_name: data?.event_name,
			start_time_utc: data?.event_start_time,
			competition_id: data?.competition_id,
			slug: data?.sport_slug,
		};
		dispatch(setTelebetMatch(newData));

		dispatch(setTelebetSport(sports?.find(({ slug }) => slug == data?.sport_slug)));
		history.push(`/telebet?event=${event_id}`);
	};

	const debouncedSearchValue = useDebounce(telebetSearchValue, 400);

	useEffect(() => {
		handleFilterSports(debouncedSearchValue);
	}, [debouncedSearchValue]);

	useEffect(() => {
		if (!telebetUser) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">{`Please select a user first to access telebet`}</p>
				</>,
				{ className: "custom-toast" }
			);
			history.push(`/search_users`);
		}
	}, [telebetUser]);

	return (
		<React.Fragment>
			<div className="telebet-vertical-menu p-0">
				<div className="h-100 w-100">
					<div className="sport-search-container" style={{ background: "#EAECEF" }}>
						<LeftDarkArrowIcon onClick={() => history.push(`/user/${telebetUser?.swifty_id}`)} />
						<span className="telebet-username">{telebetUser && firstAndLastName + " " + telebetUser?.player_id}</span>
					</div>
					<div className="sport-search-container" style={{ background: "#fff", display: "flex" }}>
						<DarkSearchIcon />
						<input
							type="text"
							placeholder="Search"
							className="sport-search-input"
							value={telebetSearchValue}
							onChange={e => {
								// setSearchValue(e.target.value);
								dispatch(setTelebetSearch(e.target.value));
							}}
						/>
						{telebetSearchValue && (
							<XCloseIcon
								fill="black"
								onClick={() => {
									dispatch(setTelebetSearchCompetitions([]));
									dispatch(setTelebetSearch(""));
								}}
							/>
						)}
					</div>
					<div className="sport-items-container">
						{telebetSearchCompetition?.length > 0 &&
							telebetSearchCompetition.map((row, i) => {
								const formatedTime = moment.utc(row?.event_start_time).format("DD MMM YYYY, HH:mm") || "";
								return (
									<div
										className={row?.event_id == id ? "search_competition_telebet  active-sport-item" : "search_competition_telebet"}
										key={i}
										onClick={() => handleClickRedirect(row)}
									>
										<div className="sport-item">
											<span>{row?.competition_name}</span>
											<RightDarkArrowIcon />
										</div>
										<p className="event_telebet_search_container">{row?.event_name}</p>
										<p className="time_telebet_search_container">{formatedTime}</p>
									</div>
								);
							})}
						{!telebetSearchValue &&
							!telebetSearchCompetition?.length &&
							sports.map(row => {
								return (
									<div
										key={row?.slug}
										className={row?.slug === telebetSport?.slug ? "sport-item telebet_sports active-sport-item" : "telebet_sports sport-item"}
										onClick={() => handleClick(row)}
									>
										<span>{row?.name}</span>
										<RightDarkArrowIcon />
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
