import React from "react";
import { ThousandSeperated } from "hooks/General";
import { getFormatDateByUserTimezone } from "utils/global";
import { AddFreeCashLogo } from "utils/icons";
import { transactionFilterTypes } from "utils/constants";

export const getTransictionReportColumns = ({ transactionData, isFilterActive }) => {
	return [
		{
			name: "transaction_id",
			label: "TxID",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				setCellProps: () => ({ style: { minWidth: "80px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "80px" } }),
			},
		},
		{
			name: "transaction_date",
			label: "DATE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = transactionData[meta.currentTableData[meta.rowIndex].index];
					const transactionByUserTimezone = getFormatDateByUserTimezone(row?.transaction_date, "YYYY-MM-DD HH:mm:ss");
					return <>{transactionByUserTimezone}</>;
				},
				setCellProps: () => ({ style: { minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),
			},
		},
		{
			name: "transaction_type",
			label: "TYPE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "details",
			label: "DETAILS",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = transactionData[meta.currentTableData[meta.rowIndex].index];
					const { bet_id, details, type } = record;

					return type === "bet_win" || type === "bet_place" || type === "cash_out" ? (
						<a href={`/bet/${bet_id}`} target="_blank" rel="noopener noreferrer">
							{details}
						</a>
					) : (
						details
					);
				},
				setCellProps: () => ({ style: { minWidth: "400px" }, className: "transaction-link" }),
				setCellHeaderProps: () => ({ style: { minWidth: "400px" } }),
			},
		},
		{
			name: "status",
			label: "STATUS",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = transactionData[meta.currentTableData[meta.rowIndex].index];
					let title = row?.status;
					let formattedTitle = title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
					return <>{formattedTitle}</>;
				},
			},
		},

		{
			name: "amount",
			label: "AMOUNT",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = transactionData[meta.currentTableData[meta.rowIndex].index];
					const isFreeBet = record?.is_free_bet == 1;
					const amount = record?.free_bet_amount > 0 ? record?.free_bet_amount : record?.amount;

					let balanceValue = ThousandSeperated(amount);
					let freeBetComponent = null;

					if (isFreeBet) {
						freeBetComponent = (
							<span>
								<span>{balanceValue}</span>
								<span className="ps-1">
									<AddFreeCashLogo />
								</span>
							</span>
						);
					}

					const isNegative = balanceValue.startsWith("-");
					balanceValue = balanceValue.replace("-", "");

					return (
						<span className={isNegative ? "costumized_row_negative_value" : ""}>
							{!isFreeBet && (isNegative ? `(${balanceValue})` : balanceValue)}
							{isFreeBet && freeBetComponent}
						</span>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", width: "150px" } }),
				setCellHeaderProps: () => ({ className: "align-right" }),
			},
		},
		{
			name: "balance",
			label: "BALANCE",
			className: "text-right",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const record = transactionData[meta.currentTableData[meta.rowIndex].index];
					const { balance, currency_symbol } = record;

					let balanceValue = "";
					let isNegative = false;
					if (record?.balance === "-" || isFilterActive) {
						balanceValue = "-";
					} else {
						balanceValue = ThousandSeperated(balance, true, true);
						isNegative = balanceValue.startsWith("-");
						balanceValue = balanceValue.replace("-", "");
					}

					balanceValue = balanceValue === "-" ? balanceValue : `${currency_symbol} ${balanceValue}`;
					return <span className={isNegative ? "costumized_row_negative_value" : ""}>{isNegative ? `(${balanceValue})` : balanceValue}</span>;
				},

				setCellProps: () => ({ style: { textAlign: "right", width: "150px" } }),
				setCellHeaderProps: () => ({ className: "align-right" }),
			},
		},
	];
};

export const filterColumns = [
	{
		name: "Details",
		type: "text",
		value: "details",
	},
	{
		name: "Type",
		type: "select",
		value: "type",
		data: JSON.stringify(transactionFilterTypes),
		optionKey: "value",
		optionName: "name",
	},
	{
		name: "Date",
		type: "date",
		value: "time",
		defaultValue: "7 days",
		defaultDateDays: "7",
	},
];
