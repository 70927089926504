import React from "react";
import { EditIconButton } from "components/GeneralComponents/AddButton";
import { DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone, DragAndDropFillIcon } from "utils/icons";
import { ActiveExpandIcon, ExpandIcon } from "utils/icons";

export const getSportCompetitionsTableColumns = props => {
	const { setEditCompetition, changeOrder, handleFavoriteCompetition, disableOrder, isUserReadOnly } = props;
	return [
		{
			name: "competition_name",
			label: "NAME",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { width: "auto" } }),
				setCellHeaderProps: () => ({ style: { width: "auto" } }),
			},
		},
		{
			name: "pre_match",
			label: "PRE-MATCH",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					let pre_match = row?.enable_prematch;
					return (
						<>
							{pre_match ? (
								<span className="badge badge-sports-published"> Published </span>
							) : (
								<span className="badge badge-sports-inactive"> Inactive </span>
							)}
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px", maxWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
			},
		},
		{
			name: "in_play",
			label: "IN-PLAY",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					let in_play = row?.enable_inplay;
					return (
						<>
							{in_play ? (
								<span className="badge badge-sports-published"> Published </span>
							) : (
								<span className="badge badge-sports-inactive"> Inactive </span>
							)}
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px", maxWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
			},
		},
		{
			name: "expand",
			label: "EXPANDED",
			className: "status_name",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				download: false,
				customBodyRender: (value, meta) => {
					const record = meta;
					const { competition_id, favorite } = record;

					return (
						<div className="d-flex">
							<div className={`${isUserReadOnly && "pe-none"}`} onClick={() => handleFavoriteCompetition(competition_id, !favorite)}>
								{favorite ? <ActiveExpandIcon /> : <ExpandIcon />}
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "100px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta, other) => {
					const row = meta;
					let { index, dragHandleProps, isDragging } = other;
					return (
						<>
							<div className={`d-flex`} style={{ justifyContent: "flex-end", gap: "10px" }}>
								<div className="sportTypes-buttons" style={{ borderRadius: "0" }}>
									<EditIconButton onClick={() => setEditCompetition(row)} />
								</div>

								<div className={`d-flex justify-content-between ${disableOrder ? "pe-none" : ""}`} style={{ gap: "10px" }}>
									{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}

									<div
										{...dragHandleProps}
										onMouseDown={(...args) => {
											dragHandleProps?.onDragStart(...args);
										}}
									>
										<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
									</div>
								</div>
							</div>
						</>
					);
				},
			},
		},
	];
};
