import React from "react";
import { XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { FavoriteSwitch, GeneralInput, StatusSwitch } from "components/GeneralComponents/CustomInputs";
import { useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { AvForm } from "availity-reactstrap-validation";

export const EditCompetitionModal = ({ setEditClicked, editClicked, filterCompetitions }) => {
	const [dataChanged, setDataChanged] = useState(false);
	const [competitionData, setCompetitionData] = useState(editClicked);
	const [loading, setLoading] = useState(false);

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const editCompetition = () => {
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.competition_edit, resolve, reject, competitionData, true);
		})
			.then(response => {
				setDataChanged(false);
				if (response.success === true) {
					filterCompetitions();
					setEditClicked(false);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			{editClicked && (
				<div className="promotion-container">
					<div className="promotion-header">
						<span>Competition</span>
						<XCloseIcon onClick={() => setEditClicked(false)} className={"xclose-icon"} />
					</div>
					<div className="promotion-body">
						<TopBarComponent
							saveHandler={() => editCompetition()}
							dataChanged={dataChanged}
							loading={loading}
							onClose={() => setEditClicked(false)}
						/>
						<div className="promotion-elements row m-0">
							<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
								<AvForm>
									<GeneralInput
										onChange={e => {
											handleDataChanged();
											setCompetitionData({
												...competitionData,
												name: e.target.value,
											});
										}}
										object={{
											name: "Competition Name",
											value: competitionData.name,
											required: true,
										}}
									/>

									<StatusSwitch data={competitionData} setData={setCompetitionData} handleDataChanged={handleDataChanged} otherRowClass />
									<FavoriteSwitch data={competitionData} setData={setCompetitionData} handleDataChanged={handleDataChanged} otherRowClass />
								</AvForm>
							</div>
							<div className="promotion-right col-12 col-lg-6"></div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
