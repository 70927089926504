import { CancelButton, SaveButton } from "components/GeneralComponents/AddButton";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

export const AddResultModal = ({ betResult, handleBetResultType, setShowAddResult, handleSaveBetResult }) => {
	const resultTypes = [
		{ id: "Winner", label: "Won" },
		{ id: "Loser", label: "Lost" },
		{ id: "Pushed", label: "Pushed" },
	];
	return (
		<SweetAlert title="Bet Result" showConfirm={false} onConfirm={() => {}} style={{ padding: "16px 24px" }}>
			<div style={{ position: "relative" }}>
				<div className="d-flex justify-content-center mt-2">
					<GeneralDropdown
						object={{
							placeholder: "Choose Result",
							options: resultTypes,
							required: true,
							normalWidth: true,
							value: betResult,
							wrapperClassNameDropdown: "w-100",
						}}
						handleChange={e => handleBetResultType(e?.id)}
					/>
				</div>
				<div className="result_modal_buttons_container">
					<CancelButton onClick={() => setShowAddResult(false)} name="Cancel" />
					<SaveButton onClick={() => handleSaveBetResult()} name="Save" classname={"general_button_white"} />
				</div>
			</div>
		</SweetAlert>
	);
};
