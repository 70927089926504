import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useEffect, useState } from "react";
import { XCloseIcon } from "utils/icons";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

export const AdjustmentModal = ({ props, handler, data, setData, setModalShowing, bankingUsers }) => {
	let title = "Manual Adjustment";
	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userStatsData, setUserStatsData] = useState({ ...data });

	useEffect(() => {
		setUserStatsData({ ...data });
	}, [data]);

	const [adjustment, setAdjustment] = useState({
		amount: "",
		note: "",
		payment_type: "",
		adjustment_type: "",
		transaction_type: "",
	});

	const [adjusmentLeftSideJson, setAdjusmentLeftSideJson] = useState([]);

	useEffect(() => {
		const adjusmentLeftSide = [
			{ type: "input", fieldName: "amount", name: "Amount", inputClassName: "general_input", numbersOnly: true, required: true },
			{
				type: "dropdown",
				fieldName: "transaction_type",
				name: "Transaction Type",
				optionsType: "transactionTypes",
				required: true,
				disabledTyping: true,
			},
			{ type: "dropdown", fieldName: "payment_type", name: "Payment Type", optionsType: "paymentTypes", required: true },
		];

		const paymentTypeToOptionsTypeMap = {
			trading_adjustment: "tradingAdjustment",
			non_trading_adjustment: "nonTradingAdjustment",
		};

		const paymentType = adjustment?.payment_type;
		const optionsType = paymentTypeToOptionsTypeMap[paymentType];

		if (optionsType) {
			const adjustmentObject = {
				type: "dropdown",
				fieldName: "adjustment_type",
				name: "Adjustment Type",
				required: true,
				optionsType: optionsType,
			};

			adjusmentLeftSide.push(adjustmentObject);
		}
		adjusmentLeftSide.push({ type: "textarea", fieldName: "note", name: "Note", textareaClassName: "general_input largeInput" });

		setAdjusmentLeftSideJson(adjusmentLeftSide);
	}, [adjustment?.payment_type]);

	const adjusmentRightSideJson = [
		{ type: "input", fieldName: "mainBalance", name: "Current Balance", inputClassName: "general_input", disabled: true, formatCurrency: true },
		{
			type: "input",
			fieldName: "withdrawalBalance",
			name: "Withdrawable balance",
			inputClassName: "general_input",
			disabled: true,
			formatCurrency: true,
		},
		{ type: "input", fieldName: "withdrawalBalance", name: "New Balance", inputClassName: "general_input", disabled: true, formatCurrency: true },
	];

	const manualAdjustment = () => {
		setLoading(true);
		let payload = { ...adjustment };

		const payment_type = adjustment.payment_type + "_" + adjustment.adjustment_type;
		payload = { ...adjustment, payment_type: payment_type };
		delete payload.adjustment_type;

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.ADD_ADJUSTMENT + props.subId, resolve, reject, payload, true, adjusmentLeftSideJson);
		})
			.then(response => {
				if (response.success) {
					setDataChanged(false);
					bankingUsers();
					setModalShowing(false);
					const balance = userStatsData.withdrawalBalance;
					setData({ ...userStatsData, mainBalance: balance });
					setAdjustment({ amount: "", note: "", payment_type: "", transaction_type: "" });
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (adjustment.transaction_type == "deposit") {
			const oldAmount = userStatsData.mainBalance;
			if (adjustment.amount >= 0) {
				const value = adjustment.amount;
				const calculatedNewAmount = Number(oldAmount) + Number(value);
				setUserStatsData({ ...userStatsData, withdrawalBalance: calculatedNewAmount });
			} else setUserStatsData({ ...userStatsData, withdrawalBalance: oldAmount });
		} else if (adjustment.transaction_type == "withdrawal") {
			const oldAmount = userStatsData.mainBalance;
			if (adjustment.amount > 0) {
				const value = adjustment.amount;
				const calculatedNewAmount = Number(oldAmount) - Number(value);
				setUserStatsData({ ...userStatsData, withdrawalBalance: calculatedNewAmount });
			} else setUserStatsData({ ...userStatsData, withdrawalBalance: oldAmount });
		}
	}, [adjustment?.amount, adjustment?.transaction_type]);

	const dependecies = { payment_type: adjustment?.payment_type };

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={manualAdjustment} formId="avForm" type="submit" dataChanged={dataChanged} loading={loading} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AllComponentsLoop
							fields={adjusmentLeftSideJson}
							data={adjustment}
							setData={setAdjustment}
							setDataChanged={setDataChanged}
							dependecies={dependecies}
						/>
					</div>
					<div className="promotion-right col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AllComponentsLoop fields={adjusmentRightSideJson} data={userStatsData} setData={setAdjustment} setDataChanged={setDataChanged} />
					</div>
				</div>
			</div>
		</div>
	);
};
