import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Row, Col, Alert, Card, Container } from "reactstrap";
import ApiServices from "utils/ApiServices";
// import axios from "axios";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import { toast } from "react-toastify";
import { apiUrls } from "utils/const.apiUrl";
import { loginLogo, platformName } from "utils/constants";
import SignInFooter from "./SignInFooter";
import xIcon from "../../assets/images/x-icon.svg";

// const axiosApi = axios.create({
// 	baseURL: process.env.REACT_APP_API_URL,
// });

const ForgetPasswordPage = props => {
	const [spinningLogin, setSpinningLogin] = useState(false);

	const resetPasswordLogin = (event, values) => {
		if (!values.email) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter email</p>
				</>,
				{ className: "custom-toast" }
			);
			setSpinningLogin(false);
			return false;
		}
		setSpinningLogin(true);

		const body = {
			email: values.email,
		};

		return new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.cms_user_password_reset, resolve, reject, body, false);
		})
			.then(function (response) {
				let data = response.data;
				let blocked = data;
				if (blocked == 1) {
					props.history.push("/blocked");
				} else {
					localStorage.setItem("email", values.email);
					props.history.push("/reset_password");
				}
			})
			.finally(() => {
				setSpinningLogin(false);
			});
	};
	useEffect(() => {
		document.body.classList.add("login-body");
		return () => {
			document.body.classList.remove("login-body");
		};
	}, []);

	return (
		<React.Fragment>
			<MetaTags>
				<title>Forget Password | CMS | {platformName}</title>
			</MetaTags>
			<div className="account-pages my-0 pt-sm-5 my-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5} className="login-flow-card">
							<Card className="overflow-hidden login-card">
								<h3 className="default-logo-login text-center mt-5">
									<div className="d-block auth-logo">
										<img src={loginLogo} alt="" height="60" width="200" className="auth-logo-dark" style={{ cursor: "auto" }} />
									</div>
								</h3>
								<h4 className="login-title">Forgot Password?</h4>
								<div className="check-reset-text">No worries, we will send you reset instructions on your email!</div>
								{props.forgetError && props.forgetError ? (
									<Alert color="danger" style={{ marginTop: "13px" }}>
										{props.forgetError}
									</Alert>
								) : null}
								{props.forgetSuccessMsg ? (
									<Alert color="success" style={{ marginTop: "13px" }}>
										{props.forgetSuccessMsg}
									</Alert>
								) : null}

								<AvForm className="form-horizontal" onValidSubmit={(e, v) => resetPasswordLogin(e, v)}>
									<div className="forget-label">
										<AvField name="email" label="Email" className="form-control login-input" placeholder="Enter Your Email" type="email" />
									</div>
									<Row className="mb-3">
										<Col className="text-end">
											<div className="login-label">
												<button className="login-button form-control" type="submit">
													{spinningLogin ? <Spinner animation="border" size="sm" /> : "Reset Password"}
												</button>
											</div>
											<div className="check-reset-footer">
												Remember Password?
												<Link to="/login" className="reset-resend">
													Sign in here
												</Link>
											</div>
										</Col>
									</Row>
								</AvForm>
							</Card>
							<SignInFooter />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

ForgetPasswordPage.propTypes = {
	forgetError: PropTypes.any,
	forgetSuccessMsg: PropTypes.any,
	history: PropTypes.object,
	userForgetPassword: PropTypes.func,
};

const mapStatetoProps = state => {
	const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
	return { forgetError, forgetSuccessMsg };
};

export default withRouter(connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage));
