import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
	key: "main-root",
	storage,
	blacklist: ["Matches", "Users", "BetTickerData", "SelectedBetReferral", "BetReferralModal", "Backdrop", "LiabilitiesData", "Bets", "Layout"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(sagaMiddleware)));
export const Persistor = persistStore(store);
export default store;
