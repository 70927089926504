import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let result = [];
let prevType = "";

export const getCasinoCatrgoryDropdown = async type => {
	if (result.length && prevType === type) return result;

	const formatedType = type.includes("_category") ? type.split("_category")[0] : type;

	return new Promise((resolve, reject) => {
		ApiServices.get(`${apiUrls.GET_CASINO_CATEGORY_OPTIONS}?type=${formatedType}`, resolve, reject, true);
	})
		.then(response => {
			prevType = type;
			let { data } = response;

			data = data.map(row => {
				return { id: row.id, label: row.title };
			});

			result = data;
			return data;
		})
		.catch(() => {
			result = [];
			return [];
		});
};
