import React from "react";
import moment from "moment";
import ApiServices from "./ApiServices";
import { apiUrls } from "./const.apiUrl";
import { setLogo } from "../store/layout/actions";
import logo from "../assets/images/swifty_w.svg";
import { toast } from "react-toastify";
import xIcon from "../assets/images/x-icon.svg";
// import { fractionalToDecimal } from "./fractionalToDecimal";
// import { decimalToFractional } from "./decimalToFractional";
import { capitalizeText } from "services/capitalizeText";
import HTMLReactParser from "html-react-parser";

export const getImageThumbnail = image => {
	if (
		image != null &&
		(image.endsWith(".mov") ||
			image.endsWith(".mp4") ||
			image.endsWith(".avi") ||
			image.endsWith(".mkv") ||
			image.endsWith(".wmv") ||
			image.endsWith(".flv") ||
			image.endsWith(".webm") ||
			image.endsWith(".ogg") ||
			image.endsWith(".ogv") ||
			image.endsWith(".m4v") ||
			image.endsWith(".mpeg") ||
			image.endsWith(".mpg") ||
			image.endsWith(".3gp") ||
			image.endsWith(".3g2") ||
			image.endsWith(".m2v") ||
			image.endsWith(".m2ts") ||
			image.endsWith(".mts") ||
			image.endsWith(".ts") ||
			image.endsWith(".vob") ||
			image.endsWith(".gpp") ||
			image.endsWith(".mxf"))
	) {
		return image;
	}

	if (image?.endsWith(".svg")) return image;
	if (image?.startsWith("https://")) {
		const lastSlashIndex = image.lastIndexOf("/");

		if (lastSlashIndex !== -1) {
			const lastPart = image.substring(lastSlashIndex + 1);
			const modifiedPart = "thumb_" + lastPart;
			const modifiedLink = image.substring(0, lastSlashIndex + 1) + modifiedPart;
			return modifiedLink;
		}
	}
	if (!image) return "";
	return "thumb_" + image;
};

export const checkIfTwoOjectsAreEqual = (obj1, obj2) => {
	if (typeof obj1 !== "object" || typeof obj2 !== "object") {
		return false;
	}
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		if (obj1[key] !== obj2[key]) {
			return false;
		}
	}

	return true;
};
export const isJSON = str => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
};

export const filterAndSortArray = (array, sortByField) => {
	if (!Array.isArray(array) || array.length === 0) {
		return [];
	}

	const sortedData = array
		.sort((a, b) => {
			const fieldA = a[sortByField] || "";
			const fieldB = b[sortByField] || "";
			return fieldA.localeCompare(fieldB);
		})
		.map(comp => ({
			...comp,
			[sortByField]: toCapitalCase(comp[sortByField]),
		}));

	return sortedData;
};

export const toCapitalCase = string => {
	if (!string || typeof string !== "string") {
		return "";
	}
	return string
		.split(" ")
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
};

export const getPhonePrefixAndNumber = (prefix, phoneNumber) => {
	if (!prefix && !phoneNumber) {
		return "-";
	}

	const formattedPrefix = prefix && prefix[0] !== "+" ? "+" + prefix : prefix || "";
	const formattedNumber = phoneNumber || "";
	return formattedPrefix + formattedNumber;
};

export const getFormatDateByUserTimezone = (date, formatType) => {
	const user = localStorage.getItem("user");
	const parsedObject = JSON.parse(user);
	const offset = parsedObject?.offset || 0;
	if (!date) {
		return "-";
	}
	const utcTime = moment.utc(date);
	let time;
	if (formatType) {
		time = utcTime.add(offset, "minutes").format(formatType);
	} else time = utcTime.add(offset, "minutes").format("YYYY-MM-DD HH:mm");

	return time;
};

export const isToday = date => {
	return moment(date).isSame(moment(), "day");
};

export const convertFormatDateInUtc = (date, format) => {
	const user = localStorage.getItem("user");
	const parsedObject = JSON.parse(user);
	const offset = parsedObject?.offset || 0;

	if (!date || date === "-") {
		return "-";
	}
	if (format) {
		return moment.utc(date).subtract(offset, "minutes").format(format);
	} else return moment.utc(date).subtract(offset, "minutes").format("YYYY-MM-DD HH:mm");
};

export const getFormatDateAndTime = date => {
	const parsedDate = moment.utc(date);
	if (!parsedDate.isValid()) {
		return "-";
	}

	const formattedDateAndTime = parsedDate.format("YYYY-MM-DD HH:mm");
	return formattedDateAndTime;
};

export const getTime = date => {
	const parsedDate = moment(date);
	if (!parsedDate.isValid()) {
		return "-";
	}
	const formattedDate = parsedDate.format("HH:mm");
	return formattedDate;
};

export const calculateAgeFromDate = date_of_birth => {
	const today = moment();
	const birthDate = moment(date_of_birth, "YYYY-MM-DD");
	const age = today.diff(birthDate, "years");
	return age;
};
export const getABS = value => {
	return Math.abs(Number(value));
};

export const passwordRequirementsCheck = data => {
	const passwordLengthCheck = data?.length >= 8;
	const specialCharacterRegex = /[!@#$%^&*.,[\]{}()?\\/"!@#%&<>':;|_~`+=-]/;
	const specialCharacterCheck = specialCharacterRegex.test(data);
	const numberCharacterCheck = /[0-9]/.test(data);

	return {
		length: passwordLengthCheck,
		specialCharacter: specialCharacterCheck,
		number: numberCharacterCheck,
	};
};

export const checkIfAllFieldsAreFilled = (payload, userFields) => {
	if (!userFields.length) return true;
	let count = 0;

	for (let i = 0; i < userFields.length; i++) {
		const fieldName = userFields[i].fieldName;
		const isRequired = userFields[i].required;
		const fieldValue = payload[fieldName];

		const textFields = document.querySelectorAll("[data-dropdownrequired='true']");

		textFields.forEach(function (textField) {
			const parent = textField.closest(".dropdown-wrapper").parentElement;

			const isMulitpleDropdown = parent?.nextSibling?.querySelector(".multiselect-wrapper");

			const childDiv = textField.querySelector("div");
			const input = textField.querySelector("input");
			if (input && input.value.trim() === "") {
				if (isMulitpleDropdown && isMulitpleDropdown.children.length > 0) return;
				childDiv.classList.add("error_empty_input");
				count++;
			}
		});

		const input = document.querySelectorAll('div[data-required="true"] .form-control');
		input.forEach(function (inputElement) {
			if (inputElement.value?.trim?.() === "") {
				const parentDiv = inputElement.parentNode;
				parentDiv.classList.add("error_empty_input");
				count++;
			}
		});

		if (isRequired && !fieldValue) {
			let requiredInputs = document.querySelectorAll("[required]");
			requiredInputs.forEach(function (input) {
				if (input.value?.trim?.() === "") {
					input.classList.add("error_empty_input");
					count++;
				}
			});
		}
	}

	return count == 0;
};

export const groupObjectsBySameValue = array => {
	return Object.entries(
		array.reduce((acc, { id, type, value, link, order, page_type, details, title, enabled }) => {
			// Group initialization
			if (!acc[type]) {
				acc[type] = [];
			}

			acc[type].push({
				id,
				type,
				value,
				link,
				order,
				page_type,
				details,
				title,
				enabled,
			});

			return acc;
		}, {})
	).map(([label, options]) => ({ label, options }));
};

export const getDynamicValue = (data, selectedIds, edit) => {
	const dynamicValues = {};
	let fields = ["sport_id", "event_id", "market_id", "selection_id", "event_name", "competition_raw_id", "competition_id"];
	fields.forEach(field => {
		if (field === "competition_raw_id" || field === "competition_id") {
			if (data["promoType"] === "dynamic") {
				dynamicValues[field] = selectedIds?.["competition_id"] || "competition_raw_id";
			} else if (edit) {
				dynamicValues[field] = data?.["competition_raw_id"] || "competition_id";
			} else {
				dynamicValues[field] = data?.["competition_id"] || "competition_raw_id";
			}
		} else {
			dynamicValues[field] = data["promoType"] === "dynamic" ? selectedIds?.[field] : data?.[field];
		}
	});
	return dynamicValues;
};

export const setSportNameBySlug = sport_slug => {
	const sportData = {
		americanfootball: {
			name: "American Football",
		},
		australianrules: {
			name: "Australian Rules",
		},
		badminton: {
			name: "Badminton",
		},
		baseball: {
			name: "Baseball",
		},
		basketball: {
			name: "Basketball",
		},
		beachvolleyball: {
			name: "Beach Volleyball",
		},
		cricket: {
			name: "Cricket",
		},
		darts: {
			name: "Darts",
		},
		esport: {
			name: "eSports",
		},
		soccer: {
			name: "Football",
		},
		futsal: {
			name: "Futsal",
		},
		handball: {
			name: "Handball",
		},
		hockey: {
			name: "Hockey",
		},
		icehockey: {
			name: "Ice Hockey",
		},
		martialarts: {
			name: "Martial Arts/UFC",
		},
		motorsport: {
			name: "Motor Sport",
		},
		rugby: {
			name: "Rugby League",
		},
		rugbyunion: {
			name: "Rugby Union",
		},
		snooker: {
			name: "Snooker",
		},
		tabletennis: {
			name: "Table Tennis",
		},
		tennis: {
			name: "Tennis",
		},
		volleyball: {
			name: "Volleyball",
		},
		basketball3x3: {
			name: "Basketball 3x3",
		},
		waterpolo: {
			name: "Water Polo",
		},
		virtualhorseracing: {
			name: "Virtual Horse Racing",
		},
		virtualgreyhoundfootball: {
			name: "Virtual Greyhound Racing",
		},
		virtualfootball: {
			name: "Virtual Football",
		},
		squash: {
			name: "Squash",
		},
		hurling: {
			name: "Hurling",
		},
		gaelicfootball: {
			name: "Gaelic Football",
		},
		bowls: {
			name: "Bowls",
		},
		cycling: {
			name: "Cycling",
		},
		golf: {
			name: "Golf",
		},
		boxing: {
			name: "Boxing",
		},
		all: {
			name: "Stake Factor",
		},
		dog_racing: {
			name: "Dog Racing",
		},
		horseracing: {
			name: "Horse Racing",
		},
		greyhoundracing: {
			name: "Greyhound Racing",
		},
	};

	return sportData[sport_slug] || { name: "" };
};

export const getLogo = dispatch => {
	// const stopCallingApi = ["customer_service", "auditor", "operations", "affiliate"];

	new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.get_css, resolve, reject, true);
	})
		.then(response => {
			const res = {
				desktop_logo: response?.data?.desktop_logo,
				mobile_logo: response?.data?.mobile_logo,
				solid_logo: response?.data?.solid_logo,
			};
			dispatch(setLogo(res));
		})
		.catch(() => {
			dispatch(setLogo(logo));
		});
};

export const sortColumn = (a, b, key) => {
	// Parse the "details" JSON string to extract the key property
	const key1 = JSON.parse(a.details)[key];
	const key2 = JSON.parse(b.details)[key];

	// Perform a case-insensitive comparison of the keys
	return key1.localeCompare(key2, undefined, { sensitivity: "base" });
};

export const sortColumnString = order => {
	return (obj1, obj2) => {
		let val1 = parseInt(obj1.data);
		let val2 = parseInt(obj2.data);
		return (val1 - val2) * (order === "asc" ? 1 : -1);
	};
};

export const formatEventPhase = phase => {
	if (phase == "Scheduled") return "Pre-Match";
	else return phase;
};

export const formatEventTier = tier => {
	if (tier == "default") return "0";
	if (tier == null) return "0";
	if (tier == 0) return "0";
	else return tier;
};

export const getVersions = (data, lang) => {
	return data[lang?.toLowerCase()]
		?.map(currentTerm => currentTerm.version)
		?.sort((a, b) => b.split(".").reduce((accum, value) => accum + value, 0) - a.split(".").reduce((accum, value) => accum + value, 0));
};

export const assignRowClasses = (records, setBgColors, key) => {
	let colors = ["white", "gray"];
	const data = records.map((record, i) => {
		let color;
		if (record[key]?.length) {
			color = colors[i % 2];
		} else {
			color = colors[(i + 1) % 2];
			colors.reverse();
		}
		return color;
	});
	setBgColors(data);
};

export const getOdds = (item, type, isToFixed = false) => {
	const { decimal, fractional, boosted_decimal, boosted_fractional, sport_slug = "", isSuspended } = item;

	if (isSuspended) return "SUSP";

	let value = null;
	if (type === "decimal") {
		if (boosted_decimal) value = isToFixed ? Number(boosted_decimal).toFixed(2) : boosted_decimal;
		else value = isToFixed ? Number(decimal).toFixed(2) : decimal;
	} else if (type === "fractional") {
		if (boosted_fractional) value = boosted_fractional;
		else value = fractional;
	}

	if ((sport_slug === "horseracing" || sport_slug === "greyhoundracing") && decimal == "SP") value = "SP";
	if (decimal === "?") value = "?";

	return value;
};

export const checkGenericUrl = link => {
	/* eslint-disable */
	const urlRegex =
		/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s#]{2,}(\#[^\s]*)?|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s#]{2,}(\#[^\s]*)?|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s#]{2,}(\#[^\s]*)?|www\.[a-zA-Z0-9]+\.[^\s#]{2,}(\#[^\s]*)?|mailto:[^\s@]+@[^\s]{2,}(?!\#)|#)/gi;
	/* eslint-enable */

	if (!urlRegex.test(link)) {
		toast.error(
			<>
				<img src={xIcon} alt="Error" />
				<p className="toast-icon-manual-message">Please enter a valid Url</p>
			</>,
			{ className: "custom-toast" }
		);

		return true;
	}
	return false;
};

export const isIsoDate = str => {
	if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
	const d = new Date(str);
	return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
};
export const createParams = (data, payloadData) => {
	const params = new URLSearchParams();
	payloadData.forEach(item => {
		if (data[item] !== "") {
			params.append(item, data[item]);
		}
	});

	return `?${params.toString()}`;
};

export const prepareFilterData = (data, columns) => {
	const a = columns?.reduce((a, v) => {
		let d = { [v.value]: data?.find(d => v.value == d?.key)?.selected || "" };

		if (Object.hasOwn(v, "value_2")) {
			d = { ...d, [v.value_2]: data?.find(d => v.value_2 == d?.key)?.selected || "" };
		}

		return { ...a, ...d };
	}, {});

	return a;
};

export const getTrainerAndJockeyForTelebetSis = (trainer, jockey) => {
	let jockeyAndtrainer;
	if (trainer && jockey) {
		jockeyAndtrainer = jockey + " / " + trainer;
	} else if (jockey) {
		jockeyAndtrainer = jockey;
	} else if (trainer) {
		jockeyAndtrainer = trainer;
	} else if (!trainer && !jockey) {
		jockeyAndtrainer = "TBC";
	}
	return jockeyAndtrainer;
};

export const getAllMonthsAndYears = months => {
	const latestYear = "2022";
	const years = [latestYear];
	const data = [];

	const date = new Date();

	let month = date.getUTCMonth() + 1; //months from 1-12
	let year = date.getUTCFullYear();

	let yearsDiff = year - +latestYear;

	for (let i = yearsDiff; i > 0; i--) {
		years.unshift(year - i + 1);
	}

	years.forEach(year => {
		months.forEach(month => {
			data.push({
				id: `${month.value}-${year}`,
				label: `${month.label} ${year}`,
			});
		});
	});

	const formatedData = data.filter(({ id }) => {
		const [m, y] = id.split("-");
		return m <= month || y < year;
	});

	return formatedData;
};

export const userReadOnly = user => {
	const rolesToBlock = ["auditor"];
	if (rolesToBlock.includes(user?.role)) return true;
	else return false;
};

export const getStatusSpan = type => {
	let statusClassName = "";
	if (!type) return "-";

	if (type === "used") statusClassName = "green";
	else if (type === "available") statusClassName = "blue";
	else if (type === "partly_used") statusClassName = "coral";
	else if (type === "rejected") statusClassName = "red";
	else if (type === "expired") statusClassName = "gray";
	else if (type === "canceled") {
		statusClassName = "yellow";
		type = "Cancelled";
	}

	const span = `<span className="badge badge-sports-${statusClassName}"> ${capitalizeText(type)} </span>`;

	return HTMLReactParser(span);
};

export const disableInputs = () => {
	const form = document.querySelector("form");

	if (form) {
		const input = form.querySelectorAll("input");
		const textarea = form.querySelectorAll("textarea");
		const dropDown = form.querySelectorAll("fieldset");
		const iframe = form.querySelectorAll("iframe");
		const divForData = form.querySelectorAll(".flatpicrCustomized");
		const data = form.querySelectorAll(".flatPickrBorderNone");

		form.style.pointerEvents = "none";

		for (const i of input) i.disabled = true;
		for (const d of dropDown) d.classList.add("isDisabled");
		for (const t of textarea) t.classList.add("isDisabled");
		for (const i of iframe) i.classList.add("isDisabled");

		for (const dfd of divForData) dfd.classList.add("isDisabled");
		for (const d of data) d.classList.add("isDisabled");
	}
	// document.getElementsByClassName("promotion-right")[0].classList.add("d-none");
	// document.getElementsByClassName("promotion-left")[0].classList.add("pe-none");
};

export const getDateDiff = targetDate => {
	// Get the current date and time
	const currentDate = new Date();

	// Calculate the time difference in milliseconds
	const timeDifference = new Date(targetDate) - currentDate;

	// Calculate days, hours, and minutes
	const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

	// Return an object with the results
	return {
		days,
		hours,
		minutes,
	};
};

export const languageToString = (data, keys) => {
	let language = "";
	Object.entries(data).forEach(([key, value]) => {
		keys.forEach(key_string => {
			if (key.includes(key_string) && value) {
				const newValue = value.replace(/"/g, "'");
				language += `"${key}":"${newValue}",`;
			}
		});
	});

	return language.replace(/,(\s+)?$/, ""); // remove last comma;
};

export const isObject = value => {
	return typeof value === "object" && value !== null && !Array.isArray(value);
};

export const isEmptyObject = value => {
	return typeof value === "object" && value !== null && Object.keys(value).length === 0;
};

export const sanitizeJSONString = data => {
	// Replace unescaped control characters (e.g., newlines) with properly escaped versions
	return data
		.replace(/\\n/g, "\\\\n") // escape newline characters
		.replace(/\\r/g, "\\\\r") // escape carriage returns if any
		.replace(/\\t/g, "\\\\t"); // escape tabs if any
};
