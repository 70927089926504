import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { LightArrowRight, ThreeDotsIcon } from "utils/icons";
import { Box } from "@material-ui/core";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
// import { capitalizeText } from "services/capitalizeText";
import { calculateAgeFromDate } from "utils/global";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setTelebetUser } from "store/actions";
import Overview from "./Overview";

import AccountMenu from "./AccountMenu";
import { AuditLog } from "./auditLog";
import UserNotes from "./UserNotes";
import { capitalizeText } from "services/capitalizeText";
import { CustomerSettings } from "./CustomerSettings/customerSettings";

import "./UserV2.scss";
function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			className="tabs-tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={"span"}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const UserV2 = props => {
	const activeTab = localStorage.getItem("activeTab");
	const history = useHistory();
	const dispatch = useDispatch();

	const [userTab, setUserTab] = useState(0);
	const [showUserActions, setShowUserActions] = useState(false);
	const [userData, setUserData] = useState([]);
	const [unchangedUserData, setUnchangedUserData] = useState({});
	const [openAccountMenu, setOpenAccountMenul] = useState(false);
	const [stakeFactorData, setStakeFactorData] = useState({ inplay: [], prematch: [] });

	const getUserTab = (event, newValue) => {
		setUserTab(newValue);

		if (event === "auditlog") setShowUserActions(false);

		localStorage.setItem("activeTab", newValue);
	};

	const getUserData = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_USER_DATA + props.match.params.id, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let data = response.data;
					if (data?.date_of_birth) {
						let years = calculateAgeFromDate(data?.date_of_birth);
						data.age = years;
					}
					setUserData(data);
					setUnchangedUserData(data);
					setStakeFactorData(data?.stakeFactorPayload);
				}
			})
			.finally(() => {});
	};

	useEffect(() => {
		const newActiveTab = activeTab ? parseInt(activeTab) : 0;
		if (activeTab) setUserTab(newActiveTab);

		getUserData();
	}, []);

	const getUserAccountStatus = type => {
		const title = capitalizeText(type);
		let className = "";

		if (type == "suspended") {
			className += "userSuspended";
		} else if (type == "pending") {
			className += "userPending";
		} else if (type == "closed") {
			className += "userClosed";
		} else {
			className += "";
		}

		return <span className={`user-account-status ${className}`}>{title}</span>;
	};

	const userTabFields = [
		{ name: "Overview", value: 0 },
		{ name: "Customer Settings", value: 1 },
		{ name: "Responsible Gambling", value: 2 },
		{ name: "Transaction History", value: 3 },
		{ name: "Banking", value: 4 },
	];

	const opeTelebet = () => {
		dispatch(setTelebetUser(userData));
		history.push("/telebet");
	};

	const handlerClick = link => {
		if (link === "auditlog") getUserTab("", 5);
		else if (link === "notes") getUserTab("", 6);
		else if (link === "telebet") opeTelebet();

		setOpenAccountMenul(false);
	};

	const fields = [
		{ name: "Audit Log", link: "auditlog" },
		{ name: "Notes", link: "notes" },
		{ name: "Telebet", link: "telebet" },
	];

	return (
		<div className="white-box">
			<div className="tabs-container m-0">
				<div className="h4 user-card-title mb-0">
					<div className="user-dropwdown">
						<div className="user-dropdown-item" onClick={() => opeTelebet()}>
							Telebet
						</div>
						<button
							className={`btn btn-primary btn-no-border ${showUserActions ? "active" : ""}`}
							onClick={() => setShowUserActions(!showUserActions)}
							// ref={dotsRef}
						>
							<LightArrowRight />
						</button>

						{showUserActions ? (
							// ref={menuRef}
							<div className="user-dropdown-list">
								<ul>
									<li onClick={() => getUserTab("auditlog", 5)} className="user-dropdown-item">
										Audit Log
									</li>
								</ul>
							</div>
						) : (
							<></>
						)}
					</div>
					<div className="user-info d-flex">
						<div className="user-fullname d-flex">
							<span className="user-firstname">{userData?.first_name}</span>
							<span className="user-firstname">{userData?.last_name}</span>
						</div>
						<div className="user-fullname d-flex">
							<span className="userId d-flex justify-content-center align-item-center">#{userData?.player_id ? userData?.player_id : "-"}</span>
						</div>
						{getUserAccountStatus(userData.account_status)}
					</div>
					<div className="user-three-dots">
						<ThreeDotsIcon className={"ms-3"} handleClick={() => setOpenAccountMenul(true)} />
					</div>
				</div>
				<span className="user-border"></span>
				<div className="mt-4">
					<Box sx={{ width: "100%" }}>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs value={userTab} onChange={getUserTab} aria-label="basic tabs example" variant="fullWidth" className="user-tabs">
								{userTabFields.map((row, i) => {
									const { name, value } = row;
									return <Tab key={i} label={name} {...a11yProps(value)} value={value} />;
								})}
							</Tabs>
						</Box>
						<TabPanel value={userTab} index={0}>
							<Overview userData={userData} extraItem={{ user_id: props.match.params.id }} />
						</TabPanel>
						<TabPanel value={userTab} index={1}>
							<CustomerSettings
								data={userData}
								setData={setUserData}
								stakeFactorData={stakeFactorData}
								getUserData={getUserData}
								unchangedUserData={unchangedUserData}
							/>
						</TabPanel>
						<TabPanel value={userTab} index={2}></TabPanel>
						<TabPanel value={userTab} index={3}></TabPanel>
						<TabPanel value={userTab} index={4}></TabPanel>
						<TabPanel value={userTab} index={5}>
							<AuditLog id={userData?.id} />
						</TabPanel>
						<TabPanel value={userTab} index={6}>
							<UserNotes userData={userData} extraItem={{ user_id: props.match.params.id }} getUserTab={getUserTab} />
						</TabPanel>
					</Box>
				</div>
			</div>
			{openAccountMenu && <AccountMenu fields={fields} handlerClick={handlerClick} setCloseAccountMenul={setOpenAccountMenul} />}
		</div>
	);
};
export default UserV2;
