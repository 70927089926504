import React, { useEffect, useState } from "react";
import SearchFilter from "components/SearchFilter/searchFilter";
import { connect, useDispatch, useSelector } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { getMatchesTableColumns } from "./matchesTableColumns";
import { getMatchesFilterColumns } from "./matchesFilterColumns";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableRowsPerPageValue, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { setMatches } from "store/Matches/actions";
import AddEventModal from "./AddEventModal";
import { formatEventPhase } from "utils/global";
import { getCompetitionsDropdown } from "services/getCompetitionsDropdown";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const Matches = props => {
	const dispatch = useDispatch();

	// const matchesFilterData = useSelector(state => state.Login.searchFilterData.matches);
	const breadcrumbItems = [{ title: "Events", link: "/events" }];

	const sports = useSelector(state => state.Login.gsData.sports);
	const regions = useSelector(state => state.Login.gsData.regions);

	const [records, setRecords] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [competitions, setCompetitions] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [showAddEventModal, setShowAddEventModal] = useState(false);
	const [eventViewData, setEventViewData] = useState({
		active: 0,
		display: 0,
		name: "",
		start_time_utc: "",
		show_pre_match_bets: 0,
		show_in_play_bets: 0,
		current_phase: "",
		grade_pre_match: "0",
		grade_in_play: "0",
		competition_id: "",
		competition_name: "",
		region_id: "",
		sport_slug: "",
	});

	const matchesInStore = useSelector(state => state.Matches.matches);

	useEffect(() => {
		props.setBreadcrumbItems("Matches", breadcrumbItems);
	}, []);

	let rc = [];

	const handleFilterSportChange = sport => {
		getCompetitionsDropdown(sport).then(response => {
			if (response.length > 0) {
				setCompetitions(response);
			}
		});
	};

	let matchesFilterColumns = getMatchesFilterColumns({ sports, handleFilterSportChange, competitions, regions });

	const events = matchesFilterData => {
		var start_time = "";
		var end_time = "";
		let match_order = "start_time_utc";

		const dateDefaultValue = matchesFilterColumns.find(({ value }) => value == "date")?.defaultValue;

		if (matchesFilterData.date && matchesFilterData.date != dateDefaultValue) {
			if (matchesFilterData.date.indexOf("to") > -1) {
				var match_date = matchesFilterData.date.split("to");
				start_time = moment(match_date[0].trim(), "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(match_date[1].trim(), "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss");
			} else {
				start_time = matchesFilterData.date.trim().split("&")[0];
				end_time = matchesFilterData.date.trim().split("&")[1];
			}
		} else {
			start_time = moment.utc().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss");
			end_time = moment.utc().format("YYYY-MM-DD 23:59:59");
		}

		let queryParams = [];

		// Query params for filter
		if (matchesFilterData?.name) {
			queryParams.push("name=" + matchesFilterData?.name);
		} else {
			if (matchesFilterData.sport_id) {
				queryParams.push("sport_slug=" + matchesFilterData.sport_id);
			}
			if (matchesFilterData.competition_id) {
				queryParams.push("competition_id=" + matchesFilterData.competition_id);
			}
			if (matchesFilterData.region_id) {
				queryParams.push("region_id=" + matchesFilterData.region_id);
			}
			if (matchesFilterData?.name) {
				queryParams.push("name=" + matchesFilterData?.name);
			}
			if (match_order && start_time) {
				queryParams.push("order_column=" + match_order);
			}
			if (start_time) {
				queryParams.push("match_start_from=" + start_time);
			}
			if (end_time) {
				queryParams.push("match_start_to=" + end_time);
			}
			if (matchesFilterData?.status) {
				queryParams.push("status=" + matchesFilterData.status);
			}
			if (matchesFilterData?.manual_event) {
				queryParams.push("is_manual=" + matchesFilterData?.manual_event);
			}
		}

		let params = queryParams.length > 0 ? "?" + queryParams.join("&") : "";

		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.events + params, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let data = response.data;
					data.map(match => {
						//Current Time
						let start_time_sh = new Date(match?.start_time_sh).getTime();
						let last_update = new Date(match?.last_update).getTime();
						let current_time = "";
						if (match?.start_time_fh === null && match?.start_time_sh === null) {
							current_time = "";
						} else if (match?.start_time_fh !== null && match?.start_time_sh == null) {
							current_time = moment(new Date(match?.start_time_fh)).format("HH:mm:ss");
						} else if (match?.start_time_fh !== null && match?.start_time_sh !== null && last_update < start_time_sh) {
							current_time = moment(new Date(match?.start_time_sh)).format("HH:mm:ss");
						} else {
							current_time = "";
						}
						let obj = {
							id: match.id,
							event_id: match.event_id,
							name: match?.name,
							competition_name: match?.competition_name,
							score:
								match?.current_phase === "PreMatch" || match?.current_phase === "Scheduled" || match?.current_phase === "Deleted"
									? undefined
									: match?.home_score !== null && match?.away_score !== null
									? match?.home_score + "-" + match?.away_score
									: undefined,
							start_time_utc: match?.start_time_utc,
							sport_slug: match?.sport_slug,
							sport_name: match?.sport_name,
							region_name: match?.region_name,
							current_phase: formatEventPhase(match?.current_phase),
							current_time: current_time ? current_time : undefined,
							last_update: match?.last_update !== null ? moment(new Date(match?.last_update)).format("YYYY-MM-DD HH:mm:ss") : undefined,
							active: match?.active,
							display: match?.display,
						};
						rc.push(obj);
					});
					setRecords(rc);
					dispatch(setMatches(rc));
					setCurrentPage(0);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	let matchesTableColumns = getMatchesTableColumns({ records, props });

	useEffect(() => {
		if (matchesInStore) setRecords(matchesInStore);
	}, []);

	return (
		<HelmetLayout titleProps={"Matches"}>
			<div className={"cms-page pt-0 cms-table-page"}>
				<div className="col-md-9 col-12 d-flex justify-content-between">
					<SearchFilter
						columns={matchesFilterColumns}
						page={"matches"}
						preSelectedType={"text"}
						preSelectedValue={"name"}
						preSelectedName={"Name"}
						preSelectedData={""}
						preSelectedOptionKey={""}
						preSelectedOptionName={""}
						filterHandler={events}
						filterLoading={isDataLoading}
						competitionsHandler={handleFilterSportChange}
						customizedFilter={true}
					/>
				</div>

				<MUIDataTable
					columns={matchesTableColumns}
					data={records}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						onChangePage(page) {
							setCurrentPage(page);
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: tableRowsPerPageValue,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: "Matches" + ".csv" },
						customToolbar: () => <AddTableButton onClick={() => setShowAddEventModal(true)} />,
					}}
					extraButtons={tableExtraButtons}
				/>

				{showAddEventModal && (
					<AddEventModal
						showAddEventModal={showAddEventModal}
						eventViewData={eventViewData}
						setEventViewData={setEventViewData}
						setShowAddEventModal={setShowAddEventModal}
					/>
				)}
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(Matches);
