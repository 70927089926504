import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

export const getCasinoGames = async value => {
	if (value) {
		return new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.GET_CASINO_OPTIONS}?provider=all&q=${value}&limit=20`, resolve, reject, true);
		})
			.then(response => {
				let games = response?.data;

				games = games.map(row => {
					return { id: row.id, label: `${row.title} | ${row.slug}` };
				});
				return games;
			})
			.catch(() => {
				return [];
			});
	} else {
		return [];
	}
};
