import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import Backdrop from "./Backdrop/reducer";
import BetReferralModal from "./Modals/reducer";
import Breadcrumb from "./Breadcrumb/reducer";
import SelectedBetReferral from "./SelectedBet/reducer";
import BetTickerData from "./BetTicker/reducer";
import NotificationsList from "./NotificationsList/reducer";
import Matches from "./Matches/reducer";
import Users from "./Users/reducer";
import LiabilitiesData from "./Liabilities/reducer";
import Bets from "./Bets/reducer";
import Telebet from "./TelebetRrducers/reducer";

// Authentication
import ForgetPassword from "./auth/forgetpwd/reducer";
import Login from "./auth/login/reducer";
import Profile from "./auth/profile/reducer";
import Account from "./auth/register/reducer";

//Calendar
import calendar from "./calendar/reducer";
import Report from "./report/reducer";
import BetNotificationsList from "./NotificationsListBets/reducer";

const appReducer = combineReducers({
	// public
	Layout,
	//Breadcrumb items
	Breadcrumb,
	Backdrop,
	LiabilitiesData,
	NotificationsList,
	BetNotificationsList,
	BetReferralModal,
	SelectedBetReferral,
	BetTickerData,
	Login,
	Account,
	ForgetPassword,
	Profile,
	calendar,
	Report,
	Matches,
	Users,
	Bets,
	Telebet,
});

export const rootReducer = (state, action) => {
	if (action.type === "RESET_STORE") {
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
