import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

export const getMarkets = async params => {
	const param = params ? params : "";
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.get_markets + param, resolve, reject, true);
	})
		.then(response => {
			if (response.success) {
				let data = response?.data;
				return data;
			}
		})
		.catch(() => {
			return [];
		});
};
