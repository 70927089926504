export const getWordsFilterColumns = ({ languages }) => {
	let languagesData = languages ? JSON.stringify(languages) : [];
	return [
		{
			name: "Language",
			type: "select",
			value: "language",
			data: languagesData,
			optionKey: "value",
			optionName: "name",
		},
	];
};
