import { SET_LIABILITIES_FILTERED_DATA } from "./actionTypes";

const initialState = {
	filteredData: [],
};

const LiabilitiesData = (state = initialState, action) => {
	switch (action.type) {
		case SET_LIABILITIES_FILTERED_DATA:
			return {
				...state,
				filteredData: action.payload,
			};

		default:
			return state;
	}
};

export default LiabilitiesData;
