import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";
import { toast } from "react-toastify";
import xIcon from "../../../assets/images/x-icon.svg";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

export const NewVariable = ({ setShowAddModal, editItem, setEditItem, records, setRecords }) => {
	const [variableData, setVariableData] = useState({
		description: editItem ? editItem?.description : "",
		value: editItem ? editItem?.value : "",
		show_hide: editItem ? editItem?.show_hide : false,
	});
	const [loading, setLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);

	const editVariable = () => {
		let payload = {
			description: variableData?.description,
			value: variableData?.value,
			show_hide: variableData?.show_hide,
			id: editItem?.id,
		};

		if (!variableData?.description) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter description</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!variableData?.value) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter value</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.edit_variable, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				let actualData = [...records];
				let filteredRow = actualData.filter(row => row.id === editItem.id);
				let index = actualData.findIndex(row => row.id === editItem.id);
				if (filteredRow && filteredRow.length > 0) {
					filteredRow[0].description = variableData?.description;
					filteredRow[0].value = variableData?.value;
					filteredRow[0].show_hide = variableData?.show_hide;
					actualData[index] = filteredRow[0];
					setRecords(actualData);
				}
				setShowAddModal(false);
				setEditItem(null);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const addVariable = () => {
		let payload = {
			description: variableData?.description,
			value: variableData?.value,
			show_hide: variableData?.show_hide,
		};
		if (!variableData?.name) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter name</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!variableData?.description) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter description</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!variableData?.value) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter value</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.add_variable, resolve, reject, payload, true);
		})
			.then(response => {
				setDataChanged(false);
				let data = response?.data;
				setRecords(data);
				setShowAddModal(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const fields = [
		{ type: "input", name: "Description", fieldName: "description", inputClassName: "general_input", required: true },
		{ type: "textarea", name: "Value", fieldName: "value", inputClassName: "general_input", required: true },
	];

	const handler = () => {
		setShowAddModal(false);
		setEditItem(null);
	};
	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>Variables</span>
				<XCloseIcon className={"xclose-icon"} onClick={handler} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editItem ? editVariable : addVariable} loading={loading} dataChanged={dataChanged} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AllComponentsLoop fields={fields} data={variableData} setData={setVariableData} setDataChanged={setDataChanged} />
						{/* James asked to hide this for now ! */}
						{/* <HideInput data={variableData} setData={setVariableData} handleDataChanged={handleDataChanged} otherRowClass /> */}
					</div>
				</div>
			</div>
		</div>
	);
};
