import { formatToLocalDatetime } from "hooks/General";
export const getResponsibleGamblingPlayBreakPage = () => {
	return [
		{
			name: "created_at",
			label: "ACTION DATE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: value => {
					return formatToLocalDatetime(value, "YYYY-MM-DD HH:mm");
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
		{
			name: "period",
			label: "PERIOD",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
		{
			name: "end_date",
			label: "END DATE",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: value => {
					return formatToLocalDatetime(value, "YYYY-MM-DD HH:mm");
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
		{
			name: "by",
			label: "OWNER",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
	];
};
