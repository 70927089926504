import { SealCodeEditorBody, SealCodeEditorHeader } from "components/GeneralComponents/CustomInputs";
import React from "react";

export const SealComponent = props => {
	const { linkData, setLinkData, handleDataChanged, isUserReadOnly } = props;
	return (
		<>
			<div className="promotion-elements p-0 mt-3 seal-promotion">
				<div style={{ height: "50%" }}>
					<SealCodeEditorHeader data={linkData} setData={setLinkData} handleDataChanged={handleDataChanged} isUserReadOnly={isUserReadOnly} />
				</div>
				<div style={{ height: "50%" }} className="m-0">
					<SealCodeEditorBody data={linkData} setData={setLinkData} handleDataChanged={handleDataChanged} isUserReadOnly={isUserReadOnly} />
				</div>
			</div>
		</>
	);
};
