import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmDeleteComponent from "components/GeneralComponents/ConfirmDeleteComponent";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { getContent } from "services/getContentAllData";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";
import { getOffersColumns } from "./offersColumn";
import { OffersPromotion } from "./offersPromotion";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import DataTable from "components/GeneralComponents/Table";
import { languageToString } from "utils/global";

const Offers = props => {
	const [showPromotion, setShowPromotion] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [offersData, setOffersData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);
	const [editItem, setEditItem] = useState(null);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [itemTitle, setItemTitle] = useState("");
	const pageHeaderData = {
		international: true,
		addNew: true,
		addNewClick: () => {
			setShowPromotion(true);
		},
	};
	const country = useSelector(state => state.Login.country);

	const promoOffer = async () => {
		try {
			setIsDataLoading(true);
			let response = await getContent("promo_offer", country);

			if (response?.length > 0) {
				response.forEach(element => {
					if (element?.active) {
						element["status"] = "Published";
					} else {
						element["status"] = "Inactive";
					}

					if (element?.details) {
						let parsedDetails = JSON.parse(element["details"]);
						parsedDetails["status"] = element?.["status"];
						element["details"] = JSON.stringify(parsedDetails);
					}
				});

				setOffersData(response);
				setUnfilteredData(response);
				setIsDataLoading(false);
			} else {
				setIsDataLoading(false);
				setOffersData([]);
				setUnfilteredData([]);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const changeOrder = index => {
		const selectedRow = unfilteredData.splice(index, 1)[0];
		const newData = [selectedRow, ...unfilteredData];
		setNewData(newData);
	};

	const orderItems = newOrder => {
		let payload = {
			order: newOrder.map((row, index) => ({ id: row.id, order: index })),
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.order_promo_offer_item, resolve, reject, payload, true);
		}).then(() => {});
	};

	const setNewData = newData => {
		setOffersData(newData);
		setUnfilteredData(newData);
		orderItems(newData);
	};

	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};

	const handleExpanded = (promo, details, id) => {
		let language = languageToString(details, ["title_", "subtitle_", "offer_header_", "offer_description_", "terms_header_", "terms_description_"]);

		const payload = {
			id,
			expanded: !details.expanded,
			title: details?.title,
			published: !!promo.active,
			image: details?.image,
			image_exist: true,
			mobile_image: details?.mobile_image,
			mobile_image_exist: true,
			country_code: "all",
			promo_type: details?.promo_type,
			offer_header: details?.offer_header,
			offer_description: details?.offer_description,
			terms_header: details?.terms_header,
			terms_description: details?.terms_description,
			language,
		};

		new Promise((resolve, reject) => {
			ApiServices.post(`${apiUrls.edit_promo_offer}`, resolve, reject, payload, true);
		}).then(() => {
			promoOffer();
		});
	};

	useEffect(() => {
		promoOffer();
	}, [country]);

	const offersColumns = getOffersColumns({ offersData, props, handleEditItem, setDeleteItem, setItemTitle, changeOrder, handleExpanded });

	return (
		<HelmetLayout titleProps={"Offers"}>
			<div className="cms-page pt-0">
				<SingleSearchFilter
					setData={setOffersData}
					unfilteredData={unfilteredData}
					detailsColumn={["title", "publish_start_date_time", "publish_stop_date_time", "status", "price_boost"]}
				/>
				<DataTable
					data={offersData}
					columns={offersColumns}
					setData={newData => {
						setNewData(newData);
					}}
					options={{
						showButtons: true,
						search: false,
						viewColumns: false,
						customToolbar: () => <AddTableButton className={"data-table-add-btn"} onClick={pageHeaderData["addNewClick"]} />,
					}}
					isLoading={isDataLoading}
					pagination={false}
					orderRows={true}
					sort={true}
				/>
			</div>
			{deleteItem && (
				<ConfirmDeleteComponent
					showDeleteCom={deleteItem}
					deleteLoading={deleteLoading}
					setDeleteLoading={setDeleteLoading}
					deleteTitle={"Confirm Deletion of Promotion"}
					titlename={itemTitle}
					setShowDeleteCom={setDeleteItem}
					betSlipData={offersData}
					setBetSlipData={setOffersData}
					id={deleteItem?.id}
				/>
			)}

			{showPromotion && (
				<OffersPromotion
					title={"Promo Pages"}
					editItem={editItem}
					countries={country}
					getPromoOffer={promoOffer}
					handler={() => {
						setShowPromotion(false);
						setEditItem(null);
					}}
				/>
			)}
		</HelmetLayout>
	);
};

export default Offers;
