import React, { useEffect, useRef, useState } from "react";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";
import { AvForm } from "availity-reactstrap-validation";
import moment from "moment";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { checkIfTwoOjectsAreEqual, checkGenericUrl, convertFormatDateInUtc, getFormatDateByUserTimezone } from "utils/global";
import { toast } from "react-toastify";
import xIcon from "../../../assets/images/x-icon.svg";
import { languageToString } from "utils/global";

export const BannerPromotion = ({ title, handler, editItem, getHeroBanner, countires, pageLayoutType }) => {
	let details = null;
	if (editItem) {
		details = JSON.parse(editItem?.details);
	}

	const [heroBannerData, setHeroBannerData] = useState({
		checked: editItem ? editItem?.active : true,
		title: details ? details?.title : "",
		show_title: details ? details?.show_title : true,
		subtitle: details ? details?.subtitle : "",
		image: details ? details?.image : null,
		image_exist: editItem ? true : false,
		link: details ? details?.link : "",
		link_type: details ? details?.link_type : "generic",
		call_to_action: details ? details?.call_to_action : "",
		startDate: editItem
			? getFormatDateByUserTimezone(editItem?.publish_start_date_time)
			: moment().startOf("day").add(1, "minute").format("YYYY-MM-DD HH:mm"),
		endDate: editItem ? getFormatDateByUserTimezone(editItem?.publish_stop_date_time) : "",
		neverExpire: editItem ? editItem?.never_expire : true,
		specific_days: details ? details?.specific_days : false,
		selected_days: details?.selected_days ? details?.selected_days : [],
		sport_id: details ? details?.sport_slug : "",
		event_id: details ? details?.event_raw_id : "",
		competition_id: details ? details?.competition_raw_id : "",
		game_id: details ? details?.game_id : "",
	});
	const [unchangedData, setUnchangedData] = useState({});
	const [fields, setfields] = useState([]);

	const [loading, setLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [selectedIds, setSelectedIds] = useState({
		sport_id: details ? details?.sport_slug : "",
		market_id: details ? details?.market_id : "",
		event_id: details ? details?.event_raw_id : "",
		competition_id: details ? details?.competition_raw_id : "",
		link_type: "generic",
	});

	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};

	const handleAcceptedFiles = () => {};
	const addHeroBanner = () => {
		let payload = {
			published: heroBannerData?.checked ? "1" : "0",
			title: heroBannerData?.title,
			subtitle: heroBannerData?.subtitle,
			image: heroBannerData?.image,
			image_exist: heroBannerData?.image_exist,
			link: heroBannerData?.link,
			start_date: convertFormatDateInUtc(heroBannerData?.startDate),
			end_date: heroBannerData?.endDate,
			type: "banner",
			country_code: countires ? countires : "all",
			name: "",
			link_type: heroBannerData?.link_type,
			sport_id: heroBannerData?.sport_id,
			event_id: heroBannerData?.event_id,
			competition_id: heroBannerData?.competition_id,
			market_id: "",
			never_expire: heroBannerData?.neverExpire ? 1 : 0,
			page_type: pageLayoutType,
			call_to_action: heroBannerData?.call_to_action ? heroBannerData?.call_to_action : "",
			show_title: heroBannerData?.show_title ? 1 : 0,
			specific_days: heroBannerData?.specific_days ? 1 : 0,
			selected_days: heroBannerData?.selected_days,
			game_id: heroBannerData?.game_id,
		};

		if (heroBannerData?.link_type === "generic") {
			const shouldStop = checkGenericUrl(heroBannerData?.link);
			if (shouldStop) return;
		}

		let language = languageToString(heroBannerData, ["title_", "subtitle_", "call_to_action_"]);
		// Object.entries(heroBannerData).forEach(([key, value]) => {
		// 	if (key.includes("title_") || key.includes("subtitle_") || key.includes("call_to_action_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });
		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };

		if (heroBannerData?.link_type == "sport") {
			delete payload.competition_id;
			delete payload.event_id;
		}

		if (!heroBannerData?.title) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter title</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.add_hero_banner, resolve, reject, payload, true, fields);
		})
			.then(() => {
				setDataChanged(false);
				getHeroBanner();
				handler();
				setSelectedIds({
					...selectedIds,
					sport_id: "",
					market_id: "",
					event_id: "",
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const editHeroBanner = () => {
		let payload = {
			published: heroBannerData?.checked ? "1" : "0",
			title: heroBannerData?.title,
			subtitle: heroBannerData?.subtitle,
			subtitle_fr: heroBannerData?.subtitle_fr,
			subtitle_es: heroBannerData?.subtitle_es,
			image: heroBannerData?.image,
			image_exist: heroBannerData?.image_exist,
			link: heroBannerData?.link,
			start_date: convertFormatDateInUtc(heroBannerData?.startDate),
			end_date: heroBannerData?.endDate,
			type: "banner",
			country_code: countires ? countires : "all",
			name: "",
			title_fr: heroBannerData?.title_fr,
			title_es: heroBannerData?.title_es,
			link_type: heroBannerData?.link_type,
			sport_id: heroBannerData?.sport_id,
			event_id: heroBannerData?.event_id,
			competition_id: heroBannerData?.competition_id,
			market_id: "",
			id: editItem?.id,
			never_expire: heroBannerData?.neverExpire ? 1 : 0,
			page_type: pageLayoutType,
			call_to_action: heroBannerData?.call_to_action ? heroBannerData?.call_to_action : "",
			show_title: heroBannerData?.show_title ? 1 : 0,
			specific_days: heroBannerData?.specific_days ? 1 : 0,
			selected_days: heroBannerData?.selected_days,
			game_id: heroBannerData?.game_id,
		};

		if (heroBannerData?.link_type === "generic") {
			const shouldStop = checkGenericUrl(heroBannerData?.link);
			if (shouldStop) return;
		}

		let language = languageToString(heroBannerData, ["title_", "subtitle_", "call_to_action_"]);
		// Object.entries(heroBannerData).forEach(([key, value]) => {
		// 	if (key.includes("title_") || key.includes("subtitle_") || key.includes("call_to_action_")) {
		// 		language += `"${key}":"${value}",`;
		// 	}
		// });

		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };
		if (heroBannerData?.link_type == "sport") {
			delete payload.competition_id;
			delete payload.event_id;
		}
		if (!heroBannerData?.title) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please enter title</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.edit_hero_banner, resolve, reject, payload, true, fields);
		})
			.then(() => {
				getHeroBanner();
				handler();
				setSelectedIds({
					...selectedIds,
					sport_id: "",
					market_id: "",
					event_id: "",
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const dependeciesObj = {
		sport_id: heroBannerData.sport_id,
		comp_id: heroBannerData.competition_id,
		link_type: heroBannerData.link_type,
		event_id: heroBannerData.competition_id,
	};

	useEffect(() => {
		let newFields = [];
		newFields.push({ type: "switch", name: "Published", fieldName: "checked" });

		// if (heroBannerData?.show_title) {
		newFields.push({
			type: "input",
			name: "Title",
			fieldName: "title",
			onFocus: () => handleShowOptions("language", "title"),
			onBlur: () => (onFocus.current = false),
			required: true,
		});
		// }
		newFields.push(
			{ type: "switch", name: "Show Title", fieldName: "show_title" },
			{
				type: "textarea",
				name: "Subtitle",
				fieldName: "subtitle",
				optionsType: "open_type",
				onFocus: () => handleShowOptions("language", "subtitle"),
				onBlur: () => (onFocus.current = false),
			},
			{
				type: "image",
				name: "Media",
				fieldName: "image",
				onFocus: () => handleShowOptions("imageLibrary", true),
				onBlur: () => (onFocus.current = false),
				active: showOptions?.imageLibrary,
				imageType: "banner",
				data: heroBannerData,
				setData: setHeroBannerData,
				setDataChanged: setDataChanged,
				dimensions: "(3480 x 230 px)",
			},
			{
				type: "dropdown",
				name: "Link Type",
				fieldName: "link_type",
				optionsType: "link_type",
				emptyValues: ["competition_id", "event_id", "sport_id", "page_id", "link"],
			}
		);
		if (heroBannerData.link_type === "generic") {
			newFields.push({ type: "input", name: "URL Address", fieldName: "link", required: true });
		} else if (heroBannerData.link_type === "event") {
			newFields.push(
				{ type: "dropdown", name: "Sport", fieldName: "sport_id", optionsType: "allSports", emptyValues: ["competition_id"], required: true },
				{
					type: "dropdown",
					name: "Competition",
					fieldName: "competition_id",
					optionsType: "competitions",
					id: heroBannerData?.sport_id,
					params: heroBannerData?.sport_id,
					required: true,
				},
				{
					type: "dropdown",
					name: "Event",
					fieldName: "event_id",
					optionsType: "event",
					id: heroBannerData.competition_id,
					params: heroBannerData.competition_id,
					property: "link_type",
					link_type: `/${heroBannerData?.sport_id}/event/`,
					required: true,
				},
				{ type: "input", name: "URL Address", fieldName: "link", disabled: true, required: true }
			);
		} else if (heroBannerData.link_type !== "" && heroBannerData.link_type !== undefined) {
			let name;
			let optionsType;
			let link = "";
			let inputType = "dropdown";
			let fieldName = "sport_id";
			if (heroBannerData.link_type === "modal") {
				name = "Page";
				optionsType = "page_content";
				link = "/";
			} else if (heroBannerData.link_type === "sport") {
				name = "Sport";
				optionsType = "allSports";
				link = "/sport/";
			} else if (heroBannerData.link_type === "casino") {
				name = "Game";
				optionsType = "game";
				link = "/casino/";
				inputType = "filterDropdown";
				fieldName = "game_id";
			} else if (heroBannerData.link_type === "page") {
				name = "Page";
				optionsType = "page_types";
			}
			newFields.push(
				{
					type: inputType,
					name: name,
					fieldName: fieldName,
					optionsType: optionsType,
					link_type: link,
					id: heroBannerData?.filterDropdownValue,
					property: "link_type",
					required: true,
				},
				{ type: "input", name: "URL Address", fieldName: "link", disabled: true, required: true }
			);
		}
		newFields.push(
			{
				type: "input",
				name: "CTA",
				fieldName: "call_to_action",
				onFocus: () => handleShowOptions("language", "call_to_action"),
				onBlur: () => (onFocus.current = false),
			},
			{ type: "date", name: "Start Date", fieldName: "startDate", isTimeExist: false, required: true },
			{ type: "switch", name: "Never Expire", fieldName: "neverExpire" }
		);

		!heroBannerData?.neverExpire ? newFields.push({ type: "date", name: "End Date", fieldName: "endDate", isTimeExist: false, required: true }) : "";
		newFields.push({ type: "switch", name: "Specific Days", fieldName: "specific_days" });
		heroBannerData.specific_days ? newFields.push({ type: "dropdown", name: "Days", fieldName: "selected_days", optionsType: "days", multiple: true }) : "";

		setfields(newFields);
	}, [heroBannerData, showOptions?.imageLibrary]);

	useEffect(() => {
		if (details) {
			let newData = { ...heroBannerData };
			Object.entries(details).forEach(([key, value]) => {
				if (key.includes("subtitle_") || key.includes("title_") || key.includes("call_to_action_")) {
					newData = { ...newData, [key]: value };
				}
			});
			setHeroBannerData(newData);
			setUnchangedData(newData);
		}
	}, []);

	useEffect(() => {
		if (editItem) {
			const valuesTheSame = checkIfTwoOjectsAreEqual(unchangedData, heroBannerData);
			if (valuesTheSame) {
				setDataChanged(false);
			}
		}
	}, [heroBannerData]);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={handler} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editItem ? editHeroBanner : addHeroBanner} loading={loading} dataChanged={dataChanged} onClose={handler} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
						<AvForm id="bannerForm" onClick={() => !onFocus.current && handleShowOptions()}>
							<AllComponentsLoop
								fields={fields}
								data={heroBannerData}
								setData={setHeroBannerData}
								setDataChanged={setDataChanged}
								dependecies={dependeciesObj}
							/>
						</AvForm>
					</div>
					<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
						{showOptions?.language && (
							<LanguageTitle data={heroBannerData} setData={setHeroBannerData} title={showOptions?.language} handleDataChanged={handleDataChanged} />
						)}
						{showOptions?.imageLibrary && (
							<ImageLibrary
								data={heroBannerData}
								setData={setHeroBannerData}
								handleAcceptedFiles={handleAcceptedFiles}
								type="banner"
								handleDataChanged={handleDataChanged}
								handleShowOptions={handleShowOptions}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
