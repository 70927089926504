import React from "react";
import { getFormatDateByUserTimezone } from "utils/global";
import { ThousandSeperated } from "hooks/General";
import { _paymentTypes } from "utils/constants";
import { capitalizeText } from "services/capitalizeText";

export const getAdjustmentTable = ({ bankingManualAdjustmentData }) => {
	return [
		{
			name: "transaction_date",
			label: "DATE",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankingManualAdjustmentData[meta.currentTableData[meta.rowIndex].index];
					const date = getFormatDateByUserTimezone(row.transaction_date, "YYYY-MM-DD");
					return <>{date}</>;
				},
				setCellProps: () => ({ style: { minWidth: "110px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "110px" } }),
			},
		},
		{
			name: "transaction_date",
			label: "TIME",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankingManualAdjustmentData[meta.currentTableData[meta.rowIndex].index];
					const date = getFormatDateByUserTimezone(row.transaction_date, "HH:mm");
					return <>{date}</>;
				},
			},
		},

		{
			name: "transaction_type",
			label: "TXN TYPE",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankingManualAdjustmentData[meta.currentTableData[meta.rowIndex].index];
					let title = row?.transaction_type;
					let formattedTitle = title
						.replace(/_/g, " ")
						.toLowerCase()
						.replace(/(?:^|\s)\S/g, function (char) {
							return char.toUpperCase();
						});
					return <span>{formattedTitle}</span>;
				},
				setCellProps: () => ({ style: { minWidth: "115px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "115px" } }),
			},
		},
		{
			name: "payment_type",
			label: "PAYMENT TYPE",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankingManualAdjustmentData[meta.currentTableData[meta.rowIndex].index];
					const { payment_type } = row;
					let newPaymenType = payment_type === "non" ? "Non Trading Adjustment" : _paymentTypes.find(row => row?.id === payment_type)?.label;
					return <span>{newPaymenType || capitalizeText(payment_type)}</span>;
				},
				setCellProps: () => ({ style: { minWidth: "250px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "250px" } }),
			},
		},
		{
			name: "description",
			label: "DESCRIPTION",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankingManualAdjustmentData[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<a>{row?.description}</a>
						</>
					);
				},
				setCellProps: () => ({ style: { minWidth: "300px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "300px" } }),
			},
		},
		{
			name: "owner",
			label: "OWNER",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankingManualAdjustmentData[meta.currentTableData[meta.rowIndex].index];
					const owner = row?.owner || "-";
					return (
						<>
							<a>{owner}</a>
						</>
					);
				},
				setCellProps: () => ({ style: { minWidth: "110px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "110px" } }),
			},
		},
		{
			name: "amount",
			label: "AMOUNT",
			align: "right",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const row = bankingManualAdjustmentData[meta.currentTableData[meta.rowIndex].index];
					const isDeposit = row?.transaction_type === "withdrawal";
					const amountStyle = {
						color: isDeposit ? "#B71C1C" : "inherit",
						textAlign: "right",
					};
					return <div style={amountStyle}>{isDeposit ? `(${ThousandSeperated(row?.amount)})` : ThousandSeperated(row?.amount)}</div>;
				},
				setCellProps: () => ({ style: { minWidth: "50px" }, className: "align-right" }),
				setCellHeaderProps: () => ({ style: { minWidth: "50px" }, className: "align-right" }),
			},
		},

		// {
		// 	name: "balance",
		// 	label: "BALANCE",

		// 	sortable: true,
		// 	sort: "asc",
		// 	options: {
		// 		customBodyRender: (value, meta) => {
		// 			const row = bankingManualAdjustmentData[meta.currentTableData[meta.rowIndex].index];
		// 			const isDeposit = row?.transaction_type === "withdrawal";
		// 			const balanceValue = row?.balance ? ThousandSeperated(row?.balance) : "-";
		// 			const amountStyle = {
		// 				color: isDeposit ? "#B71C1C" : "inherit",
		// 				textAlign: "center",
		// 			};
		// 			return <div style={amountStyle}>{balanceValue}</div>;
		// 		},
		// 		setCellProps: () => ({ style: { textAlign: "center", width: "80px" } }),
		// 		setCellHeaderProps: () => ({ style: { width: "80px" }, className: "max-button" }),
		// 	},
		// },
	];
};
