import React from "react";
import { casinoTypes } from "utils/constants";
import CasinoPageLayout from "./casinoPageLayout";

import "./casinoGames.scss";

const LiveCasino = () => {
	const type = casinoTypes.LIVE_CASINO;

	return <CasinoPageLayout type={type} title="Live Casino" />;
};

export default LiveCasino;
