import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import SidebarContent from "./SidebarContent";
import { XCloseIcon } from "utils/icons";
import { setShowSidebar } from "store/layout/actions";

import "./sidebar.scss";

const Sidebar = props => {
	const showSidebar = useSelector(state => state.Layout.showSidebar);

	const logo = useSelector(state => state.Layout.logo?.mobile_logo);
	const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;
	const url = aws_s3_url + "/media/" + logo;
	const dispatch = useDispatch();

	const onCloseSideBar = () => {
		dispatch(setShowSidebar(false));
	};

	return (
		<React.Fragment>
			<div className={`vertical-menu sidebar-wrapper ${showSidebar ? "sidebar-wrapper-open" : ""}`}>
				<div>
					<div className="sidebar-logo">
						<Link to="/" className="logo">
							<img src={url} alt="" />
						</Link>

						<div className="close-icon" onClick={onCloseSideBar}>
							<XCloseIcon />
						</div>
					</div>
				</div>
				<div data-simplebar className="h-100">
					{props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
				</div>
			</div>
		</React.Fragment>
	);
};

Sidebar.propTypes = {
	type: PropTypes.string,
};

const mapStatetoProps = state => {
	return {
		layout: state.Layout,
	};
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
