import React, { useEffect, useRef, useState } from "react";
import { NoButton, UploadButton, YesButton } from "../../components/GeneralComponents/AddButton";
import { Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { getImages } from "services/getImages";
import { deleteImages } from "services/deleteImages";
import saveImages from "services/addMultipleImages";
import "./ImageLibrary.scss";
import { XCloseIcon } from "utils/icons";
const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;
export const FooterImageLibrary = ({
	data,
	setData,
	// handleAcceptedFiles,
	type,
	imageFor,
	setShowSave,
	className,
	handleDataChanged,
	mediaLibraryUpload,
	getImagesMediaLibrary,
	pageLayoutType,
	itemsData,
	setItemsData,
	imageId,
	selectedImg,
	editData,
	handleShowOptions,
}) => {
	const inputRef = useRef(null);

	const [library, setLibrary] = useState([]);
	const [loading, setLoading] = useState(false);
	const [uploading, setUploading] = useState(false);

	const [addedImageData, setAddedImageData] = useState(null);
	const [checkDeleteLoading, setCheckDeleteLoading] = useState(null);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const imagePath = aws_s3_url + "/media/";

	const handleClick = () => {
		inputRef.current.click();
	};
	const [images, setImages] = useState([]);

	const handleFileChange = async event => {
		let imageValue = event.target.files;
		// event.target.value = null;
		setUploading(true);
		const response = await saveImages(imageValue, type, false);

		setUploading(false);

		if (response) {
			let addedImageData = response?.data?.files[0];
			const isEditedRow = editData?.map(row => row);
			setAddedImageData(addedImageData);
			// setImages(prevImages => [...prevImages, fileObj]);
			if (imageFor === "header_banner") {
				setData({
					...data,
					header_banner: addedImageData?.filename,
					header_banner_exist: true,
				});
			} else {
				setData({
					...data,
					image: addedImageData?.filename,
					image_exist: true,
				});
				selectedImg(addedImageData?.url, isEditedRow);
			}
			handleDataChanged();
			getAllImages();
			if (mediaLibraryUpload) {
				getImagesMediaLibrary(pageLayoutType);
			}
		}
	};

	const getAllImages = () => {
		setLoading(true);
		getImages(type)
			.then(response => {
				setImages(response);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleCheckDelete = id => {
		setCheckDeleteLoading(id);
		let payload = {
			id: [id],
			action: "check",
			type: "footer_links",
		};
		deleteImages(payload)
			.then(() => {
				setShowConfirmDelete(id);
			})
			.finally(() => {
				setCheckDeleteLoading(null);
			});
	};

	const deleteImage = () => {
		let payload = {
			id: [showConfirmDelete],
			action: "delete",
			type: "footer_links",
		};
		deleteImages(payload)
			.then(() => {
				let imagesItems = [...itemsData];
				let newLibrary = [...library];
				newLibrary = newLibrary.filter(image => image.id !== showConfirmDelete);
				let imageItem = imagesItems.find(item => item.id === imageId);
				if (imageItem) {
					imageItem["value"] = null;
				}
				setItemsData(imagesItems);
				setLibrary(newLibrary);
				getAllImages();
				handleDataChanged?.();
			})
			.finally(() => {
				setShowConfirmDelete(null);
			});
	};

	useEffect(() => {
		getAllImages();
		if (mediaLibraryUpload) {
			handleClick();
		}
	}, []);

	return (
		<>
			{mediaLibraryUpload ? (
				<div className={className ? `${className} image-library-container` : `image-library-container`}>
					<div className="d-flex align-items-center justify-content-between">
						<input style={{ display: "none" }} ref={inputRef} type="file" accept="image/*" multiple onChange={handleFileChange} />
					</div>
				</div>
			) : (
				<div className="image-library" onClick={() => handleShowOptions("imageLibrary", false)}>
					<div className={className ? `${className} image-library-container` : `image-library-container`}>
						<div className="upload_button_container">
							<span className="image-library-title">Image Library</span>
							<input style={{ display: "none" }} ref={inputRef} type="file" accept="image/*" multiple onChange={handleFileChange} />
							<UploadButton name={"Upload"} onClick={handleClick} classname="upload_button_in_media_library" loading={uploading} />
						</div>
						<div className="uploaded-library">
							{loading ? (
								<div className="images-library-spinner">
									<Spinner animation={"border"} size={"sm"} />
								</div>
							) : (
								<>
									<div className="row library-images-row">
										<div className="title_library_image">
											<p>{type}</p>
											<XCloseIcon />
										</div>
										{images.map((row, index) => {
											const active = editData?.find(row => row.id === imageId)?.value === row?.image;
											const isEditedRow = editData?.map(row => row);

											return (
												<div className={`library-image-container ${active ? "active-images" : ""}`} key={index}>
													<button
														className="btn btn-danger btn-sm delete-library-image delete-image-btn"
														onClick={() => {
															handleCheckDelete(row.id);
														}}
													>
														{checkDeleteLoading === row.id ? <Spinner animation={"border"} size={"sm"} /> : "x"}
													</button>
													<img
														src={imagePath + row?.image}
														alt="footer icon"
														className="image-input-file"
														onClick={() => {
															if (imageFor === "header_banner") {
																setData({
																	...data,
																	header_banner: addedImageData?.name,
																	header_banner_exist: true,
																});
															} else {
																setData({
																	...data,
																	image: addedImageData?.name,
																	image_exist: true,
																});
																selectedImg(row?.image, isEditedRow);
															}
															if (setShowSave) {
																setShowSave(true);
															}
														}}
													/>
												</div>
											);
										})}

										{library.map((row, index) => {
											return (
												<div className={row?.is_white === 1 ? "white-image library-image-container" : "library-image-container"} key={index}>
													<button
														className="btn btn-danger btn-sm delete-library-image delete-image-btn"
														onClick={() => {
															handleCheckDelete(row.id);
														}}
													>
														{checkDeleteLoading === row.id ? <Spinner animation={"border"} size={"sm"} /> : "x"}
													</button>
													{row?.file_type.indexOf("video") > -1 ? (
														<div className="library-image-container library-img-div">
															<video
																className="VideoInput_video"
																width="80%"
																height={"100"}
																controls
																src={imagePath + row?.image}
																alt={row?.image}
																key={index}
																onClick={() => {
																	if (imageFor === "header_banner") {
																		setData({
																			...data,
																			header_banner: row?.image,
																			header_banner_file: row?.file_type,
																			header_banner_exist: true,
																		});
																		handleDataChanged();
																	} else {
																		setData({
																			...data,
																			image: row?.image,
																			image_file_type: row?.file_type,
																			image_exist: true,
																		});
																		handleDataChanged();
																	}
																	if (setShowSave) {
																		setShowSave(true);
																	}
																}}
															/>
														</div>
													) : (
														<img
															src={imagePath + row?.image || imagePath + row?.header_banner}
															alt={row?.image}
															key={index}
															onClick={() => {
																if (imageFor === "header_banner") {
																	setData({
																		...data,
																		header_banner: row?.image,
																		header_banner_exist: true,
																		image_exist: true,
																	});
																	handleDataChanged();
																}
																if (type == "seo_image") {
																	setData({
																		...data,
																		seo_image: row?.image,
																		image_exist: true,
																	});
																} else {
																	setData({
																		...data,
																		image: row?.image,
																		image_exist: true,
																	});
																	handleDataChanged();
																}
																if (setShowSave) {
																	setShowSave(true);
																}
															}}
														/>
													)}
												</div>
											);
										})}
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			)}
			{showConfirmDelete && (
				<SweetAlert title={"Delete images"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div>
							<span className="delete-span">Are you sure you want to delete image ?</span>
						</div>
					</div>
					<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
						<NoButton onClick={() => setShowConfirmDelete(false)} name="No" />
						<YesButton name="Yes" onClick={deleteImage} />
					</div>
				</SweetAlert>
			)}
		</>
	);
};
