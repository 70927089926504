import React, { useRef, useState } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { XCloseIcon } from "utils/icons";
import {
	GeneralDropdown,
	GeneralInput,
	NotificationDateInput,
	NotificationMessageInput,
	NotificationUserInput,
} from "./../../../components/GeneralComponents/CustomInputs";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { useSelector } from "react-redux";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { toast } from "react-toastify";
import xIcon from "../../../assets/images/x-icon.svg";
import { AvForm } from "availity-reactstrap-validation";
import { languageToString } from "utils/global";

export const ShowCreateMessagePushNotificationModal = ({ countries, setShowCreateMessage, specialArr, showCreateMessage, getData }) => {
	let title = "Push Notification";
	const [showOptions, setShowOptions] = useState([]);
	const onFocus = useRef(false);

	const [dataChanged, setDataChanged] = useState(false);
	const [sentDate, setSentDate] = useState("");
	const [actualDate, setActualDate] = useState("");
	const [saveLoading, setSaveLoading] = useState(false);
	const user = useSelector(state => state.Login.user);
	const [createMessageData, setCreateMessageData] = useState({
		id: "",
		title: "",
		message: "",
		platform: "all",
		type: "only_notification",
		special_bet_id: "",
	});
	const [userData, setUserData] = useState({
		type: "all",
		data: [],
		show_select: false,
	});

	const addPushNotification = () => {
		var actualDateNow = actualDate || sentDate;
		let payload = {
			id: createMessageData.id,
			title: createMessageData.title,
			message: createMessageData.message,
			type: userData.type,
			sent_at: actualDateNow,
			platform: createMessageData.platform ? createMessageData.platform : "all",
			cms_user_id: user.id,
			type_message: createMessageData.type ? createMessageData.type : "only_notification",
			object_id: createMessageData?.type === "special_bet" ? createMessageData?.special_bet_id : "0",
		};

		let language = languageToString(createMessageData, ["title_"]);
		// Object.entries(createMessageData).forEach((row, key) => {
		// 	if (row[0].indexOf("title_") > -1) {
		// 		if (key + 1 === Object.entries(createMessageData).length) {
		// 			language += `"${row[0]}":"${row[1]}"`;
		// 		} else {
		// 			language += `"${row[0]}":"${row[1]}",`;
		// 		}
		// 	}
		// });
		// language = language.replace(/,(\s+)?$/, ""); // remove last comma
		payload = { ...payload, language: `${language}` };

		if (createMessageData?.type === "special_bet") {
			if (!createMessageData?.special_bet_id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select special bet</p>
					</>,
					{ className: "custom-toast" }
				);
				return false;
			}
		}
		if (!sentDate && actualDate) {
			actualDateNow = actualDate;
		}
		if (userData.type === "player_id") {
			if (!createMessageData.id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please choose a user</p>
					</>,
					{ className: "custom-toast" }
				);
				return false;
			}
		}
		if (userData.type === "country") {
			if (!createMessageData.id) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please choose a country</p>
					</>,
					{ className: "custom-toast" }
				);
				return false;
			}
		}
		if (!createMessageData.title) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please write title of notification</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}
		if (!createMessageData.message) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please write message of notification</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}
		if (!actualDateNow) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select date</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}
		setSaveLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.add_push_notification, resolve, reject, payload, true);
		})
			.then(response => {
				console.log(response);

				getData();
				setDataChanged(false);
				// let data = response?.data?.returnData?.data;
				// setRecords(data);
				setCreateMessageData({
					...createMessageData,
					id: "",
					title: "",
					message: "",
					platform: "",
				});
				setSentDate("");
				setUserData({
					...userData,
					type: "player_id",
					data: [],
					show_select: true,
				});
			})

			.finally(() => {
				setSaveLoading(false);
				setShowCreateMessage(false);
			});
	};

	const handleShowOptions = (fieldName, value) => {
		onFocus.current = true;
		setShowOptions({ [fieldName]: value });
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const handleChangedDropdown = (newValue, fieldName) => {
		setCreateMessageData({ ...createMessageData, [fieldName]: newValue?.id });
		setDataChanged(true);
	};

	return (
		<>
			{showCreateMessage && (
				<div className="promotion-container">
					<div className="promotion-header">
						<span>{title}</span>
						<XCloseIcon
							className={"xclose-icon"}
							onClick={() => {
								setShowCreateMessage(false);
							}}
						/>
					</div>
					<div className="promotion-body" style={{ overflow: "none" }}>
						<TopBarComponent
							saveHandler={addPushNotification}
							dataChanged={dataChanged}
							loading={saveLoading}
							onClose={() => setShowCreateMessage(false)}
						/>
						<div className="promotion-elements row m-0">
							<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
								<AvForm onClick={() => !onFocus.current && handleShowOptions()}>
									<div style={{ position: "relative" }}>
										<div>
											<GeneralDropdown
												object={{
													name: "Type",
													value: createMessageData?.type,
													bindValue: createMessageData?.type,
													options: typeOptions,
													fieldName: "type",
													disabled: true,
													required: true,
												}}
												handleChange={handleChangedDropdown}
											/>

											<GeneralDropdown
												object={{
													name: "Platform",
													value: createMessageData?.platform,
													options: platformOptions,
													fieldName: "platform",
													required: true,
												}}
												handleChange={handleChangedDropdown}
											/>

											{createMessageData.type == "special_bet" ? (
												<GeneralDropdown
													object={{
														name: "Special Bet",
														value: createMessageData?.special_bet_id,
														options: specialArr?.map(({ id, description }) => ({ id, label: description })),
														fieldName: "special_bet_id",
														required: true,
													}}
													handleChange={handleChangedDropdown}
												/>
											) : (
												""
											)}

											<NotificationUserInput
												userData={userData}
												setUserData={setUserData}
												data={createMessageData}
												setData={setCreateMessageData}
												handleDataChanged={handleDataChanged}
												handleShowOptions={handleShowOptions}
												countries={countries}
												otherRowClass
											/>

											<GeneralInput
												object={{
													name: "Title",
													value: createMessageData.title,
													onFocus: () => handleShowOptions("language", "descrititleption"),
													onBlur: () => (onFocus.current = false),
													required: true,
												}}
												onChange={e => {
													handleDataChanged();
													setCreateMessageData({ ...createMessageData, title: e.target.value });
												}}
											/>
											<NotificationMessageInput
												data={createMessageData}
												setData={setCreateMessageData}
												handleDataChanged={handleDataChanged}
												otherRowClass
												required
											/>
											{/* <GeneralInput
												object={{
													name: "Deep Link",
													value: createMessageData.link || "Deep Link",
													inputClassName: "link-disable-input",
												}}
											/>
											<GeneralInput
												object={{
													name: "Link Attribute",
													value: createMessageData.link || "Link Attribute",
													inputClassName: "link-disable-input",
												}}
											/> */}
											<NotificationDateInput
												actualDate={actualDate}
												setActualDate={setActualDate}
												setSentDate={setSentDate}
												handleDataChanged={handleDataChanged}
												otherRowClass
											/>
										</div>
									</div>
								</AvForm>
							</div>
							<div className="promotion-right col-12 col-lg-6 customized_modal_promotion_left">
								{showOptions.language && (
									<LanguageTitle title={"title"} data={createMessageData} setData={setCreateMessageData} handleDataChanged={handleDataChanged} />
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const typeOptions = [
	{ id: "only_notification", label: "Notification" },
	{ id: "special_bet", label: "Special Bet" },
];

const platformOptions = [
	{ id: "all", label: "All" },
	{ id: "ios", label: "iOS" },
	{ id: "android", label: "Android" },
	{ id: "web", label: "Web" },
];
