import React, { useEffect, useState } from "react";
import { EndTimeInput, SwitchInput, StartTimeInput, SpecificTimesInput, SpecificDaysInput } from "components/GeneralComponents/CustomInputs";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { toast } from "react-toastify";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import xIcon from "../../../assets/images/x-icon.svg";
import { useDispatch } from "react-redux";
import { setBackdrop } from "store/Backdrop/actions";
import { InfoIconHelper } from "utils/icons";
import InformationPageHelper from "../InformationPageHelper";
import { TraderChatHelpInformation } from "../HelpPagesForSettings/HelpPages";
import { AvForm } from "availity-reactstrap-validation";

function TraderChat() {
	const [data, setData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [unchagedData, setUnchangedData] = useState([]);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		getTraderChatSettings();
	}, []);

	// Get all trader chat settings
	const getTraderChatSettings = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.trader_chat, resolve, reject, true);
		})
			.then(response => {
				let data = response?.data[0];
				setData(data);
				setUnchangedData(data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	// Edit trader chat settings
	const editTraderChatSettings = () => {
		if (data?.specific_time) {
			if (!data?.start_time || !data?.end_time) {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Please select start and end time</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			}
		}

		let payload = {
			enable_chat: data?.enable_chat,
			start_time: data?.start_time,
			end_time: data?.end_time,
			specific_days: data?.specific_days,
			selected_days: data?.selected_days,
			specific_time: data?.specific_time,
		};
		if (data?.selected_days.length === 0) {
			payload.specific_days = false;
		}
		if (data?.specific_time == false) {
			payload.start_time = "";
			payload.end_time = "";
		}

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.trader_chat_edit, resolve, reject, payload, true);
		})
			.then(() => setDataChanged(false))
			.finally(() => {
				setData(payload);
				setIsDataLoading(false);
			});
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const discardData = () => {
		setData(unchagedData);
		setDataChanged(false);
	};

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	return (
		<div>
			<TopBarComponent
				classname="special_top_component"
				saveHandler={editTraderChatSettings}
				dataChanged={dataChanged}
				discardHandler={discardData}
				loading={isDataLoading}
			/>
			{isDataLoading ? (
				<LoadingSpinner className={"pt-5"} />
			) : (
				<div className="cms-page row">
					<div className=" col-12 trader-form">
						<AvForm>
							<SwitchInput
								onChange={() => {
									handleDataChanged();
									setData({
										...data,
										enable_chat: !data?.enable_chat,
									});
								}}
								object={{
									name: "Global Trader Chat",
									value: data?.enable_chat,
								}}
							/>

							<SpecificDaysInput data={data} setData={setData} handleDataChanged={handleDataChanged} specificDays otherRowClass />
							<SpecificTimesInput data={data} setData={setData} handleDataChanged={handleDataChanged} specificTimes otherRowClass />
							{data?.specific_time && (
								<>
									<StartTimeInput
										data={data}
										custumText="Start Time (UTC)"
										setData={setData}
										handleDataChanged={handleDataChanged}
										description="Enter time to turn on trader chat"
										resetPaddingRight
										specificTimes
										otherRowClass
									/>
									<EndTimeInput
										data={data}
										setData={setData}
										handleDataChanged={handleDataChanged}
										custumText="End Time (UTC)"
										specificTimes
										otherRowClass
										description="Enter time to turn off trader chat"
									/>
								</>
							)}
						</AvForm>
					</div>
				</div>
			)}
			{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<TraderChatHelpInformation />
				</InformationPageHelper>
			)}
		</div>
	);
}
export default TraderChat;
