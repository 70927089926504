import { apiUrls } from "utils/const.apiUrl";

const { default: ApiServices } = require("utils/ApiServices");

const getSportsData = async type => {
	const stopCallingApi = ["customer_service", "auditor", "operations", "affiliate"];
	// if (localStorage.getItem("_sports")) {
	// 	return JSON.parse(localStorage.getItem("_sports"));
	// }
	const params = type ? `?type=${type}` : "";
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.sport_list + params, resolve, reject, true, stopCallingApi);
	})
		.then(response => {
			let allSports = response.data;

			const sportsData = allSports.map(sport => ({ ...sport, id: sport.slug, sportId: sport.id, label: sport.name }));

			return sportsData;
		})

		.catch(e => {
			console.log("ERROr", e?.message);
			return [];
		});
};

export default getSportsData;
