import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { filterAndSortArray } from "utils/global";

export const getAllCmsUsers = async () => {
	if (localStorage.getItem("cmsUsers")) {
		return JSON.parse(localStorage.getItem("cmsUsers"));
	}

	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.GET_CMS_USERS, resolve, reject, true);
	})
		.then(response => {
			let users = response?.data[0];
			users = users.map(row => {
				return { id: row.id, label: row?.first_name + " " + row?.last_name };
			});
			users = filterAndSortArray(users, "label");
			localStorage.setItem("cmsUsers", JSON.stringify(users));
			return users;
		})
		.catch(() => {
			return [];
		});
};
