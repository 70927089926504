import moment from "moment";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cmsSearchFilterData } from "store/actions";
import { SaveFilterData } from "./saveFilterData";
import { SetPreselectedData } from "./setPreselectedData";
import { AddNewButton, FilterButton } from "components/GeneralComponents/AddButton";
import { SearchFilterIcon, Xicon } from "utils/icons";
import downSelectArrow from "../../assets/images/downSelectArrow.svg";
import { transformRow } from "helpers/searchFilter_helper";
import Flatpickr from "react-flatpickr";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import { isEmpty } from "hooks/General";
import { prepareFilterData } from "utils/global";
import MobileSearchFilter from "./mobileSearchFilter";

import "./searchFilter.scss";

function combineDuplicates(arr) {
	const combined = {};

	arr.forEach(item => {
		if (combined[item.name]) {
			combined[item.name].value += ` ${item.value}`;
		} else {
			combined[item.name] = { name: item.name, value: item.value };
		}
	});

	return Object.values(combined);
}

export default function SearchFilter(props) {
	const {
		columns,
		page,
		preSelect = true,
		preSelectedType,
		preSelectedValue,
		preSelectedName,
		preSelectedData = [],
		preSelectedOptionKey = "",
		preSelectedOptionName = "",
		handler,
		filterHandler,
		filterLoading,
		marketsHandler,
		eventsHandler,
		selectionsHandler,
		competitionsHandler,
		maxDate = "",
		className = "col-12",
		updateButton = false,
		dep = {},
	} = props;

	const [selectedSearch, setSelectedSearch] = useState([]);
	const [filterData, setFilterData] = useState({
		type: preSelectedType,
		name: preSelectedName,
		value: preSelectedValue,
		value2: "",
		value3: "",
		selected: preSelectedValue,
		data: preSelectedData,
		data2: [],
		option_key: preSelectedOptionKey,
		option_name: preSelectedOptionName,
		handler: handler,
	});

	const [inputValue, setInputValue] = useState("");
	const [showCadecary, setShowCadecary] = useState(false);
	const [transformedRows, setTransformedRows] = useState([]);
	const [showClearAll, setShowClearAll] = useState(false);
	const dispatch = useDispatch();
	const searchFilterData = useSelector(state => state.Login.searchFilterData);
	const selectedBadgeRef = useRef();
	// const columnsDefaultValues = columns.filter(({ value, defaultValue }) => defaultValue && { value, defaultValue });
	const columnsDefaultValues = columns.reduce((a, v) => (v.defaultValue ? { ...a, [v.value]: v.defaultValue } : a), {});
	const columnsRemoveOthersFilter = columns.filter(v => v.removeOthersFilter).map(v => v.value);
	const [formatedData, setFormatedData] = useState({});

	const [openMobileFilter, setOpenMobileFilter] = useState(false);

	const dateInputMode = useMemo(() => {
		let mode = "range";
		if (filterData.type == "date") {
			const selectedColumn = columns?.find(col => col.value == filterData.value);

			mode = selectedColumn?.mode || "range";
		}
		return mode;
	}, [columns, filterData]);

	// Select colum to filter
	const handleSearchType = e => {
		let index = e.target.selectedIndex;
		let optionElement = e.target.childNodes[index];
		let value = e.target.value;
		let type = optionElement.getAttribute("data-type");
		let name = optionElement.getAttribute("data-name");
		var data = optionElement.getAttribute("data-data");
		let optionKey = optionElement.getAttribute("data-optionkey");
		let optionName = optionElement.getAttribute("data-optionname");
		let value2 = optionElement.getAttribute("data-value2");
		let value3 = optionElement.getAttribute("data-value3");
		var data2 = optionElement.getAttribute("data-data2");

		if (data) {
			data = JSON.parse(data);
		}

		if (data2) {
			data2 = JSON.parse(data2);
		}

		setInputValue("");
		setFilterData({
			...filterData,
			type: type,
			name: name,
			value: value,
			value2: value2,
			value3: value3,
			data: data,
			data2: data2,
			option_key: optionKey,
			option_name: optionName,
		});
	};

	const setDefaultValues = (selectedSearch, { value, key }) => {
		const selectedSeachWithValue = selectedSearch?.filter(s => (s.value !== "" && s.selected !== "") || (s?.key == key && value)).map(s => s.key);

		const newSelectedSearch = selectedSearch.map(s => {
			const removedItem = columns.find(row => row?.removeDefaultValue?.includes(s?.key) && selectedSeachWithValue?.includes(row?.value));

			if (removedItem?.value) {
				return s;
			} else {
				const selectedDefaultValue = columnsDefaultValues[s?.key];

				const value = selectedDefaultValue?.name || selectedDefaultValue;
				const selected = selectedDefaultValue?.value || selectedDefaultValue;
				return selectedDefaultValue && s.value === "" ? { ...s, value: value, selected: selected } : s;
			}
		});

		return newSelectedSearch;
	};

	// remove others filter parameter
	const setRemoveOthersFilter = (selectedSearch, { value, key }) => {
		const newSelectedSearch = selectedSearch.map(s => {
			if (s.key == key) return { ...s, value: value, selected: value };
			else return { ...s, value: "", selected: "" };
		});

		return newSelectedSearch;
	};

	// Input key enter key pressed
	const handleEnterPressed = (e, callback) => {
		let value = e?.target?.value;
		const exist = selectedSearch.some(el => el?.key === filterData.value);
		let updatedSearch = [...selectedSearch];
		const isSelectAndText = filterData?.type == "select_and_text";
		const findTheRemoveElements =
			updatedSearch.find(s => columnsRemoveOthersFilter.includes(s?.key) && s.value) || (columnsRemoveOthersFilter?.includes(filterData?.value) && value);

		let removeOthersFilter = false;

		if (findTheRemoveElements) {
			const newRemoveOthersFilter = setRemoveOthersFilter(updatedSearch, {
				value: findTheRemoveElements?.value || value,
				key: findTheRemoveElements?.key || filterData.value,
			});

			updatedSearch = newRemoveOthersFilter;
			removeOthersFilter = true;

			if (!value && callback) {
				setSelectedSearch(updatedSearch);
				filterHandler(prepareFilterData(updatedSearch, columns));
			}
		}

		if (!removeOthersFilter) {
			if (!isEmpty(columnsDefaultValues)) {
				const newSelectedSearch = setDefaultValues(updatedSearch, { value, key: filterData.value });
				updatedSearch = newSelectedSearch;

				if (!value && callback) {
					setSelectedSearch(updatedSearch);
					filterHandler(prepareFilterData(updatedSearch, columns));
				}
			}
		}

		if (isSelectAndText) {
			setSelectedSearch(updatedSearch);
			filterHandler(prepareFilterData(updatedSearch, columns));
			return;
		}

		if (value) {
			if (!exist) {
				const newUpdatedSearch = [...updatedSearch, { name: filterData.name, key: filterData.value, selected: value, value: value, page: page }];
				setSelectedSearch(newUpdatedSearch);
				setInputValue("");
				if (callback) {
					// SaveFilterData(page, selectedSearch, dispatch, cmsSearchFilterData);
					filterHandler(prepareFilterData(newUpdatedSearch, columns));
				}
			} else {
				const objIndex = updatedSearch.findIndex(obj => obj.key == filterData.value);
				updatedSearch[objIndex].value = value;
				updatedSearch[objIndex].selected = value;
				setSelectedSearch(updatedSearch);

				setInputValue("");
				if (callback) {
					// SaveFilterData(page, selectedSearch, dispatch, cmsSearchFilterData);
					filterHandler(prepareFilterData(updatedSearch, columns));
				}
			}
			setShowCadecary(true);
			setShowClearAll(true);
		}
	};

	// Selected option
	const prevSportName = useRef(null);
	const handleSelectedOption = (id, label) => {
		const exist = selectedSearch.some(el => el?.key === filterData?.value);
		if (!exist) {
			setSelectedSearch(updatedSearch => [...updatedSearch, { name: filterData.name, key: filterData.value, selected: id, value: label, page: page }]);
		} else {
			var updatedSearch = selectedSearch.map(obj => {
				if (obj?.key === filterData.value) {
					return { ...obj, value: label, selected: id };
				}
				if (filterData.value == "sport_id" && obj?.key === "competition_id") {
					return { ...obj, value: "", selected: "" };
				}
				if ((filterData.value == "sport_id" || filterData.value === "competition_id") && obj?.key === "event_id") {
					return { ...obj, value: "", selected: "" };
				}
				return obj;
			});
			let oldSportValue = prevSportName.current;
			let sportValue = updatedSearch?.find(row => row?.key === "sport_id");

			if (oldSportValue && sportValue?.value !== oldSportValue) {
				// setTransformedRows([]);
				prevSportName.current = sportValue?.value;
			}

			setSelectedSearch(updatedSearch);
		}
		setShowClearAll(true);
		setShowCadecary(true);
	};

	useEffect(() => {
		prevSportName.current =
			selectedSearch?.find(row => {
				if (row?.key === "sport_id") {
					return row?.value;
				}
			})?.value || null;
	}, [selectedSearch]);

	// Datetime filter
	const handleDateFilter = selectedDate => {
		var start_time = "";
		var end_time = "";
		var unixTime = moment().unix();
		if (selectedDate) {
			if (selectedDate.indexOf("to") > -1) {
				var betted_date = selectedDate.split("to");
				start_time = moment(betted_date[0].trim(), "DD-MM-YYYY").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(betted_date[1].trim(), "DD-MM-YYYY").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			} else {
				start_time = moment(selectedDate.trim(), "DD-MM-YYYY").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(selectedDate.trim(), "DD-MM-YYYY").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			}
		} else {
			start_time = moment.unix(unixTime).subtract(1, "years").format("YYYY-MM-DD");
			end_time = moment.unix(unixTime).add(1, "years").format("YYYY-MM-DD");
		}

		var selectedDateTime = start_time + " & " + end_time;

		if (dateInputMode !== "range") {
			selectedDateTime = moment(selectedDate.trim(), "DD-MM-YYYY").format("YYYY-MM-DD");
		}

		const exist = selectedSearch.some(el => el?.key === filterData.value);
		if (!exist) {
			setSelectedSearch(updatedSearch => [
				...updatedSearch,
				{
					name: filterData?.name || "Date FROM - TO",
					key: filterData.value,
					selected: selectedDateTime,
					value: selectedDateTime,
					page: page,
				},
			]);
		} else {
			var updatedSearch = [...selectedSearch];
			var objIndex = updatedSearch.findIndex(obj => obj?.key == filterData.value);
			updatedSearch[objIndex].value = selectedDateTime;
			updatedSearch[objIndex].selected = selectedDateTime;

			setSelectedSearch(updatedSearch);

			if (!showCadecary) {
				setShowCadecary(true);
				setShowClearAll(true);
			}
		}
	};

	// Remove search
	const handleRemoveSearch = name => {
		// PreSelected Search
		const newSelectedSearch = selectedSearch.map(item => (item.name === name ? { ...item, selected: "", value: "" } : item));
		setSelectedSearch(newSelectedSearch);

		// Store it in Redux
		// let storedObj = searchFilterData ? searchFilterData[page] : {};
		// let removeFilter = selectedSearch.filter(item => item.name === value);
		// removeFilter = removeFilter[0];
		// storedObj[removeFilter.key] = "";
		dispatch(cmsSearchFilterData({ page: newSelectedSearch }));

		setInputValue("");
		if (transformedRows.length < 0) setShowClearAll(false);
	};

	// Save data to Redux Store
	useEffect(() => {
		if (selectedSearch.length) {
			const prepareData = prepareDataToFilter(selectedSearch);
			setFormatedData(prepareData);
		}
	}, [selectedSearch]);

	const prepareDataToFilter = data => {
		SaveFilterData(page, data, dispatch, cmsSearchFilterData);
		const prepareData = prepareFilterData(data, columns);
		return prepareData;
	};

	// Preselect data if first option in filter is select
	useEffect(() => {
		if (preSelectedData.length > 0) {
			setFilterData({
				...filterData,
				data: preSelectedData,
			});
		}
	}, [preSelectedData]);

	// Set preselected data
	useEffect(() => {
		if (searchFilterData) {
			var savedData = SetPreselectedData(page, searchFilterData, columns, preSelect);

			setSelectedSearch(savedData);
		}
	}, [page, preSelect, JSON.stringify(dep)]);

	useEffect(() => {
		if (!isEmpty(dep)) {
			setShowCadecary(true);
			setShowClearAll(true);
		}
	}, [JSON.stringify(dep)]);

	// Clear all selected search
	const handleRemoveAllSelectedSearch = () => {
		let newSelectedSearch = [...selectedSearch];
		newSelectedSearch.map(row => {
			row.selected = "";
			row.value = "";
		});
		setSelectedSearch(newSelectedSearch);
		setInputValue("");
		// let storedObj = searchFilterData ? searchFilterData[page] : {};
		// Object.keys(storedObj).forEach(key => {
		// 	storedObj[key] = "";
		// });
		dispatch(
			cmsSearchFilterData({
				page: newSelectedSearch,
			})
		);
		setShowClearAll(false);
	};

	// Handle column with select and input
	const handleSelectAndText = (e, type) => {
		let inputValue = "";
		var selectedValue = "";

		if (type) {
			if (type === "input") {
				inputValue = e.target.value;
				// if (inputValue) {
				const exist = selectedSearch.some(el => el.key === filterData.value2);

				if (!exist) {
					setSelectedSearch(updatedSearch => [
						...updatedSearch,
						{ name: filterData.name, key: filterData.value2, selected: inputValue, value: inputValue },
					]);
				} else {
					var updatedSearch = [...selectedSearch];
					var objIndex = updatedSearch.findIndex(obj => obj.key == filterData.value2);
					updatedSearch[objIndex].value = inputValue;
					updatedSearch[objIndex].selected = inputValue;

					setSelectedSearch(updatedSearch);
				}
				if (e.keyCode == 13) {
					handleEnterPressed(e, "callback");
				}
				// }
			} else {
				selectedValue = e.target.value;
				let index = e.target.selectedIndex;
				let optionElement = e.target.childNodes[index];
				let name = optionElement.getAttribute("data-name");
				const exist = selectedSearch.some(el => el.key === filterData.value);
				// if (selectedValue) {

				if (!exist) {
					setSelectedSearch(updatedSearch => [...updatedSearch, { name: filterData.name, key: filterData.value, selected: selectedValue, value: name }]);
				} else {
					let updatedSearch = [...selectedSearch];
					let objIndex = updatedSearch.findIndex(obj => obj.key == filterData.value);
					updatedSearch[objIndex].value = name;
					updatedSearch[objIndex].selected = selectedValue;

					setSelectedSearch(updatedSearch);
				}
				// }
			}
		}
	};

	// Handle column with multi select and input
	const handleMultiSelectAndText = (e, type, ord) => {
		let inputValue = "";
		var selectedValue = "";
		var firstSelectedValue = "";
		var secondSelectedValue = "";
		if (type) {
			if (type == "input") {
				inputValue = e.target.value;
				if (inputValue) {
					const exist = selectedSearch.some(el => el.key === filterData.value3 && el.value && el.selected);
					if (e.keyCode == 13) {
						if (!exist) {
							setSelectedSearch(updatedSearch => [
								...updatedSearch,
								{ name: filterData.name, key: filterData.value3, selected: inputValue, value: inputValue },
							]);
						} else {
							let updatedSearch = [...selectedSearch];
							let objIndex = updatedSearch.findIndex(obj => obj.key == filterData.value3);
							updatedSearch[objIndex].value = inputValue;
							updatedSearch[objIndex].selected = inputValue;
							setSelectedSearch(updatedSearch);
						}
					}
				}
			} else {
				if (ord == "first") {
					firstSelectedValue = e.target.value;
					selectedValue = firstSelectedValue;
					let index = e.target.selectedIndex;
					let optionElement = e.target.childNodes[index];
					let name = optionElement.getAttribute("data-name");
					const exist = selectedSearch.some(el => el.key === filterData.value);
					if (selectedValue) {
						if (!exist) {
							setSelectedSearch(updatedSearch => [
								...updatedSearch,
								{ name: filterData.name, key: filterData.value, selected: selectedValue, value: name },
							]);
						} else {
							let updatedSearch = [...selectedSearch];
							let objIndex = updatedSearch.findIndex(obj => obj.key == filterData.value);
							updatedSearch[objIndex].value = name;
							updatedSearch[objIndex].selected = selectedValue;
							setSelectedSearch(updatedSearch);
						}
					}
				} else {
					secondSelectedValue = e.target.value;
					selectedValue = secondSelectedValue;
					let index = e.target.selectedIndex;
					let optionElement = e.target.childNodes[index];
					let name = optionElement.getAttribute("data-name");
					const exist = selectedSearch.some(el => el.key === filterData.value2);
					if (selectedValue) {
						if (!exist) {
							setSelectedSearch(updatedSearch => [
								...updatedSearch,
								{ name: filterData.name, key: filterData.value2, selected: selectedValue, value: name },
							]);
						} else {
							var updatedSearch = [...selectedSearch];
							var objIndex = updatedSearch.findIndex(obj => obj.key == filterData.value2);
							updatedSearch[objIndex].value = name;
							updatedSearch[objIndex].selected = selectedValue;
							setSelectedSearch(updatedSearch);
						}
					}
				}
			}
		}
	};

	const onSportChangeGetData = sport_id => {
		if (page == "bets") {
			if (sport_id) {
				marketsHandler(sport_id);
				eventsHandler(sport_id);
			}

			if (formatedData.market_id) {
				selectionsHandler(formatedData.market_id);
			}
		}
		if (page == "default_league") {
			if (sport_id) {
				competitionsHandler(sport_id, "");
			}
		}
		if (page == "matches") {
			if (sport_id) {
				competitionsHandler(sport_id);
			}
		}
		if (page == "unclosed_bets") {
			if (sport_id) {
				competitionsHandler(sport_id);
			}
		}
		if (page == "period_competitions") {
			if (sport_id) {
				competitionsHandler(sport_id, "");
			}
		}
	};

	useEffect(() => {
		onSportChangeGetData(formatedData?.sport_id);
	}, [formatedData?.sport_id]);

	const handlerClick = () => {
		if (inputValue || !isEmpty(columnsDefaultValues)) {
			handleEnterPressed({ target: { value: inputValue } }, "callback");
		} else {
			filterHandler(formatedData);
		}
		setShowCadecary(true);
		setShowClearAll(true);
	};

	useEffect(() => {
		let transformedRows = selectedSearch.map(row => transformRow(row)).filter(row => row !== null);

		if (transformedRows.length > 0) {
			setShowCadecary(true);
			setShowClearAll(true);
		}
	}, [selectedSearch.length]);

	useEffect(() => {
		let transformedRows = selectedSearch.map(row => transformRow(row)).filter(row => row !== null);

		if (showCadecary) {
			transformedRows = combineDuplicates(transformedRows);

			setTransformedRows(transformedRows);
		}
	}, [selectedSearch, showCadecary]);

	const optionsSearchFilter = (filterData?.data || []).reduce((acc, row) => {
		if (row[filterData.option_name] && row[filterData.option_key]) {
			const defaultValue = row[filterData.option_key] || row;
			const dataName = row[filterData.option_name] || row;

			const isSportSelected = transformedRows.some(item => item.name === "Sport");
			if (!isSportSelected && filterData.value === "competition_id") {
				return;
			} else {
				acc.push({ id: defaultValue, label: dataName });
			}
		}
		return acc;
	}, []);

	const customizedContainer = transformedRows?.length == 0 ? `${className} search-filter-align` : `${className} search-filter-align-customized`;

	return (
		<div className="search-filter-container">
			<div className={customizedContainer}>
				<div className={`input-group`}>
					<div className="filter-icon-container">
						<div className="filter-icon">
							<SearchFilterIcon />
						</div>

						<div className="filter_trigger" onClick={() => setOpenMobileFilter(true)}>
							Search
						</div>
					</div>
					<div className="input-group-container">
						<div className="input-group-prepend select-container" style={{ marginRight: "0", width: "auto" }}>
							<select className="form-control selectLabelStyles mx-auto select" onChange={e => handleSearchType(e)}>
								{columns.map((row, index) => {
									return (
										<option
											key={index}
											value={row.value}
											data-type={row.type}
											data-name={row.name}
											data-data={row.data}
											data-optionkey={row.optionKey}
											data-optionname={row.optionName}
											data-value2={row.value_2}
											data-value3={row.value_3}
											data-data2={row.data_2}
										>
											{row.name}
										</option>
									);
								})}
							</select>
							<img src={downSelectArrow} className="down-icon" alt="" />
						</div>
						<div
							className={
								filterData.type === "select"
									? "input-group-prepend d-flex mr-3 select-container search_filter_container text_search_filter_container"
									: "input-group-prepend d-flex mr-3 select-container search_filter_container "
							}
						>
							{filterData.type === "text" ? (
								<input
									type={"text"}
									className="form-control inputLabelStyles"
									onKeyDown={e => {
										if (e.keyCode === 13) {
											if (e.shiftKey) {
												handleEnterPressed(e, "callback");
											} else {
												handleEnterPressed(e);
												setShowCadecary(true);
												setShowClearAll(true);
											}
										}
									}}
									onChange={e => {
										if (e.nativeEvent.inputType === "insertFromPaste") return;
										setInputValue(e.target.value);
									}}
									onPaste={e => {
										const value = e.clipboardData.getData("Text");
										handleEnterPressed({ target: { value: inputValue + value } });
										setInputValue(inputValue + value);
										setShowCadecary(true);
										setShowClearAll(true);
									}}
									value={inputValue}
								/>
							) : filterData.type === "select" ? (
								<GeneralDropdown
									handleChange={e => {
										handleSelectedOption(e?.id, e?.label);
										// setInputValue(e?.id);
										handler && handler(e?.id);
									}}
									object={{
										value: inputValue,
										otherRowClass: true,
										options: optionsSearchFilter,
										defaultPlaceholder: "Choose",
										wrapperClassNameDropdown: "search_filter_dropdown_wrapper",
									}}
								/>
							) : filterData.type === "select_and_text" ? (
								<>
									<select className="form-control chooseOptionSelectAndText select" onChange={e => handleSelectAndText(e, "select")}>
										<option value={""}>Choose option</option>
										{filterData.data.map((row, index) => {
											if (row[filterData.option_key] && row[filterData.option_name]) {
												return (
													<option
														value={row[filterData.option_key] ? row[filterData.option_key] : row}
														data-name={row[filterData.option_name] ? row[filterData.option_name] : row}
														key={index}
													>
														{row[filterData.option_name] ? row[filterData.option_name] : row}
													</option>
												);
											}
										})}
									</select>
									<img src={downSelectArrow} className="down-icon-options" alt="" />
									<input
										type={"text"}
										className="form-control searchInput"
										value={inputValue}
										onChange={e => {
											handleSelectAndText(e, "input");
											setInputValue(e.target.value);
										}}
										onKeyDown={e => {
											e?.keyCode == 13 && handleSelectAndText(e, "input");
											setInputValue(e.target.value);
										}}
									/>
								</>
							) : filterData.type === "multi_select_and_text" ? (
								<>
									<select
										className="form-control chooseOptionMultiSelectAndText select"
										onChange={e => handleMultiSelectAndText(e, "select", "first")}
									>
										<option value={""}>Choose option</option>
										{filterData.data.map((row, index) => {
											if (row[filterData.option_key] && row[filterData.option_name]) {
												return (
													<option
														value={row[filterData.option_key] ? row[filterData.option_key] : row}
														data-name={row[filterData.option_name] ? row[filterData.option_name] : row}
														key={index}
													>
														{row[filterData.option_name] ? row[filterData.option_name] : row}
													</option>
												);
											}
										})}
									</select>
									<img src={downSelectArrow} className="down-icon-options" alt="" />

									<select
										className="form-control chooseOptionMultiSelectAndText select"
										onChange={e => handleMultiSelectAndText(e, "select", "second")}
									>
										<option>Choose option</option>
										{filterData.data2.map((row, index) => {
											if (row[filterData.option_key] && row[filterData.option_name]) {
												return (
													<option
														value={row[filterData.option_key] ? row[filterData.option_key] : row}
														data-name={row[filterData.option_name] ? row[filterData.option_name] : row}
														key={index}
													>
														{row[filterData.option_name] ? row[filterData.option_name] : row}
													</option>
												);
											}
										})}
									</select>
									<img src={downSelectArrow} className="down-icon-options-second" alt="" />

									<input
										type={"text"}
										className="form-control inputChoseOption"
										onChange={e => {
											handleMultiSelectAndText(e, "input", "");
											setInputValue(e.target.value);
										}}
										onKeyDown={e => {
											e?.keyCode == 13 && handleMultiSelectAndText(e, "input", "");
											setInputValue(e.target.value);
										}}
									/>
								</>
							) : filterData.type === "date" ? (
								<>
									<Flatpickr
										className="form-control inputLabelStyles"
										style={{ width: "100%" }}
										placeholder="Choose date"
										options={{
											mode: dateInputMode,
											dateFormat: "d-m-Y",
											maxDate: maxDate,
											locale: {
												firstDayOfWeek: 1, // start week on Monday
											},
										}}
										onChange={(date, selectedDate) => handleDateFilter(selectedDate)}
									/>
									<img src={downSelectArrow} className="down-icon" alt="" />
								</>
							) : (
								<input
									type={"text"}
									className="form-control inputLabelStyles"
									onKeyDown={e => e?.keyCode == 13 && handleEnterPressed(e)}
									onChange={e => setInputValue(e.target.value)}
									value={inputValue}
								/>
							)}
						</div>
					</div>

					<div className="d-flex align-items-center justify-content-center filter-button-container">
						<FilterButton
							onClick={() => handlerClick()}
							name={"Filter"}
							loading={filterLoading}
							className={"d-flex align-items-center justify-content-center"}
						/>
						{updateButton && (
							<div className="d-flex align-items-center justify-content-center ms-3">{updateButton ? <AddNewButton name={"Upload"} /> : ""}</div>
						)}
					</div>
				</div>
			</div>
			<div className={`chips-ctn d-flex flex-wrap ${className} ${transformedRows.length ? "mt-2" : ""}`}>
				{transformedRows.map((row, index) => (
					<span className="search-filter-tag" key={index} ref={selectedBadgeRef}>
						<span className="pe-1">{row.name + " | " + row.value}</span>
						<Xicon onClick={() => handleRemoveSearch(row.name)} name={row.name} index={index} />
					</span>
				))}
				{showClearAll && transformedRows?.length > 0 && (
					<span className="clear-all-searches" onClick={() => handleRemoveAllSelectedSearch()}>
						Clear All
					</span>
				)}
			</div>

			{openMobileFilter && (
				<MobileSearchFilter
					onClose={() => setOpenMobileFilter(false)}
					columns={columns}
					filterData={filterData}
					selectedSearch={selectedSearch}
					setSelectedSearch={setSelectedSearch}
					prepareDataToFilter={prepareDataToFilter}
					columnsDefaultValues={columnsDefaultValues}
					columnsRemoveOthersFilter={columnsRemoveOthersFilter}
					setRemoveOthersFilter={setRemoveOthersFilter}
					setDefaultValues={setDefaultValues}
					onSubmit={data => {
						filterHandler(data);
						setShowCadecary(true);
						setShowClearAll(true);
					}}
					onSportChangeGetData={onSportChangeGetData}
				/>
			)}
		</div>
	);
}
