import React from "react";
import { platformName } from "utils/constants";

const SignInFooter = () => {
	return (
		<div className="fixed-bottom">
			<div className="text-center login-footer">
				Copyright © {new Date().getFullYear()} <span className="d-none d-sm-inline-block">{platformName}</span>
			</div>
		</div>
	);
};

export default SignInFooter;
