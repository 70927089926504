import React from "react";
import { XCloseIcon } from "utils/icons";

import "./AccountMenu.scss";

function AccountMenu(props) {
	const { fields, handlerClick, setCloseAccountMenul } = props;

	return (
		<div className="account-menu-bg-modal" style={{ zIndex: "10002" }}>
			<div className="account-menu-container">
				<div className="accout-menu-title">
					<h4> {"Account Menu"} </h4>
					<XCloseIcon className="cc" fill={"#000"} onClick={() => setCloseAccountMenul(false)} />
				</div>

				{fields.map((row, i) => {
					const { name, link } = row;
					return (
						<div className="account-menu-item" key={i} onClick={() => handlerClick(link)}>
							{name}
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default AccountMenu;
