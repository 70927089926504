import React, { useEffect } from "react";

import MetaTags from "react-meta-tags";

import { Row, Col, Card, Alert, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";

import checkIcon from "../../assets/images/CheckIcon.svg";
import { loginLogo, platformName } from "utils/constants";
import SignInFooter from "./SignInFooter";

const PasswordChanged = props => {
	const handleValidSubmit = () => {
		props.history.push("/login");
	};

	useEffect(() => {
		document.body.classList.add("login-body");
		return () => {
			document.body.classList.remove("login-body");
		};
	}, []);
	return (
		<React.Fragment>
			<MetaTags>
				<title>Enter your new password | CMS | {platformName}</title>
			</MetaTags>
			<div className="account-pages my-0 pt-sm-5 my-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5} className="login-flow-card">
							<Card className="overflow-hidden login-card reset-card">
								<h3 className="default-logo-login text-center mt-5">
									<div className="d-block auth-logo">
										<img src={loginLogo} alt="" height="60" width="200" className="auth-logo-dark" style={{ cursor: "auto" }} />
									</div>
								</h3>
								<AvForm
									className="form-horizontal mb-4"
									onValidSubmit={() => {
										handleValidSubmit();
									}}
								>
									{props.error && typeof props.error === "string" ? <Alert color="danger">{props.error}</Alert> : null}
									<div className="row changed-button">
										<img src={checkIcon} alt="" height="60" width="200" className="auth-logo-dark" style={{ cursor: "auto" }} />
										<h4 className="login-title password-changed-title">Password Changed</h4>
										<div className="forget-text password-changed-text">Your password has been successfully changed</div>

										<div className="login-label changed-label">
											<button className="login-button form-control" type="submit">
												Go back and Log In
											</button>
										</div>
									</div>
								</AvForm>
							</Card>
							<SignInFooter />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(connect()(PasswordChanged));
