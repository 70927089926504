import { Autocomplete, TextField } from "@mui/material";
import { YesButton } from "components/GeneralComponents/AddButton";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import React, { useEffect, useState } from "react";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getSportLiabilitiesTableColumns } from "./SportLiabilitiesTableColumns";
import { SportTiers } from "./sportTiers";
import { MarketTierSettings } from "./marketTierSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { ConfirmOrderItems } from "components/GeneralComponents/ConfirmOrderItems";
import MUIDataTable from "mui-datatables";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";

export const SportLiabilities = props => {
	const { sport } = props;
	const [data, setData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [editTiers, setEditTiers] = useState(false);
	const [marketTierSettings, setMarketTierSettings] = useState(null);
	const [confirmOrder, setConfirmOrder] = useState(false);
	const [sportLiabilities, setSportLiabilities] = useState([]);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [selectedTier, setSelectedTier] = useState({
		id: "0",
		name: "Default Tier",
	});

	const getSportTiers = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_tiers + `?sport_slug=${sport?.slug}`, resolve, reject, true);
		}).then(response => {
			response?.data.unshift({ id: "0", name: "Default Tier" });
			setData(response?.data);
		});
	};

	const getSportLiabilities = tier_id => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_liabilities_get + `?sport_slug=${sport?.slug}&tier_id=${tier_id}`, resolve, reject, true);
		})
			.then(response => {
				setSportLiabilities(response?.data);
				setUnfilteredData(response?.data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const filterTiers = selectedValue => {
		getSportLiabilities(selectedValue);
	};

	useEffect(() => {
		if (sport) {
			getSportTiers();
			getSportLiabilities(selectedTier?.id);
		}
	}, []);

	let columns = getSportLiabilitiesTableColumns({ sportLiabilities, setMarketTierSettings, selectedTier });

	const handleScrollToTop = () => {
		// Scroll the table container to the top
		const tableContainer = document.querySelector("table");
		if (tableContainer) tableContainer.scrollIntoView();
	};

	const checkForSelectedTier = newData => {
		const isDeletedTierSelected = !newData?.find(row => row?.id == selectedTier?.id);

		if (isDeletedTierSelected) {
			filterTiers("");
			setSelectedTier(null);
		}
	};

	return (
		<div>
			<div className="sport-tiers-filter">
				<div className="tiers-autocomplete dropdown-wrapper">
					<div className="single-serch-filter">
						<SingleSearchFilter setData={setSportLiabilities} unfilteredData={unfilteredData} filterColumn={["market_name"]} noSpace />
					</div>
					<div className="dropdown-wrapper">
						<Autocomplete
							disablePortal
							className="autocomplete-select input-arrow"
							options={data}
							getOptionLabel={option => option.name}
							value={selectedTier}
							sx={{ width: 300 }}
							renderInput={params => <TextField placeholder={"Select"} {...params} />}
							onChange={(event, newValue, reason) => {
								if (reason === "clear") {
									filterTiers("");
									setSelectedTier(null);
								} else {
									setSelectedTier(newValue);
									filterTiers(newValue?.id);
								}
							}}
						/>
					</div>
					<YesButton name={"Edit Tiers"} onClick={() => setEditTiers(true)} />
				</div>
			</div>
			<MUIDataTable
				columns={columns}
				data={sportLiabilities}
				options={{
					...tableBarSettings,

					selectableRows: "none",
					elevation: 0,
					onChangePage(page) {
						setCurrentPage(page);
						handleScrollToTop();
					},
					onChangeRowsPerPage(number) {
						setRowPerPage(number);
					},
					textLabels: {
						body: {
							noMatch: isDataLoading ? <LoadingSpinner /> : "No data to display!",
						},
					},
					sortOrder: {
						name: "market_name",
						direction: "asc",
					},
					page: currentPage,
					responsive: tableResponsive,
					rowsPerPage: rowPerPage,
					rowsPerPageOptions: tableRowsPerPage,
				}}
				extraButtons={tableExtraButtons}
			/>
			{editTiers && (
				<SportTiers
					tiersData={data}
					setTiersData={setData}
					slug={sport?.slug}
					handler={() => setEditTiers(false)}
					checkForSelectedTier={checkForSelectedTier}
				/>
			)}
			{marketTierSettings && (
				<MarketTierSettings
					data={sportLiabilities}
					setData={setSportLiabilities}
					slug={sport?.slug}
					tier={marketTierSettings}
					handler={() => setMarketTierSettings(false)}
				/>
			)}
			{confirmOrder && <ConfirmOrderItems show={confirmOrder} setShow={setConfirmOrder} />}
		</div>
	);
};
