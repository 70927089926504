import React, { useState } from "react";
import { Spinner } from "reactstrap";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

export const DownloadReport = ({ filename, name }) => {
	const [isLoading, setIsLoading] = useState(false);

	const download = data => {
		const blob = new Blob([data], { type: "text/csv" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.setAttribute("href", url);
		a.setAttribute("download", `${name}.csv`);
		a.click();
	};

	const downloadButtonHandler = () => {
		setIsLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.post(
				apiUrls.download_report,
				resolve,
				reject,
				{
					filename,
				},
				true
			);
		})
			.then(response => {
				download(response);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<div className="d-flex justify-content-start cursor-pointer">
			<div className="badge-sports-blue download_button_reporting" onClick={downloadButtonHandler}>
				{isLoading ? <Spinner animation="border" size="sm" /> : "Download"}
			</div>
		</div>
	);
};
