import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { LoadingSaveButton, NoButton, YesButton } from "components/GeneralComponents/AddButton";

export const ShowDeletePagesContentModal = ({ showDeleteModal, setShowDeleteModal, deletePage, deleteLoading, deleteTitle }) => {
	return (
		<>
			{showDeleteModal && (
				<SweetAlert title="Confirm Deletion" showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="">
							<span className="delete-span">Are you sure you want to delete &quot;{deleteTitle}&quot; ?</span>
						</div>
					</div>
					<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
						<NoButton onClick={() => setShowDeleteModal(false)} name="No" />
						{deleteLoading ? (
							<LoadingSaveButton />
						) : (
							<>
								<YesButton name="Yes" onClick={deletePage} />
							</>
						)}
					</div>
				</SweetAlert>
			)}
		</>
	);
};
