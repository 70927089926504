import React, { useEffect, useState } from "react";

import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

import { toast } from "react-toastify";
import successIcon from "../../../assets/images/success-icon.svg";
import xIcon from "../../../assets/images/x-icon.svg";

const AffiliateUsersGeneral = props => {
	const { affiliateUsers: data, getAffiliateUser } = props;

	const [loading, setLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
	const [affiliateUsers, setAffiliateUsers] = useState({
		id: "",
		active_status: 0,
		revenue_share: "0.00",
		deductions: "0.00",
		taxes: "0.00",
		duties: "0.00",
		platform_fees: "0.00",
		sports_provider_fees: "0.00",
		casino_provider_fees: "0.00",
		payment_process_fees: "0.00",
		admin_fees: "0.00",
		currency: "",
		general_fees: "0.00",
		// parent_id,
	});

	useEffect(() => {
		setAffiliateUsers({ ...affiliateUsers, ...data, active_status: data?.status });
	}, [data]);

	const handleSave = () => {
		let payload = { ...affiliateUsers, id: affiliateUsers.agent_id };
		delete payload.email;
		delete payload.first_name;
		delete payload.last_name;

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.EDIT_AFFILIATE_USERS, resolve, reject, payload, true);
		})
			.then(() => {
				getAffiliateUser();
			})
			.finally(() => {
				setLoading(false);
				setDataChanged(false);
			});
	};

	const sendResetLink = () => {
		setResetPasswordLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.RESET_PASSWORD + `?device_type=web&email=${affiliateUsers?.email}`, resolve, reject, true);
		})
			.then(res => {
				const { reset_password_link } = res;
				if (reset_password_link) {
					toast.success(
						<>
							<img src={successIcon} alt="Success" />
							<p className="toast-icon-manual-message">Send reset link successfully</p>
						</>,
						{ className: "custom-toast" }
					);
					return;
				}
			})
			.catch(() => {
				toast.error(
					<>
						<img src={xIcon} alt="Error" />
						<p className="toast-icon-manual-message">Send reset link Failed</p>
					</>,
					{ className: "custom-toast" }
				);
				return;
			})
			.finally(() => setResetPasswordLoading(false));
	};

	const discardHandler = () => {
		setAffiliateUsers(data);
	};

	const allFields = [
		{ name: "Active", type: "switch", fieldName: "active_status" },
		{ name: "ID", type: "input", fieldName: "id", disabled: true },
		{ name: "Email", type: "input", fieldName: "email" },
		{ name: "Currency", type: "dropdown", fieldName: "currency", optionsType: "currency" },
		{ name: "Rev Share", type: "input", fieldName: "revenue_share" },
		{ name: "General", type: "input", fieldName: "general_fees" },
		{ name: "Taxes", type: "input", fieldName: "taxes" },
		{ name: "Duties", type: "input", fieldName: "duties" },
		{ name: "Platform Fees", type: "input", fieldName: "platform_fees" },
		{ name: "Sports Provider Fees", type: "input", fieldName: "sports_provider_fees" },
		{ name: " Casino Provider Fees", type: "input", fieldName: "casino_provider_fees" },
		{ name: " Payment processing Fees", type: "input", fieldName: "payment_process_fees" },
		{ name: "Admin Fees", type: "input", fieldName: "admin_fees" },
		{
			title: "Reset Password",
			name: "Reset Password",
			type: "button",
			fieldName: "reset_button",
			buttonClassname: "save_affiliate_user_button",
			isLoading: resetPasswordLoading,
			onClickEvent: sendResetLink,
		},
	];

	const dependecies = { data: data };

	return (
		<>
			<TopBarComponent
				classname="special_top_component topbar-component"
				discardHandler={discardHandler}
				saveHandler={handleSave}
				dataChanged={dataChanged}
				loading={loading}
				hasGoBackArrow={false}
			/>
			<div>
				<AllComponentsLoop fields={allFields} data={affiliateUsers} setData={setAffiliateUsers} setDataChanged={setDataChanged} dependecies={dependecies} />
			</div>
		</>
	);
};

export default AffiliateUsersGeneral;
