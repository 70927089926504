import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { CancelButton, SaveButton } from "components/GeneralComponents/AddButton";
export const ShowDeleteUserListModal = ({ setShowDeleteModal, deleteUser, userFullName }) => {
	return (
		<SweetAlert title="Confirm Deletion of User" showConfirm={false} titleStyle={{ fontSize: "12px" }} onConfirm={() => {}}>
			<div style={{ position: "relative" }}>
				<div>
					<p className="delete_user_text">Are you sure you want to delete </p>
					<p className="delete_user_name">
						{userFullName?.first_name} {userFullName?.last_name} ?
					</p>
				</div>
				<div className="mt-3">
					<CancelButton onClick={() => setShowDeleteModal(false)} name="No" />
					<SaveButton onClick={deleteUser} name="Yes" />
				</div>
			</div>
		</SweetAlert>
	);
};
