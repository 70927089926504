import React from "react";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
import { DeleteIconButton } from "components/GeneralComponents/AddButton";
import RuleValue from "./ruleValue";
import { rulesData, equalityOptions } from "./rules";

const SingleRule = ({ changeConditionType, changeCondition, changeConditionValue, removeRule, rule, rules }) => {
	const { condition_type, condition: equality, rule_type } = rule;
	const selectedRule = rulesData.find(({ id }) => id == condition_type) || "";

	const checkIfDisabld = id => {
		const filteredElements = rulesData?.filter(item => item?.disable?.includes(id));
		return filteredElements?.some(item => rules.find(({ condition_type }) => condition_type == item.id));
	};

	const options = rulesData
		.filter(({ type, id, important }) => type == rule_type && !important && !rules.some(({ condition_type }) => condition_type == id))
		.map(rule => ({ ...rule, disabled: checkIfDisabld(rule.id) }));

	const equality_options = selectedRule?.equalityOptions || equalityOptions;

	return (
		<div className="rule-ctn">
			<div className="singleRule">
				<div className="rule-label">
					<GeneralDropdown
						object={{
							// value: condition_type,
							bindValue: selectedRule,
							options: options,
							defaultPlaceholder: "Select",
							sort: true,
							disabled: selectedRule?.important,
						}}
						handleChange={e => changeConditionType(condition_type, e)}
					/>
				</div>

				{selectedRule?.hideValue ? (
					<>
						<div className="equality-dropdown" />
						<div className="rule-value" />
					</>
				) : (
					<>
						<div className="equality-dropdown">
							<GeneralDropdown
								object={{
									value: equality,
									options: equality_options,
									disabled: selectedRule.equality,
									defaultPlaceholder: " ",
									bindValue: equality_options[0],
								}}
								handleChange={e => changeCondition(condition_type, e?.id)}
							/>
						</div>
						<div className="rule-value">
							<RuleValue
								selectedRule={selectedRule}
								rule={rule}
								changeConditionValue={changeConditionValue}
								changeCondition={changeCondition}
								rules={rules}
							/>
						</div>
					</>
				)}
			</div>

			<div className="delete-icon">{!selectedRule?.important && <DeleteIconButton onClick={() => removeRule(condition_type)} />}</div>
		</div>
	);
};

export default SingleRule;
