import {
	SET_TELEBET_SPORTS_GAMES,
	SET_TELEBET_USER,
	SET_TELEBET_SPORT,
	SET_SEARCH_COMPETITION_TELEBET,
	SET_SEARCH_COMPETITION_VALUE_TELEBET,
	SET_TELEBET_MATCH,
	SET_SELECTED_BETS,
	EMPTY_TELEBET_BETS,
	SET_BET_AMOUT,
	SET_BET_STAKES,
	SET_EMPTY_LEGS,
	SET_UPDATE_SELECTION,
	SET_TELEBET_BETS,
	SET_FREE_BET_CREDIT,
} from "./actionTypes";

const initialState = {
	sportsGames: {},
	telebetUser: null,
	telebetMatch: null,
	telebetSearchCompetition: null,
	selectedBets: {
		action: "check",
		bets: [],
		stakes: [],
		unnamed_favorite: [],
	},
	telebetBets: {
		singles: [],
		stakes: [],
		action: "check",
		warnings: [],
	},
	emptyLegs: [],
	freeBetCreditSelect: {},
};

const Telebet = (state = initialState, action) => {
	switch (action.type) {
		case SET_TELEBET_SPORTS_GAMES:
			return {
				...state,
				sportsGames: action.payload,
			};
		case SET_TELEBET_USER:
			state = {
				...state,
				telebetUser: action.payload,
			};
			break;
		case SET_SEARCH_COMPETITION_TELEBET:
			state = {
				...state,
				telebetSearchCompetition: action.payload,
			};
			break;
		case SET_SEARCH_COMPETITION_VALUE_TELEBET:
			state = {
				...state,
				telebetSearchValue: action.payload,
			};
			break;
		case SET_TELEBET_SPORT:
			state = {
				...state,
				telebetSport: action.payload,
			};
			break;
		case SET_TELEBET_MATCH:
			state = {
				...state,
				telebetMatch: action.payload,
			};
			break;
		case SET_SELECTED_BETS:
			if (action?.betType === "bets") {
				let actualBets = [...state.selectedBets?.bets];
				if (action?.payload?.price === false && action?.payload?.sp === false) {
					actualBets = actualBets.filter(row => row.bet_id !== action?.payload?.bet_id);
				} else if (actualBets.some(row => (row.bet_id === action?.payload?.bet_id && row?.price) || (row.bet_id === action?.payload?.bet_id && row?.sp))) {
					actualBets = actualBets.filter(row => row.bet_id !== action?.payload?.bet_id);
					actualBets.push(action?.payload);
				} else if (actualBets.some(row => row.bet_id === action?.payload?.bet_id)) {
					actualBets = actualBets.filter(row => row.bet_id !== action?.payload?.bet_id);
				} else {
					actualBets.push(action?.payload);
				}

				state = {
					...state,
					selectedBets: {
						...state.selectedBets,
						bets: actualBets,
					},
				};
			} else if (action?.betType === "unnamed_favorite") {
				let unnamedDFavorite = [...state.selectedBets?.unnamed_favorite];

				if (unnamedDFavorite.some(row => row.event_id === action?.payload?.event_id)) {
					unnamedDFavorite = unnamedDFavorite.filter(row => row.event_id !== action?.payload?.event_id);
				} else {
					unnamedDFavorite.push(action?.payload);
				}

				state = {
					...state,
					selectedBets: {
						...state.selectedBets,
						unnamed_favorite: unnamedDFavorite,
					},
				};
			} else if (action?.betType === "combinations") {
				state = {
					...state,
					selectedBets: {
						...state.selectedBets,
						stakes: action?.payload,
					},
				};
			}
			break;
		case SET_UPDATE_SELECTION:
			if (action?.payload?.type === "update") {
				const newBets = action.payload?.updatedBets;
				let actualBets = [...state.selectedBets?.bets];
				const newSelectedBets = actualBets?.map(item => {
					const element = newBets?.find(e => e?.bet_id == item?.bet_id);
					if (element) {
						return {
							...item,
							odds: element.odds,
							odds_fractional: element.odds_fractional,
						};
					} else {
						return item;
					}
				});

				state = {
					...state,
					selectedBets: {
						...state.selectedBets,
						bets: newSelectedBets,
					},
				};
			}
			break;
		case EMPTY_TELEBET_BETS:
			state = {
				...state,
				telebetBets: {
					singles: [],
					stakes: [],
					action: "check",
					warnings: [],
				},
				emptyLegs: [],
			};
			break;
		case SET_BET_AMOUT:
			if (action?.payload?.type === "unnamed_favorite") {
				let unnamedDFavorite = [...state.selectedBets?.unnamed_favorite];
				const { stake, event_id } = action?.payload;

				if (unnamedDFavorite.some(row => row.event_id === event_id)) {
					unnamedDFavorite = unnamedDFavorite.map(row => (row.event_id === event_id ? { ...row, stake: stake } : row));
				}

				state = {
					...state,
					selectedBets: {
						...state.selectedBets,
						unnamed_favorite: unnamedDFavorite,
					},
				};
			} else if (action?.payload?.type === "bets") {
				let actualBets = [...state.selectedBets?.bets];
				let index = actualBets.findIndex(row => row.bet_id === action?.payload?.bet_id);
				if (index > -1) {
					actualBets[index].stake = action?.payload?.stake;

					state = {
						...state,
						selectedBets: {
							...state.selectedBets,
							bets: actualBets,
						},
					};
				}
			}
			break;
		case SET_BET_STAKES:
			var actualStaks = [...state.selectedBets?.stakes];
			var index = actualStaks.findIndex(row => row.type === action?.payload?.type);

			if (index > -1) {
				actualStaks[index].stake = action?.payload?.stake;

				state = {
					...state,
					selectedBets: {
						...state.selectedBets,
						stakes: actualStaks,
					},
				};
			}
			break;
		case SET_EMPTY_LEGS:
			state = {
				...state,
				emptyLegs: action.payload,
			};
			break;

		case SET_TELEBET_BETS: {
			const formatedSingles = action.payload.singles.map(single => {
				const betWithProvider = single.bet_provider + "-" + single.bet_id;
				let bet_id = single.bet_id.includes(single.bet_provider) ? single.bet_id : betWithProvider;

				if (!single.bet_id) bet_id = single?.event_id;

				const selectedBet = state.telebetBets?.singles?.find(bet => bet?.bet_id == bet_id) || {};

				if (action.payload.merge) delete single.stake;

				return {
					...selectedBet,
					...single,
					bet_id,
				};
			});

			const updatedStakes = action.payload?.combinations?.map(combination => {
				const obj = { stake: combination.stake, type: combination.type };
				if (combination.allow_each_way) obj.each_way = combination.each_way;
				return obj;
			});

			state = {
				...state,
				telebetBets: {
					...action.payload,
					merge: false,
					stakes: updatedStakes,
					singles: formatedSingles,
				},
			};
			break;
		}

		case SET_FREE_BET_CREDIT:
			state = {
				...state,
				freeBetCreditSelect: action.payload,
			};
			break;
		default:
			return state;
	}
	return state;
};

export default Telebet;
