import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner } from "react-bootstrap";
import { CancelButton, LoadingSaveButton, SaveButton } from "components/GeneralComponents/AddButton";
import { Xicon } from "utils/icons";

export const AddMarketsGroupsModal = ({
	modalShow,
	setAddMarketsGroup,
	addMarketsGroup,
	sports,
	submitAddMarketGroup,
	loadingButton,
	handleSportChange,
	setSportId,
	sportId,
	sportFilterSpinning,
	markets,
	setFavoriteCompetitions,
	favoriteCompetitions,
	favoriteLoading,
	setCreatePeriodLeague,
}) => {
	return (
		<>
			{modalShow?.addMarketGroup && (
				<SweetAlert title="Competitions Groups" showConfirm={false} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="mt-3">
							<div className="row mb-2">
								<div className="col-4" style={{ textAlign: "start" }}>
									Sport Type
								</div>
								<div className="col-8">
									{!sportFilterSpinning ? (
										<select
											type={"text"}
											className="form-control"
											onChange={e => {
												var index = e.target.selectedIndex;
												var optionElement = e.target.childNodes[index];
												var name = optionElement.getAttribute("data-name");
												setAddMarketsGroup({ ...addMarketsGroup, sport_id: e.target.value, sport_name: name });
												setSportId(e.target.value);
												handleSportChange(e.target.value);
											}}
											value={sportId}
										>
											<option value={""}>Choose sport</option>
											{sports &&
												sports.map((row, index) => {
													return (
														<option data-id={row.id} key={index} value={row.id} data-name={row.name}>
															{row.name}
														</option>
													);
												})}
										</select>
									) : (
										<Spinner animation="border" size="sm"></Spinner>
									)}
								</div>
							</div>

							<div className="row align-items-center mb-2 mt-2">
								<div className="col-4 d-flex justify-content-start">Name</div>
								<div className="col-8">
									<input
										type="text"
										className="form-control"
										value={addMarketsGroup.name}
										onChange={e => {
											setAddMarketsGroup({ ...addMarketsGroup, name: e.target.value });
										}}
									/>
								</div>
							</div>
							<div className="row mt-2">
								<div className="col-4 d-flex favorite-leagues-label">Competitions</div>
							</div>
							<div className="row mb-2" style={{ marginTop: "10px" }}>
								<div className="col-4" style={{ textAlign: "start" }}>
									Leagues
								</div>
								<div className="col-8">
									{!sportFilterSpinning ? (
										<select
											className="form-control"
											onChange={e => {
												setAddMarketsGroup({ ...addMarketsGroup, market_id: e.target.value });
												var index = e.target.selectedIndex;
												var optionElement = e.target.childNodes[index];
												var name = optionElement.getAttribute("data-name");
												if (e.target.value) {
													var newSport = {
														name: name,
														market_id: e.target.value,
														added_new: true,
													};
													let exists = favoriteCompetitions.some(row => row.market_id == e.target.value);
													if (!exists) {
														setFavoriteCompetitions([...favoriteCompetitions, newSport]);
													}
												}
											}}
										>
											<option value={""}>Choose league</option>
											{markets.map((row, index) => {
												return (
													<option value={row.market_id} key={index} data-name={row.market_name}>
														{row.market_name}
													</option>
												);
											})}
										</select>
									) : (
										<Spinner animation="border" size="sm"></Spinner>
									)}
								</div>
							</div>
							{favoriteLoading ? (
								<Spinner animation="border" size="sm"></Spinner>
							) : (
								<div className="row mt-2">
									{favoriteCompetitions.map((row, index) => {
										return (
											<div className="selected-favorite-league" key={index}>
												<span>{row.name}</span>
												<button
													className="btn btn-secondary btn-sm"
													data-id={row.market_id}
													onClick={e => {
														var newData = [...favoriteCompetitions];
														newData = newData.filter(row => row.market_id != e.currentTarget.dataset.id);
														setFavoriteCompetitions(newData);
													}}
												>
													<Xicon />
												</button>
											</div>
										);
									})}
								</div>
							)}
						</div>

						<div className="mt-2">
							<CancelButton
								onClick={() => {
									setCreatePeriodLeague(false);
								}}
								name="Cancel"
							/>
							{loadingButton.modalButton ? <LoadingSaveButton /> : <SaveButton onClick={submitAddMarketGroup} name="Save" />}
						</div>
					</div>
				</SweetAlert>
			)}
		</>
	);
};
