import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { XCloseIcon } from "utils/icons";
import React, { useState } from "react";
import { GeneralInput, SwitchInput } from "components/GeneralComponents/CustomInputs";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";

export const ShowEditRegions = ({ editItem, title, handler, setRegionsNewData, setShowPromotion, regionsNewData }) => {
	const [loading, setLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [regionsData, setRegionsData] = useState({
		id: editItem ? editItem?.id : "",
		name: editItem ? editItem?.name : "",
		status: editItem?.status,
	});

	const saveRegionData = () => {
		let payload = {
			id: regionsData?.id,
			name: regionsData?.name,
			status: regionsData?.status,
		};

		setLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.region_edit, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				if (editItem) {
					let updatedData = [...regionsNewData];
					let index = updatedData.findIndex(item => item.id === editItem?.id);
					updatedData[index] = payload;
					setRegionsNewData(updatedData);
				} else {
					setRegionsNewData([...regionsNewData, payload]);
				}
				handler();
				setShowPromotion(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const handleChange = () => {
		const currentValue = regionsData?.status;
		setRegionsData({ ...regionsData, status: currentValue ? 0 : 1 });
		handleDataChanged();
	};
	return (
		<>
			<div className="promotion-container">
				<div className="promotion-header">
					<span>{title}</span>
					<XCloseIcon className={"xclose-icon"} onClick={handler} />
				</div>
				<div className="promotion-body">
					<TopBarComponent saveHandler={saveRegionData} loading={loading} dataChanged={dataChanged} onClose={handler} />
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
							<GeneralInput
								onChange={e => {
									setRegionsData({ ...regionsData, name: e.target.value });
									handleDataChanged();
								}}
								object={{
									name: "Name",
									value: regionsData?.name,
								}}
							/>
							<SwitchInput
								handleChange={handleChange}
								object={{
									name: "Status",
									value: regionsData?.status,
								}}
							/>
						</div>
						<div className="promotion-right col-12 col-lg-6"></div>
					</div>
				</div>
			</div>
		</>
	);
};
