import React, { useEffect, useState } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { LanguageTitle } from "components/GeneralComponents/LanguageTitle";
import { useDispatch } from "react-redux";
import { InfoIconHelper } from "utils/icons";
import InformationPageHelper from "../InformationPageHelper";
import { SeoHelpInformation } from "../HelpPagesForSettings/HelpPages";
import { setBackdrop } from "store/Backdrop/actions";
import { AvForm } from "availity-reactstrap-validation";
import useWindowSize from "hooks/useWindowSize";
import { useHistory } from "react-router-dom";

import { languageToString } from "utils/global";

function Seo() {
	const [dataChanged, setDataChanged] = useState(false);
	const [imageLibrary, setImageLibrary] = useState(false);
	const [unchangedData, setUnchangedData] = useState(false);
	const [seoImage, setSeoImage] = useState(false);
	const [favImage, setFavImage] = useState(false);
	const [showTitleLanguage, setShowTitleLanguage] = useState("");
	const [loading, setLoading] = useState(false);
	const [seoData, setSeoData] = useState({
		description: "",
		image: "",
		fav_icon: "",
		google_tag_header: "",
		google_tag_body: "",
		title: "",
		page_type: "",
		keywords: "",
	});

	const [iconHelperContainer, setIconHelperContainer] = useState(false);

	const { width } = useWindowSize();
	const history = useHistory();
	const dispatch = useDispatch();

	const isModal = width < 768;

	const handleShowOptions = (e, type) => {
		setShowTitleLanguage("");
		setSeoImage(false);
		setFavImage(false);
		setImageLibrary(false);

		if (e === "image-library" && type === "fav_seo") {
			setImageLibrary(true);
			setFavImage(true);
			setSeoData({ ...seoData, show_image: true });
		} else if (e === "image-library" && type === "seo_img") {
			setImageLibrary(true);
			setSeoImage(true);
			setSeoData({ ...seoData, show_image: true });
		} else if (e === "language") {
			setShowTitleLanguage(type);
		}
	};

	const dependecies = { image: seoData.image, fav_icon: seoData.fav_icon };
	const fields = [
		{
			type: "image",
			name: "Fav Icon",
			fieldName: "fav_icon",
			onFocus: () => handleShowOptions("image-library", "fav_seo"),
			imageType: "fav_seo",
			active: favImage,
			data: seoData,
			setData: setSeoData,
			setDataChanged: setDataChanged,
			imageLibaryType: "seo",
			dimensions: " (1740 x 115 px)",
		},
		{
			type: "input",
			name: "Title",
			fieldName: "title",
			required: true,
			textUpperCase: true,
			onFocus: () => handleShowOptions("language", "title"),
			// onBlur: () => handleShowOptions(),
		},
		{
			type: "textarea",
			name: "Description",
			fieldName: "description",
			textareaClassName: "scaleTextarea extra_height_textarea",
			required: true,
			textUpperCase: true,
			maxLength: 150,
			onFocus: () => handleShowOptions("language", "description"),
			// onBlur: () => handleShowOptions(),
		},
		{
			type: "input",
			name: "Keywords",
			fieldName: "keywords",
			required: true,
			onFocus: () => handleShowOptions("language", "keywords"),
			// onBlur: () => handleShowOptions(),
		},
		{
			type: "image",
			name: "Image",
			fieldName: "image",
			onFocus: () => handleShowOptions("image-library", "seo_img"),
			imageType: "seo_img",
			active: seoImage,
			data: seoData,
			setData: setSeoData,
			setDataChanged: setDataChanged,
			imageLibaryType: "seo",
			dimensions: " (3480 x 230 px)",
		},
		{
			type: "input",
			name: "Google Tag",
			fieldName: "google_tag_header",
			textareaClassName: "scaleTextarea extra_height_textarea",
			underText: "",
		},
	];

	const addSeoData = () => {
		setLoading(true);
		let payload = {
			description: seoData.description,
			image: seoData.image,
			fav_icon: seoData.fav_icon,
			google_tag_header: seoData.google_tag_header,
			google_tag_body: seoData.google_tag_body,
			title: seoData.title,
			page_type: seoData.page_type,
			keywords: seoData.keywords,
		};

		let language = languageToString(seoData, ["title_", "description_", "keywords_"]);

		payload = { ...payload, language: `${language}` };

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.seo_add, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const discardData = () => {
		setSeoData(unchangedData);
		setImageLibrary(false);
	};

	useEffect(() => {
		setUnchangedData(seoData);

		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.seo, resolve, reject, true);
		}).then(response => {
			const data = { ...response.data, ...response?.data?.language };
			setSeoData(data);
		});
	}, []);

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	return (
		<HelmetLayout titleProps={"Seo"}>
			<div className={` ${isModal ? "promotion-container" : ""}`}>
				{isModal && (
					<div className="promotion-header">
						<span>{"Seo"}</span>
					</div>
				)}

				<div className={` ${isModal ? "promotion-body" : "pt-4 mt-1"}`}>
					<TopBarComponent
						classname="special_top_component"
						saveHandler={addSeoData}
						dataChanged={dataChanged}
						loading={loading}
						discardHandler={discardData}
						onClose={() => history.push("/settings")}
					/>
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs special_promotion_pages">
							<AvForm>
								<AllComponentsLoop fields={fields} data={seoData} setData={setSeoData} setDataChanged={setDataChanged} dependecies={dependecies} />
							</AvForm>
						</div>
						<div className="promotion-right col-12 col-lg-6 special_promotion_pages">
							{imageLibrary && (
								<ImageLibrary
									data={seoData}
									setData={(_, data) => {
										if (favImage) {
											setSeoData({
												...seoData,
												fav_icon: data?.image,
											});
										} else if (seoImage) {
											setSeoData({
												...seoData,
												image: data?.image,
											});
										}
									}}
									type="seo"
									handleDataChanged={handleDataChanged}
								/>
							)}
							{showTitleLanguage && (
								<LanguageTitle data={seoData} setData={setSeoData} title={showTitleLanguage} handleDataChanged={handleDataChanged} />
							)}
						</div>
					</div>
				</div>
			</div>
			{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<SeoHelpInformation />
				</InformationPageHelper>
			)}
		</HelmetLayout>
	);
}
export default Seo;
