import { DeleteIconButton, MoveUpButton, MoveUpDisabledButton } from "components/GeneralComponents/AddButton";
import { GeneralDropdown, GeneralInput, SwitchInput } from "components/GeneralComponents/CustomInputs";
import React from "react";
import { Spinner } from "reactstrap";
import { _footerType } from "utils/constants";
// import { ModalLink } from "./ModalLink";
import { filterAndSortArray } from "utils/global";
import { LinkIcon } from "utils/icons";

export const ColumnComponent = props => {
	let {
		data,
		setInputId,
		setLinkData,
		setShowTitleLanguage,
		linkData,
		handleDataChanged,
		deleteIcon,
		deleteLoading,
		setDeleteItem,
		type,
		handleChange,
		handleLanguage,
		changeOrder,
		setImageId,
		pageContentData,
	} = props;

	pageContentData = [...pageContentData, { name: "terms", friendly_name: "Terms and Conditions" }, { name: "privacy", friendly_name: "Privacy Policy" }];
	const filteredCompetitions = filterAndSortArray(pageContentData, "friendly_name");
	let newFilteredCompetitions = filteredCompetitions.map(row => {
		return { id: row.name, label: row.friendly_name };
	});

	const handleChangeData = (newValue, id, newType) => {
		if (newType === "link") {
			handleChange(newValue?.id, id, type, "link");
		} else {
			handleChange(newValue?.id, id, "", "page_type");
		}
		handleDataChanged?.();
	};

	return (
		<div className="footer-links-container">
			<SwitchInput
				handleChange={() => {
					handleDataChanged();
					setLinkData({ ...linkData, enabled: !linkData?.enabled });
				}}
				object={{
					name: "Enabled",
					value: linkData.enabled,
					wrapperClassName: "pt-0",
				}}
			/>
			<div className="d-flex align-items-center mb-3">
				<GeneralInput
					onChange={e => handleChange(e.target.value, 0, type, "title")}
					onClick={() => {
						setInputId(null);
						setImageId(null);
						setShowTitleLanguage(0);
					}}
					object={{
						value: linkData?.title_name,
						defaultPlaceholder: "Title Name",
						inputContainer: "input-container footer-title-input",
					}}
				/>
			</div>
			{data.map((element, index) => {
				const value = newFilteredCompetitions?.find(pageContent => pageContent?.id === element?.link?.toLowerCase());
				const selectedRow = _footerType.find(row => row?.id === element?.page_type);

				return (
					<div className="d-flex mb-4 mb-md-3 gap-3" key={element.id}>
						<div className="d-flex flex-column flex-md-row w-100 w-md-auto gap-2">
							<GeneralInput
								onChange={e => handleChange(e.target.value, element.id, type, "value")}
								onClick={() => handleLanguage(element?.id)}
								object={{
									value: element.value,
									defaultPlaceholder: "Link Name",
									inputClassName: "general_input",
									inputContainer: "input-container p-0 m-0",
								}}
							/>
							<GeneralDropdown
								object={{
									options: _footerType,
									value: selectedRow?.id,
									label: selectedRow?.label,
									property: "page_type",
									defaultPlaceholder: "Type",
									fieldName: element.id,
									wrapperClassNameDropdown: "input-container p-0 m-0",
								}}
								handleChange={handleChangeData}
							/>
							{element.page_type === "modal" && pageContentData ? (
								<GeneralDropdown
									object={{
										inputClassName: "general_input",
										options: newFilteredCompetitions,
										value: value?.id,
										label: value?.label,
										fieldName: element.id,
										property: "link",
										defaultPlaceholder: "Page Content",
										wrapperClassNameDropdown: "input-container p-0 m-0",
									}}
									handleChange={handleChangeData}
								/>
							) : (
								<div className="w-100">
									{element.page_type !== "games_list" && (
										<GeneralInput
											onChange={e => handleChange(e.target.value.toLowerCase(), element.id, type, "link")}
											onClick={() => {
												setInputId(null);
												setImageId(null);
												setShowTitleLanguage(null);
											}}
											object={{
												value: element.link,
												inputContainer: "input-container p-0 m-0",
												defaultPlaceholder: element.page_type === "default" || element.page_type === "new_tab" ? "Path" : "URL Address",
												inputClassName: "general_input",
												icon: <LinkIcon />,
												error: true,
											}}
										/>
									)}
								</div>
							)}
						</div>
						<div className="footer-links-actions">
							{deleteLoading === element?.id ? (
								<Spinner animation="border" size={"sm"} />
							) : (
								<DeleteIconButton deleteIcon={deleteIcon} onClick={() => setDeleteItem(element?.id)} className="m-0" />
							)}
							{index === 0 ? (
								<MoveUpDisabledButton className="m-0" />
							) : (
								<MoveUpButton onClick={() => changeOrder(index, "UP", "column")} className="m-0" />
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};
