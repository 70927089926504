import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { DarkArrowLeft } from "utils/icons";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { SportGeneral } from "./SportGeneral";
import { SportLiabilities } from "./SportLiabilities";
import { SportCompetitions } from "./CompetitionsTab/SportCompetitions";
import { SportMarketFilters } from "./SportMarketFilters";
import { EventMarketFilters } from "./EventMarketFilters";
import { SportCountries } from "./CountriesTab/SportCountries";
import { disableInputs, userReadOnly } from "utils/global";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			className="tabs-tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={"span"}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const Sport = props => {
	const [value, setValue] = useState(0);
	const [sport, setSport] = useState(null);
	const { slug } = props.match?.params;

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const getSportData = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_SPORT + `?slug=${slug}`, resolve, reject, true);
		}).then(response => {
			if (response?.data && response?.data.length > 0) {
				setSport(response?.data[0]);
			}
		});
	};

	useEffect(() => {
		getSportData();
	}, []);

	const sisProvider = sport?.slug === "horseracing" || sport?.slug === "greyhoundracing";

	useEffect(() => {
		if (isUserReadOnly) disableInputs();
	}, [isUserReadOnly]);

	return (
		<div className="white-container">
			<div className="d-flex align-items-center">
				<DarkArrowLeft onClick={() => props.history.push("/sport/type/list")} />
				<span className="arrow-title sport-title">{sport ? sport?.name : ""}</span>
			</div>
			<div className="tabs-container">
				<Box sx={{ width: "100%" }}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
							<Tab label="General" {...a11yProps(0)} value={0} />
							<Tab label="Liabilities" {...a11yProps(1)} value={1} />
							<Tab label="Competitions" {...a11yProps(2)} value={2} />
							{sisProvider && <Tab label="Countries" {...a11yProps(3)} value={3} />}
							{!sisProvider && <Tab label="Sport Market Filters" {...a11yProps(4)} value={4} />}
							{!sisProvider && <Tab label="Event Market Filters" {...a11yProps(5)} value={5} />}
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<SportGeneral sport={sport} sisProvider={sisProvider} />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<SportLiabilities sport={sport} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<SportCompetitions sport={sport} isUserReadOnly={isUserReadOnly} />
					</TabPanel>
					{sisProvider && (
						<TabPanel value={value} index={3}>
							<SportCountries sport={sport} />
						</TabPanel>
					)}
					{!sisProvider && (
						<TabPanel value={value} index={4}>
							<SportMarketFilters sport={sport} isUserReadOnly={isUserReadOnly} />
						</TabPanel>
					)}
					{!sisProvider && (
						<TabPanel value={value} index={5}>
							<EventMarketFilters sport={sport} isUserReadOnly={isUserReadOnly} />
						</TabPanel>
					)}
				</Box>
			</div>
		</div>
	);
};

export default connect(null, { setBreadcrumbItems })(Sport);
