import { apiUrls } from "utils/const.apiUrl";

const { default: ApiServices } = require("utils/ApiServices");
export const deleteImages = async payload => {
	return new Promise((resolve, reject) => {
		ApiServices.post(apiUrls.delete_image, resolve, reject, payload, true);
	}).then(response => {
		return response;
	});
};
