import React, { useEffect, useState } from "react";
import { GeneralDropdown, GeneralInput, SwitchInput } from "components/GeneralComponents/CustomInputs";
import { getBase64 } from "hooks/General";
import Dropzone from "react-dropzone";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { _betTypesOptions, _betTypesOptionsSIS } from "utils/constants";
import { UploadIcon } from "utils/icons";
import { AvForm } from "availity-reactstrap-validation";

const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;
export const SportGeneral = props => {
	const { sport, sisProvider } = props;
	const [sportGeneral, setSportGeneral] = useState({
		icon: null,
		active_cover: null,
		inactive_cover: null,
		active: false,
		selectedBetTypes: [],
	});
	const images = [
		{ name: "Icon", fieldName: "icon" },
		{ name: "Active Cover", fieldName: "active_cover" },
		{ name: "Inactive Cover", fieldName: "inactive_cover" },
	];

	const editSport = (type, value) => {
		let payload = {};
		if (type === "published") {
			payload = {
				slug: sport ? sport.slug : "",
				active: value,
			};
		} else if (type === "bet_type") {
			payload = {
				slug: sport ? sport.slug : "",
				bet_types: value,
			};
		} else if (type === "icon") {
			payload = value;
		}
		payload.type = type;
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.sport_edit, resolve, reject, payload, true);
		}).then(() => {});
	};

	useEffect(() => {
		if (sport) {
			const betTypeData = sport?.bet_types && sport?.bet_types !== "null" && sport?.bet_types !== "undefined" ? JSON.parse(sport?.bet_types) : [];

			setSportGeneral({
				...sportGeneral,
				icon: aws_s3_url + "/sport/icon/" + sport?.icon,
				active_cover: aws_s3_url + "/sport/icon/" + sport?.cover_active,
				inactive_cover: aws_s3_url + "/sport/icon/" + sport?.cover_inactive,
				selectedBetTypes: betTypeData,
				active: sport?.active,
			});
		}
	}, [sport]);

	const updateSports = () => {
		const sportTypes = localStorage.getItem("_sports");
		if (sportTypes) localStorage.removeItem("_sports");

		setSportGeneral({ ...sportGeneral, active: !sportGeneral.active });
		editSport("published", !sportGeneral.active);
	};

	const handleChange = (value, type) => {
		const betTypes = sportGeneral?.selectedBetTypes;
		let newArray = betTypes;
		if (type === "add") {
			newArray.push(value?.id);
		} else if (type === "remove") {
			newArray = betTypes.filter(c => c !== value?.id);
		}

		setSportGeneral({ ...sportGeneral, selectedBetTypes: newArray });
		editSport("bet_type", newArray);
	};

	return (
		<div className="sport-general-container">
			<AvForm>
				<SwitchInput
					object={{
						name: "Published",
						value: sportGeneral.active,
						switchTitleClassName: "sport-general-title",
						wrapperClassName: "p-0 mb-3",
						onChangeEvent: () => updateSports(),
					}}
				/>
				{images.map((row, i) => {
					const fieldName = row.fieldName || "";
					return (
						<div className="row mb-3" key={i}>
							<div className="col-4 d-flex align-items-center">{row.name}</div>
							<div className="col-8 d-flex flex-column">
								<Dropzone
									e={{ marginTop: "1rem" }}
									onDrop={async acceptedFiles => {
										var file = await getBase64(acceptedFiles[0]);
										let payload = {
											files: JSON.stringify([{ file, fileType: fieldName }]),
											slug: sport ? sport.slug : "",
										};
										editSport("icon", payload);
										setSportGeneral({ ...sportGeneral, [fieldName]: URL.createObjectURL(acceptedFiles[0]) });
									}}
								>
									{({ getRootProps, getInputProps }) => (
										<>
											<div
												className="dropzone sport-upload-dropzone dz-clickable icon"
												style={{
													width: "100%",
													height: "58px",
													borderColor: "#8A98AB",
												}}
											>
												<div className="dz-message sport-needsclick needsclick icon" {...getRootProps()}>
													<input {...getInputProps()} />
													{sportGeneral?.[fieldName] ? (
														<img
															src={sportGeneral?.[fieldName] + `?${new Date()}`}
															alt="Sport icon"
															onError={() => setSportGeneral({ ...sportGeneral, [fieldName]: false })}
															className={"sport-icon"}
														/>
													) : (
														<div>
															<UploadIcon className={"uploadIcon"} />
															<h6 className="text-muted pb-0 m-0">Click to select</h6>
														</div>
													)}
												</div>
											</div>
										</>
									)}
								</Dropzone>
								<p className="suggested-dimensions m-0">Suggested media dimensions: (40 x 40 px)</p>
							</div>
						</div>
					);
				})}
				<GeneralInput
					object={{
						value: sport?.name,
						name: "Sport",
						disabled: true,
						inputClassName: "sport-general-input",
						inputContainer: "justify-content-between flex-row mb-3 p-0",
						extraWidth: "justify-content-end",
						titleClass: "sport-general-title",
					}}
				/>
				<GeneralDropdown
					object={{
						name: "Bet Types",
						fieldName: "selectedBetTypes",
						placeholder: "Multiple Selections",
						options: sisProvider ? [..._betTypesOptions, ..._betTypesOptionsSIS] : _betTypesOptions,
						value: sportGeneral?.selectedBetTypes,
						multiple: true,
						wrapperClassNameDropdown: "flex-row p-0",
						dropdownClassName: "sport-general-title",
					}}
					handleRemove={value => handleChange(value, "remove")}
					handleChange={value => handleChange(value, "add")}
				/>
			</AvForm>
		</div>
	);
};
