import { _resultBets, betTypeData, searchBetStatusData } from "utils/constants";

export const getFilterColumns = ({ competitions, sports, events }) => {
	let competitionsData = competitions ? JSON.parse(JSON.stringify(competitions)) : [];
	let eventsData = events ? JSON.parse(JSON.stringify(events)) : [];

	competitionsData = competitionsData.map(({ competition_id, competition_name }) => ({ id: competition_id, name: competition_name }));
	const competitionsDataSort = [...competitionsData].sort((a, b) => (a.name > b.name ? 1 : -1));
	const competitionsDataArray = competitionsDataSort ? JSON.stringify(competitionsDataSort) : [];

	eventsData = eventsData.map(({ event_id, event_name }) => ({ id: event_id, name: event_name }));
	const eventsDataSort = [...eventsData].sort((a, b) => (a.name > b.name ? 1 : -1));
	const eventsDataArray = eventsDataSort ? JSON.stringify(eventsDataSort) : [];

	const sportsData = sports ? JSON.stringify(sports) : [];

	const stakeAmountData = JSON.stringify([
		{
			value: ">=",
			name: ">=",
		},
		{
			value: "=",
			name: "=",
		},
		{
			value: "<=",
			name: "<=",
		},
	]);

	const settledData = JSON.stringify([
		{ value: "true", name: "Yes" },
		{ value: "false", name: "No" },
	]);

	return [
		{
			name: "Sport",
			type: "select",
			value: "sport_id",
			data: sportsData,
			optionKey: "id",
			optionName: "name",
		},
		{
			name: "Bet ID",
			type: "text",
			value: "bet_id",
			// removeDefaultValue: ["time"],
			removeOthersFilter: true,
		},
		{
			name: "Bet Types",
			type: "select",
			value: "bet_type",
			data: JSON.stringify(betTypeData),
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "Competition",
			type: "select",
			value: "competition_id",
			data: competitionsDataArray,
			optionKey: "id",
			optionName: "name",
		},
		{
			name: "Bet Date",
			type: "date",
			value: "time",
			defaultValue: "24H",
			defaultDateDays: "1",
		},
		{
			name: "Event",
			type: "select",
			value: "event_id",
			data: eventsDataArray,
			optionKey: "id",
			optionName: "name",
		},
		{
			name: "Event Date",
			type: "date",
			value: "event_date",
		},
		{
			name: "Stake Amount",
			type: "select_and_text",
			value: "wage_type",
			value_2: "wage",
			data: stakeAmountData,
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "Status",
			type: "select",
			value: "status",
			data: JSON.stringify(searchBetStatusData),
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "Player ID",
			type: "text",
			value: "users_player_id",
		},
		{
			name: "Result",
			type: "select",
			value: "result",
			data: JSON.stringify(_resultBets),
			optionKey: "id",
			optionName: "label",
		},
		{
			name: "Settled",
			type: "select",
			value: "settled",
			data: settledData,
			optionKey: "value",
			optionName: "name",
		},
	];
};
