import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import SearchFilter from "components/SearchFilter/searchFilter";
import { setBreadcrumbItems } from "../../../store/actions";
import { changeStateOnDeleteAndEdit } from "hooks/General";
import { getPushNotificationTableColumns } from "./pushNotificationTableColumns";
import { getPushNotificationFilterColumns } from "./pushNotificationsFilterColumns";
import { ShowCreateMessagePushNotificationModal } from "./showCreateMessagePushNotificaitonModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { prepareFilterData } from "utils/global";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const PushNotification = props => {
	const notificationFilterData = useSelector(state => state.Login.searchFilterData.notification);
	const [records, setRecords] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const breadcrumbItems = [{ title: "Policies", link: "/policies" }];
	const [showCreateMessage, setShowCreateMessage] = useState(false);
	const [specialArr, setSpecialArr] = useState([]);
	const [filterLoading, setFilterLoading] = useState(false);
	const pageHeaderData = {
		create: true,
		addCreateClick: () => {
			setShowCreateMessage(true);
		},
	};

	const [stopLoading, setStopLoading] = useState({});
	const countries = useSelector(state => state.Login.gsData.countries);
	let pushNotificationFilterColumns = getPushNotificationFilterColumns({ countries });

	const lsGSData = localStorage.getItem("gsData") ? JSON.parse(localStorage.getItem("gsData")) : [];

	useEffect(() => {
		props.setBreadcrumbItems("Push Notification", breadcrumbItems);
	});

	const pushNotification = params => {
		setIsDataLoading(true);
		setFilterLoading(true);
		let url = apiUrls.get_push_notification;
		if (params) {
			url = url + params;
		}
		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					setRecords(response?.data);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
				setFilterLoading(false);
			});
	};

	const sentStoppedNotification = (id, status) => {
		let statusTxt = status == 0 ? 1 : 0;
		setStopLoading({ ...stopLoading, [id]: true });
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.edit_sent_stop_push_notifications, resolve, reject, { id: id, sentStopped: statusTxt }, true);
		})
			.then(() => {
				const vars = {
					array: records,
					key: "id",
					mode: "edit",
					value: [statusTxt],
					key_for_change: ["sentStopped"],
					conditionValue: id,
				};
				const returnArr = changeStateOnDeleteAndEdit(vars);
				setRecords(returnArr);
			})
			.finally(() => {
				setStopLoading({ ...stopLoading, [id]: false });
			});
	};
	const filterCustomMarketP = () => {
		let body = {
			bet_start_date: "",
			bet_end_date: "",
			status: 1,
			archive: 0,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.get_specials, resolve, reject, body, true);
		}).then(response => {
			let data = response.data;
			setSpecialArr(data);
		});
	};

	const filterPushNotificationsMessages = notificationFilterData => {
		let params = `?platform=${notificationFilterData.platform || ""}&sentFinished=${notificationFilterData.processing}&title=${
			notificationFilterData?.title
		}`;
		pushNotification(params);
	};

	const getData = () => {
		const data = prepareFilterData(notificationFilterData, pushNotificationFilterColumns);
		filterPushNotificationsMessages(data);
		filterCustomMarketP();
	};

	useEffect(() => {
		getData();
	}, []);

	let pushNotificationTableColumns = getPushNotificationTableColumns({ records, stopLoading, sentStoppedNotification });
	let page_name = "Push Notification";

	return (
		<HelmetLayout titleProps={"Push Notification"}>
			<div>
				{showCreateMessage && (
					<ShowCreateMessagePushNotificationModal
						showCreateMessage={showCreateMessage}
						countries={countries}
						lsGSData={lsGSData}
						setShowCreateMessage={setShowCreateMessage}
						specialArr={specialArr}
						getData={getData}
					/>
				)}

				<div className="cms-page pt-0">
					<SearchFilter
						columns={pushNotificationFilterColumns}
						page={"notification"}
						preSelectedType={"text"}
						preSelectedValue={"title"}
						preSelectedName={"Title"}
						preSelectedData={countries || []}
						preSelectedOptionKey={"cca2"}
						preSelectedOptionName={"name"}
						filterHandler={filterPushNotificationsMessages}
						customizedFilter={true}
						filterLoading={filterLoading}
						className="col-10"
					/>
					<MUIDataTable
						columns={pushNotificationTableColumns}
						data={records}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							elevation: 0,
							onChangePage(page) {
								setCurrentPage(page);
							},
							onChangeRowsPerPage(number) {
								setRowPerPage(number);
							},
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							page: currentPage,
							responsive: tableResponsive,
							rowsPerPage: rowPerPage,
							rowsPerPageOptions: tableRowsPerPage,
							downloadOptions: { filename: `${page_name}.csv` },
							customToolbar: () => <AddTableButton onClick={pageHeaderData["addCreateClick"]} />,
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(PushNotification);
