import React from "react";
import { CloneButton, EditButton } from "components/GeneralComponents/AddButton";

export const getWordsTableColumns = ({ records, languageLabel, setWordsData, setShowCreateMessage, setEditMode }) => {
	return [
		{
			name: "id",
			label: "ID",
			className: "msg-id",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "language_code",
			label: "Language",
			className: "msg-title",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<span>{languageLabel[record.language_code]} </span>
						</>
					);
				},
			},
		},
		{
			name: "name",
			label: "Name",
			className: "msg-message",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "value",
			label: "Value",
			className: "msg-message",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "",
			label: "",
			className: "msg-message",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<CloneButton
								onClick={() => {
									setWordsData({
										id: "",
										language_code: "",
										name: record.name,
										value: "",
									});
									setShowCreateMessage(true);
									setEditMode(false);
								}}
								name="Clone"
							/>
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "20px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "20px" }, className: "max-button" }),
			},
		},
		{
			name: "",
			label: "",
			className: "msg-message",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<>
							<EditButton
								onClick={() => {
									setWordsData({
										id: record.id,
										language_code: record.language_code,
										name: record.name,
										value: record.value,
									});
									setShowCreateMessage(true);
									setEditMode(true);
								}}
							/>
						</>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", maxWidth: "20px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "20px" }, className: "max-button" }),
			},
		},
	];
};
