import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "../../../assets/images/delete.svg";
// import HTMLReactParser from "html-react-parser";

export const getAffiliateUsersTableColumn = ({ affiliateUsers, handleEditItem, setDeleteItem }) => {
	return [
		{
			name: "id",
			label: "ID",
			className: "affiliate_users_row",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "40px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "40px" } }),
			},
		},
		{
			name: "first_name",
			label: "NAME",
			className: "affiliate_users_row",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = affiliateUsers ? affiliateUsers[meta.currentTableData[meta.rowIndex].index] : [];
					return <span className="affiliate_users_row">{`${row?.first_name} ${row?.last_name}`}</span>;
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "email",
			label: "Email",
			className: "affiliate_users_row",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "150px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "150px" } }),
			},
		},
		// {
		// 	name: "status",
		// 	label: "STATUS",
		// 	className: "affiliate_users_row",
		// 	align: "left",
		// 	sortable: true,
		// 	sort: "asc",
		// 	options: {
		// 		filter: false,
		// 		viewColumns: false,
		// 		customBodyRender: (value, meta) => {
		// 			const record = affiliateUsers[meta.currentTableData[meta.rowIndex].index];
		// 			const statusColor =
		// 				record.status == "inactive"
		// 					? `<span className="badge badge-sports-red"> INACTIVE </span>`
		// 					: record.status == "active"
		// 					? `<span className="badge badge-sports-published modified_win_color"> ACTIVE </span>`
		// 					: record.status;
		// 			return HTMLReactParser(statusColor);
		// 		},
		// 		setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
		// 		setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
		// 	},
		// },
		{
			name: "revenue_share",
			label: "REV SHARE",
			className: "affiliate_users_row",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = affiliateUsers ? affiliateUsers[meta.currentTableData[meta.rowIndex].index] : [];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => handleEditItem(row)} />
								<DeleteIconButton deleteIcon={deleteIcon} onClick={() => setDeleteItem(row)} />
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "right", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" }, className: "max-button" }),
			},
		},
	];
};
