import React from "react";
import DataTable from "components/GeneralComponents/Table";
import TableColumns from "./tableColumns";

const DashboardTable = ({ data }) => {
	return (
		<div className="dashboard-table">
			{data?.map((table, index) => (
				<div className={`card ${table.column}`} key={index}>
					<div className="dashboard-table-card">
						<h4>{table.title}</h4>
						<DataTable
							data={table.data}
							columns={TableColumns(table.head_columns)}
							pagination={false}
							sort={false}
							options={{
								setRowProps: (row, dataIndex, rowIndex) => {
									return {
										className: `${rowIndex % 2 == 0 ? "white" : "gray"}`,
									};
								},
							}}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export default DashboardTable;
