import React, { useState, useEffect } from "react";

import { XCloseIcon } from "utils/icons";
import { GeneralInput, GeneralDropdown, GeneralDatePicker } from "components/GeneralComponents/CustomInputs";
import moment from "moment";
import { isEmpty } from "hooks/General";

import "./searchFilter.scss";

const MobileSearchFilter = ({
	onClose,
	columns,
	selectedSearch,
	setSelectedSearch,
	onSubmit,
	prepareDataToFilter,
	columnsDefaultValues,
	columnsRemoveOthersFilter,
	setRemoveOthersFilter,
	setDefaultValues,
	onSportChangeGetData,
}) => {
	const [state, setState] = useState({});

	useEffect(() => {
		const data = selectedSearch.reduce((a, v) => {
			const col = columns.find(col => col.value == v.key);
			const isDate = col?.type == "date";
			const isDropdown = col?.type == "select";

			if (isDate && v.value) {
				let value = "";
				let start_time;
				let end_time;

				if (v.value == col?.defaultValue) {
					start_time = moment.utc().subtract(col?.defaultDateDays, "day").format("DD-MM-YYYY");
					end_time = moment.utc().format("DD-MM-YYYY");
				} else {
					const values = v.value.split("&");
					start_time = moment(values[0]?.trim()).format("DD-MM-YYYY");
					end_time = moment(values[1]?.trim()).format("DD-MM-YYYY");
				}

				if (start_time == end_time) value = start_time;
				else value = start_time + " to " + end_time;

				return { ...a, [v.key]: value };
			}

			if (isDropdown) {
				return { ...a, [v.key]: v.selected };
			}

			return { ...a, [v.key]: v.value };
		}, {});

		setState(data);
	}, [selectedSearch]);

	const onReset = () => {
		const newState = {};
		for (const key in state) {
			newState[key] = "";
		}

		setState(newState);
	};

	const onShow = () => {
		let newSelectedSearch = selectedSearch.map(item => {
			const col = columns.find(col => col.value == item.key);
			const isDate = col?.type == "date";
			const isDropdown = col?.type == "select";

			if (isDate && item?.selected) {
				const value = onDateFormat(state[item.key]);
				return { ...item, selected: value, value: value };
			}

			if (isDropdown) {
				const { data, optionKey, optionName } = col;
				const options = getOptions(data, optionKey, optionName);
				const value = options?.find(op => op?.id == state[item.key])?.label || state[item.key];

				return { ...item, selected: state[item.key], value };
			}

			return { ...item, selected: state[item.key], value: state[item.key] };
		});

		///////
		const findTheRemoveElements = newSelectedSearch.find(s => columnsRemoveOthersFilter.includes(s?.key) && s.value);

		let removeOthersFilter = false;

		if (findTheRemoveElements) {
			const newRemoveOthersFilter = setRemoveOthersFilter(newSelectedSearch, {
				value: findTheRemoveElements?.value,
				key: findTheRemoveElements?.key,
			});

			newSelectedSearch = newRemoveOthersFilter;
			removeOthersFilter = true;
		}

		if (!removeOthersFilter) {
			if (!isEmpty(columnsDefaultValues)) {
				const newSelectedSearchDefault = setDefaultValues(newSelectedSearch, { value: "", key: "" });
				newSelectedSearch = newSelectedSearchDefault;
			}
		}

		/////

		setSelectedSearch(newSelectedSearch);

		const data = prepareDataToFilter(newSelectedSearch);

		onSubmit(data);
		onClose();
	};

	const onInputChange = (value, key) => {
		if (key == "sport_id") onSportChangeGetData(value);

		setState({ ...state, [key]: value });
	};

	const getOptions = (data, optionKey, optionName) => {
		const formatedData = JSON.parse(data);

		const a = formatedData.map(item => ({ id: item[optionKey], label: item[optionName] }));

		return a;
	};

	const onDateFormat = selectedDate => {
		var start_time = "";
		var end_time = "";
		var unixTime = moment().unix();

		if (selectedDate) {
			if (selectedDate.indexOf("to") > -1) {
				var betted_date = selectedDate.split("to");
				start_time = moment(betted_date[0]?.trim(), "DD-MM-YYYY").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(betted_date[1]?.trim(), "DD-MM-YYYY").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			} else {
				start_time = moment(selectedDate?.trim(), "DD-MM-YYYY").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(selectedDate?.trim(), "DD-MM-YYYY").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			}
		} else {
			start_time = moment.unix(unixTime).subtract(1, "years").format("YYYY-MM-DD");
			end_time = moment.unix(unixTime).add(1, "years").format("YYYY-MM-DD");
		}

		return start_time + " & " + end_time;
	};

	return (
		<div className="mobile-search-filter">
			<div className="close-icon" onClick={onClose}>
				<XCloseIcon fill="#262A3C" />
			</div>

			<h2>Filter By</h2>

			<div className="mobile-search-inputs">
				{columns.map(({ type, name, value, data, optionKey, optionName }, index) => (
					<div key={index} className="mobile-search-inputs_input">
						{
							type === "text" ? (
								<GeneralInput
									object={{
										name,
										value: state[value],
									}}
									onChange={e => onInputChange(e.target.value, value)}
								/>
							) : type === "select" ? (
								<GeneralDropdown
									handleChange={e => onInputChange(e?.id, value)}
									object={{
										name,
										value: state[value],
										options: getOptions(data, optionKey, optionName),
									}}
								/>
							) : type === "date" ? (
								<GeneralDatePicker
									object={{
										name,
										value: state[value],
										placeholder: "Choose Date",
										formatDate: false,
										formatDateRange: true,
										isTimeExist: false,
										mode: "range",
									}}
									handleChange={e => onInputChange(e, value)}
								/>
							) : (
								<></>
							)
							//  : type === "select_and_text" ? (
							// 	<>
							// 		<select className="form-control chooseOptionSelectAndText select" onChange={e => handleSelectAndText(e, "select")}>
							// 			<option value={""}>Choose option</option>
							// 			{filterData.data.map((row, index) => {
							// 				if (row[filterData.option_key] && row[filterData.option_name]) {
							// 					return (
							// 						<option
							// 							value={row[filterData.option_key] ? row[filterData.option_key] : row}
							// 							data-name={row[filterData.option_name] ? row[filterData.option_name] : row}
							// 							key={index}
							// 						>
							// 							{row[filterData.option_name] ? row[filterData.option_name] : row}
							// 						</option>
							// 					);
							// 				}
							// 			})}
							// 		</select>
							// 		<img src={downSelectArrow} className="down-icon-options" alt="" />
							// 		<input
							// 			type={"text"}
							// 			className="form-control searchInput"
							// 			value={inputValue}
							// 			onChange={e => {
							// 				handleSelectAndText(e, "input");
							// 				setInputValue(e.target.value);
							// 			}}
							// 			onKeyDown={e => {
							// 				e?.keyCode == 13 && handleSelectAndText(e, "input");
							// 				setInputValue(e.target.value);
							// 			}}
							// 		/>
							// 	</>
							// ) : type === "multi_select_and_text" ? (
							// 	<>
							// 		<select
							// 			className="form-control chooseOptionMultiSelectAndText select"
							// 			onChange={e => handleMultiSelectAndText(e, "select", "first")}
							// 		>
							// 			<option value={""}>Choose option</option>
							// 			{filterData.data.map((row, index) => {
							// 				if (row[filterData.option_key] && row[filterData.option_name]) {
							// 					return (
							// 						<option
							// 							value={row[filterData.option_key] ? row[filterData.option_key] : row}
							// 							data-name={row[filterData.option_name] ? row[filterData.option_name] : row}
							// 							key={index}
							// 						>
							// 							{row[filterData.option_name] ? row[filterData.option_name] : row}
							// 						</option>
							// 					);
							// 				}
							// 			})}
							// 		</select>
							// 		<img src={downSelectArrow} className="down-icon-options" alt="" />

							// 		<select
							// 			className="form-control chooseOptionMultiSelectAndText select"
							// 			onChange={e => handleMultiSelectAndText(e, "select", "second")}
							// 		>
							// 			<option>Choose option</option>
							// 			{filterData.data2.map((row, index) => {
							// 				if (row[filterData.option_key] && row[filterData.option_name]) {
							// 					return (
							// 						<option
							// 							value={row[filterData.option_key] ? row[filterData.option_key] : row}
							// 							data-name={row[filterData.option_name] ? row[filterData.option_name] : row}
							// 							key={index}
							// 						>
							// 							{row[filterData.option_name] ? row[filterData.option_name] : row}
							// 						</option>
							// 					);
							// 				}
							// 			})}
							// 		</select>
							// 		<img src={downSelectArrow} className="down-icon-options-second" alt="" />

							// 		<input
							// 			type={"text"}
							// 			className="form-control inputChoseOption"
							// 			onChange={e => {
							// 				handleMultiSelectAndText(e, "input", "");
							// 				setInputValue(e.target.value);
							// 			}}
							// 			onKeyDown={e => {
							// 				e?.keyCode == 13 && handleMultiSelectAndText(e, "input", "");
							// 				setInputValue(e.target.value);
							// 			}}
							// 		/>
							// 	</>
							// ) : type === "date" ? (
							// 	<>
							// 		<Flatpickr
							// 			className="form-control inputLabelStyles"
							// 			style={{ width: "100%" }}
							// 			placeholder="Choose date"
							// 			options={{
							// 				mode: "range",
							// 				dateFormat: "d-m-Y",
							// 				maxDate: maxDate,
							// 				locale: {
							// 					firstDayOfWeek: 1, // start week on Monday
							// 				},
							// 			}}
							// 			onChange={(date, selectedDate) => handleDateFilter(selectedDate)}
							// 		/>
							// 		<img src={downSelectArrow} className="down-icon" alt="" />
							// 	</>
							// ) : (
							// 	<input
							// 		type={"text"}
							// 		className="form-control inputLabelStyles"
							// 		onKeyDown={e => e?.keyCode == 13 && handleEnterPressed(e)}
							// 		onChange={e => setInputValue(e.target.value)}
							// 		value={inputValue}
							// 	/>
							// )
						}
					</div>
				))}
			</div>

			<div className="mobile-search-buttons">
				<button className="reset" onClick={onReset}>
					Reset
				</button>
				<button className="show" onClick={onShow}>
					Show
				</button>
			</div>
		</div>
	);
};

export default MobileSearchFilter;
