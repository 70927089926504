import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";

import useGenerateBetslip from "hooks/betslip";

export const TelebetEmptyLeg = ({ sports, row, emptyLegs, setEmptyLegs, handleRemove }) => {
	const [sportLadders, setSportLadders] = useState([]);

	const telebetBets = useSelector(state => state.Telebet.telebetBets);
	const generateBetslip = useGenerateBetslip();

	const setEmptyLegValue = (value, key, multipleValues, delay) => {
		const item = { ...row };

		if (multipleValues) {
			Object.entries(multipleValues).map(([key, value]) => {
				item[key] = value;
			});
		} else item[key] = value;

		const tempEmptyLegs = emptyLegs.map(tempRow => (tempRow.index === row.index ? item : tempRow));

		generateBetslip({ ...telebetBets, empty_legs: tempEmptyLegs }, delay ? 300 : null);

		setEmptyLegs(tempEmptyLegs);
	};

	const changeFractionalValue = value => {
		const option = sportLadders.find(({ in_fraction }) => in_fraction == value);
		setEmptyLegValue(null, null, { decimal: option ? option.in_decimal : "", fractional: value });
	};

	const changeDecimalValue = value => {
		const option = sportLadders.find(({ in_decimal }) => in_decimal == value);
		setEmptyLegValue(null, null, { decimal: value, fractional: option ? option.in_fraction : "" });
	};

	const getSportsLadders = id => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.get_ladder + `${id}`, resolve, reject, true);
		}).then(response => {
			const data = response.data;
			setSportLadders(data);
		});
	};

	const fractionalValueFormated = row => {
		if (row?.fractional) {
			const selectedValue = sportLadders.map(({ in_fraction }) => ({ id: in_fraction, label: in_fraction })).find(ladder => ladder.label == row?.fractional);
			return selectedValue || "";
		}
		return "";
	};

	const decimalValueFormated = row => {
		if (row?.decimal) {
			const selectedValue = sportLadders.map(({ in_decimal }) => ({ id: in_decimal, label: in_decimal })).find(ladder => ladder.label == row?.decimal);
			return selectedValue || "";
		}
		return "";
	};

	const selectForSpAndEachWay = row?.sport === "horseracing" || row?.sport === "greyhoundracing" ? true : false;

	return (
		<div className="empty-legs" key={row?.id}>
			<div className="telebet-betslip-header telebet_header">
				<span className="d-block mb-1">Sport</span>
				<XCloseIcon fill={"black"} onClick={handleRemove} />
			</div>
			<div className="mb-3 dropdown-wrapper">
				{/* <span className="d-block mb-1">Sport</span> */}
				{/* here its done  */}
				<Autocomplete
					disablePortal
					className="autocomplete-select input-arrow"
					options={sports}
					getOptionLabel={option => option?.name}
					sx={{
						width: 300,
						backgroundColor: "#eaecef",
						color: "eaecef",
						"& .MuiInputBase-root": {
							backgroundColor: "#eaecef",
						},
					}}
					renderInput={params => <TextField placeholder={"Required"} {...params} />}
					onChange={(event, newValue, reason) => {
						if (reason === "clear") {
							setEmptyLegValue("", "sport");
						} else {
							setEmptyLegValue(newValue.slug, "sport");
							getSportsLadders(newValue.slug);
							// getCompetitions(newValue, row?.index);
						}
					}}
				/>
			</div>
			<div className="mb-3">
				<span className="d-block mb-1">Event</span>

				<input
					type="text"
					placeholder="Optional"
					className="empty-leg-input"
					onChange={e => {
						setEmptyLegValue(e.target.value, "event", false, true);
					}}
					value={row?.event}
				/>
			</div>
			<div className="mb-3">
				<span className="d-block mb-1">Selection</span>

				<textarea
					type="text"
					placeholder="Optional"
					className="empty-leg-input"
					onChange={e => {
						setEmptyLegValue(e.target.value, "selection", false, true);
					}}
					value={row?.selection}
				></textarea>
			</div>
			<div className="empty-legs-odds row">
				<div className="d-flex col-6 ">
					<div className="dropdown-wrapper smaller-list w-100">
						<span className="d-block mb-1">Decimal</span>
						<Autocomplete
							disablePortal
							className="autocomplete-select input-arrow"
							options={sportLadders.map(({ in_decimal }) => ({ id: in_decimal, label: in_decimal }))}
							getOptionLabel={option => option?.label || ""}
							sx={{
								width: 300,
								backgroundColor: "#eaecef",
								color: "eaecef",
								"& .MuiInputBase-root": {
									backgroundColor: "#eaecef",
								},
							}}
							renderInput={params => <TextField placeholder={"Required"} {...params} />}
							value={decimalValueFormated(row)}
							onChange={(event, newValue, reason) => {
								if (reason === "clear") {
									changeDecimalValue("");
								} else {
									changeDecimalValue(newValue.label);
									// getCompetitions(newValue, row?.index);
								}
							}}
						/>
					</div>
				</div>
				<div className="d-flex col-6">
					<div className="dropdown-wrapper smaller-list w-100">
						<span className="d-block mb-1">Fractional</span>
						<Autocomplete
							disablePortal
							className="autocomplete-select input-arrow"
							getOptionLabel={option => option?.label || ""}
							options={sportLadders.map(({ in_fraction }) => ({ id: in_fraction, label: in_fraction }))}
							sx={{
								width: 300,
								backgroundColor: "#eaecef",
								color: "eaecef",
								"& .MuiInputBase-root": {
									backgroundColor: "#eaecef",
								},
							}}
							renderInput={params => <TextField placeholder={"Required"} {...params} />}
							value={fractionalValueFormated(row)}
							onChange={(event, newValue, reason) => {
								if (reason === "clear") {
									changeFractionalValue("");
								} else {
									changeFractionalValue(newValue.label);
									// getCompetitions(newValue, row?.index);
								}
							}}
						/>
					</div>
				</div>
			</div>
			<div className="empty-legs-odds row stake_container_input">
				<input
					type="number"
					placeholder="0.00"
					className="empty-leg-input stake_input"
					onChange={e => {
						setEmptyLegValue(e.target.value, "stake");
					}}
					value={row?.stake}
				/>

				<span className="empty-leg-odds-option stake_description">Stake</span>
			</div>
			{selectForSpAndEachWay && (
				<div className="empty-legs-odds row d-flex justift-content-between">
					<div className="d-flex w-50">
						<input
							type="checkbox"
							className="empty-leg-input customized-input-empty-leg"
							onChange={e => {
								setEmptyLegValue(e.target.checked, "each_way");
							}}
							value={row?.each_way}
							checked={row?.each_way}
						/>

						<span className="empty-leg-odds-option  customized-bet-empty-leg">Each Way</span>
					</div>
					<div className="d-flex w-50">
						<input
							type="checkbox"
							className="empty-leg-input  customized-input-empty-leg"
							onChange={e => {
								setEmptyLegValue(e.target.checked, "starting_price");
							}}
							value={row?.starting_price}
							checked={row?.starting_price}
						/>

						<span className="empty-leg-odds-option customized-bet-empty-leg">Starting Price</span>
					</div>
					{row?.each_way && (
						<div className="my-3">
							<span className="d-block mb-1">EW Terms</span>

							<input
								type="text"
								placeholder="EW Terms"
								className="empty-leg-input"
								onChange={e => {
									setEmptyLegValue(e.target.value, "ew_terms", false, true);
								}}
								value={row?.ew_terms}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
