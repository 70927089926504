import { useState, useRef, useEffect } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { setSelectedBetReferral } from "store/SelectedBet/actions";
import { setTelebetBets } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "hooks/useDebounce";

const useGenerateBetslip = () => {
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem("user"));

	const SelectedBetReferral = useSelector(state => state.SelectedBetReferral.selectedBetReferral);
	const betReferralPlayerSubId = useSelector(state => state.SelectedBetReferral.betReferralPlayerSubId);
	const betReferralFreeBetId = useSelector(state => state.SelectedBetReferral.freebetId);

	const telebetUser = useSelector(state => state.Telebet.telebetUser);
	const freeBetCreditSelect = useSelector(state => state.Telebet.freeBetCreditSelect);
	const emptyLegs = useSelector(state => state.Telebet.emptyLegs) ?? [];

	const controllerRef = useRef();
	const delay = useRef(300);
	const isTelebet = useRef(true);

	const [betslipPayload, setBetslipPayload] = useState();

	const debouncedPayload = useDebounce(betslipPayload, delay.current);

	const fetchBetSlip = payload => {
		if (!controllerRef?.current?.signal?.aborted) {
			controllerRef?.current?.abort();
		}

		controllerRef.current = new AbortController();
		const signal = controllerRef.current.signal;

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.ADD_BET_SLIP + "?cms=true", resolve, reject, payload, true, [], true, { signal });
		})
			.then(response => {
				// console.log(payload, response, "response");
				if (isTelebet.current) {
					dispatch(setTelebetBets({ ...response, merge: true }));
				} else dispatch(setSelectedBetReferral({ ...SelectedBetReferral, bet_slip: { ...response, merge: true } }));
			})
			.finally(() => {});
	};

	useEffect(() => {
		if (debouncedPayload) {
			fetchBetSlip(debouncedPayload);
		}
	}, [debouncedPayload]);

	return (selectedBets, debounce, cms_bet_ticker = false) => {
		if (cms_bet_ticker) dispatch(setSelectedBetReferral({ ...SelectedBetReferral, bet_slip: selectedBets }));
		else dispatch(setTelebetBets(selectedBets));

		let racingBetsCounter = 0;

		const bets = [];
		const unnamed_favorite = [];

		selectedBets?.singles?.forEach(selected_row => {
			if (selected_row?.place) {
				racingBetsCounter++;
			}

			// eslint-disable-next-line
			const { place, trading_status, ...bet } = selected_row;

			if (selected_row?.trading_status?.toLowerCase() === "open") {
				const betWithProvider = bet.bet_provider + "-" + bet.bet_id;

				const newBet = {
					stake: bet.stake,
					bet_id: bet.bet_id.includes(bet.bet_provider) ? bet.bet_id : betWithProvider,
					odds: bet.odds_decimal,
					odds_fractional: bet.odds_fractional,
				};

				// if (cms_bet_ticker) {
				// 	newBet.odds = bet.odds_decimal;
				// 	newBet.odds_fractional = bet.odds_fractional;
				// }

				if (bet.starting_price) {
					newBet.starting_price = bet.starting_price;
				}

				if (bet.allow_each_way) {
					newBet.each_way = bet.each_way;
				}

				if (bet.event_id) {
					newBet.event_id = bet.event_id;
				}

				if (bet.price) {
					newBet.price = bet.price;
				}

				if (place) {
					newBet.finish_position = place;
				}

				bets.push(newBet);
			}

			if (selected_row?.bet_type?.toLowerCase() === "unnamed_favorite") {
				const { bet_id, ...unnamedFavoriteBet } = bet;

				unnamed_favorite.push({ event_id: bet_id, ...unnamedFavoriteBet });
			}
		});

		let payload = {
			stakes: selectedBets.stakes || [],
			bets,
			action: "check",
			cms_user_id: user?.id,
			cms_sub_id: user?.sub_id,
		};

		if (racingBetsCounter === 2) {
			payload.forecast = true;
		}

		if (racingBetsCounter === 3) {
			payload.tricast = true;
		}

		if (cms_bet_ticker) {
			payload.cms_bet_ticker = true;
			isTelebet.current = false;
			payload.player_id = betReferralPlayerSubId;

			if (betReferralFreeBetId) {
				payload.use_free_bet = 1;
				payload.free_bet_id = betReferralFreeBetId;
			}
		}

		if (isTelebet.current) {
			payload.is_telebet = true;
			payload.player_id = telebetUser?.swifty_id;

			if (emptyLegs?.length > 0) payload.empty_legs = selectedBets?.empty_legs || emptyLegs;
		}

		if (isTelebet.current && (freeBetCreditSelect?.id || selectedBets?.free_bet_id) && selectedBets?.use_free_bet !== null) {
			const free_bet_id = selectedBets?.free_bet_id || freeBetCreditSelect?.id;
			payload.use_free_bet = 1;
			payload.free_bet_id = free_bet_id;
		}

		if (unnamed_favorite.length > 0) {
			payload.unnamed_favorite = unnamed_favorite;
		}

		// if (bets?.length === 0 && payload?.stakes?.length === 0 && unnamed_favorite.length === 0) {
		// 	return;
		// }

		if (!payload.player_id) return;

		if (debounce) {
			setBetslipPayload(payload);
			delay.current = debounce;
		} else {
			fetchBetSlip(payload);
		}
	};
};

export default useGenerateBetslip;
