import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let prevTitle = "";
let prevGameId = "";

export const getCasinoGame = async game_id => {
	if (prevGameId === game_id) return prevTitle;

	return new Promise((resolve, reject) => {
		ApiServices.get(`${apiUrls.GET_EDIT_CASINO_GAME}/${game_id}`, resolve, reject, true);
	})
		.then(response => {
			const { title } = response.data;

			prevGameId = game_id;
			prevTitle = title;
			return title;
		})

		.catch(() => {
			return [];
		});
};
