import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody } from "reactstrap";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getTransictionReportColumns, filterColumns } from "./transactionHistoryTableColumns";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { platformName } from "utils/constants";
import LoadingSpinner from "components/Loader/LoadingSpinner";
// import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import SearchFilter from "components/SearchFilter/searchFilter";
import moment from "moment";
import { isEmpty } from "hooks/General";

export const TransactionHistory = props => {
	let currencies = props.currencyData;
	const [transactionData, setTransactionData] = useState([]);
	// const [unChangedData, setUnChangedData] = useState([]);
	const [pageLoading, setPageLoading] = useState(false);
	const [count, setCount] = useState(50);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [currentPage, setCurrentPage] = useState(0);
	const [filterData, setFilterData] = useState({});
	const [isFilterActive, setIsFilterActive] = useState(false);

	const scrollToStart = () => {
		const elementSelected = document.querySelector(".page-content");
		if (elementSelected) {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
	};

	const transactionReportColumns = getTransictionReportColumns({ transactionData, setTransactionData, currencies, isFilterActive });

	const getTransactionHistory = () => {
		setPageLoading(true);
		let id = props.id;

		let start_time;
		let end_time;

		let payload = {
			userId: id,
			currentPage: currentPage + 1,
			limit: rowsPerPage,
		};

		if (filterData?.details) payload.details = filterData?.details;
		if (filterData?.type) payload.type = filterData?.type;

		if (filterData?.time) {
			if (filterData.time.indexOf("&") > -1) {
				const betted_date = filterData.time.split("&");
				start_time = moment(betted_date[0].trim(), "YYYY-MM-DD HH:mm:ss").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(betted_date[1].trim(), "YYYY-MM-DD HH:mm:ss").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			} else if (filterData.time == filterColumns.find(({ value }) => value == "time")?.defaultValue) {
				start_time = moment.utc().subtract(7, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment.utc().format("YYYY-MM-DD 23:59:59");
			} else {
				start_time = moment(filterData.time.trim(), "YYYY-MM-DD HH:mm:ss").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(filterData.time.trim(), "YYYY-MM-DD HH:mm:ss").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			}
		}
		// else {
		// 	start_time = moment.utc().subtract(7, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss");
		// 	end_time = moment.utc().format("YYYY-MM-DD 23:59:59");
		// }

		payload.fromDate = start_time;
		payload.toDate = end_time;
		// let url = `?userId=${id}&currentPage=${currentPage + 1}&limit=${rowsPerPage}`;

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.GET_TRANSICTION_HISTORY, resolve, reject, payload, true);
		})
			.then(response => {
				if (response?.success) {
					const data = response?.data?.data;
					setTransactionData(data);
					setCount(response?.data?.total_rows);

					setIsFilterActive(!isEmpty(filterData));
					// setUnChangedData(data);
				}
			})
			.finally(() => {
				setPageLoading(false);
				scrollToStart();
			});
	};

	const filterTransactionHistory = filters => {
		setFilterData(filters);
	};

	useEffect(() => {
		getTransactionHistory();
	}, [rowsPerPage, currentPage, filterData]);

	return (
		<React.Fragment>
			<MetaTags>
				<title>Transaction History - {platformName}</title>
			</MetaTags>
			<Card>
				<CardBody>
					<div className="col-12 filterResponsive" style={{ justifyContent: "space-between" }}>
						<div className="col-10 d-flex"></div>
					</div>

					{/* <div className="row" style={{ height: "30px", marginBottom: "12px" }}>
							<div className="col-12 p-0">
								<span className="tableTitle">Last 30 Days</span>
							</div>
						</div> */}

					<div className="row">
						<div className="col-12 p-0">
							{/* To be handle from backend */}
							{/* <SingleSearchFilter setData={setTransactionData} unfilteredData={unChangedData} filterColumn={["transaction_type", "details"]} /> */}

							<SearchFilter
								columns={filterColumns}
								page={"transaction_history"}
								preSelectedType={"text"}
								preSelectedValue={"details"}
								preSelectedName={"Details"}
								preSelectedData={""}
								preSelectedOptionKey={""}
								preSelectedOptionName={""}
								filterHandler={filterTransactionHistory}
								filterLoading={pageLoading}
								preSelect={false}
							/>

							<div className="mt-3 transaction-history-table">
								<MUIDataTable
									columns={transactionReportColumns}
									data={transactionData}
									options={{
										...tableBarSettings,
										selectableRows: "none",
										elevation: 0,
										pagination: true,
										count: count,
										responsive: tableResponsive,
										rowsPerPage: rowsPerPage,
										page: currentPage,
										onChangePage: setCurrentPage,
										onChangeRowsPerPage: setRowsPerPage,
										serverSide: true,
										rowsPerPageOptions: tableRowsPerPage,
										downloadOptions: { filename: "Report - Transaction.csv" },
										textLabels: {
											body: {
												noMatch: pageLoading ? <LoadingSpinner /> : " No data to display!", // Custom message for no matching records
											},
										},
									}}
									extraButtons={tableExtraButtons}
								/>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};
