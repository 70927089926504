import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");
import { filterAndSortArray } from "utils/global";

export const getPhonePrefixDropdown = async () => {
	if (sessionStorage.getItem("_phonePrefix")) {
		return filterAndSortArray(JSON.parse(sessionStorage.getItem("_phonePrefix")), "label");
	}
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.phone_prefix, resolve, reject, true);
	})
		.then(response => {
			let allPrefixes = response.data;
			allPrefixes = allPrefixes.map(row => {
				return { id: row.prefix, label: "+" + row.prefix };
			});
			allPrefixes = filterAndSortArray(allPrefixes, "label");
			sessionStorage.setItem("_phonePrefix", JSON.stringify(allPrefixes));
			return allPrefixes;
		})
		.catch(() => {
			return [];
		});
};
