import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { CancelButton, LoadingSaveButton, SaveButton } from "components/GeneralComponents/AddButton";
export const ShowDeletePoliciesModal = ({ showDeleteModal, setModalShow, submitDeletePolicy, spinningStatus }) => {
	return (
		<>
			{showDeleteModal && (
				<SweetAlert title="Are you sure you want to delete this version ?" showConfirm={false} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="mt-3">
							<CancelButton onClick={() => setModalShow(false)} name="No" />

							{spinningStatus ? <LoadingSaveButton /> : <SaveButton onClick={submitDeletePolicy} name="Yes" />}
						</div>
					</div>
				</SweetAlert>
			)}
		</>
	);
};
