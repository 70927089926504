export const SET_TELEBET_SPORTS_GAMES = "SET_TELEBET_SPORTS_GAMES";

export const SET_TELEBET_USER = "SET_TELEBET_USER";

export const SET_TELEBET_SPORT = "SET_TELEBET_SPORT";

export const SET_SEARCH_COMPETITION_TELEBET = "SET_SEARCH_COMPETITION_TELEBET";

export const SET_SEARCH_COMPETITION_VALUE_TELEBET = "SET_SEARCH_COMPETITION_VALUE_TELEBET";

export const SET_TELEBET_MATCH = "SET_TELEBET_MATCH";

export const SET_SELECTED_BETS = "SET_SELECTED_BETS";

export const EMPTY_TELEBET_BETS = "EMPTY_TELEBET_BETS";

export const SET_BET_AMOUT = "SET_BET_AMOUT";

export const SET_BET_STAKES = "SET_BET_STAKES";

export const SET_EMPTY_LEGS = "SET_EMPTY_LEGS";

export const SET_UPDATE_SELECTION = "SET_UPDATE_SELECTION";

export const SET_TELEBET_BETS = "SET_TELEBET_BETS";

export const SET_FREE_BET_CREDIT = "SET_FREE_BET_CREDIT";
