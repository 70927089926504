import { CarouselPromotion } from "./carouselPromotion";
import { PageHeader } from "components/VerticalLayout/PageHeader";
import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { getCarouselColumns } from "./carouselColums";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import ConfirmDeleteComponent from "components/GeneralComponents/ConfirmDeleteComponent";
import { CreateCarouselComponent } from "components/GeneralComponents/CreateCarouselComponent";
import SweetAlert from "react-bootstrap-sweetalert";
import { LoadingSaveButton, NoButton, YesButton } from "components/GeneralComponents/AddButton";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "components/GeneralComponents/Table";
import { getContent } from "services/getContentAllData";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";
import { setBackdrop } from "store/Backdrop/actions";
import { InfoIconHelper } from "utils/icons";
import InformationPageHelper from "../InformationPageHelper";
import { CarouselHelpInformation } from "../HelpPagesForSettings/HelpPages";

const Carousel = props => {
	const [showPromotion, setShowPromotion] = useState(false);
	const [carouselData, setCarouselData] = useState([]);
	const [editItem, setEditItem] = useState(null);
	const [pageLayoutType, setPageLayoutType] = useState("");
	const [deleteItem, setDeleteItem] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [itemTitle, setItemTitle] = useState("");
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [deleteCarousel, setDeleteCarousel] = useState(null);
	const [deleteCarouselLoading, setDeleteCarouselLoading] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();

	const [pageHeaderData, setPageHeaderData] = useState({
		international: false,
		addNew: true,
		dropdownData: [],
		addNewClick: () => {
			setShowPromotion(true);
		},
		removeHeader: true,
	});
	const [addNewShow, setAddNewShow] = useState(false);
	let country = useSelector(state => state.Login.country);

	const carousel = async () => {
		setIsDataLoading(true);
		if (!country || country === "undefined") {
			country = "all";
		}

		let response = await getContent("carousel", country, pageLayoutType);
		if (response?.length > 0) {
			response.forEach(element => {
				if (element?.active) {
					element["status"] = "Published";
				} else {
					element["status"] = "Inactive";
				}
				if (element?.details) {
					let parsedDetails = JSON.parse(element["details"]);
					parsedDetails["publish_start_date_time"] = element["publish_start_date_time"];
					parsedDetails["publish_stop_date_time"] = element["publish_stop_date_time"];
					parsedDetails["status"] = element["status"];
					element["details"] = JSON.stringify(parsedDetails);
				}
			});
			setCarouselData(response);
			setUnfilteredData(response);
			setIsDataLoading(false);
		} else {
			setIsDataLoading(false);
			setCarouselData([]);
			setUnfilteredData([]);
		}
	};

	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};

	const changeOrder = index => {
		const selectedRow = unfilteredData.splice(index, 1)[0];
		const newData = [selectedRow, ...unfilteredData];
		setNewData(newData);
	};

	const orderItems = newOrder => {
		let payload = {
			order: newOrder.map((row, index) => ({ id: row.id, order: index })),
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.order_carousel_item, resolve, reject, payload, true);
		}).then(() => {});
	};

	const setNewData = newData => {
		setCarouselData(newData);
		setUnfilteredData(newData);
		orderItems(newData);
	};

	const carouselColumns = getCarouselColumns({
		carouselData,
		props,
		handleEditItem,
		setDeleteItem,
		setItemTitle,
		changeOrder,
	});

	const getCarouselGroups = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.CAROUSEL_GROUPS, resolve, reject, true);
		}).then(response => {
			let data = response?.data?.data;
			let contentData = [];
			data.forEach(element => {
				contentData.push({
					name: element?.name,
					value: element?.id,
				});
			});
			setPageHeaderData({
				...pageHeaderData,
				dropdownData: contentData,
			});
			if (data && data.length > 0) {
				setPageLayoutType(data[0]?.id);
			}
		});
	};

	const deleteCarouselGroup = id => {
		setDeleteCarouselLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.DELETE_CAROUSEL_GROUP + `/${id}`, resolve, reject, true);
		})
			.then(response => {
				let data = response?.data?.data;
				let contentData = [];
				data.forEach(element => {
					contentData.push({
						name: element?.name,
						value: element?.id,
					});
				});
				setPageHeaderData({
					...pageHeaderData,
					dropdownData: contentData,
				});
				if (data && data.length > 0) {
					setPageLayoutType(data[0]?.id);
				}
				setDeleteCarousel(null);
			})
			.finally(() => {
				setDeleteCarouselLoading(false);
			});
	};

	useEffect(() => {
		getCarouselGroups();
	}, []);

	useEffect(() => {
		if (pageLayoutType) {
			carousel();
		}
	}, [country, pageLayoutType]);

	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	return (
		<>
			<HelmetLayout titleProps={"Carousel"}>
				{!showPromotion && (
					<PageHeader
						pageHeaderData={pageHeaderData}
						setContentData={setCarouselData}
						type={"carousel"}
						pageLayoutType={pageLayoutType}
						setPageType={setPageLayoutType}
						addNew={true}
						addNewHandler={() => setAddNewShow(true)}
						deleteCarousel={true}
						deleteCarouselHandler={() => setDeleteCarousel(pageLayoutType)}
					/>
				)}
				<div className="cms-page header-page carousel-page-header">
					<SingleSearchFilter
						setData={setCarouselData}
						unfilteredData={unfilteredData}
						detailsColumn={["title", "publish_start_date_time", "publish_stop_date_time", "status"]}
					/>
					<DataTable
						data={carouselData}
						columns={carouselColumns}
						setData={newData => {
							setNewData(newData);
						}}
						options={{
							showButtons: true,
							search: false,
							viewColumns: false,
							customToolbar: () => <AddTableButton className={"data-table-add-btn"} onClick={pageHeaderData["addNewClick"]} />,
						}}
						isLoading={isDataLoading}
						pagination={false}
						orderRows={true}
						sort={false}
					/>
				</div>
				{deleteItem && (
					<ConfirmDeleteComponent
						showDeleteCom={deleteItem}
						deleteLoading={deleteLoading}
						setDeleteLoading={setDeleteLoading}
						deleteTitle={"Confirm Deletion of Promotion"}
						titlename={itemTitle}
						setShowDeleteCom={setDeleteItem}
						betSlipData={carouselData}
						setBetSlipData={setCarouselData}
						id={deleteItem?.id}
					/>
				)}
				{showPromotion && (
					<CarouselPromotion
						title={"Carousel Promotion"}
						editItem={editItem}
						countires={country}
						getCarousel={carousel}
						pageLayoutType={pageLayoutType}
						handler={() => {
							setShowPromotion(false);
							setEditItem(null);
						}}
					/>
				)}
				{addNewShow && (
					<CreateCarouselComponent
						setShow={setAddNewShow}
						loading={false}
						setPageHeaderData={setPageHeaderData}
						pageHeaderData={pageHeaderData}
						setPageLayoutType={setPageLayoutType}
					/>
				)}
				{deleteCarousel && (
					<SweetAlert
						title={"Confirm Delete of Carousel"}
						showConfirm={false}
						onConfirm={() => setDeleteCarousel(null)}
						customClass={"delete-conf-sweet"}
					>
						<div style={{ position: "relative" }}>
							<div className="">
								<span className="delete-span">Are you sure you want to delete this Carousel ?</span>
							</div>
						</div>
						<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
							<NoButton onClick={() => setDeleteCarousel(null)} name="No" />
							{deleteCarouselLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={() => deleteCarouselGroup(pageLayoutType)} />}
						</div>
					</SweetAlert>
				)}
				{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			</HelmetLayout>
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<CarouselHelpInformation />
				</InformationPageHelper>
			)}
		</>
	);
};

export default Carousel;
