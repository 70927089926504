import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiServices from "utils/ApiServices";
import { LoadingSaveButton, NoButton, YesButton } from "./AddButton";
import { apiUrls } from "utils/const.apiUrl";
import { setAffiliateUsers } from "store/Users/actions";
import { useDispatch } from "react-redux";

const ConfirmDeleteComponent = ({
	showDeleteCom,
	deleteTitle,
	titlename,
	deleteLoading,
	setShowDeleteCom,
	setDeleteLoading,
	id,
	betSlipData,
	setBetSlipData,
	setAffiliateUsersData,
	affiliateUsersData,
	bankingUser,
	setBankingUser,
	type,
	landingPagesData,
	setLandingPagesData,
}) => {
	const dispatch = useDispatch();

	const deleteBetSlip = () => {
		setDeleteLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.content_delete + `/${id}`, resolve, reject, true);
		})
			.then(response => {
				if (response) {
					let newBetSlipData = [...betSlipData];
					newBetSlipData = newBetSlipData.filter(row => row.id !== id);
					setBetSlipData(newBetSlipData);
				}
			})
			.finally(() => {
				setShowDeleteCom(false);
				setDeleteLoading(false);
			});
	};

	const deleteAffiliateUsers = () => {
		setDeleteLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.affiliate_users_delete + `/${id}`, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let newAffiliateUsersData = [...affiliateUsersData];
					newAffiliateUsersData = newAffiliateUsersData.filter(row => row.id !== id);
					setAffiliateUsersData(newAffiliateUsersData);
					dispatch(setAffiliateUsers([]));
				}
			})
			.finally(() => {
				setShowDeleteCom(false);
				setDeleteLoading(false);
			});
	};

	const deletePendingWithdrawal = () => {
		setDeleteLoading(true);

		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.DELETE_WITHDRAWAL + id, resolve, reject, true);
		})
			.then(response => {
				if (response) {
					let newBankingUser = [...(bankingUser?.pending_withdrawal || [])];
					newBankingUser = newBankingUser.filter(row => row.id !== id);
					setBankingUser({ ...bankingUser, pending_withdrawal: newBankingUser });
				}
			})
			.finally(() => {
				setShowDeleteCom(false);
				setDeleteLoading(false);
			});
	};

	const deleteLandingPage = () => {
		setDeleteLoading(true);
		let body = {
			id: id,
		};
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.delete_landing_pages + id, resolve, reject, body, true);
		})
			.then(response => {
				if (response) {
					let newLandingPagesData = [...landingPagesData];
					newLandingPagesData = newLandingPagesData.filter(row => row.id !== id);
					setLandingPagesData(newLandingPagesData);
				}
			})
			.finally(() => {
				setShowDeleteCom(false);
				setDeleteLoading(false);
			});
	};

	return (
		<>
			{showDeleteCom && (
				<SweetAlert title={deleteTitle} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="">
							{type === "pendingWithdrawal" ? (
								<span className="delete-span">
									Are you sure you want to cancel the &quot;{titlename}&quot;? <br /> This will return the money to the user.
								</span>
							) : (
								<span className="delete-span">Are you sure you want to delete the &quot;{titlename}&quot;?</span>
							)}
						</div>
					</div>
					<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
						<NoButton onClick={() => setShowDeleteCom(false)} name="No" />
						{deleteLoading ? (
							<LoadingSaveButton />
						) : (
							<>
								<YesButton
									name="Yes"
									onClick={
										type === "affiliateUsers"
											? deleteAffiliateUsers
											: type === "pendingWithdrawal"
											? deletePendingWithdrawal
											: type === "landingPages"
											? deleteLandingPage
											: deleteBetSlip
									}
								/>
							</>
						)}
					</div>
				</SweetAlert>
			)}
		</>
	);
};

export default ConfirmDeleteComponent;
