import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getAffiliateUsersTableColumn } from "./getAffiliateUsersTableColumn";
import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

import { AddTableButton } from "components/GeneralComponents/AddTableButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddAffiliateUser from "./AddAffiliateUser";
import DeleteAffiliates from "./DeleteAffiliates";
import { capitalizeText } from "services/capitalizeText";

import "./affiliateUsers.scss";

const AffiliateUsers = () => {
	const history = useHistory();

	const [affiliateUsers, setAffiliateUsers] = useState([]);
	const [unfilteredData, setUnfilteredData] = useState([]);

	const [deleteItem, setDeleteItem] = useState("");
	const [isDataLoading, setIsDataLoading] = useState("");
	const [showPromotion, setShowPromotion] = useState(false);

	const handleEditItem = row => {
		if (row) history.push(`/affiliate_users/${row.agent_id}`);
	};

	useEffect(() => {
		if (deleteItem) {
			//TODO -> handle delete item
		}
	}, [deleteItem]);

	const getAffiliateUsers = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_AFFILIATE_USERS, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					var data = response.data;
					data.sort((a, b) => a.id - b.id);
					setAffiliateUsers(data);
					setUnfilteredData(data);
				}
			})
			.finally(() => setIsDataLoading(false));
	};

	useEffect(() => {
		getAffiliateUsers();
	}, []);

	const handleCloseModal = type => {
		if (type === "call") getAffiliateUsers();
		setShowPromotion(false);
	};

	const affiliateUsersTableColumns = getAffiliateUsersTableColumn({ affiliateUsers, handleEditItem, setDeleteItem });

	return (
		<>
			<HelmetLayout titleProps={"Affiliates Users"}>
				<div className="cms-page pt-0">
					<SingleSearchFilter setData={setAffiliateUsers} unfilteredData={unfilteredData} detailsColumn={["first_name"]} />
					<MUIDataTable
						columns={affiliateUsersTableColumns}
						data={affiliateUsers}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							sort: true,
							elevation: 0,
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							responsive: tableResponsive,
							rowsPerPage: 50,
							rowsPerPageOptions: tableRowsPerPage,
							customToolbar: () => <AddTableButton onClick={() => setShowPromotion(true)} />,
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
			</HelmetLayout>

			{showPromotion && <AddAffiliateUser handleClose={handleCloseModal} />}

			{deleteItem && (
				<DeleteAffiliates
					paragraph={`Are you sure you want to delete this agent ${capitalizeText(deleteItem?.first_name + " " + deleteItem?.last_name)}`}
					deleteItem={deleteItem}
					setDeleteItem={setDeleteItem}
					getSubAgents={getAffiliateUsers}
				/>
			)}
		</>
	);
};
export default AffiliateUsers;
