import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { PageHeader } from "components/VerticalLayout/PageHeader";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { SingleSearchFilterButtons } from "components/GeneralComponents/CustomInputs";
import { NoButton, LoadingSaveButton, YesButton } from "components/GeneralComponents/AddButton";
import { getPageLayoutColumns } from "./pageLayoutColumns";
import { PageLayoutModal } from "./pageLayoutModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import DataTable from "components/GeneralComponents/Table";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const PageLayout = () => {
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [data, setData] = useState([]);
	const [showPromotion, setShowPromotion] = useState(false);
	const [editItem, setEditItem] = useState(null);
	const [pageLayout, setPageLayout] = useState([]);
	const [pageLayoutType, setPageLayoutType] = useState("");
	const [deleteLoading, setDeleteLoading] = useState(null);
	const [deleteItem, setDeleteItem] = useState(null);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const country = useSelector(state => state.Login.country);

	const [pageHeaderData, setPageHeaderData] = useState({
		international: false,
		addNew: true,
		dropdownData: [],
		addNewClick: () => {
			setShowPromotion(true);
		},
	});
	const allMenuLinks = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.menu_links, resolve, reject, true);
		})
			.then(response => {
				let data = response?.data?.data;
				let contentData = [];
				setData(data);
				data.forEach(row => {
					contentData.push({
						name: row.link_name,
						value: row.slug,
					});
				});

				setPageHeaderData({
					...pageHeaderData,
					dropdownData: contentData,
				});

				if (contentData.length) setPageLayoutType(contentData[0]?.value);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const pageLayouts = (page, country) => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.page_layout}?type=${pageLayoutType ? pageLayoutType : "index"}&country=${country}`, resolve, reject, true);
		})
			.then(response => {
				setPageLayout(response?.data);
				setUnfilteredData(response?.data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const changeOrder = index => {
		const selectedRow = pageLayout.splice(index, 1)[0];
		const newData = [selectedRow, ...pageLayout];

		setNewData(newData);
	};

	const orderItems = newOrder => {
		let payload = {
			order: newOrder.map((row, index) => ({ id: row?.id, order: index })),
			type: pageLayoutType,
			country,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.order_page_layout, resolve, reject, payload, true);
		}).then(() => {});
	};

	const setNewData = newData => {
		setPageLayout(newData);
		setUnfilteredData(newData);
		orderItems(newData);
	};

	const handleEditItem = row => {
		setShowPromotion(true);
		setEditItem(row);
	};

	const deletePageLayout = id => {
		let body = {
			id: id,
			parent: `${pageLayoutType}_layout`,
			country: country,
		};
		setDeleteLoading(true);
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.delete_page_layout, resolve, reject, body, true);
		})
			.then(response => {
				let data = response.data;
				setPageLayout(data);
				setDeleteItem(false);
			})
			.finally(() => {
				setDeleteLoading(false);
				setIsDataLoading(false);
			});
	};

	useEffect(() => {
		pageLayouts("", country);
	}, [country, pageLayoutType]);

	useEffect(() => {
		allMenuLinks();
	}, []);

	const pageLayoutColumns = getPageLayoutColumns({ pageLayout, handleEditItem, setDeleteItem, changeOrder });

	return (
		<HelmetLayout titleProps={"Page Layout"}>
			{!showPromotion && (
				<PageHeader
					pageHeaderData={pageHeaderData}
					pageLayoutType={pageLayoutType}
					setPageType={setPageLayoutType}
					setContentData={setPageLayout}
					type={"page_layout"}
				/>
			)}
			<div className="cms-page header-page carousel-page-header">
				<SingleSearchFilterButtons
					unfilteredData={unfilteredData}
					filterColumn={["type_name", "type"]}
					extraHeaderButtons
					data={data}
					setDatas={setData}
					setData={setPageLayout}
					pageLayoutType={pageLayoutType ? pageLayoutType : "index"}
				/>
				<DataTable
					data={pageLayout}
					columns={pageLayoutColumns}
					setData={newData => {
						setNewData(newData);
					}}
					options={{
						showButtons: true,
						search: false,
						viewColumns: false,
						customToolbar: () => <AddTableButton className={"data-table-add-btn"} onClick={pageHeaderData["addNewClick"]} />,
					}}
					isLoading={isDataLoading}
					pagination={false}
					orderRows={true}
					sort={false}
				/>
			</div>
			{showPromotion && (
				<PageLayoutModal
					setShowPromotion={setShowPromotion}
					setPageLayout={setPageLayout}
					setEditItem={setEditItem}
					editItem={editItem}
					pageLayoutType={pageLayoutType}
					country={country}
				/>
			)}
			{deleteItem && (
				<SweetAlert title={"Confirm Delete"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="">
							<span className="delete-span">Are you sure you want to delete &quot;{deleteItem?.type_name}&quot; ?</span>
						</div>
					</div>
					<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
						<NoButton onClick={() => setDeleteItem(false)} name="No" />
						{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={() => deletePageLayout(deleteItem?.id)} />}
					</div>
				</SweetAlert>
			)}
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(PageLayout);
