import React from "react";
import { DeleteIconButton, EditIconButton } from "components/GeneralComponents/AddButton";
import deleteIcon from "../../../assets/images/delete.svg";
import { capitalizeText } from "services/capitalizeText";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const getSecheledReportsTableColumns = ({ setShowScheduledModal, setDeleteId }) => {
	return [
		{
			name: "report_name",
			label: "REPORT NAME",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
		},
		{
			name: "frequency",
			label: "FREQUENCY",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					const { frequency } = row;
					return capitalizeText(frequency);
				},
			},
		},
		{
			name: "recipients",
			label: "RECIPIENTS",
			align: "left",
			sortable: true,
			sort: "asc",
			className: "name",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					const { recipients } = row;
					const newRecipients = recipients.split(",");
					return (
						<LightTooltip
							title={newRecipients.map((row, i) => {
								return (
									<div key={i}>
										<span>{row}</span>
									</div>
								);
							})}
						>
							<div>{newRecipients[0]}</div>
						</LightTooltip>
					);
				},
			},
		},
		// {
		// 	name: "created_date",
		// 	label: "CREATED DATE",
		// 	className: "name",
		// 	align: "left",
		// 	sortable: true,
		// 	sort: "asc",
		// 	options: {
		// 		setCellProps: () => ({ style: { textAlign: "left", minWidth: "170px" } }),
		// 		setCellHeaderProps: () => ({ style: { minWidth: "170px" }, className: "max-button" }),
		// 	},
		// },
		{
			name: "status",
			label: "STATUS",
			className: "name",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					const status = row?.status;
					return status ? <span className="badge badge-sports-published"> Active </span> : <span className="badge badge-sports-inactive"> Inactive </span>;
				},
			},
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const row = meta;
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditIconButton onClick={() => setShowScheduledModal(row)} />
								<DeleteIconButton deleteIcon={deleteIcon} onClick={() => setDeleteId(row?.id)} />
							</div>
						</div>
					);
				},
			},
		},
	];
};

export const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: "#2E3A49",
		boxShadow: theme.shadows[1],
		fontSize: 12,
	},
}));

export const formatString = str => {
	return str
		.split("-")
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
};
