import React from "react";
import { getFormatDateByUserTimezone } from "utils/global";
import { DeleteIcon } from "utils/icons";
import { DeleteIconButton } from "components/GeneralComponents/AddButton";

export const getSelfExcludedTable = ({ selfExcludedGambling, setShowDeleteConfirm, deleteItem, setDeleteItem }) => {
	return [
		{
			name: "period",
			label: "PERIOD",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const row = selfExcludedGambling[meta.currentTableData[meta.rowIndex].index];
					let title = row?.period;

					if (title == 0 || title == -1) {
						title = "Not Set";
					} else {
						title = `${title} Days`;
					}

					return <>{title}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "166px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "60px" }, className: "max-button" }),
			},
		},

		{
			name: "start_period",
			label: "PERIOD START DATE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const period = selfExcludedGambling[meta.currentTableData[meta.rowIndex].index].period;
					if (period === 0 || period === -1) return <>{"-"}</>;

					const row = selfExcludedGambling[meta.currentTableData[meta.rowIndex].index];
					const date = getFormatDateByUserTimezone(row.start_period, "DD MMM YYYY HH:mm");
					return <>{row?.period == "-1" ? "-" : date}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
		{
			name: "end_period",
			label: "PERIOD END DATE",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				customBodyRender: (value, meta) => {
					const period = selfExcludedGambling[meta.currentTableData[meta.rowIndex].index].period;
					if (period === 0 || period === -1) return <>{"-"}</>;
					const row = selfExcludedGambling[meta.currentTableData[meta.rowIndex].index];
					const date = getFormatDateByUserTimezone(row.end_period, "DD MMM YYYY HH:mm");
					return <>{row?.period == "-1" ? "-" : date}</>;
				},
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
			},
		},
		{
			name: "",
			label: "",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				viewColumns: false,
				setCellProps: () => ({ style: { textAlign: "left", width: "150px" } }),
				setCellHeaderProps: () => ({ style: { maxWidth: "150px" }, className: "max-button" }),
				customBodyRender: (value, meta) => {
					const record = selfExcludedGambling[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<DeleteIconButton
									deleteIcon={DeleteIcon}
									onClick={() => {
										setShowDeleteConfirm(true);
										setDeleteItem({ ...deleteItem, type: "self exclude", value: record?.value });
									}}
								/>
							</div>
						</div>
					);
				},
			},
		},
	];
};

export const getSelfExcludedData = self_excluded => {
	let data = {};
	const { period, start_period, end_period } = self_excluded;
	const notSet = period == -1 || period == 0 ? true : false;

	data = { ...data, period: period == -1 || period == 0 ? "Not Set" : `${period} Months` };
	data = { ...data, start_period: period == "-1" ? "-" : getFormatDateByUserTimezone(start_period, "DD MMM YYYY HH:mm") };
	data = { ...data, end_period: period == "-1" ? "-" : getFormatDateByUserTimezone(end_period, "DD MMM YYYY HH:mm") };

	return {
		columns: [
			{ name: "Period", value: data.period },
			{ name: "Start", value: data.start_period },
			{ name: "End", value: data.end_period },
		],
		notSet: notSet,
	};
};
