import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let result = [];

export const getAllCasinoRestrictionDropdown = async (isAllRow, callAgain = false, type) => {
	if (result.length && !callAgain) return result;
	return new Promise((resolve, reject) => {
		ApiServices.get(`${apiUrls.get_casino_groups}?type=${type}`, resolve, reject, true);
	})
		.then(response => {
			let casinoRestriction = response.data;
			casinoRestriction = casinoRestriction.map(row => {
				if (isAllRow) return { ...row, label: row.title };
				else return { id: row.id + "", label: row.title };
			});

			result = casinoRestriction;
			return casinoRestriction;
		})
		.catch(() => {
			result = [];
			return [];
		});
};
