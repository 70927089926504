import React from "react";
import { XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { AvForm } from "availity-reactstrap-validation";

export const EditUserListModal = ({
	editClicked,
	dataChanged,
	setDataChanged,
	handleReset2FA,
	setData,
	data,
	showEditModal,
	setShowEditModal,
	updateUser,
	signOutUser,
	loading,
}) => {
	const userFields = [
		{
			type: "input",
			name: "First Name",
			fieldName: "firstName",
			required: true,
			textUpperCase: true,
		},
		{
			type: "input",
			name: "Last Name",
			fieldName: "lastName",
			required: true,
			textUpperCase: true,
		},
		{
			type: "dropdown",
			name: "Role",
			fieldName: "role",
			required: true,
			optionLabel: "name",
			optionsType: "role",
			textUpperCase: true,
		},
		{ type: "input", name: "Email", fieldName: "email", disabled: true, required: true },
		{
			type: "dropdown",
			name: "Timezone",
			fieldName: "timezone_name",
			required: true,
			optionsType: "timezone",
		},
		{
			type: "switch",
			name: "Enabled 2FA",
			fieldName: "status",
			// onChangeEvent: handleStatus,
		},
		{
			type: "button",
			title: "Reset 2FA",
			name: "Reset 2FA",
			fieldName: "reset2FA",
			onClickEvent: handleReset2FA,
			buttonClassname: "cms-user-buttons",
		},

		// {
		// 	type: "button",
		// 	title: "Password",
		// 	name: "Reset Password",
		// 	fieldName: "resetPassword",
		// 	textUpperCase: true,
		// 	onClickEvent: handleResetPassword,
		// 	buttonClassname: "cms-user-buttons",
		// },

		{
			type: "button",
			title: "Sign Out",
			name: "Sign Out",
			fieldName: "signOut",
			textUpperCase: true,
			onClickEvent: signOutUser,
			buttonClassname: "cms-user-buttons",
		},
	];

	return (
		<>
			{editClicked && showEditModal && (
				<div className="promotion-container">
					<div className="promotion-header">
						<span>Edit User</span>
						<XCloseIcon
							onClick={() => {
								setShowEditModal(false);
							}}
							className={"xclose-icon"}
						/>
					</div>
					<div className="promotion-body">
						<TopBarComponent
							saveHandler={() => updateUser(userFields)}
							dataChanged={dataChanged}
							loading={loading}
							onClose={() => setShowEditModal(false)}
						/>
						<div className="promotion-elements row m-0">
							<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
								<AvForm>
									<AllComponentsLoop fields={userFields} data={data} setData={setData} setDataChanged={setDataChanged} />
								</AvForm>
							</div>
							<div className="promotion-right col-12 col-lg-6"></div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
