import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { LoadingSaveButton, ModalCancelButton, ModalCreateButton } from "./AddButton";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

export const CreateCarouselComponent = props => {
	const { setShow, pageHeaderData, setPageHeaderData, setPageLayoutType } = props;
	const [name, setName] = useState("");
	const [loading, setLoading] = useState(false);

	const addNewCarousel = () => {
		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.ADD_CAROUSEL_GROUP, resolve, reject, { name: name }, true);
		})
			.then(response => {
				let data = response?.data;

				let contentData = [];
				data.forEach(element => {
					contentData.push({
						name: element?.name,
						value: element?.id,
					});
				});
				setPageHeaderData({
					...pageHeaderData,
					dropdownData: contentData,
				});

				if (data && data.length > 0) {
					setPageLayoutType(data[data.length - 1]?.id);
				}
			})
			.finally(() => {
				setShow(false);
				setLoading(false);
			});
	};
	return (
		<>
			<SweetAlert title={"Create New Carousel"} showConfirm={false} onConfirm={() => setShow(false)} customClass={"delete-conf-sweet"}>
				<div style={{ position: "relative" }}>
					<div className="modal-center-container">
						<span className="modal-center-text">Enter the name of the carousel. This will be displayed internally only. </span>
						<input
							type="text"
							placeholder="Name"
							className="modal-text-input"
							value={name}
							onChange={e => {
								setName(e.target.value);
							}}
						/>
					</div>
				</div>
				<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
					<ModalCancelButton onClick={() => setShow(false)} name="No" />
					{loading ? <LoadingSaveButton /> : <ModalCreateButton onClick={addNewCarousel} />}
				</div>
			</SweetAlert>
		</>
	);
};
