import { NoButton, LoadingSaveButton, YesButton, EditIconButton, DeleteIconButton } from "components/GeneralComponents/AddButton";
import { DragAndDropFillIcon, DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone } from "utils/icons";
import deleteIcon from "../../../assets/images/delete.svg";
import React, { useState } from "react";
import ApiServices from "utils/ApiServices";
import SweetAlert from "react-bootstrap-sweetalert";
import { apiUrls } from "utils/const.apiUrl";
import DragAndDropList from "components/GeneralComponents/DragAndDrop";

export const CategoryItems = props => {
	const { setCategory, categories, setCategories, handleRedirect } = props;

	const [deleteItem, setDeleteItem] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const handleDeleteGroup = id => {
		setDeleteLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.delete_casino_group + `/${id}`, resolve, reject, true);
		})
			.then(() => {
				let newCategories = [...categories];
				newCategories = newCategories.filter(row => row.id !== id);
				setCategories(newCategories);
				setDeleteItem(null);
			})
			.finally(() => {
				setDeleteLoading(false);
			});
	};

	const setDregAndDropData = newData => {
		setCategories(newData);
		orderItems(newData);
	};

	const handleEditItem = row => {
		let newDataUpdated = row;
		const details = JSON?.parse(row?.details);
		if (row?.details) {
			Object.entries(details).forEach(([key, value]) => {
				newDataUpdated = { ...newDataUpdated, [key]: value };
			});
		}
		newDataUpdated = { ...newDataUpdated, mode: "edit" };

		setCategory(newDataUpdated);
		handleRedirect();
	};

	const orderItems = newOrder => {
		let payload = {
			order: newOrder.map((row, index) => ({ id: row.id, order: index })),
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.order_casino_group, resolve, reject, payload, true);
		}).then(() => {});
	};

	const changeOrder = index => {
		const selectedRow = categories.splice(index, 1)[0];
		const newData = [selectedRow, ...categories];
		setDregAndDropData(newData);
	};

	return (
		<>
			<div className="category-items">
				<DragAndDropList data={categories} setData={newData => setDregAndDropData(newData)}>
					{(row, { index, dragHandleProps, isDragging }) => {
						return (
							<div className={`default-sport-row fs-6 `}>
								<span>{row?.title}</span>

								<div className="d-flex justify-content-end">
									<div className="heroBanners-buttons">
										<EditIconButton onClick={() => handleEditItem(row)} />
										<DeleteIconButton deleteIcon={deleteIcon} onClick={() => setDeleteItem(row)} />
										{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}
										<div
											{...dragHandleProps}
											onMouseDown={(...args) => {
												dragHandleProps?.onDragStart(...args);
											}}
										>
											<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
										</div>
									</div>
								</div>
							</div>
						);
					}}
				</DragAndDropList>
			</div>
			{deleteItem && (
				<SweetAlert title={"Confirm Deletion of Category"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="">
							<span className="delete-span">Are you sure you want to delete {deleteItem?.title} ?</span>
						</div>
					</div>
					<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
						<NoButton onClick={() => setDeleteItem(null)} name="No" />
						{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={() => handleDeleteGroup(deleteItem?.id)} />}
					</div>
				</SweetAlert>
			)}
		</>
	);
};
