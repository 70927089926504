import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { BlueCancelButton, LoadingSaveButton, SaveButton } from "components/GeneralComponents/AddButton";
import { GeneralDropdown } from "components/GeneralComponents/CustomInputs";
export const ShowCloneSportModal = ({
	showCloneSport,
	setSportOrderData,
	sportOrderData,
	countries,
	lsGSData,
	setShowCloneSport,
	cloneSpinning,
	handleCloneSportOrder,
	countriesArr,
}) => {
	const countriesData = countries && countries.length > 0 ? countries : lsGSData && lsGSData.countries ? lsGSData.countries : [];
	let newCountries = countriesData.filter(row => !countriesArr.includes(row?.cca2));

	return (
		<>
			{showCloneSport && (
				<SweetAlert title="Clone Sports order" showConfirm={false} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<GeneralDropdown
							object={{
								value: sportOrderData.country_code,
								bindValue: sportOrderData.country_name,
								label: sportOrderData.country_name,
								defaultPlaceholder: "Choose Country",
								options: newCountries.map(({ cca2, name }) => ({ id: cca2, label: name })),
							}}
							handleChange={value => setSportOrderData({ ...sportOrderData, country_code: value.id, country_name: value.label })}
						/>

						<div className="mt-3">
							<BlueCancelButton onClick={() => setShowCloneSport(false)} name={"Cancel"} />
							{cloneSpinning ? <LoadingSaveButton /> : <SaveButton onClick={() => handleCloneSportOrder()} name={"Save"} />}
						</div>
					</div>
				</SweetAlert>
			)}
		</>
	);
};
