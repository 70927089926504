import BetReferralItem from "./betReferralItem";
import CloseIconWhite from "../../../assets/images/closeIcon.svg";
import { BetReferralBtn } from "./betReferralButtons/betReferralButtons";
import { useDispatch, useSelector } from "react-redux";
import { setBetReferralModal } from "../../../store/Modals/actions";
import { setBackdrop } from "../../../store/Backdrop/actions";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import ApiServices from "../../../utils/ApiServices";
import { removeBetFromNotificationsList } from "../../../store/NotificationsListBets/actions";
import BetReferralCombination from "./betReferralCombination";
import { toFixedFloat } from "../BetTicketHelpers";
import { AddFreeCashLogo, InfoIconGeneral, LightArrowDownIcon, SimpleGoBackArrow } from "../../../utils/icons";
import { setEmptyBetReferral, setSelectedBetReferral, setBetReferralPlayerSubId, setUpdatedOdds, setFreeBetId } from "../../../store/SelectedBet/actions";
import { apiUrls } from "utils/const.apiUrl";
import { toastError } from "../../../utils/toast";
import { ThousandSeperated } from "hooks/General";
import axios from "axios";
import useOutsideClick from "hooks/useOutsideClick";
import { filterAndSortArray, userReadOnly } from "utils/global";
import AllSingles from "./AllSingles";
import { v4 as uuidv4 } from "uuid";
import { getLadders } from "services/getLaddersDropdown";

import "./betReferralModal.css";
import { getIdWithoutProvider } from "../../../utils/providersUtils";

const betReferralModal = () => {
	const dispatch = useDispatch();
	const context = useContext(AppContext);

	const formRef = useRef(null);
	const modalRef = useRef(null);

	const user = useSelector(state => state.Login.user);
	const isUserReadOnly = userReadOnly(user);

	const isModalOpen = useSelector(state => state.BetReferralModal.isOpen);
	const SelectedBetReferral = useSelector(state => state.SelectedBetReferral.selectedBetReferral);
	const SelectedBetReferralFreeBet = useSelector(state => state.SelectedBetReferral.freebetId);

	const userFullName = user?.first_name + " " + user?.last_name;
	const socketCommunication = context.socketCommunication;
	// const gamingSocket = context.gamingSocket;

	const totalStakes = toFixedFloat(SelectedBetReferral.bet_slip?.total_stakes);
	const totalPayouts = toFixedFloat(SelectedBetReferral.bet_slip?.total_payout);

	const [affiliateUsers, setAffiliateUsers] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [userInformation, setUserInformation] = useState({
		avgStake: "",
		currency: "",
		first_name: "",
		freeBetCredits: "",
		last_name: "",
		lifetimePosition: "",
		mainBalance: "",
		profitLoss: "",
		totalBets: 0,
		withdrawalBalance: "",
	});
	const [initialBetReferral, setInitialBetReferral] = useState("");
	const [hasChanged, setHasChanged] = useState(false);
	const [sportsLadders, setSportsLadders] = useState({});

	const betSlip = SelectedBetReferral?.bet_slip;

	const closeModalBackdrop = () => {
		dispatch(setBetReferralModal(false));
		dispatch(setBackdrop(false));
		dispatch(setEmptyBetReferral());
	};

	const closeBetReferralHandler = () => {
		closeModalBackdrop();
	};

	const resetBtnHandler = () => {
		const initial = JSON.parse(initialBetReferral);
		setHasChanged(false);

		formRef.current?.reset();
		dispatch(setSelectedBetReferral({ ...SelectedBetReferral, bet_slip: initial }));
	};

	const offerBtnHandler = () => {
		if (SelectedBetReferral.status !== "pending") {
			return toastError(`Can't make offer on non-pending bet ticker`);
		}

		const data = {
			bet_referral_id: SelectedBetReferral.id,
			bet_status: "new_offer",
			bet_slip: {
				...SelectedBetReferral.bet_slip,
				singles: betSlip?.singles.map(single => {
					const singlePayload = {
						...single,
						new_stake: single?.stake,
						new_payout: single?.payout,
						new_return: single?.odds_decimal,
						bet_id: getIdWithoutProvider(single.bet_id),
					};

					return singlePayload;
				}),
				combinations: betSlip?.combinations.map(combo => {
					return {
						...combo,
						new_stake: combo?.stake,
						new_payout: combo?.payout,
						new_total_stakes: totalStakes,
						new_return: combo?.odds_decimal,
					};
				}),
				new_total_stakes: totalStakes,
				new_total_payout: totalPayouts.toString(),

				...(SelectedBetReferralFreeBet ? { use_free_bet: 1, free_bet_id: SelectedBetReferralFreeBet } : {}),
			},
		};
		const socket = context.socketCommunication;

		socket.emit("verify_bet_referral", data, () => {
			closeModalBackdrop();
		});

		const socketPayload = {
			destination: "cms",
			data: {
				new_status: "new_offer",
				bet_ticker_id: SelectedBetReferral?.id,
				type: "bet_ticker_update",
			},
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.send_message_on_communication, resolve, reject, socketPayload, false);
		}).then();
	};

	const rejectBtnHandler = () => {
		const body = {
			bet_referral_id: SelectedBetReferral?.id,
			bet_status: "rejected",
		};

		new Promise((resolve, reject) => {
			ApiServices.post(`${apiUrls.EDIT_BET_REFERRAL_STATUS}`, resolve, reject, body, true);
		})
			.then(response => {
				if (response) {
					socketCommunication.emit("verify_bet_referral", {
						bet_referral_id: SelectedBetReferral?.id,
						bet_status: "rejected",
					});
					return response;
				}
			})
			.then(() => {
				const socketPayload = {
					destination: "cms",
					data: {
						new_status: "rejected",
						bet_ticker_id: SelectedBetReferral?.id,
						type: "bet_ticker_update",
					},
				};

				axios.post(apiUrls.send_message_on_communication, socketPayload).then(() => {
					dispatch(removeBetFromNotificationsList(SelectedBetReferral?.id));
					dispatch(setEmptyBetReferral());
				});
			});

		closeModalBackdrop();
	};

	const acceptBtnHandler = () => {
		const body = {
			bet_referral_id: SelectedBetReferral?.id,
			bet_status: "approved",
		};

		new Promise((resolve, reject) => {
			ApiServices.post(`${apiUrls.EDIT_BET_REFERRAL_STATUS}`, resolve, reject, body, true);
		})
			.then(response => {
				if (response) {
					socketCommunication.emit("verify_bet_referral", {
						bet_referral_id: SelectedBetReferral?.id,
						bet_status: "approved",
					});
					return response;
				}
			})
			.then(() => {
				const socketPayload = {
					destination: "cms",
					data: {
						new_status: "approved",
						bet_ticker_id: SelectedBetReferral?.id,
						type: "bet_ticker_update",
					},
				};

				axios.post(apiUrls.send_message_on_communication, socketPayload).then(() => {
					dispatch(removeBetFromNotificationsList(SelectedBetReferral?.id)), dispatch(setEmptyBetReferral());
				});

				new Promise((resolve, reject) => {
					ApiServices.post(
						apiUrls.ADD_BET_SLIP + "?cms=true",
						resolve,
						reject,
						{
							bets: [],
							stakes: [],
							action: "place",
							bet_referral_id: SelectedBetReferral?.id,
							player_id: SelectedBetReferral?.sub_id,
						},
						true,
						[],
						true
					);
				});
			});

		closeModalBackdrop();
	};

	const getAffiliateUsers = () => {
		return new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_CMS_USERS, resolve, reject, true);
		})
			.then(response => {
				const data = response.data[0];

				const sortData = filterAndSortArray(data, "first_name");
				setAffiliateUsers(sortData);
			})
			.catch(() => {});
	};

	useEffect(() => {
		socketCommunication &&
			socketCommunication.on("selection_updated", response => {
				response.forEach(res => {
					const data = { [res?.data?.bet_id]: res?.data };
					dispatch(setUpdatedOdds(data));
				});
			});

		return () => {
			socketCommunication.off("selection_updated");
		};
	}, [socketCommunication]);

	// subscribe to the event to get the odd changes
	useEffect(() => {
		if (socketCommunication) {
			if (betSlip.singles.length > 0) {
				betSlip.singles.forEach(bet => {
					if (bet.bet_id) {
						socketCommunication.emit("subscribe_selection", {
							value: bet.bet_id,
						});
					}
				});

				socketCommunication.on("connection", () => {
					betSlip.singles.forEach(bet => {
						if (bet.bet_id) {
							socketCommunication.emit("subscribe_selection", {
								value: bet.bet_id,
							});
						}
					});
				});
			}

			return () => {
				betSlip.singles.forEach(bet => {
					if (bet.bet_id) {
						socketCommunication.emit("unsubscribe_selection", {
							value: bet.bet_id,
							action_id: uuidv4(),
						});
					}
				});
			};
		}
	}, [socketCommunication]);

	useEffect(() => {
		setInitialBetReferral(JSON.stringify(SelectedBetReferral?.bet_slip));
		getAffiliateUsers();
		getSportsLadders();

		if (SelectedBetReferral.bet_slip?.use_free_bet) {
			dispatch(setFreeBetId(SelectedBetReferral?.bet_slip?.free_bet_id));
		}
	}, []);

	useEffect(() => {
		if (!isUserReadOnly) {
			if (SelectedBetReferral?.trader_id !== 0 && user?.id !== SelectedBetReferral?.trader_id) {
				setDisabled(true);
			} else if (SelectedBetReferral.trader_id === 0 && isModalOpen) {
				handleChangeTrader(null, user.id, userFullName);
			} else if (SelectedBetReferral.trader_id === user.id) {
				setDisabled(false);
			}
		} else setDisabled(isUserReadOnly);
	}, [isModalOpen]);

	const handleNewEvent = newBet => {
		if (newBet?.type === "BET_REASSIGNED") {
			const {
				newReassignBet: { trader_id, trader_name, id },
			} = newBet;

			if (id === SelectedBetReferral.id) {
				if (trader_id !== 0 && user.id !== trader_id) setDisabled(true);
				else setDisabled(false);

				dispatch(setSelectedBetReferral({ ...SelectedBetReferral, trader_id, trader_name }));
			}
		}
	};

	useEffect(() => {
		socketCommunication && socketCommunication.on("new_event", handleNewEvent);

		return () => {
			socketCommunication && socketCommunication.off("new_event", handleNewEvent);
		};
	}, [socketCommunication]);

	const handleChangeTrader = (e, traderId, traderName = "") => {
		if (e) e.stopPropagation();

		if (traderId !== 0 && user.id !== traderId) setDisabled(true);
		else setDisabled(false);

		setIsDropdownOpen(false);
		const body = {
			trader_id: traderId,
			trader_name: traderName,
			id: SelectedBetReferral.id,
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.ASSIGN_TRADER, resolve, reject, body, true);
		})
			.then(() => {
				dispatch(setSelectedBetReferral({ ...SelectedBetReferral, trader_id: traderId, trader_name: traderName }));
			})
			.catch(() => {});
	};

	const getUserStats = () => {
		if (SelectedBetReferral?.player_id) {
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls.GET_USER_STATS + SelectedBetReferral?.player_id, resolve, reject, true);
			}).then(response => {
				if (response.success) {
					setUserInformation(response?.data);
					dispatch(setBetReferralPlayerSubId(response?.data?.player_sub_id));
				}
			});
		}
	};

	const getSportsLadders = async () => {
		const singles = betSlip?.singles;
		const sportSlugs = singles.map(row => row.sport_slug);
		const uniqueSlugs = [...new Set(sportSlugs)];

		let ladders = await getAllLadders(uniqueSlugs);
		setSportsLadders(ladders);
	};

	const getAllLadders = async sportSlugs => {
		const promise = await Promise.all(sportSlugs.map(f => getLadders(f)));
		const resultObject = Object.fromEntries(sportSlugs.map((slug, index) => [slug, promise[index]]));
		return resultObject;
	};

	useEffect(() => {
		getUserStats();
	}, [SelectedBetReferral?.player_id]);

	const toggleDropdown = async e => {
		e.stopPropagation();
		if (!affiliateUsers.length) {
			await getAffiliateUsers();
		}
		setIsDropdownOpen(prev => !prev);
	};

	useOutsideClick(modalRef, () => closeBetReferralHandler());

	const [playerDetails, setPlayerDetails] = useState(false);

	return (
		<>
			{isModalOpen && SelectedBetReferral && (
				<div ref={modalRef}>
					<div className={`bet-user ${playerDetails ? "bet-referral-player-details-modal" : ""}`}>
						<div className="bet-user-container">
							<div className="bet-user-header">
								<div className="bet-user-title">User Information</div>
							</div>
							<div className="bet-responsive-container bet-referral-player-details">
								<SimpleGoBackArrow onClick={() => setPlayerDetails(false)} fillColor="#fff" />
								{SelectedBetReferral?.player_id} - {SelectedBetReferral?.player_name}
							</div>

							<div className="bet-user-information">
								<div className="bet-user-inputs">
									<div className="bet-user-label">
										PLAYER ID
										<div className="bet-user-name">{SelectedBetReferral?.player_id}</div>
									</div>
									<div className="bet-user-label">
										NAME
										<div className="bet-user-name">
											{userInformation?.first_name?.toUpperCase() + " " + userInformation?.last_name?.toUpperCase()}
										</div>
									</div>
									<div className="bet-user-label">
										ACCOUNT BALANCE
										<div className="bet-user-name">
											{userInformation?.currency} {ThousandSeperated(userInformation?.mainBalance)}
										</div>
									</div>
									<div className="bet-user-label">
										FREE BET CREDITS
										<div className="bet-user-name">
											{userInformation?.currency} {ThousandSeperated(userInformation?.freeBetCredits)}
											<span className="ps-1">
												<AddFreeCashLogo />
											</span>
										</div>
									</div>
									<div className="bet-user-label">
										WITHDRAWAL BALANCE
										<div className="bet-user-name">
											{userInformation?.currency} {ThousandSeperated(userInformation?.withdrawalBalance)}
										</div>
									</div>
									<div className="bet-user-label">
										AVERAGE STAKE
										<div className="bet-user-name">
											{userInformation?.currency} {ThousandSeperated(userInformation?.avgStake)}
										</div>
									</div>
									<div className="bet-user-label">
										TOTAL BETS
										<div className="bet-user-name">{userInformation?.totalBets}</div>
									</div>
									<div
										className={
											userInformation?.profitLoss && Number(userInformation?.profitLoss) > 0
												? "bet-user-label bet-user-label-green"
												: Number(userInformation?.profitLoss) < 0
												? "bet-user-label bet-user-label-red"
												: "bet-user-label bet-user-label-blue"
										}
									>
										PROFIT / LOSS
										<div className="bet-user-name bet-user-values">
											{userInformation?.currency} {ThousandSeperated(userInformation?.profitLoss)}
										</div>
									</div>
									<div
										className={
											userInformation?.lifetimePosition && Number(userInformation?.lifetimePosition) > 0
												? "bet-user-label bet-user-label-green"
												: Number(userInformation?.lifetimePosition) < 0
												? "bet-user-label bet-user-label-red"
												: "bet-user-label bet-user-label-blue"
										}
									>
										LIFETIME POSITION
										<div className="bet-user-name bet-user-values">{ThousandSeperated(userInformation?.lifetimePosition)} %</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="bet-referral-modal">
						<div className="bet-referral-header-block">
							<div className="bet-referral-block-title">
								bet referral
								<img className="bet-referral-block-close" width={20} height={20} src={CloseIconWhite} alt="close" onClick={closeBetReferralHandler} />
							</div>
							<button className="bet-referral-block-assigned" onClick={toggleDropdown} disabled={!!SelectedBetReferral.trader_id || isUserReadOnly}>
								ASSIGNED TO:
								<div className="assign-item-dropdown">{SelectedBetReferral.trader_name || ""}</div>
								<LightArrowDownIcon />
							</button>
							{SelectedBetReferral.trader_id === user.id && (
								<button className="unassign-btn" onClick={e => handleChangeTrader(e, 0)}>
									UNASSIGN BET
								</button>
							)}

							<div className={`assign-dropdown-menu ${isDropdownOpen ? "open" : ""}`}>
								{affiliateUsers.map(user => {
									const userName = user.first_name + " " + user.last_name;
									return (
										<div key={user.id} className="assign-dropdown-item" onClick={e => handleChangeTrader(e, user.id, userName)}>
											{userName}
										</div>
									);
								})}
							</div>
						</div>

						<div className={`bet-referral-scroll-area ${isUserReadOnly && "pe-none"}`}>
							<div className="bet-responsive-container">
								{SelectedBetReferral?.player_id} - {SelectedBetReferral?.player_name}
								<div className="bet-info-icon">
									<InfoIconGeneral onClick={() => setPlayerDetails(true)} />
								</div>
							</div>

							<form ref={formRef}>
								<div className="bet-referral-list">
									{betSlip?.singles?.map(itm => (
										<BetReferralItem
											itm={itm}
											key={itm.bet_id}
											disabled={disabled}
											useFreebet={SelectedBetReferralFreeBet}
											initialBetReferral={initialBetReferral && JSON.parse(initialBetReferral)}
											setHasChanged={setHasChanged}
											sportLadders={sportsLadders[itm?.sport_slug]}
										/>
									))}
								</div>
								{SelectedBetReferral.bet_slip?.singles.length > 1 && (
									<AllSingles disabled={disabled} useFreebet={SelectedBetReferralFreeBet} setHasChanged={setHasChanged} />
								)}

								{SelectedBetReferral.bet_slip?.combinations.length > 0 &&
									SelectedBetReferral.bet_slip?.combinations.map(item => (
										<BetReferralCombination
											item={item}
											key={item.type}
											disabled={disabled}
											useFreebet={SelectedBetReferralFreeBet}
											setHasChanged={setHasChanged}
										/>
									))}
							</form>
							<div className="bet-referral-total-wrapper bet-referral-total-stake">
								<div>User Balance:</div>
								<div>
									{userInformation?.currency} {ThousandSeperated(userInformation?.mainBalance)}
								</div>
							</div>
							<div className="bet-referral-total-wrapper bet-referral-total-stake">
								<div>Total Stake:</div>
								<div>
									{userInformation?.currency} {ThousandSeperated(totalStakes)}
								</div>
							</div>
							<div className="bet-referral-total-wrapper bet-referral-total">
								<div>Total Returns:</div>
								<div>
									{userInformation?.currency} {ThousandSeperated(totalPayouts)}
								</div>
							</div>
							<div className="bet-referral-btn-wrapper">
								<div className="bet-referral-btn-left">
									<BetReferralBtn disabled={disabled} name="reset" onClick={resetBtnHandler} classProps="referral-btn-no-filled" />
									<BetReferralBtn disabled={disabled} name="reject" onClick={rejectBtnHandler} classProps="referral-btn-reject" />
								</div>
								<div className="bet-referral-btn-right">
									<BetReferralBtn disabled={disabled} name="offer" onClick={offerBtnHandler} classProps="referral-btn-no-filled" />
									<BetReferralBtn disabled={disabled || hasChanged} name="approve" onClick={acceptBtnHandler} classProps="referral-btn-accept" />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default betReferralModal;
