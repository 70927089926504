import React from "react";
import { casinoTypes } from "utils/constants";

import CasinoPageLayout from "./casinoPageLayout";

import "./casinoGames.scss";

const VirtualCasino = () => {
	const type = casinoTypes.VIRTUAL_CASINO;

	return <CasinoPageLayout type={type} title="Virtual Casino" />;
};

export default VirtualCasino;
