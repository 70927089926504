export const transformRow = row => {
	if (row?.value && row?.selected) {
		switch (row.value) {
			case "init":
				row.value = "Started";
				break;
			case "pending":
				row.value = "Pending";
				break;
			case "suspended":
				row.value = "Suspended";
				break;
			case "rejected":
				row.value = "Rejected";
				break;
			case "verified":
				row.value = "Verified";
				break;
			case "not_started":
				row.value = "Not Started";
				break;
			case "processed":
				row.value = "Completed";
				break;
			default:
				switch (row.page) {
					case "competitions":
						switch (row.value) {
							case "0":
								row.value = "Inactive";
								break;
							case "1":
								row.value = "Active";
								break;
							default:
								break;
						}
						break;
					case "markets":
						switch (row.value) {
							case "0":
								row.value = "Inactive";
								break;
							case "1":
								row.value = "Active";
								break;
							default:
								break;
						}
						break;
					case "financial":
						switch (row.value) {
							case "user_balance_adjustment":
								row.value = "User Balance Adjustment";
								break;
							case "add_bonus":
								row.value = "Add bonus balance";
								break;
							case "add_free_bet":
								row.value = "Add balance for free bet";
								break;
							case "subtract_free_bet":
								row.value = "Subtract balance for free bet";
								break;
							case "subtract_bonus":
								row.value = "Subtract bonus balance";
								break;
							case "deposit_hexopay_credit_card":
								row.value = "Hexopay deposit with card";
								break;
							case "withdrawal_paysafe":
								row.value = "Paysafe Withdrawal";
								break;
							case "bet_cancel":
								row.value = "Bet Cancel";
								break;
							case "bet_undo":
								row.value = "Bet Undo";
								break;
							case "bet_place":
								row.value = "Bet Place";
								break;
							case "bet_win":
								row.value = "Bet Win";
								break;
							case "bet_pushed":
								row.value = "Bet Pushed";
								break;
							case "deposit_apple_pay":
								row.value = "Apple Pay Deposit";
								break;
							case "deposit_google_pay":
								row.value = "Google Pay Deposit";
								break;
							case "deposit_interac":
								row.value = "Interac Deposit";
								break;
							default:
								switch (row.key) {
									case "exclude_test_users":
										switch (row.value) {
											case "0":
												row.value = "Yes";
												break;
											case "1":
												row.value = "No";
												break;
											default:
												break;
										}
										break;
									default:
										break;
								}
								break;
						}
						break;
					case "users":
					case "bets":
						switch (row.value) {
							case "test_user":
								row.value = "Test Account";
								break;
							case "real_user":
								row.value = "Non Test Account";
								break;
							default:
								break;
						}
						break;
					case "notification":
						switch (row.key) {
							case "status":
								switch (row.value) {
									case "0":
										row.value = "Not Finished";
										break;
									case "1":
										row.value = "Finished";
										break;
									default:
										break;
								}
								break;
							case "stopped":
								switch (row.value) {
									case "0":
										row.value = "On Going";
										break;
									case "1":
										row.value = "Stopped";
										break;
									default:
										break;
								}
								break;
							default:
								break;
						}
						break;
					default:
						break;
				}
				break;
		}

		return {
			name: row?.name,
			value: row?.value,
		};
	}

	return null;
};
