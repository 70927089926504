import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import SearchFilter from "components/SearchFilter/searchFilter";
import { getCmsUserActivityTableColumns } from "./cmsUserActivityTableColumns";
import { getCmsUserActivityFilterColumns } from "./cmsUserActivityFilterColumns";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { _breadcrumbItems } from "utils/constants";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { capitalizeText } from "services/capitalizeText";
import { apiUrls } from "utils/const.apiUrl";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { CmsUserActivityModal } from "./CmsUserActivityModal";

const CMSUserActivity = props => {
	// const cmsActivityLogFilterData = useSelector(state => state.Login.searchFilterData.cms_activity_log);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowPerPage, setRowPerPage] = useState(50);
	const [records, setRecords] = useState([]);
	const [cmsUserActivityModal, setCmsUserActivityModal] = useState({ show: false, content: "" });
	useEffect(() => {
		props.setBreadcrumbItems("CMS Users Activity", _breadcrumbItems.cms_activity_log);
	});

	const filterCMSActivity = cmsActivityLogFilterData => {
		var date_from, date_to;

		if (cmsActivityLogFilterData.date) {
			var date = cmsActivityLogFilterData.date;
			let start_time, end_time;

			if (date.indexOf("&") > -1) {
				date = date.split("&");
				start_time = moment(date[0].trim(), "YYYY-MM-DD HH:mm:ss").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(date[1].trim(), "YYYY-MM-DD HH:mm:ss").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			} else {
				start_time = moment.utc().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment.utc().format("YYYY-MM-DD 23:59:59");
			}

			date_from = `${start_time}`;
			date_to = `${end_time}`;
		}

		let params = `?date_from=${date_from}&date_to=${date_to}`;

		if (cmsActivityLogFilterData.user_id) params += `&user_id=${cmsActivityLogFilterData.user_id}`;

		getCMSActivity(params);
	};

	const getCMSActivity = params => {
		let url = params ? apiUrls.cms_activity + params : apiUrls.cms_activity;
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				let data = response?.data;
				let newData = [];
				data.map(row => {
					let obj = {
						id: row?.id,
						datetime: row.createdAt ? moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss") : "",
						username: capitalizeText(row?.cms_users_first_name) + " " + capitalizeText(row?.cms_users_last_name),
						type: row?.type,
						method: row?.method,
						description: row?.description,
						parameters: row?.parameters,
					};
					newData.push(obj);
				});
				setRecords(newData);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const [userList, setUserList] = useState([]);

	const getUserListData = async () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.new_main + "/api/v1/auth/user-list", resolve, reject, true);
		}).then(response => {
			if (response) {
				let data = response.data[0];

				var userArray = data.map(user => ({ name: capitalizeText(`${user?.first_name} ${user?.last_name}`) + " - " + user.id, value: user.id }));
				setUserList(userArray);
			}
		});
	};

	useEffect(() => {
		getUserListData();
	}, []);

	let cmsUserActivityTableColumns = getCmsUserActivityTableColumns({ records, setCmsUserActivityModal });
	let cmsUserActivityFilterColumns = getCmsUserActivityFilterColumns({ userList });
	let page_name = "CMS Users Activity";

	return (
		<>
			<HelmetLayout titleProps={"CMS Users Activity"}>
				<div className="cms-page pt-0">
					<div className="col-12 d-flex justify-content-between">
						<SearchFilter
							columns={cmsUserActivityFilterColumns}
							page={"cms_activity_log"}
							preSelectedType={"date"}
							preSelectedValue={"date"}
							preSelectedName={"Date from-to"}
							filterHandler={filterCMSActivity}
							filterLoading={isDataLoading}
						/>
					</div>
					<MUIDataTable
						columns={cmsUserActivityTableColumns}
						data={records}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							elevation: 0,
							onChangePage(page) {
								setCurrentPage(page);
							},
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							onChangeRowsPerPage(number) {
								setRowPerPage(number);
							},
							page: currentPage,
							responsive: tableResponsive,
							rowsPerPage: rowPerPage,
							rowsPerPageOptions: tableRowsPerPage,
							downloadOptions: { filename: `${page_name}.csv` },
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
			</HelmetLayout>
			{cmsUserActivityModal?.show && <CmsUserActivityModal setData={setCmsUserActivityModal} data={cmsUserActivityModal} title="Preview Activity Log" />}
		</>
	);
};
export default connect(null, { setBreadcrumbItems })(CMSUserActivity);
