import React from "react";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import useWindowSize from "hooks/useWindowSize";
import { breakpoints } from "utils/constants";
// import { SimpleGoBackArrow } from "utils/icons";

export const CustomerModal = props => {
	const { row, data, setData, openModal, onSave, onClose, children, handlerOpenStakeFactorData, isLoading, handleDiscard } = props;
	const { id, name, fields = [], buttonType = "", dataChanged, setDataChanged } = row;

	const { width } = useWindowSize();

	const isMobile = width <= breakpoints.PHONE;
	const buttonName = width > breakpoints.PHONE ? "Save" : buttonType === "" ? "Save" : "Edit";

	const handlerClick = () => {
		if (isMobile && buttonType) {
			handlerOpenStakeFactorData(buttonType);
		} else {
			onSave();
		}
	};
	const handlerCLose = () => {
		setDataChanged(false);
		onClose();
	};

	const dependecies = { phone_number_verified: data?.phone_number_verified, email_verified: data?.email_verified };
	return (
		<div className={`open-flelds ${id === openModal.id && openModal.className} `}>
			<TopBarComponent
				classname="special_top_component"
				loading={isLoading}
				saveHandler={() => handlerClick()}
				discardHandler={() => handleDiscard()}
				dataChanged={isMobile && buttonType ? true : dataChanged}
				onClose={handlerCLose}
				name={buttonName}
			/>
			<div className="modal-container">
				<p>{name}</p>
			</div>

			{fields.length > 0 && (
				<div className="modal-body">
					<AllComponentsLoop fields={fields} data={data} setData={setData} setDataChanged={setDataChanged} dependecies={dependecies} />
				</div>
			)}

			{children && children}
		</div>
	);
};
