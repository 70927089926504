import React from "react";
import { DragAndDropFillIcon, DregAndDropIcone, MoveUpToTopDisabledIcone, MoveUpToTopIcone } from "utils/icons";

export const getSportCountriesTableColumns = props => {
	const { changeOrder } = props;
	return [
		{
			name: "code",
			label: "Code",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
		},
		{
			name: "name",
			label: "Name",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta, other) => {
					let { index, dragHandleProps, isDragging } = other;

					return (
						<div className="d-flex justify-content-end">
							<div className="heroBanners-buttons">
								{index === 0 ? <MoveUpToTopDisabledIcone /> : <MoveUpToTopIcone onClick={() => changeOrder(index)} />}

								<div
									{...dragHandleProps}
									onMouseDown={(...args) => {
										dragHandleProps?.onDragStart(...args);
									}}
								>
									<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
								</div>
							</div>
						</div>
					);
				},
			},
		},
	];
};
