import React, { useEffect, useState } from "react";
import { getSisSportCompetitionsTableColumns } from "./SisSportCompetitionsTableColumns";
import { getSportCompetitionsTableColumns } from "./SportCompetitionsTableColumns";
import { EditCompetition } from "../editCompetition";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { SaveLightButton, SaveUnchangedButton } from "components/GeneralComponents/AddButton";
// import { ConfirmOrderItems } from "components/GeneralComponents/ConfirmOrderItems";
// import MUIDataTable from "mui-datatables";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import DataTable from "components/GeneralComponents/Table";
import { filterAndSortArray } from "utils/global";

export const SportCompetitions = props => {
	const { sport, isUserReadOnly } = props;
	const sisProvider = sport?.slug === "horseracing" || sport?.slug === "greyhoundracing";

	const [data, setData] = useState();
	const [unfilteredData, setUnfilteredData] = useState();
	const [editCompetition, setEditCompetition] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [disableOrder, setDisableOrder] = useState(false);
	const [existFilter, setExistFilter] = useState(false);
	// const [confirmOrder, setConfirmOrder] = useState(false);

	const getSportCompetition = type => {
		setIsLoading("data");
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_SPORT_COMPETITION + `?slug=${sport?.slug}`, resolve, reject, true);
		})
			.then(response => {
				let data = response?.data;
				if (data && sisProvider) {
					data = filterAndSortArray(data, "country_name");
				}
				setData(data);
				setUnfilteredData(data);
				if (type === "save_edit") setExistFilter(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const changeOrder = index => {
		const selectedRow = unfilteredData.splice(index, 1)[0];
		const newData = [selectedRow, ...unfilteredData];
		setData(newData);
		setUnfilteredData(newData);
		setDataChanged(true);
	};

	useEffect(() => {
		if (isUserReadOnly) setDisableOrder(true);
	}, [isUserReadOnly]);

	const handlerSaveOrder = () => {
		let payload = {
			order: data.map((row, index) => ({ id: row.competition_id, order: index })),
			sport_slug: sport?.slug,
		};

		setIsLoading("order");
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.ORDER_SPORT_COMPETITION, resolve, reject, payload, true);
		})
			.then(() => {})
			.finally(() => {
				setDataChanged(false);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (sport) {
			getSportCompetition();
		}
	}, []);

	const handleFavoriteCompetition = (competition_id, expand) => {
		const body = { competition_id, expand: expand ? 1 : 0 };

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.FAVORITE_SPORT_COMPETITION, resolve, reject, body, true);
		}).then(() => {
			// let actualEventMarkets = [...eventMarkets];
			// actualEventMarkets.forEach(row => {
			// 	if (row.id === id) {
			// 		if (row?.sport_widget_default === 0) {
			// 			row.sport_widget_default = 1;
			// 		} else {
			// 			row.sport_widget_default = 0;
			// 		}
			// 	}
			// });
			// setEventMarkets(actualEventMarkets);

			const newData = data.map(item => (item.competition_id == competition_id ? { ...item, favorite: expand ? 1 : 0 } : item));
			setData(newData);
			setUnfilteredData(newData);
		});
	};

	const columns = sisProvider
		? getSisSportCompetitionsTableColumns({ changeOrder, setEditCompetition, isUserReadOnly })
		: getSportCompetitionsTableColumns({ setEditCompetition, changeOrder, handleFavoriteCompetition, disableOrder, isUserReadOnly });

	return (
		<div>
			<div className="sport-tiers-filter">
				{/* <div className="tiers-autocomplete"></div> */}
				<SingleSearchFilter
					setData={newData => {
						if (newData?.length == unfilteredData?.length && !isUserReadOnly) setDisableOrder(false);
						else setDisableOrder(true);
						setData(newData);
					}}
					unfilteredData={unfilteredData}
					filterColumn={["competition_name"]}
					existFilter={existFilter}
					setExistFilter={setExistFilter}
					noSpace
				/>
				<div>
					{!sisProvider ? (
						dataChanged ? (
							<SaveLightButton name={"Save Order"} onClick={handlerSaveOrder} loading={isLoading === "order"} />
						) : (
							<SaveUnchangedButton text={"Save Order"} />
						)
					) : (
						""
					)}
				</div>
			</div>

			<DataTable
				data={data}
				columns={columns}
				setData={newData => {
					setData(newData);
					setUnfilteredData(newData);
					setDataChanged(true);
				}}
				isLoading={isLoading === "data"}
				pagination={false}
				orderRows={!sisProvider}
				sort={false}
			/>

			{editCompetition && (
				<EditCompetition
					competition={editCompetition}
					getSportCompetition={getSportCompetition}
					setEditCompetition={setEditCompetition}
					sport_slug={sport.slug}
					sisProvider={sisProvider}
				/>
			)}
			{/* {confirmOrder && <ConfirmOrderItems show={confirmOrder} setShow={setConfirmOrder} handler={confirmOrderHandler} loading={orderData?.loading} />} */}
		</div>
	);
};
