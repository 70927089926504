import React, { useEffect, useState } from "react";
import { LoadingSaveButton, NoButton, YesButton } from "components/GeneralComponents/AddButton";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { SingleSearchFilter } from "../../../components/GeneralComponents/CustomInputs";
import { getFooterLinksColumns } from "./footerLinksColumns";
import { FooterLinksModal } from "./footerLinksModal";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import DataTable from "components/GeneralComponents/Table";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const FooterLinks = () => {
	const [pageContentData, setPageContentData] = useState(null);
	const [showPromotion, setShowPromotion] = useState(false);
	const [editType, setEditType] = useState("");
	const [title, setTitle] = useState("");
	const [footerData, setFooterData] = useState([]);
	const [item, setItem] = useState(null);
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const getPageContent = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.page_content, resolve, reject, true);
		}).then(response => {
			let data = response?.data;
			setPageContentData(data);
		});
	};

	const getGroupedLinks = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.footer_links, resolve, reject, true);
		})
			.then(res => {
				let data = res.data;
				let records = [];
				data.forEach(row => {
					let obj = {};
					if (row.type === "images") {
						obj = {
							component: "Images",
							title: row.title,
							type: "images",
							id: row.id,
							order_nr: row.order_nr,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					} else if (row.type === "text") {
						obj = {
							component: "Text",
							title: "None",
							type: "text",
							id: row.id,
							order_nr: row.order_nr,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					} else if (row.type === "seal") {
						obj = {
							component: "Seal",
							title: "Disclaimer",
							type: "seal",
							id: row.id,
							order_nr: row.order_nr,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					} else {
						obj = {
							component: "Column",
							title: row.title,
							type: row.type,
							id: row.id,
							order_nr: row.order_nr,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					}
					records.push(obj);
				});
				const footerSortingData = [...records].sort((a, b) => a.order_nr - b.order_nr);
				setUnfilteredData(footerSortingData);
				setFooterData(footerSortingData);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const pageHeaderData = {
		international: true,
		addNew: false,
		addNewClick: () => {
			setShowPromotion(true);
			setItem(null);
		},
	};

	const deleteFooterLink = () => {
		setDeleteLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.delete_footer_links + "/" + deleteItem?.id, resolve, reject, true);
		})
			.then(response => {
				let data = response.data;
				// const numAscending = [...data].sort((a, b) => a.order_nr - b.order_nr);
				let records = [];
				data.forEach(row => {
					let obj = {};
					if (row.type === "images") {
						obj = {
							component: "Images",
							title: row.title,
							type: "images",
							id: row.id,
							order_nr: row.order_nr,
							value: row.value,
							details: row.details,
							enabled: row.enabled,
						};
					} else if (row.type === "text") {
						obj = {
							component: "Text",
							title: "None",
							type: "text",
							id: row.id,
							value: row.value,
							details: row.details,
							order_nr: row.order_nr,
							enabled: row.enabled,
						};
					} else if (row.type === "seal") {
						obj = {
							component: "Seal",
							title: "Disclaimer",
							type: "seal",
							id: row.id,
							value: row.value,
							order_nr: row.order_nr,
							details: row.details,
							enabled: row.enabled,
						};
					} else {
						obj = {
							component: "Column",
							title: row.title,
							type: row.type,
							id: row.id,
							value: row.value,
							details: row.details,
							order_nr: row.order_nr,
							enabled: row.enabled,
						};
					}
					records.push(obj);
				});
				const footerSortingData = [...records].sort((a, b) => a.order_nr - b.order_nr);
				setFooterData(footerSortingData);
				setDeleteItem(null);
			})
			.finally(() => {
				setDeleteLoading(false);
			});
	};

	useEffect(() => {
		getGroupedLinks();
		getPageContent();
	}, []);

	const changeOrder = index => {
		const selectedRow = unfilteredData.splice(index, 1)[0];
		const newData = [selectedRow, ...unfilteredData];
		setNewData(newData);
	};
	const orderItems = newOrder => {
		let payload = {
			order: newOrder.map((row, index) => ({ id: row.id, order: index })),
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.FOOTER_ORDER, resolve, reject, payload, true);
		}).then(() => {});
	};
	const setNewData = newData => {
		setFooterData(newData);
		setUnfilteredData(newData);
		orderItems(newData);
	};
	const handleEditItem = row => {
		setTitle(row.title);
		setEditType(row.type);
		setShowPromotion(true);
		setItem(row);
	};
	const footerLinksColumns = getFooterLinksColumns({ setDeleteItem, changeOrder, handleEditItem });

	return (
		<HelmetLayout titleProps={"Footer"}>
			<div className="cms-page pt-0">
				<SingleSearchFilter setData={setFooterData} unfilteredData={unfilteredData} filterColumn={["component", "title"]} />
				<DataTable
					data={footerData}
					columns={footerLinksColumns}
					setData={newData => {
						setNewData(newData);
					}}
					options={{
						showButtons: true,
						search: false,
						viewColumns: false,
						customToolbar: () => <AddTableButton className={"data-table-add-btn"} onClick={pageHeaderData["addNewClick"]} />,
					}}
					isLoading={isDataLoading}
					pagination={false}
					orderRows={true}
					sort={false}
				/>
			</div>
			{showPromotion && (
				<FooterLinksModal
					type={editType}
					title={title}
					item={item}
					handler={() => {
						setEditType("");
						setShowPromotion(false);
						setItem(null);
					}}
					setFooterData={setFooterData}
					footerData={footerData}
					setUnfilteredData={setUnfilteredData}
					pageContentData={pageContentData}
				/>
			)}
			{deleteItem && (
				<SweetAlert title={"Confirm delete of link"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
					<div style={{ position: "relative" }}>
						<div className="">
							<span className="delete-span">Are you sure you want to delete this link &quot;{deleteItem?.title}&quot; ?</span>
						</div>
					</div>
					<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
						<NoButton onClick={() => setDeleteItem(null)} name="No" />
						{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={deleteFooterLink} />}
					</div>
				</SweetAlert>
			)}
		</HelmetLayout>
	);
};

export default FooterLinks;
