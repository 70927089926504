import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner } from "react-bootstrap";
import {
	MoveDownButton,
	MoveDownDisabledButton,
	MoveUpButton,
	MoveUpDisabledButton,
	NoButton,
	PlusAddButton,
	YesButton,
} from "components/GeneralComponents/AddButton";
import { DeleteIcon, XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { Autocomplete, TextField } from "@mui/material";
import { toast } from "react-toastify";
import xIcon from "../../../assets/images/x-icon.svg";
import { getCompetitionsDropdown } from "services/getCompetitionsDropdown";
import { AvForm } from "availity-reactstrap-validation";

export const ShowEditDefaultLeagues = ({
	setShowEditModal,
	dataChanged,
	setDataChanged,
	editClicked,
	countries,
	setRecords,
	editId,
	sportsData,
	isUserReadOnly,
}) => {
	const [loading, setLoading] = useState(null);
	const [allCompetitions, setAllCompetitions] = useState([]);
	const [defaultLeagueData, setDefaultLeagueData] = useState({
		country: "",
		sport_id: "",
		selectedLeagues: [],
		selectedCountry: null,
		selectedSport: null,
	});
	const [deleteId, setDeleteId] = useState(null);
	const [leaguesLoading, setLeaguesLoading] = useState(false);

	const handleDataChanged = () => {
		setDataChanged(true);
	};

	const getAllLeagues = slug => {
		setLeaguesLoading(true);
		getCompetitionsDropdown(slug)
			.then(response => {
				if (response.length > 0) {
					if (editId) {
						let selectedLeagues = [];
						let selected = [];
						if (editId?.league_id) {
							if (editId?.league_id.indexOf(",") > -1) {
								selectedLeagues = editId?.league_id.split(",");
							} else {
								selectedLeagues = [editId?.league_id];
							}
							selectedLeagues.map(row => {
								response.map((row2, index) => {
									if (row?.toString() === row2?.genius_id?.toString()) {
										selected.push(row2);
										response.splice(index, 1);
									}
								});
							});
							setDefaultLeagueData({
								...defaultLeagueData,
								country: editId?.region_id,
								sport_id: editId?.sport_id,
								selectedCountry: countries.find(row => row.cca2 === editId?.region_id),
								selectedSport: sportsData.find(row => row.slug === editId?.sport_id),
								selectedLeagues: selected,
							});
							setAllCompetitions(response);
						}
					} else {
						setAllCompetitions(response);
					}
				}
			})
			.finally(() => {
				setLeaguesLoading(false);
			});
	};

	const handleAddLeague = row => {
		let actualLeagues = [...defaultLeagueData.selectedLeagues];
		let allLeagues = [...allCompetitions];
		allLeagues = allLeagues.filter(row2 => row2.id !== row.id);
		actualLeagues.push(row);
		setDefaultLeagueData({
			...defaultLeagueData,
			selectedLeagues: actualLeagues,
		});
		setAllCompetitions(allLeagues);
		handleDataChanged();
	};

	const handleDeleteLeague = () => {
		let allLeagues = [...allCompetitions];
		let actualLeagues = [...defaultLeagueData.selectedLeagues];
		allLeagues = [...allLeagues, deleteId];
		actualLeagues = actualLeagues.filter(row => row.id !== deleteId.id);
		setDefaultLeagueData({
			...defaultLeagueData,
			selectedLeagues: actualLeagues,
		});
		setAllCompetitions(allLeagues);
		setDeleteId(null);
		setDataChanged(true);
	};

	const changeOrder = (index, direction) => {
		setDefaultLeagueData({
			...defaultLeagueData,
			selectedLeagues: reorderArray(
				{
					oldIndex: index,
					newIndex: index + (direction === "UP" ? -1 : 1),
				},
				defaultLeagueData?.selectedLeagues
			),
		});
	};

	const reorderArray = (event, originalArray) => {
		let payload = [];
		const movedItem = originalArray.find((item, index) => index === event.oldIndex);
		const remainingItems = originalArray.filter((item, index) => index !== event.oldIndex);

		const reorderedItems = [...remainingItems.slice(0, event.newIndex), movedItem, ...remainingItems.slice(event.newIndex)];
		reorderedItems.forEach((row, index) => {
			payload.push({ id: row.id, order: index });
		});
		setDataChanged(true);
		return reorderedItems;
	};

	const confirmAddSave = () => {
		let competitions_ids = [];
		defaultLeagueData.selectedLeagues.forEach(row => {
			competitions_ids.push(row.genius_id);
		});
		let payload = {
			sport_slug: defaultLeagueData.sport_id,
			competitions: competitions_ids,
			region_id: defaultLeagueData.country,
			country: "",
		};

		if (payload.region_id !== 0 && !payload.region_id) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select country</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!payload.sport_slug) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select sport</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (payload.competitions.length === 0) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select leaguese</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.default_league_add, resolve, reject, payload, true);
		})
			.then(response => {
				let data = response.data;
				let newData = [];
				data.map(row => {
					var regionName = row.region_id != 0 ? row.region_name : "All Countries";
					let obj = {
						id: row?.id,
						competition_name: row.competition_names,
						sport_type: row?.sport_type == "soccer" ? "Football" : row?.sport_type === "football" ? "American Football" : row?.sport_type,
						region_name: regionName,
						ord: row.ord,
						sport_id: row.sport_id,
						region_id: row.region_id,
						league_id: row.league_id,
					};
					newData.push(obj);
				});
				setRecords(newData);
			})
			.finally(() => {
				setLoading(false);
				setShowEditModal(false);
				setDataChanged(false);
			});
	};

	const confirmEditSave = () => {
		let competitions_ids = [];
		defaultLeagueData.selectedLeagues.forEach(row => {
			competitions_ids.push(row.genius_id);
		});
		let payload = {
			sport_slug: defaultLeagueData.sport_id,
			competitions: competitions_ids,
			region_id: defaultLeagueData.country,
			country: "",
			id: editId?.id,
		};

		if (payload.region_id !== 0 && !payload.region_id) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select country</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (!payload.sport_slug) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select sport</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}
		if (payload.competitions.length === 0) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please select leaguese</p>
				</>,
				{ className: "custom-toast" }
			);
			return;
		}

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.default_league_edit, resolve, reject, payload, true);
		})
			.then(response => {
				setDataChanged(false);
				let data = response.data;
				let newData = [];
				data.map(row => {
					var regionName = row.region_id != 0 ? row.region_name : "All Countries";
					let obj = {
						id: row?.id,
						competition_name: row.competition_names,
						sport_type: row?.sport_type == "soccer" ? "Football" : row?.sport_type === "football" ? "American Football" : row?.sport_type,
						region_name: regionName,
						ord: row.ord,
						sport_id: row.sport_id,
						region_id: row.region_id,
						league_id: row.league_id,
					};
					newData.push(obj);
				});
				setRecords(newData);
			})
			.finally(() => {
				setLoading(false);
				setShowEditModal(false);
			});
	};

	useEffect(() => {
		if (!countries.some(row => row.name === "All Countries")) {
			countries.unshift({ name: "All Countries", cca2: 0 });
		}
		if (editId) {
			getAllLeagues(editId?.sport_id);
			setDefaultLeagueData({
				...defaultLeagueData,
				country: editId?.region_id,
				sport_id: editId?.sport_id,
				selectedCountry: countries.find(row => row.cca2 === editId?.region_id),
				selectedSport: sportsData.find(row => row.slug === editId?.sport_id),
			});
		}
	}, []);

	return (
		<>
			{editClicked && (
				<div className="promotion-container">
					<div className="promotion-header">
						<span>Edit Default Leagues</span>
						<XCloseIcon onClick={() => setShowEditModal(false)} className={"xclose-icon"} />
					</div>
					<div className="promotion-body">
						<TopBarComponent
							saveHandler={() => {
								editId ? confirmEditSave() : confirmAddSave();
							}}
							dataChanged={dataChanged}
							loading={loading}
							onClose={() => setShowEditModal(false)}
						/>
						<div className="promotion-elements row m-0">
							<div className="promotion-left col-12 col-lg-6 promotion-inputs">
								<AvForm>
									<div className="row mb-3">
										<div className="col-4 d-flex align-items-center">Country</div>
										<div className="col-8 dropdown-wrapper">
											<Autocomplete
												disabled={editId ? true : false}
												disablePortal
												className={
													editId ? " autocomplete-select input-arrow disabled-autocomplete" : "autocomplete-select input-arrow"
												}
												options={countries}
												getOptionLabel={option => option.name}
												value={
													editId?.region_id
														? countries.find(row => row.cca2 === editId?.region_id)
														: defaultLeagueData?.selectedCountry
												}
												sx={{ width: 300 }}
												renderInput={params => <TextField placeholder={"All Countries"} {...params} />}
												onChange={(event, newValue, reason) => {
													if (reason === "clear") {
														setDefaultLeagueData({
															...defaultLeagueData,
															country: "",
															selectedCountry: null,
														});
													} else {
														setDefaultLeagueData({
															...defaultLeagueData,
															country: newValue.cca2,
															selectedCountry: newValue,
														});
													}
												}}
											/>
										</div>
									</div>
									<div className="row mb-3">
										<div className="col-4">Sport</div>
										<div className="col-8 dropdown-wrapper">
											<Autocomplete
												disabled={editId ? true : false}
												disablePortal
												className={
													editId ? " autocomplete-select input-arrow disabled-autocomplete" : "autocomplete-select input-arrow"
												}
												options={sportsData}
												getOptionLabel={option => option.name}
												value={
													editId?.sport_id ? sportsData.find(row => row.slug === editId?.sport_id) : defaultLeagueData?.selectedSport
												}
												sx={{ width: 300 }}
												renderInput={params => <TextField placeholder={"Select Sport"} {...params} />}
												onChange={(event, newValue, reason) => {
													if (reason === "clear") {
														setDefaultLeagueData({
															...defaultLeagueData,
															sport_id: "",
															selectedSport: null,
														});
													} else {
														getAllLeagues(newValue.slug);
														setDefaultLeagueData({
															...defaultLeagueData,
															sport_id: newValue.slug,
															selectedSport: newValue,
														});
													}
												}}
											/>
										</div>
									</div>
								</AvForm>
								<div className="row">
									<div className="col-4">Leagues</div>
									<div className="col-8 sports-list">
										{defaultLeagueData?.selectedLeagues.map((row, index) => {
											return (
												<div className={`laegues-item ${isUserReadOnly && "pe-none"}`} key={index}>
													<span className="pe-3">{row?.name}</span>
													<div className="element-end">
														{index === 0 ? (
															<MoveUpDisabledButton className={"btn-pen-edit"} />
														) : (
															<MoveUpButton className={"btn-pen-edit"} onClick={() => changeOrder(index, "UP")} />
														)}
														{index + 1 === defaultLeagueData?.selectedLeagues.length ? (
															<MoveDownDisabledButton className={"btn-pen-edit"} />
														) : (
															<MoveDownButton className={"btn-pen-edit"} onClick={() => changeOrder(index, "DOWN")} />
														)}
														<DeleteIcon className="casino-icon no-margin" onClick={() => setDeleteId(row)} />
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
							<div className="promotion-right col-12 col-lg-6 ">
								<div className="d-flex">
									<div className="col-12 col-lg-4 mb-auto align-items-center input-title d-flex">All Leagues</div>
									{leaguesLoading ? (
										<Spinner animation="border" variant="primary" />
									) : (
										<div className="sports-list">
											{allCompetitions
												.sort((a, b) => {
													return a.name.trim().toLowerCase() < b.name.trim().toLowerCase() ? -1 : 1;
												})
												.map((row, index) => {
													return (
														<div className={`laegues-item ${isUserReadOnly && "pe-none"}`} key={index}>
															<div className="element-start">
																<div className="ms-2">
																	<span className="casino-element-name">{row?.name}</span>
																</div>
															</div>
															<div className="element-end">
																<PlusAddButton onClick={() => handleAddLeague(row)} loading={false} />
															</div>
														</div>
													);
												})}
										</div>
									)}
								</div>
							</div>
						</div>
						{deleteId?.id && (
							<SweetAlert title={"Confirm delete"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
								<div style={{ position: "relative" }}>
									<div className="">
										<span className="delete-span">Are you sure you want to delete &quot;{deleteId?.name}&quot; ?</span>
									</div>
								</div>
								<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
									<NoButton onClick={() => setDeleteId(null)} name="No" />
									<YesButton name="Yes" onClick={() => handleDeleteLeague()} />
								</div>
							</SweetAlert>
						)}
					</div>
				</div>
			)}
		</>
	);
};
