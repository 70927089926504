import {
	LoadingSaveButton,
	MediaCancelButton,
	MediaDeleteButton,
	MediaEditButton,
	MediaEditImages,
	MediaSelectAllButton,
	MediaUnSelectAllButton,
	NoButton,
	YesButton,
} from "components/GeneralComponents/AddButton";
import { PageHeader } from "components/VerticalLayout/PageHeader";
import React, { useEffect, useState, Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { TickIcon } from "utils/icons";
import { ImageEdit } from "./imageEdit";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { ImageLibrary } from "components/GeneralComponents/ImageLibrary";
import { getBase64 } from "hooks/General";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { getImages } from "services/getImages";
import { deleteImages } from "services/deleteImages";
import { getImageThumbnail, userReadOnly } from "utils/global";

const aws_s3_url = process.env.REACT_APP_S3_ASSET_URL;

import "./mediaLibrary.scss";
import { FileUploader } from "react-drag-drop-files";
import saveImages from "services/addMultipleImages";

const MediaLibrary = () => {
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [images, setImages] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [selectedIds, setSelectedIds] = useState([]);
	const [editImage, setEditImage] = useState(null);
	const [pageLayoutType, setPageLayoutType] = useState("seo");
	const [accept, setAccept] = useState("image/*");
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [imageLibrary, setImageLibrary] = useState(false);
	const [checkDelete, setCheckDelete] = useState(false);
	const [seoData, setSeoData] = useState({
		image: "",
	});
	const [imagesTitle, setImagesTitle] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);
	const fileTypes = ["JPG", "PNG", "GIF"];

	const pageHeaderData = {
		international: true,
		addNew: false,
		upload: true,
		uploadHandler: () => {},
		dropdownData: [
			{
				name: "Generic",
				value: "seo",
			},
			{
				name: "Banners",
				value: "banner",
			},
			{
				name: "Bet Slip",
				value: "bet_slip",
			},
			{
				name: "Carousel",
				value: "carousel",
			},
			{
				name: "Email",
				value: "email",
			},
			{
				name: "Logo",
				value: "logo",
				accept: "image/png",
			},
			{
				name: "Racing Widget",
				value: "racing_widget",
			},
			{
				name: "Sport Widget",
				value: "sport_widget",
			},
			{
				name: "Pages",
				value: "menu_links",
				accept: "image/svg+xml",
			},
		],
	};

	const handleAcceptedFiles = async acceptedFiles => {
		var file = await getBase64(acceptedFiles);
		setSeoData(file);
	};
	const handleDataChanged = () => {};

	const getAllImages = type => {
		setImageLibrary(false);

		getImages(type)
			.then(response => {
				setImages(response);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const selectAllImages = type => {
		if (type === "select") {
			let ids = [];
			images.forEach(row => {
				ids.push(row);
			});
			setSelectedIds(ids);
		} else {
			setSelectedIds([]);
		}
	};

	const handleDeleteImages = () => {
		let ids = [];
		let names = [];
		selectedIds.forEach(row => {
			ids.push(row.id);
			names.push(row.image);
		});
		let payload = { id: ids, action: "delete" };
		setDeleteLoading(true);
		deleteImages(payload)
			.then(() => {
				let newData = images.filter(row => !ids.includes(row.id));
				setImages(newData);
				setSelectedIds([]);
			})
			.finally(() => {
				setDeleteLoading(false);
				setShowDeleteConfirm(false);
			});
	};

	const handleCheckDelete = () => {
		setCheckDelete(true);
		let ids = [];
		selectedIds.forEach(row => {
			ids.push(row.id);
		});
		let payload = {
			id: ids,
			action: "check",
			type: pageLayoutType,
		};
		deleteImages(payload)
			.then(response => {
				let data = response?.data[0];
				setImagesTitle(data);
				setShowDeleteConfirm(true);
			})
			.finally(() => {
				setCheckDelete(false);
			});
	};

	useEffect(() => {
		getAllImages(pageLayoutType);
	}, []);

	useEffect(() => {
		setSelectedIds([]);
		setEditMode(false);
		getAllImages(pageLayoutType);
	}, [pageLayoutType]);

	const handleShowLibrary = () => {
		setImageLibrary(!imageLibrary);
	};

	const handleChange = async file => {
		const checked = true;
		const response = await saveImages(file, pageLayoutType, checked);
		if (response) {
			getAllImages(pageLayoutType);
		}
	};

	const handleSelectImage = row => {
		if (editMode) {
			let ids = [...selectedIds];
			const existId = ids.find(item => row.id === item.id);

			if (existId?.id) {
				let b = ids.filter(el => el.id !== row.id);
				setSelectedIds(b);
			} else {
				ids.push(row);
				setSelectedIds(ids);
			}
		} else {
			setEditImage(row);
		}
	};

	return (
		<HelmetLayout titleProps={"Media Library"}>
			{imageLibrary && (
				<ImageLibrary
					data={seoData}
					setData={setSeoData}
					handleAcceptedFiles={handleAcceptedFiles}
					type={pageLayoutType}
					handleDataChanged={handleDataChanged}
					mediaLibraryUpload
					getImagesMediaLibrary={getAllImages}
					pageLayoutType={pageLayoutType}
					handleShowLibrary={handleShowLibrary}
					setIsLoading={setIsLoading}
					accept={accept}
				/>
			)}
			<div className="cms-page-library cms-page-media">
				<FileUploader handleChange={handleChange} name="file" types={fileTypes} classes={"upload_image_container"} multiple={true} />

				<div className="row align-items-center">
					<div className="col-12 col-md-7 col-lg-6 edit-media-header">
						<PageHeader
							pageHeaderData={pageHeaderData}
							handleShowLibrary={handleShowLibrary}
							type={"media_library"}
							pageLayoutType={pageLayoutType}
							setPageType={setPageLayoutType}
							setAccept={setAccept}
							isLoading={isLoading}
						/>
					</div>
					<div className="col-12 col-md-5 col-lg-6 d-flex justify-content-end flex-wrap gap-2 ps-0">
						{!editMode ? (
							<MediaEditButton
								onClick={() => {
									setEditMode(true);
								}}
							/>
						) : (
							<>
								{selectedIds.length === 1 ? (
									<>
										<MediaEditImages
											text={`Edit`}
											onClick={() => {
												setEditImage(selectedIds[0]);
											}}
										/>
										<MediaDeleteButton
											text={selectedIds.length > 1 ? `Delete (${selectedIds.length})` : `Delete`}
											onClick={() => {
												handleCheckDelete();
											}}
											loading={checkDelete}
											disabled={isUserReadOnly}
										/>
									</>
								) : (
									<MediaDeleteButton
										text={selectedIds.length > 1 ? `Delete (${selectedIds.length})` : `Delete`}
										onClick={() => {
											handleCheckDelete();
										}}
										loading={checkDelete}
										disabled={isUserReadOnly}
									/>
								)}
								{images.length === selectedIds.length ? (
									<MediaUnSelectAllButton
										onClick={() => {
											selectAllImages("unselect");
											setEditMode(true);
										}}
										disabled={isUserReadOnly}
									/>
								) : (
									<MediaSelectAllButton
										onClick={() => {
											selectAllImages("select");
											setEditMode(true);
										}}
										disabled={isUserReadOnly}
									/>
								)}
								<MediaCancelButton
									onClick={() => {
										setEditMode(false);
										setSelectedIds([]);
									}}
								/>
							</>
						)}
					</div>
				</div>
				<div>
					<div className="media-library-container">
						{isDataLoading ? (
							<LoadingSpinner />
						) : (
							<div className="flex-images">
								{images.map((row, index) => {
									const fullUrl = aws_s3_url + "/media/" + row.image;
									const thumbImage = getImageThumbnail(fullUrl);

									return (
										<Fragment key={index}>
											{row?.file_type.indexOf("video") > -1 ? (
												<div className="image-container">
													<video
														className="VideoInput_video"
														width="145px"
														height="145px"
														controls
														src={thumbImage}
														alt={row?.image}
														key={index}
													/>
												</div>
											) : (
												<div className="image-container" key={row.id}>
													<img src={thumbImage} onClick={() => handleSelectImage(row)} />

													{editMode && selectedIds.indexOf(row) === -1 ? (
														<div className="select-checkbox" onClick={() => handleSelectImage(row)}></div>
													) : (
														editMode && (
															<div className="selected-checkbox" onClick={() => handleSelectImage(row)}>
																<TickIcon />
															</div>
														)
													)}
												</div>
											)}
										</Fragment>
									);
								})}
							</div>
						)}
					</div>
				</div>

				{showDeleteConfirm && (
					<SweetAlert title={"Delete images"} showConfirm={false} customClass={"delete-conf-sweet"} onConfirm={() => {}}>
						<div style={{ position: "relative" }}>
							<div>
								<span className="delete-span">Are you sure you want to delete image ?</span>
							</div>
						</div>
						<div style={{ position: "relative" }}>
							<div>
								{imagesTitle.map((row, key) => {
									let d = row?.details == undefined ? "" : JSON.parse(row?.details);
									let details = `${row?.details == undefined ? "" : `${d?.title} ,`}`;
									return (
										<span className="" key={key}>
											{details ? `This image is used at: ${details}` : ""}
										</span>
									);
								})}
							</div>
						</div>
						<div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
							<NoButton onClick={() => setShowDeleteConfirm(false)} name="No" />
							{deleteLoading ? <LoadingSaveButton /> : <YesButton name="Yes" onClick={handleDeleteImages} />}
						</div>
					</SweetAlert>
				)}
			</div>
			{editImage && (
				<ImageEdit
					imageData={editImage}
					type={pageLayoutType}
					handler={editedImage => {
						setImages(
							images.map(image => {
								if (image.id === editedImage.id) {
									return { ...image, ...editedImage };
								}

								return image;
							})
						);
						setEditImage(null);
					}}
				/>
			)}
		</HelmetLayout>
	);
};

export default MediaLibrary;
