import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Spinner } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import Modal from "../../components/modal/Modal";
import { emptyTelebetBets, setBreadcrumbItems, setTelebetUser } from "../../store/actions";
import { apiUrls } from "utils/const.apiUrl";
import moment from "moment";
import ApiServices from "utils/ApiServices";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CustomerSettings } from "./CustomerSettings/customerSettings";
// import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { TransactionHistory } from "./transactionHistory";
import { ResponsibleGambling } from "./responsibleGambling";
import { BankingUser } from "./bankingUser";
import { AuditLog } from "./auditLog";
import { toast } from "react-toastify";
import { calculateAgeFromDate } from "utils/global";
import { LightArrowRight, DarkArrowLeft, ThreeDotsIcon } from "utils/icons";
import { platformName } from "utils/constants";
import xIcon from "../../assets/images/x-icon.svg";
import { setRecentViewedUsers } from "store/Users/actions";
// import successIcon from "../../assets/images/success-icon.svg";
import { capitalizeText } from "services/capitalizeText";

import "./users.scss";
import Overview from "./Overview";
import AccountMenu from "./AccountMenu";
import UserNotes from "./UserNotes";

const User = props => {
	const countries = useSelector(state => state.Login.gsData.countries);

	const [user, setUser] = useState(null);
	const [suspendClicked, setSuspendClicked] = useState(false);
	const [userTab, setUserTab] = useState("overview");
	const [showUserActions, setShowUserActions] = useState(false);
	const [timelineData, setTimelineData] = useState([]);
	// const [dataChanged, setDataChanged] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	// const [userFields, setUserFields] = useState([]);
	const history = useHistory();
	const [editedUserData, setEditedUserData] = useState({
		address_line1: "",
		address_line2: "",
		address_city: "",
		address_zip: "",
		address_state: "",
		address_country: "",
		birthday: "",
		daily_deposit: "",
		weekly_deposit: "",
		monthly_deposit: "",
		reality_check: "",
		profile_verification_provider: "",
		phone: "",
		prefix: "",
		phone_number_verified: "",
	});
	const [detailsPage, setDetailsPage] = useState({ type: "" });
	// const [stakeFactorData, setStakeFactorData] = useState([]);
	const [userStatsData, setUserStatsData] = useState([]);
	const [suspendData, setSuspendData] = useState({
		suspended_by: "company",
		suspended_reason: "",
		suspended_text: "",
	});
	const [openAccountMenu, setOpenAccountMenul] = useState(false);
	// const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
	const [showNotes, setShowNotes] = useState(false);
	const [activationDate, setActivationDate] = useState("");
	const [data, setData] = useState([]);
	const menuRef = useRef();
	const dotsRef = useRef();
	// const [loading, setIsLoading] = useState(false);
	const [unchangedData, setunchangedData] = useState([]);
	const subId = props.match.params.id;
	const countryMapping = {};
	countries?.forEach(row => {
		countryMapping[row.cca2] = row.name;
	});

	const getUserData = () => {
		setPageLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_USER_DATA + props.match.params.id, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let data = response.data;
					if (data?.date_of_birth) {
						let years = calculateAgeFromDate(data?.date_of_birth);
						data.age = years;
					}
					setData(data);
					// setStakeFactorData(data?.stakeFactorPayload);
					setunchangedData(data);
				}
			})
			.finally(() => {
				setPageLoading(false);
			});
	};

	useEffect(() => {
		if (data?.date_of_birth) {
			let years = calculateAgeFromDate(data?.date_of_birth);
			setData({ ...data, age: years });
		}
	}, [data?.date_of_birth]);
	// Get getUserStats with new endpoint
	const getUserStats = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_USER_STATS + props.match.params.id, resolve, reject, true);
		}).then(response => {
			if (response.success) {
				setUserStatsData(response?.data);
			}
		});
	};

	useEffect(() => {
		getUserStats();
		getUserData();
		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, [props.match.params.id]);

	const resizeHandler = () => {
		// setIsMobile(window.document.documentElement.clientWidth <= 600);
	};

	const dispatch = useDispatch();

	const breadcrumbItems = [
		{ title: "Users", link: "/users" },
		{ title: "user", link: "/user/:id" },
	];

	useEffect(() => {
		if (data?.player_id) {
			saveRecentUsers();
		}
	}, [data?.player_id]);

	useEffect(() => {
		if (data && data?.length > 0) {
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls?.user_payment_card + "?user_id=" + props.match.params.id, resolve, reject, true);
			}).then(() => {});
		}

		props.setBreadcrumbItems("User", breadcrumbItems);
		if (data && data?.length > 0) {
			setUser(data[0]);
			// setFreeBetBalance(data[0].free_bet_balance);
			// setKycStatus(data[0].kyc_status);
			setEditedUserData({
				...editedUserData,
				address_line1: data[0]?.address_line1 ? data[0]?.address_line1 : "",
				address_line2: data[0]?.address_line2 ? data[0]?.address_line2 : "",
				address_city: data[0]?.address_city ? data[0]?.address_city : "",
				address_zip: data[0]?.address_zip ? data[0]?.address_zip : "",
				address_state: data[0]?.address_state ? data[0]?.address_state : "",
				address_country: data[0]?.address_country ? data[0]?.address_country : "",
				birthday: data[0]?.birthday ? data[0]?.birthday : "",
				daily_deposit: data[0]?.deposit_limit_daily ?? "",
				weekly_deposit: data[0]?.deposit_limit_weekly ?? "",
				monthly_deposit: data[0]?.deposit_limit_monthly ?? "",
				reality_check: data[0]?.reality_check_after ? data[0]?.reality_check_after : "",
				profile_verification_provider: data[0]?.profile_verification_provider ? data[0]?.profile_verification_provider : "",
				phone: data[0]?.phone || "",
				prefix: data[0].prefix || "",
			});
		}
		document.addEventListener("mousedown", event => {
			if (menuRef.current) {
				if (!menuRef.current.contains(event.target) && !dotsRef.current.contains(event.target)) {
					setShowUserActions(false);
				}
			}
		});
	}, [data]);

	const handleSuspendUser = () => {
		let suspendReason = "";

		if (!suspendData.suspended_text) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please write unsuspend reason</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}
		suspendReason = suspendData.suspended_text;

		let payload = {
			user_id: user.id,
			description: suspendReason,
			type: "unsuspend_reason",
			suspend_status: 0,
			account_suspended_until: activationDate ? moment(activationDate, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss") : "",
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls?.suspend, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) {
					setSuspendData({
						...suspendData,
						suspended_by: "company",
						suspended_reason: "",
						suspended_text: "",
					});

					setTimelineData([...timelineData, { description: suspendReason, createdAt: moment().format("YYYY-MM-DD HH:mm:ss") }]);
				}
			})
			.finally(() => {
				setActivationDate("");
				setSuspendClicked(false);
			});
	};

	const getUserTab = value => {
		setShowUserActions(false);
		setShowNotes(false);

		setUserTab(value);
		localStorage.setItem("activeTab", value);
	};

	useEffect(() => {
		const activeTab = localStorage.getItem("activeTab");

		if (activeTab) setUserTab(activeTab);
		else setUserTab("overview");
	}, []);

	const saveRecentUsers = () => {
		try {
			const recentViewUsers = JSON.parse(localStorage.getItem("recentViewUsers"));
			let newRecents = [];
			const { first_name, last_name, player_id, swifty_id } = data;
			const full_name = capitalizeText(`${first_name} ${last_name}`);

			const obj = {
				full_name,
				player_id,
				id: swifty_id,
			};
			if (!recentViewUsers || !recentViewUsers?.length) {
				newRecents = [obj];
			} else if (recentViewUsers?.length) {
				if (recentViewUsers.some(user => user.player_id == player_id)) {
					newRecents = [obj, ...recentViewUsers.filter(item => item.player_id != player_id)];
				} else {
					newRecents = [obj, ...recentViewUsers];
				}
			}

			if (newRecents?.length > 10) newRecents = newRecents.filter((item, i) => i < 10);

			localStorage.setItem("recentViewUsers", JSON.stringify(newRecents));
			dispatch(setRecentViewedUsers(newRecents));
		} catch (e) {
			console.log(e?.message);
		}
	};

	const userTabFields = [
		{ id: "overviewTab", name: "Overview", value: "overview" },
		{ id: "customerTab", name: "Customer Settings", value: "customer" },
		{ id: "gamblingTab", name: "Responsible Gambling", value: "gambling" },
		{ id: "transactionTab", name: "Transaction History", value: "transaction" },
		{ id: "bankingTab", name: "Banking", value: "banking" },
	];

	const openTelebet = () => {
		history.push("/telebet");
		dispatch(setTelebetUser(data));
		dispatch(emptyTelebetBets());
	};

	const handlerClick = link => {
		if (link === "auditlog") getUserTab("auditlog", 5);
		else if (link === "notes") setShowNotes(true);
		else if (link === "telebet") openTelebet();

		setOpenAccountMenul(false);
	};

	const getUserAccountStatus = type => {
		let className = "";

		if (type == "suspended") {
			className += "userSuspended";
		} else if (type == "pending" || type == "self_excluded" || type == "locked") {
			className += "userPending";
		} else if (type == "closed") {
			className += "userClosed";
		} else {
			className += "";
		}

		return <span className={`user-account-status ${className}`}>{capitalizeText(type)}</span>;
	};

	const accountMenuFields = [
		{ name: "Audit Log", link: "auditlog" },
		{ name: "Notes", link: "notes" },
		{ name: "Telebet", link: "telebet" },
	];

	const onClickBack = () => {
		history.push("/search_users");
	};

	return (
		<div className={`user-page ${userTab === "customer" ? "user-page-customer" : ""}`}>
			<MetaTags>
				<title>{platformName} Player account management and reporting system</title>
			</MetaTags>
			{pageLoading ? (
				<div className="text-center mt-3">
					<Spinner animation="border" size="lg"></Spinner>
				</div>
			) : data && data?.length == 0 ? (
				<div className="no-user-found">This user does not exist</div>
			) : (
				<Row className="m-0">
					<Col className="min-height-vh">
						<Card>
							<CardBody style={{ position: "relative" }}>
								<CardTitle className="user-card-title mb-0">
									<div className="user-info d-flex">
										<div className="user-fullname d-flex align-items-center">
											<DarkArrowLeft onClick={() => onClickBack()} className="go-back me-2" />
											<span className="user-firstname">{data?.first_name}</span>
											<span className="user-firstname">{data?.last_name}</span>
										</div>
										<div className="user-fullname d-flex">
											<span className="userId d-flex justify-content-center align-item-center">#{data?.player_id ? data?.player_id : "-"}</span>
										</div>
										{getUserAccountStatus(data?.user_self_excluded ? "self_excluded" : data?.account_status)}
									</div>

									<div className="user-dropwdown">
										<div className="user-dropdown-item" onClick={() => openTelebet()}>
											Telebet
										</div>
										<button
											className={`btn btn-primary btn-no-border ${showUserActions ? "active" : ""}`}
											onClick={() => setShowUserActions(!showUserActions)}
											ref={dotsRef}
										>
											<LightArrowRight />
										</button>

										{showUserActions ? (
											<div className="user-dropdown-list" ref={menuRef}>
												<ul>
													<li onClick={() => getUserTab("auditlog")} className="user-dropdown-item">
														Audit Log
													</li>
												</ul>
											</div>
										) : (
											<></>
										)}
									</div>

									<div className="user-three-dots">
										<ThreeDotsIcon className={"ms-3 cursor-pointer"} handleClick={() => setOpenAccountMenul(true)} />
									</div>
								</CardTitle>
								<span className="user-border"></span>

								{!detailsPage?.type && (
									<div className="user-tabs user_menu col-12 col-lg-12 col-xl-12">
										{userTabFields.map((row, i) => {
											const { id, name, value } = row;
											return (
												<div
													id={id}
													key={i}
													className={userTab === value ? "user-notes user-tab active" : "user-notes user-tab"}
													onClick={() => getUserTab(value)}
												>
													{name}
												</div>
											);
										})}
									</div>
								)}

								{userTab === "auditlog" && <AuditLog id={data?.id} />}
								{userTab === "transaction" && <TransactionHistory id={data?.id} currencyData={data?.currency} />}
								{userTab === "customer" && (
									<>
										<CustomerSettings data={data} setData={setData} unchangedData={unchangedData} setunchangedData={setunchangedData} />
										{suspendClicked && (
											<Modal
												show={false}
												title={"Unsuspend Account"}
												handleClose={() => setSuspendClicked(false)}
												handler={handleSuspendUser}
												button={<Spinner animation="border" size="sm" style={{ marginRight: "5px" }} />}
											/>
										)}
									</>
								)}
								{userTab === "gambling" && (
									<ResponsibleGambling
										subId={subId}
										data={data}
										setData={setData}
										detailsPage={detailsPage}
										setDetailsPage={setDetailsPage}
										getUserData={getUserData}
									/>
								)}
								{userTab === "banking" && (
									<BankingUser subId={subId} userStatsData={userStatsData} setUserStatsData={setUserStatsData} playerId={data?.player_id} />
								)}
								{userTab === "overview" && <Overview userData={data} extraItem={{ user_id: props.match.params.id }} />}

								{openAccountMenu && <AccountMenu fields={accountMenuFields} handlerClick={handlerClick} setCloseAccountMenul={setOpenAccountMenul} />}

								{showNotes && <UserNotes userData={data} extraItem={{ user_id: props.match.params.id }} getUserTab={getUserTab} />}
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}
		</div>
	);
};
export default connect(null, { setBreadcrumbItems })(User);
