import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class NonAuthLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return <React.Fragment>{this.props.children}</React.Fragment>;
	}
}

NonAuthLayout.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
