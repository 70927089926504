import React, { useState } from "react";
import { XCloseIcon } from "utils/icons";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import { GeneralInput } from "components/GeneralComponents/CustomInputs";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
export const EditPlayersModal = ({ setShowEditModal, dataChanged, setDataChanged, playerData, setPlayerData }) => {
	const handleDataChanged = () => {
		setDataChanged(true);
	};
	const [loading, setLoading] = useState(false);

	const editPlayers = () => {
		setLoading(true);
		let payload = {
			id: playerData.id,
			first_name: playerData.first_name,
			last_name: playerData.last_name,
			display_name: playerData.display_name,
			squad_number: playerData.squad_number,
			position: playerData.position,
		};
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.players_edit, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<div className="promotion-container">
				<div className="promotion-header">
					<span>Player</span>
					<XCloseIcon onClick={() => setShowEditModal(false)} className={"xclose-icon"} />
				</div>
				<div className="promotion-body">
					<TopBarComponent saveHandler={() => editPlayers()} dataChanged={dataChanged} loading={loading} onClose={() => setShowEditModal(false)} />
					<div className="promotion-elements row m-0">
						<div className="promotion-left col-12 col-lg-6 promotion-inputs customized_modal_promotion_left">
							<GeneralInput
								object={{ name: "First Name", id: "name", placeholder: "First Name", value: playerData.first_name, firstRowClass: true }}
								onChange={e => {
									handleDataChanged();
									setPlayerData({
										...playerData,
										first_name: e.target.value,
									});
								}}
							/>
							<GeneralInput
								object={{ name: "Last Name", id: "name", placeholder: "Last Name", value: playerData.last_name, otherRowClass: true }}
								onChange={e => {
									handleDataChanged();
									setPlayerData({
										...playerData,
										last_name: e.target.value,
									});
								}}
							/>
							<GeneralInput
								object={{ name: "Display Name", id: "name", placeholder: "Display Name", value: playerData.display_name, otherRowClass: true }}
								onChange={e => {
									handleDataChanged();
									setPlayerData({
										...playerData,
										display_name: e.target.value,
									});
								}}
							/>
							<GeneralInput
								object={{ name: "Squad Number", id: "name", placeholder: "Squad Number", value: playerData.squad_number, otherRowClass: true }}
								onChange={e => {
									handleDataChanged();
									setPlayerData({
										...playerData,
										squad_number: e.target.value,
									});
								}}
								otherRowClass
							/>
							<GeneralInput
								object={{ name: "Position", id: "name", placeholder: "Position", value: playerData.position, otherRowClass: true }}
								onChange={e => {
									handleDataChanged();
									setPlayerData({
										...playerData,
										position: e.target.value,
									});
								}}
							/>
						</div>
						<div className="promotion-right col-12 col-lg-6"></div>
					</div>
				</div>
			</div>
		</>
	);
};
