import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { XCloseIcon } from "utils/icons";
import "./cmsUserActivity.css";
import { capitalize } from "lodash";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";

const generateContentRows = content => {
	return (
		<div className="m-3">
			<div className="cms_user_activity_modal">
				<div className="content_cms_user_activity_modal header_cms_user_activity_modal">PARAMETER</div>
				<div className="content_cms_user_activity_modal header_cms_user_activity_modal">VALUE</div>
			</div>

			{Object.keys(content).length === 0 ? (
				<div className="empty_cms_user_activity_modal"> No data to display! !</div>
			) : (
				Object.entries(content).map(([key, value]) => (
					<div key={`${key}_row`} className="cms_user_activity_modal">
						<div className="content_cms_user_activity_modal">
							{key
								?.replace(/[-_]/g, " ")
								.split(" ")
								.map(word => capitalize(word))
								.join(" ")}
						</div>
						<div className="content_cms_user_activity_modal">{value}</div>
					</div>
				))
			)}
		</div>
	);
};

export const CmsUserActivityModal = ({ setData, data, title }) => {
	const [activityLogDetails, setActivityLogDetails] = useState("");
	const [pageLoading, setPageLoading] = useState(true);
	const id = data?.id;

	const handleFetchData = id => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.activity_log_get_details + `/${id}`, resolve, reject, true);
		}).then(response => {
			setPageLoading(false);
			let data = response?.data;
			if (data) {
				data = generateContentRows(JSON.parse(data));
			}
			setActivityLogDetails(data);
		});
	};

	useEffect(() => {
		if (id) {
			handleFetchData(id);
		}
	}, []);

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{title}</span>
				<XCloseIcon onClick={() => setData({ ...data, show: false })} className={"xclose-icon"} />
			</div>
			{pageLoading ? (
				<div className="text-center mt-3">
					<Spinner animation="border" size="lg" />
				</div>
			) : (
				<div className="promotion-body">
					<div className="promotion-elements m-0">
						<div>{activityLogDetails}</div>
					</div>
				</div>
			)}
		</div>
	);
};
