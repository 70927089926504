import React from "react";
import HTMLReactParser from "html-react-parser";
import { EditButton } from "components/GeneralComponents/AddButton";
import { Spinner } from "reactstrap";

export const getCasinoProvidersTableColumn = ({ casinoProvidersData, setSelectedRow, setShowCasinoProvidersModal }) => {
	return [
		{
			name: "provider_name",
			label: "PROVIDERS",
			className: "name",
			sortable: true,
			sort: "asc",
			align: "left",
		},

		{
			name: "producer_name",
			label: "PRODUCER",
			className: "name",
			sortable: true,
			sort: "asc",
			align: "left",
		},

		{
			name: "last_sync",
			label: "LAST SYNC",
			sortable: true,
			sort: "asc",
			align: "left",
			options: {
				customBodyRender: (value, meta) => {
					const record = casinoProvidersData[meta.currentTableData[meta.rowIndex].index];

					return <span>{record?.last_sync_formatted}</span>;
				},
			},
		},
		{
			name: "worker_period",
			label: "SYNC PERIOD",
			sortable: true,
			sort: "asc",
			align: "left",
		},

		{
			name: "active",
			label: "STATUS",
			className: "",
			sortable: true,
			sort: "asc",
			align: "left",
			options: {
				customBodyRender: (value, meta) => {
					const record = casinoProvidersData[meta.currentTableData[meta.rowIndex].index];
					const result_txt =
						record?.active == 1
							? `<span className="badge  badge-sports-green "> Active </span>`
							: `<span class="badge badge-sports-inactive ">Inactive</span>`;

					return <>{HTMLReactParser(result_txt)}</>;
				},
			},
			setCellProps: () => ({ style: { textAlign: "left" } }),
			setCellHeaderProps: () => ({ style: { maxWidth: "max-content", width: "max-content" }, className: "max-button" }),
		},
		{
			name: "",
			label: "",
			className: "",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				filter: false,
				setCellProps: () => ({ style: { width: "200px", textAlign: "center" } }),
				customBodyRender: (value, meta) => {
					const record = casinoProvidersData[meta.currentTableData[meta.rowIndex].index];

					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div style={{ height: "24px" }}>
								<EditButton
									onClick={() => {
										setSelectedRow(record);
										setShowCasinoProvidersModal(true);
									}}
								>
									<Spinner animation="border" size="sm"></Spinner>
								</EditButton>
							</div>
						</div>
					);
				},
			},
		},
	];
};
