import React from "react";
import { EditButton } from "components/GeneralComponents/AddButton";

export const getVariablesTableColumne = ({ records, setEditItem, setShowAddModal }) => {
	return [
		{
			name: "description",
			label: "DESCRIPTION",
			className: "region",
			sortable: true,
			sort: "asc",
		},
		{
			name: "value",
			label: "VALUE",
			className: "sport",
			align: "left",
			sortable: true,
			sort: "asc",
			options: {
				setCellProps: () => ({ style: { maxWidth: "600px" } }),
				setCellHeaderProps: () => ({ className: "max-button-and-left customized_left_header_table d-flex" }),
			},
		},

		{
			name: "",
			className: "variables_edit",
			align: "left",
			sortable: true,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex">
							<EditButton
								onClick={() => {
									setEditItem(record);
									setShowAddModal(true);
								}}
							/>
						</div>
					);
				},
			},
		},
	];
};
