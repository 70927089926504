import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import MUIDataTable from "mui-datatables";
import { _marketMatchesFilterOptions } from "utils/constants";
import { GeneralDropdown, ModalButton, SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import { getMarketTableColumns } from "./marketTableColumns";
import { useHistory } from "react-router-dom";
import AddMarketsModal from "./AddMarketsModal";
import LoadingSpinner from "components/Loader/LoadingSpinner";
import { tableResponsive, tableBarSettings } from "utils/tableSettings";

import "./matches.scss";

export const MarketsMatches = props => {
	const { marketMatchesViewData, marketMatchesView, eventViewData, marketDataLoading } = props;
	const history = useHistory();
	const [dataToView, setDataToView] = useState([]);
	const [unfilteredData, setUnFilteredData] = useState([]);
	const [dropdownData, setDropdownData] = useState("");
	const [addMarketModalOpen, setAddMarketModalOpen] = useState(false);

	const { pathname } = history.location;

	const match_id = pathname.split("/")[2];

	useEffect(() => {
		setDataToView(marketMatchesViewData);
		setUnFilteredData(marketMatchesViewData);
	}, [marketMatchesViewData]);

	const handlerClick = (type, record) => {
		if (!record) return;
		const { market_id } = record;
		const {
			location: { pathname, search },
		} = history;

		history.push({
			pathname,
			search: search + `&market_id=${market_id}`,
		});
	};

	let marketTableColumns = getMarketTableColumns({
		marketMatchesViewData: dataToView,
		handlerClick,
		history,
	});

	const handleDropdownChange = result => {
		marketMatchesView(result, match_id, eventViewData.sport_slug);
	};

	const onAddMarket = () => {
		setAddMarketModalOpen(true);
	};

	return (
		<>
			<div className="market-matches">
				<div className="market-items">
					<SingleSearchFilter setData={setDataToView} unfilteredData={unfilteredData} filterColumn={["market_name"]} noSpace />
					<GeneralDropdown
						object={{
							defaultPlaceholder: "Choose result",
							label: "All",
							options: _marketMatchesFilterOptions,
							value: dropdownData,
							wrapperClassNameDropdown: "mt-0",
						}}
						handleChange={e => {
							setDropdownData(e?.id);
							handleDropdownChange(e?.id);
						}}
					/>
				</div>
				<div className="add-button">
					<ModalButton
						object={{
							buttonName: <AddIcon />,
							extraButtonClassName: "add-button-component",
							position: "justify-content-end pt-0",
							onClickEvent: onAddMarket,
							type: "add",
							wrapperClassName: "mt-0",
						}}
					/>
				</div>
			</div>

			<MUIDataTable
				columns={marketTableColumns}
				data={dataToView}
				options={{
					search: false,
					selectableRows: "none",
					elevation: 0,
					downloadOptions: { filename: "otr" + ".csv" },
					responsive: tableResponsive,
					rowsPerPage: 0,
					rowsPerPageOptions: [],
					download: false,
					customSearch: false,
					filter: false,
					fixedSelectColumn: false,
					jumpToPage: false,
					print: false,
					resizableColumns: false,
					rowHover: false,
					searchOpen: false,
					selectableRowsOnClick: false,
					viewColumns: false,
					pagination: false,
					setTableProps: () => ({
						className: "tableRowCustomized",
					}),
					textLabels: {
						body: {
							noMatch: marketDataLoading ? <LoadingSpinner /> : " No data to display!",
						},
					},
					...tableBarSettings,
				}}
			/>

			{addMarketModalOpen && (
				<AddMarketsModal
					onClose={() => setAddMarketModalOpen(false)}
					sport_slug={eventViewData?.sport_slug}
					match_id={match_id}
					getNewMarketsData={() => handleDropdownChange(dropdownData)}
				/>
			)}
		</>
	);
};
