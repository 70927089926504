import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody } from "reactstrap";
import { connect, useSelector } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { tableExtraButtons, tableBarSettings } from "utils/tableSettings";
import MUIDataTable from "mui-datatables";
import { platformName } from "utils/constants";

const UserManualBets = props => {
	const sports = useSelector(state => state.Login.gsData.sports);
	const regions = useSelector(state => state.Login.gsData.regions);
	const lsGSData = localStorage.getItem("gsData") ? JSON.parse(localStorage.getItem("gsData")) : [];
	const [currentPage, setCurrentPage] = useState(0);
	const [rowPerPage, setRowPerPage] = useState(50);
	const breadcrumbItems = [{ title: "Manual Bets", link: "/manual_bets" }];
	const [showCreateManualBet, setShowCreateManualBet] = useState(false);
	useEffect(() => {
		props.setBreadcrumbItems("Manual Bets", breadcrumbItems);
	}, []);

	const columns = [];
	const records = [];

	const regionsDropDown = regions && regions.length > 0 ? regions : lsGSData.regions ? lsGSData.regions : [];
	const sportsDropDown = sports && sports.length > 0 ? sports : lsGSData.sports ? lsGSData.sports : [];
	return (
		<React.Fragment>
			<MetaTags>
				<title>User Manual Bets - {platformName}</title>
			</MetaTags>
			<Row>
				<Col>
					<Card>
						<CardBody style={{ overflowX: "scroll" }}>
							<div className="row mb-5">
								<div className="col-12 d-flex justify-content-end">
									<button className="btn btn-primary" onClick={() => setShowCreateManualBet(true)}>
										Create
									</button>
								</div>
							</div>
							{showCreateManualBet && (
								<SweetAlert title="Create manual bet" showConfirm={false} style={{ width: "70em" }} onConfirm={() => {}}>
									<div style={{ position: "relative" }}>
										<div className="row mb-2">
											<div className="row m-0 mb-2">
												<div className="col-4 d-flex">User</div>
												<div className="col-8 d-flex">
													<select className="form-control">
														<option value={""}>Choose user</option>
													</select>
												</div>
											</div>
											<div className="row m-0 mb-2">
												<div className="col-4 d-flex">Sport</div>
												<div className="col-8 d-flex">
													<select className="form-control">
														<option value={""}>Choose sport</option>
														{sportsDropDown.map((sport, key) => {
															return (
																<option value={sport.genius_id} key={key}>
																	{sport.name}
																</option>
															);
														})}
													</select>
												</div>
											</div>
											<div className="row m-0 mb-2">
												<div className="col-4 d-flex">Region</div>
												<div className="col-8 d-flex">
													<select className="form-control">
														<option value={""}>Choose region</option>
														{regionsDropDown.map((region, key) => {
															return (
																<option value={region.code} key={key}>
																	{region.name}
																</option>
															);
														})}
													</select>
												</div>
											</div>
											<div className="row m-0 mb-2">
												<div className="col-4 d-flex">Competition</div>
												<div className="col-8 d-flex"></div>
											</div>
											<div className="row m-0 mb-2">
												<div className="col-4 d-flex">Match</div>
												<div className="col-8 d-flex"></div>
											</div>
											<div className="row m-0 mb-2">
												<div className="col-12 d-flex">Bets</div>
												<div className="col-12 manual-bets-show-container"></div>
											</div>
										</div>
										<div className="row m-0 mb-2 justify-content-end">
											<div className="col-4">
												<label>Odd</label>
												<input className="form-control" type={"text"} />
											</div>
											<div className="col-4">
												<label>Wage</label>
												<input className="form-control" type={"text"} />
											</div>
										</div>
										<div className="row m-0 mb-2 mt-3">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary" style={{ marginRight: "10px" }} onClick={() => setShowCreateManualBet(false)}>
													Cancel
												</button>
												<button className="btn btn-primary">Save</button>
											</div>
										</div>
									</div>
								</SweetAlert>
							)}

							<MUIDataTable
								columns={columns}
								data={records}
								options={{
									selectableRows: "none",
									elevation: 0,
									onChangePage(page) {
										setCurrentPage(page);
									},
									onChangeRowsPerPage(number) {
										setRowPerPage(number);
									},
									textLabels: {
										body: {
											noMatch: " No data to display!",
										},
									},
									page: currentPage,
									responsive: "simple",
									rowsPerPage: rowPerPage,
									rowsPerPageOptions: [10, 15, 50, 100],
									downloadOptions: { filename: "Manual Bets.csv" },
									...tableBarSettings,
								}}
								extraButtons={tableExtraButtons}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default connect(null, { setBreadcrumbItems })(UserManualBets);
