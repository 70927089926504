import React from "react";
import { CancelButton, SaveButton } from "components/GeneralComponents/AddButton";
import SweetAlert from "react-bootstrap-sweetalert";
export const ShowConfirmBlockUser = ({ blockUser, blocked, setBlockModal, userFullName }) => {
	return (
		<>
			<SweetAlert
				title={blocked ? "Confirm Block of User" : "Confirm Unblock of User"}
				showConfirm={false}
				titleStyle={{ fontSize: "12px" }}
				onConfirm={() => {}}
			>
				<div style={{ position: "relative" }}>
					<div>
						<p className="delete_user_text">{blocked ? "Are you sure you want to block" : "Are you sure you want to unblock"} </p>
						<p className="delete_user_name">
							{userFullName?.first_name} {userFullName?.last_name} ?
						</p>
					</div>
					<div className="mt-3">
						<CancelButton onClick={() => setBlockModal(false)} name="No" />
						<SaveButton onClick={blockUser} name="Yes" />
					</div>
				</div>
			</SweetAlert>
		</>
	);
};
