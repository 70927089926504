import {
	LOGIN_USER,
	LOGIN_SUCCESS,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
	API_ERROR,
	GET_USER_SUCCESS,
	GET_USER,
	CMS_LOGIN_USER,
	CMS_SET_BET_HISTORY_EMAIL,
	GET_GS_DATA,
	CMS_SET_TRASACTION_HISTORY_EMAIL,
	CMS_SEARCH_FILTER_DATA,
	SET_IS_LOGIN_PAGE,
	CMS_SET_TRANSACTION_HISTORY_DATE_FROM,
	CMS_SET_TRANSACTION_HISTORY_DATE_TO,
	ACTIVE_PAGE,
	SET_COUNTRY,
	SET_LANGUAGE,
} from "./actionTypes";
// import { CompetitionDefaultFilter } from "./initalState/CompetitionDefaultFilter";
// import { LiabilityReportFilter } from "./initalState/LiabilityReportFilter";
// import { activityLogsFilter } from "./initalState/activityLogsFilter";
// import { betsFilter } from "./initalState/betsFilter";
// import { cmsActivityLogFilter } from "./initalState/cmsActivityLogFilter";
// import { competitionFilter } from "./initalState/competitionFilter";
// import { duplicateUserFilter } from "./initalState/duplicateUserFilter";
// import { liabilityFilter } from "./initalState/liabilityFilter.js";
// import { manualBetsFilter } from "./initalState/manualBetsFilter";
// import { marketFilter } from "./initalState/marketFilter";
// import { matchesFilter } from "./initalState/matchesFilter";
// import { notificationFilter } from "./initalState/notificationFilter";
// import { periodCompetitionFilter } from "./initalState/periodCompetition";
// import { saferGamblingFilter } from "./initalState/saferGamblingFilter";
// import { sportOrderFilter } from "./initalState/sportOrderFilter";
// import { userFilter } from "./initalState/userFilter";
// import { wordsFilter } from "./initalState/wordsFilter";

const initialState = {
	error: "",
	loading: false,
	user: {},
	betHistoryEmail: "",
	gsData: [],
	isLoginPage: false,
	transactionHistoryEmail: "",
	transactionHistoryDateFrom: "",
	transactionHistoryDateTo: "",
	activePage: "/",
	// searchFilterData: {
	// 	users: userFilter,
	// 	competitions: competitionFilter,
	// 	matches: matchesFilter,
	// 	period_competitions: periodCompetitionFilter,
	// 	markets: marketFilter,
	// 	bets: betsFilter,
	// 	specials: manualBetsFilter,
	// 	safer_gambling: saferGamblingFilter,
	// 	duplicate_users: duplicateUserFilter,
	// 	liability_report: LiabilityReportFilter,
	// 	default_league: CompetitionDefaultFilter,
	// 	sport_order: sportOrderFilter,
	// 	activity_log: activityLogsFilter,
	// 	cms_activity_log: cmsActivityLogFilter,
	// 	notification: notificationFilter,
	// 	words: wordsFilter,
	// 	liabilities: liabilityFilter,
	// },
	searchFilterData: {},
	country: "all",
	languages: [
		{
			name: "French (FR)",
			for: "fr",
		},
		{
			name: "Spanish (ES)",
			for: "es",
		},
	],
};

const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_USER:
			state = {
				...state,
				loading: true,
			};
			break;
		case LOGIN_SUCCESS:
			state = {
				...state,
				loading: false,
			};
			break;
		case LOGOUT_USER:
		case LOGOUT_USER_SUCCESS:
			state = { ...state };
			break;
		case API_ERROR:
			state = { ...state, error: action.payload, loading: false };
			break;
		case GET_USER:
			break;
		case GET_USER_SUCCESS:
		case CMS_LOGIN_USER:
			state = {
				...state,
				user: action.payload,
			};
			break;
		case CMS_SET_BET_HISTORY_EMAIL:
			state = {
				...state,
				betHistoryEmail: action.payload,
			};
			break;
		case GET_GS_DATA:
			state = {
				...state,
				gsData: action.payload,
			};
			break;
		case CMS_SET_TRASACTION_HISTORY_EMAIL:
			state = {
				...state,
				transactionHistoryEmail: action.payload,
			};
			break;
		case CMS_SET_TRANSACTION_HISTORY_DATE_FROM:
			state = {
				...state,
				transactionHistoryDateFrom: action.payload,
			};
			break;
		case CMS_SET_TRANSACTION_HISTORY_DATE_TO:
			state = {
				...state,
				transactionHistoryDateTo: action.payload,
			};
			break;
		case CMS_SEARCH_FILTER_DATA:
			state = {
				...state,
				searchFilterData: {
					...state.searchFilterData,
					...action.payload,
				},
			};
			break;
		case SET_IS_LOGIN_PAGE:
			state = {
				...state,
				isLoginPage: action.payload,
			};
			break;
		case ACTIVE_PAGE:
			state = {
				...state,
				activePage: action.payload,
			};
			break;
		case SET_COUNTRY:
			state = {
				...state,
				country: action.payload,
			};
			break;
		case SET_LANGUAGE:
			state = {
				...state,
				languages: action.payload,
			};
			break;
		default:
			return state;
	}
	return state;
};

export default login;
