import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let results = [];
let prevValue = "";
let prevParams = "";

export const getFreebetCasinoGames = async (value, params) => {
	if (value === prevValue && params == prevParams) return results;

	if (value) {
		let url = `${apiUrls.GET_CASINO_OPTIONS}?${value}`;

		if (params) url += params;

		prevValue = value;
		prevParams = params;

		return new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				let games = response?.data;

				games = games.map(row => {
					return { id: row.slug, label: `${row.title} | ${row.slug}` };
				});

				results = games;
				return games;

				// let newGames = [...games, ...results];
				// let uniqueGames = newGames.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));

				// results = uniqueGames;
				// return uniqueGames;
			})
			.catch(() => {
				results = [];
				return [];
			});
	} else {
		return [];
	}
};
