// const { default: ApiServices } = require("utils/ApiServices");
// const { apiUrls } = require("utils/constants");

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

const getCountriesData = async () => {
	const stopCallingApi = ["customer_service", "auditor", "operations", "affiliate"];
	// if (sessionStorage.getItem("_countries")) {
	// 	return JSON.parse(sessionStorage.getItem("_countries"));
	// }
	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.country_list, resolve, reject, true, stopCallingApi);
	})
		.then(response => {
			let countries = response?.data;
			return countries;
		})
		.catch(() => {
			return [];
		});
};

export default getCountriesData;
