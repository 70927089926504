import React from "react";
import { BetsIcon, MoneyIcon } from "utils/icons";

const MiniWidget = ({ data }) => {
	return (
		<div className="miniwidget">
			{data?.map(({ title, value, icon, column }, key) => (
				<div className={`card ${column}`} key={key}>
					<div className="miniwidget-card">
						<div>
							<h3 className="miniwidget-card-title">{title}</h3>
							<p className="miniwidget-card-value">{value}</p>
						</div>

						{icon == "dice" && <BetsIcon />}
						{icon == "dollar-sign" && <MoneyIcon />}
					</div>
				</div>
			))}
		</div>
	);
};

export default MiniWidget;
