import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { getDefaultSportsTableColumns } from "./defaultSportsTableColumns";
import { ShowDeleteConfirmDefaultSportModal } from "./showDeleteConfirmDefaultSportModal";
import { ShowEditDefaultSportModal } from "./showEditDefaultSportModal";
import { ShowCloneSportModal } from "./showCloneSportModal";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import SearchFilter from "components/SearchFilter/searchFilter";
import { getDefaultSportsFilterTable } from "./defaultSportsFilterTable";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import xIcon from "../../../assets/images/x-icon.svg";
import getSportsData from "services/getSportsData";
import { prepareFilterData, userReadOnly } from "utils/global";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const defaultSports = props => {
	const sportOrderFilterData = useSelector(state => state.Login.searchFilterData.sport_order);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [addSpinning, setAddSpinning] = useState(false);
	const [orderData, setOrderData] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const [deleteSpinning, setDeleteSpinning] = useState(false);
	const [showEditSport, setShowEditSport] = useState(false);
	const [editSpinning, setEditSpinning] = useState(false);
	const [editId, setEditId] = useState("");
	const [sportSlugs, setSportSlugs] = useState([]);
	const breadcrumbItems = [{ title: "Default Leagues", link: "/default_leagues" }];
	const [sportOrderData, setSportOrderData] = useState({
		country_code: "0",
		sport_id: "",
		order: "",
		country_name: "",
	});
	const [showCloneSport, setShowCloneSport] = useState(false);
	const countries = useSelector(state => state.Login.gsData.countries);
	const lsGSData = localStorage.getItem("gsData") ? JSON.parse(localStorage.getItem("gsData")) : [];
	const [sportItems, setSportsItems] = useState([]);
	const [getData, setGetData] = useState(false);
	const [cloneSpinning, setCloneSpinning] = useState(false);
	const [cloneId, setCloneId] = useState("");
	const [sportFavorite, setSportFavorite] = useState({});
	const [rowPerPage, setRowPerPage] = useState(50);

	const user = JSON.parse(localStorage.getItem("user"));
	const isUserReadOnly = userReadOnly(user);

	let defaultSportsFilterTable = getDefaultSportsFilterTable({ countries });

	useEffect(() => {
		props.setBreadcrumbItems("Sports Order", breadcrumbItems);
	});

	let sportsLength = [];
	let sportsFavoriteArray = [];
	let favoriteObj = {};
	let countriesArr = [];

	if (sportItems && sportItems.length > 0) {
		for (var i = 0; i < sportItems.length; i++) {
			var row = sportItems[i];
			favoriteObj[row.id] = false;
		}
	}

	if (sportSlugs) sportSlugs.forEach((row, i) => sportsLength.push(i + 1));
	if (sportItems && orderData.length > 0) orderData.forEach(row => countriesArr.push(row?.country_code));

	const getEditSportData = id => {
		setEditSpinning(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_order + `?id=${id}`, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let data = response.data.return_data;
					let sport_slugs = [];
					// var sportArr = [];
					// var sport_favorite = [];
					// var favoriteObj = {};
					let coun;

					if (data) {
						sport_slugs = data[0].sport_slug.split(",");

						if (data.length > 0) {
							setSportOrderData({ ...sportOrderData, country_code: data[0].country_code });
							if (countries && countries.length > 0) {
								coun = countries.filter(count => {
									return count["cca2"] === data[0].country_code || count["cca3"] === data[0].country_code;
								})[0];
								setSportOrderData({
									...sportOrderData,
									country_name: coun && coun.name ? coun.name : "All countries",
									country_code: data[0].country_code,
								});
							}
						}

						// for (let i = 0; i < sport_names.length; i++) {
						// 	var sportNameRow = sport_names[i];
						// 	var sportIdRow = sport_ids[i];
						// 	var sportIdFavorite = sport_favorite[i];
						// 	let obj = {};
						// 	if (sportIdFavorite && sportIdFavorite != "undefined") {
						// 		favoriteObj[Number(sportIdRow)] = sportIdFavorite == "1" ? true : false;
						// 	}
						// 	obj.slug = sportIdRow;
						// 	obj.name = sportNameRow;
						// 	sportArr.push(obj);
						// }
						// for (let i = 0; i < sportItems.length; i++) {
						// 	var sportId = sportItems[i].slug;
						// 	var checkExists = sport_ids.some(element => element == sportId);
						// 	if (!checkExists) {
						// 		const obj = {
						// 			id: sportItems[i].id,
						// 			name: sportItems[i].name,
						// 			slug: sportItems[i].slug,
						// 		};
						// 		deletedSportArr.push(obj);
						// 	} else {
						// 		const obj = {
						// 			id: sportItems[i].id,
						// 			name: sportItems[i].name,
						// 			slug: sportItems[i].slug,
						// 		};
						// 		activeSportArr.push(obj);
						// 	}
						// }

						setSportSlugs(sport_slugs);
						setShowEditSport(true);
					}
				}
			})
			.finally(() => {
				setEditSpinning(false);
				setIsDataLoading(false);
			});
		return;
	};

	const handleAddSportOrder = () => {
		setAddSpinning(true);

		let sport_id = sportItems && sportItems.length > 0 ? sportSlugs.join(",") : "";
		let sport_order = sportItems && sportItems.length > 0 ? sportsLength.join(",") : "";

		for (var i = 0; i < sportSlugs.length; i++) {
			var row = Number(sportSlugs[i]);
			if (sportFavorite[row]) sportsFavoriteArray.push(1);
			else sportsFavoriteArray.push(0);
		}

		let payload = {
			country_code: sportOrderData.country_code,
			sport_id: sport_id,
			ord: sport_order,
			id: editId,
		};

		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.sport_order_add, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) {
					setGetData(true);
					setSportOrderData({ ...sportOrderData, country_code: "" });
				}
			})
			.finally(() => {
				setTimeout(() => {
					setEditId("");
					setShowEditSport(false);
					setAddSpinning(false);
					getSportTypes();
				}, 1000);
			});
	};

	const filterSports = () => {
		const formatedData = prepareFilterData(sportOrderFilterData, defaultSportsFilterTable);

		let params = "";
		if (formatedData?.country) {
			params += `?country_code=${formatedData?.country}`;
		}
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.sport_order + params, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let data = response.data.return_data;
					if (data) {
						let resp = [...data];
						resp = resp.sort((a, b) => (a.country_name > b.country_name ? 1 : b.country_name > a.country_name ? -1 : 0));
						let arr = [];
						for (var i = 0; i < resp.length; i++) {
							var row = { ...resp[i] };
							if (row.country_code == "all") {
								row.country_name = "All Countries";
							}
							arr.push(row);
						}
						setOrderData(arr);
					}
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const handleDeleteSportOrder = () => {
		setDeleteSpinning(true);
		new Promise((resolve, reject) => {
			ApiServices.delete(apiUrls.sport_order_delete + "?id=" + deleteId, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					setShowDeleteConfirm(false);
					setOrderData(orderData.filter(row => row.id !== deleteId));
				}
			})
			.finally(() => {
				setDeleteSpinning(false);
			});
	};

	const handleCloneSportOrder = () => {
		if (!sportOrderData.country_code) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Please choose country</p>
				</>,
				{ className: "custom-toast" }
			);
		}
		setCloneSpinning(true);
		let payload = {
			id: cloneId,
			country_code: sportOrderData.country_code,
		};
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.sport_order_clone, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) {
					setGetData(true);
					setSportOrderData({
						...sportOrderData,
						country_code: "",
					});
				}
			})
			.finally(() => {
				setTimeout(() => {
					setCloneSpinning(false);
					setCloneId("");
					setShowCloneSport(false);
				}, 1000);
			});
	};

	const getSportTypes = () => {
		getSportsData("all").then(response => setSportsItems(response));
	};

	useEffect(() => {
		if (getData) {
			setGetData(false);
			filterSports();
		}
	}, [getData]);

	useEffect(() => {
		getSportTypes();
		setGetData(true);
	}, []);

	let defaultSportsTableColumns = getDefaultSportsTableColumns({
		orderData,
		editId,
		getEditSportData,
		setEditId,
		setShowCloneSport,
		setCloneId,
		setShowDeleteConfirm,
		setDeleteId,
		editSpinning,
		isUserReadOnly,
	});

	let page_name = "Sports Order";

	const handleOpenModal = () => {
		setShowEditSport(true);
		setEditId("");
		setSportSlugs([]);
		setSportOrderData({
			country_code: "0",
			sport_id: "",
			order: "",
			country_name: "",
		});
		setSportFavorite({
			...sportFavorite,
			...favoriteObj,
		});
	};

	return (
		<HelmetLayout titleProps={"Sports Order"}>
			<div className="cms-page pt-0 cms-table-page">
				{showDeleteConfirm && (
					<ShowDeleteConfirmDefaultSportModal
						showDeleteConfirm={showDeleteConfirm}
						setShowDeleteConfirm={setShowDeleteConfirm}
						deleteSpinning={deleteSpinning}
						handleDeleteSportOrder={handleDeleteSportOrder}
					/>
				)}
				{showEditSport && (
					<ShowEditDefaultSportModal
						showEditSport={showEditSport}
						sportOrderData={sportOrderData}
						sportItems={sportItems}
						setSportsItems={setSportsItems}
						setShowEditSport={setShowEditSport}
						handleAddSportOrder={handleAddSportOrder}
						addSpinning={addSpinning}
						editId={editId}
						countries={countries}
						setSportOrderData={setSportOrderData}
						isUserReadOnly={isUserReadOnly}
						sportSlugs={sportSlugs}
						setSportSlugs={setSportSlugs}
						countriesArr={countriesArr}
					/>
				)}
				{showCloneSport && (
					<ShowCloneSportModal
						showCloneSport={showCloneSport}
						setSportOrderData={setSportOrderData}
						sportOrderData={sportOrderData}
						countries={countries}
						lsGSData={lsGSData}
						setShowCloneSport={setShowCloneSport}
						cloneSpinning={cloneSpinning}
						handleCloneSportOrder={handleCloneSportOrder}
						countriesArr={countriesArr}
					/>
				)}
				<SearchFilter
					columns={defaultSportsFilterTable}
					page={"sport_order"}
					preSelectedType={"select"}
					preSelectedName={"Country"}
					preSelectedData={countries ? countries : []}
					preSelectedOptionKey={"cca2"}
					preSelectedOptionName={"name"}
					filterHandler={filterSports}
					filterLoading={isDataLoading}
					preSelectedValue={"country"}
					className="col-9"
					customizedFilter={true}
				/>
				<MUIDataTable
					columns={defaultSportsTableColumns}
					data={orderData}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						onChangePage(page) {
							setCurrentPage(page);
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						page: currentPage,
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: `${page_name}.csv` },
						customToolbar: () => <AddTableButton onClick={handleOpenModal} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(defaultSports);
