import { SET_SELECTED_BET_REFERRAL, SET_EMPTY_BET_REFERRAL, SET_BET_REFERRAL_PLAYER_SUB_ID, SET_UPDATED_ODDS, SET_FREE_BET_ID } from "./actionTypes";

export const setSelectedBetReferral = payload => {
	return {
		type: SET_SELECTED_BET_REFERRAL,
		payload,
	};
};

export const setEmptyBetReferral = () => {
	return {
		type: SET_EMPTY_BET_REFERRAL,
	};
};

export const setBetReferralPlayerSubId = payload => {
	return {
		type: SET_BET_REFERRAL_PLAYER_SUB_ID,
		payload,
	};
};

export const setUpdatedOdds = payload => {
	return {
		type: SET_UPDATED_ODDS,
		payload,
	};
};

export const setFreeBetId = payload => {
	return {
		type: SET_FREE_BET_ID,
		payload,
	};
};
