import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Alert, Container } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import xIcon from "../../assets/images/x-icon.svg";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { loginLogo, platformName } from "utils/constants";
import SignInFooter from "./SignInFooter";
import * as authService from "utils/AuthService";
import { passwordRequirementsCheck } from "utils/global";
import { cmsLoginUser } from "store/actions";

const SignInFirst = props => {
	const [passwordLength, setPasswordLength] = useState(false);
	const [passwordSpecial, setPasswordSpecial] = useState(false);
	const [passwordNumber, setPasswordNumber] = useState(false);
	const [passwordMatch, setPasswordMatch] = useState(false);
	const [passwordRestriction, setPasswordRestriction] = useState(false);

	const dispatch = useDispatch();
	// handleValidSubmit
	const handleValidSubmit = (event, values) => {
		var session_key = sessionStorage.getItem("session_key");
		var email = sessionStorage.getItem("email");
		const passwordCheck = passwordRequirementsCheck(values.password);

		if (!passwordCheck?.length) {
			setPasswordLength(true);
			setPasswordRestriction(true);
			return;
		} else setPasswordLength(false);

		if (!passwordCheck?.specialCharacter) {
			setPasswordSpecial(true);
			setPasswordRestriction(true);
			return;
		} else setPasswordSpecial(false);

		if (!passwordCheck?.number) {
			setPasswordNumber(true);
			setPasswordRestriction(true);
			return;
		} else setPasswordNumber(false);

		if (values.password !== values.confirmPassword) {
			setPasswordMatch(true);
			setPasswordRestriction(true);
			return;
		}

		var body = {
			email: email,
			password: values.password,
			session: session_key,
		};

		let username = email.split("@")[0];
		if (username == values?.password) {
			toast.error(
				<>
					<img src={xIcon} alt="Error" />
					<p className="toast-icon-manual-message">Password should not be the same as username</p>
				</>,
				{ className: "custom-toast" }
			);
			return false;
		}

		// new global axios helper
		new Promise((resolve, reject) => {
			authService.signInFirst(resolve, reject, body);
		})
			.then(response => {
				if (response) {
					let data = response?.data?.returnData;

					if (data.challenge_name == "SWIFTY_MFA_SETUP") {
						sessionStorage.setItem("qr_code", data.qrcode);
						sessionStorage.setItem("manual_key", data.manual_key);
						sessionStorage.setItem("password", values?.password);
						props.history.push("/two_fa");
					} else {
						localStorage.setItem("email", email);
						localStorage.setItem("access_token", data.AccessToken);
						localStorage.setItem("refresh_token", data.RefreshToken);

						var user = [];
						var user_data = {};
						user_data = data?.userData;
						user.push(user_data);
						if (user && user.length > 0) {
							localStorage.setItem("user", JSON.stringify(user_data));
							dispatch(cmsLoginUser(user_data));
						}

						props.history.push("/");
					}

					// if (data == "2FA_CODE_REQUIRED") {
					// 	sessionStorage.setItem("session_key", data.session);

					// 	props.history.push("/two_fa");
					// } else {
					// 	props.history.push("/dashboard");
					// }
				}
			})
			.catch(err => {
				if (err.response.status === 401) {
					history.push("/login");
				}
			});
	};
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setConfirmShowPassword] = useState(false);

	const eyeIcon = showPassword ? faEyeSlash : faEye;
	const eyeConfirmIcon = showConfirmPassword ? faEyeSlash : faEye;

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const toggleConfirmPasswordVisibility = () => {
		setConfirmShowPassword(!showConfirmPassword);
	};

	useEffect(() => {
		document.body.classList.add("login-body");
		return () => {
			document.body.classList.remove("login-body");
		};
	}, []);

	return (
		<React.Fragment>
			<MetaTags>
				<title>Enter your new password | CMS | {platformName}</title>
			</MetaTags>
			<div className="account-pages my-0 pt-sm-5 my-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5} className="login-flow-card">
							<Card className="overflow-hidden login-card">
								<h3 className="default-logo-login text-center mt-5">
									<div className="d-block auth-logo">
										<img src={loginLogo} alt="" height="60" width="200" className="auth-logo-dark" style={{ cursor: "auto" }} />
									</div>
								</h3>
								<h4 className="login-title">New Password</h4>
								<AvForm
									className="form-horizontal mt-4"
									onValidSubmit={(e, v) => {
										handleValidSubmit(e, v);
									}}
								>
									{props.error && typeof props.error === "string" ? <Alert color="danger">{props.error}</Alert> : null}
									<div className="mb-4 login-label">
										<div className="password-input-wrapper">
											<AvField
												name="password"
												label="New Password"
												type={showPassword ? "text" : "password"}
												placeholder="Enter New Password"
												className="login-input"
												onInput={e => {
													e.preventDefault();
												}}
											/>
											<span className="new-password-toggle-icon" onClick={togglePasswordVisibility}>
												<FontAwesomeIcon icon={eyeIcon} />
											</span>
										</div>
									</div>
									<div className="login-label second-login-label">
										<div className="password-input-wrapper">
											<AvField
												name="confirmPassword"
												label="Confirm Password"
												placeholder="Confirm Password"
												type={showConfirmPassword ? "text" : "password"}
												className="login-input"
												onInput={e => {
													e.preventDefault();
												}}
											/>
											<span className="password-toggle-icon" onClick={toggleConfirmPasswordVisibility}>
												<FontAwesomeIcon icon={eyeConfirmIcon} />
											</span>
										</div>
										{passwordRestriction && (
											<div className="first-change-password-requirements">
												<ul className="first-change-ul">
													{passwordLength && (
														<li className="signIn-li">Must be at least 8 characters and must contain an upper case, a lower case</li>
													)}
													{passwordSpecial && <li className="signIn-li">Must include a special character</li>}
													{passwordNumber && <li className="signIn-li">Must include a number</li>}
													{passwordMatch && <li className="signIn-li">Both passwords must match</li>}
												</ul>
											</div>
										)}
									</div>
									<Row className="mb-3">
										<Col className="text-end">
											<div className="login-label">
												<button className="login-button form-control" type="submit">
													Log In
												</button>
											</div>
										</Col>
									</Row>
								</AvForm>
							</Card>
							<SignInFooter />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(connect()(SignInFirst));
