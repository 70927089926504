import React, { useEffect, useState } from "react";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

import HelmetLayout from "components/HelmetLayout/HelmetLayout";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { capitalizeText } from "services/capitalizeText";
import { getFormatDateByUserTimezone } from "utils/global";

const AffiliateSettings = () => {
	const user = JSON.parse(localStorage.getItem("user"));

	const [data, setData] = useState({
		name: "-",
		currency: "-",
		createdAt: "-",
		taxes: "0.00",
		duties: "0.00",
		admin_fees: "0.00",
		platform_fees: "0.00",
		revenue_share: "0.00",
		sports_provider_fees: "0.00",
		casino_provider_fees: "0.00",
		payment_process_fees: "0.00",
	});

	const getAffiliateSettings = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_AFFILIATE_SETTINGS + `${user?.id}/affiliate-settings`, resolve, reject, true);
		}).then(response => {
			const { success, data } = response;
			if (success) {
				const fullName = capitalizeText(`${data?.first_name} ${data?.last_name}`);
				setData({ ...data, name: fullName, createdAt: data?.createdAt ? getFormatDateByUserTimezone(data?.createdAt) : "-" });
			}
		});
	};

	useEffect(() => {
		getAffiliateSettings();
	}, []);

	const fields = [
		{ type: "input", name: "Name", fieldName: "name", disabled: true },
		{ type: "input", name: "Registered", fieldName: "createdAt", disabled: true },
		{ type: "input", name: "Currency", fieldName: "currency", disabled: true },
		{ type: "input", name: "Rev Share", fieldName: "revenue_share", disabled: true },
		{ type: "input", name: "Taxes", fieldName: "taxes", disabled: true },
		{ type: "input", name: "Duties", fieldName: "duties", disabled: true },
		{ type: "input", name: "Platform Fees", fieldName: "platform_fees", disabled: true },
		{ type: "input", name: "Sports Provider Fees", fieldName: "sports_provider_fees", disabled: true },
		{ type: "input", name: "Casino Provider Fees", fieldName: "casino_provider_fees", disabled: true },
		{ type: "input", name: "Payment processing Fees", fieldName: "payment_process_fees", disabled: true },
		{ type: "input", name: "Admin Fees", fieldName: "admin_fees", disabled: true },
	];

	return (
		<HelmetLayout titleProps={"Affiliate"}>
			<div className="cms-page pt-0">
				<div className="affiliate-white-container">
					<AllComponentsLoop fields={fields} data={data} setData={setData} />
				</div>
			</div>
		</HelmetLayout>
	);
};
export default AffiliateSettings;
