import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import React, { useEffect, useState } from "react";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";
import { useDispatch } from "react-redux";
import { setBackdrop } from "store/Backdrop/actions";
import InformationPageHelper from "../InformationPageHelper";
import { InfoIconHelper } from "utils/icons";
import { BogHelpInformation } from "../HelpPagesForSettings/HelpPages";

function BogSettings() {
	const [data, setData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);
	const [unchagedData, setUnchangedData] = useState([]);
	const [iconHelperContainer, setIconHelperContainer] = useState(false);
	const dispatch = useDispatch();

	let fields = [
		{ type: "switch", name: "Global BOG", fieldName: "enabled" },
		{ type: "input", name: "BOG max payout", fieldName: "max_payment", inputClassName: "general_input", numbersOnly: true },
		{ type: "date", name: "BOG start time (UTC)", fieldName: "start_time", isTimeExist: true, timePicker: true },
		{ type: "dropdown", name: "Countries", fieldName: "countries", optionsType: "country", multiple: true, bgWhite: true },
		{ type: "dropdown", name: "Bet Types", fieldName: "bet_types", optionsType: "bet_types", multiple: true, bgWhite: true },
	];

	useEffect(() => {
		getBogSettings();
	}, []);

	//Get Bog settings
	const getBogSettings = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.trader_options + "?type=bog-settings", resolve, reject, true);
		})
			.then(response => {
				let data = response?.data;
				const countries = JSON.parse(data.countries);
				const bet_types = JSON.parse(data.bet_types);
				setData({ ...data, countries: countries, bet_types: bet_types });
				setUnchangedData(data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	//Edit Bog settings
	const editBogSettings = () => {
		let payload = data;
		delete payload?.bet_types_static;
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.trader_options_edit, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const discardData = () => {
		setData(unchagedData);
		setDataChanged(false);
	};
	const infoIconHandler = () => {
		setIconHelperContainer(true);
		dispatch(setBackdrop(true));
	};
	const closeInfoIconHandler = () => {
		setIconHelperContainer(false);
		dispatch(setBackdrop(false));
	};

	return (
		<HelmetLayout titleProps={"BOG Settings"}>
			<TopBarComponent
				classname="special_top_component"
				saveHandler={editBogSettings}
				dataChanged={dataChanged}
				discardHandler={discardData}
				loading={isDataLoading}
			/>
			{isDataLoading ? (
				<LoadingSpinner className={"pt-5"} />
			) : (
				<div className="cms-page row">
					<div className=" col-12 trader-form">
						<AllComponentsLoop fields={fields} data={data} setData={setData} setDataChanged={setDataChanged} />
					</div>
				</div>
			)}
			{!iconHelperContainer && <InfoIconHelper onClick={infoIconHandler} />}
			{iconHelperContainer && (
				<InformationPageHelper closeHandler={closeInfoIconHandler}>
					<BogHelpInformation />
				</InformationPageHelper>
			)}
		</HelmetLayout>
	);
}

export default BogSettings;
