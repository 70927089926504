import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { tableResponsive, tableRowsPerPage } from "utils/tableSettings";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
// import { getDepositLimitTable } from "./depositLimitTable";
import { getRealityCheckData } from "./realityCheckTable";
import { getSelfExcludedData } from "./selfExcludedTable";
import { ModalOfResponsibleGambling } from "./modalOfResponsibleGambling";
import { getPlayBreaksData } from "./playBreakTable";
import DynamicTable from "./DynamicTable";
import { DeleteDetailsModal } from "./deleteUserPagesModal";
import { platformName } from "utils/constants";
import { toCapitalCase, getFormatDateByUserTimezone } from "utils/global";
import { DarkArrowLeft, RightDarkArrowIcon } from "utils/icons";
import { Spinner } from "react-bootstrap";
import { getResponsibleGamblingDepositLimitsPage } from "./responsibleGamblingDepositLimitTable";
import { getResponsibleGamblingRealityCheckPage } from "./responsibleGamblingRealityCheckTable";
import { getResponsibleGamblingPlayBreakPage } from "./responsibleGamblingPlayBreakTable";
import { getResponsibleGamblingSelfExcludedPage } from "./responsibleGamblingSelfExcludedTable";
import { ThousandSeperated } from "hooks/General";

import ProgressBar from "./progressChart.js";

import "./users.scss";

let dataToEdit = {};

// TODO: FIX this component code !!!

export const ResponsibleGambling = props => {
	const [responsibleGambling, setResponsibleGambling] = useState({
		deposit_limits: [],
		reality_check: {},
		play_break: {},
		self_excluded: {},
	});
	const [responsibleGamblingDetails, setResponsibleGamblingDetails] = useState([]);
	const [limitModalShowing, setLimitModalShowing] = useState({ value: false, type: "" });
	const [deleteItem, setDeleteItem] = useState({ type: "", value: "" });
	const [unchangedData, setUnchangedData] = useState([]);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [responsibleGamblingToEdit, setResponsibleGamblingToEdit] = useState({
		type: "",
		amount: "",
		frequency: "",
		break_period: "",
		exluded_period: "",
		max_deposit_limit: "",
	});
	const [responsibleLimits, setResponsibleLimits] = useState([]);

	let depositLimitsData = responsibleGambling.deposit_limits;
	// let realityCheckData = [responsibleGambling.reality_check];
	// let playBreakData = [responsibleGambling.play_break];
	// let selfExcludedData = [responsibleGambling.self_excluded];
	const detailsPage = props.detailsPage;
	const setDetailsPage = props.setDetailsPage;
	const getUserData = props.getUserData;
	const currency = props.data?.currency;

	// const depositLimitsTable = getDepositLimitTable({ depositLimitsGambling: depositLimitsData, setShowDeleteConfirm, deleteItem, setDeleteItem });
	const responsibleGamblingDepositLimitPage = getResponsibleGamblingDepositLimitsPage();
	const responsibleGamblingRealityCheckPage = getResponsibleGamblingRealityCheckPage();
	const responsibleGamblingPlayBreakPage = getResponsibleGamblingPlayBreakPage();
	const responsibleGamblingSelfExcludedPage = getResponsibleGamblingSelfExcludedPage();

	// const realityChecksTable = getRealityCheckTable({ realityCheckGambling: realityCheckData, setShowDeleteConfirm, deleteItem, setDeleteItem });
	// const playBreaksTable = getPlayBreakTable({ playBreakGambling: playBreakData, setShowDeleteConfirm, deleteItem, setDeleteItem });
	// const selfExcludedTable = getSelfExcludedTable({ selfExcludedGambling: selfExcludedData, setShowDeleteConfirm, setDeleteItem });

	// Get box data formated
	const realityCheckDataFormated = getRealityCheckData(responsibleGambling.reality_check);
	const playBreaksDataFromated = getPlayBreaksData(responsibleGambling.play_break);
	const selfExcludedDataFormated = getSelfExcludedData(responsibleGambling.self_excluded);

	const responsiblesGambling = () => {
		setPageLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_RESPONSIBLE_GAMBLING + props.subId, resolve, reject, true);
		}).then(response => {
			if (response?.success) {
				const data = response?.data;
				setResponsibleGambling(data);
				setResponsibleGamblingToEdit({
					type: "",
					amount: "",
					frequency: data.reality_check.frequency,
					break_period: data.play_break.period,
					exluded_period: data.self_excluded.period,
				});
				setUnchangedData(data);
				setPageLoading(false);
			}
		});
	};

	const getResponsibleTimers = () => {
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.RESPONSIBLE_GAMBLING_TIMERS + props.subId, resolve, reject, true);
		}).then(response => {
			if (response?.success) {
				const data = response?.data;
				setResponsibleLimits(data);
			}
		});
	};

	const editGambling = () => {
		const payload = {
			deposit_limits: responsibleGambling?.deposit_limits.map(deposit =>
				deposit.type == dataToEdit.type ? { ...deposit, amount: dataToEdit.amount, max_deposit_limit: dataToEdit.max_deposit_limit } : deposit
			),
			reality_check_frequency: dataToEdit?.frequency,
			play_break_period: dataToEdit?.break_period,
			self_excluded_period: dataToEdit?.exluded_period,
		};

		if (dataToEdit?.exluded_period) {
			props.setData(prevData => {
				return {
					...prevData,
					user_self_excluded: dataToEdit?.exluded_period !== "-1",
				};
			});
		}

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.EDIT_RESPONSIBLE_GAMBLING + props.subId, resolve, reject, payload, true);
		}).then(() => {
			responsiblesGambling();
			getResponsibleTimers();
		});
	};

	useEffect(() => {
		responsiblesGambling();
		getResponsibleTimers();
	}, []);

	useEffect(() => {
		dataToEdit = responsibleGamblingToEdit;
	}, [responsibleGamblingToEdit]);

	// const MUIOptions = {
	// 	search: false,
	// 	print: false,
	// 	viewColumns: false,
	// 	download: false,
	// 	filter: false,
	// 	pagination: false,
	// 	selectableRows: "none",
	// 	elevation: 0,
	// 	responsive: tableResponsive,
	// 	rowsPerPage: 50,
	// 	rowsPerPageOptions: tableRowsPerPage,
	// };

	const detailResponsibleGamblingMUIOptions = {
		search: false,
		print: true,
		viewColumns: true,
		download: true,
		filter: true,
		pagination: false,
		selectableRows: "none",
		elevation: 0,
		responsive: tableResponsive,
		rowsPerPage: 50,
		rowsPerPageOptions: tableRowsPerPage,
	};

	const boxData = [
		{
			title: "Reality Check",
			type: "reality_check",
			columns: realityCheckDataFormated,
			ctaButtons: [
				{
					buttonName: "Set Reality Check",
					className: "set_button",
					onClickEvent: () => setLimitModalShowing({ value: true, type: "reality_check" }),
				},
				{
					buttonName: "Remove",
					className: "remove_button",
					onClickEvent: () => onRemove("reality check"),
				},
			],
		},
		{
			title: "Play Break",
			type: "play_break",
			columns: playBreaksDataFromated.columns,
			moreInfo: playBreaksDataFromated.moreInfo,
			ctaButtons: [
				{
					buttonName: "Set Play Break",
					className: "set_button",
					onClickEvent: () => setLimitModalShowing({ value: true, type: "play_break" }),
				},
				{
					buttonName: "Remove",
					className: "remove_button",
					disabled: playBreaksDataFromated?.notSet,
					onClickEvent: () => onRemove("play break"),
				},
			],
		},
		{
			title: "Self Excluded",
			type: "self_exclude",
			columns: selfExcludedDataFormated.columns,
			ctaButtons: [
				{
					buttonName: "Set Self Exclusion",
					className: "set_button",
					onClickEvent: () => setLimitModalShowing({ value: true, type: "self_excluded" }),
				},
				{
					buttonName: "Remove",
					className: "remove_button",
					disabled: selfExcludedDataFormated?.notSet,
					onClickEvent: () => onRemove("self exclude"),
				},
			],
		},
	];

	const onRemove = (type, value) => {
		setShowDeleteConfirm(true);

		if (type === "play break" || type === "self exclude") setDeleteItem({ ...deleteItem, type, value, reason: "" });
		else setDeleteItem({ ...deleteItem, type, value });
	};

	// const tableProperties = [
	// 	{
	// 		title: "Deposit Limits",
	// 		data: depositLimitsData,
	// 		columns: depositLimitsTable,
	// 		options: {
	// 			...MUIOptions,
	// 			downloadOptions: { filename: "Deposit Limits - Transaction.csv" },
	// 			textLabels: {
	// 				body: {
	// 					noMatch: " No data to display!", // Custom message for no matching records
	// 				},
	// 			},
	// 		},
	// 		modalButtons: [
	// 			{
	// 				labelExist: true,
	// 				buttonName: "Set Limit",
	// 				onClickEvent: () => {
	// 					setLimitModalShowing({ value: true, type: "deposit_limits" });
	// 					setResponsibleGambling(prevData => ({
	// 						...prevData,
	// 						amount: "",
	// 						type: "",
	// 					}));
	// 				},
	// 			},
	// 			{
	// 				buttonName: "View All",
	// 				onClickEvent: () => setDetailsPage({ type: "deposit_limits" }),
	// 			},
	// 		],
	// 	},
	// 	{
	// 		title: "Reality Check",
	// 		columns: realityChecksTable,
	// 		data: realityCheckData,
	// 		options: {
	// 			...MUIOptions,
	// 			downloadOptions: { filename: "Deposit Limits - Transaction.csv" },
	// 			textLabels: {
	// 				body: {
	// 					noMatch: " No data to display!", // Custom message for no matching records
	// 				},
	// 			},
	// 		},
	// 		extraButtons: { tableExtraButtons },
	// 		modalButtons: [
	// 			{
	// 				buttonName: "Set Reality Check",
	// 				onClickEvent: () => setLimitModalShowing({ value: true, type: "reality_check" }),
	// 			},
	// 			{
	// 				buttonName: "View All",
	// 				onClickEvent: () => setDetailsPage({ type: "reality_check" }),
	// 			},
	// 		],
	// 	},
	// 	{
	// 		title: "Play Break",
	// 		columns: playBreaksTable,
	// 		data: playBreakData,
	// 		options: {
	// 			...MUIOptions,
	// 			downloadOptions: { filename: "Report - Transaction.csv" },
	// 		},
	// 		extraButtons: { tableExtraButtons },
	// 		modalButtons: [
	// 			{
	// 				buttonName: "Set Play Break",
	// 				onClickEvent: () => setLimitModalShowing({ value: true, type: "play_break" }),
	// 			},
	// 			{
	// 				buttonName: "View All",
	// 				onClickEvent: () => setDetailsPage({ type: "play_break" }),
	// 			},
	// 		],
	// 	},
	// 	{
	// 		title: "Self Excluded",
	// 		columns: selfExcludedTable,
	// 		data: selfExcludedData,
	// 		options: {
	// 			...MUIOptions,
	// 			downloadOptions: { filename: "Report - Transaction.csv" },
	// 			pagination: false,

	// 			textLabels: {
	// 				body: {
	// 					noMatch: " No data to display!", // Custom message for no matching records
	// 				},
	// 			},
	// 		},
	// 		extraButtons: { tableExtraButtons },
	// 		modalButtons: [
	// 			{
	// 				buttonName: "Set Self Exclusion",
	// 				onClickEvent: () => setLimitModalShowing({ value: true, type: "self_excluded" }),
	// 			},
	// 			{
	// 				buttonName: "View All",
	// 				onClickEvent: () => setDetailsPage({ type: "self_exclude" }),
	// 			},
	// 		],
	// 	},
	// ];

	const responsibleGamblingDepositLimitTable = [
		{
			data: responsibleGamblingDetails,
			columns: responsibleGamblingDepositLimitPage,
			options: {
				...detailResponsibleGamblingMUIOptions,
				downloadOptions: { filename: "Deposit Limits - Transaction.csv" },
				textLabels: {
					body: {
						noMatch: " No data to display!", // Custom message for no matching records
					},
				},
			},
		},
	];

	const responsibleGamblingRealityCheckTable = [
		{
			data: responsibleGamblingDetails,
			columns: responsibleGamblingRealityCheckPage,
			options: {
				...detailResponsibleGamblingMUIOptions,
				downloadOptions: { filename: "Reality Check -Transaction.csv" },
				textLabels: {
					body: {
						noMatch: " No data to display!", // Custom message for no matching records
					},
				},
			},
		},
	];

	const responsibleGamblingPlayBreakTable = [
		{
			data: responsibleGamblingDetails,
			columns: responsibleGamblingPlayBreakPage,
			options: {
				...detailResponsibleGamblingMUIOptions,
				downloadOptions: { filename: "Play Break -Transaction.csv" },
				textLabels: {
					body: {
						noMatch: " No data to display!", // Custom message for no matching records
					},
				},
			},
		},
	];

	const responsibleGamblingSelfExcludedTable = [
		{
			data: responsibleGamblingDetails,
			columns: responsibleGamblingSelfExcludedPage,
			options: {
				...detailResponsibleGamblingMUIOptions,
				downloadOptions: { filename: "Self Excluded -Transaction.csv" },
				textLabels: {
					body: {
						noMatch: " No data to display!", // Custom message for no matching records
					},
				},
			},
		},
	];

	const fields = [
		// { group: "deposit_limits", type: "dropdown", fieldName: "type", optionsType: "depositLimit", normalWidth: true, required: true },
		{
			group: "deposit_limits",
			type: "input",
			name: "Limit",
			fieldName: "amount",
			inputClassName: "general_input text Capitalized",
			inputContainer: "d-flex flex-column",
			numbersOnly: true,
			required: true,
		},
		{
			group: "deposit_limits",
			type: "input",
			name: "Max Limit",
			fieldName: "max_deposit_limit",
			inputClassName: "general_input text Capitalized",
			inputContainer: "d-flex flex-column",
			numbersOnly: true,
		},
		{
			group: "reality_check",
			type: "dropdown",
			fieldName: "frequency",
			optionsType: "realityCheck",
			normalWidth: true,
			required: true,
		},
		{ group: "play_break", type: "dropdown", fieldName: "break_period", optionsType: "playBreak", normalWidth: true, required: true },
		{
			group: "self_excluded",
			type: "dropdown",
			fieldName: "exluded_period",
			optionsType: "selfExclude",
			normalWidth: true,
			required: true,
		},
	];

	const handleClick = () => {
		editGambling();
		setLimitModalShowing({ value: false });
	};

	const handleCancelClick = () => {
		setResponsibleGambling(unchangedData);
		setResponsibleGamblingToEdit({ type: "", amount: "", frequency: "", break_period: "", exluded_period: "", max_deposit_limit: "" });
		setLimitModalShowing({ value: false });
	};

	const handleClickEvent = () => {
		let payload;

		if (deleteItem?.type == "daily" || deleteItem?.type == "monthly" || deleteItem?.type == "weekly") {
			payload = {
				deposit_limits: [
					{
						type: deleteItem?.type,
						mode: "reset",
					},
				],
			};
		} else {
			if (deleteItem?.type == "reality check") payload = { reality_check_frequency: "-1" };
			else if (deleteItem?.type == "play break") {
				payload = { play_break_period: "-1", reason: deleteItem?.reason };
			} else if (deleteItem?.type == "self exclude") {
				payload = { self_excluded_period: "-1", reason: deleteItem?.reason };
			}
		}

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.EDIT_RESPONSIBLE_GAMBLING + props.subId, resolve, reject, payload, true);
		}).then(response => {
			if (response.success) {
				setShowDeleteConfirm(false);
				responsiblesGambling();
				getResponsibleTimers();
				if (payload?.play_break_period === "-1" || payload?.self_excluded_period === "-1") {
					getUserData();
				}
			}
		});
	};

	const message =
		deleteItem?.type == "daily" || deleteItem?.type == "monthly" || deleteItem?.type == "weekly" ? deleteItem?.type + " " + "limit" : deleteItem?.type;

	const title = `Remove ${toCapitalCase(message)}`;
	const description = `Are you sure you want to remove the  ${message}?`;

	const getResponsibleGamblingDepositLimitDetails = () => {
		setPageLoading(true);
		const user_real_id = props.data.id;
		if (user_real_id) {
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls.RESPONSIBLE_GAMBLING_DEPOSIT_LIMITS_DETAILS + user_real_id, resolve, reject, true);
			})
				.then(response => {
					if (response?.success) {
						const data = response?.data;
						setResponsibleGamblingDetails(data);
					}
				})
				.finally(() => {
					setPageLoading(false);
				});
		}
	};

	const getResponsibleGamblingRealityCheckDetails = () => {
		setPageLoading(true);
		const user_real_id = props.data.id;
		if (user_real_id) {
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls.RESPONSIBLE_GAMBLING_REALITY_CHECK_DETAILS + user_real_id, resolve, reject, true);
			})
				.then(response => {
					if (response?.success) {
						const data = response?.data;
						setResponsibleGamblingDetails(data);
					}
				})
				.finally(() => {
					setPageLoading(false);
				});
		}
	};

	const getResponsibleGamblingPlayBreakDetails = () => {
		setPageLoading(true);
		const user_real_id = props.data.id;
		if (user_real_id) {
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls.RESPONSIBLE_GAMBLING_PLAY_BREAK_DETAILS + user_real_id, resolve, reject, true);
			})
				.then(response => {
					if (response?.success) {
						const data = response?.data;
						setResponsibleGamblingDetails(data);
					}
				})
				.finally(() => {
					setPageLoading(false);
				});
		}
	};

	const getResponsibleGamblingSelfExcludedDetails = () => {
		setPageLoading(true);
		const user_real_id = props.data.id;
		if (user_real_id) {
			new Promise((resolve, reject) => {
				ApiServices.get(apiUrls.RESPONSIBLE_GAMBLING_SELF_EXCLUDED_DETAILS + user_real_id, resolve, reject, true);
			})
				.then(response => {
					if (response?.success) {
						const data = response?.data;
						setResponsibleGamblingDetails(data);
					}
				})
				.finally(() => {
					setPageLoading(false);
				});
		}
	};

	useEffect(() => {
		if (detailsPage?.type == "deposit_limits") {
			getResponsibleGamblingDepositLimitDetails();
		} else if (detailsPage?.type == "reality_check") {
			getResponsibleGamblingRealityCheckDetails();
		} else if (detailsPage?.type == "play_break") {
			getResponsibleGamblingPlayBreakDetails();
		} else if (detailsPage?.type == "self_exclude") {
			getResponsibleGamblingSelfExcludedDetails();
		}
	}, [detailsPage?.type]);

	return (
		<React.Fragment>
			<MetaTags>
				<title>Responsible Gambling - {platformName}</title>
			</MetaTags>
			<div className="col-12 filterResponsive" style={{ justifyContent: "space-between" }}>
				<div className="col-10 d-flex"></div>
			</div>
			{pageLoading ? (
				<div className="text-center mt-3">
					<Spinner animation="border" size="lg" />
				</div>
			) : detailsPage?.type ? (
				<div className="col-12 p-0 px-3 pt-1 mt-3">
					<div className="mb-3">
						<DarkArrowLeft onClick={() => setDetailsPage(false)} />
					</div>
					{detailsPage?.type == "deposit_limits"
						? responsibleGamblingDepositLimitTable.map((row, index) => <DynamicTable object={row} key={index} />)
						: detailsPage?.type == "reality_check"
						? responsibleGamblingRealityCheckTable.map((row, index) => <DynamicTable object={row} key={index} />)
						: detailsPage?.type == "play_break"
						? responsibleGamblingPlayBreakTable.map((row, index) => <DynamicTable object={row} key={index} />)
						: detailsPage?.type == "self_exclude"
						? responsibleGamblingSelfExcludedTable.map((row, index) => <DynamicTable object={row} key={index} />)
						: ""}
				</div>
			) : (
				<>
					<div className="responsible-gambling">
						<div className="responsible-gambling-header">
							<h2>Deposit Limits</h2>
							<button onClick={() => setDetailsPage({ type: "deposit_limits" })}>View All</button>
						</div>
						<div className="deposit_limit box-container">
							{depositLimitsData.map((item, index) => {
								const notSet = item?.amount === -1 ? true : false;
								return (
									<div className="box" key={index}>
										<div className="title">
											<h2 className="type">{item?.type}</h2>
											<span className="max-limit-box">
												<span>Max Limit:</span>
												<span>{`${item?.max_deposit_limit === -1 ? `Not Set` : `${ThousandSeperated(item.max_deposit_limit)} ${currency}`}`}</span>
											</span>
										</div>
										<div className="box-info">
											<div className="info">
												<ul>
													<li className="used">
														Used:&nbsp;&nbsp;&nbsp;&nbsp;
														{item.amount_used ? currency + " " + ThousandSeperated(item.amount_used) : "-"}
													</li>
													<li className="limit">
														Limit:&nbsp;&nbsp;&nbsp;&nbsp;
														{item.amount == "-1" ? "Not Set" : currency + " " + ThousandSeperated(item.amount)}
													</li>
													<li className="reset">
														Next Reset:&nbsp;&nbsp;&nbsp;&nbsp;
														{item.resets_at ? getFormatDateByUserTimezone(item.resets_at) : "-"}
													</li>
												</ul>
											</div>
											<div className="chart">
												<ProgressBar used={item.amount_used} amount={item.amount} />
											</div>
										</div>
										{responsibleLimits
											.filter(({ type }) => type.includes(item.type))
											.map(limit => (
												<div className="more-info" key={limit.type}>
													<h2>Cooldown - Pending Confirmation</h2>
													<ul>
														<li>
															<span>New Limit</span> <span>{limit.value == "-1" ? "Not Set" : currency + " " + limit.value}</span>
														</li>
														<li>
															<span>Start</span> <span>{getFormatDateByUserTimezone(limit?.createdAt, "DD MMM YYYY HH:mm")}</span>
														</li>
													</ul>
												</div>
											))}

										<div className="box-buttons">
											<button
												className="set_button"
												onClick={() => {
													setLimitModalShowing({ value: true, type: "deposit_limits" });
													setResponsibleGamblingToEdit(prevData => ({
														...prevData,
														type: item.type,
														amount: item?.amount === -1 ? "" : item.amount,
														max_deposit_limit: item?.max_deposit_limit === -1 ? "" : item.max_deposit_limit,
													}));
												}}
											>
												Manage Limits
											</button>
											<button className="remove_button" disabled={notSet} onClick={() => onRemove(item.type, item.amount)}>
												Remove
											</button>
										</div>
									</div>
								);
							})}
						</div>

						<div className="box-container">
							{boxData.map(item => {
								return (
									<div className="box" key={item.type}>
										<div className="box-info">
											<div className="info">
												<h2 className="type">{item?.title}</h2>
												<ul>
													{item.columns?.map(col => (
														<li key={col.name}>
															<span>{col.name}</span> <span>{col.value}</span>
														</li>
													))}
												</ul>
											</div>

											<div className="arrow-right cursor-pointer" onClick={() => setDetailsPage({ type: item.type })}>
												<RightDarkArrowIcon />
											</div>
										</div>
										{item.moreInfo && (
											<div className="more-info">
												<p>{item.moreInfo}</p>
											</div>
										)}
										<div className="box-buttons">
											{item.ctaButtons?.map(btn => (
												<button className={btn?.className} disabled={btn?.disabled} key={btn.buttonName} onClick={() => btn?.onClickEvent()}>
													{btn.buttonName}
												</button>
											))}
										</div>
									</div>
								);
							})}
						</div>
					</div>

					<div className="row">
						{/* <div className="col-12 p-0 px-3">
							{tableProperties.map((row, index) => (
								<DynamicTable object={row} key={index} />
							))}
						</div> */}
						<div style={{ zIndex: "10001" }}>
							{limitModalShowing?.type === "deposit_limits" && (
								<ModalOfResponsibleGambling
									title={`Manage ${responsibleGamblingToEdit?.type} Limit`}
									paragraph={"If this is more than the current limit, there will be a 24-hour cooling off period"}
									setLimitModalShowing={setLimitModalShowing}
									setClick={() => {
										editGambling();
										setLimitModalShowing({ value: false });
									}}
									data={responsibleGamblingToEdit}
									property={limitModalShowing?.type}
									setData={setResponsibleGamblingToEdit}
									allFields={fields}
									handleCancelClick={handleCancelClick}
									handleClick={handleClick}
									showError={
										responsibleGamblingToEdit.max_deposit_limit
											? responsibleGamblingToEdit.amount > responsibleGamblingToEdit.max_deposit_limit
												? "Limit cannot exceed Max Limit"
												: ""
											: ""
									}
								/>
							)}
							{limitModalShowing?.type === "reality_check" && (
								<ModalOfResponsibleGambling
									title={"Set Reality Check"}
									paragraph={"The reality check will alert the customer after being on the site for the period of time specified"}
									setLimitModalShowing={setLimitModalShowing}
									setClick={() => {
										editGambling();
										setLimitModalShowing({ value: false });
									}}
									data={responsibleGamblingToEdit}
									property={limitModalShowing?.type}
									setData={setResponsibleGamblingToEdit}
									allFields={fields}
									handleCancelClick={handleCancelClick}
									handleClick={handleClick}
								/>
							)}

							{limitModalShowing?.type === "play_break" && (
								<ModalOfResponsibleGambling
									title={"Set Play Break"}
									paragraph={
										"Specify the length of time for the user to take a play break. They will be unable to place any bets for the selected period"
									}
									setLimitModalShowing={setLimitModalShowing}
									setClick={() => {
										editGambling();
										setLimitModalShowing({ value: false });
									}}
									data={responsibleGamblingToEdit}
									property={limitModalShowing?.type}
									setData={setResponsibleGamblingToEdit}
									allFields={fields}
									handleCancelClick={handleCancelClick}
									handleClick={handleClick}
								/>
							)}

							{limitModalShowing?.type === "self_excluded" && (
								<ModalOfResponsibleGambling
									title={"Set Self Exclusion"}
									paragraph={
										"This will set a self-exclusion from the site. The user will not be able to login/use the site until the exclusion has finished."
									}
									setLimitModalShowing={setLimitModalShowing}
									setClick={() => {
										editGambling();
										setLimitModalShowing({ value: false });
									}}
									data={responsibleGamblingToEdit}
									property={limitModalShowing?.type}
									setData={setResponsibleGamblingToEdit}
									allFields={fields}
									handleCancelClick={handleCancelClick}
									handleClick={handleClick}
								/>
							)}
						</div>
					</div>

					{showDeleteConfirm && (
						<DeleteDetailsModal
							object={{
								modalData: deleteItem,
								setModalData: setDeleteItem,
								setShowModal: setShowDeleteConfirm,
								clickModalEvent: handleClickEvent,
								title: title,
								description: description,
								hasTextArea: deleteItem?.type === "play break" || deleteItem?.type === "self exclude" ? true : false,
								className: "responsible_gambling_modal_container",
							}}
						/>
					)}
				</>
			)}
		</React.Fragment>
	);
};
