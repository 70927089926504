export const SetPreselectedData = (page, searchFilterData, columns, preSelect) => {
	const preselected = columns.map(column =>
		Array.isArray(searchFilterData[page]) && preSelect
			? searchFilterData[page]?.find(item => item?.key == column.value)
			: {
					name: column.name,
					key: column.value,
					selected: "",
					value: "",
					page: page,
			  }
	);
	return preselected;
};
