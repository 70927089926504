export const getPlayersFilterColumns = () => {
	const genders = JSON.stringify([
		{
			value: "",
			name: "All Genders",
		},
		{
			value: "Female",
			name: "Female",
		},
		{
			value: "Male",
			name: "Male",
		},
	]);

	const positions = JSON.stringify([
		{
			value: "",
			name: "All Positions",
		},
		{
			value: "Forward",
			name: "Forward",
		},
		{
			value: "Keeper",
			name: "Keeper",
		},
		{
			value: "Midfielder",
			name: "Midfielder",
		},
		{
			value: "Defender",
			name: "Defender",
		},
		{
			value: "Guard",
			name: "Guard",
		},
		{
			value: "Pitcher",
			name: "Pitcher",
		},
		{
			value: "Rightwing",
			name: "Rightwing",
		},
		{
			value: "Rightback",
			name: "Rightback",
		},
		{
			value: "Striker",
			name: "Striker",
		},
		{
			value: "First base",
			name: "First base",
		},
		{
			value: "Leftback",
			name: "Leftback",
		},
	]);

	const types = JSON.stringify([
		{
			value: "",
			name: "All types",
		},
		{
			value: "Person",
			name: "Person",
		},
		{
			value: "Team",
			name: "Team",
		},
	]);

	return [
		{
			name: "Gender",
			type: "select",
			value: "gender",
			data: genders,
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "Position",
			type: "select",
			value: "position",
			data: positions,
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "Type",
			type: "select",
			value: "type",
			data: types,
			optionKey: "value",
			optionName: "name",
		},
		{
			name: "First Name",
			type: "text",
			value: "first_name",
		},
		{
			name: "Last Name",
			type: "text",
			value: "last_name",
		},
		{
			name: "Display Name",
			type: "text",
			value: "name",
		},
	];
};
