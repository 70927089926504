import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import SearchFilter from "components/SearchFilter/searchFilter";
import { setBreadcrumbItems, cmsSetBetHistoryEmail, cmsSearchFilterData } from "../../../store/actions";
import { setBets, setBetsStat } from "store/Bets/actions";
import { tableExtraButtons, tableResponsive, tableBarSettings } from "utils/tableSettings";
import { getFilterColumns } from "./betsFilterColumns";
import { betsTableShowColumns, getTableBetsColumns } from "./betsTableColumns";
import { betsBreadcrumbItems } from "./betsConstants";
import ApiServices from "utils/ApiServices";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import { apiUrls } from "utils/const.apiUrl";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { assignRowClasses, createParams, prepareFilterData } from "utils/global";
import { getMarkets } from "services/getMarkets";
import { ThousandSeperated } from "hooks/General";
import { SaveFilterData } from "components/SearchFilter/saveFilterData";

// import useWindowSize from "hooks/useWindowSize";
// import { breakpoints } from "utils/constants";

import "./Bets.css";
import "./Bets.scss";

const Bets = props => {
	const sports = useSelector(state => state.Login.gsData.sports);
	const regions = useSelector(state => state.Login.gsData.regions);
	const searchBetsFilters = useSelector(state => state.Login.searchFilterData.bets);

	const [events, setEvents] = useState([]);
	const [markets, setMarkets] = useState([]);
	const [competitions, setCompetitions] = useState([]);
	const [marketsSelections, setMarketSelections] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [records, setRecords] = useState([]);
	const breadcrumbItems = betsBreadcrumbItems;
	const dispatch = useDispatch();
	const [viewColumns, setViewColumns] = useState(betsTableShowColumns);
	const [bgColors, setBgColors] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowPerPage, setRowPerPage] = useState(100);
	const [betsStats, setBetsStats] = useState([
		{ name: "Total no. of bets", value: 0, hasCurrency: "" },
		{ name: "Total stakes", value: 0, hasCurrency: "" },
		{ name: "Average stakes", value: 0, hasCurrency: "" },
		{ name: "Total P/L", value: 0, hasCurrency: "" },
	]);

	const [defaultSearch, setDefaultSearch] = useState(false);
	const betsInStore = useSelector(state => state.Bets.searchBets);
	const betsStatsStore = useSelector(state => state.Bets.betsStats);

	useEffect(() => {
		props.setBreadcrumbItems("Bets", breadcrumbItems);
		dispatch(cmsSetBetHistoryEmail(""));
		getMarketsData();

		if (betsInStore.length) setRecords(betsInStore);
		if (betsStatsStore.length) setBetsStats(betsStatsStore);
	}, []);

	const filterBets = betsFilterData => {
		setIsDataLoading(true);

		let end_time = "";
		let start_time = "";
		let event_start_date = "";
		let event_end_date = "";

		if (betsFilterData.time) {
			if (betsFilterData.time.indexOf("&") > -1) {
				const betted_date = betsFilterData.time.split("&");
				start_time = moment(betted_date[0].trim(), "YYYY-MM-DD HH:mm:ss").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(betted_date[1].trim(), "YYYY-MM-DD HH:mm:ss").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			} else if (betsFilterData.time == filterColumns.find(({ value }) => value == "time")?.defaultValue) {
				start_time = moment.utc().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment.utc().format("YYYY-MM-DD 23:59:59");
			} else {
				start_time = moment(betsFilterData.time.trim(), "YYYY-MM-DD HH:mm:ss").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				end_time = moment(betsFilterData.time.trim(), "YYYY-MM-DD HH:mm:ss").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			}
		} else if (!betsFilterData.bet_id) {
			start_time = moment.utc().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss");
			end_time = moment.utc().format("YYYY-MM-DD 23:59:59");
		}

		if (betsFilterData.event_date) {
			if (betsFilterData.event_date.indexOf("&") > -1) {
				const betted_date = betsFilterData.event_date.split("&");
				event_start_date = moment(betted_date[0].trim(), "YYYY-MM-DD HH:mm:ss").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				event_end_date = moment(betted_date[1].trim(), "YYYY-MM-DD HH:mm:ss").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			} else {
				event_start_date = moment(betsFilterData.event_date.trim(), "YYYY-MM-DD HH:mm:ss").startOf("day").format("YYYY-MM-DD HH:mm:ss");
				event_end_date = moment(betsFilterData.event_date.trim(), "YYYY-MM-DD HH:mm:ss").endOf("day").format("YYYY-MM-DD HH:mm:ss");
			}
		}

		let bet_created_start = start_time;
		let bet_created_end = end_time;

		let wage = betsFilterData?.wage;

		const filterPayloadData = filterColumns.filter(({ value }) => !["sport_id", "time", "event_date"].includes(value)).map(({ value }) => value);

		const newBetsFilterData = {
			...betsFilterData,
			bet_created_start,
			bet_created_end,
			event_start_date,
			event_end_date,
			sport_slug: betsFilterData?.sport_id,
			wage,
			result: betsFilterData?.result || "",
		};
		const newBetsfilterPayloadData = [
			...filterPayloadData,
			"sport_slug",
			"bet_created_start",
			"bet_created_end",
			"event_start_date",
			"event_end_date",
			"wage",
		];

		let params = createParams(newBetsFilterData, newBetsfilterPayloadData);

		getBetsData(params);
	};

	const getSelectionsData = market_id => {
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.get_selections}?market_id=${market_id}`, resolve, reject, true);
		}).then(response => {
			setMarketSelections(response.data);
		});
	};

	const handleBetOdds = id => {
		// TODO - Why do we need this?
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.get_bet_odds}?selection_id=${id}`, resolve, reject, true);
		}).then(() => {});
	};

	const getBetsData = params => {
		let url = params ? apiUrls.get_bets + params : apiUrls.get_bets;
		new Promise((resolve, reject) => {
			ApiServices.get(url, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;
				const { betsStats, returnResult } = data;

				setRecords(returnResult);
				dispatch(setBets(returnResult));
				//
				setBetsStats([
					{ name: "Total no. of bets", value: betsStats?.totalBets, hasCurrency: "" },
					{ name: "Total stakes", value: betsStats?.totalStakes, hasCurrency: betsStats?.currency_symbol },
					{ name: "Average stakes", value: betsStats?.AverageStake, hasCurrency: betsStats?.currency_symbol },
					{ name: "Total P/L", value: betsStats?.totalPL, hasCurrency: betsStats?.currency_symbol },
				]);
				dispatch(
					setBetsStat([
						{ name: "Total no. of bets", value: betsStats?.totalBets, hasCurrency: "" },
						{ name: "Total stakes", value: betsStats?.totalStakes, hasCurrency: betsStats?.currency_symbol },
						{ name: "Average stakes", value: betsStats?.AverageStake, hasCurrency: betsStats?.currency_symbol },
						{ name: "Total P/L", value: betsStats?.totalPL, hasCurrency: betsStats?.currency_symbol },
					])
				);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const getMarketsData = sport_id => {
		const param = `?sport_slug=${sport_id}`;
		getMarkets(param).then(response => {
			setMarkets(response);
		});
	};

	const getEventsData = sport_slug => {
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.bets_sport_comp}?type=events&sport_slug=${sport_slug}`, resolve, reject, true);
		}).then(response => {
			setEvents(response.data);
		});
	};

	const getCompetitions = sport_slug => {
		new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.bets_sport_comp}?type=competitions&sport_slug=${sport_slug}`, resolve, reject, true);
		}).then(response => {
			setCompetitions(response.data);
		});
	};

	const filterColumns = getFilterColumns({ competitions, markets, regions, marketsSelections, sports, events });
	let tableBetsColumns = getTableBetsColumns({ records, handleBetOdds, bgColors, props });

	useEffect(() => {
		if (records.length) assignRowClasses(records, setBgColors, "player_id");
	}, [records]);

	useEffect(() => {
		if (filterColumns?.selected) {
			getCompetitions(filterColumns?.sport_id);
			getEventsData(filterColumns?.sport_id);
		}
	}, [filterColumns?.sport_id]);

	useEffect(() => {
		if (!searchBetsFilters?.length) return;
		const formatedData = prepareFilterData(searchBetsFilters, filterColumns);
		const isNotEmpty = Object.keys(formatedData).some(k => !!formatedData[k]);

		if (!isNotEmpty) {
			const start_time = moment.utc().subtract(30, "day").format("YYYY-MM-DD HH:mm:ss");
			const end_time = moment.utc().format("YYYY-MM-DD 23:59:59");

			const time = start_time + " & " + end_time;

			const newData = {
				result: { selected: "open", value: "Open" },
				time: { selected: time, value: time },
			};

			const defaultSearchBets = searchBetsFilters?.map(filter => {
				if (newData[filter?.key]?.selected) {
					return { ...filter, selected: newData[filter?.key]?.selected, value: newData[filter?.key]?.value };
				}

				return filter;
			});

			SaveFilterData("bets", defaultSearchBets, dispatch, cmsSearchFilterData);

			const newFilterData = prepareFilterData(defaultSearchBets, filterColumns);
			filterBets(newFilterData);

			setDefaultSearch(true);
		}
		// else {
		// 	filterBets(formatedData);
		// }
	}, [searchBetsFilters?.length]);

	return (
		<HelmetLayout titleProps={"Bets"}>
			<div className="cms-page pt-0 bets-page">
				<SearchFilter
					columns={filterColumns}
					page={"bets"}
					preSelectedType={"select"}
					preSelectedValue={"sport_id"}
					preSelectedName={"Sport"}
					preSelectedData={sports ? sports : []}
					preSelectedOptionKey={"id"}
					preSelectedOptionName={"name"}
					filterHandler={filterBets}
					filterLoading={isDataLoading}
					marketsHandler={getCompetitions}
					eventsHandler={getEventsData}
					selectionsHandler={getSelectionsData}
					handler={""}
					hasHandler={false}
					customizedFilter={true}
					dep={{ defaultSearch }}
				/>

				<div className="bets-container">
					<div className="bets-stats">
						{betsStats.map((row, i) => {
							const { name, value, hasCurrency } = row;
							return (
								<div key={i} className="bets-more-data">
									{name}
									{": "}
									<span>
										{hasCurrency ? hasCurrency : ""}
										{!value ? 0 : hasCurrency ? ThousandSeperated(value) : value}
									</span>
								</div>
							);
						})}
					</div>
					<MUIDataTable
						columns={tableBetsColumns}
						data={records}
						options={{
							...tableBarSettings,
							selectableRows: "none",
							viewColumns: true,
							elevation: 0,
							// On table columns get which columns are showing
							onViewColumnsChange(changedColumn) {
								Object.keys(viewColumns).forEach(key => {
									if (key == changedColumn) {
										var tempObj = viewColumns;
										var changedColumnValue = !viewColumns[key];
										tempObj[key] = changedColumnValue;
										setViewColumns(tempObj);
									}
								});
							},
							textLabels: {
								body: {
									noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
								},
							},
							setRowProps: (row, dataIndex, rowIndex) => {
								const { status } = records[rowIndex];

								return {
									className: `${bgColors[rowIndex]} ${status.includes("Accepted") || status.includes("Approved") ? "cursor-pointer" : ""}`,
								};
							},
							onChangePage(page) {
								setCurrentPage(page);
							},
							onChangeRowsPerPage(number) {
								setRowPerPage(number);
							},
							onRowClick: (rowData, rowMeta) => {
								const { status, betId } = records[rowMeta.dataIndex];
								if (status.includes("Accepted") || status.includes("Approved")) props.history.push(`/bet/${betId}`);
							},
							pagination: true,
							responsive: tableResponsive,
							downloadOptions: { filename: "Report - Bets.csv" },
							page: currentPage,
							rowsPerPage: rowPerPage,
						}}
						extraButtons={tableExtraButtons}
					/>
				</div>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(Bets);
