import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let result = [];

export const getCasinoThemesDropdown = async () => {
	if (result?.length) return result;

	return new Promise((resolve, reject) => {
		ApiServices.get(apiUrls.GET_CASINO_THEMES, resolve, reject, true);
	})
		.then(response => {
			let themes = response?.data;

			themes = themes.map(row => {
				return { id: row.slug, label: row.title };
			});

			result = themes;
			return themes;
		})
		.catch(() => {
			result = [];
			return [];
		});
};
