import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { connect, useSelector } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { tableExtraButtons, tableResponsive, tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getMarketsGroupColumns } from "./marketsGroupColumns";
import { AddMarketsGroupsModal } from "./addMarketsGroup";
import ApiServices from "utils/ApiServices";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { apiUrls } from "utils/const.apiUrl";
import MUIDataTable from "mui-datatables";
import { platformName } from "utils/constants";
import { getMarkets } from "services/getMarkets";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";

const MarketsGroup = () => {
	// States
	const [rowPerPage, setRowPerPage] = useState(50);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [recordsData, setRecordsData] = useState([]);
	const [addMarketsGroup, setAddMarketsGroup] = useState({
		sport_id: "",
		name: "",
	});
	const [modalShow, setModalShow] = useState({
		addMarketGroup: false,
	});
	const sports = useSelector(state => state.Login.gsData.sports);
	const lsGSData = localStorage.getItem("gsData") ? JSON.parse(localStorage.getItem("gsData")) : [];
	const [loadingButton, setLoadingButton] = useState({
		filterButton: false,
		modalButton: false,
		editSelectionButton: false,
	});
	const [favoriteCompetitions, setFavoriteCompetitions] = useState([]);
	const [markets, setMarkets] = useState([]);
	const [sportId, setSportId] = useState("");
	const [sportFilterSpinning, setSportFilterSpinning] = useState(false);
	const [marketsFilterSpinnings, setMarketsFilterSpinnings] = useState(false);

	let records = [];

	// Filter markets group
	const filterMarketsGroup = async () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.markets_group, resolve, reject, true);
		})
			.then(response => {
				if (response.success) {
					let data = response.data;
					data.map(marketsGroup => {
						let obj = {
							id: marketsGroup.id,
							sport_id: marketsGroup.sport_id,
							name: marketsGroup.name,
						};
						records.push(obj);
					});
					setRecordsData(records);
				}
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const submitAddMarketGroup = () => {
		setLoadingButton({ ...loadingButton, modalButton: true });
		setIsDataLoading(true);
		let favDat = [];
		favoriteCompetitions.map(fc => {
			let obj = {
				id: fc.market_id,
				name: fc.name,
				add_true: fc.added_new,
			};
			favDat.push(obj);
		});
		var payload = {
			sport_id: addMarketsGroup.sport_id,
			name: addMarketsGroup.name,
			market_ids: JSON.stringify(favDat),
		};
		new Promise((resolve, reject) => {
			ApiServices.put(apiUrls.add_market_group, resolve, reject, payload, true);
		})
			.then(response => {
				if (response.success) {
					let data = response.data;
					data.map(marketsGroup => {
						let obj = {
							id: marketsGroup.id,
							sport_id: marketsGroup.sport_id,
							name: marketsGroup.name,
						};
						records.push(obj);
					});
					setRecordsData(records);
				}
			})
			.finally(() => {
				setLoadingButton({ ...loadingButton, modalButton: false });
				setModalShow({ ...modalShow, addMarketGroup: false });
				setIsDataLoading(false);
			});
	};

	const handleSportChange = sport => {
		setSportFilterSpinning(true);
		setMarketsFilterSpinnings(true);
		const param = "?sport_slug=" + sport + "&active=1";
		getMarkets(param)
			.then(data => {
				var result = data.filter(item => item.sport_slug == sport);
				setMarkets(result);
			})
			.finally(() => {
				setSportFilterSpinning(false);
				setMarketsFilterSpinnings(false);
			});
	};

	useEffect(() => {
		filterMarketsGroup();
	}, []);

	const handleOpenModal = () => {
		setAddMarketsGroup({
			sport_id: 0,
			name: "",
		});
		setModalShow({ ...modalShow, addMarketGroup: true });
	};
	let marketsGroupTableColumns = getMarketsGroupColumns({ recordsData });

	let page_name = "Markets Group";

	return (
		<div>
			<MetaTags>
				<title>
					{page_name} - {platformName}
				</title>
			</MetaTags>
			<div className="cms-page pt-0 cms-table-page">
				<div
					className="col-12 d-flex"
					style={{
						justifyContent: "space-between",
					}}
				>
					<div className="col-10"></div>
				</div>
				<AddMarketsGroupsModal
					modalShow={modalShow}
					setAddMarketsGroup={setAddMarketsGroup}
					addMarketsGroup={addMarketsGroup}
					sports={sports}
					lsGSData={lsGSData}
					submitAddMarketGroup={submitAddMarketGroup}
					loadingButton={loadingButton}
					setModalShow={setModalShow}
					handleSportChange={handleSportChange}
					setSportId={setSportId}
					sportId={sportId}
					sportFilterSpinning={sportFilterSpinning}
					marketsFilterSpinnings={marketsFilterSpinnings}
					markets={markets}
					setFavoriteCompetitions={setFavoriteCompetitions}
					favoriteCompetitions={favoriteCompetitions}
				/>
				<MUIDataTable
					columns={marketsGroupTableColumns}
					data={recordsData}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						responsive: tableResponsive,
						rowsPerPage: rowPerPage,
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: "Report - " + page_name + ".csv" },
						customToolbar: () => <AddTableButton onClick={() => handleOpenModal()} />,
					}}
					extraButtons={tableExtraButtons}
				/>
			</div>
		</div>
	);
};
export default connect(null, { setBreadcrumbItems })(MarketsGroup);
