import React from "react";
import { DeleteIconButton, EditIconButton, LanguageShortcuts } from "components/GeneralComponents/AddButton";

export const getPagesContentTableColumns = ({ props, records, setDeleteId, setShowDeleteModal, setDeleteTitle }) => {
	return [
		{
			name: "name",
			label: "NAME",
			className: "name",
			left: true,
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),
			},
		},
		{
			name: "page_meta_key",
			label: "KEY",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "250px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "250px" } }),
			},
		},
		{
			name: "language",
			label: "LANGUAGES",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					const languageString = Array.isArray(record.language) ? record.language[0] : record.language;
					const languages = languageString.split(",");

					if (languages.length > 1 || (languages.length === 1 && languages[0].trim() !== "")) {
						return (
							<div style={{ display: "flex", gap: "5px" }}>
								{languages.map((language, index) => (
									<LanguageShortcuts key={index} language={!language ? "" : language.trim().slice(0, 2).toUpperCase()} />
								))}
							</div>
						);
					} else {
						const language = languages[0];
						return <LanguageShortcuts language={!language ? "" : language.trim().slice(0, 2).toUpperCase()} />;
					}
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100%", width: "100%" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100%" } }),
			},
		},

		{
			name: "",
			className: "name",
			left: true,
			sort: "asc",
			sortable: true,
			options: {
				viewColumns: false,
				filter: false,
				customBodyRender: (value, meta) => {
					const record = records[meta.currentTableData[meta.rowIndex].index];
					return (
						<div className="d-flex" style={{ justifyContent: "flex-end" }}>
							<div className="heroBanners-buttons">
								<EditIconButton
									onClick={() => {
										props.history.push(`/page_content/${record.id}`);
									}}
								/>
								<DeleteIconButton
									onClick={() => {
										if (!record?.protected) {
											setDeleteId(record.page_meta_key);
											setDeleteTitle(record?.name);
											setShowDeleteModal(true);
										}
									}}
									isDisabled={record?.protected ? true : false}
								/>
							</div>
						</div>
					);
				},
				setCellProps: () => ({ style: { textAlign: "left", minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
	];
};
