import React from "react";
import { DragAndDropFillIcon, DregAndDropIcone } from "utils/icons";
import { GeneralDropdown, GeneralInput } from "components/GeneralComponents/CustomInputs";
import { getTrainerAndJockeyForTelebetSis } from "utils/global";

export const getPositionsTableColumns = ({ positionsData, handlerSelectionStatus, getTricastandFourcastData }) => {
	const lastThreeItems = positionsData?.slice(-3);

	return [
		{
			name: "name",
			label: "NAME",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				customBodyRender: (value, meta) => {
					const record = meta;
					const { name, jockey, trainer } = record;
					return (
						<div>
							<span>{name}</span> <br />
							<span className="fs-6">{getTrainerAndJockeyForTelebetSis(trainer, jockey)}</span>
						</div>
					);
				},
				setCellProps: () => ({ style: { minWidth: "250px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "250px" } }),
			},
		},
		{
			name: "runner_num",
			label: "RUNNER NUM",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				customBodyRender: (value, meta) => {
					const record = meta;
					const { runner_num } = record;
					return runner_num ? runner_num : "-";
				},
				setCellProps: () => ({ style: { minWidth: "110px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "110px" } }),
			},
		},
		{
			name: "position",
			label: "POSITION",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				customBodyRender: (value, meta) => {
					const record = meta;
					const { position } = record;

					return (
						<div className="d-flex justify-content-start">
							<GeneralInput
								object={{
									value: position || "",
									inputContainer: "event-result-sp-input",
									inputClassName: "text-right",
								}}
								onChange={e => getTricastandFourcastData(e.target.value, "position", record, "table")}
							/>
						</div>
					);
				},

				setCellProps: () => ({ style: { minWidth: "100px", textAlign: "start" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px", textAlign: "start" } }),
			},
		},
		{
			name: "price",
			label: "PRICE",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				customBodyRender: (value, meta) => {
					const record = meta;
					const { price } = record;
					return price ? price : "-";
				},
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
		{
			name: "sp",
			label: "SP",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				customBodyRender: (value, meta) => {
					const record = meta;
					const { sp } = record;
					return (
						<div className="d-flex justify-content-start">
							<GeneralInput
								object={{
									value: sp || "",
									inputContainer: "event-result-sp-input",
									inputClassName: "text-right",
								}}
								onChange={e => getTricastandFourcastData(e.target.value, "sp", record, "table")}
							/>
						</div>
					);
				},

				setCellProps: () => ({ style: { minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { textAlign: "start", minWidth: "200px" } }),
			},
		},
		{
			name: "non_runner",
			label: "NON RUNNER",
			sortable: true,
			sort: "asc",
			className: "sport_name",
			options: {
				customBodyRender: (value, meta) => {
					const record = meta;
					const { status, selection_id } = record;
					const isOpenDropdownUp = lastThreeItems.some(row => row.selection_id === selection_id);

					return (
						<div className="d-flex justify-content-start w-100">
							<GeneralDropdown
								handleChange={e => handlerSelectionStatus(e?.id, record)}
								object={{
									name,
									value: status,
									options: _raceSelectionStatus,
									wrapperClassNameDropdown: `p-0 w-100 ${isOpenDropdownUp ? "open_dropdown_up" : ""}`,
								}}
							/>
						</div>
					);
				},
				setCellProps: () => ({ style: { minWidth: "200px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "200px" } }),
			},
		},
		{
			name: "",
			label: "",
			className: "",
			sortable: false,
			filter: false,
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value, meta, other) => {
					let { dragHandleProps, isDragging } = other;
					return (
						<>
							<div className="d-flex justify-content-end">
								<div
									{...dragHandleProps}
									onMouseDown={(...args) => {
										dragHandleProps.onDragStart(...args);
									}}
								>
									<div>{isDragging ? <DragAndDropFillIcon /> : <DregAndDropIcone />}</div>
								</div>
							</div>
						</>
					);
				},
				setCellProps: () => ({ style: { minWidth: "100px" } }),
				setCellHeaderProps: () => ({ style: { minWidth: "100px" } }),
			},
		},
	];
};

export const _raceSelectionStatus = [
	{ id: "Runner", label: "Runner" },
	{ id: "NonRunner", label: "Non Runner" },
	{ id: "PulledUp", label: "Pulled Up" },
];
