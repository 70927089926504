import React, { useState, useEffect, useMemo } from "react";
import { BetInfoIcon } from "utils/icons";

import { useSelector } from "react-redux";
import { getOdds } from "utils/global";

import useGenerateBetslip from "hooks/betslip";

const TelebetCombinations = ({ row }) => {
	const [value, setValue] = useState(row.stake > 0 ? row.stake : "");
	const [isEW, setIsEW] = useState(false);

	const telebetBets = useSelector(state => state.Telebet.telebetBets);

	const allowEachWay = useMemo(() => row.allow_each_way, [row.allow_each_way]);

	const generateBetslip = useGenerateBetslip();

	const handlerSetMultipleStake = (value, ew, type) => {
		const temp = { ...telebetBets };

		let stakeExist = false;

		temp.stakes.forEach(stake => {
			if (stake.type === type) {
				stake.stake = value;
				stakeExist = true;

				if (allowEachWay) stake.each_way = ew;
			}
		});

		if (!stakeExist) {
			const newStake = { stake: value, type };

			if (allowEachWay) newStake.each_way = ew;

			temp.stakes.push(newStake);
		}

		generateBetslip(temp, 300);
	};

	const onEwChange = () => {
		setIsEW(!isEW);
		handlerSetMultipleStake(value, !isEW, row?.type);
	};

	const handleBetStakesChange = value => {
		setValue?.(value);
		handlerSetMultipleStake(value, isEW, row?.type);
	};

	useEffect(() => {
		setIsEW(row?.each_way);
	}, [row?.each_way]);

	return (
		<div className="bet-slip-combination">
			<div className="d-flex">
				<BetInfoIcon />
				<span className="combination-name">{row?.name}</span>
			</div>
			<div className="selected-betslip-amounts ms-2" style={{ minWidth: "180px", maxWidth: "180px" }}>
				<div className="d-flex">
					<input
						type="number"
						placeholder="0.00"
						className="selected-betslip-input"
						onChange={e => handleBetStakesChange(e.target.value)}
						value={value || ""}
						min={0}
						// value={row?.stake != 0 ? row?.stake : tempCombinations[row?.type] != 0 ? tempCombinations[row?.type] : ""}
					/>
					<span className={`selected-betslip-odd ${row?.allow_each_way ? "rounded-0" : ""}`}>
						{getOdds({ decimal: row?.odds_decimal, fractional: row?.odds_fractional, sport_slug: row?.sport_slug }, "decimal", true)}
					</span>

					{row?.allow_each_way && (
						<div className="betslip-input-item-each_way">
							<span>EW</span>
							<input type="checkbox" onChange={onEwChange} checked={isEW} />
						</div>
					)}
				</div>
				<div className="selected-betslip-returns">Returns: {row?.payout}</div>
			</div>
		</div>
	);
};

export default TelebetCombinations;
