import React, { useState } from "react";
import { TopBarComponent } from "components/GeneralComponents/TopBarComponent";
import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";
import { XCloseIcon } from "utils/icons";
import { formatEventTier } from "utils/global";
import AllComponentsLoop from "components/GeneralComponents/AllComponentsLoop";

export const EditCompetition = props => {
	const { setEditCompetition, competition, getSportCompetition, sport_slug, sisProvider } = props;

	const [loading, setLoading] = useState(false);
	const [dataChanged, setDataChanged] = useState(false);

	const [competitionData, setCompetitonData] = useState({
		competition_name: competition?.competition_name,
		early_price_show: competition?.early_price_show || 0,
		early_price_start_time: competition?.early_price_start_time || null,
		prematch_grade: formatEventTier(competition?.prematch_grade),
		in_play_grade: formatEventTier(competition?.in_play_grade),
		enable_prematch: competition?.enable_prematch,
		enable_inplay: competition?.enable_inplay,
	});

	const editCompetition = () => {
		let payload;

		if (sisProvider)
			payload = {
				name: competitionData?.competition_name,
				early_price_show: competitionData?.early_price_show,
				early_price_start_time: competitionData?.early_price_start_time,
				competition_id: competition?.competition_id,
			};
		else {
			payload = {
				name: competitionData?.competition_name,
				prematch_grade: competitionData?.prematch_grade,
				in_play_grade: competitionData?.in_play_grade,
				enable_prematch: competitionData?.enable_prematch,
				enable_inplay: competitionData?.enable_inplay,
				id: competition?.id,
				competition_id: competition?.competition_id,
			};
		}

		setLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.EDIT_SPORT_COMPETITION, resolve, reject, payload, true);
		})
			.then(() => {
				setDataChanged(false);
				getSportCompetition("save_edit");
			})
			.finally(() => {
				setEditCompetition(false);
				setLoading(false);
			});
	};

	const fields = [
		{ type: "input", name: "Name", fieldName: "competition_name" },
		{ type: "dropdown", name: "Prematch Grade", fieldName: "prematch_grade", optionsType: "tier_type", id: sport_slug },
		{ type: "dropdown", name: "In-Play Grade", fieldName: "in_play_grade", optionsType: "tier_type", id: sport_slug },
		{ type: "switch", name: "Pre-Match", fieldName: "enable_prematch" },
		{ type: "switch", name: "In-Play", fieldName: "enable_inplay" },
	];

	const SiSfields = [
		{ type: "input", name: "Name", fieldName: "competition_name" },
		{ type: "switch", name: "Show Early Prices", fieldName: "early_price_show" },
		{ type: "date", name: "Early Price Start Time (UTC)", fieldName: "early_price_start_time", isTimeExist: true, timePicker: true },
	];

	return (
		<div className="promotion-container">
			<div className="promotion-header">
				<span>{"Competition Settings"}</span>
				<XCloseIcon onClick={() => setEditCompetition(false)} className={"xclose-icon"} />
			</div>
			<div className="promotion-body">
				<TopBarComponent saveHandler={editCompetition} loading={loading} dataChanged={dataChanged} onClose={() => setEditCompetition(false)} />
				<div className="promotion-elements row m-0">
					<div className="promotion-left col-12 col-lg-6 promotion-inputs">
						<AllComponentsLoop
							fields={sisProvider ? SiSfields : fields}
							data={competitionData}
							setData={setCompetitonData}
							setDataChanged={setDataChanged}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
