export const getVenueFilterColumns = ({ regions }) => {
	const regionsData = regions ? JSON.stringify(regions) : [];

	const locations = JSON.stringify([
		{
			value: "Yes",
			name: "Yes",
		},
		{
			value: "No",
			name: "No",
		},
	]);
	return [
		{
			name: "Name",
			type: "text",
			value: "name",
		},
		{
			name: "Region",
			type: "select",
			value: "region_id",
			data: regionsData,
			optionKey: "code",
			optionName: "name",
		},
		{
			name: "With location",
			type: "select",
			value: "with_location",
			data: locations,
			optionKey: "value",
			optionName: "name",
		},
	];
};
