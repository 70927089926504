import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let result = [];
let prevParam = [];
let prevCurrency = "";

export const getAmountFreeBetDropdown = async (param, sub_id, currency = "") => {
	if (param[0] === prevParam[0] && prevCurrency === currency) return result;

	if (param.length) {
		let subId = sub_id ? `&sub_id=${sub_id}` : "";
		let currencyParam = currency ? `&currency=${currency}` : "";

		return new Promise((resolve, reject) => {
			ApiServices.get(`${apiUrls.GET_AMOUNTS_FREEBET}/?game_slug=${param[0]}${subId}${currencyParam}`, resolve, reject, true);
		})
			.then(response => {
				prevParam = param;
				prevCurrency = currency;
				let { data } = response;

				data = data.map(row => {
					return { id: Number(row.id), label: row.title };
				});
				result = data;
				return data;
			})
			.catch(() => {
				result = [];
				return [];
			});
	}
};
