import React, { useEffect, useState } from "react";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

import HelmetLayout from "components/HelmetLayout/HelmetLayout";

import MUIDataTable from "mui-datatables";
import { tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getAffiliateMonthlyOverviewColumns } from "./getAffiliateMonthlyOverviewColumns";

import LoadingSpinner from "components/Loader/LoadingSpinner";
import { toFixed } from "pages/BetTicker/BetTicketHelpers";

const AffiliateMonthlyOverview = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const getAffiliateMonthlyOverview = () => {
		setLoading(false);
		setData([]);

		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.GET_AFFILIATE_MONNTHLY_OVERVIEW, resolve, reject, true);
		})
			.then(response => {
				const { data } = response;

				const formattedData = [];

				for (const [monthYear, currencies] of Object.entries(data)) {
					const [year, month] = monthYear.split("-");
					const formattedMonth = new Date(year, month - 1).toLocaleString("default", { month: "short", year: "numeric" });

					for (const [currency, values] of Object.entries(currencies)) {
						console.log(values, "values");
						formattedData.push({
							month: formattedMonth,
							sports_ggr: values.sports_ggr,
							total_payable: `${toFixed(values.total_payable)} ${currency}`,
							casino_ggr: values.casino_ggr,
						});
					}
				}

				setData(formattedData);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		getAffiliateMonthlyOverview();
	}, []);

	const columns = getAffiliateMonthlyOverviewColumns({ data });

	return (
		<HelmetLayout titleProps={"Affiliate"}>
			<div className="cms-page pt-0 gap-4">
				<MUIDataTable
					columns={columns}
					data={data}
					options={{
						...tableBarSettings,
						selectableRows: "none",
						elevation: 0,
						textLabels: {
							body: {
								noMatch: loading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						responsive: "",
						rowsPerPage: 50,
						rowsPerPageOptions: tableRowsPerPage,
					}}
				/>
			</div>
		</HelmetLayout>
	);
};
export default AffiliateMonthlyOverview;
