import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { getRegionsTableColumns } from "./regionsTableColumns";
import { ShowEditRegions } from "./showEditRegions";
import { tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import LoadingSpinner from "../../../components/Loader/LoadingSpinner";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import { apiUrls } from "utils/const.apiUrl";
import ApiServices from "utils/ApiServices";
import HelmetLayout from "../../../components/HelmetLayout/HelmetLayout";
import MUIDataTable from "mui-datatables";

const Regions = props => {
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [regionStatus, setRegionStatus] = useState({});
	const [rowPerPage, setRowPerPage] = useState(50);
	const breadcrumbItems = [{ title: "Regions", link: "/regions" }];
	const [unfilteredData, setUnfilteredData] = useState([]);
	const [regionsData, setRegionsData] = useState([]);
	const [editItem, setEditItem] = useState(null);
	const [showModal, setShowModal] = useState({ editRegionModal: false });
	const [showPromotion, setShowPromotion] = useState(false);

	const getRegionsData = () => {
		setIsDataLoading(true);
		new Promise((resolve, reject) => {
			ApiServices.get(apiUrls.region_get, resolve, reject, true);
		})
			.then(response => {
				const data = response?.data;
				setRegionsData(data);
				setUnfilteredData(data);
			})
			.finally(() => {
				setIsDataLoading(false);
			});
	};

	const handleEditItem = row => {
		setEditItem(row);
		setShowPromotion(true);
	};

	useEffect(() => {
		getRegionsData();
	}, []);

	useEffect(() => {
		props.setBreadcrumbItems("Regions", breadcrumbItems);
		setRegionsData(regionsData);
		setUnfilteredData(regionsData);
	}, []);

	const saveRegionStatus = data => {
		var { id, status } = data;
		let active_status = status ? 1 : 0;
		let payload = {
			id: id,
			type: "region_status",
			status: active_status,
		};

		new Promise((resolve, reject) => {
			ApiServices.post(apiUrls.status_general_edit, resolve, reject, payload, true);
		}).then(response => {
			if (response.success) {
				setRegionStatus({ ...regionStatus, [id]: active_status });
			}
		});
	};

	useEffect(() => {
		if (regionsData && regionsData.length > 0) {
			var obj = {};
			regionsData.forEach(row => {
				obj[row.id] = row.status;
			});
			setRegionStatus(obj);
		} else {
			setRegionStatus({});
		}
	}, [regionsData]);

	let regionsTableColumns = getRegionsTableColumns({
		regionsData,
		regionStatus,
		saveRegionStatus,
		setShowModal,
		showModal,
		setEditItem,
		setRegionsData,
		getRegionsData,
		setShowPromotion,
		handleEditItem,
	});
	let page_name = "Regions";

	return (
		<HelmetLayout titleProps={"Regions"}>
			<div className="cms-page pt-0 cms-table-page">
				<div
					className="col-12 d-flex"
					style={{
						justifyContent: "space-between",
					}}
				>
					<div className="col-10">
						<SingleSearchFilter setData={setRegionsData} unfilteredData={unfilteredData} filterColumn={["name"]} />
					</div>
				</div>
				{showPromotion && (
					<ShowEditRegions
						title={"Region"}
						editItem={editItem}
						regionsNewData={regionsData}
						setRegionsNewData={setRegionsData}
						setShowPromotion={setShowPromotion}
						showPromotion={showPromotion}
						handler={() => {
							setShowPromotion(false);
							setEditItem(null);
						}}
					/>
				)}
				<MUIDataTable
					data={regionsData}
					columns={regionsTableColumns}
					options={{
						...tableBarSettings,
						selectableRows: false,
						elevation: 0,
						onChangeRowsPerPage(number) {
							setRowPerPage(number);
						},
						rowsPerPage: rowPerPage,
						textLabels: {
							body: {
								noMatch: isDataLoading ? <LoadingSpinner /> : " No data to display!",
							},
						},
						rowsPerPageOptions: tableRowsPerPage,
						downloadOptions: { filename: page_name + ".csv" },
					}}
				/>
			</div>
		</HelmetLayout>
	);
};

export default connect(null, { setBreadcrumbItems })(Regions);
