import { apiUrls } from "utils/const.apiUrl";
const { default: ApiServices } = require("utils/ApiServices");

let prevSportSlug = "";
let prevRegion = "";
let result = [];

export const getCompetitionsDropdown = async (sport_slug, region_id = "") => {
	if (!sport_slug) {
		result = [];
		prevSportSlug = "";
		prevRegion = "";
	}
	if (sport_slug && (prevSportSlug !== sport_slug || prevRegion !== region_id)) {
		prevSportSlug = sport_slug;
		prevRegion = region_id;

		let params = `?sport_slug=${sport_slug}`;
		if (region_id) params += `&region_id=${region_id}`;

		return new Promise((resolve, reject) => {
			//TODO Competion endpoint with sport slug
			ApiServices.get(`${apiUrls.GET_COMPETITION_OPTIONS}${params}`, resolve, reject, true);
		})
			.then(response => {
				let competitions = response?.data;
				competitions = competitions.map(row => {
					return { id: row.competition_id, label: row.name, name: row.name };
				});
				result = competitions;
				return competitions;
			})
			.catch(() => {
				result = [];
				return [];
			});
	} else return result;
};
