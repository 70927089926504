import React, { useEffect, useState } from "react";

import MUIDataTable from "mui-datatables";

import ApiServices from "utils/ApiServices";
import { apiUrls } from "utils/const.apiUrl";

import LoadingSpinner from "components/Loader/LoadingSpinner";
import { AddTableButton } from "components/GeneralComponents/AddTableButton";
import { tableRowsPerPage, tableBarSettings } from "utils/tableSettings";
import { getPromoLinksTableColumn } from "./getPromoLinksTableColumn";
import { SingleSearchFilter } from "components/GeneralComponents/CustomInputs";
import PromoLinksModal from "../PromoLinks/promoLinksModal";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DeleteAffiliates from "./DeleteAffiliates";

const AffiliatesLinks = props => {
	const { affiliateLinks: links, loading, affiliateLinksOptions, getAffiliateUser, getAffiliateLinks } = props;

	const { id: agent_id } = useParams();

	const [affiliateLinks, setAffiliateLinks] = useState(links);
	const [deleteItem, setDeleteItem] = useState(null);

	const [editItem, setEditItem] = useState(null);
	const [showPromotion, setShowPromotion] = useState("");

	const unfilteredData = links;

	useEffect(() => {
		if (links) setAffiliateLinks(links);
	}, [links]);

	const handleEditAffiliateItem = row => {
		setEditItem(row);
		setShowPromotion("edit");
	};

	const handleChangeDropdown = newValue => {
		setEditItem({ ...newValue, select_slug: newValue.slug });
	};

	const handlerSave = data => {
		const { id, name, slug, notes, onboard_date } = data;

		let payload = { agent_id: agent_id, name, slug, notes, onboard_date: onboard_date ? moment(onboard_date).format("YYYY-MM-DD HH:mm:ss") : "" };
		if (showPromotion === "edit") {
			payload = { ...payload, id };
		}

		new Promise((resolve, reject) => {
			if (showPromotion === "add") ApiServices.post(apiUrls.ADD_AFFILIATE_LINKS, resolve, reject, payload, true);
			else ApiServices.post(apiUrls.affiliate_users_add, resolve, reject, payload, true);
		}).then(response => {
			const { success } = response;
			if (success) {
				setEditItem(null);
				setShowPromotion("");
				if (showPromotion === "add") {
					getAffiliateUser();
				} else getAffiliateLinks();
			}
		});
	};

	const affiliateLinksColumn = getPromoLinksTableColumn({ affiliateLinks, handleEditAffiliateItem, setDeleteItem });

	return (
		<div style={{ position: "relative" }}>
			<SingleSearchFilter setData={setAffiliateLinks} unfilteredData={unfilteredData} detailsColumn={["name", "slug"]} />
			<MUIDataTable
				columns={affiliateLinksColumn}
				data={affiliateLinks}
				options={{
					...tableBarSettings,
					selectableRows: "none",
					sort: false,
					elevation: 0,
					textLabels: {
						body: {
							noMatch: loading ? <LoadingSpinner /> : "No data to display!",
						},
					},
					responsive: "",
					rowsPerPage: 50,
					rowsPerPageOptions: tableRowsPerPage,
					customToolbar: () => <AddTableButton onClick={() => setShowPromotion("add")} />,
				}}
			/>

			{showPromotion && (
				<PromoLinksModal
					title={"AFFILIATE LINKS"}
					editItem={editItem}
					data={affiliateLinks}
					setNewData={setAffiliateLinks}
					handlerSave={handlerSave}
					handler={() => {
						setShowPromotion("");
						setEditItem(null);
					}}
					extraFields={{
						type: "dropdown",
						name: "Select Slug",
						bindValue: editItem?.label,
						value: editItem?.slug,
						options: affiliateLinksOptions,
						handleChangeDropdown: handleChangeDropdown,
					}}
				/>
			)}

			{deleteItem && (
				<DeleteAffiliates
					type="affiliates_links"
					paragraph={`Are you sure you want to delete this affiliate link ${deleteItem?.name}`}
					deleteItem={deleteItem}
					setDeleteItem={setDeleteItem}
					getSubAgents={getAffiliateLinks}
				/>
			)}
		</div>
	);
};

export default AffiliatesLinks;
