import { createTheme } from "@mui/material/styles";

export const getMuiTheme = () =>
	createTheme({
		components: {
			MuiTableCell: {
				styleOverrides: {
					root: {
						border: "0px",
						fontFamily: "Poppins",
					},
				},
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						padding: "7px",
						color: "#5C626A",
						fontFamily: "Poppins",
					},
				},
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						"&:nth-of-type(odd)": {
							backgroundColor: "#F8F9FA",
						},
					},
				},
			},
		},
	});
